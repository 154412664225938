import { styled, ToggleButton as MuiToggleButton, toggleButtonClasses } from '@mui/material';
export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    [`&.${toggleButtonClasses.root}`]: {
        borderRadius: theme.shape.borderRadius * 2,
        backgroundColor: theme.palette.container.fill.card2,
        border: `1px solid ${theme.palette.container.border.default}`,
        color: theme.palette.text.title,
        padding: '6px 12px',
        '&:focus-visible': {
            outline: `2px solid ${theme.palette.clickable.default}`,
            outlineOffset: '2px',
        },
        '&:hover': {
            backgroundColor: theme.palette.container.fill.card2,
            borderColor: theme.palette.container.border.input,
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.container.fill.card2,
            borderColor: theme.palette.container.border.input,
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.container.fill.card2,
            border: `2px ${theme.palette.container.border.active} solid`,
            padding: '5px 11px',
        },
    },
    [`&.${toggleButtonClasses.disabled}`]: {
        color: theme.palette.container.border.input,
        borderColor: theme.palette.container.border.input,
        backgroundColor: theme.palette.container.fill.body,
    },
}));
