import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import humanize from 'humanize-duration';
import { getLanguageIcon } from './metadata';

const MODULE_RUN = `${MODULE}-Run`;
export default MODULE_RUN;

(function () {
  var app = angular.module(MODULE_RUN, []);

  app.run([
    '$rootScope',
    '$log',
    '$translate',
    'UtilityService',
    function ($rootScope, $log, $translate, UtilityService) {
      $rootScope.utility = $rootScope.utility || {};

      $rootScope.utility.longFormatDuration = UtilityService.longFormatDuration;
      $rootScope.utility.shortFormatDuration = UtilityService.shortFormatDuration;

      $rootScope.utility.humanizeDuration = function (durationInMs) {
        let locale = $translate.preferredLanguage();
        if (!locale || locale.toLowerCase() === 'uk') {
          locale = 'en';
        } else if (locale.toLowerCase() === 'zh-tw') {
          locale = 'zh_TW';
        } else if (locale.toLowerCase() == 'zh') {
          locale = 'zh_CN';
        } else {
          locale = locale.toLowerCase();
        }

        const ms = durationInMs - (durationInMs % 1000) || 0;
        return humanize(ms, { language: locale, fallbacks: ['en'] });
      };

      // This one rounds down to the nearest whole so there will be
      //   descrepencies between email correspondence and the UI.
      //    it's also a bit inaccurate.
      $rootScope.utility.quickFormatTime = function (milliseconds) {
        var temp = Math.floor(milliseconds / 1000);
        var days = Math.floor(temp / 86400);
        if (days) {
          return days + ' ' + $translate.instant('DATE_TIME_SHORT.DAYS');
        }
        var hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
          return hours + ' ' + $translate.instant('DATE_TIME_SHORT.HOURS');
        }
        var minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
          return minutes + ' ' + $translate.instant('DATE_TIME_SHORT.MINUTES');
        }
        var seconds = temp % 60;
        if (seconds) {
          return seconds + ' ' + $translate.instant('DATE_TIME_SHORT.SECONDS');
        }
        return '0'; //'just now' //or other string you like;
      };

      $rootScope.utility.dateDisplay = function (date, format) {
        return moment(date).format(format || 'lll');
      };

      var MINUTE_MILLIS = 60000;
      $rootScope.utility.convertToTimezone = function (theDate, timezone) {
        $log.debug('Converting ', theDate, ' from UTC to ', timezone);
        if (timezone) {
          var offset = moment.tz.zone(timezone).offset(theDate.valueOf());
          var shiftedDate = new Date(theDate.getTime() + MINUTE_MILLIS * offset);
          $log.debug('New date ', shiftedDate);
          return shiftedDate;
        } else {
          return theDate;
        }
      };

      $rootScope.utility.convertToUTC = function (theDate, oldTimezone) {
        $log.debug('Converting ', theDate, ' from ', oldTimezone, ' to UTC');
        var offset = moment.tz.zone(oldTimezone).offset(theDate.valueOf());
        var shiftedDate = new Date(theDate.getTime() - MINUTE_MILLIS * offset);
        $log.debug('UTC date ', shiftedDate);
        return shiftedDate;
      };

      $rootScope.utility.languageIcon = (language) => getLanguageIcon($rootScope.metadata.languages, language);

      $rootScope.utility.getProgressBarType = function (percent) {
        if (percent <= 25) {
          return 'danger';
        } else if (percent <= 50) {
          return 'warning';
        } else if (percent <= 75) {
          return 'info';
        } else {
          return 'success';
        }
      };

      $rootScope.utility.stitchQuestProgressStory = function (langState) {
        langState.displayLanguage = $rootScope.metadata.languages[langState.language._id].name;
        langState.displayFramework =
          $rootScope.metadata.languages[langState.language._id].framework[langState.language._framework].name;
        langState.displayComplete = 0;
        langState.displayTotal = 0;

        for (var j = 0; j < langState.story.realms.length; j++) {
          var realm = langState.story.realms[j];

          // do not calculate public realm progress.
          if (realm.public) continue;

          var stateRealm = langState.state && langState.state.quest && langState.state.quest[realm._id];
          realm.displayComplete = 0;
          realm.displayTotal = 0;

          for (var k = 0; k < realm.levels.length; k++) {
            var level = realm.levels[k];
            var stateLevel = stateRealm && stateRealm[level._id];
            level.displayComplete = 0;
            level.displayTotal = 0;

            for (var l = 0; l < level.quests.length; l++) {
              var quest = level.quests[l];
              var stateQuest = stateLevel && stateLevel[quest._id];
              quest.displayComplete = 0;
              quest.displayTotal = quest.challenges.length;

              if (stateQuest && stateQuest.attempts && stateQuest.attempts.length) {
                quest.displayComplete += stateQuest.attempts[stateQuest.attempts.length - 1].progress.completed || 0;
              }

              quest.displayIncomplete = quest.displayTotal - quest.displayComplete;
              quest.displayCompletePercent = Math.round((quest.displayComplete / quest.displayTotal) * 100);
              quest.displayCompleteType = $rootScope.utility.getProgressBarType(quest.displayCompletePercent);

              level.displayComplete += quest.displayComplete;
              level.displayTotal += quest.displayTotal;
            }

            level.displayIncomplete = level.displayTotal - level.displayComplete;
            level.displayCompletePercent = Math.round((level.displayComplete / level.displayTotal) * 100);
            level.displayCompleteType = $rootScope.utility.getProgressBarType(level.displayCompletePercent);

            realm.displayComplete += level.displayComplete;
            realm.displayTotal += level.displayTotal;
          }

          realm.displayIncomplete = realm.displayTotal - realm.displayComplete;
          realm.displayCompletePercent = Math.round((realm.displayComplete / realm.displayTotal) * 100);
          realm.displayCompleteType = $rootScope.utility.getProgressBarType(realm.displayCompletePercent);

          langState.displayComplete += realm.displayComplete;
          langState.displayTotal += realm.displayTotal;
        }

        langState.displayIncomplete = langState.displayTotal - langState.displayComplete;
        langState.displayCompletePercent = Math.round((langState.displayComplete / langState.displayTotal) * 100);
        langState.displayCompleteType = $rootScope.utility.getProgressBarType(langState.displayCompletePercent);
      };

      /* Taken from http://stackoverflow.com/a/6832721/11236 */
      $rootScope.utility.compareVersionNumbers = function (v1, v2) {
        var v1parts = v1.split('.');
        var v2parts = v2.split('.');

        // First, validate both numbers are true version numbers
        function validateParts(parts) {
          for (var i = 0; i < parts.length; ++i) {
            if (parts[i] < 0 || typeof parseInt(parts[i], 10) !== 'number') {
              return false;
            }
          }
          return true;
        }

        if (!validateParts(v1parts) || !validateParts(v2parts)) {
          return NaN;
        }

        for (var i = 0; i < v1parts.length; ++i) {
          if (v2parts.length === i) {
            return 1;
          }

          if (parseInt(v1parts[i], 10) === parseInt(v2parts[i], 10)) {
            continue;
          }
          if (parseInt(v1parts[i], 10) > parseInt(v2parts[i], 10)) {
            return 1;
          }
          return -1;
        }

        if (v1parts.length !== v2parts.length) {
          return -1;
        }

        return 0;
      };

      // PORTAL-2875 - Fix attempt scoring rounding errors
      // Floor result of division of two values to two decimal places
      $rootScope.utility.floorTwoDecimalPlaces = function (numerator, denominator) {
        return Math.floor((numerator / denominator) * 100 * 100) / 100;
      };

      // Floor a value to two decimal places
      $rootScope.utility.floorTwoDecimalPlacesValue = function (value) {
        return Math.floor(value * 100 * 100) / 100;
      };

      $rootScope.utility.getCCLanguagesFromAppType = function (appType) {
        var webLanguages = [
          { _id: 'java', _framework: 'ee' },
          { _id: 'java', _framework: 'spring_api' },
          { _id: 'java', _framework: 'ee_api' },
          { _id: 'c_sharp', _framework: 'vanilla' },
          { _id: 'c_sharp', _framework: 'web_api' },
          { _id: 'c_sharp', _framework: 'core' },
          { _id: 'c_sharp', _framework: 'mvc' },
          { _id: 'c_sharp', _framework: 'storyline' },
          { _id: 'c_sharp', _framework: 'web_forms' },
          { _id: 'java', _framework: 'vanilla' },
          { _id: 'java', _framework: 'spring' },
          { _id: 'java', _framework: 'servlets' },
          { _id: 'java', _framework: 'struts' },
          { _id: 'nodejs', _framework: 'express' },
          { _id: 'nodejs', _framework: 'api' },
          { _id: 'python', _framework: 'django' },
          { _id: 'python', _framework: 'vanilla' },
          { _id: 'python', _framework: 'api' },
          { _id: 'python', _framework: 'flask' },
          { _id: 'java', _framework: 'jsf' },
          { _id: 'salesforce', _framework: 'apex' },
          { _id: 'scala', _framework: 'play' },
          { _id: 'perl', _framework: 'dancer2' },
          { _id: 'php', _framework: 'symfony' },
          { _id: 'php', _framework: 'laravel' },
          { _id: 'php', _framework: 'vanilla' },
          { _id: 'ruby', _framework: 'rails' },
          { _id: 'rust', _framework: 'vanilla' },
          { _id: 'c', _framework: 'vanilla' },
          { _id: 'go', _framework: 'vanilla' },
          { _id: 'go', _framework: 'api' },
          { _id: 'ansible', _framework: 'vanilla' },
          { _id: 'docker', _framework: 'vanilla' },
          { _id: 'kubernetes', _framework: 'vanilla' },
          { _id: 'kotlin', _framework: 'spring_api' },
          { _id: 'terraform', _framework: 'azure' },
          { _id: 'terraform', _framework: 'gcp' },
          { _id: 'terraform', _framework: 'vanilla' },
          { _id: 'cloudformation', _framework: 'vanilla' },
          { _id: 'typescript', _framework: 'nodejs_express' },
        ];

        var mobileLanguages = [
          { _id: 'java', _framework: 'android' },
          { _id: 'javascript', _framework: 'react_native' },
          { _id: 'kotlin', _framework: 'vanilla' },
          { _id: 'swift', _framework: 'vanilla' },
          { _id: 'objective_c', _framework: 'ios' },
          { _id: 'dart', _framework: 'flutter' },
        ];
        var frontendLanguages = [
          { _id: 'angular', _framework: 'one' },
          { _id: 'javascript', _framework: 'vanilla' },
          { _id: 'javascript', _framework: 'angular_io' },
          { _id: 'javascript', _framework: 'react' },
          { _id: 'javascript', _framework: 'vuejs' },
          { _id: 'typescript', _framework: 'vanilla' },
          { _id: 'typescript', _framework: 'react' },
        ];
        var desktopLanguages = [{ _id: 'cpp', _framework: 'vanilla' }];
        var databaseLanguages = [
          { _id: 'plsql', _framework: 'vanilla' },
          { _id: 'tsql', _framework: 'vanilla' },
        ];
        var serverLanguages = [
          { _id: 'cobol', _framework: 'vanilla' },
          { _id: 'sap', _framework: 'abap' },
        ];
        var conceptualLanguages = [
          { _id: 'pseudocode', _framework: 'vanilla' },
          { _id: 'pseudocode', _framework: 'mobile' },
        ];
        var scriptLanguages = [{ _id: 'powershell', _framework: 'vanilla' }];

        switch (true) {
          case appType === 'web':
            return webLanguages;
          case appType === 'mobile':
            return mobileLanguages;
          case appType === 'frontend':
            return frontendLanguages;
          case appType === 'desktop':
            return desktopLanguages;
          case appType === 'database':
            return databaseLanguages;
          case appType === 'server':
            return serverLanguages;
          case appType === 'conceptual':
            return conceptualLanguages;
          case appType === 'script':
            return scriptLanguages;
          case appType === 'all':
            return []
              .concat(webLanguages)
              .concat(mobileLanguages)
              .concat(frontendLanguages)
              .concat(desktopLanguages)
              .concat(databaseLanguages)
              .concat(serverLanguages)
              .concat(conceptualLanguages)
              .concat(scriptLanguages);
          default:
            return [];
        }
      };
    },
  ]);
})();
