import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Star as StarIcon, StarOutline as EmptyStarIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
export const DIFFICULTIES = ['easy', 'medium', 'hard'];
export const difficultyTranslationMap = {
    easy: 'common.difficulty.easy',
    medium: 'common.difficulty.medium',
    hard: 'common.difficulty.hard',
};
export const difficultyFullTranslationMap = {
    easy: 'pages.assessments.results.difficulty.easy',
    medium: 'pages.assessments.results.difficulty.medium',
    hard: 'pages.assessments.results.difficulty.hard',
};
const difficultyRating = {
    easy: (_jsxs(_Fragment, { children: [_jsx(StarIcon, { color: "warning" }), _jsx(EmptyStarIcon, { sx: (theme) => ({ color: theme.palette.grayscale.bg7 }) }), _jsx(EmptyStarIcon, { sx: (theme) => ({ color: theme.palette.grayscale.bg7 }) })] })),
    medium: (_jsxs(_Fragment, { children: [_jsx(StarIcon, { color: "warning" }), _jsx(StarIcon, { color: "warning" }), _jsx(EmptyStarIcon, { sx: (theme) => ({ color: theme.palette.grayscale.bg7 }) })] })),
    hard: (_jsxs(_Fragment, { children: [_jsx(StarIcon, { color: "warning" }), _jsx(StarIcon, { color: "warning" }), _jsx(StarIcon, { color: "warning" })] })),
};
export const DifficultyRating = (props) => {
    const { t } = useTranslation();
    return (_jsx(Box, { role: "img", "aria-label": t(difficultyTranslationMap[props.difficulty]), children: difficultyRating[props.difficulty] }));
};
