var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { Icon, Dropdown, } from '@securecodewarrior/design-system-react/lib/legacy';
import { useEffect, useState } from 'react';
/** @deorecated This component uses an old icon component that's planned for removal */
const PlatformDropdown = (_a) => {
    var { iconName, items } = _a, props = __rest(_a, ["iconName", "items"]);
    const [listItems, setListItems] = useState([]);
    useEffect(() => {
        setListItems(items.map((item) => (Object.assign(Object.assign({}, item), { icon: _jsx(Icon, { name: item.icon }) }))));
    }, [items]);
    return _jsx(Dropdown, Object.assign({ component: _jsx(Icon, { name: iconName }), items: listItems }, props));
};
export default PlatformDropdown;
