export const newCodingLabAvailableAnalytics = (sendEvent, scope, labId) => ({
    onComponentLoaded: () => {
        sendEvent({ event: 'New Coding Lab Available', scope, labId });
    },
    onLoadNewLab: () => {
        sendEvent({ event: 'Discarded old coding lab and load new', scope, labId });
    },
    onLoadOldLab: () => {
        sendEvent({ event: 'Load old coding lab', scope, labId });
    },
});
