import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { usePlatformContext } from '../../../platformContext';
import { usePlatformNavigate } from '../../../navigation';
import { syncFilterState, createFilterStateFromLocation, getCybermonFilterState, isFilterStateEmpty, saveCybermonFilterState, } from './FilterState';
import { createCybermonFilterStore, CybermonFilterContext } from './FilterContext';
export const CybermonFilterProvider = ({ items, initialUserLanguageFrameworks, children }) => {
    const ctx = usePlatformContext();
    const nav = usePlatformNavigate();
    useEffect(() => {
        if (!ctx.sessionUser)
            return;
        syncFilterState(nav, initialUserLanguageFrameworks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx.sessionUser]);
    const filterStore = useMemo(() => {
        let initialFilterState = createFilterStateFromLocation(window.location.href);
        if (ctx.sessionUser) {
            const fromStorage = getCybermonFilterState();
            if (!isFilterStateEmpty(fromStorage)) {
                initialFilterState = fromStorage;
            }
            else if (!isFilterStateEmpty(initialFilterState)) {
                saveCybermonFilterState(initialFilterState);
            }
        }
        return createCybermonFilterStore(initialFilterState, items, nav.setSearchParams, ctx.logAnalyticsEvent);
    }, [nav, ctx.logAnalyticsEvent, items, ctx.sessionUser]);
    useEffect(() => {
        if (!ctx.sessionUser)
            return;
        const updateFilters = () => {
            const newFilterState = createFilterStateFromLocation(window.location.href);
            filterStore.getState().setFilterState(newFilterState, items);
        };
        ctx.addUrlChangeListener(updateFilters);
        return () => ctx.removeUrlChangeListener(updateFilters);
    }, [ctx.sessionUser, ctx, filterStore, items]);
    return _jsx(CybermonFilterContext.Provider, { value: filterStore, children: children });
};
