var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme, SvgIcon } from '@mui/material';
export const CybermonPointsIcon = (_a) => {
    var { type } = _a, props = __rest(_a, ["type"]);
    const theme = useTheme();
    if (type === 'coding_lab') {
        return (_jsx(SvgIcon, Object.assign({}, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.77778 0H14.2222C15.2 0 16 0.8 16 1.77778V14.2222C16 15.2 15.2 16 14.2222 16H1.77778C0.8 16 0 15.2 0 14.2222V1.77778C0 0.8 0.8 0 1.77778 0ZM10 11H7V9H9C10.1 9 11 8.1 11 7V5C11 3.9 10.1 3 9 3H6C5.45 3 5 3.45 5 4C5 4.55 5.45 5 6 5H9V7H7C5.9 7 5 7.9 5 9V12C5 12.55 5.45 13 6 13H10C10.55 13 11 12.55 11 12C11 11.45 10.55 11 10 11Z", fill: theme.palette.clickable.default }) })));
    }
    return (_jsx(SvgIcon, Object.assign({}, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.77778 0H14.2222C15.2 0 16 0.8 16 1.77778V14.2222C16 15.2 15.2 16 14.2222 16H1.77778C0.8 16 0 15.2 0 14.2222V1.77778C0 0.8 0.8 0 1.77778 0ZM9 13C9.55 13 10 12.55 10 12V4C10 3.45 9.55 3 9 3H7C6.45 3 6 3.45 6 4C6 4.55 6.45 5 7 5H8V12C8 12.55 8.45 13 9 13Z", fill: theme.palette.clickable.hover }) })));
};
