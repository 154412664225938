import { jsx as _jsx } from "react/jsx-runtime";
import { Snackbar, IconButton, Alert, Typography, useTheme } from '@mui/material';
import { Icon } from '@securecodewarrior/design-system-react/lib/legacy';
const TournamentSnackbar = ({ open, position, handleClose, duration, customClass, message, uniqueKey, }) => {
    const theme = useTheme();
    const snackBarStyle = {
        bottom: '10%',
        border: `${theme.typography.pxToRem(2)} solid #2AB694`,
        borderRadius: '8px',
    };
    return (_jsx(Snackbar, { anchorOrigin: position, open: open, autoHideDuration: duration, onClose: handleClose, style: snackBarStyle, children: _jsx(Alert, { severity: "success", action: _jsx(IconButton, { "aria-label": "close", sx: { p: 0.5 }, color: "inherit", onClick: handleClose, children: _jsx(Icon, { name: "close" }) }), children: _jsx(Typography, { variant: "body1", className: customClass, children: message }) }) }, uniqueKey));
};
export default TournamentSnackbar;
