const HUNDRED_HOURS = 100 * 60 * 60 * 1000;
// This function does not use retriesAllowed but instead expects maxRetries = 0 to represent retriesAllowed = false.
function hasValue(value) {
    return value !== null && !(value instanceof Error);
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getAssessmentSettingsValidation(settings, name, t) {
    const errors = [];
    if (typeof name === 'string' && name.length > 1024) {
        errors.push({ field: 'name', message: t('pages.multiassessments.validation.nameLengthExceeded') });
    }
    if (hasValue(settings.startDate) && hasValue(settings.endDate) && settings.startDate > settings.endDate) {
        errors.push({
            field: 'startDate',
            message: t('pages.multiassessments.validation.startTimeBeforeEndTime'),
        });
    }
    if (settings.lmsIntegrated) {
        if (hasValue(settings.startDate)) {
            errors.push({
                field: 'startDate',
                message: t('pages.multiassessments.validation.noSchedulingWithLMS'),
            });
        }
        if (hasValue(settings.endDate)) {
            errors.push({
                field: 'endDate',
                message: t('pages.multiassessments.validation.noSchedulingWithLMS'),
            });
        }
        if (hasValue(settings.sendInvitesOn)) {
            errors.push({
                field: 'sendInvitesOn',
                message: t('pages.multiassessments.validation.noSchedulingWithLMS'),
            });
        }
        if (!settings.selfAssess) {
            errors.push({
                field: 'selfAssess',
                message: t('pages.multiassessments.validation.selfAssessmentMandatoryWithLMS'),
            });
        }
        if (hasValue(settings.maxRetries)) {
            errors.push({
                field: 'maxRetries',
                message: t('pages.multiassessments.validation.lmsCompatibility'),
            });
        }
    }
    if (hasValue(settings.sendInvitesOn)) {
        if (settings.startDate === null) {
            errors.push({
                field: 'sendInvitesOn',
                message: t('pages.multiassessments.validation.startDateRequired'),
            });
        }
        if (hasValue(settings.startDate) && settings.startDate < settings.sendInvitesOn) {
            errors.push({
                field: 'sendInvitesOn',
                message: t('pages.multiassessments.validation.inviteDateBeforeStartDate'),
            });
        }
        if (settings.selfAssess) {
            errors.push({
                field: 'sendInvitesOn',
                message: t('pages.multiassessments.validation.disableSendInvitesOnSelfAssess'),
            });
        }
    }
    const timeLimitIssue = hasValue(settings.timeLimit) && validateNumberInRange(settings.timeLimit, 0, HUNDRED_HOURS, t);
    if (timeLimitIssue) {
        errors.push({
            field: 'timeLimit',
            message: hasValue(settings.timeLimit) && settings.timeLimit > HUNDRED_HOURS
                ? t('pages.multiassessments.validation.timeLimitExceeded')
                : timeLimitIssue,
        });
    }
    const successRatioIssue = hasValue(settings.successRatio) && validateNumberInRange(settings.successRatio, 0, 100, t);
    if (successRatioIssue) {
        errors.push({ field: 'successRatio', message: successRatioIssue });
    }
    const maxRetriesIssue = hasValue(settings.maxRetries) && validateNumberInRange(settings.maxRetries, 0, 99, t);
    if (maxRetriesIssue) {
        errors.push({ field: 'maxRetries', message: maxRetriesIssue });
    }
    const retryWaitingHoursIssue = hasValue(settings.retryWaitingHours) && validateNumberInRange(settings.retryWaitingHours, 0, 72, t);
    if (retryWaitingHoursIssue) {
        errors.push({ field: 'retryWaitingHours', message: retryWaitingHoursIssue });
    }
    if (hasValue(settings.startDate) && isNaN(settings.startDate.getTime())) {
        errors.push({ field: 'startDate', message: t('pages.multiassessments.validation.invalidDate') });
    }
    if (hasValue(settings.endDate) && isNaN(settings.endDate.getTime())) {
        errors.push({ field: 'endDate', message: t('pages.multiassessments.validation.invalidDate') });
    }
    if (hasValue(settings.sendInvitesOn) && isNaN(settings.sendInvitesOn.getTime())) {
        errors.push({ field: 'sendInvitesOn', message: t('pages.multiassessments.validation.invalidDate') });
    }
    return errors;
}
function validateNumberInRange(value, lowerLimit = null, upperLimit = null, t) {
    if (value === null || value === undefined)
        return null;
    if (typeof value !== 'number' || isNaN(value))
        return t('pages.multiassessments.nameMustBeNumber');
    if (lowerLimit !== null && value < lowerLimit)
        return t('pages.multiassessments.validation.minNumberLimit', { lowerLimit });
    if (upperLimit !== null && value > upperLimit)
        return t('pages.multiassessments.validation.maxNumberLimit', { upperLimit });
    // Disabled because some data exists that is not whole numbers
    // if (Math.round(value) !== value) return t('pages.multiassessments.nameMustBeWholeNumber');
    return null;
}
