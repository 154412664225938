import angular from 'angular';
import MODULE from './module';
import templateUrl from './admin.allowances.widget.html';

angular.module(MODULE).directive('adminAllowancesWidget', [
  '$window',
  '$rootScope',
  function ($window, $rootScope) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        company: '=?',
        team: '=?',
        showProgressBar: '=?',
        showCountersOnDisabled: '=?',
        inheritUsageLicense: '=?',
      },
      link: function (scope, _element, _attrs) {
        var team, company;

        // @tmp
        $window._allowancesWidget = scope;

        // Begin: exposed data
        scope.metadata = $rootScope.metadata;
        scope.allowances = {};
        // End: exposed data

        scope.$watchGroup(['team', 'company'], function () {
          if (!scope.team && !scope.company) return;

          team = scope.team;
          company = scope.company;

          scope.allowances = team ? team.allowances : company.allowances;

          scope.lesser = _.mapValues(
            {
              training: null,
              assessments: null,
              tournaments: null,
              sensei: null,
            },
            function (_allowance, module) {
              return getLesserLicense(module, [team, company]);
            }
          );

          scope.allowances = _.mapValues(scope.allowances, function (value, key) {
            if (scope.lesser[key].license == 'disabled') return scope.lesser[key];

            return value;
          });
        });

        function getLesserLicense(module, scopes) {
          var licensePriority = ['disabled', 'usage', 'unlimited'];
          var valid,
            validPriority = 5;
          _.forEach(scopes, function (entity) {
            if (!entity || !entity.allowances) return;

            var curr = entity.allowances[module];
            var currPriority = curr ? licensePriority.indexOf(curr.license) : -1;

            if (!valid || currPriority < validPriority) {
              // usage .license inheritance if explicitly indicated
              if (valid && !scope.inheritUsageLicense && curr.license !== 'disabled') return;

              validPriority = currPriority;
              valid = curr;
            }
          });

          return valid;
        }
      },
    };
  },
]);
