import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.service('LearningModulesApi', [
  '$http',
  '$rootScope',
  '$window',
  'FeatureFlags',
  'FeatureFlagsApi',
  'HttpConfigService',
  'Session',
  function ($http, $rootScope, $window, FeatureFlags, FeatureFlagsApi, HttpConfigService, Session) {
    const service = {};
    $rootScope.learningModules = Promise.resolve({});
    let lastChecked;

    service.isLearningModuleEnabled = (learningModule) =>
      $rootScope.learningModules.then((learningModules) => learningModules[learningModule]);

    service.refreshLearningModules = async (force = false) => {
      if (!Session.user) {
        return $rootScope.learningModules;
      }

      const shouldCheckModules = await FeatureFlagsApi.isFeatureEnabled(FeatureFlags.QUESTS_V2);
      if (!shouldCheckModules) {
        return $rootScope.learningModules;
      }

      if (!force && lastChecked && new Date().getTime() - lastChecked.getTime() < 5 * 60 * 1000) {
        return $rootScope.learningModules;
      }

      lastChecked = new Date();
      return ($rootScope.learningModules = $http
        .get($window.SCW_ENV.ApiEndpoint + '/learning-modules', HttpConfigService.getHttpConfigNoIFR())
        .then(({ data: learningModules }) => learningModules)
        .catch(function (err) {
          console.error(err);
          return {};
        }));
    };

    return service;
  },
]);
