var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
export const useLogAnalyticsEvent = (logFn) => {
    return useCallback((event) => {
        const { event: eventName } = event, eventProps = __rest(event, ["event"]);
        try {
            logFn(eventName, eventProps);
        }
        catch (error) {
            console.error('error sending analytics event: ', { error, event });
        }
    }, [logFn]);
};
