import angular from 'angular';
import MODULE from './module';

/**
 * Provide a global behavior to prevent Event(Click) propagation
 * after a user click on an ng-click or ui-sref element.
 * Generally, it is unintended behavior and
 * causes more problems than solutions.
 *
 * If you still want Event(Click) to propagate, just:
 * ```
 * <button ng-click="doDomething()" click-propagation="true">
 * 		This will propagate.
 * </button>
 * ```
 */

angular.module(MODULE).config([
  '$provide',
  function ($provide) {
    $provide.decorator('uiSrefDirective', ['$delegate', uiSrefDecorator]);
    $provide.decorator('ngClickDirective', ['$delegate', ngClickDecorator]);

    /**
     * Find provided pattern in array
     *
     * @param {String} pattern
     * @param {Array} arr
     */
    function matched(pattern, arr) {
      var reg = new RegExp(pattern);

      for (var i = 0; i < arr.length; i++) {
        if (typeof arr[i] === 'string' && arr[i].match(reg)) return true;
      }
      return false;
    }

    /**
     * Decorate ng-click to prevent event bubbling.
     * @param {NgDirectiveDelegate} $delegate
     */
    function ngClickDecorator($delegate) {
      var compile = $delegate[0].compile;
      $delegate[0].compile = function (element, attrs, transclude) {
        var decorated = compile(element, attrs, transclude);
        return function (scope, element) {
          // trying to ignore binding to ui-select
          var classes = element.prop('class').split(' ');
          var uiSelectedElement = matched('ui-select*', classes);

          if (!attrs['clickPropagation'] && !uiSelectedElement) element.bind('click', stopPropagation);

          return decorated(scope, element);
        };
      };
      return $delegate;
    }

    /**
     * Decorate ui-sref to prevent event bubbling.
     * @param {NgDirectiveDelegate} $delegate
     */
    function uiSrefDecorator($delegate) {
      var link = $delegate[0].link;
      $delegate[0].compile = function (_element, attrs, _transclude) {
        // var decorated = compile(element, attrs, transclude);
        return function (_scope, element) {
          if (!attrs['clickPropagation']) element.bind('click', stopPropagation);

          if (link) link.apply(this, arguments);
        };
      };
      return $delegate;
    }

    /**
     * Stop propagation/bubbling if possible.
     * @param {DOMEvent} event
     */
    function stopPropagation(event) {
      if (event && event.stopPropagation) event.stopPropagation();
    }
  },
]);
