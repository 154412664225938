import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { FilterAltRounded as FilterIcon } from '@mui/icons-material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
import { CybermonIcon } from './components/CybermonIcon';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CybermonAccessibility } from './components/CybermonAccessibility';
import { ITEM_INCREMENT } from './hooks/useCybermonActivityFilter';
export const CybermonSkeleton = () => {
    const { t } = useTranslation();
    return (_jsxs("main", { children: [_jsx(CybermonAccessibility, {}), _jsx(Stack, { direction: "row", justifyContent: "space-between", mb: 6, children: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(CybermonIcon, { fontSize: 40, color: "text.soft" }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('pages.cybermon.titleOverline') }), _jsx(Typography, { component: "h2", variant: "h2", children: t('pages.cybermon.title') })] })] }) }), _jsxs(Box, { display: "grid", gap: 7, gridTemplateColumns: "minmax(340px, 3fr) 9fr", children: [_jsxs(Stack, { gap: 7, direction: "column", children: [_jsxs(Card, { sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                    padding: 5,
                                }, children: [_jsx(Typography, { variant: "h5", children: t('pages.cybermon.introduction.title') }), _jsx(Typography, { children: t('pages.cybermon.introduction.paragraph1') }), _jsx(Typography, { children: t('pages.cybermon.introduction.paragraph2') }), _jsx(Typography, { variant: "subtitle1", fontWeight: "bold", color: "text.title", children: t('pages.cybermon.introduction.paragraph3') })] }), _jsxs(Card, { sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 6,
                                    background: 'none',
                                    padding: 5,
                                }, children: [_jsx(Typography, { variant: "h5", children: t('pages.cybermon.badges.title') }), _jsx(Stack, { gap: 3, sx: { width: '100%' }, children: Array.from({ length: 5 }).map((_, index) => (_jsxs(Card, { sx: {
                                                padding: 0,
                                                display: 'grid',
                                                gridTemplateColumns: '80px 1fr',
                                                alignItems: 'center',
                                                height: 80,
                                                overflow: 'hidden',
                                            }, children: [_jsx(Skeleton, { variant: "rectangular", width: 80, height: 80, sx: { backgroundColor: '#3A4971' } }), _jsxs(Box, { sx: { flexGrow: 1, padding: '0 16px' }, children: [_jsx(Skeleton, { variant: "text", width: "60%", height: 20 }), _jsx(Skeleton, { variant: "text", width: "30%", height: 15 })] })] }, index))) })] })] }), _jsxs(Stack, { gap: 7, direction: "column", children: [_jsx(Card, { height: 93, sx: { display: 'flex', alignItems: 'center' }, children: _jsx(Skeleton, { variant: "rectangular", width: "40%", height: 20, sx: { borderRadius: '7px' } }) }), _jsxs(Card, { sx: {
                                    padding: 0,
                                    display: 'grid',
                                    width: '100%',
                                    height: { xs: 'auto', lg: '650px' },
                                    overflow: 'hidden',
                                    gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' },
                                }, children: [_jsx(Skeleton, { variant: "rectangular", width: "100%", sx: {
                                            backgroundColor: (theme) => theme.palette.skeleton.default,
                                            height: { xs: 'unset', lg: '100%' },
                                            aspectRatio: { xs: '1 / 1', lg: 'auto' },
                                        } }), _jsxs(Box, { sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '28px',
                                            width: '100%',
                                            gap: '40px',
                                        }, children: [_jsxs(Stack, { gap: 1, children: [_jsx(Skeleton, { variant: "rectangular", width: "30%", height: 20, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "40%", height: 15, sx: { borderRadius: '7px' } })] }), _jsxs(Stack, { gap: 1, children: [_jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "25%", height: 9, sx: { borderRadius: '7px ' } })] }), _jsxs(Stack, { gap: 1, children: [_jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "25%", height: 9, sx: { borderRadius: '7px' } })] }), _jsxs(Stack, { gap: 1, children: [_jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "25%", height: 9, sx: { borderRadius: '7px' } })] }), _jsxs(Stack, { gap: 1, children: [_jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 9, sx: { borderRadius: '7px' } }), _jsx(Skeleton, { variant: "rectangular", width: "25%", height: 9, sx: { borderRadius: '7px' } })] })] })] }), _jsxs(Card, { sx: {
                                    width: '100%',
                                    padding: 0,
                                    overflow: 'hidden',
                                    background: 'none',
                                }, children: [_jsxs(Box, { sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '20px',
                                            gap: '20px',
                                        }, children: [_jsx(Box, { sx: { display: 'flex', gap: '20px', width: '100%' }, children: _jsx(Button, { variant: "text", disabled: true, startIcon: _jsx(FilterIcon, {}), children: t('components.organisms.languageSelection.filter.select') }) }), _jsx(Box, { sx: {
                                                    display: 'grid',
                                                    gap: '20px',
                                                    gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr 1fr' },
                                                }, children: Array.from({ length: ITEM_INCREMENT }).map((_, index) => (_jsxs(Card, { sx: {
                                                        padding: 0,
                                                        width: '100%',
                                                        height: 282,
                                                    }, children: [_jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '12px 20px',
                                                                borderBottom: '1px solid #3A4971',
                                                            }, children: [_jsx(Skeleton, { variant: "rectangular", width: 24, height: 24, sx: { marginRight: '8px' } }), _jsx(Skeleton, { variant: "text", width: "60%", height: 20 })] }), _jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                padding: '20px',
                                                                gap: '10px',
                                                            }, children: [_jsx(Skeleton, { variant: "text", width: "80%", height: 24 }), _jsx(Skeleton, { variant: "text", width: "90%", height: 20 }), _jsx(Skeleton, { variant: "text", width: "40%", height: 20 })] })] }, index))) })] }), _jsx(Box, { sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 5,
                                            borderTop: (theme) => `1px solid ${theme.palette.container.border.default}`,
                                        }, children: _jsx(Button, { variant: "text", disabled: true, children: t('pages.cybermon.loadMore') }) })] })] })] })] }));
};
