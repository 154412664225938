import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

// templates
import searchTemplate from './admin.teams.search.html';
import editTemplate from './admin.teams.edit.html';

angular.module(MODULE).directive('adminTeamsSearchTable', [
  '$window',
  '$translate',
  '$timeout',
  '$uibModal',
  '$state',
  '$rootScope',
  '$swal',
  'AuthService',
  'AdminApiService',
  'Session',
  'ErrorHandler',
  'AdminBulkReInviteUsers',
  function (
    $window,
    $translate,
    $timeout,
    $uibModal,
    $state,
    $rootScope,
    $swal,
    AuthService,
    AdminApiService,
    Session,
    ErrorHandler,
    AdminBulkReInviteUsers
  ) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: searchTemplate,
      scope: {
        id: '@',
        companyId: '=?companyId',
        company: '=?company',
        sort: '=?sort',
        autoInit: '=?autoInit',
        paginationPreferences: '=?paginationPreferences',
        onTeamUpdate: '&?onTeamUpdate',
        autoFocus: '=?',
        bind: '=?bind',
        isMetricsEnabled: '=?isMetricsEnabled',
      },
      link: function (scope, element, _attrs, _controller, _transcludeFn) {
        // @tmp
        $window._teamSearch = scope;

        scope.bind = scope.bind || {};
        scope.auth = AuthService;
        scope.metadata = $rootScope.metadata;

        // Being: subscribe to team updates in other instances
        var subscribed = [];
        subscribed.push(
          scope.$root.$on('admin:teams-updated', function () {
            reload(true);
          })
        );

        scope.$on('$destroy', function () {
          var idx;
          for (idx = 0; idx < subscribed.length; idx++) subscribed[idx]();
        });
        // End: subscribe to user updates in other instances

        // Begin: init component
        var teamsQuery = {
          _cid: scope.companyId,
        };

        scope.$watch('companyId', function (companyId) {
          if (companyId != undefined) teamsQuery['_cid'] = companyId;
        });

        if (scope.autoInit) {
          scope.$watchGroup(['companyId'], function (_n, _o) {
            $timeout(function () {
              scope.teamsQuery = teamsQuery;
            }, 10);
          });
        }
        // End: init component

        // Begin: expose behaviors and data
        scope.searchText = '';
        scope.addTeam = addTeam;
        scope.toggleTeamStatus = toggleTeamStatus;
        scope.removeTeam = removeTeam;
        scope.Session = Session;
        scope.isTeamActive = isTeamActive;
        scope.search = search;
        scope.reload = reload;
        scope.baseSearch = {}; // base search control
        scope.paginationOptions = {};
        scope.bulkActions = {};
        scope.onSearchCompletion = onSearchCompletion;
        // undefined or null would mean data has not been passed and in that case it should default to what we have today
        scope.showMetrics = scope.isMetricsEnabled ?? true;
        scope.bulkReInviteCompany = bulkReInviteCompany;

        $timeout(init, 1);
        // End: expose behaviors and data

        function init() {
          if (!scope.company && scope.companyId) {
            AdminApiService.getCompany(scope.companyId).then(function (company) {
              scope.company = company;
            });
          }
        }

        // visual: scroll to table after search and pagination (low-res hack)
        function onSearchCompletion(_page) {
          if (scope.autoFocus) {
            $timeout(function () {
              if (scope.autoFocus === 'always' || (scope.autoFocus === 'non-first' && onSearchCompletion.loadedFirst)) {
                // var elementTop = element.offset().top;
                // $window.scroll({ top: elementTop - 200, behavior: "smooth" });
                angular.element('table tbody tr:nth(0)', element).focus();
              }
            }, 500);
          }

          if (!onSearchCompletion.loadedFirst) onSearchCompletion.loadedFirst = true;
        }

        function reload(keepOptions) {
          if (scope.baseSearch.search.loaded) scope.baseSearch.search(scope.baseSearch.search.text, keepOptions);
        }

        function search(text, keepOptions) {
          scope.teamsQuery = {};

          if (scope.companyId != undefined) scope.teamsQuery['_cid'] = scope.companyId;

          search.loaded = true;
          search.text = text;
          scope.baseSearch.search(text, keepOptions, scope.teamsQuery);
        }

        function removeTeam(team) {
          $swal({
            title: $translate.instant('DELETE_TEAM'),
            text: $translate.instant('DELETE_TEAM_CONFIRMATION'),
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: $translate.instant('DELETE'),
            cancelButtonText: $translate.instant('CANCEL'),
            keyResolution: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              var previousStatus = team.status;
              team.status = 'removed';

              AdminApiService.deleteTeam(team._id)
                .then(function (message) {
                  if (message && message.mode == 'async') {
                    var messagebase = 'Management.Teams.Remove.goneAsync.';
                    return $swal({
                      type: 'info',
                      title: $translate.instant(messagebase + 'title'),
                      text: $translate.instant(messagebase + 'text'),
                      confirmButtonText: $translate.instant('OK'),
                      keyResolution: true,
                    }).then(function () {
                      reload(true);

                      if (scope.onTeamUpdate) scope.onTeamUpdate();
                    });
                  }

                  reload(true);

                  if (scope.onTeamUpdate) scope.onTeamUpdate();
                })
                .catch(function (response) {
                  team.status = previousStatus;

                  $timeout(function () {
                    ErrorHandler.addError(response.data.error);
                  }, 100);
                });
            }
          });
        }

        function toggleTeamStatus(team) {
          var previousStatus = team.status;

          //Replicating the same logic as the front end
          team.status = isTeamActive(team) ? 'disabled' : 'enabled';
          var action = team.status == 'enabled' ? 'enable' : 'disable';

          /*team.status = (team.status == "enabled") ? "disabled" : "enabled";
          var action = (team.status == "enabled") ? "enable" : "disable";*/

          AdminApiService.updateTeam(team)
            .then(function (data) {
              reload(true);

              if (data.status === 'enabled' && data.activationDate > Date.now()) {
                $translate(['ACTIVATION_DATE_IN_FUTURE', 'ACTIVATION_DATE_IN_FUTURE_MSG', 'OK'], {
                  activationDate: moment(data.activationDate).format('LLLL'),
                }).then(function (translations) {
                  $swal({
                    title: translations.ACTIVATION_DATE_IN_FUTURE,
                    text: translations.ACTIVATION_DATE_IN_FUTURE_MSG,
                    type: 'warning',
                    html: false,
                    confirmButtonColor: 'var(--color-scw-red)',
                    keyResolution: true,
                    confirmButtonText: translations.OK,
                  });
                });
              }

              if (scope.onTeamUpdate) scope.onTeamUpdate();
            })
            .catch(function (response) {
              team.status = previousStatus;
              /*$translate(["FAILED_TO_ACTION_TEAM"], {action: action}).then(function(translations) {
              if(response.data.details.validationErrors[0].message==="the team cannot be edited as it has expired") //only restricting the translation to this error
              {
                response.data.details.validationErrors[0].message=$translate.instant("ENABLE_TEAM_VALIDATION"); //Get this error, translate accordingly
              }
              ErrorHandler.addHttpError(translations.FAILED_TO_ACTION_TEAM, response);
            });*/
              if (response.data.details.validationErrors[0].message === 'the team cannot be edited as it has expired') {
                //only restricting the translation to this error
                response.data.details.validationErrors[0].message = $translate.instant('ENABLE_TEAM_VALIDATION'); //Get this error, translate accordingly
              }

              ErrorHandler.addHttpError($translate.instant('FAILED_TO_ACTION_TEAM', { action: action }), response);
            });
        }

        function isTeamActive(team) {
          if (team.status === 'disabled') return false;

          var now = Date.now();
          return (
            (!team.expirationDate && !team.activationDate) || // dates not set
            (!team.expirationDate && team.activationDate < now) || // past activation date
            (!team.activationDate && team.expirationDate > now) || // before expiration date
            (team.activationDate < now && team.expirationDate > now) // between activation date and expiration date
          );
        }

        // adds a new team, then navigates to team detail view
        function addTeam() {
          return $uibModal
            .open({
              templateUrl: editTemplate,
              controller: 'EditTeamModalController',
              size: 'lg',
              scope: scope,
              backdrop: 'static',
              resolve: {
                company: function () {
                  return scope.company;
                },
                team: function () {
                  return { _cid: scope.companyId };
                },
              },
            })
            .result.then(function (team) {
              $state.go('admin.team', { teamId: team._id });
            })
            .catch(angular.noop);
        }

        function bulkReInviteCompany() {
          return AdminBulkReInviteUsers.bulkReInviteCompany(scope.companyId);
        }

        scope.go = function (state, vars) {
          $state.go(state, vars);
        };
      },
    };
  },
]);
