import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { cookiePolicyHref, cookiePolicyText, cookiePreferencesText, openCookiePreferences, } from '../../portal-frontend/components/PlatformFooter';
import { Box, Typography, useTheme } from '@mui/material';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../platformContext';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
export const WandaFooter = () => {
    const theme = useTheme();
    const style = useMemo(() => createStyle(theme), [theme]);
    const hasCookiePolicy = usePlatformContext().hasCookiePolicy();
    const [t] = useTranslation();
    return (_jsxs(Box, { sx: style, component: "footer", children: [_jsxs(Typography, { variant: "caption", component: "span", children: ["\u00A9 ", new Date().getFullYear(), " Secure Code Warrior"] }), hasCookiePolicy && (_jsxs(_Fragment, { children: [_jsx("div", { className: "separator" }), _jsx(Link, { color: "soft", variant: "captionLink", href: cookiePolicyHref, target: "_blank", rel: "noopener", children: cookiePolicyText(t) }), _jsx("div", { className: "separator" }), _jsx(Link, { color: "soft", variant: "captionLink", onClick: openCookiePreferences, sx: { position: 'unset' }, children: cookiePreferencesText(t) })] }))] }));
};
const createStyle = (theme) => ({
    gap: 2.5,
    margin: '0', // required to override default platform style on "footer"
    height: theme.typography.pxToRem(80),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.container.fill.body,
    '>span': {
        color: theme.palette.text.body,
    },
    '>div.separator': {
        borderLeft: `1px solid ${theme.palette.container.border.default}`,
        height: theme.typography.pxToRem(14),
    },
    '>button>span, >a>span': {
        color: theme.palette.text.soft,
    },
});
