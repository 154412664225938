var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
export function CustomTabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (_jsx(Box, Object.assign({ role: "tabpanel", hidden: value !== index, id: `filter-tabpanel-${index}`, "aria-labelledby": `filter-tab-${index}` }, other, { style: {
            alignSelf: 'flex-start',
            width: '100%',
        }, children: children })));
}
function a11yProps(index) {
    return {
        id: `filter-tab-${index}`,
        'aria-controls': `filter-tabpanel-${index}`,
    };
}
const CustomTabs = styled(Tabs)(({ theme }) => ({
    background: theme.palette.container.fill.nav,
    '& .MuiTabs-indicator': {
        height: '3px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: 'calc(100% - 37px)',
        backgroundColor: theme.palette.clickable.nav.item.active,
        borderTopLeftRadius: 2.5 * theme.shape.borderRadius,
        borderTopRightRadius: 2.5 * theme.shape.borderRadius,
        transform: 'translate(-0.4px, 0)',
    },
}));
const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    minWidth: 'unset',
    color: theme.palette.clickable.default,
    '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
        outline: 0,
    },
    '&.Mui-selected': {
        color: theme.palette.clickable.nav.item.active,
        fontWeight: theme.typography.fontWeightMedium,
        outline: 0,
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.action.selected,
        outline: 0,
    },
}));
export default function Filters({ filterItems, ariaLabel, skeletonMode, onSelectFilter, }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (_event, newValue) => {
        setValue(newValue);
        onSelectFilter(newValue);
    };
    return (_jsx(CustomTabs, { value: value, onChange: handleChange, "aria-label": ariaLabel, TabIndicatorProps: {
            children: _jsx("span", { className: "MuiTabs-indicatorSpan" }),
        }, centered: true, children: filterItems.map((item, index) => (_jsx(CustomTab, Object.assign({ sx: skeletonMode ? { paddingLeft: '26px', paddingRight: '26px' } : {}, label: item.label }, a11yProps(index)), item.key))) }));
}
