/**
 * Usage:
 *
 *				<tagging-ui-select-component
 *					heading="Tags"
 *					title="Choose tags to associate with your course"
 *					model="smartInvite.tags"
 *					is-disabled="smartInviteAllOrganization"
 *					data="smartInvite.data.tags"
 *					on-removed="smartInviteRemovedTag(item, model)"
 *					on-selected="smartInviteSelectedTag(item, model)"
 *					on-tag-transform="smartInviteTagTransform(newTag)"
 *					placeholder="Search and select tags you wish to add to this course ...">
 *				</tagging-ui-select-component>
 */
import angular from 'angular';
import templateUrl from './tagging-ui-select.component.html';

const app = angular.module('SCWTaggingUiSelectModule', []);
export default app.name;

app.component('taggingUiSelectComponent', {
  templateUrl,
  bindings: {
    heading: '@',
    title: '@',
    placeholder: '@',

    model: '<',
    onSelected: '&',
    onRemoved: '&',

    onTagTransform: '&',

    data: '=',

    isDisabled: '<',
  },
  controllerAs: 'vm',
  controller: function () {
    var vm = this;

    vm.$onChanges = function (changes) {
      if (changes) {
        vm.isDisabled = changes.isDisabled.currentValue;
      }
    };

    vm.selected = function (item) {
      vm.onSelected({
        item: item,
        model: vm.model,
      });
    };

    vm.removed = function (item) {
      vm.onRemoved({
        item: item,
        model: vm.model,
      });
    };

    vm.tagTransform = function (newTag) {
      return vm.onTagTransform({ newTag: newTag });
    };
  },
});
