import { switchClasses as s, styled } from '@mui/material';
import { Switch } from '@securecodewarrior/design-system-react/lib/wanda';
export const WandaSwitch = styled(Switch)(({ theme }) => ({
    [`&:hover .MuiSwitch-colorSuccess, & .MuiSwitch-colorSuccess`]: {
        [`&+.${s.track}`]: {
            border: `1px solid ${theme.palette.badge.success.border}`,
            color: `${theme.palette.badge.success.border}`,
        },
        [`& .${s.thumb}`]: {
            backgroundColor: `${theme.palette.badge.success.border}`,
        },
        [`&.${s.checked}`]: {
            [`&+.${s.track}`]: {
                backgroundColor: `${theme.palette.badge.success.border}`,
                border: `1px solid ${theme.palette.badge.success.border}`,
            },
            [`& .${s.thumb}`]: {
                backgroundColor: `${theme.palette.badge.success.border}`,
            },
        },
    },
    [`&:hover .MuiSwitch-colorError, & .MuiSwitch-colorError`]: {
        [`&+.${s.track}`]: {
            border: `1px solid ${theme.palette.badge.error.border}`,
            color: `${theme.palette.badge.error.border}`,
        },
        [`& .${s.thumb}`]: {
            backgroundColor: `${theme.palette.badge.error.border}`,
        },
        [`&.${s.checked}`]: {
            [`&+.${s.track}`]: {
                backgroundColor: `${theme.palette.badge.error.border}`,
                border: `1px solid ${theme.palette.badge.error.border}`,
            },
            [`& .${s.thumb}`]: {
                backgroundColor: `${theme.palette.badge.error.border}`,
            },
        },
    },
}));
