'use strict';

export function validateLanguageToBeAdded(languageList, language) {
  if (!languageList || !language) {
    return false;
  }

  return !languageList.some((lang) => lang._id === language._id && lang._framework === language._framework);
}

/**
 * Function to derive a company for the user. Normally companies have been derived from
 * tournaments, but due to public tournaments, the company would be derived from
 * user session instead, in the event of the tournament being public
 */
export function determineCompanyFromUserOrTournament(tournament, user) {
  if (tournament?._company) {
    return tournament._company;
  }
  return user?.properties?._cid ? user.properties._cid : user?.properties?.company ? user.properties.company._id : null;
}

/**
 * Function to filter the languages a user can join the tournament in. The languages here represent
 * the tournament languages while language constraints are normally the languages the user is licensed
 * for. For public tournaments, there are no constraints and hence it is passed as an empty array
 */
export function generateLanguagesToBePresentedToUser(languages, languageConstraints, allowDeprecated = false) {
  return languages.filter((language) => {
    return languageConstraints.length > 0
      ? languageConstraints.some(
          (lang) =>
            lang.language._id === language._id &&
            lang.language._framework === language._framework &&
            (lang.status === 'available' || lang.status === 'active' || (allowDeprecated && lang.deprecated))
        )
      : true;
  });
}
