import angular from 'angular';
import 'angular-ui-bootstrap';
import { kebabCase } from 'lodash';
import templateUrl from './quest-progress.html';

const app = angular.module('SCWQuestProgress', ['ui.bootstrap']);
export default app.name;

app.directive('questProgressBreakdown', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        additionalClasses: '=',
        playerState: '=', // assumes full player quest state with corresponding story object as a property
        selectedLanguageFramework: '=',
      },
      link: function (scope, _element, _attrs) {
        scope.$watch('playerState', function (_newVal) {
          for (var i = 0; i < scope.playerState.length; i++) {
            $rootScope.utility.stitchQuestProgressStory(scope.playerState[i]);
          }
        });

        scope.generateProgressbarId = (...props) => {
          return `a11y-progressbar-${props.map(kebabCase).join('-')}`;
        };
      },
    };
  },
]);
