import angular from 'angular';
import MODULE from './module';
import templateUrl from './tournaments.stats-mini.html';

angular.module(MODULE).directive('tournamentsStatsMini', [
  function () {
    return {
      restrict: 'E',
      scope: {
        tournament: '=',
        stats: '=',
        showActive: '=?',
        timeRemainingString: '=?',
        timeRemainingPercent: '=?',
      },
      templateUrl,
      link: function () {},
    };
  },
]);
