import MODULE from './module';
export default MODULE;

import './admin.allowances.management';
import './admin.allowances.widget';
import './admin.commons.download-available-content-csv.service';
import './admin.commons.export-audit-log-csv.service';
import './admin.commons.export-users-csv.service';
import './admin.companies.bulk-manage';
import './admin.companies.custom-realms';
import './admin.companies.edit';
import './admin.companies.preferences';
import './admin.companies.search';
import './admin.companies.security';
import './admin.companies.view.audit-log';
import './admin.companies.view';
import './admin';
import './admin.language-stats';
import './admin.license-utils.service';
import './admin.main';
import './admin.platform-languages.service';
import './admin.sensei.edit-codebase';
import './admin.sensei.edit-repo';
import './admin.teams.edit';
import './admin.teams.search';
import './admin.teams.view';
import './admin.users.bulk-actions';
import './admin.users.bulk-invite';
import './admin.users.bulk-reinvite';
import './admin.users.invite-edit';
import './admin.users.invite-edit.service';
import './admin.users.search';
import './integrations/admin.companies.integrations.view';
import './integrations/admin.companies.integrations.register';
import './admin.companies.vulnerability-summary-report';
