import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('$flattenObject', [
  function () {
    return function flat(obj, path, res) {
      obj = angular.copy(obj);
      path = path || '';
      res = res || {};

      if (typeof obj !== 'object') return obj;
      var attr, val, thisPath;
      for (attr in obj) {
        val = obj[attr];
        thisPath = path ? path + '.' + attr : attr;
        if (val === null || typeof val !== 'object') res[thisPath] = val;
        else flat(val, thisPath, res);
      }
      return res;
    };
  },
]);
