import angular from 'angular';
import introJs from 'intro.js';
import MODULE from './module';

/*
 * NOTE: English texts in _intro_ attributes are for reference only.
 *       The module is actually translating using translation items under
 *       WALKTHROUGHS[module]
 */

const app = angular.module(MODULE);

app.factory('SenseiToastyInterceptor', [function () {}]);

var BaseConfiguration = {
  showStepNumbers: false,
  exitOnEsc: false,
  overlayClicked: '',
  hidePrev: true,
  nextLabel: 'Continue',
  doneLabel: 'Done',
  overlayOpacity: 0.9,
  showBullets: false,
  keyboardNavigation: false,
};

app.provider('Walkthrough', [
  function () {
    this.$get = [
      '$injector',
      function ($injector) {
        return $injector.get('WalkthroughService');
      },
    ];
  },
]);

app.factory('WalkthroughService', [
  '$log',
  '$timeout',
  '$q',
  '$state',
  '$stateParams',
  '$rootScope',
  'Session',
  '$translate',
  'Game013StateService',
  function ($log, $timeout, $q, $state, $stateParams, $rootScope, Session, $translate, Game013StateService) {
    /* ===================================================================
          WALKTHROUGH SERVICE LOGIC
           ================================================================ */

    return {
      checkInductionState: function (language) {
        var levelMapping = {
          PickHunks: 'l2',
          PickSolution: 'l3',
          PickVuln: 'l1',
        };
        var tutorials = _.get(Session.user, 'properties.tutorial.items');
        var defer = $q.defer();
        var pending = _.map(
          _.filter(_.keys(levelMapping), function (item) {
            return !tutorials['done' + item];
          }),
          function (item) {
            return levelMapping[item];
          }
        );

        if (pending.length > 0) {
          var translateKeys = _.map(['title', 'text'], function (item) {
            return 'WALKTHROUGHS.preState.' + item;
          });
          translateKeys.push('OK');
          $translate(translateKeys).then(function (translated) {
            swal(
              {
                title: translated['WALKTHROUGHS.preState.title'],
                text: translated['WALKTHROUGHS.preState.text'],
                type: 'info',
                html: true,
                showCancelButton: true,
                confirmButtonText: translated['OK'],
              },
              function (isConfirm) {
                if (isConfirm) {
                  // defer.reject();

                  $timeout(function () {
                    var currentState = $state.current.name;
                    var currentParams = JSON.parse(JSON.stringify($stateParams));

                    if (!language) {
                      var active = Game013StateService.active;
                      language = { _id: active._language, _framework: active._framework };
                    }

                    if (!language._id) {
                      return $state.go('game.013.languages');
                    }

                    var quest_data = {
                      _language: language._id,
                      _framework: language._framework,
                      _realm: 'ui_walkthrough',
                      _level: 'ui_induction',
                      _quest: pending.shift(),
                    };

                    /*
                     * The function will keep moving forward the _pending_ levels list,
                     * sending itself as the callback for quest completion.
                     * If there are more _pending_ levels, it will move through them.
                     * Once the list is empty, it will redirect the user to the origin state.
                     */
                    function onQuestCompletion() {
                      var next = pending.shift();
                      if (next) {
                        quest_data._quest = next;
                        return $state.go(
                          'game.013.play.quest',
                          _.merge({}, quest_data, {
                            onQuestCompletion: onQuestCompletion,
                          })
                        );
                      }

                      var translateKeys = _.map(['title', 'text'], function (x) {
                        return 'WALKTHROUGHS.postInduction.' + x;
                      });
                      translateKeys.push('OK');
                      $translate(translateKeys).then(function (translations) {
                        swal(
                          {
                            title: translations['WALKTHROUGHS.postInduction.title'],
                            text: translations['WALKTHROUGHS.postInduction.text'],
                            type: 'success',
                            confirmButtonText: translations.OK,
                            html: true,
                          },
                          function (_isConfirm) {
                            $state.go(currentState, currentParams);
                          }
                        );
                      });
                    }

                    // go hit the first one.
                    return $state.go(
                      'game.013.play.quest',
                      _.merge({}, quest_data, {
                        onQuestCompletion: onQuestCompletion,
                      })
                    );
                  }, 200);
                } else {
                  defer.resolve();
                }
              }
            );
          });
        } else {
          defer.resolve();
        }

        return defer.promise;
      },

      /**
       * Retrieve an already-configured set of IntroJS steps.
       * @param  String path of the walkthrough to be configured
       * @return {Object} IntroJS configuration
       */
      configure: function (path, options) {
        options = _.merge({}, BaseConfiguration, options);
        path = path.split('.');
        var idx, step, obj;
        obj = configurations;
        for (idx in path) {
          step = path[idx];
          obj = obj[step];
        }

        obj = JSON.parse(JSON.stringify(obj));

        var option;
        for (option in options) {
          if (obj[option] == undefined) {
            obj[option] = options[option];
          }
        }

        var translationPath = 'WALKTHROUGHS.' + path.join('.');
        var translateKeys = [];

        if (obj.nextLabel) {
          translateKeys.push('nextLabel');
        }

        if (obj.doneLabel) {
          translateKeys.push('doneLabel');
        }

        obj.START_NOW = 'Start Now';
        translateKeys.push('START_NOW');

        obj.SKIP = 'Skip';
        translateKeys.push('SKIP');

        if (obj.welcomeTitle) {
          translateKeys.push(translationPath + '.welcomeTitle');
        }

        if (obj.welcomeText) {
          translateKeys.push(translationPath + '.welcomeText');
        }

        for (idx in obj.steps) {
          step = obj.steps[idx];
          if (step && !step.$translated) {
            translateKeys.push(translationPath + '.steps.' + idx);
          }
        }

        return $translate(translateKeys).then(function (translated) {
          var key, idx;
          for (key in translated) {
            idx = key.substring(key.lastIndexOf('.') + 1);
            if (translated[key] === key)
              //:
              break;

            if (isNaN(idx))
              //:
              obj[idx] = translated[key];
            //:
            else obj.steps[idx].intro = translated[key];
          }
          return obj;
        });
      },

      triggerDebouncedEventListener: _.debounce(
        function (_evt, step, config) {
          if (!step.successTitle) {
            $timeout(function () {
              var _nextStep = $rootScope.introObj._currentStep + 1;
              if (_nextStep < config.steps.length && $rootScope.introObj._currentStep > 0) $rootScope.introObj.start();
              else if (_nextStep === config.steps.length) {
                $rootScope.introObj.exit();
              }
            }, step.nextWait || 1);
          } else {
            $translate(['OK']).then(function (translations) {
              swal(
                {
                  title: step.successTitle,
                  text: step.successText,
                  type: step.successIcon || 'success',
                  html: true,
                  confirmButtonText: translations.OK,
                },
                function (_isConfirm) {
                  return $timeout(function () {
                    $rootScope.introObj.start();
                  }, step.nextWait || 0);
                }
              );
            });
          }
        },
        100,
        { leading: true, trailing: false }
      ),

      /**
       * Configure and trigger a given walkthrough.
       * @param  {String} path
       * @param  {Object} options
       */
      trigger: function (path, options) {
        var self = this;

        self.configure(path, options).then(function (config) {
          $rootScope.currentStep = 0;
          $log.debug('Found configuration for walkthrough: ' + path, config);

          $rootScope.introObj = new introJs();
          var steps = config.steps;
          $rootScope.introObj.setOptions(config);

          $rootScope.introObj.onafterchange(function (_target) {
            // solve the dynamic DOM problem.
          });

          var nextWhen;
          var nextWait;

          $rootScope.introObj.onchange(function (targetElmt) {
            $log.debug('OnChange: ', targetElmt);
            var step = steps[$rootScope.currentStep];

            if (!step) return;

            nextWait = step.nextWait;
            nextWhen = step.nextWhen;

            if (nextWhen) {
              $log.debug('Configuring nextWhen event listener', nextWhen);
              addEventListener(nextWhen, function walkthroughFunction(evt) {
                removeEventListener(evt.type, walkthroughFunction);
                self.triggerDebouncedEventListener(evt, step, config);
              });
            } else if (nextWait) {
              // $rootScope.introObj.exit();
              $timeout(function () {
                $rootScope.introObj.start();
              }, nextWait);
            }

            $rootScope.currentStep++;

            // add outside-event triggers w/addEventListener
          });

          $rootScope.introObj.onbeforechange(function (targetElement) {
            if ($(targetElement).is(':hidden')) {
              setTimeout(function () {
                $rootScope.introObj.nextStep();
              }, 0);
            }

            var isEdge = /Edge/.test(window.navigator.userAgent);
            // This is to remove duplicate overlays being added to the DOM
            if ($('.introjs-overlay').length > 1) {
              var _overlay = $('.introjs-overlay')[0];
              _overlay.parentNode.removeChild(_overlay);
              //_overlay.remove();
            }

            if (isEdge && $(targetElement).attr('id') === 'formgroup-constraints') {
              var edgeOverlay = $('.introjs-overlay')[0];
              edgeOverlay.parentNode.removeChild(edgeOverlay);
            }

            $rootScope.$broadcast('walkthrough:inprogress');
          });

          $rootScope.introObj.onexit(function () {
            $rootScope.$broadcast('walkthrough:end');
          });

          // $rootScope.introObjJs(config).start();
          if (config.skipWelcome) {
            $timeout(function () {
              $rootScope.introObj.start();
            }, 300);
          } else {
            swal(
              {
                title: config.welcomeTitle,
                text: config.welcomeText,
                type: config.welcomeIcon || 'info',
                html: true,
                confirmButtonText: config.welcomeButton || config.START_NOW,
                cancelButtonText: config.SKIP,
                showCancelButton: config.showCancelButton || false,
              },
              function (isConfirm) {
                if (isConfirm) {
                  $timeout(function () {
                    $rootScope.introObj.start();
                  }, 300);
                }
              }
            );
          }
        });
      },
    };
  },
]);

/* ===================================================================
    WALKTHROUGHS' CONFIGURATIONS FOUNDATIONS
     ================================================================ */

var CreateAssessment = {
  welcomeTitle: "Let's create an assesment!",
  welcomeText: "Let's walk together through the assessment creation process.",
  welcomeIcon: 'info',
  showCancelButton: true,
  steps: [
    {
      element: '#formgroup-name',
      intro:
        "<b>Give this assessment a name.</b> Make sure you make it intuitive enough so that people know what it's about. Click next when you are done.",
      position: 'top',
      scrollTo: 'tooltip',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-description',
      intro:
        '<b>It also requires a description.</b> This is the space to be as specific as you want to provide developers with an overall introduction to what this assessment is about.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-language',
      intro: 'First, <b>choose a language/framework</b> for this assessment. Choose one now.',
      position: 'top',
      tooltipClass: 'intro-walkthrough force-interaction',
      nextWhen: 'assessment-create:add-language',
    },
    {
      element: '#formgroup-advanced-options',
      intro:
        'Need to configure <b>Scheduling, Invitations or Pass/Fail options</b> for this assessment? Click this to open up advanced options.',
      position: 'top',
      tooltipClass: 'intro-walkthrough force-interaction',
      nextWhen: 'assessment-create:open-accordion',
    },
    {
      element: '#formgroup-constraints',
      intro:
        '<b>Is this an EXAM? What is the minimum pass score? Is there a time limit?</b> Configure the constraints of this assessment, or leave these fields empty if no constraints apply.',
      position: 'top',
      scrollTo: 'tooltip',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-times',
      intro:
        '<b>During what times do you want this assessment run?</b> You can also configure a specific date to send out the invites (or leave empty for forever available and immediate invite dispatching).',
      position: 'top',
      scrollTo: 'tooltip',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#button-add-challenge',
      intro: 'Click on add a challenge to start configuring the assessment structure.',
      position: 'top',
      tooltipClass: 'intro-walkthrough force-interaction',
      nextWhen: 'assessment-create:add-challenge',
    },
    {
      element: '#formgroup-category-subcategory-wrapper',
      intro:
        '<b>Choose a main vulnerability category</b>. You can optionally narrow the challenge down to a specific subcategory.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
    {
      element: '#formgroup-difficulty',
      intro: '<b>Pick a difficulty level</b> for each challenge.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
    {
      element: '#button-copy-challenge',
      intro: '<b>You can make a copy of this challenge</b> configuration to make the setup a bit faster.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
    {
      element: '#button-remove-challenge',
      intro: '<b>You can remove this challenge</b> configuration by clicking on this button.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
    {
      element: '#button-save-assessment',
      intro: '<b>Almost done!</b> Finish configuring your assessment, and click save when you are done.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
  ],
};

var CreateTournament = {
  welcomeTitle: "Let's create a tournament!",
  welcomeText: "Let's walk together through the tournament creation process.",
  welcomeIcon: 'info',
  showCancelButton: true,
  steps: [
    {
      element: '.picPicker',
      intro:
        'Here you can set a picture to represent your tournament. Drag&amp;drop or pick a file now -or not!- and click on <b>continue</b>.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-name',
      intro: 'Set the name of your tournament. Be intuitive about it.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-joincode',
      intro: 'If you want to restrict access through a <i>Join Code</i> you can set or generate one here.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-description',
      intro: 'Let your developers know what this tournament is about.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-times',
      intro: 'Between which dates will this tournament run?',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#formgroup-languages',
      intro: 'Which languages will be allowed in this tournament? (developers can make their pick)',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
    },
    {
      element: '#accordion-advanced',
      intro:
        'If you wish to configure some <b>advanced</b> settings like whether or not to allow hints, to hide the leaderboard or set some custom start message (terms&amp;rules), this is the place to do it.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
    {
      element: '#button-preview',
      intro:
        'And when you are done, check the challenges preview and adjust the amount of challenges for this tournament.',
      position: 'top',
      tooltipClass: 'intro-walkthrough',
      disableInteraction: true,
    },
  ],
};

/* ===================================================================
        WALKTHROUGHS' CONFIGURATIONS STRUCTURE
       ================================================================ */

var configurations = {
  tournaments: {
    create: CreateTournament,
  },
  assessments: {
    create: CreateAssessment,
  },
  training: {
    worldmap: {
      welcomeTitle: 'Welcome to your command center.',
      welcomeText:
        'Start hardening your systems by taking on defense missions.<br/>' +
        'From here you can visualize ongoing threats and work the code<br/>before a given attack vector is exploited.',
      steps: [
        {
          element: '.worldmap-column .jvectormap-container',
          intro: 'This map shows current threat activity against systems you need to protect.',
          tooltipClass: 'intro-walkthrough',
          position: 'right',
        },
        {
          element: '.worldmap-overlay',
          intro: 'This panel lists currently active missions and provides another method to view and accept missions.',
          tooltipClass: 'intro-walkthrough',
          position: 'right',
          disableInteraction: true,
        },
        {
          element: '.worldmap-overlay button:first-of-type',
          intro: "Now let's go and take on your first defense mission.",
          tooltipClass: 'intro-walkthrough force-interaction',
          position: 'right',
          nextWhen: 'mission-panel-click',
        },
      ],
    },
  },
};
