import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import { TextInput, Switch } from '@securecodewarrior/design-system-react/lib/wanda';
import Grid from '@mui/material/Unstable_Grid2';
import { GoalEditorBadgeSelector } from './GoalEditorBadgeSelector';
import { GoalEditorSection } from './GoalEditorSection';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../common/Tooltip';
import { useMemo, useRef, useState } from 'react';
import { getEditingDisabledTooltip } from '../../../utils';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { usePlatformContext } from '../../../../platformContext';
import { goalDetailsAnalytics } from '../../../analytics/GoalDetails.analytics';
const shouldShowTooShortMessage = (alreadyTouched) => {
    var _a, _b, _c;
    const nameIsActive = ((_b = (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'input' &&
        ((_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.name) === 'name';
    return !nameIsActive && alreadyTouched;
};
export const GoalEditorGeneralAndBadgeSection = ({ name, isMandatory, badge, status, validationErrors, skeletonMode, editingDisabled, onNameEdit, onIsMandatoryToggle, onBadgeEdit, onBadgeError,
// eslint-disable-next-line sonarjs/cognitive-complexity
 }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const hasTouchedNameInput = useRef(false);
    const [showTooShortMessage, setShowTooShortMessage] = useState(false);
    const readOnly = !!skeletonMode || editingDisabled;
    const readOnlyTooltip = getEditingDisabledTooltip(editingDisabled, status, t, i18n.format);
    const nameValidationError = validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.find((e) => e.field === 'name');
    const errorMessage = name.length >= 3 || showTooShortMessage ? t((nameValidationError === null || nameValidationError === void 0 ? void 0 : nameValidationError.message) || '') : '';
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => goalDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const handleMandatoryToggle = () => {
        analytics.onMandatoryToggled(status, !isMandatory);
        onIsMandatoryToggle === null || onIsMandatoryToggle === void 0 ? void 0 : onIsMandatoryToggle();
    };
    const handleBadgeEdit = (newBadge) => {
        analytics.onBadgeUpdated(status, newBadge);
        onBadgeEdit === null || onBadgeEdit === void 0 ? void 0 : onBadgeEdit(newBadge);
    };
    return (_jsxs(GoalEditorSection, { children: [_jsxs(Grid, { xs: 12, lg: 6, sx: {
                    marginBottom: {
                        md: theme.spacing(10),
                        lg: theme.spacing(0),
                    },
                }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: {
                            marginBottom: theme.spacing(10),
                            lineHeight: theme.typography.pxToRem(18),
                        }, children: t('goalsAdmin.components.organisms.goalEditor.sections.general.heading') }), _jsxs(Stack, { direction: "column", children: [_jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { hidden: skeletonMode === 'edit' && !name, sx: {
                                        height: 'fit-content',
                                        marginRight: theme.spacing(6),
                                        marginBottom: errorMessage ? theme.spacing(1) : theme.spacing(4),
                                    }, children: _jsx(TextInput, { label: t('goalsAdmin.components.organisms.goalEditor.sections.general.goalNameInputLabel'), type: "text", name: "name", value: name, error: !!errorMessage, helperText: errorMessage || '', onKeyDown: (event) => { var _a; return (event === null || event === void 0 ? void 0 : event.key) === 'Enter' && ((_a = event === null || event === void 0 ? void 0 : event.preventDefault) === null || _a === void 0 ? void 0 : _a.call(event)); }, onBlur: () => {
                                            setShowTooShortMessage(shouldShowTooShortMessage(hasTouchedNameInput.current));
                                        }, onFocus: () => {
                                            hasTouchedNameInput.current = true;
                                            setShowTooShortMessage(shouldShowTooShortMessage(hasTouchedNameInput.current));
                                        }, onChange: (event) => {
                                            onNameEdit === null || onNameEdit === void 0 ? void 0 : onNameEdit(event.target.value);
                                            hasTouchedNameInput.current = true;
                                            setShowTooShortMessage(shouldShowTooShortMessage(hasTouchedNameInput.current));
                                            analytics.onNameUpdated(status, event.target.value);
                                        }, sx: {
                                            width: '100%',
                                            maxWidth: theme.typography.pxToRem(536),
                                            '& .MuiInputBase-input': {
                                                color: theme.palette.text.body,
                                            },
                                            '& .MuiInputBase-input[type="text"]:not(.SearchInput-input):disabled': {
                                                // global style in portal-frontend that's causing issues
                                                backgroundColor: 'transparent',
                                            },
                                        }, disabled: readOnly }) }) }), skeletonMode === 'edit' && !name && (_jsx(Box, { sx: { marginRight: theme.spacing(6) }, children: _jsx(Skeleton, { height: "37px", variant: "rounded", sx: {
                                        width: '100%',
                                        maxWidth: theme.typography.pxToRem(536),
                                        marginBottom: theme.spacing(4),
                                    } }) })), _jsxs(Stack, { direction: "column", children: [skeletonMode !== 'edit' && (_jsx(Tooltip, { title: readOnlyTooltip, arrow: false, children: _jsx(FormControlLabel, { onKeyDown: (event) => { var _a; return (event === null || event === void 0 ? void 0 : event.key) === 'Enter' && ((_a = event === null || event === void 0 ? void 0 : event.preventDefault) === null || _a === void 0 ? void 0 : _a.call(event)); }, sx: {
                                                marginBottom: 0,
                                                color: theme.palette.text.title,
                                                marginLeft: theme.spacing(0),
                                                '& .MuiFormControlLabel-label': {
                                                    marginLeft: theme.spacing(4),
                                                    color: `${theme.palette.text.body} !important`,
                                                },
                                            }, disabled: readOnly, control: _jsx(Switch, { checked: isMandatory, disabled: readOnly, onChange: handleMandatoryToggle }), label: t('goalsAdmin.components.organisms.goalEditor.sections.general.mandatorySettingTitle') }) })), skeletonMode === 'edit' && (_jsxs(Stack, { direction: "row", children: [_jsx(Skeleton, { width: "40px", height: "20px", variant: "rounded" }), _jsx("p", { style: { marginLeft: theme.spacing(4), marginBottom: 0 }, children: t('goalsAdmin.components.organisms.goalEditor.sections.general.mandatorySettingTitle') })] })), _jsx(Typography, { component: "p", variant: "caption", sx: {
                                            paddingLeft: theme.spacing(14),
                                            marginTop: theme.typography.pxToRem(10),
                                            letterSpacing: theme.typography.pxToRem(0.5),
                                            fontSize: theme.typography.pxToRem(12),
                                            lineHeight: theme.typography.pxToRem(16),
                                            maxWidth: '410px',
                                        }, children: t('goalsAdmin.components.organisms.goalEditor.sections.general.mandatorySettingCaption') })] })] })] }), _jsxs(Grid, { xs: 12, lg: 6, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: {
                            marginBottom: theme.spacing(10),
                            lineHeight: theme.typography.pxToRem(18),
                        }, children: t('goalsAdmin.components.organisms.goalEditor.sections.badge.heading') }), skeletonMode === 'edit' ? (_jsx(Skeleton, { width: "80px", height: "80px", variant: "rounded" })) : (_jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: theme.typography.pxToRem(16),
                        }, children: [_jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: {
                                        width: 'fit-content',
                                    }, children: _jsx(GoalEditorBadgeSelector, { disabled: readOnly, badge: badge, onBadgeEdit: handleBadgeEdit, onBadgeError: onBadgeError }) }) }), !badge && !readOnly && (_jsx(Typography, { component: "p", variant: "subtitle2", sx: (theme) => ({
                                    maxWidth: '256px',
                                    fontWeight: 400,
                                    fontSize: theme.typography.pxToRem(12),
                                    lineHeight: theme.typography.pxToRem(16),
                                    letterSpacing: theme.typography.pxToRem(0.5),
                                }), children: _jsx(Trans, { i18nKey: "goalsAdmin.components.organisms.goalEditor.sections.badge.badgeSpecs", components: { 1: _jsx("br", {}) } }) }))] }))] })] }));
};
