import angular from 'angular';
import MODULE from './module';

import base64url from 'base64url';

const app = angular.module(MODULE);

app.controller('SSOAcceptController', [
  '$scope',
  '$rootScope',
  'AuthService',
  '$state',
  '$stateParams',
  'AnalyticsService',
  'AnalyticsEvents',
  function ($scope, $rootScope, AuthService, $state, $stateParams, AnalyticsService, AnalyticsEvents) {
    $scope.rejected = false;

    let params = $stateParams;
    if (params.acceptToken) {
      params = JSON.parse(base64url.decode(params.acceptToken));
    }

    $scope.init = function () {
      AuthService.setEndpoint(params.endpoint);

      $rootScope.changeLanguage(params.i18n);
    };

    $scope.acceptClick = async function () {
      $rootScope.disableButton('inviteAccept');
      await AuthService.addLoading();

      const acceptance = {
        encodedToken: params.encodedToken,
        relayState: params.RelayState,
        encodedIv: params.encodedIv,
        encodedHmac: params.encodedHmac,
      };

      try {
        const response = await AuthService.ssoAcceptTermsAndConditions(acceptance);

        // This will likely need user data adding when available
        AnalyticsService.logEvent(AnalyticsEvents.Onboarding.SSO_USER_ACCEPT_TERMS, {
          accept_terms_and_conditions: 'yes',
        });

        $state.go('sso-login', {
          token: response.oneTimeLoginToken,
          RelayState: response.relayState,
          cciv: params.cciv,
          ccdata: params.ccdata,
        });
      } catch (e) {
        const { ecid, region } = e.data;
        $scope.failed = true;
        $state.go('error', { err: 'UNAUTHORIZED', ecid, region });
      } finally {
        AuthService.removeLoading();
        $rootScope.enableButton('inviteAccept');
      }
    };

    $scope.rejectClick = async function () {
      $rootScope.disableButton('ssoReject');
      $scope.rejected = true;

      const rejection = { encodedToken: params.encodedToken, encodedIv: params.encodedIv };

      try {
        await AuthService.ssoRejectTermsAndConditions(rejection);
      } catch (e) {
        //  TODO
      } finally {
        await AuthService.removeLoading();
        //  Don't turn this reject button back on as a user can decline unlimited times and spam the audit log
      }

      // This will likely need user data adding when available
      await AnalyticsService.logEvent(AnalyticsEvents.Onboarding.SSO_USER_ACCEPT_TERMS, {
        accept_terms_and_conditions: 'no',
      });
    };
    $scope.init();
  },
]);
