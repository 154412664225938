import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CountdownClock, CountdownItem, Caption } from './Countdown.styled';
import FlipDigit from './FlippingNumber';
import { useTranslation } from 'react-i18next';
const FlipClock = ({ timeLeft }) => {
    const padNumber = (num) => String(num).padStart(2, '0');
    const { t } = useTranslation('react_components', { keyPrefix: 'components.organisms.flippingClock' });
    return (_jsx(CountdownClock, { children: Object.entries(timeLeft).map(([label, value]) => (_jsxs(CountdownItem, { children: [padNumber(value)
                    .split('')
                    .map((digit, index) => (_jsx(FlipDigit, { isFirstChild: index === 0, value: digit }, index))), _jsx(Caption, { children: t(label) })] }, label))) }));
};
export default FlipClock;
