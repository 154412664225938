import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { platformHeaderHeight } from '../commonStyle';
import { Box } from '@mui/material';
import { WandaFooter } from './WandaFooter';
export const WithFooter = (props) => {
    return (_jsxs(Box, { sx: style, children: [_jsx(Box, { display: "flex", flexDirection: "column", flexGrow: "1", component: props.wrapperComponent, children: props.children }), _jsx(WandaFooter, {})] }));
};
const style = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: `calc(100vh - ${platformHeaderHeight}px)`,
};
