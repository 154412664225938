import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).factory('AssessmentsChallengeApi', [
  '$window',
  '$http',
  'HttpConfigService',
  function ($window, $http, HttpConfigService) {
    function passthrough(data) {
      return data;
    }

    function url(ctx, sub, params) {
      var result = [
        $window.SCW_ENV.ApiEndpoint,
        'assessment',
        ctx._assessment,
        'attempt',
        ctx._attempt,
        'challenge',
        sub || '',
      ].join('/');

      var encoded = [];
      if (params) {
        _.chain(params)
          .values()
          .forEach(function (val, key) {
            encoded.push(key + '=' + encodeURIComponent(JSON.stringify(val)));
          });
      }

      if (encoded.length > 0) result += '?' + encoded.join('&');

      return result;
    }

    function getFrom(_from, mapper) {
      return function (ctx, state) {
        return $http
          .get(
            url(ctx, _from, { state: state }),
            _.merge(
              {
                withCredentials: true,
              },
              HttpConfigService.getHttpConfig()
            )
          )
          .then(function (response) {
            return response.data;
          })
          .then(mapper || passthrough);
      };
    }

    return {
      getChallenge: getFrom(''),
    };
  },
]);
