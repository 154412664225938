import styled from '@emotion/styled';
import { lightGrey } from './styles';
const cellStyles = `
  border: ${lightGrey} 1px solid;
  padding: 8px;
`;
export const Th = styled.th `
  ${cellStyles}
`;
export const Td = styled.td `
  ${cellStyles}
`;
export const Table = styled.table `
  width: 100%;
  border-color: ${lightGrey};
  border-collapse: collapse;
`;
export const Thead = styled.thead `
  background-color: ${lightGrey};
`;
