import { Box, styled } from '@mui/material';
const generateIndentStyles = (theme) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const styles = {};
    for (let i = 1; i <= 8; i++) {
        styles[`&-${i}`] = {
            paddingLeft: `calc(${theme.spacing(6)} * ${i})`, // Adjust this if you need different padding logic
        };
    }
    return styles;
};
export const StyledEditor = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'error' && prop !== 'disabled',
})(({ theme }) => ({
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    background: theme.palette.container.fill.card1,
    borderRadius: `${theme.shape.borderRadius * 3}px`,
    '& .ql-toolbar': {
        borderRadius: `${theme.shape.borderRadius * 3}px ${theme.shape.borderRadius * 3}px 0 0`,
        padding: theme.spacing(2, 2, 0, 2),
        '& button': {
            width: 'unset',
            height: 'unset',
            padding: theme.spacing(1, 1),
            marginInline: theme.spacing(1),
            '&:last-of-type': {
                marginInlineEnd: 0,
            },
            '&:first-of-type': {
                marginInlineStart: 0,
            },
            '&.ql-active': {
                '& svg': {
                    color: theme.palette.clickable.button.default,
                    '&:hover': {
                        color: theme.palette.clickable.button.hover,
                    },
                },
            },
            '&:focus': {
                outline: `2px solid ${theme.palette.clickable.button.default}`,
                outlineOffset: theme.spacing(0.5),
                borderRadius: theme.shape.borderRadius * 2,
            },
        },
    },
    '& .ql-formats': {
        '&:not(:last-of-type)': {
            borderRight: `1px solid ${theme.palette.container.border.default}`,
        },
        marginBottom: theme.spacing(2),
        marginRight: '0 !important',
        padding: theme.spacing(0, 2),
        '& button.ql-active': {
            color: theme.palette.container.border.active,
            '& .ql-stroke': {
                stroke: theme.palette.container.border.active,
            },
        },
        '& .ql-stroke': {
            stroke: theme.palette.badge.text,
        },
        '& svg': {
            height: 'unset',
            color: theme.palette.badge.text,
            fontSize: theme.typography.pxToRem(24),
            '&:hover': {
                color: theme.palette.container.border.active,
            },
        },
    },
    '& .ql-container:has(.ql-editor)': {
        borderRadius: `0 0 ${theme.shape.borderRadius * 3}px ${theme.shape.borderRadius * 3}px`,
    },
    '& .ql-editor': {
        color: theme.palette.text.body,
        // 100% of the screen height - extra paddings - height of the toolbar - height of the sections
        maxHeight: 'calc(100vh - 128px - var(--toolbarHeight, 0) - var(--sectionHeights, 0))',
        minHeight: '400px',
        '& > *': {
            marginInline: 'calc(50% - 335px)',
            textWrap: 'pretty',
            wordBreak: 'break-word',
        },
        '& code, & .ql-code-block-container': {
            backgroundColor: theme.palette.container.fill.card2,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            color: theme.palette.text.soft,
        },
        '& .ql-code-block-container': {
            padding: theme.spacing(4),
            marginBlock: theme.spacing(0, 4),
        },
        '& .ql-indent': Object.assign({}, generateIndentStyles(theme)),
        '& *:has(img)': {
            maxHeight: 'inherit',
            display: 'grid',
            placeContent: 'center',
        },
        '& img': {
            maxHeight: 'inherit',
        },
        '& blockquote': {
            position: 'relative',
            padding: theme.spacing(4),
            background: theme.palette.container.fill.card2,
            fontFamily: theme.typography.body0.fontFamily,
            fontSize: theme.typography.body0.fontSize,
            fontWeight: theme.typography.body0.fontWeight,
            lineHeight: theme.typography.body0.lineHeight,
            borderRadius: theme.shape.borderRadius,
            borderLeft: `2px solid ${theme.palette.badge.default.border}`,
            marginBlock: theme.spacing(0, 4),
        },
        '& h1, & h2, & h3, & h4': {
            color: theme.palette.text.title,
            marginBottom: theme.spacing(2),
        },
        '& h1': {
            fontFamily: theme.typography.h1.fontFamily,
            fontSize: theme.typography.h1.fontSize,
            fontWeight: theme.typography.h1.fontWeight,
            lineHeight: theme.typography.h1.lineHeight,
        },
        '& h2': {
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.h2.fontSize,
            fontWeight: theme.typography.h2.fontWeight,
            lineHeight: theme.typography.h2.lineHeight,
        },
        '& h3': {
            fontFamily: theme.typography.h3.fontFamily,
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight,
            lineHeight: theme.typography.h3.lineHeight,
        },
        '& h4': {
            fontFamily: theme.typography.h4.fontFamily,
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
            lineHeight: theme.typography.h4.lineHeight,
        },
        '& p, & li, & blockquote': {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.body1.fontWeight,
            lineHeight: theme.typography.body1.lineHeight,
        },
        '& p': {
            marginBottom: theme.spacing(2.5),
        },
        '& ol, & [data-list=ordered]:has(+ [data-list=bullet]), & [data-list=bullet]:has(+ [data-list=ordered])': {
            marginBottom: theme.spacing(4),
        },
        '& a': {
            color: theme.palette.clickable.default,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.clickable.hover,
            },
        },
    },
}), ({ error, disabled, theme }) => ({
    opacity: disabled ? 0.5 : 1,
    '& .ql-toolbar': {
        border: error
            ? `1px solid ${theme.palette.badge.error.border}`
            : `1px solid ${theme.palette.container.border.input}`,
        borderBottom: error
            ? `1px solid ${theme.palette.badge.error.border}`
            : `1px solid ${theme.palette.container.border.default}`,
    },
    '& .ql-container:has(.ql-editor)': {
        border: error
            ? `1px solid ${theme.palette.badge.error.border}`
            : `1px solid ${theme.palette.container.border.input}`,
    },
}));
