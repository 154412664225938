import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { visuallyHidden } from '@mui/utils';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
import { CybermonDetail } from './components/CybermonDetail';
import { WeeklyCybermon } from './components/WeeklyCybermon';
import { Activities } from './components/Activities';
import { ResultsScreen } from './components/ResultsScreen';
import { CybermonIcon } from './components/CybermonIcon';
import { useCybermon } from './hooks/useCybermon';
import { CybermonAccessibility } from './components/CybermonAccessibility';
import { PointBanner } from './components/PointBanner';
import { ModalProvider } from './context/ModalContext';
import { CybermonAchievementDialog } from './components/CybermonAchievementDialog';
import { BadgeModal } from './components/BadgeModal';
const BodyContent = ({ filterOptions, onActivitySelected, isAuthenticated, onLoginClicked, unfilteredActivityCount, }) => {
    const { t } = useTranslation();
    const { status, activeCybermon } = useCybermon();
    if (status.ended) {
        return _jsx(ResultsScreen, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { component: "h2", sx: visuallyHidden, children: t('pages.explore.contentList') }), _jsx(PointBanner, {}), _jsx(CybermonDetail, {}), activeCybermon && !activeCybermon.earned && (_jsx(Box, { sx: (theme) => ({
                    borderRadius: theme.shape.border.radius.md,
                    border: `1px solid ${theme.palette.container.border.default}`,
                    overflow: 'hidden',
                }), children: _jsx(Stack, { flexGrow: 1, children: _jsx(Activities, { filterOptions: filterOptions, onLoginClicked: onLoginClicked, isAuthenticated: isAuthenticated, onActivitySelected: onActivitySelected, unfilteredActivityCount: unfilteredActivityCount }) }) }))] }));
};
export const CybermonView = ({ filterOptions, onActivitySelected, isAuthenticated, onLoginClicked, unfilteredActivityCount, }) => {
    const { t } = useTranslation();
    const { status, activeCybermon, userCompleted } = useCybermon();
    return (_jsx("main", { children: _jsxs(ModalProvider, { children: [_jsx(CybermonAccessibility, {}), _jsx(Stack, { direction: "row", justifyContent: "space-between", mb: 6, children: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(CybermonIcon, { fontSize: 40, color: "text.soft" }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('pages.cybermon.titleOverline') }), _jsx(Typography, { component: "h2", variant: "h2", children: t('pages.cybermon.title') })] })] }) }), _jsxs(Box, { display: "grid", gap: 7, gridTemplateColumns: "minmax(340px, 3fr) 9fr", children: [_jsxs(Stack, { gap: 7, direction: "column", children: [_jsxs(Card, { gap: 4, display: "flex", flexDirection: "column", sx: { padding: 5 }, children: [_jsx(Typography, { variant: "h5", children: t('pages.cybermon.introduction.title') }), _jsx(Typography, { children: t('pages.cybermon.introduction.paragraph1') }), _jsx(Typography, { children: t('pages.cybermon.introduction.paragraph2') }), _jsx(Typography, { variant: "subtitle1", fontWeight: "bold", color: "text.title", children: t('pages.cybermon.introduction.paragraph3') })] }), !status.ended && !userCompleted && activeCybermon && _jsx(WeeklyCybermon, {})] }), _jsx(Stack, { gap: 7, direction: "column", children: _jsx(BodyContent, { filterOptions: filterOptions, onLoginClicked: onLoginClicked, isAuthenticated: isAuthenticated, onActivitySelected: onActivitySelected, unfilteredActivityCount: unfilteredActivityCount }) })] }), _jsx(CybermonAchievementDialog, {}), _jsx(BadgeModal, {})] }) }));
};
