import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

angular.module(MODULE).controller('ManagementAdminLanguageStatsController', [
  '$log',
  '$scope',
  'AdminApiService',
  'ErrorHandler',
  function ($log, $scope, AdminApiService, ErrorHandler) {
    $scope.accordionCloseOthers = false;
    $scope.langStatus = $scope.metadata.constants.language.status;
    $('.management-css').prop('disabled', false);

    function loadStats() {
      AdminApiService.addLoading();
      AdminApiService.getAdminLanguageStats()
        .then(function (data) {
          $log.debug('Language stats data: ', data);
          data.sort(function (a, b) {
            var aConcatLanguageFramework = a.language._id + ':' + a.language._framework;
            var bConcatLanguageFramework = b.language._id + ':' + b.language._framework;
            if (aConcatLanguageFramework < bConcatLanguageFramework) {
              return -1;
            }
            if (aConcatLanguageFramework > bConcatLanguageFramework) {
              return 1;
            }
            return 0;
          });

          data.forEach(function (stat) {
            stat.displayCreated = moment(stat.created).format('LL');
            stat.displayLastUpdated = moment(stat.lastUpdated).format('LL');
          });

          $scope.languageStats = data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError('Failed to fetch language stats', response);
        })
        .finally(AdminApiService.removeLoading);
    }

    function init() {
      loadStats();
    }

    function destroy() {}

    // lifecycle
    init();
    $scope.$on('$destroy', destroy);
  },
]);
