export const activityStatusAnalytics = (scope, contentType, contentId, pageVariant, sendEvent) => ({
    onReplayButtonClicked: () => {
        sendEvent({ event: 'Replay clicked', scope, contentType, contentId, pageVariant });
    },
    onBackToActivityButtonClicked: () => {
        sendEvent({ event: 'Back to activity clicked', scope, contentType, contentId, pageVariant });
    },
    onRefresherButtonClicked: () => {
        sendEvent({ event: 'Refresher clicked', scope, contentType, contentId, pageVariant });
    },
});
