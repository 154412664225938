import { z } from 'zod';
export const QUESTS_STATE_KEY = 'quests-state';
const questsStateSchema = z.object({
    selectedLanguage: z.string(),
    expandedTopics: z.record(z.array(z.string())),
});
const readQuestsState = () => {
    try {
        const expandedTopics = window.localStorage.getItem(QUESTS_STATE_KEY);
        if (expandedTopics !== null) {
            return questsStateSchema.parse(JSON.parse(expandedTopics));
        }
    }
    catch (e) {
        console.error(e);
    }
    return { selectedLanguage: '', expandedTopics: {} };
};
export const getQuestState = (quests) => {
    const defaultLanguage = Object.keys(quests)[0];
    const stored = readQuestsState();
    if (stored.selectedLanguage === '') {
        stored.selectedLanguage = defaultLanguage;
    }
    if (!(stored.selectedLanguage in quests)) {
        stored.selectedLanguage = defaultLanguage;
    }
    for (const lang in quests) {
        if (quests[lang].chapters.length === 0)
            continue;
        const firstTopic = quests[lang].chapters[0].topics[0];
        if (!(lang in stored.expandedTopics)) {
            stored.expandedTopics[lang] = [firstTopic.id];
        }
    }
    saveQuestState(stored);
    return stored;
};
export const saveQuestState = (state) => {
    const stored = readQuestsState();
    try {
        window.localStorage.setItem(QUESTS_STATE_KEY, JSON.stringify(Object.assign(Object.assign({}, stored), state)));
    }
    catch (e) {
        console.error(e);
    }
};
export const updateTopicState = (topicId) => {
    var _a;
    const stored = readQuestsState();
    if ((_a = stored.expandedTopics[stored.selectedLanguage]) === null || _a === void 0 ? void 0 : _a.includes(topicId)) {
        stored.expandedTopics[stored.selectedLanguage] = stored.expandedTopics[stored.selectedLanguage].filter((x) => x !== topicId);
    }
    else {
        stored.expandedTopics[stored.selectedLanguage].push(topicId);
    }
    saveQuestState(stored);
};
