import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('EditCustomRealmModalController', [
  '$uibModalInstance',
  '$scope',
  'customRealm',
  function ($uibModalInstance, $scope, customRealm) {
    $scope.customRealmToSave = { languageFramework: { language: JSON.parse(JSON.stringify(customRealm.language)) } };
    $scope.copyOfCustomRealm = JSON.parse(JSON.stringify(customRealm));

    if ($scope.copyOfCustomRealm) {
      $scope.customRealmToSave.json = JSON.stringify($scope.copyOfCustomRealm, null, 2);
    } else {
      $scope.customRealmToSave.json = JSON.stringify(
        {
          name: 'Custom Realm Name',
          description: 'Description text that will be shown to users',
          enabled: true,
          language: null,
          levels: [
            {
              _id: 'level_id',
              name: 'Level name',
              description: 'Level description that will be shown to users',
              success: 'Success message that will be shown to users',
              quests: [
                {
                  _id: 'quest_id',
                  name: 'Quest name',
                  description: 'Quest description that will be shown to users',
                  success: 'Success message that will be shown to users',
                  numberOfChallenges: 5,
                  properties: {
                    app: 'Specify an application/repository ID here. E.g. 5636fcf4dcc3e8dd1496ee60',
                    app_name: 'Display name of application',
                    app_type: 'bank || cloud || ecommerce || utility',
                    company_name: 'Display company name that the application belongs to',
                    attacker_type: 'cybercriminal || hacker || hacktivist || statesponsored',
                  },
                },
              ],
            },
          ],
        },
        null,
        2
      );
    }

    $scope.save = function () {
      $scope.forms.editCustomRealmForm.json.$setValidity('json_parse', true);

      if ($scope.forms.editCustomRealmForm.$invalid) {
        $scope.forms.editCustomRealmForm.languageFramework.$dirty = true;
        $scope.forms.editCustomRealmForm.json.$dirty = true;
        return;
      }

      var customRealmObj;
      try {
        customRealmObj = JSON.parse($scope.customRealmToSave.json);
        customRealmObj._owner = $scope.editedCompany._id;
      } catch (e) {
        $scope.forms.editCustomRealmForm.json.$setValidity('json_parse', false);
        $scope.forms.editCustomRealmForm.json.$dirty = true;
        return;
      }

      customRealmObj.language = $scope.customRealmToSave.languageFramework.language;

      $uibModalInstance.close(customRealmObj);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };
  },
]);
