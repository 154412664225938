import angular from 'angular';
import MODULE from './module';
import templateUrl from './admin.users.bulk-actions.html';

angular.module(MODULE).directive('adminBulkUserActions', [
  '$timeout',
  '$http',
  '$log',
  '$translate',
  '$swal',
  'AuthService',
  'AdminApiService',
  'ErrorHandler',
  'HttpConfigService',
  function ($timeout, $http, $log, $translate, $swal, AuthService, AdminApiService, ErrorHandler, HttpConfigService) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        companyId: '=?companyId',
        selection: '=',
        onBulkUpdate: '&?onBulkUpdate',
        clear: '&?clear',
      },
      link: function (scope, _element, _attrs) {
        scope.$action = false;
        scope.payload = {};
        scope.userRoles = scope.$root.userRoles;
        scope.auth = AuthService;

        // Begin: exposed actions
        scope.enable = action('enable', true);
        scope.disable = action('disable', true);
        scope.roleShift = action('roleShift', false);
        scope.reassign = action('reassign', false);
        scope.createTeam = action('createTeam', false);
        scope.reinvite = action('reinvite', inviteReminderAction);
        scope.resetPassword = action('resetPassword', true);
        scope.addTags = action('addTags', false);
        scope.removeTags = action('removeTags', false);
        scope.delete = action('delete', true, true);
        scope.cancel = cancel;
        // End: exposed actions

        // Begin: exposed support methods
        scope.tagsPayload = tagsPayload; // special processing
        // End: exposed support methods

        // Begin: Control changes in selection
        scope.$watch('selection.search', function (n, _o) {
          if (n) {
            scope.selection.ids = [];
          }
        });

        scope.$watchCollection('selection.ids', function (n, _o) {
          if (n && n.length > 0) {
            scope.selection.search = null;
          }
        });
        // End: Control changes in selection

        function wrongCodeWarning(_fn, _payload) {
          return swal({
            type: 'error',
            title: $translate.instant('Management.Users.Bulk.dangerMode.wrongCode.title'),
            text: $translate.instant('Management.Users.Bulk.dangerMode.wrongCode.text'),
            confirmButtonText: $translate.instant('OK'),
          });
        }

        function inviteReminderAction(_callback) {
          var messageBase = 'Management.Users.Bulk.sendInviteReminder.warning';
          return $swal({
            type: 'warning',
            title: $translate.instant(messageBase + '.title'),
            text: $translate.instant(messageBase + '.text'),
            buttons: {
              withPoke: { label: $translate.instant(messageBase + '.buttons.poke'), class: 'warn', value: 'poke' },
              noPoke: { label: $translate.instant(messageBase + '.buttons.invite'), class: 'confirm', value: 'invite' },
              cancel: { label: $translate.instant(messageBase + '.buttons.cancel'), class: 'cancel', value: false },
            },
            confirmButtonText: $translate.instant('OK'),
          }).then(function (flag) {
            if (!flag) return;

            var extra = {
              poke: flag === 'poke',
            };

            execute('reinvite', _.merge(extra, scope.payload));
          });
        }

        function action(action, autoConfirm, dangerMode) {
          return function preExecution(isConfirm, payload) {
            $log.debug('Called ' + action, isConfirm, payload);
            if (typeof autoConfirm === 'function') return autoConfirm();

            var rand = Math.ceil(Math.random() * 1000000);
            var confirmText = _.padStart(rand, 6, rand); // ensure it's long enough
            var hasPayload = _.find(_.values(payload), function (val) {
              return !!val;
            });

            if (payload && !hasPayload) {
              $log.debug('bulk' + action + 'requires a loaded payload');
              return;
            }

            if (autoConfirm || isConfirm) {
              var messageBase = 'Management.Users.Bulk.' + action + '.warning';
              const callback = function (confirmed) {
                if (!confirmed) return cancel(action);

                // dangerMode requires written confirmation.
                if (dangerMode && confirmed !== confirmText) {
                  $timeout(function () {
                    wrongCodeWarning(preExecution, payload);
                  }, 200);
                  return cancel(action);
                }

                execute(action, payload);
              };
              const messageType = getActionType(action);

              if (action === 'reassign') {
                // get team
                AdminApiService.getTeam(payload._tid).then(function (team) {
                  const messageTitle = $translate.instant(
                    team.status === 'disabled' ? messageBase + '.disabled.title' : messageBase + '.title',
                    { team: team.name }
                  );
                  const messageText = $translate.instant(
                    team.status === 'disabled' ? messageBase + '.disabled.text' : messageBase + '.text'
                  );
                  return showConfirmModal(
                    messageType,
                    messageTitle,
                    messageText,
                    dangerMode,
                    confirmText,
                    $translate.instant(team.status === 'disabled' ? 'BTN_MOVE_AND_DISABLE_USERS' : 'OK'),
                    callback
                  );
                });
              } else {
                const messageTitle = $translate.instant(messageBase + '.title');
                const messageText = $translate.instant(messageBase + '.text');
                return showConfirmModal(
                  messageType,
                  messageTitle,
                  messageText,
                  dangerMode,
                  confirmText,
                  $translate.instant('OK'),
                  callback
                );
              }
            }

            scope.$action = action;
          };
        }

        function getActionType(action) {
          const warningActions = ['roleShift', 'reassign', 'disable', 'resetPassword'];
          const successActions = ['createTeam', 'enable'];
          if (warningActions.includes(action)) {
            return 'warning';
          }
          if (successActions.includes(action)) {
            return 'success';
          }
          if (action === 'delete') {
            return 'input';
          }
          return 'info';
        }

        function showConfirmModal(
          messageType,
          messageTitle,
          messageText,
          dangerMode,
          confirmText,
          confirmButtonText,
          cb
        ) {
          return swal(
            {
              type: messageType,
              title: messageTitle,
              text: messageText + ((dangerMode && confirmText) || ''),
              showCancelButton: true,
              cancelButtonText: $translate.instant('CANCEL'),
              confirmButtonText: confirmButtonText,
              dangerMode: !!dangerMode,
            },
            cb
          );
        }

        function execute(action, payload) {
          scope.inprogress = true;

          $log.debug('Executing bulk _' + action + '_ after confirmation');
          payload = _.merge(_.cloneDeep(scope.selection), payload);

          $log.debug('AdminApiService - bulk' + action + '()', payload);
          var endpoint = AdminApiService.endpoint() + '/users/bulk/' + action;
          return $http
            .post(endpoint, payload, HttpConfigService.getHttpConfig())
            .then(function (response) {
              var message = response.data;
              if (message && message.mode === 'async') {
                var messagebase = 'Management.Users.Bulk.goneAsync.';
                return swal(
                  {
                    type: 'info',
                    title: $translate.instant(messagebase + 'title'),
                    text: $translate.instant(messagebase + 'text'),
                    confirmButtonText: $translate.instant('OK'),
                  },
                  function () {
                    if (scope.onBulkUpdate) scope.onBulkUpdate();

                    if (scope.clear) scope.clear();
                  }
                );
              }

              if (scope.onBulkUpdate) scope.onBulkUpdate();

              if (scope.clear) scope.clear();

              return response.data;
            })
            .catch(function (error) {
              if (error.data.error.includes('A team with this name already exists')) {
                ErrorHandler.addError($translate.instant(error.data.error));
              } else {
                ErrorHandler.addError($translate.instant('UNABLE_CHANGE_TEAM_STRICT_SSO'));
              }
            })
            .finally(function () {
              scope.payload = {};
              scope.$action = false;
              scope.inprogress = false;
            });
        }

        function cancel(action) {
          $log.debug('User cancelled the _' + action + '_ action.');
          scope.$action = false;
          scope.payload = {};
        }

        function tagsPayload() {
          return {
            tags: _.map(scope.payload.tags, function (tag) {
              return tag.text;
            }),
          };
        }
      },
    };
  },
]);
