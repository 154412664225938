import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, } from '@mui/material';
import { Tooltip } from '../../../common/Tooltip';
const StyledTableContainer = styled(TableContainer)(() => ({}));
const StyledTable = styled(MuiTable)(({ theme }) => ({
    borderSpacing: theme.spacing(0, 5),
    borderCollapse: 'separate',
    borderWidth: 0,
    backgroundColor: 'transparent',
    p: {
        margin: 0,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td,th': {
        border: '1px solid',
        borderColor: theme.palette.container.border.default,
        borderLeft: 0,
        textAlign: 'center',
        transition: 'border-top-color 9s ease 1s, border-bottom-color 9s ease 1s, border-left-color 9s ease 1s, border-right-color 9s ease 1s, background 9s ease 1s;',
    },
    'td:first-child, th:first-child': {
        borderLeft: '1px solid',
        borderColor: theme.palette.container.border.default,
        borderTopLeftRadius: 3 * theme.shape.borderRadius,
        borderBottomLeftRadius: 3 * theme.shape.borderRadius,
        width: '39%',
        maxWidth: theme.typography.pxToRem(390),
        textAlign: 'left',
        '& p': {
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
        },
    },
    td: {
        color: theme.palette.text.body,
    },
    'td:last-child, th:last-child': {
        borderTopRightRadius: 3 * theme.shape.borderRadius,
        borderBottomRightRadius: 3 * theme.shape.borderRadius,
    },
    '&.is-highlighted td': {
        borderTopColor: theme.palette.container.border.input,
        borderBottomColor: theme.palette.container.border.input,
        background: theme.palette.container.fill.card2,
    },
    '&.is-highlighted td:first-child': {
        borderLeftColor: theme.palette.container.border.input,
    },
    '&.is-highlighted td:last-child': {
        borderRightColor: theme.palette.container.border.input,
    },
}));
const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    borderWidth: 0,
    backgroundColor: 'transparent',
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    paddingLeft: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20),
    lineHeight: 'unset',
    whiteSpace: 'nowrap',
    '@media (max-width: 1400px)': {
        paddingLeft: theme.typography.pxToRem(12),
        paddingRight: theme.typography.pxToRem(12),
    },
}));
const StyledCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.container.fill.card1,
    paddingTop: theme.typography.pxToRem(13),
    paddingBottom: theme.typography.pxToRem(13),
    paddingLeft: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20),
    whiteSpace: 'nowrap',
    '@media (max-width: 1400px)': {
        paddingLeft: theme.typography.pxToRem(12),
        paddingRight: theme.typography.pxToRem(12),
    },
}));
const Table = ({ displayedColumns, rows }) => {
    return (_jsx(StyledTableContainer, { children: _jsxs(StyledTable, { children: [_jsx(TableHead, { children: _jsx(StyledTableRow, { children: displayedColumns.map(({ label, key, tooltip }) => (_jsx(Tooltip, { title: tooltip, placement: "top", arrow: true, children: _jsx(StyledHeaderCell, { children: _jsx(Typography, { variant: "overline", children: label }) }, 'header-cell' + key.toString()) }, `table-header-tooltip-${key.toString()}`))) }) }), _jsx(TableBody, { children: rows.map((row, index) => (_jsx(StyledTableRow, { sx: row.isVisible ? {} : { height: 0, visibility: 'collapse' }, className: row.isHighlighted ? 'is-highlighted' : '', children: displayedColumns.map(({ key }) => (_jsx(StyledCell, { children: row[key] }, 'column-' + key.toString()))) }, 'row-' + index))) })] }) }));
};
export default Table;
