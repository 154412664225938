/**
 * Nya Bs Paginated Selector based upon nya-bs-select for SCW frontend
 * Allows pagination in the nya bootstrap dropdown along with a search function which retrieves results from backend
 *
 * Example Usage:

  <ol class="nya-bs-select {{ styleClass }} btn-group" ng-model="selectorData.selected" ng-change="onItemSelected();">
    <li nya-bs-option="option in list"><a class="option-content">{{ option.name }}</a></li>
    <li><a class="option-content" ng-click="fetchItems(search.filter, false, true)" translate="BTN_LOAD_MORE" translate-values="{ page: search.options.page, pages: search.options.pages }"></a></li>
  </ol>


Attributes:
  selector-data: object in form { selected: selectedItem } containing selected data
  style-class: class to be added to nya-bs-select
  on-item-selected: function to be called after an item has been selected
  search-service: service to be used when fetching list of data
*/

import angular from 'angular';
import MODULE from './module';
import templateUrl from './nya-bs-paginated-selector.html';

angular.module(MODULE).directive('nyaBsPaginatedSelector', [
  '$compile',
  'AdminApiService',
  function ($compile, AdminApiService) {
    return {
      restrict: 'E',
      scope: {
        selectorData: '=',
        styleClass: '@',
        onItemSelected: '<',
        searchService: '<',
      },
      templateUrl,
      controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
          var SEARCH_BOX =
            '<div class="bs-searchbox">' +
            '<input type="text" class="form-control" ng-model="search.filter" ng-keyup="$event.keyCode === 13 ? fetchItems(search.filter, false) : null">' +
            '</div>';

          var searchBox = angular.element(SEARCH_BOX);

          var dropdownToggle = angular.element($element[0].querySelector('.dropdown-toggle'));

          function resetList() {
            $scope.list = [];

            var filter = $scope.search && $scope.search.filter ? $scope.search.filter : null;
            $scope.search = {
              options: { page: 1, size: 10 },
              filter: filter,
            };
          }

          var prevFilter = '';

          $scope.fetchItems = function (filter, firstLoad, nextPage) {
            var filterReset = false;
            if (!filter && prevFilter) filterReset = true;

            if (firstLoad) {
              resetList();
            }

            if ($scope.search.options.page >= $scope.search.options.pages && nextPage) {
              // no more to fetch
              return;
            }

            if (nextPage && !filterReset) $scope.search.options.page++;
            else resetList();

            $scope.loading = true;

            AdminApiService.addLoading();
            return $scope
              .searchService(filter, $scope.search.options, 'name')
              .then(function (result) {
                $scope.search.options = result;
                $scope.list = $scope.list.concat(result.data);
                if (firstLoad) {
                  $scope.selectorData.selected = $scope.list[0];
                  angular.element($element).find('.dropdown-menu:not(.inner)').prepend($compile(searchBox)($scope));

                  dropdownToggle.on('click', function () {
                    if ($element.children().eq(0).hasClass('open')) {
                      searchBox = angular.element(dropdownToggle.next()[0].querySelector('.bs-searchbox'));
                      searchBox.children().eq(0)[0].focus();
                    }
                  });

                  $scope.onItemSelected();
                }
                prevFilter = filter;
              })
              .finally(function () {
                AdminApiService.removeLoading();
                $scope.loading = false;
              });
          };

          $scope.fetchItems('', true);
        },
      ],
    };
  },
]);
