export const LIGHT_THEME_ROUTES = ['success-hub', 'communications', 'dashboard'];
/**
 *
 * @param hashRoute an "angularjs style" route, e.g. '#/success-hub' extracted from portal.securecodewarrior.com/#/success-hub
 * @returns whether to use light or dark theme
 */
export function decideThemeByHashRoute(hashRoute) {
    // strip leading '#/'
    const bareRoute = hashRoute.replace(/^#\//, '');
    return decideThemeByBareRoute(bareRoute);
}
/**
 *
 * @param bareRoute a "bare" route, e.g. 'success-hub' extracted from portal.securecodewarrior.com/#/success-hub
 * @returns 'light' if the bareRoute starts with any of the specified prefixes, otherwise 'dark'
 */
export function decideThemeByBareRoute(bareRoute) {
    return LIGHT_THEME_ROUTES.some((route) => bareRoute.startsWith(route)) ? 'light' : 'dark';
}
