import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).factory('LmsIntegrationService', [
  '$window',
  function ($window) {
    return {
      isOpenedByLms: () => !!$window.top.opener,
      sendMessage: (message) => $window.top.opener.postMessage(message, '*'),
    };
  },
]);
