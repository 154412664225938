export const messages = {
  CONTACT_SUPPORT: 'contactSupport',
  MISSIONS_USERSNAP_CLOSED: 'missionUserSnapWasClosed',
  MISSIONS_USERSNAP_OPEN: 'openUserSnapInMission',
  CHALLENGEPLAYER_USERSNAP_CLOSED: 'challengePlayerUserSnapWasClosed',
  CHALLENGEPLAYER_USERSNAP_OPEN: 'openUserSnapInChallengePlayer',
  LIVECODINGLABS_USERSNAP_CLOSED: 'liveCodingLabsUserSnapWasClosed',
  LIVECODINGLABS_USERSNAP_OPEN: 'openUserSnapInLiveCodingLabs',
  PROFILE_COMPLETED: 'profileCompleted',
  PROFILE_CHANGED: 'profileChanged',
  VIDEO_PREFERENCES_CHANGED: 'videoPreferencesChanged',
  VIDEO_WATCHED: 'videoWatched',
};

export const iframes = {
  MISSIONS: 'adversary',
  CHALLENGEPLAYER: 'challenge-iframe',
  LIVECODINGLABS: 'lesson-iframe',
  COURSES: 'course-iframe',
};
