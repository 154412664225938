import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Stack, FormControl, Typography } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { LocalFireDepartmentRounded as LocalFireDepartmentIcon } from '@mui/icons-material';
import { LoginMessage } from '../../explore/LoginMessage';
import { CybermonGrid } from '../styled';
import { ITEM_INCREMENT, useCybermonActivityFilter } from '../hooks/useCybermonActivityFilter';
import { ActivityCard } from './ActivityCard';
import { SelectLanguagesModal } from './SelectLanguagesModal';
import { LanguageSelect } from './LanguageSelect';
import { ModalProvider } from '../context/ModalContext';
import { usePlatformContext } from '../../platformContext';
import { cybermonActivities } from '../hooks/Cybermon.analytics';
import { useCybermon } from '../hooks/useCybermon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
const LanguageFilter = ({ languages, isContainedVariant = false, }) => {
    return (_jsx(Stack, { direction: "row", gap: 2, flexWrap: "wrap", minHeight: (theme) => theme.typography.pxToRem(24), alignItems: "center", children: _jsx(FormControl, { component: "fieldset", children: _jsxs(ModalProvider, { children: [_jsx(LanguageSelect, { languages: languages, isContainedVariant: isContainedVariant }), _jsx(SelectLanguagesModal, { languages: languages })] }) }) }));
};
const EmptyCard = () => {
    const { t } = useTranslation();
    return (_jsxs(Card, { display: "flex", flexDirection: "column", justifyContent: "center", gap: 5, sx: { backgroundColor: (theme) => theme.palette.container.fill.card2 }, children: [_jsx(Typography, { variant: "h5", className: "card-title", children: t('pages.cybermon.content.noMoreContentAvailableTitle') }), _jsx(Typography, { className: "card-body", children: t('pages.cybermon.content.noMoreContentAvailableBody') })] }));
};
const NoLanguagesSelected = (props) => {
    const { t } = useTranslation();
    return (_jsx(Box, { children: _jsx(Stack, { p: 5, gap: 5, children: _jsxs(Stack, { sx: (theme) => ({
                    display: 'flex',
                    padding: '64px 261px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '32px',
                    alignSelf: 'stretch',
                    borderRadius: '8px',
                    border: `1px solid ${theme.palette.container.border.default}`,
                    background: theme.palette.container.fill.card2,
                }), children: [_jsx(FindInPageIcon, { fontSize: "large", sx: { width: 56, height: 56 } }), _jsxs(Stack, { gap: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Typography, { variant: "h3", children: t('pages.cybermon.content.selectALanguageTitle') }), _jsx(Typography, { variant: "body1", children: t('pages.cybermon.content.selectALanguageBody') })] }), _jsx(LanguageFilter, { languages: props.filterOptions.languages, isContainedVariant: true })] }) }) }));
};
export const Activities = (props) => {
    const { canLoadMore, visibleActivities, loadMore, selectedLanguages } = useCybermonActivityFilter();
    const { logAnalyticsEvent } = usePlatformContext();
    const activityAnalytics = cybermonActivities(logAnalyticsEvent);
    const [seenActivities, setSeenActivities] = useState(new Set());
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.content' });
    for (const activity of visibleActivities) {
        const key = activity.contentId;
        if (!seenActivities.has(key)) {
            activityAnalytics.viewActivity(activity);
            setSeenActivities((prev) => prev.add(key));
        }
    }
    const { beginAttempt } = useCybermon();
    const isNoLanguagesSelected = selectedLanguages.length === 0;
    const shouldRenderEmptyCard = !canLoadMore && visibleActivities.length < ITEM_INCREMENT;
    const handleClick = (activity) => {
        beginAttempt();
        props.onActivitySelected(activity);
    };
    if (isNoLanguagesSelected) {
        return _jsx(NoLanguagesSelected, Object.assign({}, props));
    }
    return (_jsxs(Box, { children: [_jsxs(Stack, { p: 5, gap: 5, children: [_jsx(LanguageFilter, { languages: props.filterOptions.languages }), !props.isAuthenticated && _jsx(LoginMessage, { onLoginClicked: props.onLoginClicked }), _jsxs(CybermonGrid, { gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            lg: 'repeat(3, 1fr)',
                        }, children: [visibleActivities.map((activity) => (_jsx(ActivityCard, Object.assign({ onClick: () => handleClick(activity) }, activity), `${activity.contentId}${'language' in activity ? activity.language.id : ''}`))), shouldRenderEmptyCard && _jsx(EmptyCard, {})] })] }), canLoadMore && (_jsx(Stack, { alignItems: "center", justifyContent: "center", sx: { padding: 5, borderTop: (theme) => `1px solid ${theme.palette.container.border.default}` }, children: _jsx(Button, { onClick: loadMore, startIcon: _jsx(LocalFireDepartmentIcon, {}), disabled: shouldRenderEmptyCard || visibleActivities.length < ITEM_INCREMENT, children: t('loadMore') }) }))] }));
};
