import { jsx as _jsx } from "react/jsx-runtime";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const SnackbarAlert = ({ message, severity, open, onClose }) => {
    const handleCloseSnackbar = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
    };
    return (_jsx(Snackbar, { open: open, autoHideDuration: 6000, onClose: handleCloseSnackbar, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, children: _jsx(Alert, { onClose: handleCloseSnackbar, severity: severity, sx: (theme) => (Object.assign(Object.assign({ width: '100%', borderRadius: 2 }, theme.typography.body1), { boxShadow: `0px 2px 4px 0px rgba(21, 21, 21, 0.08), 0px 1px 2px 0px, rgba(21, 21, 21, 0.08)` })), children: message }) }));
};
export default SnackbarAlert;
