import angular from 'angular';
import MODULE from './module';

(function () {
  var app = angular.module(MODULE);

  app.controller('Game013PlayController', [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    '$q',
    'Game013Api',
    'Game013StateService',
    'ErrorHandler',
    function ($scope, $state, $stateParams, $translate, $q, Game013Api, Game013StateService, ErrorHandler) {
      $scope.$gameStatusPromise = $scope.$gameStatusPromise.then(function () {
        Game013StateService.setActiveState($stateParams);
        if (!Game013StateService.active._language || !Game013StateService.active._framework) {
          $state.go('game.013');
          Game013StateService.setActiveState({});
          return $q.when();
        }

        return Game013Api.getLanguageStatus(Game013StateService.active)
          .then(function (data) {
            $scope.gameData.state = data.state;
            $scope.gameData.story = data.story;
            Game013StateService.setActiveState(data.state.active);

            if ($state.current.name == 'game.013.play') {
              $state.go('game.013.play.realms', Game013StateService.active);
            }

            /*
        // simple redirection logic
          // only trigger if in the parent state
        // always show realms page regardless of what user was previously doing
        // can potentially use the `active` property to redirect user to the last quest or realm they were in
        if ('game.013.play' === $state.current.name) {
          $state.go('game.013.play.realms', $stateParams);
        }
        */
          })
          .catch(function (response) {
            $translate(['ERROR_LOADING_LANGUAGE']).then(function (translations) {
              ErrorHandler.addHttpError(translations.ERROR_LOADING_LANGUAGE, response);
            });
            $state.go('game.013.languages');
          });
      });
    },
  ]);
})();
