import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CardTemplate } from '../../../templates/CardTemplate';
import { getOrdinal } from '../../../../utils/getOrdinal';
export const TrustScoreActive = ({ data }) => {
    var _a;
    const { palette } = useTheme();
    const chartData = {
        value: data === null || data === void 0 ? void 0 : data.value,
        type: 'fraction',
        total: 1000,
        animate: true,
        name: 'value',
        fill: palette.graph.data.b1,
    };
    const recommendationContent = data === null || data === void 0 ? void 0 : data.recommendations[0].recommendation.split('\n');
    const percentile = getOrdinal((_a = data === null || data === void 0 ? void 0 : data.percentile) !== null && _a !== void 0 ? _a : 0);
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: "Trust Score" }), _jsx(Button, { variant: "outlined", children: "View Trust Score report" })] }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { alignItems: "center", gap: 4, children: [_jsx(RadialChart, { data: chartData, dataKey: "value", domain: [0, 1000] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsxs(Typography, { textAlign: "center", children: [_jsxs("strong", { children: [percentile, " percentile"] }), " global rank"] }) })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h6", children: "Top recommendation" }), recommendationContent === null || recommendationContent === void 0 ? void 0 : recommendationContent.map((content) => _jsx(Typography, { children: content }, content))] })] })] }));
};
