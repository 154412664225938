import angular from 'angular';
import 'angular-animate';
import 'angular-chart.js';
import 'angular-bootstrap-switch';

import SCWWalkthrough from '../walkthroughs';
import SCWCommons from '../components/commons';
import SCWLocaleHelpers from '../locale-helpers';
import SCWChurnZeroMetrics from '../churnzero';
import SCWFeatures from '../feature-flags';

export default angular.module('SCWTournaments', [
  'ngAnimate',
  'frapontillo.bootstrap-switch',
  'chart.js',
  SCWWalkthrough,
  SCWCommons,
  SCWLocaleHelpers,
  SCWChurnZeroMetrics,
  SCWFeatures,
]).name;
