import angular from 'angular';
import MODULE from './module';
import templateUrl from './team-selector.html';

/**
 * Team Selector for SCW frontend
 * Author: Tushar Nautial <tnautiyal@securecodewarrior.com>
 * Example Usage:

  <team-selector ng-if="$action == 'reassign'"
    placeholder="{{'Management.Users.Bulk.reassign.subaction' | translate}}"
    company-id="companyId"
    model="payload"
    model-id="payload._tid"
    on-team-selected="teamSelected()"
    team-search="teamSearch"
    team-pages="teamPages">
  </team-selector>


Attributes:
  placeholder: The default text in the search box
  company-id: Company Id of the company to which all teams belong
  model: Where to place a selected team
  model-id: Where to place just the _id of a selected team
  team-search: Search text
  team-pages: For Pagination
  class: class to be used in ui-select (ui-select-choices will use {{class}}-choices)
*/

angular.module(MODULE).directive('teamSelector', [
  'AdminApiService',
  function (AdminApiService) {
    return {
      restrict: 'E',
      scope: {
        name: '@?',
        placeholder: '@',
        companyId: '=',
        currentTeam: '=?',
        model: '@?',
        modelId: '@?',
        onTeamSelected: '&?',
        teamSearch: '=?',
        teamPages: '=?',
        platformManaged: '=?',
        class: '@?',
      },
      templateUrl,
      controller: [
        '$scope',
        '$rootScope',
        function ($scope, $rootScope) {
          // For storing current team and company id the user is assigned to
          $scope.init = {};
          $scope.filter = '';
          $scope.selected = {};

          function hasCurrentTeam() {
            return !!$scope.init['currentTeam'];
          }

          /**
           * Reset team list and modal team pagination options
           * Sets logic for populating paginated team list with No team option and
           * user currently assigned team
           */
          function resetTeamList() {
            $scope.teamSearch = [];
            if (hasCurrentTeam()) {
              if ($scope.init['_cid'] === $scope.companyId) {
                var team = angular.copy($scope.init['currentTeam']);
                $scope.teamSearch.push(team);
                $scope.selected = angular.copy(team);
              } else {
                $scope.selected = {};
              }
            }
            $scope.teamPages = {
              options: { page: 1, size: 10 },
            };
          }

          /**
           * Fetch teams when companyId change
           */
          $scope.$watch('companyId', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              fetchTeams(null, null, true);
            }
          });

          /**
           * Retrieves teams for selected company, concats team list when
           * load more button is triggered.
           * Resets teamList and pagination on new company selection
           * @param {string} filter
           * @param {object} $event Triggers on "Load more" button
           * @param {boolean} firstLoad flagged as true whenever new company is selected
           */
          var fetchTeams = function (filter, $event, firstLoad) {
            // Prevent refresh from automatically paginating to second page
            if (filter === '' && $scope.filter === filter && $event === undefined && firstLoad === undefined) {
              return;
            }

            if ($event) {
              $event.stopPropagation();
              $event.preventDefault();
            }

            if (firstLoad || $scope.filter !== filter) {
              // Reset teamlist on first load or filter search
              resetTeamList();
              $scope.filter = filter;
            }
            // 'Load more' button event increment page number for pagination
            else if ($scope.teamPages.options.size < $scope.teamPages.options.total) {
              $scope.teamPages.options.size = $scope.teamPages.options.size + 10;
            } else {
              return;
            }

            fetchTeams.inprogress = true;

            // get team list
            var context = {};
            if (undefined !== $scope.companyId) {
              context = { _cid: $scope.companyId };
            }
            return AdminApiService.getTeamList(context, filter, $scope.teamPages.options, 'name')
              .then(function (teams) {
                if ($scope.currentTeam) {
                  checkRemoveDuplicateTeam(teams.data);
                }
                $scope.teamSearch = teams.data;
                // Update pagination options
                delete teams.data;
                $scope.teamPages.options = teams;
              })
              .finally(function () {
                fetchTeams.inprogress = false;
              });
          };

          /**
           * Removes duplicate team from the returned list of teams from the backend
           * @param {object} teams
           */
          function checkRemoveDuplicateTeam(teams) {
            var index = _.findIndex(teams, ['_id', $scope.currentTeam._id]);
            if (index > -1) {
              delete teams[index];
            }
          }

          function uiSelectAction(team) {
            $scope.selected = team;

            if ($scope.model) _.set($scope.$parent, $scope.model, team);

            if ($scope.modelId) _.set($scope.$parent, $scope.modelId, team._id);

            if ($scope.onTeamSelected) $scope.onTeamSelected({ team: team });
          }

          function resetFlags() {
            $scope.init = {};
            $scope.filter = null;
            $scope.teamSearch = null;
            $scope.selected = angular.copy($scope.currentTeam);
          }

          function resetScopeValues() {
            if ($scope.model) {
              _.set($scope.$parent, $scope.model, $scope.currentTeam);
            }

            if ($scope.modelId) {
              _.set($scope.$parent, $scope.modelId, $scope.currentTeam._id);
            }
          }

          function init() {
            // Avoid populating list if initial team list has been provided

            if (undefined !== $scope.companyId) {
              $scope.init['_cid'] = $scope.companyId;
            }
            if (undefined !== $scope.currentTeam) {
              $scope.init['currentTeam'] = $scope.currentTeam;
            }

            if (undefined === $scope.platformManaged) {
              $scope.platformManaged = true;
            }
            if (!$scope.teamSearch || !$scope.teamSearch.length) {
              fetchTeams('', null, true);
            }
          }

          // Begin: methods
          $scope.fetchTeams = fetchTeams;
          $scope.uiSelectAction = uiSelectAction;
          // End: methods

          init();

          var resetListener = $rootScope.$on('team-selector:reset', function () {
            resetFlags();
            resetScopeValues();
            init();
          });

          $scope.$on('$destroy', function () {
            resetListener();
          });
        },
      ],
    };
  },
]);
