var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useFetchWithPlatformSessionHeader } from '../ApiContext';
const DEFAULT_REALM = 'web';
const DEFAULT_CAT = 'injection';
const DEFAULT_CATEGORY = `${DEFAULT_REALM}.${DEFAULT_CAT}`;
function getValidRealmAndCat(metadata, category) {
    let [realm, cat] = category.split('.');
    // If realm or category are missing, or invalid as a combination, default to web.injection
    if (!realm || !cat || !metadata.categories[realm] || !metadata.categories[realm][cat]) {
        realm = DEFAULT_REALM;
        cat = DEFAULT_CAT;
    }
    return { realm, cat };
}
export const EngagementRedirect = (props) => {
    const { fetchWithSession, apiEndpoint } = useFetchWithPlatformSessionHeader();
    useEffect(() => {
        const fetchUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            const { type, contentId, language, activityType, category } = props.hashQueryParams;
            if (activityType && language)
                return `#explore?language=${language}&category=${category}&activityType=${activityType}`;
            if (contentId && type)
                return language ? `#explore/${type}/${contentId}/${language}` : `#explore/${type}/${contentId}`;
            const response = yield fetchWithSession(apiEndpoint + '/public/metadata', { method: 'POST' });
            const metadata = yield response.json();
            let selectedCategories = category;
            if (Array.isArray(selectedCategories)) {
                if (selectedCategories.length === 0) {
                    selectedCategories = DEFAULT_CATEGORY;
                }
                selectedCategories = category[0];
            }
            const { realm, cat } = getValidRealmAndCat(metadata, selectedCategories);
            // look up metadata for subcategories of category
            const subitem = metadata.categories[realm][cat].subitem;
            const subcats = Object.keys(subitem).filter((subcat) => subitem[subcat].status === 'visible');
            const categoryParams = subcats.map((subcat) => `&category=${realm}.${cat}.${subcat}`).join('');
            return `#/explore?activityType=coding_lab${categoryParams}`;
        });
        fetchUrl()
            .then((url) => {
            location.replace(url);
        })
            .catch((error) => {
            console.error('Error:', error);
        });
    }, [fetchWithSession, apiEndpoint, props.hashQueryParams]);
    return null;
};
