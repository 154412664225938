const PROFILE_KEY = 'profile';
const PREFERENCES_KEY = 'microlearning-preferences';

const getFromLocalStorage = ($window, key) => {
  if (!$window.localStorage.getItem(key)) {
    return {};
  }
  try {
    return JSON.parse($window.localStorage.getItem(key));
  } catch (error) {
    $window.localStorage.removeItem(key);
    return {};
  }
};

const saveToLocalStorage = ($window, key, value) => $window.localStorage.setItem(key, JSON.stringify(value));

export const getProfileFromLocalStorage = ($window) => getFromLocalStorage($window, PROFILE_KEY);
export const getPreferencesFromLocalStorage = ($window) => getFromLocalStorage($window, PREFERENCES_KEY);
export const savePreferencesToLocalStorage = ($window, value) => saveToLocalStorage($window, PREFERENCES_KEY, value);
export const saveProfileToLocalStorage = ($window, value) => saveToLocalStorage($window, PROFILE_KEY, value);

export const languageSortFn = (a, b) => {
  a = a.id;
  b = b.id;

  if (a._id === b._id && a._framework === b._framework) {
    return 0;
  }

  return a._id < b._id || (a._id === b._id && a._framework < b._framework) ? -1 : 1;
};

export const numberOfChallengesDesc = (a, b) => {
  a = a.vulnerabilityNumberOfChallenges;
  b = b.vulnerabilityNumberOfChallenges;
  return b - a;
};

export const filterHiddenSubCategories = (categoriesObject) => {
  const filteredWebCategories = {};
  const web = categoriesObject.web;
  for (const subCategoryKey in web) {
    const filteredSubitems = {};
    const subCategory = web[subCategoryKey];
    for (const subitemKey in subCategory.subitem) {
      const subitem = subCategory.subitem[subitemKey];

      if (subitem.status === 'visible') {
        filteredSubitems[subitemKey] = subitem;
      }
    }
    if (Object.keys(filteredSubitems).length > 0) {
      filteredWebCategories[subCategoryKey] = {
        ...subCategory,
        subitem: filteredSubitems,
      };
    }
  }

  const filteredMobileCategories = {};
  const mobile = categoriesObject.mobile;
  for (const subCategoryKey in mobile) {
    const filteredSubitems = {};
    const subCategory = mobile[subCategoryKey];
    for (const subitemKey in subCategory.subitem) {
      const subitem = subCategory.subitem[subitemKey];

      if (subitem.status === 'visible') {
        filteredSubitems[subitemKey] = subitem;
      }
    }
    if (Object.keys(filteredSubitems).length > 0) {
      filteredMobileCategories[subCategoryKey] = {
        ...subCategory,
        subitem: filteredSubitems,
      };
    }
  }

  return { web: filteredWebCategories, mobile: filteredMobileCategories };
};

export const mapAppTypeCategories = (metadataCategories) => {
  if (!metadataCategories) {
    return [];
  }
  let mappedCategories = [];

  Object.entries(metadataCategories).map(([apptype, categories]) => {
    Object.entries(categories).map(([_, category]) => {
      Object.entries(category.subitem).map(([_, subcategory]) => {
        mappedCategories.push(createDisplayCategory(apptype, category, subcategory));
      });
    });
  });

  return mappedCategories.sort((a, b) => {
    if (a.category.name === b.category.name) {
      return a.subcategory.name > b.subcategory.name ? 1 : -1;
    }
    return a.category.name > b.category.name ? 1 : -1;
  });
};

export const filterDisplayCategories = (filters, categories) => {
  let filtered = categories;
  const { app_type, category, subcategory } = filters;

  if (app_type) {
    filtered = filtered.filter((c) => c.appType === app_type);
  }

  if (category) {
    filtered = filtered.filter((c) => c.category.id === category);
  }

  if (subcategory) {
    filtered = filtered.filter((c) => c.subcategory.id === subcategory);
  }

  return filtered;
};

export const subcategoryLanguageSortFn = (a, b) => {
  a = a.id;
  b = b.id;
  if (a._id === b._id && a._framework === b._framework) {
    return 0;
  }
  return a._id < b._id || (a._id === b._id && a._framework < b._framework) ? -1 : 1;
};

const createDisplayCategory = (appType, category, subcategory) => {
  const subcategoryName = appType === 'web' ? subcategory.name : `${subcategory.name} (${appType})`;
  return {
    appType: appType,
    iconShown: category.iconShown,
    category: {
      name: category.name,
      id: category.id,
    },
    subcategory: {
      name: subcategoryName,
      id: subcategory.id,
    },
  };
};

export const getUtmSourceFromString = (value) => {
  const params = new URLSearchParams(value);
  return params.get('utm_source') || 'none';
};
