import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState, useId } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Trans, useTranslation } from 'react-i18next';
import { isNumber } from 'fp-ts/lib/number';
import { Box, Stack, Typography, TableHead, TableRow, TableBody, TableCell, Table, tableCellClasses, useTheme, DialogTitle, DialogContent, } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Download as DownloadIcon, CheckCircleOutline as CheckCircleOutlineIcon, CheckCircle as CheckCircleIcon, Check as CheckIcon, Close as CloseIcon, AccessTime as AccessTimeIcon, StarHalf as StarHalfIcon, RefreshOutlined as RefreshOutlinedIcon, Public as PublicIcon, PlayArrow as PlayArrowIcon, Lock as LockIcon, CenterFocusStrong as CenterFocusStrongIcon, ShuffleOutlined as ShuffleOutlinedIcon, Email as EmailIcon, MyLocation as MyLocationIcon, BarChart as BarChartIcon, Search as SearchIcon, WbIncandescent as WbIncandescentIcon, GppGood as GppGoodIcon, WatchLater as WatchLaterIcon, HourglassEmpty as HourglassEmptyIcon, Timelapse as TimelapseIcon, HighlightOffOutlined as HighlightOffOutlined, CheckCircleOutlined as CheckCircleOutlinedIcon, } from '@mui/icons-material';
import { dayjs, msAsMinutes, useSkeletonAccessibilityAttr, wrapPromise, Container, Skeleton, Suspenser, } from '@securecodewarrior/design-system-react';
import { Badge, Breadcrumbs, Button, CardStyle, Dialog, DurationText, Highlight, Link, OptionSelect, TableContainer, Text, } from '@securecodewarrior/design-system-react/lib/legacy';
import { DIFFICULTIES, DifficultyRating, difficultyTranslationMap, difficultyFullTranslationMap, } from '../../difficulty';
import { CODEBASE_SIZES, codebaseSizesTranslationMap } from '../../codebaseSizes';
import { retriesAllowedText } from '../../RetriesAllowed';
const downloadCertificateTitleRecord = {
    pending: 'pages.assessments.results.downloadCertificateTitlePending',
    success: 'pages.assessments.results.downloadCertificateTitleSuccess',
    error: 'pages.assessments.results.downloadCertificateTitleError',
};
const downloadCertificateIcon = {
    pending: (_jsx(TimelapseIcon, { sx: (theme) => ({ color: theme.palette.accent.main, fontSize: theme.typography.pxToRem(30) }) })),
    success: (_jsx(CheckCircleOutlinedIcon, { sx: (theme) => ({ color: theme.palette.success.main, fontSize: theme.typography.pxToRem(30) }) })),
    error: (_jsx(HighlightOffOutlined, { sx: (theme) => ({ color: theme.palette.error.main, fontSize: theme.typography.pxToRem(30) }) })),
};
const downloadCertificateColor = {
    pending: 'accent',
    success: 'success',
    error: 'error',
};
const DownloadCertificate = (props) => {
    const { t } = useTranslation();
    const [certDialogOpen, setCertDialogOpen] = useState(false);
    const [status, setStatus] = useState('pending');
    const onDownloadCertificate = () => {
        var _a;
        setStatus('pending');
        setCertDialogOpen(true);
        (_a = props
            .onDownloadCertificate) === null || _a === void 0 ? void 0 : _a.call(props).then(() => setStatus('success')).catch(() => setStatus('error'));
    };
    useEffect(() => {
        if (status === 'error' && !certDialogOpen)
            setCertDialogOpen(true);
    }, [status, certDialogOpen]);
    const titleText = t(downloadCertificateTitleRecord[status]);
    const titleIcon = downloadCertificateIcon[status];
    const dialogColor = downloadCertificateColor[status];
    const descId = useId();
    const titleId = useId();
    return (_jsxs(_Fragment, { children: [_jsx(Stack, { direction: "row", spacing: 2, alignSelf: "flex-end", flexShrink: 0, pt: 3, children: _jsx(Button, { onClick: onDownloadCertificate, startIcon: _jsx(DownloadIcon, {}), variant: props.buttonVariant, children: t('pages.assessments.results.downloadCertificate') }) }), _jsxs(Dialog, { open: certDialogOpen, color: dialogColor, onClose: () => setCertDialogOpen(false), "aria-labelledby": titleId, "aria-describedby": descId, children: [_jsx(DialogTitle, { children: _jsx(Text, { startIcon: titleIcon, label: titleText, typographyProps: { variant: 'inherit' } }) }), _jsx(DialogContent, { id: descId, children: _jsx(Stack, { gap: 2, children: _jsx(Trans, { i18nKey: "pages.assessments.results.downloadCertificateContent", components: { 1: _jsx("p", {}), 2: _jsx("div", {}) } }) }) })] })] }));
};
export const AssessmentResults = (props) => {
    const attemptReader = useMemo(() => wrapPromise(props.attempt), [props.attempt]);
    const { t } = useTranslation();
    const skeletonA11YAttrs = useSkeletonAccessibilityAttr();
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { component: "header", py: 4, px: 10, borderBottom: 1, borderColor: (theme) => theme.palette.grayscale.bg4, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [props.breadcrumbs !== null && (_jsx(Suspenser, { reader: attemptReader, skeleton: _jsx(Skeleton, Object.assign({}, skeletonA11YAttrs, { width: "320px" })), render: (attempt) => {
                                    var _a, _b, _c;
                                    return (_jsxs(Breadcrumbs, { children: [_jsx(Link, { href: (_a = props.breadcrumbs) === null || _a === void 0 ? void 0 : _a.assessmentsListOrProgramUrl, children: ((_b = props.breadcrumbs) === null || _b === void 0 ? void 0 : _b.mode) && props.breadcrumbs.mode === 'program'
                                                    ? t('pages.assessments.common.title-program')
                                                    : t('pages.assessments.common.title') }), _jsxs(Link, { href: (_c = props.breadcrumbs) === null || _c === void 0 ? void 0 : _c.assessmentUrl, children: [attempt.name, " v", attempt.version] }), _jsx(Typography, { "aria-current": "page", children: t('pages.assessments.results.title') })] }));
                                } })), _jsx(Suspenser, { reader: attemptReader, skeleton: _jsx(Skeleton, Object.assign({}, skeletonA11YAttrs, { width: "160px" })), render: (attempt) => {
                                    var _a;
                                    return attempt.programId || attempt._lastProgramNavigation ? (_jsx(Button, { component: "a", style: { textDecoration: 'none' }, href: (_a = props.breadcrumbs) === null || _a === void 0 ? void 0 : _a.assessmentsListOrProgramUrl, children: t(attempt.status === 'passed'
                                            ? 'pages.assessments.results.continueProgram'
                                            : 'pages.assessments.results.backToProgram') })) : undefined;
                                } })] }), _jsxs(Stack, { direction: "row", flexWrap: "wrap", columnGap: 6, pt: 6, children: [_jsx(Suspenser, { reader: attemptReader, container: (el, a11yAttrs) => (_jsx(Stack, Object.assign({ spacing: 1, flexBasis: 500, flexShrink: 1, flexGrow: 1 }, a11yAttrs, { children: el }))), render: (attempt) => (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "row", columnGap: 4, children: [_jsxs(Typography, { variant: "h1", children: [attempt.name, " v", attempt.version] }), attempt.status !== 'in_progress' && (_jsx(Box, { alignSelf: "center", children: _jsx(Badge, { label: attempt.status === 'passed'
                                                            ? t('entities.assessments.status.passed')
                                                            : t('entities.assessments.status.failed'), variant: attempt.status === 'passed' ? 'success' : 'error' }) }))] }), _jsx(Typography, { variant: "body2", children: attempt.description }), ' '] })), skeleton: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h1", width: "450px", children: _jsx(Skeleton, {}) }), _jsxs(Typography, { variant: "body2", children: [_jsx(Skeleton, {}), _jsx(Skeleton, { width: "75%" })] }), ' '] }) }), _jsx(Suspenser, { reader: attemptReader.map((a) => a.onDownloadCertificate), render: (onDownloadCertificate) => onDownloadCertificate && (_jsx(DownloadCertificate, { onDownloadCertificate: onDownloadCertificate, buttonVariant: 'outlined' })), skeleton: null })] })] }), _jsx(Container, { children: _jsxs(Stack, { rowGap: 4, children: [_jsx(Suspenser, { reader: attemptReader, render: (attempt) => {
                                const finishDate = attempt.finishedAt ? dayjs.unix(attempt.finishedAt).format('LLL') : null;
                                return (_jsx(_Fragment, { children: attempt.status !== 'in_progress' && finishDate && (_jsx(Highlight, { color: attempt.status === 'passed' ? 'success' : 'error', children: attempt.status === 'passed' ? (_jsx(Text, { startIcon: _jsx(CheckCircleOutlineIcon, { color: "success", fontSize: "large" }), label: t('pages.assessments.results.passedOnDate', { date: finishDate }) })) : (_jsx(Text, { startIcon: _jsx(CloseIcon, { color: "error", fontSize: "large" }), label: t('pages.assessments.results.failedOnDate', { date: finishDate }) })) })) }));
                            }, skeleton: null }), _jsx(AssessmentAttemptDetails, { assessment: attemptReader.map((attempt) => (Object.assign(Object.assign({}, attempt.assessmentAttemptDetailsProps), { startedAt: attempt.startedAt, timeLimit: attempt.timeLimit }))) }), _jsx(Suspenser, { reader: attemptReader, render: (attempt) => (_jsxs(_Fragment, { children: [attempt.status === 'in_progress' && _jsx(AssessmentNoData, {}), attempt.assessmentMetricsProps && (_jsx(AssessmentMetrics, Object.assign({}, attempt.assessmentMetricsProps, { timeLimit: attempt.timeLimit, startedAt: attempt.startedAt, finishedAt: attempt.finishedAt }))), attempt.strengthPerCategoryProps && _jsx(StrengthPerCategory, Object.assign({}, attempt.strengthPerCategoryProps))] })), skeleton: _jsx(Skeleton, Object.assign({}, skeletonA11YAttrs, { variant: "rounded", height: "250px", sx: (theme) => ({ bgcolor: theme.palette.grayscale.bg3 }) })) })] }) })] }));
};
const AssessmentAttemptDetails = (props) => {
    const { t } = useTranslation();
    const skeletonA11YAttrs = useSkeletonAccessibilityAttr();
    return (_jsxs(CardStyle.Stack, { children: [_jsx(Typography, { variant: "h5", component: "h2", children: t('pages.assessments.overview.overview') }), _jsxs(Stack, { direction: "row", flexWrap: "wrap", gap: 4, children: [_jsxs(Stack, { spacing: 4, flexGrow: "1", flexBasis: 0, children: [_jsx(Suspenser, { reader: props.assessment, render: (assessment) => assessment.selectedLanguage ? (_jsx(Text, { startIcon: _jsx("i", { className: assessment.selectedLanguage.iconClassName }), label: assessment.selectedLanguage.label })) : (_jsx(Typography, { children: t('pages.assessments.results.languageNotSelected') })), skeleton: _jsxs(Stack, Object.assign({ spacing: 2, direction: "row", alignItems: "center" }, skeletonA11YAttrs, { children: [_jsx(Skeleton, { width: "1em" }), _jsx(Skeleton, { width: "160px" })] })) }), _jsx(Text, { startIcon: _jsx(StarHalfIcon, {}), reader: props.assessment.map((assessment) => t(difficultyFullTranslationMap[assessment.challengeDifficulty])) }), _jsx(Text, { startIcon: _jsx(RefreshOutlinedIcon, {}), reader: props.assessment.map((assessment) => retriesAllowedText(assessment.retriesAllowed, t)) }), _jsx(Text, { startIcon: _jsx(AccessTimeIcon, {}), reader: props.assessment.map((assessment) => assessment.timeLimit === null
                                    ? t('entities.assessments.noTimeLimit')
                                    : t('entities.assessments.timeLimitInMinutes', { minutes: msAsMinutes(assessment.timeLimit) })) })] }), _jsxs(Stack, { spacing: 4, flexGrow: "1", flexBasis: 0, children: [_jsx(Text, { startIcon: _jsx(PlayArrowIcon, {}), endIcon: _jsx(PublicIcon, {}), reader: props.assessment.map((assessment) => assessment.startedAt
                                    ? t('pages.assessments.results.startedAtDate', {
                                        date: dayjs.unix(assessment.startedAt).format('L, LT'),
                                    })
                                    : t('entities.assessments.status.notStarted')) }), _jsxs(Stack, { direction: "row", spacing: 2, children: [_jsx(Text, { startIcon: _jsx(CenterFocusStrongIcon, {}), reader: props.assessment.map((assessment) => t('pages.assessments.results.challengesCount', { count: assessment.challenges })) }), _jsx(Suspenser, { reader: props.assessment, render: (assessment) => (_jsx(Text, { typographyProps: { textTransform: 'uppercase' }, startIcon: assessment.fixedChallenges ? _jsx(LockIcon, {}) : _jsx(ShuffleOutlinedIcon, {}), label: assessment.fixedChallenges
                                                ? t('pages.assessments.results.fixed')
                                                : t('pages.assessments.results.randomized') })), skeleton: null })] }), _jsx(Text, { startIcon: _jsx(EmailIcon, {}), reader: props.assessment.map((assessment) => assessment.selfAssessmentAllowed
                                    ? t('pages.assessments.results.selfAssessAllowed')
                                    : t('pages.assessments.results.selfAssessDisallowed')) }), _jsx(Text, { startIcon: _jsx(CheckCircleIcon, {}), reader: props.assessment.map((assessment) => assessment.successRatio
                                    ? t('pages.assessments.overview.percentToPass', { ratio: assessment.successRatio })
                                    : t('pages.assessments.results.nonExam')) })] })] })] }));
};
const AssessmentMetrics = (props) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h2", sx: visuallyHidden, children: t('pages.assessments.results.attemptMetrics') }), _jsxs(Stack, { direction: "row", flexWrap: "wrap", gap: 2, children: [_jsx(DataBlock, { children: _jsxs(Stack, { direction: "row", justifyContent: "space-evenly", flexGrow: "1", children: [_jsxs(Stack, { gap: 6, children: [_jsxs(Stack, { rowGap: 2, children: [_jsx(Typography, { variant: "h5", component: "p", children: t('pages.assessments.results.yourScore') }), _jsx(MetricNumberCardText, { value: props.score, large: true })] }), _jsxs(Stack, { rowGap: 2, children: [_jsx(Typography, { variant: "h5", component: "p", children: t('pages.assessments.results.avgScore') }), props.avgScore === null ? (_jsx(MetricStringCardText, { value: t('common.n_a') })) : (_jsx(MetricNumberCardText, { value: props.avgScore }))] })] }), _jsx(BarChartIcon, { sx: (theme) => ({
                                        alignSelf: 'center',
                                        fontSize: { sm: theme.typography.pxToRem(200), xs: theme.typography.pxToRem(130) },
                                    }) })] }) }), _jsxs(Stack, { flexGrow: "1", gap: 2, children: [_jsxs(Stack, { direction: "row", gap: 2, flexWrap: "wrap", children: [_jsx(MetricCard, { label: t('entities.assessments.locate'), value: (_a = props.locate) !== null && _a !== void 0 ? _a : t('common.n_a'), icon: _jsx(MyLocationIcon, { fontSize: "large" }) }), _jsx(MetricCard, { label: t('entities.assessments.identify'), value: (_b = props.identify) !== null && _b !== void 0 ? _b : t('common.n_a'), icon: _jsx(SearchIcon, { fontSize: "large" }) }), _jsx(MetricCard, { label: t('entities.assessments.solve'), value: (_c = props.solve) !== null && _c !== void 0 ? _c : t('common.n_a'), icon: _jsx(WbIncandescentIcon, { fontSize: "large", sx: { transform: 'rotate(180deg)' } }) })] }), _jsxs(Stack, { direction: "row", gap: 2, children: [_jsx(MetricCard, { label: t('pages.assessments.results.completion'), value: props.completion, icon: _jsx(GppGoodIcon, { fontSize: "large" }) }), _jsx(MetricCard, { label: t('pages.assessments.overview.completedIn'), value: props.startedAt && props.finishedAt ? (_jsx(DurationText, { durationInSeconds: props.finishedAt - props.startedAt })) : (t('pages.assessments.results.notFinished')), icon: _jsx(WatchLaterIcon, { fontSize: "large" }) })] })] })] })] }));
};
const metricTextStyle = {
    lineHeight: 'initial',
    fontWeight: '600',
};
const MetricNumberCardText = ({ value, large }) => {
    const color = () => {
        if (value >= 60)
            return 'success';
        if (value >= 50)
            return 'warning';
        return 'error';
    };
    return (_jsxs(Typography, Object.assign({ fontSize: (theme) => theme.typography.pxToRem(large ? 44 : 32), color: (theme) => theme.palette[color()].main }, metricTextStyle, { children: [floorTo2dp(value), "%"] })));
};
const MetricStringCardText = ({ value }) => (_jsx(Typography, Object.assign({}, metricTextStyle, { fontSize: (theme) => theme.typography.pxToRem(32), children: value })));
const MetricCard = ({ icon, value, label }) => (_jsx(DataBlock, { children: _jsxs(Stack, { rowGap: 2, alignItems: "center", children: [icon, _jsx(Typography, { variant: "h5", component: "p", children: label }), isNumber(value) ? _jsx(MetricNumberCardText, { value: value }) : _jsx(MetricStringCardText, { value: value })] }) }));
const DataBlock = ({ children }) => (_jsx(Box, { flexGrow: "1", borderRadius: 2, py: 6, px: 8, display: "flex", justifyContent: "center", alignItems: "center", border: (theme) => `4px solid ${theme.palette.grayscale.bg4}`, children: children }));
const StrengthPerCategory = (props) => {
    const [hoverCategory, setHoverCategory] = useState(null);
    const { t, i18n } = useTranslation();
    return (_jsxs(CardStyle.Stack, { children: [_jsx(Typography, { variant: "h5", component: "h2", children: t('pages.assessments.results.strengthPerCategory') }), _jsx(CategoryBarChart, { data: props.categories, onMouseEnter: setHoverCategory, onMouseLeave: () => setHoverCategory(null) }), _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: i18n.format(t('entities.assessments.category'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.codebase'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.difficulty'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.locate'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.identify'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.solve'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.timeSpent'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.score'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.weight'), 'sentenceCase') }), _jsx(TableCell, { children: i18n.format(t('entities.assessments.weightedScore'), 'sentenceCase') })] }) }), _jsx(TableBody, { children: props.categories.map((cat) => (_jsx(CategoryStrengthRow, { highlighted: hoverCategory === cat.id, data: cat }, cat.id))) })] }) })] }));
};
const allCompare = ['score', 'weightedScore', 'weight', 'difficulty', 'timeSpent', 'codeBaseSize'];
const CategoryBarChart = (props) => {
    const [compareBy, setCompareBy] = useState('score');
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isStringCompareBy = compareBy === 'codeBaseSize' || compareBy === 'difficulty';
    const data = useMemo(() => props.data.map((x) => (Object.assign(Object.assign({}, x), { difficulty: DIFFICULTIES.indexOf(x.difficulty) + 1, timeSpent: x.timeSpent === null ? null : Math.floor(x.timeSpent / 1000 / 60), codeBaseSize: CODEBASE_SIZES.indexOf(x.codeBaseSize) + 1 }))), [props.data]);
    const valueFormatter = (value) => {
        if (isNumber(value)) {
            if (compareBy === 'difficulty')
                return t(difficultyTranslationMap[DIFFICULTIES[value - 1]]);
            if (compareBy === 'codeBaseSize')
                return t(codebaseSizesTranslationMap[CODEBASE_SIZES[value - 1]]);
            return value.toFixed(2);
        }
        return `${value}`;
    };
    const tickFormatter = (value) => {
        if (value === 0) {
            return '';
        }
        return valueFormatter(value);
    };
    const compareByOptions = {
        score: i18n.format(t('entities.assessments.score'), 'sentenceCase'),
        weightedScore: i18n.format(t('entities.assessments.weightedScore'), 'sentenceCase'),
        weight: i18n.format(t('entities.assessments.weight'), 'sentenceCase'),
        difficulty: i18n.format(t('entities.assessments.difficulty'), 'sentenceCase'),
        timeSpent: i18n.format(t('entities.assessments.timeSpent'), 'sentenceCase'),
        codeBaseSize: i18n.format(t('entities.assessments.codebase'), 'sentenceCase'),
    };
    return (_jsxs(Stack, { spacing: 4, "aria-hidden": "true", children: [_jsx(Box, { alignSelf: "flex-end", width: "300px", children: _jsx(OptionSelect, { label: t('pages.assessments.results.compareBy'), inputProps: { tabIndex: -1 }, value: compareBy, options: compareByOptions, onChange: (change) => setCompareBy(change) }) }), _jsx(Box, { flexGrow: "1", height: "500px", children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, { width: 400, height: 600, data: data, children: [_jsx(CartesianGrid, { vertical: false, strokeDasharray: "3 3" }), _jsx(XAxis, { stroke: theme.palette.grayscale.bg8, dataKey: "name" }), _jsx(YAxis, { stroke: theme.palette.grayscale.bg8, tickFormatter: tickFormatter, width: 80, minTickGap: 1, domain: isStringCompareBy ? [0, 3] : undefined, allowDecimals: false }), _jsx(Tooltip, { formatter: valueFormatter, wrapperStyle: {
                                    backgroundColor: theme.palette.grayscale.bg3,
                                    border: 0,
                                    outline: 0,
                                    borderRadius: '8px',
                                }, cursor: { fill: theme.palette.grayscale.bg2, opacity: 0.6 }, contentStyle: { backgroundColor: theme.palette.grayscale.bg4, border: 'none', borderRadius: '8px' } }), _jsx(Bar, { dataKey: compareBy, name: compareByOptions[compareBy], fill: theme.palette.warning.main, onMouseLeave: props.onMouseLeave, onMouseEnter: (e) => props.onMouseEnter(e.payload.id) })] }) }) })] }));
};
const CategoryStrengthRow = ({ data, highlighted }) => {
    const { t } = useTranslation();
    return (_jsxs(TableRow, { sx: (theme) => ({ [`.${tableCellClasses.body}`]: { bgcolor: highlighted && theme.palette.grayscale.bg2 } }), children: [_jsx(TableCell, { children: data.name }), _jsx(TableCell, { children: t(codebaseSizesTranslationMap[data.codeBaseSize]) }), _jsx(TableCell, { children: _jsx(DifficultyRating, { difficulty: data.difficulty }) }), _jsx(TableCell, { children: _jsx(StageStatus, { status: data.locate }) }), _jsx(TableCell, { children: _jsx(StageStatus, { status: data.identify }) }), _jsx(TableCell, { children: _jsx(StageStatus, { status: data.solve }) }), _jsx(TableCell, { children: data.timeSpent === null ? (t('pages.assessments.common.pending')) : (_jsx(DurationText, { durationInSeconds: data.timeSpent / 1000 })) }), _jsx(TableCell, { children: data.score === null ? t('pages.assessments.common.pending') : data.score.toFixed(2) + '%' }), _jsxs(TableCell, { children: [data.weight.toFixed(2), "%"] }), _jsx(TableCell, { children: data.weightedScore === null ? t('pages.assessments.common.pending') : data.weightedScore.toFixed(2) + '%' })] }));
};
const StageStatus = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    if (props.status === undefined) {
        return _jsx(Box, { sx: visuallyHidden, "aria-label": t('common.not_applicable') });
    }
    const statusMap = {
        correct: (_jsx(CheckIcon, { htmlColor: theme.palette.success.main, titleAccess: t('pages.assessments.results.stageStatus.correct'), fontSize: "large" })),
        incorrect: (_jsx(CloseIcon, { htmlColor: theme.palette.error.main, titleAccess: t('pages.assessments.results.stageStatus.incorrect'), fontSize: "large" })),
        incomplete: (_jsx(HourglassEmptyIcon, { htmlColor: theme.palette.grayscale.bg7, titleAccess: t('pages.assessments.results.stageStatus.incomplete'), fontSize: "large" })),
    };
    return statusMap[props.status];
};
const AssessmentNoData = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h2", sx: visuallyHidden, children: t('pages.assessments.results.attemptMetrics') }), _jsx(CardStyle, { px: 10, py: 5, textAlign: "center", children: _jsxs(Stack, { alignItems: "center", py: 4, gap: 4, children: [_jsx(Typography, { variant: "h4", children: t('pages.assessments.results.notDoneYet') }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "pages.assessments.results.inProgress" }) })] }) })] }));
};
const floorTo2dp = (i) => Math.floor(i * 100) / 100;
