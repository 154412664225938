import angular from 'angular';
import MODULE from './module';

angular
  .module(MODULE)
  /**
   * Autofocus only if condition is true
   */
  .directive('autofocusIf', [
    '$log',
    '$timeout',
    function ($log, $timeout) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          var condition = attrs.autofocusIf;
          var result = scope.$eval(condition);

          $log.debug('[autofocus-if] evaluating condition', condition, result, scope);

          if (typeof result === 'object' && result.then) result.then(focus);
          else if (result) focus(true);

          function focus(data) {
            if (data) {
              $timeout(function () {
                element.attr('tabindex', 0);
                element.focus();
              }, attrs.autofocusWait || 500);
            }
          }
        },
      };
    },
  ]);
