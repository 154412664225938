/**
 * eg from 'injection, code' this will return 'application_security_weaknesses:web:data_handling:injection:code'
 *
 * This is safe to use for challenges as allegedly challenge categories always come from the
 * 'application_security_weaknesses' categories. I have no idea about other non-challenge activity types.
 */
export const createVideoIdForChallengeCategory = (metadata, categoryId, subcategoryId) => {
    for (const appType in metadata.categories) {
        const category = metadata.categories[appType][categoryId];
        if (category === null || category === void 0 ? void 0 : category.subitem[subcategoryId]) {
            return `application_security_weaknesses:${appType}:${category.management_category}:${categoryId}:${subcategoryId}`;
        }
    }
    return undefined;
};
