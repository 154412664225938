import { ButtonBase, styled } from '@mui/material';
export const DangerButton = styled(ButtonBase)({
    background: '#e74c3c',
    color: '#ffffff',
    appearance: 'none',
    webkitAppearance: 'none',
    fontFamily: 'sans-serif',
    cursor: 'pointer',
    padding: '12px',
    minWidth: '100px',
    border: '0px',
    webkitTransition: 'background-color 100ms linear',
    msTransition: 'background-color 100ms linear',
    transition: 'background-color 100ms linear',
    borderRadius: '8px',
    '&:focus': {
        outline: 0,
    },
    '&:hover': {
        background: '#c0392b',
        color: '#ffffff',
    },
});
