import angular from 'angular';
import MODULE from './module';

/**
 * The font-heigh directive sets the font of the element to be a relative size
 * of the immediate height-defined container height.
 * It's used for dinamic font sizing within self-contained widgets (e.g.: partial-doughnut).
 *
 * Usage: <div font-heigth="20%">Dynamically sized text</font-height>
 */
angular.module(MODULE).directive('fontHeight', [
  function () {
    return {
      restrict: 'A', // attributes only
      link: function (_scope, element, attributes) {
        // define as internal function in case re-invokation is needed on screen resize
        function fontHeight() {
          var parent,
            dimension = 0,
            elementHeight = 0;

          dimension = attributes.fontHeight;
          if (dimension[dimension.length - 1] == '%') {
            //:
            dimension = dimension.substring(0, dimension.length - 1);

            parent = element.parent();
            while (parent.height() < 1 || parent.prop('tagName') != 'DIV') {
              parent = parent.parent();
            }
            elementHeight = Math.floor((parent.height() / 100) * dimension) + 'px';
          } else if (dimension[dimension.length - 1] == 'px') {
            elementHeight = dimension;
          }

          element.css('font-size', elementHeight);
          element.css('line-height', elementHeight);
        }
        fontHeight(); // initial invokation
      },
    };
  },
]);

/**
 * Aligns the content of a given container vertically.
 * Perfect-middle references a slighly higher alignment than that of the real middle
 * improving the visual efect of the alignment on non-all-caps texts.
 */
angular.module(MODULE).directive('perfectMiddle', [
  function () {
    return {
      link: function (_scope, element, _attributes) {
        // define as internal function in case re-invokation is needed on screen resize
        function perfectMiddle() {
          //$log.debug("Has transclusion happened? ", element.html());
          var parent = element;
          var dimensions = $.makeArray(parent.find('div'))
            .map(function (d) {
              var el = angular.element(d);
              var top = parseInt(el.offset().top);
              var height = parseInt(el.height());
              var bottom = top + height;
              return { top: top, bottom: bottom };
            })
            .reduce(function (s, c) {
              var top = s.top > c.top || !s.top ? c.top : s.top;
              var bottom = s.bottom > c.bottom ? s.bottom : c.bottom;
              return { top: top, bottom: bottom };
            }, {});
          //$log.debug("Found dimensions of wrapped content: ", dimensions);
          var all_heights = dimensions.bottom - dimensions.top;
          var container_middle = Math.floor(parseInt(parent.height()) / 2);
          var padding_top = Math.floor(container_middle - (all_heights / 10) * 6);
          parent.css('padding-top', padding_top + 'px');
        }
        setTimeout(perfectMiddle, 10); // initial invokation
      },
    };
  },
]);
