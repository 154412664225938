import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { lookupV } from '../../utils/Array';
import { trustingEntries } from '../../utils/object';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../platformContext';
import { multiassessmentAnalytis } from './analytics';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useIncludedAssessmentFilter(overrides, assessmentSettingsValidations, referencesByStack) {
    const [filter, setFilter] = useState([]);
    const overrideCount = new Set(overrides.flatMap(([, a]) => a)).size;
    const validationCount = Object.entries(assessmentSettingsValidations).filter(([_, v]) => v.length > 0).length;
    const sharingCount = Object.values(referencesByStack).filter((x) => x.length > 1).length;
    const missingRefCount = [
        ...new Set(Object.values(referencesByStack)
            .flatMap((x) => x)
            .flatMap((x) => x.missingLatest)
            .map((x) => x._assessment)),
    ].length;
    useLayoutEffect(() => setFilter((filter) => {
        if (!overrideCount && filter.includes('overrides')) {
            filter = filter.filter((f) => f !== 'overrides');
        }
        if (!validationCount && filter.includes('validations')) {
            filter = filter.filter((f) => f !== 'validations');
        }
        if (!sharingCount && filter.includes('shared')) {
            filter = filter.filter((f) => f !== 'shared');
        }
        if (!missingRefCount && filter.includes('missing-ref')) {
            filter = filter.filter((f) => f !== 'missing-ref');
        }
        return filter;
    }), [overrideCount, validationCount, sharingCount, missingRefCount]);
    const overridesToShow = filter.includes('overrides')
        ? overrides.map(([field]) => field)
        : filter
            .filter((f) => f.startsWith('override-field-'))
            .map((f) => f.slice('override-field-'.length))
            .map((f) => f);
    const overridesLookup = lookupV(overrides, ([field]) => field, ([, value]) => value);
    const validationsToShow = filter.includes('validations')
        ? trustingEntries(assessmentSettingsValidations)
            .filter(([_, v]) => v.length > 0)
            .map(([k]) => k)
        : [];
    const sharingToShow = filter.includes('shared')
        ? trustingEntries(referencesByStack)
            .filter(([, v]) => v.length > 1)
            .map(([k]) => k)
        : [];
    const missingRefToShow = filter.includes('missing-ref')
        ? [
            ...new Set(Object.values(referencesByStack)
                .flatMap((x) => x)
                .flatMap((x) => x.missingLatest)
                .map((x) => x._assessment)),
        ]
        : [];
    const assessmentsToShowCandidate = [
        ...new Set([
            ...overridesToShow.flatMap((s) => overridesLookup[s]),
            ...validationsToShow,
            ...sharingToShow,
            ...missingRefToShow,
        ]),
    ];
    const assessmentsToShow = filter.length > 0 ? assessmentsToShowCandidate : null;
    return {
        filter,
        setFilter,
        validationCount,
        overrideCount,
        sharingCount,
        missingRefCount,
        assessmentsToShow,
    };
}
export function IncludedAssessmentFilter(props) {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    return (_jsx(_Fragment, { children: _jsxs(ToggleButtonGroup, { exclusive: true, 
            // ref={anchorRef}
            // value={[...props.filter, ...(isPartialOverride ? ['overrides'] : [])]}
            value: props.filter[0] || '', onChange: (_, v) => {
                logAnalyticsEvent(multiassessmentAnalytis.filterPressed(v || 'all'));
                props.setFilter(v ? [v] : []);
            }, children: [_jsx(ToggleButton, { value: "", children: t('pages.multiassessments.all') }), _jsxs(ToggleButton, { value: "overrides", disabled: props.overrideCount === 0, children: [t('pages.multiassessments.overrides'), " (", props.overrideCount, ")"] }), _jsxs(ToggleButton, { value: "validations", disabled: props.validationCount === 0, children: [t('pages.multiassessments.errors'), " (", props.validationCount, ")"] }), _jsxs(ToggleButton, { value: "shared", disabled: props.sharingCount === 0, children: [t('pages.multiassessments.shared'), " (", props.sharingCount, ")"] }), _jsxs(ToggleButton, { value: "missing-ref", disabled: props.missingRefCount === 0, children: [t('pages.multiassessments.missingReferences'), " (", props.missingRefCount, ")"] })] }) }));
}
