import { createContext, useContext } from 'react';
import { create as createZustandStore } from 'zustand';
import { createQueryString, saveCybermonFilterState } from './FilterState';
import { applyChangeToFilter, calculateRelevance, mapFilterActionToFilteringEventTrigger, } from './FilterLogic';
const filterItems = (items, filterState) => {
    return items.filter((itm) => calculateRelevance(itm, filterState) > 0).sort((a, b) => b.relevance - a.relevance);
};
export const createCybermonFilterStore = (initialFilterState, initialItems, setSearchParams, logAnalyticsEvent) => {
    return createZustandStore((set) => ({
        filterState: initialFilterState,
        filteredItems: filterItems(initialItems, initialFilterState),
        allItems: initialItems,
        doFilterAction: (action) => {
            return set((store) => {
                logAnalyticsEvent(mapFilterActionToFilteringEventTrigger(action));
                const newState = applyChangeToFilter(store.filterState, action);
                setSearchParams(createQueryString(newState));
                saveCybermonFilterState(newState);
                // normally here we would return an object with the properties that have changed,
                // but we don't need it because the URL change will call the setFilterState function which will
                // ensure we are updated. (if we still do this, it'd be a useless render)
                return {};
            });
        },
        setFilterState: (newFilterState, items) => {
            return set(() => ({
                filteredItems: filterItems(items, newFilterState),
                filterState: newFilterState,
                allItems: items,
            }));
        },
    }));
};
export const CybermonFilterContext = createContext(null);
export const useCybermonFilterContext = () => {
    const useFilterStore = useContext(CybermonFilterContext);
    if (!useFilterStore) {
        throw new Error('useCybermonFilterContext must be used within an CybermonFilterContext');
    }
    return useFilterStore;
};
