import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLayoutEffect, useMemo, useState } from 'react';
import { Button } from '@securecodewarrior/design-system-react/lib/legacy';
import { P, match } from 'ts-pattern';
import { Idle, Pending, useApiLoad, useApiMutation } from '../Api.hooks';
import { Box, Typography, IconButton, Switch, FormGroup, FormControlLabel, Divider, Snackbar, Alert, useTheme, } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useMultiAssessmentNavigation, useMultiassessmentsApi } from './hooks';
import { AssessmentSelector } from './AssessmentSelector';
import { TopLevelSettingsEditor } from './TopLevelSettingsEditor';
import { group, groupV, lookup, intersection } from '../../utils/Array';
import { computeOverrides, computeReferences, multiAssessmentDefaults, useEditorState, } from './state';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { failed } from './Inputs';
import { filterKeys, mapValues, trustingKeys } from '../../utils/object';
import { EditMultiAssessmentPageSkeleton } from './EditMultiAssessmentPage.skeleton';
import { IncludedAssessmentFilter, useIncludedAssessmentFilter } from './IncludedAssessmentFilter';
import { useTranslation } from 'react-i18next';
import { multiassessmentAnalytis, useMonitoredCallback } from './analytics';
import { usePlatformContext } from '../platformContext';
const SavingResponseIndicator = (props) => {
    const { t } = useTranslation();
    return match(props.savingResponse)
        .with(Idle, () => match(props.creatingResponse)
        .when((x) => typeof x === 'object' && !(x instanceof Error), () => _jsx("p", { children: t('pages.multiassessments.editMultiAssessmentPage.savingResponseIndicator.created') }))
        .otherwise(() => undefined))
        .with(Pending, () => _jsx("p", { children: t('pages.multiassessments.saving') }))
        .with(P.instanceOf(Error), (error) => _jsx("p", { children: t('pages.multiassessments.error', { message: error.message }) }))
        .with({ multiassessment: { _id: P.string } }, () => _jsx("p", { children: t('pages.multiassessments.saved') }))
        .with({ multiassessment: P.any }, () => _jsx("p", { children: t('pages.multiassessments.applied') }))
        .exhaustive();
};
const CreatingResponseIndicator = (props) => {
    const { t } = useTranslation();
    return match(props.response)
        .with(Idle, () => undefined)
        .with(Pending, () => _jsx("p", { children: t('pages.multiassessments.creating') }))
        .with(P.instanceOf(Error), (error) => _jsx("p", { children: t('pages.multiassessments.error', { message: error.message }) }))
        .otherwise(() => _jsx("p", { children: t('pages.multiassessments.created') }));
};
export function EditMultiAssessmentRoute() {
    var _a, _b;
    const { id } = useParams();
    const location = useLocation();
    const record = (_a = location.state) === null || _a === void 0 ? void 0 : _a.record;
    const editingEssentials = (_b = location.state) === null || _b === void 0 ? void 0 : _b.editingEssentials;
    const { t } = useTranslation();
    if (record && editingEssentials) {
        return (_jsx(EditMultiAssessmentPage, { multiassessment: record, assessments: [], references: [], essentials: editingEssentials }));
    }
    if (!id) {
        return _jsx("div", { children: t('pages.multiassessments.noIdGiven') });
    }
    return _jsx(EditMultiAssessmentPageLoader, { id: id });
}
export const EditMultiAssessmentPageLoader = (props) => {
    const api = useMultiassessmentsApi();
    const responseEssentials = useApiLoad(api.getEditingEssentials.query, undefined);
    const response = useApiLoad(api.getMultiAssessment.query, { id: props.id });
    const { t } = useTranslation();
    return match(response)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingMultiAssessment') }))
        .with(undefined, () => _jsx("p", { children: t('pages.multiassessments.unknownMultiAssessment', { id: props.id }) }))
        .otherwise((response) => match(responseEssentials)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingEditorData') }))
        .otherwise((essentials) => (_jsx(EditMultiAssessmentPage, { multiassessment: response.multiassessment, assessments: response.assessments, references: response.references, essentials: essentials }))));
};
export const CreateMultiAssessmentPageLoader = () => {
    const api = useMultiassessmentsApi();
    const responseEssentials = useApiLoad(api.getEditingEssentials.query, undefined);
    const { t } = useTranslation();
    return match(responseEssentials)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingEditorData') }))
        .otherwise((essentials) => (_jsx(EditMultiAssessmentPage, { multiassessment: undefined, assessments: [], references: [], essentials: essentials })));
};
export const EditMultiAssessmentPage = (props) => {
    var _a;
    const theme = useTheme();
    const [mode, setMode] = useState('view');
    const state = useEditorState(props.multiassessment, props.assessments);
    const [createNewRevision, setCreateNewRevision] = useState(false);
    const expectedChanges = detectChanges(state.persistedAssessments, Object.values(state.assessmentSettingsEditBuffer));
    const assessmentByAssessment = group(state.persistedAssessments, (a) => a._assessment);
    const supersedeNeededPerAssessment = mapValues(assessmentByAssessment, (records, _assessment) => {
        const latestRecord = records.sort((a, b) => b.version - a.version)[0];
        const changedFields = (expectedChanges === null || expectedChanges === void 0 ? void 0 : expectedChanges[_assessment]) || [];
        return latestRecord.activeAttempts > 0 && intersection(changedFields, ['timeLimit', 'fixedChallenges']).length > 0;
    });
    const supersedeNeeded = Object.values(supersedeNeededPerAssessment).filter((x) => x).length;
    useLayoutEffect(() => setCreateNewRevision(false), [supersedeNeeded]);
    const overrides = useMemo(() => computeOverrides(state.editBuffer, state.assessmentSettingsEditBuffer, state.persistedAssessments), [state.assessmentSettingsEditBuffer, state.editBuffer, state.persistedAssessments]);
    const assessmentsById = lookup(state.persistedAssessments, (a) => a._id);
    const references = useMemo(() => computeReferences(state.persistedAssessments, props.references), [state.persistedAssessments, props.references]);
    const referencesByStack = useMemo(() => groupV(references
        .flatMap((r) => r.refs.assessmentIds.map((id) => [id, r]))
        .filter(([id]) => assessmentsById[id]), ([id]) => assessmentsById[id]._assessment, ([, r]) => r), [assessmentsById, references]);
    const filter = useIncludedAssessmentFilter(overrides, state.assessmentSettingsValidations, referencesByStack);
    // useLayoutEffect(() => {
    //   if (filter.some((filter) => filter.k === 'overrides' && filter.fields.some((field) => !overridesLookup[field]))) {
    //     setFilter(
    //       produce((draft) => {
    //         for (const filter of draft) {
    //           if (filter.k === 'overrides' && filter.fields.some((field) => !overridesLookup[field])) {
    //             filter.fields = filter.fields.filter((field) => overridesLookup[field]);
    //           }
    //         }
    //       })
    //     );
    //   }
    // }, [filter, overridesLookup]);
    const navigateToAssessments = () => {
        window.location.href = `${window.location.protocol}//${window.location.host}/#/assessments/list`;
    };
    const assessmentsWithErrors = filterKeys(state.assessmentSettingsValidations, (_, v) => v.length > 0);
    const { t } = useTranslation();
    const [savedFeedback, setSavedFeedback] = useState({
        k: 'applied',
        visible: false,
    });
    const startEdit = useMonitoredCallback(multiassessmentAnalytis.editPressed, () => setMode('edit'), []);
    const cancelEdit = useMonitoredCallback(multiassessmentAnalytis.cancelPressed, () => {
        setMode('view');
        state.revertChanges();
    }, []);
    const { logAnalyticsEvent } = usePlatformContext();
    const [shouldAnimateCards, setShouldAnimateCards] = useState(false);
    return (_jsxs(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            colorScheme: 'dark',
            position: 'fixed',
            top: theme.spacing(16),
            left: 0,
            right: 0,
            height: 'calc(100vh - 64px)',
        }, children: [_jsxs(Box, { sx: { height: theme.spacing(14), display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }, children: [_jsxs(IconButton, { sx: {
                            display: 'flex',
                            alignItems: 'center !important',
                            padding: theme.spacing(0, 4),
                            marginTop: theme.spacing(4),
                            fontSize: theme.typography.pxToRem(16),
                            marginLeft: theme.spacing(1),
                        }, onClick: () => {
                            var _a;
                            logAnalyticsEvent(multiassessmentAnalytis.navigateBack(((_a = props.contextKey) === null || _a === void 0 ? void 0 : _a.k) || 'program'));
                            props.onBack ? props.onBack() : navigateToAssessments();
                        }, children: [_jsx(ArrowBackIcon, { sx: { opacity: 0.7 }, fontSize: "small" }), _jsx("span", { style: { marginLeft: theme.spacing(2), opacity: 0.7 }, children: props.backText || t('pages.multiassessments.backToAssessments') })] }), _jsxs(Box, { sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: theme.spacing(4),
                            gap: theme.spacing(3),
                        }, children: [failed(state.validated) ? (_jsx(Box, { children: t('pages.multiassessments.validationErrorsInDefaultSettings') })) : Object.keys(assessmentsWithErrors).length > 0 ? (_jsx(Box, { children: t('pages.multiassessments.validationErrorsInAssessments', {
                                    count: Object.keys(assessmentsWithErrors).length,
                                }) })) : supersedeNeeded > 0 ? (_jsxs(Box, { children: [t('pages.multiassessments.supersedeNeededAssessments', { count: supersedeNeeded }), _jsx(FormGroup, { children: _jsx(FormControlLabel, { labelPlacement: "start", sx: { marginBottom: 0 }, control: _jsx(Switch, { checked: createNewRevision, onChange: (event) => {
                                                    logAnalyticsEvent(multiassessmentAnalytis.toggleVersionBump(event.target.checked));
                                                    setCreateNewRevision(event.target.checked);
                                                } }), label: t('pages.multiassessments.createNewAssessmentVersions', { count: supersedeNeeded }) }) })] })) : undefined, mode === 'view' ? (_jsxs(_Fragment, { children: [!props.userHasEditAccess && _jsx(Box, { children: t('pages.multiassessments.editAccessMissing') }), _jsx(Button, { disabled: !props.userHasEditAccess, onClick: startEdit, children: t('pages.multiassessments.edit') })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: cancelEdit, variant: "outlined", children: t('pages.multiassessments.cancel') }), _jsx(CreateApplyOrSave, { multiassessment: props.multiassessment, allowVersionBump: createNewRevision, disabled: !!supersedeNeeded && !createNewRevision, state: state, essentials: props.essentials, contextKey: props.contextKey, onSaved: (k) => {
                                            setMode('view');
                                            setSavedFeedback({ k, visible: true });
                                        } })] }))] })] }), _jsx(Snackbar, { open: savedFeedback.visible, onClose: () => setSavedFeedback((x) => (Object.assign(Object.assign({}, x), { visible: false }))), autoHideDuration: 6000, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, children: _jsx(Alert, { onClose: () => setSavedFeedback((x) => (Object.assign(Object.assign({}, x), { visible: false }))), severity: "success", sx: { width: '100%' }, children: match(savedFeedback.k)
                        .with('saved', () => t(`pages.multiassessments.saved`))
                        .with('applied', () => t(`pages.multiassessments.applied`))
                        .exhaustive() }) }), _jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: theme.spacing(0, 4, 2, 6),
                }, children: _jsxs(Box, { sx: { flex: 1, display: 'block', marginTop: 0, paddingBottom: theme.spacing(1.5) }, children: [!!props.subheader && (_jsx(Typography, { variant: "h1", sx: { fontSize: theme.typography.pxToRem(28), fontWeight: 600, letterSpacing: 0.1 }, children: props.subheader })), _jsx(Typography, { variant: "h2", sx: { fontSize: theme.typography.pxToRem(16), fontWeight: 500, opacity: 0.7 }, children: props.header || t('pages.multiassessments.multiAssessment') })] }) }), _jsx(Divider, { sx: { marginBottom: 0 } }), _jsxs(Box, { sx: {
                    display: 'grid',
                    gridTemplateColumns: '380px 1fr',
                    gap: theme.spacing(5),
                    flexGrow: 1,
                    minHeight: '1px',
                }, children: [_jsx(Box, { sx: { overflowY: 'auto', paddingBottom: theme.spacing(8), paddingTop: theme.spacing(4) }, children: _jsx(TopLevelSettingsEditor, { mode: mode, editBuffer: state.editBuffer, updateSettings: state.updateSettings, essentials: props.essentials, timeZonesInUse: state.persistedAssessments.map((a) => a.assessmentSettings.timezone) }) }), _jsxs(Box, { sx: { overflowY: 'auto', paddingBottom: theme.spacing(3), paddingTop: theme.spacing(4) }, children: [_jsx(Typography, { variant: "h5", sx: { marginBottom: theme.spacing(6) }, children: t('pages.multiassessments.assessmentsCount', { count: state.editBuffer.assessments.length }) }), _jsxs(Box, { sx: { marginBottom: theme.spacing(5), display: 'flex', alignItems: 'center' }, children: [_jsx(Typography, { sx: { fontSize: theme.typography.pxToRem(16), marginRight: theme.spacing(3), fontWeight: 600 }, children: t('pages.multiassessments.filters') }), _jsx(IncludedAssessmentFilter, Object.assign({}, filter, { setFilter: (filterItems) => {
                                            setShouldAnimateCards(false);
                                            filter.setFilter(filterItems);
                                        } }))] }), _jsx(AssessmentSelector, { availableAssessments: state.persistedAssessments, mode: mode, timeLimitIsPerChallenge: state.editBuffer.timeLimitIsPerChallenge, filter: filter.assessmentsToShow, shouldAnimateCards: shouldAnimateCards, selectedAssessmentIds: ((_a = state.editBuffer.assessments) === null || _a === void 0 ? void 0 : _a.map((a) => a._assessment)) || [], defaultSettings: state.commonSettings.assessmentSettings, perAssessmentSettings: mapValues(state.assessmentSettingsEditBuffer, (editBuffer) => {
                                    var _a;
                                    return ({
                                        editBuffer,
                                        persisted: assessmentsById[editBuffer._id],
                                        references: ((_a = referencesByStack[editBuffer._assessment]) === null || _a === void 0 ? void 0 : _a.map((r) => (Object.assign(Object.assign({}, r), { missingLatest: r.missingLatest.filter((m) => m._assessment === editBuffer._assessment), isContextReference: !!props.contextKey && isSameContext(r.refs, props.contextKey) })))) || [],
                                    });
                                }), overrides: overrides, 
                                // addAssessment={state.addAssessment}
                                // removeAssessment={state.removeAssessment}
                                overrideProperty: state.overrideProperty, resetProperty: ({ _assessment }, property, value) => {
                                    setShouldAnimateCards(true);
                                    state.resetProperty({ _assessment }, property, value);
                                }, assessmentSettingsValidations: state.assessmentSettingsValidations, essentials: props.essentials, readOnlyAssessmentIds: props.readOnlyAssessmentIds || [] })] })] })] }));
};
function detectChanges(assessments, assessmentSettings) {
    const originalGroups = group(assessments, (a) => a._assessment);
    const updatedRecords = lookup(assessmentSettings, (a) => a._assessment);
    const originalKeys = Object.keys(originalGroups);
    const updatedKeys = Object.keys(updatedRecords);
    const missingUpdates = originalKeys.filter((k) => !updatedKeys.includes(k));
    if (missingUpdates.length > 0) {
        throw new Error(`Missing updates for ${missingUpdates.join(', ')}`);
    }
    const missingOriginal = updatedKeys.filter((k) => !originalKeys.includes(k));
    if (missingOriginal.length > 0) {
        throw new Error(`Missing original for ${missingOriginal.join(', ')}`);
    }
    const changes = {};
    for (const key of originalKeys) {
        const originalRecord = originalGroups[key].sort((a, b) => b.version - a.version)[0];
        const updatedRecord = updatedRecords[key];
        const fields = trustingKeys(originalRecord.assessmentSettings);
        for (const field of fields) {
            if (originalRecord.assessmentSettings[field] !== updatedRecord.assessmentSettings[field]) {
                changes[key] || (changes[key] = []);
                changes[key].push(field);
            }
        }
    }
    return changes;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
function CreateApplyOrSave(props) {
    var _a;
    const api = useMultiassessmentsApi();
    const navigate = useMultiAssessmentNavigation();
    const { t } = useTranslation();
    const noop = () => { };
    const isBulkEdit = props.multiassessment && 'bulkEdit' in props.multiassessment;
    const saveAssessment = useApiMutation(api.saveMultiAssessment.mutate, (saved) => {
        var _a;
        if (!saved) {
            throw new Error(t('pages.multiassessments.editMultiAssessmentPage.createApplyOrSave.saveFailed'));
        }
        props.state.setPersistedRecord(saved.multiassessment);
        props.state.setPersistedAssessments(saved.assessments);
        (_a = props.onSaved) === null || _a === void 0 ? void 0 : _a.call(props, 'saved');
    });
    const applyBulkEdit = useApiMutation(api.bulkEdit.apply.mutate, (applied) => {
        var _a;
        if (!applied) {
            throw new Error(t('pages.multiassessments.editMultiAssessmentPage.createApplyOrSave.applyFailed'));
        }
        props.state.setPersistedRecord(Object.assign(Object.assign({}, props.multiassessment), applied.multiassessment));
        props.state.setPersistedAssessments(applied.assessments);
        (_a = props.onSaved) === null || _a === void 0 ? void 0 : _a.call(props, 'applied');
    });
    const candidateForSave = useMemo(() => failed(props.state.validated) || Object.values(props.state.assessmentSettingsValidations).flat().length > 0
        ? undefined
        : {
            multiassessment: Object.assign(Object.assign(Object.assign({}, multiAssessmentDefaults), props.multiassessment), props.state.validated),
            assessments: Object.values(props.state.assessmentSettingsEditBuffer),
            allowVersionBump: props.allowVersionBump,
        }, [
        props.allowVersionBump,
        props.multiassessment,
        props.state.assessmentSettingsEditBuffer,
        props.state.assessmentSettingsValidations,
        props.state.validated,
    ]);
    const applyChanges = useMonitoredCallback(multiassessmentAnalytis.applyPressed(props.allowVersionBump), () => {
        var _a, _b, _c, _d, _e, _f;
        if (candidateForSave) {
            applyBulkEdit.mutate(Object.assign({ assessments: candidateForSave.assessments, allowVersionBump: props.allowVersionBump, assessmentRecordsMd5: ((_a = props.state.persistedRecord) === null || _a === void 0 ? void 0 : _a.assessmentRecordsMd5) || candidateForSave.multiassessment.assessmentRecordsMd5 }, (((_b = props.contextKey) === null || _b === void 0 ? void 0 : _b.k) === 'course'
                ? { courseId: (_c = props.contextKey) === null || _c === void 0 ? void 0 : _c.courseId }
                : ((_d = props.contextKey) === null || _d === void 0 ? void 0 : _d.k) === 'program'
                    ? { programId: (_e = props.contextKey) === null || _e === void 0 ? void 0 : _e.programId, programStageId: (_f = props.contextKey) === null || _f === void 0 ? void 0 : _f.stageId }
                    : {})));
        }
    }, [applyBulkEdit.mutate, candidateForSave, props.allowVersionBump]);
    const createAssessment = useApiMutation(api.createMultiAssessment.mutate, (created) => {
        props.state.setPersistedRecord(created);
        navigate.editPage(created, props.essentials);
    });
    const isBusy = saveAssessment.response === Pending || createAssessment.response === Pending || applyBulkEdit.response === Pending;
    return isBulkEdit ? (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: applyChanges, disabled: isBusy || !candidateForSave || props.disabled, children: t('pages.multiassessments.apply') }), _jsx(SavingResponseIndicator, { savingResponse: applyBulkEdit.response, creatingResponse: createAssessment.response })] })) : ((_a = props.state.persistedRecord) === null || _a === void 0 ? void 0 : _a._id) ? (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => {
                    if (candidateForSave) {
                        saveAssessment.mutate(candidateForSave);
                    }
                }, disabled: isBusy || !candidateForSave || props.disabled, children: t('pages.multiassessments.save') }), _jsx(SavingResponseIndicator, { savingResponse: saveAssessment.response, creatingResponse: createAssessment.response })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => (candidateForSave ? createAssessment.mutate(candidateForSave) : noop), disabled: isBusy || !candidateForSave || props.disabled, children: t('pages.multiassessments.create') }), _jsx(CreatingResponseIndicator, { response: createAssessment.response })] }));
}
const isSameContext = (a, b) => match([a, b])
    .with([{ k: 'course' }, { k: 'course' }], ([a, b]) => a.courseId === b.courseId)
    .with([{ k: 'program' }, { k: 'program' }], ([a, b]) => a.programId === b.programId && a.stageId === b.stageId)
    .otherwise(() => false);
