import angular from 'angular';

const app = angular.module('SCWFileInput', []);
export default app.name;

app.directive('fileread', [
  function () {
    return {
      scope: {
        fileread: '=',
      },
      link: function (scope, element, _attributes) {
        element.bind('change', function (changeEvent) {
          scope.$apply(function () {
            scope.fileread = changeEvent.target.files[0];
          });
        });
      },
    };
  },
]);
