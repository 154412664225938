import angular from 'angular';
import MODULE from './module';

(function (moduleName) {
  var smart_navigationEnabled = false;

  /**
   * Execute :thenFn only if navigation is enabled.
   * @param {Function} thenFn
   * @returns Function generated conditional function
   */
  function ifSmartNavigationIsEnabled(thenFn) {
    return function () {
      // implicit browser's function call stack :arguments
      if (!smart_navigationEnabled) return true;
      return thenFn.apply(this, arguments);
    };
  }

  angular
    .module(moduleName)
    .run([
      '$rootScope',
      function ($rootScope) {
        $rootScope.$on('user-preferences:updated', function (_event, preferences) {
          var newState = _.get(preferences, 'accessibility.keyboardNavigation');
          if (smart_navigationEnabled !== newState) setActive(newState);
        });

        $rootScope.$on('user-preferences:loaded', function (_event, preferences) {
          setActive(_.get(preferences, 'accessibility.keyboardNavigation'));
        });

        function setActive(enable) {
          smart_navigationEnabled = enable;
        }
      },
    ])
    .directive('smartKeys', [
      '$window',
      function ($window) {
        return {
          restrict: 'A',
          // transclude: true,
          // template: "<ng-transclude></ng-transclude>",
          priority: 10,
          link: function (scope, element, attrs) {
            var keyStatus = {};
            var registeredActions = [];

            var keyAliases = {
              right: 'arrowright',
              left: 'arrowleft',
              up: 'arrowup',
              down: 'arrowdown',
              esc: 'escape',
              dash: '-',
              hypen: '-',
              minus: '-',
              plus: '+',
              equals: '=',
              underscore: '_',
              tilde: '`',
              question: '?',
              '¿': '?', // IE11
              '/': '?', // OSX
            };

            // Begin: block all browser behavior
            $($window).on('keyup', ifSmartNavigationIsEnabled(deactivateKey));
            // End: block all browser behavior

            // Begin: key monitoring
            element.on('keydown', ifSmartNavigationIsEnabled(activateKey));
            element.on('keyup', ifSmartNavigationIsEnabled(deactivateKey));
            $($window).on('blur', ifSmartNavigationIsEnabled(deactivateAll));
            // End: key monitoring

            init(); // register actions

            function init() {
              // $log.debug("[smart-keys] configuring for ", element);
              _.chain(attrs)
                .keys()
                .forEach(function (key) {
                  // $log.debug("[smart-keys] checking binding", key);
                  if (key.indexOf('when') === 0) register(key);
                })
                .value();
            }

            function register(attr) {
              var keys = attr
                .replace(/[A-Z?]/g, function toDashCase(x) {
                  return '-' + x.toLowerCase();
                })
                .substring(5)
                .split('-');

              keys = _.map(keys, function (key) {
                return (key && keyAliases[key]) || key;
              });
              // $log.debug("[smart-keys] registering ", attr, keys);

              registeredActions.push({
                keys: keys,
                attr: attr,
                fn: function () {
                  return scope.$eval(attrs[attr]);
                },
              });
            }

            function executeIfActive(event) {
              // find active combinations
              var activeKeys = _.chain(keyStatus)
                .mapValues(function (val, key) {
                  return val && key;
                })
                .filter(function (key) {
                  return !!key;
                })
                .value();

              // $log.debug("[smart-keys] checking for active combinations with ",
              // 	activeKeys,
              // 	_.chain(keyStatus)
              // 		.keys()
              // 		.filter(function(e) { return keyStatus[e]; })
              // 		.value(),
              // 	registeredActions
              // );

              // Begin: don't interrupt writing on text inputs
              var textInputs = ['INPUT', 'TEXTAREA'];
              if (~textInputs.indexOf(event.target.tagName)) {
                if (activeKeys.length < 2 && element[0] !== event.target) return false;
              }
              // End: don't interrupt writing on text inputs

              // execute first
              var executed = _.find(registeredActions, function (entry) {
                var diff_f = _.difference(entry.keys, activeKeys);
                var diff_b = _.difference(activeKeys, entry.keys);
                return !diff_f.length && !diff_b.length;
              });

              // $log.debug("[smart-keys] found executable action", executed);
              if (executed) {
                stopPropagation(event);
                executed.code = attrs[executed.attr];
                scope.$apply(function () {
                  executed.fn();
                });

                // $log.debug("[smart-keys] executed hotkey binding on ", element, executed);
              }

              return executed;
            }

            function activateKey(event) {
              var key = event && event.key && event.key.toLowerCase();

              // IE11 magic trick
              if (key === 'unidentified') key = String.fromCharCode(event.keyCode);

              key = keyAliases[key] || key;
              keyStatus[key] = true;
              return !executeIfActive(event); // stop if executed
            }

            function deactivateKey(event) {
              var key = event && event.key && event.key.toLowerCase();

              // IE11 magic trick
              if (key === 'unidentified') key = String.fromCharCode(event.keyCode);

              key = keyAliases[key] || key;
              keyStatus[key] = false;
              return true;
            }

            function deactivateAll(_event) {
              keyStatus = {};
              return true;
            }

            function stopPropagation(event) {
              if (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                event.returnValue = false;
                event.cancelBubble = true;
                event.key = false;
                event.keyCode = false;
              }
            }
          },
        };
      },
    ]);
})(MODULE);
