import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.factory('SSOSAMLRelayStateService', [
  '$window',
  function ($window) {
    return {
      redirect: async (relayState) => {
        $window.location.hash = `#${relayState}`;
      },
    };
  },
]);
