import { getDaysDelta } from '../utils';
export const listGoalAnalytics = (sendEvent) => ({
    onCreateNewClicked: (currentGoalCount, centerButton) => {
        sendEvent({ event: 'Create New Goal Clicked', scope: 'goals-admin-overview', currentGoalCount, centerButton });
    },
    onViewGoalClicked: (goal) => {
        var _a;
        const startDateDeltaInDays = getDaysDelta(goal.startDate, new Date());
        const endDateDeltaInDays = getDaysDelta(goal.endDate, new Date());
        sendEvent({
            event: 'View Goal Clicked',
            scope: 'goals-admin-overview',
            goalStatus: goal.status,
            completionPercentage: (_a = goal.completionRate) !== null && _a !== void 0 ? _a : 0,
            participantCount: Number(goal.participantCount),
            startDateDeltaInDays,
            endDateDeltaInDays,
        });
    },
    onEditDraftClicked: (draft) => {
        const draftAgeInDays = getDaysDelta(new Date(), draft.createdAt);
        sendEvent({
            event: 'Edit Draft Clicked',
            scope: 'goals-admin-overview',
            draftAgeInDays,
        });
    },
    onGoalsListTabFiltered: (filterStatus, resultCount, totalCount) => {
        sendEvent({
            event: 'Goals List Tab Filtering',
            scope: 'goals-admin-overview',
            filterStatus,
            resultCount,
            totalCount,
        });
    },
});
