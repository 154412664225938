import { Box, styled } from '@mui/material';
export const HealthBarSegment = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'completed',
})(({ theme }) => ({
    width: 60,
    height: 8,
    borderRadius: theme.shape.borderRadius,
    display: 'grid',
    placeContent: 'center',
}), ({ completed, theme }) => ({
    backgroundColor: completed ? theme.palette.text.status.success : theme.palette.badge.default.border,
}));
