var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Footer } from '@securecodewarrior/design-system-react/lib/legacy';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
export default function PlatformFooter(props) {
    // eslint-disable-next-line react/prop-types
    const { isHidden, hasCookiePolicy } = props, rest = __rest(props, ["isHidden", "hasCookiePolicy"]);
    const [t] = useTranslation();
    return (_jsxs(Footer, Object.assign({}, rest, { className: isHidden ? 'hidden' : '', children: [hasCookiePolicy ? (_jsx(Link, { href: cookiePolicyHref, target: "_blank", rel: "noopener", children: cookiePolicyText(t) })) : null, hasCookiePolicy ? (_jsx(Link, { onClick: openCookiePreferences, sx: { position: 'static' }, children: cookiePreferencesText(t) })) : null] })));
}
export const cookiePolicyText = (t) => {
    return t('portalFrontend.components.footer.cookiePolicy');
};
export const cookiePolicyHref = 'https://www.securecodewarrior.com/trust/cookie-policy';
export const cookiePreferencesText = (t) => {
    return t('portalFrontend.components.footer.cookiePreferences');
};
export const openCookiePreferences = () => {
    if (window.Osano) {
        window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
};
