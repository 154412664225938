import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, Button, Typography } from '@mui/material';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';
export const TextButton = styled(Button)(({ theme }) => ({
    background: 'none',
    border: 'none',
    padding: 0,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    ':focus': { outline: `1px solid ${theme.palette.clickable.default}` },
}));
export const RemoveTextButton = ({ label, onClick }) => {
    return (_jsxs(TextButton, { onClick: onClick, children: [_jsx(CloseIcon, { fontSize: "small" }), _jsx(Typography, { sx: (theme) => ({
                    textDecoration: 'underline',
                    marginLeft: theme.typography.pxToRem(4),
                    color: theme.palette.clickable.default,
                    textTransform: 'lowercase',
                }), children: _jsx(Trans, { i18nKey: label }) })] }));
};
