import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.constant('SENSEI_AUTH_RESULT', {
  success: 200,
  invalid_token: 400,
  token_expired: 410,
});

app.controller('SenseiAuthController', [
  '$scope',
  '$state',
  '$stateParams',
  'AuthService',
  'SenseiApiService',
  '$window',
  'SENSEI_AUTH_RESULT',
  function ($scope, $state, $stateParams, AuthService, SenseiApiService, $window, SENSEI_AUTH_RESULT) {
    // Variables & scoped
    $scope.authenticateResult = 0;

    $scope.isSuccess = function () {
      return $scope.authenticateResult === SENSEI_AUTH_RESULT.success;
    };
    $scope.isInvalidToken = function () {
      return $scope.authenticateResult === SENSEI_AUTH_RESULT.invalid_token;
    };
    $scope.isTokenExpired = function () {
      return $scope.authenticateResult === SENSEI_AUTH_RESULT.token_expired;
    };
    $scope.removeSenseiAuthToken = function () {
      $window.sessionStorage.removeItem('senseiAuthToken');
    };

    // Store the Sensei supplied token temporarily in the browser.
    if ($stateParams.token) {
      $window.sessionStorage.setItem('senseiAuthToken', $stateParams.token);
    }

    // Check if the user is logged in.
    if (!$scope.Session.user) {
      $scope.Session.destroy();
      $state.go('login');
      return;
    }

    // Check if you have access to sensei.
    if (!AuthService.isFeatureEnabled('sensei')) {
      $scope.authenticateResult = SENSEI_AUTH_RESULT.invalid_token;
      $scope.removeSenseiAuthToken();
      return;
    }

    // Display error message and halt the request when no token is specified.
    if (!$window.sessionStorage.getItem('senseiAuthToken')) {
      $scope.authenticateResult = SENSEI_AUTH_RESULT.invalid_token;
      return false;
    }

    // Make request to backend to validate the senseiToken
    var token = $window.sessionStorage.getItem('senseiAuthToken');
    var userId = $scope.Session.user._id;

    SenseiApiService.authenticate(token, userId)
      .then(function (res) {
        $scope.authenticateResult = res;
      })
      .catch(function (err) {
        $scope.authenticateResult = err.status;
      })
      .finally(function () {
        if ($scope.isInvalidToken()) {
          $scope.removeSenseiAuthToken();
        }
      });

    // Delete the sessionStorage item since its not required.
    $scope.removeSenseiAuthToken();
  },
]);
