import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApiClient } from '../ApiContext';
import { useEffect, useMemo, useState } from 'react';
import { Dialog } from '@mui/material';
import { LanguageSelection } from '../../common/components/organisms/LanguageSelector/LanguageSelection';
import { QuestView } from './Quest';
import { QuestSkeleton } from './QuestSkeleton';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { getCategoryName } from '../utils/mapper';
import { usePlatformContext } from '../platformContext';
import { questAnalytics } from './QuestAnalytics';
import { languageSelectionAnalytics } from '../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { EmptyQuest } from './EmptyQuest';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const questsToTranslatedCategories = (quests, metadata) => {
    const translatedCategories = {};
    for (const quest of Object.values(quests)) {
        for (const chapter of quest.chapters) {
            for (const topic of chapter.topics) {
                if (!translatedCategories[topic.category]) {
                    translatedCategories[topic.category] = getCategoryName(topic.category, metadata);
                }
            }
        }
    }
    return translatedCategories;
};
export const QuestsWrapper = (props) => {
    const { trpc, handleErrorPromise } = useApiClient();
    const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
    const { metadata, setTranslatedCategories, logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const languageAnalytics = useMemo(() => languageSelectionAnalytics('quests', logAnalyticsEvent), [logAnalyticsEvent]);
    const [requestStatus, setRequestStatus] = useState({ status: 'loading' });
    const [langSelectData, setLangSelectData] = useState();
    useEffect(() => {
        if (requestStatus.status !== 'loading')
            return;
        handleErrorPromise(Promise.all([trpc.quests.homePageData.query(), trpc.quests.languageSelection.query()]), ([{ quests }, langData]) => {
            setTranslatedCategories((prev) => (Object.assign(Object.assign({}, prev), questsToTranslatedCategories(quests, metadata))));
            setRequestStatus({ status: 'success', quests });
            setLangSelectData(langData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleErrorPromise, metadata, requestStatus, setTranslatedCategories]);
    return (_jsxs(_Fragment, { children: [requestStatus.status === 'loading' || requestStatus.status === 'showSkeleton' ? (_jsx(QuestSkeleton, {})) : requestStatus.status === 'success' ? (Object.keys(requestStatus.quests).length > 0 ? (_jsx(QuestView, { quests: requestStatus.quests, openLanguageSelection: () => {
                    analytics.onEditLanguageClicked();
                    setLanguageDialogOpen(true);
                } })) : (_jsx(EmptyQuest, { onSelectLanguage: () => {
                    analytics.onFirstLanguageSelection();
                    setLanguageDialogOpen(true);
                } }))) : (assertNever(requestStatus.status, 'invalid state')), _jsx(Dialog, { open: languageDialogOpen, fullWidth: true, maxWidth: 'xl', scroll: 'body', onClose: () => {
                    setLanguageDialogOpen(false);
                    languageAnalytics.onCancelled();
                }, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        backgroundColor: theme.palette.container.fill.card1,
                        backgroundImage: 'unset',
                        borderRadius: 3,
                    },
                }), children: langSelectData && (_jsx(LanguageSelection, { collections: langSelectData.availableLanguages, initiallySelectedLanguages: langSelectData.selectedLanguages, onCategoryToggled: languageAnalytics.onCategoryToggled, onSearchReset: languageAnalytics.onSearchReset, onSearchUsed: languageAnalytics.onSearchUsed, onBack: {
                        labelKey: 'common.labels.cancel',
                        action: () => {
                            setLanguageDialogOpen(false);
                            languageAnalytics.onCancelled();
                        },
                    }, onSubmit: {
                        labelKey: 'components.organisms.languageSelection.viewQuest',
                        action: (langs) => {
                            setLanguageDialogOpen(false);
                            languageAnalytics.onSubmit(langs);
                            setRequestStatus({ status: 'showSkeleton' });
                            handleErrorPromise(trpc.quests.userLanguages.mutate(langs), () => {
                                setRequestStatus({ status: 'loading' });
                                props.onLanguageSelected();
                            });
                        },
                    }, required: true, searchPlaceholderKey: 'components.organisms.languageSelection.placeholder', subtitleKey: 'components.organisms.languageSelection.subTitle', titleKey: 'components.organisms.languageSelection.title' })) })] }));
};
