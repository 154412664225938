import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('PlatformLanguagesService', [
  '$log',
  '$http',
  '$window',
  'HttpConfigService',
  function ($log, $http, $window, HttpConfigService) {
    return {
      addStats,
      remote,
      remoteVulnerability,
    };

    function addStats(languages) {
      return remote().then(function (stats) {
        for (let _language in languages) {
          for (let _framework in languages[_language].framework) {
            // The stats returned for the languages are added to the languages in the metadata giving rise to a few extra properties
            const found = stats.languages.find((languageStats) => {
              return languageStats.id._id === _language && languageStats.id._framework === _framework;
            });

            if (found) {
              languages[_language].framework[_framework].isPoc = found.isPoc;
              languages[_language].framework[_framework].isAvailable = 'available' === found.gameAvailability['013'];
            }
          }
        }
        return languages;
      });
    }

    function remote() {
      return $http
        .get($window.SCW_ENV.ApiEndpoint + '/public/stats', HttpConfigService.getHttpConfigNoIFR())
        .then((response) => {
          $log.debug('Success: ', response);
          return response.data;
        });
    }

    function remoteVulnerability(category, subcategory) {
      return $http
        .get(
          $window.SCW_ENV.ApiEndpoint + '/public/stats/' + category + '/' + subcategory,
          HttpConfigService.getHttpConfigNoIFR()
        )
        .then((response) => {
          $log.debug('Success: ', response);
          return response.data;
        });
    }
  },
]);
