import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { PlayArrowRounded, PauseRounded, CheckRounded } from '@mui/icons-material';
import { statusTranslations, possibleActivityTypeOptions } from '../../explore/FilterLogic';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../common/Tooltip';
import { CybermonPointsIcon } from './CybermonPoints';
import { ActivityCardContainer } from '../styled';
const actionTranslationsMap = {
    in_progress: 'pages.explore.actions.inprogress',
    completed: 'pages.explore.actions.completed',
    not_started: 'pages.explore.actions.notstarted',
};
const iconMap = {
    not_started: PlayArrowRounded,
    in_progress: PauseRounded,
    completed: CheckRounded,
};
const LivesBadge = ({ type }) => {
    const { t } = useTranslation();
    const pointsTextKey = type === 'coding_lab' ? 'pages.cybermon.double' : 'pages.cybermon.single';
    return (_jsx("span", { className: "cl-badge default", children: _jsxs("div", { className: "text-with-icon", style: { display: 'flex', alignItems: 'center' }, children: [_jsx(CybermonPointsIcon, { type: type, width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }), _jsx("span", { children: t(pointsTextKey) })] }) }));
};
const LanguageBadge = (props) => {
    const language = props.type === 'video' ? undefined : props.language;
    if (!language)
        return null;
    return (_jsx("span", { className: "cl-badge default", children: _jsxs("div", { className: "text-with-icon", children: [_jsx("i", { className: language.iconClassName }), _jsx("span", { children: language.label })] }) }));
};
function ActivityContentCard(props) {
    const { t } = useTranslation();
    const StatusIcon = iconMap[props.status];
    const activity = possibleActivityTypeOptions[props.type];
    const status = statusTranslations[props.status];
    const handleClick = () => {
        var _a;
        const selection = (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString();
        if (selection)
            return;
        props.onClick();
    };
    // const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //   if (e.key === 'Enter' || e.key === 'Space') props.onClick();
    // };
    return (_jsxs(ActivityCardContainer, { className: "card-container", onClick: handleClick, "aria-label": t(actionTranslationsMap[props.status], { title: props.title, type: t(activity.label) }), children: [_jsxs("div", { className: "card-info", children: [_jsx("div", { className: "activity-type-info", children: _jsxs("span", { className: "text-with-icon", children: [activity.icon, t(activity.label)] }) }), _jsx("div", { className: "play-button", children: _jsx(Tooltip, { title: t(status), arrow: true, children: _jsx("div", { className: `play-circle ${props.status}`, children: _jsx(StatusIcon, { "aria-label": t(status), "aria-hidden": false }) }) }) })] }), _jsxs("div", { className: "content", children: [_jsxs("div", { className: "content-text", children: [_jsx("h3", { className: "card-title", children: props.title }), props.description && _jsx("p", { children: props.description })] }), _jsxs("div", { className: "badges", children: [_jsx(LivesBadge, { type: props.type }), _jsx(LanguageBadge, Object.assign({}, props)), _jsx("span", { className: "cl-badge default", children: props.category.name })] })] })] }));
}
export const ActivityCard = memo(ActivityContentCard);
