import { USER_ROLES } from '../auth/constants';

export const getRetriesAllowed = (assessment) =>
  assessment.maxRetries
    ? { kind: 'limited', amount: assessment.maxRetries }
    : { kind: assessment.retriesAllowed ? 'unlimited' : 'disabled' };

export const getLatestAttempt = (assessment) => {
  return assessment.attempts.pending?.[0] ?? assessment.attempts.completed?.[0];
};

export const mapDesignSystemUser = (user) => {
  if (user.roles.includes(USER_ROLES.admin))
    return {
      role: USER_ROLES.admin,
    };

  if (user.roles.includes(USER_ROLES.companyAdmin))
    return {
      role: USER_ROLES.companyAdmin,
    };

  if (user.roles.includes(USER_ROLES.manager))
    return {
      role: USER_ROLES.manager,
    };

  return {
    role: USER_ROLES.player,
  };
};

export const assessmentStatusMap = {
  closed: 'closed',
  disabled: 'superseded',
  enabled: 'active',
};
