import angular from 'angular';
import MODULE from './module';

export default MODULE;

(function () {
  var app = angular.module(MODULE);

  app.factory('GameApiService', [
    '$log',
    '$http',
    '$rootScope',
    '$window',
    '$translate',
    'HttpConfigService',
    function ($log, $http, $rootScope, $window, $translate, HttpConfigService) {
      var GameApiService = {};
      const { SCW_ENV } = $window;

      GameApiService.currentChallenge = null;
      GameApiService.currentSolutions = [];

      /*** Game Profile ***/

      GameApiService.updateGameProfile = function (gameProfile) {
        $log.debug('GameApiService - updateGameProfile()', gameProfile);
        return $http
          .post(SCW_ENV.ApiEndpoint + '/game/' + $rootScope.gameId, gameProfile, HttpConfigService.getHttpConfig())
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          });
      };

      GameApiService.postCreateSingleChallengePlayerSession = function (appId, cId, cbl, data) {
        const config = HttpConfigService.getHttpConfig();
        const url = `${SCW_ENV.ApiEndpoint}/game/cms/app/${appId}/challenge/${cId}/cbl/CBL_${cbl}`;
        return $http.post(url, data, config).then((response) => response.data);
      };

      GameApiService.skipChallenge = function (appId) {
        $log.debug('GameApiService - skipChallenge(' + appId + ') ');
        return $http
          .post(
            SCW_ENV.ApiEndpoint + '/game/' + $rootScope.gameId + '/app/' + appId + '/challenge/skip',
            {},
            HttpConfigService.getHttpConfig()
          )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          });
      };

      /*** Player Stats and Leaderboard ***/
      GameApiService.getLeaderboard = function () {
        $log.debug('GameApiService - getLeaderboard()');
        return $http
          .get(SCW_ENV.ApiEndpoint + '/game/' + $rootScope.gameId + '/leaderboard', HttpConfigService.getHttpConfig())
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          });
      };

      GameApiService.addLoading = function () {
        $rootScope.currentLoadingMessage = $translate.instant(
          'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
        );
        $rootScope.loading++;
      };

      GameApiService.removeLoading = function () {
        $rootScope.loading--;
        if ($rootScope.loading < 0) $rootScope.loading = 0;
      };

      // temporary for demo
      GameApiService.resetUserState = function () {
        $log.debug('GameApiService - resetUserState()');
        return $http.post(SCW_ENV.ApiEndpoint + '/auth/resetUser', {}, HttpConfigService.getHttpConfig());
      };

      GameApiService.updateTutorialStatus = function (status) {
        $log.debug('GameApiService - updateTutorialStatus() ', status);
        return $http
          .post(SCW_ENV.ApiEndpoint + '/auth/tutorial', status, HttpConfigService.getHttpConfig())
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          });
      };

      return GameApiService;
    },
  ]);

  app.run([
    '$rootScope',
    'GameApiService',
    function ($rootScope, GameApiService) {
      $rootScope.loading = 0;

      /*
      LoadingMessages array length in the translations json file
     */
      $rootScope.loadingMessagesLength = 38;

      $rootScope.resetDemoUserState = function () {
        GameApiService.resetUserState().then(function () {
          // use cached
          document.location.reload(false);
        });
      };
    },
  ]);

  app.factory('HttpConfigService', [
    '$q',
    '$rootScope',
    'SessionId',
    'SESSION_HEADER',
    function ($q, $rootScope, SessionId, SESSION_HEADER) {
      var HttpConfigService = {};
      var TIMEOUT = 5000;

      HttpConfigService.getHttpConfig = function () {
        var canceller = $q.defer();
        var httpConfig = {};

        httpConfig.headers = {};
        httpConfig.headers[SESSION_HEADER] = SessionId.get();
        httpConfig.timeout = canceller.promise;
        httpConfig.canceller = canceller;
        $rootScope.ifr.push(httpConfig);
        return httpConfig;
      };

      HttpConfigService.getHttpConfigNoSessionHeader = function () {
        var canceller = $q.defer();
        var httpConfig = {};

        httpConfig.headers = {};
        httpConfig.timeout = canceller.promise;
        httpConfig.canceller = canceller;
        $rootScope.ifr.push(httpConfig);
        return httpConfig;
      };

      HttpConfigService.getHttpConfigWithCaching = function () {
        var canceller = $q.defer();
        var httpConfig = {};

        httpConfig.headers = {};
        httpConfig.headers[SESSION_HEADER] = SessionId.get();
        httpConfig.cache = true;
        httpConfig.timeout = canceller.promise;
        httpConfig.canceller = canceller;
        $rootScope.ifr.push(httpConfig);
        return httpConfig;
      };

      HttpConfigService.getHttpConfigNoIFR = function () {
        var httpConfig = {};

        httpConfig.headers = {};
        httpConfig.headers[SESSION_HEADER] = SessionId.get();
        httpConfig.timeout = TIMEOUT;
        return httpConfig;
      };

      return HttpConfigService;
    },
  ]);
})();
