import angular from 'angular';
import moment from 'moment-timezone';
import AvsAnSimple from '../../vendor/js/AvsAn-simple';
import MODULE from './module';
import * as _ from 'lodash';

(function () {
  // @aambertin polyfill for msie incompatibility with the new Event dispatching WebAPI.
  function dispatchEvent(evt) {
    if (typeof evt === 'string') {
      // new Event object API -> OK
      if (Event.apply) {
        evt = new Event(evt);
      }
      // deprecated way -> required to work on MSIE
      else {
        var evttype = evt;
        evt = document.createEvent('Event');
        evt.initEvent(evttype, true, true);
      }
    }
    window.dispatchEvent(evt);
  }

  var app = angular.module(MODULE);

  var primaryYellow = '#ffb700';
  var bgColour = 'rgba(0,0,0,0.8)';
  var primaryOrange = '#f38b00';
  var mapGrey = '#999c9e';
  var arcRed = '#c80f28';
  var arcGrey = '#555555';

  var svgXmlns = 'http://www.w3.org/2000/svg';
  var xlinkXmlns = 'http://www.w3.org/1999/xlink';

  var STATUS = {
    inactive: 1,
    home: 2,
    active: 3,
    in_progress: 3,
    done: 4,
    completed: 4,
  };

  var SECURITYMATURITY = {
    BEGINNER: 'Beginner',
    AWARE: 'Security Aware',
    SKILLED: 'Security Skilled',
    CHAMPION: 'Security Champion',
  };

  var QUEST_STATUS = {
    ACTIVE: 'in_progress',
    DONE: 'done',
  };

  var onMissionCompletion = false;

  app.controller('Game013LevelController', [
    '$log',
    '$compile',
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    '$timeout',
    '$translate',
    'Session',
    'Game013Api',
    'GameApiService',
    'ErrorHandler',
    'Game013StateService',
    '$uibModal',
    'WalkthroughService',
    'AuthService',
    function (
      $log,
      $compile,
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $timeout,
      $translate,
      Session,
      Game013Api,
      GameApiService,
      ErrorHandler,
      Game013StateService,
      $uibModal,
      WalkthroughService,
      AuthService
    ) {
      $scope.STATUS = STATUS;
      $scope.QUEST_STATUS = QUEST_STATUS;
      $scope.questProgressPercent = 0;

      $scope.selectedCountry = null;
      $scope.terminalReady = false;
      $scope.isShowingTerminal = false;
      $scope.countryAppMap = {};
      $scope.storyValues = {};
      $scope.gameData.levelState = null;
      $scope.gameData.levelStory = null;

      if ($stateParams.onMissionCompletion) {
        onMissionCompletion = $stateParams.onMissionCompletion;
      }

      $scope.easyPieOptions = {
        animate: {
          duration: 500,
          enabled: true,
        },
        barColor: 'rgba(243,139,0,0.7)',
        trackColor: 'rgba(255,255,255,0.1)',
        scaleColor: false,
        lineWidth: 3,
        lineCap: 'circle',
        size: 52,
      };

      $scope.openHomeModal = function () {
        var homeModalInstance = $uibModal.open({
          templateUrl: 'HomeModalContent.html',
          controller: 'HomeModalController',
          size: 'lg',
          scope: $scope,
          backdrop: 'static',
          keyboard: false,
        });

        homeModalInstance.result
          .then(function (_closeData) {
            $log.debug('Closed');
            $state.go('game.013.play.level', Game013StateService.active, { reload: true });
          })
          .catch(function (_dismissData) {
            $log.debug('Dismissed');
            // should never reach here
          });
      };

      $scope.isActiveQuest = function (questStory) {
        return $scope.gameData.levelState.quest[questStory._id].status === QUEST_STATUS.ACTIVE;
      };

      $scope.isCompletedQuest = function (questStory) {
        return $scope.gameData.levelState.quest[questStory._id].status === QUEST_STATUS.DONE;
      };

      $scope.onCountrySelected = function (countryCode, clickSource) {
        if ($scope.walkthroughActive && clickSource !== 'panel') {
          return; // do nothing, force the flow
        }
        // used in walkthrough
        dispatchEvent('mission-panel-click');

        $log.debug('onCountrySelected()', countryCode);

        $rootScope.lastCountrySelected = countryCode;

        var countryName = $scope.worldmap.mapData.paths[countryCode].name;
        var appId;
        var app; // = quest
        var appState;

        if (!$scope.selectedCountry || $scope.selectedCountry.countryCode !== countryCode) {
          $scope.selectedCountry = {
            countryCode: countryCode,
            name: countryName,
            status: $scope.countryStatus[countryCode],
          };

          if (
            $scope.countryStatus[countryCode] === STATUS.active ||
            $scope.countryStatus[countryCode] === STATUS.completed
          ) {
            // a lot of this is probably legacy API awfulness (e.g. an object with numbers (0,1,2,3) as the property names that needed to be used alongside another array)
            appId = $scope.countryAppMap[countryCode];
            app = $scope.gameData.levelStory.quests[appId];
            appState = $scope.gameData.levelState.quest[app._id];
            app.status = appState.status;
            app.progress = appState.progress;
            app.attackingCountry = appState.attackingCountry;

            $scope.setStoryValues($scope.gameData.levelStory, app, Session.user, null);

            $scope.questTasksCompleted = appState.progress.completed;
            $scope.questTasksTotal = app.numberOfChallenges;
            $scope.questProgressText = $scope.questTasksCompleted + '/' + $scope.questTasksTotal;
            $timeout(function () {
              $scope.questProgressPercent = Math.floor(($scope.questTasksCompleted / $scope.questTasksTotal) * 100);
            }, 300);

            $scope.selectedCountry.appId = app._id;
          } else {
            $scope.questProgressPercent = 0;
          }
        }

        if (!$scope.$$phase) {
          $scope.$apply(); // since triggered by jQuery not Angular
        }
      };

      $scope.onMissionPanelClick = function (countryCode) {
        $scope.onCountryIconSelected(countryCode, true);
      };

      $scope.onCountryIconSelected = function (countryCode) {
        $scope.worldmap.clearSelectedRegions();
        $scope.worldmap.setSelectedRegions(countryCode);
      };

      $scope.acceptMission = function acceptMission(questId) {
        if (
          Session.user.properties.tutorial &&
          Session.user.properties.tutorial.enabled &&
          !Session.user.properties.tutorial.items.seenWorldmap
        ) {
          var updatedStatus = Session.user.properties.tutorial;
          updatedStatus.items.seenWorldmap = true;

          GameApiService.updateTutorialStatus(updatedStatus)
            .then(function (_data) {})
            .catch(function (response) {
              ErrorHandler.addHttpError('Error updating tutorial status', response);
            });
        }

        acceptMission.inprogress = true;

        Game013StateService.setActiveState({ _quest: questId });
        if ($scope.gameData.realmStory.type === 'assessment') {
          $state.go('game.013.play.assessment', Game013StateService.active);
        } else {
          $state.go('game.013.play.quest', Game013StateService.active);
        }
      };

      $scope.declineMission = function () {
        $scope.selectedCountry = null;
        $scope.worldmap.clearSelectedRegions();

        // return keyboard focus
        $('.worldmap-overlay').focus();
      };

      /*** SVG arcs and icons **/

      function distanceBetween(pointA, pointB) {
        return Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));
      }

      function midpoint(pointA, pointB) {
        var res = {};
        res.x = (pointA.x + pointB.x) / 2;
        res.y = (pointA.y + pointB.y) / 2;
        return res;
      }

      function gradient(pointA, pointB) {
        return (pointA.y - pointB.y) / (pointA.x - pointB.x);
      }

      function yInterceptConstant(x, y, m) {
        return y - m * x;
      }

      function findBezierControlPoint(pointA, pointB) {
        var originalGradient = gradient(pointA, pointB);
        var mid = midpoint(pointA, pointB);
        var normalGradient = -1 / originalGradient;
        var normalYIntercept = yInterceptConstant(mid.x, mid.y, normalGradient);
        var i = 0;
        var dist = 0;
        var controlPointDistance = distanceBetween(pointA, pointB) / 6;
        var testPoint;

        if (normalGradient === Number.POSITIVE_INFINITY || normalGradient === Number.NEGATIVE_INFINITY) {
          testPoint = { x: mid.x, y: mid.y - controlPointDistance };
        } else if (normalGradient > 0) {
          while (dist < controlPointDistance) {
            var minX = Math.min(pointA.x, pointB.x);
            if (mid.x - i < minX) {
              testPoint = { x: minX, y: normalGradient * minX + normalYIntercept };
              break;
            }
            testPoint = { x: mid.x - i, y: normalGradient * (mid.x - i) + normalYIntercept };
            dist = distanceBetween(mid, testPoint);
            i += 1;
          }
        } else {
          while (dist < controlPointDistance) {
            var maxX = Math.max(pointA.x, pointB.x);
            if (mid.x + i > maxX) {
              testPoint = { x: maxX, y: normalGradient * maxX + normalYIntercept };
              break;
            }
            testPoint = { x: mid.x + i, y: normalGradient * (mid.x + i) + normalYIntercept };
            dist = distanceBetween(mid, testPoint);
            i += 1;
          }
        }

        return testPoint;
      }

      $scope.drawArc = function (fromCoords, toCoords, xOffset, yOffset, isAttacking) {
        $log.debug('Drawing arc from ' + fromCoords + ' to ', toCoords);

        var controlPoint = findBezierControlPoint(fromCoords, toCoords);
        var g = $('.jvectormap-container > svg > g[transform]');
        var path = document.createElementNS(svgXmlns, 'path');
        var pathString =
          'M' +
          fromCoords.x +
          ',' +
          fromCoords.y +
          ' Q' +
          controlPoint.x +
          ',' +
          controlPoint.y +
          ' ' +
          (toCoords.x + xOffset) +
          ',' +
          (toCoords.y + yOffset);
        path.setAttributeNS(null, 'fill', 'none');
        path.setAttributeNS(null, 'stroke', isAttacking ? arcRed : arcGrey);
        path.setAttributeNS(null, 'stroke-width', '2');
        path.setAttributeNS(null, 'stroke-linecap', 'butt');
        path.setAttributeNS(null, 'class', 'arc');
        path.setAttributeNS(null, 'd', pathString);

        g.append(path);
      };

      $scope.addIcon = function (
        coords,
        iconName,
        xOffset,
        yOffset,
        countryCode,
        targetClickCountryCode,
        isAttacking,
        isAttacked
      ) {
        $log.debug(
          'Adding ' + iconName + ' icon at: ',
          coords,
          countryCode,
          targetClickCountryCode,
          isAttacking,
          isAttacked
        );

        var g = angular.element('.jvectormap-container > svg > g[transform]');
        var img = document.createElementNS(svgXmlns, 'image');
        img.setAttributeNS(null, 'x', coords.x - 15 + xOffset);
        img.setAttributeNS(null, 'y', coords.y - 15 + yOffset);
        img.setAttributeNS(null, 'width', '30');
        img.setAttributeNS(null, 'height', '30');

        var iconSuffix = isAttacking ? '-01' : '-02';
        var iconFile;

        if (iconName === 'hacker') {
          iconFile = 'images/Attackers/hacker2' + iconSuffix + '.png';
        } else if (iconName === 'hacktivist') {
          iconFile = 'images/Attackers/hacktivist2' + iconSuffix + '.png';
        } else if (iconName === 'cybercriminal') {
          iconFile = 'images/Attackers/cybercriminal' + iconSuffix + '.png';
        } else if (iconName === 'statesponsored') {
          iconFile = 'images/Attackers/statesponsored' + iconSuffix + '.png';
        } else if (iconName === 'bank') {
          iconFile = 'images/Targets/bank' + iconSuffix + '.png';
        } else if (iconName === 'cloud') {
          iconFile = 'images/Targets/cloud' + iconSuffix + '.png';
        } else if (iconName === 'ecommerce') {
          iconFile = 'images/Targets/ecommerce' + iconSuffix + '.png';
        } else if (iconName === 'utility') {
          iconFile = 'images/Targets/utilities' + iconSuffix + '.png';
        }

        img.setAttributeNS(xlinkXmlns, 'href', iconFile);
        img.setAttributeNS(xlinkXmlns, 'orig-ng-click', 'onCountryIconSelected("' + targetClickCountryCode + '")');
        img.setAttributeNS(null, 'pointer', true);

        $compile(img)($scope);
        g.append(img);
      };

      function getCircularOffsets(n, total) {
        var iconSize = total === 2 ? 20 : total === 3 ? 25 : 30;

        if (total <= 0) {
          return null;
        } else if (total === 1) {
          return { x: 0, y: 0 };
        } else {
          return {
            x: iconSize * Math.cos((2 * Math.PI * n) / total - Math.PI / 2),
            y: iconSize * Math.sin((2 * Math.PI * n) / total - Math.PI / 2),
          };
        }
      }

      $scope.numHomeIcons = 0;
      $scope.multiIconTest = function (n) {
        var homeCode = $rootScope.gameState.properties.country.home;
        var toCoords = $scope.worldmap.latLngToRawPoint(
          $rootScope.metadata.countries.all[homeCode].latLng[0],
          $rootScope.metadata.countries.all[homeCode].latLng[1]
        );
        var xOffsets = [];
        var yOffsets = [];
        var fromCoords;

        for (var i = 0; i < n; i++) {
          xOffsets.push(30 * Math.cos((2 * Math.PI * i) / n - Math.PI / 2));
          yOffsets.push(30 * Math.sin((2 * Math.PI * i) / n - Math.PI / 2));

          if (i === 0) {
            $scope.countryStatus['US'] = STATUS.active;
            fromCoords = $scope.worldmap.latLngToRawPoint(
              $rootScope.metadata.countries.all['US'].latLng[0],
              $rootScope.metadata.countries.all['US'].latLng[1]
            );
            $scope.drawArc(fromCoords, toCoords, xOffsets[i], yOffsets[i], true);
            $scope.addIcon(fromCoords, 'hacktivist-01', 0, 0, 'US', 'US', true, false);
            $scope.addIcon(toCoords, 'Cloud-02', xOffsets[i], yOffsets[i], homeCode, 'US', false, true);
          }
          if (i === 1) {
            $scope.countryStatus['BR'] = STATUS.active;
            fromCoords = $scope.worldmap.latLngToRawPoint(
              $rootScope.metadata.countries.all['BR'].latLng[0],
              $rootScope.metadata.countries.all['BR'].latLng[1]
            );
            $scope.drawArc(fromCoords, toCoords, xOffsets[i], yOffsets[i], true);
            $scope.addIcon(fromCoords, 'hacktivist-01', 0, 0, 'BR', 'BR', true, false);
            $scope.addIcon(toCoords, 'Cloud-02', xOffsets[i], yOffsets[i], homeCode, 'BR', false, true);
          }
          if (i === 2) {
            $scope.countryStatus['RU'] = STATUS.active;
            fromCoords = $scope.worldmap.latLngToRawPoint(
              $rootScope.metadata.countries.all['RU'].latLng[0],
              $rootScope.metadata.countries.all['RU'].latLng[1]
            );
            $scope.drawArc(fromCoords, toCoords, xOffsets[i], yOffsets[i], true);
            $scope.addIcon(fromCoords, 'cybercriminal-01', 0, 0, 'RU', 'RU', true, false);
            $scope.addIcon(toCoords, 'eCommerce-02', xOffsets[i], yOffsets[i], homeCode, 'RU', false, true);
          }
          if (i === 3) {
            $scope.countryStatus['IN'] = STATUS.active;
            fromCoords = $scope.worldmap.latLngToRawPoint(
              $rootScope.metadata.countries.all['IN'].latLng[0],
              $rootScope.metadata.countries.all['IN'].latLng[1]
            );
            $scope.drawArc(fromCoords, toCoords, xOffsets[i], yOffsets[i], true);
            $scope.addIcon(fromCoords, 'hacker-01', 0, 0, 'IN', 'IN', true, false);
            $scope.addIcon(toCoords, 'Utilities-02', xOffsets[i], yOffsets[i], homeCode, 'IN', false, true);
          }
        }
        $scope.numHomeIcons++;
      };

      var arr = [STATUS.active, STATUS.inactive, STATUS.completed, STATUS.home];
      $scope.idx = 0;
      $scope.cycleRegionStatus = function (region) {
        $timeout(function () {
          region.status = arr[$scope.idx++ % arr.length];
          $scope.countryStatus[region.countryCode] = region.status;
          $scope.worldmap.series.regions[0].setValues($scope.countryStatus);
        });
      };

      $scope.setStoryValues = function (level, quest, user, countryName) {
        if (level) {
          $scope.storyValues.levelInfo = level;
        }

        if (quest) {
          $scope.storyValues.questInfo = quest;
          $scope.storyValues.questState = $scope.gameData.levelState.quest[quest._id];

          if ($scope.storyValues.questInfo.properties.company_name) {
            $scope.storyValues.questCompanyNameAvsAn = AvsAnSimple.query(
              $scope.storyValues.questInfo.properties.company_name
            );
          }

          if ($scope.storyValues.questInfo.timeLimit) {
            $scope.storyValues.questInfo.time_limit_mins_display = moment
              .duration($scope.storyValues.questInfo.timeLimit, 'milliseconds')
              .humanize();
          }

          var iconSuffix = quest.status === QUEST_STATUS.ACTIVE ? '-01' : '-02';

          if (quest.properties.attacker_type === 'hacker') {
            quest.attacker_icon = 'images/Attackers/hacker2' + iconSuffix + '.png';
          } else if (quest.properties.attacker_type === 'hacktivist') {
            quest.attacker_icon = 'images/Attackers/hacktivist2' + iconSuffix + '.png';
          } else if (quest.properties.attacker_type === 'cybercriminal') {
            quest.attacker_icon = 'images/Attackers/cybercriminal' + iconSuffix + '.png';
          } else if (quest.properties.attacker_type === 'statesponsored') {
            quest.attacker_icon = 'images/Attackers/statesponsored' + iconSuffix + '.png';
          }

          if (quest.properties.app_type === 'bank') {
            quest.system_icon = 'images/Targets/bank' + iconSuffix + '.png';
          } else if (quest.properties.app_type === 'cloud') {
            quest.system_icon = 'images/Targets/cloud' + iconSuffix + '.png';
          } else if (quest.properties.app_type === 'ecommerce') {
            quest.system_icon = 'images/Targets/ecommerce' + iconSuffix + '.png';
          } else if (quest.properties.app_type === 'utility') {
            quest.system_icon = 'images/Targets/utilities' + iconSuffix + '.png';
          }
        }

        if (user) {
          $scope.storyValues.user = user;
        }

        if (countryName) {
          $scope.storyValues.country = {};
          $scope.storyValues.country.name = countryName;
        } else if (quest) {
          $scope.storyValues.country = {};
          $scope.storyValues.country.name = $rootScope.metadata.countries.all[quest.attackingCountry].name;
        }

        $scope.storyValues.vulnerabilitySubcategory = 'SQL Injection'; // TODO
        $scope.storyValues.vulnerabilityAvsAn = AvsAnSimple.query($scope.storyValues.vulnerabilitySubcategory);
      };

      $scope.updateMapRegions = function () {
        var allCountries = $scope.metadata.countries.all;

        var homeCode = $scope.gameData.homeCountry;
        var toCoords = $scope.worldmap.latLngToRawPoint(
          allCountries[homeCode].latLng[0],
          allCountries[homeCode].latLng[1]
        );

        var quests = $scope.gameData.levelStory.quests;
        var questsState = $scope.gameData.levelState.quest;
        var numQuests = quests.length;

        var quest;
        var questState;
        var countryCode;
        var fromCoords;
        var fromCoordsMap = {};
        var questIndex;
        var numShownQuests = 0;
        var numActiveQuests = 0;

        var totalTasks = 0;
        var totalCompleteTasks = 0;

        // calculate level total percentage completion for stats overlay
        for (questIndex = 0; questIndex < numQuests; questIndex++) {
          quest = quests[questIndex];
          questState = questsState[quest._id];
          if (questState.status === QUEST_STATUS.ACTIVE || questState.status === QUEST_STATUS.DONE) {
            numShownQuests++;
          }

          if (questState.status === QUEST_STATUS.ACTIVE) {
            numActiveQuests++;
          }

          totalTasks += quest.numberOfChallenges;
          totalCompleteTasks += questState.progress.correct;
        }

        $scope.totalCompletedChallengesPercent = Math.round((totalCompleteTasks / totalTasks) * 100);
        $scope.noActiveMissions = numActiveQuests === 0;
        $scope.noCompletedMissions = numActiveQuests === numQuests;

        if ($scope.totalCompletedChallengesPercent === 100) {
          $translate(['OK', 'LEVEL_COMPLETE', 'LEVEL_COMPLETE_SWAL_TEXT']).then(function (translations) {
            var gameData = $scope.gameData;
            var successText = gameData.levelStory.success;
            if (!gameData.realmStory.disableReplay) successText += '<br><br>' + translations.LEVEL_COMPLETE_SWAL_TEXT;

            swal({
              title: translations.LEVEL_COMPLETE,
              text: successText,
              type: 'success',
              confirmButtonText: translations.OK,
              html: true,
            });

            if (onMissionCompletion) {
              onMissionCompletion();
              onMissionCompletion = false;
            }
          });
        }

        // calculate attacking countries' coordinates
        // TODO this will only shift things once per quest, and later shifts may move things back closer to previous shifted countries
        for (questIndex = 0; questIndex < numQuests; questIndex++) {
          quest = quests[questIndex];
          questState = questsState[quest._id];

          if (questState.status !== QUEST_STATUS.ACTIVE && questState.status !== QUEST_STATUS.DONE) {
            continue;
          }

          countryCode = questState.attackingCountry;
          fromCoords = $scope.worldmap.latLngToRawPoint(
            allCountries[countryCode].latLng[0],
            allCountries[countryCode].latLng[1]
          );
          //shiftPointsApart(fromCoords, toCoords);
          fromCoordsMap[questIndex] = fromCoords;
        }

        var offsets;
        var attackerIconString;
        var targetSystemString;
        var isAttacking;

        // draw arcs in first pass so that icons will be overlayed on top of arcs
        for (questIndex = 0; questIndex < numQuests; questIndex++) {
          quest = quests[questIndex];
          questState = questsState[quest._id];
          $log.debug('QUEST: ', quest);

          if (questState.status !== QUEST_STATUS.ACTIVE && questState.status !== QUEST_STATUS.DONE) {
            continue;
          }

          countryCode = questState.attackingCountry;
          fromCoords = fromCoordsMap[questIndex];

          $scope.countryAppMap[countryCode] = questIndex;
          $scope.countryStatus[countryCode] = STATUS[questState.status];

          offsets = getCircularOffsets(questIndex, numShownQuests);
          attackerIconString = quest.properties.attacker_type;
          targetSystemString = quest.properties.app_type;
          isAttacking = false;

          if (questState.status === QUEST_STATUS.ACTIVE) {
            isAttacking = true;
          }

          $scope.drawArc(fromCoords, toCoords, offsets.x, offsets.y, isAttacking);
        }

        // draw icons in second pass so that icons will be overlayed on top of arcs
        for (questIndex = 0; questIndex < numQuests; questIndex++) {
          quest = quests[questIndex];
          questState = questsState[quest._id];

          if (questState.status !== QUEST_STATUS.ACTIVE && questState.status !== QUEST_STATUS.DONE) {
            continue;
          }

          countryCode = questState.attackingCountry;
          fromCoords = fromCoordsMap[questIndex];

          $scope.countryAppMap[countryCode] = questIndex;
          $scope.countryStatus[countryCode] = STATUS[questState.status];

          offsets = getCircularOffsets(questIndex, numShownQuests);
          attackerIconString = quest.properties.attacker_type;
          targetSystemString = quest.properties.app_type;
          isAttacking = false;

          if (questState.status === QUEST_STATUS.ACTIVE) {
            isAttacking = true;
            //if (Session.user.properties.game[$rootScope.gameId].tutorial.status == $rootScope.metadata.constants.game.tutorial.status.DONE) {
            $scope.addIcon(fromCoords, attackerIconString, 0, 0, countryCode, countryCode, isAttacking, false);
            $scope.addIcon(
              toCoords,
              targetSystemString,
              offsets.x,
              offsets.y,
              homeCode,
              countryCode,
              isAttacking,
              true
            );
          } else {
            $scope.addIcon(fromCoords, attackerIconString, 0, 0, countryCode, countryCode, isAttacking, false);
            $scope.addIcon(
              toCoords,
              targetSystemString,
              offsets.x,
              offsets.y,
              homeCode,
              countryCode,
              isAttacking,
              true
            );
          }
        }

        $scope.countryStatus[homeCode] = STATUS.home;
        $scope.worldmap.series.regions[0].setValues($scope.countryStatus);

        if ($rootScope.lastCountrySelected) {
          $scope.onCountrySelected($rootScope.lastCountrySelected);
        }
      };

      $scope.displayTerminal = function () {
        $scope.isShowingTerminal = true;
        $scope.terminal.enable();
        $scope.terminal.focus();
      };

      // background music (intense!)
      $scope.bgmMuted = true;
      $scope.toggleBgm = function () {
        const bgm = document.getElementById('bgm');
        $scope.bgmMuted = !$scope.bgmMuted;
        if ($scope.bgmMuted) {
          bgm.pause();
        } else {
          if (!bgm.childNodes.length) {
            const source = document.createElement('source');
            source.setAttribute('src', 'media/bgm.m4v');
            source.setAttribute('type', 'audio/mp4');
            bgm.appendChild(source);
          }
          bgm.play();
        }
      };

      $scope.$on('$destroy', function () {
        if ($scope.tutorialTimeout) {
          $timeout.cancel($scope.tutorialTimeout);
        }
      });

      $scope.walkthroughActive = false;
      function walkthrough() {
        $scope.walkthroughActive = true;
        WalkthroughService.trigger('training.worldmap');

        var updatedStatus = Session.user.properties.tutorial;
        updatedStatus.items['seenWorldmap'] = true;

        AuthService.updateTutorialStatus(updatedStatus)
          .then(function (data) {
            Session.user.properties.tutorial = data;
          })
          .catch(function (response) {
            ErrorHandler.addHttpError('Error updating tutorial status', response);
          })
          .finally(function () {});
      }

      $scope.closeTerminal = function () {
        $scope.isShowingTerminal = false;
        $scope.terminal.disable();
      };

      $scope.displayHelp = function () {
        // de-select country and clear map selection
        $scope.declineMission();
        walkthrough();
      };

      $scope.proactiveHelp = function () {
        if (
          Session.user.properties.tutorial &&
          Session.user.properties.tutorial.enabled &&
          !Session.user.properties.tutorial.items.seenWorldmap
        ) {
          walkthrough();
        }
      };
      /*** tour ***/
      $translate([
        'CLICK_THIS_BUTTON_TO_VIEW_THIS_TUTORIAL_ANYTIME',
        'NEXT',
        'PREVIOUS',
        'CLOSE',
        'FINISH',
        'TOUR_WORLD_MAP_OPTIONS_1',
        'TOUR_WORLD_MAP_OPTIONS_2',
        'TOUR_WORLD_MAP_OPTIONS_3',
        'TOUR_WORLD_MAP_OPTIONS_4',
        'TOUR_WORLD_MAP_OPTIONS_5',
        'TOUR_WORLD_MAP_OPTIONS_6',
      ]).then(function (translations) {
        $scope.tourWorldmapOptions = {
          steps: [
            {
              intro: translations.TOUR_WORLD_MAP_OPTIONS_1,
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '.worldmap-column',
              intro: translations.TOUR_WORLD_MAP_OPTIONS_2,
              position: 'right',
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '.worldmap-overlay',
              intro: translations.TOUR_WORLD_MAP_OPTIONS_3,
              position: 'right',
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '.worldmap-stats',
              intro: translations.TOUR_WORLD_MAP_OPTIONS_4,
              position: 'bottom',
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '#home-button',
              intro: translations.TOUR_WORLD_MAP_OPTIONS_5,
              position: 'right',
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '#terminal-button',
              intro: translations.TOUR_WORLD_MAP_OPTIONS_6,
              position: 'right',
              tooltipClass: 'intro-walkthrough',
            },
            {
              element: '#tutorial-menu',
              intro: translations.CLICK_THIS_BUTTON_TO_VIEW_THIS_TUTORIAL_ANYTIME,
              position: 'bottom',
              tooltipClass: 'intro-walkthrough',
            },
          ],
          showStepNumbers: false,
          exitOnOverlayClick: false,
          exitOnEsc: true,
          nextLabel: translations.NEXT,
          prevLabel: translations.PREVIOUS,
          skipLabel: translations.CLOSE,
          doneLabel: translations.FINISH,
          overlayOpacity: 0.9,
          showBullets: false,
        };
      });

      $scope.tourComplete = function (_elem, _scope) {
        $log.debug('Tour complete');
      };

      /*This has been added to calculate the quest progress*/

      $scope.calculateCompletedQuests = function (levelState, levelStory) {
        var completed = 0;
        var total = 0;

        for (var i = 0; i < levelStory.quests.length; i++) {
          completed = completed + levelState.quest[levelStory.quests[i]._id].progress.completed;
          total = total + levelStory.quests[i].numberOfChallenges;
        }

        $timeout(function () {
          $scope.completedQuestProgress = Math.floor((completed / total) * 100);
        }, 300);
      };

      $scope.backToRealms = function () {
        $state.go('game.013.play.realms', {
          _language: Game013StateService.active._language,
          _framework: Game013StateService.active._framework,
        });
      };

      // loads storyline
      $scope.fetchStoryAndUpdateState = function () {
        if ($scope.Session.user.properties.preferences.blockedCountryList) {
          var blockedCountryList = $scope.Session.user.properties.preferences.blockedCountryList;
          if (blockedCountryList.length > 0) {
            _.forEach(blockedCountryList, function (eachCountry) {
              _.forEach(jvm.$('path'), function (path) {
                var $path = $(path);
                if ($path.data('code') === eachCountry.id) {
                  $path.attr('class', '');
                }
              });
            });
          }
        }

        $scope.worldmap.updateSize(); // workaround for PORTAL-2068

        $scope.$gameStatusPromise
          .then(function () {
            Game013StateService.setActiveState($stateParams);

            var ctx = Game013StateService.active;
            return Game013Api.getLevelStatus(ctx);
          })
          .then(function (levelData) {
            $scope.gameData.levelState = levelData.state;
          })
          .then(function () {
            var story = $scope.gameData.story;
            var realmStory = ($scope.gameData.realmStory = $scope.findById(
              story.realms,
              Game013StateService.active._realm
            ));
            $scope.gameData.levelStory = $scope.findById(realmStory.levels, Game013StateService.active._level);

            $scope.playerLevel = 1;
            for (var i = 0; i < $scope.gameData.realmStory.levels.length; i++) {
              if ($scope.gameData.levelStory._id === $scope.gameData.realmStory.levels[i]._id) {
                $scope.playerLevel = i + 1;
                break;
              }
            }

            if (!$scope.gameData.homeCountry) {
              $scope.openHomeModal();
              return;
            }

            $scope.calculateCompletedQuests($scope.gameData.levelState, $scope.gameData.levelStory);

            Game013Api.getLanguagePlayerMetrics(Game013StateService.active, false)
              .then(function (data) {
                $scope.proactiveHelp(); // first-time walkthrough

                $scope.playerStats = data;

                if (
                  $scope.playerStats &&
                  $scope.playerStats.challenges &&
                  $scope.playerStats.challenges.correct + $scope.playerStats.challenges.incorrect > 0
                ) {
                  $scope.playerStats.challenges.accuracy = Math.round(
                    ($scope.playerStats.challenges.correct /
                      ($scope.playerStats.challenges.correct + $scope.playerStats.challenges.incorrect)) *
                      100
                  );
                } else {
                  $scope.playerStats.challenges.accuracy = 0;
                }

                if (
                  $scope.playerStats &&
                  $scope.playerStats.securityMaturity &&
                  $scope.playerStats.securityMaturity.maturityLevel
                ) {
                  if (
                    $scope.playerStats.securityMaturity.maturityLevel ===
                    $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
                  ) {
                    $scope.playerStats.securityMaturity.maturityLevelIndex = 0;
                  } else if (
                    $scope.playerStats.securityMaturity.maturityLevel ===
                    $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
                  ) {
                    $scope.playerStats.securityMaturity.maturityLevelIndex = 1;
                  } else if (
                    $scope.playerStats.securityMaturity.maturityLevel ===
                    $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
                  ) {
                    $scope.playerStats.securityMaturity.maturityLevelIndex = 2;
                  } else if (
                    $scope.playerStats.securityMaturity.maturityLevel ===
                    $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
                  ) {
                    $scope.playerStats.securityMaturity.maturityLevelIndex = 3;
                  }
                }
              })
              .then(function () {
                var leaderboardPageOptions = {
                  size: 10,
                };
                return Game013Api.getLanguageLeaderboard(Game013StateService.active, leaderboardPageOptions)
                  .then(function (leaderboardWithLeaderboardPreferences) {
                    $scope.playerStats.leaderboard = leaderboardWithLeaderboardPreferences.leaderboard;

                    if (leaderboardWithLeaderboardPreferences.leaderboard.list.data.length < 10) {
                      // worldmap leaderboard page size is fixed at 10 so if length is less than 10 we are on the last page
                      // refetch leaderboard in reverse to get bottom 10
                      leaderboardPageOptions.size = -10;
                      leaderboardPageOptions.page = 1;
                      Game013Api.getLanguageLeaderboard(Game013StateService.active, leaderboardPageOptions).then(
                        function (leaderboardWithLeaderboardPreferences) {
                          leaderboardWithLeaderboardPreferences.leaderboard.list.data.reverse();
                          $scope.playerStats.leaderboard = leaderboardWithLeaderboardPreferences.leaderboard;
                          $scope.updateLeaderboard($scope.playerStats.leaderboard);
                        }
                      );
                    } else {
                      $scope.updateLeaderboard($scope.playerStats.leaderboard);
                    }

                    Session.user.properties.preferences.leaderboard =
                      leaderboardWithLeaderboardPreferences.leaderboardPreferences;
                  })
                  .catch(function (response) {
                    $translate(['ERROR_RETRIEVING_LEADERBOARD']).then(function (translations) {
                      ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
                    });
                  });
              })
              .catch(function (response) {
                $translate(['ERROR_RETRIEVING_PLAYER_STATISTICS']).then(function (translations) {
                  ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_PLAYER_STATISTICS, response);
                });
              });

            $scope.proactiveHelp();
            $scope.updateMapRegions();
          })
          .catch(function (response) {
            $log.debug('Failed to retrieve level data, redirecting back to level select', response);
            if (response.status !== -1 || response.config.timeout.$$state.value !== 'Abort $http') {
              $state.go('game.013.play.realms', Game013StateService.active);
            }
          });
      };

      $scope.developerTableData = [];
      $scope.updateLeaderboard = function (data) {
        $scope.developerTableData = data.list.data;

        for (var i = 0; i < $scope.developerTableData.length; i++) {
          var developer = $scope.developerTableData[i];

          if (developer.profile.name) {
            developer.name = developer.profile.name.first + ' ' + developer.profile.name.last;
            developer.name = developer.name.trim() || 'Anonymous';
          }

          if (developer.previousRank !== -1) {
            developer.rankChange = developer.previousRank - developer.currentRank;
          } else {
            developer.rankChange = 'New';
          }

          if (developer.securityMaturity) {
            if (
              developer.securityMaturity.maturityLevel ===
              $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
            ) {
              developer.sortedSecurityMaturity = 1;
              developer.displaySecurityMaturity = SECURITYMATURITY.BEGINNER;
            } else if (
              developer.securityMaturity.maturityLevel ===
              $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
            ) {
              developer.sortedSecurityMaturity = 2;
              developer.displaySecurityMaturity = SECURITYMATURITY.AWARE;
            } else if (
              developer.securityMaturity.maturityLevel ===
              $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
            ) {
              developer.sortedSecurityMaturity = 3;
              developer.displaySecurityMaturity = SECURITYMATURITY.SKILLED;
            } else if (
              developer.securityMaturity.maturityLevel ===
              $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
            ) {
              developer.sortedSecurityMaturity = 4;
              developer.displaySecurityMaturity = SECURITYMATURITY.CHAMPION;
            }
          }

          // PORTAL-1038 - null for invited users that have not accepted the invite
          if (developer.registered) {
            developer.registeredDisplay = moment(developer.registered).format('LL');
          }
        }
      };
    },
  ]);

  app.directive('sidepanel013', [
    function () {
      return {
        restrict: 'A',
        link: function (scope, element, _attrs) {
          element.css('height', Math.floor(getResponsiveHeight() * 0.33) + 'px');
          element.css('min-height', Math.floor(minHeight * 0.33) + 'px');

          var resize = function () {
            element.css('height', Math.floor(getResponsiveHeight() * 0.33) + 'px');
          };
          $(window).on('resize', resize);
          scope.$watch('isHeaderHidden', resize);

          scope.$on('$destroy', function () {
            $(window).off('resize', resize);
          });
        },
      };
    },
  ]);

  app.directive('fullHeightPanel', [
    function () {
      return {
        restrict: 'A',
        link: function (scope, element, _attrs) {
          element.css('height', Math.ceil(getResponsiveHeight()) + 'px');
          element.css('min-height', Math.ceil(minHeight) + 'px');

          var resize = function () {
            element.css('height', Math.ceil(getResponsiveHeight()) + 'px');
          };
          $(window).on('resize', resize);
          scope.$watch('isHeaderHidden', resize);

          scope.$on('$destroy', function () {
            $(window).off('resize', resize);
          });
        },
      };
    },
  ]);

  app.directive('vectormap013', [
    '$timeout',
    '$log',
    function ($timeout, $log) {
      return {
        restrict: 'E',
        replace: true,
        template: '<div id="worldmap" aria-hidden="true"></div>',
        link: function (scope, element, _attrs) {
          $log.debug('postlink vectormap');

          element.css('height', getResponsiveHeight() + 'px');
          element.css('min-height', minHeight + 'px');

          scope.countryStatus = {
            BD: 1,
            BE: 1,
            BF: 1,
            BG: 1,
            BA: 1,
            BN: 1,
            BO: 1,
            JP: 1,
            BI: 1,
            BJ: 1,
            BT: 1,
            JM: 1,
            BW: 1,
            BR: 1,
            BS: 1,
            BY: 1,
            BZ: 1,
            RU: 1,
            RW: 1,
            RS: 1,
            LT: 1,
            LU: 1,
            LR: 1,
            RO: 1,
            GW: 1,
            GT: 1,
            GR: 1,
            GQ: 1,
            GY: 1,
            GE: 1,
            GB: 1,
            GA: 1,
            GN: 1,
            GM: 1,
            GL: 1,
            KW: 1,
            GH: 1,
            OM: 1,
            _1: 1,
            _0: 1,
            JO: 1,
            HR: 1,
            HT: 1,
            HU: 1,
            HN: 1,
            PR: 1,
            PS: 1,
            PT: 1,
            PY: 1,
            PA: 1,
            PG: 1,
            PE: 1,
            PK: 1,
            PH: 1,
            PL: 1,
            '-99': 1,
            ZM: 1,
            EH: 1,
            EE: 1,
            EG: 1,
            ZA: 1,
            EC: 1,
            AL: 1,
            AO: 1,
            KZ: 1,
            ET: 1,
            ZW: 1,
            ES: 1,
            ER: 1,
            ME: 1,
            MD: 1,
            MG: 1,
            MA: 1,
            UZ: 1,
            MM: 1,
            ML: 1,
            MN: 1,
            MK: 1,
            MW: 1,
            MR: 1,
            UG: 1,
            MY: 1,
            MX: 1,
            VU: 1,
            FR: 1,
            FI: 1,
            FJ: 1,
            FK: 1,
            NI: 1,
            NL: 1,
            NO: 1,
            NA: 1,
            NC: 1,
            NE: 1,
            NG: 1,
            NZ: 1,
            NP: 1,
            CI: 1,
            CH: 1,
            CO: 1,
            CN: 1,
            CM: 1,
            CL: 1,
            CA: 1,
            CG: 1,
            CF: 1,
            CD: 1,
            CZ: 1,
            CY: 1,
            CR: 1,
            CU: 1,
            SZ: 1,
            SY: 1,
            KG: 1,
            KE: 1,
            SS: 1,
            SR: 1,
            KH: 1,
            SV: 1,
            SK: 1,
            KR: 1,
            SI: 1,
            KP: 1,
            SO: 1,
            SN: 1,
            SL: 1,
            SB: 1,
            SA: 1,
            SE: 1,
            SD: 1,
            DO: 1,
            DJ: 1,
            DK: 1,
            DE: 1,
            YE: 1,
            AT: 1,
            DZ: 1,
            US: 1,
            LV: 1,
            UY: 1,
            LB: 1,
            LA: 1,
            TW: 1,
            TT: 1,
            TR: 1,
            LK: 1,
            TN: 1,
            TL: 1,
            TM: 1,
            TJ: 1,
            LS: 1,
            TH: 1,
            TF: 1,
            TG: 1,
            TD: 1,
            LY: 1,
            AE: 1,
            VE: 1,
            AF: 1,
            IQ: 1,
            IS: 1,
            IR: 1,
            AM: 1,
            IT: 1,
            VN: 1,
            AR: 1,
            AU: 1,
            IL: 1,
            IN: 1,
            TZ: 1,
            AZ: 1,
            IE: 1,
            ID: 1,
            UA: 1,
            QA: 1,
            MZ: 1,
            HK: 1,
          };

          element.vectorMap({
            map: 'world_mill_en',
            backgroundColor: '#111',
            regionsSelectable: true,
            regionsSelectableOne: true,
            regionStyle: {
              initial: {
                fill: mapGrey,
                'fill-opacity': 1,
                stroke: mapGrey,
                'stroke-width': 1.5,
                'stroke-opacity': 1,
              },
              hover: {
                'fill-opacity': 1,
                cursor: 'pointer',
              },
              selected: {
                cursor: 'pointer',
                'stroke-width': 1.5,
                stroke: mapGrey,
              },
              selectedHover: {},
            },
            series: {
              regions: [
                {
                  scale: [mapGrey], // [inactive, home, attacking, complete]
                  attribute: 'fill',
                  values: scope.countryStatus,
                  min: 1,
                  max: 4,
                },
              ],
            },
            onRegionSelected: function (_e, code, isSelected, _selectedRegions) {
              if (isSelected) {
                scope.onCountrySelected(code);
              }
            },
            onRegionTipShow: function (e) {
              e.preventDefault();
            },
          });

          scope.worldmap = element.vectorMap('get', 'mapObject');

          var resize = function () {
            element.css('height', getResponsiveHeight() + 'px');
            scope.worldmap.updateSize();
          };
          $(window).on('resize', resize);
          scope.$watch('isHeaderHidden', resize);

          var handleKeypress = function (e) {
            if (e.charCode === 96) {
              if (scope.isShowingTerminal) {
                scope.closeTerminal();
              } else {
                scope.displayTerminal();
              }
              scope.$apply();
            }
          };
          $(window).on('keypress', handleKeypress);

          scope.$on('$destroy', function () {
            $(window).off('resize', resize);
            $(window).off('keypress', handleKeypress);
            scope.worldmap.remove();
          });

          // deal with terminal loading after jvectormap
          $timeout(() => {
            if (scope.terminalReady) {
              scope.fetchStoryAndUpdateState(scope);
            } else {
              scope.$on('terminalReady', function (_event, _data) {
                scope.fetchStoryAndUpdateState(scope);
              });
            }
            scope.terminal.disable();
          }, 400);
        },
      };
    },
  ]);

  app.directive('terminal013', [
    '$state',
    '$stateParams',
    '$timeout',
    '$log',
    'Session',
    'Game013StateService',
    function ($state, $stateParams, $timeout, $log, Session, Game013StateService) {
      return {
        restrict: 'E',
        replace: true,
        template: '<div id="terminal"></div>',
        link: function (scope, element, _attrs) {
          $log.debug('postlink terminal');

          var greeting =
            'Secure Code Warrior Terminal\n' +
            '  <Pg-Up/Pg-Dn for scroll>\n\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']          .::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::.]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']      .::::::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::::::.]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']   .:::::::  ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']   :::::::.]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] .:::::      ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']       ::::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] ::::        ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']          :::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] ::::        ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']          :::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] :::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] ::::::::::::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::::::::::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] ::::::::::::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::::::::::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']             ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']          .::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] :::.        ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']         .:::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            '] ::::        ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']         ::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']  :::.       ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']        .:::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']   ::::.     ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']      .::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']     :::::.  ][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']   .:::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']       ::::::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::::::]\n' +
            '[[g;' +
            primaryOrange +
            ';' +
            bgColour +
            ']           ::][[g;' +
            primaryYellow +
            ';' +
            bgColour +
            ']:::]';

          scope.terminal = element.terminal(
            function (command, term) {
              if (command.trim() === 'accept') {
                if (scope.selectedCountry && scope.selectedCountry.status === STATUS.active) {
                  term.echo('Mission accepted. Launching mission interface...');
                  $timeout(function () {
                    Game013StateService.setActiveState({ _quest: scope.selectedCountry.appId });
                    if (scope.gameData.realmStory.type === 'assessment') {
                      $state.go('game.013.play.assessment', Game013StateService.active);
                    } else {
                      $state.go('game.013.play.quest', Game013StateService.active);
                    }
                  }, 1000);
                } else {
                  term.echo('No mission to accept');
                }
              } else if (command.trim().match(/^accept (.+)/)) {
                var match = command.trim().match(/^accept (.+)/);
                var cc = match[1].toUpperCase();
                if (scope.worldmap.mapData.paths[cc]) {
                  scope.onCountrySelected(cc);
                  term.echo('Mission accepted. Launching challenge interface...');
                  $timeout(function () {
                    Game013StateService.setActiveState({ _quest: scope.selectedCountry.appId });
                    if (scope.gameData.realmStory) {
                      // if training
                      $state.go('game.013.play.quest', Game013StateService.active);
                    } else {
                      // if tournament
                      $state.go('tournaments.challenge', {
                        tournamentId: $stateParams.tournamentId,
                        questId: scope.selectedCountry.appId,
                      });
                    }
                  }, 1000);
                } else {
                  term.echo('No attacking country: ' + cc);
                }
              } else if (command.trim() === 'whoami') {
                if (Session.user.name) {
                  term.echo(
                    Session.user.email +
                      ' (' +
                      Session.user.properties.profile.name.first +
                      (Session.user.properties.profile.name.middle
                        ? ' ' + Session.user.properties.profile.name.middle
                        : '') +
                      ' ' +
                      Session.user.properties.profile.name.last +
                      ')'
                  );
                } else {
                  term.echo(Session.user.email);
                }
              } else if (command.trim() === 'dncornholio' || command.trim() === 'iddqd') {
                term.echo('No more bazinga');
              } else if (command.trim() === 'clear') {
                term.clear();
                term.echo(greeting);
              } else if (command.trim() === 'list' || command.trim() === 'ls' || command.trim() === 'dir') {
                term.echo('\nActive missions:\n');
                scope.gameData.levelStory.quests.forEach(function (quest) {
                  var questState = scope.gameData.levelState.quest[quest._id];
                  quest.status = questState.status;
                  quest.progress = questState.progress;
                  quest.attackingCountry = questState.attackingCountry;

                  if (quest.status === QUEST_STATUS.ACTIVE) {
                    var countryCode = quest.attackingCountry;

                    scope.setStoryValues(
                      scope.levelStory,
                      quest,
                      Session.user,
                      scope.metadata.countries.all[countryCode].name
                    );

                    (function (quest, countryCode) {
                      $timeout(function () {
                        term.echo(
                          'Country: ' +
                            (scope.worldmap.mapData.paths[countryCode]
                              ? scope.worldmap.mapData.paths[countryCode].name
                              : countryCode) +
                            ' (enter [[;' +
                            primaryYellow +
                            ';' +
                            bgColour +
                            ']accept ' +
                            countryCode +
                            '] to play)'
                        );
                        term.echo('Targeted company: ' + quest.properties.company_name);
                        term.echo('Targeted application: ' + quest.properties.app_name);
                        term.echo('Description: ' + quest.description);
                        term.echo(quest.progress.completed + '/' + quest.numberOfChallenges + ' challenges completed');
                        term.echo('');
                      }, 100);
                    })(quest, countryCode);
                  }
                });
              } else if (command.trim() === '?' || command.trim() === 'help') {
                term.echo(
                  '  help ?\n' +
                    '  whoami\n' +
                    '  clear\n' +
                    '  list ls dir\n' +
                    '  accept\n' +
                    '  accept [country_code]\n' +
                    '  close\n'
                );
              } else if (command.trim() === 'close') {
                scope.closeTerminal();
                scope.$apply();
              } else if (command === '') {
                //term.echo();
              } else {
                term.echo("Command not found. '?' or 'help' to list supported commands");
              }
            },
            {
              greetings: greeting,
              clear: false,
              enabled: false,
              name: 'Terminal',
              prompt: '>',
              height: getResponsiveHeight(),
              completion: ['close', 'accept', 'list', 'dir', 'clear', 'whoami', 'help'],
              onInit: function () {
                scope.$broadcast('terminalReady', true);
                scope.terminalReady = true;
              },
              keypress: function (e, _terminal) {
                if (e.charCode === 96) {
                  if (scope.isShowingTerminal) {
                    scope.closeTerminal();
                  } else {
                    scope.displayTerminal();
                  }
                  scope.$apply();
                  return false;
                }
              },
            }
          );

          var resize = function () {
            element.css('height', getResponsiveHeight() + 'px');
          };
          $(window).on('resize', resize);
          scope.$watch('isHeaderHidden', resize);

          scope.$on('$destroy', function () {
            $(window).off('resize', resize);
            scope.terminal.purge();
            scope.terminal.destroy();
          });
        },
      };
    },
  ]);

  // Game013Api mapped to GameApiService object
  app.controller('HomeModalController', [
    '$uibModalInstance',
    '$translate',
    '$scope',
    '$rootScope',
    'GeoIPService',
    'Game013Api',
    'ErrorHandler',
    function ($uibModalInstance, $translate, $scope, $rootScope, GeoIPService, Game013Api, ErrorHandler) {
      $translate([
        'LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE',
        'ESTABLISHING_UPLINK_TO_LOCATION',
        'LOCATION_COULD_NOT_BE_ESTABLISHED_MANUAL_OVERRIDE_REQUIRED',
        'UPLINK_COMPLETE',
      ]).then(function (translations) {
        $scope.close = close;
        $scope.forms = {};

        $scope.countries = [];
        _.map($rootScope.metadata.countries.all, function (each, index) {
          $scope.countries.push({ id: index, name: each.name });
        });

        // sort countries by name
        $scope.countries.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        if (
          $scope.Session.user.properties.preferences.blockedCountryList &&
          $scope.Session.user.properties.preferences.blockedCountryList.length > 0
        ) {
          _.forEach($scope.Session.user.properties.preferences.blockedCountryList, function (eachCountry) {
            _.remove($scope.countries, function (country) {
              return country.id === eachCountry.id;
            });
          });
        }

        /*$translate(['LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE', 'ESTABLISHING_UPLINK_TO_LOCATION', 'UPLINK_COMPLETE']).then(function(translations){*/
        if ($scope.gameData.homeCountry) {
          $scope.geoIPLoadingMessage = translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE;
          for (var c = 0; c < $scope.countries.length; c++) {
            if ($scope.countries[c].id === $scope.gameData.homeCountry) {
              $scope.forms.selectedHomeCountry = $scope.countries[c];
              break;
            }
          }
        } else {
          $scope.geoIPLoadingMessage = translations.ESTABLISHING_UPLINK_TO_LOCATION;
          GeoIPService.getLocation().then(function (data) {
            $scope.geoIPLoadingMessage = translations.UPLINK_COMPLETE;
            for (var cIndex = 0; cIndex < $scope.countries.length; cIndex++) {
              if ($scope.countries[cIndex].id === data.country_code) {
                $scope.forms.selectedHomeCountry = $scope.countries[cIndex];
                $scope.geoIPLoadingMessage = translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE;
                break;
              }
            }
            $scope.geoIPLoadingMessage =
              $scope.geoIPLoadingMessage === translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE
                ? $scope.geoIPLoadingMessage
                : translations.LOCATION_COULD_NOT_BE_ESTABLISHED_MANUAL_OVERRIDE_REQUIRED;
          });
        }

        function close() {
          $uibModalInstance.dismiss();
        }

        $scope.update = function () {
          if ($scope.forms.homeCountryForm.$invalid) {
            $scope.forms.homeCountryForm.homeCountry.$dirty = true;
            return;
          }

          $rootScope.disableButton('setHome');
          Game013Api.updateGameProfile($scope.forms.selectedHomeCountry.id)
            .then(function (_data) {
              $scope.gameData.homeCountry = $scope.forms.selectedHomeCountry.id;
            })
            .catch(function (response) {
              $translate(['ERROR_UPDATING_GAME_PROFILE']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_UPDATING_GAME_PROFILE, response);
              });
            })
            .finally(function () {
              $rootScope.enableButton('setHome');
              $uibModalInstance.close();
            });
        };
      });
    },
  ]);
})();
