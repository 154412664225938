/**
 * Usage:
 * 				<multi-ui-select-component
 * 					class="tags-class"
 *					heading="Tags"
 *					title="Choose tags to associate with your course"
 *					model="smartInvite.tags"
 *					is-disabled="smartInviteAllOrganization"
 *					data="smartInvite.data.tags"
 *					on-removed="smartInviteRemovedTag(item, model)"
 *					on-selected="smartInviteSelectedTag(item, model)"
 *					placeholder="Search and select tags you wish to add to this course ...">
 *				</multi-ui-select-component>
 */
import angular from 'angular';
import templateUrl from './multi-ui-select.component.html';

const app = angular.module('SCWMultiUiSelectModule', []);
export default app.name;

app.component('multiUiSelectComponent', {
  templateUrl,
  bindings: {
    class: '@',
    heading: '@',
    title: '@',
    placeholder: '@',
    noResultsFound: '@',

    model: '<',
    onSelected: '&',
    onRemoved: '&',
    onClear: '&',

    data: '=',

    isDisabled: '<',
  },
  controllerAs: 'vm',
  controller: function () {
    var vm = this;

    vm.$onChanges = function (changes) {
      if (changes && changes.isDisabled) {
        vm.isDisabled = changes.isDisabled.hasOwnProperty('currentValue') ? changes.isDisabled.currentValue : true;
      }
    };

    vm.selected = function (item) {
      vm.onSelected({ item: item, model: vm.model });
    };

    vm.removed = function (item) {
      vm.onRemoved({ item: item, model: vm.model });
    };

    vm.clear = function () {
      vm.model = [];
      vm.onClear({ model: vm.model });
    };
  },
});
