import nameModalTemplate from './name-modal.html';
import loginModalTemplate from './login-modal.html';
import logoutModalTemplate from './logout-modal.html';
import signupModalTemplate from './signup-modal.html';
import { resetWorldmap } from '../util/world-map.helper';
import _ from 'lodash';
import angular from 'angular';
import MODULE from './module';
import {
  filterDisplayCategories,
  getPreferencesFromLocalStorage,
  getProfileFromLocalStorage,
  getUtmSourceFromString,
  languageSortFn,
  mapAppTypeCategories,
  numberOfChallengesDesc,
  savePreferencesToLocalStorage,
  filterHiddenSubCategories,
} from './utils';
import { addIcon, drawArc } from './mapFunctions';

function zoom() {}

const easyPieOptions = {
  animate: {
    duration: 500,
    enabled: true,
  },
  barColor: 'rgba(243,139,0,0.7)',
  trackColor: 'rgba(255,255,255,0.1)',
  scaleColor: false,
  lineWidth: 3,
  lineCap: 'square',
  size: 52,
};

/*
 * Note that contextual-microlearning.js configures the /contextual-microlearning end-points to use this
 * MicrolearningController along with indexTemplate (index.html).
 */
angular.module(MODULE).controller('MicrolearningController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$log',
  '$timeout',
  '$window',
  '$translate',
  'MicrolearningServices',
  'AuthService',
  'Session',
  '$compile',
  'PlatformLanguagesService',
  '$uibModal',
  'Game013Api',
  '$uibModalStack',
  'GeoIPService',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $log,
    $timeout,
    $window,
    $translate,
    MicrolearningServices,
    AuthService,
    Session,
    $compile,
    PlatformLanguagesService,
    $uibModal,
    Game013Api,
    $uibModalStack,
    GeoIPService,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $scope.stateParams = $stateParams;
    $scope.language = $translate.use();
    $scope.easyPieOptions = easyPieOptions;
    $scope.countryStatus = $scope.countryStatus || {};

    const visibleCategories = filterHiddenSubCategories($scope.metadata.categories);
    const displayCategories = mapAppTypeCategories(visibleCategories);
    $scope.displayCategories = filterDisplayCategories(
      { appType: $stateParams.app_type, category: $stateParams.category, subcategory: $stateParams.subcategory },
      displayCategories
    );
    $scope.showCategoryList = false;
    $scope.showLanguageList = false;
    $scope.currentLanguage = null;
    $scope.currentCategory = null;
    $scope.category = null;
    $scope.enableCategorySelection = true;
    $scope.enableLanguageSelection = false;
    $scope.isQuestAvailable = false;
    $scope.noContentForLanguage = false;
    $scope.rememberedLanguage = false;

    let _apptype = $stateParams.app_type;
    let _category = null;
    let _subcategory = null;
    let _language = null;
    let _framework = null;
    let _preferences = getPreferencesFromLocalStorage($window);

    function getDetailsFromSession() {
      var profile = getProfileFromLocalStorage($window);
      $scope.registered = profile.registered || false;
      // Update profile name in session storage if session is not anonymous.
      if (!Session.isAnonymous()) {
        if (Session.user.properties.profile.isComplete) {
          profile.firstName = Session.user.properties.profile.name.first || null;
        } else {
          profile.firstName = null;
        }
        $window.localStorage.profile = JSON.stringify(profile);
        $scope.registered = true;
      }

      if (profile.firstName && profile.firstName.trim()) {
        return updateProfile(profile);
      }
      $scope.hasSetName = false;
    }

    function init() {
      // Only draw world map once component has been initialised
      $scope.drawWorldmap = true;

      getDetailsFromSession();
      setInitialCategory();
      setInitialLanguageFramework();

      clearAllModals();

      if ($stateParams.finished) {
        delete $stateParams.finished;
        return $state.go('microlearning.done', $stateParams);
      }

      if (!Session.user || Session.isAnonymous()) {
        const forceLogin = $stateParams.login;
        showLoginModal(forceLogin);
        // NB There's no async here, so the modal is still open (or about to open)
      }

      $scope.ui = { explainer: { step: 0 }, step: 'play' };
      zoom();
      $scope.learningResourcesUrl = $window.SCW_ENV.DefaultUiHost + '/#/learning-resources/';
      $scope.nameModalIsOpen = false;
      initDummyLeaderboard();
    }

    function fetchCategoryInfo() {
      MicrolearningServices.getCategoryInformation(_apptype, _category, _subcategory)
        .then(function (data) {
          var filter = data.category;
          $scope.category = data;
          $scope.categoryInfo = $scope.metadata.categories[filter._type][filter._id].subitem[filter._sub];
          $scope.categoryInfo.typeName = $scope.metadata.categories[filter._type][filter._id].name;

          if (screen && screen.height < 1000) {
            $timeout(zoom, 200);
          }
        })
        .catch(() => {
          $translate(['OK', 'CATEGORY_NOT_FOUND', 'CATEGORY_NOT_IN_DB_OR_LINK_BROKEN']).then(function (translations) {
            swal(
              {
                title: translations.CATEGORY_NOT_FOUND,
                text: translations.CATEGORY_NOT_IN_DB_OR_LINK_BROKEN,
                type: 'warning',
                confirmButtonText: translations.OK,
              },
              function () {
                $stateParams.app_type = 'web';
                $stateParams.category = 'injection';
                $stateParams.subcategory = 'sql';
                $state.go('.', $stateParams, { reload: true });
              }
            );
          });
        });
    }

    function initDummyLeaderboard() {
      Game013Api.getDummyLeaderboard(9).then(function (dummyLeaderboard) {
        dummyLeaderboard.push({
          me: true,
          name: getProfileFromLocalStorage($window).firstName,
          points: 0,
        });
        $scope.developerTableData = dummyLeaderboard;
      });
    }

    function showNameModal() {
      if ($scope.nameModalIsOpen) {
        return;
      }

      $scope.nameModalIsOpen = true;
      $uibModal
        .open({
          templateUrl: nameModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          resolve: {
            translations: {},
            sourcePage: function () {},
          },
          controller: 'ContextualMicroLearningSignupModalController',
          size: 'md',
        })
        .result.then(updateProfile)
        .catch(function () {
          $scope.hasSetName = false;
        })
        .finally(function () {
          AnalyticsService.logEvent(AnalyticsEvents.PlayNow.SET_NAME, {
            is_name_saved: $scope.hasSetName ? 'yes' : 'no',
          });
          $scope.nameModalIsOpen = false;
        });
    }

    /**
     * @param forceLogin {boolean}
     */
    function showLoginModal(forceLogin) {
      clearAllModals();
      if (!Session.user) {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = $stateParams;
      }
      $rootScope.forceLogin = forceLogin;
      $uibModal
        .open({
          animation: true,
          ariaLabelledBy: 'loginModalTitle',
          ariaDescribedBy: 'loginModalBody',
          // IMPORTANT: Don't change this to true unless you want to handle the modal dismissal yourself
          backdrop: 'static',
          templateUrl: loginModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          resolve: {
            translations: {},
          },
          controller: 'LoginController',
          size: 'md',
          // Don't allow Esc to dismiss dialog if forceLogin is true
          keyboard: !forceLogin,
        })
        .result.then(getDetailsFromSession)
        .catch(function () {
          $scope.hasSetName = false;
        })
        .finally(function () {
          getDetailsFromSession();
        });
    }

    function showLogoutModal() {
      clearAllModals();
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'logoutModalTitle',
        ariaDescribedBy: 'logoutModalBody',
        backdrop: 'static',
        controller: 'LoginController',
        size: 'md',
        templateUrl: logoutModalTemplate,
        windowClass: 'simple-flow-modal-z-index-hack',
        resolve: {
          translations: {},
        },
      });
    }

    function updateProfile(updatedProfile) {
      if (
        !updatedProfile ||
        !updatedProfile.firstName ||
        !updatedProfile.firstName.trim() ||
        updatedProfile.firstName === ''
      ) {
        $scope.hasSetName = false;
        return;
      }
      $scope.hasSetName = true;
      $scope.firstName = updatedProfile.firstName.trim();
      if ($scope.developerTableData) {
        $scope.developerTableData[$scope.developerTableData.length - 1].name = updatedProfile.firstName;
      }
    }

    function showSignupModal() {
      clearAllModals();
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          templateUrl: signupModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          controller: 'ContextualMicroLearningSignupModalController',
          size: 'md',
          resolve: {
            translations: {
              modalTitle: 'SIMPLE_FLOW_SIGNUP_MODAL_TITLE_DURING_CHALLENGE',
              modalIntroText: 'SIMPLE_FLOW_SIGNUP_MODAL_INTRO_TEXT_DURING_CHALLENGE',
            },
            sourcePage: function () {
              return 'worldmap leaderboard';
            },
          },
        })
        .result.catch(angular.noop);
    }

    function clearAllModals() {
      $uibModalStack.dismissAll();
    }

    const fetchStoryAndUpdateState = (mapScope) => {
      $scope.worldmap = mapScope.worldmap;
      GeoIPService.getLocation().then(function (location) {
        $scope.updateMapRegions(location.country_code);
        $scope.worldmap.updateSize();
        var profileFromLocalStorage = getProfileFromLocalStorage($window);
        if (!profileFromLocalStorage.country) {
          profileFromLocalStorage.country = location.country_name;
        }
        $scope.newProfile = profileFromLocalStorage;
        $window.localStorage.profile = JSON.stringify(profileFromLocalStorage);
        resetWorldmap($scope.worldmap);
      });
    };

    const updateMapRegions = (homeCode) => {
      if (!$scope.hasLoadedLanguage) {
        return;
      }
      var allCountries = $scope.metadata.countries.all;
      var attackingCountries = ['US', 'RU', 'CN', 'TR', 'BR'];
      var targetCode = attackingCountries[Math.floor(Math.random() * attackingCountries.length)];

      var toCoords = $scope.worldmap.latLngToRawPoint(
        allCountries[homeCode].latLng[0],
        allCountries[homeCode].latLng[1]
      );
      var fromCoords = $scope.worldmap.latLngToRawPoint(
        allCountries[targetCode].latLng[0],
        allCountries[targetCode].latLng[1]
      );

      var offsets = { x: 0, y: 0 };
      var attackerIconString;
      var targetSystemString;
      var isAttacking = true;

      attackerIconString = 'hacker';
      targetSystemString = 'bank';
      $scope.drawArc(fromCoords, toCoords, offsets.x, offsets.y, isAttacking);

      $scope.addIcon(fromCoords, attackerIconString, offsets.x, offsets.y, targetCode, targetCode, isAttacking, false);
      $scope.addIcon(toCoords, targetSystemString, offsets.x, offsets.y, homeCode, targetCode, isAttacking, true);
      var STATUS = {
        inactive: 1,
        home: 2,
        active: 3,
        in_progress: 3,
        done: 4,
        completed: 4,
      };

      $scope.countryStatus[homeCode] = STATUS.home;
      $scope.worldmap.series.regions[0].setValues($scope.countryStatus);
    };

    /* ----------------------------
     * Flow control
     * ------------------------- */
    $scope.showNameModal = showNameModal;
    $scope.showLoginModal = showLoginModal;
    $scope.showSignupModal = showSignupModal;
    $scope.showLogoutModal = showLogoutModal;
    $scope.getDetailsFromSession = getDetailsFromSession;
    $scope.play = play;
    $scope.isLearningResourcesAvailable = learningResourcesAvailable;
    $rootScope.MicrolearningCurrentLanguage = $scope.currentLanguage;
    $scope.fetchStoryAndUpdateState = fetchStoryAndUpdateState;
    $scope.updateMapRegions = updateMapRegions;
    $scope.drawArc = drawArc($log, document);

    $scope.onCountrySelected = function () {
      /*no action on click*/
    };

    $scope.onMissionPanelClick = function (countryCode) {
      $scope.onCountryIconSelected(countryCode, true);
    };

    $scope.onCountryIconSelected = function (countryCode) {
      $scope.worldmap.clearSelectedRegions();
      $scope.worldmap.setSelectedRegions(countryCode);
    };

    $scope.addIcon = addIcon($compile, $scope, $log, document);

    // index.html calls this via ng-click="play()" when the user chooses to "Enter game mode" after login.
    function play() {
      if ($scope.ui.step !== 'play') {
        return ($scope.ui.step = 'play');
      }

      var _quest = _category + '_' + _subcategory;
      $state.current.keepState = false;
      AuthService.resumeSession()
        .then(function () {
          $state.go(
            'microlearning-quest',
            {
              app_type: _apptype,
              category: _category,
              subcategory: _subcategory,
              _language: _language,
              _framework: _framework,
              _realm: 'contextual-microlearning',
              _level: encodeURIComponent(getUtmSourceFromString($window.location.search).replaceAll('.', '_')),
              _quest: _quest,
              _returnParams: angular.copy($stateParams),
            },
            { reload: true }
          );
        })
        .catch(function () {
          var inviteSource = $rootScope.referrer;
          var onReady = AuthService.goAnonymous(inviteSource);
          onReady.then(function () {
            $state.go(
              'microlearning-quest',
              {
                app_type: _apptype,
                category: _category,
                subcategory: _subcategory,
                _language: _language,
                _framework: _framework,
                _realm: 'contextual-microlearning',
                _level: encodeURIComponent(getUtmSourceFromString($window.location.search).replaceAll('.', '_')),
                _quest: _quest,
                _returnParams: $stateParams,
              },
              { reload: true }
            );
          });
        })
        .finally(function () {});

      return ($scope.ui.step = 'game');
    }

    function learningResourcesAvailable() {
      var category_meta = $rootScope.metadata.categories[_apptype][_category];
      var subcategory = category_meta.subitem[_subcategory];
      return (
        subcategory &&
        subcategory.ref &&
        subcategory.ref.learning &&
        subcategory.ref.learning.SCW_public &&
        subcategory.ref.learning.SCW_public.slides
      );
    }

    function filterLanguages(languages, vulnerabilityLanguages, languageStatuses) {
      const lockedLanguages = [],
        unlockedLanguages = [];
      let retrieveComparedLanguage, lang, i;

      for (i = 0; i < languages.length; i++) {
        lang = languages[i];

        retrieveComparedLanguage = _.find(
          vulnerabilityLanguages,
          (language) => language.id._id === lang.id._id && language.id._framework === lang.id._framework
        );
        if (!retrieveComparedLanguage) {
          return;
        }

        lang.vulnerabilityNumberOfChallenges = retrieveComparedLanguage.numberOfChallenges;
        lang.numberOfChallenges *= 3; // each set of source code can support 3 challenge types

        if (lang.gameAvailability['013'].toLowerCase() === languageStatuses.ACTIVE.toLowerCase()) {
          unlockedLanguages.push(lang);
        }

        if (lang.gameAvailability['013'].toLowerCase() === languageStatuses.AVAILABLE.toLowerCase()) {
          unlockedLanguages.push(lang);
        }

        if (lang.gameAvailability['013'].toLowerCase() === languageStatuses.NOT_SUBSCRIBED.toLowerCase()) {
          lockedLanguages.push(lang);
        }
      }

      return { lockedLanguages, unlockedLanguages };
    }

    function specifyLanguageToPlay(languageStatuses, vulnerabilityData, data, currentLanguage) {
      let languages, languageToPlay, currentlySelectedLanguage;

      const processedLanguages = filterLanguages(data.languages, vulnerabilityData.languages, languageStatuses);
      if (!processedLanguages) {
        return false;
      }

      processedLanguages.lockedLanguages.sort(languageSortFn);
      processedLanguages.unlockedLanguages.sort(languageSortFn);

      languages = processedLanguages.unlockedLanguages.concat(processedLanguages.lockedLanguages);
      languages.sort(numberOfChallengesDesc);

      if (!currentLanguage) {
        return {
          languages: languages,
          languageToPlay: null,
        };
      }

      currentlySelectedLanguage = _.find(languages, function (language) {
        return language.id._framework === currentLanguage._framework && language.id._id === currentLanguage._id;
      });

      languageToPlay = currentlySelectedLanguage;

      if (
        currentlySelectedLanguage.numberOfChallenges < 20 ||
        currentlySelectedLanguage.vulnerabilityNumberOfChallenges === 0
      ) {
        languageToPlay = null;
      }

      return {
        languages: languages,
        languageToPlay: languageToPlay,
      };
    }

    $scope.filterLanguages = filterLanguages;
    $scope.specifyLanguageToPlay = specifyLanguageToPlay;

    // $scope.validateLanguage = function (language) {
    //   if (language.numberOfChallenges < 20) {
    //     return false;
    //   }
    //   return language.vulnerabilityNumberOfChallenges !== 0;
    // };

    $scope.validateLanguage = function (language) {
      if (language.numberOfChallenges < 20) {
        return false;
      }
      if (language.vulnerabilityNumberOfChallenges === 0) {
        return false;
      }
      return $scope.metadata.languages[language.id._id].framework[language.id._framework].categoryType === _apptype;
    };

    function setupUnlockedLanguages() {
      PlatformLanguagesService.remote().then(function (data) {
        PlatformLanguagesService.remoteVulnerability(_category, _subcategory).then(function (vulnerabilityData) {
          const specifyLanguageResult = specifyLanguageToPlay(
            $scope.metadata.constants.language.status,
            vulnerabilityData,
            data,
            $scope.currentLanguage
          );
          $scope.languages = specifyLanguageResult.languages;

          if (!specifyLanguageResult.languageToPlay) {
            clearCurrentLanguageFramework();
          }
        });
      });
    }

    function categoriesProvided() {
      return _category && _subcategory && _apptype;
    }

    function languageFrameworkProvided() {
      return _language && _framework;
    }

    $scope.languageFrameworkProvided = languageFrameworkProvided;
    $scope.categoriesProvided = categoriesProvided;

    $scope.changeLanguage = (selected) => {
      $scope.noContentForLanguage = false;
      $scope.showLanguageList = false;
      changeSelectedLanguageFramework({ _id: selected._id, _framework: selected._framework }, false, false);
    };

    $scope.changeCategory = (selected) => {
      $scope.showCategoryList = false;
      changeSelectedCategory(selected);
    };

    // When category is changed the language list is updated
    // if there is currently a language selected it should be kept if:
    // - the language exists for that category (is in the scope.languages list)
    // - the language validates (enough challenges etc..)
    $scope.$watch('languages', (_, newLanguages) => {
      if (!newLanguages) {
        return;
      }

      const found = newLanguages.filter((l) => l.id._id === _language && l.id._framework === _framework);

      if (found.length === 0 || !$scope.validateLanguage(found[0])) {
        $scope.currentLanguage = null;
        _language = null;
        _framework = null;
      }
    });

    $scope.toggleLanguageList = () => {
      if (!$scope.currentCategory) {
        return;
      }
      $scope.showLanguageList = !$scope.showLanguageList;
      $scope.showCategoryList = false;
    };

    $scope.toggleCategoryList = () => {
      if (!$scope.enableCategorySelection) {
        return;
      }
      $scope.showCategoryList = !$scope.showCategoryList;
      $scope.showLanguageList = false;
    };

    $scope.canEnterGameMode = () => {
      return $scope.currentLanguage && $scope.currentCategory && $scope.isQuestAvailable;
    };

    $scope.$watch('currentCategory', (oldValue, newValue) => {
      if (oldValue !== newValue) {
        setupUnlockedLanguages();
        fetchCategoryInfo();
      }
    });

    const getQuestAvailability = (app_type, category, subcategory, language, framework) => {
      MicrolearningServices.checkQuestAvailability({ app_type, category, subcategory, language, framework }).then(
        (data) => {
          $scope.isQuestAvailable = data && data.available;
          if (data && !data.available) {
            $scope.noContentForLanguage = true;
          }
        }
      );
    };

    const clearCurrentLanguageFramework = () => {
      _language = null;
      _framework = null;
      $scope.currentLanguage = null;
      $scope.hasLoadedLanguage = false;
    };

    const changeSelectedLanguageFramework = (languageFramework, initialSelection, remembered) => {
      _language = languageFramework._id;
      _framework = languageFramework._framework;
      $scope.currentLanguage = languageFramework;
      $scope.hasLoadedLanguage = true;
      $scope.rememberedLanguage = remembered;

      const pref = {
        language: { _id: _language },
        framework: { _id: _framework },
      };

      savePreferencesToLocalStorage($window, pref);

      if (categoriesProvided() && initialSelection) {
        getQuestAvailability(_apptype, _category, _subcategory, _language, _framework);
      }

      if (categoriesProvided() && !initialSelection) {
        $scope.isQuestAvailable = true;
      }
    };

    const changeSelectedCategory = (category) => {
      _apptype = category.appType;
      _category = category.category.id;
      _subcategory = category.subcategory.id;
      $scope.currentCategory = category;
      setupUnlockedLanguages();
      fetchCategoryInfo();

      if (categoriesProvided() && $scope.currentLanguage) {
        getQuestAvailability(_apptype, _category, _subcategory, _language, _framework);
      }
    };

    const setInitialCategory = () => {
      if ($stateParams.category && $stateParams.subcategory && $stateParams.app_type) {
        const cat = $scope.displayCategories.find(
          (cat) =>
            cat.appType === $stateParams.app_type &&
            cat.category.id === $stateParams.category &&
            cat.subcategory.id === $stateParams.subcategory
        );
        if (cat) {
          $scope.enableCategorySelection = false;
          changeSelectedCategory(cat);

          // Language list is built from the category information
          // Even if we have retrieved it from URL or preferences this might be an invalid value
          // So we only allow selection when category is loaded
          $scope.enableLanguageSelection = true;
        }
      }
    };

    const setLanguageFrameworkFromRouteParams = () => {
      if ($stateParams.language && $stateParams.framework) {
        let lang = $scope.metadata.languages[$stateParams.language];
        let framework = lang.framework[$stateParams.framework];

        if (lang && framework) {
          changeSelectedLanguageFramework(
            {
              _id: $stateParams.language,
              _framework: $stateParams.framework,
            },
            true,
            false
          );
        }
      }
    };

    const setLanguageFromPreferences = () => {
      let language = $scope.metadata.languages[_preferences.language._id];
      let framework = language.framework[_preferences.framework._id];
      if (language && framework) {
        changeSelectedLanguageFramework(
          {
            _id: _preferences.language._id,
            _framework: _preferences.framework._id,
          },
          true,
          true
        );
      }
    };

    const setInitialLanguageFramework = () => {
      _preferences.hasOwnProperty('language') && _preferences.hasOwnProperty('framework')
        ? setLanguageFromPreferences()
        : setLanguageFrameworkFromRouteParams();
    };

    $scope.searchValue = '';
    $scope.filterCategory = () => {
      let input = document.getElementById('searchCategory');
      const list = document.querySelectorAll('#listCategory li');
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].innerText.toLowerCase().includes(input.value.toLowerCase())) {
          list[i].style.display = 'block';
        } else {
          list[i].style.display = 'none';
        }
      }
    };
    $scope.filterLanguage = () => {
      let input = document.getElementById('searchLanguage');
      const list = document.querySelectorAll('#listLanguage li');
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].innerText.toLowerCase().includes(input.value.toLowerCase())) {
          list[i].style.display = 'block';
        } else {
          list[i].style.display = 'none';
        }
      }
    };

    init();

    $scope.$on('$destroy', function () {
      clearAllModals();
    });
  },
]);
