import angular from 'angular';
import MODULE from './module';
import newCourseTemplate from '../training/NewCourse.html';

const app = angular.module(MODULE);

app.controller('TempRedirectToHomeController', [
  '$state',
  '$stateParams',
  function ($state, $stateParams) {
    $state.go('home', { ...$stateParams });
  },
]);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'USER_ROLES',
  function ($stateProvider, $urlRouterProvider, USER_ROLES) {
    /** TODO: Delete below route redirects after GA rollout */
    $urlRouterProvider.when('/home-new', '/home');
    $urlRouterProvider.when('/home-new/onboarding', '/home/onboarding');
    $urlRouterProvider.when('/home-new/relay-state/{relayState}', '/home/relay-state/{relayState}');

    $stateProvider.state('home-deprecated', {
      url: '/home-new',
      controller: 'TempRedirectToHomeController',
    });
    /** TODO: Delete above route redirects and state after GA rollout */

    $stateProvider
      .state('home', {
        url: '/home?relayState',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player],
          feature: 'home',
        },
      })
      .state('home.relay-state', { url: '/relay-state/{relayState}' })
      .state('home.onboarding', { url: '/onboarding' });
  },
]);
