/**
 * Usage:
 * 				<mission-debug-bar-component
 * 				  player-session-id="1234567890"
 * 				>
 * 				</mission-debug-bar-component>
 */
import angular from 'angular';
import templateUrl from './missions-debug-bar.component.html';

const app = angular.module('SCWMissionsDebugBar', []);
export default app.name;

app.component('missionDebugBarComponent', {
  templateUrl,
  bindings: {
    playerSessionId: '<',
    completeCb: '&',
    hintCb: '&',
    quest: '=',
  },
  controllerAs: 'vm',

  controller: function ($http, $window, HttpConfigService) {
    const { SCW_ENV } = $window;
    const vm = this;
    const env = window.env;
    let authToken = false;

    if (env.ADVERSARY_AUTH_TOKEN) {
      authToken = env.ADVERSARY_AUTH_TOKEN;
    }

    vm.enabled = !!authToken;

    vm.debugCompleteMission = () => {
      const data = { correct: true };
      const config = HttpConfigService.getHttpConfig();
      config.headers['authorization'] = authToken;
      config.headers['x-tracking'] = vm.playerSessionId;

      $http.post(SCW_ENV.ApiEndpoint + '/external-players/attempt', data, config).then(function () {
        vm.completeCb();
      });
    };

    vm.debugUseHint = () => {
      const data = { weight: 'small' };
      const config = HttpConfigService.getHttpConfig();
      config.headers['authorization'] = authToken;
      config.headers['x-tracking'] = vm.playerSessionId;

      vm.quest.pointsBreakdown.numberOfHintsUsed += 1;

      $http.post(SCW_ENV.ApiEndpoint + '/external-players/hint', data, config).then(function () {
        vm.hintCb();
      });
    };
  },
});
