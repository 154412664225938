var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
export const Pending = Symbol();
export const Idle = Symbol();
//need to find out how to init properly while avoiding null
export const ApiHooksContext = createContext(undefined);
export const useApiHooksContext = () => {
    const trpc = useContext(ApiHooksContext);
    if (!trpc) {
        throw new Error(`this component requires the api context to be present`);
    }
    return trpc;
};
class ServerError extends Error {
    constructor(message) {
        super(message);
    }
}
export function useApiPatternLoad(query, param, deps) {
    const r = useApiLoad(query, param, deps);
    if (r instanceof Error || r == Pending) {
        return r;
    }
    if (r.kind === 'error') {
        return new ServerError(r.message);
    }
    return r.data;
}
export function useApiLoad(query, param, deps) {
    const [response, setResponse] = useState(Pending);
    const load = useCallback((signal) => __awaiter(this, void 0, void 0, function* () {
        setResponse(Pending);
        try {
            const data = yield query(param, { signal });
            setResponse(data);
        }
        catch (e) {
            if (e instanceof Error) {
                setResponse(e);
            }
            else {
                throw e;
            }
        }
    }), 
    // We can't use the query itself because tRPC will always return a new function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...(deps || []), JSON.stringify(param)]);
    useEffect(() => {
        const controller = new AbortController();
        load(controller.signal);
        return () => controller.abort();
    }, [load]);
    return response;
}
export const useApiPatternMutation = (mutation, onResponse, onError) => {
    const { response, mutate } = useApiMutation(mutation, (r) => {
        if (r.kind === 'ok') {
            onResponse === null || onResponse === void 0 ? void 0 : onResponse('data' in r ? r.data : undefined);
        }
        else {
            onError === null || onError === void 0 ? void 0 : onError(new ServerError(r.message));
        }
    }, onError);
    if (response instanceof Error || response == Pending || response == Idle) {
        return { mutate, response };
    }
    if (response.kind === 'error') {
        return { mutate, response: new ServerError(response.message) };
    }
    return { mutate, response: 'data' in response ? response.data : undefined };
};
export const useApiMutation = (mutation, onResponse, onError) => {
    const [response, setResponse] = useState(Idle);
    const load = useCallback((param) => __awaiter(void 0, void 0, void 0, function* () {
        setResponse(Pending);
        try {
            const data = yield mutation(param);
            onResponse === null || onResponse === void 0 ? void 0 : onResponse(data);
            setResponse(data);
        }
        catch (e) {
            if (e instanceof Error) {
                setResponse(e);
                onError === null || onError === void 0 ? void 0 : onError(e);
            }
            else {
                throw e;
            }
        }
    }), [mutation, onResponse, onError]);
    const mutate = useCallback((param) => {
        load(param);
    }, [load]);
    return useMemo(() => Object.freeze({ mutate, response }), [mutate, response]);
};
