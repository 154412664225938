import angular from 'angular';
import MODULE from './module';
import templateUrl from './admin.companies.view.audit-log.html';

angular.module(MODULE).directive('adminCompaniesAuditTable', [
  '$log',
  '$rootScope',
  'AuthService',
  'Session',
  function ($log, $rootScope, AuthService, Session) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        companyId: '=?',
        paginationPreferences: '=?',
      },
      link: function (scope, _element, _attrs, _controller) {
        scope.auditUrl = 'management/admin/audit/' + scope.companyId;
        scope.auth = AuthService;
        scope.metadata = $rootScope.metadata;

        // Begin: expose behaviors and data
        scope.Session = Session;
        scope.reload = reload;
        // End: expose behaviors and data

        // Begin: life-cycle management
        var listeners = {};
        listeners.companyLoaded = $rootScope.$on('admin:company-loaded', function () {
          reload();
        });

        scope.$on('$destroy', function () {
          var key;
          for (key in listeners) listeners[key](); // unsubscribe
        });
        // End: life-cycle management

        function reload() {
          if (!scope.companyId) return;

          $log.debug('Company was reloaded. Reloading audit-log...');
          if (scope.baseSearch) scope.baseSearch.search('', false);
        }
      },
    };
  },
]);
