export const roleMap = {
    admin: 'scw admin',
    reseller: 'reseller',
    companyAdmin: 'company admin',
    manager: 'team manager',
    player: 'developer',
    cmsDeveloper: 'cms developer',
    assessmentUser: 'assessment developer',
    anonymous: 'anonymous',
};
