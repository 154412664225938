import angular from 'angular';

const app = angular.module('SCWTriggerFocus', []);
export default app.name;

app.directive('triggerFocus', [
  '$timeout',
  function ($timeout) {
    return function (scope, _element, attrs) {
      scope.$watch(
        attrs.triggerFocus,
        function (_newValue) {
          $timeout(function () {
            // newValue && element.focus();
          });
        },
        true
      );
    };
  },
]);
