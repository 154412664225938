import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { Container } from '@securecodewarrior/design-system-react';
import { EmailSettings } from './components/EmailSettings';
import { CommunicationChannels } from './components/CommunicationChannels';
import { CommunicationEvents } from './components/CommunicationEvents';
import { CommunicationsHeader } from './components/CommunicationsHeader';
import { PlatformMessaging } from './components/PlatformMessaging';
export const CommunicationsForm = ({ commsCentreQuillEnabled, goalsFeatureEnabled, isLoading, showToaster, }) => {
    return (_jsx(Container, { children: _jsxs(Stack, { rowGap: 10, children: [_jsx(CommunicationsHeader, {}), commsCentreQuillEnabled && _jsx(PlatformMessaging, { isLoading: isLoading, showToaster: showToaster }), _jsx(CommunicationEvents, { showToaster: showToaster, goalsFeatureEnabled: !!goalsFeatureEnabled }), _jsx(CommunicationChannels, { showToaster: showToaster }), _jsx(EmailSettings, { showToaster: showToaster })] }) }));
};
