import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
export const PlatformContext = createContext(null);
export const PlatformContextProvider = ({ children, value }) => {
    const ctxProps = value;
    const { current: urlChangeSubscribers } = useRef(new Set());
    const exploreCacheKey = useRef(1);
    const clearExploreCacheOnNextRequest = useCallback(() => {
        exploreCacheKey.current++;
    }, []);
    const getExploreCacheKey = useCallback(() => {
        return exploreCacheKey.current;
    }, []);
    const navigateTo = useCallback((path, notifySubscribers, replace) => {
        ctxProps.navigator.navigate(path, replace);
        if (notifySubscribers)
            [...urlChangeSubscribers].forEach((f) => f());
    }, [ctxProps.navigator, urlChangeSubscribers]);
    const setRequestedState = useCallback((state, params) => {
        ctxProps.setRequestedState(state, params);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ctxProps.setRequestedState]);
    const goToState = useCallback((state, params) => {
        ctxProps.goToState(state, params);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ctxProps.goToState]);
    const addUrlChangeListener = useCallback((subscriber) => {
        urlChangeSubscribers.add(subscriber);
    }, [urlChangeSubscribers]);
    const removeUrlChangeListener = useCallback((subscriber) => {
        urlChangeSubscribers.delete(subscriber);
    }, [urlChangeSubscribers]);
    const setSessionUserGoalEmails = useCallback((emailsEnabled) => {
        var _a, _b, _c;
        if ((_c = (_b = (_a = ctxProps.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.preferences) === null || _c === void 0 ? void 0 : _c.goals) {
            ctxProps.sessionUser.properties.preferences.goals.emailsEnabled = emailsEnabled;
        }
    }, [ctxProps.sessionUser]);
    const setTranslatedCategories = (callback) => {
        ctxProps.translatedCategories = callback(ctxProps.translatedCategories);
    };
    const getCategoryTranslation = useCallback((categoryId) => { var _a, _b; return (_b = (_a = ctxProps.translatedCategories) === null || _a === void 0 ? void 0 : _a[categoryId]) !== null && _b !== void 0 ? _b : categoryId; }, [ctxProps.translatedCategories]);
    useEffect(() => {
        const notifySubscribers = () => {
            [...urlChangeSubscribers].forEach((f) => f());
        };
        window.addEventListener('popstate', notifySubscribers);
        return () => window.removeEventListener('popstate', notifySubscribers);
    }, [urlChangeSubscribers]);
    return (_jsx(PlatformContext.Provider, { value: Object.assign(Object.assign({}, ctxProps), { navigateTo,
            goToState,
            setRequestedState,
            setTranslatedCategories,
            getCategoryTranslation,
            addUrlChangeListener,
            removeUrlChangeListener,
            getExploreCacheKey,
            clearExploreCacheOnNextRequest,
            setSessionUserGoalEmails }), children: children }));
};
export const usePlatformContext = () => {
    const platform = useContext(PlatformContext);
    if (!platform) {
        throw new Error('platform context is required but is missing');
    }
    return platform;
};
