export const appBarButtonStyles = (active, mobile, theme) => {
    const color = active ? theme.palette.primary.main : theme.palette.grayscale.bg9;
    const borderBottomColor = theme.palette.primary.main;
    return {
        root: Object.assign(Object.assign(Object.assign(Object.assign({}, (!mobile
            ? {
                fontSize: '19px', // for expand more/less icon
                padding: '0px 16px 0px',
                height: '100%',
                '.MuiTypography-root': {
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0.5px',
                },
                '&::after': {
                    content: '""',
                    display: 'block',
                    height: '3px',
                    width: 'calc(100% - 32px)',
                    backgroundColor: active ? borderBottomColor : 'transparent',
                    position: 'absolute',
                    bottom: 0,
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                },
            }
            : {
                borderBottomStyle: 'solid',
                borderBottomWidth: '2px',
                borderBottomColor: 'transparent',
                padding: '4px 12px 2px',
            })), { borderRadius: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '56px', position: 'relative', textTransform: 'none', color, '&:focus-visible': {
                outline: `3px solid ${theme.palette.secondary.main}`,
                borderRadius: '4px',
            }, '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'unset',
            }, '.ScwIcon-root': {
                margin: 0,
            }, '.MuiButton-startIcon': {
                margin: '0',
                '& .MuiSvgIcon-root': {
                    fontSize: theme.typography.pxToRem(24),
                },
            } }), (active ? { borderBottomColor } : {})), (mobile ? { flexDirection: 'row', width: '100%' } : {})),
        textBox: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
    };
};
