import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Dialog } from '@mui/material';
import { LanguageSelection, } from '../../../common/components/organisms/LanguageSelector/LanguageSelection';
import { usePlatformContext } from '../../platformContext';
import { languageSelectionAnalytics } from '../../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { useModal } from '../context/ModalContext';
import { useCybermonActivityFilter } from '../hooks/useCybermonActivityFilter';
export const SelectLanguagesModal = ({ languages }) => {
    const { handleLanguageSelected, selectedLanguages, languageHasContent } = useCybermonActivityFilter();
    const modalContext = useModal();
    const { logAnalyticsEvent } = usePlatformContext();
    const languageAnalytics = useMemo(() => languageSelectionAnalytics('explore', logAnalyticsEvent), [logAnalyticsEvent]);
    const handleSubmit = (selection) => {
        modalContext.setLanguageModalOpen(false);
        handleLanguageSelected(selection);
        languageAnalytics.onSubmit(selection);
    };
    const handleClose = () => {
        modalContext.setLanguageModalOpen(false);
        languageAnalytics.onCancelled();
    };
    const languageGroups = useMemo(() => Object.values(languages !== null && languages !== void 0 ? languages : {}).reduce((result, language) => {
        var _a;
        var _b;
        if (language.group && language.languageId !== 'pseudocode') {
            (_a = result[_b = language.group]) !== null && _a !== void 0 ? _a : (result[_b] = []);
            result[language.group].push({
                id: language.id,
                label: language.label,
                iconClassName: language.iconClassName,
                disabled: !languageHasContent(language.id),
            });
        }
        return result;
    }, {}), [languages, languageHasContent]);
    const initiallySelectedLanguages = selectedLanguages.filter((languageId) => {
        return languageHasContent(languageId);
    });
    return (_jsx(Dialog, { open: modalContext.isLanguageModalOpen, fullWidth: true, maxWidth: 'xl', scroll: 'body', onClose: handleClose, sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                backgroundColor: theme.palette.container.fill.card1,
                backgroundImage: 'unset',
                borderRadius: 3,
            },
        }), children: _jsx(LanguageSelection, { required: false, titleKey: 'pages.explore.filters.languages.title', subtitleKey: 'pages.explore.filters.languages.helperTextFull', searchPlaceholderKey: 'pages.explore.filters.languages.searchPlaceholder', collections: languageGroups, initiallySelectedLanguages: initiallySelectedLanguages, onSearchUsed: languageAnalytics.onSearchUsed, onCategoryToggled: languageAnalytics.onCategoryToggled, onSearchReset: languageAnalytics.onSearchReset, onBack: { labelKey: 'common.labels.cancel', action: handleClose }, onSubmit: { labelKey: 'pages.explore.filters.languages.save', action: handleSubmit } }) }));
};
