import { locales } from '@securecodewarrior/design-system-react';
const fullLocaleMap = Object.fromEntries(locales.map((locale) => [locale.toLowerCase(), locale]));
const localeMap = {
  ...fullLocaleMap,
  de: 'de-DE',
  en: 'en-US',
  'en-gb': 'en-GB',
  es: 'es-ES',
  fr: 'fr-FR',
  ja: 'ja-JP',
  ko: 'ko-KR',
  zh: 'zh-CN',
  'zh-tw': 'zh-TW',
};
/**
 * @param {string|null|undefined} locale
 * @param {string|null} fallback
 * @returns {string|null}
 */
export function mapLocale(locale, fallback = 'en-US') {
  if (!locale || locale === 'none') {
    return null;
  }
  const language = locale.toLowerCase();
  if (language in localeMap) {
    return localeMap[language];
  }
  return fallback;
}
