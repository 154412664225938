import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, debounce, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useId, useMemo, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { NoSearchResultAlert } from '@securecodewarrior/design-system-react';
import { Checkbox, OptionSelect, Button, SearchInput } from '@securecodewarrior/design-system-react/lib/wanda';
import { ChipWithClose } from '../../atoms/Chip/Chip';
import { Divider } from '../../../../scw-react/Divider';
import { ToggleButton } from '../../atoms/ToggleButton/ToggleButton';
import { usePlatformContext } from '../../../../scw-react/platformContext';
import { categorySelectionAnalytics } from './CategorySelection.analytics';
import { CloseIconButton } from '../../atoms/CloseIconButton';
import { textContainsSearch } from '../../../../utils/string';
const groupCategories = (categories, initial) => {
    const res = {};
    categories.forEach((cat) => {
        const collectionId = cat.id.substring(0, cat.id.lastIndexOf('.'));
        if (res[cat.mainName]) {
            if (initial.has(cat.id) || initial.has(collectionId))
                res[cat.mainName].selected.add(cat.id);
            res[cat.mainName].categories.push(cat);
            return;
        }
        const collection = {
            id: collectionId,
            name: cat.mainName,
            categories: [cat],
            selected: new Set(initial.has(cat.id) || initial.has(collectionId) ? [cat.id] : []),
        };
        res[cat.mainName] = Object.assign(Object.assign({}, collection), { type: cat.rootName === 'conceptual_root' ? 'concepts' : 'vulnerabilities' });
    });
    return res;
};
export const CategorySelection = (props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [filterOption, setFilterOption] = useState('all');
    const [groupedCategories, setGroupedCategories] = useState(groupCategories(props.categories, new Set(props.initiallySelectedCategories)));
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => categorySelectionAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const filteredCollection = Object.values(groupedCategories)
        .filter((x) => filterOption === 'all' || x.type === filterOption)
        .map((c) => (Object.assign(Object.assign({}, c), { categories: c.categories.filter((c) => textContainsSearch(c.name, search)) })))
        .filter((x) => x.categories.length !== 0);
    const toggleCategory = (mainName, id) => setGroupedCategories((prev) => {
        const collection = prev[mainName];
        const wasCatSelected = collection.selected.has(id);
        analytics.onCategoryToggled(wasCatSelected, id);
        wasCatSelected ? collection.selected.delete(id) : collection.selected.add(id);
        return Object.assign({}, prev);
    });
    const debouncedSearchAnalytics = useMemo(() => debounce((searchTerm) => {
        analytics.onSearchUsed(searchTerm);
    }, 500), [analytics]);
    const updateSearch = (searchTerm) => {
        setSearch(searchTerm);
        debouncedSearchAnalytics(searchTerm);
    };
    const resetSearch = () => {
        setSearch('');
        analytics.onSearchReset();
    };
    const setCollectionSelected = (mainName) => setGroupedCategories((prev) => {
        const collection = prev[mainName];
        const wasSelected = collection.selected.size === collection.categories.length;
        analytics.onCollectionToggled(mainName, wasSelected);
        wasSelected
            ? (collection.selected = new Set([]))
            : (collection.selected = new Set(collection.categories.map((x) => x.id)));
        return Object.assign({}, prev);
    });
    const onFilterChange = (val) => {
        setFilterOption(val);
        analytics.onFilterUsed(val);
    };
    const selectionPills = Object.values(groupedCategories).map((collection) => {
        const shouldShowCategory = collection.selected.size === collection.categories.length;
        if (shouldShowCategory) {
            return (_jsx(ChipWithClose, { label: `${t('components.organisms.categorySelection.category')}: `, suffix: collection.name, onDelete: () => setCollectionSelected(collection.name) }, `chip_collection_${collection.id}`));
        }
        return Array.from(collection.selected.values()).map((selected) => {
            var _a, _b;
            return (_jsx(ChipWithClose, { label: collection.type === 'concepts'
                    ? `${t('components.organisms.categorySelection.concept')}:`
                    : `${t('components.organisms.categorySelection.vulnerability')}:`, suffix: (_b = (_a = collection.categories.find((x) => x.id === selected)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'NOT FOUND', onDelete: () => toggleCategory(collection.name, selected) }, `chip_item_${collection.id}_${selected}`));
        });
    });
    return (_jsxs(Box, { py: 8, bgcolor: (theme) => theme.palette.container.fill.card1, position: "relative", children: [_jsx(CloseIconButton, { "aria-label": props.onBack.label, onClick: props.onBack.action, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsxs(Stack, { gap: 4, mb: 10, marginX: 7, children: [_jsx(Typography, { variant: "h2", component: "h1", children: t('components.organisms.categorySelection.title') }), _jsx(Typography, { children: t('components.organisms.categorySelection.subTitle') })] }), _jsxs(Stack, { gap: 4, minHeight: "60vh", marginX: 7, children: [_jsxs(Stack, { direction: { sm: 'column', md: 'row' }, justifyContent: "space-between", flexWrap: "wrap", gap: 4, children: [_jsx(SearchInput, { onReset: resetSearch, search: search, setSearch: updateSearch }), _jsx(Box, { minWidth: "300px", children: _jsx(OptionSelect, { label: t('common.filtering.label.filterBy'), displayFn: t, value: filterOption, onChange: onFilterChange, options: CategoryFilterOptions }) })] }), filteredCollection.length === 0 ? (_jsx(NoSearchResultAlert, { onReset: () => resetSearch() })) : (_jsx(Stack, { gap: 2, role: "list", overflow: "auto", maxHeight: "60vh", flexGrow: "1", pr: 4, sx: (theme) => ({
                            scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
                            scrollbarWidth: 'thin',
                            '::-webkit-scrollbar': {
                                background: theme.palette.grayscale.bg4,
                                width: '10px',
                            },
                            '::-webkit-scrollbar-thumb': {
                                background: theme.palette.grayscale.bg6,
                                borderRadius: 4,
                            },
                        }), children: filteredCollection.map((collection) => {
                            var _a;
                            return (_jsx(CategoryCollection, { filteredCollection: collection, collectionLength: (_a = groupedCategories[collection.name].categories.length) !== null && _a !== void 0 ? _a : 0, setOrRemoveCollection: setCollectionSelected, toggleCategory: toggleCategory }, `${collection.name}_collection`));
                        }) })), _jsx(Box, { flexGrow: "1" })] }), _jsx("div", { children: _jsx(Divider, {}) }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 2, paddingX: 7, paddingTop: 7, children: [_jsx("div", { children: _jsx(Button, { variant: "outlined", onClick: props.onBack.action, children: props.onBack.label }) }), _jsx("div", { children: _jsx(Box, { flexGrow: "1", flexWrap: "wrap", display: { xs: 'none', md: 'flex' }, gap: 2, justifyContent: "center", children: selectionPills }) }), _jsx("div", { children: _jsx(Button, { variant: "contained", sx: { textWrap: 'nowrap' }, onClick: () => props.onSubmit.action(Object.values(groupedCategories).flatMap((collection) => {
                                if (collection.selected.size === collection.categories.length) {
                                    // TODO: sometimes the group has a mobile and a web root category
                                    const parentCategories = collection.categories.map((c) => c.id.substring(0, c.id.lastIndexOf('.')));
                                    return [...new Set(parentCategories).values()];
                                }
                                return [...collection.selected.values()];
                            })), children: props.onSubmit.label }) })] }), _jsx(Box, { flexGrow: "1", flexWrap: "wrap", display: { xs: 'flex', md: 'none' }, gap: 2, justifyContent: "center", mt: 3, children: selectionPills })] }));
};
export const CategoryCollection = (props) => {
    const a11yId = useId();
    const isCollectionChecked = props.filteredCollection.selected.size === props.collectionLength;
    const onCollectionToggled = () => props.setOrRemoveCollection(props.filteredCollection.name, props.filteredCollection.type);
    return (_jsx(Stack, { role: "listitem", "aria-labelledby": a11yId, bgcolor: (theme) => theme.palette.container.fill.body, border: (theme) => `1px solid ${theme.palette.container.border.default}`, borderRadius: 3, p: 4, children: _jsxs(Grid2, { container: true, spacing: 2, children: [_jsx(Grid2, { xs: 12, sm: 4, children: _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", component: "h2", fontWeight: 600, id: a11yId, children: props.filteredCollection.name }), _jsx(FormControlLabel, { label: _jsx(Typography, { variant: "caption", sx: { span: { fontWeight: 700 } }, children: _jsx(Trans, { values: { amount: props.collectionLength }, i18nKey: "components.organisms.categorySelection.selectAllOf" }) }), control: _jsx(Checkbox, { onChange: onCollectionToggled, checked: isCollectionChecked }) })] }) }), _jsx(Grid2, { xs: 12, sm: 8, children: _jsx(FormGroup, { sx: { flexWrap: 'wrap', flexDirection: { sm: 'column', md: 'row' }, gap: 2 }, children: props.filteredCollection.categories.map((c) => (_jsx(ToggleButton, { onClick: () => props.toggleCategory(props.filteredCollection.name, c.id), selected: props.filteredCollection.selected.has(c.id), value: c.id, children: c.name }, `toggle_${c.id}`))) }) })] }) }));
};
const CategoryFilterOptions = {
    vulnerabilities: 'components.organisms.categorySelection.filter.vulnerabilities',
    concepts: 'components.organisms.categorySelection.filter.concepts',
    all: 'components.organisms.categorySelection.filter.vulnerabilitiesAndConcepts',
};
