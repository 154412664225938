import angular from 'angular';
import MODULE from './module';
import { constants } from '@scw/metadata';

// templates
import inviteEditTemplate from './admin.users.invite-edit.html';
import bulkInviteTemplate from './admin.users.bulk-invite.html';

angular.module(MODULE).service('AddInviteEditUser', [
  '$log',
  '$translate',
  '$uibModal',
  'AdminApiService',
  'ErrorHandler',
  function ($log, $translate, $uibModal, AdminApiService, ErrorHandler) {
    return function addInviteEditUser(action, userType, user, done) {
      // user is optional and only for action='edit'

      user = user || null;
      $log.debug('AddInviteEditUser called with user ', user);

      return $uibModal
        .open({
          templateUrl: inviteEditTemplate,
          controller: 'AddInviteEditUserModalController',
          size: 'lg',
          // scope: $scope,
          backdrop: 'static',
          resolve: {
            action: function () {
              return action;
            },
            userType: function () {
              return userType;
            },
            user: function () {
              return user;
            },
          },
        })
        .result.then(function (user) {
          if (action == 'invite') {
            // properties re-structuring
            var properties = user && user.properties;

            var params = _.merge(
              {
                email: user.email[0],
                roles: [userType],
              },
              { properties: properties }
            );

            // Email addresses from invite dialog
            var bulkInvitees = null;
            if (user.email.emails) {
              // result of file upload
              bulkInvitees = {};
              bulkInvitees.emails = user.email.emails;
              bulkInvitees.tags = user.email.tags;
              bulkInvitees.provision_type = constants.user.provision_type.CSV;
            } else {
              bulkInvitees = {};
              bulkInvitees.emails = user.email;
              bulkInvitees.provision_type = constants.user.provision_type.MANUAL;
            }
            $uibModal
              .open({
                templateUrl: bulkInviteTemplate,
                controller: 'BulkInviteUserModalController',
                backdrop: 'static',
                size: 'lg',
                // scope: $scope,
                resolve: {
                  request: function () {
                    return { invitees: bulkInvitees, params: params };
                  },
                },
              })
              .result.catch(angular.noop)
              .finally(done);
          } else if (action == 'edit') {
            delete user.name;
            AdminApiService.addLoading();
            AdminApiService.updateUser(user._id, user)
              .then(function (user) {
                if (done) done(user);
              })
              .catch(function (response) {
                // @todo - use static strings for i18n
                $translate(
                  [
                    'FAILED_TO_EDIT_USER',
                    'TEAM_MUST_BE_ASSIGNED',
                    'A_USER_CANNOT_BE_SCW_ADMIN_AND_COMPANY_ADMIN',
                    'A_USER_CANNOT_BE_SCW_ADMIN_AND_TEAM_MANAGER',
                    'A_USER_CANNOT_BE_ADMIN_AND_MANAGER',
                    'A_USER_CANNOT_BE_ADMIN_AND_DEVELOPER',
                    'CANNOT_CHANGE_THE_ROLE_OF_SCWADMIN',
                    'UNABLE_CHANGE_TEAM_STRICT_SSO',
                  ],
                  { action: action }
                ).then(function (translations) {
                  if (
                    response.data.error &&
                    response.data.error == 'Team managers or developers must be assigned to a team'
                  ) {
                    ErrorHandler.addError(translations.TEAM_MUST_BE_ASSIGNED);
                  } else if (
                    response.data.error &&
                    response.data.error == 'A user cannot be both a scw admin and a company admin'
                  ) {
                    ErrorHandler.addError(translations.A_USER_CANNOT_BE_SCW_ADMIN_AND_COMPANY_ADMIN);
                  } else if (
                    response.data.error &&
                    response.data.error == 'A user cannot be both a scw admin and a team manager'
                  ) {
                    ErrorHandler.addError(translations.A_USER_CANNOT_BE_SCW_ADMIN_AND_TEAM_MANAGER);
                  } else if (
                    response.data.error &&
                    response.data.error == 'A user cannot be both a company admin and a team manager'
                  ) {
                    ErrorHandler.addError(translations.A_USER_CANNOT_BE_ADMIN_AND_MANAGER);
                  } else if (
                    response.data.error &&
                    response.data.error == 'A user cannot be both a company admin and a developer'
                  ) {
                    ErrorHandler.addError(translations.A_USER_CANNOT_BE_ADMIN_AND_DEVELOPER);
                  } else if (response.data.error && response.data.error == 'Cannot change the role of an SCW admin') {
                    ErrorHandler.addError(translations.CANNOT_CHANGE_THE_ROLE_OF_SCWADMIN);
                  } else if (
                    response.data.error &&
                    response.data.error == 'Unable to change team for user under strict mode SSO'
                  ) {
                    ErrorHandler.addError(translations.UNABLE_CHANGE_TEAM_STRICT_SSO);
                  } else {
                    ErrorHandler.addError(translations.FAILED_TO_EDIT_USER);
                  }
                });
              })
              .finally(function () {
                AdminApiService.removeLoading();
              });
          }
        })
        .catch(angular.noop);
    };
  },
]);
