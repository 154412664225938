import { jsx as _jsx } from "react/jsx-runtime";
import { useCardState } from '../../../context/dashboardContext';
import { TrustScoreSkeleton } from './TrustScoreSkeleton';
import { TrustScoreEmpty } from './TrustScoreEmpty';
import { TrustScoreActive } from './TrustScoreActive';
export const TrustScore = () => {
    const { loading, data, error } = useCardState('trust-score');
    if (loading || error) {
        return _jsx(TrustScoreSkeleton, {});
    }
    if (data === null || data === void 0 ? void 0 : data.onboarding) {
        return _jsx(TrustScoreEmpty, {});
    }
    return _jsx(TrustScoreActive, { data: data === null || data === void 0 ? void 0 : data.trustScore });
};
