import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { P, match } from 'ts-pattern';
import { Button } from '@securecodewarrior/design-system-react/lib/legacy';
import { useRef, useState } from 'react';
import { useMultiassessmentsApi } from './hooks';
import { Idle, Pending, useApiMutation } from '../Api.hooks';
import { Box, Chip, Paper, Popper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { multiassessmentAnalytis, useMonitoredCallback } from './analytics';
export function ReferenceFixerButtonAndPopup(props) {
    const { t } = useTranslation();
    const { references } = props;
    const [open, setOpen] = useState(false);
    const ref = useRef();
    const problems = references.filter((r) => r.missingLatest.length > 0);
    return (_jsxs(Box, { ref: ref, children: [_jsx(Chip, { label: t('pages.multiassessments.referenceFixer.missingLatestCount', { count: problems.length }), onClick: () => setOpen((x) => !x) }), _jsx(Popper, { open: open, anchorEl: ref.current, children: _jsx(Paper, { elevation: 3, sx: { padding: 4 }, children: _jsx(ReferenceFixerPanel, { assessmentId: props.assessmentId, _assessment: props._assessment, references: references }) }) })] }));
}
function ReferenceFixerPanel(props) {
    const { references } = props;
    const problems = references.filter((r) => r.missingLatest.length > 0);
    return (_jsx(_Fragment, { children: problems.map((r) => match(r.refs)
            .with({ k: 'program' }, (p) => (_jsx(ProgramReferenceFixer, { assessmentId: props.assessmentId, _assessment: props._assessment, program: p, missingAssessmentIds: [...new Set(r.missingLatest.map((m) => m._id))] }, p.programId)))
            .with({ k: 'course' }, (c) => _jsx(CourseReferenceFixer, { course: c }, c.courseId))
            .exhaustive()) }));
}
function ProgramReferenceFixer(props) {
    const { program } = props;
    const { t } = useTranslation();
    return (_jsx(Stack, { children: _jsxs(Stack, { direction: "row", alignItems: "baseline", gap: 2, children: [_jsx(Typography, { variant: "body1", children: t('pages.multiassessments.program', { programName: program.name }) }), _jsx(FixProgramButton, Object.assign({}, props, { variant: "text" }))] }) }));
}
export function FixProgramButton(props) {
    const { t } = useTranslation();
    const api = useMultiassessmentsApi();
    const fixProgram = useApiMutation(api.fixers.addAssessmentsToProgramStage.mutate, () => {
        var _a;
        (_a = props.onFixed) === null || _a === void 0 ? void 0 : _a.call(props);
    });
    const fix = useMonitoredCallback(multiassessmentAnalytis.fixProgramPressed(props._assessment, props.missingAssessmentIds, props.program.programId, props.program.stageId), () => fixProgram.mutate({
        programId: props.program.programId,
        stageId: props.program.stageId,
        assessmentIds: props.missingAssessmentIds,
    }), [fixProgram, props.missingAssessmentIds, props.program.programId, props.program.stageId]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { sx: props.sx, onClick: fix, variant: props.variant, size: props.size, disabled: props.disabled ||
                    match(fixProgram.response)
                        .with(P.instanceOf(Error), Idle, () => false)
                        .otherwise(() => true), children: match(fixProgram.response)
                    .with(Idle, P.instanceOf(Error), () => t('pages.multiassessments.fix'))
                    .with(Pending, () => t('pages.multiassessments.fixing'))
                    .otherwise(() => t('pages.multiassessments.fixed')) }), match(fixProgram.response)
                .with(P.instanceOf(Error), (err) => t('pages.multiassessments.failed') + ' ' + err.message)
                .otherwise(() => '')] }));
}
function CourseReferenceFixer(props) {
    const { course } = props;
    const { t } = useTranslation();
    return (_jsx("div", { children: _jsx("p", { children: t('pages.multiassessments.course', { corseName: course.name }) }) }));
}
