export function trustingKeys(obj) {
    return Object.keys(obj);
}
export function trustingEntries(obj) {
    return Object.entries(obj);
}
export function trustFromEntries(entries) {
    return Object.fromEntries(entries);
}
export function record(k, v) {
    return { [k]: v };
}
export function mapValues(obj, fn) {
    return Object.fromEntries(trustingEntries(obj).map(([k, v]) => [k, fn(v, k)]));
}
export function filterKeys(obj, fn) {
    return Object.fromEntries(trustingEntries(obj).filter(([k, v]) => fn(k, v)));
}
export function zipValues(a, b) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return trustingKeys(a).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: [a[key], b[key]] })), {});
}
