import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { match, P } from 'ts-pattern';
import { dayjs, getDurationFromFuture } from '@securecodewarrior/design-system-react';
import { Button, CardStyle, DurationText, Link, OptionSelect, Select, Text, Unstable_MenuItem as MenuItem, } from '@securecodewarrior/design-system-react/lib/legacy';
import { Trans, useTranslation } from 'react-i18next';
import { PlayArrowRounded as PlayArrowIcon, HighlightOffRounded as CloseIcon, CheckCircleOutlineRounded as CheckCircleIcon, TimelapseRounded as TimeLapseIcon, } from '@mui/icons-material';
import * as A from '../../../utils/Array';
const isInFuture = (timestamp) => dayjs().unix() <= timestamp;
const inSchedule = (a) => (!a.startDate || !isInFuture(a.startDate)) && (!a.endDate || isInFuture(a.endDate));
const inCooldownPeriod = (a) => match(a)
    .with({ attemptConfig: { unlocksAt: P.number } }, (b) => getDurationFromFuture(b.attemptConfig.unlocksAt).asMilliseconds() > 0)
    .otherwise(() => false);
const isEnabled = (a) => a.assessmentStatus === 'active';
const withDate = (x) => ({ date: dayjs.unix(x.finishedAt).format('L, LT') });
const isStillLocked = (unlocksAt) => unlocksAt && unlocksAt > dayjs().unix();
const RemainingNumber = (props) => {
    const theme = useTheme();
    return (_jsx(Typography, Object.assign({ component: "strong", variant: "inherit", color: theme.palette.accent.main, fontWeight: theme.typography.fontWeightBold }, props)));
};
const Cooldown = (props) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [t('pages.assessments.overview.caption.cooldownRemaining'), _jsx(Typography, { component: "div", fontWeight: "bold", color: (theme) => theme.palette.accent.high, children: _jsx(DurationText.Timer, { endTime: props.endTime, onFinished: props.onFinished }) })] }));
};
const LanguageMenuItem = ({ language }) => (_jsx(Text, { label: language.label, startIcon: _jsx("i", { className: language.iconClassName }), typographyProps: { noWrap: true } }));
const onTimeReached = (time, callback) => {
    const diffInMs = dayjs.unix(time).diff();
    if (diffInMs > 0) {
        setTimeout(callback, diffInMs + 1000);
    }
};
export const ActionPanel = (props) => {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [languageSelected, setLanguageSelected] = useState(props.view === 'resume' ? props.language : null);
    const [isInSchedule, setIsInSchedule] = useState(inSchedule(props));
    const updateSchedule = () => {
        setIsInSchedule(inSchedule(props));
    };
    useEffect(() => {
        props.endDate && onTimeReached(props.endDate, updateSchedule);
    }, [props.endDate]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        props.startDate && onTimeReached(props.startDate, updateSchedule);
    }, [props.startDate]); // eslint-disable-line react-hooks/exhaustive-deps
    const [_, setShowCooldown] = useState(true);
    const hideCooldown = () => {
        setShowCooldown(false);
    };
    const languageOptions = A.asObject(props.supportedLanguages, (l) => l.id);
    const noLanguageSelectedLabel = _jsx(Text, { label: t('common.noneSelected'), typographyProps: { noWrap: true } });
    return (_jsxs(CardStyle.Stack, Object.assign({ padding: 5, flexGrow: { sm: 1, md: 0 }, flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center", minWidth: "400px", maxWidth: { md: '400px' } }, match(props.view)
        .with(P.union('completed', 'passed', 'completedWithRetake'), () => ({
        bgcolor: palette.success.lowest,
        border: 1,
        borderColor: palette.success.main,
    }))
        .with('failed', () => ({ bgcolor: palette.error.lowest, border: 1, borderColor: palette.error.main }))
        .with('resume', () => ({ bgcolor: palette.accent.lowest, border: 1, borderColor: palette.accent.main }))
        .otherwise(() => ({ bgcolor: palette.grayscale.bg3 })), { children: [match(props)
                .with({ view: 'failed' }, () => (_jsx(CloseIcon, { color: "error", sx: (theme) => ({ fontSize: theme.typography.pxToRem(26) }) })))
                .with({ view: P.union('completed', 'passed', 'completedWithRetake') }, () => (_jsx(CheckCircleIcon, { color: "success", sx: (theme) => ({ fontSize: theme.typography.pxToRem(26) }) })))
                .with({ view: 'resume' }, () => (_jsx(TimeLapseIcon, { htmlColor: palette.accent.main, sx: (theme) => ({ fontSize: theme.typography.pxToRem(26) }) })))
                .otherwise(() => (_jsx(_Fragment, {}))), _jsxs(Typography, { variant: "h4", component: "h2", children: [_jsxs(Box, { component: "span", sx: visuallyHidden, children: [t('pages.assessments.overview.actionTitle'), " -\u00A0"] }), match(props)
                        .with({ view: P.union('completed', 'completedWithRetake') }, () => t('pages.assessments.overview.title.completed'))
                        .with({ view: 'passed' }, () => t('pages.assessments.overview.title.passed'))
                        .with({ view: 'failed' }, () => t('pages.assessments.overview.title.failed'))
                        .with({ view: 'resume' }, () => t('pages.assessments.overview.title.resume'))
                        .with({ view: 'start', attemptConfig: { retriesEnabled: false } }, () => (_jsx(Trans, { i18nKey: "pages.assessments.overview.title.single", components: { strong: _jsx(RemainingNumber, {}) } })))
                        .with({ view: 'retake', attemptConfig: { retriesEnabled: false } }, () => (_jsx(Trans, { i18nKey: "pages.assessments.overview.title.limited", values: { count: 1 }, components: { strong: _jsx(RemainingNumber, {}) } })))
                        .with({ attemptConfig: { retriesEnabled: true, remaining: 'unlimited' } }, () => t('pages.assessments.overview.title.unlimited'))
                        .with({ attemptConfig: { retriesEnabled: true, remaining: P.number } }, (x) => (_jsx(Trans, { i18nKey: "pages.assessments.overview.title.limited", values: { count: x.attemptConfig.remaining }, components: { strong: _jsx(RemainingNumber, {}) } })))
                        .exhaustive()] }), _jsx(Typography, { variant: "body1", component: "span", sx: match(props)
                    .with({ view: P.union('completed', 'passed', 'completedWithRetake') }, () => ({
                    color: palette.success.higher,
                }))
                    .with({ view: 'failed' }, () => ({ color: palette.error.higher }))
                    .with({ view: 'resume' }, () => ({ color: palette.accent.higher }))
                    .otherwise(() => ({ color: palette.grayscale.bg7 })), children: match(props)
                    .with({ view: P.union('completed', 'completedWithRetake') }, (x) => t('pages.assessments.overview.finished.completed', withDate(x)))
                    .with({ view: 'passed' }, (x) => t('pages.assessments.overview.finished.passed', withDate(x)))
                    .with({ view: 'failed' }, (x) => t('pages.assessments.overview.finished.failed', withDate(x)))
                    .with({ view: 'resume' }, () => _jsx(Trans, { i18nKey: "pages.assessments.overview.caption.resume" }))
                    .with({ view: 'start', attemptConfig: { retriesEnabled: false } }, () => (_jsx(Trans, { i18nKey: "pages.assessments.overview.caption.single" })))
                    .with({ view: 'retake', attemptConfig: { retriesEnabled: false } }, () => (_jsx(Trans, { i18nKey: "pages.assessments.overview.caption.limitedAttempts", values: { count: 1 } })))
                    .with({ view: 'retake', attemptConfig: { unlocksAt: P.when(isStillLocked) } }, (x) => (_jsx(Cooldown, { endTime: x.attemptConfig.unlocksAt, onFinished: hideCooldown })))
                    .with({ attemptConfig: { retriesEnabled: true } }, (x) => match(x.attemptConfig)
                    .with({ remaining: 1 }, () => t('pages.assessments.overview.caption.limitedAttempts', { count: 1 }))
                    .with({ remaining: P.number, cooldownBetweenAttemptsInHours: P.number }, (x) => (_jsx(Trans, { i18nKey: 'pages.assessments.overview.caption.waitingPeriod', values: { count: x.cooldownBetweenAttemptsInHours } })))
                    .with({ remaining: P.number }, () => t('pages.assessments.overview.caption.noWaitingPeriod'))
                    .with({ remaining: 'unlimited', cooldownBetweenAttemptsInHours: P.number }, (x) => (_jsx(Trans, { i18nKey: 'pages.assessments.overview.caption.unlimitedWaitingPeriod', values: { count: x.cooldownBetweenAttemptsInHours } })))
                    .with({ remaining: 'unlimited' }, () => t('pages.assessments.overview.caption.unlimitedNoWaitingPeriod'))
                    .exhaustive())
                    .exhaustive() }), match(props)
                .with({ view: 'resume' }, () => (_jsxs("div", { children: [_jsxs(Typography, { color: palette.accent.higher, component: "span", children: [t('pages.assessments.overview.language'), ":", ' '] }), _jsx(Typography, { component: "span", fontWeight: "bold", color: palette.accent.main, children: languageSelected === null || languageSelected === void 0 ? void 0 : languageSelected.label })] })))
                .with({ view: P.union('start', 'retake') }, () => !(props.view === 'retake' && inCooldownPeriod(props)) && (_jsx(OptionSelect, { label: t('pages.assessments.overview.language'), options: languageOptions, displayFn: (language) => _jsx(LanguageMenuItem, { language: language }), onChange: (id) => setLanguageSelected(languageOptions[id]), placeholder: noLanguageSelectedLabel })))
                .otherwise(() => null), match(props)
                .with({ view: P.union('completed', 'passed', 'failed', 'completedWithRetake') }, (x) => (_jsx(Link, { onClick: x.onViewResult, children: t('pages.assessments.overview.viewResult') })))
                .with({ view: 'resume' }, (x) => (_jsx(Button, { startIcon: _jsx(PlayArrowIcon, {}), disabled: !isInSchedule, onClick: () => x.onResume(), children: t('common.action.resume') })))
                .with({ view: 'start' }, (x) => !isEnabled(x) || !isInSchedule || !languageSelected ? (_jsx(Button, { startIcon: _jsx(PlayArrowIcon, {}), disabled: true, children: t('common.action.start') })) : (_jsx(Button, { startIcon: _jsx(PlayArrowIcon, {}), onClick: () => x.onStart(languageSelected), children: t('common.action.start') })))
                .with({ view: 'retake' }, (x) => !isEnabled(x) || !isInSchedule || inCooldownPeriod(x) || !languageSelected ? (_jsx(Button, { startIcon: _jsx(PlayArrowIcon, {}), disabled: true, children: t('common.action.retake') })) : (_jsx(Button, { startIcon: _jsx(PlayArrowIcon, {}), onClick: () => x.onRetake(languageSelected), children: t('common.action.retake') })))
                .exhaustive(), match(props)
                .with({ view: 'completedWithRetake', attemptConfig: { retriesEnabled: true } }, () => (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: (theme) => ({ backgroundColor: theme.palette.grayscale.bg4 }), flexItem: true }), _jsx(Typography, { variant: "body1", component: "span", sx: (theme) => ({ color: theme.palette.success.higher }), children: match(props)
                            .with({
                            view: 'completedWithRetake',
                            attemptConfig: { remaining: P.number, unlocksAt: P.when(isStillLocked) },
                        }, (x) => (_jsxs(_Fragment, { children: [t('pages.assessments.overview.caption.completed.limitedAttempts', {
                                    count: x.attemptConfig.remaining,
                                }), "\u00A0", _jsx(Cooldown, { endTime: x.attemptConfig.unlocksAt, onFinished: hideCooldown })] })))
                            .with({ view: 'completedWithRetake', attemptConfig: { remaining: P.number } }, (x) => (_jsx(_Fragment, { children: t('pages.assessments.overview.caption.completed.limitedAttempts', {
                                count: x.attemptConfig.remaining,
                            }) })))
                            .with({ view: 'completedWithRetake', attemptConfig: { remaining: 'unlimited' } }, () => (_jsx(_Fragment, { children: t('pages.assessments.overview.caption.completed.unlimitedAttempts') })))
                            .otherwise(() => null) }), match(props)
                        .with({ view: 'completedWithRetake', attemptConfig: { retriesEnabled: true } }, (x) => (_jsxs(Stack, { direction: "row", spacing: 2, sx: { width: '100%' }, children: [_jsx(Select, { fullWidth: true, size: "small", label: t('pages.assessments.overview.language'), onChange: (e) => setLanguageSelected(languageOptions[e.target.value]), placeholder: _jsx(MenuItem, { disabled: true, value: "", children: noLanguageSelectedLabel }), children: Object.entries(languageOptions).map(([key, language]) => (_jsx(MenuItem, { value: key, children: _jsx(LanguageMenuItem, { language: language }) }, key))) }), !isEnabled(x) || !isInSchedule || inCooldownPeriod(x) || !languageSelected ? (_jsx(Button, { variant: "outlined", disabled: true, children: t('common.action.retake') })) : (_jsx(Button, { variant: "outlined", onClick: () => x.onRetake(languageSelected), children: t('common.action.retake') }))] })))
                        .otherwise(() => null)] })))
                .otherwise(() => null)] })));
};
