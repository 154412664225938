import angular from 'angular';
import MODULE from './module';

(function () {
  var app = angular.module(MODULE);

  app.controller('Game013RealmsController', [
    '$scope',
    '$state',
    'Game013StateService',
    'SenseiApiService',
    function ($scope, $state, Game013StateService, SenseiApiService) {
      $scope.LEVEL_STATUS = {
        locked: 'locked',
        active: 'active',
        available: 'available',
        complete: 'done',
      };

      $scope.realms = [];

      $scope.supportedBySensei = false;

      $scope.goToSensei = function () {
        $state.go('sensei-download');
      };

      $scope.goToLevel = function (_realm, _level, status) {
        if (status == $scope.LEVEL_STATUS.locked) {
          return;
        }

        Game013StateService.setActiveState({ _realm: _realm, _level: _level });
        $state.go('game.013.play.level', Game013StateService.active);
      };

      $scope.backToLanguages = function () {
        $state.go('game.013.languages');
      };

      $scope.$gameStatusPromise.then(function () {
        var story = $scope.gameData.story;
        if (!story) return;

        $scope.gameData.realmStory = $scope.findById(story.realms, Game013StateService.active._realm);
        $scope.realms = story.realms;

        SenseiApiService.getSupportedIDEs().then(function (response) {
          var selectedLanguage = $scope.gameData.state.language;

          for (var langName in response) {
            var item = response[langName];
            if (item.language._id == selectedLanguage._id && item.language._framework == selectedLanguage._framework) {
              $scope.supportedBySensei = true;
            }
          }
        });
      });

      $scope.$on('$destroy', function () {
        $scope.realms = [];
      });
    },
  ]);
})();
