import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('ManagementAdminCompaniesController', [
  '$window',
  '$log',
  '$scope',
  '$state',
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  'SenseiApiService',
  function ($window, $log, $scope, $state, $translate, AdminApiService, ErrorHandler, SenseiApiService) {
    $window._admin = $scope;
    $('.management-css').prop('disabled', false);

    $scope.sensei = {
      repos: [],
    };

    var regexLocation = /.*[.|-]eu\.*/;

    if (regexLocation.test(window.ApiEndpoint)) {
      $scope.location = 'eu';
    } else {
      $scope.location = 'us';
    }

    $scope.customerTypes = Object.keys($scope.metadata.management.customerTypes).map(function (i) {
      var customerType = $scope.metadata.management.customerTypes[i];
      return { id: customerType.id, name: customerType.name };
    });

    $scope.statuses = [
      { id: 'enabled', name: 'Enabled' },
      { id: 'disabled', name: 'Disabled' },
    ];

    $scope.sensei.addNewRepo = function () {
      if ($scope.sensei.newRepoName.length > 0) {
        AdminApiService.addLoading();
        SenseiApiService.addNewBlankRepo($scope.sensei.newRepoName)
          .then(function (data) {
            $scope.repo = {};
            $scope.repo.raw = data;
            $scope.primaryName = data.names[0];
            $scope.aliases = data.names.slice(1);
            $scope.languages = [];
            $scope.selectRepo($scope.repo);
          })
          .catch(function (response) {
            // @todo - use translate
            $translate(['FAILED_TO_ADD_NEW']).then(function (translations) {
              ErrorHandler.addHttpError(translations.FAILED_TO_ADD_NEW, response);
            });
          })
          .finally(AdminApiService.removeLoading);
      }
    };

    $scope.sensei.deleteRepo = function ($event, repo) {
      $translate(
        [
          'DELETE',
          'CANCEL',
          'DELETE_REPOSITORY_REQUEST',
          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPOSITORY',
          'FAILED_TO_REMOVE_REPO',
        ],
        { repoPrimaryName: repo.primaryName }
      ).then(function (translations) {
        if ($event) {
          $event.stopPropagation();
        }
        swal(
          {
            title: translations.DELETE_REPOSITORY_REQUEST,
            text: translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE_REPOSITORY,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.DELETE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              SenseiApiService.deleteRepo(repo.raw._id)
                .then(function (_data) {
                  AdminApiService.removeLoading();
                })
                .then($scope.sensei.getRepos)
                .catch(function (response) {
                  AdminApiService.removeLoading();
                  ErrorHandler.addHttpError(translations.FAILED_TO_REMOVE_REPO, response);
                });
            }
          }
        );
      });
    };

    $scope.selectRepo = function (repo) {
      AdminApiService.removeLoading();
      $state.go('admin.repo', { repoID: repo.raw._id, repo: repo });
    };

    $scope.sensei.addNewCodebase = function () {
      if ($scope.sensei.newCodebaseName.length > 0) {
        AdminApiService.addLoading();
        SenseiApiService.addNewBlankCodebase($scope.sensei.newCodebaseName)
          .then(function (data) {
            $scope.codebase = {};
            $scope.codebase.raw = data;
            $scope.name = data.name;
            $scope.selectCodebase($scope.codebase);
          })
          .catch(function (response) {
            $translate(['FAILED_TO_ADD_NEW']).then(function (translations) {
              ErrorHandler.addHttpError(translations.FAILED_TO_ADD_NEW, response);
            });
          })
          .finally(AdminApiService.removeLoading);
      }
    };

    $scope.sensei.deleteCodebase = function ($event, codebase) {
      $translate(
        [
          'DELETE',
          'CANCEL',
          'DELETE_CODEBASE_REQUEST',
          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_CODEBASE',
          'FAILED_TO_REMOVE_CODEBASE',
        ],
        { codebaseName: codebase.name }
      ).then(function (translations) {
        $event.stopPropagation();
        swal(
          {
            title: translations.DELETE_CODEBASE_REQUEST,
            text: translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE_CODEBASE,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.DELETE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              SenseiApiService.deleteCodebase(codebase.raw._id)
                .then(function (_data) {
                  AdminApiService.removeLoading();
                })
                .then($scope.sensei.getCodebases)
                .catch(function (response) {
                  AdminApiService.removeLoading();
                  // @todo - use translate
                  ErrorHandler.addHttpError(translations.FAILED_TO_REMOVE_CODEBASE, response);
                });
            }
          }
        );
      });
    };

    $scope.selectCodebase = function (codebase) {
      AdminApiService.removeLoading();
      $state.go('admin.codebase', { codebaseID: codebase.raw._id, codebase: codebase });
    };

    var getSenseiRepos = ($scope.sensei.getRepos = function () {
      AdminApiService.addLoading();
      SenseiApiService.getRepos()
        .then(function (res) {
          AdminApiService.removeLoading();
          $scope.sensei.repos = [];
          res.forEach(function (repo) {
            var thisRepo = {};
            thisRepo.raw = repo;
            thisRepo.primaryName = repo.names[0];
            thisRepo.aliases = repo.names.slice(1);
            thisRepo.languages = [];
            repo.languages.forEach(function (lang) {
              var thisLang = {};
              thisLang.name = metadata.languages[lang._id].name;
              thisLang.framework = metadata.languages[lang._id].framework[lang._framework].name;
              thisRepo.languages.push(thisLang.name + ' / ' + thisLang.framework);
            });
            $scope.sensei.repos.push(thisRepo);
          });
        })
        .catch(function (response) {
          $translate(['FAILED_TO_FETCH_SENSEI_REPO_LIST']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_FETCH_SENSEI_REPO_LIST, response);
            $scope.sensei.repos = [];
          });
        });
    });

    var getSenseiCodebases = ($scope.sensei.getCodebases = function () {
      AdminApiService.addLoading();
      SenseiApiService.getCodebases()
        .then(function (res) {
          AdminApiService.removeLoading();
          $scope.sensei.codebases = [];
          res.forEach(function (codebase) {
            var thisCodebase = {};
            thisCodebase.raw = codebase;
            thisCodebase.name = codebase.name;
            $scope.sensei.codebases.push(thisCodebase);
          });
        })
        .catch(function (response) {
          $translate(['FAILED_TO_FETCH_SENSEI_CODEBASE_LIST']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_FETCH_SENSEI_CODEBASE_LIST, response);
            $scope.sensei.codebases = [];
          });
        });
    });

    // reset scope and view state to its initial value
    function reset() {
      $scope.companies = null;
      $scope.regions = $scope.metadata.management.regions;
      $scope.industries = $scope.metadata.management.industries;
    }

    // calls `reset()` and loads companies
    function init() {
      reset();
      getSenseiRepos();
      getSenseiCodebases();
      loadHealth();
    }

    /*** intro tours ***/
    $translate([
      'NEXT',
      'PREVIOUS',
      'CLOSE',
      'FINISH',
      'TOUR_SCW_ADMIN_OPTIONS_1',
      'TOUR_SCW_ADMIN_OPTIONS_2',
      'TOUR_SCW_ADMIN_OPTIONS_3',
      'TOUR_SCW_ADMIN_OPTIONS_4',
      'TOUR_SCW_ADMIN_OPTIONS_5',
    ]).then(function (translations) {
      $scope.tourSCWAdminOptions = {
        steps: [
          {
            intro: translations.TOUR_SCW_ADMIN_OPTIONS_1,
          },
          {
            element: '#user-search-section',
            intro: translations.TOUR_SCW_ADMIN_OPTIONS_2,
            position: 'bottom',
          },
          {
            element: '#scw-administrators-tab',
            intro: translations.TOUR_SCW_ADMIN_OPTIONS_3,
            position: 'top',
          },
          {
            element: '#searchFilter-tab',
            intro: translations.TOUR_SCW_ADMIN_OPTIONS_4,
            position: 'top',
          },
          {
            element: '#companies-tab',
            intro: translations.TOUR_SCW_ADMIN_OPTIONS_5,
            position: 'top',
          },
        ],
        showStepNumbers: false,
        exitOnOverlayClick: false,
        exitOnEsc: true,
        nextLabel: translations.NEXT,
        prevLabel: translations.PREVIOUS,
        skipLabel: translations.CLOSE,
        doneLabel: translations.FINISH,
        overlayOpacity: 0.9,
        showBullets: false,
      };
    });

    // @modifies $scope.health
    // @todo - error handling
    function loadHealth() {
      AdminApiService.addLoading();

      AdminApiService.ping()
        .then(function (health) {
          $log.debug('Got health: ', health);
          $scope.health = health;
        })
        .finally(AdminApiService.removeLoading);
    }

    function isHealthy() {
      return $scope.health && $scope.health.healthy;
    }

    $scope.healthStatusIcon = function () {
      return isHealthy() ? 'glyphicon-ok' : 'glyphicon-remove';
    };

    $scope.healthStatusText = function () {
      return isHealthy() ? 'Health' : 'Unhealthy';
    };

    $scope.healthStatusClass = function () {
      return isHealthy() ? 'panel-success' : 'panel-danger';
    };

    init();
  },
]);
