import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const cybermonContext = createContext(null);
export const CybermonProvider = ({ children, value }) => {
    return _jsx(cybermonContext.Provider, { value: value, children: children });
};
export const useCybermonContext = () => {
    const context = useContext(cybermonContext);
    if (!context) {
        throw new Error('cybermon context is required but is missing');
    }
    return context;
};
