import angular from 'angular';
import MODULE from './module';

export default MODULE;

import './api';
import './languages';
import './leaderboard';
import './level';
import './play';
import './quest';
import './realm';
import './realms';
import './stats';

import languagesTemplate from './languages.html';
import leaderboardTemplate from './leaderboard.html';
import levelTemplate from './level.html';
import playTemplate from './play.html';
import questTemplate from './quest.html';
import realmTemplate from './realm.html';
import realmsTemplate from './realms.html';
import statsTemplate from './stats.html';
import trainingNotLicensedTemplate from './training-not-licensed.html';

(function () {
  var app = angular.module(MODULE);

  app.controller('Game013Controller', [
    '$scope',
    '$rootScope',
    '$state',
    'Game013Api',
    '$log',
    'Game013StateService',
    'Session',
    'ErrorHandler',
    function ($scope, $rootScope, $state, Game013Api, $log, Game013StateService, Session, ErrorHandler) {
      $scope.gameData = {};
      $scope.onLanguagesPage = false;
      $rootScope.Game013StateService = Game013StateService;

      $scope.findById = function (arr, _id) {
        return arr.filter(function (item) {
          return _id === item._id;
        })[0];
      };

      $scope.switchLanguage = function () {
        $state.go('game.013.languages');
      };

      $scope.checkProfileComplete = function () {
        if (!(Session.user && Session.user.properties.profile.isComplete) && !Session.isAnonymous()) {
          $log.debug('profile incomplete - redirecting to profile');
          $state.go('home', {}, { reload: true });
          return;
        }
      };

      //This workaround has been added to cater to a cache not being refreshed for the user object
      $scope.statsMaturity = _.has(Session.user.properties.preferences, 'statistics.securityMaturity.enabled')
        ? Session.user.properties.preferences.statistics.securityMaturity.enabled
        : true;

      // load initial state
      $scope.init = function () {
        $scope.checkProfileComplete();

        /** Catching errors for API calls made everytime a route is called. Can help understanding the reason for the
         * failure and whether it could be customer plan related or not
         */
        $scope.$gameStatusPromise = Game013Api.getGameStatus()
          .then(function (data) {
            $log.debug('getGameStatus: ', data);
            $scope.gameData.languages = data.languages;
            $scope.gameData.homeCountry = data.homeCountry;

            // redirect to active language if available
            /*if (['game.013.languages', 'game.013.leaderboard', 'game.013.stats'].indexOf($state.current.name) < 0) {
          $rootScope.goToActiveLanguage();
        }*/
            if ($state.current.name == 'game.013') {
              $log.debug('Redirecting to active language or list of languages');

              var languageIsActive = data.activeIndex > -1;
              if (languageIsActive) {
                Game013StateService.setActiveState($scope.gameData.languages[data.activeIndex].active);
              }

              $rootScope.goToActiveLanguage();
            }
          })
          .catch((response) => {
            ErrorHandler.addHttpError('Error loading training', response);
          });
      };

      $scope.init();

      var deregisterStateChangeStart = $rootScope.$on(
        '$stateChangeStart',
        function (event, toState, _toParams, _fromState, _fromParams) {
          // can't use $state.includes() within $stateChangeStart? hence indexOf check...
          if (toState.name.indexOf('game.013') > -1) {
            $scope.checkProfileComplete(event);
          }

          if (toState.name == 'game.013') {
            $scope.init();
          }

          if (toState.name == 'game.013.languages') {
            $scope.onLanguagesPage = true;
          } else {
            $scope.onLanguagesPage = false;
          }

          if (['game.013.play.level', 'game.013.play.quest'].indexOf(toState.name) < 0) {
            $rootScope.lastCountrySelected = null;
          }
        }
      );

      $scope.$on('$destroy', deregisterStateChangeStart);

      $scope.$on('$destroy', function () {
        Game013StateService.reset();
      });

      $rootScope.goToActiveLanguage = function () {
        // redirect to active language if available
        if (Game013StateService.active._language && Game013StateService.active._framework) {
          $state.go('game.013.play.realms', {
            _language: Game013StateService.active._language,
            _framework: Game013StateService.active._framework,
          });
        } else {
          $scope.onLanguagesPage = true;
          $state.go('game.013.languages', { source: 'training mission control' });
        }
      };
    },
  ]);

  app.factory('Game013StateService', [
    function () {
      var Game013StateService = {};

      Game013StateService.active = {};

      Game013StateService.reset = function () {
        Game013StateService.active._language = null;
        Game013StateService.active._framework = null;
        Game013StateService.active._realm = null;
        Game013StateService.active._level = null;
        Game013StateService.active._quest = null;
      };

      Game013StateService.setActiveState = function (state) {
        if (state._language !== undefined && state._language !== '') {
          Game013StateService.active._language = state._language;
        }

        if (state._framework !== undefined && state._framework !== '') {
          Game013StateService.active._framework = state._framework;
        }

        if (state._realm !== undefined && state._realm !== '') {
          Game013StateService.active._realm = state._realm;
        }

        if (state._level !== undefined && state._level !== '') {
          Game013StateService.active._level = state._level;
        }

        if (state._quest !== undefined && state._quest !== '') {
          Game013StateService.active._quest = state._quest;
        }
      };

      Game013StateService.getActiveState = function () {
        return Game013StateService.active;
      };

      Game013StateService.reset();

      return Game013StateService;
    },
  ]);

  app.config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('game', {
        url: '/game',
        abstract: true,
        template: '<ui-view/>',
        data: { authorizedRoles: ['!anonymous'] },
      });

      $stateProvider
        .state('game.013', {
          url: '/013',
          // cannot mark as abstract since we need to run controller logic to redirect to appropriate child state
          // abstract: true,
          controller: 'Game013Controller',
          template: '<ui-view/>',
        })
        .state('game.013.languages', {
          url: '/languages',
          controller: 'Game013LanguagesController',
          templateUrl: languagesTemplate,
          params: { source: '' },
        })
        .state('game.013.play', {
          url: '/play/:_language/:_framework',
          controller: 'Game013PlayController',
          templateUrl: playTemplate,
        })
        .state('game.013.leaderboard', {
          url: '/leaderboard',
          controller: 'Game013LeaderboardController',
          templateUrl: leaderboardTemplate,
        })
        .state('game.013.stats', {
          url: '/stats',
          controller: 'Game013StatsController',
          templateUrl: statsTemplate,
        })
        .state('public-stats', {
          url: '/stats/{userId}',
          controller: 'Game013StatsController',
          templateUrl: statsTemplate,
        })
        .state('game.013.play.realms', {
          url: '/realms',
          controller: 'Game013RealmsController',
          templateUrl: realmsTemplate,
        })
        .state('game.013.play.realm', {
          url: '/realm/:_realm',
          controller: 'Game013RealmController',
          templateUrl: realmTemplate,
        })
        .state('game.013.play.level', {
          url: '/realm/:_realm/level/:_level',
          controller: 'Game013LevelController',
          templateUrl: levelTemplate,
          params: { onMissionCompletion: false },
        })
        .state('game.013.play.quest', {
          url: '/realm/:_realm/level/:_level/quest/:_quest',
          controller: 'Game013QuestController',
          templateUrl: questTemplate,
          params: { _return: false, onQuestCompletion: false },
        })
        .state('training-not-licensed', {
          url: '/training-not-licensed',
          templateUrl: trainingNotLicensedTemplate,
        });
    },
  ]);
})();
