var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { Button as DSButton, IconButton as DSIconButton, Icon as DSIcon, IconSize as DSIconSize, } from '@securecodewarrior/design-system-react/lib/legacy';
/** @deorecated This component will be removed in the future. Use the IconButton component from the design-system
 *  instead. */
const IconButton = (props) => {
    const { children, iconName, iconSize } = props, rest = __rest(props, ["children", "iconName", "iconSize"]);
    return children ? (_jsx(DSButton, Object.assign({}, rest, { startIcon: _jsx(DSIcon, { name: iconName, size: iconSize || DSIconSize.small }), children: children }))) : (_jsx(DSIconButton, Object.assign({}, rest, { children: _jsx(DSIcon, { name: iconName, size: iconSize || DSIconSize.small }) })));
};
export default IconButton;
