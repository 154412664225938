import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { confettiOverlay } from './ConfettiOverlay';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../../../scw-react/commonStyle';
import { Box, Stack, Typography } from '@mui/material';
import { Trophy } from './Trophy';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { RemoveRedEyeRounded, ReplayRounded, RefreshRounded } from '@mui/icons-material';
import { match, P } from 'ts-pattern';
import { FailedIcon } from './FailedIcon';
import { FailedRetryIcon } from './FailedRetryIcon';
import { RefresherIcon } from './RefresherIcon';
import { usePlatformContext } from '../../../../scw-react/platformContext';
import { useEffect, useMemo, useState } from 'react';
import { activityStatusAnalytics } from './ActivityStatus.analytics';
export const ActivityStatusPage = (props) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => activityStatusAnalytics(props.scope, props.contentType, props.contentId, props.variant, logAnalyticsEvent), [logAnalyticsEvent, props]);
    const [countdown, setCountdown] = useState(props.variant === 'failed' || props.variant === 'already_failed'
        ? Math.max(Math.ceil((props.canRetryAt.getTime() - Date.now()) / 1000), 0)
        : 0);
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setCountdown((previousTime) => {
                if (previousTime === 0) {
                    clearInterval(timerInterval);
                    return 0;
                }
                return previousTime - 1;
            });
        }, 1000);
        return () => clearInterval(timerInterval);
    }, []);
    const formatDuration = (timeInSeconds) => {
        const duration = [];
        const minutes = Math.floor(timeInSeconds / 60);
        if (minutes) {
            duration.push(t('common.datetime.short.minutes', { minutes }));
        }
        const seconds = timeInSeconds % 60;
        duration.push(t('common.datetime.short.seconds', { seconds: seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 }) }));
        return duration.join(' ');
    };
    const iconStyling = { width: '215px', height: '150px' };
    const titleStyling = (theme) => ({
        font: 'inherit',
        color: match(props.variant)
            .with('success', 'already_completed', () => theme.palette.text.status.success)
            .with('refresher_required', () => theme.palette.badge.accent3.border)
            .with('failed', 'already_failed', () => theme.palette.text.status.error)
            .exhaustive(),
    });
    return (_jsx(Box, { sx: Object.assign(Object.assign({}, createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true })), { overflow: 'auto', backgroundPosition: 'center', backgroundImage: ['already_completed', 'success'].includes(props.variant) ? confettiOverlay : undefined }), children: _jsx(Box, { component: "div", display: "flex", justifyContent: "center", width: "100%", padding: "4em", children: _jsxs(Stack, { sx: {
                    width: '900px',
                    maxWidth: '900px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 5,
                    p: 10,
                    borderRadius: 3,
                }, bgcolor: (style) => style.palette.container.fill.card1, border: (style) => `1px solid ${style.palette.container.border.default}`, children: [match(props.variant)
                        .with('already_completed', 'success', () => _jsx(Trophy, { sx: iconStyling }))
                        .with('refresher_required', () => _jsx(RefresherIcon, { sx: iconStyling }))
                        .with('failed', 'already_failed', () => countdown ? _jsx(FailedIcon, { sx: iconStyling }) : _jsx(FailedRetryIcon, { sx: iconStyling }))
                        .exhaustive(), _jsx(Typography, { variant: "h1", maxWidth: "635px", textAlign: "center", children: 'canRetryAt' in props ? (countdown ? (_jsx(Trans, { i18nKey: `components.organisms.activityStatus.title.${props.variant}`, values: { timeRemaining: formatDuration(countdown) }, children: _jsx(Typography, { component: "em", sx: titleStyling }) })) : (_jsx(Trans, { i18nKey: `components.organisms.activityStatus.title.cooldown_expired`, children: _jsx(Typography, { component: "em", sx: titleStyling }) }))) : (_jsx(Trans, { i18nKey: `components.organisms.activityStatus.title.${props.variant}`, children: _jsx(Typography, { component: "em", sx: titleStyling }) })) }), _jsx(Typography, { sx: (theme) => ({
                            color: theme.palette.text.body,
                            maxWidth: '620px',
                            textAlign: 'center',
                            textWrap: 'balance',
                        }), variant: "subtitle1", component: "p", children: 'canRetryAt' in props ? (_jsx(Trans, { i18nKey: `components.organisms.activityStatus.descriptionPage.${countdown ? (props.variant === 'already_failed' ? 'failed' : props.variant) : 'cooldown_expired'}` })) : (_jsxs(_Fragment, { children: [t(`components.organisms.activityStatus.descriptionPage.${props.variant}`), props.variant === 'success' && props.onClickBackToActivity !== undefined && (_jsxs(_Fragment, { children: [_jsx("br", {}), t(`components.organisms.activityStatus.review.${props.variant}`)] }))] })) }), match(props)
                        .with({ variant: P.union('already_completed', 'refresher_required') }, (p) => (_jsx(Button, { onClick: () => {
                            analytics.onRefresherButtonClicked();
                            p.onClickStartRefresher();
                        }, startIcon: _jsx(RefreshRounded, {}), variant: props.variant === 'refresher_required' ? 'contained' : 'text', children: t('components.organisms.activityStatus.startRefresher') })))
                        .with({ variant: 'success' }, (p) => (_jsx(_Fragment, { children: p.onClickBackToActivity && (_jsx(Button, { onClick: () => {
                                var _a;
                                (_a = p.onClickBackToActivity) === null || _a === void 0 ? void 0 : _a.call(p);
                                analytics.onBackToActivityButtonClicked();
                            }, variant: "text", startIcon: _jsx(RemoveRedEyeRounded, {}), children: t('components.organisms.activityStatus.backToActivity') })) })))
                        .with({ variant: P.union('failed', 'already_failed') }, (p) => (_jsx(Button, { onClick: () => {
                            p.onClickReplay();
                            analytics.onReplayButtonClicked();
                        }, variant: countdown ? 'text' : 'contained', startIcon: _jsx(ReplayRounded, {}), disabled: countdown !== 0, children: t('components.organisms.activityStatus.replay') })))
                        .exhaustive()] }) }) }));
};
