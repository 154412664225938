import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import { ANALYTICS_EVENTS } from '../../analytics/constants';

angular.module(MODULE).controller('EditTeamModalController', [
  '$window',
  '$uibModalInstance',
  '$scope',
  '$translate',
  '$rootScope',
  '$location',
  '$swal',
  'company',
  'team',
  'AuthService',
  'AdminApiService',
  'LanguageUtils',
  'USER_ROLES',
  'ErrorHandler',
  'AnalyticsService',
  function (
    $window,
    $uibModalInstance,
    $scope,
    $translate,
    $rootScope,
    $location,
    $swal,
    company,
    team,
    AuthService,
    AdminApiService,
    LanguageUtils,
    USER_ROLES,
    ErrorHandler,
    AnalyticsService
  ) {
    // @tmp
    $window.editTeam = $scope;

    var metadata = $rootScope.metadata;
    var licenseTypes = metadata.management.moduleLicenses.types;
    company = angular.copy(company);
    team = team
      ? angular.copy(team)
      : {
          _cid: company && company._id,
        };
    if (company) {
      company.languages = company.languages.filter(function (l) {
        return !LanguageUtils.isExclusiveLanguage(l._id, l._framework);
      });
    }

    // Begin: exposed data
    $scope.auth = AuthService;
    $scope.metadata = metadata;
    $scope.moduleLicenseTypes = licenseTypes;
    $scope.auth = AuthService;
    $scope.userRoles = USER_ROLES;
    $scope.forms = {};
    $scope.moduleLicenseTypesList = [];
    $scope.company = company;
    $scope.team = team;

    $scope.languageList = LanguageUtils.languageList();
    $scope.addAllLanguages = addAllLanguages;
    $scope.apiKeys = team.apiKeys;
    $scope.apicenter = $window.SCW_ENV.ApiEndpoint + '/api/';

    $scope.dateOptions = {
      startingDay: 1,
      showWeeks: false,
      clearText: 'Clear',
    };
    // End: exposed data

    // Begin: exposed methods
    /* Default population of the dates */
    $scope.forms = {
      expirationDate: (team.expirationDate && new Date(team.expirationDate)) || null,
      activationDate: (team.activationDate && new Date(team.activationDate)) || null,
    };

    $scope.generateTeamAPIKey = generateTeamAPIKey;
    $scope.disableTeamAPIAccess = disableTeamAPIAccess;
    $scope.save = saveTeam;
    $scope.cancel = cancel;
    $scope.logFieldChange = logFieldChange;
    // End: exposed methods;

    AnalyticsService.logPageViewEvent($location.absUrl(), 'Add Team Modal', 'modal');

    $scope.$watch(
      'forms.expirationDate',
      function (_newValue, _oldValue, scope) {
        scope.form.activationDate && scope.form.activationDate.$validate();
      },
      true
    );
    $scope.$watch(
      'forms.activationDate',
      function (_newValue, _oldValue, scope) {
        scope.form.expirationDate && scope.form.expirationDate.$validate();
      },
      true
    );

    function init() {
      var license;
      for (license in licenseTypes) {
        $scope.moduleLicenseTypesList.push(licenseTypes[license]);
      }

      if (team._cid) {
        team.allowances = team.allowances || {};
        var modules = metadata.management.moduleLicenses.modules; // ['assessments'];
        for (var i = 0; i < modules.length; i++) {
          var module = modules[i];
          team.allowances[module] =
            team.allowances[module] || angular.copy(metadata.management.moduleLicenses.defaults);
          if (module === 'sensei' && !team._id) {
            /* only if this modal = create modal */
            team.allowances[module].license = metadata.management.moduleLicenses.types.DISABLED;
          }
        }

        if (company) $scope.languageList = company.languages;
      }

      // Display all the languages in the selection box when the team.languages is an empty array
      if (team && team.languages && team.languages.length === 0) {
        team.languages = $scope.languageList;
      }
    }
    // Add all languages to the language selection input field.
    function addAllLanguages() {
      team.languages = $scope.languageList;
      $scope.logFieldChange('allowed-languages', null, true);
    }
    function saveTeam() {
      // Testing whether form is valid but also trigger the inner date-range-selector function to check the dates are valid
      if (!$scope.form.$valid) {
        return;
      }
      saveTeam.inprogress = true;

      team.languages = team.languages || [];

      // Make the team languages empty if they are equal to the available languages.
      if (team.languages === $scope.languageList) {
        team.languages = [];
      }

      const modules = metadata.management.moduleLicenses.modules;
      let requestGrantedLicenses = [];
      for (const module of modules) {
        requestGrantedLicenses[module] = team.allowances[module].granted;
      }

      team.expirationDate = null;
      team.activationDate = null;

      if (!$scope.forms.isAlwaysActive) {
        if ($scope.forms.activationDate) team.activationDate = $scope.forms.activationDate.valueOf();
        if ($scope.forms.expirationDate)
          team.expirationDate = moment($scope.forms.expirationDate).endOf('day').valueOf();
      }

      team.onboardingMessage = team.onboardingMessage || '';
      if (team.company) delete team.company;

      const saveMethod = team._id ? 'updateTeam' : 'addTeam';

      return AdminApiService[saveMethod](team)
        .then(function (saved) {
          const { _id: teamId, _cid, ...data } = saved; // _cid is unused and is deconstructed in order to exclude it from fields_changed
          const props = {
            ...AnalyticsService.getRouteProps(),
            ...{
              name: saveMethod === 'updateTeam' ? 'edit team' : 'add team',
              fields_changed: { teamId, ...data },
              interaction: 'click',
              type: 'button',
            },
          };
          AnalyticsService.logEvent(ANALYTICS_EVENTS.General.ADD_TEAM, props);
          $uibModalInstance.close(saved);

          if (typeof saved.allowances === 'object') {
            const licenseGrantWarnings = [];
            for (const module of Object.keys(saved.allowances)) {
              const granted = saved.allowances[module].granted;
              if (
                granted !== undefined &&
                requestGrantedLicenses[module] !== undefined &&
                requestGrantedLicenses[module] !== granted
              ) {
                licenseGrantWarnings.push(
                  $translate.instant('WARNING_GRANTED_LICENSES_MISMATCH', {
                    requested: requestGrantedLicenses[module],
                    used: granted,
                    module,
                  })
                );
              }
            }
            if (licenseGrantWarnings.length) {
              $swal({
                type: 'warning',
                html: true,
                title: '',
                text: licenseGrantWarnings.join('<br><br>'),
              });
            }
          }
        })
        .catch(function (response) {
          if (response && response.data && response.data.details && response.data.details.validationErrors) {
            if (
              response.data.details.validationErrors[0].message === 'must have expiration date after activation date'
            ) {
              //checking for a specific error only that has reported a translation issue
              response.data.details.validationErrors[0].message = $translate.instant('EXPIRATION_BEFORE_ACTIVATION');
            }
          }
          var action = team._id ? 'update' : 'create';
          ErrorHandler.addHttpError($translate.instant('FAILED_TO_UPDATE_TEAM', { action: action }), response);
        })
        .finally(function () {
          saveTeam.inprogress = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss(team);
    }

    function logFieldChange(attr, event, force) {
      // Check if target is dirty to prevent event firing on form load (ngModel propagation)
      if (event?.target?.classList.contains('ng-dirty') || force) {
        const props = {
          ...AnalyticsService.getRouteProps(),
          ...{
            name: team._id ? 'edit team' : 'add team',
            fields_name: attr,
            interaction: 'blur',
            type: 'form',
          },
        };
        AnalyticsService.logEvent(ANALYTICS_EVENTS.General.FORM_INTERACTION, props);
      }
    }

    function generateTeamAPIKey() {
      if (!$scope.team.apiKeyLabel) return;
      AdminApiService.generateTeamAPIKey($scope.team._cid, $scope.team._id, $scope.team.apiKeyLabel)
        .then(function (data) {
          $scope.apiKeys.push(data);
          $scope.team.apiKeyLabel = null;

          swal({
            title: $translate.instant('GENERATED_TEAM_API_KEY'),
            text: $translate.instant('GENERATED_API_KEY_MSG', { key: data.apiKey }),
            html: true,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GENERATING_TEAM_API_KEY'), response);
        });
    }

    function disableTeamAPIAccess(apiKey) {
      $translate(['OK', 'DELETE_TEAM_API_KEY', 'DELETE_TEAM_API_KEY_MSG', 'ERROR_DISABLING_TEAM_API_ACCESS']).then(
        function (translations) {
          AdminApiService.disableTeamAPIAccess($scope.team._id, apiKey)
            .then(function (_data) {
              _.remove($scope.apiKeys, function (value) {
                return value._id === apiKey._id;
              });

              swal({
                title: translations.DELETE_TEAM_API_KEY,
                text: translations.DELETE_TEAM_API_KEY_MSG,
                type: 'info',
                showCancelButton: false,
                confirmButtonText: translations.OK,
              });
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_DELETING_TEAM_API_KEY, response);
            });
        }
      );
    }

    init();
  },
]);
