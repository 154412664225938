var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Typography, useTheme } from '@mui/material';
export default function ConfirmationDialog(props) {
    const { confirmButtonLabel, cancelButtonLabel, title, onConfirm, onCancel, children, color = 'default' } = props, rest = __rest(props, ["confirmButtonLabel", "cancelButtonLabel", "title", "onConfirm", "onCancel", "children", "color"]);
    const theme = useTheme();
    return (_jsxs(Dialog, Object.assign({ sx: {
            '& .MuiDialog-paper': {
                minWidth: theme.spacing(125),
                textAlign: 'center',
                backgroundColor: theme.palette.container.fill.card1,
                backgroundImage: 'unset',
                borderRadius: theme.spacing(2),
                border: 'solid',
                borderColor: theme.palette.container.border.default,
                borderWidth: '1px',
                paddingTop: theme.spacing(8),
                paddingBottom: theme.spacing(8),
            },
        }, onClose: onCancel, maxWidth: "xs" }, rest, { children: [_jsx(Box, { sx: {
                    '.MuiSvgIcon-root': {
                        color: color === 'default'
                            ? theme.palette.clickable.nav.item.default
                            : color === 'warning'
                                ? theme.palette.text.status.error
                                : 'unset',
                        fontSize: theme.typography.pxToRem(55),
                    },
                }, children: props.icon }), _jsx(DialogTitle, { variant: "h3", sx: { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }, children: title }), _jsx(DialogContent, { children: _jsx(Typography, { component: "p", variant: "subtitle1", sx: {
                        color: theme.palette.text.body,
                        whiteSpace: 'pre-line',
                        paddingBottom: theme.spacing(1),
                    }, children: children }) }), _jsxs(DialogActions, { sx: {
                    justifyContent: 'space-between',
                    paddingLeft: theme.spacing(7),
                    paddingRight: theme.spacing(7),
                    paddingTop: theme.spacing(7),
                    paddingBottom: 0,
                    borderTop: 'solid 1px',
                    borderColor: theme.palette.container.border.default,
                }, children: [_jsx(Button, { variant: "outlined", onClick: onCancel, children: cancelButtonLabel }), _jsx(Button, { variant: "contained", color: color, onClick: onConfirm, children: confirmButtonLabel })] })] })));
}
