import angular from 'angular';
import MODULE from './module';

(function () {
  var app = angular.module(MODULE);

  app.controller('Game013RealmController', [
    '$scope',
    '$state',
    '$stateParams',
    'Game013StateService',
    function ($scope, $state, $stateParams, Game013StateService) {
      $scope.LEVEL_STATUS = {
        locked: 'locked',
        active: 'active',
        available: 'available',
        complete: 'complete',
      };

      Game013StateService.setActiveState($stateParams);

      $scope.goToLevel = function (_level) {
        Game013StateService.active._level = _level;
        $state.go('game.013.play.level', Game013StateService.active);
      };

      $scope.$gameStatusPromise.then(function () {
        var story = $scope.gameData.story;
        $scope.gameData.realmStory = $scope.findById(story.realms, Game013StateService.active._realm);
      });
    },
  ]);
})();
