import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
export const CardTopStatus = (props) => {
    return (_jsx(Typography, { variant: "caption", sx: (theme) => ({
            borderRadius: `0 0 ${theme.shape.border.radius.sm} ${theme.shape.border.radius.sm}`,
            padding: theme.spacing(1, 2),
            color: theme.palette.badge.text,
            backgroundColor: props.fill,
            border: `1px solid ${props.borderColor}`,
            marginTop: '-1px',
        }), children: props.children }));
};
