import { styled } from '@mui/material';
import { fontFamily } from '@securecodewarrior/design-system-react';
import { TextInput as DSTextInput } from '@securecodewarrior/design-system-react/lib/legacy';
// TODO: this should probably be replaced with the wanda design system component instead
export const TextInput = styled(DSTextInput)(({ theme }) => ({
    '.MuiInputBase-root': {
        borderRadius: theme.typography.pxToRem(12),
        paddingLeft: theme.typography.pxToRem(16),
        fontFamily: fontFamily.mona,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 300,
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: theme.typography.pxToRem(0.25),
        background: theme.palette.container.fill.card1,
        height: theme.typography.pxToRem(50),
        fieldset: {
            borderColor: theme.palette.container.border.input,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.container.border.active,
        },
    },
    '.MuiInputBase-input': {
        padding: theme.typography.pxToRem(10),
        paddingLeft: 0,
        '&::placeholder': {
            color: theme.palette.text.soft,
            opacity: 1,
        },
    },
}));
export default TextInput;
