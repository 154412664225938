import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).directive('userPreferences', [
  '$timeout',
  '$log',
  'UserPreferences',
  function ($timeout, $log, UserPreferences) {
    return {
      restrict: 'A',
      replace: false,
      link: function (scope, _element, attributes, _controller) {
        var configuration;
        var defaults =
          attributes.userPreferencesDefaults && JSON.parse(attributes.userPreferencesDefaults.replace(/'/g, '"'));
        var attribute, preference, current;

        try {
          // increase tolerance to javascript notation (not pure json)
          configuration = attributes.userPreferences.replace(/([\w_.]+):/gi, '"$1":');
          configuration = JSON.parse(attributes.userPreferences.replace(/'/g, '"'));
        } catch (err) {
          $log.error(
            'Error processing user preferences instruction in user-preferences directive.',
            configuration,
            attributes.userPreferences
          );
        }

        for (attribute in configuration) {
          preference = configuration[attribute];
          current = UserPreferences.get(preference, defaults && defaults[attribute]);

          _.set(scope, attribute, current);

          $timeout(function () {
            scope.$watch(attribute, function (n, o) {
              if (n !== undefined && n !== o)
                UserPreferences.save(preference, n).then(function () {
                  // $log.debug("Saved preference "+preference, n);
                });
            });
          }, 100);
        }
      },
    };
  },
]);
