import angular from 'angular';
import moment from 'moment-timezone';

const app = angular.module('SCWActivityTracker', []);
export default app.name;

var PING_INTERVAL = 15 * 1000; // quarter of a minute

app.service('ActivityTracker', [
  '$http',
  '$interval',
  '$window',
  '$location',
  '$log',
  'HttpConfigService',
  'Session',
  function ($http, $interval, $window, $location, $log, HttpConfigService, Session) {
    var hasFocus = true;
    var active = true; // not set by init but rather by inactivity directive
    return {
      pingState: function (inactiveState) {
        if (inactiveState != undefined) {
          active = inactiveState;
        }
      },
      startPing: function () {
        $interval(function () {
          if ((document.hasFocus() || hasFocus) && Session.user && active) {
            $log.debug(moment().format('LTS') + ' | Sending activity log');
            $http
              .post(
                $window.SCW_ENV.ApiEndpoint + '/activity/log',
                {
                  url: $location.url(),
                },
                HttpConfigService.getHttpConfig()
              )
              .then(function (_response) {})
              .catch(function (_err) {});
          } else {
            $log.debug(moment().format('LTS') + ' | Not focussed, not sending activity log');
          }
        }, PING_INTERVAL);
      },
      init: function () {
        $window.onfocus = function () {
          hasFocus = true;
        };
        $window.onblur = function () {
          hasFocus = false;
        };
        this.startPing();
      },
    };
  },
]);
