import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Unsubscribe } from './Unsubscribe';
import { useLocation } from 'react-router-dom';
export const UnsubscribeRoute = () => {
    const location = useLocation();
    const [token, setToken] = useState('');
    const [apiEndPoint, setApiEndpoint] = useState('');
    const [locale, setLocale] = useState('');
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const unsubscribeToken = queryParams.get('token');
        const userLocale = queryParams.get('locale') || 'en-US';
        if (unsubscribeToken) {
            setToken(unsubscribeToken);
        }
        let region = queryParams.get('region');
        if (!['EU', 'US'].includes(region !== null && region !== void 0 ? region : '')) {
            region = 'US';
        }
        // Set the locale state
        setLocale(userLocale);
        if (region) {
            // @ts-expect-error No type definitions for SCW_ENV (in this module)
            const { SCW_ENV } = global;
            if (SCW_ENV &&
                SCW_ENV.ApiEndpoints &&
                SCW_ENV.API_ENDPOINT_INDEXES &&
                SCW_ENV.API_ENDPOINT_INDEXES[region] !== undefined) {
                setApiEndpoint(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES[region]]);
            }
            else {
                console.warn('Unable to set API endpoint due to missing values');
            }
        }
    }, [location]);
    return _jsx(Unsubscribe, { token: token, apiEndPoint: apiEndPoint, platformLocale: locale });
};
