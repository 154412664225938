import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuBookRounded } from '@mui/icons-material';
import { Stack, SvgIcon } from '@mui/material';
export const LearnIcon = (props) => {
    return (_jsxs(Stack, { direction: "row", sx: (theme) => ({
            width: props.width,
            height: props.height,
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.soft,
            position: 'relative',
        }), children: [_jsx(SvgIcon, Object.assign({}, props, { sx: {
                    width: props.width,
                    height: props.height,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "216", height: "150", viewBox: "0 0 216 150", fill: "none", children: [_jsx("mask", { id: "mask0_1910_12226", maskUnits: "userSpaceOnUse", type: "luminance", x: "0", y: "0", width: "216", height: "150", children: _jsx("path", { d: "M215.861 0H0.13916V150H215.861V0Z", fill: "white" }) }), _jsxs("g", { mask: "url(#mask0_1910_12226)", children: [_jsx("path", { d: "M176.855 9.93451C178.8 9.93451 180.376 8.36292 180.376 6.42426C180.376 4.4856 178.8 2.914 176.855 2.914C174.911 2.914 173.335 4.4856 173.335 6.42426C173.335 8.36292 174.911 9.93451 176.855 9.93451Z", fill: "#7058F3" }), _jsx("path", { d: "M196.185 50.9139C198.129 50.9139 199.705 49.3423 199.705 47.4036C199.705 45.465 198.129 43.8934 196.185 43.8934C194.241 43.8934 192.665 45.465 192.665 47.4036C192.665 49.3423 194.241 50.9139 196.185 50.9139Z", fill: "#0BCCE2" }), _jsx("path", { d: "M210.107 16.8934C212.908 16.8934 215.178 14.6294 215.178 11.8367C215.178 9.04393 212.908 6.77997 210.107 6.77997C207.307 6.77997 205.036 9.04393 205.036 11.8367C205.036 14.6294 207.307 16.8934 210.107 16.8934Z", fill: "#FFBE12" }), _jsx("path", { d: "M23.7624 69.4706C25.7065 69.4706 27.2826 67.899 27.2826 65.9603C27.2826 64.0217 25.7065 62.4501 23.7624 62.4501C21.8182 62.4501 20.2422 64.0217 20.2422 65.9603C20.2422 67.899 21.8182 69.4706 23.7624 69.4706Z", fill: "#7058F3" }), _jsx("path", { d: "M26.0822 105.038C28.0264 105.038 29.6024 103.466 29.6024 101.528C29.6024 99.5889 28.0264 98.0173 26.0822 98.0173C24.1381 98.0173 22.562 99.5889 22.562 101.528C22.562 103.466 24.1381 105.038 26.0822 105.038Z", fill: "#FFBE12" }), _jsx("path", { d: "M4.77834 93.3781C7.34048 93.3781 9.41751 91.3011 9.41751 88.7389C9.41751 86.1768 7.34048 84.0997 4.77834 84.0997C2.21619 84.0997 0.13916 86.1768 0.13916 88.7389C0.13916 91.3011 2.21619 93.3781 4.77834 93.3781Z", fill: "#0BCCE2" })] })] }) })), _jsx(Stack, { sx: (theme) => ({
                    borderRadius: '50%',
                    border: `1px solid ${theme.palette.container.fill.card2}`,
                    backgroundColor: theme.palette.container.fill.body,
                    width: theme.typography.pxToRem(140),
                    height: theme.typography.pxToRem(140),
                    justifyContent: 'center',
                    alignItems: 'center',
                }), children: _jsx(MenuBookRounded, { sx: (theme) => ({
                        fontSize: theme.typography.pxToRem(80),
                    }) }) })] }));
};
