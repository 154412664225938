import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { allowError, failed, flattenErrors } from './Inputs';
import { produce } from 'immer';
import { mapValues, filterKeys, record, trustFromEntries, trustingEntries, trustingKeys } from '../../utils/object';
import { group } from '../../utils/Array';
import { getAssessmentSettingsValidation } from './validation';
import { modeNumericNullable } from './programmigration';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
export class ErrorWithStoredValue extends Error {
    constructor(message, lastGoodValue, currentValue) {
        super(message);
        Object.defineProperty(this, "lastGoodValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: lastGoodValue
        });
        Object.defineProperty(this, "currentValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: currentValue
        });
    }
}
export function getCurrentStoredValue(x) {
    return x instanceof ErrorWithStoredValue ? x.currentValue : x;
}
export function getLastGoodValue(x) {
    return x instanceof ErrorWithStoredValue ? x.lastGoodValue : x;
}
dayjs.extend(utc);
dayjs.extend(timezone);
export const dateFields = ['startDate', 'endDate', 'sendInvitesOn'];
const updateAssessmentsInPlace = (draft, wasTimeLimitPerChallenge, isTimeLimitPerChallenge, defaultSettings, update, nameUpdate, assessmentsById
// eslint-disable-next-line sonarjs/cognitive-complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
) => {
    var _a;
    const fields = trustingKeys(update);
    for (const settings of Object.values(draft)) {
        if (nameUpdate && settings.name.startsWith(nameUpdate.current)) {
            settings.name = nameUpdate.update + settings.name.slice(nameUpdate.current.length);
        }
        if ('timezone' in update &&
            !('startDate' in update) &&
            settings.assessmentSettings.startDate &&
            settings.assessmentSettings.timezone === defaultSettings.timezone) {
            settings.assessmentSettings.startDate = adjustUTCDateForTimeZoneConversion(settings.assessmentSettings.startDate, settings.assessmentSettings.timezone, update.timezone);
        }
        if ('timezone' in update &&
            !('endDate' in update) &&
            settings.assessmentSettings.endDate &&
            settings.assessmentSettings.timezone === defaultSettings.timezone) {
            settings.assessmentSettings.endDate = adjustUTCDateForTimeZoneConversion(settings.assessmentSettings.endDate, settings.assessmentSettings.timezone, update.timezone);
        }
        if ('timezone' in update &&
            !('sendInvitesOn' in update) &&
            settings.assessmentSettings.sendInvitesOn &&
            settings.assessmentSettings.timezone === defaultSettings.timezone) {
            settings.assessmentSettings.sendInvitesOn = adjustUTCDateForTimeZoneConversion(settings.assessmentSettings.sendInvitesOn, settings.assessmentSettings.timezone, update.timezone);
        }
        const numberOfChallenges = assessmentsById[settings._id].assessmentContent.numberOfChallenges;
        for (const field of fields) {
            const defaultTimeLimit = defaultSettings.timeLimit instanceof ErrorWithStoredValue
                ? defaultSettings.timeLimit.lastGoodValue
                : defaultSettings.timeLimit;
            const lastValue = defaultSettings[field];
            const lastGoodValue = lastValue instanceof ErrorWithStoredValue ? lastValue.lastGoodValue : lastValue;
            if (field === 'timeLimit') {
                if (update.timeLimit &&
                    !failed(update.timeLimit) &&
                    update.timeLimit !== null &&
                    normalizedTimeLimit(wasTimeLimitPerChallenge, settings.assessmentSettings.timeLimit, {
                        numberOfChallenges,
                    }) === defaultTimeLimit) {
                    settings.assessmentSettings.timeLimit = update.timeLimit
                        ? update.timeLimit * (isTimeLimitPerChallenge ? numberOfChallenges : 1)
                        : (_a = update.timeLimit) !== null && _a !== void 0 ? _a : null;
                }
            }
            else if ((field === 'startDate' || field === 'endDate' || field === 'sendInvitesOn') &&
                `${settings.assessmentSettings[field]}` === `${lastGoodValue}`) {
                const tm = update[field];
                if (!failed(tm)) {
                    copyField(field, settings.assessmentSettings, {
                        [field]: tm
                            ? adjustUTCDateForTimeZoneConversion(tm, defaultSettings.timezone, settings.assessmentSettings.timezone)
                            : null,
                    });
                }
            }
            else if (!failed(update[field]) && settings.assessmentSettings[field] === lastGoodValue) {
                copyField(field, settings.assessmentSettings, update);
            }
        }
    }
};
export const useEditorState = (multiassessment, assessments
// eslint-disable-next-line sonarjs/cognitive-complexity  --  This function is a bit complex but it's isolating the main complexity of the editor in one place
) => {
    const [persistedRecord, setPersistedRecord] = useState(multiassessment);
    const [persistedAssessments, setPersistedAssessments] = useState(assessments);
    const initialValues = useMemo(() => (Object.assign(Object.assign({}, multiAssessmentDefaults), persistedRecord)), [persistedRecord]);
    const [editBuffer, setEditBuffer] = useEditBufferState(initialValues, persistedAssessments);
    const assessmentsById = useMemo(() => trustFromEntries(persistedAssessments.map((a) => [a._id, a])), [persistedAssessments]);
    const assessmentsByAssessment = useMemo(() => group(persistedAssessments, (a) => a._assessment), [persistedAssessments]);
    const { t } = useTranslation();
    const updateSettings = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (update) => {
        const update_ = update;
        setEditBuffer((currentEditBuffer) => {
            const update = 'timeLimitIsPerChallenge' in update_ &&
                (!('assessmentSettings' in update_) || !('timeLimit' in update_.assessmentSettings))
                ? produce(update_, (draft) => {
                    // The admin is toggling the time limit per challenge setting
                    // so we need to update the time limit setting to a comparable value
                    draft.assessmentSettings || (draft.assessmentSettings = {});
                    draft.assessmentSettings.timeLimit = getCommonTimeLimit(update_.timeLimitIsPerChallenge, Object.values(currentEditBuffer.perAssessmentSettings), assessmentsById);
                })
                : update_;
            // current.assessmentSettings.timeLimit = commonValue;
            const withUpdatesApplied = produce(currentEditBuffer, (draft) => {
                const current = draft.defaultSettings;
                if ('name' in update) {
                    current.name = update.name;
                }
                if ('assessmentSettings' in update) {
                    const currentStartDate = current.assessmentSettings.startDate;
                    const currentEndDate = current.assessmentSettings.endDate;
                    const currentSendInvitesOn = current.assessmentSettings.sendInvitesOn;
                    // Rectify clocks when changing time zones so that the local times do not jump around
                    if ('timezone' in update.assessmentSettings &&
                        !('startDate' in update.assessmentSettings) &&
                        currentStartDate &&
                        !failed(currentStartDate)) {
                        current.assessmentSettings.startDate = adjustUTCDateForTimeZoneConversion(currentStartDate, current.assessmentSettings.timezone, update.assessmentSettings.timezone);
                    }
                    if ('timezone' in update.assessmentSettings &&
                        !('endDate' in update.assessmentSettings) &&
                        currentEndDate &&
                        !failed(currentEndDate)) {
                        current.assessmentSettings.endDate = adjustUTCDateForTimeZoneConversion(currentEndDate, current.assessmentSettings.timezone, update.assessmentSettings.timezone);
                    }
                    if ('timezone' in update.assessmentSettings &&
                        !('sendInvitesOn' in update.assessmentSettings) &&
                        currentSendInvitesOn &&
                        !failed(currentSendInvitesOn)) {
                        current.assessmentSettings.sendInvitesOn = adjustUTCDateForTimeZoneConversion(currentSendInvitesOn, current.assessmentSettings.timezone, update.assessmentSettings.timezone);
                    }
                    for (const kv of trustingEntries(update.assessmentSettings)) {
                        setField(kv[0], current.assessmentSettings, kv[1]);
                    }
                }
                if ('timeLimitIsPerChallenge' in update) {
                    current.timeLimitIsPerChallenge = update.timeLimitIsPerChallenge;
                }
            });
            const commonSettingsErrors = getAssessmentSettingsValidation(withUpdatesApplied.defaultSettings.assessmentSettings, withUpdatesApplied.defaultSettings.name, t);
            const errorFields = new Set(commonSettingsErrors.map((e) => e.field));
            const currentCommonSettingsErrors = getAssessmentSettingsValidation(currentEditBuffer.defaultSettings.assessmentSettings, currentEditBuffer.defaultSettings.name, t);
            const currentDefaultsWithValidationApplied = produce(currentEditBuffer, (draft) => {
                currentCommonSettingsErrors.forEach((error) => {
                    if (error.field === 'name') {
                        draft.defaultSettings.name = new ErrorWithStoredValue(error.message, failed(draft.defaultSettings.name)
                            ? draft.defaultSettings.name.currentValue
                            : draft.defaultSettings.name, failed(draft.defaultSettings.name)
                            ? draft.defaultSettings.name.lastGoodValue
                            : draft.defaultSettings.name);
                    }
                    else {
                        injectErrorInPlace(draft.defaultSettings.assessmentSettings, currentEditBuffer.defaultSettings.assessmentSettings, error.field, error.message);
                    }
                });
            });
            const fieldsToUpdate = filterKeys(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (update.assessmentSettings || {}), 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (k, v) => !failed(v) && !errorFields.has(k)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            );
            const currentErrorFields = trustingEntries(currentEditBuffer.defaultSettings.assessmentSettings)
                .filter(([, v]) => v instanceof Error)
                .map(([k]) => k);
            const fixedFields = currentErrorFields.filter((f) => !errorFields.has(f));
            for (const field of fixedFields) {
                if (!(field in fieldsToUpdate)) {
                    fieldsToUpdate[field] = getCurrentStoredValue(currentEditBuffer.defaultSettings.assessmentSettings[field]);
                }
            }
            return produce(withUpdatesApplied, (draft) => {
                for (const field of fixedFields) {
                    clearErrorInPlace(draft.defaultSettings.assessmentSettings, field);
                }
                commonSettingsErrors.forEach((error) => {
                    if (error.field === 'name') {
                        draft.defaultSettings.name = new ErrorWithStoredValue(error.message, getCurrentStoredValue(currentEditBuffer.defaultSettings.name), getLastGoodValue(currentEditBuffer.defaultSettings.name));
                    }
                    else {
                        injectErrorInPlace(draft.defaultSettings.assessmentSettings, currentEditBuffer.defaultSettings.assessmentSettings, error.field, error.message);
                    }
                });
                if (update.assessmentSettings || 'name' in update) {
                    const shouldUpdateName = 'name' in update && typeof update.name === 'string';
                    const prevUsedName = editBuffer.defaultSettings.name instanceof ErrorWithStoredValue
                        ? editBuffer.defaultSettings.name.currentValue
                        : editBuffer.defaultSettings.name;
                    updateAssessmentsInPlace(draft.perAssessmentSettings, currentDefaultsWithValidationApplied.defaultSettings.timeLimitIsPerChallenge, withUpdatesApplied.defaultSettings.timeLimitIsPerChallenge, currentDefaultsWithValidationApplied.defaultSettings.assessmentSettings, fieldsToUpdate, shouldUpdateName ? { current: prevUsedName, update: update.name } : undefined, assessmentsById);
                }
            });
        });
    }, [assessmentsById, editBuffer.defaultSettings.name, setEditBuffer, t]);
    const validated = flattenErrors(editBuffer.defaultSettings);
    const assessmentSettingsValidations = mapValues(editBuffer.perAssessmentSettings, (v) => getAssessmentSettingsValidation(v.assessmentSettings, v.name, t));
    const commonSettings = Object.assign(Object.assign(Object.assign({}, initialValues), editBuffer.defaultSettings), { assessmentSettings: Object.assign(Object.assign({}, initialValues.assessmentSettings), editBuffer.defaultSettings.assessmentSettings) });
    const resetProperty_ = (key, property, value
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => setEditBuffer(produce((draft) => {
        const current = draft.perAssessmentSettings;
        if (value === undefined) {
            value || (value = draft.defaultSettings.assessmentSettings[property]);
        }
        if (property === 'timezone' &&
            current[key._assessment].assessmentSettings.timezone !== draft.defaultSettings.assessmentSettings.timezone &&
            typeof value === 'string') {
            const { startDate, endDate, sendInvitesOn } = current[key._assessment].assessmentSettings;
            if (startDate) {
                current[key._assessment].assessmentSettings.startDate = adjustUTCDateForTimeZoneConversion(startDate, current[key._assessment].assessmentSettings.timezone, value);
            }
            if (endDate) {
                current[key._assessment].assessmentSettings.endDate = adjustUTCDateForTimeZoneConversion(endDate, current[key._assessment].assessmentSettings.timezone, value);
            }
            if (sendInvitesOn) {
                current[key._assessment].assessmentSettings.sendInvitesOn = adjustUTCDateForTimeZoneConversion(sendInvitesOn, current[key._assessment].assessmentSettings.timezone, value);
            }
        }
        if (property === 'timeLimit' && draft.defaultSettings.timeLimitIsPerChallenge && value && !failed(value)) {
            const latest = assessmentsByAssessment[key._assessment].sort((a, b) => b.version - a.version)[0];
            setFieldIfNotError(property, current[key._assessment].assessmentSettings, value * latest.assessmentContent.numberOfChallenges);
        }
        else if (dateFields.includes(property)) {
            setFieldIfNotError(property, current[key._assessment].assessmentSettings, value instanceof Date
                ? adjustUTCDateForTimeZoneConversion(value, draft.defaultSettings.assessmentSettings.timezone, current[key._assessment].assessmentSettings.timezone)
                : value);
        }
        else {
            setFieldIfNotError(property, current[key._assessment].assessmentSettings, value);
        }
        if (property === '_certificateTemplate') {
            const emitsCertificateValue = draft.defaultSettings.assessmentSettings.emitsCertificate;
            setFieldIfNotError('emitsCertificate', current[key._assessment].assessmentSettings, emitsCertificateValue);
        }
    }));
    const overrideProperty_ = (key, property, value) => setEditBuffer((currentEditBuffer) => (Object.assign(Object.assign({}, currentEditBuffer), { assessmentSettings: produce(currentEditBuffer.perAssessmentSettings, (current) => {
            setFieldIfNotError(property, current[key._assessment].assessmentSettings, value);
        }) })));
    const resetProperty = useCallback(resetProperty_, [assessmentsByAssessment, setEditBuffer]);
    const overrideProperty = useCallback(overrideProperty_, [setEditBuffer]);
    const revertChanges = useCallback(() => {
        setPersistedRecord((r) => (r ? Object.assign({}, r) : undefined));
        setPersistedAssessments((a) => [...a]);
    }, [setPersistedRecord]);
    return {
        updateSettings,
        validated,
        assessmentSettingsValidations,
        editBuffer: editBuffer.defaultSettings,
        assessmentSettingsEditBuffer: editBuffer.perAssessmentSettings,
        commonSettings,
        persistedRecord,
        persistedAssessments,
        setPersistedRecord,
        setPersistedAssessments,
        assessments: persistedAssessments,
        // addAssessment,
        // removeAssessment,
        resetProperty,
        overrideProperty,
        revertChanges,
    };
};
function getCommonTimeLimit(timeLimitIsPerChallenge, assessmentSettings, assessmentsById) {
    const assessmentTimeLimits = assessmentSettings
        .map((a) => normalizedTimeLimit(timeLimitIsPerChallenge, a.assessmentSettings.timeLimit, assessmentsById[a._id].assessmentContent))
        .filter((t) => !failed(t));
    return modeNumericNullable(assessmentTimeLimits);
}
function injectErrorInPlace(settings, previousSettings, field, message) {
    const val = settings[field];
    const pval = getCurrentStoredValue(previousSettings[field]);
    const lgval = getLastGoodValue(settings[field]);
    if (!(val instanceof Error)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = new ErrorWithStoredValue(message, pval, lgval);
        settings[field] = err;
    }
}
function clearErrorInPlace(settings, field) {
    const val = settings[field];
    if (val instanceof ErrorWithStoredValue) {
        settings[field] = val.currentValue;
    }
}
export function computeOverrides(editBuffer, assessmentSettingsEditBuffer, assessments) {
    const assessmentsInPlay = Object.values(assessmentSettingsEditBuffer).filter((a) => editBuffer.assessments.map((a) => a._assessment).includes(a._assessment));
    const assessmentContent = assessments.reduce((acc, settings) => (Object.assign(Object.assign({}, acc), { [settings._id]: settings })), {});
    const normalized = assessmentsInPlay.map((a) => produce(a, (a) => {
        a.assessmentSettings.timeLimit = normalizedTimeLimit(editBuffer.timeLimitIsPerChallenge, a.assessmentSettings.timeLimit, assessmentContent[a._id].assessmentContent);
        if (!a.assessmentSettings.emitsCertificate) {
            a.assessmentSettings._certificateTemplate = null;
        }
    }));
    function overrideEntry(field, value) {
        return record(field, [
            ...new Set(normalized
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((a) => `${a.assessmentSettings[field]}` !== `${getLastGoodValue(value)}`)
                .map((a) => a._assessment)),
        ]);
    }
    const es = trustingEntries(editBuffer.assessmentSettings);
    const overrideCountEntries = es.filter((e) => e[0] !== 'emitsCertificate').map((e) => overrideEntry(e[0], e[1]));
    const overrideCounts = overrideCountEntries.reduce((acc, v) => (Object.assign(Object.assign({}, acc), v)), {});
    return trustingEntries(overrideCounts).filter(([, as]) => as.length > 0);
}
// The reason d'etre of this function is to update the field of the settings object in a manner
// so that Typescript understands that the type of the source and target fields is the same
const copyField = (field, settings, update) => {
    if (!(update[field] instanceof Error)) {
        settings[field] = update[field];
    }
};
// The reason d'etre of this function is to update the field of the settings object in a manner
// so that Typescript understands that the type of the source and target fields is the same
const setField = (field, settings, value) => {
    if (value instanceof Error) {
        //@ts-expect-error -- TBD
        settings[field] = new ErrorWithStoredValue(value.message, getCurrentFieldValue(field, settings), getLastGoodFieldValue(field, settings));
    }
    else {
        //@ts-expect-error -- TBD
        settings[field] = value;
    }
};
function getCurrentFieldValue(field, settings) {
    const v = settings[field];
    return v instanceof ErrorWithStoredValue ? v.currentValue : v;
}
function getLastGoodFieldValue(field, settings) {
    const v = settings[field];
    return v instanceof ErrorWithStoredValue ? v.lastGoodValue : v;
}
// The reason d'etre of this function is to update the field of the settings object in a manner
// so that Typescript understands that the type of the source and target fields is the same
const setFieldIfNotError = (field, settings, value) => {
    if (!failed(value)) {
        settings[field] = value;
    }
};
export const multiAssessmentDefaults = {
    name: 'New Multi Assessment',
    timeLimitIsPerChallenge: true,
    assessmentSettings: {
        successRatio: null,
        maxRetries: null,
        startDate: null,
        endDate: null,
        timeLimit: null,
        emitsCertificate: false,
        _certificateTemplate: null,
        retryWaitingHours: null,
        timezone: 'Europe/London',
        lmsIntegrated: false,
        levelGrouping: null,
        fixedChallenges: false,
        selfAssess: false,
        sendInvitesOn: null,
    },
    assessments: [],
    assessmentRecordsMd5: '',
};
function editBufferForAssessment(settings) {
    return {
        _id: settings._id,
        _assessment: settings._assessment,
        version: settings.version,
        name: settings.name,
        assessmentSettings: {
            successRatio: settings.assessmentSettings.successRatio,
            maxRetries: settings.assessmentSettings.maxRetries,
            startDate: settings.assessmentSettings.startDate,
            endDate: settings.assessmentSettings.endDate,
            timeLimit: settings.assessmentSettings.timeLimit,
            emitsCertificate: settings.assessmentSettings.emitsCertificate,
            _certificateTemplate: settings.assessmentSettings._certificateTemplate,
            retryWaitingHours: settings.assessmentSettings.retryWaitingHours,
            timezone: settings.assessmentSettings.timezone,
            lmsIntegrated: settings.assessmentSettings.lmsIntegrated,
            levelGrouping: settings.assessmentSettings.levelGrouping,
            fixedChallenges: settings.assessmentSettings.fixedChallenges,
            selfAssess: settings.assessmentSettings.selfAssess,
            sendInvitesOn: settings.assessmentSettings.sendInvitesOn,
        },
    };
}
export function normalizedTimeLimit(isPerChallenge, timeLimit, content, accuracy = 1000) {
    if (timeLimit instanceof Error)
        return timeLimit;
    if (!timeLimit)
        return timeLimit;
    if (!isPerChallenge)
        return Math.round(timeLimit / accuracy) * 1000;
    return Math.round(timeLimit / content.numberOfChallenges / accuracy) * 1000;
}
function useEditBufferState(initialValues, assessments) {
    const defaultSettings = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return ({
            assessments: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessments) || [],
            name: allowError(initialValues === null || initialValues === void 0 ? void 0 : initialValues.name),
            timeLimitIsPerChallenge: initialValues === null || initialValues === void 0 ? void 0 : initialValues.timeLimitIsPerChallenge,
            assessmentSettings: {
                successRatio: allowError((_a = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _a === void 0 ? void 0 : _a.successRatio),
                maxRetries: allowError((_b = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _b === void 0 ? void 0 : _b.maxRetries),
                startDate: allowError((_c = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _c === void 0 ? void 0 : _c.startDate),
                endDate: allowError((_d = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _d === void 0 ? void 0 : _d.endDate),
                timeLimit: allowError((_e = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _e === void 0 ? void 0 : _e.timeLimit),
                emitsCertificate: !!((_f = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _f === void 0 ? void 0 : _f.emitsCertificate),
                _certificateTemplate: allowError((_g = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _g === void 0 ? void 0 : _g._certificateTemplate),
                retryWaitingHours: allowError((_h = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _h === void 0 ? void 0 : _h.retryWaitingHours),
                timezone: (_j = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _j === void 0 ? void 0 : _j.timezone,
                fixedChallenges: (_k = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _k === void 0 ? void 0 : _k.fixedChallenges,
                selfAssess: (_l = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _l === void 0 ? void 0 : _l.selfAssess,
                levelGrouping: allowError((_m = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _m === void 0 ? void 0 : _m.levelGrouping),
                lmsIntegrated: (_o = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _o === void 0 ? void 0 : _o.lmsIntegrated,
                sendInvitesOn: allowError((_p = initialValues === null || initialValues === void 0 ? void 0 : initialValues.assessmentSettings) === null || _p === void 0 ? void 0 : _p.sendInvitesOn),
            },
        });
    }, [initialValues]);
    const perAssessmentSettings = useMemo(() => trustFromEntries(assessments
        .sort((a, b) => a.version - b.version)
        .map((settings) => [settings._assessment, editBufferForAssessment(settings)])), [assessments]);
    const [state, setState] = useState({ defaultSettings, perAssessmentSettings });
    // Reset the state if the input properties change. This happens on eg. "cancel edit" and after applying changes
    // once new data is fetched from the server
    useLayoutEffect(() => setState({ defaultSettings, perAssessmentSettings }), [defaultSettings, perAssessmentSettings]);
    return [state, setState];
}
export const computeReferences = (assessments, references) => {
    const assessmentGroups = group(assessments, (a) => a._assessment);
    const latestPerAssessment = Object.fromEntries(Object.entries(assessmentGroups).map(([k, v]) => [k, v.sort((a, b) => b.version - a.version)[0]]));
    const latestPerId = Object.fromEntries(assessments.map((a) => [a._id, latestPerAssessment[a._assessment]]));
    return references.map((refs) => ({
        refs,
        missingLatest: refs.assessmentIds
            .map((id) => latestPerId[id])
            .filter((a) => a) // skip deleted assessments
            .filter((a) => !refs.assessmentIds.includes(a._id)), // detect missing
    }));
};
export function adjustUTCDateForTimeZoneConversion(date, from, to) {
    return dayjs
        .utc(date)
        .tz(from || 'utc')
        .tz(to || 'utc', true)
        .utc()
        .toDate();
}
