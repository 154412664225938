import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import useVulnerabilitySummariser from '../../hooks/useVulnerabilitySummariser';
import { Box } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/legacy';
const JiraCsvSummaryParser = (props) => {
    const [terminated, setTerminated] = useState(false);
    const { setVulnerabilityInfo, directLinkingApiEndpoint } = props;
    const { file, setFile, abortParsing, error, complete, progress: _progress, } = useVulnerabilitySummariser(setVulnerabilityInfo, directLinkingApiEndpoint, props.translator);
    const onTerminateParse = () => {
        abortParsing.current();
        setTerminated(true);
        props.logEvent('click', {
            name: 'Cancel',
            scope: 'Vulnerability Summary - Processing File',
            type: 'button',
        });
    };
    const isLoading = !!file && !complete && !terminated;
    props.isLoading(isLoading);
    useEffect(() => {
        if (complete) {
            props.logEvent('process', {
                filetype: (file === null || file === void 0 ? void 0 : file.type) || '',
                page_name: 'Vulnerability Summary - Results',
                status: 'completed',
                success: error ? 'no' : 'yes',
            });
            if (!error) {
                props.logEvent('view', {
                    page_name: 'Vulnerability Summary - Results',
                    type: 'modal',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complete]);
    const handleFileChange = (event) => {
        var _a, _b;
        setTerminated(false);
        const selectedFile = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (selectedFile) {
            setFile(selectedFile);
            props.logEvent('file selected', {
                filetype: selectedFile.type,
                page_name: 'Vulnerability Summary',
            });
        }
    };
    const hiddenFileInput = React.useRef(null);
    const handleClick = () => {
        var _a;
        props.logEvent('click', {
            name: 'Choose file',
            scope: 'Vulnerability Summary Modal',
            type: 'button',
        });
        (_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (_jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', gap: '8px' }, children: [_jsxs(Box, { sx: { display: 'flex', flexDirection: 'row', gap: '14px' }, children: [_jsx(Button, { onClick: handleClick, disabled: isLoading, variant: "contained", children: props.translator('CHOOSE_FILE') }), _jsx("input", { "data-testid": "file-input", type: "file", accept: "text/csv", ref: hiddenFileInput, onChange: handleFileChange, multiple: false, style: { display: 'none' } }), isLoading && (_jsx(_Fragment, { children: _jsx(Button, { onClick: onTerminateParse, variant: "outlined", children: props.translator('CANCEL') }) }))] }), error && (_jsx("p", { style: {
                    fontSize: '12px',
                }, children: error }))] }));
};
export default JiraCsvSummaryParser;
