import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.controller('LearningResourcesSearchController', [
  '$stateParams',
  function ($stateParams) {
    window.location = `/#/explore${$stateParams.searchtext ? `?searchTerm=${$stateParams.searchtext}` : ''}`;
  },
]);
