import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { ExploreView } from './ExploreView';
import { usePlatformNavigate } from '../navigation';
import { usePlatformContext } from '../platformContext';
import { isFilterStateEmpty, saveFilterState, getFilterState, createQueryString, createFilterStateFromLocation, } from './FilterState';
import { createExploreFilterStore, ExploreFilterContext, useExploreFilterStore } from './FilterContext';
import { possibleStatusOptions, createFilteringEvent, possibleActivityTypeOptions } from './FilterLogic';
import { hasLanguageInformation, isMission, isVideo, isWalkThrough, } from './ContentItem';
import { useApiClient } from '../ApiContext';
import { backendToFrontend } from '../utils/mapper';
import { ExploreSkeleton } from './Explore.skeleton';
import { Suspenser, wrapPromise } from '@securecodewarrior/design-system-react';
export const ExploreWithBackendItems = () => {
    const ctx = usePlatformContext();
    const { trpc } = useApiClient();
    const navigate = usePlatformNavigate();
    const isAuthenticated = !!ctx.sessionUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const request = useMemo(() => wrapPromise(trpc.explore.availableContent.query()), [ctx.getExploreCacheKey()]);
    const { metadata } = usePlatformContext();
    const onLoginClicked = useCallback(() => {
        navigate.setRequestedState('explore', { path: '' });
        return navigate.toState('login');
    }, [navigate]);
    return (_jsx(Suspenser, { reader: request, skeleton: _jsx(ExploreSkeleton, { isAuthenticated: isAuthenticated, onLoginClicked: onLoginClicked }), render: (data) => {
            if (data.authenticated !== isAuthenticated) {
                return ctx.onUnAuthenticated();
            }
            const items = backendToFrontend(data.items, metadata);
            return (_jsx(ExploreFilterContextFromBrowserUrlAndLocalstorage, { items: items, children: _jsx(Explore, { items: items, onLoginClicked: onLoginClicked }) }));
        } }));
};
export const ExploreFilterContextFromBrowserUrlAndLocalstorage = (props) => {
    const ctx = usePlatformContext();
    const nav = usePlatformNavigate();
    const filterStore = useMemo(() => {
        let initialFilterState = createFilterStateFromLocation(window.location.href);
        if (ctx.sessionUser) {
            if (!isFilterStateEmpty(initialFilterState)) {
                saveFilterState(initialFilterState);
            }
            else {
                const fromStorage = getFilterState();
                if (!isFilterStateEmpty(fromStorage)) {
                    nav.setSearchParams(createQueryString(fromStorage), true);
                    initialFilterState = fromStorage;
                }
            }
        }
        return createExploreFilterStore(initialFilterState, props.items, nav.setSearchParams, ctx.logAnalyticsEvent);
    }, [nav, ctx.logAnalyticsEvent, props.items]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!ctx.sessionUser)
            return;
        const updateFilters = () => {
            const newFilterState = createFilterStateFromLocation(window.location.href);
            filterStore.getState().setFilterState(newFilterState, props.items);
        };
        ctx.addUrlChangeListener(updateFilters);
        return () => ctx.removeUrlChangeListener(updateFilters);
    }, [ctx, ctx.addUrlChangeListener, ctx.removeUrlChangeListener, nav, filterStore, props.items]);
    return _jsx(ExploreFilterContext.Provider, { value: filterStore, children: props.children });
};
export const Explore = (props) => {
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const isAuthenticated = !!sessionUser;
    const navigate = usePlatformNavigate();
    const filterState = useExploreFilterStore((store) => store.filterState);
    const filteredItemsLength = useExploreFilterStore((store) => store.filteredItems.length);
    logAnalyticsEvent(createFilteringEvent(filterState, filteredItemsLength));
    const filterOptions = useMemo(() => {
        const languageEntries = props.items
            .filter(hasLanguageInformation)
            .map((i) => [i.language.id, i.language])
            .sort(([_aId, { label: aLabel }], [_bId, { label: bLabel }]) => aLabel.localeCompare(bLabel));
        return {
            categories: Object.fromEntries(props.items.map((i) => [i.category.id, i.category])),
            languages: Object.fromEntries(languageEntries),
            activityTypes: isAuthenticated ? possibleActivityTypeOptions : { video: possibleActivityTypeOptions.video },
            activityStatus: possibleStatusOptions,
        };
    }, [props.items, isAuthenticated]);
    const gotoActivity = useCallback((item) => {
        const extraData = {
            category: item.category.id,
            language: isVideo(item) ? '' : item.language.id,
            status: item.status,
            title: item.title,
            activityType: item.type,
        };
        logAnalyticsEvent(Object.assign({ event: 'click', scope: 'explore', type: 'button', name: 'play activity' }, extraData));
        const nextPath = isMission(item) || isWalkThrough(item)
            ? `${activityPathRecord[item.type]}/${item.contentId}/${item.language.id}`
            : `${activityPathRecord[item.type]}/${item.contentId}`;
        const path = {
            pathname: nextPath,
            search: '',
            hash: '',
        };
        navigate.to(path);
    }, [logAnalyticsEvent, navigate]);
    return (_jsx(ExploreView, { filterOptions: filterOptions, onActivitySelected: gotoActivity, isAuthenticated: isAuthenticated, onLoginClicked: props.onLoginClicked, unfilteredActivityCount: props.items.length }));
};
const activityPathRecord = {
    coding_lab: '/explore/coding-lab',
    video: '/explore/video',
    challenge: '/explore/challenge',
    guideline: '/explore/guideline',
    mission: '/explore/mission',
    walkthrough: '/explore/walkthrough',
};
