import angular from 'angular';

const app = angular.module('SenseiApi', []);
export default app.name;

app.factory('SenseiApiService', [
  '$log',
  '$http',
  '$window',
  'Upload',
  'HttpConfigService',
  function ($log, $http, $window, Upload, HttpConfigService) {
    var SenseiApiService = {};

    var senseiApiEndpoint = function () {
      return $window.SCW_ENV.ApiEndpoint + '/sensei';
    };
    var senseiReposApiEndpoint = function () {
      return senseiApiEndpoint() + '/repo';
    };
    var senseiCodebasesApiEndpoint = function () {
      return senseiApiEndpoint() + '/codebase';
    };
    var senseiBuildsApiEndpoint = function () {
      return senseiApiEndpoint() + '/build';
    };
    var senseiDemoRulesApiEndpoint = function () {
      return senseiApiEndpoint() + '/demo-rules';
    };
    var senseiAllRulesApiEndpoint = function () {
      return senseiApiEndpoint() + '/all-rules';
    };
    var senseiRuleSettingsApiEndpoint = function () {
      return senseiApiEndpoint() + '/rule-settings';
    };
    var senseiMetricsApiEndpoint = function () {
      return senseiApiEndpoint() + '/metrics';
    };
    var senseiAuthApiEndpoint = function () {
      return senseiApiEndpoint() + '/tokens/validate';
    };

    /******************** Repos ********************/

    // Get all Repos
    SenseiApiService.getRepos = function () {
      $log.debug('SenseiApiService - getRepos()');
      return $http.get(senseiReposApiEndpoint(), HttpConfigService.getHttpConfig()).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    // Get individual repo
    SenseiApiService.getRepo = function (_id) {
      $log.debug('SenseiApiService - getRepo()');
      return $http
        .get(senseiReposApiEndpoint() + '/' + _id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Update single repo
    SenseiApiService.updateRepo = function (_id, repoDetails) {
      $log.debug('SenseiApiService - updateRepo()');
      return $http
        .put(senseiReposApiEndpoint() + '/' + _id, repoDetails, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Create a new Repo with no languages or aliases
    SenseiApiService.addNewBlankRepo = function (repoName) {
      $log.debug('SenseiApiService - addNewBlankRepo()');
      var repoDetails = {
        names: [repoName],
        languages: [],
        installationInstructions: '',
        isComingSoon: false,
        downloadType: '',
      };
      return $http
        .post(senseiReposApiEndpoint(), repoDetails, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Delete the given repo
    SenseiApiService.deleteRepo = function (_id) {
      $log.debug('SenseiApiService - deleteRepo()');
      return $http
        .delete(senseiReposApiEndpoint() + '/' + _id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** Codebases ********************/

    // Get all Codebases
    SenseiApiService.getCodebases = function () {
      $log.debug('SenseiApiService - getCodebases()');
      return $http.get(senseiCodebasesApiEndpoint(), HttpConfigService.getHttpConfig()).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    // Get individual codebase
    SenseiApiService.getCodebase = function (_id) {
      $log.debug('SenseiApiService - getCodebase()');
      return $http
        .get(senseiCodebasesApiEndpoint() + '/' + _id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Update single codebase
    SenseiApiService.updateCodebase = function (_id, codebaseDetails) {
      $log.debug('SenseiApiService - updateCodebase()');
      return $http
        .put(senseiCodebasesApiEndpoint() + '/' + _id, codebaseDetails, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Create a new Repo with no languages or aliases
    SenseiApiService.addNewBlankCodebase = function (codebaseName) {
      $log.debug('SenseiApiService - addNewBlankCodebase()');
      var codebaseDetails = {
        name: codebaseName,
      };
      return $http
        .post(senseiCodebasesApiEndpoint(), codebaseDetails, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Delete the given codebase
    SenseiApiService.deleteCodebase = function (_id) {
      $log.debug('SenseiApiService - deleteCodebase()');
      return $http
        .delete(senseiCodebasesApiEndpoint() + '/' + _id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** Builds ********************/

    // Add a new Build with the given data
    SenseiApiService.addNewBuild = function (build) {
      $log.debug('SenseiApiService - addNewBuild()');

      var url = senseiBuildsApiEndpoint();

      var fields = {
        _repo: build._repo,
        version: build.version,
        description: build.description,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'POST',
        fileFormDataName: 'archive',
        file: build.file,
      };

      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    // Delete Build
    SenseiApiService.deleteBuild = function (build) {
      $log.debug('SenseiApiService - deleteBuild()');
      return $http
        .delete(senseiBuildsApiEndpoint() + '/' + build._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    // Update the Build details
    SenseiApiService.updateBuild = function (build) {
      $log.debug('SenseiApiService - updateBuild()');

      var url = senseiBuildsApiEndpoint() + '/' + build._id;

      var fields = {
        version: build.version,
        description: build.description,
        _repo: build._repo,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'PUT',
      };

      if (build.updatedBuild) {
        options.fileFormDataName = 'archive';
        options.file = build.updatedBuild;
      }
      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    // Get all Builds for a Repo
    SenseiApiService.getBuilds = function (_repo) {
      $log.debug('SenseiApiService - getBuilds()');
      return $http
        .get(senseiBuildsApiEndpoint() + '/?_repo=' + _repo, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.downloadBuild = function (buildid) {
      $log.debug('SenseiApiService - downloadBuild()');
      return $http
        .get(senseiBuildsApiEndpoint() + '/' + buildid, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** Demo Rulesets ********************/

    SenseiApiService.getDemoRulesets = function (codebaseId) {
      $log.debug('SenseiApiService - getDemoRulesets()');
      return $http
        .get(senseiDemoRulesApiEndpoint() + '/?_codebase=' + codebaseId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.addNewDemoRuleset = function (codebase) {
      $log.debug('SenseiApiService - addNewDemoRuleset()');

      var url = senseiDemoRulesApiEndpoint();

      var fields = {
        _codebase: codebase._codebase,
        version: codebase.version,
        description: codebase.description,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'POST',
        fileFormDataName: 'archive',
        file: codebase.file,
      };

      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.downloadDemoFile = function (ruleid) {
      $log.debug('SenseiApiService - downloadDemoFile()');
      return $http
        .get(senseiDemoRulesApiEndpoint() + '/' + ruleid, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.downloadAllFile = function (ruleid) {
      $log.debug('SenseiApiService - downloadDemoFile()');
      return $http
        .get(senseiAllRulesApiEndpoint() + '/' + ruleid, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.updateDemoRuleset = function (rule) {
      $log.debug('SenseiApiService - updateDemoRuleset()');

      var url = senseiDemoRulesApiEndpoint() + '/' + rule._id;

      var fields = {
        version: rule.version,
        description: rule.description,
        _codebase: rule._codebase,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'PUT',
      };

      if (rule.updatedRule) {
        options.fileFormDataName = 'archive';
        options.file = rule.updatedRule;
      }
      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.deleteDemoRuleset = function (rule) {
      $log.debug('SenseiApiService - deleteDemoRuleset()');
      return $http
        .delete(senseiDemoRulesApiEndpoint() + '/' + rule._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** "All rules" Rulesets ********************/

    SenseiApiService.getAllRulesets = function (codebaseId) {
      $log.debug('SenseiApiService - getAllRulesets()');
      return $http
        .get(senseiAllRulesApiEndpoint() + '/?_codebase=' + codebaseId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.addNewAllRuleset = function (codebase) {
      $log.debug('SenseiApiService - addNewAllRuleset()');

      var url = senseiAllRulesApiEndpoint();

      var fields = {
        _codebase: codebase._codebase,
        version: codebase.version,
        description: codebase.description,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'POST',
        fileFormDataName: 'archive',
        file: codebase.file,
      };

      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.updateAllRuleset = function (rule) {
      $log.debug('SenseiApiService - updateAllRuleset()');

      var url = senseiAllRulesApiEndpoint() + '/' + rule._id;

      var fields = {
        version: rule.version,
        description: rule.description,
        _codebase: rule._codebase,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: fields,
        headers: headers,
        method: 'PUT',
      };

      if (rule.updatedRule) {
        options.fileFormDataName = 'archive';
        options.file = rule.updatedRule;
      }
      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.deleteAllRuleset = function (rule) {
      $log.debug('SenseiApiService - deleteAllRuleset()');
      return $http
        .delete(senseiAllRulesApiEndpoint() + '/' + rule._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };
    /******************** Rule-settings ********************/

    SenseiApiService.getRuleSettings = function (companyId) {
      $log.debug('SenseiApiService - getRulesettings()');
      return $http
        .get(senseiRuleSettingsApiEndpoint() + '/all/' + companyId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.addNewRuleSetting = function (ruleSetting) {
      $log.debug('SenseiApiService - addNewRulesetting()');

      var url = senseiRuleSettingsApiEndpoint();

      var fields = {
        _cid: ruleSetting._cid,
        name: ruleSetting.name,
        location: ruleSetting.location,
        enabledForCompanyAdmins: ruleSetting.enabledForCompanyAdmins,
        teamIds: ruleSetting.teamIds,
      };

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: { ruleSetting: JSON.stringify(fields) },
        headers: headers,
        method: 'POST',
        fileFormDataName: 'archive',
        file: ruleSetting.file,
      };

      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.updateRuleSetting = function (ruleSetting) {
      $log.debug('SenseiApiService - updateRuleSettings()');

      var url = senseiRuleSettingsApiEndpoint() + '/' + ruleSetting._id;

      var fields = {
        _cid: ruleSetting._cid,
        name: ruleSetting.name,
        location: ruleSetting.location,
        enabledForCompanyAdmins: ruleSetting.enabledForCompanyAdmins,
        teamIds: ruleSetting.teamIds,
      };
      if (ruleSetting.filename && ruleSetting.fileid) {
        fields.filename = ruleSetting.filename;
        fields.fileid = ruleSetting.fileid;
      }

      var headers = _.merge({}, HttpConfigService.getHttpConfig().headers, { 'Content-Type': 'multipart/form-data' });
      var options = {
        url: url,
        fields: { ruleSetting: JSON.stringify(fields) },
        headers: headers,
        method: 'PUT',
        fileFormDataName: 'archive',
        file: ruleSetting.file,
      };

      return Upload.upload(options).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    SenseiApiService.updateDefaultRuleSettings = function (ruleSetting) {
      $log.debug('SenseiApiService - updateDefaultRuleSettings()');
      return $http
        .put(senseiRuleSettingsApiEndpoint() + '/demo-rule-settings/', ruleSetting, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.deleteRulesetting = function (rule) {
      $log.debug('SenseiApiService - deleteRuleSetting()');
      return $http
        .delete(senseiRuleSettingsApiEndpoint() + '/' + rule._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** Metrics ********************/

    SenseiApiService.getMetricsByCompany = function (_companyID) {
      $log.debug('SenseiApiService - getMetricsByCompany()');
      return SenseiApiService.getMetrics(null, null, null, _companyID);
    };

    SenseiApiService.getMetrics = function (from, to, duration, company, team) {
      $log.debug('SenseiApiService - getMetrics()');

      var data = {
        from: from,
        to: to,
        duration: duration,
        _company: company,
        _team: team,
      };

      var params = _.toPairs(data);
      var queryString = '';
      params.forEach(function (element) {
        if (element[1]) {
          queryString += '&' + encodeURIComponent(element[0]) + '=' + encodeURIComponent(element[1]);
        }
      }, this);
      if (queryString.length > 0) queryString = '?' + queryString.slice(1);

      return $http
        .get(senseiMetricsApiEndpoint() + queryString, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /******************** Download ********************/

    // Get Sensei supported IDEs
    SenseiApiService.getSupportedIDEs = function () {
      $log.debug('SenseiApiService - getSupportedIDEs()');
      return $http
        .get(senseiReposApiEndpoint() + '/getSupportedIDEs', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    SenseiApiService.getAvailableDownloadTypes = function () {
      $log.debug('SenseiApiService - getAvailableDownloadTypes()');
      return $http
        .get(senseiReposApiEndpoint() + '/getAvailableDownloadTypes', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /*************** Authentication / Validation  ***************/

    SenseiApiService.authenticate = function (token, _userId) {
      $log.debug('SenseiApiService - authenticate()');
      return $http
        .get(senseiAuthApiEndpoint() + '?appToken=' + token, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug('Sensei Auth Response: ' + response.data);
          return response.status;
        });
    };

    return SenseiApiService;
  },
]);
