import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Container } from '@securecodewarrior/design-system-react';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
import { platformLocaleToBcp47 } from '../../utils/platformLocaleToBcp47';
export const OuterBox = styled(Box)(({ theme }) => ({
    width: '100%',
    gap: theme.spacing(12),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(6),
    border: `1px solid ${theme.palette.container.border.default}`,
    backgroundColor: theme.palette.container.fill.card1,
}));
export const EmptyStateCard = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingBlock: theme.spacing(45),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.container.fill.card2,
    border: `1px solid ${theme.palette.container.border.default}`,
    display: 'grid',
    placeContent: 'center',
}));
export const InnerCard = styled(Box)(({ theme }) => ({
    gap: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-root': {
        maxWidth: 600,
    },
}));
const supportEmail = 'support@securecodewarrior.com';
export const SuccessState = ({ successMessage, bcp47Locale }) => {
    const { i18n, t } = useTranslation('react_components');
    useEffect(() => {
        i18n.changeLanguage(bcp47Locale);
    }, [bcp47Locale, i18n]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h3", align: "center", children: t('pages.unsubscribe.success') }), _jsx(Typography, { variant: "body1", align: "center", children: successMessage }), _jsxs(Box, { sx: { display: 'grid', placeItems: 'center' }, children: [_jsx(Typography, { variant: "body1", align: "center", children: t('pages.unsubscribe.contactUs') }), _jsx(Link, { color: "soft", href: `mailto:${supportEmail}`, children: supportEmail })] })] }));
};
export const ErrorState = ({ errorMessage, bcp47Locale }) => {
    const { i18n, t } = useTranslation('react_components');
    useEffect(() => {
        i18n.changeLanguage(bcp47Locale);
    }, [bcp47Locale, i18n]);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h3", align: "center", children: t('pages.unsubscribe.error') }), _jsx(Typography, { variant: "body1", align: "center", children: errorMessage }), _jsxs(Box, { sx: { display: 'grid', placeItems: 'center' }, children: [_jsx(Typography, { variant: "body1", align: "center", children: t('pages.unsubscribe.contactUs') }), _jsx(Link, { color: "soft", href: `mailto:${supportEmail}`, children: supportEmail })] })] }));
};
export const Unsubscribe = ({ apiEndPoint, platformLocale, token }) => {
    var _a;
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const bcp47Locale = (_a = platformLocaleToBcp47(platformLocale)) !== null && _a !== void 0 ? _a : 'en-US';
    const { t } = useTranslation('react_components');
    useEffect(() => {
        if (token && apiEndPoint) {
            fetch(`${apiEndPoint}/subscriptions/unsubscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token, platformLocale }),
            })
                .then((response) => response.json())
                .then((data) => {
                if (data.errorMessage) {
                    console.error('Error unsubscribing:', data.errorMessage);
                    setErrorMessage(data.errorMessage);
                }
                else {
                    console.log('Successfully unsubscribed:', data);
                    setSuccessMessage(data.successMessage);
                }
            })
                .catch((error) => {
                console.error('Error unsubscribing:', error);
                setErrorMessage(t('pages.unsubscribe.genericError'));
            })
                .finally(() => {
                setLoading(false);
            });
        }
        else {
            setLoading(false);
            setErrorMessage(t('pages.unsubscribe.genericError'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiEndPoint, token, t]);
    return (_jsx(Container, { maxWidth: "xl", children: _jsx(OuterBox, { children: _jsx(EmptyStateCard, { children: loading ? (_jsx(_Fragment, { children: t('common.loading') })) : (_jsx(InnerCard, { children: successMessage ? (_jsx(SuccessState, { successMessage: successMessage, bcp47Locale: bcp47Locale })) : (_jsx(ErrorState, { errorMessage: errorMessage, bcp47Locale: bcp47Locale })) })) }) }) }));
};
