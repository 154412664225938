import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-autofocus */
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
export function ConfirmationDialog(props) {
    const { open, onCancel, onConfirm, dialogTitle, dialogText, cancelButtonLabel, confirmButtonLabel } = props;
    return (_jsxs(Dialog, { open: open, onClose: onCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: dialogTitle }), _jsx(DialogContent, { children: _jsx(DialogContentText, { id: "alert-dialog-description", children: dialogText }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onCancel, children: cancelButtonLabel }), _jsx(Button, { onClick: onConfirm, autoFocus: true, children: confirmButtonLabel })] })] }));
}
