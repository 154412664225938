import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Stack, Typography, Unstable_Grid2 as Grid2 } from '@mui/material';
import { Badge, Link } from '@securecodewarrior/design-system-react/lib/legacy';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import { DifficultyRating } from '../../difficulty';
import { useDisplayTime } from '../../time';
const ValueBox = ({ children }) => (_jsx(Box, { role: "cell", display: "flex", children: children }));
const LabelBox = ({ children }) => (_jsx(Typography, { "aria-hidden": true, role: "cell", textTransform: "uppercase", variant: "overline", display: "flex", alignItems: "center", children: children }));
const InnerGrid = ({ value, label, columns, }) => (_jsx(Grid2, { lg: 12 / columns, md: 6, xs: 12, direction: "row", spacing: 2, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", flexGrow: "1", children: [_jsx(LabelBox, { children: label }), _jsx(ValueBox, { children: value })] }) }));
const useGridIcons = (props) => {
    const { t } = useTranslation();
    const selfAssessIcon = (_jsx(Box, { role: "img", "aria-label": props.selfAssess ? t('common.enabled') : t('common.disabled'), display: "flex", alignItems: "center", children: props.selfAssess ? _jsx(CheckIcon, { color: "success", fontSize: "large" }) : _jsx(CloseIcon, { color: "error", fontSize: "large" }) }));
    const fixedChallengesIcon = (_jsx(Box, { role: "img", "aria-label": props.fixedChallenges ? t('common.enabled') : t('common.disabled'), display: "flex", alignItems: "center", children: props.fixedChallenges ? (_jsx(CheckIcon, { color: "success", fontSize: "large" })) : (_jsx(CloseIcon, { color: "error", fontSize: "large" })) }));
    const retriesAllowedIcon = match(props.retriesAllowed)
        .with({ kind: 'disabled' }, () => (_jsx(Box, { role: "img", "aria-label": t('common.disabled'), display: "flex", alignItems: "center", children: _jsx(CloseIcon, { color: "error", fontSize: "large" }) })))
        .with({ kind: 'limited' }, ({ amount }) => (_jsx(Box, { display: "flex", alignItems: "center", children: amount })))
        .with({ kind: 'unlimited' }, () => (_jsx(Box, { role: "img", "aria-label": t('common.enabled'), display: "flex", alignItems: "center", children: _jsx(CheckIcon, { color: "success", fontSize: "large" }) })))
        .exhaustive();
    return { selfAssessIcon, fixedChallengesIcon, retriesAllowedIcon };
};
export const DeveloperGrid = (props) => {
    const { t } = useTranslation();
    const displayTime = useDisplayTime();
    const icons = useGridIcons(props);
    return (_jsxs(Grid2, { container: true, pt: 4, rowSpacing: 4, columnSpacing: 13, children: [_jsx(InnerGrid, { label: _jsxs(_Fragment, { children: [_jsx("span", { "aria-hidden": "true", children: "#\u00A0" }), t('entities.assessments.challenges')] }), value: props.challenges, columns: 2 }), _jsx(InnerGrid, { label: t('entities.assessments.selfAssess'), value: icons.selfAssessIcon, columns: 2 }), _jsx(InnerGrid, { label: t('entities.assessments.timeLimit'), value: props.timeLimit === null ? t('entities.assessments.noTimeLimit') : displayTime(props.timeLimit), columns: 2 }), _jsx(InnerGrid, { label: t('entities.assessments.fixedChallenges'), value: icons.fixedChallengesIcon, columns: 2 }), _jsx(InnerGrid, { label: t('entities.assessments.difficulty'), value: _jsx(DifficultyRating, { difficulty: props.difficulty }), columns: 2 }), _jsx(InnerGrid, { label: t('entities.assessments.retriesAllowed'), value: icons.retriesAllowedIcon, columns: 2 })] }));
};
export const AdminGrid = (props) => {
    const { t } = useTranslation();
    const displayTime = useDisplayTime();
    const icons = useGridIcons(props);
    return (_jsxs(Grid2, { container: true, pt: 4, rowSpacing: 4, columnSpacing: 13, children: [_jsx(InnerGrid, { label: _jsxs(_Fragment, { children: [_jsx("span", { "aria-hidden": "true", children: "#\u00A0" }), t('entities.assessments.challenges')] }), value: props.challenges, columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.completedAverageScore'), value: props.completedAverageScore, columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.selfAssess'), value: icons.selfAssessIcon, columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.timeLimit'), value: props.timeLimit === null ? t('entities.assessments.noTimeLimit') : displayTime(props.timeLimit), columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.completedAverageTime'), value: displayTime(props.completedAverageTime), columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.fixedChallenges'), value: icons.fixedChallengesIcon, columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.difficulty'), value: _jsx(DifficultyRating, { difficulty: props.difficulty }), columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.completionRatio'), value: props.completionRatio, columns: 3 }), _jsx(InnerGrid, { label: t('entities.assessments.retriesAllowed'), value: icons.retriesAllowedIcon, columns: 3 })] }));
};
const statusMap = {
    active: { variant: 'success', label: 'common.statusActive' },
    superseded: { variant: 'warning', label: 'pages.assessments.list.statusSuperseded' },
    closed: { variant: 'warning', label: 'common.statusClosed' },
};
export const TableCard = (props) => {
    const { t } = useTranslation();
    const statusInfo = statusMap[props.status];
    return (_jsxs(Stack, { direction: "column", mb: 4, bgcolor: (theme) => theme.palette.grayscale.bg3, borderRadius: 2, p: 5, role: "row", children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", pb: 4, children: [_jsxs(Stack, { direction: "column", children: [_jsx("span", { role: "cell", children: _jsx(Typography, { variant: "h5", component: "h3", children: _jsxs(Link, { href: props.url, children: [props.title, " v", props.version] }) }) }), _jsx(Typography, { variant: "body1", pt: "16px", role: "cell", color: (theme) => theme.palette.grayscale.bg9, children: props.description })] }), _jsx(Box, { children: _jsx(Badge, { TypographyProps: { role: 'cell' }, label: t(statusInfo.label), variant: statusInfo.variant }) })] }), _jsx(Divider, { "aria-hidden": true, role: "cell", orientation: "horizontal", flexItem: true }), props.renderGrid()] }));
};
