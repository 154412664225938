import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Container } from '@securecodewarrior/design-system-react';
import { QuestIcon } from './QuestIcon';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
export const EmptyQuest = (props) => {
    const { t } = useTranslation();
    return (_jsxs(Container, { sx: { py: 10 }, children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, mb: 6, children: [_jsx(QuestIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(35) }) }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('pages.quests.subtitle') }), _jsx(Typography, { component: "h1", variant: "h2", children: t('pages.quests.title') })] })] }), _jsxs(Stack, { sx: (theme) => ({
                    backgroundColor: theme.palette.container.fill.card1,
                    borderRadius: 3,
                    border: `1px solid ${theme.palette.container.border.default}`,
                    p: 10,
                    height: '70vh',
                    minHeight: 400,
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 5,
                }), children: [_jsxs(Stack, { textAlign: "center", maxWidth: "366px", gap: 5, mb: 5, children: [_jsx(Typography, { variant: "h3", children: t('pages.quests.empty.title') }), _jsx(Typography, { variant: "subtitle1", children: t('pages.quests.empty.description') })] }), _jsx(Button, { variant: "contained", onClick: props.onSelectLanguage, children: t('pages.quests.empty.button') })] })] }));
};
