import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { Button, CardStyle } from '@securecodewarrior/design-system-react/lib/legacy';
import { Box, Collapse, Stack, styled, Typography, useTheme } from '@mui/material';
import { dateFields, getLastGoodValue, normalizedTimeLimit, } from './state';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { group, uniq, uniqBy } from '../../utils/Array';
import humanizeDuration from 'humanize-duration';
import * as Diff from 'diff';
import { simplifyChanges } from './differ';
import { getTimezoneAbbreviations } from './timeZones';
import { match } from 'ts-pattern';
import { FixProgramButton } from './ReferenceFixer';
import { useTranslation } from 'react-i18next';
import { usePlatformNavigate } from '../navigation';
import { trustingEntries } from '../../utils/object';
import { multiassessmentAnalytis, useMonitoredCallback } from './analytics';
import { usePlatformContext } from '../platformContext';
import { getLanguageInformation } from '../utils/mapper';
dayjs.extend(utc);
dayjs.extend(timezone);
export function IncludedAssessmentCard(props) {
    const ref = useRef(null);
    const theme = useTheme();
    const [visibleHeight, setVisibleHeight] = useState(null);
    const [heightControl, setHeightControl] = useState({
        transition: false,
        toHeight: null,
        contentHeight: visibleHeight,
    });
    const refLastVisibleHeight = useRef(visibleHeight);
    useLayoutEffect(() => {
        if (visibleHeight && !!refLastVisibleHeight.current) {
            console.log('Resizing - no transition needed');
            setHeightControl((x) => ({
                transition: x.toHeight === 0 ? true : false,
                toHeight: x.toHeight ? visibleHeight : x.toHeight,
                contentHeight: visibleHeight,
            }));
        }
        refLastVisibleHeight.current = visibleHeight;
    }, [visibleHeight]);
    useLayoutEffect(() => {
        console.log('Visibility changed - need transition');
        setHeightControl((x) => ({
            toHeight: props.isVisible ? x.contentHeight : 0,
            transition: true,
            contentHeight: x.contentHeight,
        }));
        if (ref.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    // set the visible height
                    if (entry.target === ref.current) {
                        setVisibleHeight(entry.contentRect.height + 20); // the 20 is top-padding
                        console.log('Measured visible height');
                    }
                }
            });
            resizeObserver.observe(ref.current);
            return () => resizeObserver.disconnect();
            // // measure the height at the moment of hiding
            // setVisibleHeight(ref.current.clientHeight);
            // console.log('Measured visible height at', ref.current.clientHeight);
        }
    }, [props.isVisible]);
    return (_jsxs(Box, { "aria-hidden": !props.isVisible ? 'true' : undefined, sx: Object.assign({ position: 'relative', overflow: 'visible', padding: 0, maxHeight: heightControl.toHeight === null ? visibleHeight : `${heightControl.toHeight}px` }, (heightControl.transition ? { transition: 'max-height 400ms ease-in-out' } : {})), children: [_jsx(Box, { ref: ref, sx: {
                    paddingRight: theme.spacing(5),
                    paddingTop: theme.spacing(5),
                }, children: _jsx(CardStyle, { sx: {
                        padding: theme.spacing(5),
                    }, children: _jsx(IncludedAssessment, Object.assign({}, props)) }) }), _jsx("div", { style: {
                    position: 'absolute',
                    top: theme.spacing(5),
                    left: 0,
                    right: 0,
                    bottom: '-10000px',
                    backgroundColor: '#232636',
                    // backgroundColor: 'red',
                    opacity: props.isVisible ? 0 : 1,
                    pointerEvents: 'none',
                    transition: props.isVisible
                        ? 'opacity 750ms cubic-bezier(0.22, 0.61, 0.36, 1)'
                        : 'opacity 1500ms cubic-bezier(0.22, 0.61, 0.36, 1)',
                } })] }));
}
function IncludedAssessment(props) {
    const { latestRecord, name, readOnlyAssessmentIds } = props;
    const challengesByDifficulty = group(props.latestRecord.assessmentContent.questStory.challenges, (c) => c.filter.difficulty);
    function derivePseudoStatus(latestRecord, activeAttempts) {
        const now = new Date();
        if (latestRecord.status === 'closed')
            return 'closed';
        if (latestRecord.status === 'disabled')
            return 'superseded';
        if (latestRecord.assessmentSettings.startDate && latestRecord.assessmentSettings.startDate > now)
            return 'pending';
        if (latestRecord.assessmentSettings.endDate && latestRecord.assessmentSettings.endDate < now)
            return 'closed';
        if (activeAttempts > 0)
            return 'active';
        return 'scheduled';
    }
    const { t } = useTranslation();
    const dt = t;
    return (_jsxs(_Fragment, { children: [_jsx(AssessmentTitle, { mode: props.mode, assessmentId: latestRecord._id, _assessment: latestRecord._assessment, name: name, version: latestRecord.version, persistedName: latestRecord.name, 
                // status={latestRecord.status}
                readOnlyAssessmentIds: readOnlyAssessmentIds, difficulty: props.latestRecord.assessmentContent.difficulty, languages: props.latestRecord.assessmentContent.questStory.languages, pseudoStatus: derivePseudoStatus(latestRecord, props.activeAttempts) }), _jsx(Stack, { direction: "row", spacing: 2, children: _jsxs(Typography, { variant: "body2", children: [t('pages.multiassessments.nChallenges', { count: props.latestRecord.assessmentContent.numberOfChallenges }), Object.keys(challengesByDifficulty).length > 1 && (_jsx(_Fragment, { children: _jsxs("em", { children: ["(", t('pages.multiassessments.incl'), ' ', trustingEntries(challengesByDifficulty)
                                        .filter(([d]) => d !== props.latestRecord.assessmentContent.difficulty)
                                        .map(([d, cs]) => `${cs.length} ${dt(d.toUpperCase())}`)
                                        .join(', '), ")"] }) }))] }) }), _jsx(Typography, { variant: "h3", sx: { fontSize: '13px', fontWeight: 400 }, children: latestRecord.description }), props.references.length > 0 && (_jsx(References, { mode: props.mode, _assessment: props.latestRecord._assessment, references: props.references })), _jsx(AssessmentDataGrid, Object.assign({}, props))] }));
}
function References(props) {
    return (_jsx(Box, { children: uniqBy(props.references, (r) => refKey(r.refs))
            .filter((r) => !r.isContextReference || r.missingLatest.length > 0)
            .map((ref) => (_jsx(Reference, { reference: ref, mode: props.mode, _assessment: props._assessment }, refKey(ref.refs)))) }));
}
function Reference(props) {
    const { t } = useTranslation();
    const { reference: ref, mode } = props;
    const [fixed, setFixed] = useState([]);
    const unfixed = ref.missingLatest.filter((m) => !fixed.includes(m._id));
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "body2", sx: {}, children: [ref.isContextReference
                        ? t('pages.multiassessments.usedByRef', { refType: ref.refs.k })
                        : t('pages.multiassessments.sharedWithRef', { refType: ref.refs.k }), ' ', mode === 'view' ? (_jsx("a", { style: {
                            color: '#dd9935',
                            textDecoration: 'none',
                        }, href: contextUrl(ref.refs), children: ref.refs.name })) : (_jsx("span", { children: ref.refs.name }))] }), ref.missingLatest.length > 0 && (_jsx(Box, { sx: {
                    border: unfixed.length > 0 ? '1px solid #F96E64' : '1px solid #575f86',
                    backgroundColor: 'rgba(35, 38, 54, 0.5)',
                    borderRadius: '8px',
                    display: 'grid',
                    gridTemplateColumns: '1fr max-content',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    marginTop: 2,
                    marginBottom: 4,
                }, children: uniqBy(ref.missingLatest, (m) => m._id).map((missing) => (_jsxs(Fragment, { children: [_jsxs(Typography, { variant: "body2", children: [t('pages.multiassessments.missingLatestAssessment'), ' ', _jsxs("em", { children: [missing.name, " ", _jsx("strong", { children: `v${missing.version + 1}` })] })] }), ref.refs.k === 'program' && (_jsx(FixProgramButton, { _assessment: props._assessment, disabled: mode === 'edit', variant: "text", sx: { padding: 1 }, program: ref.refs, missingAssessmentIds: uniq(ref.missingLatest.map((m) => m._id)), onFixed: () => setFixed((f) => [...f, missing._id]) }))] }, missing._id))) }))] }));
}
const DiffChars = (props) => _jsx(DiffCharsOrWords, Object.assign({}, props, { level: "chars" }));
const DiffWords = (props) => _jsx(DiffCharsOrWords, Object.assign({}, props, { level: "words" }));
function DiffCharsOrWords({ prev, current, level }) {
    const d0 = level === 'chars' ? Diff.diffChars(prev, current) : Diff.diffWords(prev, current);
    const d = simplifyChanges(d0);
    const formatInsDel = level === 'chars' ? (s) => s.replaceAll(' ', '␣') : (s) => s;
    const formatUnchanged = level === 'chars'
        ? (s) => s
            .split(/(\s{2,})|(^\s)|(\s$)/g)
            .filter((x) => x)
            .map((p, i) => (_jsx(Fragment, { children: p.startsWith(' ') ? _jsx("span", { style: { opacity: 0.5 }, children: p.replaceAll(' ', '␣') }) : _jsx("span", { children: p }) }, i)))
        : (s) => s;
    return (_jsx(_Fragment, { children: d.map((part, index) => part.added ? (_jsx("ins", { style: { color: 'rgb(255, 190, 18)', textDecoration: 'none', display: 'inline-block' }, children: formatInsDel(part.value) }, index)) : part.removed ? (_jsx("del", { style: { opacity: 0.5, display: 'inline-block' }, children: formatInsDel(part.value) }, index)) : (formatUnchanged(part.value))) }));
}
function AssessmentTitle({ mode, assessmentId, _assessment, name, persistedName, 
// status,
readOnlyAssessmentIds, languages, difficulty, version, pseudoStatus, }) {
    var _a;
    const { t } = useTranslation();
    const theme = useTheme();
    const dt = t;
    const navigate = usePlatformNavigate();
    const { metadata } = usePlatformContext();
    const manageAssessmentAction = useMonitoredCallback(multiassessmentAnalytis.manageAssessmentPressed(assessmentId, _assessment), () => {
        navigate.toState('assessments.view', { assessmentId });
    }, [assessmentId, navigate]);
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "overline", sx: {
                    color: 'white',
                    display: 'block',
                    marginBottom: '-8px',
                    fontWeight: 600,
                    fontStretch: '110%',
                    letterSpacing: 0.7,
                    fontSize: '10px',
                }, children: _jsx("span", { style: { opacity: 0.5 }, children: t('pages.multiassessments.statusAssessment', {
                        status: match(pseudoStatus)
                            .with('active', () => t('pages.multiassessments.assessmentCard.title.active'))
                            .with('scheduled', () => t('pages.multiassessments.assessmentCard.title.scheduled'))
                            .with('pending', () => t('pages.multiassessments.assessmentCard.title.pending'))
                            .with('superseded', () => t('pages.multiassessments.assessmentCard.title.superseded'))
                            .with('closed', () => t('pages.multiassessments.assessmentCard.title.closed'))
                            .exhaustive(),
                    }) }) }), _jsxs("span", { style: { display: 'flex', alignItems: 'center', gap: theme.spacing(4) }, children: [_jsxs(Typography, { variant: "h6", children: [_jsx(DiffChars, { prev: persistedName, current: name }), _jsxs(Box, { sx: { display: 'inline-flex', gap: 2, marginLeft: 2, alignItems: 'baseline' }, children: [version > 0 && (_jsxs("span", { style: {
                                            fontSize: theme.typography.pxToRem(12),
                                            fontWeight: 700,
                                            color: 'rgba(255 255 255 / 80%)',
                                            letterSpacing: 1.3,
                                            padding: '1px 6px 0px 6px',
                                            // display: 'inline-block',
                                            position: 'relative',
                                        }, children: [_jsx("span", { style: {
                                                    position: 'absolute',
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    borderRadius: theme.shape.borderRadius,
                                                    boxSizing: 'border-box',
                                                    border: '1px solid rgba(255 255 255 / 30%)',
                                                    pointerEvents: 'none',
                                                } }), "v", version + 1] })), _jsx("span", { style: {
                                            fontSize: theme.typography.pxToRem(12),
                                            fontWeight: 400,
                                            padding: '1px 6px 0px 6px',
                                            color: 'rgba(0 0 0 / 80%)',
                                            borderRadius: theme.shape.borderRadius,
                                            textShadow: 'none',
                                            backgroundColor: 'rgb(171 188 226)', //'rgba(255 255 255 / 9%)',
                                        }, children: languages.length > 1 ? (_jsxs(_Fragment, { children: [(_a = getLanguageInformation(languages[0].language._id + '.' + languages[0].language._framework, metadata)) === null || _a === void 0 ? void 0 : _a.label, ' ', _jsx("span", { style: { paddingLeft: theme.spacing(1) }, children: t('pages.multiassessments.moreLanguages', {
                                                        count: languages.length - 1,
                                                    }) })] })) : (languages.map((lang) => {
                                            var _a;
                                            return (_jsx("span", { children: (_a = getLanguageInformation(lang.language._id + '.' + lang.language._framework, metadata)) === null || _a === void 0 ? void 0 : _a.label }, `${lang.language._id}.${lang.language._framework}`));
                                        })) }), _jsx("span", { style: { fontSize: theme.typography.pxToRem(12), opacity: 0.85, fontWeight: 600, letterSpacing: 1.3 }, children: dt(difficulty.toUpperCase()).toUpperCase() })] })] }), _jsx(Button, { onClick: manageAssessmentAction, disabled: mode === 'edit', style: { marginLeft: 'auto', marginRight: '15px' }, variant: "outlined", children: (readOnlyAssessmentIds === null || readOnlyAssessmentIds === void 0 ? void 0 : readOnlyAssessmentIds.includes(assessmentId))
                            ? t('pages.multiassessments.viewAssessment')
                            : t('pages.multiassessments.manageAssessment') })] })] }));
}
function AssessmentDataGrid(props) {
    const settings = props.settings;
    const items = props.items;
    const hasInterestingItems = items.some((i) => i.isModified || i.isNonDefault || i.validations.length);
    return (_jsx(Collapse, { in: hasInterestingItems, children: _jsx(Box, { style: {
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gap: '10px',
                border: '0px solid #575f86',
                borderRadius: '8px',
                gridAutoRows: 'min-content',
                justifyItems: 'start',
                justifyContent: 'start',
                opacity: hasInterestingItems ? 1 : 0,
                flex: 0,
                transition: 'opacity 200ms ease-out',
            }, children: items.map((item, index) => {
                return (_jsx(AssessmentDataGridItem, Object.assign({}, props, { item: item, settings: settings, numberOfChallenges: props.latestRecord.assessmentContent.numberOfChallenges }), index));
            }) }) }));
}
const AssessmentDataGridItemMessage = styled('p')(({ theme, color }) => ({
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.5),
    marginTop: '3px',
    marginRight: '14px',
    marginLeft: '14px',
    maxWidth: '162px',
    textWrap: 'pretty',
    color,
}));
function AssessmentDataGridItem(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { resetProperty } = props;
    const dataProperty = props.item.dataProperty;
    const resetPropertyAction = useMonitoredCallback(multiassessmentAnalytis.resetToDefaultPressed(props.latestRecord._id, props.latestRecord._assessment, dataProperty), () => resetProperty(dataProperty), [resetProperty, dataProperty]);
    return (_jsxs(Box, { children: [_jsx("div", { style: {
                    border: props.item.validations.length
                        ? '1px solid #F96E64'
                        : props.item.isModified && props.item.needsSuperseding
                            ? '1px solid #ffd054'
                            : props.item.isNonDefault
                                ? '1px solid #575f86'
                                : undefined,
                    backgroundColor: props.item.isNonDefault ? 'rgba(35, 38, 54, 0.5)' : 'rgba(35, 38, 54, 0.25)',
                    position: 'relative',
                    borderRadius: 2 * theme.shape.borderRadius,
                    width: '190px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '120px',
                    padding: theme.spacing(2.5),
                    marginTop: theme.spacing(2.5),
                }, children: _jsxs("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        placeItems: 'center',
                        gap: theme.spacing(1),
                    }, children: [_jsx(Typography, { variant: "subtitle1", style: {
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: theme.typography.pxToRem(16),
                                letterSpacing: '0.25px',
                                color: 'rgb(255, 255, 255)',
                                opacity: props.item.isNonDefault ? 1 : 0.5,
                            }, children: props.item.key }), _jsx(Typography, { variant: "body1", style: {
                                textAlign: 'center',
                                fontSize: theme.typography.pxToRem(14),
                                letterSpacing: theme.typography.pxToRem(0.25),
                            }, children: props.item.isModified ? (_jsx(_Fragment, { children: _jsx(DiffWords, { prev: `${props.item.persistedValue}`, current: `${props.item.value}` }) })) : (_jsx("span", { style: { opacity: 0.85 }, children: props.item.value })) }), props.mode === 'edit' && props.item.isNonDefault ? (_jsx(Button, { sx: {
                                height: '20px',
                                color: '#90AFC5',
                            }, variant: "text", disabled: props.item.defaultSettingHasError, onClick: resetPropertyAction, children: t('pages.multiassessments.resetToDefault') })) : null] }) }), props.item.validations.map((validation, index) => (_jsx(AssessmentDataGridItemMessage, { color: "#F96E64", children: validation.message }, index))), props.item.isModified && props.item.needsSuperseding && props.item.validations.length === 0 && (_jsx(AssessmentDataGridItemMessage, { color: "#FFD054", children: t('pages.multiassessments.thisChangeCausesNewAssessmentVersion') }))] }));
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getDataGridValues(t, settings, persistedSettings, activeAttempts, defaultSettings, visibleDataProperties, validations, isTimeLimitPerChallenge, numberOfChallenges, essentials) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const validationsByField = group(validations, (item) => item.field);
    return [
        {
            dataProperty: 'startDate',
            key: t('pages.multiassessments.startTime'),
            value: settings.startDate instanceof Date
                ? formatDateTime(settings.timezone, settings.startDate)
                : t('pages.multiassessments.noStartTime'),
            persistedValue: persistedSettings.startDate instanceof Date
                ? formatDateTime(persistedSettings.timezone, persistedSettings.startDate)
                : t('pages.multiassessments.noStartTime'),
            validations: validationsByField.startDate || [],
        },
        {
            dataProperty: 'endDate',
            key: t('pages.multiassessments.endTime'),
            value: settings.endDate instanceof Date
                ? formatDateTime(settings.timezone, settings.endDate)
                : t('pages.multiassessments.noEndTime'),
            persistedValue: persistedSettings.endDate instanceof Date
                ? formatDateTime(persistedSettings.timezone, persistedSettings.endDate)
                : t('pages.multiassessments.noEndTime'),
            validations: validationsByField.endDate || [],
        },
        {
            dataProperty: 'sendInvitesOn',
            key: 'Send invites on',
            value: settings.sendInvitesOn instanceof Date
                ? formatDateTime(settings.timezone, settings.sendInvitesOn)
                : t('pages.multiassessments.never'),
            persistedValue: persistedSettings.sendInvitesOn instanceof Date
                ? formatDateTime(persistedSettings.timezone, persistedSettings.sendInvitesOn)
                : t('pages.multiassessments.never'),
            validations: validationsByField.sendInvitesOn || [],
        },
        {
            dataProperty: 'timezone',
            key: 'Time zone',
            value: settings.timezone
                ? settings.timezone.replaceAll('/', ' / ').replaceAll('_', ' ')
                : t('pages.multiassessments.timezoneMissing'),
            persistedValue: persistedSettings.timezone
                ? persistedSettings.timezone.replaceAll('/', ' / ').replaceAll('_', ' ')
                : t('pages.multiassessments.timezoneMissing'),
            validations: [], // validationsByField.timezone || [],
        },
        {
            dataProperty: 'successRatio',
            key: t('pages.multiassessments.successRatio'),
            value: settings.successRatio ? settings.successRatio + '%' : t('pages.multiassessments.noMinimumPassScore'),
            persistedValue: persistedSettings.successRatio
                ? persistedSettings.successRatio + '%'
                : t('pages.multiassessments.noMinimumPassScore'),
            validations: validationsByField.successRatio || [],
        },
        {
            dataProperty: '_certificateTemplate',
            key: t('pages.multiassessments.certificate'),
            value: settings.emitsCertificate && settings._certificateTemplate
                ? (_b = (_a = essentials.certificateTemplates.find((t) => t._id === settings._certificateTemplate)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : `(${t('pages.multiassessments.assessmentCard.dataGridValues.deleted')})`
                : settings.emitsCertificate
                    ? t('pages.multiassessments.default')
                    : t('pages.multiassessments.none'),
            persistedValue: persistedSettings.emitsCertificate && persistedSettings._certificateTemplate
                ? (_d = (_c = essentials.certificateTemplates.find((t) => t._id === persistedSettings._certificateTemplate)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : `(${t('pages.multiassessments.assessmentCard.dataGridValues.deleted')})`
                : persistedSettings.emitsCertificate
                    ? t('pages.multiassessments.default')
                    : t('pages.multiassessments.none'),
            validations: [
                //...(validationsByField.emitsCertificate || []),
                ...(validationsByField._certificateTemplate || []),
            ],
        },
        {
            dataProperty: 'levelGrouping',
            key: t('pages.multiassessments.levelGrouping'),
            value: settings.levelGrouping
                ? (_f = (_e = essentials.levelGroupings.find((l) => l._id === settings.levelGrouping)) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : `(${t('pages.multiassessments.assessmentCard.dataGridValues.deleted')})`
                : t('pages.multiassessments.none'),
            persistedValue: persistedSettings.levelGrouping
                ? (_h = (_g = essentials.levelGroupings.find((l) => l._id === persistedSettings.levelGrouping)) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : `(${t('pages.multiassessments.assessmentCard.dataGridValues.deleted')})`
                : t('pages.multiassessments.none'),
            validations: validationsByField.levelGrouping || [],
        },
        {
            dataProperty: 'timeLimit',
            key: t('pages.multiassessments.timeLimitKey'),
            value: formatTimeLimit(t, settings.timeLimit, isTimeLimitPerChallenge, numberOfChallenges),
            persistedValue: formatTimeLimit(t, persistedSettings.timeLimit, isTimeLimitPerChallenge, numberOfChallenges),
            validations: validationsByField.timeLimit || [],
        },
        {
            dataProperty: 'maxRetries',
            key: t('pages.multiassessments.retryCount'),
            value: settings.maxRetries
                ? settings.maxRetries
                : settings.maxRetries === null
                    ? t('pages.multiassessments.unlimited')
                    : t('pages.multiassessments.none'),
            persistedValue: persistedSettings.maxRetries
                ? persistedSettings.maxRetries
                : persistedSettings.maxRetries === null
                    ? t('pages.multiassessments.unlimited')
                    : t('pages.multiassessments.none'),
            validations: validationsByField.maxRetries || [],
        },
        {
            dataProperty: 'retryWaitingHours',
            key: t('pages.multiassessments.retryIntervalLabel'),
            value: settings.retryWaitingHours ? settings.retryWaitingHours : t('pages.multiassessments.noRetryInterval'),
            persistedValue: persistedSettings.retryWaitingHours
                ? persistedSettings.retryWaitingHours
                : t('pages.multiassessments.noRetryInterval'),
            validations: validationsByField.retryWaitingHours || [],
        },
        {
            dataProperty: 'lmsIntegrated',
            key: t('pages.multiassessments.lmsIntegration'),
            value: settings.lmsIntegrated === true ? t('pages.multiassessments.enabled') : t('pages.multiassessments.disabled'),
            persistedValue: persistedSettings.lmsIntegrated === true
                ? t('pages.multiassessments.enabled')
                : t('pages.multiassessments.disabled'),
            validations: [], //validationsByField.lmsIntegrated || [],
        },
        {
            dataProperty: 'fixedChallenges',
            key: t('pages.multiassessments.fixedChallenges'),
            value: settings.fixedChallenges ? t('pages.multiassessments.yes') : t('pages.multiassessments.no'),
            persistedValue: persistedSettings.fixedChallenges
                ? t('pages.multiassessments.yes')
                : t('pages.multiassessments.no'),
            validations: [], //validationsByField.fixedChallenges || [],
        },
        {
            dataProperty: 'selfAssess',
            key: t('pages.multiassessments.selfAssessmentAllowed'),
            value: settings.selfAssess ? t('pages.multiassessments.yes') : t('pages.multiassessments.no'),
            persistedValue: persistedSettings.selfAssess ? t('pages.multiassessments.yes') : t('pages.multiassessments.no'),
            validations: validationsByField.selfAssess || [],
        },
    ]
        .filter((item) => visibleDataProperties.includes(item.dataProperty))
        .map((item) => (Object.assign(Object.assign({}, item), { isNonDefault: isNonDefault(isTimeLimitPerChallenge, numberOfChallenges, item.dataProperty, settings, defaultSettings), defaultSettingHasError: defaultSettings[item.dataProperty] instanceof Error, isModified: item.dataProperty && item.dataProperty === 'timeLimit'
            ? normalizedTimeLimit(isTimeLimitPerChallenge, settings === null || settings === void 0 ? void 0 : settings[item.dataProperty], { numberOfChallenges }) !==
                normalizedTimeLimit(isTimeLimitPerChallenge, persistedSettings[item.dataProperty], { numberOfChallenges })
            : `${settings === null || settings === void 0 ? void 0 : settings[item.dataProperty]}` !== `${persistedSettings[item.dataProperty]}` })))
        .map((item) => (Object.assign(Object.assign({}, item), { needsSuperseding: item.isModified && ['timeLimit', 'fixedChallenges'].includes(item.dataProperty) && activeAttempts > 0 })));
}
function isNonDefault(isTimeLimitPerChallenge, numberOfChallenges, dataProperty, settings, defaultSettings) {
    return dataProperty && dataProperty === 'timeLimit'
        ? normalizedTimeLimit(isTimeLimitPerChallenge, settings === null || settings === void 0 ? void 0 : settings[dataProperty], { numberOfChallenges }) !==
            getLastGoodValue(defaultSettings[dataProperty])
        : dataProperty && dateFields.includes(dataProperty)
            ? isNonDefaultDate(settings === null || settings === void 0 ? void 0 : settings[dataProperty], settings === null || settings === void 0 ? void 0 : settings.timezone, getLastGoodValue(defaultSettings[dataProperty]), defaultSettings.timezone)
            : (settings === null || settings === void 0 ? void 0 : settings[dataProperty]) !== getLastGoodValue(defaultSettings[dataProperty]);
}
const isNonDefaultDate = (dateSetting, dateSettingTimezone, defaultDateSetting, defaultDateSettingTimezone) => {
    if (dateSetting === null && defaultDateSetting === null) {
        return false;
    }
    if (!(dateSetting instanceof Date) || !(defaultDateSetting instanceof Date)) {
        return true;
    }
    return !isSameLocalTime(dateSetting, dateSettingTimezone, defaultDateSetting, defaultDateSettingTimezone);
};
const isSameLocalTime = (firstDate, firstDateTimezone, secondDate, secondDateTimezone) => {
    return (dayjs(firstDate).tz(firstDateTimezone).format('YYYY-MM-DD HH:mm:ss') ===
        dayjs(secondDate).tz(secondDateTimezone).format('YYYY-MM-DD HH:mm:ss'));
};
const formatTimeLimit = (t, timeLimit, isTimeLimitPerChallenge, numberOfChallenges) => timeLimit
    ? isTimeLimitPerChallenge
        ? t('pages.multiassessments.durationPerChallenge', {
            duration: humanizeDuration(normalizedTimeLimit(isTimeLimitPerChallenge, timeLimit, { numberOfChallenges })),
        })
        : humanizeDuration(normalizedTimeLimit(isTimeLimitPerChallenge, timeLimit, { numberOfChallenges }))
    : t('pages.multiassessments.noTimeLimit');
const tzAbbr = getTimezoneAbbreviations(new Date());
export const dateTimeFormat = (timeZone) => {
    const timeZoneAbbreviation = tzAbbr[timeZone];
    const timeZoneSuffix = timeZoneAbbreviation ? ` ${timeZoneAbbreviation}` : ` ${timeZone}`;
    return timeZone ? `llll[${timeZoneSuffix}]` : 'llll';
};
const formatDateTime = (timeZone, date) => {
    return dayjs
        .utc(date)
        .tz(timeZone || 'utc')
        .locale('en-gb')
        .format(dateTimeFormat(timeZone));
};
const contextUrl = (ref) => match(ref)
    .with({ k: 'course' }, (course) => `/#/courses/manage/${course.courseId}`)
    .with({ k: 'program' }, (program) => `/#/programs/manage/${program.programId}`)
    .exhaustive();
const refKey = (ref) => match(ref)
    .with({ k: 'course' }, (course) => course.courseId)
    .with({ k: 'program' }, (program) => `${program.programId}-${program.stageId}`)
    .exhaustive();
