import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import _ from 'lodash';

angular.module(MODULE).controller('TournamentsRootController', [
  '$scope',
  '$rootScope',
  '$state',
  '$filter',
  'AuthService',
  'Session',
  'TournamentsApiService',
  'TOURNAMENT_STATUS',
  'TOURNAMENT_STAGE_STATUS',
  function (
    $scope,
    $rootScope,
    $state,
    $filter,
    AuthService,
    Session,
    TournamentsApiService,
    TOURNAMENT_STATUS,
    TOURNAMENT_STAGE_STATUS
  ) {
    $scope.tournamentData = {};
    $scope.TOURNAMENT_STATUS = TOURNAMENT_STATUS;
    $scope.TOURNAMENT_CHALLENGE_STATUS = TOURNAMENT_STATUS;
    $scope.TOURNAMENT_STAGE_STATUS = TOURNAMENT_STAGE_STATUS;

    $scope.init = function () {
      checkRootStateAndRedirect($state.current);
    };

    $scope.init();

    const availableLanguages = _.filter(Session.user?.properties?.languages, function (language) {
      return language && ['available', 'active'].indexOf(language.status) >= 0;
    });

    $scope.filterTournament = function (tournament) {
      let number = 0;

      // If tournament is public no modifications are needed
      if (!tournament._company && !tournament._team) {
        return;
      }

      _.map(tournament.languages, function (language) {
        _.map(availableLanguages, function (availableLanguage) {
          if (
            availableLanguage.language._id === language._id &&
            availableLanguage.language._framework === language._framework
          ) {
            number++;
          }
        });
      });

      if (number === 0) {
        tournament.languages = [];
      }
    };

    $scope.processTournamentData = function (tournamentObj) {
      tournamentObj.displayStartTime = $filter('date')(tournamentObj.startTime, 'medium');
      tournamentObj.displayFinishTime = $filter('date')(tournamentObj.finishTime, 'medium');
      tournamentObj.tournamentDuration = moment.duration(tournamentObj.finishTime - tournamentObj.startTime);
      tournamentObj.displayTournamentDuration = tournamentObj.tournamentDuration.humanize();
      // NB this compares client clock to server's finish time, which may not give accurate results
      tournamentObj.remainingTime = moment.duration(tournamentObj.finishTime - Date.now());
      tournamentObj.displayRemainingTime = tournamentObj.remainingTime.humanize();
      tournamentObj.percentageTimeRemaining =
        Math.round((tournamentObj.remainingTime.valueOf / tournamentObj.tournamentDuration.valueOf) * 100) || 0;
      // NB this compares client clock to server's start time, which may not give accurate results
      tournamentObj.displayTimeUntilStart = moment.duration(tournamentObj.startTime - moment()).humanize();
      tournamentObj.link = $state.href(
        'tournaments.list',
        { tournamentId: tournamentObj._id + '' },
        { absolute: true }
      );
    };

    // mostly used by List screen since updating the arrays underneath Bootstrap Carousel tends to mess it up
    $scope.updateTournamentInPlace = function (tournament) {
      TournamentsApiService.getTournament(tournament._id).then(function (data) {
        angular.extend(tournament, data);
        $scope.processTournamentData(tournament);
        $scope.filterTournament(tournament);
      });
    };

    $scope.companySelectedBySCWAdmin = {};

    $scope.removeTournamentDisplayData = function (tournamentObj) {
      delete tournamentObj.displayStartTime;
      delete tournamentObj.displayFinishTime;
      delete tournamentObj.displayRemainingTime;
      delete tournamentObj.displayTimeUntilStart;
      delete tournamentObj.displayPicFile;
    };

    $scope.processParticipant = function (participant) {
      participant.displayName =
        participant.profile.name.first +
        (participant.profile.name.middle ? ' ' + participant.profile.name.middle : '') +
        ' ' +
        participant.profile.name.last;
    };

    function checkRootStateAndRedirect(state) {
      if (!AuthService.isAuthenticated()) {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = $state.params;
        $state.go('login');
      } else if (!AuthService.hasTournamentAccess() && !state.name.startsWith('tournaments.devlympics')) {
        $state.go('tournaments-not-licensed');
      } else if (state.name === 'tournaments') {
        $state.go('tournaments.list');
      }
    }

    const deregisterStateChangeStart = $rootScope.$on(
      '$stateChangeStart',
      function (_event, toState, _toParams, _fromState, _fromParams) {
        checkRootStateAndRedirect(toState);
      }
    );

    $scope.$on('$destroy', deregisterStateChangeStart);
  },
]);
