export const simplifyChanges = (changes) => {
    const segments = [[]];
    for (const change of changes) {
        if (!change.added && !change.removed && change.value.length > 3) {
            segments.push(...[[change], []]);
        }
        else {
            segments[segments.length - 1].push(change);
        }
    }
    return segments.flatMap((segment) => unifyChanges(segment)).filter((x) => x.value);
};
const unifyChanges = (changes) => {
    const removed = changes
        .filter((c) => !c.added)
        .map((c) => c.value)
        .join('');
    const added = changes
        .filter((c) => !c.removed)
        .map((c) => c.value)
        .join('');
    if (removed === added) {
        return [{ value: added, count: added.length }];
    }
    else {
        return [
            { value: removed, removed: true, count: removed.length },
            { value: added, added: true, count: added.length },
        ];
    }
};
