import angular from 'angular';
import MODULE from './module';

// This code is taken from https://gist.github.com/JustMaier/6ef7788709d675bd8230

angular
  .module(MODULE)
  .service('ngCopy', [
    '$window',
    function ($window) {
      var body = angular.element($window.document.body);
      var textarea = angular.element('<textarea/>');
      textarea.css({
        position: 'fixed',
        opacity: '0',
      });

      return function (toCopy) {
        textarea.val(toCopy);
        body.append(textarea);
        textarea[0].select();

        try {
          var successful = document.execCommand('copy');
          if (!successful) throw successful;
        } catch (err) {
          window.prompt('Copy to clipboard: Ctrl+C', toCopy);
        }

        textarea.remove();
      };
    },
  ])
  .directive('ngClickCopy', [
    'ngCopy',
    function (ngCopy) {
      return {
        restrict: 'A',
        link: function (_scope, element, attrs) {
          element.bind('click', function (_e) {
            ngCopy(attrs.ngClickCopy);
          });
        },
      };
    },
  ]);
