import { dayjs, msAsMinutes, msAsSeconds } from '@securecodewarrior/design-system-react';
import { useTranslation } from 'react-i18next';
export const useDisplayTime = () => {
    const { t } = useTranslation();
    return (ms) => {
        return dayjs.duration({ milliseconds: ms }).asMinutes() >= 1
            ? t('common.datetime.minutes', { minutes: Math.floor(msAsMinutes(ms)) })
            : t('common.datetime.seconds', { seconds: Math.floor(msAsSeconds(ms)) });
    };
};
