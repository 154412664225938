import { jsx as _jsx } from "react/jsx-runtime";
import { Component, useState } from 'react';
import { usePlatformContext } from './platformContext';
import { TRPCClientError } from '@trpc/client';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from './ErrorPage';
export class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                hasError: false,
            }
        });
    }
    static getDerivedStateFromError(_) {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        var _a, _b;
        // eslint-disable-next-line react/prop-types
        (_b = (_a = this.props).onError) === null || _b === void 0 ? void 0 : _b.call(_a, error, info);
    }
    render() {
        if (this.state.hasError) {
            // eslint-disable-next-line react/prop-types
            return this.props.fallback;
        }
        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}
export const ErrorWrapper = (props) => {
    const platformCtx = usePlatformContext();
    const [error, setError] = useState(undefined);
    return (_jsx(ErrorBoundary, { fallback: _jsx(GenericErrorPage, { error: error }), onError: (error, info) => {
            var _a;
            if (error instanceof TRPCClientError && ((_a = error.data) === null || _a === void 0 ? void 0 : _a.httpStatus) === 401) {
                platformCtx.onUnAuthenticated();
                return;
            }
            console.error('caught error in errorboundary:', error, info);
            setError(error);
        }, children: props.children }));
};
export const GenericErrorPage = (props) => {
    var _a, _b;
    const { t } = useTranslation();
    const platformCtx = usePlatformContext();
    return (_jsx(ErrorPage, { title: t('pages.explore.unexpectedError.title'), description: t('pages.explore.unexpectedError.description'), errorMessage: (_b = (_a = props.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : undefined, onAction: platformCtx.navigator.reload, actionText: t('pages.explore.unexpectedError.retry') }));
};
export default ErrorBoundary;
