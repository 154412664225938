import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { CommunicationsForm } from './CommunicationsForm';
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
import { decideThemeByHashRoute } from '../../utils/themes';
import { Typography } from '@mui/material';
import { Error as MuiErrorIcon } from '@mui/icons-material';
import { CloseIconButton } from '../../common/components/atoms/CloseIconButton';
import { CommunicationsToaster, CommunicationsToasterMessage } from './Communications.styled';
export const CommunicationsRoute = ({ commsCentreQuillEnabled, goalsFeatureEnabled }) => {
    const [showToaster, setShowToaster] = useState({
        open: false,
        message: '',
    });
    const showToasterMessage = (message) => {
        setShowToaster({
            open: true,
            message,
        });
    };
    return (_jsxs(DSThemeProvider, { theme: decideThemeByHashRoute(window.location.hash), children: [_jsx(CommunicationsForm, { commsCentreQuillEnabled: commsCentreQuillEnabled, goalsFeatureEnabled: goalsFeatureEnabled, showToaster: showToasterMessage }), _jsx(CommunicationsToaster, { open: showToaster.open, anchorOrigin: { vertical: 'top', horizontal: 'center' }, onClose: () => setShowToaster({ open: false, message: '' }), autoHideDuration: 3000, message: _jsxs(CommunicationsToasterMessage, { children: [_jsx(MuiErrorIcon, {}), _jsx(Typography, { children: showToaster.message })] }), action: _jsx(CloseIconButton, { onClick: () => {
                        setShowToaster({ open: false, message: '' });
                    }, "aria-label": 'Close' }) })] }));
};
