import angular from 'angular';
import MODULE from './module';

import { USER_ROLES } from '../../auth/constants';
import DsAssessmentsListTemplate from './DsAssessmentsListDirective.html';
import { assessmentStatusMap, getRetriesAllowed } from '../utils';

const mapAssessment = (mapperFunctions) => (list) =>
  list.map((assessment) =>
    mapperFunctions.reduce((mapped, f) => {
      return { ...mapped, ...f(assessment) };
    }, {})
  );

const mapBaseAssessment = (router) => (entry) => {
  return {
    id: entry._id,
    title: entry.name,
    version: entry.version + 1,
    description: entry.description,
    status: assessmentStatusMap[entry.status],
    challenges: entry.numberOfChallenges,
    timeLimit: entry.timeLimit || null,
    difficulty: entry.difficulty,
    selfAssess: entry.selfAssess,
    fixedChallenges: entry.fixedChallenges,
    retriesAllowed: getRetriesAllowed(entry),
    url: router.href('assessments.view', { assessmentId: entry._id, from: 'assessments' }),
  };
};

const mapAssessmentAdminFields = (entry) => {
  return {
    completedAverageScore: entry.averages?.[0]?.averageScore,
    completedAverageTime: entry.averages?.[0]?.averageTime,
    completedRatio: entry.completionPercentage,
  };
};

export const assessmentsAdapterDeveloper = (router, list) => mapAssessment([mapBaseAssessment(router)])(list);
export const assessmentsAdapterAdmin = (router, list) =>
  mapAssessment([mapBaseAssessment(router), mapAssessmentAdminFields])(list);

angular.module(MODULE).directive('dsScwAssessmentsListPage', [
  '$state',
  function ($state) {
    return {
      restrict: 'E',
      // For 2-way binding using `=` always pass objects! 2-way binding on strings doesn't work!
      scope: {
        assessmentsPage: '=',
        assessmentsInfo: '=',
        sorting: '=',
        levelGroups: '<',
        getAssessmentList: '<',
        assessments: '<',
        levelGroupingEnabled: '<',
        view: '<',
        clearFilters: '<',
        setSort: '<',
        user: '<',
      },
      templateUrl: DsAssessmentsListTemplate,
      link: function (scope) {
        // When navigating to this page the filter state gets loaded from localstorage. Since the search term is managed
        // by the React component we need to set the default value
        scope.defaultSearch = scope.assessmentsInfo.assessmentSearchText;

        const onPageSelected = async (page) => {
          scope.assessmentsPage.options.page = page;
          scope.getAssessmentList();
        };

        const onItemsPerPageChanged = async (itemCount) => {
          scope.assessmentsPage.options.size = itemCount;
          scope.assessmentsPage.options.page = 1;
          scope.getAssessmentList();
        };

        const adaptPagination = (paginationInfo) => ({
          page: paginationInfo?.page ?? 1,
          perPage: paginationInfo?.size ?? 10,
          pages: paginationInfo?.pages ?? 0,
          total: paginationInfo?.total ?? 0,
          onPageSelected,
          onItemsPerPageChanged,
        });

        const transformLevelGroups = (levelGroups) => {
          scope.groups = levelGroups.reduce(function (map, obj) {
            map[obj._id] = obj.name;
            return map;
          }, {});
        };
        transformLevelGroups(scope.levelGroups);
        scope.$watchCollection('levelGroups', transformLevelGroups);

        scope.onSortChange = (sort) => {
          scope.sorting.sortSelectInput = sort;
          scope.setSort(sort);
          scope.getAssessmentList();
        };

        scope.onStatusChange = (status) => {
          scope.sorting.filterAssessmentsByStatus = status;
          scope.assessmentsPage.options.page = 1;
          scope.getAssessmentList();
        };

        scope.onGroupChange = (group) => {
          scope.sorting.filterAssessmentsByGroupId = group === '100' ? '' : group;
          scope.assessmentsPage.options.page = 1;
          scope.getAssessmentList();
        };

        scope.onSearchChange = (searchTerm) => {
          scope.assessmentsInfo.assessmentSearchText = searchTerm;
          scope.assessmentsPage.options.page = 1;
          scope.getAssessmentList();
        };

        scope.$watch(
          'assessmentsPage.options',
          (data) => {
            scope.paginationInfo = adaptPagination(data);
          },
          true
        );

        const assessmentsAdapter =
          scope.user.role === USER_ROLES.player ? assessmentsAdapterDeveloper : assessmentsAdapterAdmin;
        scope.$watch('assessments', (promise) => {
          scope.transformedAssessments = promise.then((list) => assessmentsAdapter($state, list));
        });
      },
    };
  },
]);
