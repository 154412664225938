import angular from 'angular';
import MODULE from './module';

angular
  .module(MODULE)
  .constant('TOURNAMENT_STATUS', {
    ACTIVE: 'active',
    LOCKED: 'not started',
    COMPLETED: 'finished',
  })
  .constant('TOURNAMENT_CHALLENGE_STATUS', {
    DONE: 'done',
    IN_PROGRESS: 'in_progress',
    PENDING: 'pending',
    SKIPPED: 'skipped',
  })
  .constant('TOURNAMENT_STAGE_STATUS', {
    CORRECT: 'correct',
    INCORRECT: 'incorrect',
    IN_PROGRESS: 'in_progress',
    PENDING: 'pending',
    SKIPPED: 'skipped',
  });
