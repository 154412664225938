import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { List } from './List';
import { AdminBody, AdminHeader, AdminPageContainer, Icon } from './ViewStyled';
export function View(props) {
    const { showBackNavigation, onBack, adminApiService, company } = props;
    const [integrations, setIntegrations] = useState([]);
    const fetchIntegrations = () => {
        adminApiService.getIntegrations(company._id).then(setIntegrations);
    };
    useEffect(() => {
        fetchIntegrations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company._id]);
    const handleDeleteIntegration = (integrationId, tenantId) => {
        props.adminApiService.deleteIntegration(company._id, integrationId, tenantId).then(() => {
            fetchIntegrations();
        });
    };
    return (_jsxs(AdminPageContainer, { children: [_jsx(AdminHeader, { children: _jsxs("h1", { className: "header", children: [showBackNavigation && (_jsx(Icon, { onClick: onBack, role: "button", "aria-label": "Go back", tabIndex: 0, className: "glyphicon glyphicon-arrow-left cursor-pointer" })), company.name] }) }), _jsx(AdminBody, { children: _jsx(List, { integrations: integrations, translator: props.translator, onDeleteIntegration: handleDeleteIntegration }) })] }));
}
