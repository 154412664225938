import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import * as _ from 'lodash';
import companiesSearchTemplate from './admin.companies.search.html';
import companiesEditTemplate from './admin.companies.edit.html';

angular.module(MODULE).directive('adminCompaniesSearchTable', [
  '$translate',
  '$timeout',
  '$uibModal',
  '$state',
  '$rootScope',
  '$swal',
  'DownloadFile',
  'AuthService',
  'AdminApiService',
  'ErrorHandler',
  'MetadataApiService',
  function (
    $translate,
    $timeout,
    $uibModal,
    $state,
    $rootScope,
    $swal,
    DownloadFile,
    AuthService,
    AdminApiService,
    ErrorHandler,
    MetadataApiService
  ) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: companiesSearchTemplate,
      scope: {
        sort: '=?sort',
        autoInit: '=?autoInit',
        paginationPreferences: '=?paginationPreferences',
        onCompanyUpdate: '&?onCompanyUpdate',
        autoFocus: '=?autoFocus',
        bind: '=?bind',
      },
      link: function (scope, element, _attrs, _controller, _transcludeFn) {
        scope.bind = scope.bind || {};
        scope.auth = AuthService;
        scope.metadata = $rootScope.metadata;

        // Being: subscribe to company updates in other instances
        var subscribed = [];
        subscribed.push(
          scope.$root.$on('admin:companies-updated', function () {
            reload(true);
          })
        );

        scope.$on('$destroy', function () {
          var idx;
          for (idx = 0; idx < subscribed.length; idx++) subscribed[idx]();
        });
        // End: subscribe to company updates in other instances

        // Begin: expose behaviors and data
        scope.searchText = '';
        scope.addCompany = addCompany;
        scope.toggleCompanyStatus = toggleCompanyStatus;
        scope.removeCompany = removeCompany;
        scope.search = search;
        scope.reload = reload;
        scope.baseSearch = {}; // base search control
        scope.paginationOptions = {};
        scope.bulkActions = {};
        scope.companiesQuery = {};
        scope.downloadCompaniesCSV = downloadCompaniesCSV;
        scope.onSearchCompletion = onSearchCompletion;
        scope.accountOwners = [{ id: $rootScope.Session.user._id, name: 'Me' }];
        scope.customerPlans = [];
        scope.colSpan = 9;
        scope.placeholders = _.fill(new Array(scope.colSpan)).map((_, i) => i + 1);
        // End: expose behaviors and data

        MetadataApiService.getCustomerPlans().then((data) => {
          scope.customerPlans = (data || []).map((x) => ({ id: x._id, name: $translate.instant(x.name) }));
          scope.$apply();
        });

        $timeout(init, 500);
        // End: expose behaviors and data

        function init() {
          // End: exposed methods

          if (!init.done) {
            $timeout(function () {
              scope.$watch(
                'companiesQuery',
                function (_n, _o) {
                  if (search.inprogress === true) return;
                  if (JSON.stringify(_n) === JSON.stringify(_o)) return;
                  reload(false);
                },
                true
              );

              const hasFilterToApplyWhenScreenInit = _.chain(scope.companiesQuery)
                .some((val) => !!val)
                .value();
              if (hasFilterToApplyWhenScreenInit) {
                // tick if filters already set on first render
                reload(false);
              }
            }, 100); // tick 'til preferencs are set
            init.done = true;
          }
        }

        // visual: scroll to table after search and pagination (low-res hack)
        function onSearchCompletion(_page) {
          if (scope.autoFocus) {
            $timeout(function () {
              if (scope.autoFocus === 'always' || (scope.autoFocus === 'non-first' && onSearchCompletion.loadedFirst)) {
                // var elementTop = element.offset().top;
                // $window.scroll({ top: elementTop - 200, behavior: "smooth" });
                angular.element('table tbody tr:nth(0)', element).focus();
              }
            }, 500);
          }

          if (!onSearchCompletion.loadedFirst) {
            onSearchCompletion.loadedFirst = true;
          }
          search.inprogress = false;
        }

        function reload(keepOptions) {
          if (!scope || !scope.baseSearch || !scope.baseSearch.search) return;

          if (scope.baseSearch.search.loaded) {
            search.inprogress = true;
            return scope.baseSearch.search(
              scope.baseSearch.search.text || '',
              keepOptions,
              _.mapValues(scope.companiesQuery, function (val) {
                return val === '' || val === null ? undefined : val;
              })
            );
          }
        }

        function search(text, keepOptions) {
          scope.companiesQuery = scope.companiesQuery || {};

          search.loaded = true;
          search.text = text;
          search.inprogress = true;
          scope.baseSearch.search(
            text,
            keepOptions,
            _.mapValues(scope.companiesQuery, function (val) {
              return val === '' || val === null ? undefined : val;
            })
          );
        }

        function removeCompany(company) {
          $swal({
            title: $translate.instant('DELETE_COMPANY'),
            text: $translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMPANY'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: $translate.instant('DELETE'),
            cancelButtonText: $translate.instant('CANCEL'),
            keyResolution: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              AdminApiService.deleteCompany(company._id)
                .then(function (_data) {
                  reload(true);

                  if (scope.onCompanyUpdate) scope.onCompanyUpdate();
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError($translate.instant('FAILED_TO_REMOVE_COMPANY'), response);
                });
            }
          });
        }

        function toggleCompanyStatus(companyData) {
          var previousStatus = companyData.status;
          /** Removing the plan property to respect to company update schema. The additional
           * plan is purely for display and hence is removed while updating company as company
           * object requires only the customer plan property and does not save the plan name
           **/
          const { plan: _plan, ...company } = companyData;
          var payload = angular.copy(company);
          company.status = company.status === 'enabled' ? 'disabled' : 'enabled';
          payload.status = company.status;

          var action = company.status === 'enabled' ? 'enable' : 'disable';
          AdminApiService.updateCompany(payload)
            .then(function (_data) {
              reload(true);

              if (scope.onCompanyUpdate) scope.onCompanyUpdate();
            })
            .catch(function (response) {
              company.status = previousStatus;
              ErrorHandler.addHttpError($translate.instant('FAILED_TO_ACTION_COMPANY', { action: action }), response);
            });
        }

        function addCompany() {
          return $uibModal
            .open({
              templateUrl: companiesEditTemplate,
              controller: 'EditCompanyModalController',
              resolve: {
                company: {},
              },
              size: 'lg',
              scope: scope,
              backdrop: 'static',
            })
            .result.then(function (company) {
              $state.go('admin.company', { companyId: company._id, company: company });

              if (scope.onCompanyUpdate) scope.onCompanyUpdate();
            })
            .catch(angular.noop);
        }

        function downloadCompaniesCSV() {
          downloadCompaniesCSV.inprogress = true;
          var nowDate = new Date().toISOString();
          var filename = $translate.instant('COMPANIES') + '-' + nowDate + '.csv';
          var tz = moment.tz.guess();

          AdminApiService.getCompaniesReport(
            scope.baseSearch.search.text,
            scope.companiesQuery.customerType,
            scope.companiesQuery.accountOwnerId,
            scope.companiesQuery.status,
            scope.companiesQuery.customerPlan,
            'name',
            tz
          )
            .then(function (csv) {
              return DownloadFile.csv(filename, csv);
            })
            .finally(function () {
              downloadCompaniesCSV.inprogress = false;
            });
        }

        scope.go = function (state, vars) {
          $state.go(state, vars);
        };
      },
    };
  },
]);
