import { debounce } from '@mui/material';
import { getCustomVulnerabilityObjectives, getDaysDelta, includesMostCommonVulnerabilitiesObjective } from '../utils';
const editableFields = [
    'name',
    'badge',
    'isMandatory',
    'objectives',
    'startDate',
    'endDate',
    'assignedTeamIds',
];
const getUpdatedFields = (goalBuffer, initialGoal) => {
    const updatedFields = [];
    editableFields.forEach((field) => {
        if (`${goalBuffer[field]}` !== `${initialGoal[field]}`) {
            updatedFields.push(field);
        }
    });
    return updatedFields;
};
export const goalDetailsAnalytics = (sendEvent) => ({
    onNewGoalCreated: (goalInput) => {
        var _a;
        try {
            const startDateDeltaInDays = getDaysDelta(goalInput.startDate, new Date());
            const endDateDeltaInDays = getDaysDelta(goalInput.endDate, new Date());
            let initialStatus = 'in_progress';
            if (goalInput.endDate && goalInput.endDate.getTime() < Date.now()) {
                initialStatus = 'finished';
            }
            if (goalInput.startDate && goalInput.startDate.getTime() > Date.now()) {
                initialStatus = 'scheduled';
            }
            sendEvent({
                event: 'New Goal Created',
                scope: 'admin-goal-details',
                vulnerabilityCount: getCustomVulnerabilityObjectives(goalInput.objectives).length,
                mostCommonVulnerabilitiesSelected: includesMostCommonVulnerabilitiesObjective(goalInput.objectives),
                startDateDeltaInDays,
                endDateDeltaInDays,
                initialStatus,
                includesBadge: !!goalInput.badge,
                isMandatory: goalInput.isMandatory,
                assignedTeamCount: ((_a = goalInput.assignedTeamIds) === null || _a === void 0 ? void 0 : _a.length) || 0,
            });
        }
        catch (error) {
            console.error('error in goalDetailsAnalytics.onNewGoalCreated: ', error);
        }
    },
    onDraftCreated: (goalInput) => {
        var _a, _b, _c, _d, _e, _f;
        try {
            const startDate = ((_b = (_a = goalInput.startDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a)) || 'invalid';
            const endDate = ((_d = (_c = goalInput.endDate) === null || _c === void 0 ? void 0 : _c.getTime) === null || _d === void 0 ? void 0 : _d.call(_c)) || 'invalid';
            sendEvent({
                event: 'New Draft Created',
                scope: 'admin-goal-details',
                vulnerabilityCount: getCustomVulnerabilityObjectives(goalInput.objectives).length,
                mostCommonVulnerabilitiesSelected: includesMostCommonVulnerabilitiesObjective(goalInput.objectives),
                startDate,
                endDate,
                includesBadge: !!goalInput.badge,
                isMandatory: goalInput.isMandatory,
                nameLength: ((_e = goalInput.name) === null || _e === void 0 ? void 0 : _e.length) || 0,
                assignedTeamCount: ((_f = goalInput.assignedTeamIds) === null || _f === void 0 ? void 0 : _f.length) || 0,
            });
        }
        catch (error) {
            console.error('error in goalDetailsAnalytics.onDraftCreated: ', error);
        }
    },
    onBackLinkClicked: (goalStatus, goalBuffer, initialGoal) => {
        const unsavedFields = getUpdatedFields(goalBuffer, initialGoal);
        sendEvent({
            event: 'Back Link Clicked',
            scope: 'admin-goal-details',
            goalStatus,
            unsavedFields: unsavedFields.join(', '),
        });
    },
    onMandatoryToggled: (goalStatus, isMandatory) => {
        sendEvent({
            event: 'Mandatory Toggled',
            scope: 'admin-goal-details',
            goalStatus,
            isMandatory,
        });
    },
    onBadgeUpdated: (goalStatus, badge) => {
        sendEvent({
            event: 'Badge Updated',
            scope: 'admin-goal-details',
            goalStatus,
            badgeSize: (badge === null || badge === void 0 ? void 0 : badge.length) || 0,
        });
    },
    onObjectiveVulnerabilitiesUpdated: (goalStatus, vulnsBefore, vulnsAfter) => {
        sendEvent({
            event: 'Objective Vulnerabilities Updated',
            scope: 'admin-goal-details',
            goalStatus,
            countBefore: vulnsBefore.length,
            countAfter: vulnsAfter.length,
            vulnsBefore: vulnsBefore.join(', '),
            vulnsAfter: vulnsAfter.join(', '),
        });
    },
    onMostCommonVulnerabilitiesToggled: (goalStatus, selected) => {
        sendEvent({
            event: 'Most Common Vulnerabilities Toggled',
            scope: 'admin-goal-details',
            goalStatus,
            selected,
        });
    },
    onTeamAssignmentUpdated: (goalStatus, assignedTeamIdsBefore, assignedTeamIdsAfter, companyTeamCount) => {
        sendEvent({
            event: 'Team Assignment Updated',
            scope: 'admin-goal-details',
            goalStatus,
            countBefore: assignedTeamIdsBefore.length,
            countAfter: assignedTeamIdsAfter.length,
            companyTeamCount,
        });
    },
    onStartDateUpdated: (goalStatus, startDate, endDate) => {
        var _a, _b;
        const startDateInMs = ((_a = startDate === null || startDate === void 0 ? void 0 : startDate.getTime) === null || _a === void 0 ? void 0 : _a.call(startDate)) || 'invalid';
        const endDateInMs = ((_b = endDate === null || endDate === void 0 ? void 0 : endDate.getTime) === null || _b === void 0 ? void 0 : _b.call(endDate)) || 'invalid';
        const goalLengthDays = getDaysDelta(endDate, startDate, true);
        sendEvent({
            event: 'Start Date Updated',
            scope: 'admin-goal-details',
            goalStatus,
            startDate: startDateInMs,
            endDate: endDateInMs,
            goalLengthDays,
            startDateDeltaInDays: getDaysDelta(startDate, new Date()),
        });
    },
    onEndDateUpdated: (goalStatus, startDate, endDate) => {
        var _a, _b;
        const startDateInMs = ((_a = startDate === null || startDate === void 0 ? void 0 : startDate.getTime) === null || _a === void 0 ? void 0 : _a.call(startDate)) || 'invalid';
        const endDateInMs = ((_b = endDate === null || endDate === void 0 ? void 0 : endDate.getTime) === null || _b === void 0 ? void 0 : _b.call(endDate)) || 'invalid';
        const goalLengthDays = getDaysDelta(endDate, startDate, true);
        sendEvent({
            event: 'End Date Updated',
            scope: 'admin-goal-details',
            goalStatus,
            endDate: endDateInMs,
            startDate: startDateInMs,
            goalLengthDays,
            endDateDeltaInDays: getDaysDelta(endDate, new Date()),
        });
    },
    onNameUpdated: debounce((goalStatus, name) => {
        sendEvent({
            event: 'Name Updated',
            scope: 'admin-goal-details',
            goalStatus,
            nameLength: (name === null || name === void 0 ? void 0 : name.length) || 0,
        });
    }, 1200),
    onArchiveClicked: (goalStatus) => {
        sendEvent({
            event: 'Archive Clicked',
            scope: 'admin-goal-details',
            goalStatus,
        });
    },
    onArchiveDialogClosed: (goalStatus, confirmed) => {
        sendEvent({
            event: 'Archive Dialog Closed',
            scope: 'admin-goal-details',
            goalStatus,
            confirmed,
        });
    },
    onPublishGoalDialogOpened: (goalStatus, scenario) => {
        sendEvent({
            event: 'Publish Dialog Opened',
            scope: 'admin-goal-details',
            goalStatus,
            scenario,
        });
    },
    onPublishGoalDialogClosed: (goalStatus, scenario, confirmed) => {
        sendEvent({
            event: 'Publish Dialog Closed',
            scope: 'admin-goal-details',
            goalStatus,
            confirmed,
            scenario,
        });
    },
    onDeleteClicked: (goalStatus) => {
        sendEvent({
            event: 'Delete Clicked',
            scope: 'admin-goal-details',
            goalStatus,
        });
    },
    onDeleteDialogClosed: (goalStatus, confirmed) => {
        sendEvent({
            event: 'Delete Dialog Closed',
            scope: 'admin-goal-details',
            goalStatus,
            confirmed,
        });
    },
    onChangesSaved: (goalStatus, goalBuffer, initialGoal, updatedStatus) => {
        const updatedFields = getUpdatedFields(goalBuffer, initialGoal);
        sendEvent({
            event: 'Changes Saved',
            scope: 'admin-goal-details',
            goalStatus,
            updatedFields: updatedFields.join(', '),
            updatedStatus,
        });
    },
    onVulnerabilityDialogOpened: (goalStatus, vulnCountBefore) => {
        sendEvent({
            event: 'Vulnerability Dialog Opened',
            scope: 'admin-goal-details',
            goalStatus,
            vulnCountBefore,
        });
    },
    onVulnerabilityDialogClosed: (goalStatus) => {
        sendEvent({
            event: 'Vulnerability Dialog Closed',
            scope: 'admin-goal-details',
            goalStatus,
            confirmed: false,
        });
    },
    onTeamAssignmentDialogOpened: (goalStatus, teamCountBefore, companyTeamCount) => {
        sendEvent({
            event: 'Team Assignment Dialog Opened',
            scope: 'admin-goal-details',
            goalStatus,
            teamCountBefore,
            companyTeamCount,
        });
    },
    onTeamAssignmentyDialogClosed: (goalStatus) => {
        sendEvent({
            event: 'Team Assignment Dialog Closed',
            scope: 'admin-goal-details',
            goalStatus,
            confirmed: false,
        });
    },
    onTeamAssignmentDialogSelectionChange: (goalStatus, companyTeamCount, visibleRowCount, selectionCountBefore, selectionCountAfter) => {
        sendEvent({
            event: 'Team Assignment Dialog Selection Change',
            scope: 'admin-goal-details',
            goalStatus,
            companyTeamCount,
            visibleRowCount,
            selectionCountBefore,
            selectionCountAfter,
        });
    },
    onTeamAssignmentDialogFilterChange: (goalStatus, companyTeamCount, visibleRowCount, searchTerms) => {
        sendEvent({
            event: 'Team Assignment Dialog Filter Change',
            scope: 'admin-goal-details',
            goalStatus,
            companyTeamCount,
            visibleRowCount,
            searchTerms: searchTerms.join(', '),
        });
    },
    onTeamAssignmentDialogSortChange: (goalStatus, companyTeamCount, visibleRowCount, sortModel) => {
        var _a, _b;
        sendEvent({
            event: 'Team Assignment Dialog Sort Change',
            scope: 'admin-goal-details',
            goalStatus,
            companyTeamCount,
            visibleRowCount,
            sortColumn: (_a = sortModel[0]) === null || _a === void 0 ? void 0 : _a.field,
            sortDirection: (_b = sortModel[0]) === null || _b === void 0 ? void 0 : _b.sort,
        });
    },
    onVulnerabilitySearchUpdated: debounce((goalStatus, search, groupedCategories, filteredCategorySelection) => {
        try {
            const updatedCollection = filteredCategorySelection(groupedCategories, search);
            sendEvent({
                event: 'Vulnerability Search Updated',
                scope: 'admin-goal-details',
                goalStatus,
                search,
                subcategoryResultCount: updatedCollection.reduce((acc, cat) => acc + cat.categories.length, 0),
                categoryResultCount: updatedCollection.length,
            });
        }
        catch (error) {
            console.error('error in onVulnerabilitySearchUpdated: ', error);
        }
    }, 700),
    onVulnerabilityCategorySelected: (goalStatus, categoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Category Selected',
            scope: 'admin-goal-details',
            goalStatus,
            categoryKey,
            search,
        });
    },
    onVulnerabilityCategoryUnselected: (goalStatus, categoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Category Unselected',
            scope: 'admin-goal-details',
            goalStatus,
            categoryKey,
            search,
        });
    },
    onVulnerabilitySubcategorySelected: (goalStatus, subcategoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Subcategory Selected',
            scope: 'admin-goal-details',
            goalStatus,
            subcategoryKey,
            search,
        });
    },
    onVulnerabilitySubcategoryUnselected: (goalStatus, subcategoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Subcategory Unselected',
            scope: 'admin-goal-details',
            goalStatus,
            subcategoryKey,
            search,
        });
    },
});
