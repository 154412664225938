// eslint-disable-next-line sonarjs/cognitive-complexity
export const appBarStyles = (mobile, opened, theme) => ({
    root: Object.assign(Object.assign(Object.assign({ boxShadow: theme.shadows[2], background: theme.palette.grayscale.bg3, '&, & nav': Object.assign({ boxSizing: 'border-box', display: 'flex', width: '100%' }, (mobile
            ? { flexDirection: 'column', height: 'auto', minHeight: '64px' }
            : {
                flexDirection: 'row',
                height: '64px',
            })), '& nav': {
            backgroundColor: 'unset',
            padding: '0 40px',
        }, 
        // Avatar
        '.scw-avatar': {
            '.scw-avatar-image-wrapper': {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                '.scw-avatar-image': {
                    width: 'auto',
                },
            },
            outlineOffset: '12px',
            cursor: 'pointer',
            marginLeft: '15px',
        }, '.ScwAppBarMenu-root > .MuiBox-root:has(.scw-avatar)': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'default',
        }, '.ScwAppBar-logoLink': {
            margin: '4px 15px 4px 0',
        }, 
        // Loading and SearchInput
        '.ScwLoading-root': Object.assign({}, (mobile ? { display: 'none' } : {})), 
        // SearchInput
        '.ScwSearchInput-root': Object.assign({}, (mobile ? { display: 'none' } : { margin: '0 8px' })) }, (mobile
        ? {
            ['div:not(.ScwAppBarButton-root, .ScwAppBarMenuItem-root, .ScwLogo),' +
                'nav:not(.ScwAppBarButton-root, .ScwAppBarMenuItem-root, .ScwLogo)']: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
            },
            '.ScwHamburger': {
                fontSize: '24px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: '0px 8px',
                padding: '20px 10px',
                width: '100%',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
        }
        : {})), (mobile
        ? {}
        : {
            '.ScwLogo:focus, .scw-avatar:focus': {
                outline: `3px solid ${theme.palette.secondary.main}`,
                borderRadius: '4px',
            },
            /** SearchInput */
            '& .MuiInputBase-root:focus-within': {
                outline: `2px solid ${theme.palette.primary.main}`,
                '.MuiButton-root:focus': {
                    outline: `2px solid ${theme.palette.primary.higher}`,
                },
            },
        })), { '.ScwAppBarMenu-root > .MuiBox-root': {
            height: '100%',
        } }),
    list: Object.assign(Object.assign({}, (opened ? {} : { display: 'none' })), { width: '100%' }),
});
