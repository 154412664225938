import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).factory('GeoIPService', [
  '$log',
  '$http',
  '$window',
  function ($log, $http, $window) {
    var GeoIPService = {};

    GeoIPService.getLocation = function () {
      $log.debug('GeoIPService - getLocation()');
      return $http
        .get(`${$window.SCW_ENV.ApiEndpoint}/geo`, {
          withCredentials: false,
        })
        .then((response) => response.data);
    };

    return GeoIPService;
  },
]);
