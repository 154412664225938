import angular from 'angular';
import templateUrl from './maturity-badge.html';

const app = angular.module('SCWMaturityBadge', []);
export default app.name;

app.directive('maturityBadge', function () {
  return {
    restrict: 'E',
    replace: true,
    templateUrl,
    scope: {
      level: '@',
      size: '@',
      alt: '@',
    },
  };
});
