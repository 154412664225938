import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('Game013QuestController', [
  '$state',
  '$scope',
  'Game013Api',
  '$translate',
  'AnalyticsService',
  'AnalyticsEvents',
  function ($state, $scope, Game013Api, $translate, AnalyticsService, AnalyticsEvents) {
    $scope.playmode = 'training';
    $scope.quest = null;
    $scope.loading = true;
    $scope.beginChallenge = beginChallenge;
    $scope.nextChallenge = nextChallenge;
    $scope.skipChallenge = skipChallenge;

    this.$onInit = function () {
      $scope.loading = true;

      Game013Api.getChallenge($state.params)
        .then((response) => {
          $scope.quest = response;
          $scope.loading = false;
        })
        .catch(handleError);
    };

    function beginChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.BEGIN_CHALLENGE);
    }

    function getQuestProgressPriorToExecutingNextStep(nextStep) {
      const { _language, _framework, _realm, _level, _quest } = $state.params;
      Game013Api.getQuestProgress(_language, _framework, _realm, _level, _quest)
        .then((response) => {
          if (response.status === 'done') {
            return back(true); // replicate the behaviour of the back button to go back to the level page
          } else {
            return nextStep();
          }
        })
        .catch(handleError);
    }

    function nextChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.COMPLETE_CHALLENGE);
      $scope.loading = true;
      getQuestProgressPriorToExecutingNextStep(reload);
    }

    function skipChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.SKIP_CHALLENGE);
      $scope.loading = true;
      getQuestProgressPriorToExecutingNextStep(skip);
    }

    function skip() {
      Game013Api.skipChallenge($state.params).then(reload).catch(handleError);
    }

    function reload() {
      return $state.go($state.current.name, $state.params, { reload: true });
    }

    function back(reload) {
      $state.go(
        'game.013.play.level',
        {
          _language: $state.params._language,
          _framework: $state.params._framework,
          _realm: $state.params._realm,
          _level: $state.params._level,
          _quest: $state.params._quest,
        },
        { reload }
      );
    }

    function sendAnalyticsEvent(eventName) {
      const eventProps = {
        challenge_id: $scope.quest?.challenge?._id,
        portal_area: $scope.playmode,
        language_framework: `${$state.params?._language}:${$state.params?._framework}`,
      };
      AnalyticsService.logEvent(eventName, eventProps);
    }

    function handleError(response) {
      $scope.error = {
        status: response.status || '',
        message: `${$translate.instant('ERROR_RETRIEVING_CHALLENGE')}: ${response.data.error}`,
        onReload: reload,
        onBack: back,
      };
    }
  },
]);
