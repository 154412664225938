import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.directive('event', [
  'AnalyticsService',
  function (AnalyticsService) {
    return {
      restrict: 'A',
      scope: {
        event: '@',
        trigger: '@',
        context: '=',
      },
      link: function (scope, element) {
        const { event, trigger, context } = scope;
        if (!event) {
          throw new Error(`event directive value is not specified`);
        }
        if (!trigger) {
          throw new Error(`trigger directive is missing or the value is not specified`);
        }
        element.on(trigger, function () {
          if (context === false) {
            return;
          }
          const defaultProps = AnalyticsService.getDefaultEventProps();
          const props = Object.keys(context).length
            ? { ...defaultProps, ...context }
            : typeof context === 'function'
              ? context(defaultProps)
              : defaultProps;
          AnalyticsService.logEvent(event, props);
        });
      },
    };
  },
]);
