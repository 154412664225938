import angular from 'angular';
import MODULE from './module';
import bulkReInviteUserTemplate from './admin.users.bulk-reinvite.html';

angular.module(MODULE).service('AdminBulkReInviteUsers', [
  '$uibModal',
  '$location',
  'AnalyticsService',
  function ($uibModal, $location, AnalyticsService) {
    function openBulkReInviteModal(entityType, entityId) {
      AnalyticsService.logPageViewEvent($location.absUrl(), 'Bulk Re-Invite User Modal', 'modal');
      return $uibModal
        .open({
          templateUrl: bulkReInviteUserTemplate,
          controller: 'AdminBulkReInviteUsersModalController',
          backdrop: 'static',
          bindToController: true,
          resolve: {
            EntityType: function () {
              return entityType;
            },
            EntityId: function () {
              return entityId;
            },
          },
        })
        .result.catch(angular.noop);
    }

    function bulkReInviteCompany(companyId) {
      return openBulkReInviteModal('company', companyId);
    }

    function bulkReInviteTeam(teamId) {
      return openBulkReInviteModal('team', teamId);
    }

    return {
      bulkReInviteCompany: bulkReInviteCompany,
      bulkReInviteTeam: bulkReInviteTeam,
    };
  },
]);

angular.module(MODULE).controller('AdminBulkReInviteUsersModalController', [
  '$uibModalInstance',
  '$scope',
  '$translate',
  'AdminApiService',
  'AnalyticsService',
  'AnalyticsEvents',
  'ErrorHandler',
  'EntityType',
  'EntityId',
  function (
    $uibModalInstance,
    $scope,
    $translate,
    AdminApiService,
    AnalyticsService,
    AnalyticsEvents,
    ErrorHandler,
    EntityType,
    EntityId
  ) {
    $scope.done = closeModal;
    $scope.cancel = closeModal;
    $scope.reInvite = reInvite;
    $scope.userCountDescription = userCountDescription;
    $scope.entityType = EntityType;
    $scope.entityId = EntityId;
    $scope.teamOrCompany = $translate.instant(EntityType === 'team' ? 'TEAM' : 'COMPANY');
    $scope.reInviteCount = -1;
    $scope.status = 'loaded';
    $scope.canReInviteUsers = function () {
      return $scope.reInviteCount > 0;
    };

    this.$onInit = function () {
      getReInviteCount();
    };

    function closeModal() {
      $uibModalInstance.close();
    }

    function getReInviteCount() {
      AdminApiService.addLoading();
      AdminApiService.getBulkResendInvitationCount($scope.entityType, $scope.entityId)
        .then(function (data) {
          $scope.reInviteCount = data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_LOADING_USER_COUNT'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    }

    function userCountDescription() {
      if ($scope.reInviteCount === 0) {
        return $translate.instant('Admin.BulkReInviteUsers.Modal.pending.noAvailableUsers');
      } else if ($scope.reInviteCount >= 1) {
        return $translate.instant(
          'Admin.BulkReInviteUsers.Modal.pending.userCountDescription',
          { reInviteCount: $scope.reInviteCount },
          'messageformat'
        );
      }
      return $translate.instant('Admin.BulkReInviteUsers.Modal.pending.calculatingUsers');
    }

    $scope.$on('$destroy', function () {
      if ($scope.status !== 'success') {
        const defaultProps = AnalyticsService.getDefaultEventProps();
        const context = {
          type: 'button',
          name: 'Cancel/Close Bulk Re-Invite Users Modal',
          entity_type: $scope.entityType,
          invitation_count: $scope.reInviteCount,
        };
        const props = { ...defaultProps, ...context };
        AnalyticsService.logEvent('click', props);
      }
    });

    function reInvite() {
      AdminApiService.addLoading();
      AdminApiService.bulkResendInvitations($scope.entityType, $scope.entityId)
        .then(function () {
          AnalyticsService.logEvent(AnalyticsEvents.Management.BULK_REINVITE, {
            entity_type: $scope.entityType,
            invitation_count: $scope.reInviteCount,
          });
          $scope.status = 'success';
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_REINVITING_USERS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    }
  },
]);
