import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.run([
  '$rootScope',
  '$state',
  'LearningModulesApi',
  ($rootScope, $state, LearningModulesApi) => {
    // Currently this does not check for url changes within the scw-react part of the application
    // I use $stateChangeStart instead of $locationChangeStart since this also gets triggered when navigating to the
    // same page again
    $rootScope.$on('$stateChangeStart', async (_event, _next, _current) => {
      const url = $state.href(_next.name, {});
      await LearningModulesApi.refreshLearningModules();
      if (url.startsWith('#/assessments') || url.startsWith('#/courses') || url.startsWith('#/programs')) {
        await LearningModulesApi.isLearningModuleEnabled('guidedLearning').then((enabled) => {
          if (enabled === false) {
            return $state.go('unavailable');
          }
        });
      }

      if (url.startsWith('#/quests') || url.startsWith('#/learn') || url.startsWith('#/my-quests')) {
        await LearningModulesApi.isLearningModuleEnabled('quests').then((enabled) => {
          if (enabled === false) {
            return $state.go('unavailable');
          }
        });
      }

      if (url.startsWith('#/training')) {
        await Promise.all([
          LearningModulesApi.isLearningModuleEnabled('guidedLearning'),
          LearningModulesApi.isLearningModuleEnabled('quests'),
        ]).then(([guidedLearningEnabled, questsEnabled]) => {
          if (guidedLearningEnabled === false || questsEnabled === true) {
            return $state.go('unavailable');
          }
        });
      }
    });
  },
]);
