import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { CybermonView } from './CybermonView';
import { usePlatformNavigate } from '../navigation';
import { usePlatformContext } from '../platformContext';
import { possibleStatusOptions, possibleActivityTypeOptions } from '../explore/FilterLogic';
import { hasLanguageInformation, isMission, isVideo, isWalkThrough } from '../explore/ContentItem';
import { useApiClient } from '../ApiContext';
import { backendToFrontend } from '../utils/mapper';
import { Suspenser, wrapPromise } from '@securecodewarrior/design-system-react';
import { useCybermonActivityFilter } from './hooks/useCybermonActivityFilter';
import { CybermonFilterProvider, useCybermonFilterContext, createCybermonFilteringEvent, } from './context/FilterContext';
import { CybermonProvider } from './context/CybermonContext';
import { CybermonSkeleton } from './Cybermon.skeleton';
export const CybermonRecommendedActivities = () => {
    const { trpc } = useApiClient();
    const navigate = usePlatformNavigate();
    const availableContent = trpc.explore.availableContent;
    const getUserLanguageFrameworks = trpc.recommendationEngine.getUserLanguageFrameworks;
    const getCybermon = trpc.achievements.getCybermon;
    const request = useMemo(() => {
        const contentPromise = availableContent.query();
        const filterPromise = getUserLanguageFrameworks.query();
        const cybermonPromise = getCybermon.query();
        return wrapPromise(Promise.all([contentPromise, filterPromise, cybermonPromise]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { metadata } = usePlatformContext();
    const onLoginClicked = useCallback(() => {
        navigate.setRequestedState('cybermon', { path: '' });
        return navigate.toState('login');
    }, [navigate]);
    return (_jsx(Suspenser, { reader: request, skeleton: _jsx(CybermonSkeleton, {}), render: ([contentData, filterData, cybermonData]) => {
            const items = backendToFrontend(contentData.items, metadata);
            const userLanguageFrameworks = filterData.kind === 'ok' ? [filterData.userLanguageFrameworks] : undefined;
            return (_jsx(CybermonFilterProvider, { items: items, initialUserLanguageFrameworks: userLanguageFrameworks, children: _jsx(CybermonProvider, { value: cybermonData, children: _jsx(Cybermon, { items: items, onLoginClicked: onLoginClicked }) }) }));
        } }));
};
export const Cybermon = (props) => {
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const isAuthenticated = !!sessionUser;
    const navigate = usePlatformNavigate();
    const filterStore = useCybermonFilterContext().getState();
    logAnalyticsEvent(createCybermonFilteringEvent(filterStore.filterState, filterStore.filteredItems.length));
    const filterOptions = useMemo(() => {
        const languageEntries = props.items
            .filter(hasLanguageInformation)
            .map((i) => [i.language.id, i.language])
            .sort(([_aId, { label: aLabel }], [_bId, { label: bLabel }]) => aLabel.localeCompare(bLabel));
        return {
            categories: Object.fromEntries(props.items.map((i) => [i.category.id, i.category])),
            languages: Object.fromEntries(languageEntries),
            activityTypes: isAuthenticated ? possibleActivityTypeOptions : { video: possibleActivityTypeOptions.video },
            activityStatus: possibleStatusOptions,
        };
    }, [props.items, isAuthenticated]);
    const gotoActivity = useCallback((item) => {
        const extraData = {
            category: item.category.id,
            language: isVideo(item) ? '' : item.language.id,
            status: item.status,
            title: item.title,
            activityType: item.type,
        };
        logAnalyticsEvent(Object.assign({ event: 'click', scope: 'cybermon', type: 'button', name: 'play activity' }, extraData));
        const nextPath = isMission(item) || isWalkThrough(item)
            ? `${activityPathRecord[item.type]}/${item.contentId}/${item.language.id}`
            : `${activityPathRecord[item.type]}/${item.contentId}`;
        const path = {
            pathname: nextPath,
            search: '',
            hash: '',
        };
        navigate.to(path);
    }, [logAnalyticsEvent, navigate]);
    useCybermonActivityFilter();
    return (_jsx(CybermonView, { filterOptions: filterOptions, onActivitySelected: gotoActivity, isAuthenticated: isAuthenticated, onLoginClicked: props.onLoginClicked, unfilteredActivityCount: props.items.length }));
};
const activityPathRecord = {
    coding_lab: '/cybermon/coding-lab',
    video: '/cybermon/video',
    challenge: '/cybermon/challenge',
    guideline: '/cybermon/guideline',
    mission: '/cybermon/mission',
    walkthrough: '/cybermon/walkthrough',
};
