import angular from 'angular';
import templateUrl from './custom-checkbox.html';

const app = angular.module('SCWCustomCheckbox', []);
export default app.name;

app.directive('customCheckbox', function () {
  return {
    restrict: 'EA',
    require: 'ngModel',
    replace: true,
    templateUrl,
    scope: {
      label: '@',
      uniqueIndex: '=',
      ngModel: '=',
    },
    link: function (scope, _elements, _attr, ctrl) {
      scope.updateModel = function (ngModel) {
        ctrl.$setViewValue(ngModel);
      };
    },
  };
});
