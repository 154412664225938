import angular from 'angular';

import SCWAssessmentsList from './list';
import SCWAssessmentsView from './view';
import SCWAssessmentsTake from './take';
import SCWAssessmentsCertificateTemplates from './certificates';

export default angular.module('SCWAssessments', [
  SCWAssessmentsList,
  SCWAssessmentsView,
  SCWAssessmentsTake,
  SCWAssessmentsCertificateTemplates,
]).name;
