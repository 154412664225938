import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { SuccessHub } from './SuccessHub';
import { useFetchWithPlatformSessionHeader } from '../ApiContext';
import { decideThemeByHashRoute } from '../../utils/themes';
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
export const SuccessHubRoute = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [url, setUrl] = useState('');
    const { fetchWithSession, apiEndpoint } = useFetchWithPlatformSessionHeader();
    useEffect(() => {
        fetchWithSession(apiEndpoint + '/management/success-hub/embed-url')
            .then((response) => response.text())
            .then((url) => {
            setUrl(url);
            setIsLoading(false);
        })
            .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
        });
    }, [fetchWithSession, apiEndpoint]);
    return (_jsx(DSThemeProvider, { theme: decideThemeByHashRoute(window.location.hash), children: _jsx(SuccessHub, { isLoading: isLoading, url: url }) }));
};
