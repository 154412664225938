import angular from 'angular';
import MODULE from './module';

/**
 * Decorate ui-select to return focus to a keyboard-selectable element
 * after an item has been chosen (otherwise keyboard navigation gets
 * reset as a consecuence of _blur_).
 */

angular.module(MODULE).config([
  '$provide',
  function ($provide) {
    $provide.decorator('uiSelectDirective', ['$delegate', uiSelectDecorator]);

    /**
     * Decorate ui-select to refocus after selection.
     * @param {NgDirectiveDelegate} $delegate
     */
    function uiSelectDecorator($delegate) {
      var compile = $delegate[0].compile;
      $delegate[0].compile = function (_element, _attrs, _transclude) {
        // var decorated = compile(element, attrs, transclude);
        var compiler = compile.apply(this, arguments);

        return function (_scope, element) {
          var compiled = compiler.apply(this, arguments);
          var input = element.find('input');
          input.attr('autocomplete', 'false');
          input.on('blur', refocus(element));
          element.on('keydown', focusInputIfApplicable(element));
          return compiled;
        };
      };
      return $delegate;
    }

    function focusInputIfApplicable(element) {
      return function (event) {
        var key = event.key;
        if (key === 'Enter') {
          var input = element.find('input');
          input && input.trigger('focus');
        }
      };
    }

    /**
     * Re-focus on proper selectable element.
     * @param {DOMEvent} event
     */
    function refocus(element) {
      return function () {
        var selected = element.find('.select2-choice:visible');
        selected && selected.focus();
      };
    }
  },
]);
