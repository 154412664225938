import angular from 'angular';
import MODULE from './module';
import templateUrl from './world-map-background.directive.html';

angular.module(MODULE).directive('worldMapBackground', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        step: '@',
      },
    };
  },
]);
