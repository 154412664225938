export const appBarMenuStyles = (opened, theme) => ({
    root: {
        height: '100%',
        '.MuiPaper-root': {
            backgroundColor: theme.palette.grayscale.bg3,
            backgroundImage: 'none',
            borderRadius: '4px',
            boxShadow: theme.shadows[2],
            minWidth: '280px',
        },
        '.MuiList-root': {
            paddingBottom: '8px',
            paddingTop: '8px',
        },
    },
    icon: Object.assign({}, (opened ? {} : { display: 'none' })),
});
