import { useEffect, useMemo, useState } from 'react';
import { useCybermonFilterContext } from '../context/FilterContext';
import { useCybermon } from './useCybermon';
import { isItemRelevant } from './utils';
export const ITEM_INCREMENT = 6;
const prioritiseActivities = (items, activeCybermon) => {
    // Return early if there's no active cybermon
    if (!activeCybermon) {
        return [];
    }
    // Define the activity order
    const activityOrder = ['coding_lab', 'walkthrough', 'mission', 'challenge'];
    const activities = items.filter((item) => { var _a; return isItemRelevant(item, (_a = activeCybermon === null || activeCybermon === void 0 ? void 0 : activeCybermon.custom_data) === null || _a === void 0 ? void 0 : _a.category); });
    // Sort otherActivities according to the activityOrder
    return activities.sort((a, b) => activityOrder.indexOf(a.type) - activityOrder.indexOf(b.type));
};
export const useCybermonActivityFilter = () => {
    const { activeCybermon } = useCybermon();
    const { doFilterAction } = useCybermonFilterContext().getState();
    const { selectedLanguages, filteredItems, allItems } = useCybermonFilterContext()((store) => ({
        selectedLanguages: store.filterState.language,
        filteredItems: store.filteredItems,
        allItems: store.allItems,
    }));
    const prioritisedActivities = useMemo(() => prioritiseActivities(filteredItems, activeCybermon), [filteredItems, activeCybermon]);
    const languageAvailability = useMemo(() => {
        const map = new Map();
        for (const item of allItems) {
            if ('language' in item && isItemRelevant(item, activeCybermon === null || activeCybermon === void 0 ? void 0 : activeCybermon.custom_data.category)) {
                map.set(item.language.id, true);
            }
        }
        return map;
    }, [allItems, activeCybermon]);
    const [maxItems, setMaxItems] = useState(ITEM_INCREMENT);
    useEffect(() => {
        setMaxItems(ITEM_INCREMENT);
    }, [selectedLanguages, prioritisedActivities]);
    const visibleActivities = useMemo(() => {
        return selectedLanguages.length ? prioritisedActivities.slice(0, maxItems) : [];
    }, [maxItems, prioritisedActivities, selectedLanguages.length]);
    return {
        selectedLanguages,
        canLoadMore: prioritisedActivities.length > maxItems,
        visibleActivities,
        loadMore: () => setMaxItems((max) => max + ITEM_INCREMENT),
        handleLanguageSelected: (languages) => doFilterAction({ action: 'changeLanguages', value: languages }),
        itemsPerPage: ITEM_INCREMENT,
        languageHasContent: (languageId) => languageAvailability.has(languageId),
    };
};
