import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { HealthBarSegment } from '../styled';
import { useCybermon } from '../hooks/useCybermon';
import { useTranslation } from 'react-i18next';
export const HealthBar = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.healthBar' });
    const { livesEarned, activeCybermon, cyberHero, userCompleted } = useCybermon();
    const lives = [1, 2, 3];
    const healthBarTitle = (cyberHero && cyberHero.earned) || userCompleted
        ? t('eventEnd')
        : activeCybermon && activeCybermon.earned
            ? t('earned')
            : t('instruction');
    return (_jsxs(Stack, { gap: 5, direction: "row", justifyContent: { xs: 'center', lg: 'space-between' }, alignContent: "center", flexWrap: "wrap", children: [_jsx(Typography, { variant: "h4", color: "text.title", textAlign: { xs: 'center', lg: 'start' }, children: healthBarTitle }), !userCompleted && activeCybermon && !(cyberHero === null || cyberHero === void 0 ? void 0 : cyberHero.earned) && (_jsx(Stack, { direction: "row", spacing: 2, alignItems: "center", children: lives.map((num) => (_jsx(HealthBarSegment, { completed: num <= livesEarned }, num))) }))] }));
};
