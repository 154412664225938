const toSearchTerms = (value) => value.toLocaleLowerCase().split(' ');
export const textContainsSearch = (text, search) => {
    const lowerCaseText = text.toLocaleLowerCase();
    for (const term of toSearchTerms(search)) {
        if (lowerCaseText.includes(term)) {
            return true;
        }
    }
    return false;
};
