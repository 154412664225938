import angular from 'angular';

const MODULE = 'SCWTrainingApi';
export default MODULE;

const app = angular.module(MODULE, []);

app.factory('TrainingApiService', [
  '$log',
  '$http',
  '$window',
  '$rootScope',
  'TrainingUtils',
  '$translate',
  'HttpConfigService',
  function ($log, $http, $window, $rootScope, TrainingUtils, $translate, HttpConfigService) {
    const { SCW_ENV } = $window;
    const TrainingApi = {
      data: {},
    };

    const TrainingApiEndpoint = function () {
      return SCW_ENV.ApiEndpoint + '/training';
    };
    const CourseApiEndpoint = function () {
      return SCW_ENV.ApiEndpoint + '/courses';
    };
    const AssessmentsApiEndpoint = function () {
      return SCW_ENV.ApiEndpoint + '/assessment';
    };

    TrainingApi.currentSolutions = [];

    TrainingApi.addLoading = function () {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    };

    TrainingApi.removeLoading = function () {
      $rootScope.loading--;
      if ($rootScope.loading < 0) $rootScope.loading = 0;
    };

    TrainingApi.requestParams = function (filter, pageOptions) {
      pageOptions = pageOptions || {};

      return { filter: filter, page: pageOptions.page, size: pageOptions.size };
    };

    //with credentials has been added to talk to cloudfront
    function httpGet(config) {
      TrainingApi.addLoading();
      return $http(angular.extend(config, { method: 'GET', withCredentials: true }, HttpConfigService.getHttpConfig()))
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          TrainingApi.removeLoading();
        });
    }

    function httpPost(config) {
      TrainingApi.addLoading();
      return $http(angular.extend(config, { method: 'POST', withCredentials: true }, HttpConfigService.getHttpConfig()))
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          TrainingApi.removeLoading();
        });
    }

    /*** Courses ***/

    // This endpoint is NOT deprecated, do NOT remove
    TrainingApi.getCourseReadyLanguages = function () {
      return httpGet({
        url: CourseApiEndpoint() + '/availablelanguages',
      });
    };

    TrainingApi.getPublishedCourses = function (_entity, language) {
      return httpGet({
        url: CourseApiEndpoint() + '/publishedCourses/' + _entity,
        params: {
          language: language,
        },
      });
    };

    TrainingApi.getEnrolledCourses = function (_pagination, _languages, _courseName) {
      return httpGet({
        url: CourseApiEndpoint() + '/leaderboard/enrolledCourses',
      });
    };

    /*** Metrics ***/

    TrainingApi.listCourseEnabledCompanies = function (filter, pageOptions, sortBy) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/enabledCompanies',
        params: angular.extend(
          {},
          filter ? { filter: filter } : {},
          pageOptions ? { page: pageOptions.page, size: pageOptions.size } : {},
          { sortBy: sortBy }
        ),
      });
    };

    TrainingApi.getPlayerBaseStats = function (_course) {
      encodeParams(arguments);

      const params = {};

      if (_course) {
        params._course = _course;
      }

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/player/baseStats',
        params: params,
      });
    };

    TrainingApi.getManagerEntities = function (_entity) {
      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/entities' + (_entity ? '/' + _entity : ''),
      });
    };

    TrainingApi.getManagerEntityBaseStats = function (_entity, _course, dateFrom, dateTo) {
      encodeParams(arguments);

      const params = {};
      if (_course) {
        params._course = _course;
      }
      if (dateFrom) {
        params.dateFrom = dateFrom;
      }
      if (dateTo) {
        params.dateTo = dateTo;
      }

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/baseStats/' + _entity,
        params: params,
      });
    };

    /*** Leaderboards ***/

    TrainingApi.getManagerPlayerProgressLeaderboard = function (
      _entity,
      courses,
      filter,
      page,
      size,
      displayCurrentUser,
      isManagementMetrics
    ) {
      encodeParams(arguments);

      if (!isManagementMetrics) {
        isManagementMetrics = false;
      }

      return httpPost({
        url: CourseApiEndpoint() + '/metrics/manager/playerProgressLeaderboard/' + _entity,
        data: angular.extend(
          {
            isManagementMetrics: isManagementMetrics,
          },
          TrainingApi.requestParams(filter),
          { page: page, size: size, displayCurrentUser: displayCurrentUser, courses: courses.join(',') }
        ),
      });
    };

    TrainingApi.getManagerPlayerPointsLeaderboard = function (
      _entity,
      filter,
      page,
      size,
      language,
      displayCurrentUser,
      isManagementMetrics
    ) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/playerPointsLeaderboard/' + _entity,
        params: angular.extend(
          {
            isManagementMetrics: isManagementMetrics,
          },
          TrainingApi.requestParams(filter),
          { page: page, size: size, language: language, displayCurrentUser: displayCurrentUser }
        ),
      });
    };

    TrainingApi.getManagerTeamProgressLeaderboard = function (_company, courses, filter, page, size) {
      encodeParams(arguments);

      return httpPost({
        url: CourseApiEndpoint() + '/metrics/manager/teamProgressLeaderboard/' + _company,
        data: angular.extend({}, TrainingApi.requestParams(filter), {
          page: page,
          size: size,
          courses: courses.join(','),
        }),
      });
    };

    TrainingApi.getManagerTeamPointsLeaderboard = function (_company, filter, page, size, language) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/teamPointsLeaderboard/' + _company,
        params: angular.extend({}, TrainingApi.requestParams(filter), { page: page, size: size, language: language }),
      });
    };

    TrainingApi.getCoursesAssignedForTeams = function (_company, page, size) {
      encodeParams(arguments);

      return httpGet({
        url: TrainingApiEndpoint() + '/metrics/manager/teamAssignedCourses/' + _company,
        params: angular.extend({}, { page: page, size: size }),
      });
    };

    /*** Exports ***/

    TrainingApi.getCoursesProgressSummaryCsv = function (_entity) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/courses/exports/progressSummary/' + _entity,
        params: angular.extend({}),
      });
    };

    TrainingApi.getTeamCourseProgressSummaryCsv = function (_company) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/exports/teamCourseProgressSummary/' + _company,
      });
    };

    TrainingApi.getPlayerCourseSummaryCsv = function (_entity) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/exports/playerCourseSummary/' + _entity,
      });
    };

    TrainingApi.getPlayerCourseDataCsv = function (_entity) {
      encodeParams(arguments);

      return httpGet({
        url: TrainingApiEndpoint() + '/metrics/manager/exports/playerCourseData/' + _entity,
      });
    };

    TrainingApi.getManagerExportPlayerProgressLeaderboard = function (_entity, courses) {
      encodeParams(arguments);

      return httpPost({
        url: CourseApiEndpoint() + '/metrics/manager/exports/playerProgressLeaderboard/' + _entity,
        data: { courses },
      });
    };

    TrainingApi.getManagerExportPlayerPointsLeaderboard = function (_entity) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/exports/playerPointsLeaderboard/' + _entity,
      });
    };

    TrainingApi.getManagerExportPlayerChallenges = function (_entity, _course) {
      encodeParams(arguments);
      const url =
        CourseApiEndpoint() + '/metrics/manager/exports/playerChallenges/' + _entity + (_course ? '/' + _course : '');
      return httpGet({
        url: url,
      });
    };

    TrainingApi.getManagerExportAggregateCourseStats = function (_company) {
      encodeParams(arguments);
      const url = TrainingApiEndpoint() + '/metrics/manager/exports/aggregateCourseStats/' + _company;
      return httpGet({
        url: url,
      });
    };

    TrainingApi.getManagerExportTeamProgressLeaderboard = function (_company, courses) {
      encodeParams(arguments);

      return httpPost({
        url: CourseApiEndpoint() + '/metrics/manager/exports/teamProgressLeaderboard/' + _company,
        data: { courses },
      });
    };

    TrainingApi.getManagerExportTeamPointsLeaderboard = function (_company) {
      encodeParams(arguments);

      return httpGet({
        url: CourseApiEndpoint() + '/metrics/manager/exports/teamPointsLeaderboard/' + _company,
      });
    };

    /*** TODO: Challenges ***/

    /** The new routes for the challenge screen for courses */
    TrainingApi.getChallenge = function (challengeParams) {
      encodeParams(arguments);

      const _userCourse = _.get(challengeParams, '_userCourse', '');
      const _language = _.get(challengeParams, '_language', '');
      const _module = _.get(challengeParams, '_module', '');
      const _task = _.get(challengeParams, '_task', '');

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/userCourse/' +
          _userCourse +
          '/language/' +
          _language +
          '/module/' +
          _module +
          '/task/' +
          _task +
          '/challenges',
      });
    };

    TrainingApi.markChallenge = function (challengeParams, _answer) {
      encodeParams(arguments);

      const _language = _.get(challengeParams, '_language', '');
      const _module = _.get(challengeParams, '_module', '');
      const _taskAttempt = _.get(challengeParams, '_taskAttempt', '');
      const answer = _.get(_answer, 'answer', '');

      return httpPost({
        url:
          CourseApiEndpoint() +
          '/challenges/language/' +
          _language +
          '/module/' +
          _module +
          '/taskAttempt/' +
          _taskAttempt +
          '/answer',
        data: {
          answer: answer,
        },
      });
    };

    TrainingApi.skipChallenge = function (challengeParams, _skipParams) {
      encodeParams(arguments);

      const _language = _.get(challengeParams, '_language', '');
      const _module = _.get(challengeParams, '_module', '');
      const _taskAttempt = _.get(challengeParams, '_taskAttempt', '');

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/challenges/language/' +
          _language +
          '/module/' +
          _module +
          '/taskAttempt/' +
          _taskAttempt +
          '/skip',
      });
    };

    //Get the same challenge again, let backend decide
    TrainingApi.retryChallenge = function (challengeParams) {
      encodeParams(arguments);

      const _userCourse = _.get(challengeParams, '_userCourse', '');
      const _language = _.get(challengeParams, '_language', '');
      const _module = _.get(challengeParams, '_module', '');
      const _task = _.get(challengeParams, '_task', '');

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/userCourse/' +
          _userCourse +
          '/language/' +
          _language +
          '/module/' +
          _module +
          '/task/' +
          _task +
          '/challenges',
      });
    };

    //basically get the challenge again. The backend decides the challenge
    TrainingApi.getNextStage = function (challengeParams) {
      const _userCourse = _.get(challengeParams, '_userCourse', '');
      const _language = _.get(challengeParams, '_language', '');
      const _module = _.get(challengeParams, '_module', '');
      const _task = _.get(challengeParams, '_task', '');

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/userCourse/' +
          _userCourse +
          '/language/' +
          _language +
          '/module/' +
          _module +
          '/task/' +
          _task +
          '/challenges',
      });
    };

    /** End new routes for challenge screen */

    //Not changing this as this is called via the results screen
    TrainingApi.getNextTask = function (_language, _module, _taskAttempt) {
      encodeParams(arguments);

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/challenges/language/' +
          _language +
          '/module/' +
          _module +
          '/taskAttempt/' +
          _taskAttempt +
          '/next',
      });
    };

    TrainingApi.checkpoint = function (_language, _module, _taskAttempt) {
      encodeParams(arguments);

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/challenges/language/' +
          _language +
          '/module/' +
          _module +
          '/taskAttempt/' +
          _taskAttempt +
          '/checkpoint',
      });
    };

    //Not changing this as this is called via the results screen
    TrainingApi.getResult = function (_userCourse, _language, _module, _taskAttempt) {
      encodeParams(arguments);

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/challenges/userCourse/' +
          _userCourse +
          '/language/' +
          _language +
          '/module/' +
          _module +
          '/taskAttempt/' +
          _taskAttempt +
          '/result',
      });
    };

    TrainingApi.getCourseLanguageDetails = function (_userCourse, _language) {
      encodeParams(arguments);

      return httpGet({
        url:
          CourseApiEndpoint() +
          '/challenges/userCourse/' +
          _userCourse +
          '/language/' +
          _language +
          '/courseLanguageDetails',
      });
    };

    /** Other Utils */
    TrainingApi.getLanguagesStats = function (languages) {
      $log.debug('TrainingApi - getLanguagesStats(' + JSON.stringify(languages) + ')');
      encodeParams(arguments);

      languages = _.map(languages, TrainingUtils.ensureUnfoldLanguage);
      return httpPost({
        url: AssessmentsApiEndpoint() + '/quest-story/stats',
        data: {
          languages: languages,
        },
      });
    };

    TrainingApi.getCoursesProgressSummary = function (pagination, options) {
      return httpGet({
        url: CourseApiEndpoint() + '/metrics/courses/progressSummary',
        params: {
          options: JSON.stringify(options),
          page: pagination.page,
          size: pagination.size,
          order: pagination.order,
        },
      });
    };

    return TrainingApi;
  },
]);
