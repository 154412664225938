import angular from 'angular';
import MODULE from './module';
import templateUrl from './tournaments.list.other-options.html';
import * as _ from 'lodash'; // Added to make the controller testable
import moment from 'moment-timezone';

const app = angular.module(MODULE);

/*********** list ***********/
app.constant('TAB_INDEXES', ['active', 'completed', 'draft']);

app.constant('TAB_INDEXES_ALT_NAME', ['active', 'finished', 'drafts']);

app.controller('TournamentListController', [
  '$scope',
  '$state',
  '$stateParams',
  '$log',
  '$interval',
  '$translate',
  '$swal',
  '$uibModal',
  '$window',
  'Session',
  'TournamentsApiService',
  'ErrorHandler',
  'TAB_INDEXES',
  'TAB_INDEXES_ALT_NAME',
  'FeatureFlagsApi',
  'FeatureFlags',
  function (
    $scope,
    $state,
    $stateParams,
    $log,
    $interval,
    $translate,
    $swal,
    $uibModal,
    $window,
    Session,
    TournamentsApiService,
    ErrorHandler,
    TAB_INDEXES,
    TAB_INDEXES_ALT_NAME,
    FeatureFlagsApi,
    FeatureFlags
  ) {
    var userRoles = $scope.userRoles;
    var isAuthorized = $scope.isAuthorized;
    $scope.isPublicTournamentCreationAllowedForAdmin = false;

    $scope.isAdmin = isAuthorized([userRoles.admin, userRoles.reseller, userRoles.companyAdmin, userRoles.manager]);
    $scope.isCompanyManager = isAuthorized([userRoles.companyAdmin, userRoles.manager]);
    $scope.isSCWAdmin = Session?.user?.roles?.indexOf(userRoles.admin) > -1 ?? false;
    $scope.isCompanyAdmin = Session?.user?.roles?.includes(userRoles.companyAdmin) ?? false;
    $scope.isMCTBannerToBeDisplayed = false;
    $scope.learnMoreLink = $window.SCW_ENV.MCT_BANNER_LEARN_MORE_LINK;

    $scope.tabs = {
      activeIndex: 0,
    };
    $scope.TAB_NAMES = {
      ACTIVE: 0,
      COMPLETED: 1,
      DRAFT: 2,
    };
    $scope.TAB_INDEXES = TAB_INDEXES;
    $scope.TAB_INDEXES_ALT_NAME = TAB_INDEXES_ALT_NAME;
    $scope.totalNumberOfPages = {
      active: 0,
      finished: 0,
      drafts: 0,
    };
    $scope.totalNumberOfPages = {
      active: 0,
      finished: 0,
      drafts: 0,
    };
    $scope.$watch('tabs', onChangedTabs, true);

    $scope.searchFormData = {};
    $scope.isTournamentSnackBarOpen = false; // One snackbar can be open at a given time. Ideally should allow for a queue
    $scope.tournamentSnackBarMessage = '';
    $scope.tournamentSnackBarPosition = {
      vertical: 'bottom',
      horizontal: 'center',
    };
    $scope.uniqueSnackBarKey = '';

    $scope.tournamentSnackBarHandleClose = () => {
      $scope.isTournamentSnackBarOpen = false;
    };

    $scope.paginate = paginate;

    $scope.goToLearnMore = () => $window.open($scope.learnMoreLink, '_blank', 'noopener noreferrer');

    function paginate(inc) {
      $log.debug('[tournaments-list] received pagination request', inc);
      var options = $scope.tournamentPages[TAB_INDEXES[$scope.tabs.activeIndex]].options;
      options.currentPage = (options.currentPage || 1) + inc;
      pagerChanged();
    }

    $scope.pagerChanged = pagerChanged;

    function pagerChanged(resetPage) {
      if (resetPage) {
        $scope.tournamentPages[$scope.TAB_INDEXES[$scope.tabs.activeIndex]].options.currentPage = 1;
      }
      refreshCurrentTab();
    }

    $scope.showOtherOptionsDialog = showOtherOptionsDialog;

    function showOtherOptionsDialog() {
      $swal({
        title: $translate.instant('Tournaments.List.OptionsDialog.title'),
        templateUrl,
        showConfirmButton: false,
        showCancelButton: false,
        scope: $scope.$new(),
        keyResolution: true,
      }).then(function (resolution) {
        if (resolution === 'summaryCsvReport') return $scope.downloadAllSummaryCsv();

        return resolution;
      });
    }

    function refreshCurrentTab() {
      var activeIndex = $scope.tabs.activeIndex;
      var tabName = $scope.TAB_INDEXES[activeIndex];
      var alternativeTabName = $scope.TAB_INDEXES_ALT_NAME[activeIndex];
      $scope.tournamentPages[tabName].options.page = $scope.tournamentPages[tabName].options.currentPage;
      $scope.tournamentPages[tabName].options.size = $scope.tournamentPages.size;
      TournamentsApiService.getTournamentList(
        $scope.tournamentPages.filter,
        $scope.tournamentPages[tabName].options,
        null,
        alternativeTabName,
        $scope.companySelectedBySCWAdmin._id
      )
        .then(function (data) {
          $scope.processTournamentList(data, tabName);
        })
        .catch(function () {
          if (Session.user) {
            ErrorHandler.addHttpError($translate.instant('ERROR_LOADING_' + tabName.toUpperCase() + '_TOURNAMENTS'));
          }
        });
    }

    function onChangedTabs() {
      resetPageValues();
      $scope.tournamentPages.filter = null;
      $scope.searchFormData.filter = null;
      refreshCurrentTab();
    }

    $scope.sorting = {
      tournaments: {
        sortBy: 'name',
        sortByParam: 'name',
        sortReverse: false,
      },
    };

    $scope.isActiveTab = function (tabNum) {
      return $scope.tabs.activeIndex === tabNum;
    };

    $scope.newTournamentSearch = function () {
      if (!$scope.searchFormData.filter) {
        return $scope.clearTournamentSearch();
      }
      $scope.tournamentPages.filter = $scope.searchFormData.filter;
      refreshCurrentTab();
    };

    $scope.clearTournamentSearch = function () {
      var altTabName = $scope.TAB_INDEXES_ALT_NAME[$scope.tabs.activeIndex];
      var tabName = $scope.TAB_INDEXES[$scope.tabs.activeIndex];
      $scope.totalNumberOfPages[altTabName] = $scope.tournamentPages[tabName].options[altTabName];
      $scope.tournamentPages.filter = null;
      $scope.searchFormData.filter = null;
      refreshCurrentTab();
    };

    $scope.isShowingTournamentsInfo = false;

    var found = false;
    $scope.processTournamentList = function (data, status) {
      setTournamentData(data, status);
      var tabIndex = getTabIndexFromStatus(status);
      for (var i = 0; i < $scope.tournamentLists[status].length; i++) {
        var tournamentObj = $scope.tournamentLists[status][i];
        $scope.processTournamentData(tournamentObj);
        checkCanAcceptNewParticipants(tournamentObj);

        if ($stateParams.tournamentId && $stateParams.tournamentId == tournamentObj._id + '') {
          $scope.tournamentLists[status + 'Index'] = i;
          $scope.tabs.activeIndex = tabIndex;
          found = true;
        }
      }

      if (!found && $scope.tournamentLists[status] && $scope.tournamentLists[status][0]) {
        $scope.tournamentLists[status + 'Index'] = 0;
      }
    };

    function setTournamentData(pageData, status) {
      var selectedTab = $scope.tournamentPages[status];
      if (!selectedTab.options || !$scope.tournamentLists[status]) {
        selectedTab.options = pageData;
        $scope.tournamentLists[status] = pageData.data;
      } else {
        var indexOfFirstObjectInPage = (pageData.page - 1) * pageData.size;
        var iterationStart = $scope.tournamentLists[status][indexOfFirstObjectInPage]
          ? indexOfFirstObjectInPage
          : $scope.tournamentLists[status].length;
        for (var j = iterationStart; j < indexOfFirstObjectInPage + pageData.size; j++) {
          if (j >= indexOfFirstObjectInPage && j < indexOfFirstObjectInPage + pageData.data.length) {
            $scope.tournamentLists[status][j] = pageData.data[j - indexOfFirstObjectInPage];
          } else {
            $scope.tournamentLists[status][j] = {};
          }
        }
        if (pageData.data.length < pageData.size) {
          $scope.tournamentLists[status].length = indexOfFirstObjectInPage + pageData.data.length;
        }
        if (pageData.data.length === 0 && pageData.total !== 0) {
          selectedTab.options.currentPage = selectedTab.options.currentPage - 1;
          refreshCurrentTab();
        }
      }
      selectedTab.options.pages = pageData.pages;
      selectedTab.options.total = pageData.total;
      calculatePageNumbers(pageData);

      $scope.filterTournaments($scope.tournamentLists.active);
    }

    const availableLanguages = _.filter(Session.user?.properties?.languages, function (language) {
      if (language) {
        return ['available', 'active'].indexOf(language.status) >= 0 || language.deprecated;
      } else {
        return false;
      }
    });

    $scope.filterTournaments = function (tournaments) {
      _.map(tournaments, function (tournament) {
        var number = 0;

        // This is a public tournament, no modification to the languages
        if (!tournament._company && !tournament._team) {
          return tournament;
        }

        _.map(tournament.languages, function (language) {
          _.map(availableLanguages, function (availableLanguage) {
            if (
              availableLanguage.language._id === language._id &&
              availableLanguage.language._framework === language._framework
            ) {
              number++;
            }
          });
        });

        if (number === 0) {
          tournament.languages = [];
        }
      });
    };

    function calculatePageNumbers(pageData) {
      $scope.totalNumberOfPages.active = pageData.active;
      $scope.totalNumberOfPages.drafts = pageData.drafts;
      $scope.totalNumberOfPages.finished = pageData.finished;
      if ($scope.tournamentPages.filter) {
        // total reflects searched params, while others show total
        var activePage = $scope.TAB_INDEXES_ALT_NAME[$scope.tabs.activeIndex];
        if (pageData.total > 0) {
          $scope.totalNumberOfPages[activePage] = pageData.total;
        } else {
          // no search result
          $scope.totalNumberOfPages[activePage] = pageData[activePage];
        }
      }
    }

    function getTabIndexFromStatus(status) {
      for (var i = 0; i < $scope.TAB_INDEXES.length; i++) {
        if (status === $scope.TAB_INDEXES[i]) {
          return $scope.TAB_NAMES[$scope.TAB_INDEXES[i].toUpperCase()];
        }
      }
      return $scope.TAB_NAMES.ACTIVE;
    }

    function checkCanAcceptNewParticipants(tournamentObj) {
      tournamentObj.canAcceptNewParticipants = Boolean(
        !tournamentObj.maxPlayers || tournamentObj.maxPlayers - tournamentObj.numPlayers > 0
      );
      return;
    }

    $scope.manageTournament = function (tournament) {
      $state.go('tournaments.edit', {
        id: tournament._id,
        tournamentId: $stateParams.tournamentId,
        companyId: _.get($scope, 'companySelector.selected._id', null),
      });
    };

    $scope.playTournament = function (tournament) {
      if (tournament.languages.length !== 0 || tournament.participating === true) {
        if (!tournament.participating && (!tournament.isAdmin || (tournament.isAdmin && tournament.sharedSecret)))
          angular.element('#tournament-' + tournament._id + '-joincode').trigger('focus');
        else return $state.go('tournaments.player', { _tournament: tournament._id });
      } else {
        return;
      }
    };

    $scope.viewTournamentResults = function (tournament) {
      $state.go('tournaments.player', { _tournament: tournament._id, viewResults: true });
    };

    function publishToggleError(response) {
      $translate(['ERROR_UPDATING_TOURNAMENT']).then(function (translations) {
        ErrorHandler.addHttpError(translations.ERROR_UPDATING_TOURNAMENT, response);
      });
    }

    $scope.publish = function (tournament) {
      tournament.published = true;
      TournamentsApiService.updateTournament(tournament).then(refreshCurrentTab).catch(publishToggleError);
    };

    $scope.unpublish = function (tournament) {
      tournament.published = false;
      TournamentsApiService.updateTournament(tournament).then(refreshCurrentTab).catch(publishToggleError);
      if ($scope.tournament) {
        $scope.clearSelectedTournament();
      }
    };

    $scope.goToLiveStatus = function (tournament) {
      if (tournament.languages.length !== 0 || tournament.participating === true) {
        $log.debug('[Tournament] GoToLiveStatus', tournament);
        var params = {
          id: tournament._id, //separate id attribute because tournamentId can be empty, linking to list instead of detail
          tournamentId: $stateParams.tournamentId,
          from: $state.current.name,
        };

        $state.go('tournaments.live-status', params);
      } else {
        return;
      }
    };

    $scope.goToMetrics = function (tournament) {
      const param = {
        tournamentIds: [tournament._id],
        hasSelection: true,
        type: 1,
      };

      const paramString = encodeURIComponent(JSON.stringify(param));
      window.location.href = `#/reporting/tournaments?content=${paramString}`;
    };

    $scope.toggleLink = function (tournament) {
      tournament.isShowingLink = !tournament.isShowingLink;
      if (tournament.isShowingLink) {
        var linkElem = angular.element('#tournament-link-' + tournament._id);
        linkElem.focus();
      }
    };

    $scope.selectTournament = function (tournament, $index) {
      $state.go('tournaments.list', { tournamentId: tournament._id + '' }, { notify: false });
      $scope.tournamentToSelect = tournament._id;

      if ($scope.tabs.activeIndex == $scope.TAB_NAMES.ACTIVE) {
        $scope.tournamentLists.activeIndex = $index;
      }
    };

    $scope.interactWithSnackBarAndSetMessage = (tournament) => {
      const tournamentName = tournament.name;
      const tournamentDateUtc = moment.utc(tournament.startTime).format('MMM D, YYYY HH:mm z');
      $scope.tournamentSnackBarMessage = $translate.instant('TOURNAMENT_SNACKBAR_MESSAGE', {
        tournamentName,
        tournamentDateUtc,
      });
      $scope.uniqueSnackBarKey = `${tournament._id}`; // This will always be unique
      $scope.isTournamentSnackBarOpen = true;
    };

    $scope.joinTournamentAndPlayIfApplicable = function (tournament, sharedSecret) {
      TournamentsApiService.joinTournament(tournament, sharedSecret)
        .then(function (_data) {
          $scope.isTournamentSnackBarOpen = false; // close any previous snackbars
          if (tournament.status == $scope.TOURNAMENT_STATUS.ACTIVE) {
            $state.go('tournaments.player', { _tournament: tournament._id });
          } else {
            tournament.participating = true;
            tournament.numPlayers++;
            $scope.interactWithSnackBarAndSetMessage(tournament);
          }
        })
        .catch(function (response) {
          var translationList = [
            'INCORRECT_JOIN_CODE',
            'ERROR_JOINING_TOURNAMENT',
            'LICENSE_INACTIVE_TEXT',
            'TOURNAMENT_REACHED_CAPACITY',
          ];
          $translate(translationList).then(function (translations) {
            if (response.status == 403) {
              ErrorHandler.addError(translations.LICENSE_INACTIVE_TEXT);
            } else {
              if (translationList.indexOf(response.data.error) > -1) {
                ErrorHandler.addHttpError(translations[response.data.error]);
              } else {
                ErrorHandler.addHttpError(translations.ERROR_JOINING_TOURNAMENT, response);
              }
            }
          });
        });
    };

    $scope.joinRequest = {};

    $scope.openJoinModal = function () {
      const modalInstance = $uibModal.open(
        {
          useThemeProvider: true,
          templateUrl: 'tournaments/list/JoinModal',
          controller: 'JoinModalController',
          size: 'lg',
          windowClass: 'scw-theme--accessible scw-theme--dark', // Use the theme manually as tournaments are not a themed module
          scope: $scope,
        },
        'joinModal'
      );

      modalInstance.result.then(angular.noop, angular.noop); // Don't log anything to the console.
    };

    $scope.joinTournament = function (tournament, sharedSecret) {
      if (tournament.languages.length !== 0 || tournament.participating === true) {
        $scope.selectTournament(tournament);
        if (tournament.public) {
          // Set local scope data
          $scope.modalData = {
            tournament,
            sharedSecret,
          };
          $scope.openJoinModal();
        } else {
          $scope.joinTournamentAndPlayIfApplicable(tournament, sharedSecret);
        }
      } else {
        return swal({
          title: $translate.instant('TOURNAMENT_LANGUAGES_NOT_ACCESSIBLE'),
          text: $translate.instant('NO_ACCESS_TO_TOURNAMENT_LANGUAGES_PLEASE_CONTACT_YOUR_MANAGER'),
          type: 'error',
          html: true,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: $translate.instant('OK'),
        });
      }
    };

    $scope.goToDrafts = function () {
      $scope.tabs.activeIndex = 2;
    };

    $scope.goToActive = function () {
      $scope.tabs.activeIndex = 0;
    };

    $scope.addTournament = function () {
      $scope.isShowingAdd = true;

      $state.go('tournaments.add', {
        companyId: _.get($scope, 'companySelector.selected._id', null),
      });
    };

    $scope.recalcQuests = function (tournament) {
      TournamentsApiService.recalcTournamentQuests(tournament)
        .then(function (_data) {
          return swal({
            title: $translate.instant('TOURNAMENTLIST_RECALC_TOURNAMENT_QUESTS'),
            text: $translate.instant('TOURNAMENTLIST_RECALC_TOURNAMENT_QUESTS_DESC'),
            type: 'success',
            html: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          return swal({
            title: $translate.instant('TOURNAMENTLIST_RECALC_TOURNAMENT_QUESTS'),
            text: $translate.instant('TOURNAMENTLIST_RECALC_TOURNAMENT_QUESTS_ERROR') + ' - ' + response.data.error,
            type: 'error',
            html: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: $translate.instant('OK'),
          });
        });
    };

    $scope.supportDownloadForIE = (url) => {
      $translate(
        [
          'CSV_REPORT_READY',
          'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
        ],
        { dataUrl: url }
      ).then(function (translations) {
        // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
        swal({
          title: translations.CSV_REPORT_READY,
          text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
          type: 'success',
          html: true,
          showCancelButton: false,
          showConfirmButton: false,
        });
      });
    };

    $scope.getInfoMessageWhileCsvDownloads = () => {
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.handleCsvProcessing = (url) => {
      const isIE = /Trident|MSIE/.test(window.navigator.userAgent);
      if (window.navigator.msSaveBlob && isIE) {
        $scope.supportDownloadForIE(url);
      } else {
        document.location.href = url;
        swal.close();
      }
    };

    $scope.downloadAllTournamentsCsv = function () {
      TournamentsApiService.downloadAllSummaryCsv().then(function (csv) {
        if (csv.url) {
          $scope.handleCsvProcessing(csv.url);
        } else {
          $scope.getInfoMessageWhileCsvDownloads();
        }
      });
    };

    $scope.downloadPublicTournamentDetailedCsv = function (tournament) {
      TournamentsApiService.downloadPublicDetailedCsv(tournament).then(function (csv) {
        // The url will never be sent as null as the csv will be generated on the fly without queues
        $scope.handleCsvProcessing(csv.url);
      });
      $scope.getInfoMessageWhileCsvDownloads();
    };

    // auto refresh timer
    $scope.REFRESH_TIMER_INTERVAL = 60000;
    $scope.startRefreshTimer = function () {
      if ($scope._refreshTimerInterval) {
        $log.debug('Timer already running');
        return;
      }

      $scope._refreshTimerInterval = $interval(function () {
        if ($scope.tabs.activeIndex != $scope.TAB_NAMES.ACTIVE) {
          return;
        }

        var list = $scope.tournamentLists.active;
        var tournament = list && list[$scope.tournamentLists.activeIndex];
        if (list && tournament) {
          $scope.updateTournamentInPlace(tournament);
        }
      }, $scope.REFRESH_TIMER_INTERVAL);
    };

    $scope.stopRefreshTimer = function () {
      if ($scope._refreshTimerInterval) {
        $interval.cancel($scope._refreshTimerInterval);
      }
      delete $scope._refreshTimerInterval;
    };
    // end auto refresh timer

    $scope.companySelected = function () {
      $scope.selectedCompanyId = $scope.companySelector.selected?._id ? $scope.companySelector.selected._id : null;
      $scope.companySelectedBySCWAdmin._id = $scope.selectedCompanyId;
      $scope.companySelectedBySCWAdmin.company = $scope.companySelector.selected;
      resetPageValues();
      $scope.pagerChanged(true);
      refreshCurrentTab();
    };

    function resetPageValues() {
      $scope.tournamentPages = {
        active: {
          filter: '',
          options: { page: 1 },
        },
        completed: {
          filter: '',
          options: { page: 1 },
        },
        draft: {
          filter: '',
          options: { page: 1 },
        },
        size: 3,
        maxSize: 5,
      };

      $scope.tournamentLists = {
        active: null,
        activeIndex: 0,
        completed: null,
        completedIndex: 0,
        draft: null,
        draftIndex: 0,
      };
      $scope.tournamentToSelect = $stateParams.tournamentId;
    }

    $scope.tournamentCompanySearch = {
      options: { page: 1, size: 10 },
    };

    $scope.companySelector = {};

    $scope.init = function () {
      // Public tournaments is for scw admins and hence ensuring the scope restricts just to them
      if ($scope.isSCWAdmin) {
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.PUBLIC_TOURNAMENTS).then(function (result) {
          $scope.isPublicTournamentCreationAllowedForAdmin = result;
        });
      }

      if ($scope.isCompanyAdmin) {
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.MULTI_COMPANY_TOURNAMENT_BANNER).then(function (result) {
          $scope.isMCTBannerToBeDisplayed = result;
        });
      }

      // check if show new tournament page feature is enabled
      FeatureFlagsApi.isFeatureEnabled(FeatureFlags.NEW_TOURNAMENTS_LIST).then((result) => {
        if (result === true) {
          $state.go('tournaments.new', {});
        }
      });

      if (
        $scope.companySelectedBySCWAdmin &&
        $scope.companySelectedBySCWAdmin.company &&
        $scope.companySelectedBySCWAdmin._id
      ) {
        $scope.companySelector.selected = $scope.companySelectedBySCWAdmin.company;
      }
      resetPageValues();

      $scope.startRefreshTimer();
      if ($stateParams.tournamentId) {
        TournamentsApiService.getTournament($stateParams.tournamentId).then(function (res) {
          $scope.processTournamentData(res);
          $scope.tournament = res;
          checkCanAcceptNewParticipants($scope.tournament);
        });
      }
    };

    $scope.clearSelectedTournament = function () {
      $scope.tournament = null;
      $stateParams.tournamentId = null;
      $state.go('tournaments.list', { tournamentId: '' }, { notify: false });
    };

    $scope.$on('$destroy', function () {
      $scope.stopRefreshTimer();
    });

    $scope.init();
  },
]);

app.controller('JoinModalController', [
  '$scope',
  '$translate',
  '$uibModalInstance',
  function ($scope, $translate, $uibModalInstance) {
    $scope.save = save;
    $scope.close = close;
    const { tournament, sharedSecret } = $scope.modalData;
    // Defensive coding. Audiences will always be an array as the join modal can only be accessed for public tournaments
    $scope.isMCT = Array.isArray(tournament.audiences) && tournament.audiences.length > 0;

    $scope.joinButtonEnabled = false;
    $scope.translatedModalHeader = `${$translate.instant('JOIN')} ${tournament.name}`;

    $scope.toggleJoinButtonState = function () {
      $scope.joinButtonEnabled = !$scope.joinButtonEnabled;
    };

    function save() {
      if ($scope.joinButtonEnabled) {
        $scope.isTournamentSnackBarOpen = false; // force close any old snackbars. (Ideally background clicks, hovers should auto close them anyway)
        $uibModalInstance.close();
        $scope.joinTournamentAndPlayIfApplicable(tournament, sharedSecret);
      }
    }

    function close() {
      $uibModalInstance.close();
    }
  },
]);
