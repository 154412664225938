const pluralRules = new Intl.PluralRules(undefined, { type: 'ordinal' });
export const getOrdinal = (n) => {
    const type = pluralRules.select(n);
    // TODO: return the translated ordinal for each supported language
    switch (type) {
        case 'one':
            return `${n}st`;
        case 'two':
            return `${n}nd`;
        case 'few':
            return `${n}rd`;
        default:
            return `${n}th`;
    }
};
