import _get from 'lodash/get';

export const setMissionChallengeIdIfApplicable = (addInfo) => {
  const externalChallengeId = _get(window, '_tournamentQuest.quest.externalChallengeId');
  if (externalChallengeId) {
    addInfo.challengeId = externalChallengeId;
  }
};

export const setMissionCourseChallengeIdIfApplicable = (addInfo) => {
  const externalChallengeId = sessionStorage.getItem('course-mission-id');
  if (externalChallengeId) {
    addInfo.courseMissionId = externalChallengeId;
  }
};

export const addExploreOrQuestMissionDataToUserSnapData = (userSnapData) => {
  const ds = window.adversary?.dataset;
  if (ds?.missionId && ds?.missionLanguage) {
    userSnapData.missionId = ds.missionId;
    userSnapData.missionLanguage = ds.missionLanguage;
  }
};

export const generateUserAndPlanPropertiesForUsersnap = (sessionUser) => {
  const user = sessionUser
    ? {
        _id: sessionUser._id,
        email: sessionUser.email,
        status: sessionUser.status,
        roles: sessionUser.roles,
      }
    : 'User information not available';

  // If, a plan cannot be derived, default to N/A. Should not happen for a user in a company
  const customerPlan = sessionUser?.properties?.company?.customerPlan ?? 'n/a';

  return {
    user,
    customerPlan,
  };
};
