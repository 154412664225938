import angular from 'angular';
import moment from 'moment-timezone';
import AvsAnSimple from '../../vendor/js/AvsAn-simple';
import MODULE from './module';
import {
  validateLanguageToBeAdded,
  determineCompanyFromUserOrTournament,
  generateLanguagesToBePresentedToUser,
} from './tournaments.helpers';
import _ from 'lodash';

const app = angular.module(MODULE);

const arcRed = '#c80f28';
const arcGrey = '#555555';
const svgXmlns = 'http://www.w3.org/2000/svg';
const xlinkXmlns = 'http://www.w3.org/1999/xlink';

const PNG_EXTENTION = '.png';
const ATTACKERS_PATH = 'images/Attackers';
const TARGETS_PATH = 'images/Targets';

function dispatchEvent(evt) {
  if (typeof evt === 'string') {
    // new Event object API -> OK
    if (Event.apply) {
      evt = new Event(evt);
    }
    // deprecated way -> required to work on MSIE
    else {
      var evttype = evt;
      evt = document.createEvent('Event');
      evt.initEvent(evttype, true, true);
    }
  }
  window.dispatchEvent(evt);
}

app.controller('TournamentPlayerHomeController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$log',
  '$q',
  '$http',
  'HttpConfigService',
  '$compile',
  '$timeout',
  '$interval',
  '$uibModal',
  '$translate',
  'Session',
  'ErrorHandler',
  'TournamentsApiService',
  'GameApiService',
  '$window',
  'CHURNZERO_METRICS',
  'WalkthroughService',
  'AuthService',
  function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $log,
    $q,
    $http,
    HttpConfigService,
    $compile,
    $timeout,
    $interval,
    $uibModal,
    $translate,
    Session,
    ErrorHandler,
    TournamentsApiService,
    GameApiService,
    $window,
    CHURNZERO_METRICS,
    WalkthroughService,
    AuthService
  ) {
    const intervals = {};
    /** Making these variables local to the controller */
    const STATUS = {
      inactive: 1,
      home: 2,
      active: 3,
      in_progress: 3,
      done: 4,
      completed: 4,
    };
    const QUEST_STATUS = {
      ACTIVE: 'in_progress',
      DONE: 'done',
    };
    const TAB_NAMES = {
      COUNTRY: 0,
      OVERALL: 1,
    };

    // Have been moved around for better testability
    const { CLOUD, ECOMMERCE, BANK, UTILITY } = $rootScope.metadata.constants.game.type['007'].appType;
    const { HACKER, HACKTIVIST, CYBER_CRIMINAL, STATE_SPONSORED } =
      $rootScope.metadata.constants.game.type['007'].attackerType;
    const { BEGINNER, AWARE, SKILLED, CHAMPION } = $rootScope.metadata.constants.metrics.security_maturity.level;

    /** End variables to local scope */

    $scope.STATUS = STATUS;
    $scope.QUEST_STATUS = QUEST_STATUS;
    $scope.TAB_NAMES = TAB_NAMES;
    $scope.questProgressPercent = 0;

    $scope.selectedCountry = null;
    //$scope.terminalReady = false;
    $scope.terminalReady = true;
    $scope.isShowingTerminal = false;
    $scope.countryAppMap = {};
    $scope.storyValues = {};
    $scope.tournamentData.levelStory = null;
    $scope.now = Date.now();
    $scope.showLeaderboard = false;
    $scope.acceptMissionRequestInFlight = false;

    intervals.now = $interval(now, 1000);

    function now() {
      $scope.now = Date.now();
    }

    $translate([
      'LOCATE_VULN',
      'MARK_VULN_LINES',
      'SELECT_VULN_CATEGORY',
      'REVIEW_PROPOSED_SOLUTION',
      'INTERACTIVE_CHALLENGE',
    ]).then(function (translations) {
      $scope.STAGES = {
        L1: [translations.SELECT_VULN_CATEGORY],
        L2: [translations.LOCATE_VULN],
        L3: [translations.REVIEW_PROPOSED_SOLUTION],
        L4: [translations.SELECT_VULN_CATEGORY, translations.REVIEW_PROPOSED_SOLUTION],
        L5: [translations.LOCATE_VULN, translations.REVIEW_PROPOSED_SOLUTION],
        I1: [translations.INTERACTIVE_CHALLENGE],
      };
    });

    $scope.goBack = function () {
      $state.go('tournaments.list', $stateParams);
    };

    $scope.easyPieOptions = {
      animate: {
        duration: 500,
        enabled: true,
      },
      barColor: 'rgba(243,139,0,0.7)',
      trackColor: 'rgba(255,255,255,0.1)',
      scaleColor: false,
      lineWidth: 3,
      lineCap: 'circle',
      size: 52,
    };

    $scope.openTournamentStartModal = function (userData) {
      const { tournament } = $scope;

      const tournamentStartModalInstance = $uibModal.open({
        templateUrl: 'TournamentStartModalContent.html',
        controller: 'TournamentStartModalController',
        size: 'lg',
        scope: $scope,
      });

      tournamentStartModalInstance.result
        .then(function (userPrefData) {
          $log.debug('Closed', userPrefData, userData);

          if (!userPrefData) {
            // cancel was clicked
            $state.go('tournaments.list', $stateParams);
            return;
          }

          const saveMe = JSON.parse(JSON.stringify(userData));
          saveMe.language = { _id: userPrefData.language._id, _framework: userPrefData.language._framework };
          saveMe.homeCountry = userPrefData.homeCountry.id;

          TournamentsApiService.updatePlayerProfile(tournament, saveMe)
            .then(function () {
              $state.go($state.current.name, $stateParams, { reload: true });
            })
            .catch(function (response) {
              $translate(['ERROR_SETTING_TOURNAMENT_PREFERENCES']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_SETTING_TOURNAMENT_PREFERENCES, response);
                $scope.openTournamentStartModal(userData);
              });
            });
        })
        .catch(function () {
          // re-open the dialog if they just dismiss it
          $scope.openTournamentStartModal(userData);
        });
    };

    $scope.openTournamentInfoModal = function () {
      const tournamentInfoModalInstance = $uibModal.open({
        templateUrl: 'TournamentInfoModalContent.html',
        controller: 'TournamentInfoModalController',
        size: 'lg',
        scope: $scope,
      });

      tournamentInfoModalInstance.result
        .then(function () {
          $log.debug('Closed');
        })
        .catch(function () {
          $log.debug('Dismissed');
        });
    };

    $scope.goToLiveStats = function () {
      $state.go('tournaments.live-status', { id: $scope.tournament._id, from: 'tournaments.player' });
    };

    $scope.isActiveQuest = function (quest) {
      return quest.status === QUEST_STATUS.ACTIVE;
    };

    $scope.isCompletedQuest = function (quest) {
      return quest.status === QUEST_STATUS.DONE;
    };

    $scope.onCountrySelected = function (countryCode) {
      // used in walkthrough
      dispatchEvent('mission-panel-click');

      $log.debug('onCountrySelected()', countryCode);

      $rootScope.lastTournamentCountrySelected = countryCode;

      const countryName = $scope.worldmap.mapData.paths[countryCode].name;
      let questIndex;
      let quest;

      if (!$scope.selectedCountry || $scope.selectedCountry.countryCode !== countryCode) {
        $scope.selectedCountry = {
          countryCode,
          name: countryName,
          status: $scope.countryStatus[countryCode],
          isShowing: true,
        };

        if (
          $scope.countryStatus[countryCode] === STATUS.active ||
          $scope.countryStatus[countryCode] === STATUS.completed
        ) {
          // a lot of this is probably legacy API awfulness (e.g. an object with numbers (0,1,2,3) as the property names that needed to be used alongside another array)
          questIndex = $scope.countryAppMap[countryCode];
          quest = $scope.tournamentData.levelStory.quests[questIndex];

          $scope.setStoryValues($scope.tournamentData.levelStory, quest, Session.user, null);

          $scope.questTasksCompleted = quest.challengeSummary.done;
          $scope.questTasksTotal = quest.challengeSummary.total;
          $scope.questProgressText = $scope.questTasksCompleted + '/' + $scope.questTasksTotal;

          // can't remember what this is. most likely a hack for UI display lag
          $timeout(function () {
            $scope.questProgressPercent = Math.floor(($scope.questTasksCompleted / $scope.questTasksTotal) * 100);
          }, 300);
        } else {
          $scope.questProgressPercent = 0;
        }
      }

      if (!$scope.$$phase) {
        $scope.$apply(); // since triggered by jQuery not Angular
      }
    };

    $scope.onCountryIconSelected = function (countryCode) {
      $scope.worldmap.clearSelectedRegions();
      $scope.worldmap.setSelectedRegions(countryCode);
    };

    $scope.acceptMission = function (questId) {
      $scope.acceptMissionRequestInFlight = true;
      /**
       * This will load a different route/page. When the player page will be loaded again the
       * acceptMissionRequestInFlight will be reset to false
       */
      $state.go('tournaments.challenge', {
        tournament: $scope.tournament,
        _tournament: $stateParams._tournament,
        _quest: questId,
      });
    };

    $scope.worldmapDialogActiveIndex = TAB_NAMES.COUNTRY; // default to country view
    $scope.declineMission = function () {
      $scope.selectedCountry = null;
      $scope.worldmap.clearSelectedRegions();
      $scope.isShowingOverallProgress = false;
      $scope.worldmapDialogActiveIndex = TAB_NAMES.COUNTRY;
      delete $rootScope.lastTournamentCountrySelected;
      $('.worldmap-overlay').focus();
    };

    $scope.showOverallProgress = function () {
      if (
        $scope.tournament.leaderboardOptions.options.isDisplayed &&
        $scope.tournament.finishTime - Date.now() >= $scope.tournament.leaderboardOptions.options.hideBeforeEnd
      ) {
        TournamentsApiService.getUserRank($stateParams._tournament)
          .then(function (data) {
            $scope.rank = data;
          })
          .catch(function (response) {
            ErrorHandler.addHttpError($translate.instant('ERROR_GETTING_RANK'), response);
          });
      }
      $scope.isShowingOverallProgress = true;
      $scope.worldmapDialogActiveIndex = TAB_NAMES.OVERALL;
    };

    /*** SVG arcs and icons **/

    function distanceBetween(pointA, pointB) {
      return Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));
    }

    function midpoint(pointA, pointB) {
      const res = {};
      res.x = (pointA.x + pointB.x) / 2;
      res.y = (pointA.y + pointB.y) / 2;
      return res;
    }

    function gradient(pointA, pointB) {
      return (pointA.y - pointB.y) / (pointA.x - pointB.x);
    }

    function yInterceptConstant(x, y, m) {
      return y - m * x;
    }

    function findBezierControlPoint(pointA, pointB) {
      const originalGradient = gradient(pointA, pointB);
      const mid = midpoint(pointA, pointB);
      const normalGradient = -1 / originalGradient;
      const normalYIntercept = yInterceptConstant(mid.x, mid.y, normalGradient);
      let i = 0;
      let dist = 0;
      const controlPointDistance = distanceBetween(pointA, pointB) / 6;
      let testPoint;

      if (normalGradient === Number.POSITIVE_INFINITY || normalGradient === Number.NEGATIVE_INFINITY) {
        testPoint = { x: mid.x, y: mid.y - controlPointDistance };
      } else if (normalGradient > 0) {
        while (dist < controlPointDistance) {
          const minX = Math.min(pointA.x, pointB.x);
          if (mid.x - i < minX) {
            testPoint = { x: minX, y: normalGradient * minX + normalYIntercept };
            break;
          }
          testPoint = { x: mid.x - i, y: normalGradient * (mid.x - i) + normalYIntercept };
          dist = distanceBetween(mid, testPoint);
          i += 1;
        }
      } else {
        while (dist < controlPointDistance) {
          const maxX = Math.max(pointA.x, pointB.x);
          if (mid.x + i > maxX) {
            testPoint = { x: maxX, y: normalGradient * maxX + normalYIntercept };
            break;
          }
          testPoint = { x: mid.x + i, y: normalGradient * (mid.x + i) + normalYIntercept };
          dist = distanceBetween(mid, testPoint);
          i += 1;
        }
      }

      return testPoint;
    }

    $scope.drawArc = function (fromCoords, toCoords, xOffset, yOffset, isAttacking) {
      $log.debug('Drawing arc from ' + fromCoords + ' to ', toCoords);
      //var fromCoords = $scope.worldmap.latLngToRawPoint($rootScope.metadata.countries.all[fromCountryCode].latLng[0], $rootScope.metadata.countries.all[fromCountryCode].latLng[1]);
      //var toCoords = $scope.worldmap.latLngToRawPoint($rootScope.metadata.countries.all[toCountryCode].latLng[0], $rootScope.metadata.countries.all[toCountryCode].latLng[1]);
      const controlPoint = findBezierControlPoint(fromCoords, toCoords);

      const g = $('.jvectormap-container > svg > g[transform]');
      const path = document.createElementNS(svgXmlns, 'path');
      const pathString =
        'M' +
        fromCoords.x +
        ',' +
        fromCoords.y +
        ' Q' +
        controlPoint.x +
        ',' +
        controlPoint.y +
        ' ' +
        (toCoords.x + xOffset) +
        ',' +
        (toCoords.y + yOffset);
      path.setAttributeNS(null, 'fill', 'none');
      path.setAttributeNS(null, 'stroke', isAttacking ? arcRed : arcGrey);
      path.setAttributeNS(null, 'stroke-width', '2');
      path.setAttributeNS(null, 'stroke-linecap', 'butt');
      path.setAttributeNS(null, 'class', 'arc');
      path.setAttributeNS(null, 'd', pathString);

      g.append(path);
    };

    $scope.addIcon = function (
      coords,
      iconName,
      xOffset,
      yOffset,
      countryCode,
      targetClickCountryCode,
      isAttacking,
      isAttacked
    ) {
      $log.debug(
        'Adding ' + iconName + ' icon at: ',
        coords,
        countryCode,
        targetClickCountryCode,
        isAttacking,
        isAttacked
      );

      const g = angular.element('.jvectormap-container > svg > g[transform]');
      const img = document.createElementNS(svgXmlns, 'image');
      img.setAttributeNS(null, 'x', coords.x - 15 + xOffset);
      img.setAttributeNS(null, 'y', coords.y - 15 + yOffset);
      img.setAttributeNS(null, 'width', '30');
      img.setAttributeNS(null, 'height', '30');

      const iconFile = getIconFilePath(iconName, isAttacking);

      img.setAttributeNS(xlinkXmlns, 'href', iconFile);
      img.setAttributeNS(xlinkXmlns, 'orig-ng-click', 'onCountryIconSelected("' + targetClickCountryCode + '")');
      img.setAttributeNS(null, 'pointer', true);

      $compile(img)($scope);
      g.append(img);
    };

    function getIconFilePath(type, isActive) {
      const iconSuffix = isActive ? '-01' : '-02';
      switch (type) {
        case HACKER:
          return `${ATTACKERS_PATH}/${HACKER}2${iconSuffix}${PNG_EXTENTION}`;
        case HACKTIVIST:
          return `${ATTACKERS_PATH}/${HACKTIVIST}2${iconSuffix}${PNG_EXTENTION}`;
        case CYBER_CRIMINAL:
          return `${ATTACKERS_PATH}/${CYBER_CRIMINAL}${iconSuffix}${PNG_EXTENTION}`;
        case STATE_SPONSORED:
          return `${ATTACKERS_PATH}/${STATE_SPONSORED}${iconSuffix}${PNG_EXTENTION}`;
        case BANK:
          return `${TARGETS_PATH}/${BANK}${iconSuffix}${PNG_EXTENTION}`;
        case CLOUD:
          return `${TARGETS_PATH}/${CLOUD}${iconSuffix}${PNG_EXTENTION}`;
        case ECOMMERCE:
          return `${TARGETS_PATH}/${ECOMMERCE}${iconSuffix}${PNG_EXTENTION}`;
        case UTILITY:
          return `${TARGETS_PATH}/${UTILITY.substring(0, UTILITY.length - 1)}ies${iconSuffix}${PNG_EXTENTION}`;
        default:
          return '';
      }
    }

    function getCircularOffsets(n, total) {
      const iconSize = total === 2 ? 20 : total === 3 ? 25 : 30;

      if (total <= 0) {
        return null;
      } else if (total === 1) {
        return { x: 0, y: 0 };
      } else {
        return {
          x: iconSize * Math.cos((2 * Math.PI * n) / total - Math.PI / 2),
          y: iconSize * Math.sin((2 * Math.PI * n) / total - Math.PI / 2),
        };
      }
    }

    $scope.setStoryValues = function (level, quest, user, countryName) {
      if (level) {
        $scope.storyValues.levelInfo = level;
      }

      if (quest) {
        $scope.storyValues.questInfo = quest;

        if ($scope.storyValues.questInfo.company_name) {
          $scope.storyValues.questCompanyNameAvsAn = AvsAnSimple.query($scope.storyValues.questInfo.company_name);
        }

        if ($scope.storyValues.questInfo.timeLimit) {
          $scope.storyValues.questInfo.time_limit_mins_display = moment
            .duration($scope.storyValues.questInfo.timeLimit, 'milliseconds')
            .humanize();
        }

        quest.attacker_icon = getIconFilePath(quest.attacker_type, quest.status === QUEST_STATUS.ACTIVE);
        quest.system_icon = getIconFilePath(quest.app_type, quest.status === QUEST_STATUS.ACTIVE);
      }

      if (user) {
        $scope.storyValues.user = user;
      }

      if (countryName) {
        $scope.storyValues.country = {
          name: countryName,
        };
      } else if (quest) {
        $scope.storyValues.country = {
          name: $rootScope.metadata.countries.all[quest.attackingCountry].name,
        };
      }

      $scope.storyValues.vulnerabilitySubcategory = 'SQL Injection';
      $scope.storyValues.vulnerabilityAvsAn = AvsAnSimple.query($scope.storyValues.vulnerabilitySubcategory);
    };

    $scope.updateMapRegions = function () {
      const allCountries = $scope.metadata.countries.all;

      const homeCode = $scope.tournamentData.homeCountry;
      const toCoords = $scope.worldmap.latLngToRawPoint(
        allCountries[homeCode].latLng[0],
        allCountries[homeCode].latLng[1]
      );

      const quests = $scope.tournamentData.levelStory.quests;
      const numQuests = quests.length;

      let quest;
      let countryCode;
      let fromCoords;
      const fromCoordsMap = {};
      let questIndex;
      let numActiveQuests = 0;

      const totalTasks = $scope.player.stats.challengeSummary.total;
      const totalCompleteTasks = $scope.player.stats.challengeSummary.done;

      // count active (incomplete) quests and set statuses
      for (questIndex = 0; questIndex < numQuests; questIndex++) {
        quest = quests[questIndex];

        if (quest.challengeSummary.done < quest.challengeSummary.total) {
          numActiveQuests++;
          quest.status = QUEST_STATUS.ACTIVE;
        } else if (quest.challengeSummary.done >= quest.challengeSummary.total) {
          quest.status = QUEST_STATUS.DONE;
        }
      }

      $scope.totalCompletedChallengesPercent = Math.round((totalCompleteTasks / totalTasks) * 100);
      $scope.noActiveMissions = numActiveQuests === 0;
      $scope.noCompletedMissions = numActiveQuests === numQuests;

      if ($scope.totalCompletedChallengesPercent === 100) {
        $translate(['LEVEL_COMPLETE', 'OK']).then(function (translations) {
          // CHURNZERO - Event - Contact - Tournament Completed User
          $window.ChurnZeroEvent({ eventName: CHURNZERO_METRICS.TOURNAMENT.USER.COMPLETED });

          swal({
            title: translations.LEVEL_COMPLETE,
            text: $scope.tournamentData.levelStory.success,
            type: 'success',
            confirmButtonText: translations.OK,
            html: true,
          });
        });
      }

      // calculate attacking countries' coordinates
      // TODO this will only shift things once per quest, and later shifts may move things back closer to previous shifted countries
      for (questIndex = 0; questIndex < numQuests; questIndex++) {
        quest = quests[questIndex];
        countryCode = quest.attackingCountry;
        fromCoords = $scope.worldmap.latLngToRawPoint(
          allCountries[countryCode].latLng[0],
          allCountries[countryCode].latLng[1]
        );
        //shiftPointsApart(fromCoords, toCoords);
        fromCoordsMap[questIndex] = fromCoords;
      }

      let offsets;
      let attackerIconString;
      let targetSystemString;
      let isAttacking;

      // draw arcs in first pass so that icons will be overlayed on top of arcs
      for (questIndex = 0; questIndex < numQuests; questIndex++) {
        quest = quests[questIndex];
        $log.debug('QUEST: ', quest);

        countryCode = quest.attackingCountry;
        fromCoords = fromCoordsMap[questIndex];

        $scope.countryAppMap[countryCode] = questIndex;
        $scope.countryStatus[countryCode] = STATUS[quest.status];

        offsets = getCircularOffsets(questIndex, numQuests);
        attackerIconString = quest.attacker_type;
        targetSystemString = quest.app_type;
        isAttacking = quest.status === QUEST_STATUS.ACTIVE;

        $scope.drawArc(fromCoords, toCoords, offsets.x, offsets.y, isAttacking);
      }

      // draw icons in second pass so that icons will be overlayed on top of arcs
      for (questIndex = 0; questIndex < numQuests; questIndex++) {
        quest = quests[questIndex];
        countryCode = quest.attackingCountry;
        fromCoords = fromCoordsMap[questIndex];

        offsets = getCircularOffsets(questIndex, numQuests);
        attackerIconString = quest.attacker_type;
        targetSystemString = quest.app_type;
        isAttacking = false;

        if (quest.status === QUEST_STATUS.ACTIVE) {
          isAttacking = true;
          $scope.addIcon(fromCoords, attackerIconString, 0, 0, countryCode, countryCode, isAttacking, false);
          $scope.addIcon(toCoords, targetSystemString, offsets.x, offsets.y, homeCode, countryCode, isAttacking, true);
        } else {
          $scope.addIcon(fromCoords, attackerIconString, 0, 0, countryCode, countryCode, isAttacking, false);
          $scope.addIcon(toCoords, targetSystemString, offsets.x, offsets.y, homeCode, countryCode, isAttacking, true);
        }
      }

      $scope.countryStatus[homeCode] = STATUS.home;
      $scope.worldmap.series.regions[0].setValues($scope.countryStatus);

      if ($rootScope.lastTournamentCountrySelected) {
        const country = $rootScope.lastTournamentCountrySelected;
        delete $rootScope.lastTournamentCountrySelected;
        $scope.onCountrySelected(country);
      }
    };

    $scope.displayTerminal = function () {
      // hack to stop terminal errors whilst temporarily disabled
    };
    // hack to stop terminal errors whilst temporarily disabled
    $scope.terminal = {
      disable: function () {},
    };

    // background music (intense!)
    $scope.bgmMuted = true;
    $scope.toggleBgm = function () {
      const bgm = document.getElementById('bgm');
      $scope.bgmMuted = !$scope.bgmMuted;
      if ($scope.bgmMuted) {
        bgm.pause();
      } else {
        if (!bgm.childNodes.length) {
          const source = document.createElement('source');
          source.setAttribute('src', 'media/bgm.m4v');
          source.setAttribute('type', 'audio/mp4');
          bgm.appendChild(source);
        }
        bgm.play();
      }
    };

    $scope.walkthroughActive = false;
    function walkthrough() {
      $scope.walkthroughActive = true;
      WalkthroughService.trigger('training.worldmap', $scope.tourWorldmapOptions);

      var updatedStatus = Session.user.properties.tutorial;
      updatedStatus.items['seenWorldmap'] = true;

      AuthService.updateTutorialStatus(updatedStatus)
        .then(function (data) {
          Session.user.properties.tutorial = data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError('Error updating tutorial status', response);
        })
        .finally(function () {});
    }

    $scope.displayHelp = function () {
      $scope.selectedCountry = null;
      walkthrough();
    };

    $scope.proactiveHelp = function () {
      if (
        Session.user.properties.tutorial &&
        Session.user.properties.tutorial.enabled &&
        !Session.user.properties.tutorial.items.seenWorldmap
      ) {
        $scope.displayHelp();

        const updatedStatus = Session.user.properties.tutorial;
        updatedStatus.items.seenWorldmap = true;
        GameApiService.addLoading();
        GameApiService.updateTutorialStatus(updatedStatus)
          .then(function () {
            // nothing
          })
          .catch(function (response) {
            $translate(['ERROR_UPDATING_TUTORIAL_STATUS']).then(function (translations) {
              ErrorHandler.addHttpError(translations.ERROR_UPDATING_TUTORIAL_STATUS, response);
            });
          })
          .finally(function () {
            GameApiService.removeLoading();
          });
      }
    };

    /*** tour ***/
    $translate([
      'CLICK_THIS_BUTTON_TO_VIEW_THIS_TUTORIAL_ANYTIME',
      'NEXT',
      'PREVIOUS',
      'CLOSE',
      'FINISH',
      'TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_1',
      'TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_2',
      'TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_3',
      'TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_4',
    ]).then(function (translations) {
      $scope.tourWorldmapOptions = {
        steps: [
          {
            intro: translations.TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_1,
          },
          {
            intro: translations.TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_2,
          },
          {
            element: '.worldmap-overlay',
            intro: translations.TOUR_TOURNAMENT_WORLD_MAP_OPTIONS_3,
            position: 'right',
          },
          {
            element: '#tutorial-menu',
            intro: translations.CLICK_THIS_BUTTON_TO_VIEW_THIS_TUTORIAL_ANYTIME,
            position: 'bottom',
          },
        ],
        showStepNumbers: false,
        exitOnOverlayClick: true,
        exitOnEsc: true,
        nextLabel: translations.NEXT,
        prevLabel: translations.PREVIOUS,
        skipLabel: translations.CLOSE,
        doneLabel: translations.FINISH,
        overlayOpacity: 0.9,
        showBullets: false,
      };
    });

    $scope.tourComplete = function () {
      $log.debug('Tour complete');
    };

    // this gets called by game013 worldmap directive so redirect it to init
    $scope.fetchStoryAndUpdateState = function () {
      $scope.init();
    };

    $scope.findById = function (arr, _id) {
      return arr.filter(function (item) {
        return _id === item._id;
      })[0];
    };

    $scope.populateLanguageList = function (languageData) {
      let languageList;
      const filteredLanguageList = [];
      const isPublic = !$scope.tournament._company && !$scope.tournament._team;

      if (languageData?.length) {
        // restrict to allowed languages
        languageList = JSON.parse(JSON.stringify(languageData));
      } else {
        // use any licensed language
        if (Session.user?.languages?.length) {
          // standalone developer
          languageList = JSON.parse(JSON.stringify(Session.user.languages));
        } else if (Session.user?.properties?.team?.languages?.length) {
          // standalone team or team restriction
          languageList = JSON.parse(JSON.stringify(Session.user.properties.team.languages));
        } else if (Session?.user?.properties?.company?.languages?.length) {
          // company
          languageList = JSON.parse(JSON.stringify(Session.user.properties.company.languages));
        }
      }

      const languageConstraints = isPublic ? [] : Session.user.properties.languages;
      // For a normal tournament, the user would be restricted based on the licenses the user has. No such constraint in public tournaments
      const languagesToPresentToUser = generateLanguagesToBePresentedToUser(languageList, languageConstraints, true);

      languagesToPresentToUser.forEach((language) => {
        language.displayLanguage = $scope.metadata.languages[language._id].name;
        language.displayFramework = $scope.metadata.languages[language._id].framework[language._framework].name;
        if (validateLanguageToBeAdded(filteredLanguageList, language)) {
          filteredLanguageList.push(language);
        }
      });
      $scope.languageList = filteredLanguageList;
    };

    $scope.updateMapForParticipant = function () {
      // this just makes the terminal work
      $scope.gameData = $scope.tournamentData;

      $scope.playerLevel = 1;
      if (!$scope.useUserFlowTournaments) {
        $scope.proactiveHelp();
      }
      $scope.updateMapRegions(); // This will need to be verified when public tournaments open for non-company users
    };

    $scope.getBlockedCountriesListFromCompanyForMap = function (companyId) {
      $log.debug('Get Blocked Countries Preferences - ' + companyId);
      $http
        .get($window.SCW_ENV.ApiEndpoint + '/blocked/countries/' + companyId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          if (response.data.length > 0) {
            _.forEach(response.data, function (eachCountry) {
              _.forEach(jvm.$('path'), function (path) {
                const $path = $(path);
                if ($path.data('code') === eachCountry.id) {
                  $path.attr('class', '');
                }
              });
            });
          }
          // Wait for the response to remove blocked countries prior to updating user map
          $scope.updateMapForParticipant();
        });
    };

    // loads storyline
    $scope._fetchStoryAndUpdateState = function () {
      // fetch tournament status
      TournamentsApiService.getPlayerDetails($scope.tournament, Session.user._id)
        .then(function (data) {
          $translate(['CONGRATULATIONS_COMPLETED_TOURNAMENT_GO_CHECK_LEADERBOARD']).then(function (translations) {
            if (!data.isInitialised) {
              $scope.populateLanguageList($scope.tournament.languages);
              $scope.openTournamentStartModal(data);
              return;
            }
            if (data.language) {
              $rootScope.tournaments_language = {
                _language: data.language._id,
                _framework: data.language._framework,
              };
            }

            $scope.startCountdownTimer();
            $scope.startRefreshTimer();

            $scope.player = data;

            $scope.tournamentData.homeCountry = data.homeCountry;

            for (let i = 0; i < data.stats.quests.length; i++) {
              let quest = data.stats.quests[i];
              quest.name = $scope.tournament.questNames[i].name;
              quest.description = $scope.tournament.questNames[i].description;
            }

            $scope.player.stats.challengeSummary = data.stats.challengeSummary;

            // Get the category type for player's selected language
            const playerLanguage = $scope.player.language;
            const categoryType =
              $rootScope.metadata.languages[playerLanguage._id].framework[playerLanguage._framework].categoryType;

            for (let i = 0; i < data.stats.quests.length; i++) {
              const quest = data.stats.quests[i];

              for (let j = 0; j < quest.challenges.length; j++) {
                const challenge = quest.challenges[j];

                // Category type can be either "web" or "mobile"
                if (challenge?.category?._id) {
                  challenge.category.categoryText =
                    $rootScope.metadata.categories[categoryType][challenge.category._id].name;
                }
                if (challenge?.category?._sub) {
                  challenge.category.subcategoryText =
                    $rootScope.metadata.categories[categoryType][challenge.category._id].subitem[
                      challenge.category._sub
                    ].name;
                }
              }
            }

            $scope.tournamentData.levelStory = {
              _id: $scope.tournament._id,
              description: $scope.tournament.description,
              name: $scope.tournament.name,
              quests: data.stats.quests,
              success: translations.CONGRATULATIONS_COMPLETED_TOURNAMENT_GO_CHECK_LEADERBOARD,
            };

            // With public tournaments, would not always be able to extract a company from tournaments
            const companyId = determineCompanyFromUserOrTournament($scope.tournament, Session?.user);

            if (companyId) {
              $scope.getBlockedCountriesListFromCompanyForMap(companyId);
            } else {
              $scope.updateMapForParticipant(); // If no company associated directly update the map regions
            }

            if ($stateParams.viewResults) {
              $scope.showOverallProgress();
            }
          });
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_TOURNAMENT_STATUS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_TOURNAMENT_STATUS, response);
          });
        });
    };

    $scope.developerTableData = [];
    $scope.updateLeaderboard = function (data) {
      $translate(['BEGINNER', 'SECURITY_AWARE', 'SECURITY_SKILLED', 'SECURITY_CHAMPION']).then(function (translations) {
        $scope.developerTableData = data.list.data;

        for (let i = 0; i < $scope.developerTableData.length; i++) {
          const developer = $scope.developerTableData[i];
          developer.rankChange = developer.previousRank !== -1 ? developer.previousRank - developer.currentRank : 'New';

          if (developer.securityMaturity) {
            switch (developer.securityMaturity.maturityLevel) {
              case BEGINNER.id:
                developer.sortedSecurityMaturity = 1;
                developer.displaySecurityMaturity = translations.BEGINNER;
                break;
              case AWARE.id:
                developer.sortedSecurityMaturity = 2;
                developer.displaySecurityMaturity = translations.SECURITY_AWARE;
                break;
              case SKILLED.id:
                developer.sortedSecurityMaturity = 3;
                developer.displaySecurityMaturity = translations.SECURITY_SKILLED;
                break;
              case CHAMPION.id:
                developer.sortedSecurityMaturity = 4;
                developer.displaySecurityMaturity = translations.SECURITY_CHAMPION;
                break;
            }
          }

          // PORTAL-1038 - null for invited users that have not accepted the invite
          if (developer.registered) {
            developer.registeredDisplay = moment(developer.registered).format('LL');
          }
        }
      });
    };

    // auto refresh timer
    $scope.REFRESH_TIMER_INTERVAL = 30000;
    $scope.startRefreshTimer = function () {
      if ($scope._refreshTimerInterval) {
        $log.debug('Timer already running');
        return;
      }

      $scope._refreshTimerInterval = $interval(function () {
        TournamentsApiService.getTournament($scope.tournament._id).then(function (data) {
          const refreshedTournament = data;
          $scope.processTournamentData(refreshedTournament);
          $scope.tournament = refreshedTournament;
          $scope.showLeaderboard =
            !!$scope.tournament._company &&
            $scope.tournament.leaderboardOptions.options.isDisplayed &&
            $scope.tournament.finishTime - $scope.now >= $scope.tournament.leaderboardOptions.options.hideBeforeEnd;
        });
      }, $scope.REFRESH_TIMER_INTERVAL);
    };

    $scope.stopRefreshTimer = function () {
      if ($scope._refreshTimerInterval) {
        $interval.cancel($scope._refreshTimerInterval);
      }
      delete $scope._refreshTimerInterval;
    };
    // end auto refresh timer

    // countdown timer and display
    $scope.isShowingCountdown = false;
    $scope.isShowingLiveStats = true;
    $scope.timeIsUp = false;
    $scope.COUNTDOWN_TIMER_INTERVAL = 1000;
    $scope.countdownString = $translate.instant('LOADING');
    $scope.startCountdownTimer = function () {
      if ($scope._countdownTimerInterval) {
        $log.debug('Timer already running');
        return;
      }

      TournamentsApiService.getCountdownForTournament($scope.tournament._id)
        .then(function (data) {
          const { remainingSeconds } = data;
          $scope.countdownValue = Math.floor(remainingSeconds);
          $scope.timeLimit = ($scope.tournament.finishTime - $scope.tournament.startTime) / 1000;

          $scope.timeThreshold = 181; // time in seconds - go into final countdown mode at 3 mins unless configured
          if ($scope.tournament.leaderboardOptions.options.hideBeforeEnd) {
            $scope.timeThreshold = $scope.tournament.leaderboardOptions.options.hideBeforeEnd / 1000 + 1;
          }

          handleCountdownValue();
          $scope._countdownTimerInterval = $interval(countdownTimerTick, $scope.COUNTDOWN_TIMER_INTERVAL);
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GETTING_COUNTDOWN'), response);
        });
    };

    function countdownTimerTick() {
      // Defensive check in case countdownTimer cancellation was unsuccessful see PLAT-13175
      if ($scope.timeIsUp) return;
      $scope.countdownValue -= 1;
      handleCountdownValue();
    }

    function handleCountdownValue() {
      if (!$scope.isShowingCountdown && $scope.countdownValue < $scope.timeThreshold) {
        if ($scope.tournament.leaderboardOptions.options.hideBeforeEnd) {
          $scope.isShowingLiveStats = false;
        }
        $scope.isShowingCountdown = true;
      }

      $scope.countdownValuePercent = Math.round(($scope.countdownValue / $scope.timeLimit) * 100);
      $scope.countdownString = $rootScope.utility.longFormatDuration($scope.countdownValue * 1000);

      if ($scope.countdownValue <= 0) {
        $scope.countdownValue = 0;
        $scope.countdownString = 'Tournament Over';
        $scope.timeIsUp = true;
        $scope.stopCountdownTimer();
        $scope.sendTimeoutLog();

        if ($scope.tournament.leaderboardOptions.options.isDisplayed) {
          $scope.isShowingLiveStats = true;
        }
      }
    }

    $scope.getTimeSpentString = function (timeSpent) {
      return $rootScope.utility.longFormatDuration(timeSpent);
    };

    $scope.sendTimeoutLog = function () {
      TournamentsApiService.sendTimeoutLog($stateParams._tournament);
    };

    $scope.stopCountdownTimer = function () {
      try {
        if ($scope._countdownTimerInterval) {
          if ($interval.cancel($scope._countdownTimerInterval)) {
            $log.debug('_countdownTimerInterval cancelled - tournament home');
            $scope._countdownTimerInterval = undefined;
          } else {
            $log.warn('did not cancel _countdownTimerInterval - tournament home');
          }
        }
      } catch (e) {
        $log.error('Error when trying to cancel _countdownTimerInterval - tournament home', e);
      }
    };

    $scope.playerHomeTabs = {
      activeIndex: 0,
    };

    $scope.PLAYER_HOME_TAB_NAMES = {
      BASE: 0,
      BONUS: 1,
    };

    $scope.init = function () {
      $q.all([TournamentsApiService.getTournament($stateParams._tournament)])
        .then(function (data) {
          const tournament = data[0];
          $scope.processTournamentData(tournament);
          $scope.tournament = tournament;
          $scope.showLeaderboard =
            !!tournament._company &&
            tournament.leaderboardOptions.options.isDisplayed &&
            tournament.finishTime - $scope.now >= tournament.leaderboardOptions.options.hideBeforeEnd;
          $scope._fetchStoryAndUpdateState();
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_TOURNAMENT']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_TOURNAMENT, response);
            $state.go('tournaments.list');
          });
        });
    };

    $scope.$on('$destroy', function () {
      $scope.stopCountdownTimer();
      $scope.stopRefreshTimer();
    });

    $scope.ceilHintsScoring = function (value) {
      return Math.ceil(value * 100);
    };

    $scope.floorHintsScoring = function (value) {
      return Math.floor(value * 100);
    };
  },
]);

app.controller('TournamentStartModalController', [
  '$uibModalInstance',
  '$log',
  '$translate',
  '$scope',
  '$rootScope',
  '$http',
  'HttpConfigService',
  'GeoIPService',
  '$window',
  'Session',
  function (
    $uibModalInstance,
    $log,
    $translate,
    $scope,
    $rootScope,
    $http,
    HttpConfigService,
    GeoIPService,
    $window,
    Session
  ) {
    $translate([
      'LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE',
      'ESTABLISHING_UPLINK_TO_LOCATION',
      'LOCATION_COULD_NOT_BE_ESTABLISHED_MANUAL_OVERRIDE_REQUIRED',
      'UPLINK_COMPLETE',
    ]).then(function (translations) {
      $scope.forms = {};

      $scope.countries = [];
      _.map($rootScope.metadata.countries.all, function (each, index) {
        $scope.countries.push({ id: index, name: each.name });
      });

      $scope.prePopulateDataForStartModal = function () {
        if ($scope.languageList.length === 1) {
          $scope.forms.selectedLanguageFramework = $scope.languageList[0];
        }

        if ($scope.tournamentData.homeCountry) {
          $scope.geoIPLoadingMessage = translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE;
          for (let c = 0; c < $scope.countries.length; c++) {
            if ($scope.countries[c].id === $scope.tournamentData.homeCountry) {
              $scope.forms.selectedHomeCountry = $scope.countries[c];
              break;
            }
          }
        } else {
          $scope.geoIPLoadingMessage = translations.ESTABLISHING_UPLINK_TO_LOCATION;
          GeoIPService.getLocation().then(function (data) {
            $scope.geoIPLoadingMessage = translations.UPLINK_COMPLETE;
            for (let cIndex = 0; cIndex < $scope.countries.length; cIndex++) {
              if ($scope.countries[cIndex].id === data.country_code) {
                $scope.forms.selectedHomeCountry = $scope.countries[cIndex];
                $scope.geoIPLoadingMessage = translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE;
                break;
              }
            }
            $scope.geoIPLoadingMessage =
              $scope.geoIPLoadingMessage === translations.LOCATION_ESTABLISHED_MANUAL_OVERRIDE_AVAILABLE
                ? $scope.geoIPLoadingMessage
                : translations.LOCATION_COULD_NOT_BE_ESTABLISHED_MANUAL_OVERRIDE_REQUIRED;
          });
        }
      };

      $scope.getBlockedCountriesListFromCompanyForModal = function (companyId) {
        $log.debug('Get Blocked Countries Preferences - ' + companyId);
        $http
          .get($window.SCW_ENV.ApiEndpoint + '/blocked/countries/' + companyId, HttpConfigService.getHttpConfig())
          .then(function (response) {
            if (response.data.length > 0) {
              _.forEach(response.data, function (eachCountry) {
                _.remove($scope.countries, function (country) {
                  return country.id === eachCountry.id;
                });
              });
            }
            // Wait for the response to remove blocked countries prior to pre populating modal data
            $scope.prePopulateDataForStartModal();
          });
      };

      const companyId = determineCompanyFromUserOrTournament($scope.tournament, Session?.user);

      if (companyId) {
        $scope.getBlockedCountriesListFromCompanyForModal(companyId);
      } else {
        $scope.prePopulateDataForStartModal(); // If no company associated directly update the map regions
      }

      $scope.update = function () {
        if ($scope.forms.homeCountryForm.$invalid) {
          $scope.forms.homeCountryForm.homeCountry.$dirty = true;
          $scope.forms.homeCountryForm.languageFramework.$dirty = true;
          return;
        }

        $uibModalInstance.close({
          language: $scope.forms.selectedLanguageFramework,
          homeCountry: $scope.forms.selectedHomeCountry,
        });
      };

      $scope.cancel = function () {
        $uibModalInstance.close();
      };
    });
  },
]);

app.controller('TournamentInfoModalController', [
  '$uibModalInstance',
  '$scope',
  function ($uibModalInstance, $scope) {
    $scope.close = function () {
      $uibModalInstance.close();
    };

    // Remove free hints for new challenge player
    $scope.tournament.scoringOptions.hints['L1'] = $scope.tournament.scoringOptions.hints['L1'].filter(
      (hintCost) => hintCost !== 0
    );
    $scope.tournament.scoringOptions.hints['L2'] = $scope.tournament.scoringOptions.hints['L2'].filter(
      (hintCost) => hintCost !== 0
    );
  },
]);
