import angular from 'angular';
import MODULE from '../module';

angular.module(MODULE).controller('ManagementAdminCompanyIntegrationsRegisterController', [
  '$uibModalInstance',
  '$scope',
  '$translate',
  'AdminApiService',
  function ($uibModalInstance, $scope, $translate, AdminApiService) {
    // TODO: Update page title and description for accessibility with input from design and UX team
    $scope.adminApiService = AdminApiService;
    $scope.translate = function translate(key) {
      return $translate.instant(key);
    };

    $scope.close = function close() {
      $uibModalInstance.close();
    };
  },
]);
