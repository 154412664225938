import { jsx as _jsx } from "react/jsx-runtime";
import { styled, Snackbar } from '@mui/material';
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
// TODO: Replace hard coded values with theme variables once Toaster designs are finalised
const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    maxWidth: '720px',
    minWidth: '360px',
    width: 'calc(100% - 24px)',
    '& .MuiSnackbarContent-root': {
        backgroundColor: '#2C3854',
        width: '100%',
        color: '#F6F8FB',
        padding: theme.spacing(2, 4),
        borderRadius: theme.shape.borderRadius * 3,
        boxShadow: '0px 4px 4px 0px #202A4240',
        backgroundImage: 'unset',
    },
    '& .MuiSnackbarContent-message': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
    },
}));
export const Toaster = (props) => (_jsx(DSThemeProvider, { theme: "dark", children: _jsx(StyledSnackbar, Object.assign({}, props)) }));
