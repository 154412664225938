import angular from 'angular';
import moment from 'moment-timezone';
import d3 from 'd3';
import MODULE from './module';
import templateUrl from './tournaments.admin.edit.save-or-publish.html';
import $ from 'jquery';
import 'chart.js';
import 'bootstrap-switch';
import * as _ from 'lodash';

const app = angular.module(MODULE);

app.controller('TournamentAddController', [
  '$q',
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$uibModal',
  '$log',
  '$translate',
  '$timeout',
  '$swal',
  'AuthService',
  'USER_ROLES',
  'Session',
  'TournamentsApiService',
  'ErrorHandler',
  'AdminApiService',
  'LanguageUtils',
  'PlatformLanguagesService',
  'WalkthroughService',
  'AnalyticsService',
  'AnalyticsEvents',
  'FeatureFlagsApi',
  'FeatureFlags',
  function (
    $q,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $uibModal,
    $log,
    $translate,
    $timeout,
    $swal,
    AuthService,
    USER_ROLES,
    Session,
    TournamentsApiService,
    ErrorHandler,
    AdminApiService,
    LanguageUtils,
    PlatformLanguagesService,
    WalkthroughService,
    AnalyticsService,
    AnalyticsEvents,
    FeatureFlagsApi,
    FeatureFlags
  ) {
    let tts; // to be used as a shorthand reference to tournamentToSave

    $scope.forms = {};
    $scope.isShowingAdvancedSettings = false;
    $scope.enableInteractiveChallengeButton = true;
    $scope.isTournamentReplicated = false;
    $scope.inviteAllUsers = false;

    FeatureFlagsApi.isFeatureEnabled(FeatureFlags.TOURNAMENT_INVITES).then(function (result) {
      $timeout(function () {
        $scope.tournamentInviteEnabled = result;
      });
    });

    //To be used for mocking test function only
    $scope.testInit;
    /** This is an interim step for labs
     *  Will be moved over to metadata
     */
    $scope.showTournamentCompanySelectorComponent = false; // Toggle switch for company selection
    $scope.isTournamentAudienceComponentDisabled = false; // should the component be disabled
    $scope.companyList = [];
    $scope.isSaveAndPublishButtonDisabled = false;

    $scope.onTournamentCompanySelectorSwitchToggle = () => {
      $scope.showTournamentCompanySelectorComponent = !$scope.showTournamentCompanySelectorComponent;

      // Essentially toggled around for the first load of the page
      if ($scope.showTournamentCompanySelectorComponent) {
        if (!$scope.companyList.length) {
          $scope.fetchCompanyList();
        }
        $scope.isSaveAndPublishButtonDisabled = ($scope.tournamentToSave?.audiences ?? []).length < 2;
      } else {
        $scope.isSaveAndPublishButtonDisabled = false;
        $scope.tournamentToSave.audiences = []; // clear any existing companies on the model
      }
    };

    $scope.onTournamentCompanySelectedListUpdate = (selectedCompaniesArray) => {
      $scope.tournamentToSave.audiences = selectedCompaniesArray;
      $scope.isSaveAndPublishButtonDisabled = ($scope.tournamentToSave?.audiences ?? []).length < 2;
    };

    $scope.fetchCompanyList = () => {
      // get company and team list
      AdminApiService.addLoading();
      AdminApiService.getConsolidatedCompanyList()
        .then(function (companies) {
          $scope.companyList = companies;
        })
        .catch((response) => {
          ErrorHandler.addHttpError('Error loading company list', response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.interactive = {
      interactive: {
        easy: 200,
        medium: 400,
        hard: 600,
      },
      hintWeight: {
        small: 0.1,
        medium: 0.3,
        high: 0.5,
      },
      maxPenalty: {
        forgiving: 0.6,
        default: 0.8,
        aggressive: 1,
      },
    };

    $scope.walkthrough = function () {
      const tutorials = Session.user.properties.tutorial;
      if (!tutorials.items.doneCreateTournament) {
        tutorials.items.doneCreateTournament = true;
        AuthService.updateTutorialStatus(tutorials)
          .then(function (data) {
            Session.user.properties.tutorial = data;
          })
          .catch(function (response) {
            ErrorHandler.addHttpError('Error updating tutorial status', response);
          })
          .finally(function () {});
      }
      WalkthroughService.trigger('tournaments.create', { overlayClicked: 'exit' });
    };

    // check if user has done the assessment create walkthrough already
    $timeout(function () {
      const tutorials = Session.user.properties.tutorial;
      if (tutorials?.enabled && !tutorials.items.doneCreateTournament) {
        tutorials.items.doneCreateTournament = true;
        AuthService.updateTutorialStatus(tutorials)
          .then(function (data) {
            Session.user.properties.tutorial = data;
          })
          .catch(function (response) {
            ErrorHandler.addHttpError('Error updating tutorial status', response);
          })
          .finally(function () {});

        $scope.ui = $scope.ui || {};
        $scope.ui.advanced = $scope.ui.advanced || {};
        $scope.ui.advanced.isOpen = true;

        WalkthroughService.trigger('tournaments.create');
      }
    }, 100);

    $scope.ERRORS = {
      SIZE: 'INVALID_SIZE',
      LANGUAGE: 'INVALID_LANGUAGE',
    };
    $scope.minDuration = 24; // minimum value for duration (number of challenges) slider
    const ABS_MAX_DURATION = ($scope.maxDuration = 80); // maximum value

    $scope.donutOptions = {
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      percentageInnerCutout: 20,
      responsive: false,
    };
    $scope.donutColours = ['rgba(255,255,255,0.2)', 'rgba(255,255,255,0.5)', 'rgba(255,255,255,0.8)'];
    Chart.defaults.global.defaultFontColor = '#ccc';

    $scope.categoryChartOptions = {
      scales: {
        xAxes: [{ ticks: { min: 0 } }],
      },
    };

    $scope.stackedBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        margin: {
          left: 220,
        },
        x: function (d) {
          return d.label;
        },
        y: function (d) {
          return d.value;
        },
        showControls: false,
        stacked: true,
        showLegend: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false,
        },
        yAxis: {
          //axisLabel: "Values",
          tickFormat: function (d) {
            // FIXME: this is the ONLY use of D3 in the entire codebase.
            // Just implement the format function manually to avoid the large dependency.
            return d3.format('d')(d);
          },
        },
        callback: function (chart) {
          if (chart && chart.update) {
            chart.update();
          }
        },
      },
    };
    const BAR_COLOURS = [
      'rgba(255,255,255,0.2)',
      'rgba(255,255,255,0.3)',
      'rgba(255,255,255,0.4)',
      'rgba(255,255,255,0.5)',
      'rgba(255,255,255,0.6)',
      'rgba(255,255,255,0.7)',
      'rgba(255,255,255,0.8)',
      'rgba(255,255,255,0.9)',
    ];

    // active tab tracking
    $scope.tabs = {
      activeIndex: 0,
    };

    $scope.scoringTabs = {
      activeIndex: 0,
    };

    $scope.TAB_NAMES = {
      SETTINGS: 0,
      LANGUAGES: 1,
      SCORING: 2,
      ADVANCED: 3,
      PARTICIPANTS: 4,
    };

    $scope.SCORING_TAB_NAMES = {
      BASE: 0,
      BONUS: 1,
    };

    $scope.$watch('questsCheckingInProgress', function (n, _o) {
      if (!n) {
        $timeout(initDurationSlider, 100);
      }
    });

    $scope.$watch('tabs.activeIndex', function (val) {
      if (val == $scope.TAB_NAMES.LANGUAGES) {
        $timeout(function () {
          $scope.hasPreviewed = true;
          $scope.checkPromise.then(function () {
            $scope.chartWorkaround = true;
            initDurationSlider();
          });
        });
      } else {
        $scope.chartWorkaround = false;
      }
    });

    $translate([
      'LOCATE_VULN',
      'MARK_VULN_LINES',
      'SELECT_VULN_CATEGORY',
      'REVIEW_PROPOSED_SOLUTION',
      'INTERACTIVE_CHALLENGE',
    ]).then(function (translations) {
      $scope.STAGES = {
        L1: [translations.SELECT_VULN_CATEGORY],
        L2: [translations.LOCATE_VULN],
        L3: [translations.REVIEW_PROPOSED_SOLUTION],
        L4: [translations.SELECT_VULN_CATEGORY, translations.REVIEW_PROPOSED_SOLUTION],
        L5: [translations.LOCATE_VULN, translations.REVIEW_PROPOSED_SOLUTION],
        I1: [translations.INTERACTIVE_CHALLENGE],
      };
    });

    $scope.dateOptions = {
      startingDay: 1,
      showWeeks: false,
      clearText: 'Clear',
    };

    $scope.timePickerOptions = {
      hourStep: 1,
      minuteStep: 5,
    };

    // Remove free hints for new challenge player
    const scoringPresets = $scope.metadata.constants.tournaments.scoring.presets.map((preset) => ({
      ...preset,
      scoringOptions: {
        ...preset.scoringOptions,
        hints: {
          ...preset.scoringOptions.hints,
          L1: preset.scoringOptions.hints['L1'].filter((hintCost) => hintCost !== 0),
          L2: preset.scoringOptions.hints['L2'].filter((hintCost) => hintCost !== 0),
          L3: preset.scoringOptions.hints['L3'].filter((hintCost) => hintCost !== 0),
        },
      },
    }));

    $scope.scoringPresets = scoringPresets || $scope.metadata.constants.tournaments.scoring.presets;
    $scope.forms.selectedScoringPreset = scoringPresets[1]; // "Default" preset

    /**
     *  Important to have this property rather than whether replication was successful or not. If this property exists,
     *  it means replication has been attempted and all content modifiers (languages etc) need to be locked.
     */
    $scope.setReplicationIfApplicable = function (tournament) {
      return !tournament._company ? 'replicated' in tournament : false;
    };

    /** This is just an interim step till we add these options to metadata
     *  Will be called when tournament created
     *  Will be called when we edit any old tournament
     */
    function addInteractiveScoringData() {
      //These preset values are then updated to scoring options later
      $scope.scoringPresets.forEach(function (preset) {
        //do this if value not set already
        if (preset.scoringOptions) {
          _.assign(preset.scoringOptions, $scope.interactive);
        }
      });
    }

    $scope.onSelectedScoringPreset = function ($item) {
      tts.scoringOptions = $item.scoringOptions;
      tts.scoringPreset = $scope.forms.selectedScoringPreset._id;
      updateWorkedExample($item.scoringOptions);
    };

    // If user inputs '0' for maxPlayers, wipe the form so it shows the placeholder value of
    // 'Unlimited' - and also ensure only ints are recorded
    $scope.checkMaxPlayersForm = function () {
      const maxPlayerElement = document.getElementById('maxPlayers');
      maxPlayerElement.value = tts.maxPlayers = parseInt(maxPlayerElement.value) || null;
      if (maxPlayerElement.value <= 0) {
        maxPlayerElement.value = tts.maxPlayers = null;
      }
    };

    $scope.updateMaxPlayers = function (increment) {
      tts.maxPlayers = tts.maxPlayers || 0;
      tts.maxPlayers += increment;
      if (tts.maxPlayers < 1) {
        const maxPlayerElement = document.getElementById('maxPlayers');
        maxPlayerElement.value = tts.maxPlayers = null;
      }
    };

    $scope.updateAutoHide = function (increment) {
      const opts = tts.leaderboardOptions.options;
      opts.hideBeforeEnd = opts.hideBeforeEnd || 0;
      opts.hideBeforeEnd += increment;

      if (opts.hideBeforeEnd <= 0) {
        $scope.forms.autoHideSwitch = false;
        opts.hideBeforeEnd = 0;
      }
    };

    $scope.isMixLanguagesTournament = function () {
      return (
        ($scope.questsData &&
          $scope.questsData.quests &&
          $scope.questsData.quests[0] &&
          $scope.questsData.quests[0].appType) === 'mix'
      );
    };

    // Initialise the 'hideBeforeEnd' value to 10 when the user turns on 'Auto Hide' in the UI
    // And change it to zero if they switch it off.
    $scope.$watch('forms.autoHideSwitch', function (switchIsOn) {
      // stop console error on load
      if (!tts) {
        return;
      }
      const opts = tts.leaderboardOptions.options;
      if (switchIsOn) {
        // Initialise with a value of 10 if we don't already have a value
        if (!opts.hideBeforeEnd) {
          opts.hideBeforeEnd = 10;
        }
      } else {
        // Reset to zero
        opts.hideBeforeEnd = 0;
      }
    });

    $scope.$watch('tournamentToSave.leaderboardOptions.options.isDisplayed', function (switchIsOn) {
      if (!switchIsOn) {
        $scope.forms.autoHideSwitch = false;
      }
    });

    $scope.$watch('tournamentToSave.inviteAllUsers', function (switchIsOn) {
      if (!switchIsOn) {
        $scope.inviteAllUsers = false;
      }
    });

    $scope.openScoringDialog = function () {
      // Remove free hints for new challenge player
      $scope.tournamentToSave.scoringOptions.hints['L1'] = $scope.tournamentToSave.scoringOptions.hints['L1'].filter(
        (hintCost) => hintCost !== 0
      );
      $scope.tournamentToSave.scoringOptions.hints['L2'] = $scope.tournamentToSave.scoringOptions.hints['L2'].filter(
        (hintCost) => hintCost !== 0
      );
      updateWorkedExample($scope.tournamentToSave.scoringOptions);

      const uibModalInstance = $uibModal.open({
        templateUrl: 'tournaments/scoring/ScoringDetailsModal.html',
        // controller: "",
        size: 'lg',
        scope: _.merge(
          {
            $close: function () {
              uibModalInstance.close();
            },
          },
          $scope
        ),
      });
    };

    $scope.openInteractiveChallengeDialog = function () {
      const uibInteractiveModalInstance = $uibModal.open({
        templateUrl: 'tournaments/adversary/InteractiveChallengesModal.html',
        // controller: "",
        size: 'lg',
        scope: _.merge(
          {
            $close: function () {
              uibInteractiveModalInstance.close();
            },
          },
          $scope
        ),
      });
    };

    function updateWorkedExample(scoringOptions) {
      $scope.workedExample = {};
      $scope.workedExample.playerStart = 1000;
      $scope.workedExample.base = scoringOptions.basic.medium;
      $scope.workedExample.attempt1PenaltyPoints = $scope.workedExample.base * scoringOptions.attempts[0].incorrect;
      $scope.workedExample.attempt1PenaltyPercent = scoringOptions.attempts[0].incorrect * 100;

      $scope.workedExample.playerStage1Attempt1 =
        $scope.workedExample.playerStart + $scope.workedExample.attempt1PenaltyPoints;

      $scope.workedExample.attempt2PenaltyPoints = $scope.workedExample.base * scoringOptions.attempts[1].incorrect;
      $scope.workedExample.attempt2PenaltyPercent = scoringOptions.attempts[1].incorrect * 100;
      $scope.workedExample.locateHint1PenaltyPercent = scoringOptions.hints.L2[0] * 100;
      $scope.workedExample.locateHint2PenaltyPercent = scoringOptions.hints.L2[1] * 100;
      $scope.workedExample.attempt2CorrectPercent = scoringOptions.attempts[1].correct * 100;
      $scope.workedExample.attempt2CorrectPoints = $scope.workedExample.base * scoringOptions.attempts[1].correct;

      $scope.workedExample.locateHint1PenaltyPoints =
        $scope.workedExample.base * scoringOptions.attempts[1].correct * scoringOptions.hints.L2[0];
      $scope.workedExample.locateHint2PenaltyPoints =
        $scope.workedExample.base * scoringOptions.attempts[1].correct * scoringOptions.hints.L2[1];
      $scope.workedExample.locateTotalPoints =
        $scope.workedExample.attempt2CorrectPoints +
        $scope.workedExample.locateHint1PenaltyPoints +
        $scope.workedExample.locateHint2PenaltyPoints;

      $scope.workedExample.playerStage1Attempt2 =
        $scope.workedExample.playerStage1Attempt1 + $scope.workedExample.locateTotalPoints;
      $scope.workedExample.playerStage2Attempt1 =
        $scope.workedExample.playerStage1Attempt2 + $scope.workedExample.attempt1PenaltyPoints;
      $scope.workedExample.playerStage2Attempt2 =
        $scope.workedExample.playerStage2Attempt1 + $scope.workedExample.attempt2PenaltyPoints;
      $scope.workedExample.playerStage2Failed = $scope.workedExample.playerStage2Attempt2;

      $scope.workedExample.pickHint1PenaltyPercent = scoringOptions.hints.L3[0] * 100;

      $scope.workedExample.hasAttempt3 = !!scoringOptions.attempts[2];
      $scope.workedExample.maxAttempts = scoringOptions.attempts.length;
      if (scoringOptions.attempts[2]) {
        $scope.workedExample.attempt3CorrectPercent = scoringOptions.attempts[2].correct * 100;
        $scope.workedExample.attempt3CorrectPoints = $scope.workedExample.base * scoringOptions.attempts[2].correct;
        $scope.workedExample.pickHint1PenaltyPoints = Math.round(
          $scope.workedExample.base * scoringOptions.attempts[2].correct * scoringOptions.hints.L3[0]
        );
        $scope.workedExample.pickTotalPoints = Math.round(
          $scope.workedExample.attempt3CorrectPoints + $scope.workedExample.pickHint1PenaltyPoints
        );

        $scope.workedExample.playerStage2Attempt3 = Math.round(
          $scope.workedExample.playerStage2Attempt2 + $scope.workedExample.pickTotalPoints
        );
      }

      $scope.workedInteractiveExample = {};
      $scope.workedInteractiveExample.base = scoringOptions.interactive.hard;
      $scope.workedInteractiveExample.attempt1PenaltyPoints =
        $scope.workedInteractiveExample.base * scoringOptions.hintWeight.small * -1;
      $scope.workedInteractiveExample.attempt1PenaltyPercent = scoringOptions.hintWeight.small * -100;
      $scope.workedInteractiveExample.attempt2PenaltyPoints =
        $scope.workedInteractiveExample.base * scoringOptions.hintWeight.medium * -1;
      $scope.workedInteractiveExample.attempt2PenaltyPercent = scoringOptions.hintWeight.medium * -100;
      $scope.workedInteractiveExample.correctlyAnswerStage = $scope.workedInteractiveExample.base;
      $scope.workedInteractiveExample.correctlyAnswerPercent = 100;
      $scope.workedInteractiveExample.totalPoints = Math.round(
        $scope.workedInteractiveExample.base +
          $scope.workedInteractiveExample.attempt1PenaltyPoints +
          $scope.workedInteractiveExample.attempt2PenaltyPoints
      );
    }

    /***** START pagination, searching and sorting *****/

    $scope.potentialAdminsPage = {
      options: { page: 1, size: 10 },
    };

    function resetPotentialAdminsList() {
      $scope.potentialAdminList = [];

      $scope.potentialAdminsPage = {
        options: { page: 1, size: 10 },
      };
    }

    $scope.fetchPotentialAdmins = async function (filter, $event, firstLoad) {
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      } else if (firstLoad || filter) {
        // (no event) and (firstLoad or filter) string means first load or new search
        resetPotentialAdminsList();
      } else if (!filter) {
        // not firstLoad and no filter means first dropdown open or filter was cleared
        return;
      }

      if ($scope.potentialAdminsPage.options.page >= $scope.potentialAdminsPage.options.pages) {
        // no more to fetch
        return;
      }

      if ($event) {
        $scope.potentialAdminsPage.options.page++;
      }

      $scope.potentialAdminsLoading = true;

      // get potential admins list
      const query = {
        roles: [USER_ROLES.companyAdmin, USER_ROLES.manager],
        status: [$scope.metadata.constants.user.status.ENABLED, $scope.metadata.constants.user.status.REGISTERED],
      };

      if (AuthService.isAuthorized(USER_ROLES.admin)) {
        // If the company has been selected set appropriate values to ensure the search happens
        if ($scope.companySelectedBySCWAdmin?._id) {
          query['properties._cid'] = $scope.companySelectedBySCWAdmin._id;
          tts._company = $scope.companySelectedBySCWAdmin._id;
        } else {
          // If no company set, search among other scw admins only who can be added as administrators
          query['roles'] = [USER_ROLES.admin];
          tts._company = null; // set the empty company for the scw admin
        }
      }

      if (AuthService.isAuthorized(USER_ROLES.companyAdmin)) {
        query['properties._cid'] = Session.user.properties._cid;
      } else if (AuthService.isAuthorized(USER_ROLES.manager)) {
        query['properties._tid'] = Session.user.properties._tid;
      }

      AdminApiService.addLoading();
      try {
        const data = await AdminApiService.getUsers(query, filter, $scope.potentialAdminsPage.options, 'name');
        $scope.parseUserAdmins(data);
      } catch (response) {
        ErrorHandler.addHttpError($translate.instant('ERROR_LOADING_USER_LIST'), response);
      }
      AdminApiService.removeLoading();
      $scope.potentialAdminsLoading = false;
    };

    $scope.parseUserAdmins = function (data) {
      $scope.potentialAdminsPage.options = data;

      // filter out items that have already been selected since duplicates are not supported and result in error
      for (let i = 0; i < data.data.length; i++) {
        const user = data.data[i];
        const userIndex = tts.admins.some(function (obj) {
          return obj.email == user.email;
        });
        if (userIndex) {
          data.data.splice(i, 1);
          i--; // since we are removing the current element, move the index back one
        }
        user.displayName = $scope.getDisplayName(user);
      }

      $scope.potentialAdminList = $scope.potentialAdminList.concat(data.data);
    };

    $scope.getDisplayName = function (user) {
      const firstName = user?.properties?.profile?.name?.first;
      if (firstName) {
        return firstName + ' ' + user.properties.profile.name.last;
      }
      return user.email;
    };

    $scope.searchText = {
      participant: '',
    };

    $scope.sorting = {
      participants: {
        sortBy: 'name',
        sortByParam: 'name',
        sortReverse: false,
      },
    };

    $scope.setSort = function (sortBy, sortTarget) {
      if ($scope.sorting[sortTarget].sortBy == sortBy) {
        $scope.sorting[sortTarget].sortReverse = !$scope.sorting[sortTarget].sortReverse;
        $scope.sorting[sortTarget].sortByParam = ($scope.sorting[sortTarget].sortReverse ? '-' : '') + sortBy;
      } else {
        $scope.sorting[sortTarget].sortBy = sortBy;
        $scope.sorting[sortTarget].sortByParam = sortBy;
        $scope.sorting[sortTarget].sortReverse = false;
      }
      getParticipants($scope.tournament);
    };

    $scope.participantsPage = {
      filter: '',
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.participantsPage.options.page = 1;
        $scope.participantsPage.options.size = newVal;
        getParticipants($scope.tournament);
      }
    });

    $scope.participantsPagerChanged = function () {
      getParticipants($scope.tournament);
    };

    $scope.newParticipantSearch = function () {
      $scope.participantsPage.options.page = 1;
      getParticipants($scope.tournament);
    };

    /***** END pagination, searching and sorting *****/
    const defaultEmptyTournament = {
      name: null,
      description: null,
      sharedSecret: '',
      displayPic: '',
      startTime: moment(moment().add(1, 'days').format('YYYY-MM-DD')).toDate(),
      finishTime: moment(moment().add(2, 'days').format('YYYY-MM-DD')).toDate(),
      rulesText: '',
      maxPlayers: null,
      languages: [],
      admins: [],
      leaderboardOptions: {
        options: {
          isAnonymous: false,
          isDisplayed: true,
          hideBeforeEnd: 0,
        },
        fields: {
          rank: true,
          avatar: true,
          name: true,
          points: true,
          accuracy: true,
          timeSpent: true,
          joinDate: true,
        },
      },
      suggestInduction: false, // default value should be false
      scoringPreset: $scope.scoringPresets[1]._id,
      scoringOptions: $scope.scoringPresets[1].scoringOptions, // "Default" preset
      numberOfChallenges: $scope.maxDuration,
      inviteAllUsers: $scope.inviteAllUsers,
    };
    // Maximum minutes you can hind leaderboard before end
    $scope.toggle = function (what) {
      _.set($scope, what, !_.get($scope, what));
    };

    $scope.toggleShowJoinPassword = function () {
      $scope.isShowingJoinPassword = !$scope.isShowingJoinPassword;
    };

    $scope.togglePublishedState = function () {
      $scope.tournament.published = !$scope.tournament.published;
      // TODO save it
    };

    $scope.$watch('tournamentToSave.sharedSecret', function (n, _o) {
      if (n?.toLowerCase() === 'autogenerate') {
        $scope.autogenerateJoinCode();
      }
    });

    $scope.autogenerateJoinCode = function () {
      const joinCode = Math.random().toString().substr(2, 8);
      tts.sharedSecret = joinCode.substr(0, 4) + ' ' + joinCode.substr(4, 4);
    };

    $scope.toggleInviteAllUsers = function () {
      // If we're turning the toggle off, just do it without confirmation
      if (!$scope.tournamentToSave.inviteAllUsers) {
        return;
      }

      const numberOfUsersQuery = {
        status: [$scope.metadata.constants.user.status.ENABLED, $scope.metadata.constants.user.status.REGISTERED],
        'properties._cid': Session.user.properties._cid,
      };

      const filter = null;

      // We only need one record to get the total count
      const pageOptions = {
        page: 1,
        size: 1,
      };

      AdminApiService.getUsers(numberOfUsersQuery, filter, pageOptions)
        .then(function (data) {
          const totalUserCount = data.total;

          $translate(
            [
              'TOURNAMENT_CONFIRM_INVITE_ALL_USERS',
              'TOURNAMENT_INVITE_ALL_USERS_MESSAGE',
              'TOURNAMENT_YES_INVITE_ALL',
              'CANCEL',
            ],
            { userCount: totalUserCount }
          ).then(function (translations) {
            swal(
              {
                title: translations.TOURNAMENT_CONFIRM_INVITE_ALL_USERS,
                text: translations.TOURNAMENT_INVITE_ALL_USERS_MESSAGE,
                type: 'warning',
                html: true,
                showCancelButton: true,
                confirmButtonColor: 'var(--color-scw-red)',
                confirmButtonText: translations.TOURNAMENT_YES_INVITE_ALL,
                cancelButtonText: translations.CANCEL,
              },
              function (isConfirm) {
                $scope.$apply(function () {
                  if (!isConfirm) {
                    $scope.tournamentToSave.inviteAllUsers = false;
                  }
                });
              }
            );
          });
        })
        .catch(function (error) {
          console.error('Error fetching user count:', error);
        });
    };

    function getParticipants(tournamentObj) {
      return TournamentsApiService.getParticipants(
        tournamentObj._id,
        $scope.searchText.participant,
        $scope.participantsPage.options,
        $scope.sorting.participants.sortByParam
      ).then(function (participants) {
        $scope.participantsPage.options = participants;

        for (let i = 0; i < participants.data.length; i++) {
          const participant = participants.data[i];
          $scope.processParticipant(participant);
        }

        $scope.tournamentParticipants = participants.data;

        //If there are participants, disable the button as it cant be toggled to recreate quests
        if ($scope.tournamentParticipants && $scope.tournamentParticipants.length > 0) {
          if ($scope.tournament.enableBonusLevelCheck && !$scope.tournament.numberOfInteractiveChallenges) {
            $('#enableBonusLevelCheck').bootstrapSwitch('state', false, true);
            $('#enableBonusLevelCheck').bootstrapSwitch('disabled', true, true);
          }
          $scope.enableInteractiveChallengeButton = false;
        }
      });
    }

    function getCompanyData() {
      // Return an empty promise where we already have company or team data
      // or where we already have the companySelectedBySCWAdmin
      // or where we don't have a companyId and its a new tournament, therefore will be
      // impossible to retrieve company info
      if (
        Session.user.properties.company ||
        Session.user.properties.team ||
        _.get($scope, 'companySelectedBySCWAdmin.company') ||
        (!$stateParams.companyId && $state.current.name == 'tournaments.add')
      ) {
        return $q.when();
      }

      // companyId has been passed through, we just need the company object
      if ($stateParams.companyId) {
        $log.debug('getCompanyData - companyId in params, getting company');
        return AdminApiService.getCompany($stateParams.companyId)
          .then(function (data) {
            $scope.companySelectedBySCWAdmin.company = data;
            $scope.companySelectedBySCWAdmin._id = data._id;
          })
          .catch(function (err) {
            $log.debug(err);
          });
      }

      // No company Id, we need to get it from the tournament object and then use that
      // to get the company object
      $log.debug('getCompanyData - No companyId, getting tournament._company and company');
      return TournamentsApiService.getTournament($stateParams.id).then(function (tournament) {
        // Check needed as public tournaments would not have a company
        if (tournament?._company) {
          return AdminApiService.getCompany(tournament._company)
            .then(function (data) {
              $scope.companySelectedBySCWAdmin.company = data;
              $scope.companySelectedBySCWAdmin._id = data._id;
            })
            .catch(function (err) {
              $log.debug(err);
            });
        }
      });
    }

    function getTournamentData() {
      return TournamentsApiService.getTournament($stateParams.id)
        .then(function (data) {
          $scope.processTournamentData(data);
          $scope.tournament = data;

          addInteractiveScoringData();
          //Value for the scoringOptions
          if (_.isEmpty(_.get($scope.tournament.scoringOptions, 'interactive', {}))) {
            _.assign($scope.tournament.scoringOptions, $scope.interactive);
          }

          /** If the tournament is edited/managed post creation never force re-calculation
           *  Quests would have been created and challenge count has been generated
           *  Since re-calculation has been disabled, interactive challenge would remain as 0
           *  So, admin would need to regenerate quests to use interactive challenges
           */

          if (!_.has($scope.tournament, 'enableBonusLevelCheck')) {
            $scope.enableInteractiveChallengeButton = true;
            $scope.tournament.enableBonusLevelCheck = false;
          }

          if (!_.has($scope.tournament, 'numberOfInteractiveChallenges')) {
            $scope.tournament.numberOfInteractiveChallenges = 0;
          }

          getParticipants($scope.tournament);
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_TOURNAMENT']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_TOURNAMENT, response);
            $state.go('tournaments.list');
          });
        });
    }

    function getQuestList() {
      return TournamentsApiService.listQuests($stateParams.id).then(function (data) {
        $scope.questList = JSON.stringify(data, null, 2);
      });
    }

    $scope.saveQuestList = function (questsToSave) {
      let quests;
      try {
        quests = JSON.parse(questsToSave);
      } catch (error) {
        swal('Invalid JSON.');
      }
      $rootScope.disableButton('saveCustomQuests');
      if (quests) {
        TournamentsApiService.saveQuests($stateParams.id, questsToSave)
          .then(function (_res) {
            swal('Successfully saved quests');
          })
          .catch(function (_error) {
            swal('Error saving quests');
          })
          .finally(function () {
            $rootScope.enableButton('saveCustomQuests');
          });
      }
    };

    $scope.viewLiveStatus = function () {
      $state.go('tournaments.live-status', { id: $stateParams.id });
    };

    $scope.goBack = function () {
      if ($scope.isShowingJoinPassword) {
        $scope.toggleShowJoinPassword();
      } else {
        $stateParams.tournamentId = $stateParams.tournamentId || $stateParams.id;
        $state.go('tournaments.list', $stateParams);
      }
    };

    $scope.clone = function () {
      $rootScope.disableButton('cloneTournament');
      TournamentsApiService.cloneTournament($stateParams.id)
        .then(function (data) {
          // TODO - potential optimisation available here - refactor getTournamentPromise.then() fn and call it here, instead of reloading state (and data since the clone API returns the new tournament object)
          $state.go('tournaments.edit', { id: data._id });
        })
        .catch(function (response) {
          $translate(['ERROR_CLONING_TOURNAMENT', 'TOURNAMENT_CLONE_ERROR_SIZE']).then(function (translations) {
            if (response.status === 400 && response.data.error === 'INVALID_SIZE') {
              ErrorHandler.addHttpError(translations.TOURNAMENT_CLONE_ERROR_SIZE);
            } else {
              ErrorHandler.addHttpError(translations.ERROR_CLONING_TOURNAMENT, response);
            }
          });
        })
        .finally(function () {
          $rootScope.enableButton('cloneTournament');
        });
    };

    $scope.deleteTournament = function () {
      $translate([
        'DELETE_TOURNAMENT_ALERT_TITLE',
        'DELETE_TOURNAMENT_ALERT_TEXT',
        'ERROR_DELETING_TOURNAMENT',
        'DELETE',
        'CANCEL',
      ]).then(function (translations) {
        swal(
          {
            title: translations.DELETE_TOURNAMENT_ALERT_TITLE,
            text: translations.DELETE_TOURNAMENT_ALERT_TEXT,
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.DELETE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              $rootScope.disableButton('deleteTournament');
              TournamentsApiService.deleteTournament($scope.tournament)
                .then(function (_data) {
                  $state.go('tournaments.list');
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.ERROR_DELETING_TOURNAMENT, response);
                })
                .finally(function () {
                  $rootScope.enableButton('deleteTournament');
                });
            }
          }
        );
      });
    };
    $scope.blockParticipant = function (participant, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      $log.debug('Blocking participant: ', participant);

      TournamentsApiService.blockUser($scope.tournament, participant).then(function () {
        getParticipants($scope.tournament);
      });
    };

    $scope.unblockParticipant = function (participant, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      $log.debug('Unblocking participant: ', participant);

      TournamentsApiService.unblockUser($scope.tournament, participant).then(function () {
        getParticipants($scope.tournament);
      });
    };

    $scope.removeParticipant = function (participant, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      $translate([
        'DELETE_PARTICIPANT_ALERT_TITLE',
        'DELETE_PARTICIPANT_ALERT_TEXT',
        'ERROR_DELETING_PARTICIPANT',
        'REMOVE',
        'CANCEL',
      ]).then(function (translations) {
        swal(
          {
            title: translations.DELETE_PARTICIPANT_ALERT_TITLE,
            text: translations.DELETE_PARTICIPANT_ALERT_TEXT,
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.REMOVE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              TournamentsApiService.deleteUser($scope.tournament, participant)
                .then(function () {
                  getParticipants($scope.tournament);
                })
                .catch(function (error) {
                  ErrorHandler.addHttpError(translations.ERROR_DELETING_PARTICIPANT, error);
                });
            }
          }
        );
      });
    };

    $scope.recalcParticipant = function (participant, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      $log.debug('Recalculating stats for participant: ', participant);

      TournamentsApiService.recalcUser($scope.tournament, participant).then(function () {
        getParticipants($scope.tournament);
      });
    };

    $scope.removeDisplayPic = function () {
      tts.displayPic = '';
      delete tts.displayPicFile;
    };

    $scope.startFinishTimeCheck = function () {
      // reset form
      $scope.forms.editTournamentForm.startTime.$setValidity('finishBeforeStart', true);
      $scope.forms.editTournamentForm.finishTime.$setValidity('finishBeforeStart', true);

      if (tts.finishTime <= tts.startTime) {
        $scope.forms.editTournamentForm.startTime.$setValidity('finishBeforeStart', false);
        $scope.forms.editTournamentForm.finishTime.$setValidity('finishBeforeStart', false);
      }

      $scope.forms.editTournamentForm.startTime.$dirty = true;
      $scope.forms.editTournamentForm.finishTime.$dirty = true;
    };

    /*
    $scope.$watch("tournamentToSave.timezone", function(newVal, oldVal) {
      if (newVal != oldVal) {
        var newStartDate = convertToUTC(tts.startTime, oldVal);
        newStartDate = convertToTimezone(newStartDate, newVal);
        tts.startTime = newStartDate;

        var newFinishDate = convertToUTC(tts.finishTime, oldVal);
        newFinishDate = convertToTimezone(newFinishDate, newVal);
        tts.finishTime = newFinishDate;
      }
    });
    */

    const { convertToTimezone, convertToUTC } = $rootScope.utility;

    $scope.saveOrPublish = function () {
      return $swal({
        title: $translate.instant('TOURNAMENTADD_SAVEORPUBLISH_TITLE'),
        templateUrl: templateUrl,
        buttons: {},
        confirmButtonText: $translate.instant('OK'),
      }).then(function (response) {
        $log.debug('[TournamentAdd] ng-submit option response', response);
        $swal.close();

        if (response === 'publish') return $scope.saveAndPublish();
        if (response === 'save') return $scope.saveTournament();
      });
    };

    $scope.saveAndPublish = function () {
      tts.published = true;
      $scope._saveTournament(true);
    };

    $scope.saveTournament = function () {
      $scope._saveTournament(false);
    };

    $scope._saveTournament = function (isSaveAndPublish) {
      $scope.startFinishTimeCheck();

      // multi UI select does not seem to respect 'required' attribute so set it here
      setLanguageSelectValidity(tts.languages);
      $scope.forms.editTournamentForm.languageFramework.$dirty = true;

      // check settings form and switch to it if invalid
      if ($scope.forms.editTournamentForm.$invalid) {
        $scope.forms.editTournamentForm.name.$dirty = true;
        $scope.forms.editTournamentForm.description.$dirty = true;
        $scope.forms.editTournamentForm.sharedSecret.$dirty = true;
        $scope.forms.editTournamentForm.startTimezone.$dirty = true;
        $scope.forms.editTournamentForm.startTime.$dirty = true;
        $scope.forms.editTournamentForm.finishTime.$dirty = true;

        if (isSaveAndPublish) {
          tts.published = false;
        }

        // $scope.tabs.activeIndex = $scope.TAB_NAMES.SETTINGS;
        return;
      }

      $scope.checkPromise.then(function () {
        if ($scope.questsUnavailable) {
          const translationKeys = [
            'ERROR_SAVING_TOURNAMENT',
            'TOURNAMENTADD_LANGUAGE_INSUFFICIENT_CHALLENGES',
            'TOURNAMENTADD_INSUFFICIENT_CHALLENGES_AVAILABLE2',
            'TOURNAMENTADD_OR_CONTACT_SUPPORT',
            'TOURNAMENTADD_INSUFFICIENT_CHALLENGES_AVAILABLE',
            'TOURNAMENTADD_REMOVE_LANGUAGE_SUGGESTION',
            'TOURNAMENTADD_REMOVE_LANGUAGE_NOTIFY',
            'OK',
          ];
          // questsData.error.code == ERRORS.SIZE
          // tournamentToSave.languages.length == 1

          $translate(translationKeys).then(function (translated) {
            let title, message, message2;
            title = 'ERROR_SAVING_TOURNAMENT';
            if (
              _.get($scope, 'questsData.error.code', undefined) == $scope.ERRORS.SIZE ||
              $scope.appTypesDifficultyLevelMismatch(
                $scope.questsData.difficultyDistribution,
                $scope.questsData.appTypes
              )
            ) {
              message =
                tts.languages.length === 1
                  ? 'TOURNAMENTADD_LANGUAGE_INSUFFICIENT_CHALLENGES'
                  : 'TOURNAMENTADD_INSUFFICIENT_CHALLENGES_AVAILABLE2';
              message2 = 'TOURNAMENTADD_OR_CONTACT_SUPPORT';
            } else {
              message = 'TOURNAMENTADD_INSUFFICIENT_CHALLENGES_AVAILABLE';
              if ($scope.questsData.error.suggestRemovingLanguage) {
                message2 = 'TOURNAMENTADD_REMOVE_LANGUAGE_SUGGESTION';
              } else {
                message2 = 'TOURNAMENTADD_REMOVE_LANGUAGE_NOTIFY';
              }
            }

            swal({
              title: translated[title],
              text: translated[message] + ' ' + translated[message2],
              type: 'error',
              html: true,
              confirmButtonText: translated['OK'],
            });
          });

          if (isSaveAndPublish) {
            tts.published = false;
          }

          return;
        }

        if (tts.displayPicFile) {
          const image = new Image();
          image.onload = function () {
            let { width, height } = image;
            const maxWidth = 390;
            const maxHeight = 390;

            const scale = Math.max(1, height / maxHeight, width / maxWidth);
            width /= scale;
            height /= scale;

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            URL.revokeObjectURL(image.src);

            const result = canvas.toDataURL('image/png');
            tts.displayPic = result;
            delete tts.displayPicFile;
            doSave(isSaveAndPublish);
          };
          image.onerror = function () {
            $translate(['NOT_AN_IMAGE']).then(function (translations) {
              ErrorHandler.addError(translations.NOT_AN_IMAGE);
            });
          };
          image.src = tts.displayPicFile.$ngfBlobUrl;
        } else {
          doSave(isSaveAndPublish);
        }
      });
    };

    function doSave(isSaveAndPublish) {
      let saveFunc;
      if ($state.current.name == 'tournaments.edit') {
        saveFunc = TournamentsApiService.updateTournament;
      } else {
        saveFunc = TournamentsApiService.createTournament;
      }

      const saveMe = _.cloneDeep(tts);
      $scope.removeTournamentDisplayData(saveMe);
      // Remove name property from languages
      saveMe.languages = saveMe.languages.map(function (language) {
        return _.omit(language, ['name']);
      });

      saveMe.startTime = convertToTimezone(
        convertToUTC(saveMe.startTime, moment.tz.guess()),
        saveMe.timezone
      ).valueOf();

      saveMe.finishTime = convertToTimezone(
        convertToUTC(saveMe.finishTime, moment.tz.guess()),
        saveMe.timezone
      ).valueOf();

      // displayed in minutes, data is saved as ms
      const hideBeforeEnd = saveMe?.leaderboardOptions?.options?.hideBeforeEnd;
      if (hideBeforeEnd) {
        saveMe.leaderboardOptions.options.hideBeforeEnd *= 60 * 1000;
      }

      // If the rules text is hidden, we must clear it
      if (!$scope.forms.customRuleSwitch) {
        saveMe.rulesText = '';
      }

      $rootScope.disableButton('saveTournament');
      saveFunc(saveMe)
        .then(function () {
          $state.go('tournaments.list', { tournamentId: $stateParams.id });
        })
        .catch(function (response) {
          $translate([
            'TOURNAMENT_START_TIME_CANNOT_BE_IN_THE_PAST',
            'ERROR_SAVING_TOURNAMENT',
            'LICENSE_RESTRICTIONS_CANNOT_CREATE_TOURNAMENTS',
          ]).then(function (translations) {
            if (
              response.status === 400 &&
              response.data &&
              response.data.details &&
              response.data.details.validationErrors &&
              response.data.details.validationErrors[0] &&
              response.data.details.validationErrors[0].stack === 'instance.startTime cannot be in the past'
            ) {
              ErrorHandler.addError(translations.TOURNAMENT_START_TIME_CANNOT_BE_IN_THE_PAST);
            } else if (
              response.data &&
              response.data.error &&
              response.data.error === 'Cannot publish tournament. Please contact your account manager for assistance.'
            ) {
              $scope.tabs.activeIndex = $scope.TAB_NAMES.LANGUAGES;
              ErrorHandler.addHttpError(translations.ERROR_SAVING_TOURNAMENT, response);
            } else if (
              response.data &&
              response.data.error &&
              (response.data.error === 'LICENSE_BREACH' || response.data.error === 'Forbidden')
            ) {
              ErrorHandler.addError(translations.LICENSE_RESTRICTIONS_CANNOT_CREATE_TOURNAMENTS);
            } else {
              ErrorHandler.addHttpError(translations.ERROR_SAVING_TOURNAMENT, response);
            }

            if (isSaveAndPublish) {
              tts.published = false;
            }
          });
        })
        .finally(function () {
          $rootScope.enableButton('saveTournament');
        });
    }

    $scope.preview = function () {
      $scope.hasPreviewed = true;
      $scope.tabs.activeIndex = $scope.TAB_NAMES.LANGUAGES;
    };
    $scope.tuneDifficultyDistribution = function (difficultyDistribution, appTypes) {
      // Very large values to start off
      const min = [99999, 99999, 99999];

      appTypes.forEach(function (appType) {
        [0, 1, 2].forEach(function (difficulty) {
          if (difficultyDistribution[appType][difficulty] < min[difficulty]) {
            min[difficulty] = difficultyDistribution[appType][difficulty];
          }
        });
      });

      appTypes.forEach(function (appType) {
        [0, 1, 2].forEach(function (difficulty) {
          if (difficultyDistribution[appType][difficulty] > min[difficulty]) {
            difficultyDistribution[appType][difficulty] = min[difficulty];
          }
        });
      });
    };

    $scope.appTypesDifficultyLevelMismatch = function (difficultyDistribution, appTypes) {
      let mismatch = false;

      if (!appTypes) {
        return mismatch;
      }

      appTypes.forEach(function (appTypeLHS) {
        appTypes.forEach(function (appTypeRHS) {
          if (
            difficultyDistribution[appTypeLHS][0] !== difficultyDistribution[appTypeRHS][0] ||
            difficultyDistribution[appTypeLHS][1] !== difficultyDistribution[appTypeRHS][1] ||
            difficultyDistribution[appTypeLHS][2] !== difficultyDistribution[appTypeRHS][2]
          ) {
            mismatch = true;
          }
        });
      });

      if (mismatch) {
        const mismatchTracker = {};

        appTypes.forEach(function (appType) {
          mismatchTracker[appType] = [];
          mismatchTracker[appType].push({
            0: difficultyDistribution[appType][0],
            1: difficultyDistribution[appType][1],
            2: difficultyDistribution[appType][2],
          });
        });

        $log.log('Mismatch Tracker: ', JSON.stringify(mismatchTracker));
      }

      return mismatch;
    };

    /**
     * Broken out into a function to facilitate unit testing
     */
    $scope.determineInteractiveChallengeButtonBehaviour = function (
      tournamentToSave,
      questLevelEnabled,
      recalculateQuests = false
    ) {
      /**
       * If no players have registered yet, allow user to change configurations. Once player
       * registers, take the state of the check from db and disable the button.
       */
      if (!tournamentToSave.numPlayers) {
        /**
         * If the combination of languages doesn't have any bonus challenges, they will always be disabled
         * and switched off as it does not matter if the user enables or disables the check, the
         * number of challenges will always be 0.
         */
        if (!questLevelEnabled) {
          $('#enableBonusLevelCheck').bootstrapSwitch('state', false, true);
          $('#enableBonusLevelCheck').bootstrapSwitch('disabled', true, true);
          $scope.enableInteractiveChallengeButton = false; //disable the button
        } else {
          /**
           * If there are some challenges but the enable check has been switched off, keep the
           * button enabled but the switch is moved to off. No recalculation needed.
           * If there are some challenges and the enable check is switched on, enable the button.
           * Force recalculation only if previously the button was disabled.
           */
          if (!tournamentToSave.enableBonusLevelCheck) {
            $('#enableBonusLevelCheck').bootstrapSwitch('state', false, true);
            $('#enableBonusLevelCheck').bootstrapSwitch('disabled', false, true);
            $scope.enableInteractiveChallengeButton = true; //keep the button enabled
          } else if (tournamentToSave.enableBonusLevelCheck && !$scope.enableInteractiveChallengeButton) {
            $scope.enableInteractiveChallengeButton = true; //keep the button enabled
            $('#enableBonusLevelCheck').bootstrapSwitch('disabled', false, true);
            $('#enableBonusLevelCheck').bootstrapSwitch('state', true, true);
            if (recalculateQuests) {
              $scope.checkLanguageQuests(false, true);
            }
          }
        }
      }
    };

    /*** languages and quests ***/
    $scope.checkLanguageQuests = function (_isUpdatingDuration, useStatsQuestsList) {
      useStatsQuestsList = useStatsQuestsList || false;
      $scope.questsCheckingInProgress = true;
      $scope.questsUnavailable = false;

      $scope.displayDurationText = moment.duration(3 * tts.numberOfChallenges, 'minutes').humanize();

      if (!tts.languages.length) {
        return $q.when(true).finally(function () {
          $scope.questsCheckingInProgress = false;
        });
      }

      let ttsLanguages = _.cloneDeep(tts.languages);
      // Remove 'name' property from ttsLanguages and not $scope.tournamentToSave
      ttsLanguages = ttsLanguages.map(function (language) {
        return _.omit(language, ['name']);
      });

      const appTypeVersion = getAppTypeVersion($state.current.name, $scope.tournament);

      return TournamentsApiService.checkLanguageQuests(
        '123',
        ttsLanguages,
        tts.numberOfChallenges,
        !!tts.enableBonusLevelCheck && $scope.enableInteractiveChallengeButton,
        appTypeVersion,
        tts.scoringPreset
      )
        .then(function (data) {
          let listQuestsPromise;
          if ($state.current.name == 'tournaments.edit' && !useStatsQuestsList) {
            listQuestsPromise = TournamentsApiService.listQuests($stateParams.id);
          } else {
            listQuestsPromise = $q.when();
          }

          listQuestsPromise.then(function (listQuestsData) {
            $scope.questsData = data;

            //So that they come in the preview
            if (Object.keys(data.bonusQuest).length > 0) {
              $scope.questsData.quests.push(data.bonusQuest);
            }

            if (listQuestsData) {
              $scope.questsData.quests = listQuestsData;
            }

            if ($scope.questsData.error) {
              $scope.questsUnavailable = true;

              if (
                $scope.questsData.error.code == $scope.ERRORS.SIZE &&
                tts.numberOfChallenges >= $scope.minDuration &&
                $scope.questsData.error.suggestSize >= $scope.minDuration
              ) {
                tts.numberOfChallenges = $scope.maxDuration = $scope.questsData.error.suggestSize;

                if ($scope.tabs.activeIndex == $scope.TAB_NAMES.LANGUAGES) {
                  $scope.checkPromise.then(function () {
                    updateDurationSliderValue(tts.numberOfChallenges);
                  });
                }
              }
            } else {
              $scope.questsUnavailable = false;

              if ($scope.questsData.usableChallenges.usable < $scope.minDuration) {
                $scope.questsUnavailable = true;
                return;
              }

              $scope.maxDuration =
                $scope.questsData.usableChallenges.usable > ABS_MAX_DURATION
                  ? ABS_MAX_DURATION
                  : $scope.questsData.usableChallenges.usable;

              /** Stats call has been triggered and based on that button actions need to be decided
               *  If the bonus level disabled, disable the button else if previously disabled enable it
               *  On re-enabling retrigger the calculation but do not update number of interactive challenges on tts
               *  If a quest recalculation has been done, then update these values.
               */
              if (useStatsQuestsList) {
                $scope.tournamentToSave.numberOfInteractiveChallenges =
                  $scope.questsData.usableChallenges.bonusLevelChallenges;
              }

              $scope.determineInteractiveChallengeButtonBehaviour(
                $scope.tournamentToSave,
                $scope.questsData.usableChallenges.bonusLevelEnabled,
                true
              );

              if ($scope.tabs.activeIndex == $scope.TAB_NAMES.LANGUAGES) {
                $scope.checkPromise.then(function () {
                  updateDurationSliderRange($scope.minDuration, $scope.maxDuration);
                });
              }

              const EASY = 0;
              const MEDIUM = 1;
              const HARD = 2;

              // chart settings
              $scope.difficultyDistributionLabels = {};
              $scope.questsData.totalChallenges = {};

              $scope.questsData.difficultyDistribution = {};
              $scope.questsData.categoryDistribution = {};
              $scope.questsData.categoryDistributionLabels = {};
              $scope.questsData.categoryDistributionColours = {};
              $scope.questsData.subcategoryDistribution = {};
              $scope.questsData.subcategoryDistributionLabels = {};
              $scope.questsData.subcategoryDistributionColours = {};

              $scope.questsData.categoryDistribution2 = {};
              $scope.questsData.subcategoryMap = {};

              $scope.questsData.categoryMap = {};

              $scope.questsData.appTypes.forEach(function (appType) {
                $scope.questsData.totalChallenges[appType] = 0;
                // chart settings
                $scope.difficultyDistributionLabels[appType] = ['Easy', 'Medium', 'Hard'];

                $scope.questsData.difficultyDistribution[appType] = [0, 0, 0];
                $scope.questsData.categoryDistribution[appType] = [[]];
                $scope.questsData.categoryDistributionLabels[appType] = [];
                $scope.questsData.categoryDistributionColours[appType] = [];
                $scope.questsData.subcategoryDistribution[appType] = [[]];
                $scope.questsData.subcategoryDistributionLabels[appType] = [];
                $scope.questsData.subcategoryDistributionColours[appType] = [];

                $scope.questsData.categoryDistribution2[appType] = [];
                $scope.questsData.subcategoryMap[appType] = {};

                $scope.questsData.categoryMap[appType] = {};
              });

              let i, j, k, quest, challenge;
              $scope.questsData.appTypes.forEach(function (appType) {
                // If a particular appType doesn't exist, continue (return in forEach)

                for (i = 0; i < $scope.questsData.quests.length; i++) {
                  quest = $scope.questsData.quests[i];

                  if (!quest.challenges[appType]) continue;

                  $scope.questsData.totalChallenges[appType] += quest.challenges[appType].length;

                  for (j = 0; j < quest.challenges[appType].length; j++) {
                    challenge = quest.challenges[appType][j];

                    // get category and subcategory text from filter
                    let category;
                    if (challenge.filter.categories && challenge.filter.categories[0]) {
                      category = challenge.filter.categories[0];
                    } else {
                      category = {};
                    }

                    const metadataAppType = appType === 'mobile' || appType === 'mobile_1' ? 'mobile' : 'web';
                    if (category._id) {
                      challenge.categoryText = $rootScope.metadata.categories[metadataAppType][category._id].name;
                    }
                    if (category._sub) {
                      challenge.subcategoryText =
                        $rootScope.metadata.categories[metadataAppType][category._id].subitem[category._sub].name;
                    } else {
                      challenge.subcategoryText = challenge.categoryText;
                    }

                    // build categories distribution
                    const categoryIndex = $scope.questsData.categoryDistributionLabels[appType].indexOf(
                      challenge.categoryText
                    );
                    if (categoryIndex < 0) {
                      // not in list so far
                      $scope.questsData.categoryDistributionLabels[appType].unshift(challenge.categoryText);
                      $scope.questsData.categoryDistribution[appType][0].unshift(1);
                      $scope.questsData.categoryDistributionColours[appType].unshift('rgba(255,255,255,0.8)');
                    } else {
                      // found in list
                      $scope.questsData.categoryDistribution[appType][0][categoryIndex]++;
                    }

                    /*
                  if ($scope.questsData.level == "subcategory") {
                    // build subcategories distribution
                    var fulltext = challenge.subcategoryText == "Other" ? challenge.categoryText + " - " + challenge.subcategoryText : challenge.subcategoryText;
                    var subcategoryIndex = $scope.questsData.subcategoryDistributionLabels.indexOf(fulltext);
                    if (subcategoryIndex < 0) {
                      // not in list so far
                      $scope.questsData.subcategoryDistributionLabels.unshift(fulltext);
                      $scope.questsData.subcategoryDistribution[0].unshift(1);
                      $scope.questsData.subcategoryDistributionColours.unshift({
                        strokeColor: "rgba(255,255,255,0.2)",
                        fillColor: "rgba(255,255,255,0.2)",
                      });
                    }
                    else {
                      // found in list
                      $scope.questsData.subcategoryDistribution[0][subcategoryIndex]++;
                    }
                  }
                  */

                    // nvd3 chart
                    if ($scope.questsData.level == 'subcategory') {
                      // if subcategories are used we simply collect a list of all categories for use later to build the required (annoying) data structure
                      if (!$scope.questsData.categoryMap[appType][challenge.categoryText]) {
                        $scope.questsData.categoryMap[appType][challenge.categoryText] = {
                          label: challenge.categoryText,
                          value: 0,
                        };
                      }
                    }

                    // get difficulty text from filter
                    challenge.difficulty = challenge.filter.difficulties[0];
                    if (challenge.difficulty == 'easy') {
                      $scope.questsData.difficultyDistribution[appType][EASY]++;
                    } else if (challenge.difficulty == 'medium') {
                      $scope.questsData.difficultyDistribution[appType][MEDIUM]++;
                    } else if (challenge.difficulty == 'hard') {
                      $scope.questsData.difficultyDistribution[appType][HARD]++;
                    }
                  }
                }

                // if category level then sort the categories for the chart in descending order
                if ($scope.questsData.level == 'category') {
                  // combine crappy chartjs data structure
                  const tempCategoryDistribution = [];
                  for (i = 0; i < $scope.questsData.categoryDistributionLabels[appType].length; i++) {
                    tempCategoryDistribution.push({
                      label: $scope.questsData.categoryDistributionLabels[appType][i],
                      value: $scope.questsData.categoryDistribution[appType][0][i],
                      colour: $scope.questsData.categoryDistributionColours[appType][i],
                    });
                  }

                  // sort category counts
                  tempCategoryDistribution.sort(function (a, b) {
                    if (a.value < b.value) {
                      return -1;
                    } else if (a.value > b.value) {
                      return 1;
                    } else {
                      return a.label > b.label ? -1 : 1;
                    }
                  });

                  // split back into crappy chartjs data structure
                  $scope.questsData.categoryDistributionLabels[appType] = [];
                  $scope.questsData.categoryDistribution[appType][0] = [];
                  $scope.questsData.categoryDistributionColours[appType] = [];
                  for (i = 0; i < tempCategoryDistribution.length; i++) {
                    const tempCategory = tempCategoryDistribution[i];
                    $scope.questsData.categoryDistributionLabels[appType].push(tempCategory.label);
                    $scope.questsData.categoryDistribution[appType][0].push(tempCategory.value);
                    $scope.questsData.categoryDistributionColours[appType].push(tempCategory.colour);
                  }
                }

                // building data structure for nvd3 stacked bar chart
                const keys = Object.keys($scope.questsData.categoryMap[appType]).sort();
                const categoryValuesBaseArray = [];

                keys.forEach((key) => categoryValuesBaseArray.push($scope.questsData.categoryMap[appType][key]));

                if ($scope.questsData.level == 'subcategory') {
                  // if subcategories are used now we build the count of subcategories, but it requires the full list of all categories due to the data structure used by nvd3 (it's "sliced" the opposite way for our purposes so we need to work around it)
                  $scope.questsData.subcategoryMap[appType] = {};

                  let colourIndex = 0;

                  for (i = 0; i < $scope.questsData.quests.length; i++) {
                    quest = $scope.questsData.quests[i];

                    for (j = 0; j < quest.challenges[appType].length; j++) {
                      challenge = quest.challenges[appType][j];

                      if (!$scope.questsData.subcategoryMap[appType][challenge.subcategoryText]) {
                        $scope.questsData.subcategoryMap[appType][challenge.subcategoryText] = {
                          key: challenge.subcategoryText,
                          color: BAR_COLOURS[colourIndex % BAR_COLOURS.length],
                          values: _.cloneDeep(categoryValuesBaseArray),
                        };
                      }

                      const current = $scope.questsData.subcategoryMap[appType][challenge.subcategoryText];
                      for (k = 0; k < current.values.length; k++) {
                        const currentCategory = current.values[k];
                        if (currentCategory.label == challenge.categoryText) {
                          currentCategory.value++;
                          break;
                        }
                      }
                      colourIndex++;
                    }
                  }

                  const subcategoryKeys = Object.keys($scope.questsData.subcategoryMap[appType]).sort();
                  for (i = 0; i < subcategoryKeys.length; i++) {
                    const subcategoryKey = subcategoryKeys[i];
                    $scope.questsData.categoryDistribution2[appType].push(
                      $scope.questsData.subcategoryMap[appType][subcategoryKey]
                    );
                  }

                  // PORTAL-4073: Quickfix to make the charts always adapt the larger value instead of the latest value.
                  if (
                    !$scope.stackedBarOptions.chart.height ||
                    $scope.stackedBarOptions.chart.height < 45 * keys.length
                  ) {
                    $scope.stackedBarOptions.chart.height = 45 * keys.length;
                  }
                }
              });

              // For mix appType Tournaments, check difficulty distribution to be same for
              // 'web', 'mobile', mobile_1, 'frontend', 'server', 'desktop', 'database' and 'conceptual' application types
              if ($scope.isMixLanguagesTournament()) {
                // Tune the difficultyDistribution once again, this time on frontend to confirm to the min value for EASY, MEDIUM and HARD challenges
                $scope.tuneDifficultyDistribution($scope.questsData.difficultyDistribution, $scope.questsData.appTypes);

                if (
                  $scope.appTypesDifficultyLevelMismatch(
                    $scope.questsData.difficultyDistribution,
                    $scope.questsData.appTypes
                  )
                ) {
                  // Mark questsUnavailable as true
                  $scope.questsUnavailable = true;
                }
              }
            }
          });
        })
        .catch(function (response) {
          $log.log(response);
          $scope.questsUnavailable = true;
          $translate(['ERROR_CHECKING_VALIDITY_OF_SELECTED_LANGUAGES']).then(function (translation) {
            ErrorHandler.addHttpError(translation.ERROR_CHECKING_VALIDITY_OF_SELECTED_LANGUAGES, response);
          });
        })
        .finally(function () {
          $scope.questsCheckingInProgress = false;
        });

      function getAppTypeVersion(currentState, tournament) {
        if (currentState === 'tournaments.add') {
          return 1;
        } else if (currentState === 'tournaments.edit' && tournament) {
          return tournament.appTypeVersion;
        }
        return 0;
      }
    };

    function initCheckLanguageQuests() {
      // check initial languages list and keep the promise so that we only call save once this promise (last language quests check) has resolved
      $scope.$watch('tournamentToSave.languages', function (languages, oldLanguages) {
        let categoryType;

        if (languages && languages[0]) {
          categoryType = $scope.metadata.languages[languages[0]._id].framework[languages[0]._framework].categoryType;
          $scope.categoryType = categoryType;

          const newList = [];
          $scope.languageFrameworkTypesAvailable.map(function (lang) {
            const exists = $scope.tournamentToSave.languages.some(function (language) {
              return lang._id === language._id && lang._framework === language._framework;
            });
            if (!exists) {
              newList.push(lang);
            }
          });

          if (categoryType) {
            $scope.languageList = newList;
          }
        } else {
          $scope.languageList = $scope.masterLanguageList;
        }

        if (languages?.length) {
          const isUpdatingDuration = false;

          // wtf is the form not initialised yet? ffs...
          if ($scope.forms.languageQuestForm) {
            // multi UI select does not seem to respect 'required' attribute so set it here
            const valid = setLanguageSelectValidity(languages);
            $scope.forms.editTournamentForm.languageFramework.$dirty = true;

            if (!valid) {
              return;
            }
          }

          $scope.checkPromise = $scope.checkLanguageQuests(isUpdatingDuration, !_.isEqual(languages, oldLanguages));
        }
      });

      $scope.$watch('tournamentToSave.numberOfChallenges', function (val, oldVal) {
        if (val != oldVal) {
          const isUpdatingDuration = true;
          $scope.checkLanguageQuests(isUpdatingDuration, true);
        }
      });

      $scope.$watch('tournamentToSave.enableBonusLevelCheck', function (val, oldVal) {
        if (val != oldVal) {
          const isUpdatingDuration = false;
          $scope.checkLanguageQuests(isUpdatingDuration, true);
        }
      });
    }

    function setLanguageSelectValidity(languages) {
      $scope.forms.editTournamentForm.languageFramework.$setValidity('required', true);
      if (!languages || languages.length < 1) {
        $scope.forms.editTournamentForm.languageFramework.$setValidity('required', false);
        return false;
      }
      return true;
    }

    /*** participant CSV ***/

    $scope.downloadSummaryCSV = function () {
      TournamentsApiService.downloadSummaryCsv($scope.tournament._id).then(function (csv) {
        if (csv.url) {
          const isIE = /Trident|MSIE/.test(window.navigator.userAgent);
          $translate(
            [
              'CSV_REPORT_READY',
              'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
            ],
            { dataUrl: csv.url }
          ).then(function (translations) {
            if (window.navigator.msSaveBlob && isIE) {
              // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
              swal({
                title: translations.CSV_REPORT_READY,
                text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                type: 'success',
                html: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
            } else {
              document.location.href = csv.url;
              swal.close();
            }
          });
        } else {
          $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
            function (translations) {
              swal({
                title: translations.GENERATING_CSV_REPORT,
                text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
                type: 'info',
                showCancelButton: false,
                showConfirmButton: false,
              });
            }
          );
        }
      });
    };

    $scope.downloadDetailedCSV = function () {
      TournamentsApiService.downloadDetailedCsv($scope.tournament._id)
        .then(function (csv) {
          if (csv.url) {
            const isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            $translate(
              [
                'CSV_REPORT_READY',
                'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
              ],
              { dataUrl: csv.url }
            ).then(function (translations) {
              if (window.navigator.msSaveBlob && isIE) {
                // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
                swal({
                  title: translations.CSV_REPORT_READY,
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
              } else {
                document.location.href = csv.url;
                swal.close();
              }
            });
          } else {
            //if csv is currently being generated
            if (csv.isBeingGenerated) {
              $translate([
                'CANNOT_GENERATE_CSV_REPORT',
                'YOU_CURRENTLY_HAVE_A_CHALLENGES_PLAYED_CSV_BEING_GENERATED',
                'OK',
              ]).then(function (translations) {
                swal({
                  title: translations.CANNOT_GENERATE_CSV_REPORT,
                  text: translations.YOU_CURRENTLY_HAVE_A_CHALLENGES_PLAYED_CSV_BEING_GENERATED,
                  type: 'error',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: translations.OK,
                });
              });
            } else {
              $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
                function (translations) {
                  swal({
                    title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                    text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                    type: 'success',
                    html: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: translations.OK,
                  });
                }
              );
            }
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_TOURNAMENT_DETAILED_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_TOURNAMENT_DETAILED_CSV, response);
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.participantTabSelected = function (tournamentToSave) {
      const {
        _id,
        languages,
        name: tournament_name,
        scoringPreset: scoring_configuration,
        numberOfChallenges,
        numberOfInteractiveChallenges,
      } = tournamentToSave;
      const tournament_languages = languages.map(({ _id, _framework }) => `${_id}:${_framework}`);
      const total_number_of_challenges = numberOfChallenges + (numberOfInteractiveChallenges || 0);
      const eventProps = {
        tournament_languages,
        tournament_id: _id,
        tournament_name,
        total_number_of_challenges,
        scoring_configuration,
      };
      AnalyticsService.logEvent(AnalyticsEvents.Tournaments.ACCESS_PARTICIPANTS_PAGE, eventProps);
    };

    $scope.ceilHintsScoring = function (value) {
      return Math.ceil(value * 100);
    };

    $scope.floorHintsScoring = function (value) {
      return Math.floor(value * 100);
    };

    /*** duration slider ***/
    let debouncer = null;

    function initDurationSlider() {
      const sliderMin = $scope.minDuration;
      const sliderMax = $scope.maxDuration;
      //var sliderStep = 8; // 8 quests

      const handle = $('#durationSlider #custom-handle');

      if (tts) {
        $('#durationSlider').slider({
          value: tts.numberOfChallenges,
          //step: sliderStep,
          min: sliderMin,
          max: sliderMax,
          disabled: !!tts.numPlayers || $scope.isTournamentReplicated,
          create: function () {
            handle.text($(this).slider('value'));
          },
          change: function (_event, ui) {
            handle.text(ui.value);
            tts.numberOfChallenges = ui.value;

            // since triggered by jQuery not Angular
            if (!$scope.$$phase) {
              clearTimeout(debouncer);
              debouncer = setTimeout(function () {
                $scope.$apply();
              }, 1000);
            }
          },
          slide: function (_event, ui) {
            handle.text(ui.value);
          },
        });
      }
    }

    function updateDurationSliderRange(min, max) {
      $('#durationSlider').slider('option', 'min', min);
      $('#durationSlider').slider('option', 'max', max);
    }

    function updateDurationSliderValue(value) {
      $('#durationSlider').slider('option', 'value', value);
    }

    // Initialise data for global tournaments in edit mode
    $scope.initialiseGlobalTournamentDataOnEdit = function (tournamentToSave, isTournamentReplicated) {
      // This is to be discussed
      $scope.isTournamentAudienceComponentDisabled =
        tournamentToSave.published ||
        tournamentToSave.status === 'finished' ||
        tournamentToSave.numPlayers > 0 ||
        isTournamentReplicated;

      /**
       * The save and publish button should be only be disabled on load of tournament if only 1 audience has been
       * selected so far. Post the initial load, the button onToggle and onChange events should take over.
       */
      $scope.audienceLength = tournamentToSave?.audiences?.length;

      $scope.isSaveAndPublishButtonDisabled = $scope.audienceLength === 1;
      $scope.fetchCompanyList();

      if ($scope.audienceLength) {
        $scope.showTournamentCompanySelectorComponent = true;
      }
    };

    $scope.initLanguages = function initLanguages() {
      // init languages
      return getCompanyData().then(function () {
        if (!AuthService.isAuthorized(USER_ROLES.admin)) {
          if (Session.user.properties.company) {
            $scope.languageList = _.cloneDeep(Session.user.properties.company.languages);
          } else if (Session.user.properties.team) {
            $scope.languageList = _.cloneDeep(Session.user.properties.team.languages);
          } else {
            $scope.languageList = [];
          }
          return $q.when($scope.languageList);
        } else {
          if (_.get($scope, 'companySelectedBySCWAdmin.company.languages')) {
            $scope.languageList = $scope.companySelectedBySCWAdmin.company.languages;
            return $q.when($scope.languageList);
          } else {
            $rootScope.languageList = [];
            return PlatformLanguagesService.addStats(metadata.languages).then(function () {
              $rootScope.languageList = LanguageUtils.languageList();
              $rootScope.languageName = LanguageUtils.languageName;

              return _.cloneDeep($rootScope.languageList);
            });
          }
        }
      });
    };

    $scope.initialiseLanguages = async function initialiseLanguages() {
      const list = await $scope.initLanguages();
      $scope.languageList = list;
      const availableLanguages = list;

      LanguageUtils.populate($scope.languageList);

      // PORTAL-731 - Add support for mobile languages in a tournament
      $scope.languageList = $scope.languageList.filter(function (language) {
        return (
          ['web', 'mobile'].indexOf(
            $scope.metadata.languages[language._id].framework[language._framework].categoryType
          ) > -1
        );
      });

      $scope.masterLanguageList = $scope.languageList;

      // init timezones
      $scope.timezoneList = moment.tz.names();

      if ($state.current.name == 'tournaments.edit') {
        $scope.getTournamentPromise = getTournamentData();

        $scope.getTournamentPromise.then(function (_data) {
          $scope.editing = true;
          $scope.hasPreviewed = true;
          $scope.tournamentToSave = _.cloneDeep($scope.tournament);
          // shorthand reference to tournamentToSave
          tts = $scope.tournamentToSave;

          // Broken into a function for testability
          $scope.isTournamentReplicated = $scope.setReplicationIfApplicable($scope.tournamentToSave);

          if ($scope.tournamentToSave?.public) {
            // If this by chance has not been set for global tournaments, default to an empty array
            $scope.tournamentToSave.audiences = $scope.tournamentToSave.audiences ?? [];
            $scope.initialiseGlobalTournamentDataOnEdit($scope.tournamentToSave, $scope.isTournamentReplicated);
          }

          // set preset according to loaded tournament
          if (tts.scoringPreset) {
            for (let i = 0; i < $scope.scoringPresets.length; i++) {
              const preset = $scope.scoringPresets[i];
              if (tts.scoringPreset == preset._id) {
                $scope.forms.selectedScoringPreset = preset;
                // set preset for dropdown but do NOT populate scoringOptions from preset since the tournament MAY have customised values
                updateWorkedExample(tts.scoringOptions);
              }
            }
          } else {
            // assume 'Default'
            updateWorkedExample(tts.scoringOptions);
          }

          // Make sure custom rule box is only on if we have rulesText
          $scope.forms.customRuleSwitch = Boolean(tts.rulesText);
          // Make sure maxPlayers shows up as 'unlimited' if maxPlayer value is zero
          tts.maxPlayers = tts.maxPlayers || null;

          tts.startTime = convertToTimezone(convertToUTC(new Date(tts.startTime), tts.timezone), moment.tz.guess());
          tts.finishTime = convertToTimezone(convertToUTC(new Date(tts.finishTime), tts.timezone), moment.tz.guess());

          // displayed in minutes, data is saved as ms
          const hideBeforeEnd = _.get($scope, 'tournamentToSave.leaderboardOptions.options.hideBeforeEnd');
          if (hideBeforeEnd) {
            tts.leaderboardOptions.options.hideBeforeEnd /= 60 * 1000;
          }
          $scope.forms.autoHideSwitch = Boolean(hideBeforeEnd);

          initCheckLanguageQuests();

          // init list of potential admins
          $scope.fetchPotentialAdmins('', null, true);
        });

        if (Session.user.roles.indexOf('scw admin') > -1) {
          getQuestList();
        }
      } else {
        $scope.hasPreviewed = false;
        $scope.tournamentToSave = _.cloneDeep(defaultEmptyTournament);
        $scope.tournamentToSave.numberOfInteractiveChallenges = 0;
        $scope.tournamentToSave.enableBonusLevelCheck = true; //set the value only if needed
        addInteractiveScoringData();
        //Value for the scoringOptions
        if (_.isEmpty(_.get($scope.tournamentToSave.scoringOptions, 'interactive', {}))) {
          _.assign($scope.tournamentToSave.scoringOptions, $scope.interactive);
        }

        // shorthand reference to tournamentToSave
        tts = $scope.tournamentToSave;
        tts.timezone = moment.tz.guess();
        // no need to convert default times since they are created in local zone
        $scope.forms.customRuleSwitch = Boolean(tts.rulesText);

        // If the user creating this is a SCW Admin and is doing this by not selecting a company
        if (!$scope.companySelectedBySCWAdmin?._id && AuthService.isAuthorized([USER_ROLES.admin])) {
          $scope.tournamentToSave.audiences = [];
        }

        updateWorkedExample(tts.scoringOptions);
        initCheckLanguageQuests();

        // Default value for auto hide leaderboard is false
        $scope.forms.autoHideSwitch = false;

        // init list of potential admins
        $scope.fetchPotentialAdmins('', null, true);
      }

      const languageFrameworkTypesAvailable = [];

      availableLanguages.map(function (language) {
        // availableLanguages doesn't have categoryType, isPoc and isAvailable properties
        // get them from metadata and Session.user.properties.languages
        const currentLanguage = Session.user.properties.languages.filter(function (lang) {
          return lang.language._id === language._id && lang.language._framework === language._framework;
        });
        if (currentLanguage.length) {
          const languageObj = _.merge(language, {
            name: LanguageUtils.languageName(language),
            $$isPoc: !!currentLanguage[0].isPoc,
            $$isAvailable: ['available', 'active'].indexOf(currentLanguage[0].status) > -1,
          });
          languageFrameworkTypesAvailable.push(languageObj);
        }
      });

      $scope.languageFrameworkTypesAvailable = languageFrameworkTypesAvailable;
    };

    $scope.hasQuestLanguageError = () => {
      return (
        ($scope.questsData?.error?.code == $scope.ERRORS?.SIZE && $scope.questsData?.error?.suggestSize < 24) ||
        $scope.questsData?.error?.code == 'INVALID_LANGUAGE' ||
        $scope.appTypesDifficultyLevelMismatch($scope.questsData?.difficultyDistribution, $scope.questsData?.appTypes)
      );
    };

    $scope.getAppTypeLabel = (appType) => (appType === 'mobile_1' ? 'Modern Mobile' : appType);

    function init() {
      $scope.initialiseLanguages();
    }

    /*** controller init ***/
    /**
     * The testInit function is used to inject a mock value while testing. This has been used
     * as the tournament.main.js overwrites the init function with a value initially. This pattern
     * of injecting a testInit is being tested and is likely to change eventually
     */
    $scope.init = $scope.testInit || init;
    $scope.init();
  },
]);
