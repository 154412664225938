import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.factory('MetadataApiService', [
  '$log',
  '$http',
  '$rootScope',
  '$timeout',
  '$translate',
  '$window',
  'HttpConfigService',
  function ($log, $http, $rootScope, $timeout, $translate, $window, HttpConfigService) {
    const MetadataApi = {};
    const MetadataApiEndpoint = function () {
      return $window.SCW_ENV.ApiEndpoint + '/public/metadata';
    };

    MetadataApi.addLoading = function () {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    };

    MetadataApi.removeLoading = function () {
      $rootScope.loading--;
      if ($rootScope.loading < 0) {
        $rootScope.loading = 0;
      }
    };

    MetadataApi.GetLocaleMetadata = function (langKey) {
      $log.debug('MetadataApi - GetLocaleMetadata()', arguments);
      encodeParams(arguments);

      MetadataApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'POST',
            url: MetadataApiEndpoint(),
            data: { language: langKey },
          },
          HttpConfigService.getHttpConfigNoIFR()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          MetadataApi.removeLoading();
        });
    };

    MetadataApi.getCustomerPlans = async () => {
      const response = await $http({
        method: 'GET',
        url: $window.SCW_ENV.ApiEndpoint + '/public/customer-plans',
        ...HttpConfigService.getHttpConfigNoIFR(),
      });
      return response.data;
    };

    return MetadataApi;
  },
]);
