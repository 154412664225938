var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const recommendations = [
    {
        recommendation: 'Lorem ipsum odor amet, consectetuer adipiscing elit.',
        ordinal: 1,
    },
    {
        recommendation: 'Laoreet viverra blandit dolor molestie ullamcorper finibus.',
        ordinal: 2,
    },
    {
        recommendation: 'Agravida cursus turpis vulputate maximus neque tempor.',
        ordinal: 3,
    },
    {
        recommendation: 'Mattis nulla senectus elit pretium dictum, cursus erat elit.',
        ordinal: 4,
    },
    {
        recommendation: 'Ornare lectus risus interdum senectus montes cursus sodales pharetra senectus.',
        ordinal: 5,
    },
    {
        recommendation: 'Class quis risus semper sollicitudin blandit laoreet habitant leo.',
        ordinal: 6,
    },
    {
        recommendation: 'Donec tristique primis primis tellus; iaculis efficitur ridiculus iaculis.',
        ordinal: 7,
    },
    {
        recommendation: 'Enim tincidunt efficitur, dapibus sapien viverra purus.',
        ordinal: 8,
    },
    {
        recommendation: 'Nibh lobortis ridiculus ad ultricies aptent erat.',
        ordinal: 9,
    },
    {
        recommendation: 'Vel elit sodales accumsan habitasse dignissim netus.',
        ordinal: 10,
    },
    {
        recommendation: 'Fames class metus fames duis conubia natoque.',
        ordinal: 11,
    },
    {
        recommendation: 'Non eget maximus arcu nunc ac.',
        ordinal: 12,
    },
    {
        recommendation: 'Risus odio maecenas vitae ante lobortis rutrum facilisi proin platea.',
        ordinal: 13,
    },
    {
        recommendation: 'Dis per pretium amet elementum commodo porttitor feugiat ultrices.',
        ordinal: 14,
    },
    {
        recommendation: 'Facilisis mus laoreet dictum arcu molestie.',
        ordinal: 15,
    },
    {
        recommendation: 'Bibendum auctor parturient aliquam dictumst orci.',
        ordinal: 16,
    },
    {
        recommendation: 'Aptent integer himenaeos orci euismod arcu conubia.',
        ordinal: 17,
    },
    {
        recommendation: 'Diam malesuada eleifend accumsan bibendum nibh nulla fames? Quisque amet metus elit praesent; ligula inceptos ridiculus.',
        ordinal: 18,
    },
    {
        recommendation: 'Ornare suscipit aenean potenti sapien in taciti malesuada.',
        ordinal: 19,
    },
    {
        recommendation: 'Aptent posuere integer mollis parturient lacinia ipsum aliquam.',
        ordinal: 20,
    },
    {
        recommendation: 'Class congue suscipit dictum magnis fringilla; nam tincidunt class.',
        ordinal: 21,
    },
    {
        recommendation: 'Posuere gravida malesuada turpis iaculis montes efficitur etiam vel? Justo fames habitant ullamcorper senectus est est placerat magnis id.',
        ordinal: 22,
    },
    {
        recommendation: 'Maximus dolor mauris, senectus himenaeos non dictumst metus! Nascetur etiam quisque magnis rhoncus, parturient ligula viverra.',
        ordinal: 23,
    },
    {
        recommendation: 'Feugiat nibh cras maximus magnis pharetra rhoncus.',
        ordinal: 24,
    },
    {
        recommendation: 'Velit risus risus turpis sed rutrum in.',
        ordinal: 25,
    },
];
const prng = (max) => () => Math.floor(Math.random() * max) + 1;
const randomMs = prng(3000);
const randomTrustScore = prng(1000);
const randomPercent = prng(100);
const randomRecommendationIdx = prng(recommendations.length - 1);
const randomRecommendations = () => Array.from({ length: 5 }, () => recommendations[randomRecommendationIdx()]);
const randomBoolean = (threshold = 0.5) => Math.random() < threshold;
const getRequestState = () => ({
    delay: randomMs(), // Random delay between 1-5 seconds
    shouldFail: randomBoolean(0.3), // 30% chance of failure
    onboarding: randomBoolean(0.3), // 30% chance of onboarding
});
export const fetchTrustScore = () => __awaiter(void 0, void 0, void 0, function* () {
    const { delay, shouldFail, onboarding } = getRequestState();
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (shouldFail) {
                console.log(`Failed to load TrustScore data`);
                reject(new Error(`Failed to load TrustScore data`));
                return;
            }
            if (onboarding) {
                resolve({ onboarding, trustScore: undefined });
            }
            resolve({
                onboarding: false,
                trustScore: {
                    value: randomTrustScore(),
                    recommendations: randomRecommendations(),
                    percentile: randomPercent(),
                    rank: {
                        position: randomPercent(),
                        total: randomPercent(),
                    },
                },
            });
        }, delay);
    });
});
export const fetchLearningProgress = () => {
    const { delay, shouldFail, onboarding } = getRequestState();
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (shouldFail) {
                reject(new Error(`Failed to learning progress load data`));
                return;
            }
            if (onboarding) {
                resolve({ onboarding, value: undefined });
            }
            resolve({ onboarding: false, value: randomPercent() });
        }, delay);
    });
};
