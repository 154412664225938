import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from '@mui/material';
export const MainContainer = ({ children, component }) => {
    return (_jsx(Container, { maxWidth: false, disableGutters: true, component: component || 'main', sx: {
            width: '100vw',
            padding: '0',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            minHeight: 'calc(100vh - 64px)',
            maxHeight: 'calc(100vh - 64px);',
            overflow: 'hidden',
            '& footer': {
                minHeight: '80px',
            },
        }, children: children }));
};
