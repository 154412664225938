import angular from 'angular';
import uiRouter from 'angular-ui-router';
import angularTranslate from 'angular-translate';

import SCWCommons from '../components/commons';
import SCWWalkthrough from '../walkthroughs';
import SenseiApi from '../sensei/sensei-api';
import SCWUtil from '../util';
import SCWGameComponents from '../components/challenges';
import SCWFeatures from '../feature-flags';

export default angular.module('SCWGame013', [
  angularTranslate,
  uiRouter,
  SCWCommons,
  SCWWalkthrough,
  SenseiApi,
  SCWUtil,
  SCWGameComponents,
  SCWFeatures,
]).name;
