import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useApiClient } from '../../ApiContext';
import { Link } from '../../navigation';
export const CourseDetailsPage = () => {
    const { languageId } = useParams();
    if (!languageId) {
        throw new Error('Missing language ID');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const [course, setCourse] = useState();
    useEffect(() => {
        setCourse(undefined);
        handleErrorPromise(trpc.learn.course.getCourseForLanguage.query({ language: languageId }), (r) => {
            setCourse(r.course);
        });
    }, [languageId]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs(Stack, { alignItems: "center", textAlign: "center", gap: 2.5, children: [_jsx(Link, { to: "/learn", children: "Back to overview" }), course ? (_jsxs("div", { children: [_jsx("h2", { children: course.name }), _jsxs("p", { children: ["Language: ", course.frameworkLanguage, course.backupLanguage && ` (Backup: ${course.backupLanguage})`] }), course.chapters.map((chapter) => (_jsxs("div", { children: [_jsx("h3", { children: chapter.name }), chapter.topics.map((topic) => (_jsxs("div", { children: [_jsx("h4", { children: topic.name }), _jsxs("p", { children: ["Category: ", topic.category] }), topic.activities.map((activity) => (_jsxs("div", { children: [_jsxs("p", { children: [activity.kind, " (", activity.id, ")"] }), _jsx("p", { children: activity.finished ? 'Finished' : 'Not finished' })] }, activity.id)))] }, topic.id)))] }, chapter.name)))] })) : (_jsx("p", { children: "Loading..." }))] }));
};
