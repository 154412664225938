import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('ExportUsersCSVFn', [
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  function ($translate, AdminApiService, ErrorHandler) {
    return function ExportUserListsCSV(query) {
      AdminApiService.addLoading();
      AdminApiService.exportUserListsCSV(query)
        .then(function (data) {
          var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
          if (window.navigator.msSaveBlob && isIE) {
            // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
            swal({
              title: $translate.instant('CSV_REPORT_READY'),
              text: $translate.instant(
                'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
                { dataUrl: data.url }
              ),
              type: 'success',
              html: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
          } else {
            document.location.href = data.url;
            swal.close();
          }
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TYPE_LEADERBOARD_CSV'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });

      swal({
        title: $translate.instant('GENERATING_CSV_REPORT'),
        text: $translate.instant('PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT'),
        type: 'info',
        showCancelButton: false,
        showConfirmButton: false,
      });
    };
  },
]);
