/* eslint-disable import/no-named-as-default-member */
var _a;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18n as DSi18n } from '@securecodewarrior/design-system-react';
import { designSystemResourceBundles, NS_COMPONENTS, NS_PLATFORM_FRONTEND } from './resources';
i18n.use(initReactI18next).init({
    load: 'currentOnly',
    resources: designSystemResourceBundles,
    lng: 'en-US',
    fallbackLng: 'en-US',
    ns: NS_COMPONENTS,
    fallbackNS: [NS_PLATFORM_FRONTEND],
    interpolation: {
        escapeValue: false,
    },
    supportedLngs: Object.keys(designSystemResourceBundles),
    returnNull: false,
    react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'u', 'b', 'ul', 'ol', 'li'],
    },
});
(_a = i18n.services.formatter) === null || _a === void 0 ? void 0 : _a.add('sentenceCase', (value, _lng, _options) => {
    if (_lng === 'de') {
        // Ideally this should be made sentence case in the base translations. We can't automate this since more words
        // should be capitalised in German. The only thing we can do in german is making the first letter uppercase.
        return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
    }
    return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
});
i18n.on('languageChanged', (lng) => {
    DSi18n.changeLanguage(lng);
});
export { i18n };
