import { CustomerAnalyticsEvent } from '@securecodewarrior/integrations-core';

/**
 * NB this may be widened to string in future (or just replaced):
 * @typedef PortalArea = 'microlearning'
 */

export class CustomerAnalyticsPageViewEvent extends CustomerAnalyticsEvent {
  /**
   *
   * @param absUrl {string} URL of the page
   * @param pageName {string} name of the page
   */
  constructor(absUrl, pageName) {
    super('pageView');
    this.absUrl = absUrl;
    this.pageName = pageName;
  }
}

export class CustomerAnalyticsLoginEvent extends CustomerAnalyticsEvent {
  constructor() {
    super('login');
  }
}

export class CustomerAnalyticsChallengeStartedEvent extends CustomerAnalyticsEvent {
  /**
   * @param portalArea {PortalArea}
   */
  constructor(portalArea) {
    super('challengeStarted');
    this.portalArea = portalArea;
  }
}

export class CustomerAnalyticsChallengeCompletedEvent extends CustomerAnalyticsEvent {
  /**
   * @param portalArea {PortalArea}
   */
  constructor(portalArea) {
    super('challengeCompleted');
    this.portalArea = portalArea;
  }
}
