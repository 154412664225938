import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Backpack, BookmarkBorder, CheckCircle, EmojiEvents, ShowChart, Info, Warning } from '@mui/icons-material';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { Box, Typography, useTheme } from '@mui/material';
import { possibleActivityTypeOptions } from '../explore/FilterLogic';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../platformContext';
import { getCategoryName } from '../utils/mapper';
import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tooltip } from '../common/Tooltip';
import { useGoalFilterContext } from './useGoalFilter';
import { usePlatformNavigate } from '../navigation';
import { goalAnalytics } from './Goals.analytics';
export const ObjectiveElement = (props) => {
    const { objective, currentModule } = props;
    const kind = objective.kind;
    const [t] = useTranslation();
    const theme = useTheme();
    const sx = useMemo(() => createStyle(theme), [theme]);
    return (_jsxs(Box, { sx: sx, children: [_jsxs(Typography, { variant: "overline", component: "p", className: "title", children: [criterionIcon[kind], t(`goals.objective.title.${kind}`)] }), kind === 'skill' ? (_jsx(SkillItem, { objective: objective })) : kind === 'activity' ? (_jsx(ActivityItem, { objective: objective, currentModule: currentModule })) : kind === 'tournament' ? (_jsx(TournamentItem, { objective: objective })) : kind === 'categories' ? (_jsx(CategoryItems, { objective: objective, currentModule: currentModule })) : (assertNever(kind, 'invalid object'))] }));
};
const matchModule = function (module, explore, quests) {
    return module === 'explore' ? explore(module) : module === 'quests' ? quests(module) : assertNever(module, '');
};
const SkillItem = ({ objective }) => {
    return (_jsxs(Typography, { variant: "body1", className: "skill", children: [_jsx("span", { className: "skillpgbar", children: _jsx("span", { style: { width: `${Math.round((100 * objective.actual) / objective.goal)}%` } }) }), _jsxs("span", { children: [_jsx(Typography, { variant: "body0", children: objective.actual }), "/", objective.goal] })] }));
};
const ActivityItem = ({ objective, currentModule, }) => {
    const { icon, label } = possibleActivityTypeOptions[objective.activityType];
    const { metadata, logAnalyticsEvent } = usePlatformContext();
    const [t] = useTranslation();
    const analytics = goalAnalytics(logAnalyticsEvent);
    const labelKey = matchModule(currentModule, (_) => 'goals.objective.action.filter', (_) => 'goals.objective.action.goToExplore');
    const doAction = () => {
        analytics.onActivityObjectiveAction('filter', objective.activityType, objective.categoryId, currentModule);
    };
    return (_jsxs("div", { className: "boxed-item", children: [icon, _jsx(Typography, { component: "span", className: "activity-type", children: t(label) }), _jsx("div", { className: "separator" }), _jsx(Typography, { variant: "caption", className: "caption", children: getCategoryName(objective.categoryId, metadata) }), objective.completed ? (_jsx(CheckCircle, { className: "check" })) : (_jsx(Link, { variant: "captionLink", onClick: doAction, children: t(labelKey) }))] }));
};
const TournamentItem = ({ objective }) => {
    const [t] = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = goalAnalytics(logAnalyticsEvent);
    const doAction = () => {
        analytics.onTournamentObjectiveAction('open', objective.tournamentId, objective.name);
    };
    return (_jsxs("div", { className: "boxed-item", children: [_jsx(Typography, { variant: "caption", className: "caption", children: t(`goals.objective.tournament`, { name: objective.name }) }), objective.completed ? (_jsx(CheckCircle, { className: "check" })) : (_jsx(Link, { variant: "captionLink", onClick: doAction, children: t(`goals.objective.action.register`) }))] }));
};
const CategoryItems = ({ objective, currentModule, }) => {
    const { metadata, logAnalyticsEvent } = usePlatformContext();
    const navigate = usePlatformNavigate();
    const goalFilterStore = useGoalFilterContext();
    const [t] = useTranslation();
    const analytics = goalAnalytics(logAnalyticsEvent);
    return objective.categories.map((entry) => {
        const categoryId = entry.categoryId;
        const categoryName = getCategoryName(categoryId, metadata);
        const isSelected = currentModule === 'quests' && goalFilterStore.filteredCategory === categoryId;
        const labelKey = matchModule(currentModule, (_) => 'goals.objective.action.goToQuests', (_) => (isSelected ? 'pages.quests.clear' : 'goals.objective.action.filter'));
        const doAction = () => {
            matchModule(currentModule, (_) => {
                goalFilterStore.setFilteredCategory(entry.categoryId);
                navigate.to({ pathname: '/quests', hash: '', search: '' });
            }, (_) => goalFilterStore.setFilteredCategory(isSelected ? null : categoryId));
            analytics.onCategoryObjectiveAction(isSelected ? 'clear_filter' : 'filter', entry.categoryId, currentModule);
        };
        return (_jsxs("div", { className: `boxed-item is-filtered-category-${isSelected}`, children: [_jsx(Typography, { variant: "caption", className: "caption", children: categoryName }), entry.completed === 'yes' || entry.completed === 'before_publish_date' ? (_jsxs(_Fragment, { children: [entry.completed === 'before_publish_date' && (_jsx(Tooltip, { title: _jsx(Typography, { sx: { textAlign: 'center' }, children: _jsx(Trans, { i18nKey: 'goals.objective.completedBeforePublishDate', values: { category: categoryName } }) }), arrow: true, children: _jsx(Info, { "aria-label": t('goals.objective.completedBeforePublishDateLabel', { category: categoryName }), className: "info" }) })), _jsx(CheckCircle, { className: "check" })] })) : (_jsxs(_Fragment, { children: [entry.completed === 'expires_before_end_date' && (_jsx(Tooltip, { title: _jsx(Typography, { sx: { textAlign: 'center' }, children: _jsx(Trans, { i18nKey: 'goals.objective.expiresBeforeEndDate', values: { category: categoryName } }) }), arrow: true, children: _jsx(Warning, { "aria-label": t('goals.objective.expiresBeforeEndDateLabel', { category: categoryName }), className: "warning" }) })), _jsx(Link, { variant: "captionLink", onClick: doAction, children: t(labelKey) })] }))] }, `goal_category_${categoryId}`));
    });
};
const criterionIcon = {
    skill: _jsx(ShowChart, {}),
    activity: _jsx(Backpack, {}),
    tournament: _jsx(EmojiEvents, {}),
    categories: _jsx(BookmarkBorder, {}),
};
const createStyle = (theme) => ({
    margin: 4,
    '.title': {
        display: 'flex',
        gap: 1,
        lineHeight: theme.typography.pxToRem(24),
        textTransform: 'uppercase',
        color: theme.palette.text.body,
        marginBottom: 3,
        svg: {
            color: theme.palette.clickable.nav.item.default,
            width: theme.typography.pxToRem(24),
            height: theme.typography.pxToRem(24),
        },
    },
    '> .skill': {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.soft,
        height: theme.typography.pxToRem(12),
        marginBottom: 7,
        gap: 2,
        '.skillpgbar': {
            display: 'block',
            flexGrow: '1',
            height: '100%',
            borderRadius: theme.typography.pxToRem(12),
            background: theme.palette.container.border.default,
            overflow: 'hidden',
            '> span': {
                display: 'block',
                borderRadius: theme.typography.pxToRem(12),
                height: '100%',
                background: theme.palette.badge.accent1.border,
            },
        },
        '> span+span': {
            minWidth: '8ch',
            marginLeft: '1ch',
            textAlign: 'right',
        },
    },
    '> div.boxed-item': {
        marginTop: 2,
        padding: 2,
        background: theme.palette.badge.default.fill,
        border: `1px solid ${theme.palette.badge.default.border}`,
        '&.is-filtered-category-true': {
            border: `1px solid ${theme.palette.container.border.active}`,
        },
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '.activity-type': {
            color: theme.palette.text.soft,
            lineHeight: theme.typography.pxToRem(18),
        },
        '.separator': {
            border: `1px solid ${theme.palette.container.border.default}`,
            height: theme.typography.pxToRem(12),
            borderRadius: 3,
        },
        '.caption': {
            color: theme.palette.badge.text,
            flexGrow: '1',
        },
        svg: {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            color: theme.palette.text.soft,
            '&.check': {
                color: theme.palette.text.status.success,
            },
            '&.info': {
                color: theme.palette.container.border.input,
            },
            '&.warning': {
                color: theme.palette.text.status.warning,
            },
        },
    },
});
