import { Box, styled } from '@mui/material';
export const AchievementGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    padding: theme.spacing(4, 4, 3),
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `'content icon'`,
    alignItems: 'center',
}));
