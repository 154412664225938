import { jsx as _jsx } from "react/jsx-runtime";
import { GoalFilterContext, useGoalFilterStore } from '../goals/useGoalFilter';
import { WithGoalsSidenav } from '../goals/WithGoalsSidenav';
import { useFetchGoals } from '../goals/useFetchGoals';
import { WithFooter } from '../footer/WithFooter';
import { QuestsWrapper } from './QuestsWrapper';
import { useCallback, useState } from 'react';
export const QuestsHomePage = () => {
    const createFetchGoalsPromise = useFetchGoals();
    const [fetchGoals, setFetchGoals] = useState(() => () => createFetchGoalsPromise());
    const goalFilterStore = useGoalFilterStore();
    const onLanguageSelected = useCallback(() => setFetchGoals(() => () => createFetchGoalsPromise()), [createFetchGoalsPromise]);
    return (_jsx(GoalFilterContext.Provider, { value: goalFilterStore, children: _jsx(WithGoalsSidenav, { currentModule: "quests", fetchGoals: fetchGoals, children: _jsx(WithFooter, { children: _jsx(QuestsWrapper, { onLanguageSelected: onLanguageSelected }) }) }) }));
};
