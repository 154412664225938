import angular from 'angular';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { VideoPlayer } from '@securecodewarrior/design-system-react';
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Link,
  Loading,
  Logo,
  Pagination,
  Radio,
  SearchInput,
  Switch,
  TextArea,
  TextInput,
} from '@securecodewarrior/design-system-react/lib/legacy';

import {
  IntegrationsView,
  IntegrationsRegister,
  TournamentParticipantCompanies,
  TournamentSpecialEvent,
  VulnerabilitySummaryReporter,
  AssessmentList,
  AssessmentResults,
  AssessmentOverview,
  BannerWithDescription,
  IconButton,
  IconTooltip,
  PlatformHeader,
  PlatformFooter,
  PlatformDropdown,
  TournamentCompanySelector,
  TournamentSnackbar,
  ScwReactApp,
} from '@scw/react-components';

import reactToAngularFactory from './reactToAngularFactory';

const SCWDesignSystem = 'SCWDesignSystem';
export default SCWDesignSystem;

export const { createDirective } = reactToAngularFactory({
  createRoot: ReactDOM.createRoot,
  createElement: React.createElement,
  themeProviderDirectiveName: `scwThemeProvider`,
});

angular
  .module(SCWDesignSystem, [])
  .directive(`scwIntegrationsView`, createDirective(IntegrationsView, { theme: 'light' }))
  .directive(`scwIntegrationsRegister`, createDirective(IntegrationsRegister, { theme: 'light' }))
  .directive(`scwVulnerabilitySummaryReporter`, createDirective(VulnerabilitySummaryReporter, { theme: 'light' }))
  .directive(`scwAvatar`, createDirective(Avatar))
  .directive(`scwButton`, createDirective(Button))
  .directive(`scwCheckbox`, createDirective(Checkbox))
  .directive(`scwDropdown`, createDirective(Dropdown))
  .directive('scwIcon', createDirective(Icon))
  .directive(`scwLink`, createDirective(Link))
  .directive(`scwLoading`, createDirective(Loading))
  .directive(`scwLogo`, createDirective(Logo))
  .directive(`scwPagination`, createDirective(Pagination))
  .directive(`scwRadio`, createDirective(Radio))
  .directive(`scwSwitch`, createDirective(Switch))
  .directive(`scwSearchInput`, createDirective(SearchInput, { overridableMethods: ['onChange'] }))
  .directive('scwTournamentSpecialEvent', createDirective(TournamentSpecialEvent))
  .directive('scwTournamentParticipantCompanies', createDirective(TournamentParticipantCompanies))
  .directive(`scwTextArea`, createDirective(TextArea, { overridableMethods: ['onChange'] }))
  .directive(`scwTextInput`, createDirective(TextInput, { overridableMethods: ['onChange'] }))
  .directive('scwVideoPlayer', createDirective(VideoPlayer))
  .directive(`scwAssessmentListPage`, createDirective(AssessmentList))
  .directive(`scwAssessmentResultsPage`, createDirective(AssessmentResults))
  .directive(`scwAssessmentOverviewPage`, createDirective(AssessmentOverview))
  .directive('scwBannerWithDescription', createDirective(BannerWithDescription))
  .directive('scwButtonWithIcon', createDirective(IconButton))
  .directive('scwIconTooltip', createDirective(IconTooltip))
  .directive('scwPlatformHeader', createDirective(PlatformHeader))
  .directive('scwPlatformFooter', createDirective(PlatformFooter))
  .directive('scwPlatformDropdown', createDirective(PlatformDropdown))
  .directive('scwTournamentCompanySelector', createDirective(TournamentCompanySelector))
  .directive('scwTournamentSnackbar', createDirective(TournamentSnackbar))
  .directive('scwReactApp', createDirective(ScwReactApp));
