import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('DownloadAvailableContentCSVFn', [
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  function ($translate, AdminApiService, ErrorHandler) {
    return function DownloadAvailableContentCSV(query) {
      AdminApiService.addLoading();
      AdminApiService.downloadAvailableContentCSV(query)
        .then(({ url }) => {
          const isIE = /Trident|MSIE/.test(window.navigator.userAgent);
          if (window.navigator.msSaveBlob && isIE) {
            openCSVReportReadyModel($translate, url);
          } else {
            document.location.href = url;
            swal.close();
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_AVAILABLE_CONTENT_CSV']).then((translations) => {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_AVAILABLE_CONTENT_CSV, response);
          });
        })
        .finally(() => {
          AdminApiService.removeLoading();
        });

      openGeneratingCSVReportModel($translate);
    };
  },
]);

function openCSVReportReadyModel($translate, url) {
  $translate(
    [
      'CSV_REPORT_READY',
      'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
    ],
    { dataUrl: url }
  ).then((translations) => {
    swal({
      title: translations.CSV_REPORT_READY,
      text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
      type: 'success',
      html: true,
      showCancelButton: false,
      showConfirmButton: false,
    });
  });
}

function openGeneratingCSVReportModel($translate) {
  $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
    function (translations) {
      swal({
        title: translations.GENERATING_CSV_REPORT,
        text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
        type: 'info',
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  );
}
