import { styled, Box } from '@mui/material';
export const CountdownClock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    alignItems: 'center',
}));
export const CountdownItem = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'calc(1fr + 1px) 1fr',
    border: `1px solid ${theme.palette.container.border.input}`,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    width: '42px',
    '& > :first-of-type': {
        borderRight: `1px solid ${theme.palette.container.border.default}`,
    },
}));
export const Caption = styled(Box)(({ theme }) => (Object.assign(Object.assign({ gridColumn: '1 / 3', display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: `1px solid ${theme.palette.container.border.default}` }, theme.typography.codeSnippet), { color: theme.palette.text.soft })));
