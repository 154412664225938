import { loadRemoteEntry, loadRemoteModule } from '@angular-architects/module-federation';

//TODO: this is all module loading stuff that can be replaced with webpack configs in Angular 2+.
export const loadReportingModuleFromRegion = (environment) => {
  const reportingURL = reportingURLForRegion(environment);
  const reportingEntryURL = reportingEntryURLForRegion(reportingURL);
  return loadRemoteModule({
    type: 'module',
    remoteEntry: reportingEntryURL,
    remoteName: 'reportingApp',
    exposedModule: './ReportingApp',
  })
    .then(() => {
      console.debug('Loaded Reporting App Module Successfully!');
    })
    .catch((err) => {
      console.error(`Error loading reportingApp/ReportingApp: ${err}`);
      throw err;
    });
};

export const loadReportingEntryFromURL = (environment) => {
  const reportingURL = reportingURLForRegion(environment);
  const reportingEntryURL = reportingEntryURLForRegion(reportingURL);
  return loadRemoteEntry({
    type: 'module',
    remoteEntry: reportingEntryURL,
  })
    .then(() => {
      console.debug('Pre-loaded Reporting App Module Successfully!');
    })
    .catch((err) => {
      console.error(`Error pre-loading reportingApp/ReportingApp: ${err}`);
    });
};

const reportingURLForRegion = (environment) => {
  return environment.ApiEndpoint?.includes('.eu.')
    ? environment.ReportingUiHosts[environment.API_ENDPOINT_INDEXES.EU]
    : environment.ReportingUiHosts[environment.API_ENDPOINT_INDEXES.US];
};

const reportingEntryURLForRegion = (path) => {
  return `${path}/assets/remoteEntry.js`;
};
