import angular from 'angular';
import MODULE from '../module';
import integrationsRegisterTemplate from './admin.companies.integrations.register.html';

angular.module(MODULE).controller('ManagementAdminCompanyIntegrationsController', [
  '$scope',
  '$translate',
  '$uibModal',
  '$stateParams',
  'AdminApiService',
  'AuthService',
  'FeatureFlagsApi',
  'FeatureFlags',
  'USER_ROLES',
  'Session',
  '$state',
  function (
    $scope,
    $translate,
    $uibModal,
    $stateParams,
    AdminApiService,
    AuthService,
    FeatureFlagsApi,
    FeatureFlags,
    USER_ROLES,
    Session,
    $state
  ) {
    // TODO: Update page title and description for accessibility with input from design and UX team
    $('.management-css').prop('disabled', false);
    $scope.showBackNavigation = false;
    $scope.adminApiService = AdminApiService;
    $scope.company = angular.copy($stateParams.company || {});
    function sameCompany() {
      return $scope.company?._id === Session.getUserCompany()?._id;
    }
    if (!AuthService.isAuthorized([USER_ROLES.admin]) && !sameCompany()) {
      $state.go('unavailable');
    }
    $scope.translate = function translate(key) {
      return $translate.instant(key);
    };
    $scope.goBack = function goBack() {
      return $state.go('admin.company', { companyId: $scope.company._id, company: $scope.company });
    };
    function reload() {
      $state.go(
        $state.current.name,
        { companyId: $stateParams.companyId, company: $stateParams.company },
        { reload: true, inherit: false }
      );
    }

    $scope.init = async function init() {
      const result = await FeatureFlagsApi.isFeatureEnabled(FeatureFlags.CUSTOMER_ANALYTICS);
      if (!result) {
        $state.go('unavailable');
        return;
      }
      $scope.showBackNavigation = AuthService.isAuthorized([
        USER_ROLES.admin,
        USER_ROLES.reseller,
        USER_ROLES.companyAdmin,
      ]);

      if ($stateParams.registrationRequest) {
        $scope.processJWT = true;
        $scope.registrationRequest = $stateParams.registrationRequest;
      } else if ($stateParams.integrationId) {
        $scope.processJWT = false;
        $scope.integrationId = $stateParams.integrationId;
      }
      if ($scope.registrationRequest || $scope.integrationId) {
        $uibModal
          .open(
            {
              templateUrl: integrationsRegisterTemplate,
              controller: 'ManagementAdminCompanyIntegrationsRegisterController',
              size: 'lg',
              scope: $scope,
              windowClass: 'ninety-percent-modal',
              backdrop: 'static',
            },
            'edit'
          )
          .result.then(reload)
          .catch(angular.noop);
      }
    };

    $scope.init();
  },
]);
