import angular from 'angular';
import MODULE from './module';
import { featureFlags } from '@scw/react-components';

const app = angular.module(MODULE);

// A partial list of LaunchDarkly feature flag names.
// See also the list in packages/react-components/src/common/helpers/featureFlags.ts
app.constant('FeatureFlags', featureFlags);

app.service('FeatureFlagsApi', [
  'HttpConfigService',
  '$http',
  '$window',
  'SessionId',
  function (HttpConfigService, $http, $window, SessionId) {
    const service = {};
    let sessionBasedCache = {};

    function settingsEndpoint(flag) {
      return $window.SCW_ENV.ApiEndpoint + '/feature-flags/settings/' + flag;
    }

    function getCacheForCurrentSession() {
      const sessionId = SessionId.get();
      if (!(sessionId in sessionBasedCache)) {
        sessionBasedCache[sessionId] = {};
      }

      return sessionBasedCache[sessionId];
    }

    service.clearCacheForFeatures = function (features) {
      const cache = getCacheForCurrentSession();

      for (const feature of features) {
        if (feature.name in cache) {
          delete cache[feature.name];
        }
      }
    };

    service.clearAllUserCache = function () {
      sessionBasedCache = {};
    };

    service.isFeatureEnabled = function (flag) {
      const cache = getCacheForCurrentSession();
      if (flag in cache) {
        return Promise.resolve(cache[flag]);
      }

      return $http
        .get(settingsEndpoint(flag), HttpConfigService.getHttpConfigNoIFR())
        .then(function (resp) {
          const enabled = resp.data && typeof resp.data.status === 'boolean' ? resp.data.status : false;
          cache[flag] = enabled;
          return enabled;
        })
        .catch(function (_err) {
          return false;
        });
    };

    return service;
  },
]);
