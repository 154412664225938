export const cybermonActivities = (sendEvent) => ({
    viewActivity: (activity) => sendEvent({
        event: 'View Activity',
        program: 'cybermon',
        activityType: activity.type,
        activityTitle: activity.title,
        activityStatus: activity.status,
    }),
});
export const cybermonEvents = (sendEvent) => ({
    viewBadge: (achievement) => sendEvent({
        event: 'View Badge',
        program: 'cybermon',
        achievement,
    }),
    showAnimation: (achievement) => sendEvent({
        event: 'Show Animation',
        program: 'cybermon',
        achievement,
    }),
    exploreActivities: (achievement) => sendEvent({
        event: 'Explore Activities',
        program: 'cybermon',
        achievement,
    }),
});
