export const goalAnalytics = (sendEvent) => ({
    onGoalDrawerExpandToggle: (expanded) => {
        sendEvent({ event: 'Goal sidebar expand toggled', expanded });
    },
    onGoalExpandToggle: (expanded, goalName) => {
        sendEvent({ event: 'Goal expand toggled', expanded, goalName });
    },
    onTournamentObjectiveAction: (action, tournamentId, name) => {
        sendEvent({ event: 'Goal objective action', type: 'tournament', action, tournamentId, name });
    },
    onActivityObjectiveAction: (action, activityType, categoryId, scope) => {
        sendEvent({ event: 'Goal objective action', type: 'activity', action, activityType, categoryId, scope });
    },
    onCategoryObjectiveAction: (action, categoryId, scope) => {
        sendEvent({ event: 'Goal objective action', type: 'category', action, categoryId, scope });
    },
});
