import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('ExportAuditLogCSVFn', [
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  function ($translate, AdminApiService, ErrorHandler) {
    return function ExportAuditLogCSV(query) {
      AdminApiService.addLoading();
      AdminApiService.exportAuditLogCSV(query)
        .then(function (data) {
          if (data.url) {
            var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            if (window.navigator.msSaveBlob && isIE) {
              $translate(
                [
                  'CSV_REPORT_READY',
                  'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
                ],
                { dataUrl: data.url }
              ).then(function (translations) {
                // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
                swal({
                  title: translations.CSV_REPORT_READY,
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
              });
            } else {
              document.location.href = data.url;
              swal.close();
            }
          } else {
            $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
              function (translations) {
                swal({
                  title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                  text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: translations.OK,
                });
              }
            );
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_AUDIT_LOG_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_AUDIT_LOG_CSV, response);
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };
  },
]);
