import MODULE from './module';
export default MODULE;

import './tournaments.constants';
import './tournaments.main';
import './tournaments.not-licensed';
import './tournaments.scoring-info';
import './tournaments.stats-mini';
import './tournaments.admin.edit';
import './tournaments.api';
import './tournaments.challenge';
import './tournaments.list';
import './tournaments.list.item';
import './tournaments.live-status';
import './tournaments.player-home';
import './tournaments.new';
import './tournaments';
