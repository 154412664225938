export const statusKeys = ['all', 'in_progress', 'scheduled', 'finished', 'archived', 'draft'];
export const statusToBadgeMap = {
    in_progress: 'accent2',
    scheduled: 'primary',
    finished: 'accent4',
    archived: 'default',
    draft: 'default',
};
export const goalsAdminUrls = {
    listPage: `/admin/goals`,
    createPage: `/admin/goals/create`,
    detailsPage: (goalId) => `/admin/goals/${goalId}`,
};
