import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useMultiassessmentsApi } from './hooks';
import { Pending, useApiLoad } from '../Api.hooks';
import { P, match } from 'ts-pattern';
import { EditMultiAssessmentPage } from './EditMultiAssessmentPage';
import { EditMultiAssessmentPageSkeleton } from './EditMultiAssessmentPage.skeleton';
import { useTranslation } from 'react-i18next';
import { usePlatformNavigate } from '../navigation';
import { multiassessmentAnalytis, useLoadAnalytics } from './analytics';
export function ProgramStageBulkEditorRoute() {
    const { programId, stageId } = useParams();
    if (!programId) {
        throw new Error('Missing programId');
    }
    if (!stageId) {
        throw new Error('Missing stageId');
    }
    return _jsx(ProgramStageBulkEditorLoader, { programId: programId, stageId: stageId });
}
export function ProgramStageBulkEditorLoader({ programId, stageId }) {
    const api = useMultiassessmentsApi();
    const responseEssentials = useApiLoad(api.getEditingEssentials.query, undefined);
    const response = useApiLoad(api.bulkEdit.programStage.query, { programId, stageId });
    const { t } = useTranslation();
    const navigate = usePlatformNavigate();
    useLoadAnalytics(multiassessmentAnalytis.openBulkEditProgramStage(programId, stageId));
    return match(response)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingProgram') }))
        .otherwise((response) => match(responseEssentials)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingEditorData') }))
        .otherwise((essentials) => (_jsx(EditMultiAssessmentPage, { multiassessment: Object.assign(Object.assign({}, response.multiassessment), { _id: undefined, bulkEdit: { k: 'program', programId, stageId } }), assessments: response.assessments, references: response.references, essentials: essentials, userHasEditAccess: response.hasEditAccess, readOnlyAssessmentIds: response.readOnlyAssessmentIds, backText: t('pages.multiassessments.backToProgram'), onBack: () => navigate.toState('programs.manage', { programId }), header: response.programStageContext.name, subheader: t('pages.multiassessments.levelNameAssessmentGroup', {
            levelName: response.programStageContext.levelName,
            assessmentGroupNumber: response.programStageContext.assessmentGroupIndex + 1,
        }), contextKey: { k: 'program', programId, stageId } }))));
}
export function CourseBulkEditorRoute() {
    const { courseId } = useParams();
    if (!courseId) {
        throw new Error('Missing courseId');
    }
    return _jsx(CourseBulkEditorLoader, { courseId: courseId });
}
export function CourseBulkEditorLoader({ courseId }) {
    const api = useMultiassessmentsApi();
    const responseEssentials = useApiLoad(api.getEditingEssentials.query, undefined);
    const response = useApiLoad(api.bulkEdit.course.query, { courseId });
    const { t } = useTranslation();
    const navigate = usePlatformNavigate();
    useLoadAnalytics(multiassessmentAnalytis.openBulkEditCourse(courseId));
    return match(response)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingCourse') }))
        .otherwise((response) => match(responseEssentials)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingEditorData') }))
        .otherwise((essentials) => (_jsx(EditMultiAssessmentPage, { multiassessment: Object.assign(Object.assign({}, response.multiassessment), { _id: undefined, bulkEdit: { k: 'course', courseId } }), assessments: response.assessments, references: response.references, essentials: essentials, userHasEditAccess: response.hasEditAccess, readOnlyAssessmentIds: response.readOnlyAssessmentIds, backText: t('pages.multiassessments.backToCourse'), onBack: () => navigate.toState('courses.manage', { courseId }), header: t('pages.multiassessments.endOfCourseMultiAssessment'), subheader: response.references.find((ref) => ref.k === 'course' && ref.courseId === courseId).name, contextKey: { k: 'course', courseId } }))));
}
export function LevelGroupingBulkEditorRoute() {
    const { levelGroupingId } = useParams();
    if (!levelGroupingId) {
        throw new Error('Missing levelGroupingId');
    }
    return _jsx(LevelGroupingBulkEditorLoader, { levelGroupingId: levelGroupingId });
}
function LevelGroupingBulkEditorLoader({ levelGroupingId }) {
    const api = useMultiassessmentsApi();
    const responseEssentials = useApiLoad(api.getEditingEssentials.query, undefined);
    const response = useApiLoad(api.bulkEdit.levelGrouping.query, { levelGroupingId });
    const { t } = useTranslation();
    const navigate = usePlatformNavigate();
    useLoadAnalytics(multiassessmentAnalytis.openBulkEditLevelGrouping(levelGroupingId));
    return match(response)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingLevelGroup') }))
        .otherwise((response) => match(responseEssentials)
        .with(Pending, () => _jsx(EditMultiAssessmentPageSkeleton, {}))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingEditorData') }))
        .otherwise((essentials) => (_jsx(EditMultiAssessmentPage, { multiassessment: Object.assign(Object.assign({}, response.multiassessment), { _id: undefined, bulkEdit: { k: 'levelGrouping', levelGroupingId } }), assessments: response.assessments, references: response.references, essentials: essentials, userHasEditAccess: response.hasEditAccess, readOnlyAssessmentIds: response.readOnlyAssessmentIds, backText: t('pages.multiassessments.backToAssessments'), onBack: () => navigate.toState('assessments.list'), header: t('pages.multiassessments.levelGroupingMultiAssessment'), subheader: essentials.levelGroupings.find((levelGroup) => levelGroup._id === levelGroupingId).name }))));
}
