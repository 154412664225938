import { Box, Stack, styled, Modal as MuiModal, Paper } from '@mui/material';
import { Toaster } from '../common/Toaster/Toaster';
export const CommunicationsSection = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(7, 6),
    backgroundColor: theme.palette.container.fill.card1,
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.container.border.default}`,
    gap: theme.spacing(8),
}));
export const CommunicationItemContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 1fr',
    gridTemplateRows: 'auto 1fr',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'switch title right' '. caption right'",
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateAreas: "'switch title' '. caption' '. right'",
        rowGap: theme.spacing(2),
    },
}));
export const WandaModal = styled(MuiModal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: theme.spacing(10),
}));
export const WandaModalContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.container.fill.card1,
    borderRadius: theme.spacing(3),
    border: theme.palette.container.border.default,
    paddingBlock: theme.spacing(8),
    width: 'min(100%, 1280px)',
    position: 'relative',
    maxHeight: 'calc(100vh - 64px)',
}));
export const WandaModalSection = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'footer',
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    ['&:only-child']: {
        padding: theme.spacing(0, 7, 0, 7),
    },
}), (props) => ({
    padding: props.footer ? props.theme.spacing(7, 7, 0, 7) : props.theme.spacing(0, 7, 7, 7),
    borderTop: props.footer ? `1px solid ${props.theme.palette.container.border.default}` : 'none',
}));
export const DialogContainer = styled(Paper)(({ theme }) => ({
    background: theme.palette.background.default,
    borderRadius: theme.spacing(3),
    border: theme.palette.container.border.default,
    paddingBlock: theme.spacing(8),
    width: '500px',
    position: 'relative',
}));
export const CommunicationsToaster = styled(Toaster)({
    top: 'calc(64px + 24px) !important',
});
export const CommunicationsToasterMessage = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
}));
