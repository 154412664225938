export const appBarMenuItemStyles = (mobile, theme) => ({
    root: Object.assign(Object.assign({ outline: 'none', textDecoration: 'none' }, (mobile ? { padding: '4px 0 4px 40px' } : { padding: '12px 24px 12px 16px' })), { '.Mui-disabled': {
            opacity: '0.8',
        }, '.MuiListItemIcon-root': {
            display: 'flex',
            justifyContent: 'center',
            minWidth: '32px',
            width: '40px',
            color: 'inherit',
        }, '.MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(22),
        }, '.MuiTypography-root': Object.assign({ color: 'inherit', textDecoration: 'none' }, (!mobile
            ? {
                fontSize: theme.typography.pxToRem(14),
                letterSpacing: theme.typography.pxToRem(0.25),
                lineHeight: theme.typography.pxToRem(20),
            }
            : {})), '&:hover, &:focus': {
            backgroundColor: theme.palette.grayscale.bg4,
            outline: 'none',
            textDecoration: 'none',
            color: theme.palette.primary.main,
        } }),
});
