import { jsx as _jsx } from "react/jsx-runtime";
import { PauseRounded, CheckRounded, LockRounded, PlayArrowRounded, Close } from '@mui/icons-material';
import { StatusBadge as StyledStatusBadge } from '../styled/StatusBadge.styled';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../common/Tooltip';
const iconMap = {
    not_started: PlayArrowRounded,
    in_progress: PauseRounded,
    completed: CheckRounded,
    undiscovered: LockRounded,
    missed: Close,
};
const statusTranslations = {
    not_started: 'common.status.notStarted',
    in_progress: 'common.status.inProgress',
    completed: 'common.status.completed',
    undiscovered: 'common.status.undiscovered',
    missed: 'common.status.undiscovered',
};
export const StatusBadge = ({ status }) => {
    const { t } = useTranslation();
    const StatusIcon = iconMap[status];
    return (_jsx(StyledStatusBadge, { children: _jsx(Tooltip, { title: t(statusTranslations[status]), arrow: true, children: _jsx("div", { className: `play-circle ${status}`, children: _jsx(StatusIcon, { "aria-label": t(statusTranslations[status]), "aria-hidden": false }) }) }) }));
};
