export const asObject = (as, keyFn) => as.reduce((result, entry) => {
    result[keyFn(entry)] = entry;
    return result;
}, {});
export const group = (array, key) => {
    const result = {};
    for (const item of array) {
        const k = key(item);
        if (!result[k])
            result[k] = [];
        result[k].push(item);
    }
    return result;
};
export const groupV = (array, key, element) => {
    const result = {};
    for (const item of array) {
        const k = key(item);
        if (!result[k])
            result[k] = [];
        result[k].push(element(item));
    }
    return result;
};
export const lookup = (array, key) => {
    const result = {};
    for (const item of array) {
        const k = key(item);
        if (k in result) {
            throw new Error(`Duplicate key ${k}`);
        }
        result[k] = item;
    }
    return result;
};
export const lookupV = (array, key, value) => {
    const result = {};
    for (const item of array) {
        const k = key(item);
        if (k in result) {
            throw new Error(`Duplicate key ${k}`);
        }
        result[k] = value(item);
    }
    return result;
};
export const intersection = (a, b) => a.filter((x) => b.includes(x));
export const uniq = (a) => Array.from(new Set(a));
export const uniqBy = (a, fn) => {
    const seen = new Set();
    return a.filter((v) => {
        const key = fn(v);
        if (seen.has(key)) {
            return false;
        }
        else {
            seen.add(key);
            return true;
        }
    });
};
