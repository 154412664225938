import angular from 'angular';
import MODULE from './module';
import templateUrl from './admin.companies.security.diff.html';

angular.module(MODULE).controller('CompanySecurityModalController', [
  '$window',
  '$uibModalInstance',
  '$scope',
  '$translate',
  '$timeout',
  '$q',
  '$http',
  '$diffObject',
  '$swal',
  'AdminApiService',
  'ErrorHandler',
  'company',
  function (
    $window,
    $uibModalInstance,
    $scope,
    $translate,
    $timeout,
    $q,
    $http,
    $diffObject,
    $swal,
    AdminApiService,
    ErrorHandler,
    company
  ) {
    // @tmp
    $window._companySecurity = $scope;
    var mappingStrategyi18nBase = 'Admin.Company.Security.SSO.MappingStrategies.';
    var mappingStrategies = [
      {
        name: $translate.instant(mappingStrategyi18nBase + 'authonly.name'),
        description: $translate.instant(mappingStrategyi18nBase + 'authonly.description'),
        type: 'authonly',
      },
      {
        name: $translate.instant(mappingStrategyi18nBase + 'discover.name'),
        description: $translate.instant(mappingStrategyi18nBase + 'discover.description'),
        type: 'discover',
      },
      {
        name: $translate.instant(mappingStrategyi18nBase + 'attribute.name'),
        description: $translate.instant(mappingStrategyi18nBase + 'attribute.description'),
        type: 'attribute',
      },
      {
        name: $translate.instant(mappingStrategyi18nBase + 'prefix.name'),
        description: $translate.instant(mappingStrategyi18nBase + 'prefix.description'),
        type: 'prefix',
      },
      {
        name: $translate.instant(mappingStrategyi18nBase + 'regex_prefix.name'),
        description: $translate.instant(mappingStrategyi18nBase + 'regex_prefix.description'),
        type: 'regex_prefix',
      },
    ];
    var ui = {
      tabs: {
        activeIndex: 1,
      },
    };

    // Begin: basic initialization
    var securityGroupTemplate = {
      strategy: 'saml',
      saml: {
        mappings: {
          strategy: 'authonly',
          defaultTeam: false,
          defaultRole: false,
        },
      },
      $$forms: {
        addDomain: {},
        import: {},
        name: {},
      },
    };

    var sourcedGroups = _.map(_.cloneDeep(company.securityGroups || []), function (group) {
      return _.merge({}, securityGroupTemplate, group);
    });
    sourcedGroups.flat = _.flatten(sourcedGroups);
    var securityGroups = _.cloneDeep(sourcedGroups);
    securityGroups = _.map(securityGroups, function (entry, idx) {
      return _.merge(
        {
          name: 'Group #' + idx,
        },
        entry
      );
    });
    // End: basic initialization

    // Begin: exposed attributes
    $scope.mappingStrategies = mappingStrategies;
    $scope.securityGroups = securityGroups;
    $scope.ui = ui;
    $scope.hasChanged = false;
    // End: exposed attributes

    // Begin: exposed methods
    $scope.addGroup = addGroup;
    $scope.selectGroup = selectGroup;
    $scope.removeGroup = removeGroup;
    $scope.editName = editName;
    $scope.addSamlDomain = addSamlDomain;
    $scope.removeSamlDomain = removeSamlDomain;
    $scope.saveAll = saveAll;
    $scope.cancel = cancel;
    $scope.uploadMetadata = uploadMetadata;
    $scope.fetchMetadata = fetchMetadata;
    // End: exposed methods

    function selectGroup(group) {
      $scope.$selectedGroup = group;
    }

    function getTranslationKey(key) {
      key = key || '';
      var match = key.match(/[a-z.]+[a-z]/i);
      key = match && match[0].split('.');
      key = key[key.length - 1]; // just last bit is enough

      if (!key) return 'INVALID_TRANSLATION';

      return 'Admin.Company.Security.SSO.SaveBehavior.Fields.' + key;
    }

    function addGroup() {
      $scope.hasChanged = true;
      var newGroup = _.merge(
        {
          name: 'Group #' + (securityGroups.length + 1),
        },
        securityGroupTemplate
      );
      securityGroups.push(newGroup);
      if (event && event.target) {
        var tabGroup = angular.element(event.target).closest('.nav-tabs');
        $timeout(function () {
          var tabLinks = tabGroup.find('li a');
          angular
            .element(tabLinks[tabLinks.length - 2])
            .click()
            .focus();
        }, 200);
        event.preventDefault();
      }
    }

    function removeGroup(group) {
      removeGroup.firstClick = !removeGroup.firstClick;
      if (removeGroup.firstClick) {
        $timeout(function () {
          removeGroup.firstClick = false;
        }, 4000);
        return;
      }

      swal(
        {
          type: 'warning',
          title: $translate.instant('Admin.Company.Security.SSO.RemoveGroupWarning.title'),
          text: $translate.instant('Admin.Company.Security.SSO.RemoveGroupWarning.text'),
          showCancelButton: true,
          confirmButtonText: $translate.instant('OK'),
          cancelButtonText: $translate.instant('CANCEL'),
        },
        function (confirmed) {
          if (confirmed) {
            var idx = securityGroups.indexOf(group);
            if (idx < 0) return;

            if (sourcedGroups[idx])
              // remove original copy
              sourcedGroups.splice(idx, 1);

            securityGroups.splice(idx, 1);
            $scope.hasChanged = true;
          }
        }
      );
    }

    function editName(group, newName) {
      if (!newName) {
        group.$$forms.name.input = group.name;
        group.$$forms.name.visible = true;

        // testability
        if (event && event.target) {
          var tabHeading = angular.element(event.target).closest('uib-tab-heading');
          $timeout(function () {
            tabHeading.find('input').focus();
          }, 100);
          event.preventDefault();
        }
        return;
      }

      group.name = newName;
      group.$$forms.name.visible = false;
    }

    function addSamlDomain(group, domain) {
      var domains = group.saml.domains || [];
      domain = domain.toLowerCase();
      _.set(group.$$forms, 'addDomain.input', '');

      if (domains.indexOf(domain) > -1) return;

      domains.push(domain);

      checkIfDomainIsInUse(domain).then((domainIsInUse) => {
        if (domainIsInUse) {
          return swal(
            {
              title: $translate.instant('Admin.Company.Security.SSO.DuplicateDomainWarning.title'),
              text: $translate.instant('Admin.Company.Security.SSO.DuplicateDomainWarning.text'),
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: $translate.instant('CANCEL'),
              confirmButtonText: $translate.instant('DUPLICATE'),
            },
            function (confirmed) {
              if (confirmed) {
                group.saml.domains = domains;
              } else if (!confirmed) {
                removeSamlDomain(group, domain);
              }
            }
          );
        } else {
          group.saml.domains = domains;
        }
      });
    }

    async function checkIfDomainIsInUse(domain) {
      return await AdminApiService.checkForDuplicateDomains(domain);
    }

    function removeSamlDomain(group, domain) {
      var domains = group.saml.domains;
      if (domains === undefined) {
        return;
      }

      if (domains.length === 1) {
        return swal({
          title: $translate.instant('Admin.Company.Security.SSO.DeleteLastDomainWarning.title'),
          text: $translate.instant('Admin.Company.Security.SSO.DeleteLastDomainWarning.text'),
          type: 'warning',
          showCancelButton: false,
          confirmButtonText: $translate.instant('CANCEL'),
        });
      }
      domains.splice(domains.indexOf(domain), 1);
    }

    function isBoolean(value) {
      return value === true || value === false;
    }

    function calculateChanges() {
      // get rid of groups without changes
      return _.chain(sourcedGroups)
        .map(function (group, key) {
          var result = $diffObject.changedOnly(group, securityGroups[key], {
            ignore: /\$\$/,
            include: /^(name|saml.domains.[0-9])$/,
          });
          return result;
        })
        .reduce(function (result, group) {
          // name is always there, double check for changes
          var changed = _(group)
            .keys()
            .filter(function (key) {
              return group[key].status !== 'unchanged';
            })
            .value();

          if (changed.length) result.push(group);

          return result;
        }, [])
        .value();
    }

    function saveAll(confirmed) {
      if (!confirmed) {
        var diffGroups = calculateChanges();
        if (diffGroups && diffGroups.length)
          return $swal({
            type: 'warning',
            title: $translate.instant('Admin.Company.Security.SSO.SaveBehavior.hasChangesHeader'),
            showCancelButton: true,
            confirmButtonText: $translate.instant('OK'),
            cancelButtonText: $translate.instant('CANCEL'),
            customClass: 'swal-self-adjust',
            templateUrl,
            scope: {
              isBoolean: isBoolean,
              getTranslationKey: getTranslationKey,
              diffGroups: diffGroups,
            },
          }).then(function (confirmed) {
            $scope.hasChanged = true;
            if (confirmed) {
              return saveAll(confirmed);
            }
          });
      }

      if (!$scope.hasChanged) {
        $uibModalInstance.close();
        return $q.resolve();
      }

      saveAll.inprogress = true;

      return AdminApiService.updateCompanySecurity(company, securityGroups)
        .then(function (R) {
          saveAll.inprogress = false;
          $uibModalInstance.close();
          return R;
        })
        .catch(function (response) {
          /* add timeout to prevent the removal of review changes modal to auto-remove this modal */
          $timeout(function () {
            ErrorHandler.addHttpError($translate.instant('ERROR_UPDATING_COMPANY_SECURITY'), response);
          });
        })
        .finally(function () {
          saveAll.inprogress = false;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function showError(message) {
      swal({
        title: 'Computer says NO!',
        text: message,
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Thanks for letting me know :-(',
      });
    }

    function parseMetadata(group, data) {
      var xmlDoc = $.parseXML(data);
      var p = $(xmlDoc);
      // Get the Entity ID
      group.saml.url = group.saml.url || {};
      group.saml.url.spInit = $(
        "SingleSignOnService[Binding='urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect']",
        xmlDoc
      ).attr('Location');

      // Find the Keydescriptor.
      var IDPSSODescriptor = p.find('IDPSSODescriptor');
      var KeyDescriptors = p.find('KeyDescriptor');
      if (KeyDescriptors.length > 0) {
        // Find Certificate
        var keydescriptor = KeyDescriptors[0];
        if (KeyDescriptors.length > 1) {
          // Find the correct one!
          keydescriptor = IDPSSODescriptor.find("KeyDescriptor[use='signing']");
        }
        // Now find the cert
        var potentialKeyNodes = $(keydescriptor)
          .find('*')
          .filter(function () {
            return this.nodeName.toLowerCase().indexOf('x509certificate') > -1;
          });
        potentialKeyNodes = potentialKeyNodes.toArray();
        potentialKeyNodes.some(function (node) {
          var k = p.find(node).text();
          if (k.length > 0) {
            group.saml.key = k;
            return true;
          }
          return false;
        });
      }
    }

    function uploadMetadata(group, file) {
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = function () {
          parseMetadata(group, reader.result);
        };
      }
    }

    function fetchMetadata(group, url) {
      if (url && url.length > 0) {
        $http
          .get(url)
          .then(function (resp) {
            parseMetadata(group, resp.data);
          })
          .catch(function (response) {
            showError('Error fetching Metadata: ' + (response.statusText || response.data.error));
          });
      }
    }
  },
]);
