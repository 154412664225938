import angular from 'angular';
import * as _ from 'lodash';
import MODULE from './module';
import templateUrl from './tournaments.scoring-info.html';
import companyTournamentLeaderboard from './tournaments.live-status-company.html';
import globalTournamentLeaderboard from './tournaments.live-status-global.html';

const app = angular.module(MODULE).controller('TournamentLiveStatusController', [
  '$window',
  '$uibModal',
  '$scope',
  '$rootScope',
  '$interval',
  '$state',
  '$stateParams',
  '$translate',
  '$back',
  'TournamentsApiService',
  'localeHelpers',
  'ErrorHandler',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $window,
    $uibModal,
    $scope,
    $rootScope,
    $interval,
    $state,
    $stateParams,
    $translate,
    $back,
    TournamentsApiService,
    localeHelpers,
    ErrorHandler,
    AnalyticsService,
    AnalyticsEvents
  ) {
    // @tmp
    $window._tournamentStats = $scope;

    var tournament,
      leaderboardOptions,
      intervals = {};

    // Begin: exposed methods and data
    $scope.forceShowLeaderboard = forceShowLeaderboard;
    $scope.goBack = $back().or('tournaments.list', $stateParams).asFunction();
    $scope.forms = {};
    $scope.tournamentData = {};
    $scope.now = Date.now();
    $scope.isShowingCountdown = false;
    $scope.isShowingLiveStats = true;
    $scope.openScoringInfoModal = openScoringInfoModal;
    $scope.language = $translate.use();
    $scope.timer = { lang: localeHelpers.timerLanguageFor($scope.language) };
    // End: exposed methods and data

    $scope.viewStarted = new Date();
    $scope.logAmplitudeEvent = logAmplitudeEvent;
    // Begin: timers
    intervals.now = $interval(now, 1000);
    intervals.dataRefresh = $interval(init, 45000);
    intervals.columnRotation = $interval(rotateColumns, 5000);
    intervals.statsRotation = $interval(rotateStats, 5000);
    // End: timers

    $scope.intervals = {};
    $scope.intervals.columnRotation = intervals.columnRotation;
    $scope.toggleAutoScroll = toggleAutoScroll;
    $scope.autoScrollState = $scope.intervals.columnRotation ? true : false;

    // TODO: replace all this copy&paste pagination code with <search-base>
    $scope.page = {
      options: { size: $rootScope.pagerPrefs.itemsPerPage },
    };
    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        delete $scope.page.options.page;
        $scope.page.options.size = newVal;
        if ($scope.tournament) getLeaderboard();
      }
    });
    $scope.pagerMaxSize = 10;
    $scope.pagerChanged = getLeaderboard;

    // Begin: life-cycle management
    $scope.$on('$destroy', function () {
      $scope.logAmplitudeEvent(new Date());
      _.forOwn(intervals, function (value, _key) {
        $interval.cancel(value);
      });
    });

    $scope.isAudienceRestrictedTournament = false;

    init();
    // End: life-cycle management

    function init() {
      $scope.companyLeaderboardTmpl = companyTournamentLeaderboard;
      $scope.globalLeaderboardTmpl = globalTournamentLeaderboard;

      return TournamentsApiService.getTournament($stateParams.id)
        .then(function (data) {
          $scope.processTournamentData(data);
          $scope.tournament = tournament = data;
          $scope.leaderboardOptions = leaderboardOptions = tournament.leaderboardOptions.options;

          $scope.showBigCountdown =
            tournament.finishTime - $scope.now <= leaderboardOptions.hideBeforeEnd &&
            leaderboardOptions.isDisplayed === true &&
            tournament.finishTime > $scope.now;

          $scope.timeThreshold = 180; // time in seconds - go into final countdown mode at 3 mins unless configured
          if (leaderboardOptions.hideBeforeEnd) {
            $scope.timeThreshold = leaderboardOptions.hideBeforeEnd / 1000 + 1;
          }

          getLeaderboard();
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_LOADING_TOURNAMENT'), response);
          $back().or('tournaments.list').go();
        });
    }

    function now() {
      $scope.now = Date.now();
    }

    function getProgressBarType(percent) {
      var values = ['danger', 'warning', 'info', 'success'];
      return values[Math.floor(percent / 25)];
    }

    function stitchQuestProgressStory(developer, languageSelected) {
      if (languageSelected) {
        developer.displayLanguage = $rootScope.metadata.languages[developer.language._id].name;
        developer.displayFramework =
          $rootScope.metadata.languages[developer.language._id].framework[developer.language._framework].name;
      }

      for (var i = 0; i < developer.stats.quests.length; i++) {
        var quest = developer.stats.quests[i];
        quest.displayComplete = quest.challengeSummary.done;
        quest.displayTotal = quest.challengeSummary.total;
        quest.displayIncomplete = quest.displayTotal - quest.displayComplete;
        quest.displayCompletePercent = quest.challengeSummary.displayPercentage;
        quest.displayCompleteType = getProgressBarType(quest.displayCompletePercent);
        quest.name = $scope.tournament.questNames[i].name;
      }

      developer.displayComplete = developer.stats.challengeSummary.done;
      developer.displayTotal = developer.stats.challengeSummary.total;
      developer.displayIncomplete = developer.displayTotal - developer.displayComplete;
      developer.displayCompletePercent = developer.stats.challengeSummary.displayPercentage;
      developer.displayCompleteType = getProgressBarType(developer.displayCompletePercent);
    }

    $scope.determineIfTournamentIsRestricted = function (tournament) {
      return Array.isArray(tournament?.audiences) && tournament.audiences.length > 0;
    };

    $scope.getLeaderboardForGlobalTournaments = function () {
      TournamentsApiService.getGlobalLeaderboard($scope.tournament._id).then(function (leaderboard) {
        $scope.isShowingLiveStats = !leaderboard.hidden;
        $scope.tournamentData.leaderboard = leaderboard;
        $scope.tournamentData.developerTableData = leaderboard.users;
      });
    };

    $scope.getLeaderboardForEntityTournaments = function () {
      TournamentsApiService.getLeaderboard($scope.tournament._id, null, $scope.page.options, null).then(
        function (leaderboard) {
          $scope.isShowingLiveStats = !leaderboard.hidden || $scope.isAdminForceView;

          $scope.tournamentData.leaderboard = leaderboard;
          $scope.page.options = leaderboard.list;
          $scope.tournamentData.developerTableData = leaderboard.list.data;

          if (leaderboard.languages) {
            $scope.updateLanguageList(leaderboard.languages);
          }

          if ($scope.tournamentData?.developerTableData) {
            for (var i = 0; i < $scope.tournamentData.developerTableData.length; i++) {
              var developer = $scope.tournamentData.developerTableData[i];

              // only do this if user has selected their language yet, otherwise this will fail
              stitchQuestProgressStory(developer, developer.language && developer.stats && developer.stats.quests);

              developer.totalHintsUsed = developer.stats.hintsUsed;
              developer.totalLivesLost = developer.stats.failedAttempts;

              developer.displayOtherStats = [{ type: 'hintsUsed', isActive: true }, { type: 'livesLost' }];

              developer.rankChange = 'New';
              if (developer.previousRank != -1) developer.rankChange = developer.previousRank - developer.currentRank;
            }
          }
        }
      );
    };

    function getLeaderboard() {
      $scope.isAudienceRestrictedTournament = $scope.determineIfTournamentIsRestricted($scope.tournament);
      if ($scope.isAudienceRestrictedTournament) {
        return $scope.getLeaderboardForGlobalTournaments();
      }
      return $scope.getLeaderboardForEntityTournaments();
    }

    function forceShowLeaderboard() {
      $state.go($state.current.name, angular.extend({}, $stateParams, { forceView: true }));
    }

    function rotateColumns() {
      if ($scope.tournamentData?.developerTableData && !$scope.tournament.public) {
        for (var i = 0; i < $scope.tournamentData.developerTableData.length; i++) {
          var developer = $scope.tournamentData.developerTableData[i];
          developer.stats.questIndex = ++developer.stats.questIndex % developer.stats.quests.length;
        }
      }
    }

    function rotateStats() {
      if ($scope.tournamentData?.developerTableData && !$scope.tournament.public) {
        var inc = rotateStats.inc || 1;
        _.forEach($scope.tournamentData.developerTableData, function (developer, _idx) {
          var statcount = developer.displayOtherStats.length;
          developer.displayOtherStats[(inc - 1) % statcount].isActive = false;
          developer.displayOtherStats[inc % statcount].isActive = true;
        });
        rotateStats.inc = inc + 1;
      }
    }

    function openScoringInfoModal() {
      return $uibModal.open({
        templateUrl,
        controller: 'ScoringInfoModalController',
        size: 'lg',
        scope: $scope,
      });
    }

    function toggleAutoScroll(onOrOff) {
      if (onOrOff) {
        if (!$scope.intervals.columnRotation) {
          $scope.intervals.columnRotation = $interval(rotateColumns, 5000);
        }
        $scope.autoScrollState = true;
      } else {
        $interval.cancel($scope.intervals.columnRotation);
        $scope.intervals.columnRotation = null;
        $scope.autoScrollState = false;
      }
    }

    function logAmplitudeEvent(endTime) {
      if (!$scope.tournament) {
        return;
      }

      const duration = parseInt(Math.abs(endTime - $scope.viewStarted.getTime()) / 1000);
      const eventProps = {
        tournament_id: $scope.tournament._id,
        tournament_name: $scope.tournament.name,
        tournament_languages: $scope.tournament.languages.map((l) => `${l._id}:${l._framework}`),
        total_number_of_challenges: $scope.tournament.numberOfChallenges,
        scoring_configuration: $scope.tournament.scoringPreset,
        time_spent_on_tournament_leaderboard_seconds: duration,
      };
      AnalyticsService.logEvent(AnalyticsEvents.Tournaments.ACCESS_TOURNAMENT_LEADERBOARD, eventProps);
    }
  },
]);

// Directive to access the carousel's controls.
app.directive('carouselControls', function () {
  return {
    restrict: 'A',
    link: function (scope, _element, _attrs) {
      var carousel = scope.$$childTail;
      scope.goNext = function () {
        // scope.toggleAutoScroll();
        carousel.next();
      };
      scope.goPrev = function () {
        // scope.toggleAutoScroll();
        carousel.prev();
      };
    },
  };
});
