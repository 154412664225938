import angular from 'angular';
import MODULE from './module';
import template from './assessments.challenge.header.html';

angular.module(MODULE).directive('assessmentHeader', [
  '$window',
  function ($window) {
    return {
      restrict: 'E',
      scope: {
        started: '=?',
        deadline: '=?',
        progress: '=?',
      },
      templateUrl: template,
      link: function (scope, _element, _attrs) {
        // @tmp
        $window._assessmentHeader = scope;

        let progressTicker = false;
        const styles = ['danger', 'warning', 'info', 'info'];

        scope.timeUsed = 0;
        // scope.stressStyle = styles[1];

        if (scope.deadline) {
          progressTicker = setInterval(tick, 1000);
          tick();
        }

        function tick() {
          const domain = scope.deadline - scope.started;
          const current = scope.deadline - Date.now();
          let progress = (current / domain) * 100;
          if (progress > 100) {
            progress = 100;
            clearInterval(progressTicker);
          }

          scope.timeUsed = progress;
          scope.stressStyle = styles[Math.floor(progress / 25) % 4];
        }

        scope.$on('$destroy', function () {
          clearInterval(progressTicker);
        });
      },
    };
  },
]);
