var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { CloseIconButton } from '../../../common/components/atoms/CloseIconButton';
import { WandaModalContainer, WandaModalSection as ModalSection, CommunicationsToasterMessage, } from '../../communications/Communications.styled';
import { Error as MuiErrorIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Editor } from './Editor';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Toaster } from '../Toaster/Toaster';
import { RocketIcon } from '../icons/RocketIcon';
const useResponsiveModalHeight = () => {
    // Height of the editor's toolbar
    const [toolbarHeight, setToolbarHeight] = useState(0);
    // Height of all sibling sections not including the editor
    const [combinedSiblingHeight, setCombinedSiblingHeight] = useState(0);
    useEffect(() => {
        const observedToolbar = document.querySelector('.ql-toolbar');
        const scwSections = document.querySelectorAll('.scw-section');
        // Calculate the height of all sections. Required for responsive modal height
        const heightOfSections = Array.from(scwSections).reduce((totalHeight, section) => {
            return totalHeight + section.offsetHeight;
        }, 0);
        setCombinedSiblingHeight(heightOfSections);
        // Observe the toolbar height and resize the modal accordingly
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const currentHeight = entry.target.offsetHeight;
                setToolbarHeight(currentHeight);
            }
        });
        if (observedToolbar) {
            resizeObserver.observe(observedToolbar);
        }
        return () => {
            if (observedToolbar) {
                resizeObserver.unobserve(observedToolbar);
            }
        };
    }, []);
    return { toolbarHeight: toolbarHeight, sectionHeights: combinedSiblingHeight };
};
const MAX_MESSAGE_SIZE_MB = 4.5;
const MAX_MESSAGE_SIZE = MAX_MESSAGE_SIZE_MB * 1024 * 1024; // 4.5 MB in bytes
export const RichTextForm = (_a) => {
    var { initialContent, type = 'default', onClose, onCancel, onSave } = _a, props = __rest(_a, ["initialContent", "type", "onClose", "onCancel", "onSave"]);
    const { t } = useTranslation();
    const [value, setValue] = useState(initialContent);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const editorRef = useRef(null);
    const { toolbarHeight, sectionHeights } = useResponsiveModalHeight();
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        setError(null);
        try {
            // Check message size
            const messageSize = new Blob([value]).size;
            if (messageSize > MAX_MESSAGE_SIZE) {
                throw new Error(t('pages.administration.communicationsCentre.error.messageSizeExceeded', {
                    limit: MAX_MESSAGE_SIZE_MB,
                    size: (messageSize / (1024 * 1024)).toFixed(2),
                }));
            }
            yield onSave(value);
            onClose();
        }
        catch (err) {
            setError(err instanceof Error ? err.message : t('pages.administration.communicationsCentre.error.genericSaveError'));
            if (editorRef.current) {
                editorRef.current.focus();
            }
        }
        finally {
            setIsSubmitting(false);
        }
    });
    useEffect(() => {
        if (error && editorRef.current) {
            editorRef.current.focus();
        }
    }, [error]);
    return (_jsxs(WandaModalContainer, { tabIndex: -1, sx: { '--toolbarHeight': `${toolbarHeight}px`, '--sectionHeights': `${sectionHeights + 32}px` }, children: [props.children, _jsx(Toaster, { open: !!error, autoHideDuration: 6000, onClose: () => setError(null), anchorOrigin: { vertical: 'top', horizontal: 'center' }, message: _jsxs(CommunicationsToasterMessage, { children: [_jsx(MuiErrorIcon, { fontSize: "large" }), _jsx(Typography, { children: error })] }), action: _jsx(CloseIconButton, { onClick: () => {
                        setError(null);
                    }, "aria-label": 'Close' }), sx: { position: 'absolute', top: 0, left: 0, right: 0 } }), _jsx(ModalSection, { children: _jsx(Editor, { value: value, onContentChange: setValue, type: type, disabled: isSubmitting, error: !!error }) }), _jsx(ModalSection, { className: "scw-section", footer: true, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: onCancel, disabled: isSubmitting, children: t('pages.administration.communicationsCentre.common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleSave, disabled: isSubmitting, sx: {
                                minWidth: '135px',
                            }, children: isSubmitting ? (_jsx(RocketIcon, { sx: { width: '100%', height: '100%' } })) : (t('pages.administration.communicationsCentre.common.save')) })] }) }), _jsx(CloseIconButton, { "aria-label": "Close", onClick: onClose, sx: {
                    position: 'absolute',
                    right: 10,
                    top: 10,
                } })] }));
};
