import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { useCybermon } from '../hooks/useCybermon';
import { CybermonAchievement } from './CybermonAchievement';
import { useTranslation } from 'react-i18next';
export const WeeklyCybermon = () => {
    const { cybermon } = useCybermon();
    const { t } = useTranslation();
    return (_jsxs(Card, { sx: { display: 'flex', flexDirection: 'column', gap: 6, background: 'none', padding: 5 }, children: [_jsx(Typography, { variant: "h5", children: t('pages.cybermon.badges.title') }), _jsx(Box, { gap: 3, sx: { display: 'grid', gridAutoRows: '80px' }, children: cybermon.map(({ _id, name, active, discovered, custom_data: { images }, earned, image, attempted }, i) => (_jsx(CybermonAchievement, { image: image, name: name, discovered: discovered, active: active, earned: earned, attempted: attempted, images: images, week: i + 1 }, _id))) })] }));
};
