import angular from 'angular';

const MODULE = 'SCWTrainingProgressLeaderboard';
export default MODULE;

const app = angular.module(MODULE, []);

app.controller('TrainingProgressLeaderboardController', [
  '$scope',
  '$log',
  'USER_ROLES',
  'Session',
  'TrainingApiService',
  'TrainingUtils',
  'AnalyticsService',
  'AnalyticsEvents',
  function ($scope, $log, USER_ROLES, Session, TrainingApiService, TrainingUtils, AnalyticsService, AnalyticsEvents) {
    $scope.Math = window.Math;
    $scope.confidenceCalculator = TrainingUtils.confidenceCalculator;
    $scope.selectedCourse = {};

    $scope.isCompanyAdmin = Session.user.roles.indexOf(USER_ROLES.companyAdmin) > -1;
    $scope.isTeamManager = Session.user.roles.indexOf(USER_ROLES.manager) > -1;
    $scope.isSCWAdmin = Session.user.roles.indexOf(USER_ROLES.admin) > -1;

    $scope.progressLeaderboard;
    $scope.pagination = [10, 25, 50];
    $scope.paginationSettings = {
      page: 1,
      maxSize: 5,
      itemsPerPage: $scope.pagination[0],
      totalItems: 0,
    };

    $scope.isFinishedLoading = false; //Stop default population

    $scope.getCourses = () => {
      if ($scope.isCompanyAdmin) {
        return TrainingApiService.getPublishedCourses(Session.user.properties._cid);
      } else if ($scope.isTeamManager) {
        return TrainingApiService.getPublishedCourses(Session.user.properties._tid);
      } else {
        return TrainingApiService.getEnrolledCourses();
      }
    };

    //In the progress leaderboard, we would restrict the courses, the user has enrolled in, else there is no difference here and metrics
    $scope.loadcourses = () =>
      $scope.getCourses().then((response) => {
        $scope.allCourses = [];
        $scope.isFinishedLoading = true; //The courses have been loaded for the page

        // Depending on the Api call made in $scope.getCourses
        // the response structure changes (not consistent)
        // so we had to use an OR condition to provide for all functions
        (response.courses || response).forEach((course) => {
          $scope.allCourses.push({ _id: course._course || course._id, name: course.name });
        });
        if ($scope.allCourses.length > 0) {
          $scope.selectedCourse.course = $scope.allCourses[0];
        } else {
          $scope.selectedCourse.course = null;
        }
      });

    //Refresh the leaderboard with change of dropdown
    $scope.refreshLeaderboard = function (resetPagination) {
      if (!$scope.selectedCourse.course) return;
      if ($scope.isSCWAdmin || $scope.isCompanyAdmin) {
        $scope.entityId = $scope.selectedEntity._id;
      } else {
        $scope.entityId = Session.user.properties._tid;
      }

      TrainingApiService.getManagerPlayerProgressLeaderboard(
        $scope.entityId,
        [$scope.selectedCourse.course._id],
        $scope.filter,
        resetPagination ? 1 : $scope.paginationSettings.page,
        $scope.paginationSettings.itemsPerPage,
        $scope.displayCurrentUser
      )
        .then(function (res) {
          $scope.progressLeaderboard = res;
          $scope.paginationSettings.totalItems = res.total;
          $scope.paginationSettings.page = res.page;
          $scope.displayCurrentUser = false;
        })
        .catch(function (res) {
          $log.debug('Error getting learning path manager metrics player leaderboard', res);
        });
    };

    $scope.getEntities = function (_entity) {
      return TrainingApiService.getManagerEntities(_entity).then(function (res) {
        $scope.entities = res;
        $scope.selectedEntity = $scope.entities[0];
        // $scope.refreshLeaderboard();
      });
    };

    $scope.changeEntity = function () {
      $scope.loadcourses().then(function () {
        $scope.refreshLeaderboard(true);
      });
    };

    $scope.init = function () {
      if ($scope.isSCWAdmin || $scope.isCompanyAdmin) {
        $scope.displayCurrentUser = false;
        $scope
          .getEntities()
          .then(function (_res) {
            return $scope.loadcourses();
          })
          .then(function (_res) {
            return $scope.refreshLeaderboard();
          });
      } else {
        $scope.loadcourses().then(function () {
          $scope.displayCurrentUser = true;
          $scope.refreshLeaderboard();
        });
      }
      $scope.viewStarted = new Date();
    };

    $scope.init();

    //if size of results to display gets changed
    $scope.$watch('size', function () {
      if ($scope.selectedEntity) {
        var courses = $scope.leaderboard.selectedCourses.map(function (c) {
          return c._id;
        });
        TrainingApiService.getManagerPlayerProgressLeaderboard(
          $scope.selectedEntity._id,
          courses,
          $scope.filter,
          $scope.page,
          $scope.size
        )
          .then(function (res) {
            $scope.progressLeaderboard = res;
            $scope.totalPages = Math.ceil(res.total / $scope.size);
            $scope.tabPage = 0;
            $scope.page = 1;
            //updateTabRange();
          })
          .catch(function (res) {
            $log.debug('Error getting learning path manager metrics player leaderboard', res);
          });
      }
    });

    $scope.changeItemsPerPage = function () {
      $scope.paginationSettings.page = 1;
      $scope.refreshLeaderboard();
    };

    $scope.$on('$destroy', function () {
      logAmplitudeEvent(new Date());
    });

    function logAmplitudeEvent(endTime) {
      const duration = parseInt(Math.abs(endTime - $scope.viewStarted.getTime()) / 1000);
      const eventProps = {
        course_id: $scope.selectedCourse.course._id,
        course_name_last_selected: $scope.selectedCourse.course.name,
        company_team_assigned_last_selected: $scope.entityId,
        time_spent_on_courses_leaderboard_seconds: duration,
      };

      AnalyticsService.logEvent(AnalyticsEvents.Courses.ACCESS_LEADERBOARD, eventProps);
    }
  },
]);
