import { z } from 'zod';
import { createSearchParams } from 'react-router-dom';
export const filterStateSchema = z.object({
    language: z.array(z.string()).default([]),
});
export const createFilterState = (params) => {
    var _a;
    return ({
        language: (_a = params === null || params === void 0 ? void 0 : params.getAll('language').filter((l) => !l.startsWith('pseudocode'))) !== null && _a !== void 0 ? _a : [],
    });
};
const FILTER_STATE_KEY = 'explore-filter-state';
const CYBERMON_FILTER_STATE_KEY = 'cybermon-filter-state';
export const saveCybermonFilterState = (state) => {
    try {
        window.localStorage.setItem(CYBERMON_FILTER_STATE_KEY, JSON.stringify(state));
    }
    catch (e) {
        console.error(e);
    }
};
export const getCybermonFilterState = () => {
    var _a;
    try {
        const state = (_a = window.localStorage.getItem(CYBERMON_FILTER_STATE_KEY)) !== null && _a !== void 0 ? _a : '{}';
        return filterStateSchema.parse(JSON.parse(state));
    }
    catch (e) {
        console.error(e);
        return filterStateSchema.parse({});
    }
};
export const isFilterStateEmpty = (state) => {
    return Object.values(state).every((value) => !value.length);
};
export const createQueryString = (state) => {
    const searchParams = createSearchParams(state);
    searchParams.forEach((_v, k) => {
        var _a;
        if (((_a = searchParams.get(k)) === null || _a === void 0 ? void 0 : _a.length) === 0)
            searchParams.delete(k);
    });
    return searchParams.toString();
};
export const createFilterStateFromLocation = (location) => {
    return createFilterState(createSearchParams(location.split('?').pop()));
};
const getInitialState = (initialUserLanguageFrameworks) => {
    const cybermonState = getCybermonState();
    if (cybermonState.language) {
        return cybermonState;
    }
    const exploreState = getFilterState();
    if (exploreState.language) {
        return { language: exploreState.language };
    }
    return { language: initialUserLanguageFrameworks !== null && initialUserLanguageFrameworks !== void 0 ? initialUserLanguageFrameworks : [] };
};
export const syncFilterState = (platformNavigate, initialUserLanguageFrameworks) => {
    try {
        const cybermonState = getInitialState(initialUserLanguageFrameworks);
        setCybermonState(cybermonState, platformNavigate);
    }
    catch (e) {
        console.error('Error saving to Cybermon storage', e);
    }
};
const getFilterState = () => {
    return JSON.parse(window.localStorage.getItem(FILTER_STATE_KEY) || '{}');
};
const getCybermonState = () => {
    return JSON.parse(window.localStorage.getItem(CYBERMON_FILTER_STATE_KEY) || '{}');
};
const setCybermonState = (cybermonState, platformNavigate) => {
    const queryString = createQueryString(cybermonState);
    platformNavigate.setSearchParams(queryString, true);
    window.localStorage.setItem(CYBERMON_FILTER_STATE_KEY, JSON.stringify(cybermonState));
};
