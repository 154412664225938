import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('BulkInviteUserModalController', [
  '$uibModalInstance',
  '$scope',
  '$translate',
  '$log',
  '$q',
  '$timeout',
  'AuthService',
  'AdminApiService',
  'USER_ROLES',
  'request',
  'ErrorHandler',
  function (
    $uibModalInstance,
    $scope,
    $translate,
    $log,
    $q,
    $timeout,
    AuthService,
    AdminApiService,
    USER_ROLES,
    request,
    ErrorHandler
  ) {
    $scope.inprogress = false;
    $scope.cancelled = false;
    $scope.errors = [];
    $scope.progress = { done: 0, total: request.invitees.emails.length };

    $scope.done = done;
    $scope.cancel = cancel;
    $scope.invite = invite;
    $scope.reinviteUser = reinviteUser;

    function done() {
      $scope.$emit('admin:users-updated');
      $uibModalInstance.close();
    }

    function cancel() {
      $scope.cancelled = true;
      $scope.inprogress = false;
      $uibModalInstance.dismiss();
    }

    function errorHandler(email) {
      return function (response) {
        $log.error('Error in invite: ', response);
        $translate(['UNKNOWN_ERROR_OCCURRED', 'USER_WITH_EMAIL_ALREADY_EXISTS', 'CANNOT_INVITE_USER'], {
          email: email,
        }).then(function (translations) {
          var error;

          if (response.data) {
            error = (response.data && response.data.error) || translations.UNKNOWN_ERROR_OCCURRED;
            if (response.data.error && response.data.error.match(/Email address has already been registered/)) {
              error = translations.USER_WITH_EMAIL_ALREADY_EXISTS;
            }
            if (translations[response.data.error]) {
              error = translations[response.data.error];
            }
          } else if (response.email) {
            error = translations.USER_WITH_EMAIL_ALREADY_EXISTS;
          }

          $scope.errors.push({ email: email, error: error });
        });
      };
    }

    function reinviteUser(user) {
      AdminApiService.addLoading();
      AdminApiService.resendInvite(user)
        .then(function (_user) {
          swal({
            title: $translate.instant('INVITATION_RESET'),
            text: $translate.instant('NEW_INVITATION_EMAIL_SENT'),
            type: 'success',
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('FAILED_TO_RESEND_INVITATION'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    }

    function ssoInviteExistingUser(user) {
      var team = user.properties.team;
      var email = user.email;
      var deferred = $q.defer();
      var error = {};

      if (user.properties._tid == request.params.properties._tid) {
        if (user.existing) {
          error = {
            email: user.email,
            error:
              user.status == 'invited'
                ? $translate.instant('USER_ALREADY_INVITED_TO_TEAM')
                : $translate.instant('USER_ALREADY_REGISTERED_TO_TEAM'),
            changeTeam: false,
            userObj: user,
          };
          $scope.errors.push(error);
        }
        return $q.resolve(user);
      }

      // User does exist, but in a different team so remove this flag
      delete user.existing;

      if (
        AuthService.isAuthorized([USER_ROLES.admin, USER_ROLES.companyAdmin]) ||
        (AuthService.isAuthorized([USER_ROLES.manager]) && team.isDefault)
      ) {
        var heading = $translate.instant('USER_ALREADY_EXISTS');
        var msgKey = team.isDefault
          ? 'TRANSFER_USER_FROM_DEFAULT_TEAM_TO_CURRENT_TEAM'
          : 'TRANSFER_USER_FROM_EXISTING_TEAM_TO_CURRENT_TEAM';
        var optionDescription = $translate.instant(msgKey, { email: email, teamName: team.name });

        swal(
          {
            type: 'warning',
            title: heading,
            text: optionDescription,
            confirmButtonText: $translate.instant('OK'),
            cancelButtonText: $translate.instant('IGNORE'),
            showCancelButton: true,
          },
          function (confirmed) {
            if (confirmed) {
              var payload = _.merge({}, user, {
                properties: request.params.properties,
                roles: request.params.roles,
              });
              return AdminApiService.updateUser(user._id, payload).then(deferred.resolve).catch(deferred.reject);
            }

            return deferred.reject(user);
          }
        );

        return deferred.promise;
      }

      var errorMsg = $translate.instant('USER_ASSIGNED_TO_SSO_TEAM_CONTACT_COMPANY_ADMIN', {
        email: user.email,
        teamName: team.name,
      });
      error = {
        email: user.email,
        error: errorMsg,
        inDefaultTeam: team.isDefault,
        changeTeam: false,
        userObj: user,
      };
      $scope.errors.push(error);
      deferred.resolve();

      return deferred.promise;
    }

    function invite(email) {
      $scope.inprogress = true;
      return function () {
        if (!$scope.cancelled) {
          $scope.current = { email: email };
          var invitee = _.cloneDeep(request.params);
          $log.debug('Using params for invite() ', request.params);
          invitee.email = email;
          if (request.invitees.tags && request.invitees.tags[email]) {
            invitee.properties.tags = request.invitees.tags[email];
          }
          // Standalone users won't have any properties at this point so we need to introduce it for them
          if (!invitee.properties) {
            invitee.properties = {};
          }
          invitee.properties.provision = { type: request.invitees.provision_type };
          $log.debug('About to InviteUser()', invitee);
          return AdminApiService.inviteUser(invitee)
            .then(function (user) {
              $log.debug('InviteUser() returned', user);

              if (user.properties.team) {
                return ssoInviteExistingUser(user);
              }

              if (user.existing) {
                var error = {
                  email: user.email,
                  error:
                    user.status == 'invited'
                      ? $translate.instant('USER_ALREADY_INVITED')
                      : $translate.instant('USER_ALREADY_REGISTERED'),
                  changeTeam: false,
                  userObj: user,
                };
                $scope.errors.push(error);

                return $q.resolve(user);
              }

              return user;
            })
            .catch(errorHandler(email))
            .finally(function () {
              $scope.progress.done += 1;
            });
        }
      };
    }

    // chains invite calls so they happen one at a time instead of alltogether
    var promise = $scope.invite(request.invitees.emails[0])();
    for (var i = 1, len = request.invitees.emails.length; i < len; i += 1) {
      promise = promise.then(invite(request.invitees.emails[i])).catch(invite(request.invitees.emails[i]));
    }

    promise.finally(function () {
      $scope.errors.sort(function (a, b) {
        return a.error > b.error;
      });
      if ($scope.errors.length < 1) $timeout(done, 7000);

      $scope.inprogress = false;
    });
  },
]);
