import styled from '@emotion/styled';
import { fontWeightSemiBold } from './styles';
import { styled as muiStyled } from '@mui/material';
export const ModalContainer = muiStyled('div')(({ theme }) => ({
    flexDirection: 'column',
    width: '100%',
    '.modal-header': {
        borderBottom: '1px solid #e5e5e5',
        padding: '15px',
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'normal',
    },
    '.modal-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '20px',
    },
    '.modal-footer': {
        padding: '20px',
        display: 'flex',
        gap: '8px',
        justifyContent: 'flex-end',
        borderTop: '1px solid #e5e5e5',
    },
}));
export const ErrorMessage = styled.div `
  background-color: red;
  padding: 8px;
  border-radius: 4px;
  color: white;
`;
export const FormInputLabels = styled.label `
  font-weight: ${fontWeightSemiBold};
`;
