import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('ManagementAdminSenseiCodebaseController', [
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  'SenseiApiService',
  function ($rootScope, $scope, $state, $stateParams, $translate, AdminApiService, ErrorHandler, SenseiApiService) {
    $scope.AdminApiService = AdminApiService;

    //**** Codebase related operations ****/

    // Check if changes were made to the base codebase
    $scope.changesMade = function () {
      // yes - this call can happen when $scope isn't defined...
      if (!$scope || !$scope.codebase) return false;

      // Codebase related changes
      if ($scope.codebase.newName != $scope.codebase.raw.name) return true;

      return false;
    };

    $scope.saveCodebase = function () {
      // Apply the changes
      var codebaseDetails = {};
      codebaseDetails.name = $scope.codebase.newName;
      // Send to server
      AdminApiService.addLoading();
      SenseiApiService.updateCodebase($scope.codebase.raw._id, codebaseDetails)
        .then(function (response) {
          $scope.codebase.raw = response;
        })
        .catch(function (response) {
          $translate(['FAILED_TO_UPDATE_CODEBASE']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_UPDATE_CODEBASE, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    };

    //**** Rule related operations ****/

    function updateAllRulesList(codebaseId) {
      AdminApiService.addLoading();
      SenseiApiService.getAllRulesets(codebaseId)
        .then(function (allRules) {
          $scope.codebase.allRules = {};
          $scope.codebase.allRules.raw = allRules;
          $scope.codebase.allRules.current = allRules.slice(0);
        })
        .catch(function (response) {
          $translate(['FAILED_TO_GET_RULES']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_GET_RULES, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    }

    function updateDemoRulesList(codebaseId) {
      AdminApiService.addLoading();
      SenseiApiService.getDemoRulesets(codebaseId)
        .then(function (rules) {
          $scope.codebase.demoRules = {};
          $scope.codebase.demoRules.raw = rules;
          $scope.codebase.demoRules.current = rules.slice(0);
        })
        .catch(function (response) {
          $translate(['FAILED_TO_GET_RULES']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_GET_RULES, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    }

    $scope.addNewDemoRuleset = function () {
      $scope.codebase.newDemoRule._codebase = $scope.codebase.raw._id;

      // Send to server
      AdminApiService.addLoading();
      SenseiApiService.addNewDemoRuleset($scope.codebase.newDemoRule)
        .then(function (response) {
          $scope.codebase.demoRules.current.push(response);
          $scope.codebase.demoRules.current = $scope.codebase.demoRules.current.sort(function (rule1, rule2) {
            return -$rootScope.utility.compareVersionNumbers(rule1.version, rule2.version);
          });
          $scope.codebase.newDemoRule = {};
        })
        .catch(function (response) {
          $translate(['FAILED_TO_UPLOAD_NEW_RULESET']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_UPLOAD_NEW_RULESET, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    };

    $scope.downloadDemoFile = function (ruleid) {
      AdminApiService.addLoading();
      SenseiApiService.downloadDemoFile(ruleid)
        .then(function (data) {
          if (data.url) {
            document.location.href = data.url;
          }
        })
        .catch(function (response) {
          swal({
            title: response.data.details,
            type: 'info',
            confirmButtonText: $translate.OK,
            html: true,
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.downloadAllFile = function (ruleid) {
      AdminApiService.addLoading();
      SenseiApiService.downloadAllFile(ruleid)
        .then(function (data) {
          if (data.url) {
            document.location.href = data.url;
          }
        })
        .catch(function (response) {
          swal({
            title: response.data.details,
            type: 'info',
            confirmButtonText: $translate.OK,
            html: true,
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.saveDemoRule = function (ruleset) {
      // Send to server
      AdminApiService.addLoading();
      SenseiApiService.updateDemoRuleset(ruleset)
        .then(function (response) {
          // Find original rule
          var originalRulesIndex = _.findIndex($scope.codebase.demoRules.current, function (b) {
            return b._id == response._id;
          });
          // Replace original rule
          $scope.codebase.demoRules.current.splice(originalRulesIndex, 1, response);
        })
        .catch(function (response) {
          $translate(['FAILED_TO_UPLOAD_NEW_RULESET']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_UPDATE_RULE, response);
          });
        })
        .finally(function () {
          ruleset.editing = false;
          AdminApiService.removeLoading();
        });
    };

    $scope.removeDemoRule = function (index) {
      var rule = $scope.codebase.demoRules.current[index];
      $translate(
        [
          'DELETE',
          'CANCEL',
          'DELETE_RULESET',
          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_RULESET_VERSION',
          'FAILED_TO_DELETE_RULESET',
        ],
        { ruleVersion: rule.version }
      ).then(function (translations) {
        swal(
          {
            title: translations.DELETE_RULESET,
            text: translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE_RULESET_VERSION,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.DELETE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              SenseiApiService.deleteDemoRuleset(rule)
                .then(function (_response) {
                  _.pullAt($scope.codebase.demoRules.current, index);
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.FAILED_TO_DELETE_RULESET, response);
                })
                .finally(AdminApiService.removeLoading);
            }
          }
        );
      });
    };

    $scope.addNewAllRuleset = function () {
      $scope.codebase.newAllRule._codebase = $scope.codebase.raw._id;

      // Send to server
      AdminApiService.addLoading();
      SenseiApiService.addNewAllRuleset($scope.codebase.newAllRule)
        .then(function (response) {
          $scope.codebase.allRules.current.push(response);
          $scope.codebase.allRules.current = $scope.codebase.allRules.current.sort(function (rule1, rule2) {
            return -$rootScope.utility.compareVersionNumbers(rule1.version, rule2.version);
          });
          $scope.codebase.newAllRule = {};
        })
        .catch(function (response) {
          $translate(['FAILED_TO_UPLOAD_NEW_RULESET']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_UPLOAD_NEW_RULESET, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    };

    $scope.saveAllRule = function (allRuleset) {
      // Send to server
      AdminApiService.addLoading();
      SenseiApiService.updateAllRuleset(allRuleset)
        .then(function (response) {
          // Find original rule
          var originalRulesIndex = _.findIndex($scope.codebase.allRules.current, function (b) {
            return b._id == response._id;
          });
          // Replace original rule
          $scope.codebase.allRules.current.splice(originalRulesIndex, 1, response);
        })
        .catch(function (response) {
          $translate(['FAILED_TO_UPLOAD_NEW_RULESET']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_UPDATE_RULE, response);
          });
        })
        .finally(function () {
          allRuleset.editing = false;
          AdminApiService.removeLoading();
        });
    };

    $scope.removeAllRule = function (index) {
      var rule = $scope.codebase.allRules.current[index];
      $translate(
        [
          'DELETE',
          'CANCEL',
          'DELETE_RULESET',
          'ARE_YOU_SURE_YOU_WANT_TO_DELETE_RULESET_VERSION',
          'FAILED_TO_DELETE_RULESET',
        ],
        { ruleVersion: rule.version }
      ).then(function (translations) {
        swal(
          {
            title: translations.DELETE_RULESET,
            text: translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE_RULESET_VERSION,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--color-scw-red)',
            confirmButtonText: translations.DELETE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              SenseiApiService.deleteAllRuleset(rule)
                .then(function (_response) {
                  _.pullAt($scope.codebase.allRules.current, index);
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.FAILED_TO_DELETE_RULESET, response);
                })
                .finally(AdminApiService.removeLoading);
            }
          }
        );
      });
    };

    function init() {
      var codebaseId = ($scope.codebaseId = $stateParams.codebaseID);
      if (!$scope.codebaseId) {
        // Abort back to Admin page
        $state.go('admin.companies');
      }

      // Setup data structures
      AdminApiService.addLoading();
      SenseiApiService.getCodebase(codebaseId)
        .then(function (codebase) {
          var thisCodebase = {};
          thisCodebase.raw = codebase;
          thisCodebase.name = codebase.name;
          thisCodebase.newDemoRule = {};
          thisCodebase.newAllRule = {};
          thisCodebase.newName = codebase.name;
          $scope.codebase = thisCodebase;
        })
        .then(function () {
          updateDemoRulesList(codebaseId);
          updateAllRulesList(codebaseId);
        })
        .catch(function (response) {
          $translate(['THERE_WAS_AN_ERROR_DURING_INITIALISATION']).then(function (translations) {
            ErrorHandler.addHttpError(translations.THERE_WAS_AN_ERROR_DURING_INITIALISATION, response);
          });
        })
        .finally(AdminApiService.removeLoading);
      SenseiApiService.getAvailableDownloadTypes().then(function (result) {
        $scope.availableDownloadTypes = result;
      });
    }

    function destroy() {}

    // lifecycle
    init();
    $scope.$on('$destroy', destroy);
  },
]);
