import { Dialog as MuiDialog, Box as MuiBox, styled, } from '@mui/material';
export const CybermonDialog = styled(MuiDialog)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(10),
    '& .MuiModal-backdrop': {
        backgroundColor: `${theme.palette.container.fill.body}80`,
        backdropFilter: 'blur(2px)',
    },
}));
export const CybermonDialogContainer = styled(MuiBox)({
    width: 1280,
    height: 800,
    padding: 0,
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyContent: 'stretch',
    gridTemplateAreas: "'content'",
});
export const CybermonDialogRiveContainer = styled(MuiBox)({
    maxWidth: '1280px',
    maxHeight: '720px',
    aspectRatio: '16 / 9',
    width: '100%',
    height: '100%',
});
export const CybermonDialogContentContainer = styled(MuiBox)(({ theme }) => ({
    padding: theme.spacing(6),
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(9),
    width: '100%',
    background: `radial-gradient(ellipse at 50% 50%, ${theme.palette.container.fill.body}80 40%, ${theme.palette.container.fill.body}00 60%)`,
}));
