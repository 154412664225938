import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Container, Skeleton } from '@securecodewarrior/design-system-react';
import { AccessibilityInformation, CssGridContainer, Filters } from './ExploreView';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextInput } from './TextInput';
import { Divider } from '../Divider';
import { LoginMessage } from './LoginMessage';
export const ExploreSkeleton = (props) => {
    const { t } = useTranslation();
    return (_jsxs("main", { children: [_jsx(AccessibilityInformation, {}), _jsx(Container, { component: "div", sx: { paddingY: 10 }, children: _jsx(TextInput, { fullWidth: true, InputProps: {
                        startAdornment: (_jsx(SearchIcon, { fontSize: "large", sx: (theme) => ({
                                mr: 2,
                                color: theme.palette.clickable.button.default,
                            }) })),
                    }, placeholder: t('pages.explore.filters.search.placeholder') }) }), _jsx(Divider, {}), _jsxs(Container, { component: "div", sx: { display: 'flex', gap: 7, paddingTop: 7, paddingBottom: 5 }, children: [_jsx(Filters, { isAuthenticated: props.isAuthenticated, onLoginClicked: props.onLoginClicked }), _jsx(Typography, { component: "h2", sx: visuallyHidden, children: t('pages.explore.contentList') }), _jsxs(Stack, { gap: 5, sx: { flexGrow: 1 }, children: [_jsx(Skeleton, { sx: (theme) => ({ height: theme.typography.pxToRem(24), width: '200px' }) }), !props.isAuthenticated && _jsx(LoginMessage, { onLoginClicked: props.onLoginClicked }), _jsx(CssGridContainer, { children: Array.from(Array(100).keys()).map((x) => (_jsx(Skeleton, { variant: "rounded", sx: { flexGrow: 1, borderRadius: 3 }, height: "320px" }, `skeleton_${x}`))) })] })] })] }));
};
