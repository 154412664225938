import { createContext, useContext } from 'react';
import { create as createZustandStore, useStore } from 'zustand';
import { createQueryString, saveFilterState } from './FilterState';
import { applyChangeToFilter, calculateRelevance, mapFilterActionToFilteringEventTrigger, } from './FilterLogic';
const SEARCH_TERM_LIMIT = 25;
export const filterItems = (items, filterState) => {
    const terms = filterState.searchTerm
        ? filterState.searchTerm
            .toLocaleLowerCase()
            .split(' ')
            .filter((x) => x !== '')
            .slice(0, SEARCH_TERM_LIMIT)
        : [];
    items.forEach((itm) => (itm.relevance = calculateRelevance(itm, filterState, terms)));
    return items.filter((itm) => itm.relevance > 0).sort((a, b) => b.relevance - a.relevance);
};
export const createExploreFilterStore = (initialFilterState, initialItems, setSearchParams, logAnalyticsEvent) => {
    return createZustandStore((set, get) => ({
        filterState: initialFilterState,
        filteredItems: filterItems(initialItems, initialFilterState),
        doFilterAction: (action) => {
            logAnalyticsEvent(mapFilterActionToFilteringEventTrigger(action));
            const newState = applyChangeToFilter(get().filterState, action);
            setSearchParams(createQueryString(newState));
            saveFilterState(newState);
        },
        setFilterState: (newFilterState, items) => {
            return set(() => ({ filteredItems: filterItems(items, newFilterState), filterState: newFilterState }));
        },
    }));
};
export const ExploreFilterContext = createContext(null);
export const useExploreFilterStore = (selector) => {
    const filterContext = useContext(ExploreFilterContext);
    if (!filterContext) {
        throw new Error('useExploreFilterStore must be used within an ExploreFilterContext');
    }
    return useStore(filterContext, selector);
};
