import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { HealthBar } from './HealthBar';
import { useState, useEffect } from 'react';
import FlipClock from '../../common/FlippingClock/Countdown';
import { useCybermon } from '../hooks/useCybermon';
export const PointBanner = () => {
    const { nextCybermonStart, activeCybermon, nextCybermon } = useCybermon();
    const [timeLeft, setTimeLeft] = useState();
    useEffect(() => {
        const timeToUse = nextCybermon || !activeCybermon ? nextCybermonStart : activeCybermon.custom_data.active_end;
        if (!timeToUse)
            return;
        const timer = setInterval(() => {
            const difference = timeToUse.valueOf() - Date.now();
            const totalSeconds = Math.floor(difference / 1000);
            if (difference < 1) {
                clearInterval(timer);
                window.location.reload();
            }
            setTimeLeft({
                days: Math.max(0, Math.floor(totalSeconds / (60 * 60 * 24))),
                hours: Math.max(0, Math.floor((totalSeconds / (60 * 60)) % 24)),
                mins: Math.max(0, Math.floor((totalSeconds / 60) % 60)),
                secs: Math.max(0, totalSeconds % 60),
            });
        }, 1000);
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Card, { gridColumn: "1 / 3", sx: {
            background: 'none',
            padding: 5,
            minHeight: 93, // Make sure the card doesn't shrink when the clock is removed
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: { xs: 'center', lg: 'space-between' },
            alignItems: 'center',
            gap: { xs: 5, lg: 0 },
        }, children: [_jsx(HealthBar, {}), activeCybermon && !activeCybermon.earned && (_jsx(FlipClock, { timeLeft: timeLeft !== null && timeLeft !== void 0 ? timeLeft : { days: 0, hours: 0, mins: 0, secs: 0 } }))] }));
};
