import { Box, styled } from '@mui/material';
export const BadgeImageContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'earned',
})(({ theme }) => ({
    position: 'relative',
    isolation: 'isolate',
    overflow: 'hidden',
    '& img': {
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& .showAnimation': {
        bottom: 20,
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        inset: '0 0 0 0',
        transition: '150ms background-color ease-in-out',
        background: 'rgba(255, 244, 215, 0)',
        zIndex: 1,
    },
}), ({ earned, theme }) => (Object.assign({}, (earned && {
    '&:hover': {
        cursor: 'pointer',
        '& img': {
            transform: 'scale(1.01)',
        },
        '& .showAnimation .MuiTypography-root': {
            color: theme.palette.clickable.button.hover,
        },
        '&::after': {
            background: 'rgba(255, 244, 215, 0.30)',
        },
    },
}))));
export const BadgeModalImageButton = styled(Box)(({ theme }) => (Object.assign({ position: 'absolute', left: 20, zIndex: 2, borderRadius: theme.shape.borderRadius * 2, border: `1px solid ${theme.palette.container.border.default}`, background: theme.palette.container.fill.card1, height: 36, padding: theme.spacing(2, 4), display: 'flex', alignItems: 'center', justifyContent: 'center' }, theme.typography.button)));
