export class ReportingAssessmentsApi {
  constructor(reportingServiceEndpoint) {
    this.reportingServiceEndpoint = reportingServiceEndpoint;
  }

  /**
   * Extracts the reporting service's end-point from the window (SCW_ENV) configuration
   * @param window the window object (sometimes passed around as $window)
   * @return {string} the URL of the reporting service end-point
   */
  static reportingEndpoint(window) {
    if (!window) throw new Error('missing parameter: window');
    if (!window.SCW_ENV) throw new Error('window parameter is missing SCW_ENV');
    const reportingServiceEndpoints = window.SCW_ENV.ReportingServiceEndpoints;
    if (!reportingServiceEndpoints) throw new Error('window parameter is missing SCW_ENV.ReportingServiceEndpoints');
    if (!reportingServiceEndpoints[0])
      throw new Error('window parameter is missing default [0 index] endpoint in SCW_ENV.ReportingServiceEndpoints');
    const currentApiEndpoint = window.SCW_ENV.ApiEndpoint;
    if (!currentApiEndpoint) throw new Error('window parameter is missing SCW_ENV.ApiEndpoint');
    const apiEndpoints = window.SCW_ENV.ApiEndpoints;
    if (!apiEndpoints) throw new Error('window parameter is missing SCW_ENV.ApiEndpoints');
    return ReportingAssessmentsApi.decideReportingEndpointUsingCurrentApiEndpoint({
      apiEndpoints,
      currentApiEndpoint,
      reportingServiceEndpoints,
    });
  }

  /**
   * Returns the reporting-service endpoint to be used based on the currently configured ApiEndpoint
   * Determines the key for the current ApiEndpoint in the apiEndpoints object. Uses this
   * key to retrieve the corresponding reportingServiceEndpoint from reportingServiceEndpoints.
   * @param apiEndpoints
   * @param currentApiEndpoint
   * @param reportingServiceEndpoints
   * @returns {string} the URL of the reporting service end-point
   */
  static decideReportingEndpointUsingCurrentApiEndpoint({
    apiEndpoints,
    currentApiEndpoint,
    reportingServiceEndpoints,
  }) {
    const currentEndpointKey = this.findKeyWithinObject(apiEndpoints, currentApiEndpoint);
    if (currentEndpointKey && reportingServiceEndpoints[currentEndpointKey]) {
      return reportingServiceEndpoints[currentEndpointKey];
    }
    return reportingServiceEndpoints[0];
  }

  static findKeyWithinObject(obj, value) {
    return Object.keys(obj).find((key) => {
      return obj[key] === value;
    });
  }

  checkTimezone(tz) {
    if (!tz) {
      throw new Error('missing parameter: tz');
    }
  }

  checkSharedAssessmentId(sharedAssessmentId) {
    if (!sharedAssessmentId) {
      throw new Error('missing parameter: sharedAssessmentId');
    }
  }

  /**
   * Builds the URL to request the CSV named by this function
   * @param tz time zone to use for timestamps in the CSV
   * @return checkTimezone{string} the URL of the CSV end-point
   */
  assessmentsResultsAllUrl(tz) {
    this.checkTimezone(tz);
    return `${this.reportingServiceEndpoint}/rest/assessments/results?tz=${tz}`;
  }

  /**
   * Builds the URL to request the CSV named by this function
   * @param tz time zone to use for timestamps in the CSV
   * @param sharedAssessmentId the `_assessment` field from the `assessments` collection, which is shared by all versions of an assessment
   * @return {string} the URL of the CSV end-point
   */
  assessmentsResultsSingleUrl(tz, sharedAssessmentId) {
    this.checkSharedAssessmentId(sharedAssessmentId);
    this.checkTimezone(tz);
    return `${this.reportingServiceEndpoint}/rest/assessments/results/a/${sharedAssessmentId}?tz=${tz}`;
  }

  /**
   * Builds the URL to request the CSV named by this function
   * @param tz time zone to use for timestamps in the CSV
   * @return {string} the URL of the CSV end-point
   */
  assessmentsSummaryAllUrl(tz) {
    this.checkTimezone(tz);
    return `${this.reportingServiceEndpoint}/rest/assessments/summary?tz=${tz}`;
  }

  /**
   * Builds the URL to request the CSV named by this function
   * @param tz time zone to use for timestamps in the CSV
   * @param sharedAssessmentId the `_assessment` field from the `assessments` collection, which is shared by all versions of an assessment
   * @return {string} the URL of the CSV end-point
   */
  assessmentsSummarySingleUrl(tz, sharedAssessmentId) {
    this.checkSharedAssessmentId(sharedAssessmentId);
    this.checkTimezone(tz);
    return `${this.reportingServiceEndpoint}/rest/assessments/summary/a/${sharedAssessmentId}?tz=${tz}`;
  }
}
