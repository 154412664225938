'use strict';

import angular from 'angular';

export async function setDisabled(
  tournamentId,
  userId,
  disabled,
  TournamentsApi,
  $http,
  TournamentsApiEndpoint,
  HttpConfigService
) {
  TournamentsApi.addLoading();
  const response = await $http(
    angular.extend(
      {
        method: 'PUT',
        url: TournamentsApiEndpoint() + '/' + tournamentId + '/user/' + userId + '/disable',
        data: {
          disabled,
        },
      },
      HttpConfigService.getHttpConfig()
    )
  );

  TournamentsApi.removeLoading();

  return response.data;
}
