import angular from 'angular';
import MODULE from './module';
import moment from 'moment-timezone';

const app = angular.module(MODULE);

app.service('UtilityService', [
  '$translate',
  function ($translate) {
    this.longFormatDuration = function (timeSpent) {
      timeSpent = timeSpent || 0;

      let timeSpentString = '';
      const duration = moment.duration(timeSpent, 'milliseconds');

      const seconds = duration.seconds();
      if (seconds > 0) {
        timeSpentString =
          seconds +
          (seconds === 1
            ? ' ' + $translate.instant('DATE_TIME.SECOND')
            : ' ' + $translate.instant('DATE_TIME.SECONDS'));
      }

      const minutes = duration.minutes();
      if (minutes > 0) {
        timeSpentString =
          minutes +
          (minutes === 1
            ? ' ' + $translate.instant('DATE_TIME.MINUTE') + ' '
            : ' ' + $translate.instant('DATE_TIME.MINUTES') + ' ') +
          timeSpentString;
      }

      const asHours = Math.floor(duration.asHours());
      const hours = duration.hours();
      if (asHours > 0) {
        timeSpentString =
          hours +
          (hours === 1
            ? ' ' + $translate.instant('DATE_TIME.HOUR') + ' '
            : ' ' + $translate.instant('DATE_TIME.HOURS') + ' ') +
          timeSpentString;
      }

      const asDays = Math.floor(duration.asDays());
      if (asDays > 0) {
        timeSpentString =
          asDays +
          (asDays === 1
            ? ' ' + $translate.instant('DATE_TIME.DAY') + ' '
            : ' ' + $translate.instant('DATE_TIME.DAYS') + ' ') +
          timeSpentString;
      }

      return timeSpentString || '0 ' + $translate.instant('DATE_TIME.SECONDS');
    };

    this.shortFormatDuration = function (timeSpent) {
      let timeSpentString = '';
      const duration = moment.duration(timeSpent || 0, 'milliseconds');

      const minutes = duration.minutes();
      if (minutes > 0) {
        timeSpentString = minutes + ' ' + $translate.instant('DATE_TIME_SHORT.MINUTES');
      }

      if (Math.floor(duration.asHours()) > 0) {
        const hours = duration.hours();
        timeSpentString =
          hours + ' ' + $translate.instant('DATE_TIME_SHORT.HOURS') + (timeSpentString ? ' ' + timeSpentString : '');
      }

      const asDays = Math.floor(duration.asDays());
      if (asDays > 0) {
        timeSpentString =
          asDays + ' ' + $translate.instant('DATE_TIME_SHORT.DAYS') + (timeSpentString ? ' ' + timeSpentString : '');
      }

      return timeSpentString || '0 ' + $translate.instant('DATE_TIME_SHORT.HOURS');
    };

    this.timeElapsed = function (timeSpent) {
      timeSpent = timeSpent || 0;
      const localeOptions = {
        minimumIntegerDigits: 2,
        useGrouping: false,
      };
      const duration = moment.duration(timeSpent, 'milliseconds');

      const seconds = duration.seconds().toLocaleString('en-US', localeOptions);
      const minutes = duration.minutes().toLocaleString('en-US', localeOptions);
      const asHours = Math.floor(duration.asHours()).toLocaleString('en-US', localeOptions);

      let timeSpentString = `${asHours}:${minutes}:${seconds}`;

      return timeSpentString || '00:00:00';
    };
  },
]);
