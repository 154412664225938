import angular from 'angular';
import MODULE from './module';
import templateUrl from './admin.allowances.management.html';

angular.module(MODULE).directive('adminAllowancesManagement', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        team: '=?',
        company: '=?',
        allowancesChanged: '&',
      },
      link: function (scope, _element, _attributes, _controller) {
        var metadata = $rootScope.metadata;
        var moduleLicenses = metadata.management.moduleLicenses;
        var moduleLicenseTypes = moduleLicenses.types;
        var modules = moduleLicenses.modules;
        var entity = scope.team || scope.company;

        // Begin: exposed data
        scope.entity = entity;
        scope.metadata = metadata;
        scope.moduleLicenseTypes = moduleLicenseTypes;
        // End: exposed data

        // Begin: set defaults if unset
        entity.allowances = entity.allowances || {};
        var i;
        for (i = 0; i < modules.length; i++) {
          var module = modules[i];
          if (module != 'sensei') {
            entity.allowances[module] = entity.allowances[module] || {
              granted: 10,
              available: 0,
              license: metadata.management.moduleLicenses.types.USAGE,
              reserved: 0,
              used: 0,
            };
          } else {
            entity.allowances[module] = entity.allowances[module] || {
              granted: 0,
              available: 0,
              license: metadata.management.moduleLicenses.types.DISABLED,
              reserved: 0,
              used: 0,
            };
          }
        }
        // End: set defaults if unset
      },
    };
  },
]);
