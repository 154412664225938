var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from '@securecodewarrior/design-system-react/lib/legacy';
import { ErrorMessage, FormInputLabels, ModalContainer } from './RegisterStyled';
const isJWTRegistration = (prop) => {
    return 'registrationRequest' in prop;
};
const parseJWTToken = (props) => {
    const [_header, payload, _sig] = props.registrationRequest.split('.');
    return JSON.parse(atob(payload));
};
export function Register(props) {
    const jwtRegistration = isJWTRegistration(props);
    const { onClose, companyId, translator, adminApiService } = props;
    const { integrationId, tenantId: initTenantId } = isJWTRegistration(props)
        ? parseJWTToken(props)
        : { integrationId: props.integrationId, tenantId: '' };
    const [errorMessage, setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [registered, setRegistered] = useState(false);
    const [tenantId, setTenantId] = useState(initTenantId);
    const handleSubmit = (e) => __awaiter(this, void 0, void 0, function* () {
        e.preventDefault();
        if (errorMessage) {
            return;
        }
        if (jwtRegistration) {
            const payload = { registrationRequest: props.registrationRequest, displayName };
            const response = yield adminApiService.registerIntegration(companyId, payload);
            if (response.success) {
                onClose();
            }
            else {
                setErrorMessage(response.message);
            }
        }
        else {
            const response = yield adminApiService.registerIntegration(companyId, {
                integrationId: props.integrationId,
                displayName,
            });
            if (response.success) {
                setTenantId(response.tenantId);
                setRegistered(true);
            }
            else {
                setErrorMessage(response.message);
            }
        }
    });
    const handleClose = (e) => {
        e.preventDefault();
        onClose();
    };
    const handleChange = (e) => {
        const { value } = e.target;
        if (!value.length) {
            setErrorMessage(translator('DISPLAY_NAME_MUST_BE_PROVIDED'));
        }
        else if (value.length > 64) {
            setErrorMessage(translator('DISPLAY_NAME_LENGTH_GREATER_THAN_64'));
        }
        else {
            setErrorMessage('');
        }
        setDisplayName(value);
    };
    return (_jsxs(ModalContainer, { children: [_jsx("div", { className: "modal-header", children: _jsx("h3", { className: "modal-title", children: translator('REGISTER_INTEGRATION') }) }), _jsxs("form", { onSubmit: handleSubmit, children: [_jsxs("div", { className: "modal-body", children: [_jsx(FormInputLabels, { htmlFor: "integrationId", children: translator('FORM_LABEL_INTEGRATION_ID') }), _jsx("input", { id: "integrationId", name: "integrationId", value: integrationId, readOnly: true }), tenantId && (_jsxs(_Fragment, { children: [_jsx(FormInputLabels, { htmlFor: "tenantId", children: translator('FORM_LABEL_TENANT_ID') }), _jsx("input", { id: "tenantId", name: "tenantId", value: tenantId, readOnly: true })] })), _jsx(FormInputLabels, { htmlFor: "displayName", children: translator('FORM_LABEL_DISPLAY_NAME') }), _jsx("input", { type: "text", id: "displayName", value: displayName, name: "displayName", onChange: handleChange, maxLength: 64, required: true, "aria-invalid": !!errorMessage, "aria-describedby": errorMessage ? 'error-message' : '' }), errorMessage && (_jsx(ErrorMessage, { id: "error-message", role: "alert", children: errorMessage })), registered && _jsx("span", { children: translator('PLEASE_COPY_TENANT_ID') })] }), _jsxs("div", { className: "modal-footer", children: [jwtRegistration ? (_jsx(Button, { variant: "outlined", type: "button", onClick: handleClose, title: "Cancel", children: translator('CANCEL') })) : (_jsx(Button, { variant: "outlined", type: "button", onClick: handleClose, title: "Close", children: translator('CLOSE') })), registered || (_jsx(Button, { variant: "contained", type: "submit", title: "Submit", children: translator('SUBMIT') }))] })] })] }));
}
