import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, DSThemeProvider } from '@securecodewarrior/design-system-react';
import { Typography, Stack, SvgIcon, Box } from '@mui/material';
import { usePlatformContext } from '../../platformContext';
import { Home } from '@mui/icons-material';
import { LearningProgress } from '../components/organisms/learningProgress/LearningProgress';
import { CommunicationsToaster, CommunicationsToasterMessage } from '../../communications/Communications.styled';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CardTemplate } from '../templates/CardTemplate';
import { useDashboard } from '../context/dashboardContext';
import { TrustScore } from '../components/organisms/TrustScore/TrustScore';
const DashboardHero = () => {
    var _a, _b, _c;
    const { sessionUser } = usePlatformContext();
    return (_jsx(Stack, { direction: "row", justifyContent: "space-between", mb: 6, children: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(SvgIcon, { sx: { fontSize: 40 }, children: _jsx(Home, {}) }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", color: "text.soft", children: "Home" }), _jsx(Typography, { component: "h2", variant: "h2", children: ((_c = (_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.first)
                                ? `Welcome, ${sessionUser.properties.profile.name.first}!`
                                : 'Welcome!' })] })] }) }));
};
const getErrorMessage = (cards) => {
    const errors = Object.values(cards).reduce((result, { error }) => {
        error && result.push(error.message);
        return result;
    }, []);
    if (errors.length === 0) {
        return undefined;
    }
    if (errors.length === 1) {
        return errors[0];
    }
    return 'Some of the content on the page failed to load. Please try refreshing.';
};
const DashboardToaster = () => {
    const { state, refreshFailedCards } = useDashboard();
    const message = getErrorMessage(state.cards);
    return (_jsx(CommunicationsToaster, { open: !!message, anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: null, message: _jsx(CommunicationsToasterMessage, { children: _jsx(Typography, { children: message }) }), action: _jsx(DSThemeProvider, { theme: "light", children: _jsx(Button, { variant: "text", onClick: refreshFailedCards, children: "Refresh" }) }) }));
};
export const DashboardPage = () => {
    return (_jsxs(Container, { children: [_jsx(DashboardHero, {}), _jsxs(Stack, { gap: 5, children: [_jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h5", children: "Platform usage" }), _jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 4 }, children: [_jsx(TrustScore, {}), _jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h6", children: "License usage" }) }), _jsx(CardTemplate.Body, { children: _jsx(Typography, { variant: "body2", children: "This is the body of the card" }) })] })] })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h5", children: "Learner Activity" }), _jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 4 }, children: [_jsx(LearningProgress, {}), _jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h6", children: "Learner Engagement" }) }), _jsx(CardTemplate.Body, { children: _jsx(Typography, { variant: "body2", children: "This is the body of the card" }) })] })] })] })] }), _jsx(DashboardToaster, {})] }));
};
