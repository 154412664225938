var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { ListItemButton as MuiListItemButton, ListItemText as MuiListItemText, styled, useMediaQuery, } from '@mui/material';
import { Unstable_MenuItem } from '@securecodewarrior/design-system-react/lib/legacy';
import { AppBarMobileContext } from '../../AppBar.context';
import { appBarMenuItemStyles } from './AppBarMenuItem.styles';
// =============================================================
// Styled Components
// =============================================================
const StyledListItemButton = styled(MuiListItemButton)(({ theme }) => (Object.assign({}, appBarMenuItemStyles(true, theme).root)));
const StyledMenuItem = styled(Unstable_MenuItem)(({ theme }) => (Object.assign({}, appBarMenuItemStyles(false, theme).root)));
// =============================================================
// Mobile
// =============================================================
const AppBarMenuItemMobile = (props) => {
    const { children, className = '', onClick, startIcon: _startIcon } = props, rest = __rest(props, ["children", "className", "onClick", "startIcon"]);
    const appBarMobileContext = useContext(AppBarMobileContext);
    const handleClick = useCallback((event) => {
        var _a;
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
        (_a = appBarMobileContext === null || appBarMobileContext === void 0 ? void 0 : appBarMobileContext.setOptionSelected) === null || _a === void 0 ? void 0 : _a.call(appBarMobileContext);
    }, [appBarMobileContext, onClick]);
    return (_jsx(StyledListItemButton, Object.assign({ className: `ScwAppBarMenuItem-root ${className}`, onClick: handleClick }, rest, { children: _jsx(MuiListItemText, { primaryTypographyProps: { variant: 'caption', color: 'inherit' }, children: children }) })));
};
// =============================================================
// Desktop
// =============================================================
const AppBarMenuItemDesktop = (props) => {
    const { className = '' } = props, rest = __rest(props, ["className"]);
    return _jsx(StyledMenuItem, Object.assign({ className: `ScwAppBarMenuItem-root ${className}` }, rest));
};
// =============================================================
// AppBarMenu
// =============================================================
export const Unstable_AppBarMenuItem = (props) => {
    const isDesktopLayout = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return isDesktopLayout ? _jsx(AppBarMenuItemDesktop, Object.assign({}, props)) : _jsx(AppBarMenuItemMobile, Object.assign({}, props));
};
Unstable_AppBarMenuItem.displayName = 'AppBarMenuItem';
/** @deprecated use {@link Unstable_AppBarMenuItem} to indicate that you are aware that this component will change */
export const AppBarMenuItem = Unstable_AppBarMenuItem;
