import { jsx as _jsx } from "react/jsx-runtime";
import { Chip } from '@mui/material';
import { Public as PublicIcon } from '@mui/icons-material';
// This component is used from angular.js pages via the directive
// "scwTournamentSpecialEvent" <scw-tournament-special-event>
export const SpecialEvent = ({ label }) => {
    return (_jsx(Chip, { color: "primary", icon: _jsx(PublicIcon, { name: "globe", sx: { fontSize: 16, color: '#15141E' } }), sx: {
            background: 'linear-gradient(269.79deg, #EAA13D 0%, #F9C702 99.63%)',
            borderRadius: '8px',
            textTransform: 'uppercase',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '11px',
            color: '#15141E',
            lineHeight: '16px',
            letterSpacing: '0.12px',
            height: 24,
        }, label: label }));
};
