import angular from 'angular';
import MODULE from './module';
import breadcrumbStatsTemplate from './challenge.breadcrumb.stats.html';

angular.module(MODULE).component('breadcrumbStats', {
  templateUrl: breadcrumbStatsTemplate,
  bindings: {
    playmode: '<',
    breadcrumbStats: '<',
  },
  controller: [
    '$scope',
    function ($scope) {
      this.isShowing = false;
      const styles = ['danger', 'warning', 'info', 'info'];

      this.$onChanges = function () {
        $scope.playmode = this.playmode;
        const { timeRemainingString, timeRemainingPercent, playerStats, assessmentData } = this.breadcrumbStats;

        if (this.playmode === 'tournaments' && timeRemainingString && playerStats) {
          $scope.timeRemainingString = timeRemainingString;
          $scope.timeRemainingPercent = timeRemainingPercent || 0;
          $scope.playerStats = playerStats;
        }

        if (this.playmode === 'assessments' && assessmentData) {
          $scope.assessmentStarted = assessmentData.started;
          $scope.assessmentDeadline = assessmentData.deadline || 0;

          try {
            $scope.assessmentDeadlineString = assessmentData.deadline
              ? new Date(assessmentData.deadline).toUTCString()
              : '';
          } catch (error) {
            console.log(error);
          }

          $scope.assessmentProgress = assessmentData.progress;

          const tick = () => {
            const deadlineToStarted = assessmentData.deadline - assessmentData.started;
            const deadlineToNow = assessmentData.deadline - Date.now();
            let progress = (deadlineToNow / deadlineToStarted) * 100;
            if (progress > 100) {
              progress = 100;
              clearInterval(this.progressTicker);
            }

            $scope.timeUsed = progress;
            $scope.stressStyle = styles[Math.floor(progress / 25) % 4];
          };

          if (!this.progressTicker && assessmentData.deadline) {
            tick();
            this.progressTicker = setInterval(tick, 1000);
          }
        }
      };

      this.$onDestroy = function () {
        clearInterval(this.progressTicker);
      };
    },
  ],
});
