import angular from 'angular';
import MODULE from './module';
import Papa from 'papaparse';
import _ from 'lodash';
import { ANALYTICS_EVENTS } from '../../analytics/constants';

angular.module(MODULE).controller('AddInviteEditUserModalController', [
  '$uibModalInstance',
  '$rootScope',
  '$scope',
  '$translate',
  'action',
  'userType',
  'user',
  'USER_ROLES',
  'AdminApiService',
  'LanguageUtils',
  '$window',
  '$uibModal',
  'USER_STATUS',
  'AnalyticsService',
  function (
    $uibModalInstance,
    $rootScope,
    $scope,
    $translate,
    action,
    userType,
    user,
    USER_ROLES,
    AdminApiService,
    LanguageUtils,
    $window,
    $uibModal,
    USER_STATUS,
    AnalyticsService
  ) {
    $scope.forms = {};
    $scope.addInviteEdit = action;
    $scope.originalUser = user;
    $scope.editedUser = JSON.parse(JSON.stringify(user)) || { properties: {} };
    $scope.editedUserType = userType;
    $scope.selectedTeam = null;
    $scope.handleFormInteraction = _.debounce(handleFormInteraction, 500);

    $scope.modalCompanyPage = {
      options: { page: 1, size: 10 },
    };

    $scope.modalTeamPage = {
      options: { page: 1, size: 10 },
    };

    $scope.invitationList = [];
    $scope.invitationListTags = {};
    $scope.invitationListLimit = 5;
    $scope.statuses = _.keys($scope.metadata.constants.user.status)
      .map(function (s) {
        return $scope.metadata.constants.user.status[s];
      })
      .sort();

    // Begin: exposed methods
    $scope.removeAddress = removeAddress;
    $scope.fetchCompanies = fetchCompanies;
    $scope.onCompanyChange = function () {
      $scope.editedUser.properties._tid = null;
    };
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.close = close;
    $scope.onTeamSelected = onTeamSelected;
    var confirmTeamChangeModal;

    var isSCWAdmin = $scope.isAuthorized(USER_ROLES.admin);
    $scope.isSCWAdmin = isSCWAdmin;

    // End: exposed methods

    if ($scope.isSCWAdmin) {
      $scope.roleList = [
        USER_ROLES.admin,
        USER_ROLES.companyAdmin,
        USER_ROLES.manager,
        USER_ROLES.player,
        USER_ROLES.cmsDeveloper,
      ];
    } else {
      $scope.roleList = [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player];
    }

    // Begin: event management
    $scope.fileupload = {};
    $scope.$watch('fileupload.csvFile', function (file) {
      if (file) {
        $scope.isInvalidFile = false;

        var reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = loadHandler;
      }
    });
    // End: event management

    function loadHandler(content) {
      var data = processData(content);
      if (data.emails.length < 1) {
        $scope.isInvalidFile = true;
      }
      $scope.invitationList = data.emails;
      $scope.invitationListTags = data.tags;
      $scope.$apply();
    }

    function processData(evt) {
      var csvContent = evt.target.result;
      var emails = []; // All emails will go here []
      var tags = {}; //Tags of respective emails will go here { 'email' : [tags] }

      Papa.parse(csvContent, {
        comments: '#',
        delimiter: ',',
        step: (results, _parser) => {
          const [rawEmailCol, rawTagsCol] = results.data;
          const theEmail = rawEmailCol.trim().toLowerCase();
          const notValidMail = !$window.emailRegex.test(theEmail);
          const alreadyNoted = emails.indexOf(theEmail) > -1;
          if (theEmail && !notValidMail && !alreadyNoted) {
            emails.push(theEmail);
            if (rawTagsCol)
              Papa.parse(rawTagsCol, {
                delimiter: ';',
                step: (tagResults) => {
                  tags[theEmail] = tagResults.data.reduce((result, value, _key) => {
                    const string = value.trim();
                    if (string) result.push(string);
                    return result;
                  }, []);
                },
              });
          }
        },
      });

      return { emails: emails, tags: tags };
    }

    function removeAddress(email) {
      $scope.invitationList.splice($scope.invitationList.indexOf(email), 1);
      if ($scope.invitationListTags[email]) $scope.invitationListTags[email] = null;
    }

    function resetCompanyList() {
      $translate(['NO_COMPANY']).then(function (translations) {
        $scope.companyList = [
          {
            _id: null,
            name: translations.NO_COMPANY,
          },
        ];

        var user = $scope.editedUser;
        var currentCompany = user.properties.company;
        if (currentCompany) {
          var inList = _.find($scope.companyList, { _id: currentCompany._id });
          if (!inList) $scope.companyList.push(currentCompany);
        }

        $scope.modalCompanyPage = {
          options: { page: 1, size: 10 },
        };
      });
    }

    function init() {
      if (isSCWAdmin) {
        $scope.roleList = [
          USER_ROLES.admin,
          USER_ROLES.companyAdmin,
          USER_ROLES.manager,
          USER_ROLES.player,
          USER_ROLES.cmsDeveloper,
        ];
        $scope.fetchCompanies('', null, true);
        $scope.LOG_TYPES = ['training', 'assessments', 'tournaments', 'courses'];
        for (var i = 0; i < $scope.LOG_TYPES.length; i++) {
          $scope.userLogSearch($scope.LOG_TYPES[i]);
        }
      } else {
        $scope.roleList = [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player];
      }

      $scope.statuses = Object.keys($scope.metadata.constants.user.status)
        .map(function (s) {
          return $scope.metadata.constants.user.status[s];
        })
        .sort();

      if (action == 'edit') {
        // init: user tags
        $scope.forms = $scope.forms || {};
        $scope.editedUser.properties.tags = $scope.editedUser.properties.tags || [];
        $scope.forms.editedUserTags = _.map($scope.editedUser.properties.tags, function (tag) {
          return { text: tag };
        });
        // end: user tags

        if (isSCWAdmin) {
          resetCompanyList();

          // Need to manually add it since out of edit-team or edit-company scope this is missing.
          if (!$scope.editedUser.properties._tid && $scope.editedUser._id) {
            $scope.languageList = LanguageUtils.languageList();
            $scope.languageName = LanguageUtils.languageName;
          }
        }
      }
    }

    function handleFormInteraction() {
      const host = $window.location.origin;
      // Check if its the Add User Invite Form
      if ($scope.forms.addInviteEditUserForm && $scope.forms.addInviteEditUserForm.$dirty) {
        const props = {
          name: 'Invite Users',
          fields_name: 'email',
          interaction: 'change',
          host: host,
        };

        AnalyticsService.logEvent(ANALYTICS_EVENTS.General.FORM_INTERACTION, props);
      }
    }

    function fetchCompanies(filter, $event, firstLoad) {
      $scope.companyList = $scope.companyList || [];
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      if (firstLoad || filter) resetCompanyList();

      if (!filter && !firstLoad) return;

      if ($scope.modalCompanyPage.options.page >= $scope.modalCompanyPage.options.pages) return;

      if ($event) {
        $scope.modalCompanyPage.options.page++;
      }

      $scope.companiesLoading = true;

      var currentCompany;
      var user = $scope.editedUser;
      if (user && user.properties) {
        currentCompany = user.properties.company;
        if (currentCompany) {
          var inList = _.find($scope.companyList, { _id: currentCompany._id });
          if (!inList) $scope.companyList.push(currentCompany);
        }
      }

      // get company and team list
      return AdminApiService.getCompanyList(filter, $scope.modalCompanyPage.options, 'name')
        .then(function (response) {
          $scope.modalCompanyPage.options = response;

          if (currentCompany) _.remove(response.data, { _id: currentCompany._id });

          $scope.companyList = $scope.companyList.concat(response.data);
        })
        .finally(function () {
          $scope.companiesLoading = false;
        });
    }

    /**
     * User game logs for training, assessments and tournaments
     */

    // pager controls
    $scope.activeLogsTab = 0;

    $scope.userLogsPage = {
      training: { options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage } },
      assessments: { options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage } },
      tournaments: { options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage } },
      courses: { options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage } },
    };

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        for (var type in $scope.userLogsPage) {
          $scope.userLogsPage[type].options.page = 1;
          $scope.userLogsPage[type].options.size = newVal;
          $scope.userLogSearch(type);
        }
      }
    });

    $scope.userLogsTableFilter = {
      training: { searchText: $scope.gameLogFilter || '' },
      assessments: { searchText: $scope.gameLogFilter || '' },
      tournaments: { searchText: $scope.gameLogFilter || '' },
      courses: { searchText: $scope.gameLogFilter || '' },
    };

    $scope.userLogs = {
      training: [],
      assessments: [],
      tournaments: [],
      courses: [],
    };
    $scope.userLogSearch = function (type, increment) {
      if (!isSCWAdmin) return;

      // This is used for keyboard navigation
      if (increment) {
        $scope.userLogsPage[type].options.page = $scope.userLogsPage[type].options.page + increment;
      }

      if (action == 'edit') {
        AdminApiService.addLoading();
        AdminApiService.getUserLog(
          $scope.editedUser._id,
          $scope.userLogsTableFilter[type].searchText,
          $scope.userLogsPage[type].options,
          type
        )
          .then(function (gameLog) {
            $scope.setUserLog(type, gameLog);
          })
          .finally(AdminApiService.removeLoading);
      }
    };

    //
    $scope.userLogSearchKey = function (event, type) {
      if (event.key === 'Enter') {
        event.currentTarget.blur();
        $scope.userLogSearch(type);
      }
    };

    $scope.userLogPagerChanged = function (type, increment) {
      $scope.userLogSearch(type, increment);
    };

    /**
     * For tournaments, stats are stored at an attempt level but while getting these attempt
     * stats we loose track of the stage on which the attempt was recorded. Hence, we are using a
     * helper function that would use stageIndex passed, to determine the type of the challenge stage.
     */
    $scope.isTournamentSecondStage = function (type, stageIndex) {
      return type === 'tournaments' && stageIndex === 1;
    };

    // create a row per stage
    $scope.setUserLog = function (type, gameLog) {
      $scope.userLogsPage[type].options = gameLog;
      $scope.userLogs[type] = [];

      $translate([
        'LOCATE_VULN',
        'MARK_VULN_LINES',
        'SELECT_VULN_CATEGORY',
        'REVIEW_PROPOSED_SOLUTION',
        'PLAY_MISSION',
      ]).then(function (translations) {
        var alternateRow = false;

        for (var i = 0, len = gameLog.data.length; i < len; i += 1) {
          var logItem = gameLog.data[i];
          const isSecondStageOfTournamentLog = $scope.isTournamentSecondStage(type, logItem.stageIndex);

          for (var j = 0, len2 = logItem.stages.length; j < len2; j += 1) {
            var stage;
            if (logItem.cbl == 'L1') {
              stage = translations.SELECT_VULN_CATEGORY;
            } else if (logItem.cbl === 'I1') {
              stage = translations.PLAY_MISSION;
            } else if (logItem.cbl == 'L2') {
              stage = translations.LOCATE_VULN;
            } else if (logItem.cbl == 'L3') {
              stage = translations.REVIEW_PROPOSED_SOLUTION;
            } else if (logItem.cbl == 'L4' && j == 0) {
              stage = translations.SELECT_VULN_CATEGORY;
            } else if (logItem.cbl == 'L4' && j == 1) {
              stage = translations.REVIEW_PROPOSED_SOLUTION;
            } else if (logItem.cbl == 'L5' && j == 0) {
              stage = translations.LOCATE_VULN;
            } else if (logItem.cbl == 'L5' && j == 1) {
              stage = translations.REVIEW_PROPOSED_SOLUTION;
            }

            $scope.userLogs[type].push({
              _id: j == 0 ? logItem._id : '',
              _challenge: j == 0 ? logItem._challenge : '',
              language: logItem.language,
              completed: logItem.stages[j].completed,
              stage: isSecondStageOfTournamentLog ? translations.REVIEW_PROPOSED_SOLUTION : stage,
              status: logItem.stages[j].status,
              points: logItem.stages[j].points,
              pointsBreakdown: logItem.stages[j].pointsBreakdown,
              attemptIndex: logItem.stages[j].attemptIndex,
              hintsUsed: logItem.hints[j] ? logItem.hints[j].given : 0,
              raw: logItem,
              cssClass: alternateRow ? 'active' : '',
              isFirstStage: j == 0,
            });
          }

          alternateRow = !alternateRow;
        }
      });
    };

    $scope.userLogRawURL = function (logItem) {
      return 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(logItem.raw, null, 2));
    };

    $scope.$on('$destroy', function () {
      const defaultProps = AnalyticsService.getDefaultEventProps();
      const context = { type: 'button', name: 'Cancel/Close Invite Users Modal' };
      const props = { ...defaultProps, ...context };
      AnalyticsService.logEvent('click', props);
    });

    function onTeamSelected(team) {
      $scope.selectedTeam = team;
      if (team.status === USER_STATUS.DISABLED && $scope.editedUser.status !== USER_STATUS.DISABLED) {
        confirmTeamChangeModal = $uibModal.open({
          templateUrl: 'admin/confirm-disabled-team.html',
          size: 'md',
          scope: $scope,
          backdrop: 'static',
          windowClass: 'mgmt-modal',
          windowTopClass: 'mgmt-team-change-modal',
        });

        confirmTeamChangeModal.result
          .then(function () {
            confirmTeamChangeModal.close();
          })
          .catch(angular.noop);
      }

      if (team.status === USER_STATUS.ENABLED && $scope.editedUser.status === USER_STATUS.DISABLED) {
        confirmTeamChangeModal = $uibModal.open({
          templateUrl: 'admin/confirm-enabled-team.html',
          size: 'md',
          scope: $scope,
          backdrop: 'static',
          windowClass: 'mgmt-modal',
          windowTopClass: 'mgmt-team-change-modal',
        });

        confirmTeamChangeModal.result
          .then(function () {
            confirmTeamChangeModal.close();
          })
          .catch(angular.noop);
      }
    }

    function close(option) {
      switch (option) {
        case 'changeStatus':
          if ($scope.editedUser.status === USER_STATUS.ENABLED) {
            $scope.editedUser.status = USER_STATUS.DISABLED;
          } else {
            $scope.editedUser.status = USER_STATUS.ENABLED;
          }
          break;
        case 'reset':
          $rootScope.$broadcast('team-selector:reset');
          break;
      }
      confirmTeamChangeModal.dismiss();
    }

    function save() {
      // Don't save SAML Attributes
      if (_.get($scope.editedUser, 'properties.SAMLAttributes') !== undefined)
        delete $scope.editedUser.properties.SAMLAttributes;

      if (_.get($scope.editedUser, 'properties.platformManaged') !== undefined)
        delete $scope.editedUser.properties.platformManaged;
      // support for CSV loaded invitation list.
      if ($scope.invitationList.length > 0) {
        $scope.editedUser.email = { emails: $scope.invitationList, tags: $scope.invitationListTags };
      }

      var noCompany, noTeam;

      if (action == 'edit') {
        // only applicable for edit case
        noCompany = !$scope.editedUser.properties._cid;
        noTeam = !$scope.editedUser.properties._tid;
      }
      if (
        action == 'edit' &&
        $scope.isAuthorized($scope.userRoles.admin) &&
        $scope.editedUser.roles.indexOf(USER_ROLES.admin) < 0 &&
        noCompany &&
        noTeam
      ) {
        $scope.forms.addInviteEditUserForm.languageFramework.$setValidity('required', true);
        if (!$scope.editedUser.languages || $scope.editedUser.languages.length < 1) {
          $scope.forms.addInviteEditUserForm.languageFramework.$setValidity('required', false);
        }
        $scope.forms.addInviteEditUserForm.languageFramework.$dirty = true;
      }

      if (
        action == 'edit' &&
        ($scope.editedUser.roles.indexOf(USER_ROLES.player) >= 0 ||
          $scope.editedUser.roles.indexOf(USER_ROLES.manager) >= 0) &&
        $scope.isAuthorized([USER_ROLES.admin, USER_ROLES.companyAdmin])
      ) {
        $scope.forms.addInviteEditUserForm.team.$setValidity('team_required', true);
        if ($scope.editedUser.properties._cid && !$scope.editedUser.properties._tid) {
          $scope.forms.addInviteEditUserForm.team.$setValidity('team_required', false);
        }
      }

      if (
        action == 'edit' &&
        $scope.editedUser.roles.indexOf(USER_ROLES.companyAdmin) >= 0 &&
        $scope.isAuthorized([USER_ROLES.admin])
      ) {
        $scope.forms.addInviteEditUserForm.company.$setValidity('company_required', true);
        if (!$scope.editedUser.properties._cid) {
          $scope.forms.addInviteEditUserForm.company.$setValidity('company_required', false);
        }
      }

      if ($scope.forms.editedUserTags) {
        var tags = [];
        var tag;
        for (var i = 0; i < $scope.forms.editedUserTags.length; i++) {
          tag = $scope.forms.editedUserTags[i];
          tags.push(tag.text);
        }
        $scope.editedUser.properties.tags = tags;
      }

      if ($scope.forms.addInviteEditUserForm && $scope.forms.addInviteEditUserForm.$invalid) {
        if ($scope.forms.addInviteEditUserForm.email) {
          $scope.forms.addInviteEditUserForm.email.$dirty = true;
        }
        if ($scope.forms.addInviteEditUserForm.team) {
          $scope.forms.addInviteEditUserForm.team.$dirty = true;
        }
        //$scope.forms.addInviteEditUserForm.status.$dirty = true;
        if ($scope.forms.addInviteEditUserForm.roles) {
          $scope.forms.addInviteEditUserForm.roles.$dirty = true;
        }
        if (isSCWAdmin) {
          if ($scope.forms.addInviteEditUserForm.company) {
            $scope.forms.addInviteEditUserForm.company.$dirty = true;
          }
        }
        const errors = $scope.forms.addInviteEditUserForm.$error;
        if (errors) {
          let errorValue;
          if (errors.required) {
            errorValue = 'ADDINVITEEDITUSERMODAL_PROVIDE_EMAIL';
          } else {
            errorValue = 'INVALID_EMAIL';
          }

          if ($scope.isInvalidFile) {
            errorValue = 'USER_FILE_UPLOAD_INVALID_FILE';
          }

          const defaultProps = AnalyticsService.getDefaultEventProps();
          const errorProps = {
            page_name: 'Invite User Error',
            type: 'error',
            value: errorValue,
            text: $translate.instant(errorValue),
          };
          const errorProperties = { ...defaultProps, ...errorProps };
          AnalyticsService.logEvent('view', errorProperties);
        }
        return;
      }

      if (action == 'invite') {
        $scope.editedUser.isInvite = true;

        let eventName = 'invite users (admin)';
        const props = {
          type: $scope.invitationList.length > 0 ? 'csv' : 'manual',
        };
        AnalyticsService.logEvent(eventName, props);
      }

      $uibModalInstance.close($scope.editedUser);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    if (action == 'edit') {
      if ($scope.isAuthorized(USER_ROLES.companyAdmin)) {
        if ($scope.editedUser.roles.indexOf(USER_ROLES.companyAdmin) >= 0) {
          $scope.forms.simpleRole = USER_ROLES.companyAdmin;
        } else if (
          $scope.editedUser.roles.indexOf(USER_ROLES.manager) >= 0 &&
          $scope.editedUser.roles.indexOf(USER_ROLES.player) >= 0
        ) {
          $scope.forms.simpleRole = USER_ROLES.manager;
        } else if ($scope.editedUser.roles.indexOf(USER_ROLES.player) >= 0) {
          $scope.forms.simpleRole = USER_ROLES.player;
        }

        $scope.$watch('forms.simpleRole', function (simpleRole) {
          if (simpleRole == USER_ROLES.companyAdmin) {
            $scope.editedUser.roles = [USER_ROLES.companyAdmin];
          } else if (simpleRole == USER_ROLES.manager) {
            $scope.editedUser.roles = [USER_ROLES.manager, USER_ROLES.player];
          } else if (simpleRole == USER_ROLES.player) {
            $scope.editedUser.roles = [USER_ROLES.player];
          } else {
            $scope.editedUser.roles = null;
          }
        });
      }
    }

    init();
  },
]);

angular.module(MODULE).directive('scwMultiEmail', [
  '$window',
  function ($window) {
    // requires an isloated model
    return {
      // restrict to an attribute type.
      restrict: 'A',
      // element must have ng-model attribute.
      require: 'ngModel',
      link: function (_scope, _elem, _attrs, ctrl) {
        // add a parser that will process each time the value is
        // parsed into the model when the user updates it.
        ctrl.$parsers.unshift(function (value) {
          var valid;

          if (value) {
            // trim leading and trailing spaces and commas
            value = value
              .trim()
              .replace(/^\s*,\s*/, '')
              .replace(/\s*,\s*$/, '');
            // since we allow multiple email address, split them and validate them individually
            value = value.split(/\s*,\s*/g);
            valid = value.every(function (email) {
              return $window.emailRegex.test(email);
            });
          }

          ctrl.$setValidity('scw-multi-email', valid);

          // if it's valid, return the value to the model,
          // otherwise return undefined.
          return valid ? value : undefined;
        });
      },
    };
  },
]);
