import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

import { mapDesignSystemUser } from './utils';

// templates
import certificateTemplatesListTemplate from './certificates/CertificateTemplatesList.html';
import listTemplate from './list/AssessmentsList.html';
import addTemplate from './list/AssessmentsAdd.html';
import viewTemplate from './view/AssessmentsView.html';
import inviteTeamsTemplate from './view/AssessmentsInviteTeams.html';
import inviteCompanyTemplate from './view/AssessmentsInviteCompany.html';
import inviteTeamTemplate from './view/AssessmentsInviteTeam.html';
import inviteTemplate from './view/AssessmentsInvite.html';
import viewAttemptTemplate from './view/AssessmentsViewAttempt.html';
import takeTemplate from './take/assessments.challenge.html';
import notLicensedTemplate from './assessments-not-licensed.html';

const app = angular.module(MODULE);

app.controller('AssessmentsRootController', [
  '$scope',
  '$rootScope',
  '$state',
  '$log',
  '$filter',
  'AuthService',
  'USER_ROLES',
  'Session',
  'AssessmentsApiService',
  'ErrorHandler',
  function (
    $scope,
    $rootScope,
    $state,
    $log,
    $filter,
    AuthService,
    USER_ROLES,
    Session,
    AssessmentsApiService,
    ErrorHandler
  ) {
    $scope.AssessmentsApiService = AssessmentsApiService;
    $scope.assessmentsData = {};
    $scope.allowances = {};

    $scope.user = mapDesignSystemUser(Session.user);

    $scope.DISPLAY_ACTIONS = {
      VIEW: 'view',
      RESULTS: 'results',
      RESUME: 'resume',
    };
    var DISPLAY_ACTIONS = $scope.DISPLAY_ACTIONS;

    /** Catching errors for API calls made everytime a route is called. Can help understanding the reason for the
     * failure and whether it could be customer plan related or not
     */
    $scope.retrieveAllowances = function () {
      AssessmentsApiService.retrieveAllowances()
        .then(function (data) {
          $scope.allowances = data;
          $log.debug('[Assessments] Retrieved licensing information: ', data);
        })
        .catch((response) => {
          ErrorHandler.addHttpError('Error retrieving assessment licensing information', response);
        });
    };

    $scope.checkProfileComplete = function () {
      if (
        AuthService.isAuthorized([USER_ROLES.player, USER_ROLES.assessmentUser]) &&
        !Session.user.properties.profile.isComplete
      ) {
        $log.debug('Profile incomplete - redirecting to profile page');
        $state.go('home', {}, { reload: true });
        return;
      }
    };

    function checkRootStateAndRedirect(state) {
      if (state.name == 'assessments') {
        $state.go('assessments.list');
        return;
      }
    }

    $scope.processAttempt = function (attempt) {
      if (attempt.started !== null) {
        attempt.timeSpent =
          (attempt.completed !== null ? moment(attempt.completed) : moment()) - moment(attempt.started);
      } else {
        attempt.timeSpent = null;
      }
      attempt.displayStarted = moment(attempt.started).format('LLL');
      attempt.displayCompleted = moment(attempt.completed).format('LLL');
      attempt.displayDeadline = moment(attempt.deadline).format('LLL');

      attempt.displayTimeSpent = $rootScope.utility.longFormatDuration(attempt.timeSpent);

      attempt.progress.completed = attempt.progress.correct + attempt.progress.incorrect;
      attempt.progress.percentageCompleted = Math.floor(
        (attempt.progress.completed / attempt.progress.numberOfChallenges) * 100
      );

      // if assessment is finished (status = done) then we calculate total accuracy, otherwise calculate current accuracy based on number of questions answered so far
      if (attempt.status == 'done') {
        attempt.progress.accuracy = Math.floor(
          (attempt.progress.correct / attempt.assessment.numberOfChallenges) * 100
        );
      } else {
        attempt.progress.accuracy =
          attempt.progress.completed === 0
            ? 0
            : Math.floor((attempt.progress.correct / attempt.progress.completed) * 100);
      }

      // create timestamp for sorting in recent activity
      if (attempt.status == 'done') {
        attempt.sortTimestamp = attempt.completed;
      } else if (attempt.status == 'pending' && attempt.selfAssess == undefined) {
        // parse timestamp out of mongo _id
        var timestamp = attempt._id.toString().substring(0, 8);
        attempt.sortTimestamp = parseInt(timestamp, 16) * 1000;
      } else if (attempt.status == 'in_progress') {
        attempt.sortTimestamp = attempt.started;
      }

      if (attempt.sortTimestamp) {
        attempt.displaySortTimestamp = moment(attempt.sortTimestamp).format('LLL').toUpperCase();
      }

      if (attempt.user) {
        attempt.user.displayName = attempt.user.name.first + ' ' + attempt.user.name.last;
        if (attempt.user.displayName == ' ') {
          attempt.user.displayName = attempt.user.email;
        }
      }

      if (attempt.author) {
        attempt.author.displayName = attempt.author.name.first + ' ' + attempt.author.name.last;
        if (attempt.author.displayName == ' ') {
          attempt.author.displayName = attempt.author.email;
        }
      }

      if (attempt.score) {
        attempt.score = $scope.utility.floorTwoDecimalPlacesValue(attempt.score); // PORTAL-2875 - Fix attempt scoring rounding errors
        attempt.displayScore = $filter('number')(attempt.score);
      } else {
        attempt.score = 0;
        attempt.displayScore = 0;
      }

      if (attempt.log) {
        var categoryType;
        if (attempt.assessment.categoryType) {
          categoryType = attempt.assessment.categoryType;
        } else {
          categoryType =
            $rootScope.metadata.languages[attempt.assessment.language._id].framework[
              attempt.assessment.language._framework
            ].categoryType;
        }

        for (var i = 0; i < attempt.log.length; i++) {
          var logItem = attempt.log[i];

          if (logItem.challenge) {
            if (logItem.challenge.category && logItem.challenge.category._id) {
              logItem.challenge.category.categoryText =
                $rootScope.metadata.categories[categoryType][logItem.challenge.category._id].name;
            }
            if (logItem.challenge.category && logItem.challenge.category._sub) {
              logItem.challenge.category.subcategoryText =
                $rootScope.metadata.categories[categoryType][logItem.challenge.category._id].subitem[
                  logItem.challenge.category._sub
                ].name;
            }
          } else {
            logItem.challenge = {
              category: {
                _id: 'deprecated',
                _sub: 'deprecated',
                categoryText: 'Deprecated Challenge',
                subcategoryText: 'Deprecated Challenge',
              },
              difficulty: null,
            };
          }
        }
      }

      //set status flags for whether assessment needs to be resumed, view result
      //or view in recent activity
      if (attempt.status == 'pending' || attempt.assessment.status == 'closed') {
        attempt.displayAction = DISPLAY_ACTIONS.VIEW;
      } else if (
        attempt.status == 'in_progress' &&
        attempt.assessment.status == 'enabled' &&
        attempt.assessment.endDate > Date.now()
      ) {
        attempt.displayAction = DISPLAY_ACTIONS.RESUME;
      } else if (
        attempt.status == 'done' ||
        (attempt.status == 'in_progress' && attempt.assessment.endDate <= Date.now())
      ) {
        attempt.displayAction = DISPLAY_ACTIONS.RESULTS;
      } else {
        //set status flag to impossible string
        attempt.displayAction = "shouldn't reach this block";
      }
    };

    $scope.init = function () {
      $scope.checkProfileComplete();
      checkRootStateAndRedirect($state.current);
      $scope.retrieveAllowances();
    };

    $scope.init();

    var deregisterStateChangeStart = $rootScope.$on(
      '$stateChangeStart',
      function (_event, toState, _toParams, _fromState, _fromParams) {
        checkRootStateAndRedirect(toState);
      }
    );

    $scope.$on('$destroy', deregisterStateChangeStart);
  },
]);

app.config([
  '$stateProvider',
  'USER_ROLES',
  function ($stateProvider, USER_ROLES) {
    $stateProvider
      .state('assessments', {
        url: '/assessments',
        controller: 'AssessmentsRootController',
        template: '<ui-view/>',
        data: {
          authorizedRoles: [
            USER_ROLES.admin,
            USER_ROLES.reseller,
            USER_ROLES.companyAdmin,
            USER_ROLES.manager,
            USER_ROLES.player,
            USER_ROLES.assessmentUser,
          ],
        },
      })
      .state('assessments.certificates', {
        url: '/certificates',
        controller: 'AssessmentsCertificatesController',
        templateUrl: certificateTemplatesListTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin],
        },
      })
      .state('assessments.list', {
        url: '/list?d',
        controller: 'AssessmentsListController',
        templateUrl: listTemplate,
        // data is inherited
      })
      .state('assessments.list.add', {
        url: '/add',
        controller: 'AssessmentsAddController',
        templateUrl: addTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view.edit', {
        url: '/edit',
        controller: 'AssessmentsAddController',
        templateUrl: addTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view', {
        url: '/view/{assessmentId}?from=assessments&ownResult',
        controller: 'AssessmentsViewController',
        templateUrl: viewTemplate,
        // data is inherited
      })
      .state('assessments.view.inviteTeams', {
        url: '/invite/teams',
        controller: 'AssessmentsInviteController',
        templateUrl: inviteTeamsTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view.inviteCompany', {
        url: '/invite/company',
        controller: 'AssessmentsInviteController',
        templateUrl: inviteCompanyTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view.inviteTeam', {
        url: '/invite/team',
        controller: 'AssessmentsInviteController',
        templateUrl: inviteTeamTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view.invite', {
        url: '/invite',
        controller: 'AssessmentsInviteController',
        templateUrl: inviteTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('assessments.view.attempt', {
        url: '/attempt/{attemptId}?d&publicNotStarted&ownResult',
        views: {
          '@': {
            // override parent view completely.
            controller: 'AssessmentsViewAttemptController',
            templateUrl: viewAttemptTemplate,
          },
        }, // templateUrl: "assessments/view/AssessmentsViewAttempt.html"
      })
      .state('assessments.take', {
        url: '/take/{_assessment}/{_attempt}?d',
        controller: 'AssessmentsTakeController',
        templateUrl: takeTemplate,
        data: {
          authorizedRoles: [USER_ROLES.player, USER_ROLES.assessmentUser, USER_ROLES.companyAdmin],
        },
      })
      .state('assessments-not-licensed', {
        url: '/assessments-not-licensed',
        templateUrl: notLicensedTemplate,
      });
  },
]);
