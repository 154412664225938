import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Text } from '@securecodewarrior/design-system-react/lib/legacy';
import { FilterAltRounded as FilterIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useModal } from '../context/ModalContext';
import { useCybermonActivityFilter } from '../hooks/useCybermonActivityFilter';
const SelectedLanguages = ({ languages }) => {
    const theme = useTheme();
    const { selectedLanguages } = useCybermonActivityFilter();
    const isTabletLayout = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isOldDesktopLayout = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    if (!selectedLanguages.length || !languages) {
        return null;
    }
    const maxLanguagesToDisplay = isTabletLayout ? 3 : isOldDesktopLayout ? 4 : 5;
    const shouldTruncate = selectedLanguages.length >= maxLanguagesToDisplay;
    const displayLanguages = shouldTruncate ? selectedLanguages.slice(0, maxLanguagesToDisplay) : selectedLanguages;
    const truncatedLanguages = shouldTruncate
        ? selectedLanguages.slice(maxLanguagesToDisplay - 1).map((truncatedLanguage) => { var _a; return (_a = languages[truncatedLanguage]) === null || _a === void 0 ? void 0 : _a.label; })
        : [];
    return (_jsx(Stack, { direction: "row", gap: 4, alignItems: "center", flexWrap: "wrap", children: displayLanguages.map((language, idx) => {
            var _a, _b, _c, _d, _e;
            const isTruncated = idx + 1 >= displayLanguages.length && shouldTruncate;
            const label = isTruncated
                ? `+${selectedLanguages.length - displayLanguages.length + 1}`
                : (_b = (_a = languages[language]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '';
            const icon = isTruncated ? 'devicon-devicon-plain' : (_c = languages[language]) === null || _c === void 0 ? void 0 : _c.iconClassName;
            const tooltip = isTruncated ? truncatedLanguages : (_e = (_d = languages[language]) === null || _d === void 0 ? void 0 : _d.label) !== null && _e !== void 0 ? _e : '';
            return (_jsx(Tooltip, { title: `${tooltip}`, children: _jsx("span", { children: _jsx(Text, { typographyProps: { color: theme.palette.text.soft, noWrap: true }, label: label, startIcon: _jsx("i", { style: { color: theme.palette.text.soft }, className: icon }) }, `selected_language_${language}`) }) }, `tooltip_${idx}`));
        }) }));
};
const SelectLanguagesButton = ({ languages, isContainedVariant = false }) => {
    const { setLanguageModalOpen } = useModal();
    const { selectedLanguages } = useCybermonActivityFilter();
    const { t } = useTranslation('react_components', { keyPrefix: 'components.organisms.languageSelection.filter' });
    return !selectedLanguages || !languages ? (_jsx(Skeleton, { height: "30px", width: "135px", variant: "rounded" })) : (_jsx(Button, { sx: {
            whiteSpace: 'nowrap',
        }, variant: isContainedVariant ? 'contained' : 'text', onClick: () => setLanguageModalOpen(true), startIcon: _jsx(FilterIcon, {}), children: t((selectedLanguages === null || selectedLanguages === void 0 ? void 0 : selectedLanguages.length) === 0 ? 'select' : 'change') }));
};
export const LanguageSelect = ({ languages, isContainedVariant = false, }) => {
    return (_jsxs(Stack, { direction: "row", gap: 4, flexWrap: "wrap", children: [_jsx(SelectLanguagesButton, { languages: languages, isContainedVariant: isContainedVariant }), _jsx(SelectedLanguages, { languages: languages })] }));
};
