import angular from 'angular';
import MODULE from './module';
import { CustomerAnalyticsPageViewEvent } from '../analytics/customer-analytics-events';

const app = angular.module(MODULE);

const excludedStates = ['courses.', 'login', 'logout', 'product-trial-signup'];

const isWhitelistedState = (state = '') => !excludedStates.some((x) => state.includes(x));

app.service('AnalyticsRouteChangeService', [
  '$rootScope',
  '$location',
  'AnalyticsService',
  'customerAnalyticsClient',
  function ($rootScope, $location, AnalyticsService, customerAnalyticsClient) {
    $rootScope.$on('$stateChangeSuccess', function (_event, next, _nextParams, _from, _fromParams) {
      if (isWhitelistedState(next.name)) {
        AnalyticsService.logPageViewEvent($location.absUrl(), next.name);
        // example absUrl: 'https://portal.securecodewarrior.com/#/contextual-microlearning/web/injection/sql'
        if ($location.path().startsWith('/contextual-microlearning')) {
          customerAnalyticsClient.logEvent(new CustomerAnalyticsPageViewEvent($location.absUrl(), next.name));
        }
      }
    });

    return {
      init: function () {},
    };
  },
]);

app.run([
  'AnalyticsRouteChangeService',
  function (AnalyticsRouteChangeService) {
    AnalyticsRouteChangeService.init();
  },
]);
