import angular from 'angular';
import MODULE from './module';
import templateUrl from './company-selector.html';

/**
 * Company Selector for SCW frontend
 * Author: Tushar Nautial <tnautiyal@securecodewarrior.com>
 * Example Usage:

  <company-selector ng-if="isAuthorized(userRoles.admin)"
            placeholder="{{'TOURNAMENTLIST_SELECT_COMPANY_PLACEHOLDER' | translate}}"
            company-selector="companySelector"
            company-selected="companySelected()"
            ui-select-analysis="tournament-field-company"
            company-search="tournamentCompanySearch"
            btn-load-more-analytics="tournament-field-loadMoreCompanies"
            company-ui-select="tournament-ui-select"
            allow-clear="false">
  </company-selector>


Attributes:
  placeholder: The default text in the search box
  company-selector: Selected company
  company-selected: Function to call when a company is selected
  ui-select-analysis: The text to use for analytics when the user focusses on ui-select
  company-search: Search text
  btn-load-more-analytics: The text to use for analytics when the user clicks load more button
  company-ui-select: Styling for ui-select element
  company-ui-select-choices: Styling for ui-select-choices element
  allow-clear: Allows you to clear the current value
*/

angular.module(MODULE).directive('companySelector', [
  'AdminApiService',
  function (AdminApiService) {
    return {
      restrict: 'E',
      scope: {
        placeholder: '@',
        companySelected: '&',
        companySelector: '=',
        uiSelectAnalytics: '@',
        companySearch: '=',
        btnLoadMoreAnalytics: '@',
        companyUiSelect: '@',
        companyUiSelectChoices: '@',
        allowClear: '=',
      },
      templateUrl,
      controller: [
        '$scope',
        '$element',
        function ($scope, _$element) {
          function resetCompanyList() {
            $scope.companyList = [];

            $scope.companySearch = {
              options: { page: 1, size: 10 },
            };
          }

          $scope.fetchCompanies = function (filter, $event, firstLoad) {
            if ($event) {
              $event.stopPropagation();
              $event.preventDefault();
            } else if (firstLoad || filter) {
              // (no event) and (firstLoad or filter) string means first load or new search
              resetCompanyList();
            } else if (!filter) {
              // not firstLoad and no filter means first dropdown open or filter was cleared
              return;
            }

            if ($scope.companySearch.options.page >= $scope.companySearch.options.pages) {
              // no more to fetch
              return;
            }

            if ($event) {
              $scope.companySearch.options.page++;
            }

            $scope.companiesLoading = true;

            // get company and team list
            AdminApiService.addLoading();
            return AdminApiService.getCompanyList(filter, $scope.companySearch.options, 'name')
              .then(function (companies) {
                $scope.companySearch.options = companies;
                $scope.companyList = $scope.companyList.concat(companies.data);
              })
              .finally(function () {
                AdminApiService.removeLoading();
                $scope.companiesLoading = false;
              });
          };

          $scope.uiSelectAction = function ($select) {
            $select.search = '';
            $scope.companySelected();
          };

          $scope.fetchCompanies('', null, true);
        },
      ],
    };
  },
]);
