import { platformLocaleToBcp47 } from '@scw/react-components';
import { getLocalVideoPreferences } from '@securecodewarrior/design-system-react';
import angular from 'angular';
import MODULE from './module';
import singleChallengePlayerHTML from './single.challenge.player.html';

angular.module(MODULE).component('singleChallengePlayer', {
  templateUrl: singleChallengePlayerHTML,
  bindings: {
    playmode: '=',
    challenge: '<',
    loading: '<',
    error: '<',
    onBeginChallenge: '&?',
    onNextChallenge: '&?',
    onSkipChallenge: '&?',
    onAbandon: '&?',
    breadcrumbStats: '<',
  },
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    'CHURNZERO_METRICS',
    '$sce',
    '$window',
    'Session',
    'AnalyticsService',
    function ($scope, $rootScope, $state, CHURNZERO_METRICS, $sce, $window, Session, AnalyticsService) {
      $scope.i18n = Session?.user?.properties?.profile?.i18nLanguagePreference || 'en';
      $scope.initialSubtitle = Session?.user?.properties?.profile?.subtitlesLanguage;
      $scope.initialVideoPreferences = getLocalVideoPreferences();

      $scope.throttle = false;
      $scope.videosWatched = new Set();

      $scope.createVideoId = function (categoryKey, subCategoryKey) {
        const learningSubItems = Object.entries($rootScope.metadata.learning.subitem);
        let result = '';

        // using .find for early return
        learningSubItems.find(([firstLayerKey, firstLayerValue]) => {
          if (!firstLayerValue.subitem) return;

          return Object.entries(firstLayerValue.subitem).find(([secondLayerKey, secondLayerValue]) => {
            // key should be either web or mobile here, I think
            if (!secondLayerValue.subitem) return;

            return Object.entries(secondLayerValue.subitem).find(([thirdLayerKey, thirdLayerValue]) => {
              if (!thirdLayerValue.subitem) return;

              const category = thirdLayerValue.subitem[categoryKey];

              if (category && category.subitem && category.subitem[subCategoryKey]) {
                result = `${firstLayerKey}:${secondLayerKey}:${thirdLayerKey}:${categoryKey}:${subCategoryKey}`;
                return true;
              }
            });
          });
        });

        return result;
      };

      $scope.handleEvent = function (message) {
        if ($scope.throttle) return;
        switch (message.event) {
          case 'cp:analytics': {
            const routeProps = AnalyticsService.getRouteProps();
            const portal_area = $scope.playmode;
            const { eventName, eventProps } = message;
            AnalyticsService.logEvent(eventName, { ...routeProps, ...eventProps, portal_area });
            break;
          }
          case 'cp:nextChallenge':
            $scope.onNextChallenge();
            $scope.throttle = true;
            break;
          case 'cp:skipChallenge':
            if (this.playmode == 'assessments') break; // assessments can't skip challenges
            $scope.onSkipChallenge();
            $scope.throttle = true;
            break;
          case 'cp:videoStarted':
            $window.ChurnZeroEvent({ eventName: CHURNZERO_METRICS.LEARNING.USER.ACCESSED });
            break;
          case 'cp:videoWatched': {
            const videoId = $scope.createVideoId(message.categoryKey, message.subCategoryKey);

            if (!videoId || $scope.videosWatched.has(videoId)) break;

            if (['courses', 'training', 'assessments', 'tournaments'].includes($scope.playmode)) {
              const playmode = `${$scope.playmode}-challenge`;
              $window.top.postMessage({ event: 'videoWatched', duration: message.duration, videoId, playmode });
            } else if (!['microlearning', 'cms'].includes($scope.playmode)) {
              console.error(`single challenge player: unknown playmode "${$scope.playmode}", not sending video log`);
            }
            $scope.videosWatched.add(videoId);
            break;
          }
          default:
            break;
        }
      };

      $scope.onMessage = function (message) {
        $scope.handleEvent(message.data);
      };

      $scope.reload = function () {
        $state.go($state.current.name, $state.params, { reload: true });
      };

      $scope.contactSupport = function () {
        $rootScope.showUsersnap();
      };

      this.$onInit = function () {
        $scope.playmode = this.playmode;
        $scope.challenge = this.challenge;
        $scope.loading = this.loading;
        $scope.onNextChallenge = this.onNextChallenge;
        $scope.onSkipChallenge = this.onSkipChallenge;
        $scope.breadcrumbStats = this.breadcrumbStats;

        $scope.$on('breadcrumb:abandon', this.onAbandon);
        $scope.$on('breadcrumb:skip', () => $scope.handleEvent({ event: 'cp:skipChallenge' }));
        $window.addEventListener('message', $scope.onMessage);
      };

      this.$onChanges = function () {
        $scope.error = this.error;
        if (!this.challenge) return;

        if (this.onBeginChallenge && !$scope.challenge && this.challenge) {
          this.onBeginChallenge();
        }

        $scope.challenge = this.challenge;
        $scope.loading = this.loading;
        $scope.breadcrumbStats = this.breadcrumbStats;
        $scope.throttle = false;
        const url = new URL(this.challenge.externalPlayerUrl);

        if ($scope.initialSubtitle) url.searchParams.set('subtitle', platformLocaleToBcp47($scope.initialSubtitle));

        url.searchParams.set('lang', $scope.i18n);
        url.searchParams.set('volume', $scope.initialVideoPreferences.volume);
        url.searchParams.set('muted', $scope.initialVideoPreferences.muted);
        url.searchParams.set('playbackrate', $scope.initialVideoPreferences.playbackRate);
        if (this.playmode === 'assessments') {
          url.searchParams.set('enableSkip', 'false');
        }
        $scope.url = $sce.trustAsResourceUrl(url.toString());
      };

      this.$onDestroy = function () {
        $window.removeEventListener('message', $scope.onMessage);
      };
    },
  ],
});
