import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import _ from 'lodash';
import swal from 'sweetalert';
import csvExportTemplate from '../assessments.csv.export.info.html';
import { AssessmentsBannerTooltip } from '@scw/react-components';
import { USER_ROLES } from '../../auth/constants';

// @aambertin polyfill for msie incompatibility with the new Event dispatching WebAPI.
function dispatchEvent(evt) {
  if (typeof evt === 'string') {
    // new Event object API -> OK
    if (Event.apply) {
      evt = new Event(evt);
    }
    // deprecated way -> required to work on MSIE
    else {
      let evttype = evt;
      evt = document.createEvent('Event');
      evt.initEvent(evttype, true, true);
    }
  }
  window.dispatchEvent(evt);
}

const app = angular.module(MODULE).run([
  '$anchorScroll',
  function ($anchorScroll) {
    $anchorScroll.yOffset = 100; // always scroll by 50 extra pixels
  },
]);

/*********** list ***********/

app.controller('AssessmentsListController', [
  '$scope',
  '$rootScope',
  '$state',
  '$translate',
  '$uibModal',
  '$log',
  '$swal',
  '$location',
  '$window',
  'AuthService',
  'AssessmentsApiService',
  'ErrorHandler',
  'CustomerPlanApi',
  'AnalyticsService',
  function (
    $scope,
    $rootScope,
    $state,
    $translate,
    $uibModal,
    $log,
    $swal,
    $location,
    $window,
    AuthService,
    AssessmentsApiService,
    ErrorHandler,
    CustomerPlanApi,
    AnalyticsService
  ) {
    $scope.designUplift = $scope.user.role === USER_ROLES.player ? true : false;
    $scope.greenSx = {
      color: '#657d19',
    };

    const VERSION_STR = ' v';
    const VERSION_REGEX = / v[0-9]+$/;
    let tz = moment.tz.guess();

    let retainedFilterAndSortSettings = {};
    try {
      const settings = $window.localStorage.getItem('assessmentListFilterSettings');
      if (settings) {
        retainedFilterAndSortSettings = JSON.parse(settings) || {};
      }
    } catch (error) {
      console.log(error);
    }

    $scope.noAssessmentExists = false;

    $scope.manageRetryLimit = function (assessment) {
      $scope.assessment = assessment;
      const manageRetryLimitModal = $uibModal.open({
        templateUrl: 'ManageRetryLimitModal.html',
        controller: 'ManageRetryLimitModal',
        size: 'lg',
        scope: $scope,
        backdrop: 'static',
      });

      manageRetryLimitModal.result
        .then(function () {
          getAssessmentList();
          $state.go('assessments.list');
        })
        .catch(function (error) {
          $log.debug('error:', error);
        });
    };

    $scope.goToMetrics = function (assessment) {
      const assessmentParam = {
        id: assessment._assessment,
        name: assessment.name,
      };
      const param = {
        assessments: [assessmentParam],
        hasSelection: true,
        type: 2,
      };
      const paramString = encodeURIComponent(JSON.stringify(param));
      window.location.href = `#/reporting/assessments?content=${paramString}`;
    };

    $scope.bannerChildrenRenderProp = () => (
      <AssessmentsBannerTooltip
        tooltipIconName="info"
        tooltipAssessmentsDescriptionTranslationKey="pages.assessments.legacy.banner.description"
      />
    );

    $scope.levelGroups = [
      { _id: '100', name: $translate.instant('ALL_GROUPS') },
      { _id: '200', name: $translate.instant('UNASSIGNED') },
    ];

    $scope.goToManageCertificates = () => $location.path('/assessments/certificates');
    $scope.menuChildren = [
      {
        text: $translate.instant('DOWNLOAD_CSV'),
        icon: 'download',
        onClick: () => {
          $scope.toggleAssessmentOptionsMenu();
          $scope.showCsvDownloadModal();
          AnalyticsService.logEvent('assessmentView-button-certificates');
        },
      },
    ];
    $scope.assessmentOptionsMenu = { open: false };

    $scope.toggleAssessmentOptionsMenu = () =>
      ($scope.assessmentOptionsMenu = { open: !$scope.assessmentOptionsMenu.open });

    $scope.lmsIntegrationEnabled = false;

    $scope.sorting = {
      sortAssessmentsBy: retainedFilterAndSortSettings?.sorting?.sortAssessmentsBy || 'name',
      sortAssessmentsByParam: retainedFilterAndSortSettings?.sorting?.sortAssessmentsByParam || 'name',
      sortAssessmentsReverse: retainedFilterAndSortSettings?.sorting?.sortAssessmentsReverse || false,
      filterAssessmentsByStatus: retainedFilterAndSortSettings?.sorting?.filterAssessmentsByStatus || 'all',
      filterAssessmentsByGroupId: retainedFilterAndSortSettings?.sorting?.filterAssessmentsByGroupId || '',
      assessmentsGroup: $scope.levelGroups[0],
    };

    $scope.getSortSelectInput = () =>
      `${$scope.sorting?.sortAssessmentsBy || 'name'}_${$scope.sorting.sortAssessmentsReverse ? 'desc' : 'asc'}`;

    $scope.sorting.sortSelectInput = $scope.getSortSelectInput();

    $scope.clearFiltersAndSort = () => {
      if (!$scope.enableClearFilters) return;

      $scope.assessmentsInfo.assessmentSearchText = '';
      $scope.ui.assessmentSearchText = '';
      $scope.assessmentsPage.filter = '';
      $scope.sorting.sortAssessmentsBy = 'name';
      $scope.sorting.sortAssessmentsByParam = 'name';
      $scope.sorting.filterAssessmentsByStatus = 'all';
      $scope.sorting.filterAssessmentsByGroupId = '';
      $scope.sorting.assessmentsGroup = $scope.levelGroups[0];
      $scope.sorting.sortAssessmentsReverse = false;

      $scope.sorting.sortSelectInput = $scope.getSortSelectInput();

      getAssessmentList();
    };

    $scope.isDefaultFilterState = () => {
      return (
        !$scope.assessmentsInfo?.assessmentSearchText &&
        $scope.sorting?.filterAssessmentsByStatus === 'all' &&
        !$scope.sorting?.filterAssessmentsByGroupId
      );
    };

    $scope.isDefaultFilterAndSortState = () => {
      return (
        $scope.isDefaultFilterState() &&
        $scope.assessmentsPage?.filter === '' &&
        $scope.sorting?.sortAssessmentsByParam === 'name' &&
        $scope.sorting?.sortAssessmentsReverse === false
      );
    };

    $scope.init = function () {
      getAssessmentGroupsConfigured();
      getAssessmentList();

      if ($state.current.name == 'assessments.list.add') {
        $scope.isShowingAdd = true;
      }

      CustomerPlanApi.isAllowed('lms-integration').then((res) => {
        $scope.lmsIntegrationEnabled = res;
      });
    };

    $scope.limitNameLength = function (name, size) {
      return name.length <= size ? name : `${name.substr(0, size)}...`;
    };

    $scope.setAssessmentsGroupByFilter = function (group) {
      if ($scope.levelGroups.find((g) => g._id === group._id)) {
        $scope.sorting.assessmentsGroup = group;
      }
      $scope.sorting.filterAssessmentsByGroupId = group._id === '100' ? '' : group._id;
      getAssessmentList();
    };

    $scope.setSortAssessmentsBy = function (s) {
      let sortParam = s.split('_');
      let isAsc = (sortParam[1] || 'asc') === 'asc';
      $scope.sorting.sortAssessmentsBy = sortParam[0];
      $scope.sorting.sortAssessmentsByParam = (isAsc ? '' : '-') + sortParam[0];
      $scope.sorting.sortAssessmentsReverse = isAsc ? false : true;

      getAssessmentList();
    };
    $scope.setFilterAssessmentsBy = function (s) {
      $scope.sorting.filterAssessmentsByStatus = s;
      getAssessmentList();
    };
    // recent activity is not paginated so not used for now
    $scope.recentActivityPage = {
      options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage },
    };

    let assessmentsPage = ($scope.assessmentsPage = {
      filter: retainedFilterAndSortSettings?.assessmentsPage?.filter || '',
      options: {
        page: 1,
        size: $rootScope.pagerPrefs.itemsPerPage,
      },
    });

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.assessmentsPage.options.page = 1;
        $scope.assessmentsPage.options.size = newVal;
        getAssessmentList();
      }
    });

    $scope.assessmentsPagerMaxSize = 10;

    $scope.paginate = paginate;

    function paginate(inc) {
      assessmentsPage.options.page += inc;
      getAssessmentList();
    }

    $scope.assessmentsPagerChanged = function (_event, page) {
      $scope.assessmentsPage.options.page = page;
      getAssessmentList();
    };

    $scope.ui = $scope.ui || {};
    $scope.ui.assessmentSearchText = retainedFilterAndSortSettings?.assessmentsInfo?.assessmentSearchText || '';
    $scope.newAssessmentSearch = function (searchTerm) {
      // Safeguard for undefined searchTerm
      if (searchTerm != void 0) {
        $scope.assessmentsInfo.assessmentSearchText = getSearchTermWithoutDisplayVersionSuffix(searchTerm);
      }
      $scope.assessmentsPage.options.page = 1;
      getAssessmentList();
    };

    $scope.searchOnClick = {
      onClick: function () {
        $scope.newAssessmentSearch($scope.ui.assessmentSearchText);
      },
    };

    $scope.displayTime = $rootScope.utility.quickFormatTime;

    let assessmentsMap = {};
    $scope.isShowingAssessmentInfo = false;

    function zeroPad(num, size) {
      let s = '000000' + num;
      return s.substr(s.length - size);
    }

    $scope.processAssessmentList = function (data) {
      $scope.assessmentsPage.options = data;

      let list = data.data;
      for (let i = 0; i < list.length; i++) {
        let assessmentObj = list[i];
        assessmentObj.displayTimeLimit =
          moment.duration(assessmentObj.timeLimit).asHours() +
          (moment.duration(assessmentObj.timeLimit).asHours() == 1
            ? ' ' + $translate.instant('DATE_TIME.HOUR')
            : ' ' + $translate.instant('DATE_TIME.HOURS'));

        if (assessmentObj.difficulty == 'easy') {
          assessmentObj.difficulties = [true];
        } else if (assessmentObj.difficulty == 'medium') {
          assessmentObj.difficulties = [true, true];
        } else if (assessmentObj.difficulty == 'hard') {
          assessmentObj.difficulties = [true, true, true];
        }

        if (assessmentObj.difficulties)
          //:
          assessmentObj.sortedDifficulty = assessmentObj.difficulties.length || 0;

        assessmentsMap[assessmentObj._id] = assessmentObj;

        assessmentObj.displayName = `${assessmentObj.name}${getVersionSuffixFromAssessmentObj(assessmentObj)}`;
        assessmentObj.sortName = assessmentObj.name + ' v' + zeroPad(100000 - assessmentObj.version, 6); // HACK: if it ever looks like an assessment version is going to go over 100000 then this will need to be updated
      }

      $scope.assessmentsList = list;
    };

    function getSearchTermWithoutDisplayVersionSuffix(searchTerm) {
      return searchTerm.replace(VERSION_REGEX, '');
    }

    function getVersionSuffixFromAssessmentObj(assessmentObj) {
      return `${VERSION_STR}${assessmentObj.version + 1}`;
    }

    $scope.selectAssessment = function (assessment) {
      $state.go('assessments.view', { assessmentId: assessment._id, from: 'assessments' });
    };

    $scope.selectAttempt = function (attempt) {
      $scope.assessmentsData.fromRecentActivity = true;
      $state.go('assessments.view.attempt', { assessmentId: attempt._assessment, attemptId: attempt._id });
    };

    //allow user to download combined csv report for all assessments
    $scope.getAllSummaryCsvReportV3 = function () {
      AssessmentsApiService.addLoading();
      AssessmentsApiService.getAllSummaryCsvReportV3(tz)
        .then(function (response) {
          AssessmentsApiService.performDownload(response);
          swal.close();
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_SUMMARY_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_SUMMARY_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    //allow user to download combined csv report for all assessments
    $scope.getAllResultsCsvReportV3 = function () {
      AssessmentsApiService.addLoading();
      AssessmentsApiService.getAllResultsCsvReportV3(tz)
        .then(function (response) {
          AssessmentsApiService.performDownload(response);
          swal.close();
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_RESULTS_REPORT_CSV']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_RESULTS_REPORT_CSV, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
        });

      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.addAssessment = function () {
      if (AuthService.isFeatureEnabled('assessments')) {
        $scope.isShowingAdd = true;
        $state.go('assessments.list.add');
      } else {
        $state.go('assessments-not-licensed');
      }
    };

    $scope.navigateToBaseWithPath = function (path) {
      const newUrl = `${window.location.protocol}//${window.location.host}/#${path}`;
      window.location.href = newUrl;
    };

    $scope.goBack = function () {
      if ($state.current.name == 'assessments.list.add') {
        $scope.isShowingAdd = false;
        $state.go('assessments.list', {}, { reload: true });
      }
    };

    // Keep track of total number of assessments
    $scope.assessmentsInfo = {
      numberOfAssessments: 1,
      assessmentSearchText: retainedFilterAndSortSettings?.assessmentsInfo?.assessmentSearchText || '',
    };

    function getAssessmentGroupsConfigured() {
      AssessmentsApiService.loadGroupsForCompany()
        .then(function (data) {
          $scope.levelGroups.splice(1, 0, ...data);
          $scope.sorting.assessmentsGroup = $scope.levelGroups[0];
          if ($scope.sorting.filterAssessmentsByGroupId && $scope.sorting.filterAssessmentsByGroupId !== '100') {
            const matchingGroup = $scope.levelGroups.find(
              (group) => group._id === $scope.sorting.filterAssessmentsByGroupId
            );
            if (matchingGroup) {
              $scope.sorting.assessmentsGroup = matchingGroup;
            } else {
              $scope.sorting.filterAssessmentsByGroupId = '';
              getAssessmentList(); // A group has been deleted so we re-fetch with no group condition
            }
          }
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_LEVELGROUPS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_LEVELGROUPS, response);
          });
        });
    }

    $scope.loading = false;
    function getAssessmentList() {
      $scope.loading = true;
      const currentFetchTimeStamp = Date.now();
      $scope.lastFetchTimestamp = currentFetchTimeStamp;

      $scope.assessments = AssessmentsApiService.getAssessmentList(
        $scope.assessmentsInfo.assessmentSearchText,
        $scope.assessmentsPage.options,
        $scope.sorting.sortAssessmentsByParam,
        $scope.sorting.filterAssessmentsByStatus,
        $scope.sorting.filterAssessmentsByGroupId
      )
        .then(function (data) {
          if ($scope.lastFetchTimestamp !== currentFetchTimeStamp) return;

          $scope.processAssessmentList(data);

          if ($scope.isDefaultFilterState()) {
            $scope.noAssessmentExists = !data.data.length;
          }

          try {
            $window.localStorage.setItem(
              'assessmentListFilterSettings',
              JSON.stringify({
                assessmentsInfo: $scope.assessmentsInfo,
                assessmentsPage: $scope.assessmentsPage,
                sorting: $scope.sorting,
              })
            );

            $scope.enableClearFilters = !$scope.isDefaultFilterAndSortState();
            $scope.loading = false;
          } catch (error) {
            $scope.loading = false;
          }

          return $scope.assessmentsList;
        })
        .catch(function (response) {
          $scope.loading = false;
          $translate(['ERROR_LOADING_ASSESSMENTS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_ASSESSMENTS, response);
          });
        });
    }
    $scope.getAssessmentList = getAssessmentList;

    $scope.getUserAssessmentStatus = function (attempt) {
      if (attempt.status === 'pending') {
        return attempt.isSelfAssess ? 'selfAssess' : 'pending';
      }
      if (attempt.status === 'in_progress') {
        return 'inProgress';
      }
      return attempt.status === 'done' ? 'done' : 'other';
    };

    $scope.isDeveloper = function () {
      return !AuthService.isAuthorized([
        $scope.userRoles.admin,
        $scope.userRoles.reseller,
        $scope.userRoles.companyAdmin,
        $scope.userRoles.manager,
      ]);
    };

    $scope.showCsvDownloadModal = () => {
      const scope = $scope.$new(false, $scope);
      scope.options = $scope.getCsvModalParams();
      $swal({
        title: $translate.instant('ASSESSMENT_EXPORTS'),
        templateUrl: csvExportTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: true,
        keyResolution: true,
        scope: scope,
      });
    };

    $scope.getCsvModalParams = () => {
      return [getAllResultsCvsParams(), getAllSummaryCsvParams()];
    };

    const getAllResultsCvsParams = () => {
      return {
        name: 'ASSESSMENT_RESULTS_CSV',
        description: 'ASSESSMENT_RESULTS_CSV_DESCRIPTION',
        resolution: $scope.getAllResultsCsvReportV3,
      };
    };

    const getAllSummaryCsvParams = () => {
      return {
        name: 'ASSESSMENT_PROGRESS_CSV',
        description: 'ASSESSMENT_PROGRESS_CSV_DESCRIPTION',
        resolution: $scope.getAllSummaryCsvReportV3,
      };
    };

    $scope.init();
  },
]);

/****** add *******/
app.directive('assessmentsAddHourDuration', function () {
  let HOUR_DURATION = 60 * 60 * 1000;

  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (_scope, _element, _attrs, ngModel) {
      ngModel.$formatters.push(function (value) {
        return value ? value / HOUR_DURATION : '';
      });

      ngModel.$parsers.push(function (value) {
        return value ? value * HOUR_DURATION : null;
      });
    },
  };
});

app.controller('AssessmentsAddController', [
  '$scope',
  '$rootScope',
  '$translate',
  '$state',
  '$log',
  '$location',
  'LanguageUtils',
  'AuthService',
  '$uibModal',
  'Session',
  'AssessmentsApiService',
  'ErrorHandler',
  'WalkthroughService',
  '$timeout',
  '$anchorScroll',
  '$stateParams',
  'CustomerPlanApi',
  '$analytics',
  function (
    $scope,
    $rootScope,
    $translate,
    $state,
    $log,
    $location,
    LanguageUtils,
    AuthService,
    $uibModal,
    Session,
    AssessmentsApiService,
    ErrorHandler,
    WalkthroughService,
    $timeout,
    $anchorScroll,
    $stateParams,
    CustomerPlanApi,
    $analytics
  ) {
    let storedAdvancedOptions = null;

    $scope.descriptionInputProps = { maxLength: 1024, name: 'description', required: true };
    $scope.nameInputProps = { maxLength: 1024, name: 'name', required: true };
    $scope.availableCertificateTemplates = [];
    $scope.availableGroups = [];
    $scope.highlightInvalid = false;
    $scope.isEditingFieldsLocked = false;
    $scope.shouldValidateChallenges = true;
    $scope.levelGroupingFeatureEnabled = $rootScope.levelGroupingFeatureEnabled;
    $scope.levelGroups = [];
    $scope.lmsIntegrationEnabled = false;
    $scope.showLmsDescription = false;
    $scope.toggleLmsDescription = function () {
      $scope.showLmsDescription = !$scope.showLmsDescription;
    };

    $scope.init = function () {
      getLevelGroups();
      CustomerPlanApi.isAllowed('lms-integration').then((res) => {
        $scope.lmsIntegrationEnabled = res;
      });
    };

    $scope.addOrEditDescription = $scope.editing
      ? $translate.instant('ASSESSMENTSADD_CAN_EDITING_USERROLES')
      : $translate.instant('ASSESSMENTSADD_NO_EDITING_USERROLES');

    function getLevelGroups() {
      AssessmentsApiService.loadGroupsForCompany()
        .then(function (groups) {
          $scope.levelGroups = groups;
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_LEVELGROUPS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_LEVELGROUPS, response);
          });
        });
    }

    function refreshAssessmentGroup() {
      AssessmentsApiService.getAssessmentData($stateParams.assessmentId)
        .then((assessment) => {
          $scope.assessmentToSave.levelGrouping = assessment.levelGrouping;
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_ASSESSMENTS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_LOADING_ASSESSMENTS, response);
          });
        });
    }

    function setAnalyticsForElements() {
      const emitPdfCheckbox = document.querySelector('#emitPdf input');
      emitPdfCheckbox.onfocus = () => {
        $analytics.eventTrack('assessmentAdd-field-certificateCheckbox');
      };
    }

    $scope.toggleEmitsCertificate = function () {
      $scope.assessmentToSave.emitsCertificate = !$scope.assessmentToSave.emitsCertificate;
    };

    $scope.lockedFieldsModal = function (field) {
      $scope.uplockFieldName = $translate.instant(field);

      let lockedFieldsModal = $uibModal.open({
        templateUrl: 'LockedFieldsModal.html',
        controller: 'LockedFieldsModal',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
      });

      lockedFieldsModal.result
        .then(function (unlockFields) {
          if (unlockFields) {
            $scope.isEditingFieldsLocked = false;
            $scope.shouldValidateChallenges = true;
          }
        })
        .catch(function (error) {
          $log.debug('error:', error);
        });
    };

    $scope.assignGroupModal = function () {
      $rootScope.prefilledLevelGroup = $scope.assessmentToSave.levelGrouping;

      let levelGroupModal = $uibModal.open({
        templateUrl: 'LevelGroupModal.html',
        controller: 'LevelGroupModalController',
        windowClass: 'modal-level-group',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
      });

      levelGroupModal.result
        .then(async (data) => {
          await getLevelGroups();
          if ($state.current.name === 'assessments.view.edit' && data?.refreshAfterClose) {
            await refreshAssessmentGroup();
          }
        })
        .catch(function (error) {
          $log.debug('error:', error);
        })
        .finally(function () {
          delete $rootScope.prefilledLevelGroup;
        });
    };

    $scope.walkthrough = function () {
      let tutorials = Session.user.properties.tutorial;
      tutorials.items['doneCreateAssessment'] = true;
      AuthService.updateTutorialStatus(tutorials)
        .then(function (data) {
          Session.user.properties.tutorial = data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError('Error updating tutorial status', response);
        })
        .finally(function () {});

      $scope.accordion.open = false;
      WalkthroughService.trigger('assessments.create', { overlayClicked: 'exit' });
    };

    let invalidChallengeList = [];
    let trackedDataForLog = { languages: null, template: null, templateFlag: false };

    $scope.isShowingAssessmentStateDesc = false;
    $scope.accordion = { open: false };

    $scope.scrollToInvalidChallenge = function () {
      //Get Invalid challenge
      let index = _.findIndex($scope.questStoryValidityResult.challenges, function (c) {
        return !c.isValid;
      });
      let newHash = 'challenge-row-' + index;
      $location.hash(newHash);
      $anchorScroll();
    };

    // check if user has done the assessment create walkthrough already
    if ($state.current.name == 'assessments.list.add') {
      $timeout(function () {
        let tutorials = Session.user.properties.tutorial;
        if (tutorials.enabled && !tutorials.items['doneCreateAssessment']) {
          tutorials.items['doneCreateAssessment'] = true;
          AuthService.updateTutorialStatus(tutorials)
            .then(function (data) {
              Session.user.properties.tutorial = data;
            })
            .catch(function (response) {
              ErrorHandler.addHttpError('Error updating tutorial status', response);
            })
            .finally(function () {});
          $scope.accordion.open = false;
          WalkthroughService.trigger('assessments.create');
        }
      }, 100);
    }

    AssessmentsApiService.retrieveCertificateTemplatesList().then(function (templates) {
      $scope.availableCertificateTemplates = templates;
    });

    function categoryCompare(a, b) {
      if (a.categoryName == 'Other') return 1;
      if (b.categoryName == 'Other') return -1;
      if (a.categoryName < b.categoryName) return -1;
      if (a.categoryName > b.categoryName) return 1;
      return 0;
    }

    function subcategoryCompare(a, b) {
      if (a.subcategoryName == 'Other') return 1;
      if (b.subcategoryName == 'Other') return -1;
      if (a.subcategoryName < b.subcategoryName) return -1;
      if (a.subcategoryName > b.subcategoryName) return 1;
      return 0;
    }

    let convertToTimezone = $rootScope.utility.convertToTimezone;
    let convertToUTC = $rootScope.utility.convertToUTC;

    $scope.forms = {};

    $translate(['EASY', 'MEDIUM', 'HARD']).then(function (translations) {
      $scope.challengeDifficuties = {
        easy: translations.EASY,
        medium: translations.MEDIUM,
        hard: translations.HARD,
      };
    });
    $scope.difficultyList = [];

    $scope.categoryTypes = [
      { id: 'web', name: 'Web Application' },
      { id: 'mobile', name: 'Mobile Application' },
    ];

    let languageFrameworkTypesAvailable = [];
    let i;
    for (i = 0; i < Session.user.properties.languages.length; i++) {
      let l = Session.user.properties.languages[i];
      if (l.status === 'available' || l.status === 'active') {
        languageFrameworkTypesAvailable.push(_.merge({ name: LanguageUtils.languageName(l.language) }, l.language));
      }
    }
    for (i = 0; i < languageFrameworkTypesAvailable.length; i++) {
      let lft = languageFrameworkTypesAvailable[i];
      lft.categoryType = $scope.metadata.languages[lft._id].framework[lft._framework].categoryType;
    }

    $scope.languageFrameworkTypes = languageFrameworkTypesAvailable;
    $scope.languageFrameworkTypesAvailable = languageFrameworkTypesAvailable;

    const defaultAdvancedOptions = {
      startDate: null,
      endDate: null,
      sendInvitesOn: null,
      timezone: moment.tz.guess(),
      selfAssess: false,
      retriesAllowed: false,
      maxRetries: null,
      timeLimit: null,
      retryWaitingHours: null,
      fixedCBL: null,
    };

    const defaultEmptyAssessmentBase = {
      name: '',
      description: '',
      languages: [],
      levelGrouping: null,
      questStory: {
        challenges: [],
      },
      _certificateTemplate: '',
      emitsCertificate: false,
      fixedChallenges: false,
      suggestInduction: false,
      lmsIntegrated: false,
      prioritizeChallengeDifficulty: false,
    };

    const defaultEmptyAssessment = {
      ...defaultEmptyAssessmentBase,
      ...defaultAdvancedOptions,
    };

    $scope.questStoryValidityResult = null;

    /**
     * Return a subcategory name from metadata
     * @param  {String} cat metadata Id
     * @param  {String} subcat metadata Id
     * @return {String}
     */
    function resolveSubcategoryName(cat, subcat) {
      return $rootScope.metadata.categories[$scope.categoryType][cat].subitem[subcat].name;
    }

    /**
     * Return a lang name from metadata
     * @param  {String} cat
     * @return {String}
     */
    function resolveCategoryName(cat) {
      return $rootScope.metadata.categories[$scope.categoryType][cat].name;
    }

    function resolveLanguageName(lang) {
      let result = $scope.assessmentToSave.languages.reduce(function (accumulator, language) {
        let langString = language._id + '::' + language._framework;
        if (langString === lang) {
          return (accumulator += LanguageUtils.languageName(language));
        }
        return accumulator;
      }, '');
      return result;
    }

    /**
     * Adds invalid challenge selection to the list for logs
     * @param {string} catKey
     * @param {string} diffKey
     * @param {string} subcatKey
     */
    function addInvalidChallengeSelected(catKey, diffKey, subcatKey) {
      let invalidChallenge = {
        languages: trackedDataForLog.languages,
        category: catKey,
        subcategory: subcatKey,
        difficulty: diffKey,
      };
      if (trackedDataForLog.templateFlag) {
        invalidChallenge.template = trackedDataForLog.template;
      }
      if (!_.some(invalidChallengeList, invalidChallenge)) {
        invalidChallengeList.push(invalidChallenge);
        //call assessment invalid challenge log api
        AssessmentsApiService.logInvalidChallenge(invalidChallenge);
      }
    }

    function toggle(what) {
      _.set($scope, what, !_.get($scope, what));
    }

    $scope.toggle = toggle;

    /**
     * Figures out whether a challenge is part of the combined stats which is a combination
     * of approved and deprecated stats. If the combined category and deprecatedLanguages
     * property are not available, it is safe to say the challenge is not deprecated
     * @param catKey
     * @param diffKey
     * @param subcatKey
     * @returns {{isAvailableInCombinedStats: boolean, language: string}}
     */
    $scope.isChallengeAvailableInCombinedStats = function (catKey, diffKey, subcatKey) {
      const { combinedCategory, deprecatedLanguages } = $scope.questStoryValidityResult;

      if (combinedCategory && deprecatedLanguages) {
        const category = combinedCategory?.[catKey];
        const isAvailableInCombinedStats = !!(subcatKey
          ? category?.subcategory?.[subcatKey]?.[diffKey]?.min > 0
          : category?.difficulty?.[diffKey]?.min > 0);
        /**
         * Lookup for the specific category inside the languages to derive the specific language
         * due to which a challenge added to the assessment now stands deprecated
         */
        const [language] = Object.keys(deprecatedLanguages).filter((langKey) => {
          const { category } = deprecatedLanguages[langKey];
          return !!(subcatKey
            ? category?.[catKey]?.subcategory?.[subcatKey]?.[diffKey]
            : category?.[catKey]?.difficulty?.[diffKey]);
        });

        return {
          isAvailableInCombinedStats,
          language: language ?? '',
        };
      }
      return {
        isAvailableInCombinedStats: false,
        language: '',
      };
    };

    /**
     * Goes through each challenge and checks if its valid by checking the availability count(min) on category
     * */
    $scope.questIsValid = function () {
      if ($scope.assessmentToSave.languages) {
        let invalid = false;
        for (let i = 0; i < $scope.assessmentToSave.questStory.challenges.length; i++) {
          let challenge = $scope.assessmentToSave.questStory.challenges[i];
          if (!challenge.filter.category._id) {
            $scope.forms.editAssessmentForm['category' + i].$dirty = true;
            invalid = true;
          }
        }

        if (invalid) {
          $scope.questStoryValidityResult.isValid = false;
        }

        let challengeAvailability = JSON.parse(JSON.stringify($scope.questStoryValidityResult.category));
        // Mapping for the validation of each challenge.
        let r = $scope.assessmentToSave.questStory.challenges.map(function (c) {
          let result = {
            isValid: false,
            category: false,
            subcategory: false,
            challengesAvailable: false,
            recommendCat: null,
            recommendSub: null,
            recommendDiff: null,
            recommendLang: null,
            isDeprecated: false,
            isNew: c?.$$isNew ?? false,
          };
          if (angular.equals($scope.questStoryValidityResult.category, {})) return result;
          else result.challengesAvailable = true;

          let catKey = c.filter.category._id;
          let subcatKey = c.filter.category._sub;
          let diffKey = c.filter.difficulty;

          const { isAvailableInCombinedStats, language } = $scope.isChallengeAvailableInCombinedStats(
            catKey,
            diffKey,
            subcatKey
          );
          const isSubCategoryExisting = subcatKey ? !!challengeAvailability?.[catKey]?.subcategory?.[subcatKey] : true;
          const isCategoryExisting = !!challengeAvailability?.[catKey];
          const isSubCategoryCompletelyDeprecated = isAvailableInCombinedStats && !isSubCategoryExisting;
          const isCategoryCompletelyDeprecated = isAvailableInCombinedStats && !isCategoryExisting;

          /**
           * If the subcategory or category are missing in the approved stats for the languages, we look them up in the
           * combined stats. If we are able to find them in the combined stats, this will prove the deprecation of the
           * language and we take the path below. If still not, that means this is not a common category among the
           * languages.
           */
          if (isSubCategoryCompletelyDeprecated || isCategoryCompletelyDeprecated) {
            result.recommendLang = language ? resolveLanguageName(language) : language;
            if (subcatKey) {
              result.recommendSub = resolveSubcategoryName(catKey, subcatKey);
            }
            result.recommendDiff = $scope.challengeDifficuties[diffKey];
            result.recommendCat = resolveCategoryName(catKey);
            result.isValid = true;
            result.isDeprecated = true;
          } else {
            // We take this path when we know the content has not faced any kind of deprecation
            //Category
            if (challengeAvailability && catKey && diffKey) {
              if (
                challengeAvailability[catKey] &&
                challengeAvailability[catKey].difficulty[diffKey] &&
                challengeAvailability[catKey].difficulty[diffKey].min > 0
              ) {
                result.category = true;
                --challengeAvailability[catKey].difficulty[diffKey].min;
              } else {
                if (!challengeAvailability[catKey] || !challengeAvailability[catKey].difficulty[diffKey]) {
                  //Finds available category and difficulty to find recommendedLang to remove
                  let catKeyList = Object.keys(challengeAvailability);
                  let availableCatKey;
                  let availableDiffKey;
                  if (catKeyList.indexOf(catKey) > 0) availableCatKey = catKey;
                  else availableCatKey = catKeyList[0];

                  let diffKeyList = Object.keys(challengeAvailability[availableCatKey].difficulty);

                  if (diffKeyList.indexOf(diffKey) > 0) availableDiffKey = diffKey;
                  else availableDiffKey = diffKeyList[0];

                  result.recommendLang = resolveLanguageName(
                    challengeAvailability[availableCatKey].difficulty[availableDiffKey].minLang
                  );
                } else {
                  result.recommendLang = resolveLanguageName(challengeAvailability[catKey].difficulty[diffKey].minLang);
                }
                result.recommendCat = resolveCategoryName(catKey);
                result.recommendDiff = $scope.challengeDifficuties[diffKey];
                result.category = false;
              }
            }

            //Subcategory
            if (
              challengeAvailability &&
              subcatKey &&
              diffKey &&
              result.category &&
              challengeAvailability[catKey].subcategory
            ) {
              if (
                challengeAvailability[catKey] &&
                challengeAvailability[catKey].subcategory[subcatKey] &&
                challengeAvailability[catKey].subcategory[subcatKey][diffKey] &&
                challengeAvailability[catKey].subcategory[subcatKey][diffKey].min > 0
              ) {
                result.subcategory = true;
                --challengeAvailability[catKey].subcategory[subcatKey][diffKey].min;
                if (result.category) result.recommendSub = resolveSubcategoryName(catKey, subcatKey);
              } else {
                if (!challengeAvailability[catKey] || !challengeAvailability[catKey].difficulty[diffKey]) {
                  //Finds available category and difficulty to find recommendedLang to remove
                  let catKeyList = Object.keys(challengeAvailability);
                  let availableCatKey;
                  let availableDiffKey;
                  if (catKeyList.indexOf(catKey) > 0) availableCatKey = catKey;
                  else availableCatKey = catKeyList[0];

                  let diffKeyList = Object.keys(challengeAvailability[availableCatKey].difficulty);
                  if (diffKeyList.indexOf(diffKey) > 0) availableDiffKey = diffKey;
                  else availableDiffKey = diffKeyList[0];
                  result.recommendLang = resolveLanguageName(
                    challengeAvailability[availableCatKey].difficulty[availableDiffKey].minLang
                  );
                } else {
                  result.recommendLang = resolveLanguageName(challengeAvailability[catKey].difficulty[diffKey].minLang);
                }
                result.recommendSub = resolveSubcategoryName(catKey, subcatKey);
                result.recommendDiff = $scope.challengeDifficuties[diffKey];
                result.subcategory = false;
              }
            }
            result.isValid = result.category && (c.filter.category._sub ? result.subcategory : true);
          }

          if (!result.isValid && catKey && diffKey) {
            addInvalidChallengeSelected(catKey, diffKey, subcatKey);
          }
          return result;
        });
        $scope.questStoryValidityResult.challenges = r;
        $scope.questStoryValidityResult.isValid = true;
        r.map(function (c) {
          if (!c.isValid) {
            $scope.questStoryValidityResult.isValid = false;
          }
        });

        // If highlight flag and challenges is invalid scroll to invalid challenge
        if ($scope.highlightInvalid && !$scope.questStoryValidityResult.isValid) {
          $scope.scrollToInvalidChallenge();
        } else {
          $scope.highlightInvalid = false;
        }
      }
    };
    /**
     * Gets challenge availability and populates difficultiesList for each challenges
     * if there are existing challenges saved
     * */
    $scope.triggerAddLanguageWalkthrough = _.debounce(
      function () {
        dispatchEvent('assessment-create:add-language');
      },
      200,
      { leading: true, trailing: false }
    );

    $scope.languagesIsValid = function () {
      if ($scope.assessmentToSave.languages) {
        let languages = [];
        $scope.assessmentToSave.languages.forEach(function (lang) {
          languages.push(_.pick(lang, ['_id', '_framework']));
        });
        trackedDataForLog.languages = languages;
        if ($scope.assessmentToSave.languages.length > 0) {
          const allowDeprecated = $state.current.name === 'assessments.list.add' ? false : true;
          /**
           * We need to capture allow deprecated only as a backward compatibility mechanism. If a user is adding an
           * assessment for the first time, they need not know about deprecated content. This is only to be used
           * while the user is editing content.
           */
          AssessmentsApiService.getLanguagesStats(languages, allowDeprecated).then(function (result) {
            if (angular.equals(result.category, {})) {
              $scope.questStoryValidityResult = result;
              $scope.forms.editAssessmentForm.languages.$setValidity('noChallenges', false);
            } else {
              $scope.forms.editAssessmentForm.languages.$setValidity('noChallenges', true);
              $scope.questStoryValidityResult = result;
              $scope.questStoryValidityResult['challenges'] = [{ isValid: false }];
              if ($scope.assessmentToSave.questStory.challenges) {
                $scope.assessmentToSave.questStory.challenges.map(function (c) {
                  if (c.filter.category._sub) $scope.onSubcategoryChange(c);
                  else $scope.onCategoryChange(c);
                });
              }
              $scope.filterAssessmentTemplates();
            }
            $scope.triggerAddLanguageWalkthrough();
          });
        } else {
          $scope.assessmentTemplates = [];
        }
      }
    };

    // check if category/subcategory/difficulty combination exceeds available challenges
    $scope.challengeIndexCheck = function (challenge, index) {
      if (!challenge.filter.category._id) return false;
      else {
        if ($scope.questStoryValidityResult.challenges && $scope.questStoryValidityResult.challenges[index])
          return $scope.questStoryValidityResult.challenges[index].isValid;
        else {
          $scope.questIsValid();
          $scope.challengeIndexCheck(challenge, index);
        }
      }
    };

    /**
     * If the language has changed, do the following:
     *
     * 1. Filter out languages that doesn't match the categoryType of the first language
     * 2. Delete all challenges if language count reaches 0
     * 3. Check if the quests is valid if more languages are added
     */
    $scope.$watchCollection('assessmentToSave.languages', function (languages, oldLanguages) {
      let categoryType;
      if (languages && languages[0]) {
        categoryType = $scope.metadata.languages[languages[0]._id].framework[languages[0]._framework].categoryType;
        $scope.categoryType = categoryType;
      }
      if (languages) {
        let newList = [];
        $scope.languageFrameworkTypesAvailable.map(function (l) {
          if (l.categoryType === categoryType) {
            let exists = $scope.assessmentToSave.languages.some(function (language) {
              return l._id === language._id && l._framework === language._framework;
            });
            if (!exists) newList.push(_.merge({ name: LanguageUtils.languageName(l) }, l));
          }
        });
        $scope.languageFrameworkTypes = newList;
      }
      if (languages && languages.length === 0) {
        $scope.languageFrameworkTypes = JSON.parse(JSON.stringify($scope.languageFrameworkTypesAvailable));
        $scope.forms.assessmentTemplate = null;
        if ($scope.assessmentToSave.questStory.challenges) {
          $scope.assessmentToSave.questStory.challenges = [];
        }
      }

      if (languages && oldLanguages && languages.length > oldLanguages.length) {
        if ($scope.assessmentToSave.questStory.challenges && $scope.assessmentToSave.questStory.challenges.length) {
          $scope.questIsValid();
        }
      }
    });
    /**
     * Populates categories and subcategories available for the selected languages
     * */
    $scope.$watchCollection('questStoryValidityResult', function (languages, _oldlanguages) {
      let categoryType;
      if (languages) {
        let language = $scope.assessmentToSave.languages[0];
        categoryType = $scope.metadata.languages[language._id].framework[language._framework].categoryType;
        $scope.categoryType = categoryType;
        $scope.customCategories = $scope.metadata.categories[categoryType];

        $scope.categories = Object.keys($scope.questStoryValidityResult.category)
          .sort()
          .map(function (c) {
            return { categoryId: c, categoryName: $scope.metadata.categories[categoryType][c].name };
          })
          .sort(categoryCompare);

        $scope.subcategories = {};
        Object.keys($scope.questStoryValidityResult.category)
          .sort()
          .map(function (c) {
            if ($scope.questStoryValidityResult.category[c].subcategory) {
              let subcategoryArr = Object.keys($scope.questStoryValidityResult.category[c].subcategory)
                .sort()
                .map(function (s) {
                  return {
                    subcategoryId: s,
                    subcategoryName: $scope.metadata.categories[categoryType][c].subitem[s].name,
                  };
                });
              $scope.subcategories[c] = subcategoryArr.sort(subcategoryCompare);
            } else {
              $scope.subcategories[c] = [];
            }
          });
      }
      // $scope.addChallengeFilter();
    });

    $scope.onLanguagesChange = function () {
      $scope.languagesIsValid();
    };

    $scope.onCategoryChange = function (challenge, _index) {
      if ('undefined' === typeof challenge.filter.category._id) {
        challenge.filter.category._id = null;
        challenge.filter.category._sub = null;
        challenge.filter.difficulty = null;
      }

      if (challenge.filter.category._id) {
        let availableDifficulties = [];

        if (challenge.filter.category._id) {
          // Populates list of difficulties, then reduce to select default value.
          if ($scope.questStoryValidityResult.category[challenge.filter.category._id])
            Object.keys($scope.challengeDifficuties).map(function (d) {
              Object.keys($scope.questStoryValidityResult.category[challenge.filter.category._id].difficulty).map(
                function (x) {
                  if (d === x) availableDifficulties.push({ id: d, name: $scope.challengeDifficuties[d] });
                }
              );
            });
          $scope.populateDifficulty(challenge, availableDifficulties);
        }
        challenge.filter.category._sub = null;
        dispatchEvent('assessment-create:challenge-category-change');
      }
      $scope.questIsValid();
    };

    $scope.onSubcategoryChange = function (challenge, index) {
      if ('undefined' === typeof challenge.filter.category._sub) {
        challenge.filter.category._sub = null;
        challenge.filter.difficulty = null;
      } else {
        let availableDifficulties = [];
        if (
          challenge.filter.category._sub &&
          $scope.questStoryValidityResult.category[challenge.filter.category._id]?.subcategory
        ) {
          // Populates list of difficulties, then reduce to select default value.
          if (
            $scope.questStoryValidityResult.category[challenge.filter.category._id] &&
            $scope.questStoryValidityResult.category[challenge.filter.category._id].subcategory[
              challenge.filter.category._sub
            ]
          )
            Object.keys($scope.challengeDifficuties).map(function (d) {
              Object.keys(
                $scope.questStoryValidityResult.category[challenge.filter.category._id].subcategory[
                  challenge.filter.category._sub
                ]
              ).map(function (x) {
                if (d === x) availableDifficulties.push({ id: d, name: $scope.challengeDifficuties[d] });
              });
            });
          $scope.populateDifficulty(challenge, availableDifficulties);
          $scope.questIsValid();
        } else {
          // For empty select
          $scope.onCategoryChange(challenge, index);
        }
      }
    };

    $scope.$watch('accordion.open', function (isOpen) {
      if (isOpen) {
        dispatchEvent('assessment-create:open-accordion');
        setAnalyticsForElements();
      }
    });

    $scope.populateDifficulty = function (challenge, availableDifficulties) {
      if (challenge.difficultyList) challenge.difficultyList = [];

      challenge.difficultyList = availableDifficulties;

      //The auto-population is being reverted as some issues have been reported with autopopulation
      /*if(challenge.difficultyList.length > 0 && $state.current.name == "assessments.list.add"){
          challenge.filter.difficulty = challenge.difficultyList[0].id; //We are replicating the work of the dropdown here and the first available difficulty will be selected by default
        }*/
    };

    $scope.onDifficultyChange = function (challenge) {
      if (challenge.filter.category._id) {
        dispatchEvent('assessment-create:challenge-difficulty-change');
        $scope.questIsValid();
      }
    };

    $scope.filterAssessmentTemplates = function () {
      let result = [];
      if ($scope.assessmentTemplatesList)
        $scope.assessmentTemplatesList.forEach(function (template) {
          if (template.status !== 'disabled' && template.status !== 'closed') {
            let matchingLanguages = false;
            if ($scope.assessmentToSave.languages)
              matchingLanguages = $scope.assessmentToSave.languages.every(function (language) {
                if (template.languages) {
                  return template.languages.some(function (templateLanguage) {
                    return templateLanguage._id === language._id && templateLanguage._framework === language._framework;
                  });
                } else return false;
              });
            if (matchingLanguages) {
              result.push(template);
            }
          }
        });
      $scope.assessmentTemplates = result;
    };

    $scope.$watch('forms.assessmentTemplate', function (at) {
      if ($state.current.name == 'assessments.list.add') {
        if (at) {
          trackedDataForLog.templateFlag = true;
          trackedDataForLog.template = at._id;
          let assessmentTemplate = JSON.parse(JSON.stringify(at));
          // $scope.assessmentToSave.name = assessmentTemplate.name;
          // $scope.assessmentToSave.description = assessmentTemplate.description;
          // $scope.assessmentToSave.startDate = assessmentTemplate.startDate;
          // $scope.assessmentToSave.endDate = assessmentTemplate.endDate;
          // $scope.assessmentToSave.sendInvitesOn = assessmentTemplate.sendInvitesOn;
          // $scope.assessmentToSave.timeLimit = assessmentTemplate.timeLimit;
          $scope.assessmentToSave.assessmentTemplate = assessmentTemplate.name;
          $scope.assessmentToSave.questStory = assessmentTemplate.questStory;
          assessmentTemplate.questStory.challenges.map(function (c) {
            if (c.filter.category._sub) $scope.onSubcategoryChange(c);
            else $scope.onCategoryChange(c);
          });
          trackedDataForLog.templateFlag = false;
        } else {
          // PORTAL-1449 - only update challenges
          //$scope.assessmentToSave = JSON.parse(JSON.stringify(defaultEmptyAssessment));
          $scope.assessmentToSave.questStory.challenges = [];
          trackedDataForLog.template = null;
        }

        if ($scope.forms.editAssessmentForm && $scope.forms.editAssessmentForm.languages) {
          $scope.forms.editAssessmentForm.languages.$dirty = true;
        }
      }
    });

    $scope.timePickerOptions = {
      hourStep: 1,
      minuteStep: 1,
    };

    $scope.startFinishTimeCheck = function () {
      // reset form
      $scope.forms.editAssessmentForm.sendInvitesOn.$setValidity('finishBeforeStart', true);
      $scope.forms.editAssessmentForm.startDate.$setValidity('finishBeforeStart', true);
      $scope.forms.editAssessmentForm.endDate.$setValidity('finishBeforeStart', true);

      // if the time fields are empty, skip the time checks
      if (
        !$scope.assessmentToSave.startDate &&
        !$scope.assessmentToSave.endDate &&
        !$scope.assessmentToSave.sendInvitesOn
      ) {
        $scope.forms.editAssessmentForm.sendInvitesOn.$dirty = true;
        $scope.forms.editAssessmentForm.startDate.$dirty = true;
        $scope.forms.editAssessmentForm.endDate.$dirty = true;
        return;
      }

      if (
        $scope.assessmentToSave.endDate &&
        $scope.assessmentToSave.sendInvitesOn &&
        $scope.assessmentToSave.endDate <= $scope.assessmentToSave.sendInvitesOn
      ) {
        $scope.forms.editAssessmentForm.sendInvitesOn.$setValidity('finishBeforeStart', false);
      }

      if (
        $scope.assessmentToSave.startDate &&
        $scope.assessmentToSave.sendInvitesOn &&
        $scope.assessmentToSave.startDate <= $scope.assessmentToSave.sendInvitesOn
      ) {
        $scope.forms.editAssessmentForm.sendInvitesOn.$setValidity('finishBeforeStart', false);
      }

      if (
        $scope.assessmentToSave.startDate &&
        $scope.assessmentToSave.endDate &&
        $scope.assessmentToSave.endDate <= $scope.assessmentToSave.startDate
      ) {
        $scope.forms.editAssessmentForm.startDate.$setValidity('finishBeforeStart', false);
        $scope.forms.editAssessmentForm.endDate.$setValidity('finishBeforeStart', false);
      }

      $scope.forms.editAssessmentForm.sendInvitesOn.$dirty = true;
      $scope.forms.editAssessmentForm.startDate.$dirty = true;
      $scope.forms.editAssessmentForm.endDate.$dirty = true;
    };

    $scope.addChallengeFilter = function (index) {
      dispatchEvent('assessment-create:add-challenge');
      if (
        !($scope.assessmentToSave.languages.length > 0 || $scope.assessmentToSave.categoryType) ||
        ($scope.questStoryValidityResult &&
          !$scope.questStoryValidityResult.isValid &&
          $scope.assessmentToSave.questStory.challenges.length > 0)
      ) {
        $scope.highlightInvalid = true;
        $scope.scrollToInvalidChallenge();
        return;
      }

      index = isFinite(index) ? index : $scope.assessmentToSave.questStory.challenges.length - 1;

      let newidx = index + 1;
      $scope.assessmentToSave.questStory.challenges.splice(newidx, 0, {
        // alternate between L4 or L5
        cbl: ['L4', 'L5'][$scope.assessmentToSave.questStory.challenges.length % 2],
        filter: {
          category: {
            _id: null,
            _sub: null,
          },
          difficulty: null,
        },
        $$isNew: true,
      });
      if ($scope.forms.editAssessmentForm && $scope.forms.editAssessmentForm.languages) {
        $scope.forms.editAssessmentForm.languages.$dirty = true;
      }
      $scope.questStoryValidityResult.isValid = false;
    };

    $scope.removeChallengeFilter = function (index) {
      dispatchEvent('assessment-create:remove-challenge');
      $scope.assessmentToSave.questStory.challenges.splice(index, 1);
      $scope.questIsValid();
    };

    $scope.copyChallengeFilter = function (index) {
      dispatchEvent('assessment-create:copy-challenge');
      let challengeFilter = JSON.parse(JSON.stringify($scope.assessmentToSave.questStory.challenges[index]));
      let newidx = index + 1;
      delete challengeFilter.$$hashKey;
      $scope.assessmentToSave.questStory.challenges.splice(newidx, 0, challengeFilter);
      $scope.questIsValid();

      $timeout(function () {
        $($('.assessment-challenge-number')[newidx]).trigger('focus');
      }, 500);
    };

    $scope.moveUpChallengeFilter = function (index) {
      let challengeFilter = $scope.assessmentToSave.questStory.challenges[index];
      let newidx = index - 1;
      $scope.assessmentToSave.questStory.challenges.splice(index, 1);
      $scope.assessmentToSave.questStory.challenges.splice(newidx, 0, challengeFilter);

      $scope.questIsValid();

      $timeout(function () {
        $($('.assessment-challenge-number')[newidx]).trigger('focus');
      }, 50);
    };

    $scope.moveDownChallengeFilter = function (index) {
      let challengeFilter = $scope.assessmentToSave.questStory.challenges[index];
      let newidx = index + 1;
      $scope.assessmentToSave.questStory.challenges.splice(index, 1);
      $scope.assessmentToSave.questStory.challenges.splice(newidx, 0, challengeFilter);

      $scope.questIsValid();

      $timeout(function () {
        $($('.assessment-challenge-number')[newidx]).trigger('focus');
      }, 50);
    };

    $scope.shouldFieldsBeUnlockable = function () {
      if ($scope.levelGroupingFeatureEnabled && $scope.assessmentToSave.status === 'disabled') {
        return false;
      }

      if ($scope.editing && $scope.isEditingFieldsLocked && $scope.assessmentToSave.status !== 'disabled') {
        return true;
      }

      return false;
    };

    $scope.saveAssessment = function () {
      if ($scope.assessmentToSave?.questStory?.languages?.length) {
        $scope.assessmentToSave.questStory.languages = $scope.assessmentToSave.questStory.languages.filter(
          (language) => {
            return $scope.assessmentToSave.languages.some(
              (lang) => lang._id === language.language._id && lang._framework === language.language._framework
            );
          }
        );
      }

      if ($scope.levelGroupingFeatureEnabled) {
        if ($scope.assessmentToSave.levelGrouping) {
          delete $scope.assessmentToSave.levelGrouping.linkedItems;
        }
        if ($scope.assessmentToSave.status === 'disabled') {
          return AssessmentsApiService.updateSupersededAssessment($scope.assessmentToSave)
            .then(function (data) {
              $state.go('assessments.view', { assessmentId: data._id }, { reload: 1 });
            })
            .catch(function (response) {
              $translate(['ERROR_SAVING_ASSESSMENT']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_SAVING_ASSESSMENT, response);
              });
            })
            .finally(function () {
              $rootScope.enableButton('saveAssessment');
            });
        }
      } else {
        delete $scope.assessmentToSave.levelGrouping;
      }

      $scope.startFinishTimeCheck();

      // triggers highlight invalid flag if challenge list is not populated or list of challenges is invalid
      if (
        !$scope.assessmentToSave.questStory.challenges.length ||
        ($scope.questStoryValidityResult && !$scope.questStoryValidityResult.isValid)
      ) {
        if ($scope.shouldValidateChallenges) {
          $scope.highlightInvalid = true;
          $scope.scrollToInvalidChallenge();
          return;
        }
      }
      if ($scope.forms.editAssessmentForm.$invalid) {
        $scope.forms.editAssessmentForm.name.$dirty = true;
        $scope.forms.editAssessmentForm.description.$dirty = true;
        $scope.forms.editAssessmentForm.timeLimit.$dirty = true;
        $scope.forms.editAssessmentForm.successRatio.$dirty = true;
        $scope.forms.editAssessmentForm.languages.$dirty = true;
        $scope.forms.editAssessmentForm.startTimezone.$dirty = true;
        $scope.forms.editAssessmentForm.startDate.$dirty = true;
        $scope.forms.editAssessmentForm.endDate.$dirty = true;
        $scope.forms.editAssessmentForm.sendInvitesOn.$dirty = true;
        return;
      }

      if (!$scope.assessmentToSave.maxRetries) {
        $scope.assessmentToSave.maxRetries = null;
      }

      if (!$scope.assessmentToSave.retryWaitingHours) {
        $scope.assessmentToSave.retryWaitingHours = null;
      }

      if ($scope.assessmentToSave.startDate) {
        $scope.assessmentToSave.startDate = convertToUTC(
          new Date($scope.assessmentToSave.startDate),
          moment.tz.guess()
        );
        $scope.assessmentToSave.startDate = convertToTimezone(
          $scope.assessmentToSave.startDate,
          $scope.assessmentToSave.timezone
        );
        $scope.assessmentToSave.startDate.setSeconds(0); // PLAT-10703
        $scope.assessmentToSave.startDate = $scope.assessmentToSave.startDate.valueOf();
      }

      if ($scope.assessmentToSave.endDate) {
        $scope.assessmentToSave.endDate = convertToUTC(new Date($scope.assessmentToSave.endDate), moment.tz.guess());
        $scope.assessmentToSave.endDate = convertToTimezone(
          $scope.assessmentToSave.endDate,
          $scope.assessmentToSave.timezone
        );
        $scope.assessmentToSave.endDate.setSeconds(0); // PLAT-10703
        $scope.assessmentToSave.endDate = $scope.assessmentToSave.endDate.valueOf();
      }

      if ($scope.assessmentToSave.sendInvitesOn) {
        $scope.assessmentToSave.sendInvitesOn = convertToUTC(
          new Date($scope.assessmentToSave.sendInvitesOn),
          moment.tz.guess()
        );
        $scope.assessmentToSave.sendInvitesOn = convertToTimezone(
          $scope.assessmentToSave.sendInvitesOn,
          $scope.assessmentToSave.timezone
        );
        $scope.assessmentToSave.sendInvitesOn.setSeconds(0); // PLAT-10703
        $scope.assessmentToSave.sendInvitesOn = $scope.assessmentToSave.sendInvitesOn.valueOf();
      }

      // PORTAL-1170 - fix start, end dates and invite dates
      // $scope.assessmentToSave.startDate = $scope.assessmentToSave.startDate && moment($scope.assessmentToSave.startDate).startOf('day').valueOf();
      // $scope.assessmentToSave.endDate = $scope.assessmentToSave.endDate && moment($scope.assessmentToSave.endDate).endOf('day').valueOf();
      // $scope.assessmentToSave.sendInvitesOn = $scope.assessmentToSave.sendInvitesOn && moment($scope.assessmentToSave.sendInvitesOn).startOf('day').valueOf();

      delete $scope.assessmentToSave.displayTimeLimit;
      delete $scope.assessmentToSave.difficulties;
      // backwards compatibility
      if ($scope.assessmentToSave.languages) {
        $scope.assessmentToSave.languages.map(function (l) {
          delete l.categoryType;
        });
      }
      // Delete difficulty list
      if ($scope.assessmentToSave.questStory.challenges) {
        $scope.assessmentToSave.questStory.challenges.map(function (c) {
          delete c.difficultyList;
        });
      }

      $scope.assessmentToSave.successRatio = $scope.assessmentToSave.successRatio || null;

      let saveFunc;
      if ($state.current.name == 'assessments.view.edit') {
        saveFunc = AssessmentsApiService.updateAssessment;
      } else {
        saveFunc = AssessmentsApiService.createAssessment;
      }

      // Remove 'name' property from $scope.assessmentToSave.languages
      $scope.assessmentToSave.languages = $scope.assessmentToSave.languages.map(function (language) {
        return _.omit(language, ['name']);
      });

      $rootScope.disableButton('saveAssessment');
      saveFunc($scope.assessmentToSave)
        .then(function (data) {
          $state.go('assessments.view', { assessmentId: data._id }, { reload: 1 });
        })
        .catch(function (response) {
          $translate(['ERROR_SAVING_ASSESSMENT']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_SAVING_ASSESSMENT, response);
          });
        })
        .finally(function () {
          $rootScope.enableButton('saveAssessment');
        });
    };

    $scope.$watch('assessmentToSave.selfAssess', function (newVal, _oldVal) {
      if (newVal) {
        $scope.assessmentToSave.sendInvitesOn = null;
      }
    });

    // init timezones
    $scope.timezoneList = moment.tz.names();

    if ($state.current.name == 'assessments.view.edit') {
      $scope.getAssessmentPromise
        .then(function (_data) {
          // from AssessmentsViewController
          $scope.editing = true;
          $scope.isEditingFieldsLocked = true;
          $scope.shouldValidateChallenges = false;
          $scope.assessmentToSave = JSON.parse(JSON.stringify($scope.assessment));
          $scope.assessmentToSave._certificateTemplate = $scope.assessmentToSave._certificateTemplate || '';
          $scope.assessmentToSave.fixedCBL = $scope.assessmentToSave.fixedCBL || null;
          $scope.assessmentToSave.prioritizeChallengeDifficulty =
            $scope.assessmentToSave.prioritizeChallengeDifficulty || false;

          if (!$scope.assessmentToSave.timezone) {
            $scope.assessmentToSave.timezone = moment.tz.guess();
          }

          if ($scope.assessmentToSave.startDate) {
            $scope.assessmentToSave.startDate = convertToUTC(
              new Date($scope.assessmentToSave.startDate),
              $scope.assessmentToSave.timezone
            );
            $scope.assessmentToSave.startDate = convertToTimezone($scope.assessmentToSave.startDate, moment.tz.guess());
          }

          if ($scope.assessmentToSave.endDate) {
            $scope.assessmentToSave.endDate = convertToUTC(
              new Date($scope.assessmentToSave.endDate),
              $scope.assessmentToSave.timezone
            );
            $scope.assessmentToSave.endDate = convertToTimezone($scope.assessmentToSave.endDate, moment.tz.guess());
          }

          if ($scope.assessmentToSave.sendInvitesOn) {
            $scope.assessmentToSave.sendInvitesOn = convertToUTC(
              new Date($scope.assessmentToSave.sendInvitesOn),
              $scope.assessmentToSave.timezone
            );
            $scope.assessmentToSave.sendInvitesOn = convertToTimezone(
              $scope.assessmentToSave.sendInvitesOn,
              moment.tz.guess()
            );
          }

          if (!$scope.assessmentToSave.timezone) {
            $scope.assessmentToSave.timezone = moment.tz.guess();
          }

          $scope.languagesIsValid();
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_ASSESSMENT']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_ASSESSMENT, response);
          });
        });
    } else {
      $scope.assessmentToSave = JSON.parse(JSON.stringify(defaultEmptyAssessment));
      $scope.assessmentToSave.timezone = moment.tz.guess();
      AssessmentsApiService.getAssessmentTemplates('', { page: 1, size: 50 }).then(function (data) {
        $scope.processAssessmentList(data);
        $scope.assessmentTemplates = data.data;
        $scope.assessmentTemplatesList = data.data;
      });
    }

    $scope.disableSchedulingOptions = () => {
      return $scope.assessmentToSave?.lmsIntegrated || $scope.assessmentToSave?.status === 'disabled';
    };

    $scope.disableInvitationOptions = () => {
      return (
        $scope.assessmentToSave?.selfAssess ||
        $scope.assessmentToSave?.status === 'disabled' ||
        $scope.assessmentToSave?.lmsIntegrated
      );
    };

    $scope.disableSelfAssessOption = () => {
      return $scope.assessmentToSave?.status === 'disabled' || $scope.assessmentToSave?.lmsIntegrated;
    };

    $scope.$watch('assessmentToSave.lmsIntegrated', (newVal, oldVal) => {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal && storedAdvancedOptions !== null) {
        $scope.assessmentToSave = {
          ...$scope.assessmentToSave,
          ...storedAdvancedOptions,
          ...{ lmsIntegrated: false },
        };
      }

      if (newVal) {
        storedAdvancedOptions = {
          startDate: $scope.assessmentToSave.startDate,
          endDate: $scope.assessmentToSave.endDate,
          sendInvitesOn: $scope.assessmentToSave.sendInvitesOn,
          timezone: $scope.assessmentToSave.timezone,
          selfAssess: $scope.assessmentToSave.selfAssess,
          retriesAllowed: $scope.assessmentToSave.retriesAllowed,
          maxRetries: $scope.assessmentToSave.maxRetries,
          retryWaitingHours: $scope.assessmentToSave.retryWaitingHours,
        };

        const lmsOverrideOptions = {
          retriesAllowed: true,
          selfAssess: true,
        };

        $scope.assessmentToSave = {
          ...$scope.assessmentToSave,
          ...defaultAdvancedOptions,
          ...lmsOverrideOptions,
        };
      }
    });

    $scope.init();
  },
]);

app.controller('LockedFieldsModal', [
  '$uibModalInstance',
  '$scope',
  function ($uibModalInstance, $scope) {
    $scope.unlockFields = function () {
      $uibModalInstance.close(true);
    };

    $scope.close = function () {
      $uibModalInstance.close(false);
    };
  },
]);

app.controller('LevelGroupModalController', [
  '$rootScope',
  '$scope',
  '$window',
  '$uibModalInstance',
  '$location',
  function ($rootScope, $scope, $window, $uibModalInstance, $location) {
    $scope.newAppUrl = '/web/index.html';

    $window.addEventListener('message', receiveMessage);

    function receiveMessage(event) {
      if (event.isTrusted && event.origin === location.origin) {
        var messageType = event.data.type;
        var messageData = event.data.data;

        if (messageType === 'closeModal') {
          $uibModalInstance.close(messageData);
        }

        if (messageType === 'navigateToUrl' && messageData?.includes && messageData.includes('assessments/multi')) {
          $location.path(messageData);
          $rootScope.$digest(); // Soft re-render the app
        }

        if (messageType === 'requestGroup') {
          $window.parent.postMessage(
            {
              type: 'requestGroupResponse',
              data: $rootScope.prefilledLevelGroup,
            },
            event.origin
          );
        }
      } else {
        console.warn('iFrame message not sent from trusted origin', event.origin);
      }
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('message', receiveMessage);
    });
  },
]);

app
  .controller('ManageRetryLimitModal', [
    '$uibModalInstance',
    '$scope',
    '$translate',
    function ($uibModalInstance, $scope, $translate) {
      $scope.oldRetryLimit = $scope.assessment.maxRetries;
      $scope.newRetryLimit = $scope.oldRetryLimit + 1;

      $scope.updateRetryLimit = function () {
        if (!$scope.retryLimitForm.$valid) {
          return;
        }
        $scope.savingNewLimit = true;

        $scope.AssessmentsApiService.updateAssessmentRetries($scope.assessment._id, {
          maxRetries: $scope.newRetryLimit,
        })
          .then(function (data) {
            $uibModalInstance.close(data);
          })
          .catch(function (response) {
            $translate(['ERROR_SAVING_ASSESSMENT']).then(function (translations) {
              $scope.ErrorHandler.addHttpError(translations.ERROR_SAVING_ASSESSMENT, response);
            });
          });
      };

      $scope.close = function () {
        $uibModalInstance.close();
      };
    },
  ])
  .directive('validateRetryLimit', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, _element, _attr, ctrl) {
        function newRetryLimitValidator(newRetryLimit) {
          if (newRetryLimit <= $scope.oldRetryLimit && newRetryLimit > 0) {
            ctrl.$setValidity('tooLowLimit', false);
          } else {
            ctrl.$setValidity('tooLowLimit', true);
          }
          return newRetryLimit;
        }

        ctrl.$parsers.push(newRetryLimitValidator);
      },
    };
  });
