import angular from 'angular';
import MODULE from './module';
import swal from '../../../vendor/js/sweet-alert.min';

angular.module(MODULE).service('$swal', [
  '$templateRequest',
  '$compile',
  '$timeout',
  '$rootScope',
  '$log',
  '$q',
  function ($templateRequest, $compile, $timeout, $rootScope, $log, $q) {
    function handler(config) {
      // all of swal() config attributes +
      // ! config.templateUrl
      // ! config.scope

      var happening = $q.defer(); // response

      if (config.templateUrl) {
        (config.text = "<div id='ng-swal_content'></div>"), (config.html = true);
        $templateRequest(config.templateUrl).then(function (html) {
          $timeout(function () {
            var template = angular.element('<div>' + html + '</div>');
            var container = angular.element('#ng-swal_content');
            container.html('');
            container.append(template);

            var scope =
              (config.scope && config.scope.$new && config.scope.$new()) || _.merge($rootScope.$new(), config.scope);

            scope.resolve = accept;
            scope.reject = reject;
            $compile(template)(scope);
          }, 1);
        });
      }

      if (config.buttons) {
        $timeout(function () {
          var buttonContainer = angular.element('.sa-button-container');
          buttonContainer.attr('keyboard-navigation', 'button');
          var origHtml = buttonContainer.html();
          buttonContainer.html('');
          _(config.buttons)
            .values()
            .forEach(function (buttonCfg, idx) {
              var btnDom = angular.element('<button>');
              btnDom.text(buttonCfg.label || buttonCfg.text);
              btnDom.addClass(buttonCfg.class);
              btnDom.on('click', function () {
                accept(buttonCfg.value);
                buttonContainer.html('');
              });

              if (!idx) btnDom.attr('autofocus-if', 'true');
              buttonContainer.append(btnDom);
            });

          var scope =
            (config.scope && config.scope.$new && config.scope.$new()) || _.merge($rootScope.$new(), config.scope);
          $compile(buttonContainer)(scope);

          happening.promise.finally(function () {
            $timeout(function restore() {
              buttonContainer.html(origHtml);
            }, 500);
          });
        }, 1);
      }

      $timeout(function () {
        try {
          const sweetAlertContainer = document.querySelector('.showSweetAlert.sweet-alert.visible');
          if (sweetAlertContainer) {
            sweetAlertContainer.setAttribute('role', 'dialog');
            sweetAlertContainer.setAttribute('aria-label', config.title);
          }

          const icons = document.querySelectorAll('.showSweetAlert.sweet-alert.visible .sa-icon');
          icons.forEach((icon) => {
            icon.setAttribute('role', 'presentation');
          });

          const buttonContainer = document.querySelector('.sa-button-container');
          if (buttonContainer) {
            buttonContainer.id = config.a11y ? 'swal-button-container' : '';
          }

          const buttons = document.querySelectorAll('.sa-button-container button');
          buttons.forEach((button) => {
            button.setAttribute('tabindex', '0');
            button.classList.add('ds-focus');
          });
        } catch (error) {
          console.log(error);
        }
      }, 1100);

      var activeElement = document.activeElement;

      $log.debug('SWAL CONFIG', config);
      swal(config, function (confirmed) {
        try {
          cleanup();
        } catch (error) {
          console.log(error);
        }
        happening.resolve(confirmed);
      });

      if (config.keyResolution) {
        var element = $('.sweet-alert');
        element.trigger('focus');
        element.on('keydown', keyResolver);
      }

      function keyResolver(event) {
        event.stopPropagation();
        event.preventDefault();

        if (event.key === 'Enter') accept(true);
        else if (~['Escape', 'Esc'].indexOf(event.key)) reject(false);

        if (event.key === 'Tab') return false;
      }

      function accept(response) {
        cleanup();
        setTimeout(function () {
          happening.resolve(response);
        }, 500);

        return response;
      }

      function reject(_err) {
        cleanup();
        setTimeout(function (response) {
          happening.reject(response);
        }, 500);
      }

      function cleanup() {
        if (config.keyResolution) $('.sweet-alert').off('keydown', keyResolver);

        $(activeElement).trigger('focus');
        swal.close();
      }

      return happening.promise;
    }

    handler.close = swal.close;

    return handler;
  },
]);
