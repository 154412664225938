import angular from 'angular';
import MODULE from './module';
import templateUrl from './partial-doughnut.html';

(function (moduleName) {
  /**
   * The PartialDougnhutChartDirectiveCtrl is the controller associated with the partial-dougnhut directive.
   * It is not meant for direct usage by user-mode development.
   */

  function PartialDoughnutChartDirectiveCtrl($scope, $window) {
    _.assign($scope, {
      options: {
        cutoutPercentage: $scope.cutout || 80,
        rotation: ($scope.rotation / 360) * 2 * Math.PI || -0.5 * Math.PI,
        circumference: (($scope.circumference || 100) / 100) * 2 * Math.PI,
        responsive: true,
        maintainAspectRatio: false,
      },
      colours: [],
      labels: [],
      data: [],
      datasetOverride: {
        borderWidth: [],
      },
    });

    // begin: colour management.
    var i_colour,
      userColours = $scope.userColours;
    if (!userColours) userColours = [];
    if (!Array.isArray(userColours)) userColours = [userColours];
    for (i_colour = 0; i_colour < userColours.length; i_colour++) {
      var colour = userColours[i_colour];
      $scope.colours.push(colour);
      $scope.datasetOverride.borderWidth.push(0);
    }
    // end: colour management
    var label,
      value,
      entries = $scope.entries || [];
    var unfill = 100;
    if (entries.length < 1) {
      entries[$scope.label] = parseInt($scope.value);
    }

    for (label in entries) {
      value = parseInt(entries[label]);
      $scope.labels.push(label);
      $scope.data.push(value);
      unfill -= value;
    }

    var unfilledColour = $scope.unfilledColour || 'rgba(255,255,255,0.05)';
    $scope.colours.push(unfilledColour); // unfilled
    $scope.labels.push(''); // unfilled space
    $scope.data.push(unfill); // unfilled space
    $scope.datasetOverride.borderWidth.push(0); // unfilled space

    // handle re-rendering through ng-if
    $scope.rendered = 1;
    angular.element($window).bind('resize', function () {
      $scope.$apply(function () {
        $scope.rendered++;
      });
    });

    return $scope;
  }

  /**
   * The partial-doughnut directive (element only) is used to display a doughnut chart
   * that supports inner content (initial challenge was to perfectly center that content
   * in a dynamically-sized doughnut chart which seemed to be almost imposible to achieve
   * with pure CSS.
   */
  angular.module(moduleName).directive('partialDoughnut', [
    function () {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          label: '=?label',
          value: '=?value',
          userColours: '=?colours',
          unfilledColour: '=?unfilledColour',
          entries: '=?data',
          width: '=?width',
          height: '=?height',
          cutout: '=?cutout',
          circumference: '=?circumference', // as %
          rotation: '=?rotation', // as degrees
        },
        controller: ['$scope', '$window', PartialDoughnutChartDirectiveCtrl],
        templateUrl,
      };
    },
  ]);
})(MODULE);
