var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { challengePlayerEmbedderAnalytics } from './ChallengePlayerEmbedderAnalytics';
import { getLocalVideoPreferences } from '@securecodewarrior/design-system-react';
import { createVideoIdForChallengeCategory } from '../../utils/metadata/metadata';
import { platformLocaleToBcp47 } from '../../utils/platformLocaleToBcp47';
import { useEffect, useMemo, useRef } from 'react';
import { usePlatformContext } from '../platformContext';
export const challengeplayerBackgroundColor = '#1e2125'; // resulting color of #25292e with a #ffffff33 overlay applied
export const ChallengePlayerEmbedder = (props) => {
    var _a;
    const enableSkip = !!((_a = props.eventHandlers) === null || _a === void 0 ? void 0 : _a.onSkipChallenge);
    const platform = usePlatformContext();
    const playerUrl = useMemo(() => {
        var _a;
        return enhancePlayerUrl(props.baseUrl, (_a = platform.sessionUser) !== null && _a !== void 0 ? _a : undefined, enableSkip);
    }, [props.baseUrl, platform.sessionUser, enableSkip]);
    useInstallWindowMessageListener(props.playmode, props.eventHandlers);
    const style = useMemo(() => (Object.assign(Object.assign({}, props.style), { background: challengeplayerBackgroundColor })), [props.style]);
    return (_jsx("iframe", { src: playerUrl, style: style, title: "Challenge Player", allow: "display-capture" // PLAT-13084 / ff07b1b0
        , id: "challenge-iframe" // required for scw-auto
        , name: "challenge-iframe" // required for function $rootScope.showUsersnap (we do have a fallback so just id attribute works too but it seems like DOM ref prefers name over id)
     }));
};
const enhancePlayerUrl = (url, sessionUser, enableSkip) => {
    var _a, _b, _c, _d;
    const subtitleLang = platformLocaleToBcp47((_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.subtitlesLanguage);
    const lang = ((_d = (_c = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _c === void 0 ? void 0 : _c.profile) === null || _d === void 0 ? void 0 : _d.i18nLanguagePreference) || 'en';
    const videoPreferences = getLocalVideoPreferences();
    if (subtitleLang) {
        url.searchParams.set('subtitle', subtitleLang);
    }
    url.searchParams.set('lang', lang);
    url.searchParams.set('volume', `${videoPreferences.volume}`);
    url.searchParams.set('muted', `${videoPreferences.muted}`);
    url.searchParams.set('playbackrate', `${videoPreferences.playbackRate}`);
    url.searchParams.set('enableSkip', `${enableSkip}`);
    return url.toString();
};
const useInstallWindowMessageListener = (portalArea, eventHandlers) => {
    const _a = usePlatformContext(), { logAnalyticsEvent } = _a, platform = __rest(_a, ["logAnalyticsEvent"]);
    const analytics = useMemo(() => challengePlayerEmbedderAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const videosWatchedEventAlreadyTriggeredIds = useRef(new Set());
    const passedChallenge = useRef(false);
    const { onSkipChallenge, onChallengeComplete, onNonLastStageComplete } = eventHandlers;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        const messageListener = (messageEvent) => {
            var _a;
            if (typeof messageEvent.data === 'object' && typeof messageEvent.data.event === 'string') {
                const data = messageEvent.data;
                const event = messageEvent.data.event;
                // For all messages that are sent from challenge player, see
                // https://github.com/SecureCodeWarrior/challenge-player-frontend/blob/main/src/utils/messagePlatform.ts
                // The events not prefixed by 'cp:' are normally handled in 'onIframeMessage' in angular src (scw-angular/index.js)
                if (event === 'cp:analytics') {
                    if (typeof data.eventProps === 'object' && typeof data.eventName === 'string') {
                        const routeProps = platform.rawAnalyticsService.getRouteProps();
                        const { eventName, eventProps } = data;
                        platform.rawAnalyticsService.logEvent(eventName, Object.assign(Object.assign({ portal_area: portalArea }, routeProps), eventProps));
                    }
                }
                else if (event === 'cp:videoWatched') {
                    if (typeof data.categoryKey === 'string' &&
                        typeof data.subCategoryKey === 'string' &&
                        typeof data.duration === 'number') {
                        const videoId = createVideoIdForChallengeCategory(platform.metadata, data.categoryKey, data.subCategoryKey);
                        if (videoId && !videosWatchedEventAlreadyTriggeredIds.current.has(videoId)) {
                            videosWatchedEventAlreadyTriggeredIds.current.add(videoId);
                            const playmode = `${portalArea}-challenge`;
                            // following msg should be caught in function 'onIframeMessage' in angular src (scw-angular/index.js)
                            (_a = window.top) === null || _a === void 0 ? void 0 : _a.postMessage({ event: 'videoWatched', duration: data.duration, videoId, playmode });
                        }
                    }
                }
                else if (event === 'cp:stageComplete') {
                    onNonLastStageComplete === null || onNonLastStageComplete === void 0 ? void 0 : onNonLastStageComplete();
                }
                else if (event === 'cp:nextChallenge') {
                    onChallengeComplete(passedChallenge.current);
                }
                else if (event === 'cp:skipChallenge') {
                    onSkipChallenge === null || onSkipChallenge === void 0 ? void 0 : onSkipChallenge();
                }
                else if (event === 'cp:onChallengeFinished') {
                    if (!isChallengeStageArray(data.stages)) {
                        console.error('Received invalid data for cp:onChallengeFinished: ' + JSON.stringify(data));
                        return;
                    }
                    if (data.stages.every((x) => x.status === 'COMPLETE')) {
                        analytics.onChallengePassed(portalArea);
                        passedChallenge.current = true;
                        return;
                    }
                    analytics.onChallengeFailed(portalArea);
                    passedChallenge.current = false;
                }
            }
        };
        window.addEventListener('message', messageListener);
        return () => window.removeEventListener('message', messageListener);
    }, [
        platform.rawAnalyticsService,
        platform.metadata,
        portalArea,
        onSkipChallenge,
        onChallengeComplete,
        onNonLastStageComplete,
        analytics,
    ]);
};
const isChallengeStageArray = (obj) => {
    return Array.isArray(obj) && obj.every(isChallengeStage);
};
// prettier-ignore
const isChallengeStage = (obj) => typeof obj === 'object' && obj !== null &&
    'name' in obj && (obj.name === 'identify' || obj.name === 'locate' || obj.name === 'fix') &&
    'status' in obj && (obj.status === 'COMPLETE' || obj.status === 'FAILED');
