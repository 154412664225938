import angular from 'angular';

const MODULE = 'SCWTrainingPointsLeaderboard';
export default MODULE;

const app = angular.module(MODULE, []);

app.controller('TrainingPointsLeaderboardController', [
  '$scope',
  '$log',
  'USER_ROLES',
  'Session',
  'TrainingApiService',
  'TrainingUtils',
  function ($scope, $log, USER_ROLES, Session, TrainingApiService, TrainingUtils) {
    $scope.confidenceCalculator = TrainingUtils.confidenceCalculator;

    $scope.pointsLeaderboard;
    $scope.pagination = [10, 25, 50];

    $scope.paginationSettings = {
      page: 1,
      maxSize: 5,
      itemsPerPage: $scope.pagination[0],
      totalItems: 0,
    };

    //used to calculate the number of tabs in leaderboard
    $scope.totalPages;
    $scope.tabPage = 0;
    $scope.pageRange;

    //Refresh the leaderboard with change of dropdown
    $scope.refreshLeaderboard = function () {
      //($scope.selectedEntity._id, $scope.playerLeaderboardSettings.filter, page, size)
      TrainingApiService.getManagerPlayerPointsLeaderboard(
        $scope.selectedEntity._id,
        null,
        $scope.paginationSettings.page,
        $scope.paginationSettings.itemsPerPage,
        null,
        $scope.displayCurrentUser
      )
        .then(function (res) {
          $scope.pointsLeaderboard = res;
          $scope.paginationSettings.totalItems = res.totalUsers;
          $scope.paginationSettings.page = res.page;
          $scope.displayCurrentUser = false;
        })
        .catch(function (res) {
          $log.debug('Error getting learning path points player leaderboard', res);
        });
    };

    $scope.getEntities = function (_entity) {
      return TrainingApiService.getManagerEntities(_entity).then(function (res) {
        $scope.entities = res;
        $scope.selectedEntity = $scope.entities[0];
        return;
      });
    };

    $scope.init = function () {
      //set leaderboard to initially display page with user
      $scope.displayCurrentUser = true;

      if (
        Session.user.roles.indexOf(USER_ROLES.admin) > -1 ||
        Session.user.roles.indexOf(USER_ROLES.companyAdmin) > -1 ||
        Session.user.roles.indexOf(USER_ROLES.manager) > -1
      ) {
        $scope.getEntities().then(function () {
          $scope.refreshLeaderboard();
        });
      } else {
        $scope.selectedEntity = {
          _id: Session.user.properties._tid,
        };
        $scope.refreshLeaderboard();
      }
    };
    $scope.init();

    $scope.changeItemsPerPage = function () {
      $scope.paginationSettings.page = 1;
      if ($scope.selectedEntity) {
        $scope.refreshLeaderboard();
      }
    };
  },
]);
