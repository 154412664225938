import angular from 'angular';
import 'angular-ui-bootstrap';
import templateUrl from './tournament-quest-preview.html';

const app = angular.module('SCWTournamentQuestPreview', ['ui.bootstrap']);
export default app.name;

app.directive('tournamentQuestPreview', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        additionalClasses: '=',
        quest: '=',
        appType: '=', // 'web', 'mobile', 'frontend', 'server', 'desktop', 'database', 'conceptual'
        stages: '=', // challenge stage names
      },
      link: function (scope, _element, _attrs) {
        scope.STAGES = scope.stages;

        return;
      },
    };
  },
]);
