import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { ReplayRounded, TimelineRounded } from '@mui/icons-material';
import { RestartIcon } from './RestartIcon';
import { usePlatformContext } from '../../../../scw-react/platformContext';
import { useEffect, useMemo } from 'react';
import { newCodingLabAvailableAnalytics } from './NewCodingLabAvailable.analytics';
export const NewCodingLabAvailable = (props) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => newCodingLabAvailableAnalytics(logAnalyticsEvent, props.scope, props.labId), [logAnalyticsEvent, props.scope, props.labId]);
    useEffect(() => analytics.onComponentLoaded(), []); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx(Box, { flexGrow: "1", display: "flex", alignItems: "center", justifyContent: "center", pt: 4, children: _jsx(Stack, { sx: {
                width: '900px',
                maxWidth: '900px',
                alignItems: 'center',
                borderRadius: 3,
            }, bgcolor: (theme) => theme.palette.container.fill.card1, border: (theme) => `1px solid ${theme.palette.container.border.default}`, children: _jsxs(Stack, { maxWidth: "582px", p: 10, gap: 7, alignItems: "center", children: [_jsx(RestartIcon, { sx: { maxWidth: '300px', maxHeight: '210px', width: '100%', height: 'auto' } }), _jsx(Typography, { variant: "h1", textAlign: "center", children: t('components.organisms.newCodingLabAvailable.title') }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "subtitle1", maxWidth: "502px", textAlign: "center", component: "p", children: t('components.organisms.newCodingLabAvailable.description') }), _jsxs(Stack, { flexDirection: "row", gap: 3, children: [_jsx(Button, { onClick: () => {
                                    analytics.onLoadOldLab();
                                    props.onClickLoadOldSession();
                                }, variant: "text", startIcon: _jsx(TimelineRounded, {}), disabled: props.disabled, children: t('components.organisms.newCodingLabAvailable.loadOldSession') }), _jsx(Button, { onClick: () => {
                                    analytics.onLoadNewLab();
                                    props.onClickRestartLab();
                                }, variant: "contained", startIcon: _jsx(ReplayRounded, {}), disabled: props.disabled, children: t('components.organisms.newCodingLabAvailable.restartUpdatedLab') })] })] }) }) }));
};
