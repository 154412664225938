import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Rive } from '../../common/Rive/Rive';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CybermonDialog, CybermonDialogContainer, CybermonDialogContentContainer, CybermonDialogRiveContainer, } from '../styled/CybermonDialog.styled';
import { useModal } from '../context/ModalContext';
import { useEffect } from 'react';
import { useCybermon } from '../hooks/useCybermon';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const AchievementDialog = ({ src, open, onClose, name, }) => {
    const translationPrefix = name === 'Cyberhero' ? 'pages.cybermon.modal.receivedCyberhero' : 'pages.cybermon.modal.receivedBadge';
    const { t } = useTranslation('react_components', { keyPrefix: translationPrefix });
    const body = () => (name === 'Cyberhero' ? t('body') : t('body', { name }));
    return (_jsxs(CybermonDialog, { open: open, PaperComponent: CybermonDialogContainer, PaperProps: { sx: { maxWidth: 'unset', overflowY: 'hidden' } }, children: [_jsx(CybermonDialogRiveContainer, { gridArea: "content", children: _jsx(Rive, { src: src }) }), _jsxs(CybermonDialogContentContainer, { gridArea: "content", children: [_jsxs(Stack, { direction: "column", gap: 4, justifyContent: "center", alignItems: "center", children: [_jsx(Typography, { variant: "h1", sx: { textShadow: (theme) => `0 0 20px ${theme.palette.container.fill.body}` }, children: t('title') }), _jsx(Typography, { variant: "subtitle1", children: body() })] }), _jsx(Button, { onClick: onClose, variant: "contained", color: "default", sx: (theme) => ({ boxShadow: theme.shadows[1] }), children: t('button') })] })] }));
};
export const CybermonAchievementDialog = () => {
    const { setAchievementModalOpen, isAchievementModalOpen } = useModal();
    const { activeCybermon, cybermon, cyberHero, didEarnActiveCybermon, didEarnCyberHero } = useCybermon();
    useEffect(() => {
        if (!activeCybermon)
            return;
        if (didEarnActiveCybermon) {
            setAchievementModalOpen((state) => (Object.assign(Object.assign({}, state), { cybermon: activeCybermon.earned })));
        }
    }, [setAchievementModalOpen, activeCybermon, cybermon, didEarnActiveCybermon]);
    const handleClose = () => {
        if (!activeCybermon || !cyberHero)
            return;
        setAchievementModalOpen((state) => (Object.assign(Object.assign({}, state), { cybermon: false })));
        if (didEarnCyberHero) {
            setAchievementModalOpen((state) => (Object.assign(Object.assign({}, state), { cyberHero: true })));
        }
    };
    const handleCloseCyberHero = () => {
        setAchievementModalOpen({ cybermon: false, cyberHero: false });
    };
    if (!activeCybermon)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(AchievementDialog, { open: isAchievementModalOpen.cybermon, src: `https://media.securecodewarrior.com/achievements/rive/${activeCybermon.name}.riv`, onClose: handleClose, name: activeCybermon.name }), cyberHero && (_jsx(AchievementDialog, { open: isAchievementModalOpen.cyberHero, src: `https://media.securecodewarrior.com/achievements/rive/CyberHero.riv`, onClose: handleCloseCyberHero, name: cyberHero.name }))] }));
};
