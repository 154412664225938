var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { Banner } from '@securecodewarrior/design-system-react/lib/legacy';
const BannerWithDescription = (props) => {
    const { bannerTitle, bannerDescription, children } = props, rest = __rest(props, ["bannerTitle", "bannerDescription", "children"]);
    const bannerDescriptionComponent = (_jsxs("div", { className: "banner-description", children: [_jsx("span", { className: "banner-description-content", children: bannerDescription }), children && children()] }));
    return _jsx(Banner, Object.assign({ description: bannerDescriptionComponent, title: bannerTitle }, rest));
};
export default BannerWithDescription;
