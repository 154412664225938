export const guidelinePlayerAnalytics = (sendEvent, scope, categoryId) => ({
    onThumbsUpClicked: (activityId) => {
        sendEvent({ event: 'Thumbs Up Clicked', scope, activityType: 'Guideline', activityId, categoryId });
    },
    onThumbsDownClicked: (activityId) => {
        sendEvent({ event: 'Thumbs Down Clicked', scope, activityType: 'Guideline', activityId, categoryId });
    },
    onFeedbackSubmitted: (activityId, reason, additionalInformation) => {
        sendEvent({
            event: 'Player feedback submitted',
            scope,
            activityType: 'Guideline',
            activityId,
            categoryId,
            reason,
            additionalInformation,
        });
    },
    onFeedbackClosed: (activityId) => {
        sendEvent({ event: 'Player feedback closed', scope, activityType: 'Guideline', activityId, categoryId });
    },
});
