import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoginIcon } from './icons/LoginIcon';
import { Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
export const LoginMessage = (props) => {
    const { t } = useTranslation();
    return (_jsxs(Stack, { sx: (theme) => ({
            border: `1px solid ${theme.palette.container.border.default}`,
            borderRadius: 3,
            p: 5,
            flexDirection: 'row',
            gap: 3,
        }), children: [_jsx(LoginIcon, { sx: { height: '100%', width: 'auto' } }), _jsxs(Stack, { sx: { gap: 1 }, children: [_jsx(Typography, { variant: "h6", children: _jsx(Trans, { i18nKey: 'pages.explore.login.title', children: _jsx(Link, { variant: "inherit", onClick: props.onLoginClicked }) }) }), _jsx(Typography, { variant: "body1", children: t('pages.explore.login.description') })] })] }));
};
