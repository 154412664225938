import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, svgIconClasses, Typography } from '@mui/material';
import { Container } from '@securecodewarrior/design-system-react';
import { Link } from '@securecodewarrior/design-system-react/lib/legacy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Divider } from '../Divider';
import { ThumbDown as ThumbDownIcon, ThumbUp as ThumbUpIcon } from '@mui/icons-material';
import { FeedbackSentimentIcon } from '../common/icons/FeedbackSentimentIcon';
import { usePlatformContext } from '../platformContext';
import { guidelinePlayerAnalytics } from './GuidelinePlayerAnalytics';
import { useTranslation } from 'react-i18next';
import { FeedbackDialog } from './FeedbackDialog';
const negativeFeedbackReasons = [
    'unclear',
    'outdated',
    'incorrect_information',
    'other',
];
export const GuidelinePlayer = (props) => {
    const contentRef = useRef(null);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => guidelinePlayerAnalytics(logAnalyticsEvent, props.analyticsScope, props.category), [logAnalyticsEvent, props.analyticsScope, props.category]);
    const { t, i18n } = useTranslation();
    const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);
    const [isFeedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const [isFeedbackProvided, setFeedbackProvided] = useState(false);
    const onThumbsUpClick = () => {
        analytics.onThumbsUpClicked(props.id);
        setFeedbackProvided(true);
        props.onFeedbackProvided({
            guidelineId: props.id,
            reason: 'useful',
            additionalInformation: null,
            playMode: props.analyticsScope,
        });
    };
    const onThumbsDownSubmit = (reason, additionalInformation) => {
        setFeedbackProvided(true);
        setFeedbackDialogOpen(false);
        analytics.onFeedbackSubmitted(props.id, reason, additionalInformation);
        props.onFeedbackProvided({ guidelineId: props.id, reason, additionalInformation, playMode: props.analyticsScope });
    };
    const checkScrollPosition = useCallback(() => {
        var _a;
        if (!hasScrolledToEnd && contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
            const isBottomInView = scrollTop + clientHeight >= scrollHeight - 10;
            if (isBottomInView) {
                setHasScrolledToEnd(true);
                (_a = props.onScrolledToEnd) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        }
    }, [hasScrolledToEnd, props]);
    useEffect(() => {
        const currentContentRef = contentRef.current;
        currentContentRef === null || currentContentRef === void 0 ? void 0 : currentContentRef.addEventListener('scroll', checkScrollPosition);
        return () => {
            currentContentRef === null || currentContentRef === void 0 ? void 0 : currentContentRef.removeEventListener('scroll', checkScrollPosition);
        };
    }, [checkScrollPosition, hasScrolledToEnd]);
    useEffect(() => {
        setHasScrolledToEnd(false);
    }, [props.children]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { ref: contentRef, onScroll: checkScrollPosition, sx: Object.assign(Object.assign({}, props.style), { overflow: 'auto' }), children: [props.children, _jsxs(Container, { className: "guideline-player-content", sx: (theme) => ({
                            h1: theme.typography.h1,
                            h2: theme.typography.h2,
                            h3: theme.typography.h3,
                            h4: theme.typography.h4,
                            h5: theme.typography.h5,
                            h6: theme.typography.h6,
                            body: theme.typography.body1,
                            strong: theme.typography.body0,
                            a: {
                                textDecoration: 'underline',
                                color: theme.palette.clickable.default,
                                svg: {
                                    color: theme.palette.clickable.default,
                                },
                                '&:focus-visible': {
                                    outline: `2px solid ${theme.palette.clickable.default}`,
                                    outlineOffset: '2px',
                                    borderRadius: 1,
                                },
                                '&:hover:not([aria-disabled="true"]), &:hover:not([aria-disabled="true"]) svg': {
                                    color: theme.palette.clickable.hover,
                                },
                                '&:active, &:active svg': {
                                    color: `${theme.palette.text.status.success}`,
                                },
                            },
                            hr: {
                                borderTop: `1px solid ${theme.palette.container.border.default}`,
                            },
                            'li pre': {
                                // full width minus (container paddingX plus li left margin) (100vw - (2 * 40px + 20px))
                                maxWidth: 'calc(100vw - 100px)',
                            },
                            pre: {
                                backgroundColor: theme.palette.container.fill.card2,
                                borderRadius: 2,
                                border: `1px solid ${theme.palette.container.border.default}`,
                                padding: 4,
                                // full width minus container paddingX (100vw - (2 * 40px))
                                maxWidth: 'calc(100vw - 80px)',
                                my: 5,
                            },
                            'pre code': {
                                whiteSpace: 'pre',
                                wordWrap: 'normal',
                                overflow: 'auto',
                                p: 0,
                            },
                            code: {
                                backgroundColor: theme.palette.container.fill.card2,
                                color: theme.palette.text.soft,
                                borderRadius: 2,
                                p: 1,
                            },
                            li: {
                                listStyle: 'outside',
                                marginLeft: 5,
                            },
                            img: {
                                display: 'block',
                                maxWidth: '80%',
                                margin: 'auto',
                                marginY: 2,
                            },
                            '.table-responsive': {
                                overflowX: 'auto',
                            },
                            table: {
                                py: 5,
                                borderCollapse: 'separate',
                                borderSpacing: 0,
                                width: '100%',
                                maxWidth: 'calc(100vw - 80px)',
                                backgroundColor: 'transparent',
                            },
                            'table:has(thead) thead tr:first-child > *, table:not(:has(thead)) tbody tr:first-child > *': {
                                borderTop: `1px solid ${theme.palette.container.border.default}`,
                            },
                            'table tr th, table tr td': {
                                borderRight: `1px solid ${theme.palette.container.border.default}`,
                                borderBottom: `1px solid ${theme.palette.container.border.default}`,
                                py: 2.5,
                                px: 5,
                            },
                            'tr > :first-child': {
                                borderLeft: `1px solid ${theme.palette.container.border.default}`,
                            },
                            'table:has(thead) thead tr:first-child >:first-child, table:not(:has(thead)) tbody tr:first-child >:first-child': {
                                borderTopLeftRadius: theme.shape.borderRadius * 2,
                            },
                            'table:has(thead) thead tr:first-child >:last-child, table:not(:has(thead)) tbody tr:first-child >:last-child': {
                                borderTopRightRadius: theme.shape.borderRadius * 2,
                            },
                            'table:not(:has(tfoot)) tbody tr:last-child >:first-child, tfoot tr:last-child >:first-child': {
                                borderBottomLeftRadius: theme.shape.borderRadius * 2,
                            },
                            'table:not(:has(tfoot)) tbody tr:last-child >:last-child, tfoot tr:last-child >:last-child': {
                                borderBottomRightRadius: theme.shape.borderRadius * 2,
                            },
                            'table th': {
                                textAlign: 'left',
                                backgroundColor: theme.palette.container.fill.card2,
                                typography: 'overline',
                            },
                        }), children: [_jsx(Typography, { variant: "h1", children: props.title }), _jsx(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 3,
                                    mb: 3,
                                }, dangerouslySetInnerHTML: { __html: props.guideline } }), _jsx(Divider, {}), _jsx(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    py: 2,
                                    gap: 2,
                                }, children: !isFeedbackProvided ? (_jsxs(_Fragment, { children: [_jsx(Typography, { children: i18n.format(t('components.organisms.feedback.description', {
                                                activityType: t('common.activityType.guideline'),
                                            }), 'sentenceCase') }), _jsxs(Box, { sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }, children: [_jsx(Link, { sx: (theme) => ({
                                                        color: theme.palette.text.status.success,
                                                        textDecoration: 'none',
                                                        p: 2.5,
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                            color: theme.palette.text.status.success,
                                                            [`.${svgIconClasses.root}`]: {
                                                                color: theme.palette.text.status.success,
                                                            },
                                                        },
                                                        fontWeight: 'bold',
                                                    }), startIcon: _jsx(ThumbUpIcon, { sx: (theme) => ({ color: theme.palette.container.border.default }) }), onClick: onThumbsUpClick, children: t('common.labels.yes') }), _jsx(Divider, { orientation: "vertical", sx: (theme) => ({
                                                        backgroundColor: theme.palette.container.border.default,
                                                        height: theme.typography.pxToRem(20),
                                                    }) }), _jsx(Link, { sx: (theme) => ({
                                                        color: theme.palette.text.status.error,
                                                        textDecoration: 'none',
                                                        p: 2.5,
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                            color: theme.palette.text.status.error,
                                                            [`.${svgIconClasses.root}`]: {
                                                                color: theme.palette.text.status.error,
                                                            },
                                                        },
                                                        fontWeight: 'bold',
                                                    }), onClick: () => {
                                                        analytics.onThumbsDownClicked(props.id);
                                                        setFeedbackDialogOpen(true);
                                                    }, startIcon: _jsx(ThumbDownIcon, { sx: (theme) => ({ color: theme.palette.container.border.default }) }), children: t('common.labels.no') })] })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.soft }), children: t('components.organisms.feedback.thanks') }), _jsx(FeedbackSentimentIcon, { sx: (theme) => ({
                                                width: theme.typography.pxToRem(24),
                                                height: theme.typography.pxToRem(24),
                                                color: theme.palette.text.soft,
                                            }) })] })) })] })] }), _jsx(FeedbackDialog, { open: isFeedbackDialogOpen, onClose: () => {
                    analytics.onFeedbackClosed(props.id);
                    setFeedbackDialogOpen(false);
                }, reasons: Object.fromEntries(negativeFeedbackReasons.map((id) => {
                    const activityType = t('common.activityType.guideline');
                    const label = t(`components.organisms.feedback.reason.${id}`, { activityType });
                    return [i18n.format(label, 'sentenceCase'), id];
                })), onSubmit: onThumbsDownSubmit })] }));
};
