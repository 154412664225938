import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip, Stack } from '@mui/material';
// This component is used from angular.js pages via the directive
// "scwTournamentParticipantCompanies" <scw-tournament-participant-companies>
export const ParticipantCompanies = ({ heading, audiences }) => {
    return (_jsxs(Box, { sx: { width: '100%', marginTop: '24px' }, children: [_jsx(Box, { sx: {
                    fontFamily: '"Red Hat Display", Tahoma, Verdana',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '36px',
                    /* identical to box height, or 200% */
                    letterSpacing: '0.5px',
                    color: '#D9D9D9',
                }, children: heading }), Array.isArray(audiences) && audiences.length > 0 && (_jsx(Stack, { direction: "row", flexWrap: "wrap", sx: {
                    gap: '8px',
                    width: '100%',
                }, spacing: 1, children: audiences.map((a) => (_jsx(Chip, { sx: {
                        fontFamily: 'Poppins, Tahoma, Verdana, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '11px',
                        lineHeight: '16px',
                        color: '#15141E',
                        background: '#CBD1EF',
                        borderRadius: '8px',
                    }, size: "small", color: "default", label: a.name }, `${a._id}-${a.region}`))) }))] }));
};
