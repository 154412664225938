import angular from 'angular';
import 'angular-ui-bootstrap';
import templateUrl from './tournament-quest-log.html';

const app = angular.module('SCWTournamentQuestLog', ['ui.bootstrap']);
export default app.name;

app.directive('tournamentQuestLog', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        additionalClasses: '=',
        quest: '=',
        tournament: '=',
        stages: '=', // challenge stage names
        tournamentChallengeStatus: '=', // challenge status constants
        tournamentStageStatus: '=', // stage status constants
      },
      link: function (scope, _element, _attrs) {
        if (!scope.tournament) {
          return;
        }

        scope.STAGES = scope.stages;
        scope.TOURNAMENT_CHALLENGE_STATUS = scope.tournamentChallengeStatus;
        scope.TOURNAMENT_STAGE_STATUS = scope.tournamentStageStatus;

        var quest = scope.quest;
        for (var challengeIndex = 0; challengeIndex < quest.challenges.length; challengeIndex++) {
          var challenge = quest.challenges[challengeIndex];
          var stages = challenge.stages;
          var lives;
          var hints;

          for (var stageIndex = 0; stageIndex < stages.length; stageIndex++) {
            var stage = stages[stageIndex];

            var maxAttempts = scope.tournament.scoringOptions.attempts.length || 3;
            var livesRemaining = maxAttempts - stage.failedAttempts;

            lives = [];
            var i;
            for (i = 0; i < livesRemaining; i++) {
              lives.push(true);
            }
            for (i = 0; i < stage.failedAttempts; i++) {
              lives.push(false);
            }
            stage.lives = lives;

            hints = [];
            for (i = 0; i < stage.hintsUsed; i++) {
              hints.push(true);
            }
            stage.hints = hints;
          }
        }

        return;
      },
    };
  },
]);
