import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('$diffObject', [
  '$flattenObject',
  function ($flattenObject) {
    function diff(src, obj, options) {
      src = src || {};
      obj = obj || {};
      options = options || {};

      var flatSrc = $flattenObject(src);
      var flatNew = $flattenObject(obj);
      var flatAll = _.merge({}, flatSrc, flatNew);

      return _.mapValues(flatAll, function (_merged, key) {
        // includes and ignores
        if (!options.include || !options.include.test(key)) if (options.ignore && options.ignore.test(key)) return;

        var newValue = flatNew[key];
        var oldValue = flatSrc[key];
        var entry = { oldValue: oldValue, newValue: newValue };

        if (oldValue == undefined && newValue != undefined) entry.status = 'added';
        else if (oldValue != undefined && newValue == undefined) entry.status = 'removed';
        else if (oldValue !== newValue) entry.status = 'changed';
        else entry.status = 'unchanged';

        return entry;
      });
    }

    diff.changedOnly = function changedOnly(src, obj, options) {
      var difference = diff(src, obj, options);
      var key,
        result = {};
      for (key in difference)
        if (options.include && options.include.test(key)) result[key] = difference[key];
        else if (difference[key] && difference[key].status !== 'unchanged') result[key] = difference[key];

      return result;
    };

    return diff;
  },
]);
