import angular from 'angular';
import MODULE from './module';

// templates
import newCourseTemplate from './NewCourse.html';
import progressLeaderboardTemplate from './progressLeaderboard.html';
import challengeNewTemplate from './TrainingChallengeNew.html';
import managerMetricsTemplate from './ManagerMetrics.html';
import trainingTemplate from './Training.html';
import notLicensedTemplate from './TrainingNotLicensed.html';

const app = angular.module(MODULE);

app.controller('TrainingController', [
  '$scope',
  '$rootScope',
  '$state',
  '$window',
  'AuthService',
  function ($scope, $rootScope, $state, $window, AuthService) {
    $scope.trainingData = {};
    $scope.companySelectedBySCWAdmin = {};
    $scope.courseId;

    //global data for footer section including xp, currency, level and credits
    $scope.xp = 0;
    $scope.currency = 0;
    $scope.nextLevelXp = 0;
    $scope.currentLevelXp = 0;
    $scope.level = 1;

    function checkRootStateAndRedirect(_state) {
      if (!AuthService.isFeatureEnabled('courses')) {
        $rootScope.defaultRedirects();
        return;
      }
    }

    var deregisterStateChangeStartFn = $rootScope.$on(
      '$stateChangeStart',
      function (_event, toState, _toParams, _fromState, _fromParams) {
        checkRootStateAndRedirect(toState);
      }
    );
    $scope.$on('$destroy', deregisterStateChangeStartFn);

    function init() {
      var slateTheme = document.styleSheets[1];
      slateTheme.disabled = true;

      checkRootStateAndRedirect($state.current);
      if ($window.localStorage.courseId) {
        $scope.courseId = $window.localStorage.courseId;
      }
    }
    init();
  },
]);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'USER_ROLES',
  function ($stateProvider, $urlRouterProvider, USER_ROLES) {
    $urlRouterProvider.when('/training/course-management/assignedCourses', '/courses/manager-metrics');

    $stateProvider.state('assessments-new', {
      url: '/assessments-new',
      controller: 'CourseNewController',
      templateUrl: newCourseTemplate,
      data: {
        authorizedRoles: [
          USER_ROLES.admin,
          USER_ROLES.reseller,
          USER_ROLES.companyAdmin,
          USER_ROLES.manager,
          USER_ROLES.player,
        ],
        feature: 'assessments',
      },
    });

    $stateProvider
      .state('courses', {
        url: '/courses',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [
            USER_ROLES.admin,
            USER_ROLES.reseller,
            USER_ROLES.companyAdmin,
            USER_ROLES.manager,
            USER_ROLES.player,
          ],
          feature: 'courses',
        },
      })
      .state('courses.management', { url: '/course-management' })
      .state('courses.manage', { url: '/manage/{courseId}' })
      .state('courses.my-courses', { url: '/course-list' })
      .state('courses.course-list-details', {
        url: '/course-list/{courseId}',
      })
      .state('courses.course-list-language-breakdown', {
        url: '/course-list/{courseId}/breakdown/{languageId}',
      })
      .state('courses.course-list-language-breakdown-with-action', {
        url: '/course-list/{courseId}/breakdown/{languageId}/{action}',
      })
      .state('courses.assign-additional', {
        url: '/assign-additional/{courseId}',
      })
      .state('courses.assign-participants', {
        url: '/assign-participants/{courseId}',
      })
      .state('courses.course-list-language-concluded', {
        url: '/course-list/{courseId}/breakdown/{languageId}/{moduleId}/{taskAttemptId}/results',
      })
      .state('courses.participants-list', {
        url: '/participants-list/{courseId}/{page}',
      })
      .state('courses.participants-list-v2', {
        url: '/participants-list-v2/{courseId}/{page}',
      })
      .state('courses.participants', { url: '/participants/{courseId}' })
      .state('courses.course-new', { url: '/course-new' })
      .state('courses.management.badge-manager', {
        url: '/badge-manager',
      })
      .state('courses.management.edit-course', {
        url: '/edit-course/{userCourseId}',
      })
      .state('courses.view-task', {
        url: '/course-task/{userCourseId}/{languageId}/{moduleId}/{taskId}',
      })

      // AngularJS routes need to start with "courses-", while angular9 start with "courses."
      .state('courses-progressLeaderboard', {
        url: '/courses/progressleaderboard?_course&_language&_framework',
        controller: 'TrainingProgressLeaderboardController',
        templateUrl: progressLeaderboardTemplate,
        data: {
          authorizedRoles: [
            USER_ROLES.admin,
            USER_ROLES.reseller,
            USER_ROLES.companyAdmin,
            USER_ROLES.manager,
            USER_ROLES.player,
          ],
        },
      })
      .state('courses-challenge-new', {
        url: '/courses/challenge/{_userCourse}/{_language}/{_module}/{_task}',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('challenge-mock', {
        url: '/challenge/mock',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('courses-challenge', {
        url: '/courses/task/challenge/{_userCourse}/{_language}/{_module}/{_task}',
        controller: 'TrainingChallengeNewController',
        templateUrl: challengeNewTemplate,
        params: {
          _userCourse: null,
          _language: null,
          _module: null,
          _task: null,
        },
      })
      .state('courses-mission', {
        url: '/mission/course/{_userCourse}/{_language}/{_module}/{_task}',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        params: {
          _userCourse: null,
          _language: null,
          _module: null,
          _task: null,
        },
      })
      .state('courses-coding-lab', {
        url: '/courses/task/coding-lab/{_userCourse}/{_language}/{_module}/{_task}',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        params: {
          _userCourse: null,
          _language: null,
          _module: null,
          _task: null,
        },
      })
      .state('courses-manager-metrics', {
        url: '/courses/manager-metrics',
        templateUrl: managerMetricsTemplate,
        controller: 'TrainingManagerMetricsController',
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      });

    $stateProvider
      .state('programs', {
        url: '/programs',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [
            USER_ROLES.admin,
            USER_ROLES.reseller,
            USER_ROLES.companyAdmin,
            USER_ROLES.manager,
            USER_ROLES.player,
          ],
          feature: 'programs',
        },
      })
      .state('programs.management', { url: '/management' })
      .state('programs.create-new', { url: '/create' })
      .state('programs.manage', { url: '/manage/:programId' })
      .state('programs.my-programs', { url: '/list' })
      .state('programs.my', { url: '/my/:programId' })
      .state('programs.participants', {
        url: '/participants/{programId}?current-participants_progress',
      });

    $stateProvider.state('coding-labs', {
      url: '/coding-labs',
      controller: [
        '$state',
        function ($state) {
          $state.go('explore');
        },
      ],
      data: {
        authorizedRoles: [
          USER_ROLES.admin,
          USER_ROLES.reseller,
          USER_ROLES.companyAdmin,
          USER_ROLES.manager,
          USER_ROLES.player,
        ],
        feature: 'coding-labs',
      },
    });

    $stateProvider.state('lesson-player', {
      url: '/coding-labs/lesson/:revisionId/:language/:difficulty',
      controller: [
        '$stateParams',
        function ($stateParams) {
          window.location = `${window.location.origin}/#/explore/coding-lab/${$stateParams.revisionId}`;
        },
      ],
      data: {
        authorizedRoles: [
          USER_ROLES.admin,
          USER_ROLES.reseller,
          USER_ROLES.companyAdmin,
          USER_ROLES.manager,
          USER_ROLES.player,
        ],
        feature: 'coding-labs',
      },
    });

    $stateProvider
      .state('training', {
        url: '/training',
        controller: 'TrainingController',
        templateUrl: trainingTemplate,
        data: {
          authorizedRoles: [
            USER_ROLES.admin,
            USER_ROLES.reseller,
            USER_ROLES.companyAdmin,
            USER_ROLES.manager,
            USER_ROLES.player,
          ],
          feature: 'courses',
        },
      })
      .state('training.progressLeaderboard', {
        url: '/progressleaderboard?_course&_language&_framework',
        controller: 'TrainingProgressLeaderboardController',
        templateUrl: progressLeaderboardTemplate,
      })
      .state('training.pointsLeaderboard', {
        url: '/pointsleaderboard?_course&_language&_framework',
        controller: 'TrainingPointsLeaderboardController',
        templateUrl: progressLeaderboardTemplate,
      })
      .state('training.course-management', {
        url: '/course-management',
        abstract: true,
        template: '<ui-view/>',
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('training.training-not-licensed', {
        url: '/training-not-licensed',
        templateUrl: notLicensedTemplate,
      });
  },
]);
