var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { VulnerabilitySummariser, ParserError, ParsingAbortedError, } from '@securecodewarrior/vulnerability-parser';
const useVulnerabilitySummariser = (setVulnerabilityInfo, directLinkingApiEndpoint, translator) => {
    const zeroProgress = {
        progressPercent: 0,
        rowsProcessed: 0,
    };
    const [file, setFile] = useState();
    const [vulnerabilitySummariser] = useState(() => new VulnerabilitySummariser(directLinkingApiEndpoint));
    const abortParsingRef = useRef(() => undefined);
    const [progress, setProgress] = useState(zeroProgress);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState();
    useEffect(() => {
        if (!file)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setVulnerabilityInfo(undefined);
            setComplete(false);
            setError(undefined);
            try {
                const fileSize = file.size;
                console.time('timer');
                const config = { webWorker: false, updateProgressGranularity: 100 };
                const { vulnerabilities, abortParsing } = yield vulnerabilitySummariser.parseCsv(file, config, ({ rowsProcessed, cursor }) => setProgress({
                    progressPercent: cursor ? (cursor / fileSize) * 100 : 0,
                    rowsProcessed,
                }));
                abortParsingRef.current = abortParsing;
                const result = yield vulnerabilities;
                console.timeEnd('timer');
                setVulnerabilityInfo([...result]);
                setComplete(true);
            }
            catch (e) {
                if (e instanceof ParsingAbortedError) {
                    return;
                }
                else if (e instanceof ParserError) {
                    setError(translator('FAILED_TO_PARSE_FILENAME_AT_ROWNUMBER', { name: file.name, row: e.row }));
                    setComplete(true);
                }
                else {
                    console.error(e);
                    setError(translator('VULNERABILITY_SUMMARISER_ERROR_CSV_MALFORMED'));
                    setComplete(true);
                }
            }
            finally {
                abortParsingRef.current = () => undefined;
                setProgress(zeroProgress);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);
    return Object.assign({ file,
        setFile, abortParsing: abortParsingRef, complete,
        progress }, (error && { error }));
};
export default useVulnerabilitySummariser;
