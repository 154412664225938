var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton as MuiIconButton } from '@mui/material';
import { deepmerge } from '@mui/utils';
export const IconButton = (_a) => {
    var { sx } = _a, props = __rest(_a, ["sx"]);
    return (_jsx(MuiIconButton, Object.assign({ sx: (theme) => deepmerge({
            color: theme.palette.clickable.default,
            padding: theme.spacing(1),
            backgroundColor: theme.palette.container.fill.card2,
            ':focus-visible': {
                outline: `2px solid ${theme.palette.clickable.default}`,
                outlineOffset: '2px',
            },
            ':hover': {
                backgroundColor: theme.palette.container.fill.card2,
                color: theme.palette.clickable.hover,
            },
        }, sx) }, props)));
};
export default IconButton;
