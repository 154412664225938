import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useMemo } from 'react';
import { usePlatformContext } from '../platformContext';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../ApiContext';
import { VideoPlayerBase } from '../players/VideoPlayerBase';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformNavigate } from '../navigation';
import { WithFooter } from '../footer/WithFooter';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { ActivityStatusPage } from '../../common/components/organisms/Activities/ActivityStatusPage';
import { ActivityStatusBanner } from '../../common/components/organisms/Activities/ActivityStatusBanner';
import { Divider } from '@mui/material';
export const VideoPlayerWrapper = () => {
    const { videoId } = useParams();
    if (!videoId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const isAuthenticated = !!platformCtx.sessionUser;
    const [response, setResponse] = useState(undefined);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const [secondsSpent, setSecondsSpent] = useState(0);
    const fetch = (redoIfCompleted = false) => {
        setCompletionStatus('in_progress');
        setEnableMarkAsComplete(false);
        setResponse(undefined);
        handleErrorPromise(trpc.explore.video.startOrContinue.mutate({ videoId, redoIfCompleted }), (r) => {
            if (r.authenticated !== isAuthenticated) {
                platformCtx.onUnAuthenticated();
                return;
            }
            setResponse(r);
        });
    };
    useEffect(fetch, [videoId]); // eslint-disable-line react-hooks/exhaustive-deps
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', hash: '', search: '' });
    }, [navigate, platformCtx]);
    const onCompleteVideo = useCallback((secondsSpent) => {
        setSecondsSpent(Math.round(secondsSpent));
        setEnableMarkAsComplete(true);
    }, []);
    const canMarkAsComplete = response && response.kind === 'ok' && response.canMarkAsComplete !== false;
    const onMarkAsComplete = useMemo(() => {
        if (canMarkAsComplete) {
            return () => {
                setEnableMarkAsComplete(false);
                handleErrorPromise(trpc.explore.video.finish.mutate({ videoId, secondsSpent }), (_r) => {
                    setCompletionStatus('passed');
                });
            };
        }
    }, [videoId, secondsSpent, handleErrorPromise, navigateBack, canMarkAsComplete]); // eslint-disable-line react-hooks/exhaustive-deps
    const loadingState = () => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "video", module: "explore", status: "none", onBackNavigation: navigateBack }), _jsx(VideoPlayerBase, { video: undefined, analyticsScope: "explore", onComplete: undefined })] }));
    };
    const alreadyCompletedState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "video", module: "explore", status: "passed", date: response.lastCompleted, onBackNavigation: navigateBack }), _jsx(ActivityStatusBanner, { scope: "explore", variant: "already_completed", contentType: "video", contentId: videoId, onClickStartRefresher: () => {
                        fetch(true);
                    } }), _jsx(Divider, {}), _jsx(VideoPlayerBase, { video: response.video, analyticsScope: "explore", onComplete: onCompleteVideo })] }));
    };
    const justPassedState = () => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "video", module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "success", contentType: "video", contentId: videoId, onClickBackToActivity: () => setCompletionStatus('review') })] }));
    };
    const inProgressState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "video", module: "explore", status: canMarkAsComplete ? 'in_progress' : 'none', onBackNavigation: navigateBack, onMarkAsComplete: onMarkAsComplete, enableMarkAsComplete: enableMarkAsComplete }), _jsx(VideoPlayerBase, { video: response.video, analyticsScope: "explore", onComplete: onCompleteVideo })] }));
    };
    const reviewAfterPassedState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "video", module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsx(ActivityStatusBanner, { scope: "explore", variant: "already_completed", contentType: "video", contentId: videoId, onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {}), _jsx(VideoPlayerBase, { video: response.video, analyticsScope: "explore" })] }));
    };
    const playingState = (response) => {
        return completionStatus === 'passed' ? (_jsx(_Fragment, { children: justPassedState() })) : completionStatus === 'in_progress' ? (_jsx(_Fragment, { children: inProgressState(response) })) : completionStatus === 'review' ? (_jsx(_Fragment, { children: reviewAfterPassedState(response) })) : (assertNever(completionStatus, 'invalid state'));
    };
    return (_jsx(WithFooter, { wrapperComponent: "main", children: !response ? (_jsx(_Fragment, { children: loadingState() })) : response.kind === 'already_completed' ? (_jsx(_Fragment, { children: alreadyCompletedState(response) })) : response.kind === 'ok' ? (_jsx(_Fragment, { children: playingState(response) })) : (assertNever(response, 'invalid state')) }));
};
