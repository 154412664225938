import angular from 'angular';
import MODULE from './module';
import templateUrl from './search.base.html';

angular.module(MODULE).directive('searchBase', [
  '$translate',
  '$log',
  '$rootScope', // metadata
  'SearchService',
  'ErrorHandler',
  function (
    $translate,
    $log,
    $rootScope, // metadata
    SearchService,
    ErrorHandler
  ) {
    return {
      restrict: 'E',
      templateUrl,
      transclude: true,
      scope: {
        autoInit: '=?autoInit',
        searchUrl: '=searchUrl',
        summaryUrl: '=?summaryUrl',
        searchMapping: '=?searchMapping',
        summaryMapping: '=?summaryMapping',
        onSelectionUpdate: '&?onSelectionUpdate',
        onSearchCompletion: '&?onSearchCompletion',
        paginationPreferences: '=?paginationPreferences',
        paginationOptions: '=?paginationOptions',
        sort: '=?sort',
        query: '=?query',
        bind: '=?bind',
      },
      link: function (scope, element, _attrs, _controller, transcludeFn) {
        element.find('transclusion').append(transcludeFn(scope, function () {}));

        scope.metadata = $rootScope.metadata;
        scope.super = scope.$parent; // access to transclusion functions

        var ui = {
          selection: {},
        };

        scope.$watch(
          'ui.selection',
          function (_n, _o) {
            var allSelected = true,
              item;

            if (!scope.results) return;

            for (var i = 0; i < scope.results.length; i++) {
              item = scope.results[i];
              if (!ui.selection[item._id]) {
                allSelected = false;
                break;
              }
            }
            toggleSelection.all = allSelected;
            ui.selection.all = allSelected;

            if (scope.onSelectionUpdate) {
              $log.debug('onSelectionUpdate is declared. Invoking with: ', selection());
              scope.onSelectionUpdate({
                selection: selection(),
              });
            }
          },
          true
        );

        scope.$watch('query', function (n, _o) {
          if (!scope.autoInit || !n) return;
          if (search.inprogress === true) return;
          search('');
        });

        // Begin: pagination options vs preferences
        scope.paginationOptions = scope.paginationOptions || {
          page: 1,
        };
        if (scope.paginationPreferences) {
          scope.paginationOptions.size = scope.paginationOptions.size || scope.paginationPreferences.itemsPerPage || 10;
        }

        scope.$watch('paginationOptions.size', function (n, _o) {
          if (scope.paginationPreferences) scope.paginationPreferences.itemsPerPage = n;
          if (search.inprogress === true) return;
          search(search.text, false);
        });

        scope.$watch('paginationOptions.page', function (_n, _o) {
          if (search.inprogress === true) return;
          search(search.text, true);
        });
        // End: pagination options vs preferences

        // Begin: exposed methods
        scope.sortBy = sortBy;
        sortBy.current = scope.sort;
        scope.search = search;
        scope.toggleSelection = toggleSelection;
        scope.ui = ui;
        scope.selection = selection;
        scope.nextPage = nextPage;
        scope.previousPage = previousPage;
        scope.firstPage = firstPage;
        scope.lastPage = lastPage;
        scope.increaseItemsPerPage = increaseItemsPerPage;
        scope.decreaseItemsPerPage = decreaseItemsPerPage;

        // End: exposed methods

        // Begin: external access
        scope.bind = scope.bind || {};
        scope.bind.sort = sortBy;
        scope.bind.search = search;
        scope.bind.selection = selection;
        scope.bind.results = function () {
          return scope.results;
        };
        // End: external access

        function selection() {
          return _.filter(_.keys(ui.selection), function (key) {
            return ui.selection[key] && key !== 'all';
          });
        }

        selection.clear = function () {
          ui.selection = { all: false };
        };

        function toggleSelection(_flag) {
          // _all_ behavior
          _.forEach(scope.results, function (user) {
            scope.ui.selection[user._id] = !toggleSelection.all;
          });

          toggleSelection.all = !toggleSelection.all;

          if (scope.bind) {
            scope.bind.selection.all = toggleSelection.all;
          }
        }

        function between(start, end, val) {
          if (val < start) return start;
          if (val > end) return end;
          return val;
        }

        function increaseItemsPerPage() {
          var options = [10, 25, 50];
          var curr = scope.paginationOptions.size;

          var curridx = options.indexOf(curr);
          var nextidx = between(0, options.length - 1, curridx + 1);
          scope.paginationOptions.size = options[nextidx];
        }

        function decreaseItemsPerPage() {
          var options = [10, 25, 50];
          var curr = scope.paginationOptions.size;

          var curridx = options.indexOf(curr);
          var nextidx = between(0, options.length - 1, curridx - 1);
          scope.paginationOptions.size = options[nextidx];
        }

        function firstPage() {
          scope.paginationOptions.page = 1;
        }

        function lastPage() {
          scope.paginationOptions.page = (scope.page && scope.page.pages) || 1;
        }

        function previousPage() {
          var page = scope.paginationOptions.page - 1;
          if (page < 1) page = 1;
          scope.paginationOptions.page = page;
        }

        function nextPage() {
          var pages = (scope.page && scope.page.pages) || 1;
          var page = scope.paginationOptions.page + 1;
          if (page > pages) page = pages;
          scope.paginationOptions.page = page;
        }

        function sortBy(attribute) {
          if (sortBy.current == attribute) sortBy.current = '-' + attribute;
          else sortBy.current = attribute;

          search(search.text, true);
        }

        function search(text, paging, newQuery) {
          if (newQuery) scope.query = newQuery;

          if (!scope.query) return; // do nothing

          search.inprogress = true;
          search.text = text; // current search

          if (!scope.paginationOptions) scope.paginationOptions = {};

          // reset search to page 1
          if (!paging) scope.paginationOptions.page = 1;

          scope.$broadcast(
            'search-base:search-triggered',
            _.merge(
              {
                text: search.text,
              },
              scope.query
            )
          );

          var searches = [];
          searches.push(
            SearchService.search(
              scope.searchUrl,
              scope.query,
              text,
              scope.paginationOptions,
              sortBy.current,
              scope.searchMapping
            )
              .then(searchSuccess)
              .catch(processError)
              .finally(function (_userList) {
                search.loaded = true;
                search.inprogress = false;
              })
          );

          if (scope.summaryUrl) {
            searches.push(
              SearchService.summary(scope.summaryUrl, scope.query, text)
                .then(function (data) {
                  scope.summary = data;
                })
                .catch(processError)
            );
          }

          return searches;
        }

        function processError(response) {
          $log.error(response);
          if (response.status !== 0 || response.data.error != 'HTTP request aborted') {
            ErrorHandler.addHttpError($translate.instant('UNABLE_TO_RETRIEVE_DATA'), response);
          }
        }

        function searchSuccess(page) {
          var allSelected = true;
          var item;

          scope.page = page;
          scope.results = page.data;

          for (var i = 0; i < scope.results.length; i++) {
            item = scope.results[i];
            if (!ui.selection[item._id]) {
              allSelected = false;
              break;
            }
          }

          toggleSelection.all = allSelected;
          ui.selection.all = allSelected;

          if (scope.onSearchCompletion)
            try {
              scope.onSearchCompletion({ page: page });
            } catch (e) {
              $log.error(e);
            } // handle your own errors...

          return scope.results;
        }
      },
    };
  },
]);
