import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('ScoringInfoModalController', [
  '$uibModalInstance',
  '$scope',
  function ($uibModalInstance, $scope) {
    $scope.liveStatusTabs = { activeIndex: 0 };

    $scope.LIVE_STATUS_TABS = {
      BASE: 0,
      BONUS: 1,
    };

    $scope.close = $uibModalInstance.close;
  },
]);
