var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { Icon, Tooltip } from '@securecodewarrior/design-system-react/lib/legacy';
import { Trans } from 'react-i18next';
const AssessmentsBannerTooltip = (props) => {
    const { tooltipIconName, tooltipAssessmentsDescriptionTranslationKey } = props, rest = __rest(props, ["tooltipIconName", "tooltipAssessmentsDescriptionTranslationKey"]);
    const tooltipTitleComponent = tooltipAssessmentsDescriptionTranslationKey && (_jsx("div", { className: "tooltip-content", children: _jsx(Trans, { i18nKey: tooltipAssessmentsDescriptionTranslationKey }) }));
    return (_jsx(Tooltip, Object.assign({ arrow: true, title: tooltipTitleComponent }, rest, { children: _jsx("span", { className: "banner-tooltip", children: _jsx(Icon, { name: tooltipIconName }) }) })));
};
export default AssessmentsBannerTooltip;
