export const ANALYTICS_EVENTS = {
  General: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    VIEW_CHANGE: 'view',
    FORM_INTERACTION: 'form interaction',
    ADD_TEAM: 'add team',
    SESSION_TIMED_OUT: 'session timed out',
  },
  Onboarding: {
    INVITE_USER: 'invite user',
    INVITE_EMAIL_CLICKED: 'invite email clicked',
    COMPLETE_USER_PROFILE: 'complete user profile',
    SSO_USER_ACCEPT_TERMS: 'user_sso-accept-terms',
  },
  Challenges: {
    BEGIN_CHALLENGE: 'begin challenge',
    COMPLETE_CHALLENGE: 'complete challenge',
    SKIP_CHALLENGE: 'skip challenge stage',
  },
  PlayNow: {
    SIGN_UP: 'sign up',
    SET_NAME: 'set name',
  },
  Training: {
    CLICK_LOCKED_LANGUAGE: 'click locked development language',
    SHARE_STATS_ON_LINKEDIN: 'click share my statistics on linkedin',
  },
  Tournaments: {
    ACCESS_TOURNAMENT_LEADERBOARD: 'access tournament leaderboard',
    ACCESS_PARTICIPANTS_PAGE: 'access tournament participants page',
  },
  Courses: {
    ACCESS_LEADERBOARD: 'access courses leaderboard',
  },
  Assessments: {
    DETAILS_RECENT_ACTIVITY: 'access assessments recent activity',
  },
  Missions: {
    MISSIONS_HINT_FEEDBACK: 'missions hint feedback',
    INSTRUCTIONS_COMPLETED: 'missions instructions completed',
  },
  Management: {
    BULK_REINVITE: 'bulk reinvite',
  },
};

export const WEBSITE_TRIAL_EVENTS = new Set();
WEBSITE_TRIAL_EVENTS.add(ANALYTICS_EVENTS.General.VIEW_CHANGE);
WEBSITE_TRIAL_EVENTS.add(ANALYTICS_EVENTS.PlayNow.SIGN_UP);
WEBSITE_TRIAL_EVENTS.add(ANALYTICS_EVENTS.PlayNow.SET_NAME);
