import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import templateUrl from './time-popover.html';
import timePopoverBodyTemplateUrl from './time-popover-body.html';

angular.module(MODULE).directive('timePopover', [
  function () {
    return {
      restrict: 'E',
      scope: {
        hideGlyphicon: '=',
        utctime: '=',
        createdTimezone: '=',
        hoverenabled: '=', // Boolean (true/false) so we can toggle click event or hover event.
      },
      replace: true,
      transclude: true,
      templateUrl,
      controller: [
        '$scope',
        function ($scope) {
          $scope.$watch('createdTimezone', function (_value) {
            updateDisplay();
          });
          $scope.timePopoverBodyTemplateUrl = timePopoverBodyTemplateUrl;

          // Made the following a function so that when createdTimezone is updated in assessment, it can be recalculated again
          function updateDisplay() {
            // For storing current team and company id the user is assigned to
            var localTimezone = moment.tz.guess();
            var createdTimezone = $scope.createdTimezone || localTimezone;
            var t = moment.utc($scope.utctime);
            var format = 'MMM D, YYYY HH:mm z';
            var formatedUtc = t.format(format);
            var formatedCreatedTime = t.tz(createdTimezone).format(format);
            var formatedLocal = t.tz(localTimezone).format(format);
            var displayTimes = [formatedUtc, formatedCreatedTime, formatedLocal];
            var dedup = [];
            for (var i = 0, len = displayTimes.length; i < len; i++) {
              var j = displayTimes[i];
              if (dedup.indexOf(j) === -1) {
                dedup.push(j);
              }
            }

            // Add an option to have the hover effect on the icon to display the popup,
            // this is for the clickable rows in assesments.
            var onEvent = 'outsideClick';
            if ($scope.hoverenabled) onEvent = 'mouseenter';

            $scope.onEvent = onEvent;
            $scope.displayTimes = dedup;
          }

          // initial call
          updateDisplay();
        },
      ],
    };
  },
]);
