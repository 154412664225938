import angular from 'angular';
import humanizeDuration from 'humanize-duration';
import MODULE from './module';
import _ from 'lodash';

angular.module(MODULE).factory('localeHelpers', [
  'Session',
  (Session) => {
    return {
      translationAvailable: (locale) => {
        let allowedLocales = ['en', 'en-gb'];

        if (_.get(Session.getUser(), 'properties.preferences.challenges.useAutomatedTranslations.enabled', false)) {
          allowedLocales.push('de');
        }

        return allowedLocales.includes(locale.toLowerCase());
      },

      timerLanguageFor: (locale) => {
        const normalized = locale.replace('-', '_');
        if (humanizeDuration.getSupportedLanguages().includes(normalized)) {
          return normalized;
        }

        const stripped = normalized.split(/[_]/, 1)[0];
        if (humanizeDuration.getSupportedLanguages().includes(stripped)) {
          return stripped;
        }

        return 'en';
      },
    };
  },
]);
