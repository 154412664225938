import angular from 'angular';
import MODULE from './module';
import templateUrl from './tournaments.list.item.html';

angular.module(MODULE).directive('tournamentListItem', [
  '$log',
  '$translate',
  'TOURNAMENT_STATUS',
  'TAB_INDEXES',
  'TAB_INDEXES_ALT_NAME',
  function ($log, $translate, TOURNAMENT_STATUS, TAB_INDEXES, TAB_INDEXES_ALT_NAME) {
    return {
      restrict: 'E',
      scope: {
        autoFocus: '=?',
        tournament: '=',
        isAdmin: '<',
        goToLiveStatus: '&',
        manageTournament: '&',
        goToMetrics: '&',
        unpublish: '&',
        publish: '&',
        joinTournament: '&',
        playTournament: '&',
        selectTournament: '&',
        hideTournamentStatus: '@',
        toggleLink: '&',
        viewTournamentResults: '&',
        recalcQuests: '&',
        downloadCsv: '&',
      },
      templateUrl,
      link: function (scope) {
        scope.now = Date;
        scope.TOURNAMENT_STATUS = TOURNAMENT_STATUS;
        scope.TAB_INDEXES = TAB_INDEXES;
        scope.TAB_INDEXES_ALT_NAME = TAB_INDEXES_ALT_NAME;
        scope.toggleShowJoinPassword = function () {
          scope.isShowingJoinPassword = !scope.isShowingJoinPassword;
        };
        scope.sharedSecret = {};
        var userRoles = scope.$parent.userRoles;
        var isAuthorized = scope.$parent.isAuthorized;
        var isSCWAdmin = isAuthorized(userRoles.admin);
        scope.isSCWAdmin = isSCWAdmin;

        scope.isSpecialEvent = function (tournament) {
          return Array.isArray(tournament?.audiences) && tournament.audiences.length > 0;
        };

        scope.defaultAction = function (tournament) {
          $log.debug('tournament.status', tournament.status, tournament);

          if (tournament.status === 'not started') return scope.manageTournament(tournament);

          if (tournament.status === 'active') {
            return scope.playTournament(tournament);
          }

          return scope.goToLiveStatus(tournament);
        };

        scope.languagesNotAccessibleSwal = function () {
          swal({
            title: $translate.instant('TOURNAMENT_LANGUAGES_NOT_ACCESSIBLE'),
            text: $translate.instant('NO_ACCESS_TO_TOURNAMENT_LANGUAGES_PLEASE_CONTACT_YOUR_MANAGER'),
            type: 'error',
            html: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: $translate.instant('OK'),
          });
        };
      },
    };
  },
]);
