import angular from 'angular';
import MODULE from './module';
import breadcrumbTemplate from './challenge.breadcrumb.html';

angular.module(MODULE).component('breadcrumb', {
  templateUrl: breadcrumbTemplate,
  bindings: {
    playmode: '=',
    challenge: '=',
  },
  controller: [
    '$window',
    '$scope',
    '$rootScope',
    '$state',
    '$translate',
    '$back',
    function ($window, $scope, $rootScope, $state, $translate, $back) {
      this.TRANSLATIONS = {
        L1: $translate.instant('SELECT_VULN_CATEGORY'),
        L2: $translate.instant('LOCATE_VULN'),
        L3: $translate.instant('REVIEW_PROPOSED_SOLUTION'),
        L4: `${$translate.instant('SELECT_VULN_CATEGORY')} & ${$translate.instant('REVIEW_PROPOSED_SOLUTION')}`,
        L5: `${$translate.instant('LOCATE_VULN')} & ${$translate.instant('REVIEW_PROPOSED_SOLUTION')}`,
        I1: $translate.instant('INTERACTIVE_CHALLENGE'),
        COURSE_OVERVIEW: $translate.instant('COURSE_OVERVIEW'),
        COURSE_BREAKDOWN: $translate.instant('COURSE_BREAKDOWN'),
        TOURNAMENT_ACTIVE_MISSIONS: $translate.instant('ACTIVE_MISSIONS'),
        ASSESSMENT_OVERVIEW: $translate.instant('ASSESSMENT_OVERVIEW'),
        ASSESSMENT: $translate.instant('ASSESSMENT'),
        ASSESSMENT_ATTEMPT: $translate.instant('ASSESSMENT_ATTEMPT'),
        TRAINING_LANGUAGES: $translate.instant('LANGUAGES'),
        TRAINING_MISSION_CONTROL: $translate.instant('MISSION_CONTROL'),
        TRAINING_ACTIVE_MISSIONS: $translate.instant('ACTIVE_MISSIONS'),
      };

      this.$onChanges = function () {
        $scope.spinner = false;
        $scope.playmode = this.playmode;

        if (this.playmode === 'courses') {
          $scope.breadcrumb = initCoursesBreadcrumb(this.challenge, this.TRANSLATIONS, $state);
        }
        if (this.playmode === 'tournaments') {
          $scope.breadcrumb = initTournamentsBreadCrumb(this.challenge, this.TRANSLATIONS, $state);
        }
        if (this.playmode === 'assessments') {
          $scope.breadcrumb = initAssessmentsBreadcrumb(this.challenge, this.TRANSLATIONS, $state);
        }
        if (this.playmode === 'training') {
          $scope.breadcrumb = initTrainingBreadcrumb(this.challenge, this.TRANSLATIONS, $state);
        }
        if (this.playmode === 'microlearning') {
          $scope.breadcrumb = initMicrolearningBreadcrumb(this.challenge, this.TRANSLATIONS, $state);
        }
        if (this.playmode === 'cms') {
          const cId = this.challenge.cId;
          const route = () => $window.open(`https://cms.securecodewarrior.com/search?q=${cId}`, '_blank');
          $scope.breadcrumb = [{ title: `CMS Challenge ${cId}`, route }];
        }
      };

      $scope.navigate = (index) => {
        const fn = $scope.breadcrumb[index].route;
        if (typeof fn === 'function') {
          fn.apply();
        }
      };

      $scope.skip = () => {
        $scope.spinner = true;
        $scope.$emit('breadcrumb:skip');
      };

      $scope.abandon = () => {
        $scope.$emit('breadcrumb:abandon');
      };

      const initTournamentsBreadCrumb = (challenge, TRANSLATIONS, state) => {
        const questName = challenge.tournament.questNames.find((qn) => qn._id === state.params._quest).name || '';
        return [
          {
            title: TRANSLATIONS.TOURNAMENT_ACTIVE_MISSIONS,
            route: () => {
              delete $rootScope.lastTournamentCountrySelected;
              $back().or('tournaments.player', state.params).with({ reload: true }).go();
            },
          },
          {
            title: `${questName}`,
            route: () => $back().or('tournaments.player', state.params).with({ reload: true }).go(),
          },
          {
            title: `${TRANSLATIONS[challenge.cbl]}`,
            route: '',
          },
        ];
      };

      const initCoursesBreadcrumb = (challenge, TRANSLATIONS, state) => {
        if (!challenge) return;
        return [
          {
            title: TRANSLATIONS.COURSE_OVERVIEW,
            route: () => {
              state.go('courses.course-list-details', { courseId: state.params.courseId });
            },
          },
          {
            title: TRANSLATIONS.COURSE_BREAKDOWN,
            route: () => {
              state.go('courses.course-list-language-breakdown', {
                courseId: state.params.courseId,
                languageId: state.params._language,
              });
            },
          },
          {
            title: TRANSLATIONS[challenge.cbl],
            route: '',
          },
        ];
      };

      const initAssessmentsBreadcrumb = (challenge, TRANSLATIONS, state) => {
        if (!challenge) return;
        return [
          {
            title: TRANSLATIONS.ASSESSMENT,
            route: () => {
              state.go('assessments.view.attempt', {
                assessmentId: state.params._assessment,
                attemptId: state.params._attempt,
              });
            },
          },
          {
            title: TRANSLATIONS[challenge.cbl],
            route: '',
          },
        ];
      };

      const initTrainingBreadcrumb = (challenge, TRANSLATIONS, state) => {
        if (!challenge) return;
        return [
          {
            title: TRANSLATIONS.TRAINING_LANGUAGES,
            route: () => {
              state.go('game.013.languages');
            },
          },
          {
            title: TRANSLATIONS.TRAINING_MISSION_CONTROL,
            route: () => {
              state.go('game.013.play.realms', {
                _language: state.params._language,
                _framework: state.params._framework,
              });
            },
          },
          {
            title: TRANSLATIONS.TRAINING_ACTIVE_MISSIONS,
            route: () => {
              state.go('game.013.play.level', {
                _language: state.params._language,
                _framework: state.params._framework,
                _realm: state.params._realm,
                _level: state.params._level,
                _quest: state.params._quest,
              });
            },
          },
          {
            title: TRANSLATIONS[challenge.cbl],
            route: '',
          },
        ];
      };

      const initMicrolearningBreadcrumb = (challenge, TRANSLATIONS, state) => {
        if (!challenge) return;
        return [
          {
            title: TRANSLATIONS.TRAINING_MISSION_CONTROL,
            route: () => {
              state.go('microlearning-picker');
            },
          },
          {
            title: TRANSLATIONS[challenge.cbl],
            route: '',
          },
        ];
      };
    },
  ],
});
