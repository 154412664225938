import angular from 'angular';
import MODULE from './module';

var app = angular.module(MODULE);

app.controller('LearningResourcesController', [
  '$stateParams',
  '$rootScope',
  function ($stateParams, $rootScope) {
    var learning = $rootScope.metadata.learning;
    var path = decodeURIComponent($stateParams.path);
    path = path.split('/');
    // strip out empty values from path
    path = path.reduce(function (cleanedPath, item) {
      if (item) {
        cleanedPath.push(item);
      }
      return cleanedPath;
    }, []);

    // get the right item from metadata based on the path
    var learningResource = path.reduce(function (learningResource, itemId) {
      if (learningResource) return learningResource.subitem[itemId];
    }, learning);

    if (learningResource.ref?.learning?.SCW_public) {
      const videoPath = path.filter((i) => i).join(':');
      window.location = `/#/explore/video/${videoPath}`;
    } else {
      window.location = '/#/explore';
    }
  },
]);
