import angular from 'angular';
import MODULE from './module';

(function () {
  var app = angular.module(MODULE);

  app.factory('LearningresourcesApiService', [
    '$log',
    '$http',
    '$rootScope',
    '$translate',
    '$window',
    'HttpConfigService',
    function ($log, $http, $rootScope, $translate, $window, HttpConfigService) {
      var LearningresourcesApi = {};

      var LearningresourcesApiEndpoint = function () {
        return $window.SCW_ENV.ApiEndpoint + '/learning';
      };

      LearningresourcesApi.addLoading = function () {
        $rootScope.currentLoadingMessage = $translate.instant(
          'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
        );
        $rootScope.loading++;
      };

      LearningresourcesApi.removeLoading = function () {
        $rootScope.loading--;
        if ($rootScope.loading < 0) $rootScope.loading = 0;
      };

      LearningresourcesApi.createLogRecord = function (learningresource, totalduration, playmode) {
        $log.debug('LearningresourcesApi - createLogRecord()', learningresource, totalduration, playmode);
        encodeParams(arguments);

        LearningresourcesApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: LearningresourcesApiEndpoint() + '/log',
              data: { learningresource: learningresource, totalduration: totalduration, playmode },
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            LearningresourcesApi.removeLoading();
          });
      };

      return LearningresourcesApi;
    },
  ]);
})();
