import angular from 'angular';
import MODULE from './module';

var EANONIMITY = {
  NONE: 0,
  DEV: 1,
  DEVNTM: 2,
  ALL: 3,
};

angular.module(MODULE).controller('CompanyPreferencesModalController', [
  '$uibModalInstance',
  '$scope',
  '$rootScope',
  'USER_ROLES',
  '$state',
  '$translate',
  'AdminApiService',
  'ErrorHandler',
  'SenseiApiService',
  '$swal',
  '$q',
  function (
    $uibModalInstance,
    $scope,
    $rootScope,
    USER_ROLES,
    $state,
    $translate,
    AdminApiService,
    ErrorHandler,
    SenseiApiService,
    $swal,
    $q
  ) {
    $scope.preferences = {};
    $scope.prefId = null;

    $scope.$watch(
      () => {
        return $scope.preferences.model?.leaderboard?.options;
      },
      (newVal, oldVal) => {
        if (newVal?.hideLeaderboard === true && oldVal?.hideLeaderboard === false && newVal.isAnonymous === false) {
          $scope.preferences.model.leaderboard.options.isAnonymous = true;
          const isAnonymousButton = angular.element('input[type=checkbox][name="isAnonymous"]');
          $(isAnonymousButton).bootstrapSwitch('state', true, false);
        }
      },
      true
    );

    // Watch anonymization settings to prompt a warning
    $scope.$watch(
      function () {
        return _.get($scope.preferences, 'model.anonymization', undefined);
      },
      function (newValue, oldValue) {
        var isCompanyAdmin = _.get($rootScope, 'Session.user.roles', []).includes(USER_ROLES.companyAdmin);
        if (
          isCompanyAdmin &&
          [EANONIMITY.NONE, EANONIMITY.DEV, EANONIMITY.DEVNTM].includes(oldValue) &&
          newValue === EANONIMITY.ALL
        ) {
          $swal({
            title: $translate.instant('ARE_YOU_SURE_YOU_WANT_TO_APPLY_THIS_CHANGE'),
            text: $translate.instant('ANONYMIZATION_SETTING_WARNING'),
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: $translate.instant('OK'),
            cancelButtonText: $translate.instant('CANCEL'),
            keyResolution: true,
          }).then(function (isConfirm) {
            if (!isConfirm) {
              $scope.preferences.model.anonymization = oldValue;
            }
          });
        }
      },
      true
    );

    if ($state.current.name == 'admin.company') {
      $scope.prefId = $scope.company._id;
    } else if ($state.current.name == 'admin.team') {
      $scope.prefId = $scope.team._id;
    }

    AdminApiService.getTeamList({ _cid: $scope.prefId }, null, { size: 50 }).then(function (teams) {
      $scope.teams = teams.data;
    });

    function initPreferences(val) {
      AdminApiService.removeLoading();
      /*$scope.company = $scope.editedCompany = val;
          if ($scope.company) {
            return getAdditionalData(val);
          }*/
      $scope.preferences = val;
      var shouldDisableFeedbackButton = false;

      if (_.get($scope.company.allowances, 'sensei.license') === 'disabled') {
        delete val.model.sensei;
        delete val.schema.properties.sensei;
      }
      if ($rootScope.Session.user.roles.indexOf(USER_ROLES.admin) < 0) {
        if (_.get(val, 'model.sensei.feedback.enabled') === true) {
          val.schema.properties.sensei.properties.feedback.properties.enabled.description =
            'Please contact your customer success manager to change this setting';
          shouldDisableFeedbackButton = true;
        } else if (val.schema.properties.sensei) {
          delete val.schema.properties.sensei.properties.feedback;
        }
        $scope.hideScwAdminControls = true;
      }
      // This value is deleted from the model to prevent the company preferences update from overriding
      // rule setting preferences that are stored in company preferences
      _.unset(val, 'model.sensei.defaultRuleSettingEnabledForCompanyAdmins');

      // AA: Ohhhh Almighty God! Please forgive me...
      // CW: Tried to rewrite using schema form add-on to use angular bootstrap switch but schema form add-on documentation sucks so I've left it as is for now but rewritten it to not use eval()
      setTimeout(function () {
        // NEED TO USE TIMEOUT TO COUNTERACT UNSTOPPABLE DEFAULT BEHAVIOR
        var all = angular.element('.modal-body .company_preferences input[type=checkbox]');
        if (shouldDisableFeedbackButton) {
          disableSenseifeedbackButton(all);
        }
        all.bootstrapSwitch({ size: 'mini' }).on('switchChange.bootstrapSwitch', function (e, state) {
          e.stopPropagation();
          e.preventDefault();
          var $this = this;
          var $el = $scope.preferences;
          setTimeout(function () {
            var ngModelStr = $this.getAttribute('ng-model');
            var attrs = ngModelStr.match(/model\['(.+)'\]\['(.+)'\]\['(.+)'\]/);
            $el.model[attrs[1]][attrs[2]][attrs[3]] = state;
            $scope.$apply();
          }, 100);
        });
      }, 0);
    }

    function disableSenseifeedbackButton(allCheckboxes) {
      var feedbackButton = _.find(allCheckboxes, function (element) {
        return element.attributes['ng-model'].value.endsWith("['sensei']['feedback']['enabled']");
      });
      _.pull(allCheckboxes, feedbackButton);
      $(feedbackButton).bootstrapSwitch({
        disabled: true,
        size: 'mini',
      });
    }

    $scope.save = function () {
      if (!$scope.ruleSettingsFormIsValid()) {
        $scope.showError = true;
        return;
      }
      var allSaveFunctions = getSenseiRulesetPromises();

      $q.all(allSaveFunctions)
        .then(function () {
          // not async because of concurrency (both affect company preferences)
          AdminApiService.updatePreferences($scope.prefId, $scope.preferences.model)
            .then(function () {
              $uibModalInstance.close($scope.editedUser);
            })
            .catch(function (response) {
              $translate(['ERROR_UPDATING_COMPANY_PREFERENCES']).then(function (translations) {
                ErrorHandler.addHttpError(translations.ERROR_UPDATING_COMPANY_PREFERENCES, response);
              });
            });
        })
        .catch(function (error) {
          $translate(['ERROR_UPDATING_COMPANY_PREFERENCES']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_UPDATING_COMPANY_PREFERENCES, error);
          });
        });
    };

    $scope.onUpdateFileLocation = function (ruleSetting) {
      ruleSetting.file = null;
      ruleSetting.filename = '';
      ruleSetting.fileid = '';
      $scope.onUpdateRuleSetting(ruleSetting);
    };

    AdminApiService.getPreferences($scope.prefId).then(initPreferences);
    getRuleSettings();

    //Rulesetting related code

    var ruleSettingsToPost = [];
    var ruleSettingsToRemove = [];

    $scope.ruleSettingsFormIsValid = function () {
      for (var i = 0; i < $scope.ruleSettings.length; i++) {
        if (
          !$scope.ruleSettings[i].name ||
          !($scope.ruleSettings[i].location || $scope.ruleSettings[i].file || $scope.ruleSettings[i].filename)
        ) {
          return false;
        }
      }
      return true;
    };

    function getSenseiRulesetPromises() {
      var allSaveFunctions = [];
      for (var i = 0; i < ruleSettingsToPost.length; i++) {
        var ruleSetting = cleanUpObject(ruleSettingsToPost[i]);
        if (ruleSetting.isDefaultRuleSetting) {
          allSaveFunctions.push(SenseiApiService.updateDefaultRuleSettings(ruleSetting));
        } else if (_.includes(ruleSettingsToRemove, ruleSetting)) {
          allSaveFunctions.push(SenseiApiService.deleteRulesetting(ruleSetting));
        } else if (ruleSetting._id) {
          allSaveFunctions.push(SenseiApiService.updateRuleSetting(ruleSetting));
        } else {
          ruleSetting._cid = $scope.company._id;
          allSaveFunctions.push(SenseiApiService.addNewRuleSetting(ruleSetting));
        }
      }
      return allSaveFunctions;
    }

    function cleanUpObject(ruleSetting) {
      delete ruleSetting.showLocationTabs;
      delete ruleSetting.locationStrategy;
      delete ruleSetting.oldLocation;
      delete ruleSetting.oldName;
      delete ruleSetting.showNameInput;
      if (ruleSetting.location) {
        ruleSetting.filename = '';
        ruleSetting.fileid = '';
      }
      if (ruleSetting.file) {
        ruleSetting.location = '';
        ruleSetting.fileid = '';
        ruleSetting.filename = '';
      }
      return ruleSetting;
    }

    $scope.onUpdateRuleSetting = function (ruleSetting) {
      if (ruleSettingsToPost.indexOf(ruleSetting) < 0) {
        ruleSettingsToPost.push(ruleSetting);
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    function getRuleSettings() {
      SenseiApiService.getRuleSettings($scope.company._id).then(function (result) {
        $translate(['SENSEI_RULESET_DEMO_RULES_BY_SCW', 'SENSEI_RULESET_HOSTED_BY_SCW']).then(function (translations) {
          result[0]._id = 'default';
          result[0].name = translations.SENSEI_RULESET_DEMO_RULES_BY_SCW;
          result[0].location = translations.SENSEI_RULESET_HOSTED_BY_SCW;
          result[0].isDefaultRuleSetting = true;
          result[0].isEditable = false;
          result[0]._cid = $scope.company._id;
          $scope.ruleSettings = result;
        });
      });
    }

    $scope.ruleIsEnabledForTeam = function (team, ruleSetting) {
      return ruleSetting.teamIds.indexOf(team._id) >= 0;
    };

    $scope.toggleRuleForTeam = function (team, ruleSetting) {
      $scope.onUpdateRuleSetting(ruleSetting);
      if ($scope.ruleIsEnabledForTeam(team, ruleSetting)) {
        return _.pull(ruleSetting.teamIds, team._id);
      }
      return ruleSetting.teamIds.push(team._id);
    };

    $scope.addNewRuleSetting = function () {
      $scope.ruleSettings.push({
        teamIds: [],
        enabledForCompanyAdmins: false,
        name: '',
        editName: true,
        location: '',
      });
    };

    $scope.deleteRuleSetting = function (ruleSetting) {
      _.pull($scope.ruleSettings, ruleSetting);
      if (!ruleSetting._id) {
        return _.pull(ruleSettingsToPost, ruleSetting);
      }
      $scope.onUpdateRuleSetting(ruleSetting);
      ruleSettingsToRemove.push(ruleSetting);
    };

    $scope.countries = [];
    _.map($rootScope.metadata.countries.all, function (each, index) {
      $scope.countries.push({ id: index, name: each.name });
    });
  },
]);
