import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import _ from 'lodash';

import $ from 'jquery';

import challengeMetrics from './challenge-metrics.html';

Chart.defaults.global.scaleFontFamily = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
Chart.defaults.global.tooltipFontFamily = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
Chart.defaults.global.tooltipTitleFontFamily = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

const app = angular.module(MODULE);

//var pointHighlightStroke = "#555";

var REFRESH_TIMER_INTERVAL = 5 * 60 * 1000; // 5 minutes

// Ensures we have consistent colouring for areas across users
// for readability
var ACTIVITY_COLORS = {
  LEARNING: '#016b94',
  TOURNAMENTS: '#3c9e40',
  TRAINING: '#F38B00',
  ASSESSMENTS: '#C9493A',
  COURSES: '#00BCD4',
  // "SENSEI": "#292140",
  // "METRICS": "#00BCD4",
  // "ADMINISTRATION": "#F0F0F0",
  // "OTHER": "#F44336"
};

var PANELS = {
  DASHBOARD: 'dashboard',
  LEADERBOARD: 'leaderboard',
  TIMESPENT: 'timeSpent',
  DISTRIBUTION: 'distribution',
  STRENGTHWEAKNESS: 'strengthWeakness',
};

var INNERCHARTS = {
  DASHBOARD: 'dashboard',
  LEADERBOARD: 'leaderboard',
  TIMESPENT: 'timeSpent',
  DISTRIBUTION: 'distribution',
  STRENGTHWEAKNESS: 'strengthWeakness',
};

var SECURITYMATURITY = {
  BEGINNER: 'Beginner',
  AWARE: 'Security Aware',
  SKILLED: 'Security Skilled',
  CHAMPION: 'Security Champion',
};

var TARGETTYPES = {
  DEVELOPER: 'developer',
  TEAM: 'team',
  COMPANY: 'company',
};

var PRESETS = {
  MYTEAM: 'My Team',
  MYORG: 'My Organisation',
  INDUSTRY: 'My Industry',
  MYTEAM_MYORG: 'My Team vs My Organisation',
  MYTEAM_INDUSTRY: 'My Team vs My Industry',
  MYORG_INDUSTRY: 'My Organisation vs My Industry',
  WEEK: 'Previous Week',
  MONTH: 'Previous Month',
  YEAR: 'Previous Year',
  FIVEYEARS: 'Previous 5 Years',
};

var INTERVALS = {
  DAYS: 'Days',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
};

var LEADERBOARDTYPES = {
  DEVELOPER: 'developer',
  TEAM: 'team',
};

var MATURITY_LEVEL_RULES = {
  champion: {
    timeSpent: 0,
    points: 12000,
    challenges: { attempted: 60, ratioCorrect: 0.9 },
  },
  skilled: {
    timeSpent: 0,
    points: 8000,
    challenges: { attempted: 40, ratioCorrect: 0.6 },
  },
  aware: {
    timeSpent: 0,
    points: 4000,
    challenges: { attempted: 20, ratioCorrect: 0.2 },
  },
  beginner: {
    timeSpent: 0,
    points: 0,
    challenges: { attempted: 0, ratioCorrect: 0 },
  },
};

function allDatesInRange(start, end) {
  if (moment(start).isSame(end, 'day')) return [start, start];

  var dates = [];

  while (moment(start).isSameOrBefore(end, 'day')) {
    dates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }
  return dates;
}

function sumArray(arr) {
  return arr.reduce(function (total, current) {
    return total + current;
  }, 0);
}

app.controller('ManagementMetricsController', [
  '$q',
  '$scope',
  '$rootScope',
  '$timeout',
  '$interval',
  '$log',
  '$state',
  '$stateParams',
  '$translate',
  '$uibModal',
  'AdminApiService',
  'ErrorHandler',
  'AuthService',
  'USER_ROLES',
  'Session',
  'UserPreferences',
  function (
    $q,
    $scope,
    $rootScope,
    $timeout,
    $interval,
    $log,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    AdminApiService,
    ErrorHandler,
    AuthService,
    USER_ROLES,
    Session,
    UserPreferences
  ) {
    $scope.PANELS = PANELS;
    $scope.INNERCHARTS = INNERCHARTS;
    $scope.SECURITYMATURITY = {
      BEGINNER: $translate.instant('BEGINNER'),
      AWARE: $translate.instant('SECURITY_AWARE'),
      SKILLED: $translate.instant('SECURITY_SKILLED'),
      CHAMPION: $translate.instant('SECURITY_CHAMPION'),
    };
    $scope.TARGETTYPES = TARGETTYPES;
    $scope.PRESETS = PRESETS;
    $scope.INTERVALS = INTERVALS;
    $scope.accordionCloseOthers = false;
    $scope.searchText = '';
    $scope.anonymise = false;
    $scope.viewStarted = new Date();
    $scope.removeSecurityMaturityTimespent = true;

    $scope.$on('$destroy', function () {
      $scope.stopTimer();
      $interval.cancel($scope.autoRefreshInterval);
      $(window).off('resize', resize);
    });

    $scope.stopTimer = function () {
      $timeout.cancel($scope._timerRestartTimeout);
      $interval.cancel($scope._interval);
      $scope.timeSpanRemaining = $scope.timeSpans;
      delete $scope._interval;
    };

    $scope.languageList = [
      {
        displayLanguage: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
        displayFramework: '',
        searchable: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
        language: {
          _id: 'all',
          _framework: 'all',
        },
      },
    ];

    // this is just used by the stats.html template to match against the translated value - fixes PORTAL-1899
    $scope.translatedAllLanguages = $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS');

    $scope.forms = {};
    $scope.forms.selectedLanguageFramework = $scope.languageList[0];

    $scope.MATURITY_LEVEL_RULES = MATURITY_LEVEL_RULES;

    let securityMaturityMetadata = $scope.metadata.constants.metrics.security_maturity.level;
    let MATURITY_LEVEL_REQS_HTML = {};
    MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.CHAMPION.id] =
      '<ul><li>' +
      $translate.instant('POINTS') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.CHAMPION.id].points +
      '</li><li>' +
      $translate.instant('ACCURACY') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.CHAMPION.id].challenges.ratioCorrect * 100 +
      '%</li><li>' +
      $translate.instant('CHALLENGES_ATTEMPTED') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.CHAMPION.id].challenges.attempted +
      '</li></ul>';

    MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.SKILLED.id] =
      '<ul><li>' +
      $translate.instant('POINTS') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.SKILLED.id].points +
      '</li><li>' +
      $translate.instant('ACCURACY') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.SKILLED.id].challenges.ratioCorrect * 100 +
      '%</li><li>' +
      $translate.instant('CHALLENGES_ATTEMPTED') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.SKILLED.id].challenges.attempted +
      '</li></ul>';

    MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.AWARE.id] =
      '<ul><li>' +
      $translate.instant('POINTS') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.AWARE.id].points +
      '</li><li>' +
      $translate.instant('ACCURACY') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.AWARE.id].challenges.ratioCorrect * 100 +
      '%</li><li>' +
      $translate.instant('CHALLENGES_ATTEMPTED') +
      ': ' +
      MATURITY_LEVEL_RULES[securityMaturityMetadata.AWARE.id].challenges.attempted +
      '</li></ul>';

    MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.BEGINNER.id] =
      '<ul><li>' + $translate.instant('NO_REQUIREMENTS') + '</li></ul>';

    let SECURITY_MATURITY_TEXT = {};
    SECURITY_MATURITY_TEXT[securityMaturityMetadata.BEGINNER.id] = $translate.instant('BEGINNER');
    SECURITY_MATURITY_TEXT[securityMaturityMetadata.AWARE.id] = $translate.instant('SECURITY_AWARE');
    SECURITY_MATURITY_TEXT[securityMaturityMetadata.SKILLED.id] = $translate.instant('SECURITY_SKILLED');
    SECURITY_MATURITY_TEXT[securityMaturityMetadata.CHAMPION.id] = $translate.instant('SECURITY_CHAMPION');

    let PROGRESS_STATS_CAROUSEL = [
      {
        id: securityMaturityMetadata.BEGINNER.id,
        name: SECURITY_MATURITY_TEXT[securityMaturityMetadata.BEGINNER.id],
        points: { required: MATURITY_LEVEL_RULES.beginner.points },
        accuracy: { required: MATURITY_LEVEL_RULES.beginner.challenges.ratioCorrect * 100 },
        attempted: { required: MATURITY_LEVEL_RULES.beginner.challenges.attempted },
      },
      {
        id: securityMaturityMetadata.AWARE.id,
        name: SECURITY_MATURITY_TEXT[securityMaturityMetadata.AWARE.id],
        points: { required: MATURITY_LEVEL_RULES.aware.points },
        accuracy: { required: MATURITY_LEVEL_RULES.aware.challenges.ratioCorrect * 100 },
        attempted: { required: MATURITY_LEVEL_RULES.aware.challenges.attempted },
      },
      {
        id: securityMaturityMetadata.SKILLED.id,
        name: SECURITY_MATURITY_TEXT[securityMaturityMetadata.SKILLED.id],
        points: { required: MATURITY_LEVEL_RULES.skilled.points },
        accuracy: { required: MATURITY_LEVEL_RULES.skilled.challenges.ratioCorrect * 100 },
        attempted: { required: MATURITY_LEVEL_RULES.skilled.challenges.attempted },
      },
      {
        id: securityMaturityMetadata.CHAMPION.id,
        name: SECURITY_MATURITY_TEXT[securityMaturityMetadata.CHAMPION.id],
        points: { required: MATURITY_LEVEL_RULES.champion.points },
        accuracy: { required: MATURITY_LEVEL_RULES.champion.challenges.ratioCorrect * 100 },
        attempted: { required: MATURITY_LEVEL_RULES.champion.challenges.attempted },
      },
    ];

    $scope.securityMaturityRequirementsHtml =
      '<p><b>' +
      SECURITY_MATURITY_TEXT[securityMaturityMetadata.BEGINNER.id] +
      '</b></p>' +
      MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.BEGINNER.id] +
      '<p><b>' +
      SECURITY_MATURITY_TEXT[securityMaturityMetadata.AWARE.id] +
      '</b></p>' +
      MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.AWARE.id] +
      '<p><b>' +
      SECURITY_MATURITY_TEXT[securityMaturityMetadata.SKILLED.id] +
      '</b></p>' +
      MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.SKILLED.id] +
      '<p><b>' +
      SECURITY_MATURITY_TEXT[securityMaturityMetadata.CHAMPION.id] +
      '</b></p>' +
      MATURITY_LEVEL_REQS_HTML[securityMaturityMetadata.CHAMPION.id];

    $scope.deltaDurations = [
      { text: $translate.instant('LAST_DAY'), value: 86400000 },
      { text: $translate.instant('LAST_7_DAYS'), value: 604800000 },
      { text: $translate.instant('LAST_30_DAYS'), value: 2592000000 },
    ];

    $scope.deltaDurationData = {
      duration: UserPreferences.get('metrics.deltaDurationData', $scope.deltaDurations[1]),
    };

    $scope.deltaDurationData.duration = _.find($scope.deltaDurations, function (elem) {
      return elem.value === $scope.deltaDurationData.duration.value;
    });

    $scope.$watch('deltaDurationData.duration', function (newVal, oldVal) {
      if (newVal != oldVal) {
        $scope.getLeaderboard(
          $scope.selectedTargets.t1,
          LEADERBOARDTYPES.DEVELOPER,
          $scope.sorting.sortdevLeaderboardByParam,
          $scope.searchText
        );
        $scope.changeDuration();
      }
    });

    $('.management-css').prop('disabled', false);

    $scope.selectedTargets = {};
    $scope.dateRange = {};
    $scope.meIndex = -1; // index into $scope.availableTargets of my user - testing only - will be irrelevant for real team managers/company admins
    $scope.myTeamIndex = -1; // index into $scope.availableTargets of my team
    $scope.myOrgIndex = -1; // index into $scope.availableTargets of my company

    $scope.languageMap = {}; // combined map of historic languages for selected target(s) from metrics and active/available languages for company/standalone team

    // only company admin and above can see team leaderboard
    $scope.isAllowedTeamLeaderboard = AuthService.isAuthorized([
      USER_ROLES.companyAdmin,
      USER_ROLES.reseller,
      USER_ROLES.admin,
    ]);

    //This workaround has been added to cater to a cache not being refreshed for the user object
    $scope.statsMaturity = _.has(Session.user.properties.preferences, 'statistics.securityMaturity.enabled')
      ? Session.user.properties.preferences.statistics.securityMaturity.enabled
      : true;

    function processTarget(target) {
      if (target.type == TARGETTYPES.DEVELOPER) {
        target.displayType = 'Developer';
        var tmpName =
          target.properties.profile.name.first +
          (target.properties.profile.name.middle ? ' ' + target.properties.profile.name.middle : '') +
          ' ' +
          target.properties.profile.name.last;
        tmpName = tmpName.trim();
        target.displayName = tmpName || target.email;
      } else if (target.type == TARGETTYPES.TEAM) {
        target.displayType = 'Team';
        target.displayName = target.name;
      } else if (target.type == TARGETTYPES.COMPANY) {
        target.displayType = 'Company';
        target.displayName = target.name;
      }
    }

    $scope.updateLanguageList = function (languageData) {
      // update language list - data structure is different to gameData.languages
      var languageList = languageData;
      for (var i = 0; i < languageList.length; i++) {
        var language = languageList[i];
        language.language = {
          _id: language._id, // making data consistent with data in gameData.languages
          _framework: language._framework,
        };
        if ($scope.metadata.languages[language.language._id]) {
          if ($scope.languageMap[language.language._id + language.language._framework]) {
            continue;
          } else {
            $scope.languageMap[language.language._id + language.language._framework] = true;
            language.displayLanguage = $scope.metadata.languages[language.language._id].name;
            language.displayFramework =
              $scope.metadata.languages[language.language._id].framework[language.language._framework].name;
            language.searchable = `${language.displayLanguage} ${language.displayFramework}`;
            $scope.languageList.push(language);
          }
        }
      }

      $scope.languageList.sort(function (a, b) {
        if (a.language._id == 'all') {
          return -1; // always put all:all at the top
        }
        var aConcatLanguageFramework = a.language._id + ':' + a.language._framework;
        var bConcatLanguageFramework = b.language._id + ':' + b.language._framework;
        if (aConcatLanguageFramework < bConcatLanguageFramework) {
          return -1;
        }
        if (aConcatLanguageFramework > bConcatLanguageFramework) {
          return 1;
        }
        return 0;
      });
    };

    $scope.initState = function () {
      $scope.selectedTargets = {};
      $scope.shownPanel = $stateParams.panel ? $stateParams.panel : PANELS.DASHBOARD;

      $scope.challengeMetricsTempl = challengeMetrics;

      $scope
        .getMetricsTargets()
        .then(function (data) {
          if (!data) return;

          $scope.availableTargetsData = data;
          $scope.availableTargets = [];

          var availableTargetsCount = 0;
          for (var i = $scope.availableTargetsData.length - 1; i >= 0; i--) {
            var targetData = $scope.availableTargetsData[i];
            if (targetData.type === TARGETTYPES.COMPANY) {
              //The Anonymisation property of the company only would be considered
              //Standalone teams if any will default to false
              $scope.anonymise = targetData.anonymise;
            }
            if ($scope.shownPanel == PANELS.DISTRIBUTION && targetData.type == TARGETTYPES.DEVELOPER) continue;

            for (var j = 0; j < targetData.children.length; j++) {
              var target = targetData.children[j];
              target.type = targetData.type;

              if (targetData.type == TARGETTYPES.DEVELOPER) {
                target.displayType = 'Developer';
                var tmpName =
                  target.properties.profile.name.first +
                  (target.properties.profile.name.middle ? ' ' + target.properties.profile.name.middle : '') +
                  ' ' +
                  target.properties.profile.name.last;
                tmpName = tmpName.trim();
                target.displayName = tmpName || target.email;
                //target.displayNameWithEmail = target.properties.profile.name.first + ' ' + target.properties.profile.name.middle + ' ' + target.properties.profile.name.last + ' (' + target.email + ')';
              } else if (targetData.type == TARGETTYPES.TEAM) {
                target.displayType = 'Team';
                target.displayName = target.name;
              } else if (targetData.type == TARGETTYPES.COMPANY) {
                target.displayType = 'Company';
                target.displayName = target.name;
              }

              if (target.me) $scope.meIndex = availableTargetsCount;
              if (target.myTeam) $scope.myTeamIndex = availableTargetsCount;
              if (target.myCompany) $scope.myOrgIndex = availableTargetsCount;

              $scope.availableTargets.push(target);
              availableTargetsCount++;
            }
          }

          if (!$stateParams.interval && !$stateParams.from && !$stateParams.to) {
            $scope.selectedPresetTime = PRESETS.MONTH;
          }

          for (var k = 0; k < $scope.availableTargets.length; k++) {
            var checkTarget = $scope.availableTargets[k];

            if ($stateParams.t1 && $stateParams.t1 == checkTarget._id) {
              $scope.forms.t1 = checkTarget;
              $scope.selectedTargets.t1 = checkTarget;
            }
            if ($stateParams.t2 && $stateParams.t2 == checkTarget._id) {
              $scope.forms.t2 = checkTarget;
              $scope.selectedTargets.t2 = checkTarget;
            }
          }

          if ($stateParams.t2 && !$scope.selectedTargets.t2) {
            AdminApiService.addLoading();
            AdminApiService.getMetricsTarget($stateParams.t2)
              .then(function (data) {
                $scope.forms.t2 = data;
                $scope.selectedTargets.t2 = data;
                processTarget(data);
              })
              .catch(function (response) {
                ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TARGET'), response);
              })
              .finally(function () {
                AdminApiService.removeLoading();
              });
          }

          if ($stateParams.t1 && !$scope.selectedTargets.t1) {
            AdminApiService.addLoading();
            return AdminApiService.getMetricsTarget($stateParams.t1)
              .then(function (data) {
                $scope.forms.t1 = data;
                $scope.selectedTargets.t1 = data;
                processTarget(data);
              })
              .catch(function (response) {
                ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TARGET'), response);
              })
              .finally(function () {
                AdminApiService.removeLoading();
              });
          } else {
            // noop promise
            return $q(function (resolve) {
              resolve();
            });
          }
        })
        .then(function (_data) {
          // default to My Organisation, then My Team, then first available target
          if (!$scope.selectedTargets.t1) {
            if (AuthService.isAuthorized(USER_ROLES.companyAdmin)) {
              if ($scope.myOrgIndex > -1) {
                $scope.forms.t1 = $scope.availableTargets[$scope.myOrgIndex];
                $scope.selectedTargets.t1 = $scope.availableTargets[$scope.myOrgIndex];
                $scope.selectedPreset = PRESETS.MYORG;
              }
            } else if (AuthService.isAuthorized(USER_ROLES.manager)) {
              if ($scope.myTeamIndex > -1) {
                $scope.forms.t1 = $scope.availableTargets[$scope.myTeamIndex];
                $scope.selectedTargets.t1 = $scope.availableTargets[$scope.myTeamIndex];
                $scope.selectedPreset = PRESETS.MYTEAM;
              }
            } else if ($scope.availableTargets && $scope.availableTargets[0]) {
              $scope.forms.t1 = $scope.availableTargets[0];
              $scope.selectedTargets.t1 = $scope.availableTargets[0];
            }
          }

          $scope.dateRange.statsInterval = $stateParams.interval ? $stateParams.interval : INTERVALS.DAYS;
          $scope.dateRange.toDate = $stateParams.to ? $stateParams.to : moment().format('D MMM YYYY');
          $scope.dateRange.fromDate = $stateParams.from
            ? $stateParams.from
            : moment().subtract({ months: 1 }).add({ days: 1 }).format('D MMM YYYY');

          $scope.xSeriesTimeLabels = $scope.generateDateLabels($scope.dateRange.fromDate, $scope.dateRange.toDate);

          $scope.comparisonSeries = [];
          $scope.strengthWeaknessOverTimeData = [];
          $scope.myStrengthWeaknessData = [];
          $scope.strengthWeaknessData = [];
          $scope.averageHoursOverTimeData = [];
          $scope.totalHoursOverTimeData = [];
          $scope.averageHoursData = [];
          $scope.averageHoursPercentData = [];
          $scope.totalHoursData = [];
          $scope.totalHoursPercentData = [];
          $scope.fullTotalHoursDisplay = [];
          $scope.averagePointsData = [];
          $scope.averagePointsPercentData = [];
          $scope.averagePointsOverTimeData = [];
          $scope.distributionData = [];
          $scope.distributionOverTimeData = [];
          $scope.myDistributionData = [];
          $scope.playerStats = [];

          if ($scope.selectedTargets.t1) $scope.comparisonSeries.push($scope.selectedTargets.t1.displayName);
          if ($scope.selectedTargets.t2) $scope.comparisonSeries.push($scope.selectedTargets.t2.displayName);

          if ($scope.selectedTargets.t1) {
            // fetch target properties
            var target = $scope.selectedTargets.t1;
            if (target.type === 'developer' && !target._id) {
              target._id = $stateParams.t1; // when anonymised
            }
            return $scope.getTargetProperties(target);
          } else {
            // noop promise
            return $q(function (resolve) {
              resolve();
            });
          }
        })
        .then(function (data) {
          if (!data) return;

          // init language and framework list based on t1 played languages
          var targetLangList = [];
          for (var targetLangIndex = 0; targetLangIndex < data.length; targetLangIndex++) {
            var targetLang = data[targetLangIndex];

            // workaround for unmigrated metrics data
            if (targetLang._id == 'java' && targetLang._framework == 'j2ee') {
              targetLang._framework = 'ee';
            }

            targetLangList.push({
              language: targetLang,
              displayLanguage: $scope.metadata.languages[targetLang._id].name,
              displayFramework: $scope.metadata.languages[targetLang._id].framework[targetLang._framework].name,
              searchable: `${$scope.metadata.languages[targetLang._id].name} ${
                $scope.metadata.languages[targetLang._id].framework[targetLang._framework].name
              }`,
            });
            $scope.languageMap[targetLang._id + targetLang._framework] = true;
          }

          if (targetLangList.length > 0) {
            $scope.languageList = targetLangList;
          }

          if (!_.find(targetLangList, (l) => l.language._id == 'all')) {
            targetLangList.unshift({
              displayLanguage: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
              displayFramework: '',
              searchable: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
              language: {
                _id: 'all',
                _framework: 'all',
              },
            });
          }

          // add company or standalone team licensed languages to list
          if ($scope.myOrgIndex > -1 && $scope.availableTargets[$scope.myOrgIndex].languages) {
            var companyLanguages = $scope.availableTargets[$scope.myOrgIndex].languages;
            $scope.updateLanguageList(companyLanguages);
          } else if ($scope.myTeamIndex > -1 && $scope.availableTargets[$scope.myTeamIndex].languages) {
            var teamLanguages = $scope.availableTargets[$scope.myTeamIndex].languages;
            $scope.updateLanguageList(teamLanguages);
          }

          // default to all
          if (!$stateParams.language || !$stateParams.framework) {
            $stateParams.language = 'all';
            $stateParams.framework = 'all';
          }

          // find stateparam language and framework in ui-select, populate selection
          for (var z = 0; z < $scope.languageList.length; z++) {
            if (
              $scope.languageList[z].language._id == $stateParams.language &&
              $scope.languageList[z].language._framework == $stateParams.framework
            ) {
              $scope.forms.selectedLanguageFramework = $scope.languageList[z];
              break;
            }
          }

          // fetch and merge t2 played languages if selected
          if ($scope.selectedTargets.t2) {
            // fetch target properties
            var target = $scope.selectedTargets.t2;
            if (target.type === 'developer' && !target._id) {
              target._id = $stateParams.t2; // when anonymised
            }
            $scope.getTargetProperties($scope.selectedTargets.t2).then(function (data) {
              $scope.updateLanguageList(data);
            });
          }

          // update panels
          $scope.stopTimer();
          if ($scope.shownPanel == PANELS.DASHBOARD) {
            $scope.updateDashboardPanel();
          } else if ($scope.shownPanel == PANELS.LEADERBOARD) {
            $scope.updateLeaderboardPanel();
          } else if ($scope.shownPanel == PANELS.STRENGTHWEAKNESS) {
            $scope.updateStrengthWeaknessPanel();
          } else if ($scope.shownPanel == PANELS.DISTRIBUTION) {
            $scope.updateSecurityMaturityPanel();
          } else if ($scope.shownPanel == PANELS.TIMESPENT) {
            $scope.updateTimeSpentPanel();
          }

          $timeout(function () {
            resize();
          }, 1000);
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_METRICS'), response);
        });
    };

    // update dashboard panel
    $scope.updateDashboardPanel = function () {
      $translate(['MINUTES', 'ERROR_RETRIEVING_METRICS']).then(function (translations) {
        // Activity Chart Options
        $scope.activityform = {
          start: moment().subtract({ months: 1 }).add({ days: 1 }).toDate(),
          finish: moment().toDate(),
        };
        $scope.datepickerOptions = {
          minDate: new Date('2000-01-01'),
          initDate: new Date(),
        };
        $scope.activityChart = {};
        $scope.activityChart.options = {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: translations.MINUTES,
                  fontSize: 14,
                  fontColor: '#FFF',
                },
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: '#FFF',
                  beginAtZero: true,
                  fontSize: 14,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: '#FFF',
                  fontSize: 14,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var value = data.datasets[0].data[tooltipItem.index];
                var result = moment.duration(value, 'minutes').humanize();
                if (value >= 60) result += ' (' + value.toLocaleString() + ' ' + translations.MINUTES + ')';
                return result;
              },
            },
            bodyFontSize: 14,
            titleFontSize: 14,
          },
          title: {
            display: false,
          },
          layout: {
            padding: 30,
          },
        };

        if ($scope.selectedTargets.t1) {
          if ($scope.selectedTargets.t1.type === TARGETTYPES.DEVELOPER) {
            $scope
              .getPlayerData($scope.selectedTargets.t1)
              .then(function (data) {
                $scope.updateSWData(data.strengthsAndWeaknesses, null);
                $scope.updateSWCharts();
                $scope.updateTimeSpentData(data.timeSpent, null);
                $scope.initialiseActivityData(data.activity, null);
                $scope.updateTimeSpentCharts();
                $scope.updateSecurityMaturityData(
                  {
                    points: data.points,
                    challenges: data.challenges,
                    securityMaturity: data.securityMaturity,
                    currentRank: data.leaderboard.rank,
                    previousRank: data.leaderboard.previousRank,
                    totalCount: data.leaderboard.totalCount,
                    hints: data.hints,
                    attempts: data.attempts,
                  },
                  null
                );
                $scope.updateSecurityMaturityCharts();
                $scope.updateQuestProgressData(data.questProgress, null);
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
              });
          } else {
            $scope
              .getStrengthWeaknessData($scope.selectedTargets.t1)
              .then(function (data) {
                $scope.updateSWData(data, null);
                $scope.updateSWCharts();
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
              });

            /*
                        $scope.getTimeSpentData($scope.selectedTargets.t1)
                            .then(function(data) {
                                $scope.updateTimeSpentData(data, null);
                                $scope.updateTimeSpentCharts();
                            })
                            .catch(function(response){
                                ErrorHandler.addHttpError("Error retrieving metrics", response);
                            });
                        */

            $scope
              .getSecurityMaturityData($scope.selectedTargets.t1)
              .then(function (data) {
                $scope.updateSecurityMaturityData(data, null);
                $scope.updateSecurityMaturityCharts();
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
              });
          }

          $scope.myTeamSize = $scope.availableTargets.length; // TODO
          $scope.myTeamSizePercent = ($scope.myTeamSize / 50) * 100; // fixed max of 50 for now - TODO get max from backend when API supports it

          // no way to get points yet
          //$scope.myAveragePoints = rand2400();
          //$scope.myAveragePointsPercent = $scope.myAveragePoints / 2400 * 100;

          // leaderboard
          $scope.refreshLeaderboard();
        }

        $timeout(function () {
          $scope.shownInnerChart = INNERCHARTS.DASHBOARD;
        }, 200);
      });
    };

    // Open modal to export user 'time spent' stats
    $scope.openActivityExportModal = function (_data) {
      var activityExportModal = $uibModal.open({
        templateUrl: 'ActivityExportModal.html',
        controller: 'ActivityExportModal',
        size: 'lg',
        backdrop: 'static',
        scope: $scope,
      });

      activityExportModal.result
        .then(function () {
          $log.debug('opened');
        })
        .catch(function (error) {
          $log.debug('error:', error);
        });
    };

    $scope.goToMetrics = function () {
      window.location.href = '/#/reporting/onboarding-and-engagement';
    };

    function handleCSVData(data) {
      if (data.url) {
        var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
        $translate(
          [
            'CSV_REPORT_READY',
            'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
          ],
          { dataUrl: data.url }
        ).then(function (translations) {
          if (window.navigator.msSaveBlob && isIE) {
            // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
            swal({
              title: translations.CSV_REPORT_READY,
              text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
              type: 'success',
              html: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
          } else {
            document.location.href = data.url;
            swal.close();
          }
        });
      } else {
        $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
          function (translations) {
            swal({
              title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
              text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
              type: 'success',
              html: true,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: translations.OK,
            });
          }
        );
      }
    }

    $scope.userActivitySummary = function (_user) {
      var from = moment($scope.activityform.start).startOf('day').format();
      var to = moment($scope.activityform.finish).endOf('day').format();
      var timezone = moment.tz.guess();
      AdminApiService.getUserActivitySummary(_user, from, to, timezone).then(function (data) {
        handleCSVData(data);
      });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.teamActivitySummary = function (_tid) {
      var from = moment($scope.activityform.start).startOf('day').format();
      var to = moment($scope.activityform.finish).endOf('day').format();
      var timezone = moment.tz.guess();
      AdminApiService.getTeamActivitySummary(_tid, from, to, timezone).then(function (data) {
        handleCSVData(data);
      });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.companyActivitySummary = function (_cid) {
      var from = moment($scope.activityform.start).startOf('day').format();
      var to = moment($scope.activityform.finish).endOf('day').format();
      var timezone = moment.tz.guess();
      AdminApiService.getCompanyActivitySummary(_cid, from, to, timezone).then(function (data) {
        handleCSVData(data);
      });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    // update leaderboard panel
    // not currently used - leaderboard is currently embedded in dashboard panel

    $scope.updateLeaderboardPanel = function () {
      // no way to get points or leaderboard yet
      //$scope.myAveragePoints = rand2400();
      //$scope.myAveragePointsPercent = $scope.myAveragePoints / 2400 * 100;

      $timeout(function () {
        $scope.shownInnerChart = INNERCHARTS.LEADERBOARD;
      }, 200);
    };

    // update strength weakness panel
    $scope.updateStrengthWeaknessPanel = function () {
      $translate(['ERROR_RETRIEVING_METRICS']).then(function (translations) {
        if ($scope.selectedTargets.t1 && $scope.selectedTargets.t2) {
          $q.all([
            $scope.getStrengthWeaknessData($scope.selectedTargets.t1),
            $scope.getStrengthWeaknessData($scope.selectedTargets.t2),
          ])
            .then(function (values) {
              var t1SW = values[0];
              var t2SW = values[1];
              $scope.updateSWData(t1SW, t2SW);
              $scope.updateSWCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $q.all([
            $scope.getStrengthWeaknessOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
            $scope.getStrengthWeaknessOverTimeData(
              $scope.selectedTargets.t2,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
          ])
            .then(function (values) {
              var t1SWOverTime = values[0];
              var t2SWOverTime = values[1];
              $scope.updateSWOverTimeData(t1SWOverTime, t2SWOverTime);
              $scope.updateSWOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        } else if ($scope.selectedTargets.t1) {
          $scope
            .getStrengthWeaknessData($scope.selectedTargets.t1)
            .then(function (data) {
              $scope.updateSWData(data, null);
              $scope.updateSWCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $scope
            .getStrengthWeaknessOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            )
            .then(function (data) {
              $scope.updateSWOverTimeData(data, null);
              $scope.updateSWOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        }
      });

      $timeout(function () {
        $scope.shownInnerChart = INNERCHARTS.STRENGTHWEAKNESS;
        createSwTimeSlider();
        $scope.startTimer();
      }, 200);
    };

    // update security maturity/distribution panel
    $scope.updateSecurityMaturityPanel = function () {
      $translate(['ERROR_RETRIEVING_METRICS']).then(function (translations) {
        if ($scope.selectedTargets.t1 && $scope.selectedTargets.t2) {
          $q.all([
            $scope.getSecurityMaturityData($scope.selectedTargets.t1),
            $scope.getSecurityMaturityData($scope.selectedTargets.t2),
          ])
            .then(function (values) {
              var t1Maturity = values[0];
              var t2Maturity = values[1];
              $scope.updateSecurityMaturityData(t1Maturity, t2Maturity);
              $scope.updateSecurityMaturityCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $q.all([
            $scope.getSecurityMaturityOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
            $scope.getSecurityMaturityOverTimeData(
              $scope.selectedTargets.t2,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
          ])
            .then(function (values) {
              var t1MaturityOverTime = values[0];
              var t2MaturityOverTime = values[1];
              $scope.updateSecurityMaturityOverTimeData(t1MaturityOverTime, t2MaturityOverTime);
              $scope.updateSecurityMaturityOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        } else if ($scope.selectedTargets.t1) {
          $scope
            .getSecurityMaturityData($scope.selectedTargets.t1)
            .then(function (data) {
              $scope.updateSecurityMaturityData(data, null);
              $scope.updateSecurityMaturityCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $scope
            .getSecurityMaturityOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            )
            .then(function (data) {
              $scope.updateSecurityMaturityOverTimeData(data, null);
              $scope.updateSecurityMaturityOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        }
      });

      $timeout(function () {
        $scope.shownInnerChart = INNERCHARTS.DISTRIBUTION;
      }, 200);
    };

    // update time spent panel
    $scope.updateTimeSpentPanel = function () {
      $translate(['ERROR_RETRIEVING_METRICS']).then(function (translations) {
        if ($scope.selectedTargets.t1 && $scope.selectedTargets.t2) {
          $q.all([
            $scope.getTimeSpentData($scope.selectedTargets.t1),
            $scope.getTimeSpentData($scope.selectedTargets.t2),
          ])
            .then(function (values) {
              var t1TimeSpent = values[0];
              var t2TimeSpent = values[1];
              $scope.updateTimeSpentData(t1TimeSpent, t2TimeSpent);
              $scope.updateTimeSpentCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $q.all([
            $scope.getTimeSpentOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
            $scope.getTimeSpentOverTimeData(
              $scope.selectedTargets.t2,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            ),
          ])
            .then(function (values) {
              var t1TimeSpentOverTime = values[0];
              var t2TimeSpentOverTime = values[1];
              $scope.updateTimeSpentOverTimeData(t1TimeSpentOverTime, t2TimeSpentOverTime);
              $scope.updateTimeSpentOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        } else if ($scope.selectedTargets.t1) {
          $scope
            .getTimeSpentData($scope.selectedTargets.t1)
            .then(function (data) {
              $scope.updateTimeSpentData(data, null);
              $scope.updateTimeSpentCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });

          $scope
            .getTimeSpentOverTimeData(
              $scope.selectedTargets.t1,
              $scope.dateRange.fromDate,
              $scope.dateRange.toDate,
              $scope.dateRange.statsInterval
            )
            .then(function (data) {
              $scope.updateTimeSpentOverTimeData(data, null);
              $scope.updateTimeSpentOverTimeCharts();
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_METRICS, response);
            });
        }
      });

      $timeout(function () {
        $scope.shownInnerChart = INNERCHARTS.TIMESPENT;
      }, 200);
    };

    // update URL params
    $scope.updateURL = function () {
      $rootScope.metricsPrevStateParams = _.cloneDeep($stateParams);

      var stateParams = {
        panel: $scope.shownPanel,
        t1: $scope.forms.t1._id,
        t2: null,
        interval: $scope.dateRange.statsInterval,
        from: moment(new Date($scope.dateRange.fromDate)).format('D MMM YYYY'),
        to: moment(new Date($scope.dateRange.toDate)).format('D MMM YYYY'),
        language: $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language._id : null,
        framework: $scope.forms.selectedLanguageFramework
          ? $scope.forms.selectedLanguageFramework.language._framework
          : null,
      };
      if ($scope.forms.t2)
        //:
        stateParams.t2 = $scope.forms.t2._id;

      $state.go('metrics', stateParams, { reload: false });
    };

    $scope.selectDeveloperRow = function (developer) {
      $scope.forms.t1 = {
        _id: developer._player,
        type: TARGETTYPES.DEVELOPER,
      };
      $scope.updateURL();
    };

    $scope.selectTeamRow = function (team) {
      $scope.forms.t1 = {
        _id: team._team,
        type: TARGETTYPES.TEAM,
      };
      $scope.updateURL();
    };

    // preset reports
    $scope.presetReport = function (preset, forceURLUpdate) {
      $translate([
        'OK',
        'ERROR',
        'COMING_SOON',
        'MY_TEAM_NOT_FOUND',
        'MY_ORGANISATION_NOT_FOUND',
        'INDUSTRY_METRICS_COMING_SOON',
      ]).then(function (translations) {
        if (preset == PRESETS.MYTEAM) {
          $scope.selectedPreset = PRESETS.MYTEAM;
          if ($scope.myTeamIndex > -1) {
            $scope.forms.t1 = $scope.availableTargets[$scope.myTeamIndex];
          } else {
            swal({
              title: translations.ERROR,
              text: translations.MY_TEAM_NOT_FOUND,
              type: 'error',
              confirmButtonText: translations.OK,
            });
          }
          delete $scope.forms.t2;
        } else if (preset == PRESETS.MYORG) {
          $scope.selectedPreset = PRESETS.MYORG;
          if ($scope.myOrgIndex > -1) {
            $scope.forms.t1 = $scope.availableTargets[$scope.myOrgIndex];
          } else {
            swal({
              title: translations.ERROR,
              text: translations.MY_ORGANISATION_NOT_FOUND,
              type: 'error',
              confirmButtonText: translations.OK,
            });
          }
          delete $scope.forms.t2;
        } else if (preset == PRESETS.INDUSTRY) {
          $scope.selectedPreset = PRESETS.INDUSTRY;
          swal({
            title: translations.COMING_SOON,
            text: translations.INDUSTRY_METRICS_COMING_SOON,
            type: 'info',
            confirmButtonText: translations.OK,
          });
        } else if (preset == PRESETS.MYTEAM_MYORG) {
          $scope.selectedPreset = PRESETS.MYTEAM_MYORG;
          if ($scope.myTeamIndex > -1) {
            $scope.forms.t1 = $scope.availableTargets[$scope.myTeamIndex];
          } else {
            swal({
              title: translations.ERROR,
              text: translations.MY_TEAM_NOT_FOUND,
              type: 'error',
              confirmButtonText: translations.OK,
            });
          }

          if ($scope.myOrgIndex > -1) {
            $scope.forms.t2 = $scope.availableTargets[$scope.myOrgIndex];
          } else {
            swal({
              title: translations.ERROR,
              text: translations.MY_ORGANISATION_NOT_FOUND,
              type: 'error',
              confirmButtonText: translations.OK,
            });
          }
        } else if (preset == PRESETS.MYTEAM_INDUSTRY) {
          $scope.selectedPreset = PRESETS.MYTEAM_INDUSTRY;
          //$scope.forms.t1 = $scope.availableTargets[$scope.myTeamIndex];
          //$scope.forms.t2 = $scope.availableTargets[2];
          swal({
            title: translations.COMING_SOON,
            text: translations.INDUSTRY_METRICS_COMING_SOON,
            type: 'info',
            confirmButtonText: translations.OK,
          });
        } else if (preset == PRESETS.MYORG_INDUSTRY) {
          $scope.selectedPreset = PRESETS.MYORG_INDUSTRY;
          //$scope.forms.t1 = $scope.availableTargets[$scope.myOrgIndex];
          //$scope.forms.t2 = $scope.availableTargets[2];
          swal({
            title: translations.COMING_SOON,
            text: translations.INDUSTRY_METRICS_COMING_SOON,
            type: 'info',
            confirmButtonText: translations.OK,
          });
        } else if (preset == PRESETS.WEEK) {
          $scope.selectedPresetTime = PRESETS.WEEK;
          $scope.dateRange.statsInterval = INTERVALS.DAYS;
          $scope.dateRange.toDate = moment().format('D MMM YYYY');
          $scope.dateRange.fromDate = moment().subtract({ weeks: 1 }).add({ days: 1 }).format('D MMM YYYY');
        } else if (preset == PRESETS.MONTH) {
          $scope.selectedPresetTime = PRESETS.MONTH;
          $scope.dateRange.statsInterval = INTERVALS.DAYS;
          $scope.dateRange.toDate = moment().format('D MMM YYYY');
          $scope.dateRange.fromDate = moment().subtract({ months: 1 }).add({ days: 1 }).format('D MMM YYYY');
        } else if (preset == PRESETS.YEAR) {
          $scope.selectedPresetTime = PRESETS.YEAR;
          $scope.dateRange.statsInterval = INTERVALS.WEEKS;
          $scope.dateRange.toDate = moment().format('D MMM YYYY');
          $scope.dateRange.fromDate = moment().subtract({ years: 1 }).add({ days: 1 }).format('D MMM YYYY');
        } else if (preset == PRESETS.FIVEYEARS) {
          $scope.selectedPresetTime = PRESETS.FIVEYEARS;
          $scope.dateRange.statsInterval = INTERVALS.MONTHS;
          $scope.dateRange.toDate = moment().format('D MMM YYYY');
          $scope.dateRange.fromDate = moment().subtract({ years: 5 }).add({ days: 1 }).format('D MMM YYYY');
        }

        if (forceURLUpdate) $scope.updateURL();
      });
    };

    // leaderboard
    $scope.refreshLeaderboard = function () {
      if ($scope.selectedTargets.t1.type != TARGETTYPES.DEVELOPER) {
        if ($scope.selectedTargets.t1.type == TARGETTYPES.COMPANY) {
          if ($scope.isAllowedTeamLeaderboard) {
            $scope.getLeaderboard($scope.selectedTargets.t1, LEADERBOARDTYPES.TEAM);
          }
        }

        $scope.getLeaderboard(
          $scope.selectedTargets.t1,
          LEADERBOARDTYPES.DEVELOPER,
          $scope.sorting.sortdevLeaderboardByParam,
          $scope.searchText
        );
      }
    };

    $scope.clickRefreshLeaderboard = function () {
      $scope.csvStatus.developer = 'idle';
      $scope.csvStatus.team = 'idle';
      $scope.refreshLeaderboard();
    };

    // update data functions (called when promises resolve)
    $scope.updateSWData = function (data1, data2) {
      $log.debug('Updating SW data:', data1, data2);

      if (data1) {
        $scope.selectedTargets.t1.swData = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.swData = data2;
      }
    };

    $scope.updateSWOverTimeData = function (data1, data2) {
      $log.debug('Updating SW over time data:', data1, data2);

      if (data1) {
        $scope.selectedTargets.t1.swOverTimeData = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.swOverTimeData = data2;
      }
    };

    $scope.updateTimeSpentData = function (data1, data2) {
      $log.debug('Updating hours data:', data1, data2);

      if (data1) {
        $scope.selectedTargets.t1.timeSpentData = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.timeSpentData = data2;
      }
    };

    $scope.updateTimeSpentOverTimeData = function (data1, data2) {
      $log.debug('Updating hours data:', data1, data2);
      if (data1) {
        $scope.selectedTargets.t1.timeSpentOverTimeData = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.timeSpentOverTimeData = data2;
      }
    };

    // When the date sliders are updated, this function is called
    // and it updates the activity data displayed
    $scope.filterActivityData = function (startDate, endDate) {
      // NOTE be careful adding translations here as the logic below
      // iterates through them and will assume they are all categories
      let labels = ['LEARNING', 'TOURNAMENTS', 'TRAINING', 'ASSESSMENTS', 'COURSES'];
      if (!$rootScope.isAssessmentAllowedByCustomerPlan) {
        labels = labels.filter((x) => x !== 'ASSESSMENTS');
      }

      $translate(labels).then(function (translations) {
        var data = [];
        //for (var i = 0; i < Object.values(translations).length; i++) {
        for (var i = 0; i < _.map(translations).length; i++) {
          //Using lodash function here to make it IE compatible
          data[i] = 0;
        }

        // This is slightly complex looking but all it does it map a category
        // i.e. "LEARNING" to its index in data[]
        // this way we can increment the values for a category and we can keep data[]
        // as an array (as this is the format required for chart js)
        Object.keys(translations).map(function (k, i) {
          translations[k] = i;
        });

        // filter data for relevant dates and calculate totals
        //Object.entries($scope.selectedTargets.t1.originalActivityData)
        _.toPairs($scope.selectedTargets.t1.originalActivityData) //Make it IE compatible
          .filter(function (date) {
            return moment(date[0]).isBetween(startDate, endDate, 'day', '[]');
          })
          .map(function (oneDaysData) {
            //Object.entries(oneDaysData[1]).map(function(entry) {
            _.toPairs(oneDaysData[1]).map(function (entry) {
              //Make it IE compatible
              var category = entry[0].toUpperCase();
              var minsSpent = entry[1];
              var index = translations[category];
              if (index !== undefined) data[index] += minsSpent;
            });
          });
        $scope.selectedTargets.t1.activityData = data;
        // Make the dates match on export
        $scope.activityform.start = startDate;
        $scope.activityform.finish = endDate;
        $translate(['MINUTES']).then(function (trans) {
          var total = sumArray(data);
          $scope.selectedTargets.t1.activityTotal = moment.duration(total, 'minutes').humanize();
          if (total >= 60)
            $scope.selectedTargets.t1.activityTotal += ' (' + total.toLocaleString() + ' ' + trans.MINUTES + ')';
        });
      });
    };

    // initialises the activity data
    $scope.initialiseActivityData = function (data1, data2) {
      $log.debug('Updating activity data:', data1, data2);
      // NOTE be careful adding translations here as the logic below
      // iterates through them and will assume they are all categories
      let labels = ['LEARNING', 'TOURNAMENTS', 'TRAINING', 'ASSESSMENTS', 'COURSES'];
      if (!$rootScope.isAssessmentAllowedByCustomerPlan) {
        labels = labels.filter((x) => x !== 'ASSESSMENTS');
      }

      $translate(labels).then(function (translations) {
        if (data1) {
          $scope.selectedTargets.t1.originalActivityData = data1;
          //$scope.selectedTargets.t1.activityLabels = Object.values(translations);
          $scope.selectedTargets.t1.activityLabels = _.map(translations); // IE Compatible instead of browser values
          $scope.selectedTargets.t1.activityColors = Object.keys(translations).map(function (k) {
            return ACTIVITY_COLORS[k];
          });
          var data = [];
          //for (var i = 0; i < Object.values(translations).length; i++) {
          for (var i = 0; i < _.map(translations).length; i++) {
            //IE Compatible
            data[i] = 0;
          }

          // This is slightly complex looking but all it does it map a category
          // i.e. "LEARNING" to its index in data[]
          // this way we can increment the values for a category and we can keep data[]
          // as an array (as this is the format required for chart js)
          Object.keys(translations).map(function (k, i) {
            translations[k] = i;
          });

          // increments the total time spent at each category by iterating through the data
          //Object.entries(data1).map(function(oneDaysData) {
          //Object.entries(oneDaysData[1]).map(function(entry) {
          _.toPairs(data1).map(function (oneDaysData) {
            // Removed to make them browser compatible
            _.toPairs(oneDaysData[1]).map(function (entry) {
              var category = entry[0].toUpperCase();
              var minsSpent = entry[1];
              var index = translations[category];
              if (index !== undefined) data[index] += minsSpent;
            });
          });

          var dates = allDatesInRange(new Date(Object.keys(data1).sort()[0]), new Date());
          // slider settings
          $scope.selectedTargets.t1.slider = {
            minValue: dates[0],
            maxValue: dates[dates.length - 1],
            options: {
              stepsArray: dates,
              translate: function (date) {
                if (date != null) return moment(date).format('ll');
                return '';
              },
              onChange: function () {
                $scope.filterActivityData(
                  $scope.selectedTargets.t1.slider.minValue,
                  $scope.selectedTargets.t1.slider.maxValue
                );
              },
            },
          };
          $scope.activityform.start = $scope.selectedTargets.t1.slider.minValue;
          $scope.activityform.finish = $scope.selectedTargets.t1.slider.maxValue;

          // initialise total time spent
          $scope.selectedTargets.t1.activityData = data;
          $translate(['MINUTES']).then(function (trans) {
            var total = sumArray(data);
            $scope.selectedTargets.t1.activityTotal = moment.duration(total, 'minutes').humanize();
            if (total >= 60)
              $scope.selectedTargets.t1.activityTotal += ' (' + total.toLocaleString() + ' ' + trans.MINUTES + ')';
          });
        }

        if (data2) {
          $scope.selectedTargets.t2.activityData = data2.data;
          $scope.selectedTargets.t2.activityTotal = data2.total;
          var colors2 = [];
          var labels2 = [];
          for (var j = 0; j < data2.labels.length; j++) {
            colors2.push(ACTIVITY_COLORS[data2.labels[j]]);
            labels2.push(translations[data2.labels[j].toUpperCase()]);
          }
          $scope.selectedTargets.t2.activityLabels = labels2;
          $scope.selectedTargets.t2.activityColors = colors2;
        }
      });
    };

    $scope.updateSecurityMaturityData = function (data1, data2) {
      $log.debug('Updating security maturity data:', data1, data2);

      if (data1) {
        data1.displayRank = data1.currentRank + 1;
        if (data1.previousRank !== -1) {
          data1.rankChange = data1.previousRank - data1.currentRank;
        } else {
          data1.rankChange = 'New';
        }
        // calculate the total attempts
        if (data1.attempts) {
          data1.attempts.total = data1.attempts.correct + data1.attempts.incorrect;
          data1.attempts.percentAccuracy =
            data1.attempts.total > 0 ? Math.round((data1.attempts.correct / data1.attempts.total) * 100) : 0;
        }

        $scope.selectedTargets.t1.securityMaturityData = data1;
      }

      if (data2) {
        data2.displayRank = data2.currentRank + 1;
        if (data2.previousRank !== -1) {
          data2.rankChange = data2.previousRank - data2.currentRank;
        } else {
          data2.rankChange = 'New';
        }
        $scope.selectedTargets.t2.securityMaturityData = data2;
      }
    };

    $scope.updateSecurityMaturityOverTimeData = function (data1, data2) {
      $log.debug('Updating security maturity over time data:', data1, data2);

      if (data1) {
        $scope.selectedTargets.t1.securityMaturityOverTimeData = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.securityMaturityOverTimeData = data2;
      }
    };

    $scope.updateQuestProgressData = function (data1, data2) {
      $log.debug('Updating quest progress data:', data1, data2);

      if (data1) {
        $scope.selectedTargets.t1.questProgress = data1;
      }

      if (data2) {
        $scope.selectedTargets.t2.questProgress = data2;
      }
    };

    // update charts
    $scope.updateSWCharts = function () {
      var swData1, swData2;

      if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.swData) {
        // calculate real attempts since server number is unreliable
        $scope.selectedTargets.t1.swData.auth.challenges.realAttempts =
          $scope.selectedTargets.t1.swData.auth.challenges.correct +
          $scope.selectedTargets.t1.swData.auth.challenges.incorrect;
        $scope.selectedTargets.t1.swData.misconfig.challenges.realAttempts =
          $scope.selectedTargets.t1.swData.misconfig.challenges.correct +
          $scope.selectedTargets.t1.swData.misconfig.challenges.incorrect;
        $scope.selectedTargets.t1.swData.practices.challenges.realAttempts =
          $scope.selectedTargets.t1.swData.practices.challenges.correct +
          $scope.selectedTargets.t1.swData.practices.challenges.incorrect;
        $scope.selectedTargets.t1.swData.sensitive_data.challenges.realAttempts =
          $scope.selectedTargets.t1.swData.sensitive_data.challenges.correct +
          $scope.selectedTargets.t1.swData.sensitive_data.challenges.incorrect;
        $scope.selectedTargets.t1.swData.data_handling.challenges.realAttempts =
          $scope.selectedTargets.t1.swData.data_handling.challenges.correct +
          $scope.selectedTargets.t1.swData.data_handling.challenges.incorrect;

        swData1 = [
          /*$scope.selectedTargets.t1.swData.auth.points,
          $scope.selectedTargets.t1.swData.misconfig.points,
          $scope.selectedTargets.t1.swData.practices.points,
          $scope.selectedTargets.t1.swData.sensitive_data.points,
          $scope.selectedTargets.t1.swData.data_handling.points*/
          Math.round(
            ($scope.selectedTargets.t1.swData.auth.challenges.correct /
              $scope.selectedTargets.t1.swData.auth.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t1.swData.misconfig.challenges.correct /
              $scope.selectedTargets.t1.swData.misconfig.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t1.swData.practices.challenges.correct /
              $scope.selectedTargets.t1.swData.practices.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t1.swData.sensitive_data.challenges.correct /
              $scope.selectedTargets.t1.swData.sensitive_data.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t1.swData.data_handling.challenges.correct /
              $scope.selectedTargets.t1.swData.data_handling.challenges.realAttempts) *
              100
          ) || 0,
        ];
        if ($scope.myStrengthWeaknessData.length === 0) $scope.myStrengthWeaknessData.push(swData1);
        $scope.strengthWeaknessData.push(swData1);

        $scope.swLegendCorrectData = [
          $scope.selectedTargets.t1.swData.auth.challenges.correct,
          $scope.selectedTargets.t1.swData.misconfig.challenges.correct,
          $scope.selectedTargets.t1.swData.practices.challenges.correct,
          $scope.selectedTargets.t1.swData.sensitive_data.challenges.correct,
          $scope.selectedTargets.t1.swData.data_handling.challenges.correct,
        ];

        $scope.swLegendInCorrectData = [
          $scope.selectedTargets.t1.swData.auth.challenges.incorrect,
          $scope.selectedTargets.t1.swData.misconfig.challenges.incorrect,
          $scope.selectedTargets.t1.swData.practices.challenges.incorrect,
          $scope.selectedTargets.t1.swData.sensitive_data.challenges.incorrect,
          $scope.selectedTargets.t1.swData.data_handling.challenges.incorrect,
        ];

        var maxValue = 1;
        var x;

        for (x = 0; x < $scope.swLegendCorrectData.length; x++) {
          if ($scope.swLegendCorrectData[x] > maxValue) maxValue = $scope.swLegendCorrectData[x];
          if ($scope.swLegendInCorrectData[x] > maxValue) maxValue = $scope.swLegendInCorrectData[x];
        }

        for (x = 0; x < $scope.swLegendCorrectData.length; x++) {
          $scope.swLegendCorrectData[x] = ($scope.swLegendCorrectData[x] / maxValue) * 30; // using static 1000 for now, 76px high is 100% so divide by 2 for correct/incorrect and we leave 1px always on for each, then subtract a few px to make it look nicer
          $scope.swLegendInCorrectData[x] = ($scope.swLegendInCorrectData[x] / maxValue) * 30;
        }

        $('#my_auth_column_correct').height($scope.swLegendCorrectData[0] + 1);
        $('#my_auth_column_incorrect').height($scope.swLegendInCorrectData[0] + 1);
        $('#my_misconfig_column_correct').height($scope.swLegendCorrectData[1] + 1);
        $('#my_misconfig_column_incorrect').height($scope.swLegendInCorrectData[1] + 1);
        $('#my_practices_column_correct').height($scope.swLegendCorrectData[2] + 1);
        $('#my_practices_column_incorrect').height($scope.swLegendInCorrectData[2] + 1);
        $('#my_sensitivedata_column_correct').height($scope.swLegendCorrectData[3] + 1);
        $('#my_sensitivedata_column_incorrect').height($scope.swLegendInCorrectData[3] + 1);
        $('#my_datahandling_column_correct').height($scope.swLegendCorrectData[4] + 1);
        $('#my_datahandling_column_incorrect').height($scope.swLegendInCorrectData[4] + 1);

        $('#left_auth_column_correct').height($scope.swLegendCorrectData[0] + 1);
        $('#left_auth_column_incorrect').height($scope.swLegendInCorrectData[0] + 1);
        $('#left_misconfig_column_correct').height($scope.swLegendCorrectData[1] + 1);
        $('#left_misconfig_column_incorrect').height($scope.swLegendInCorrectData[1] + 1);
        $('#left_practices_column_correct').height($scope.swLegendCorrectData[2] + 1);
        $('#left_practices_column_incorrect').height($scope.swLegendInCorrectData[2] + 1);
        $('#left_sensitivedata_column_correct').height($scope.swLegendCorrectData[3] + 1);
        $('#left_sensitivedata_column_incorrect').height($scope.swLegendInCorrectData[3] + 1);
        $('#left_datahandling_column_correct').height($scope.swLegendCorrectData[4] + 1);
        $('#left_datahandling_column_incorrect').height($scope.swLegendInCorrectData[4] + 1);
      } else {
        // placeholder data for empty chart
        swData1 = [0, 0, 0, 0, 0];
        if ($scope.myStrengthWeaknessData.length === 0) $scope.myStrengthWeaknessData.push(swData1);
        $scope.strengthWeaknessData.push(swData1);
        $scope.swLegendCorrectData = [0, 0, 0, 0, 0];
        $scope.swLegendInCorrectData = [0, 0, 0, 0, 0];
        $('#my_auth_column_correct').height(1);
        $('#my_auth_column_incorrect').height(1);
        $('#my_misconfig_column_correct').height(1);
        $('#my_misconfig_column_incorrect').height(1);
        $('#my_practices_column_correct').height(1);
        $('#my_practices_column_incorrect').height(1);
        $('#my_sensitivedata_column_correct').height(1);
        $('#my_sensitivedata_column_incorrect').height(1);
        $('#my_datahandling_column_correct').height(1);
        $('#my_datahandling_column_incorrect').height(1);

        $('#left_auth_column_correct').height(1);
        $('#left_auth_column_incorrect').height(1);
        $('#left_misconfig_column_correct').height(1);
        $('#left_misconfig_column_incorrect').height(1);
        $('#left_practices_column_correct').height(1);
        $('#left_practices_column_incorrect').height(1);
        $('#left_sensitivedata_column_correct').height(1);
        $('#left_sensitivedata_column_incorrect').height(1);
        $('#left_datahandling_column_correct').height(1);
        $('#left_datahandling_column_incorrect').height(1);
      }

      if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.swData) {
        // calculate real attempts since server number is unreliable
        $scope.selectedTargets.t2.swData.auth.challenges.realAttempts =
          $scope.selectedTargets.t2.swData.auth.challenges.correct +
          $scope.selectedTargets.t2.swData.auth.challenges.incorrect;
        $scope.selectedTargets.t2.swData.misconfig.challenges.realAttempts =
          $scope.selectedTargets.t2.swData.misconfig.challenges.correct +
          $scope.selectedTargets.t2.swData.misconfig.challenges.incorrect;
        $scope.selectedTargets.t2.swData.practices.challenges.realAttempts =
          $scope.selectedTargets.t2.swData.practices.challenges.correct +
          $scope.selectedTargets.t2.swData.practices.challenges.incorrect;
        $scope.selectedTargets.t2.swData.sensitive_data.challenges.realAttempts =
          $scope.selectedTargets.t2.swData.sensitive_data.challenges.correct +
          $scope.selectedTargets.t2.swData.sensitive_data.challenges.incorrect;
        $scope.selectedTargets.t2.swData.data_handling.challenges.realAttempts =
          $scope.selectedTargets.t2.swData.data_handling.challenges.correct +
          $scope.selectedTargets.t2.swData.data_handling.challenges.incorrect;

        swData2 = [
          /*$scope.selectedTargets.t2.swData.auth.points,
          $scope.selectedTargets.t2.swData.misconfig.points,
          $scope.selectedTargets.t2.swData.practices.points,
          $scope.selectedTargets.t2.swData.sensitive_data.points,
          $scope.selectedTargets.t2.swData.data_handling.points*/
          Math.round(
            ($scope.selectedTargets.t2.swData.auth.challenges.correct /
              $scope.selectedTargets.t2.swData.auth.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t2.swData.misconfig.challenges.correct /
              $scope.selectedTargets.t2.swData.misconfig.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t2.swData.practices.challenges.correct /
              $scope.selectedTargets.t2.swData.practices.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t2.swData.sensitive_data.challenges.correct /
              $scope.selectedTargets.t2.swData.sensitive_data.challenges.realAttempts) *
              100
          ) || 0,
          Math.round(
            ($scope.selectedTargets.t2.swData.data_handling.challenges.correct /
              $scope.selectedTargets.t2.swData.data_handling.challenges.realAttempts) *
              100
          ) || 0,
        ];
        $scope.strengthWeaknessData.push(swData2);

        $scope.swRightLegendCorrectData = [
          $scope.selectedTargets.t2.swData.auth.challenges.correct,
          $scope.selectedTargets.t2.swData.misconfig.challenges.correct,
          $scope.selectedTargets.t2.swData.practices.challenges.correct,
          $scope.selectedTargets.t2.swData.sensitive_data.challenges.correct,
          $scope.selectedTargets.t2.swData.data_handling.challenges.correct,
        ];

        $scope.swRightLegendInCorrectData = [
          $scope.selectedTargets.t2.swData.auth.challenges.incorrect,
          $scope.selectedTargets.t2.swData.misconfig.challenges.incorrect,
          $scope.selectedTargets.t2.swData.practices.challenges.incorrect,
          $scope.selectedTargets.t2.swData.sensitive_data.challenges.incorrect,
          $scope.selectedTargets.t2.swData.data_handling.challenges.incorrect,
        ];

        var rightMaxValue = 1;
        var rightX;

        for (rightX = 0; rightX < $scope.swRightLegendCorrectData.length; rightX++) {
          if ($scope.swRightLegendCorrectData[rightX] > rightMaxValue)
            rightMaxValue = $scope.swRightLegendCorrectData[rightX];
          if ($scope.swRightLegendInCorrectData[rightX] > rightMaxValue)
            rightMaxValue = $scope.swRightLegendInCorrectData[rightX];
        }

        for (rightX = 0; rightX < $scope.swRightLegendCorrectData.length; rightX++) {
          $scope.swRightLegendCorrectData[rightX] = ($scope.swRightLegendCorrectData[rightX] / rightMaxValue) * 30; // using static 1000 for now, 76px high is 100% so divide by 2 for correct/incorrect and we leave 1px always on for each, then subtract a few px to make it look nicer
          $scope.swRightLegendInCorrectData[rightX] = ($scope.swRightLegendInCorrectData[rightX] / rightMaxValue) * 30;
        }

        $('#right_auth_column_correct').height($scope.swRightLegendCorrectData[0] + 1);
        $('#right_auth_column_incorrect').height($scope.swRightLegendInCorrectData[0] + 1);
        $('#right_misconfig_column_correct').height($scope.swRightLegendCorrectData[1] + 1);
        $('#right_misconfig_column_incorrect').height($scope.swRightLegendInCorrectData[1] + 1);
        $('#right_practices_column_correct').height($scope.swRightLegendCorrectData[2] + 1);
        $('#right_practices_column_incorrect').height($scope.swRightLegendInCorrectData[2] + 1);
        $('#right_sensitivedata_column_correct').height($scope.swRightLegendCorrectData[3] + 1);
        $('#right_sensitivedata_column_incorrect').height($scope.swRightLegendInCorrectData[3] + 1);
        $('#right_datahandling_column_correct').height($scope.swRightLegendCorrectData[4] + 1);
        $('#right_datahandling_column_incorrect').height($scope.swRightLegendInCorrectData[4] + 1);
      }
    };

    $scope.updateSWOverTimeCharts = function () {
      var swData, i;

      if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.swOverTimeData) {
        for (i = 0; i < $scope.selectedTargets.t1.swOverTimeData.length; i++) {
          // calculate real attempts since server number is unreliable
          $scope.selectedTargets.t1.swOverTimeData[i].data.auth.challenges.realAttempts =
            $scope.selectedTargets.t1.swOverTimeData[i].data.auth.challenges.correct +
            $scope.selectedTargets.t1.swOverTimeData[i].data.auth.challenges.incorrect;
          $scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.challenges.realAttempts =
            $scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.challenges.correct +
            $scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.challenges.incorrect;
          $scope.selectedTargets.t1.swOverTimeData[i].data.practices.challenges.realAttempts =
            $scope.selectedTargets.t1.swOverTimeData[i].data.practices.challenges.correct +
            $scope.selectedTargets.t1.swOverTimeData[i].data.practices.challenges.incorrect;
          $scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.challenges.realAttempts =
            $scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.challenges.correct +
            $scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.challenges.incorrect;
          $scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.challenges.realAttempts =
            $scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.challenges.correct +
            $scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.challenges.incorrect;

          swData = [
            /*$scope.selectedTargets.t1.swOverTimeData[i].data.auth.points,
            $scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.points,
            $scope.selectedTargets.t1.swOverTimeData[i].data.practices.points,
            $scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.points,
            $scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.points*/
            Math.round(
              ($scope.selectedTargets.t1.swOverTimeData[i].data.auth.challenges.correct /
                $scope.selectedTargets.t1.swOverTimeData[i].data.auth.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.challenges.correct /
                $scope.selectedTargets.t1.swOverTimeData[i].data.misconfig.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t1.swOverTimeData[i].data.practices.challenges.correct /
                $scope.selectedTargets.t1.swOverTimeData[i].data.practices.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.challenges.correct /
                $scope.selectedTargets.t1.swOverTimeData[i].data.sensitive_data.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.challenges.correct /
                $scope.selectedTargets.t1.swOverTimeData[i].data.data_handling.challenges.realAttempts) *
                100
            ) || 0,
          ];

          if ($scope.strengthWeaknessOverTimeData.length == $scope.selectedTargets.t1.swOverTimeData.length) {
            if ($scope.strengthWeaknessOverTimeData[i] !== null) {
              $scope.strengthWeaknessOverTimeData[i].push(swData);
            }
          } else {
            $scope.strengthWeaknessOverTimeData.push([swData]);
          }
        }
      }

      if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.swOverTimeData) {
        for (i = 0; i < $scope.selectedTargets.t2.swOverTimeData.length; i++) {
          // calculate real attempts since server number is unreliable
          $scope.selectedTargets.t2.swOverTimeData[i].data.auth.challenges.realAttempts =
            $scope.selectedTargets.t2.swOverTimeData[i].data.auth.challenges.correct +
            $scope.selectedTargets.t2.swOverTimeData[i].data.auth.challenges.incorrect;
          $scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.challenges.realAttempts =
            $scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.challenges.correct +
            $scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.challenges.incorrect;
          $scope.selectedTargets.t2.swOverTimeData[i].data.practices.challenges.realAttempts =
            $scope.selectedTargets.t2.swOverTimeData[i].data.practices.challenges.correct +
            $scope.selectedTargets.t2.swOverTimeData[i].data.practices.challenges.incorrect;
          $scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.challenges.realAttempts =
            $scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.challenges.correct +
            $scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.challenges.incorrect;
          $scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.challenges.realAttempts =
            $scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.challenges.correct +
            $scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.challenges.incorrect;

          swData = [
            /*$scope.selectedTargets.t2.swOverTimeData[i].data.auth.points,
            $scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.points,
            $scope.selectedTargets.t2.swOverTimeData[i].data.practices.points,
            $scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.points,
            $scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.points*/
            Math.round(
              ($scope.selectedTargets.t2.swOverTimeData[i].data.auth.challenges.correct /
                $scope.selectedTargets.t2.swOverTimeData[i].data.auth.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.challenges.correct /
                $scope.selectedTargets.t2.swOverTimeData[i].data.misconfig.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t2.swOverTimeData[i].data.practices.challenges.correct /
                $scope.selectedTargets.t2.swOverTimeData[i].data.practices.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.challenges.correct /
                $scope.selectedTargets.t2.swOverTimeData[i].data.sensitive_data.challenges.realAttempts) *
                100
            ) || 0,
            Math.round(
              ($scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.challenges.correct /
                $scope.selectedTargets.t2.swOverTimeData[i].data.data_handling.challenges.realAttempts) *
                100
            ) || 0,
          ];

          if ($scope.strengthWeaknessOverTimeData.length == $scope.selectedTargets.t2.swOverTimeData.length) {
            if ($scope.strengthWeaknessOverTimeData[i] !== null) {
              $scope.strengthWeaknessOverTimeData[i].push(swData);
            }
          } else {
            $scope.strengthWeaknessOverTimeData.push([swData]);
          }
        }
      }
      //$scope.strengthWeaknessOverTimeData = randomSWOverTimeData();
    };

    $scope.updateTimeSpentCharts = function () {
      var mTimeSpent;

      $scope.totalHoursPercentData = []; // wtf why is this [0] when nothing else touches this variable? literally the only other instance of it not in a comment is when it is initialised to []

      if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.timeSpentData) {
        mTimeSpent = moment.duration($scope.selectedTargets.t1.timeSpentData.total, 'milliseconds');
        $scope.totalHoursData.push(Math.floor(mTimeSpent.as('hours')));
        $scope.totalHoursPercentData.push((mTimeSpent.as('hours') / 12) * 100); // using 12 hours as max for now - requirement to get to Security Champion
        $scope.fullTotalHoursDisplay.push(
          $rootScope.utility.longFormatDuration($scope.selectedTargets.t1.timeSpentData.total)
        );
      }

      if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.timeSpentData) {
        mTimeSpent = moment.duration($scope.selectedTargets.t2.timeSpentData.total, 'milliseconds');
        $scope.totalHoursData.push(Math.floor(mTimeSpent.as('hours')));
        $scope.totalHoursPercentData.push((mTimeSpent.as('hours') / 12) * 100); // using 12 hours as max for now - requirement to get to Security Champion
        $scope.fullTotalHoursDisplay.push(
          $rootScope.utility.longFormatDuration($scope.selectedTargets.t2.timeSpentData.total)
        );
      }
    };

    $scope.updateTimeSpentOverTimeCharts = function () {
      var pushMe, i;

      pushMe = [];
      if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.timeSpentOverTimeData) {
        for (i = 0; i < $scope.selectedTargets.t1.timeSpentOverTimeData.length; i++) {
          pushMe.push($scope.selectedTargets.t1.timeSpentOverTimeData[i].data.total / 1000 / 60 / 60);
        }
        $scope.totalHoursOverTimeData.push(pushMe);
      }

      pushMe = [];
      if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.timeSpentOverTimeData) {
        for (i = 0; i < $scope.selectedTargets.t2.timeSpentOverTimeData.length; i++) {
          pushMe.push($scope.selectedTargets.t2.timeSpentOverTimeData[i].data.total / 1000 / 60 / 60);
        }
        $scope.totalHoursOverTimeData.push(pushMe);
      }
    };

    $scope.updateSecurityMaturityCharts = function () {
      var maturityData;

      if (
        $scope.selectedTargets.t1 &&
        $scope.selectedTargets.t1.type == 'developer' &&
        $scope.selectedTargets.t1.securityMaturityData
      ) {
        maturityData = $scope.selectedTargets.t1.securityMaturityData.securityMaturity;

        if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.AWARE.id;
        } else if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.AWARE.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id;
        } else if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id;
        } else if (
          maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
        ) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = null;
        }

        $timeout(function () {
          $scope.selectedTargets.t1.securityMaturityData.challenges.ratioCorrect =
            Math.round($scope.selectedTargets.t1.securityMaturityData.challenges.ratioCorrect * 100) || 0;
          $scope.selectedTargets.t1.securityMaturityData.challenges.realAttempts =
            $scope.selectedTargets.t1.securityMaturityData.challenges.correct +
            $scope.selectedTargets.t1.securityMaturityData.challenges.incorrect;

          if (maturityData.maturityLevelNext) {
            maturityData.progressStats = _.clone(PROGRESS_STATS_CAROUSEL);

            for (var i = 0; i < maturityData.progressStats.length; i++) {
              var progressStat = maturityData.progressStats[i];
              progressStat.points.current = $scope.selectedTargets.t1.securityMaturityData.points;
              progressStat.points.percentage = Math.floor(
                (progressStat.points.current / progressStat.points.required) * 100
              );
              progressStat.points.percentage =
                progressStat.points.percentage > 100 ? 100 : progressStat.points.percentage;

              if (!$scope.removeSecurityMaturityTimespent) {
                progressStat.time.current = $scope.totalHoursData[0];
                progressStat.time.currentDisplay = $scope.fullTotalHoursDisplay[0] || $scope.totalHoursData[0];
                progressStat.time.percentage = Math.floor(
                  (progressStat.time.current / progressStat.time.required) * 100
                );
                progressStat.time.percentage = progressStat.time.percentage > 100 ? 100 : progressStat.time.percentage;
              }

              progressStat.accuracy.current = $scope.selectedTargets.t1.securityMaturityData.challenges.ratioCorrect;
              progressStat.accuracy.percentage = Math.floor(
                (progressStat.accuracy.current / progressStat.accuracy.required) * 100
              );
              progressStat.accuracy.percentage =
                progressStat.accuracy.percentage > 100 ? 100 : progressStat.accuracy.percentage;

              progressStat.attempted.current = $scope.selectedTargets.t1.securityMaturityData.challenges.attempted;
              progressStat.attempted.percentage = Math.floor(
                (progressStat.attempted.current / progressStat.attempted.required) * 100
              );
              progressStat.attempted.percentage =
                progressStat.attempted.percentage > 100 ? 100 : progressStat.attempted.percentage;
            }

            $scope.progressStatIndex = Object.keys(MATURITY_LEVEL_RULES)
              .reverse()
              .indexOf(maturityData.maturityLevelNext);
          }
        });
      } else if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.securityMaturityData) {
        maturityData = [
          $scope.selectedTargets.t1.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
          ],
          $scope.selectedTargets.t1.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
          ],
          $scope.selectedTargets.t1.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
          ],
          $scope.selectedTargets.t1.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
          ],
        ];
        if ($scope.myDistributionData.length === 0) $scope.myDistributionData = maturityData;
        $scope.distributionData.push(maturityData);
      }

      if (
        $scope.selectedTargets.t2 &&
        $scope.selectedTargets.t2.type == 'developer' &&
        $scope.selectedTargets.t2.securityMaturityData
      ) {
        maturityData = $scope.selectedTargets.t2.securityMaturityData.securityMaturity;

        if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.AWARE.id;
        } else if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.AWARE.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id;
        } else if (maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id;
        } else if (
          maturityData.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
        ) {
          maturityData.maturityText = SECURITY_MATURITY_TEXT[maturityData.maturityLevel];
          maturityData.maturityReqsHtml = MATURITY_LEVEL_REQS_HTML[maturityData.maturityLevel];
          maturityData.maturityLevelNext = null;
        }

        $timeout(function () {
          $scope.selectedTargets.t2.securityMaturityData.challenges.ratioCorrect =
            Math.round($scope.selectedTargets.t2.securityMaturityData.challenges.ratioCorrect * 100) || 0;
          $scope.selectedTargets.t2.securityMaturityData.challenges.realAttempts =
            $scope.selectedTargets.t2.securityMaturityData.challenges.correct +
            $scope.selectedTargets.t2.securityMaturityData.challenges.incorrect;

          if (maturityData.maturityLevelNext) {
            maturityData.progressStats = _.clone(PROGRESS_STATS_CAROUSEL);

            for (var i = 0; i < maturityData.progressStats.length; i++) {
              var progressStat = maturityData.progressStats[i];
              progressStat.points.current = $scope.selectedTargets.t2.securityMaturityData.points;
              progressStat.points.percentage = Math.floor(
                (progressStat.points.current / progressStat.points.required) * 100
              );
              progressStat.points.percentage =
                progressStat.points.percentage > 100 ? 100 : progressStat.points.percentage;

              if (!$scope.removeSecurityMaturityTimespent) {
                progressStat.time.current = $scope.totalHoursData[1];
                progressStat.time.currentDisplay = $scope.fullTotalHoursDisplay[1] || $scope.totalHoursData[1];
                progressStat.time.percentage = Math.floor(
                  (progressStat.time.current / progressStat.time.required) * 100
                );
                progressStat.time.percentage = progressStat.time.percentage > 100 ? 100 : progressStat.time.percentage;
              }

              progressStat.accuracy.current = $scope.selectedTargets.t2.securityMaturityData.challenges.ratioCorrect;
              progressStat.accuracy.percentage = Math.floor(
                (progressStat.accuracy.current / progressStat.accuracy.required) * 100
              );
              progressStat.accuracy.percentage =
                progressStat.accuracy.percentage > 100 ? 100 : progressStat.accuracy.percentage;

              progressStat.attempted.current = $scope.selectedTargets.t2.securityMaturityData.challenges.attempted;
              progressStat.attempted.percentage = Math.floor(
                (progressStat.attempted.current / progressStat.attempted.required) * 100
              );
              progressStat.attempted.percentage =
                progressStat.attempted.percentage > 100 ? 100 : progressStat.attempted.percentage;
            }

            $scope.progressStatIndex = Object.keys(MATURITY_LEVEL_RULES)
              .reverse()
              .indexOf(maturityData.maturityLevelNext);
          }
        });
      } else if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.securityMaturityData) {
        maturityData = [
          $scope.selectedTargets.t2.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
          ],
          $scope.selectedTargets.t2.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
          ],
          $scope.selectedTargets.t2.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
          ],
          $scope.selectedTargets.t2.securityMaturityData[
            $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
          ],
        ];
        if ($scope.myDistributionData.length === 0) $scope.myDistributionData = maturityData;
        $scope.distributionData.push(maturityData);
      }
    };

    $scope.progressCarouselPrev = function () {
      $scope.progressStatIndex--;
      if ($scope.progressStatIndex < 0) {
        $scope.progressStatIndex = 0;
      }
    };

    $scope.progressCarouselNext = function () {
      $scope.progressStatIndex++;
      if (
        $scope.progressStatIndex >= $scope.selectedTargets.t1.securityMaturityData.securityMaturity.progressStats.length
      ) {
        $scope.progressStatIndex =
          $scope.selectedTargets.t1.securityMaturityData.securityMaturity.progressStats.length - 1;
      }
    };

    $scope.updateSecurityMaturityOverTimeCharts = function () {
      var i, dataPoint, pushMe, dateValue, arrayObj;

      pushMe = [
        {
          key: 'asdf',
          values: [],
          color: 'rgba(255,255,255,0.4)',
        },
        {
          key: SECURITYMATURITY.AWARE,
          values: [],
          color: 'rgba(255,255,255,0.6)',
        },
        {
          key: SECURITYMATURITY.SKILLED,
          values: [],
          color: 'rgba(255,255,255,0.8)',
        },
        {
          key: SECURITYMATURITY.CHAMPION,
          values: [],
          color: '#ffb700',
        },
      ];
      $translate(['OK', 'ERROR', 'UNKNOWN_STATISTICS_INTERVAL']).then(function (translations) {
        if ($scope.selectedTargets.t1 && $scope.selectedTargets.t1.securityMaturityOverTimeData) {
          for (i = 0; i < $scope.selectedTargets.t1.securityMaturityOverTimeData.length; i++) {
            arrayObj = $scope.selectedTargets.t1.securityMaturityOverTimeData[i];
            dataPoint = $scope.selectedTargets.t1.securityMaturityOverTimeData[i].data;
            //dateValue = new Date($scope.xSeriesTimeLabels[i]).getTime();
            dateValue = moment(arrayObj._id.timestamp);
            if ($scope.dateRange.statsInterval == INTERVALS.MONTHS) {
              dateValue.endOf('month');
            } else if ($scope.dateRange.statsInterval == INTERVALS.WEEKS) {
              dateValue.endOf('week');
            } else if ($scope.dateRange.statsInterval == INTERVALS.DAYS) {
              dateValue.endOf('day');
            } else {
              swal({
                title: translations.ERROR,
                text: translations.UNKNOWN_STATISTICS_INTERVAL,
                type: 'error',
                confirmButtonText: translations.OK,
              });
              return;
            }
            dateValue = dateValue.valueOf();
            pushMe[0].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id],
            ]);
            pushMe[1].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.AWARE.id],
            ]);
            pushMe[2].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.SKILLED.id],
            ]);
            pushMe[3].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id],
            ]);
          }
          $scope.distributionOverTimeData.push(pushMe);
        }

        pushMe = [
          {
            key: SECURITYMATURITY.BEGINNER,
            values: [],
            color: 'rgba(255,255,255,0.4)',
          },
          {
            key: SECURITYMATURITY.AWARE,
            values: [],
            color: 'rgba(255,255,255,0.6)',
          },
          {
            key: SECURITYMATURITY.SKILLED,
            values: [],
            color: 'rgba(255,255,255,0.8)',
          },
          {
            key: SECURITYMATURITY.CHAMPION,
            values: [],
            color: '#ffb700',
          },
        ];
        if ($scope.selectedTargets.t2 && $scope.selectedTargets.t2.securityMaturityOverTimeData) {
          for (i = 0; i < $scope.selectedTargets.t2.securityMaturityOverTimeData.length; i++) {
            arrayObj = $scope.selectedTargets.t1.securityMaturityOverTimeData[i];
            dataPoint = $scope.selectedTargets.t2.securityMaturityOverTimeData[i].data;
            //dateValue = new Date($scope.xSeriesTimeLabels[i]).getTime();
            dateValue = moment(arrayObj._id.timestamp);
            if ($scope.dateRange.statsInterval == INTERVALS.MONTHS) {
              dateValue.endOf('month');
            } else if ($scope.dateRange.statsInterval == INTERVALS.WEEKS) {
              dateValue.endOf('week');
            } else if ($scope.dateRange.statsInterval == INTERVALS.DAYS) {
              dateValue.endOf('day');
            } else {
              swal({
                title: translations.ERROR,
                text: translations.UNKNOWN_STATISTICS_INTERVAL,
                type: 'error',
                confirmButtonText: translations.OK,
              });
              return;
            }
            dateValue = dateValue.valueOf();
            pushMe[0].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id],
            ]);
            pushMe[1].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.AWARE.id],
            ]);
            pushMe[2].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.SKILLED.id],
            ]);
            pushMe[3].values.push([
              dateValue,
              dataPoint[$scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id],
            ]);
          }
          $scope.distributionOverTimeData.push(pushMe);
        }
      });
    };

    // panel changes
    $scope.goBack = function () {
      if ($rootScope.metricsPrevStateParams) {
        $state.go('metrics', $rootScope.metricsPrevStateParams, { reload: true });
        delete $rootScope.metricsPrevStateParams;
      } else {
        $state.go(
          'metrics',
          { panel: null, t1: null, t2: null, interval: null, from: null, to: null, language: null, framework: null },
          { reload: true }
        );
      }
    };

    $scope.showDashboard = function () {
      $scope.shownPanel = PANELS.DASHBOARD;
      $scope.updateURL();
    };

    $scope.showLeaderboard = function () {
      $scope.shownPanel = PANELS.LEADERBOARD;
      $scope.updateURL();
    };

    $scope.showSW = function () {
      $scope.shownPanel = PANELS.STRENGTHWEAKNESS;
      $scope.updateURL();
    };

    $scope.showDistribution = function () {
      $scope.shownPanel = PANELS.DISTRIBUTION;
      $scope.updateURL();
    };

    $scope.showTimeSpent = function () {
      $scope.shownPanel = PANELS.TIMESPENT;
      $scope.updateURL();
    };

    function createSwTimeSlider() {
      var sliderElement = angular.element('#swTimeSlider');
      if (sliderElement) {
        var children = sliderElement.children();
        for (var z = 0; z < children.length; z++) {
          if (children[z].nodeName != 'SPAN') {
            children[z].remove();
          }
        }
      }

      var m1 = moment(new Date($scope.dateRange.fromDate));
      var m2 = moment(new Date($scope.dateRange.toDate));

      $scope.sliderMin = m1.unix();
      $scope.sliderMax = m2.unix();

      $scope.timeSpans = $scope.xSeriesTimeLabels.length - 1;
      $scope.sliderStep = ($scope.sliderMax - $scope.sliderMin) / $scope.timeSpans;

      $('#swTimeSlider')
        .slider({
          animate: 'fast',
          disabled: true,
          value: $scope.sliderMin,
          step: $scope.sliderStep,
          min: $scope.sliderMin,
          max: $scope.sliderMax,
        })
        .each(function () {
          var labelText = moment.unix($scope.sliderMin).format('LL');
          var el = $('<label>' + labelText + '</label>')
            .css('left', '0%')
            .addClass('sliderStart');
          $('#swTimeSlider').append(el);

          labelText = moment.unix($scope.sliderMax).format('LL');
          el = $('<label>' + labelText + '</label>')
            .css('left', '100%')
            .addClass('sliderEnd');
          $('#swTimeSlider').append(el);
        });
    }

    $scope.startTimer = function () {
      if ($scope._interval) {
        throw new Error('Timer already running');
      }

      $scope.timeSpanRemaining = $scope.timeSpans;

      $scope.swTimeData = $scope.strengthWeaknessOverTimeData[0];
      $scope.currentDate = $scope.xSeriesTimeLabels[0];

      $scope._interval = $interval(function () {
        $('#swTimeSlider').slider('value', $scope.sliderMax - --$scope.timeSpanRemaining * $scope.sliderStep);

        $scope.currentDate = $scope.xSeriesTimeLabels[$scope.timeSpans - $scope.timeSpanRemaining];
        $scope.swTimeData = $scope.strengthWeaknessOverTimeData[$scope.timeSpans - $scope.timeSpanRemaining];

        if ($scope.timeSpanRemaining <= 0) {
          $scope.stopTimer();
          $scope.timeSpanRemaining = $scope.timeSpans;
          $scope._timerRestartTimeout = $timeout(function () {
            $scope.stopTimer();
            $scope.swTimeData = $scope.strengthWeaknessOverTimeData[0];
            $('#swTimeSlider').slider('value', $scope.sliderMin);
            $scope.startTimer();
          }, 5000);
        }
      }, 400);
    };

    // get list of targets
    $scope.getMetricsTargets = function () {
      AdminApiService.addLoading();
      var language = null;
      var filter = null;
      return AdminApiService.getMetricsTargets(language, filter)
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    // get target 'options' (read as 'properties')
    $scope.getTargetProperties = function (target) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };
      var optionsObj = {
        target: targetObj,
      };

      AdminApiService.addLoading();
      return AdminApiService.getTargetProperties(optionsObj)
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TARGET_PROPERTIES'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    // get various metrics
    $scope.getSecurityMaturityData = function (target) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };
      var optionsObj = {
        target: targetObj,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'security_maturity',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_SECURITY_MATURITY_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getSecurityMaturityOverTimeData = function (target, from, to, interval) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };

      var m1 = moment(/*.utc*/ new Date(from));
      var m2 = moment(/*.utc*/ new Date(to));
      var intervalValue;
      if (interval == INTERVALS.DAYS) intervalValue = 'date';
      else if (interval == INTERVALS.WEEKS) intervalValue = 'week';
      else if (interval == INTERVALS.MONTHS) intervalValue = 'month';
      var dateRange = {
        interval: intervalValue,
        start: m1.valueOf(),
        end: m2.valueOf(),
      };

      var optionsObj = {
        target: targetObj,
        date: dateRange,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'security_maturity_over_time',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_SECURITY_MATURITY_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getPlayerData = function (target) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };
      var optionsObj = {
        target: targetObj,
        offset: moment().utcOffset(),
      };

      AdminApiService.addLoading();
      return AdminApiService.getPlayerMetrics(
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          for (var i = 0; i < data.questProgress.length; i++) {
            var langState = data.questProgress[i];
            $rootScope.utility.stitchQuestProgressStory(langState);
          }
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_PLAYER_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getStrengthWeaknessData = function (target) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };
      var optionsObj = {
        target: targetObj,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'strengths_and_weaknesses',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_STRENGTH_WEAKNESS_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getStrengthWeaknessOverTimeData = function (target, from, to, interval) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };

      var m1 = moment(/*.utc*/ new Date(from));
      var m2 = moment(/*.utc*/ new Date(to));
      var intervalValue;
      if (interval == INTERVALS.DAYS) intervalValue = 'date';
      else if (interval == INTERVALS.WEEKS) intervalValue = 'week';
      else if (interval == INTERVALS.MONTHS) intervalValue = 'month';
      var dateRange = {
        interval: intervalValue,
        start: m1.valueOf(),
        end: m2.valueOf(),
      };

      var optionsObj = {
        target: targetObj,
        date: dateRange,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'strengths_and_weaknesses_over_time',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_STRENGTH_WEAKNESS_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getTimeSpentData = function (target) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };
      var optionsObj = {
        target: targetObj,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'time_spent',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TIME_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.getTimeSpentOverTimeData = function (target, from, to, interval) {
      var targetObj = {
        _id: target._id,
        type: target.type,
      };

      var m1 = moment(/*.utc*/ new Date(from));
      var m2 = moment(/*.utc*/ new Date(to));
      var intervalValue;
      if (interval == INTERVALS.DAYS) intervalValue = 'date';
      else if (interval == INTERVALS.WEEKS) intervalValue = 'week';
      else if (interval == INTERVALS.MONTHS) intervalValue = 'month';
      var dateRange = {
        interval: intervalValue,
        start: m1.valueOf(),
        end: m2.valueOf(),
      };

      var optionsObj = {
        target: targetObj,
        date: dateRange,
      };

      AdminApiService.addLoading();
      return AdminApiService.getMetric(
        'time_spent_over_time',
        optionsObj,
        $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null
      )
        .then(function (data) {
          return data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_RETRIEVING_TIME_METRICS'), response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    $scope.teamPage = { options: { size: $rootScope.pagerPrefs.itemsPerPage } };
    $scope.topBottom5GetTotalPage = { options: { page: 1, size: 1 } };
    $scope.top5DevPage = { options: { page: 1, size: 5 } };
    $scope.bottom5DevPage = { options: { page: 1, size: -5 } };
    $scope.devPage = { options: { page: 1, size: $rootScope.pagerPrefs.itemsPerPage } };
    $scope.teamPagerMaxSize = 10;
    $scope.devPagerMaxSize = 10;
    $scope.filterActiveUsers = UserPreferences.get('metrics.showActiveUsers', false);

    $scope.toggleActiveUsers = function () {
      $scope.filterActiveUsers = !$scope.filterActiveUsers;
      UserPreferences.save('metrics.showActiveUsers', $scope.filterActiveUsers);
      $scope.clickRefreshLeaderboard();
    };

    $scope.changeDuration = function () {
      UserPreferences.save('metrics.deltaDurationData', $scope.deltaDurationData.duration);
    };

    $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          $scope.selectedTargets &&
          $scope.selectedTargets.t1 &&
          $scope.selectedTargets.t1.type &&
          $scope.selectedTargets.t1.type == 'company'
        ) {
          $scope.teamPage.options.page = 1;
          $scope.teamPage.options.size = newVal;
          $scope.getLeaderboard($scope.selectedTargets.t1, LEADERBOARDTYPES.TEAM);
        }

        $scope.devPage.options.page = 1;
        $scope.devPage.options.size = newVal;
        $scope.getLeaderboard(
          $scope.selectedTargets.t1,
          LEADERBOARDTYPES.DEVELOPER,
          $scope.sorting.sortdevLeaderboardByParam,
          $scope.searchText
        );
      }
    });

    $scope.teamPagerChanged = function () {
      $scope.getLeaderboard($scope.selectedTargets.t1, LEADERBOARDTYPES.TEAM);
    };

    $scope.devPagerChanged = function () {
      $scope.getLeaderboard(
        $scope.selectedTargets.t1,
        LEADERBOARDTYPES.DEVELOPER,
        $scope.sorting.sortdevLeaderboardByParam,
        $scope.searchText
      );
    };

    $scope.devSearch = function (searchText) {
      $scope.searchText = searchText;
      $scope.getLeaderboard(
        $scope.selectedTargets.t1,
        LEADERBOARDTYPES.DEVELOPER,
        $scope.sorting.sortdevLeaderboardByParam,
        searchText
      );
    };

    $scope.getLeaderboard = function (target, type, sortBy, searchText) {
      $translate([
        'BEGINNER',
        'SECURITY_AWARE',
        'SECURITY_SKILLED',
        'SECURITY_CHAMPION',
        'ERROR_RETRIEVING_LEADERBOARD',
      ])
        .then(function (translations) {
          const hasPermission =
            AuthService.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.admin]) ||
            (target.type === 'team' && AuthService.isAuthorized([USER_ROLES.manager]));

          if (!hasPermission) {
            return;
          }

          var sendTarget = { _id: target._id, type: target.type };

          var optionsObj = {
            target: sendTarget,
            type: type,
            filterActiveUsers: $scope.filterActiveUsers,
          };

          if (type == LEADERBOARDTYPES.TEAM) {
            AdminApiService.addLoading();
            AdminApiService.getLeaderboard(
              optionsObj,
              $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null,
              $scope.teamPage.options,
              $scope.deltaDurationData.duration.value,
              sortBy,
              searchText
            )
              .then(function (data) {
                var i; //, csvData, csvContent, csvContentArray, encodedUri, link;

                $scope.teamTableData = data.list.data;
                $scope.teamPage.options = data.list;

                if (data.delta) {
                  $scope.deltaInfo = data.delta;
                  $scope.deltaInfo.displayDuration = moment.duration($scope.deltaInfo.duration).asDays();
                  $scope.deltaInfo.displayTimestamp = moment($scope.deltaInfo.timestamp).format('LL');
                }

                for (i = 0; i < $scope.teamTableData.length; i++) {
                  var team = $scope.teamTableData[i];

                  if (team.previousRank != -1) {
                    team.rankChange = team.previousRank - team.currentRank;
                  } else {
                    team.rankChange = 'New';
                  }

                  if (team.securityMaturity) {
                    if (
                      team.securityMaturity.maturityLevel ==
                      $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
                    ) {
                      team.sortedSecurityMaturity = 1;
                      team.displaySecurityMaturity = translations.BEGINNER;
                    } else if (
                      team.securityMaturity.maturityLevel ==
                      $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
                    ) {
                      team.sortedSecurityMaturity = 2;
                      team.displaySecurityMaturity = translations.SECURITY_AWARE;
                    } else if (
                      team.securityMaturity.maturityLevel ==
                      $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
                    ) {
                      team.sortedSecurityMaturity = 3;
                      team.displaySecurityMaturity = translations.SECURITY_SKILLED;
                    } else if (
                      team.securityMaturity.maturityLevel ==
                      $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
                    ) {
                      team.sortedSecurityMaturity = 4;
                      team.displaySecurityMaturity = translations.SECURITY_CHAMPION;
                    }
                  }

                  if (team.challenges) {
                    if (team.challenges.correct === 0 && team.challenges.incorrect === 0) {
                      team.accuracy = 0;
                    } else {
                      team.accuracy = Math.round(
                        (team.challenges.correct / (team.challenges.correct + team.challenges.incorrect)) * 100
                      );
                    }
                  }

                  team.timeSpentDisplay = $rootScope.utility.shortFormatDuration(team.timeSpent);
                }
              })
              .catch(function (response) {
                ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
              })
              .finally(function () {
                AdminApiService.removeLoading();
              });
          } else {
            // type == LEADERBOARDTYPES.DEVELOPER

            if ($scope.devLeaderboardType == 'TOP_BOTTOM') {
              // fetch leaderboard with size 1 initially to get the total number of developers to decide if it makes sense to show top5/bottom5 leaderboards
              AdminApiService.addLoading();
              AdminApiService.getLeaderboard(
                optionsObj,
                $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null,
                $scope.topBottom5GetTotalPage.options,
                $scope.deltaDurationData.duration.value,
                sortBy,
                searchText
              )
                .then(function (data) {
                  var i; //, csvData, csvContent, csvContentArray, encodedUri, link;

                  // save some $scope copying
                  delete data.list.data;

                  $scope.topBottom5GetTotalPage.options = data.list;

                  // TODO this may need to be stored per leaderboard to allow for independent selection of delta timeframes
                  if (data.delta) {
                    $scope.deltaInfo = data.delta;
                    $scope.deltaInfo.displayDuration = moment.duration($scope.deltaInfo.duration).asDays();
                    $scope.deltaInfo.displayTimestamp = moment($scope.deltaInfo.timestamp).format('LL');
                  }

                  if (data.list.total > 10) {
                    $scope.canShowTopBottom5 = true;
                  } else {
                    // if we do not have enough developers to show top5/bottom5 then switch to the all developer leaderboard
                    $scope.canShowTopBottom5 = false;
                    $scope.showAllDevsLeaderboard();
                    $scope.getLeaderboard(target, type, sortBy, searchText);
                    return;
                  }

                  if ($scope.canShowTopBottom5) {
                    // get top 5
                    AdminApiService.addLoading();
                    AdminApiService.getLeaderboard(
                      optionsObj,
                      $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null,
                      $scope.top5DevPage.options,
                      $scope.deltaDurationData.duration.value,
                      sortBy,
                      searchText
                    )
                      .then(function (data) {
                        // process before assigning to $scope
                        for (i = 0; i < data.list.data.length; i++) {
                          processDeveloperRow(data.list.data[i], data.stories);
                        }

                        $scope.top5DeveloperTableData = data.list.data;
                      })
                      .catch(function (response) {
                        ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
                      })
                      .finally(function () {
                        AdminApiService.removeLoading();
                      });

                    // get bottom 5
                    AdminApiService.addLoading();
                    AdminApiService.getLeaderboard(
                      optionsObj,
                      $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null,
                      $scope.bottom5DevPage.options,
                      $scope.deltaDurationData.duration.value,
                      sortBy,
                      searchText
                    )
                      .then(function (data) {
                        // process before assigning to $scope
                        for (i = 0; i < data.list.data.length; i++) {
                          processDeveloperRow(data.list.data[i], data.stories);
                        }

                        $scope.bottom5DeveloperTableData = data.list.data;
                      })
                      .catch(function (response) {
                        ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
                      })
                      .finally(function () {
                        AdminApiService.removeLoading();
                      });
                  }
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
                })
                .finally(function () {
                  AdminApiService.removeLoading();
                });
            } else {
              // $scope.devLeaderboardType == "ALL"

              AdminApiService.addLoading();
              AdminApiService.getLeaderboard(
                optionsObj,
                $scope.forms.selectedLanguageFramework ? $scope.forms.selectedLanguageFramework.language : null,
                $scope.devPage.options,
                $scope.deltaDurationData.duration.value,
                sortBy,
                searchText
              )
                .then(function (data) {
                  var i; //, csvData, csvContent, csvContentArray, encodedUri, link;

                  // process before assigning to $scope
                  for (i = 0; i < data.list.data.length; i++) {
                    processDeveloperRow(data.list.data[i], data.stories);
                  }

                  // TODO this may need to be stored per leaderboard to allow for independent selection of delta timeframes
                  if (data.delta) {
                    $scope.deltaInfo = data.delta;
                    $scope.deltaInfo.displayDuration = moment.duration($scope.deltaInfo.duration).asDays();
                    $scope.deltaInfo.displayTimestamp = moment($scope.deltaInfo.timestamp).format('LL');
                  }

                  $scope.developerTableData = data.list.data;
                  $scope.devPage.options = data.list;
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_LEADERBOARD, response);
                })
                .finally(function () {
                  AdminApiService.removeLoading();
                });
            }
          }
        })
        .catch((e) => console.error(e));
    };

    $scope.csvStatus = {
      developer: 'idle',
      team: 'idle',
    };

    $scope.downloadCSV = function (type) {
      var target;
      var sendTarget;
      var optionsObj;

      target = $scope.selectedTargets.t1;
      sendTarget = { _id: target._id, type: target.type };

      optionsObj = {
        target: sendTarget,
        type: type,
        filterActiveUsers: $scope.filterActiveUsers,
      };

      // temporarily disable auto refresh timer
      $interval.cancel($scope.autoRefreshInterval);

      AdminApiService.addLoading();
      AdminApiService.getLeaderboardCSV(optionsObj, null, null, $scope.deltaDurationData.duration.value)
        .then(function (data) {
          if (data.url) {
            var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            $translate(
              [
                'CSV_REPORT_READY',
                'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
              ],
              { dataUrl: data.url }
            ).then(function (translations) {
              if (window.navigator.msSaveBlob && isIE) {
                // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
                swal({
                  title: translations.CSV_REPORT_READY,
                  //text: "Your requested CSV export is now ready for you to download.<br><a class='custom-sweet-alert-button' onclick='swal.close()' href='" + data.url + "'>Download</a>",
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
              } else {
                document.location.href = data.url;
                swal.close();
              }
            });
          } else {
            $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
              function (translations) {
                swal({
                  title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                  text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: translations.OK,
                });
              }
            );
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_TYPE_LEADERBOARD_CSV'], { type: type }).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_TYPE_LEADERBOARD_CSV, response);
          });
        })
        .finally(function () {
          AdminApiService.removeLoading();
          $scope.autoRefreshInterval = $interval(function () {
            if (
              $scope.shownPanel == PANELS.DASHBOARD &&
              $scope.selectedTargets.t1.type != $scope.TARGETTYPES.DEVELOPER
            ) {
              $scope.updateDashboardPanel();
            }
          }, REFRESH_TIMER_INTERVAL);
        });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.recalculateStats = function () {
      var target = $scope.selectedTargets.t1;
      var sendTarget = { _id: target._id, type: target.type };

      var optionsObj = {
        target: sendTarget,
      };

      $translate(['STATS_RECALC', 'STATS_RECALC_MSG', 'STATS_RECALC_CONFIRM', 'CONFIRM', 'CANCEL', 'OK'], {
        targetName: target.displayName,
        targetType: target.type,
      }).then(function (translations) {
        swal(
          {
            title: translations.STATS_RECALC,
            text: translations.STATS_RECALC_CONFIRM,
            type: 'warning',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonText: translations.OK,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              AdminApiService.recalculateStats(optionsObj)
                .then(function (_data) {
                  // Reload immediately to show the zeroe'd out stats, then, Update Stats on frontend after '30 seconds'
                  $state.reload();
                  $timeout(function () {
                    $state.reload();
                  }, 30000);

                  swal({
                    title: translations.STATS_RECALC,
                    text: translations.STATS_RECALC_MSG,
                    type: 'info',
                    showCancelButton: false,
                    closeOnConfirm: true,
                    confirmButtonText: translations.OK,
                  });
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError($translate.instant('ERROR_RECALC_STATS'), response);
                })
                .finally(function () {
                  AdminApiService.removeLoading();
                });
            }
          }
        );
      });
    };

    $scope.recalculateQuests = function () {
      var target = $scope.selectedTargets.t1;
      var sendTarget = { _id: target._id, type: target.type };

      var optionsObj = {
        target: sendTarget,
      };

      $translate(['QUESTS_RECALC', 'QUESTS_RECALC_MSG', 'QUESTS_RECALC_CONFIRM', 'CONFIRM', 'CANCEL', 'OK'], {
        targetName: target.displayName,
        targetType: target.type,
      }).then(function (translations) {
        swal(
          {
            title: translations.QUESTS_RECALC,
            text: translations.QUESTS_RECALC_CONFIRM,
            type: 'warning',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonText: translations.OK,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              AdminApiService.recalculateQuests(optionsObj)
                .then(function (_data) {
                  swal({
                    title: translations.QUESTS_RECALC,
                    text: translations.QUESTS_RECALC_MSG,
                    type: 'info',
                    showCancelButton: false,
                    closeOnConfirm: true,
                    confirmButtonText: translations.OK,
                  });
                })
                .catch(function (response) {
                  ErrorHandler.addHttpError($translate.instant('ERROR_RECALC_QUESTS'), response);
                })
                .finally(function () {
                  AdminApiService.removeLoading();
                });
            }
          }
        );
      });
    };

    $scope.downloadProgressCSV = function () {
      var target = $scope.selectedTargets.t1;
      var sendTarget = { _id: target._id, type: target.type };

      var optionsObj = {
        target: sendTarget,
      };

      // temporarily disable auto refresh timer
      $interval.cancel($scope.autoRefreshInterval);

      AdminApiService.addLoading();
      AdminApiService.getProgressCSV(optionsObj, null, null, $scope.deltaDurationData.duration.value)
        .then(function (data) {
          if (data.url) {
            var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            if (window.navigator.msSaveBlob && isIE) {
              $translate(
                [
                  'CSV_REPORT_READY',
                  'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
                ],
                { dataUrl: data.url }
              ).then(function (translations) {
                // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
                swal({
                  title: translations.CSV_REPORT_READY,
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
              });
            } else {
              document.location.href = data.url;
              swal.close();
            }
          } else {
            $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
              function (translations) {
                swal({
                  title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                  text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: translations.OK,
                });
              }
            );
          }
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_TARGETTYPE_PROGRESS_CSV'], { targetType: target.type }).then(
            function (translations) {
              ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_TARGETTYPE_PROGRESS_CSV, response);
            }
          );
        })
        .finally(function () {
          AdminApiService.removeLoading();
          $scope.autoRefreshInterval = $interval(function () {
            if (
              $scope.shownPanel == PANELS.DASHBOARD &&
              $scope.selectedTargets.t1.type != $scope.TARGETTYPES.DEVELOPER
            ) {
              $scope.updateDashboardPanel();
            }
          }, REFRESH_TIMER_INTERVAL);
        });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    $scope.downloadChallengeLogCSV = function () {
      var target = $scope.selectedTargets.t1;
      var sendTarget = { _id: target._id, type: target.type };

      var optionsObj = {
        target: sendTarget,
      };

      // temporarily disable auto refresh timer
      $interval.cancel($scope.autoRefreshInterval);

      AdminApiService.addLoading();
      AdminApiService.getChallengeLogCSV(optionsObj, null, null, $scope.deltaDurationData.duration.value)
        .then(function (data) {
          if (data.url) {
            var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
            if (window.navigator.msSaveBlob && isIE) {
              // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
              $translate(
                [
                  'CSV_REPORT_READY',
                  'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
                ],
                { dataUrl: data.url }
              ).then(function (translations) {
                swal({
                  title: translations.CSV_REPORT_READY,
                  text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                  type: 'success',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                });
              });
            } else {
              document.location.href = data.url;
              swal.close();
            }
          } else {
            //if csv is currently being generated
            if (data.isBeingGenerated) {
              $translate([
                'CANNOT_GENERATE_CSV_REPORT',
                'YOU_CURRENTLY_HAVE_A_CHALLENGES_PLAYED_CSV_BEING_GENERATED',
                'OK',
              ]).then(function (translations) {
                swal({
                  title: translations.CANNOT_GENERATE_CSV_REPORT,
                  //text: "You currently have a leaderboard csv file being generated. Please wait for that to complete and try again.<br><a class='custom-sweet-alert-button' onclick='swal.close()' href='" + data.url + "'>Download</a>",
                  text: translations.YOU_CURRENTLY_HAVE_A_CHALLENGES_PLAYED_CSV_BEING_GENERATED,
                  type: 'error',
                  html: true,
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: translations.OK,
                });
              });
            } else {
              $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
                function (translations) {
                  swal({
                    title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                    text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                    type: 'success',
                    html: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: translations.OK,
                  });
                }
              );
            }
          }
        })
        .catch(function (response) {
          ErrorHandler.addHttpError('Error retrieving ' + target.type + ' challenges played CSV', response);
        })
        .finally(function () {
          AdminApiService.removeLoading();
          $scope.autoRefreshInterval = $interval(function () {
            if (
              $scope.shownPanel == PANELS.DASHBOARD &&
              $scope.selectedTargets.t1.type != $scope.TARGETTYPES.DEVELOPER
            ) {
              $scope.updateDashboardPanel();
            }
          }, REFRESH_TIMER_INTERVAL);
        });
      $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CSV_REPORT,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    };

    function processDeveloperRow(row, stories) {
      var developer = row;

      developer.name =
        developer.profile.name.first +
        (developer.profile.name.middle ? ' ' + developer.profile.name.middle : '') +
        ' ' +
        developer.profile.name.last;
      developer.name = developer.name.trim() || developer.profile.email;
      developer.avatar = developer.profile.displayPic;

      if (developer.previousRank != -1) {
        developer.rankChange = developer.previousRank - developer.currentRank;
      } else {
        developer.rankChange = 'New';
      }

      if (developer.securityMaturity) {
        if (
          developer.securityMaturity.maturityLevel ==
          $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
        ) {
          developer.sortedSecurityMaturity = 1;
          developer.displaySecurityMaturity = $translate.instant('BEGINNER');
        } else if (
          developer.securityMaturity.maturityLevel == $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
        ) {
          developer.sortedSecurityMaturity = 2;
          developer.displaySecurityMaturity = $translate.instant('SECURITY_AWARE');
        } else if (
          developer.securityMaturity.maturityLevel ==
          $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
        ) {
          developer.sortedSecurityMaturity = 3;
          developer.displaySecurityMaturity = $translate.instant('SECURITY_SKILLED');
        } else if (
          developer.securityMaturity.maturityLevel ==
          $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
        ) {
          developer.sortedSecurityMaturity = 4;
          developer.displaySecurityMaturity = $translate.instant('SECURITY_CHAMPION');
        } else {
          developer.sortedSecurityMaturity = 0;
          developer.displaySecurityMaturity = $translate.instant('UNKNOWN');
        }

        if (developer.securityMaturity.challenges) {
          developer.accuracy = Math.round(developer.securityMaturity.challenges.ratioCorrect * 100);
        }

        if (developer.securityMaturity.hints) {
          developer.confidenceLevel = developer.securityMaturity.hints.confidenceLevel;
        }
      }

      // PORTAL-1038 - null for invited users that have not accepted the invite
      if (developer.registered) {
        developer.registeredDisplay = moment(developer.registered).format('LL');
      }

      developer.timeSpentDisplay = $rootScope.utility.shortFormatDuration(developer.timeSpent);
      developer.delta.displayTimeSpent = $rootScope.utility.shortFormatDuration(developer.delta.timeSpent);

      for (var i = 0; i < developer.questProgress.length; i++) {
        var langStats = developer.questProgress[i];
        var lang = langStats.language;

        // using JSON parse/stringify hack to clone the data since it was originally JSON anyway
        // if we need true clone later we can switch to _.cloneDeep(). angular.copy has horrible performance particularly in Internet Explorer
        langStats.story = JSON.parse(JSON.stringify(stories[lang._id + ':' + lang._framework]));

        $rootScope.utility.stitchQuestProgressStory(langStats);
      }
    }

    $scope.generateDateLabels = function (fromDate, toDate) {
      var m1 = moment(new Date(fromDate));
      var m2 = moment(new Date(toDate));

      var addMe = {};
      var xLabels = [];
      if ($scope.dateRange.statsInterval && m1 <= m2) {
        if ($scope.dateRange.statsInterval == INTERVALS.DAYS) {
          addMe = { days: -1 };
        } else if ($scope.dateRange.statsInterval == INTERVALS.WEEKS) {
          addMe = { weeks: -1 };
        } else if ($scope.dateRange.statsInterval == INTERVALS.MONTHS) {
          addMe = { months: -1 };
        }

        while (m1 <= m2) {
          xLabels.push(m2.format('LL'));
          m2.add(addMe);
        }
      }
      return xLabels.reverse();
    };

    $scope.isShowingFilters = false;
    $scope.toggleFilters = function () {
      $scope.isShowingFilters = !$scope.isShowingFilters;
    };

    /*** calendar pickers ***/
    $scope.todayDate = new Date();
    $scope.fromDateOpen = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dateRange.fromDateOpened = true;
    };

    $scope.toDateOpen = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dateRange.toDateOpened = true;
    };

    $scope.dateOptions = {
      startingDay: 1,
      showWeeks: false,
    };

    $scope.comparisonSeries = [];

    /*** strength weakness chart ***/
    $translate([
      'AUTH_ACCESS_CONTROL',
      'SECURITY_CONFIGURATION',
      'SECURE_DEVELOPMENT_PRACTICES',
      'SENSITIVE_DATA_PROT',
      'DATA_HANDLING',
    ]).then(function (translations) {
      $scope.swLabels = [
        translations.AUTH_ACCESS_CONTROL,
        translations.SECURITY_CONFIGURATION,
        translations.SECURE_DEVELOPMENT_PRACTICES,
        translations.SENSITIVE_DATA_PROT,
        translations.DATA_HANDLING,
      ];
    });
    //$scope.strengthWeaknessOverTimeData = [];
    //$scope.strengthWeaknessData = [];
    //$scope.myStrengthWeaknessData = [];

    $scope.swOptions = {
      responsive: true,
      maintainAspectRatio: true,
      scale: {
        ticks: {
          min: 0,
          max: 100,
          display: false,
        },
        pointLabels: {
          fontColor: '#FFFFFF',
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel;
            label += '%';
            return label;
          },
        },
      },
    };

    /*** time spent chart ***/
    //$scope.averageHoursData = [];
    //$scope.averageHoursPercentData = [];
    //$scope.totalHoursData = [];
    //$scope.totalHoursPercentData = [];

    /*** points chart ***/
    $scope.averagePointsData = [];
    $scope.averagePointsPercentData = [];

    $scope.easyPieTimeOptions = {
      animate: {
        duration: 500,
        enabled: true,
      },
      barColor: 'rgba(255,255,255,0.6)',
      trackColor: 'rgba(255,255,255,0.1)',
      scaleColor: '#FFF',
      lineWidth: 10,
      lineCap: 'circle',
      size: 104,
    };

    $scope.easyPiePointsOptions = {
      animate: {
        duration: 500,
        enabled: true,
      },
      barColor: 'rgba(255,255,255,0.6)',
      trackColor: 'rgba(255,255,255,0.1)',
      scaleColor: false,
      lineWidth: 10,
      lineCap: 'circle',
      size: 90,
    };

    $scope.donutOptions = {
      cutoutPercentage: 70,
      responsive: true,
      maintainAspectRatio: true,
    };

    /*** hours spent chart (chartjs) ***/
    $scope.xSeriesTimeLabels = [];
    $scope.averageHoursOverTimeData = [];

    $scope.lineOverTimeOptions = {
      scaleOverride: true,
      scaleSteps: 6,
      scaleStepWidth: 4,
      scaleStartValue: 0,
      responsive: true,
      //maintainAspectRatio: false,
    };
    Chart.defaults.global.scaleLineColor = '#666';
    Chart.defaults.global.scaleFontColor = '#ccc';

    /*** my current distribution (chartjs) ***/
    $scope.distLabels = [
      $scope.SECURITYMATURITY.BEGINNER,
      $scope.SECURITYMATURITY.AWARE,
      $scope.SECURITYMATURITY.SKILLED,
      $scope.SECURITYMATURITY.CHAMPION,
    ];
    /*$scope.myDistributionData = [
      rand24(),
      rand24(),
      rand24(),
      rand24()
    ];*/

    $scope.chartJSDonutColours = [
      'rgba(255,255,255,0.2)',
      'rgba(255,255,255,0.5)',
      'rgba(255,255,255,0.8)',
      'rgba(255,183,0,0.8)',
    ];

    $scope.chartJSDonutDataset = {
      borderWidth: [0, 0, 0, 0],
      hoverBackgroundColor: [
        'rgba(255,255,255,0.25)',
        'rgba(255,255,255,0.6)',
        'rgba(255,255,255,1.0)',
        'rgba(255,183,0,1.0)',
      ],
    };

    $scope.chartJSLineColours = [
      {
        strokeColor: 'rgba(255,255,255,0.3)',
        //pointHighlightStroke: 'rgba(255,255,255,1)',
        fillColor: 'rgba(255,255,255,0.3)',
        pointColor: 'rgba(255,255,255,1)',
      },
      {
        strokeColor: 'rgba(0,0,0,0.1)',
        //pointHighlightStroke: 'rgba(255,255,255,1)',
        fillColor: 'rgba(0,0,0,0.1)',
        pointColor: 'rgba(0,0,0,0.5)',
      },
    ];

    $scope.chartJSRadarColours = ['rgba(255,255,255,0.3)', 'rgba(0,0,0,0.1)'];

    /*** distribution over time chart (nvd3) ***/

    let date = new Date();
    let timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    $scope.distOptions = {
      chart: {
        type: 'stackedAreaChart',
        height: 450,
        margin: {
          top: 20,
          right: 20,
          bottom: 60,
          left: 40,
        },
        x: function (d) {
          return d[0] - timezoneOffset;
        },
        y: function (d) {
          return d[1];
        },
        useVoronoi: false,
        clipEdge: true,
        transitionDuration: 500,
        useInteractiveGuideline: true,
        style: 'stack',
        xAxis: {
          showMaxMin: false,
          tickFormat: function (d) {
            return moment(d - timezoneOffset).format('LL');
          },
        },
        //xScale: d3.time.scale()
      },
    };

    $scope.devLeaderboardType = 'TOP_BOTTOM';
    $scope.showTopBottomLeaderboard = function () {
      $scope.devLeaderboardType = 'TOP_BOTTOM';
      $scope.getLeaderboard(
        $scope.selectedTargets.t1,
        LEADERBOARDTYPES.DEVELOPER,
        $scope.sorting.sortdevLeaderboardByParam,
        $scope.searchText
      );
    };

    $scope.showAllDevsLeaderboard = function () {
      $scope.devLeaderboardType = 'ALL';
      $scope.getLeaderboard(
        $scope.selectedTargets.t1,
        LEADERBOARDTYPES.DEVELOPER,
        $scope.sorting.sortdevLeaderboardByParam,
        $scope.searchText
      );
    };

    $scope.sorting = {
      sortdevLeaderboardBy: 'currentRank',
      sortdevLeaderboardByParam: 'currentRank',
      sortdevLeaderboardReverse: false,
    };

    $scope.setSortdevLeaderboardBy = function (s) {
      if ($scope.sorting.sortdevLeaderboardBy == s) {
        $scope.sorting.sortdevLeaderboardReverse = !$scope.sorting.sortdevLeaderboardReverse;
        $scope.sorting.sortdevLeaderboardByParam = ($scope.sorting.sortdevLeaderboardReverse ? '-' : '') + s;
      } else {
        $scope.sorting.sortdevLeaderboardBy = s;
        $scope.sorting.sortdevLeaderboardByParam = s;
        $scope.sorting.sortdevLeaderboardReverse = false;
      }
      $scope.getLeaderboard(
        $scope.selectedTargets.t1,
        LEADERBOARDTYPES.DEVELOPER,
        $scope.sorting.sortdevLeaderboardByParam,
        $scope.searchText
      );
    };

    $scope.displayTime = $rootScope.utility.quickFormatTime;

    let resize = function () {
      $('#filter-panel').height($('#main-view').height());
    };

    $(window).on('resize', resize);

    $timeout(function () {
      $scope.initState();

      $scope.autoRefreshInterval = $interval(function () {
        if ($scope.shownPanel == PANELS.DASHBOARD && $scope.selectedTargets.t1.type != $scope.TARGETTYPES.DEVELOPER) {
          $scope.updateDashboardPanel();
        }
      }, REFRESH_TIMER_INTERVAL);
    });
  },
]);

app.controller('ActivityExportModal', [
  '$uibModalInstance',
  '$scope',
  function ($uibModalInstance, $scope) {
    $scope.isFormValid = function () {
      // Testing whether form is valid but also trigger the inner date-range-selector function to check the dates are valid
      return $scope.exportTimeForm.$valid;
    };

    $scope.getActivitySummary = function (type, _id) {
      if (!$scope.isFormValid()) return;

      switch (type) {
        case 'team':
          $scope.teamActivitySummary(_id);
          break;
        case 'developer':
          $scope.userActivitySummary(_id);
          break;
        case 'company':
          $scope.companyActivitySummary(_id);
          break;
      }

      $scope.close();
    };

    $scope.close = function () {
      $uibModalInstance.close();
    };
  },
]);
