import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

/*********** list ***********/
app.controller('AssessmentsCertificatesController', [
  '$scope',
  '$uibModal',
  '$translate',
  'AssessmentsApiService',
  'ErrorHandler',
  'DownloadFile',
  /*										  */ function ($scope, $uibModal, $translate, AssessmentsApiService, ErrorHandler, DownloadFile) {
    $scope.templates = [];
    $scope.item = null;
    $scope.titleInputProps = { maxLength: 80, name: 'title', required: true };
    $scope.descriptionInputProps = { maxLength: 200, name: 'description' };

    // ==============================================================================
    // EXPORTED METHODS
    // ==============================================================================
    $scope.list = list;
    $scope.create = create;
    $scope.edit = edit;
    $scope.preview = preview;
    $scope.download = download;
    $scope.goBack = goBack;
    $scope.upload = upload;

    // initialization code
    setTimeout(function () {
      $scope.$apply(list());
    }, 0);

    // ==============================================================================
    // MAIN METHODS IMPLEMENTATIONS
    // ==============================================================================

    function goBack() {
      window.history.back();
    }

    // ==============================================================================
    // Retrieve a certificate template list to be displayed to the user.
    function list() {
      AssessmentsApiService.retrieveCertificateTemplatesList().then(function (templates) {
        $scope.templates = templates;
      });
    }

    function openCreateEditModal() {
      $scope.modalInstance = $uibModal.open(
        {
          templateUrl: 'assessments/certificates/AddEditCertificateTemplateModal',
          controller: 'AddEditCertificateTemplateController',
          size: 'lg',
          scope: $scope,
        },
        'add'
      );
    }

    function create() {
      edit({});
    }

    function edit(template) {
      $scope.originalItem = template;
      $scope.item = JSON.parse(JSON.stringify(template));

      if (!$scope.item.name) {
        $scope.item.name = '';
      }

      openCreateEditModal();
    }

    function upload() {
      $('#CertificateTemplateUploadElement').click();
    }

    // ==============================================================================
    // Pre-visualize an existing template.
    function preview(template) {
      var templateCopy = _.clone(template, true);
      delete templateCopy._company;
      AssessmentsApiService.addLoading();
      AssessmentsApiService.previewCertificateTemplate(templateCopy)
        .then(function (data) {
          var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
          $translate(
            [
              'CSV_REPORT_READY',
              'PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
              'PDF_CERTIFICATE_READY',
            ],
            { content: data.body.url }
          ).then(function (translations) {
            if (window.navigator.msSaveBlob && isIE) {
              // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
              swal({
                title: translations.PDF_CERTIFICATE_READY,
                text: translations.PDF_CERTIFICATE_READY_DESC_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
                type: 'success',
                html: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
            } else {
              document.location.href = data.body.url;
              swal.close();
            }
          });
        })
        .catch(function (response) {
          $translate(['ERROR_RETRIEVING_CERTIFICATE']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_CERTIFICATE, response);
          });
        })
        .finally(function () {
          AssessmentsApiService.removeLoading();
        });
      $translate(['GENERATING_CERTIFICATE', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE']).then(
        function (translations) {
          swal({
            title: translations.GENERATING_CERTIFICATE,
            text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_CERTIFICATE,
            type: 'info',
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      );
    }

    // ==============================================================================
    // Allow to download a base template to adapt and create a new one.
    // Not converted to new download mechanism yet due to effort for now, and no
    // existing issues with HTML download so far
    function download(template) {
      DownloadFile.file('text/plain', template.name + '.html', template.template);
    }
  },
]);

app.controller('AddEditCertificateTemplateController', [
  '$scope',
  '$translate',
  'AssessmentsApiService',
  /*											 */ function ($scope, $translate, AssessmentsApiService) {
    $scope.save = save;
    $scope.remove = remove;
    $scope.close = close;
    $scope.formError = null;

    // initialization code
    setTimeout(function () {
      var input = angular.element('#CertificateTemplateUploadElement');
      input[0].onchange = handleFiles;
    }, 2);

    // ==============================================================================
    // MAIN METHODS IMPLEMENTATIONS
    // ==============================================================================

    function handleFiles() {
      var input = this;
      if (!input.files || !input.files[0]) return true;

      var file = this.files[0];

      var reader = new FileReader();
      reader.onload = function (e) {
        var item = $scope.item;
        item.template = e.target.result;
        $scope.$apply();
      };
      reader.readAsText(file);
    }

    // ==============================================================================
    // Save the template for future usage as an assessment certificate.
    function save(template) {
      if ($scope.certificate_template.$invalid) {
        return;
      }

      AssessmentsApiService.saveCertificateTemplate(template).then(function (data) {
        if (data.data && data.data.details && data.data.details.validationErrors) {
          $scope.formError = true;
        } else {
          $scope.formError = null;
          if ($scope.item._id) {
            var idx = $scope.templates.indexOf($scope.originalItem);
            $scope.templates.splice(idx, 1, data);
          } else {
            $scope.templates.splice(0, 0, data);
          }
          close();
        }
      });
    }

    function close() {
      $scope.modalInstance.dismiss();
    }

    // ==============================================================================
    // Remove a template from the list of available templates.
    // On successfull APi call, remove it from controller-contained list.
    function remove(template) {
      // TODO: add confirm dialog
      if ($scope.removeConfirmation) {
        AssessmentsApiService.removeCertificateTemplate(template._id).then(function (_data) {
          $scope.list();
          close();
        });
      } else {
        $scope.removeConfirmation = true;
        setTimeout(function () {
          $scope.$apply(function () {
            $scope.removeConfirmation = false;
          });
        }, 2000);
      }
    }

    // Design System form controls - start
    $scope.validateTitleControlErrors = function () {
      if ($scope.certificate_template.title.$error.required) {
        $scope.certificate_template.title.$error.message = $translate.instant('The_TITLE_CANNOT_BE_BLANK');
      } else if ($scope.certificate_template.title.$error.maxlength) {
        $scope.certificate_template.title.$error.message = $translate.instant('EXCEEDED_MAX_LENGTH');
      } else {
        $scope.certificate_template.title.$error.message = '';
      }
    };

    $scope.validateDescriptionControlErrors = function () {
      if ($scope.certificate_template.description.$error.maxlength) {
        $scope.certificate_template.description.$error.message = $translate.instant('EXCEEDED_MAX_LENGTH');
      } else {
        $scope.certificate_template.description.$error.message = '';
      }
    };
    // Design System form controls - end
  },
]);
