import angular from 'angular';
import MODULE from './module';
import { onSsoTokenLoginResponse } from './login-helpers';
import _ from 'lodash';
import { USER_ROLES } from './constants';

const app = angular.module(MODULE);
const AuthApiPrefix = '/auth';

// Main auth service
app.factory('AuthService', [
  '$q',
  '$log',
  '$http',
  '$rootScope',
  '$window',
  '$translate',
  '$state',
  'Session',
  'USER_STATUS',
  'UserPreferences',
  'ErrorHandler',
  'HttpConfigService',
  'PlatformLanguagesService',
  'LanguageUtils',
  'SESSION_HEADER',
  /*				 */
  function (
    $q,
    $log,
    $http,
    $rootScope,
    $window,
    $translate,
    $state,
    Session,
    USER_STATUS,
    UserPreferences,
    ErrorHandler,
    HttpConfigService,
    PlatformLanguagesService,
    LanguageUtils,
    SESSION_HEADER
  ) {
    let endpointSelected = false;
    const { SCW_ENV } = $window;
    const setEnvName = (regionKey) => {
      if (!SCW_ENV.ENV_NAME) {
        SCW_ENV.ENV_NAME = 'local';
        return;
      }
      SCW_ENV.ENV_NAME = SCW_ENV.ENV_NAME.includes('prod') ? `prod-${regionKey}` : SCW_ENV.ENV_NAME;
    };

    const endpointUnaware = {
      get: function (path, config) {
        $log.debug('Endpoint unaware GET');
        const defer = $q.defer();

        config.isRetry = true;
        config.isEndpointUnawareCheck = true;

        if (!endpointSelected) {
          // try US endpoint
          $log.debug('Trying US API endpoint', SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US], path, config);
          $http
            .get(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US] + path, config)
            .then(function (response) {
              $log.debug('Selecting US API endpoint');

              SCW_ENV.ApiEndpoint = SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US];
              // if prod set region type, otherwise default (sit/uat/local) // prod => prod-us, uat, sit, undefined,
              setEnvName('us');
              authService.onEndpointSelected();
              defer.resolve(response);
            })
            .catch(function (_response) {
              // try EU endpoint
              $log.debug('Trying EU API endpoint');
              $http
                .get(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU] + path, config)
                .then(function (response) {
                  $log.debug('Selecting EU API endpoint');

                  SCW_ENV.ApiEndpoint = SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU];
                  // if prod set region type, otherwise default (sit/uat/local)
                  setEnvName('eu');
                  authService.onEndpointSelected();

                  defer.resolve(response);
                })
                .catch(function (response) {
                  $log.debug('Falling back to US API endpoint');
                  defer.reject(response);
                });
            });

          return defer.promise;
        }

        return $http.get(SCW_ENV.ApiEndpoint + path, config);
      },
      post: function (path, data, config) {
        $log.debug('Endpoint unaware POST');
        const defer = $q.defer();

        config.isRetry = true;
        config.isEndpointUnawareCheck = true;

        if (!endpointSelected) {
          // try US endpoint
          $log.debug('Trying US API endpoint');
          $http
            .post(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US] + path, data, config)
            .then(function (response) {
              $log.debug('Selecting US API endpoint');

              SCW_ENV.ApiEndpoint = SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US];
              // if prod set region type, otherwise default (sit/uat/local)
              setEnvName('us');
              authService.onEndpointSelected();

              defer.resolve(response);
            })
            .catch(function (response) {
              const usResponse = response;

              // try EU endpoint
              $log.debug('Trying EU API endpoint');
              $http
                .post(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU] + path, data, config)
                .then(function (response) {
                  $log.debug('Selecting EU API endpoint');

                  SCW_ENV.ApiEndpoint = SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU];
                  // if prod set region type, otherwise default (sit/uat/local)
                  setEnvName('eu');
                  authService.onEndpointSelected();
                  defer.resolve(response);
                })
                .catch(function (response) {
                  $log.debug('Falling back to US API endpoint');
                  response.usResponse = usResponse;
                  defer.reject(response);
                });
            });

          return defer.promise;
        }

        return $http.post(SCW_ENV.ApiEndpoint + path, data, config);
      },
    };

    const allEndpoints = {
      post: function (path, data, config) {
        $log.debug('All endpoints POST');

        config.isRetry = true;
        config.isEndpointUnawareCheck = true;

        $http.post(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US] + path, data, config);
        return $http.post(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU] + path, data, config);
      },
    };

    const authService: any = {};
    const ALIASES = {
      'company admin': ['company administrator', 'comp admin', 'co admin', 'ca'],
      'team manager': ['team manager', 'team mngr', 't mngr', 'tm'],
      developer: ['developer', 'dev', 'd'],
    };

    authService.getSenseiAuthToken = function () {
      return $window.sessionStorage.getItem('senseiAuthToken');
    };

    /* Trust agent listener for authorization flow from GitHub */
    authService.hasTrustAgentProvider = function () {
      return new URLSearchParams($window.location.search.substring(1)).has('trust-agent-provider');
    };

    authService.roleFromAbbreviation = function (abbreviation) {
      for (var role in ALIASES) {
        if (role === abbreviation) {
          return role;
        }

        if (ALIASES[role].indexOf(abbreviation) > -1) {
          return role;
        }
      }
    };

    authService.resetEndpoint = function () {
      endpointSelected = false;
    };

    authService.setEndpoint = function (endpoint) {
      // endpoint must be US or EU string
      SCW_ENV.ApiEndpoint = SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES[endpoint]];
      setEnvName(endpoint.toLowerCase());

      authService.onEndpointSelected();
    };

    authService.onEndpointSelected = function () {
      endpointSelected = true;
      $rootScope.startCheckInterval(true);

      // mark metadata languages with game availability status
      $rootScope.languageList = [];
      PlatformLanguagesService.addStats(metadata.languages).then(function () {
        $rootScope.languageList = LanguageUtils.languageList();
        $rootScope.languageName = LanguageUtils.languageName;
      });
    };

    authService.createSession = function (sessionId, loginUser, keepMeLoggedIn) {
      const UserStatus = $rootScope.metadata.constants.user.status;
      const allowedStatuses = [UserStatus.ENABLED, UserStatus.REGISTERED, UserStatus.READ_ONLY];
      if (allowedStatuses.indexOf(loginUser.status) > -1) {
        Session.create(sessionId, loginUser, keepMeLoggedIn);
        $window.DD_RUM?.onReady(() => {
          $window.DD_RUM.setUser({ id: loginUser?._id });
        });
        $window.DD_LOGS?.onReady(() => {
          $window.DD_LOGS.setUser({ id: loginUser?._id });
        });

        // PLAT-941 update session user after create to ensure we get the latest user object including labs
        $rootScope.userUpdate();
      } else {
        throw new Error('User account is not active');
      }

      return UserPreferences.load().then(function () {
        $rootScope.updateRootPrefs();
        return loginUser;
      });
    };

    authService.ssoTokenLogin = function (token, domain) {
      return endpointUnaware
        .post(
          AuthApiPrefix + '/sso/login',
          {
            token: token,
          },
          HttpConfigService.getHttpConfigNoSessionHeader()
        )
        .then(function (response) {
          $log.debug('Login response: ', response.data);
          return onSsoTokenLoginResponse(response, $window, domain, authService);
        });
    };

    function getEndpointIndex() {
      const apiEndpointsKey = _.findKey(SCW_ENV.ApiEndpoints, (endpoint) => endpoint === SCW_ENV.ApiEndpoint);
      if (apiEndpointsKey) {
        return _.findKey(SCW_ENV.API_ENDPOINT_INDEXES, (index) => index === parseInt(apiEndpointsKey));
      }
    }

    authService.setSSOCompanyContext = function (data) {
      const endpoint = getEndpointIndex();
      $window.localStorage.setItem('ssoCompanyContext', JSON.stringify({ ...data, endpoint }));
    };

    authService.getSSOCompanyContext = function () {
      const ssoCompanyContext = $window.localStorage.getItem('ssoCompanyContext');
      return ssoCompanyContext ? JSON.parse(ssoCompanyContext) : {};
    };
    authService.clearSSOCompanyContext = function () {
      $window.localStorage.removeItem('ssoCompanyContext');
    };

    authService.ssoAcceptTermsAndConditions = function (acceptance) {
      $log.debug('SSO - ssoAcceptTermsAndConditions()');

      return $http(
        angular.extend(
          {
            method: 'POST',
            url: SCW_ENV.ApiEndpoint + AuthApiPrefix + '/sso-accept',
            data: acceptance,
          },
          HttpConfigService.getHttpConfig()
        )
      ).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    authService.ssoRejectTermsAndConditions = function (rejection) {
      $log.debug('SSO - ssoRejectTermsAndConditions()');

      return $http(
        angular.extend(
          {
            method: 'POST',
            url: SCW_ENV.ApiEndpoint + AuthApiPrefix + '/sso-reject',
            data: rejection,
          },
          HttpConfigService.getHttpConfig()
        )
      ).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    authService.login = function (credentials) {
      $log.debug('authService - login()');
      return endpointUnaware
        .post(AuthApiPrefix + '/login', credentials, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          $log.debug('Login response: ', response.data);
          const { sessionId, user } = response.data;
          return authService.createSession(sessionId, user, credentials.remember);
        });
    };

    authService.loginImage = function (eid) {
      return endpointUnaware.get('/public/login-image/' + eid, HttpConfigService.getHttpConfigNoSessionHeader());
    };

    function getValidEndpoints(endpoint) {
      if (SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES[endpoint]]) {
        return [SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES[endpoint]]];
      }
      const sameEndpoints =
        SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US] === SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU];
      if (sameEndpoints) {
        return [SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US]];
      }
      return [
        SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US],
        SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU],
      ];
    }

    function createSSOCheckRequests(endpoints, data) {
      const path = `${AuthApiPrefix}/sso/check`;
      const config = HttpConfigService.getHttpConfig();
      config.isRetry = true;
      config.isEndpointUnawareCheck = true;
      return endpoints.map((endpoint) => $http.post(`${endpoint}${path}`, data, config));
    }

    authService.checkAuthContext = async function (email) {
      const { cciv, ccdata, endpoint } = authService.getSSOCompanyContext();
      const endpoints = getValidEndpoints(endpoint);
      const requestPromises = createSSOCheckRequests(endpoints, {
        email: email,
        ...(cciv && ccdata && { cciv, ccdata }),
      });

      const promiseResult = await Promise.allSettled(requestPromises);
      const { fulfilled, rejected } = promiseResult.reduce(
        (acc, curr) => {
          if (curr.status === 'fulfilled') {
            acc.fulfilled.push(curr.value.data);
          } else {
            acc.rejected.push(curr.reason.data);
          }
          return acc;
        },
        { fulfilled: [], rejected: [] }
      );

      if (rejected.length === requestPromises.length) {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = null;
        $state.go('unavailable');
        return;
      }

      const ssoRegions = fulfilled.filter(
        (data) => data.provider === 'sso' || !!data.multiple_companies_or_security_groups_same_domain
      );

      if (ssoRegions.length) {
        return ssoRegions.length > 1 ? { multiple_companies_or_security_groups_same_domain: true } : ssoRegions.pop();
      }

      if (fulfilled.length) {
        return fulfilled.pop();
      }

      throw rejected.pop();
    };

    authService.goAnonymous = function (inviteSource) {
      $log.debug('authService - createEphemeralSession');
      const httpConfig = HttpConfigService.getHttpConfig();
      httpConfig.params = {
        lang: $translate.use(),
        inviteSource: inviteSource,
      };
      return $http.get(SCW_ENV.ApiEndpoint + AuthApiPrefix + '/anonymous', httpConfig).then(function (response) {
        const { sessionId, user } = response.data;
        const isSessionRemembered = Session.isSessionRemembered();
        $log.debug('goAnonymous() response: ', user);

        return authService.createSession(sessionId, user, isSessionRemembered);
      });
    };

    authService.resumeSession = function () {
      $log.debug('authService - resumeSession(): ', Session.getSessionId());
      return endpointUnaware
        .get(AuthApiPrefix + '/resume', HttpConfigService.getHttpConfig())
        .then(function (response) {
          const { sessionId, user } = response.data;
          $log.debug('resumeSession() response: ', user);

          return authService.createSession(sessionId, user);
        })
        .catch(function (response) {
          Session.destroy();
          throw response;
        });
    };

    authService.refreshUser = function () {
      $log.debug('authService - refreshUser()');
      return $http
        .get(SCW_ENV.ApiEndpoint + AuthApiPrefix + '/resume', HttpConfigService.getHttpConfig())
        .then(function (response) {
          // why does a 401 response still trigger then instead of catch? no idea...
          // so check response exists otherwise we get undefined errors
          if (response) {
            const { user } = response.data;
            $log.debug('refreshUser() response: ', user);

            return user;
          }
        });
    };

    authService.isAuthenticated = function () {
      return !!Session.user;
    };

    authService.hasCookiePolicy = function () {
      return !!SCW_ENV.OSANO_CUST_ID && !!SCW_ENV.OSANO_CONFIG_ID;
    };

    authService.isStrictSso = function (target) {
      if (!authService.isAuthenticated()) {
        return false;
      }

      target = target || Session.user;
      let company;
      // is user
      if (target.properties) {
        company = target.properties.company;
      } else if (target._cid) {
        company = target.company;
      } else {
        company = target;
      }

      const isStrict = _.get(company, 'security.saml.mappings.strategy');

      return !!isStrict;
    };

    authService.isSSOUser = function () {
      return !!$window.localStorage.ssodomain;
    };

    authService.mainRole = function (roles) {
      if (roles && roles.roles) {
        roles = roles.roles;
      }

      if (!roles) {
        return;
      }

      const precedence = ['admin', 'company admin', 'team manager', 'developer'];
      let role, idx, found;
      for (idx = 0; idx < precedence.length; idx++) {
        role = precedence[idx];
        found = _.find(roles, function (userRole) {
          return userRole === role;
        });

        if (found) {
          return found;
        }
      }
    };

    /**
     * This function checks an array of input roles, and will return true if any of those roles are authorized.
     * E.G. an input array of isAuthorized([roleA, roleB, roleC]) is equal to
     * isAuthorized(roleA) || isAuthorized(roleB) || isAuthorized(roleC).
     */
    authService.isAuthorized = function (authorizedRoles) {
      if (!authService.isAuthenticated()) {
        return false;
      }

      if (!angular.isArray(authorizedRoles)) {
        authorizedRoles = [authorizedRoles];
      }

      let hasPoss = false;
      for (let i = 0; i < authorizedRoles.length; i++) {
        let roleCheck = authorizedRoles[i];
        const isNeg = roleCheck && roleCheck.substr(0, 1) === '!';
        if (isNeg) {
          roleCheck = roleCheck.substring(1);
        } else {
          hasPoss = true;
        }

        if (Session.user.roles.indexOf(roleCheck) > -1)
          //:
          return !isNeg;
      }

      return !hasPoss;
    };

    authService.isFeatureEnabled = function (feature) {
      if (!authService.isAuthenticated()) {
        return false;
      }

      const user = Session.user;
      const team = user && user.properties && user.properties.team;
      const company = user && user.properties && user.properties.company;
      const isSCWAdmin = user && user.roles.indexOf('scw admin') > -1;

      if (isSCWAdmin) {
        return true;
      }

      const standaloneDeveloper = authService.isStandaloneDeveloper();
      const standaloneTeam = authService.isStandaloneTeam();
      // if user is standalone, its own restrictions apply
      // otherwise team and company restrictions apply.
      const scopes = standaloneDeveloper ? [user] : standaloneTeam ? [user, team] : [user, team, company];

      //standalone developer - Only access to Training Module and Tournaments
      if (standaloneDeveloper) {
        const allowedFeatures = ['training', 'home', 'tournaments'];
        return allowedFeatures.includes(feature);
      }

      /** Block standalone teams from accessing courses.
       * However, the companies should have access to training.
       * So initial check via courses but allowance check via training
       **/
      if (feature === 'courses') {
        if (standaloneTeam) {
          return false;
        }
        feature = 'training';
      }

      let scope;
      for (let i = 0; i < scopes.length; i++) {
        scope = scopes[i];
        if (scope && scope.allowances && scope.allowances[feature]) {
          if (scope.allowances[feature].license === $rootScope.metadata.management.moduleLicenses.types.DISABLED) {
            return false;
          }
        }
      }

      return true;
    };

    authService.isStandaloneDeveloper = function () {
      if (!authService.isAuthenticated()) {
        return false;
      }

      const user = Session.user;
      const team = user.properties.team;
      const company = user.properties.company;
      const isSCWAdmin = user.roles.indexOf('scw admin') > -1;
      const isCompanyAdmin = user.roles.indexOf('company admin') > -1;
      const isTeamManager = user.roles.indexOf('team manager') > -1;
      return !team && !company && !isSCWAdmin && !isCompanyAdmin && !isTeamManager;
    };

    authService.isStandaloneTeam = function () {
      if (!authService.isAuthenticated()) {
        return false;
      }

      const user = Session.user;
      const team = user.properties.team;
      const company = user.properties.company;
      const isSCWAdmin = user.roles.indexOf('scw admin') > -1;
      const isCompanyAdmin = user.roles.indexOf('company admin') > -1;
      return team && !company && !isSCWAdmin && !isCompanyAdmin;
    };

    authService.isFeatureUnlimited = function (feature) {
      const teamFeature = feature === 'game' ? 'training' : feature;
      const scopes = [Session.user.properties.company, Session.user.properties.team];
      let scope;
      let res = false;

      for (let i = 0; i < scopes.length; i++) {
        scope = scopes[i];
        if (scope && scope.allowances && scope.allowances[feature]) {
          scope.allowances = scope.allowances || {};
          scope.allowances[feature] = scope.allowances[teamFeature] || metadata.management.moduleLicenses.defaults;
          res = scope.allowances[feature].license === metadata.management.moduleLicenses.types.UNLIMITED;
        }
      }

      return res;
    };

    authService.isStandaloneTeamOrAnonUser = function () {
      return authService.isAuthorized(USER_ROLES.anonymous) || authService.isStandaloneTeam();
    };

    authService.isActiveUserWithActiveSubscription = function () {
      const user = Session.user;
      // When subscription has expired user status is read only
      return user.status === USER_STATUS.ENABLED || user.status === USER_STATUS.REGISTERED;
    };

    authService.hasDevlympicsAccess = function () {
      // PLAT-14708 Remove Devlympics from TopNav until further notice.  Return false rather than removing function in case we bring this feature back.
      // if (!authService.isAuthenticated()) {
      //   return false;
      // }
      //
      // const showGlobalTournaments = !!(Session.user.properties?.preferences?.globalTournaments ?? 1);
      //
      // return (
      //   authService.isActiveUserWithActiveSubscription() &&
      //   !authService.isStandaloneTeamOrAnonUser() &&
      //   showGlobalTournaments
      // );
      return false;
    };

    authService.isAdminOrReseller = function () {
      return authService.isAuthorized([USER_ROLES.admin, USER_ROLES.reseller]);
    };

    authService.hasTournamentAccess = function () {
      const isEnabled = authService.isAdminOrReseller() || authService.isFeatureEnabled('tournaments');
      return isEnabled && !authService.isStandaloneTeamOrAnonUser();
    };

    authService.logout = function () {
      $log.debug('authService - logout()');
      // create httpConfig object manually for logout since we want to copy the session ID and then instantly destroy the session
      const httpConfig: any = {};
      httpConfig.headers = {};
      httpConfig.headers[SESSION_HEADER] = Session.getSessionId();
      Session.destroy();
      $window.localStorage.removeItem('courseId');
      $window.localStorage.removeItem('course_nav_details');
      authService.resetEndpoint();

      $window.DD_RUM?.onReady(() => {
        $window.DD_RUM.clearUser();
      });
      $window.DD_LOGS?.onReady(() => {
        $window.DD_LOGS.clearUser();
      });

      return $http.post(SCW_ENV.ApiEndpoint + AuthApiPrefix + '/logout', {}, httpConfig).finally(function () {
        $state.go('login');
      });
    };

    authService.publicTournamentRegistration = function (email) {
      $log.debug('publicTournamentRegistration - register()');
      return $http
        .post(
          SCW_ENV.ApiEndpoint + AuthApiPrefix + '/tournament-registration',
          { email },
          HttpConfigService.getHttpConfigNoSessionHeader()
        )
        .then(function (response) {
          return response.data;
        });
    };

    authService.register = function (newUser, token) {
      if (token) {
        newUser.token = token;
        newUser.acceptedTerms = true;
        $log.debug('authService - register()');
        return endpointUnaware
          .post('/management/admin/users/token-register', newUser, HttpConfigService.getHttpConfigNoSessionHeader())
          .then(function (response) {
            if (response.data && response.data.scw_configured) {
              // SSO configured
              // go to SAML redirection stuff
              if (response.data.login_url) {
                window.location.href = response.data.login_url;
              } else {
                $translate(['SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN']).then(
                  function (translations) {
                    ErrorHandler.addHttpError(
                      translations.SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN
                    );
                  }
                );
              }
              return 'SSO_NO_ACTION';
            }

            //Standard user token registration
            const { sessionId, user } = response.data;
            return authService.createSession(sessionId, user);
          });
      }
      // no longer used
      return endpointUnaware
        .post(AuthApiPrefix + '/register', newUser, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          return response.data;
        });
    };

    authService.forgotPassword = function (email) {
      $log.debug('authService - forgotPassword()', email);
      return allEndpoints
        .post(
          AuthApiPrefix + '/password/reset-request',
          {
            email: email,
          },
          HttpConfigService.getHttpConfigNoSessionHeader()
        )
        .then(function (response) {
          return response.data;
        });
    };

    authService.resetPassword = function (resetReq) {
      $log.debug('authService - resetPassword()');
      return endpointUnaware
        .post(AuthApiPrefix + '/password/reset-response', resetReq, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          return response.data;
        });
    };

    authService.resendActivation = function (email) {
      $log.debug('authService - resendActivation()', email);
      return $http
        .post(
          SCW_ENV.ApiEndpoint + AuthApiPrefix + '/resend-activation',
          email,
          HttpConfigService.getHttpConfigNoSessionHeader()
        )
        .then(function (response) {
          return response.data;
        });
    };

    authService.activate = function (activation) {
      $log.debug('authService - activate()', activation);
      return endpointUnaware
        .post(AuthApiPrefix + '/activate', activation, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          $log.debug('Activation response: ', response.data);
          const { sessionId, user } = response.data;
          Session.create(sessionId, user);
          return user;
        });
    };

    authService.productTrialActivate = async function (token) {
      $log.debug('authService - productTrialActivate()', token);
      const config = HttpConfigService.getHttpConfigNoSessionHeader();
      const response = await endpointUnaware.get(`${AuthApiPrefix}/product-trial/activate/${token}`, config);
      $log.debug('productTrialActivate response: ', response.data);
      const { sessionId, user } = response.data;
      Session.create(sessionId, user);
      return user;
    };

    authService.changePassword = function (newPasswordReq) {
      $log.debug('authService - changePassword()');
      return $http
        .post(
          SCW_ENV.ApiEndpoint + AuthApiPrefix + '/password/change',
          newPasswordReq,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    authService.inviteAccept = function (acceptance) {
      $log.debug('authService - inviteAccept()');
      acceptance.acceptedTerms = true;
      return endpointUnaware
        .post('/management/admin/users/invite/accept', acceptance, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          $log.debug('Invite accept response: ', response.data);
          const { sessionId, user } = response.data;
          Session.create(sessionId, user);
          return user;
        });
    };

    authService.getEmailByActivationToken = function (token) {
      $log.debug('authService - getEmailByActivationToken');
      return endpointUnaware
        .get('/management/admin/users/invite/email/' + token, HttpConfigService.getHttpConfigNoSessionHeader())
        .then(function (response) {
          $log.debug('Get email by activation token response: ', response.data);
          return response.data;
        });
    };

    authService.updateProfile = function (profile) {
      $log.debug('authService - updateProfile()', profile);

      delete profile.profile.preferredDevLanguages;

      return $http
        .post(SCW_ENV.ApiEndpoint + AuthApiPrefix + '/profile', profile, HttpConfigService.getHttpConfig())
        .then(function (response) {
          Session.updateUserProfile(response.data.profile);
          return response.data;
        });
    };

    authService.updateProfileLanguage = function (language) {
      $log.debug('authService - updateProfileLanguage()', language);
      return $http
        .post(SCW_ENV.ApiEndpoint + AuthApiPrefix + '/profile-language', language, HttpConfigService.getHttpConfig())
        .then(function (response) {
          Session.updateUserProfile(response.data.properties.profile);
          return response.data;
        });
    };

    authService.addLoading = function () {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    };

    authService.removeLoading = function () {
      $rootScope.loading--;
      if ($rootScope.loading < 0) $rootScope.loading = 0;
    };

    authService.requestLicenseExtension = function (module) {
      $log.debug('authService - requestLicenseExtension() ', module);
      return $http
        .post(SCW_ENV.ApiEndpoint + '/auth/license/extension/' + module, null, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .catch(function (_response) {
          $translate(['LICENSING.ERR_CANT_GET_MORE_EXTENSIONS']).then(function (translated) {
            return ErrorHandler.addError(translated['LICENSING.ERR_CANT_GET_MORE_EXTENSIONS']);
          });
        });
    };

    authService.updateTutorialStatus = function (status) {
      $log.debug('authService - updateTutorialStatus() ', status);
      return $http
        .post(SCW_ENV.ApiEndpoint + '/auth/tutorial', status, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    return authService;
  },
]);
