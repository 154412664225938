import angular from 'angular';
import moment from 'moment-timezone';
import csvExportTemplate from './manager-metrics-csv-export.html';
import courseFilterTemplate from './CoursesFilter.html';
import _ from 'lodash';

const MODULE = 'SCWTrainingManagerMetrics';
export default MODULE;

const app = angular.module(MODULE, []);

app.controller('TrainingManagerMetricsController', [
  '$scope',
  '$rootScope',
  '$log',
  '$translate',
  '$swal',
  '$sce',
  'AuthService',
  'USER_ROLES',
  'Session',
  'TrainingApiService',
  'TrainingUtils',
  '$uibModal',
  function (
    $scope,
    $rootScope,
    $log,
    $translate,
    $swal,
    $sce,
    AuthService,
    USER_ROLES,
    Session,
    TrainingApiService,
    TrainingUtils,
    $uibModal
  ) {
    /**
     * Number of items per page
     */
    $scope.pagination = [10, 25, 50];

    /**
     * Active Tab
     */
    $scope.tabs = {
      activeIndex: 0,
    };
    $scope.TAB_NAMES = {
      PROGRESS: 0,
      POINTS: 1,
    };

    /**
     * Setting up the leaderboard variables
     * Initializing the leaderboard object
     */
    $scope.leaderboard = {
      displayCourses: [],
      selectedCourses: [],
      progress: {
        team: {
          data: [],
          filter: null,
          pagination: {
            page: 1,
            itemsPerPage: 10,
            maxSize: 5,
            totalItems: 0,
          },
        },
        player: {
          data: [],
          filter: null,
          pagination: {
            page: 1,
            itemsPerPage: 10,
            maxSize: 5,
            totalItems: 0,
          },
        },
      },
      points: {
        team: {
          data: [],
          filter: null,
          pagination: {
            page: 1,
            itemsPerPage: 10,
            maxSize: 5,
            totalItems: 0,
          },
        },
        player: {
          data: [],
          filter: null,
          pagination: {
            page: 1,
            itemsPerPage: 10,
            maxSize: 5,
            totalItems: 0,
          },
        },
      },
    };

    /**
     * Setting up the selection menus for Course Stats
     * Initializing the course stats object
     */
    $scope.courses = {
      settings: {
        sortOption: {
          option: 'MOST_RECENT',
        },
        sortOptions: ['MOST_RECENT', 'OLDEST'],

        filterOption: {
          option: 'All Visible Courses',
        },
        filterOptions: ['All Visible Courses', 'Published', 'Unpublished', 'Expired', 'Archived'],

        paginationSettings: {
          totalItems: 0,
          page: 1,
          maxSize: 5,
          itemsPerPage: 5,
          order: -1,
        },
        pagination: [5, 10, 25, 50],
      },
    };

    $scope.confidenceCalculator = TrainingUtils.confidenceCalculator;
    $scope.displayTime = $rootScope.utility.quickFormatTime;
    $scope.pressEnter = TrainingUtils.pressEnter;

    $scope.getCompanyList = TrainingApiService.listCourseEnabledCompanies;

    $scope.selectedEntity = {};

    /**
     * Detecting if leaderboards should be shown
     */
    $scope.displayTeamLeaderboard = function () {
      if (
        $scope.selectedEntity &&
        $scope.selectedEntity.selected &&
        $scope.selectedEntity.selected.type === 'company' &&
        Session &&
        Session.user &&
        (Session.user.roles.indexOf(metadata.constants.user.role.COMPANY_ADMIN) > -1 ||
          Session.user.roles.indexOf(metadata.constants.user.role.SCW_ADMIN) > -1)
      ) {
        return true;
      }
      return false;
    };

    $scope.displayPlayerLeaderboard = function () {
      if (
        ($scope.selectedEntity && $scope.selectedEntity.selected && $scope.selectedEntity.selected.type === 'team') ||
        $scope.displayTeamLeaderboard()
      ) {
        return true;
      }
      return false;
    };

    /**
     * Get list of available languages from company allowances
     */
    function _getAvailableLanguages() {
      $scope.availableLanguages = [{ _id: null, _framework: null }];
      var ccEnabledLanguages = $rootScope.utility.getCCLanguagesFromAppType('all');
      var languages = Session.user.properties.languages;
      for (var i = 0; i < ccEnabledLanguages.length; i++) {
        var ccEnabledLanguage = ccEnabledLanguages[i];
        var languageLicensed = !!_.find(languages, function (lang) {
          return (
            lang.language._id === ccEnabledLanguage._id &&
            lang.language._framework === ccEnabledLanguage._framework &&
            lang.status === 'available'
          );
        });
        if (languageLicensed) $scope.availableLanguages.push(ccEnabledLanguage);
      }
      $scope.selectedLanguage = $scope.availableLanguages[0];
    }

    /**
     * format language into readable string
     */
    $scope.formatLanguage = function (_id, _framework) {
      if (_id === null && _framework === null) {
        return $translate.instant('ALL_AVAILABLE_LANGUAGES');
      } else {
        return metadata.languages[_id].name + ' ' + metadata.languages[_id].framework[_framework].name;
      }
    };

    /**
     * format the status filter
     */
    $scope.formatStatus = function (option) {
      var _option = option.replace(/ /g, '_');

      return $translate.instant('COURSES_PROGRESS_SUMMARY.' + _option.toUpperCase());
    };

    /**
     * get list of available courses, then set courses selector to all courses as by default
     * points leaderboard is set to first course
     */
    $scope.getAvailableCourses = function () {
      if (!$scope.selectedEntity || !$scope.selectedEntity.selected || !$scope.selectedEntity.selected._id) return;
      return TrainingApiService.getPublishedCourses($scope.selectedEntity.selected._id).then(function (res) {
        $scope.availableCourses = res;

        $scope.leaderboard.displayCourses = [{ _id: null, name: $translate.instant('ALL_COURSES') }];
        if ($scope.availableCourses.length > 0) {
          $scope.leaderboard.displayCourses = JSON.parse(JSON.stringify($scope.availableCourses));
        } else {
          $scope.leaderboard.displayCourses = [{ _id: null, name: $translate.instant('NONE') }];
        }

        $scope.leaderboard.selectedCourses = $scope.leaderboard.displayCourses.slice(
          0,
          $scope.leaderboard.displayCourses.length
        );
      });
    };

    $scope.openCsvExportMenu = function () {
      $swal({
        title: $translate.instant('COURSE_REPORTS_EXPORTS'),
        templateUrl: csvExportTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: true,
        scope: $scope,
        keyResolution: true,
      }).then(function (resolution) {
        switch (resolution) {
          case 'courseProgressSummary':
            TrainingApiService.getCoursesProgressSummaryCsv($scope.selectedEntity.selected._id).then(function (data) {
              TrainingUtils.openCsvSwal(data.url);
            });
            TrainingUtils.generateCsvSwal();
            break;
          case 'teamData':
            TrainingApiService.getTeamCourseProgressSummaryCsv($scope.selectedEntity.selected._id).then(
              function (data) {
                TrainingUtils.openCsvSwal(data.url);
              }
            );
            TrainingUtils.generateCsvSwal();
            break;
          case 'playerData':
            TrainingApiService.getPlayerCourseSummaryCsv($scope.selectedEntity.selected._id).then(function (data) {
              if (data.url) TrainingUtils.openCsvSwal(data.url);
              else TrainingUtils.csvEmailSwal(data.isBeingGenerated, 'playerData');
            });
            TrainingUtils.generateCsvSwal();
            break;
          case 'exportAllData':
            TrainingApiService.getManagerExportPlayerChallenges($scope.selectedEntity.selected._id).then(
              function (data) {
                if (data.url) TrainingUtils.openCsvSwal(data.url);
                else TrainingUtils.csvEmailSwal(data.isBeingGenerated, 'exportAllData');
              }
            );
            TrainingUtils.generateCsvSwal();
            break;
          default:
            break;
        }
      });
    };

    /**
     * Resetting the provided leaderboard based on type
     * @param {Number} type
     */
    function resetTeamLeaderboard(type) {
      if (type === $scope.TAB_NAMES.POINTS) {
        $scope.leaderboard.points.team.data = [];
        $scope.leaderboard.points.team.pagination.totalItems = 0;
        $scope.leaderboard.points.team.page = 1;
      } else if (type === $scope.TAB_NAMES.PROGRESS) {
        $scope.leaderboard.progress.team.data = [];
        $scope.leaderboard.progress.team.pagination.totalItems = 0;
        $scope.leaderboard.progress.team.page = 1;
      }
    }

    function resetPlayerLeaderboard(type) {
      if (type === $scope.TAB_NAMES.POINTS) {
        $scope.leaderboard.points.player.data = [];
        $scope.leaderboard.points.player.pagination.totalItems = 0;
        $scope.leaderboard.points.player.page = 1;
      } else if (type === $scope.TAB_NAMES.PROGRESS) {
        $scope.leaderboard.progress.player.data = [];
        $scope.leaderboard.progress.player.pagination.totalItems = 0;
        $scope.leaderboard.progress.player.page = 1;
      }
    }

    /**
     * Retrieve and set leaderboard object
     * @param {Number} type
     */
    $scope.getTeamLeaderboard = function (type, resetPagination) {
      //only get team leaderboard if selected entity is company
      if ($scope.selectedEntity.selected.type !== 'company') {
        return;
      }
      //only company and scw admins can get team leaderboard
      if (
        Session.user.roles.indexOf(metadata.constants.user.role.COMPANY_ADMIN) <= -1 &&
        Session.user.roles.indexOf(metadata.constants.user.role.SCW_ADMIN) <= -1
      ) {
        return;
      }

      resetTeamLeaderboard(type);

      var company = $scope.selectedEntity.selected._id;
      var page, size;
      if (type === $scope.TAB_NAMES.POINTS) {
        page = resetPagination ? 1 : $scope.leaderboard.points.team.pagination.page;
        size = $scope.leaderboard.points.team.pagination.itemsPerPage;

        TrainingApiService.getManagerTeamPointsLeaderboard(
          company,
          $scope.leaderboard.points.team.filter,
          page,
          size,
          $scope.selectedLanguage
        ).then(function (res) {
          $scope.leaderboard.points.team.data = res;
          $scope.leaderboard.points.team.pagination.totalItems = res.total;
          $scope.leaderboard.points.team.pagination.page = res.page;
        });
      }
      if (type === $scope.TAB_NAMES.PROGRESS) {
        page = resetPagination ? 1 : $scope.leaderboard.progress.team.pagination.page;
        size = $scope.leaderboard.progress.team.pagination.itemsPerPage;

        if ($scope.leaderboard.selectedCourses) {
          var courses = $scope.leaderboard.selectedCourses.map(function (c) {
            return c._id;
          });
          TrainingApiService.getManagerTeamProgressLeaderboard(
            company,
            courses,
            $scope.leaderboard.progress.team.filter,
            page,
            size
          ).then(function (res) {
            $scope.leaderboard.progress.team.data = res;
            $scope.leaderboard.progress.team.pagination.totalItems = res.total;
            $scope.leaderboard.progress.team.pagination.page = res.page;
          });
        } else {
          $scope.leaderboard.progress.team.data = [];
          $scope.leaderboard.progress.team.pagination.totalItems = 0;
          $scope.leaderboard.progress.team.pagination.page = 1;
        }
      }
    };

    $scope.getPlayerLeaderboard = function (type, resetPagination) {
      //if company is selected entity, user must be company admin or scw admin.
      if (
        $scope.selectedEntity.selected.type === 'company' &&
        Session.user.roles.indexOf(metadata.constants.user.role.COMPANY_ADMIN) > -1 &&
        Session.user.roles.indexOf(metadata.constants.user.role.SCW_ADMIN) > -1
      ) {
        return;
      }

      resetPlayerLeaderboard(type);

      var page, size;
      if (type === $scope.TAB_NAMES.POINTS) {
        page = resetPagination ? 1 : $scope.leaderboard.points.player.pagination.page;
        size = $scope.leaderboard.points.player.pagination.itemsPerPage;

        TrainingApiService.getManagerPlayerPointsLeaderboard(
          $scope.selectedEntity.selected._id,
          $scope.leaderboard.points.player.filter,
          page,
          size,
          $scope.selectedLanguage,
          null,
          true
        )
          .then(function (res) {
            $scope.leaderboard.points.player.data = res;
            $scope.leaderboard.points.player.pagination.totalItems = res.total;
            $scope.leaderboard.points.player.pagination.page = res.page;
          })
          .catch(function (res) {
            $log.debug('Error getting learning path manager metrics player leaderboard', res);
          });
      }

      if (type === $scope.TAB_NAMES.PROGRESS) {
        page = resetPagination ? 1 : $scope.leaderboard.progress.player.pagination.page;
        size = $scope.leaderboard.progress.player.pagination.itemsPerPage;

        if ($scope.leaderboard.selectedCourses) {
          var courses = $scope.leaderboard.selectedCourses.map(function (c) {
            return c._id;
          });
          TrainingApiService.getManagerPlayerProgressLeaderboard(
            $scope.selectedEntity.selected._id,
            courses,
            $scope.leaderboard.progress.player.filter,
            page,
            size,
            null,
            true
          )
            .then(function (res) {
              $scope.leaderboard.progress.player.data = res;
              $scope.leaderboard.progress.player.pagination.totalItems = res.total;
              $scope.leaderboard.progress.player.pagination.page = res.page;
            })
            .catch(function (res) {
              $log.debug('Error getting learning path manager metrics player leaderboard', res);
            });
        }
      }
    };

    /**
     * clicking on a row in team leaderboard selects that row
     */
    $scope.selectEntity = function (entity) {
      if (
        Session.user.roles.indexOf(metadata.constants.user.role.SCW_ADMIN) > -1 &&
        $scope.selectedEntity.selected.type === 'company'
      ) {
        $scope.getEntities($scope.selectedEntity.selected._id).then(function () {
          $scope.selectedEntity.selected = _.find($scope.entities, function (row) {
            return row._id === entity._team;
          });

          $scope.refreshLeaderboards();
        });
      } else {
        $scope.selectedEntity.selected = _.find($scope.entities, function (row) {
          return row.name === entity.name;
        });

        $scope.refreshLeaderboards();
      }
    };

    $scope.changeEntityDropdown = function () {
      $scope.getAvailableCourses().then(function () {
        $scope.refreshLeaderboards();
      });

      fetchCoursesProgressSummary();
    };

    /**
     * Get list of entities to populate entities dropdown menu
     */
    $scope.getEntities = function (_entity) {
      return TrainingApiService.getManagerEntities(_entity).then(function (res) {
        $scope.entities = res;
        $scope.selectedEntity.selected = $scope.entities[0];
        return;
      });
    };

    $scope.showCoursesFilterDialog = function () {
      $uibModal
        .open({
          templateUrl: courseFilterTemplate,
          controller: 'CoursesFilterController',
          windowClass: 'course-filter simple-flow-modal-z-index-hack',
          openedClass: 'simple-flow-signup-modal-open',
          size: 'lg',
          resolve: {
            options: {
              translations: {
                modalTitle: 'COURSES_FILTER',
              },
              displayCourses: $scope.leaderboard.displayCourses,
              selectedCourses: $scope.leaderboard.selectedCourses,
            },
          },
        })
        .result.then(function (filter) {
          if (filter) {
            $scope.leaderboard.selectedCourses = filter;
          }
          $scope.refreshLeaderboards();
        })
        .catch(angular.noop);
    };

    $scope.getTeamLeaderboardCsv = function (type) {
      var _entity = $scope.selectedEntity.selected._id;
      if (type === $scope.TAB_NAMES.POINTS) {
        TrainingApiService.getManagerExportTeamPointsLeaderboard(_entity).then(function (data) {
          TrainingUtils.openCsvSwal(data.url);
        });
      } else if (type === $scope.TAB_NAMES.PROGRESS) {
        var courses = $scope.leaderboard.selectedCourses
          .map(function (course) {
            return course._id;
          })
          .join(',');
        TrainingApiService.getManagerExportTeamProgressLeaderboard(_entity, courses).then(function (data) {
          TrainingUtils.openCsvSwal(data.url);
        });
      }
      TrainingUtils.generateCsvSwal();
    };

    $scope.getAggregateCourseStatsCsv = function () {
      var _entity = $scope.selectedEntity.selected._id;
      TrainingApiService.getManagerExportAggregateCourseStats(_entity).then(function (data) {
        TrainingUtils.openCsvSwal(data.url);
      });
      TrainingUtils.generateCsvSwal();
    };

    $scope.getPlayerLeaderboardCsv = function (type) {
      var _entity = $scope.selectedEntity.selected._id;
      if (type === $scope.TAB_NAMES.POINTS) {
        TrainingApiService.getManagerExportPlayerPointsLeaderboard(_entity).then(function (data) {
          TrainingUtils.openCsvSwal(data.url);
        });
      } else if (type === $scope.TAB_NAMES.PROGRESS) {
        var courses = $scope.leaderboard.selectedCourses
          .map(function (course) {
            return course._id;
          })
          .join(',');
        TrainingApiService.getManagerExportPlayerProgressLeaderboard(_entity, courses).then(function (data) {
          TrainingUtils.openCsvSwal(data.url);
        });
      }
      TrainingUtils.generateCsvSwal();
    };

    $scope.getAggregateTrainingChallengesCsv = function () {
      var _entity = $scope.selectedEntity.selected._id;
      TrainingApiService.getManagerExportPlayerChallenges(_entity).then(function (data) {
        if (data.url) {
          TrainingUtils.openCsvSwal(data.url);
        } else {
          TrainingUtils.csvEmailSwal(data.isBeingGenerated);
        }
      });
      TrainingUtils.generateCsvSwal();
    };

    /**
     * Sort By
     */
    $scope.changeCourseStatsSortOption = function () {
      if ($scope.courses.settings.sortOption.option === 'MOST_RECENT') {
        $scope.courses.settings.paginationSettings.page = 1;
        $scope.courses.settings.paginationSettings.order = -1;
      } else {
        $scope.courses.settings.paginationSettings.page = 1;
        $scope.courses.settings.paginationSettings.order = 1;
      }
      fetchCoursesProgressSummary();
    };

    /**
     * Filter By
     */
    $scope.changeCourseStatsFilterOption = function () {
      $scope.courses.settings.paginationSettings.page = 1;
      $scope.courses.settings.paginationSettings.order =
        $scope.courses.settings.sortOption.option === 'MOST_RECENT' ? -1 : 1;
      fetchCoursesProgressSummary();
    };

    $scope.convertDeadlineToDisplayStr = function (deadlineObj) {
      if (deadlineObj.type === 'date') {
        var _dateObj = moment(Date.parse(deadlineObj.value));
        return _dateObj.format('MMM Do YYYY');
      } else if (deadlineObj.type === 'days') {
        return $translate.instant('COURSES_PROGRESS_SUMMARY.TIME_LIMIT_DAYSTR', { days: deadlineObj.value });
      }
      return '-';
    };

    /**
     * Initialise the Courses object with the Courses Progress Summary
     * for all Teams under Selected Company or all Teams for all Companies
     */
    function fetchCoursesProgressSummary(resetPagination) {
      // Needed to reset the data, as visually it was still showing the old set of data before it
      // goes and fetches the new set
      $scope.courses.data = [];

      var _options = {};
      if ($scope.courses.settings.filterOption.option !== 'All Visible Courses') {
        _options.filter = $scope.courses.settings.filterOption.option;
      }

      if ($scope.selectedEntity && $scope.selectedEntity.selected && $scope.selectedEntity.selected._id) {
        _options.entity = $scope.selectedEntity.selected._id;
      }

      TrainingApiService.getCoursesProgressSummary(
        {
          page: resetPagination ? 1 : $scope.courses.settings.paginationSettings.page,
          size: $scope.courses.settings.paginationSettings.itemsPerPage,
          order: $scope.courses.settings.paginationSettings.order,
        },
        _options
      ).then(function (res) {
        $scope.courses.data = res._data && res._data.summary ? res._data.summary : [];
        $scope.courses.settings.paginationSettings.totalItems =
          res._data && res._data.totalCount ? res._data.totalCount : 0;
        $scope.courses.settings.paginationSettings.page = res._data.page;
        makeLanguagesTooltipData($scope.courses.data);
      });
    }

    /**
     * Creates the language tooltip with all the languages listed on hover.
     *
     * @param {*} coursesData - courses metric data
     */
    function makeLanguagesTooltipData(coursesData) {
      if (coursesData) {
        coursesData.forEach((course) => {
          var courseLanguages = course.language.split(',');
          var tooltipHtml = '';

          courseLanguages.forEach((language) => {
            tooltipHtml += _.escape(language) + '<br>';
          });

          var threeLanguages = courseLanguages.slice(0, 3).join(', ');
          var remainingLanguages = courseLanguages.length - 3;

          course.languageTitle = threeLanguages;
          if (remainingLanguages > 0) {
            course.languageTitle += ' +' + (courseLanguages.length - 3) + ' ' + $translate.instant('MORE');
          }

          course.languageTooltip = $sce.trustAsHtml(tooltipHtml);
        });
      }
    }

    /**
     * Refresh the Courses Progress Summary Table
     */
    $scope.refreshCoursesProgressSummary = function (resetPagination) {
      // Making another call to fetch courses progress summary
      fetchCoursesProgressSummary(resetPagination);
    };

    /**
     * Refresh All Leaderboards
     */
    $scope.refreshLeaderboards = function () {
      $scope.getTeamLeaderboard($scope.TAB_NAMES.PROGRESS);
      $scope.getPlayerLeaderboard($scope.TAB_NAMES.PROGRESS);

      $scope.getTeamLeaderboard($scope.TAB_NAMES.POINTS);
      $scope.getPlayerLeaderboard($scope.TAB_NAMES.POINTS);
    };

    /**
     * Initialise Function
     */
    $scope.init = function () {
      //get list of entities which manager can view metrics for
      if (AuthService.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.manager])) {
        $scope.getEntities().then(function () {
          $scope.getAvailableCourses().then(function () {
            $scope.refreshLeaderboards();
            //initialize all the courses you can find for this company
            fetchCoursesProgressSummary();
          });
        });
      }
    };

    // Initialise the page
    $scope.init();
  },
]);

app.controller('CoursesFilterController', [
  '$scope',
  '$uibModalInstance',
  'options',
  function ($scope, $uibModalInstance, options) {
    $scope.translations = options.translations;
    $scope.filterCourses = options.displayCourses.slice(0, options.displayCourses.length);
    $scope.selectedCourses = options.selectedCourses
      ? options.selectedCourses.slice(0, options.selectedCourses.length)
      : [];
    $scope.filterText = '';

    if ($scope.selectedCourses.length) {
      $scope.filterCourses = $scope.filterCourses.filter(function (c) {
        return !isCourseSelected(c);
      });
    }

    $scope.selectCourseFilter = function (course) {
      var idx = $scope.filterCourses.findIndex(function (elm) {
        return elm._id === course._id;
      });

      if (idx >= 0) {
        var c = _.extend({}, $scope.filterCourses[idx]);

        $scope.filterCourses.splice(idx, 1);
        $scope.selectedCourses.push(c);

        if ($scope.filterText) {
          $scope.filterText = '';
        }
      }
    };
    $scope.removeCourseFilter = function (course) {
      var idx = $scope.selectedCourses.findIndex(function (elm) {
        return elm._id === course._id;
      });

      if (idx >= 0) {
        var c = _.extend({}, $scope.selectedCourses[idx]);

        $scope.selectedCourses.splice(idx, 1);
        $scope.filterCourses.push(c);
      }
    };
    $scope.clearFilters = function () {
      $scope.filterCourses = [...$scope.filterCourses, ...$scope.selectedCourses];
      $scope.selectedCourses = [];
    };
    $scope.canApplyFilter = function () {
      return $scope.selectedCourses.length > 0;
    };
    $scope.apply = function () {
      $uibModalInstance.close($scope.selectedCourses);
    };
    $scope.cancel = function () {
      $uibModalInstance.close(null);
    };

    function isCourseSelected(course) {
      for (var i = 0; i < $scope.selectedCourses.length; i++) {
        if (course._id === $scope.selectedCourses[i]._id) {
          return true;
        }
      }

      return false;
    }
  },
]);
