// This file is an identical copy of the backend file with the same name.
// Please make sure to keep them in sync.
// When this becomes too arduous, we'll know it's time to move this code to a shared package.
export function multiAssessmentFromAssessments(assessments) {
    const { timeLimitIsPerChallenge, timeLimit } = inferTimeLimit(assessments);
    return {
        name: commonPrefix(assessments.map((a) => a.name)),
        timeLimitIsPerChallenge,
        assessmentSettings: {
            maxRetries: modeNumericNullable(assessments.map((a) => { var _a; return (_a = a.assessmentSettings.maxRetries) !== null && _a !== void 0 ? _a : null; })),
            startDate: modeD(assessments.map((a) => a.assessmentSettings.startDate)),
            endDate: modeD(assessments.map((a) => a.assessmentSettings.endDate)),
            successRatio: modeNumericNullable(assessments.map((a) => a.assessmentSettings.successRatio)),
            timeLimit,
            emitsCertificate: modeB(assessments.map((a) => a.assessmentSettings.emitsCertificate)),
            _certificateTemplate: modeSNull(assessments.map((a) => a.assessmentSettings._certificateTemplate)),
            retryWaitingHours: modeNumericNullable(assessments.map((a) => a.assessmentSettings.retryWaitingHours)),
            timezone: modeS(assessments.map((a) => a.assessmentSettings.timezone || 'Europe/London')),
            lmsIntegrated: modeB(assessments.map((a) => a.assessmentSettings.lmsIntegrated)),
            levelGrouping: modeSNull(assessments.map((a) => a.assessmentSettings.levelGrouping)),
            fixedChallenges: modeB(assessments.map((a) => a.assessmentSettings.fixedChallenges)),
            selfAssess: modeB(assessments.map((a) => a.assessmentSettings.selfAssess)),
            sendInvitesOn: modeD(assessments.map((a) => a.assessmentSettings.sendInvitesOn)),
        },
        assessments: assessments.map((a) => ({
            _assessment: a._assessment,
        })),
        assessmentRecordsMd5: '',
    };
}
export function inferTimeLimit(assessments) {
    const unormlimits = assessments.map((a) => a.assessmentSettings.timeLimit);
    const normlimits = assessments.map((a) => a.assessmentSettings.timeLimit ? a.assessmentSettings.timeLimit / a.assessmentContent.numberOfChallenges : null);
    const unormMode = modeNumericNullable(unormlimits);
    const normMode = modeNumericNullable(normlimits);
    const timeLimitIsPerChallenge = unormlimits.filter((l) => l === unormMode).length <= normlimits.filter((l) => l === normMode).length;
    return { timeLimitIsPerChallenge, timeLimit: timeLimitIsPerChallenge ? normMode : unormMode };
}
function modeS(ts) {
    const Tcounts = ts.reduce((acc, t) => {
        acc[t] = (acc[t] || 0) + 1;
        return acc;
    }, {});
    const max = Math.max(...Object.values(Tcounts));
    const modes = Object.entries(Tcounts)
        .filter(([_, count]) => count === max)
        .map(([t]) => t);
    return modes[0];
}
function modeSNull(ts) {
    var _a;
    let nullCounts = 0;
    const Tcounts = ts.reduce((acc, t) => {
        if (t !== null) {
            acc[t] = (acc[t] || 0) + 1;
        }
        else {
            nullCounts++;
        }
        return acc;
    }, {});
    const max = Math.max(...Object.values(Tcounts));
    if (nullCounts > max) {
        return null;
    }
    const modes = Object.entries(Tcounts)
        .filter(([_, count]) => count === max)
        .map(([t]) => t);
    return (_a = modes[0]) !== null && _a !== void 0 ? _a : null;
}
export function modeNumericNullable(ts) {
    let nullCounts = 0;
    const Tcounts = ts.reduce((acc, t) => {
        if (t !== null) {
            acc[t] = (acc[t] || 0) + 1;
        }
        else {
            nullCounts++;
        }
        return acc;
    }, {});
    const max = Math.max(...Object.values(Tcounts));
    if (nullCounts > max) {
        return null;
    }
    const modes = Object.entries(Tcounts)
        .filter(([_, count]) => count === max)
        .map(([t]) => t);
    return modes[0] ? parseInt(modes[0]) : null;
}
function modeB(ts) {
    const ayes = ts.filter((t) => t).length;
    const nays = ts.length - ayes;
    return ayes > nays;
}
function modeD(ts) {
    const ns = ts.map((d) => { var _a; return (_a = d === null || d === void 0 ? void 0 : d.getTime()) !== null && _a !== void 0 ? _a : null; });
    const m = modeNumericNullable(ns);
    return m === null ? null : new Date(m);
}
export function modeNumeric(ts) {
    const strings = ts.map((t) => t.toString());
    const m = modeS(strings);
    return parseInt(m);
}
function commonPrefix(strings) {
    if (strings.length === 0) {
        return '';
    }
    // console.log('strings', strings);
    let prefix = '';
    while (prefix.length < strings[0].length) {
        const candidate = strings[0][prefix.length];
        if (strings.every((s) => s[prefix.length] === candidate)) {
            prefix += candidate;
            // console.log('Got', prefix);
        }
        else {
            break;
        }
    }
    return prefix;
}
