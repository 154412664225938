import angular from 'angular';
import introJs from 'intro.js';

const app = angular.module('SCWInactivityDetector', []);
export default app.name;

var ACTIVITY_EVENTS = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';
var INACTIVITY_PERIOD = 10 * 60 * 1000; // 10 minutes of inactivity

app.directive('inactivityDetector', [
  'ActivityTracker',
  function (ActivityTracker) {
    return {
      restrict: 'A',
      link: function (_scope, element, _attrs) {
        var inactivityHandler = function () {
          if (introJs) introJs().exit(); // close walkthroughs
          ActivityTracker.pingState(false); //set the active flag to false for tracking time
        };
        var debouncedActivityHandler = _.debounce(inactivityHandler, INACTIVITY_PERIOD);
        var resetActiveFlag = function () {
          ActivityTracker.pingState(true);
        };

        $('body').on(ACTIVITY_EVENTS, debouncedActivityHandler);
        $('body').on(ACTIVITY_EVENTS, resetActiveFlag); //set the active flag true again

        element.on('$destroy', function () {
          $('body').off(ACTIVITY_EVENTS, debouncedActivityHandler);
          debouncedActivityHandler.cancel();
          $('body').off(ACTIVITY_EVENTS, resetActiveFlag); //set the active flag to true again
        });

        // start inactivity timer
        debouncedActivityHandler();

        return;
      },
    };
  },
]);
