import styled from '@emotion/styled';
import { fontWeightNormal, lightGrey } from './styles';
import { styled as muiStyled } from '@mui/material';
export const AdminPageContainer = muiStyled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(10),
    width: '100%',
}));
export const AdminHeader = styled.div `
  background-color: ${lightGrey};
  .header {
    padding: 0 15px;
    font-weight: ${fontWeightNormal};
  }
`;
export const AdminBody = styled.div `
  padding: 0 15px;
`;
export const Icon = styled.span `
  margin-right: 8px;
`;
