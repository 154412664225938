import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../platformContext';
import { usePlatformNavigate } from '../navigation';
import { useApiClient } from '../ApiContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Suspenser } from '@securecodewarrior/design-system-react';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { NavigationBar } from '../navigation/NavigationBar';
import { ActivityStatusPage } from '../../common/components/organisms/Activities/ActivityStatusPage';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
export const MissionPlayerWrapper = ({ type }) => {
    const { contentId, languageFramework } = useParams();
    if (!contentId || !languageFramework)
        throw new Error('this component may only be used in a router that will set the correct params');
    const { t } = useTranslation();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const { trpc, wrapErrorPromise } = useApiClient();
    const [redoIfCompleted, setRedoIfCompleted] = useState(false);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const response = useMemo(() => wrapErrorPromise(trpc.explore.mission.startOrContinue
        .mutate({ missionAlias: contentId, language: languageFramework, redoIfCompleted })
        .then((response) => {
        if (response.kind === 'ok') {
            const ev = response.content_type === 'mission'
                ? { event: 'Access Mission', mission_slug: contentId, scope: 'explore' }
                : { event: 'Access Walkthrough', walkthrough_slug: contentId, scope: 'explore' };
            platformCtx.logAnalyticsEvent(ev);
        }
        return response;
    })), [wrapErrorPromise, contentId, languageFramework, platformCtx, redoIfCompleted] //eslint-disable-line react-hooks/exhaustive-deps
    );
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', search: '', hash: '' });
    }, [navigate, platformCtx]);
    useEffect(() => {
        const handlePostMessage = (event) => {
            const kind = event.data.event;
            if (kind === 'navigateToMissions' || kind === 'navigateToNextStage' || kind === 'skipChallenge') {
                setCompletionStatus('passed');
            }
        };
        window.addEventListener('message', handlePostMessage);
        return () => window.removeEventListener('message', handlePostMessage);
    }, [navigateBack]);
    const usersnapData = {
        [`data-${type}-id`]: contentId,
        [`data-${type}-language`]: languageFramework,
    };
    return (_jsx(_Fragment, { children: _jsx(Suspenser, { reader: response, skeleton: _jsx(NavigationBar, { type: type, module: "explore", status: "none", onBackNavigation: navigateBack }), render: (resp) => resp.kind === 'ok' ? (completionStatus === 'in_progress' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: type, module: "explore", status: "in_progress", onBackNavigation: navigateBack }), _jsx("iframe", Object.assign({ src: resp.playerUrl, id: "adversary", style: iframeStyle, "aria-label": t(resp.content_type.toUpperCase()), title: t(resp.content_type.toUpperCase()), allow: "clipboard-read; clipboard-write; display-capture;" }, usersnapData))] })) : completionStatus === 'passed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: type, module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "success", contentType: type, contentId: contentId, onClickBackToActivity: undefined })] })) : (assertNever(completionStatus, 'invalid activity status'))) : resp.kind === 'already_completed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: type, module: "explore", status: "passed", date: resp.lastCompleted, onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "already_completed", contentType: type, contentId: contentId, onClickStartRefresher: () => setRedoIfCompleted(true) })] })) : (assertNever(resp, 'invalid state')) }) }));
};
const iframeStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
