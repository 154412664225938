import angular from 'angular';
import MODULE from './module';
import templateUrl from './date-range-selector.html';

angular.module(MODULE).directive('dateRangeSelector', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        form: '=',
        className: '=',
        checkToday: '=',

        startDate: '=',
        startDateChanged: '&',
        sAnalyticsField: '@',
        sAnalyticsButton: '@',
        sSubtext: '@',

        endDate: '=',
        endDateChanged: '&',
        eAnalyticsField: '@',
        eAnalyticsButton: '@',
        eSubtext: '@',

        startDateTitle: '@',
        endDateTitle: '@',
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.dateOptions = {
            startingDay: 1,
            showWeeks: false,
            clearText: 'Clear',
          };

          $scope.formReference = $scope.form || {};
          $scope.formReference.isInvalid = publicValidationTriggered;

          // Setting up the initial states for error messages
          resetErrorMessages();

          function validate(start, end) {
            var startDate = new Date(start);
            var endDate = new Date(end);

            if ($scope.checkToday) {
              var now = new Date();
              var today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Removing the time portion

              if (start && startDate < today) {
                updateErrorMessage({
                  startDateError: {
                    isInvalid: true,
                  },
                });
              }
              if (end && endDate < today) {
                updateErrorMessage({
                  endDateError: {
                    isInvalid: true,
                  },
                });
              }
            }

            if (end && start && endDate <= startDate) {
              updateErrorMessage({
                startDateError: {
                  isInvalidRange: true,
                },
              });
              updateErrorMessage({
                endDateError: {
                  isInvalidRange: true,
                },
              });
            } else {
              updateErrorMessage({
                startDateError: {
                  isInvalidRange: false,
                },
              });
              updateErrorMessage({
                endDateError: {
                  isInvalidRange: false,
                },
              });
            }
          }

          function publicValidationTriggered() {
            var isStartInvalid = $scope.startDateError.isInvalid || $scope.startDateError.isInvalidRange;
            var isEndInvalid = $scope.endDateError.isInvalid || $scope.endDateError.isInvalidRange;

            return isStartInvalid || isEndInvalid;
          }

          function resetErrorMessages(type) {
            switch (type) {
              case 'startDate':
                $scope.startDateError = {
                  isInvalid: false,
                  isInvalidRange: false,
                };
                break;
              case 'endDate':
                $scope.endDateError = {
                  isInvalid: false,
                  isInvalidRange: false,
                };
                break;
              default:
                $scope.startDateError = {
                  isInvalid: false,
                  isInvalidRange: false,
                };
                $scope.endDateError = {
                  isInvalid: false,
                  isInvalidRange: false,
                };
            }
          }

          function updateErrorMessage(obj) {
            _.merge($scope, obj);
            $scope.startDateError.show = true;
          }

          function triggerErrorMessage(newValue) {
            if (newValue) {
              validate($scope.startDate, $scope.endDate);
            }
          }

          $scope.onStartDateChange = function (newValue) {
            resetErrorMessages('startDate');
            triggerErrorMessage(newValue);
            if ($scope.startDateChanged) {
              $scope.startDateChanged();
            }
          };

          $scope.onEndDateChange = function (newValue) {
            resetErrorMessages('endDate');
            triggerErrorMessage(newValue);
            if ($scope.endDateChanged) {
              $scope.endDateChanged();
            }
          };

          $scope.$watch('startDateOpened', function () {
            if ($scope.endDateOpened) {
              $scope.endDateOpened = false;
            }
          });
          $scope.$watch('endDateOpened', function () {
            if ($scope.startDateOpened) {
              $scope.startDateOpened = false;
            }
          });
        },
      ],
    };
  },
]);
