import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.controller('SenseiController', [
  '$scope',
  '$state',
  '$stateParams',
  '$translate',
  'AuthService',
  'USER_ROLES',
  'ErrorHandler',
  'SenseiApiService',
  'AdminApiService',
  '$window',
  function (
    $scope,
    $state,
    $stateParams,
    $translate,
    AuthService,
    USER_ROLES,
    ErrorHandler,
    SenseiApiService,
    AdminApiService,
    $window
  ) {
    // Ensure the user has the rights to view this page
    // As we are not checking via role ACLs
    if (!AuthService.isAuthorized(USER_ROLES.admin)) {
      if (!$scope.Session.user || !AuthService.isFeatureEnabled('sensei')) {
        // Take that and party
        $scope.Session.destroy();
        $state.go('login');
        return;
      }
    }

    if ($state.current.name === 'sensei') {
      if ($scope.isAuthorized([$scope.userRoles.admin, $scope.userRoles.companyAdmin, $scope.userRoles.manager])) {
        $state.go('sensei-metrics');
      } else {
        $state.go('sensei-download');
      }
    }

    $scope.companyList = [];

    $scope.doShowDownload = function () {
      $state.go('sensei-download');
    };
    $scope.doShowMetrics = function () {
      $state.go('sensei-metrics');
    };

    /*** Download ***/

    $scope.ides = {};

    $scope.downloadDemo = function (uri) {
      var link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    function updateLanguages() {
      AdminApiService.addLoading();
      SenseiApiService.getSupportedIDEs()
        .then(function (languages) {
          languages = _.values(languages);
          AdminApiService.removeLoading();
          if ($stateParams.ideName) {
            var selectedIde = getIdeByName(languages, $stateParams.ideName);
            setupSelectedIde(selectedIde);
            return;
          }
          setupIdes(languages);
        })
        .catch(function (response) {
          AdminApiService.removeLoading();
          $translate(['FAILED_TO_GET_SUPPORTED_IDES']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_GET_SUPPORTED_IDES, response);
          });
        });
    }

    var cSharpDemoUrl = 'https://media.securecodewarrior.com/sensei/demo-projects/csharp-demo.zip';
    var javaDemoUrl = 'https://media.securecodewarrior.com/sensei/demo-projects/java-demo.zip';
    var androidDemoUrl = 'https://media.securecodewarrior.com/sensei/demo-projects/android-demo.zip';

    function setupSelectedIde(selectedIde) {
      selectedIde.pluginURL = $window.SCW_ENV.ApiEndpoint + selectedIde.pluginURL;
      if (selectedIde.name.indexOf('Android Studio') === 0) {
        selectedIde.demoUrl = androidDemoUrl;
      } else {
        selectedIde.demoUrl =
          selectedIde.downloadType === $scope.metadata.constants.sensei.downloadType.VISUAL_STUDIO
            ? cSharpDemoUrl
            : javaDemoUrl;
      }

      $scope.selectedIDE = selectedIde;
    }

    function getIdeByName(languages, ideName) {
      for (var i = 0; i < languages.length; i++) {
        for (var j = 0; j < languages[i].ides.length; j++) {
          var ide = languages[i].ides[j];
          if (ide.name === ideName) {
            return ide;
          }
        }
      }
    }

    function setupIdes(languages) {
      var ides = [];
      for (var i = 0; i < languages.length; i++) {
        var language = languages[i];
        for (var j = 0; j < language.ides.length; j++) {
          var ide = language.ides[j];
          if (!ides[ide.name]) {
            ide.languages = [];
            ides[ide.name] = ide;
          }
          ides[ide.name].languages.push(language.language);
        }
      }
      $scope.ides = _.values(ides);
    }

    updateLanguages();

    /*** Metrics ***/

    $scope.metrics = {};
    $scope.metrics.selected = null;

    function processMetrics(data) {
      $scope.metrics.timeseries = {};
      $scope.metrics.timeseries.options = {
        maintainAspectRatio: false,
        scaleFontColor: '#ffffff',
        scaleFontSize: 14,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      $scope.metrics.timeseries.series = ['Total Users', 'Active Users'];
      $scope.metrics.timeseries.labels = _.map(data.timeseries, 'label');
      $scope.metrics.timeseries.totalUsers = _.map(data.timeseries, 'totalUsers');
      $scope.metrics.timeseries.activeUsers = _.map(data.timeseries, 'activeUsers');

      $scope.metrics.timeseries.data = [$scope.metrics.timeseries.totalUsers, $scope.metrics.timeseries.activeUsers];
      $scope.metrics.last7days = data.current;
      $scope.metrics.last7days.labels = ['Active Users', 'Inactive Users'];

      // Remove
      // $scope.metrics.last7days.totalUsers = 50;
      // $scope.metrics.last7days.activeUsers = 30;

      $scope.metrics.last7days.data = [
        $scope.metrics.last7days.activeUsers,
        $scope.metrics.last7days.totalUsers - $scope.metrics.last7days.activeUsers,
      ];
    }

    function updateMetrics() {
      AdminApiService.addLoading();
      SenseiApiService.getMetrics()
        .then(processMetrics)
        .catch(function (response) {
          $translate(['FAILED_TO_GET_METRICS']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_GET_METRICS, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    }

    $scope.updateMetricsByCompany = function () {
      AdminApiService.addLoading();
      SenseiApiService.getMetricsByCompany($scope.metrics.selected._id)
        .then(processMetrics)
        .catch(function (response) {
          $translate(['FAILED_TO_GET_METRICS_BY_COMPANY']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_GET_METRICS_BY_COMPANY, response);
          });
        })
        .finally(AdminApiService.removeLoading);
    };

    $scope.senseiCompanySearch = {
      options: { page: 1, size: 10 },
    };

    $scope.activeUsersLabels = ['Active Users', 'Inactive Users'];

    $scope.donutOptions = {
      cutoutPercentage: 70,
      responsive: true,
      maintainAspectRatio: true,
    };

    $scope.chartJSDonutColours = ['rgba(255,183,0,0.8)', 'rgba(255,255,255,0.5)'];

    $scope.totalInstallsColours = ['rgba(255,255,255,0.5)', 'rgba(255,183,0,0.8)'];

    if (AuthService.isAuthorized([USER_ROLES.admin, USER_ROLES.companyAdmin, USER_ROLES.manager])) {
      updateMetrics();
    }
  },
]);
