import { useState, useCallback, createContext, useContext } from 'react';
export const goalFilterSessionStorageKey = 'goals_filter';
export const GoalFilterContext = createContext(null);
export const useGoalFilterContext = () => {
    const useGoalFilterStore = useContext(GoalFilterContext);
    if (!useGoalFilterStore) {
        throw new Error('useGoalFilterContext must be used within a GoalFilterContext provider');
    }
    return useGoalFilterStore;
};
export const useGoalFilterStore = () => {
    const [filteredCategory, _setFilteredCategory] = useState(() => window.sessionStorage.getItem(goalFilterSessionStorageKey));
    const setFilteredCategory = useCallback((newCategory) => {
        try {
            if (newCategory) {
                window.sessionStorage.setItem(goalFilterSessionStorageKey, newCategory);
            }
            else {
                window.sessionStorage.removeItem(goalFilterSessionStorageKey);
            }
            _setFilteredCategory(newCategory);
        }
        catch (error) {
            console.error('Error updating category filter:', error);
        }
    }, []);
    return { filteredCategory, setFilteredCategory };
};
