import angular from 'angular';
import MODULE from './module';

angular
  .module(MODULE)
  /**
   * App specific code to prevent usersnap from stealing initial page load focus.
   */
  .run([
    '$window',
    function ($window) {
      var _autofocused = null;
      angular.element($window).on('focusin', snapSnap);

      function snapSnap(event) {
        var elmt = $(event.target);
        if (elmt.attr('autofocus')) _autofocused = elmt;
        // else if (elmt.attr("id") === "us_report_button") {
        // 	autofocused && autofocused.focus();
        // }
      }
    },
  ])
  /**
   * Actual auto-focus logic on element creation.
   */
  .directive('autofocus', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'A',
        link: function (_scope, element, attrs) {
          element.attr('tabindex', 0);
          $timeout(function () {
            if (attrs.autofocus) element = element.find(attrs.autofocus);

            element.attr('tabindex', 0);
            element.focus();
          }, attrs.autofocusWait || 500);
        },
      };
    },
  ]);
