var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useApiClient } from '../../ApiContext';
import { useCybermonContext } from '../context/CybermonContext';
export const useCybermon = () => {
    const { trpc } = useApiClient();
    const { nextCybermon, cyberHero, activeCybermon, cybermon, livesEarned, didEarnActiveCybermon, didEarnCyberHero, status, nextCybermonStart, userCompleted, } = useCybermonContext();
    const attemptCybermon = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!activeCybermon || activeCybermon.attempted || activeCybermon.earned) {
            return;
        }
        yield trpc.achievements.updateEarnedAchievement.mutate({
            achievementId: activeCybermon._id.toString(),
            type: 'attempt',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    return {
        cybermon,
        activeCybermon,
        cyberHero,
        livesEarned,
        didEarnActiveCybermon,
        didEarnCyberHero,
        nextCybermon,
        status,
        beginAttempt: attemptCybermon,
        nextCybermonStart,
        userCompleted,
    };
};
