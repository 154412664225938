import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { Trans, useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../platformContext';
import { useCybermon } from '../hooks/useCybermon';
import FlipClock from '../../common/FlippingClock/Countdown';
import { ResultsScreen } from './ResultsScreen';
const CybermonDetailContainer = ({ src, alt, children }) => {
    return (_jsx(Card, { display: "flex", alignItems: "center", flexDirection: "column", gap: 3, height: "fit-content", sx: { p: 0, overflow: 'hidden' }, children: _jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' } }, children: [_jsx("img", { src: src, alt: alt, style: {
                        width: '100%',
                        height: '100%',
                        objectPosition: 'center center',
                        objectFit: 'cover',
                        textAlign: 'center',
                    } }), children] }) }));
};
const PendingCybermonTitle = ({ preEvent }) => {
    return (_jsx(Box, { sx: { '& strong': { color: (theme) => theme.palette.text.status.success } }, children: preEvent ? (_jsx(Typography, { variant: "h1", align: "center", children: _jsx(Trans, { i18nKey: 'pages.cybermon.pending.comingSoon' }) })) : (_jsx(Typography, { variant: "h1", align: "center", children: _jsx(Trans, { i18nKey: 'pages.cybermon.pending.nextCybermon' }) })) }));
};
const PendingCybermon = ({ src, alt, isPreEvent }) => {
    const { nextCybermonStart } = useCybermon();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.pending' });
    const [timeLeft, setTimeLeft] = useState();
    useEffect(() => {
        if (!nextCybermonStart.valueOf())
            return;
        const timer = setInterval(() => {
            const difference = nextCybermonStart.valueOf() - Date.now();
            const totalSeconds = Math.floor(difference / 1000);
            if (difference < 1) {
                clearInterval(timer);
                window.location.reload();
            }
            setTimeLeft({
                days: Math.max(0, Math.floor(totalSeconds / (60 * 60 * 24))),
                hours: Math.max(0, Math.floor((totalSeconds / (60 * 60)) % 24)),
                mins: Math.max(0, Math.floor((totalSeconds / 60) % 60)),
                secs: Math.max(0, totalSeconds % 60),
            });
        }, 1000);
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(CybermonDetailContainer, { src: src, alt: alt, children: _jsxs(Stack, { justifyContent: "center", gap: 8, p: 7, children: [_jsx(PendingCybermonTitle, { preEvent: isPreEvent }), _jsx(FlipClock, { timeLeft: timeLeft !== null && timeLeft !== void 0 ? timeLeft : { days: 0, hours: 0, mins: 0, secs: 0 } }), _jsx(Typography, { align: "center", children: _jsx(Trans, { i18nKey: 'pages.cybermon.pending.checkBack' }) }), _jsx(Link, { href: "#/explore", rel: "noopener noreferrer", textAlign: "center", children: t('exploreLink') })] }) }));
};
export const Details = ({ cybermon }) => {
    const { metadata } = usePlatformContext();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon' });
    const { t: ct } = useTranslation('react_components', {
        keyPrefix: `pages.cybermon.${cybermon.name}`,
    });
    const categoryName = React.useMemo(() => {
        var _a, _b;
        const categoryPath = cybermon.custom_data.category.split('.');
        if (categoryPath.length < 2)
            return cybermon.custom_data.category;
        const [root, sub] = categoryPath;
        const category = (_a = metadata.categories[root]) === null || _a === void 0 ? void 0 : _a[sub];
        return (_b = category === null || category === void 0 ? void 0 : category.name) !== null && _b !== void 0 ? _b : cybermon.custom_data.category;
    }, [cybermon, metadata.categories]);
    const weaknesses = Object.values(ct(`weaknesses`, { returnObjects: true }));
    return (_jsxs(Stack, { sx: { background: (theme) => theme.palette.container.fill.card2, p: 7, overflow: 'auto' }, direction: "column", gap: 8, children: [_jsxs(Box, { className: "Title", children: [_jsx(Typography, { variant: "h3", children: cybermon.name }), _jsx(Typography, { variant: "subtitle1", children: categoryName !== null && categoryName !== void 0 ? categoryName : cybermon.custom_data.category })] }), _jsx(Box, { className: "Description", children: _jsx(Typography, { sx: { textWrap: 'pretty' }, children: ct(`description`) }) }), _jsxs(Stack, { className: "SignatureMove", gap: 3, children: [_jsx(Typography, { variant: "overline", children: t('detail.signatureMove') }), _jsxs(Typography, { sx: { textWrap: 'pretty' }, children: [_jsx("span", { style: { display: 'block', fontWeight: 'bold' }, children: ct(`signatureMove.name`) }), ct(`signatureMove.description`)] })] }), _jsxs(Stack, { className: "WaysToDefeat", gap: 3, children: [_jsx(Typography, { variant: "overline", children: t('detail.waysToDefeat') }), weaknesses.map((weakness) => (_jsxs(Typography, { sx: { textWrap: 'pretty' }, children: [_jsx("span", { style: { display: 'block', fontWeight: 'bold' }, children: weakness.name }), weakness.description] }, weakness.name)))] })] }));
};
export const ActiveCybermonDetails = ({ activeCybermon }) => {
    return (_jsx(CybermonDetailContainer, { src: activeCybermon.custom_data.images.detail.full, alt: activeCybermon.name, children: _jsx(Details, { cybermon: activeCybermon }) }));
};
export const CybermonDetail = () => {
    const { activeCybermon, nextCybermon, status } = useCybermon();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon' });
    if (activeCybermon && !activeCybermon.earned) {
        return _jsx(ActiveCybermonDetails, { activeCybermon: activeCybermon });
    }
    else if (nextCybermon) {
        return (_jsx(PendingCybermon, { src: nextCybermon.custom_data.images.undiscovered.full, alt: t('pending.nextCybermon'), isPreEvent: !status.started }));
    }
    else {
        return _jsx(ResultsScreen, {});
    }
};
