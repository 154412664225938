export const label = {
    challenge: 'common.activityType.challenge',
    video: 'common.activityType.video',
    guideline: 'common.activityType.guideline',
    coding_lab: 'common.activityType.codingLab',
    mission: 'common.activityType.mission',
    walkthrough: 'common.activityType.walkthrough',
};
// This needs to match the backend version of estimatedDuration: @scw/portal-backend/src/learn/course/types.ts
export const estimatedDuration = {
    challenge: 3,
    video: 2,
    guideline: 10,
    coding_lab: 15,
    mission: 5,
    walkthrough: 5,
};
