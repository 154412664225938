import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { styled, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RemoveTextButton } from '../../atoms/TextButton';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export const GoalEditorBadgeSelector = ({ badge, disabled, onBadgeEdit, onBadgeError, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {
        var _a;
        const selectedFile = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string') {
                onBadgeEdit(reader.result);
            }
        };
        const sizeInKb = ((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.size) || 0) / 1024;
        if (selectedFile && sizeInKb <= 2048) {
            reader.readAsDataURL(selectedFile);
        }
        else if (selectedFile) {
            onBadgeError === null || onBadgeError === void 0 ? void 0 : onBadgeError(new Error(t('goalsAdmin.components.organisms.goalEditor.sections.badge.badgeSpecsSizeError')));
        }
    };
    const clearInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            onBadgeEdit(null);
        }
    };
    return (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: theme.spacing(4) }, children: [_jsxs(Button, { component: "label", role: undefined, tabIndex: -1, sx: {
                    height: theme.spacing(20),
                    width: theme.spacing(20),
                    '&.MuiButton-text:not(:focus):not(:focus-within)': {
                        borderColor: badge
                            ? 'transparent'
                            : disabled
                                ? theme.palette.container.border.default
                                : theme.palette.container.border.input,
                    },
                    '&.MuiButton-text:not(:disabled):not(:focus):hover': {
                        backgroundColor: theme.palette.container.fill.card2,
                        borderColor: theme.palette.container.border.active,
                    },
                    overflow: 'hidden',
                    '&:focus-within': {
                        borderColor: theme.palette.clickable.default,
                        backgroundColor: theme.palette.container.fill.card2,
                    },
                    padding: 0,
                }, disabled: disabled, children: [badge ? (_jsx("img", { src: badge, alt: t('goalsAdmin.components.organisms.goalEditor.sections.badge.badgePreviewAltText'), style: {
                            height: theme.spacing(20),
                            width: theme.spacing(20),
                            minWidth: theme.spacing(20),
                            borderColor: theme.palette.container.border.input,
                        } })) : (_jsx(AddRoundedIcon, { sx: {
                            fontSize: theme.typography.pxToRem(44),
                            color: disabled ? theme.palette.container.border.default : theme.palette.clickable.nav.item.default,
                        } })), _jsx(VisuallyHiddenInput, { "aria-label": t('goalsAdmin.components.organisms.goalEditor.sections.badge.badgeInputLabel'), ref: fileInputRef, disabled: disabled, type: "file", accept: ".png,.jpg,.jpeg,.svg", onChange: handleFileChange })] }), badge && !disabled && (_jsx(RemoveTextButton, { onClick: clearInput, label: "goalsAdmin.components.organisms.goalEditor.sections.badge.removeImageButtonText" }))] }));
};
