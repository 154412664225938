import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { createFilterState } from './FilterState';
import { CodingLabIcon } from './icons/CodingLabIcon';
import { VideoIcon } from './icons/VideoIcon';
import { ChallengeIcon } from './icons/ChallengeIcon';
import { GuidelineIcon } from './icons/GuidelineIcon';
import { MissionIcon } from './icons/MissionIcon';
import { WalkthroughIcon } from './icons/WalkthroughIcon';
import { isVideo } from './ContentItem';
export const createFilteringEvent = (state, resultCount) => {
    return {
        event: 'filtering',
        scope: 'explore',
        count: resultCount,
        applied_filters_type_search_term: state.searchTerm,
        applied_filters_type_activity_type: Array.from(state.activityType).join(', '),
        applied_filters_type_language: Array.from(state.language).join(', '),
        applied_filters_type_category: Array.from(state.category).join(', '),
        applied_filters_type_status: Array.from(state.status).join(', '),
    };
};
export const calculateSearchRelevance = (searchTerms, i) => {
    const relevance = Object.values(i.textSearch).reduce((acc, prop) => {
        return searchTerms.reduce((acc, term) => {
            return acc + (prop.includes(term) ? term.length / prop.length : 0);
        }, acc);
    }, 0);
    return i.status === 'in_progress' ? relevance * 5 : relevance;
};
const visibleInState = (state, value) => state.length === 0 || state.includes(value);
export const calculateRelevance = (item, filterState, searchTerms) => {
    if ((visibleInState(filterState.category, item.category.id) ||
        visibleInState(filterState.category, item.category.id.substring(0, item.category.id.lastIndexOf('.')))) &&
        (isVideo(item) || visibleInState(filterState.language, item.language.id)) &&
        visibleInState(filterState.status, item.status) &&
        visibleInState(filterState.activityType, item.type)) {
        return searchTerms.length ? calculateSearchRelevance(searchTerms, item) : 1;
    }
    return 0;
};
export const applyChangeToFilter = (state, change) => {
    switch (change.action) {
        case 'reset':
            if (change.target === 'all') {
                return createFilterState();
            }
            if (change.target === 'searchTerm') {
                state['searchTerm'] = '';
            }
            else {
                state[change.target] = [];
            }
            return state;
        case 'search':
            state.searchTerm = change.value;
            return state;
        case 'changeCategories':
            state.category = change.value;
            return state;
        case 'changeLanguages':
            state.language = change.value;
            return state;
        default:
            change.action === 'add'
                ? state[change.type].push(change.value)
                : (state[change.type] = state[change.type].filter((x) => x != change.value));
            return state;
    }
};
export const mapFilterActionToFilteringEventTrigger = (filter) => {
    const common = { event: 'filter_trigger', scope: 'explore' };
    if (filter.action === 'reset')
        return Object.assign(Object.assign({}, common), { type: filter.action, target: filter.target });
    if (filter.action === 'search')
        return Object.assign(Object.assign({}, common), { type: filter.action, search_term: filter.value });
    if (filter.action === 'add' || filter.action === 'delete')
        return Object.assign(Object.assign({}, common), { type: filter.action, filter_name: filter.type, item_key: filter.value });
    if (filter.action === 'changeCategories')
        return Object.assign(Object.assign({}, common), { type: 'replace', filter_name: 'categories', categories: filter.value });
    if (filter.action === 'changeLanguages')
        return Object.assign(Object.assign({}, common), { type: 'replace', filter_name: 'languages', languages: filter.value });
    return assertNever(filter.action, 'invalid filter action');
};
export const statusTranslations = {
    in_progress: 'common.status.inProgress',
    completed: 'common.status.completed',
    not_started: 'common.status.notStarted',
};
export const possibleStatusOptions = {
    in_progress: {
        label: statusTranslations['in_progress'],
    },
    not_started: {
        label: statusTranslations['not_started'],
    },
    completed: {
        label: statusTranslations['completed'],
    },
};
export const possibleActivityTypeOptions = {
    coding_lab: {
        label: 'common.activityType.codingLab',
        icon: CodingLabIcon({}),
    },
    video: {
        label: 'common.activityType.video',
        icon: VideoIcon({}),
    },
    challenge: {
        label: 'common.activityType.challenge',
        icon: ChallengeIcon({}),
    },
    guideline: {
        label: 'common.activityType.guideline',
        icon: GuidelineIcon({}),
    },
    mission: {
        label: 'common.activityType.mission',
        icon: MissionIcon({}),
    },
    walkthrough: {
        label: 'common.activityType.walkthrough',
        icon: WalkthroughIcon({}),
    },
};
