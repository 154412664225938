import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuBookRounded } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { Link } from '../navigation';
import { Trans } from 'react-i18next';
export const LearnHero = () => {
    const theme = useTheme();
    return (_jsx("div", { style: {
            borderBottom: `1px solid ${theme.palette.container.border.default}`,
            background: theme.palette.container.fill.card1,
        }, children: _jsxs(Stack, { sx: {
                gap: 5,
                padding: 10,
                margin: '0 auto',
                maxWidth: '960px',
            }, children: [_jsxs(Stack, { direction: "row", sx: (theme) => ({
                        gap: 2,
                        alignItems: 'center',
                        color: theme.palette.text.title,
                    }), children: [_jsx(MenuBookRounded, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(40) }) }), _jsx(Typography, { variant: "h2", component: "h1", children: _jsx(Trans, { i18nKey: "pages.learn.overview.hero.title" }) })] }), _jsxs(Stack, { sx: {
                        gap: 1,
                    }, children: [_jsx(Typography, { children: _jsx(Trans, { i18nKey: "pages.learn.overview.hero.info1" }) }), _jsx(Typography, { children: _jsxs(Trans, { i18nKey: "pages.learn.overview.hero.info2", children: [_jsx(Link, { to: "/explore" }), "."] }) }), _jsx(Typography, { sx: (theme) => ({
                                color: theme.palette.text.soft,
                            }), children: _jsx(Trans, { i18nKey: "pages.learn.overview.hero.info3", children: _jsx(Link, { to: "/profile" }) }) })] })] }) }));
};
