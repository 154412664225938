var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import { fetchLearningProgress, fetchTrustScore } from './dataService';
const initialState = {
    cards: {
        'license-usage': { loading: false, error: null, data: null },
        'learning-progress': { loading: false, error: null, data: null },
        'learner-engagement': { loading: false, error: null, data: null },
        'trust-score': { loading: false, error: null, data: null },
    },
};
export const DashboardContext = createContext(undefined);
const dashboardReducer = (state, action) => {
    const updateCard = (cardId, updates) => (Object.assign(Object.assign({}, state), { cards: Object.assign(Object.assign({}, state.cards), { [cardId]: Object.assign(Object.assign({}, state.cards[cardId]), updates) }) }));
    switch (action.type) {
        case 'SET_LOADING':
            return updateCard(action.cardId, { loading: true });
        case 'SET_ERROR':
            return updateCard(action.cardId, { loading: false, error: action.error });
        case 'SET_DATA':
        case 'SET_LEARNING_PROGRESS_DATA':
        case 'SET_TRUST_SCORE_DATA':
        case 'SET_LICENSE_USAGE_DATA':
        case 'SET_LEARNER_ENGAGEMENT_DATA':
            return updateCard(action.cardId, { loading: false, error: null, data: action.data });
        default:
            return state;
    }
};
const useCardActions = (dispatch) => {
    const fetchCardData = useCallback((cardId) => __awaiter(void 0, void 0, void 0, function* () {
        switch (cardId) {
            case 'learning-progress': {
                const data = yield fetchLearningProgress();
                dispatch({ type: 'SET_LEARNING_PROGRESS_DATA', cardId, data });
                break;
            }
            case 'trust-score': {
                const data = yield fetchTrustScore();
                dispatch({ type: 'SET_TRUST_SCORE_DATA', cardId, data });
                break;
            }
            case 'license-usage': {
                dispatch({ type: 'SET_LICENSE_USAGE_DATA', cardId, data: null });
                break;
            }
            case 'learner-engagement': {
                dispatch({ type: 'SET_LEARNER_ENGAGEMENT_DATA', cardId, data: null });
                break;
            }
        }
    }), [dispatch]);
    const refreshCard = useCallback((cardId) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch({ type: 'SET_LOADING', cardId });
        try {
            yield fetchCardData(cardId);
        }
        catch (error) {
            dispatch({ type: 'SET_ERROR', cardId, error: error });
        }
    }), [fetchCardData, dispatch]);
    return { refreshCard };
};
export const DashboardProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dashboardReducer, initialState);
    const { refreshCard } = useCardActions(dispatch);
    const refreshFailedCards = useCallback(() => {
        Object.entries(state.cards).forEach(([cardId, cardState]) => {
            if (cardState.error) {
                refreshCard(cardId);
            }
        });
    }, [state.cards, refreshCard]);
    const value = {
        state,
        refreshFailedCards,
        refreshCard,
    };
    return _jsx(DashboardContext.Provider, { value: value, children: children });
};
export const useDashboard = () => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error('useDashboard must be used within a DashboardProvider');
    }
    return context;
};
export const useCardState = (cardId) => {
    const { state, refreshCard } = useDashboard();
    useEffect(() => {
        refreshCard(cardId);
    }, [cardId, refreshCard]);
    return state.cards[cardId];
};
