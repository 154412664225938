const availableTimeZones = Intl.supportedValuesOf('timeZone');
const getTimezoneAbbreviationsForLocale = (timeZones, locale, utc) => {
    var _a;
    const subbed = {
        'Europe/Guernsey': 'Europe/London',
        'Europe/Isle_of_Man': 'Europe/London',
        'Europe/Jersey': 'Europe/London',
    };
    const founds = {};
    // const unexpected: { timeZone: string; name: string }[] = [];
    for (const timeZone of timeZones) {
        const f = new Intl.DateTimeFormat(locale, { timeZoneName: 'short', timeZone: subbed[timeZone] || timeZone });
        const name = (_a = f.formatToParts(utc).find((part) => part.type === 'timeZoneName')) === null || _a === void 0 ? void 0 : _a.value;
        if (name && !/(GMT)|(UTC)\s*[+-]/.test(name)) {
            // if (name.includes('+') || name.includes('-')) {
            //   unexpected.push({ timeZone, name });
            // }
            founds[timeZone] = name;
        }
    }
    // if (unexpected.length > 0) {
    //   throw new Error(
    //     `Unexpected time zone abbreviations for locale ${locale}: ` +
    //       unexpected.map(({ timeZone, name }) => `${timeZone} (${name})`).join(', ')
    //   );
    // }
    return founds;
};
export const getTimezoneAbbreviations = (when) => {
    const mapped = {
        // This list is compiled with copilot. The few entries I double-checked are correct.
        'Europe/Moscow': 'MSK',
        'Europe/Minsk': 'MSK',
        'Europe/Kirov': 'MSK',
        'Europe/Simferopol': 'MSK',
        'Europe/Volgograd': 'MSK',
        'Europe/Samara': 'SAMT',
        'Europe/Saratov': 'SAMT',
        'Europe/Ulyanovsk': 'MSK+1',
        'Europe/Astrakhan': 'MSK+1',
        'Europe/Istanbul': 'EEST',
        'Asia/Shanghai': 'CST',
        'Asia/Hong_Kong': 'HKT',
        'Asia/Taipei': 'CST',
        'Asia/Tokyo': 'JST',
        'Asia/Baghdad': 'AST',
        'Asia/Bahrain': 'AST',
        'Asia/Kuwait': 'AST',
        'Asia/Qatar': 'AST',
        'Asia/Riyadh': 'AST',
        'Asia/Aden': 'AST',
        'Asia/Damascus': 'AST',
        'Asia/Amman': 'AST',
        'Asia/Nicosia': 'AST',
        'Asia/Beirut': 'AST',
        'Asia/Almaty': 'ALMT',
        'Asia/Anadyr': 'ANAT',
        'Asia/Aqtau': 'AQTT',
        'Asia/Aqtobe': 'AQTT',
        'Asia/Ashgabat': 'TMT',
        'Asia/Atyrau': 'AQTT',
        'Asia/Baku': 'AZT',
        'Asia/Barnaul': 'OMST',
        'Asia/Bishkek': 'KGT',
        'Asia/Chita': 'IRKT',
        'Asia/Choibalsan': 'CHOT',
        'Asia/Dushanbe': 'TJT',
        'Asia/Famagusta': 'EET',
        'Asia/Hovd': 'HOVT',
        'Asia/Irkutsk': 'IRKT',
        'Asia/Jerusalem': 'IDT',
        'Asia/Kamchatka': 'PETT',
        'Asia/Khandyga': 'YAKT',
        'Asia/Krasnoyarsk': 'KRAT',
        'Asia/Macau': 'CST',
        'Asia/Magadan': 'MAGT',
        'Asia/Manila': 'PHT',
        'Asia/Novokuznetsk': 'KRAT',
        'Asia/Novosibirsk': 'NOVT',
        'Asia/Omsk': 'OMST',
        'Asia/Oral': 'ORAT',
        'Asia/Pyongyang': 'KST',
        'Asia/Qostanay': 'QYZT',
        'Asia/Qyzylorda': 'QYZT',
        'Asia/Rangoon': 'MMT',
        'Asia/Sakhalin': 'SAKT',
        'Asia/Samarkand': 'UZT',
        'Asia/Seoul': 'KST',
        'Asia/Srednekolymsk': 'SRET',
        'Asia/Tashkent': 'UZT',
        'Asia/Tbilisi': 'GET',
        'Asia/Tomsk': 'KRAT',
        'Asia/Ulaanbaatar': 'ULAT',
        'Asia/Urumqi': 'XJT',
        'Asia/Ust-Nera': 'VLAT',
        'Asia/Vladivostok': 'VLAT',
        'Asia/Yakutsk': 'YAKT',
        'Asia/Yekaterinburg': 'YEKT',
        'Asia/Yerevan': 'AMT',
        'Indian/Chagos': 'IOT',
        'Indian/Kerguelen': 'TFT',
        'Indian/Mahe': 'SCT',
        'Indian/Mauritius': 'MUT',
        'Indian/Reunion': 'RET',
        'Pacific/Apia': 'WST',
        'Pacific/Bougainville': 'BST',
        'Pacific/Efate': 'VUT',
        'Pacific/Enderbury': 'PHOT',
        'Pacific/Fakaofo': 'TKT',
        'Pacific/Fiji': 'FJT',
        'Pacific/Funafuti': 'TVT',
        'Pacific/Guadalcanal': 'SBT',
        'Pacific/Guam': 'ChST',
        'Pacific/Kiritimati': 'LINT',
        'Pacific/Kosrae': 'KOST',
        'Pacific/Kwajalein': 'MHT',
        'Pacific/Majuro': 'MHT',
        'Pacific/Nauru': 'NRT',
        'Pacific/Norfolk': 'NFT',
        'Pacific/Noumea': 'NCT',
        'Pacific/Palau': 'PWT',
        'Pacific/Ponape': 'PONT',
        'Pacific/Port_Moresby': 'PGT',
        'Pacific/Saipan': 'ChST',
        'Pacific/Tarawa': 'GILT',
        'Pacific/Tongatapu': 'TOT',
        'Pacific/Truk': 'CHUT',
        'Pacific/Wake': 'WAKT',
        'Pacific/Wallis': 'WFT',
        'Antarctica/Davis': 'DAVT',
        'Antarctica/DumontDUrville': 'DDUT',
        'Antarctica/Mawson': 'MAWT',
        'Antarctica/Syowa': 'SYOT',
        'Antarctica/Troll': 'UTC',
        'Antarctica/Vostok': 'VOST',
    };
    const unmapped = Object.fromEntries(availableTimeZones.filter((x) => !Object.keys(mapped).includes(x)).map((tz) => [tz, tz]));
    for (const locale of [
        'en-GB',
        'en-US',
        'en-AU',
        'en-CA',
        'en-IN',
        'en-NZ',
        'en-ZA',
        'en-IE',
        'fr-FR',
        'pt-BR',
        'es-AR',
        'es-CL',
        'es-CO',
        'ja-JP',
        ...allLocales,
    ]) {
        const founds = getTimezoneAbbreviationsForLocale(Object.keys(unmapped), locale, when);
        for (const timeZone in founds) {
            delete unmapped[timeZone];
            mapped[timeZone] = founds[timeZone];
        }
    }
    // if (Object.keys(unmapped).length > 0) {
    //   throw new Error(
    //     `Could not find abbreviations for time zones (${Object.keys(unmapped).length}): ` +
    //       Object.keys(unmapped).join(', ')
    //   );
    // }
    return mapped;
};
const allLocales = [
    'af-ZA',
    // 'am-ET',
    // 'ar-AE',
    // 'ar-BH',
    // 'ar-DZ',
    // 'ar-EG',
    // 'ar-IQ',
    // 'ar-JO',
    // 'ar-KW',
    // 'ar-LB',
    // 'ar-LY',
    // 'ar-MA',
    'arn-CL',
    // 'ar-OM',
    // 'ar-QA',
    // 'ar-SA',
    // 'ar-SD',
    // 'ar-SY',
    // 'ar-TN',
    // 'ar-YE',
    'as-IN',
    'az-az',
    'az-Cyrl-AZ',
    'az-Latn-AZ',
    'ba-RU',
    'be-BY',
    // 'bg-BG',
    // 'bn-BD',
    // 'bn-IN',
    'bo-CN',
    'br-FR',
    'bs-Cyrl-BA',
    'bs-Latn-BA',
    'ca-ES',
    'co-FR',
    'cs-CZ',
    'cy-GB',
    'da-DK',
    'de-AT',
    'de-CH',
    'de-DE',
    'de-LI',
    'de-LU',
    'dsb-DE',
    'dv-MV',
    'el-CY',
    'el-GR',
    'en-029',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-cb',
    'en-GB',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MT',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-TT',
    'en-US',
    'en-ZA',
    'en-ZW',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-ES',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-PY',
    'es-SV',
    'es-US',
    'es-UY',
    'es-VE',
    'et-EE',
    'eu-ES',
    // 'fa-IR',
    'fi-FI',
    'fil-PH',
    'fo-FO',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-FR',
    'fr-LU',
    'fr-MC',
    'fy-NL',
    'ga-IE',
    'gd-GB',
    'gd-ie',
    'gl-ES',
    'gsw-FR',
    'gu-IN',
    'ha-Latn-NG',
    'he-IL',
    'hi-IN',
    'hr-BA',
    'hr-HR',
    'hsb-DE',
    'hu-HU',
    'hy-AM',
    'id-ID',
    'ig-NG',
    'ii-CN',
    'in-ID',
    'is-IS',
    'it-CH',
    'it-IT',
    'iu-Cans-CA',
    'iu-Latn-CA',
    'iw-IL',
    'ja-JP',
    'ka-GE',
    'kk-KZ',
    'kl-GL',
    'km-KH',
    'kn-IN',
    'kok-IN',
    'ko-KR',
    'ky-KG',
    'lb-LU',
    'lo-LA',
    'lt-LT',
    'lv-LV',
    'mi-NZ',
    'mk-MK',
    // 'ml-IN',
    'mn-MN',
    'mn-Mong-CN',
    'moh-CA',
    'mr-IN',
    'ms-BN',
    'ms-MY',
    'mt-MT',
    'nb-NO',
    'ne-NP',
    'nl-BE',
    'nl-NL',
    'nn-NO',
    'no-no',
    'nso-ZA',
    'oc-FR',
    'or-IN',
    'pa-IN',
    'pl-PL',
    // 'prs-AF',
    'ps-AF',
    'pt-BR',
    'pt-PT',
    'qut-GT',
    'quz-BO',
    'quz-EC',
    'quz-PE',
    'rm-CH',
    'ro-mo',
    'ro-RO',
    'ru-mo',
    'ru-RU',
    'rw-RW',
    'sah-RU',
    'sa-IN',
    'se-FI',
    'se-NO',
    'se-SE',
    'si-LK',
    'sk-SK',
    'sl-SI',
    'sma-NO',
    'sma-SE',
    'smj-NO',
    'smj-SE',
    'smn-FI',
    'sms-FI',
    'sq-AL',
    'sr-BA',
    'sr-CS',
    'sr-Cyrl-BA',
    'sr-Cyrl-CS',
    'sr-Cyrl-ME',
    'sr-Cyrl-RS',
    'sr-Latn-BA',
    'sr-Latn-CS',
    'sr-Latn-ME',
    'sr-Latn-RS',
    'sr-ME',
    'sr-RS',
    'sr-sp',
    'sv-FI',
    'sv-SE',
    'sw-KE',
    'syr-SY',
    'ta-IN',
    'te-IN',
    'tg-Cyrl-TJ',
    'th-TH',
    'tk-TM',
    'tlh-QS',
    'tn-ZA',
    'tr-TR',
    'tt-RU',
    'tzm-Latn-DZ',
    'ug-CN',
    'uk-UA',
    'ur-PK',
    'uz-Cyrl-UZ',
    'uz-Latn-UZ',
    'uz-uz',
    'vi-VN',
    'wo-SN',
    'xh-ZA',
    'yo-NG',
    'zh-CN',
    'zh-HK',
    'zh-MO',
    'zh-SG',
    'zh-TW',
    'zu-ZA',
];
