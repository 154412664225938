import { useApiHooksContext } from '../Api.hooks';
import { usePlatformNavigate } from '../navigation';
import { useMemo } from 'react';
export const multiAssessmentUrls = {
    editPage: (id) => `/assessments/multi/edit/${id}`,
    createPage: `/assessments/multi/create`,
};
export const useMultiAssessmentNavigation = () => {
    const nav = usePlatformNavigate();
    return {
        editPage: (record, editingEssentials) => nav.to({ pathname: multiAssessmentUrls.editPage(record._id), search: '', hash: '' }, { state: { record, editingEssentials } }),
    };
};
export const useMultiassessmentsApi = () => {
    const api = useApiHooksContext();
    return api.multiassessments;
};
export const useStableReferenceByJson = (value) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => value, [JSON.stringify(value)]);
};
