import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

angular.module(MODULE).controller('AssessmentsTakeController', [
  '$window',
  '$translate',
  '$state',
  '$scope',
  '$rootScope',
  '$swal',
  'AssessmentsApiService',
  'AssessmentsChallengeApi',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $window,
    $translate,
    $state,
    $scope,
    $rootScope,
    $swal,
    AssessmentsApiService,
    AssessmentsChallengeApi,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $window._assessmentQuest = $scope;

    var timeoutAlarm;

    $scope.playmode = 'assessments';
    $scope.quest = null;
    $scope.loading = true;
    $scope.beginChallenge = beginChallenge;
    $scope.nextChallenge = nextChallenge;
    $scope.onAbandon = onAbandon;

    $scope.assessment = {};
    $scope.attempts = {};

    $scope.$on('$destroy', function () {
      clearTimeout(timeoutAlarm);
    });

    this.$onInit = function () {
      $scope.loading = true;

      AssessmentsChallengeApi.getChallenge($state.params)
        .then((response) => {
          $scope.quest = response;
          $scope.loading = false;

          if (!$scope.quest) return;

          if ($scope.quest.deadline) {
            setTimeoutAlarm();
          }

          Promise.all([
            AssessmentsApiService.getAssessmentData($state.params._assessment),
            AssessmentsApiService.getMyAttemptsList($state.params._assessment),
          ]).then((result) => {
            const [assessment, attempts] = result;

            $scope.assessment = assessment;
            $scope.attempts = attempts.data;
          });
        })
        .catch(handleError);
    };

    function setTimeoutAlarm() {
      var alarmMillis = $scope.quest.deadline - Date.now();
      timeoutAlarm = setTimeout(assessmentTimedOut, alarmMillis);
    }

    function assessmentTimedOut() {
      $swal({
        type: 'warning',
        title: $translate.instant('OUT_OF_TIME'),
        text: $translate.instant('OUT_OF_TIME_DESC'),
      }).then(back);
    }

    function beginChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.BEGIN_CHALLENGE);
    }

    function nextChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.COMPLETE_CHALLENGE);
      $scope.loading = true;
      const { _assessment, _attempt } = $state.params;
      AssessmentsApiService.getAssessmentAttemptDetails(_assessment, _attempt)
        .then((response) => {
          if (response.status === 'done') {
            $swal({
              type: 'success',
              title: $translate.instant('ASSESSMENT_COMPLETED'),
              text: $translate.instant('ASSESSMENT_COMPLETED_DESC'),
            }).then(function () {
              return $state.go(
                'assessments.view.attempt',
                { assessmentId: _assessment, attemptId: _attempt, ownResult: true },
                { reload: true }
              );
            });
          } else {
            return $state.go('assessments.take', { _assessment, _attempt }, { reload: true });
          }
        })
        .catch(handleError);
    }

    function onAbandon() {
      const { retriesAllowed, retryWaitingHours, maxRetries } = $scope.assessment;
      const attemptsRemaining = retriesAllowed ? determineAttemptsRemaining($scope.assessment, $scope.attempts) : 0;

      let abandonAssessmentMessage = $translate.instant('ABANDON_ASSESSMENT_NO_RETRIES_MESSAGE');

      if (retriesAllowed && maxRetries > 0 && attemptsRemaining > 0) {
        abandonAssessmentMessage =
          retryWaitingHours > 0
            ? $translate.instant('ABANDON_ASSESSMENT_RETRIES_WAITING_PERIOD_MESSAGE', {
                attemptsRemaining,
                retryWaitingHours,
              })
            : $translate.instant('ABANDON_ASSESSMENT_RETRIES_MESSAGE', { attemptsRemaining });
      }

      if (retriesAllowed && (maxRetries === null || maxRetries === 0)) {
        abandonAssessmentMessage =
          retryWaitingHours > 0
            ? $translate.instant('ABANDON_ASSESSMENT_UNLIMITED_RETRIES_WAITING_PERIOD_MESSAGE', {
                retryWaitingHours,
              })
            : $translate.instant('ABANDON_ASSESSMENT_UNLIMITED_RETRIES_MESSAGE');
      }

      return $swal({
        type: 'warning',
        title: $translate.instant('ChallengeComponent.ConfirmAbandon.title'),
        text: abandonAssessmentMessage,
        html: true,
        keyResolution: false,
        a11y: true,
        showCancelButton: true,
        confirmButtonColor: 'var(--color-scw-red)',
        cancelButtonText: $translate.instant('CANCEL'),
        confirmButtonText: $translate.instant('CONFIRM'),
      }).then((confirm) => {
        if (confirm) {
          AssessmentsApiService.abandonAssessment($state.params._assessment, $state.params._attempt)
            .then(() => back(true))
            .catch((response) => {
              $swal({
                type: 'error',
                title: $translate.instant('ERROR'),
                text: $translate.instant(response?.data?.error ?? 'ERROR'),
                confirmButtonColor: 'var(--color-scw-red)',
                cancelButtonText: $translate.instant('CANCEL'),
                confirmButtonText: $translate.instant('CONFIRM'),
              });
            });
        }
      });
    }

    function reload() {
      $state.go($state.current.name, $state.params, { reload: true });
    }

    function back(reload) {
      $state.go(
        'assessments.view.attempt',
        {
          assessmentId: $state.params._assessment,
          attemptId: $state.params._attempt,
        },
        { reload }
      );
    }

    function determineAttemptsRemaining(assessment, attemptsData) {
      const { maxRetries } = assessment;
      const attempts = attemptsData?.length || 0;
      return (maxRetries ?? 0) + 1 - (typeof attempts === 'number' && attempts === attempts ? attempts : 0);
    }

    function sendAnalyticsEvent(eventName) {
      // This is bad, and rootScope will only be defined when navigating from assessment.view first
      const language_framework =
        $rootScope['assessments_language']?._language + ':' + $rootScope['assessments_language']?._framework || '';
      const eventProps = {
        challenge_id: $scope.quest?.challenge?._id,
        portal_area: $scope.playmode,
        language_framework,
      };
      AnalyticsService.logEvent(eventName, eventProps);
    }

    function handleError(response) {
      $scope.error = {
        status: response.status || '',
        message: response.data?.error || '',
        onReload: reload,
        onBack: back,
      };

      switch (response.status) {
        case 404:
          $scope.error.message = $translate.instant('UNABLE_TO_FIND_YOUR_ASSESSMENT_CONTACT_HOST');
          $scope.error.onReload = null;
          $scope.error.onBack = () => {
            return $state.go('assessments.list');
          };
          break;
        default:
          if (response.data && response.data.type && response.data.type === 'START_ERROR') {
            $scope.error.message = $translate.instant('ASSESSMENT_ONLY_ACCESSIBLE_FROM', {
              startTime: moment(response.data.time).format('LLLL'),
            });
          } else if (response.data && response.data.type && response.data.type === 'END_ERROR') {
            $scope.error.message = $translate.instant('ASSESSMENT_ONLY_ACCESSIBLE_UNTIL', {
              endTime: moment(response.data.time).format('LLLL'),
            });
          } else {
            $scope.error.message = `${$translate.instant('ERROR_RETRIEVING_CHALLENGE')}: ${response.data.error}`;
          }
          break;
      }
    }
  },
]);
