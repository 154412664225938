import { jsx as _jsx } from "react/jsx-runtime";
import { P, match } from 'ts-pattern';
import { Pending, useApiHooksContext, useApiLoad } from '../Api.hooks';
import { group } from '../../utils/Array';
import { IncludedAssessmentCard, getDataGridValues } from './AssessmentCard';
import { EditMultiAssessmentListSkeleton } from './EditMultiAssessmentPage.skeleton';
import { mapValues, trustingEntries, zipValues } from '../../utils/object';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
export const AssessmentSelectorLoader = (props) => {
    const api = useApiHooksContext();
    const response = useApiLoad(api.multiassessments.listAssessments.query, undefined);
    const { t } = useTranslation();
    return match(response)
        .with(Pending, () => _jsx(EditMultiAssessmentListSkeleton, { hideOverridesSkeleton: true }))
        .with(P.instanceOf(Error), () => _jsx("p", { children: t('pages.multiassessments.errorLoadingAssessments') }))
        .otherwise((response) => (_jsx(AssessmentSelector, Object.assign({ availableAssessments: response.assessments, shouldAnimateCards: false }, props))));
};
const AssessmentSelectorWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(20),
}));
export const AssessmentSelector = (props) => {
    return (_jsx(AssessmentSelectorWrapper, { children: _jsx(AssessmentList, Object.assign({}, props, { availableAssessments: props.availableAssessments.filter((a) => props.selectedAssessmentIds.includes(a._assessment)) })) }));
};
function AssessmentList(props) {
    const groups = group(props.availableAssessments, (item) => item._assessment);
    const overrideDataProperties = props.overrides
        .filter((item) => { var _a; return !((_a = props.filter) === null || _a === void 0 ? void 0 : _a.length) || props.filter.some((assessmentStackId) => { var _a, _b; return (_b = (_a = item[1]) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, assessmentStackId); }); })
        .map((item) => item[0]) || [];
    const validationProperties = Object.keys(props.assessmentSettingsValidations)
        .filter((key) => { var _a; return !((_a = props.filter) === null || _a === void 0 ? void 0 : _a.length) || props.filter.includes(key); })
        .map((key) => props.assessmentSettingsValidations[key])
        .flat()
        .map((item) => item.field);
    const modifiedProperties = Object.values(props.perAssessmentSettings)
        .filter((s) => { var _a; return !((_a = props.filter) === null || _a === void 0 ? void 0 : _a.length) || props.filter.includes(s.persisted._assessment); })
        .flatMap((s) => trustingEntries(mapValues(zipValues(s.editBuffer.assessmentSettings, s.persisted.assessmentSettings), ([a, b]) => a === b)))
        .filter(([, value]) => !value)
        .map(([key]) => key);
    const visibleDataProperties0 = [...overrideDataProperties, ...validationProperties, ...modifiedProperties];
    const visibleDataProperties = [
        ...visibleDataProperties0,
        ...(visibleDataProperties0.includes('emitsCertificate') ? ['_certificateTemplate'] : []),
    ];
    const visibilityMap = props.filter ? Object.fromEntries(props.filter.map((item) => [item, true])) : null;
    const { t } = useTranslation();
    return (_jsx("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
            overflowX: 'hidden',
        }, children: trustingEntries(groups)
            .map(([_assessment, assessments]) => [_assessment, assessments.length, assessments.sort((a, b) => b.version - a.version)[0]])
            .map(([_assessment, versionCount, latestRecord]) => [
            _assessment,
            {
                latestRecord,
                versionCount,
                activeAttempts: props.perAssessmentSettings[_assessment].persisted.activeAttempts,
                items: props.perAssessmentSettings[_assessment].editBuffer.assessmentSettings
                    ? getDataGridValues(t, props.perAssessmentSettings[_assessment].editBuffer.assessmentSettings, props.perAssessmentSettings[_assessment].persisted.assessmentSettings, props.perAssessmentSettings[_assessment].persisted.activeAttempts, props.defaultSettings, visibleDataProperties, props.assessmentSettingsValidations[_assessment] || [], props.timeLimitIsPerChallenge, latestRecord.assessmentContent.numberOfChallenges, props.essentials)
                    : [],
            },
        ])
            .sort(([, a], [, b]) => a.latestRecord.name.localeCompare(b.latestRecord.name))
            .map(([_assessment, x]) => {
            return (_jsx(IncludedAssessmentCard, Object.assign({}, props, { isVisible: visibilityMap === null || visibilityMap[_assessment] === true ? true : false, shouldAnimate: props.shouldAnimateCards, items: x.items, name: props.perAssessmentSettings[_assessment].editBuffer.name, persistedName: props.perAssessmentSettings[_assessment].persisted.name, references: props.perAssessmentSettings[_assessment].references, isTimeLimitPerChallenge: props.timeLimitIsPerChallenge, latestRecord: x.latestRecord, visibleDataProperties: visibleDataProperties, versionCount: x.versionCount, activeAttempts: x.activeAttempts, settings: props.perAssessmentSettings[_assessment].editBuffer.assessmentSettings, persistedSettings: props.perAssessmentSettings[_assessment].persisted.assessmentSettings, validations: props.assessmentSettingsValidations[_assessment] || [], resetProperty: (property, value) => props.resetProperty({ _assessment }, property, value), readOnlyAssessmentIds: props.readOnlyAssessmentIds }), _assessment));
        }) }));
}
