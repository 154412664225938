!(function (e, t) {
  'function' == typeof define && define.amd
    ? define(['angular', 'objectpath', 'tv4'], t)
    : 'object' == typeof exports
    ? (module.exports = t(require('angular'), require('objectpath'), require('tv4')))
    : (e.schemaForm = t(e.angular, e.objectpath, e.tv4));
})(this, function (e, t, r) {
  var n = [];
  try {
    e.module('ngSanitize'), n.push('ngSanitize');
  } catch (i) {}
  try {
    e.module('ui.sortable'), n.push('ui.sortable');
  } catch (i) {}
  try {
    e.module('angularSpectrumColorpicker'), n.push('angularSpectrumColorpicker');
  } catch (i) {}
  var o = e.module('schemaForm', n);
  return (
    e.module('schemaForm').provider('sfPath', [
      function () {
        var r = window.ObjectPath || t,
          n = { parse: r.parse };
        1 === e.version.major && e.version.minor < 3
          ? (n.stringify = function (e) {
              return Array.isArray(e) ? e.join('.') : e.toString();
            })
          : (n.stringify = r.stringify),
          (n.normalize = function (e, t) {
            return n.stringify(Array.isArray(e) ? e : n.parse(e), t);
          }),
          (this.parse = n.parse),
          (this.stringify = n.stringify),
          (this.normalize = n.normalize),
          (this.$get = function () {
            return n;
          });
      },
    ]),
    e.module('schemaForm').provider('sfBuilder', [
      'sfPathProvider',
      function (t) {
        var r = /[A-Z]/g,
          n = function (e, t) {
            return (
              (t = t || '_'),
              e.replace(r, function (e, r) {
                return (r ? t : '') + e.toLowerCase();
              })
            );
          },
          i = 0;
        'firstElementChild' in document.createDocumentFragment() ||
          Object.defineProperty(DocumentFragment.prototype, 'firstElementChild', {
            get: function () {
              for (var e, t = this.childNodes, r = 0, n = t.length; n > r; ++r)
                if (((e = t[r]), 1 === e.nodeType)) return e;
              return null;
            },
          });
        var o = {
          sfField: function (e) {
            e.fieldFrag.firstElementChild.setAttribute('sf-field', i), (e.lookup['f' + i] = e.form), i++;
          },
          ngModel: function (e) {
            if (e.form.key) {
              var r = e.form.key;
              e.state.keyRedaction && (r = r.slice(e.state.keyRedaction));
              var n;
              if (e.state.modelValue) n = e.state.modelValue;
              else {
                var i = t.stringify(r).replace(/"/g, '&quot;');
                (n = e.state.modelName || 'model'), i && (n += ('[' !== i[0] ? '.' : '') + i);
              }
              for (var o = e.fieldFrag.querySelectorAll('[sf-field-model]'), a = 0; a < o.length; a++) {
                var l = o[a],
                  s = l.getAttribute('sf-field-model');
                if (s && '' !== s)
                  if ('replaceAll' === s)
                    for (var u = l.attributes, c = 0; c < u.length; c++)
                      u[c].value &&
                        -1 !== u[c].value.indexOf('$$value') &&
                        (u[c].value = u[c].value.replace(/\$\$value\$\$/g, n));
                  else {
                    var f = l.getAttribute(s);
                    f && f.indexOf('$$value$$')
                      ? l.setAttribute(s, f.replace(/\$\$value\$\$/g, n))
                      : l.setAttribute(s, n);
                  }
                else l.setAttribute('ng-model', n);
              }
            }
          },
          simpleTransclusion: function (e) {
            var t = e.build(e.form.items, e.path + '.items', e.state);
            e.fieldFrag.firstChild.appendChild(t);
          },
          ngModelOptions: function (e) {
            e.form.ngModelOptions &&
              Object.keys(e.form.ngModelOptions).length > 0 &&
              e.fieldFrag.firstChild.setAttribute('ng-model-options', JSON.stringify(e.form.ngModelOptions));
          },
          transclusion: function (e) {
            var t = e.fieldFrag.querySelectorAll('[sf-field-transclude]');
            if (t.length)
              for (var r = 0; r < t.length; r++) {
                var n = t[r],
                  i = n.getAttribute('sf-field-transclude') || 'items',
                  o = e.form[i];
                if (o) {
                  var a = e.build(o, e.path + '.' + i, e.state);
                  n.appendChild(a);
                }
              }
          },
          condition: function (e) {
            if (e.form.condition) {
              var r = 'evalExpr(' + e.path + '.condition, { model: model, "arrayIndex": $index})';
              if (e.form.key) {
                var n = t.stringify(e.form.key);
                r =
                  'evalExpr(' +
                  e.path +
                  '.condition,{ model: model, "arrayIndex": $index, "modelValue": model' +
                  ('[' === n[0] ? '' : '.') +
                  n +
                  '})';
              }
              for (var i, o, a = e.fieldFrag.children || e.fieldFrag.childNodes, l = 0; l < a.length; l++)
                (i = a[l]),
                  (o = !1),
                  i.hasAttribute && i.hasAttribute('ng-if') && (o = i.getAttribute('ng-if')),
                  i.setAttribute && i.setAttribute('ng-if', o ? '(' + o + ') || (' + r + ')' : r);
            }
          },
          array: function (r) {
            var n = r.fieldFrag.querySelector('[schema-form-array-items]');
            if (n) {
              if (
                ((state = e.copy(r.state)),
                (state.keyRedaction = 0),
                (state.keyRedaction += r.form.key.length + 1),
                r.form.schema &&
                  r.form.schema.items &&
                  r.form.schema.items.type &&
                  -1 === r.form.schema.items.type.indexOf('object') &&
                  -1 === r.form.schema.items.type.indexOf('array'))
              ) {
                t.stringify(r.form.key).replace(/"/g, '&quot;') + '[$index]';
                state.modelValue = 'modelArray[$index]';
              } else state.modelName = 'item';
              state.arrayCompatFlag = !0;
              var i = r.build(r.form.items, r.path + '.items', state);
              n.appendChild(i);
            }
          },
        };
        this.builders = o;
        var a = [o.sfField, o.ngModel, o.ngModelOptions, o.condition];
        (this.stdBuilders = a),
          (this.$get = [
            '$templateCache',
            'schemaFormDecorators',
            'sfPath',
            function (e, t, r) {
              var i = function (e, t) {
                  if (e.key) {
                    var n = t[r.stringify(e.key)];
                    if (n) {
                      for (; n.firstChild; ) n.removeChild(n.firstChild);
                      return n;
                    }
                  }
                },
                l = function (e, t, r, o, a, s, u) {
                  (s = s || {}), (u = u || Object.create(null)), (a = a || 'schemaForm.form');
                  var c = document.createDocumentFragment();
                  return (
                    e.reduce(function (e, c, f) {
                      if (!c.type) return e;
                      var d = t[c.type] || t['default'];
                      if (d.replace) {
                        var m;
                        s.arrayCompatFlag = !1;
                        var p = document.createElement('div'),
                          h = r(c, d) || r(c, t['default']);
                        for (p.innerHTML = h, m = document.createDocumentFragment(); p.childNodes.length > 0; )
                          m.appendChild(p.childNodes[0]);
                        var v = {
                            fieldFrag: m,
                            form: c,
                            lookup: u,
                            state: s,
                            path: a + '[' + f + ']',
                            build: function (e, n, i) {
                              return l(e, t, r, o, n, i, u);
                            },
                          },
                          y = c.builder || d.builder;
                        'function' == typeof y
                          ? y(v)
                          : y.forEach(function (e) {
                              e(v);
                            }),
                          (i(c, o) || e).appendChild(m);
                      } else {
                        var g = document.createElement(n(t.__name, '-'));
                        s.arrayCompatFlag
                          ? g.setAttribute('form', 'copyWithIndex($index)')
                          : g.setAttribute('form', a + '[' + f + ']'),
                          (i(c, o) || e).appendChild(g);
                      }
                      return e;
                    }, c),
                    c
                  );
                };
              return {
                build: function (t, r, n, i) {
                  return l(
                    t,
                    r,
                    function (t, r) {
                      return 'template' === t.type ? t.template : e.get(r.template);
                    },
                    n,
                    void 0,
                    void 0,
                    i
                  );
                },
                builder: o,
                stdBuilders: a,
                internalBuild: l,
              };
            },
          ]);
      },
    ]),
    e.module('schemaForm').provider('schemaFormDecorators', [
      '$compileProvider',
      'sfPathProvider',
      function (t, r) {
        var n = '',
          i = {},
          o = function (e, t) {
            'sfDecorator' === e && (e = n);
            var r = i[e];
            return r[t.type] ? r[t.type].template : r['default'].template;
          },
          a = function (n) {
            t.directive(n, [
              '$parse',
              '$compile',
              '$http',
              '$templateCache',
              '$interpolate',
              '$q',
              'sfErrorMessage',
              'sfPath',
              'sfSelect',
              function (t, i, a, l, s, u, c, f, d) {
                return {
                  restrict: 'AE',
                  replace: !1,
                  transclude: !1,
                  scope: !0,
                  require: '?^sfSchema',
                  link: function (t, m, p, h) {
                    t.$on('schemaFormPropagateNgModelController', function (e, r) {
                      e.stopPropagation(), e.preventDefault(), (t.ngModel = r);
                    }),
                      (t.showTitle = function () {
                        return t.form && t.form.notitle !== !0 && t.form.title;
                      }),
                      (t.listToCheckboxValues = function (t) {
                        var r = {};
                        return (
                          e.forEach(t, function (e) {
                            r[e] = !0;
                          }),
                          r
                        );
                      }),
                      (t.checkboxValuesToList = function (t) {
                        var r = [];
                        return (
                          e.forEach(t, function (e, t) {
                            e && r.push(t);
                          }),
                          r
                        );
                      }),
                      (t.buttonClick = function (r, n) {
                        e.isFunction(n.onClick)
                          ? n.onClick(r, n)
                          : e.isString(n.onClick) &&
                            (h
                              ? h.evalInParentScope(n.onClick, { $event: r, form: n })
                              : t.$eval(n.onClick, { $event: r, form: n }));
                      }),
                      (t.evalExpr = function (e, r) {
                        return h ? h.evalInParentScope(e, r) : t.$eval(e, r);
                      }),
                      (t.evalInScope = function (e, r) {
                        return e ? t.$eval(e, r) : void 0;
                      }),
                      (t.interp = function (e, t) {
                        return e && s(e)(t);
                      }),
                      (t.hasSuccess = function () {
                        return t.ngModel
                          ? t.options && t.options.pristine && t.options.pristine.success === !1
                            ? t.ngModel.$valid && !t.ngModel.$pristine && !t.ngModel.$isEmpty(t.ngModel.$modelValue)
                            : t.ngModel.$valid && (!t.ngModel.$pristine || !t.ngModel.$isEmpty(t.ngModel.$modelValue))
                          : !1;
                      }),
                      (t.hasError = function () {
                        return t.ngModel ? t.ngModel.$invalid && !t.ngModel.$pristine : !1;
                      }),
                      (t.errorMessage = function (e) {
                        return c.interpolate(
                          (e && e.code + '') || 'default',
                          (t.ngModel && t.ngModel.$modelValue) || '',
                          (t.ngModel && t.ngModel.$viewValue) || '',
                          t.form,
                          t.options && t.options.validationMessage
                        );
                      });
                    var v = t.$watch(p.form, function (s) {
                      if (s) {
                        (s.ngModelOptions = s.ngModelOptions || {}), (t.form = s);
                        var c;
                        if ('template' === s.type && s.template) c = u.when(s.template);
                        else {
                          var p = 'template' === s.type ? s.templateUrl : o(n, s);
                          c = a.get(p, { cache: l }).then(function (e) {
                            return e.data;
                          });
                        }
                        c.then(function (n) {
                          if (s.key) {
                            var o = s.key ? r.stringify(s.key).replace(/"/g, '&quot;') : '';
                            n = n.replace(/\$\$value\$\$/g, 'model' + ('[' !== o[0] ? '.' : '') + o);
                          }
                          if ((m.html(n), s.condition)) {
                            var a = 'evalExpr(form.condition,{ model: model, "arrayIndex": arrayIndex})';
                            s.key &&
                              (a =
                                'evalExpr(form.condition,{ model: model, "arrayIndex": arrayIndex, "modelValue": model' +
                                f.stringify(s.key) +
                                '})'),
                              e.forEach(m.children(), function (e) {
                                var t = e.getAttribute('ng-if');
                                e.setAttribute('ng-if', t ? '(' + t + ') || (' + a + ')' : a);
                              });
                          }
                          i(m.contents())(t);
                        }),
                          s.key &&
                            (t.$on('schemaForm.error.' + s.key.join('.'), function (e, r, n, i, o) {
                              (o = i),
                                (n === !0 || n === !1) && ((i = n), (n = void 0)),
                                (void 0 == o || t.ngModel.$$parentForm.$name === o) &&
                                  t.ngModel &&
                                  r &&
                                  (t.ngModel.$setDirty
                                    ? t.ngModel.$setDirty()
                                    : ((t.ngModel.$dirty = !0), (t.ngModel.$pristine = !1)),
                                  n &&
                                    (s.validationMessage || (s.validationMessage = {}), (s.validationMessage[r] = n)),
                                  t.ngModel.$setValidity(r, i === !0),
                                  i === !0 && (t.ngModel.$validate(), t.$broadcast('schemaFormValidate')));
                            }),
                            t.$on('$destroy', function () {
                              if (!t.externalDestructionInProgress) {
                                var e = s.destroyStrategy || (t.options && t.options.destroyStrategy) || 'remove';
                                if (s.key && 'retain' !== e) {
                                  var r = t.model;
                                  if ((s.key.length > 1 && (r = d(s.key.slice(0, s.key.length - 1), r)), void 0 === r))
                                    return;
                                  var n = (s.schema && s.schema.type) || '';
                                  'empty' === e && -1 !== n.indexOf('string')
                                    ? (r[s.key.slice(-1)] = '')
                                    : 'empty' === e && -1 !== n.indexOf('object')
                                    ? (r[s.key.slice(-1)] = {})
                                    : 'empty' === e && -1 !== n.indexOf('array')
                                    ? (r[s.key.slice(-1)] = [])
                                    : 'null' === e
                                    ? (r[s.key.slice(-1)] = null)
                                    : delete r[s.key.slice(-1)];
                                }
                              }
                            })),
                          v();
                      }
                    });
                  },
                };
              },
            ]);
          },
          l = function (r, n, i) {
            (i = e.isDefined(i) ? i : !1),
              t.directive('sf' + r[0].toUpperCase() + r.substr(1), function () {
                return {
                  restrict: 'EAC',
                  scope: !0,
                  replace: !0,
                  transclude: i,
                  template: '<sf-decorator form="form"></sf-decorator>',
                  link: function (t, n, i) {
                    var o = { items: 'c', titleMap: 'c', schema: 'c' },
                      a = { type: r },
                      l = !0;
                    e.forEach(i, function (r, n) {
                      if ('$' !== n[0] && 0 !== n.indexOf('ng') && 'sfField' !== n) {
                        var s = function (r) {
                          e.isDefined(r) &&
                            r !== a[n] &&
                            ((a[n] = r), l && a.type && (a.key || e.isUndefined(i.key)) && ((t.form = a), (l = !1)));
                        };
                        'model' === n
                          ? t.$watch(r, function (e) {
                              e && t.model !== e && (t.model = e);
                            })
                          : 'c' === o[n]
                          ? t.$watchCollection(r, s)
                          : i.$observe(n, s);
                      }
                    });
                  },
                };
              });
          };
        (this.createDecorator = function (t, r) {
          (i[t] = { __name: t }),
            e.forEach(r, function (e, r) {
              i[t][r] = { template: e, replace: !1, builder: [] };
            }),
            i[n] || (n = t),
            a(t);
        }),
          (this.defineDecorator = function (t, r) {
            (i[t] = { __name: t }),
              e.forEach(r, function (r, n) {
                (r.builder = r.builder || []), (r.replace = e.isDefined(r.replace) ? r.replace : !0), (i[t][n] = r);
              }),
              i[n] || (n = t),
              a(t);
          }),
          (this.createDirective = l),
          (this.createDirectives = function (t) {
            e.forEach(t, function (e, t) {
              l(t, e);
            });
          }),
          (this.decorator = function (e) {
            return (e = e || n), i[e];
          }),
          (this.addMapping = function (e, t, r, n, o) {
            i[e] && (i[e][t] = { template: r, builder: n, replace: !!o });
          }),
          (this.defineAddOn = function (e, t, r, n) {
            i[e] && (i[e][t] = { template: r, builder: n, replace: !0 });
          }),
          (this.$get = function () {
            return {
              decorator: function (e) {
                return i[e] || i[n];
              },
              defaultDecorator: n,
            };
          }),
          a('sfDecorator');
      },
    ]),
    e.module('schemaForm').provider('sfErrorMessage', function () {
      var t = {
        default: 'Field does not validate',
        0: 'Invalid type, expected {{schema.type}}',
        1: 'No enum match for: {{viewValue}}',
        10: 'Data does not match any schemas from "anyOf"',
        11: 'Data does not match any schemas from "oneOf"',
        12: 'Data is valid against more than one schema from "oneOf"',
        13: 'Data matches schema from "not"',
        100: 'Value is not a multiple of {{schema.multipleOf}}',
        101: '{{viewValue}} is less than the allowed minimum of {{schema.minimum}}',
        102: '{{viewValue}} is equal to the exclusive minimum {{schema.minimum}}',
        103: '{{viewValue}} is greater than the allowed maximum of {{schema.maximum}}',
        104: '{{viewValue}} is equal to the exclusive maximum {{schema.maximum}}',
        105: 'Value is not a valid number',
        200: 'String is too short ({{viewValue.length}} chars), minimum {{schema.minLength}}',
        201: 'String is too long ({{viewValue.length}} chars), maximum {{schema.maxLength}}',
        202: 'String does not match pattern: {{schema.pattern}}',
        300: 'Too few properties defined, minimum {{schema.minProperties}}',
        301: 'Too many properties defined, maximum {{schema.maxProperties}}',
        302: 'Required',
        303: 'Additional properties not allowed',
        304: 'Dependency failed - key must exist',
        400: 'Array is too short ({{value.length}}), minimum {{schema.minItems}}',
        401: 'Array is too long ({{value.length}}), maximum {{schema.maxItems}}',
        402: 'Array items are not unique',
        403: 'Additional items not allowed',
        500: 'Format validation failed',
        501: 'Keyword failed: "{{title}}"',
        600: 'Circular $refs',
        1e3: 'Unknown property (not in schema)',
      };
      (t.number = t[105]),
        (t.required = t[302]),
        (t.min = t[101]),
        (t.max = t[103]),
        (t.maxlength = t[201]),
        (t.minlength = t[200]),
        (t.pattern = t[202]),
        (this.setDefaultMessages = function (e) {
          t = e;
        }),
        (this.getDefaultMessages = function () {
          return t;
        }),
        (this.setDefaultMessage = function (e, r) {
          t[e] = r;
        }),
        (this.$get = [
          '$interpolate',
          function (r) {
            var n = {};
            return (
              (n.defaultMessages = t),
              (n.interpolate = function (n, i, o, a, l) {
                l = l || {};
                var s = a.validationMessage || {};
                0 === n.indexOf('tv4-') && (n = n.substring(4));
                var u = s['default'] || l['default'] || '';
                [s, l, t].some(function (t) {
                  return e.isString(t) || e.isFunction(t) ? ((u = t), !0) : t && t[n] ? ((u = t[n]), !0) : void 0;
                });
                var c = {
                  error: n,
                  value: i,
                  viewValue: o,
                  form: a,
                  schema: a.schema,
                  title: a.title || (a.schema && a.schema.title),
                };
                return e.isFunction(u) ? u(c) : r(u)(c);
              }),
              n
            );
          },
        ]);
    }),
    e.module('schemaForm').provider('schemaForm', [
      'sfPathProvider',
      function (t) {
        var r = function (e) {
            if (Array.isArray(e) && 2 == e.length) {
              if ('null' === e[0]) return e[1];
              if ('null' === e[1]) return e[0];
            }
            return e;
          },
          n = function (e) {
            var t = [];
            return (
              e.forEach(function (e) {
                t.push({ name: e, value: e });
              }),
              t
            );
          },
          i = function (t, r) {
            if (!e.isArray(t)) {
              var n = [];
              return (
                r
                  ? e.forEach(r, function (e, r) {
                      n.push({ name: t[e], value: e });
                    })
                  : e.forEach(t, function (e, t) {
                      n.push({ name: e, value: t });
                    }),
                n
              );
            }
            return t;
          },
          o = function (t, n, i) {
            var o = h[r(n.type)];
            if (o)
              for (var a, l = 0; l < o.length; l++)
                if ((a = o[l](t, n, i)))
                  return (
                    a.schema['x-schema-form'] &&
                      e.isObject(a.schema['x-schema-form']) &&
                      (a = e.extend(a, a.schema['x-schema-form'])),
                    a
                  );
          },
          a = function (t, r, n) {
            n = n || {};
            var o = n.global && n.global.formDefaults ? e.copy(n.global.formDefaults) : {};
            return (
              n.global && n.global.supressPropertyTitles === !0 ? (o.title = r.title) : (o.title = r.title || t),
              r.description && (o.description = r.description),
              (n.required === !0 || r.required === !0) && (o.required = !0),
              r.maxLength && (o.maxlength = r.maxLength),
              r.minLength && (o.minlength = r.minLength),
              (r.readOnly || r.readonly) && (o.readonly = !0),
              r.minimum && (o.minimum = r.minimum + (r.exclusiveMinimum ? 1 : 0)),
              r.maximum && (o.maximum = r.maximum - (r.exclusiveMaximum ? 1 : 0)),
              r.validationMessage && (o.validationMessage = r.validationMessage),
              r.enumNames && (o.titleMap = i(r.enumNames, r['enum'])),
              (o.schema = r),
              (o.ngModelOptions = o.ngModelOptions || {}),
              o
            );
          },
          l = function (e, n, i) {
            if ('string' === r(n.type) && !n['enum']) {
              var o = a(e, n, i);
              return (o.key = i.path), (o.type = 'text'), (i.lookup[t.stringify(i.path)] = o), o;
            }
          },
          s = function (e, n, i) {
            if ('number' === r(n.type)) {
              var o = a(e, n, i);
              return (o.key = i.path), (o.type = 'number'), (i.lookup[t.stringify(i.path)] = o), o;
            }
          },
          u = function (e, n, i) {
            if ('integer' === r(n.type)) {
              var o = a(e, n, i);
              return (o.key = i.path), (o.type = 'number'), (i.lookup[t.stringify(i.path)] = o), o;
            }
          },
          c = function (e, n, i) {
            if ('boolean' === r(n.type)) {
              var o = a(e, n, i);
              return (o.key = i.path), (o.type = 'checkbox'), (i.lookup[t.stringify(i.path)] = o), o;
            }
          },
          f = function (e, i, o) {
            if ('string' === r(i.type) && i['enum']) {
              var l = a(e, i, o);
              return (
                (l.key = o.path),
                (l.type = 'select'),
                l.titleMap || (l.titleMap = n(i['enum'])),
                (o.lookup[t.stringify(o.path)] = l),
                l
              );
            }
          },
          d = function (e, i, o) {
            if ('array' === r(i.type) && i.items && i.items['enum']) {
              var l = a(e, i, o);
              return (
                (l.key = o.path),
                (l.type = 'checkboxes'),
                l.titleMap || (l.titleMap = n(i.items['enum'])),
                (o.lookup[t.stringify(o.path)] = l),
                l
              );
            }
          },
          m = function (n, i, l) {
            if ('object' === r(i.type)) {
              var s = a(n, i, l);
              return (
                (s.type = 'fieldset'),
                (s.key = l.path),
                (s.items = []),
                (l.lookup[t.stringify(l.path)] = s),
                e.forEach(i.properties, function (e, r) {
                  var n = l.path.slice();
                  if ((n.push(r), l.ignore[t.stringify(n)] !== !0)) {
                    var a = i.required && -1 !== i.required.indexOf(r),
                      u = o(r, e, { path: n, required: a || !1, lookup: l.lookup, ignore: l.ignore, global: l.global });
                    u && s.items.push(u);
                  }
                }),
                s
              );
            }
          },
          p = function (e, n, i) {
            if ('array' === r(n.type)) {
              var l = a(e, n, i);
              (l.type = 'array'), (l.key = i.path), (i.lookup[t.stringify(i.path)] = l);
              var s = n.required && -1 !== n.required.indexOf(i.path[i.path.length - 1]),
                u = i.path.slice();
              return (
                u.push(''),
                (l.items = [
                  o(e, n.items, { path: u, required: s || !1, lookup: i.lookup, ignore: i.ignore, global: i.global }),
                ]),
                l
              );
            }
          },
          h = { string: [f, l], object: [m], number: [s], integer: [u], boolean: [c], array: [d, p] },
          v = function (e) {
            return e;
          };
        (this.defaults = h),
          (this.stdFormObj = a),
          (this.defaultFormDefinition = o),
          (this.postProcess = function (e) {
            v = e;
          }),
          (this.appendRule = function (e, t) {
            h[e] || (h[e] = []), h[e].push(t);
          }),
          (this.prependRule = function (e, t) {
            h[e] || (h[e] = []), h[e].unshift(t);
          }),
          (this.createStandardForm = a),
          (this.$get = function () {
            var n = {};
            return (
              (n.merge = function (r, o, a, l, s, u) {
                (o = o || ['*']), (l = l || {}), (s = s || r.readonly || r.readOnly);
                var c = n.defaults(r, a, l),
                  f = o.indexOf('*');
                -1 !== f &&
                  (o = o
                    .slice(0, f)
                    .concat(c.form)
                    .concat(o.slice(f + 1)));
                var d = c.lookup;
                return v(
                  o.map(function (o) {
                    if (
                      ('string' == typeof o && (o = { key: o }),
                      o.key && 'string' == typeof o.key && (o.key = t.parse(o.key)),
                      o.titleMap && (o.titleMap = i(o.titleMap)),
                      o.itemForm)
                    ) {
                      o.items = [];
                      var c = t.stringify(o.key),
                        f = d[c];
                      e.forEach(f.items, function (t) {
                        var r = e.copy(o.itemForm);
                        (r.key = t.key), o.items.push(r);
                      });
                    }
                    if (o.key) {
                      var m = t.stringify(o.key);
                      if (d[m]) {
                        var p = d[m];
                        e.forEach(p, function (e, t) {
                          void 0 === o[t] && (o[t] = p[t]);
                        });
                      }
                    }
                    return (
                      s === !0 && (o.readonly = !0),
                      o.items && (o.items = n.merge(r, o.items, a, l, o.readonly, u)),
                      o.tabs &&
                        e.forEach(o.tabs, function (e) {
                          e.items = n.merge(r, e.items, a, l, o.readonly, u);
                        }),
                      'checkbox' === o.type && e.isUndefined(o.schema['default']) && (o.schema['default'] = !1),
                      u && 'template' === o.type && !o.template && o.templateUrl && u.push(o),
                      o
                    );
                  })
                );
              }),
              (n.defaults = function (t, n, i) {
                var a = [],
                  l = {};
                if (((n = n || {}), (i = i || {}), 'object' !== r(t.type)))
                  throw new Error('Not implemented. Only type "object" allowed at root level of schema.');
                return (
                  e.forEach(t.properties, function (e, r) {
                    if (n[r] !== !0) {
                      var s = t.required && -1 !== t.required.indexOf(r),
                        u = o(r, e, { path: [r], lookup: l, ignore: n, required: s, global: i });
                      u && a.push(u);
                    }
                  }),
                  { form: a, lookup: l }
                );
              }),
              (n.traverseSchema = function (t, r, n, i) {
                (i = e.isDefined(i) ? i : !0), (n = n || []);
                var o = function (t, r, n) {
                  if (
                    (r(t, n),
                    e.forEach(t.properties, function (e, t) {
                      var i = n.slice();
                      i.push(t), o(e, r, i);
                    }),
                    !i && t.items)
                  ) {
                    var a = n.slice();
                    a.push(''), o(t.items, r, a);
                  }
                };
                o(t, r, n || []);
              }),
              (n.traverseForm = function (t, r) {
                r(t),
                  e.forEach(t.items, function (e) {
                    n.traverseForm(e, r);
                  }),
                  t.tabs &&
                    e.forEach(t.tabs, function (t) {
                      e.forEach(t.items, function (e) {
                        n.traverseForm(e, r);
                      });
                    });
              }),
              n
            );
          });
      },
    ]),
    e.module('schemaForm').factory('sfSelect', [
      'sfPath',
      function (e) {
        var t = /^\d+$/;
        return function (r, n, i) {
          n || (n = this);
          var o = 'string' == typeof r ? e.parse(r) : r;
          if ('undefined' != typeof i && 1 === o.length) return (n[o[0]] = i), n;
          'undefined' != typeof i &&
            'undefined' == typeof n[o[0]] &&
            (n[o[0]] = o.length > 2 && t.test(o[1]) ? [] : {});
          for (var a = n[o[0]], l = 1; l < o.length; l++) {
            if ('' === o[l]) return void 0;
            if ('undefined' != typeof i) {
              if (l === o.length - 1) return (a[o[l]] = i), i;
              var s = a[o[l]];
              ('undefined' == typeof s || null === s) && ((s = t.test(o[l + 1]) ? [] : {}), (a[o[l]] = s)), (a = s);
            } else a && (a = a[o[l]]);
          }
          return a;
        };
      },
    ]),
    e.module('schemaForm').factory('sfValidator', [
      function () {
        var t = {};
        return (
          (t.validate = function (t, n) {
            if (!t) return { valid: !0 };
            var i = t.schema;
            if (!i) return { valid: !0 };
            '' === n && (n = void 0), 'number' === t.type && null === n && (n = void 0);
            var o = { type: 'object', properties: {} },
              a = t.key[t.key.length - 1];
            (o.properties[a] = i), t.required && (o.required = [a]);
            var l = {};
            return e.isDefined(n) && (l[a] = n), r.validateResult(l, o);
          }),
          t
        );
      },
    ]),
    e.module('schemaForm').directive('sfArray', [
      'sfSelect',
      'schemaForm',
      'sfValidator',
      'sfPath',
      function (t, r, n, i) {
        var o = function (e) {
          return function (t) {
            t.key && (t.key[t.key.indexOf('')] = e);
          };
        };
        return {
          restrict: 'A',
          scope: !0,
          require: '?ngModel',
          link: function (a, l, s, u) {
            var c = {};
            (a.validateArray = e.noop), u && a.$emit('schemaFormPropagateNgModelController', u);
            var f = a.$watch(s.sfArray, function (l) {
              if (l) {
                var s = t(l.key, a.model),
                  d = i.normalize(l.key);
                if (
                  (a.$watch('model' + ('[' !== d[0] ? '.' : '') + d, function (e) {
                    s = a.modelArray = e;
                  }),
                  e.isUndefined(s) && ((s = []), t(l.key, a.model, s)),
                  (a.modelArray = s),
                  l.items)
                ) {
                  var m = l.items[0];
                  l.items.length > 1 &&
                    (m = {
                      type: 'section',
                      items: l.items.map(function (t) {
                        return (
                          (t.ngModelOptions = l.ngModelOptions),
                          e.isUndefined(t.readonly) && (t.readonly = l.readonly),
                          t
                        );
                      }),
                    });
                }
                if (
                  ((a.copyWithIndex = function (t) {
                    if (!c[t] && m) {
                      var n = e.copy(m);
                      (n.arrayIndex = t), r.traverseForm(n, o(t)), (c[t] = n);
                    }
                    return c[t];
                  }),
                  (a.appendToArray = function () {
                    var n = s.length,
                      i = a.copyWithIndex(n);
                    if (
                      (r.traverseForm(i, function (r) {
                        if (r.key) {
                          var n;
                          e.isDefined(r['default']) && (n = r['default']),
                            e.isDefined(r.schema) && e.isDefined(r.schema['default']) && (n = r.schema['default']),
                            e.isDefined(n) && t(r.key, a.model, n);
                        }
                      }),
                      n === s.length)
                    ) {
                      var o,
                        u = t('schema.items.type', l);
                      'object' === u ? (o = {}) : 'array' === u && (o = []), s.push(o);
                    }
                    return a.validateArray(), s;
                  }),
                  (a.deleteFromArray = function (e) {
                    return s.splice(e, 1), a.validateArray(), u && u.$setDirty && u.$setDirty(), s;
                  }),
                  l.titleMap || l.startEmpty === !0 || 0 !== s.length || a.appendToArray(),
                  l.titleMap && l.titleMap.length > 0)
                ) {
                  a.titleMapValues = [];
                  var p = function (e) {
                    (a.titleMapValues = []),
                      (e = e || []),
                      l.titleMap.forEach(function (t) {
                        a.titleMapValues.push(-1 !== e.indexOf(t.value));
                      });
                  };
                  p(a.modelArray),
                    a.$watchCollection('modelArray', p),
                    a.$watchCollection('titleMapValues', function (e, t) {
                      if (e && e !== t) {
                        for (var r = a.modelArray; r.length > 0; ) r.pop();
                        l.titleMap.forEach(function (t, n) {
                          e[n] && r.push(t.value);
                        }),
                          a.validateArray();
                      }
                    });
                }
                if (u) {
                  var h;
                  (a.validateArray = function () {
                    var e = n.validate(l, a.modelArray.length > 0 ? a.modelArray : void 0);
                    Object.keys(u.$error)
                      .filter(function (e) {
                        return 0 === e.indexOf('tv4-');
                      })
                      .forEach(function (e) {
                        u.$setValidity(e, !0);
                      }),
                      e.valid !== !1 ||
                        !e.error ||
                        ('' !== e.error.dataPath && e.error.dataPath !== '/' + l.key[l.key.length - 1]) ||
                        (u.$setViewValue(a.modelArray), (h = e.error), u.$setValidity('tv4-' + e.error.code, !1));
                  }),
                    a.$on('schemaFormValidate', a.validateArray),
                    (a.hasSuccess = function () {
                      return a.options && a.options.pristine && a.options.pristine.success === !1
                        ? u.$valid && !u.$pristine && !u.$isEmpty(u.$modelValue)
                        : u.$valid && (!u.$pristine || !u.$isEmpty(u.$modelValue));
                    }),
                    (a.hasError = function () {
                      return a.options && a.options.pristine && a.options.pristine.errors === !1
                        ? u.$invalid && !u.$pristine
                        : u.$invalid;
                    }),
                    (a.schemaError = function () {
                      return h;
                    });
                }
                f();
              }
            });
          },
        };
      },
    ]),
    e.module('schemaForm').directive('sfChanged', function () {
      return {
        require: 'ngModel',
        restrict: 'AC',
        scope: !1,
        link: function (t, r, n, i) {
          var o = t.$eval(n.sfChanged);
          o &&
            o.onChange &&
            i.$viewChangeListeners.push(function () {
              e.isFunction(o.onChange)
                ? o.onChange(i.$modelValue, o)
                : t.evalExpr(o.onChange, { modelValue: i.$modelValue, form: o });
            });
        },
      };
    }),
    e.module('schemaForm').directive('sfField', [
      '$parse',
      '$compile',
      '$http',
      '$templateCache',
      '$interpolate',
      '$q',
      'sfErrorMessage',
      'sfPath',
      'sfSelect',
      function (t, r, n, i, o, a, l, s, u) {
        return {
          restrict: 'AE',
          replace: !1,
          transclude: !1,
          scope: !0,
          require: '^sfSchema',
          link: {
            pre: function (e, t, r, n) {
              e.$on('schemaFormPropagateNgModelController', function (t, r) {
                t.stopPropagation(), t.preventDefault(), (e.ngModel = r);
              }),
                (e.form = n.lookup['f' + r.sfField]);
            },
            post: function (t, r, n, i) {
              (t.showTitle = function () {
                return t.form && t.form.notitle !== !0 && t.form.title;
              }),
                (t.listToCheckboxValues = function (t) {
                  var r = {};
                  return (
                    e.forEach(t, function (e) {
                      r[e] = !0;
                    }),
                    r
                  );
                }),
                (t.checkboxValuesToList = function (t) {
                  var r = [];
                  return (
                    e.forEach(t, function (e, t) {
                      e && r.push(t);
                    }),
                    r
                  );
                }),
                (t.buttonClick = function (r, n) {
                  e.isFunction(n.onClick)
                    ? n.onClick(r, n)
                    : e.isString(n.onClick) &&
                      (i
                        ? i.evalInParentScope(n.onClick, { $event: r, form: n })
                        : t.$eval(n.onClick, { $event: r, form: n }));
                }),
                (t.evalExpr = function (e, r) {
                  return i ? i.evalInParentScope(e, r) : t.$eval(e, r);
                }),
                (t.evalInScope = function (e, r) {
                  return e ? t.$eval(e, r) : void 0;
                }),
                (t.interp = function (e, t) {
                  return e && o(e)(t);
                }),
                (t.hasSuccess = function () {
                  return t.ngModel
                    ? t.options && t.options.pristine && t.options.pristine.success === !1
                      ? t.ngModel.$valid && !t.ngModel.$pristine && !t.ngModel.$isEmpty(t.ngModel.$modelValue)
                      : t.ngModel.$valid && (!t.ngModel.$pristine || !t.ngModel.$isEmpty(t.ngModel.$modelValue))
                    : !1;
                }),
                (t.hasError = function () {
                  return t.ngModel
                    ? t.options && t.options.pristine && t.options.pristine.errors === !1
                      ? t.ngModel.$invalid && !t.ngModel.$pristine
                      : t.ngModel.$invalid
                    : !1;
                }),
                (t.errorMessage = function (e) {
                  return l.interpolate(
                    (e && e.code + '') || 'default',
                    (t.ngModel && t.ngModel.$modelValue) || '',
                    (t.ngModel && t.ngModel.$viewValue) || '',
                    t.form,
                    t.options && t.options.validationMessage
                  );
                });
              var a = t.form;
              a.key &&
                (t.$on('schemaForm.error.' + a.key.join('.'), function (e, r, n, i, o) {
                  (o = i),
                    (n === !0 || n === !1) && ((i = n), (n = void 0)),
                    (void 0 == o || t.ngModel.$$parentForm.$name === o) &&
                      t.ngModel &&
                      r &&
                      (t.ngModel.$setDirty
                        ? t.ngModel.$setDirty()
                        : ((t.ngModel.$dirty = !0), (t.ngModel.$pristine = !1)),
                      n && (a.validationMessage || (a.validationMessage = {}), (a.validationMessage[r] = n)),
                      t.ngModel.$setValidity(r, i === !0),
                      i === !0 && (t.ngModel.$validate(), t.$broadcast('schemaFormValidate')));
                }),
                t.$on('$destroy', function () {
                  if (!t.externalDestructionInProgress) {
                    var e = a.destroyStrategy || (t.options && t.options.destroyStrategy) || 'remove';
                    if (a.key && 'retain' !== e) {
                      var r = t.model;
                      if ((a.key.length > 1 && (r = u(a.key.slice(0, a.key.length - 1), r)), void 0 === r)) return;
                      var n = (a.schema && a.schema.type) || '';
                      'empty' === e && -1 !== n.indexOf('string')
                        ? (r[a.key.slice(-1)] = '')
                        : 'empty' === e && -1 !== n.indexOf('object')
                        ? (r[a.key.slice(-1)] = {})
                        : 'empty' === e && -1 !== n.indexOf('array')
                        ? (r[a.key.slice(-1)] = [])
                        : 'null' === e
                        ? (r[a.key.slice(-1)] = null)
                        : delete r[a.key.slice(-1)];
                    }
                  }
                }));
            },
          },
        };
      },
    ]),
    e.module('schemaForm').directive('sfMessage', [
      '$injector',
      'sfErrorMessage',
      function (t, r) {
        var n = t.has('$sanitize')
          ? t.get('$sanitize')
          : function (e) {
              return e;
            };
        return {
          scope: !1,
          restrict: 'EA',
          link: function (t, i, o) {
            var a = '';
            o.sfMessage &&
              t.$watch(o.sfMessage, function (e) {
                e && ((a = n(e)), u(!!t.ngModel));
              });
            var l,
              s = function (e) {
                e !== l && (i.html(e), (l = e));
              },
              u = function (n) {
                if (n)
                  if (t.hasError()) {
                    var i = [];
                    e.forEach(t.ngModel && t.ngModel.$error, function (e, t) {
                      e && i.push(t);
                    }),
                      (i = i.filter(function (e) {
                        return 'schemaForm' !== e;
                      }));
                    var o = i[0];
                    s(
                      o
                        ? r.interpolate(
                            o,
                            t.ngModel.$modelValue,
                            t.ngModel.$viewValue,
                            t.form,
                            t.options && t.options.validationMessage
                          )
                        : a
                    );
                  } else s(a);
                else s(a);
              };
            u();
            var c = t.$watch('ngModel', function (e) {
              e &&
                (e.$parsers.push(function (e) {
                  return u(!0), e;
                }),
                e.$formatters.push(function (e) {
                  return u(!0), e;
                }),
                c());
            });
            t.$watchCollection('ngModel.$error', function () {
              u(!!t.ngModel);
            });
          },
        };
      },
    ]),
    e.module('schemaForm').directive('sfNewArray', [
      'sfSelect',
      'sfPath',
      'schemaForm',
      function (t, r, n) {
        return {
          scope: !1,
          link: function (i, o, a) {
            (i.min = 0), (i.modelArray = i.$eval(a.sfNewArray));
            var l = function () {
                (i.modelArray = i.$eval(a.sfNewArray)),
                  (!(i.ngModel && i.ngModel.$pristine && i.firstDigest) ||
                    (i.options && i.options.validateOnRender === !0)) &&
                    i.validateField &&
                    i.validateField();
              },
              s = function () {
                i.form &&
                  i.form.onChange &&
                  (e.isFunction(i.form.onChange)
                    ? i.form.onChange(i.modelArray, i.form)
                    : i.evalExpr(i.form.onChange, { modelValue: i.modelArray, form: i.form }));
              },
              u = function () {
                var e = i.modelArray;
                if (!e) {
                  var n = r.parse(a.sfNewArray);
                  (e = []), t(n, i, e), (i.modelArray = e);
                }
                return e;
              },
              c = i.$watch('form', function (e) {
                if (e) {
                  if (
                    (e.titleMap ||
                      e.startEmpty === !0 ||
                      (i.modelArray && 0 !== i.modelArray.length) ||
                      i.appendToArray(),
                    i.form && i.form.schema && i.form.schema.uniqueItems === !0
                      ? (i.$watch(a.sfNewArray, l, !0), i.$watch([a.sfNewArray, a.sfNewArray + '.length'], s))
                      : i.$watchGroup
                      ? i.$watchGroup([a.sfNewArray, a.sfNewArray + '.length'], function () {
                          l(), s();
                        })
                      : (i.$watch(a.sfNewArray, function () {
                          l(), s();
                        }),
                        i.$watch(a.sfNewArray + '.length', function () {
                          l(), s();
                        })),
                    e.titleMap && e.titleMap.length > 0)
                  ) {
                    i.titleMapValues = [];
                    var t = function (t) {
                      (i.titleMapValues = []),
                        (t = t || []),
                        e.titleMap.forEach(function (e) {
                          i.titleMapValues.push(-1 !== t.indexOf(e.value));
                        });
                    };
                    t(i.modelArray),
                      i.$watchCollection('modelArray', t),
                      i.$watchCollection('titleMapValues', function (t, r) {
                        if (t && t !== r) {
                          var n = u();
                          e.titleMap.forEach(function (e, r) {
                            var i = n.indexOf(e.value);
                            -1 === i && t[r] && n.push(e.value), -1 === i || t[r] || n.splice(i, 1);
                          }),
                            i.validateField && i.validateField();
                        }
                      });
                  }
                  c();
                }
              });
            (i.appendToArray = function () {
              var r,
                o = u();
              if (i.form && i.form.schema && i.form.schema.items) {
                var a = i.form.schema.items;
                a.type && -1 !== a.type.indexOf('object')
                  ? ((r = {}),
                    (i.options && i.options.setSchemaDefaults === !1) ||
                      ((r = e.isDefined(a['default']) ? a['default'] : r),
                      r &&
                        n.traverseSchema(a, function (n, i) {
                          e.isDefined(n['default']) && t(i, r, n['default']);
                        })))
                  : a.type && -1 !== a.type.indexOf('array')
                  ? ((r = []), (i.options && i.options.setSchemaDefaults === !1) || (r = a['default'] || r))
                  : (i.options && i.options.setSchemaDefaults === !1) || (r = a['default'] || r);
              }
              return o.push(r), o;
            }),
              (i.deleteFromArray = function (e) {
                var t = i.modelArray;
                return t && t.splice(e, 1), t;
              });
            var f = function (e) {
                return function (t) {
                  t.key && (t.key[t.key.indexOf('')] = e);
                };
              },
              d = {};
            i.copyWithIndex = function (t) {
              var r = i.form;
              if (!d[t]) {
                var o = r.items[0];
                if (
                  (r.items.length > 1 &&
                    (o = {
                      type: 'section',
                      items: r.items.map(function (t) {
                        return (
                          (t.ngModelOptions = r.ngModelOptions),
                          e.isUndefined(t.readonly) && (t.readonly = r.readonly),
                          t
                        );
                      }),
                    }),
                  o)
                ) {
                  var a = e.copy(o);
                  (a.arrayIndex = t), n.traverseForm(a, f(t)), (d[t] = a);
                }
              }
              return d[t];
            };
          },
        };
      },
    ]),
    e.module('schemaForm').directive('sfSchema', [
      '$compile',
      '$http',
      '$templateCache',
      '$q',
      'schemaForm',
      'schemaFormDecorators',
      'sfSelect',
      'sfPath',
      'sfBuilder',
      function (t, r, n, i, o, a, l, s, u) {
        return {
          scope: { schema: '=sfSchema', initialForm: '=sfForm', model: '=sfModel', options: '=sfOptions' },
          controller: [
            '$scope',
            function (e) {
              this.evalInParentScope = function (t, r) {
                return e.$parent.$eval(t, r);
              };
              var t = this;
              e.lookup = function (e) {
                return e && (t.lookup = e), t.lookup;
              };
            },
          ],
          replace: !1,
          restrict: 'A',
          transclude: !0,
          require: '?form',
          link: function (s, c, f, d, m) {
            s.formCtrl = d;
            var p = {};
            m(s, function (e) {
              if ((e.addClass('schema-form-ignore'), c.prepend(e), c[0].querySelectorAll)) {
                var t = c[0].querySelectorAll('[ng-model]');
                if (t)
                  for (var r = 0; r < t.length; r++) {
                    var n = t[r].getAttribute('ng-model');
                    p[n.substring(n.indexOf('.') + 1)] = !0;
                  }
              }
            });
            var h,
              v = {},
              y = function (e, t) {
                var a = [],
                  l = o.merge(e, t, p, s.options, void 0, a);
                a.length > 0
                  ? i
                      .all(
                        a.map(function (e) {
                          return r.get(e.templateUrl, { cache: n }).then(function (t) {
                            e.template = t.data;
                          });
                        })
                      )
                      .then(function () {
                        g(e, t, l);
                      })
                  : g(e, t, l);
              },
              g = function (r, n, i) {
                h && ((s.externalDestructionInProgress = !0), h.$destroy(), (s.externalDestructionInProgress = !1)),
                  (h = s.$new()),
                  (h.schemaForm = { form: i, schema: r }),
                  c.children(':not(.schema-form-ignore)').remove();
                for (var d = {}, m = c[0].querySelectorAll('*[sf-insert-field]'), p = 0; p < m.length; p++)
                  d[m[p].getAttribute('sf-insert-field')] = m[p];
                var v = a.decorator(f.sfUseDecorator),
                  y = Object.create(null);
                s.lookup(y),
                  c[0].appendChild(u.build(i, v, d, y)),
                  (h.firstDigest = !0),
                  setTimeout(function () {
                    h.firstDigest = !1;
                  }, 0),
                  t(c.children())(h),
                  (s.options && s.options.setSchemaDefaults === !1) ||
                    o.traverseSchema(r, function (t, r) {
                      if (e.isDefined(t['default'])) {
                        var n = l(r, s.model);
                        e.isUndefined(n) && l(r, s.model, t['default']);
                      }
                    }),
                  s.$emit('sf-render-finished', c);
              },
              $ = ['*'];
            s.$watch(function () {
              var e = s.schema,
                t = s.initialForm || $;
              t &&
                e &&
                e.type &&
                (v.form !== t || v.schema !== e) &&
                Object.keys(e.properties).length > 0 &&
                ((v.schema = e), (v.form = t), y(e, t));
            }),
              s.$on('schemaFormRedraw', function () {
                var t = s.schema,
                  r = s.initialForm ? e.copy(s.initialForm) : ['*'];
                t && y(t, r);
              }),
              s.$on('$destroy', function () {
                s.externalDestructionInProgress = !0;
              }),
              (s.evalExpr = function (e, t) {
                return s.$parent.$eval(e, t);
              });
          },
        };
      },
    ]),
    e.module('schemaForm').directive('schemaValidate', [
      'sfValidator',
      '$parse',
      'sfSelect',
      function (t, r, n) {
        return {
          restrict: 'A',
          scope: !1,
          priority: 500,
          require: 'ngModel',
          link: function (r, i, o, a) {
            r.$emit('schemaFormPropagateNgModelController', a);
            var l = null,
              s = r.$eval(o.schemaValidate);
            s.copyValueTo &&
              a.$viewChangeListeners.push(function () {
                var t = s.copyValueTo;
                e.forEach(t, function (e) {
                  n(e, r.model, a.$modelValue);
                });
              });
            var u = function (e) {
              if (!s) return e;
              if (r.options && r.options.tv4Validation === !1) return e;
              var n = t.validate(s, e);
              return (
                Object.keys(a.$error)
                  .filter(function (e) {
                    return 0 === e.indexOf('tv4-');
                  })
                  .forEach(function (e) {
                    a.$setValidity(e, !0);
                  }),
                n.valid ? e : (a.$setValidity('tv4-' + n.error.code, !1), (l = n.error), a.$validators ? e : void 0)
              );
            };
            'function' == typeof s.ngModel && s.ngModel(a),
              ['$parsers', '$viewChangeListeners', '$formatters'].forEach(function (e) {
                s[e] &&
                  a[e] &&
                  s[e].forEach(function (t) {
                    a[e].push(t);
                  });
              }),
              ['$validators', '$asyncValidators'].forEach(function (t) {
                s[t] &&
                  a[t] &&
                  e.forEach(s[t], function (e, r) {
                    a[t][r] = e;
                  });
              }),
              a.$parsers.push(u),
              a.$validators &&
                (a.$validators.schemaForm = function () {
                  return !Object.keys(a.$error).some(function (e) {
                    return 'schemaForm' !== e;
                  });
                });
            var c = s.schema;
            (r.validateField = function (e) {
              (void 0 == e || a.$$parentForm.$name === e) &&
                (c && -1 !== c.type.indexOf('array') && u(a.$modelValue),
                a.$setDirty
                  ? (a.$setDirty(),
                    a.$setViewValue(a.$viewValue),
                    a.$commitViewValue(),
                    s.required && a.$isEmpty(a.$modelValue) && a.$setValidity('tv4-302', !1))
                  : a.$setViewValue(a.$viewValue));
            }),
              a.$formatters.push(function (e) {
                return !a.$pristine || !r.firstDigest || (r.options && r.options.validateOnRender === !0)
                  ? (u(a.$modelValue), e)
                  : e;
              }),
              r.$on('schemaFormValidate', function (e, t) {
                r.validateField(t);
              }),
              (r.schemaError = function () {
                return l;
              });
          },
        };
      },
    ]),
    o
  );
});
