import { jsx as _jsx } from "react/jsx-runtime";
import Grid from '@mui/material/Unstable_Grid2';
export const GoalEditorSection = ({ children }) => {
    return (_jsx(Grid, { container: true, sx: (theme) => ({
            backgroundColor: theme.palette.container.fill.card1,
            width: '100%',
            textAlign: 'left',
            border: '1px solid',
            borderColor: theme.palette.container.border.default,
            borderRadius: 3,
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            paddingTop: theme.spacing(7),
            paddingBottom: theme.spacing(7),
        }), children: children }));
};
export const GoalEditorSubsection = ({ children }) => {
    return (_jsx(Grid, { container: true, sx: (theme) => ({
            backgroundColor: theme.palette.container.fill.body,
            width: '100%',
            textAlign: 'left',
            border: '1px solid',
            borderColor: theme.palette.container.border.default,
            borderRadius: 3,
            padding: theme.spacing(5),
            gap: theme.spacing(5),
        }), children: children }));
};
