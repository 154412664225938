import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, TextField, Typography } from '@mui/material';
import { Switch, Checkbox } from '@securecodewarrior/design-system-react/lib/legacy';
// This needs to be ideally be put in its own scss file (or css)
const flexRow = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
};
const flexColumn = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
};
const TextWithSwitchToggle = ({ switchState, onSwitchToggle, isDisabled }) => {
    const flexItems = {
        flex: 'none',
        order: 0,
        flexGrow: 1,
    };
    return (_jsxs("div", { style: flexRow, children: [_jsxs("div", { style: Object.assign(Object.assign({}, flexColumn), { order: 0, alignSelf: 'stretch' }), children: [_jsx("div", { style: Object.assign(Object.assign({}, flexItems), { color: '#FFFFFF' }), children: _jsx(Typography, { variant: "h4", style: { fontWeight: 700 }, children: "Create as a Multi Company tournament" }) }), _jsx("div", { style: flexItems, children: _jsx(Typography, { variant: "body1", children: "This will allow specific companies to be able to view this tournament. Minimum of 2 companies. Management of this tournament can only be done from the server you are publishing to: [current] server." }) })] }), _jsx(Switch, { inputProps: { 'aria-label': switchState ? 'On' : 'Off' }, onChange: onSwitchToggle, checked: switchState, disabled: isDisabled, style: Object.assign(Object.assign({}, flexItems), { alignSelf: 'stretch' }) })] }));
};
const TextWithAutoComplete = ({ options, onSelectCompanies, defaultValue, isDisabled }) => {
    const flexItems = {
        flex: 'none',
        order: 0,
        flexGrow: 1,
    };
    return (_jsxs("div", { style: Object.assign(Object.assign({}, flexColumn), { width: '100%' }), children: [_jsx("div", { style: Object.assign({ color: '#FBFBFD' }, flexItems), children: _jsx(Typography, { variant: "h4", style: { fontWeight: 700 }, children: "Participating companies" }) }), _jsx(Autocomplete, { multiple: true, id: "checkboxes-autocomplete", options: options, disableCloseOnSelect: true, disabled: isDisabled, fullWidth: true, value: defaultValue, ChipProps: { style: { fontSize: '12px' } }, getOptionLabel: (option) => { var _a; return (_a = option.name) !== null && _a !== void 0 ? _a : ''; }, onChange: (event, values) => {
                    onSelectCompanies(values);
                }, isOptionEqualToValue: (option, value) => `${option._id}:${option.region}` === `${value._id}:${value.region}`, renderOption: (props, option, state) => (_createElement("li", Object.assign({}, props, { key: `${option._id}:${option.region}` }),
                    _jsx(Checkbox, { style: { marginRight: 8 }, checked: state.selected }),
                    option.name)), style: Object.assign(Object.assign({}, flexItems), { background: '#2B2D3D' }), renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: "Select companies" })) })] }));
};
const TournamentAudience = ({ onSwitchToggle, showCompanySelector, data, onSelectCompanies, preSelectedValues, isDisabled, }) => {
    return (_jsxs("div", { style: Object.assign(Object.assign({}, flexColumn), { padding: '24px', background: '#29313B', borderRadius: '6px', gap: '16px', marginBottom: '32px', marginTop: '24px' }), children: [_jsx(TextWithSwitchToggle, { switchState: showCompanySelector, onSwitchToggle: onSwitchToggle, isDisabled: isDisabled }), showCompanySelector && (_jsx(TextWithAutoComplete, { options: data, onSelectCompanies: onSelectCompanies, defaultValue: preSelectedValues, isDisabled: isDisabled }))] }));
};
const TournamentType = ({ showCompanySelector }) => {
    const color = showCompanySelector ? '#459BFF' : '#FFBE12';
    return (_jsxs("div", { style: Object.assign(Object.assign({}, flexRow), { alignItems: 'center', gap: '8px' }), children: [_jsx("div", { style: {
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    background: color,
                } }), _jsx(Typography, { variant: "h3", style: { fontWeight: 700 }, children: showCompanySelector ? 'Multi Company Tournament' : 'Global Tournament' })] }));
};
const TournamentCompanySelector = (props) => {
    const { showCompanySelector } = props;
    return (_jsxs(_Fragment, { children: [_jsx(TournamentType, { showCompanySelector: showCompanySelector }), _jsx(TournamentAudience, Object.assign({}, props))] }));
};
export default TournamentCompanySelector;
