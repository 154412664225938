var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Toolbar as MuiToolbar } from '@mui/material';
export const Unstable_AppBarToolbar = (props) => {
    const { className = '' } = props, rest = __rest(props, ["className"]);
    return _jsx(MuiToolbar, Object.assign({ className: `ScwAppBarToolbar-root ${className}`, disableGutters: true, variant: "dense" }, rest));
};
Unstable_AppBarToolbar.displayName = 'AppBarToolbar';
/** @deprecated use {@link Unstable_AppBarToolbar} to indicate that you are aware that this component will change */
export const AppBarToolbar = Unstable_AppBarToolbar;
