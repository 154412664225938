export const getLanguageInformation = (id, metadata) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [langId, frameworkId] = id.split(/[:.]/);
    if (langId === 'none')
        return {
            id: id,
            languageId: id,
            frameworkId: id,
            label: id,
            iconClassName: 'devicon-devicon-plain',
            group: undefined,
        };
    const language = metadata.languages[langId];
    return {
        id: `${langId}:${frameworkId}`,
        languageId: langId,
        frameworkId: frameworkId,
        label: `${(_a = language === null || language === void 0 ? void 0 : language.name) !== null && _a !== void 0 ? _a : langId} ${(_c = (_b = language === null || language === void 0 ? void 0 : language.framework[frameworkId]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : frameworkId}`.trim(),
        iconClassName: (_f = (_e = (_d = language === null || language === void 0 ? void 0 : language.framework[frameworkId]) === null || _d === void 0 ? void 0 : _d.icon) !== null && _e !== void 0 ? _e : language === null || language === void 0 ? void 0 : language.icon) !== null && _f !== void 0 ? _f : 'devicon-devicon-plain',
        group: (_h = (_g = language === null || language === void 0 ? void 0 : language.framework[frameworkId]) === null || _g === void 0 ? void 0 : _g.frameworkType) !== null && _h !== void 0 ? _h : undefined,
    };
};
export const mapCategory = (id, metadata) => {
    try {
        const [group, main, sub] = id.split('.');
        const groupi = metadata['categories'][group];
        const maini = groupi[main];
        const subi = maini.subitem[sub];
        return {
            name: subi.name,
            id: id,
            mainName: maini.name,
            rootName: group,
        };
    }
    catch (e) {
        return {
            name: id,
            id: id,
            mainName: id,
            rootName: id,
        };
    }
};
const mapLearningCategory = (videoId, metadata, categoryId) => {
    try {
        const [group, main, sub] = videoId.split(':');
        const groupi = metadata['learning'].subitem[group];
        const maini = groupi.subitem[main];
        const subi = maini.subitem[sub];
        return {
            name: subi.name,
            id: categoryId, // todo see if we can change this in the backend
            mainName: maini.name,
            rootName: 'conceptual_root',
        };
    }
    catch (e) {
        return {
            name: videoId,
            id: categoryId,
            mainName: videoId,
            rootName: videoId,
        };
    }
};
export const contentItemToSearchable = (item) => {
    return Object.assign(Object.assign({}, item), { textSearch: {
            title: item.title.toLocaleLowerCase().replace(' ', ''),
            description: item.description.toLocaleLowerCase().replace(' ', ''),
            languageLabel: 'language' in item ? item.language.label.toLocaleLowerCase().replace(' ', '') : '',
            categoryId: item.category.id.toLocaleLowerCase().replace(' ', ''),
            categoryName: item.category.name.toLocaleLowerCase().replace(' ', ''),
            status: item.status.replace('_', ' ').replace(' ', ''),
            type: item.type.replace('_', ' ').replace(' ', ''),
            difficulty: 'difficulty' in item ? item.difficulty.toLocaleLowerCase().replace(' ', '') : '',
        }, relevance: 1 });
};
export const backendToFrontend = (items, metadata) => items.map((i) => {
    if ('language' in i && 'category' in i) {
        const mappedCategory = mapCategory(i.category, metadata);
        const mappedLanguage = getLanguageInformation(i.language, metadata);
        return contentItemToSearchable(Object.assign(Object.assign({}, i), { language: mappedLanguage, category: mappedCategory }));
    }
    const cat = i.category.startsWith('conceptual_root')
        ? mapLearningCategory(i.contentId, metadata, i.category)
        : mapCategory(i.category, metadata);
    return contentItemToSearchable(Object.assign(Object.assign({}, i), { category: cat }));
});
export const getCategoryName = (categoryId, metadata) => {
    var _a, _b, _c, _d, _e, _f;
    const parts = categoryId.split('.');
    if (parts[0] === 'web' || parts[0] === 'mobile') {
        return (_d = (_c = (_b = (_a = metadata.categories[parts[0]]) === null || _a === void 0 ? void 0 : _a[parts[1]]) === null || _b === void 0 ? void 0 : _b.subitem[parts[2]]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : categoryId;
    }
    const itm = (_e = metadata.learning.subitem[parts[0]]) === null || _e === void 0 ? void 0 : _e.subitem[parts[1]];
    if (itm && !('status' in itm)) {
        return (_f = itm.subitem[parts[2]]) === null || _f === void 0 ? void 0 : _f.name;
    }
    return categoryId;
};
