import angular from 'angular';
import MODULE from './module';
import contentDisposition from 'content-disposition';
import { ReportingAssessmentsApi } from './ReportingAssessmentsApi';

angular.module(MODULE).factory('AssessmentsApiService', [
  '$log',
  '$http',
  '$rootScope',
  '$translate',
  '$window',
  'HttpConfigService',
  function ($log, $http, $rootScope, $translate, $window, HttpConfigService) {
    const AssessmentsApi = {};

    const AssessmentsApiEndpoint = function () {
      return $window.SCW_ENV.ApiEndpoint + '/assessment';
    };
    const reportingApi = function () {
      return new ReportingAssessmentsApi(ReportingAssessmentsApi.reportingEndpoint($window));
    };

    AssessmentsApi.addLoading = function () {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    };

    AssessmentsApi.removeLoading = function () {
      $rootScope.loading--;
      if ($rootScope.loading < 0) $rootScope.loading = 0;
    };

    AssessmentsApi.requestParams = function (filter, pageOptions) {
      pageOptions = pageOptions || {};

      return { filter: filter, page: pageOptions.page, size: pageOptions.size };
    };

    function httpGet(config) {
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          config,
          {
            method: 'GET',
            withCredentials: true,
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then((response) => response.data)
        .finally(() => {
          AssessmentsApi.removeLoading();
        });
    }

    AssessmentsApi.previewCertificateTemplate = function (template) {
      const endpoint = AssessmentsApiEndpoint() + '/certificates/templates/preview';
      AssessmentsApi.addLoading();
      return $http
        .post(endpoint, template, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getSummaryAssessmentReport = function (_assessment, tz) {
      const endpoint = AssessmentsApiEndpoint() + '/' + _assessment + '/report/summary.csv' + '?tz=' + tz;
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: endpoint,
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getSingleSummaryCsvReportV3 = function (sharedAssessmentId, tz) {
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: reportingApi().assessmentsSummarySingleUrl(tz, sharedAssessmentId),
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.getSingleResultsCsvReportV3 = function (sharedAssessmentId, tz) {
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: reportingApi().assessmentsResultsSingleUrl(tz, sharedAssessmentId),
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.getAllSummaryCsvReport = function (tz) {
      const endpoint = AssessmentsApiEndpoint() + '/report/summary.csv' + '?tz=' + tz;
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: endpoint,
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.getAllSummaryCsvReportV3 = function (tz) {
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: reportingApi().assessmentsSummaryAllUrl(tz),
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.getAllResultsCsvReportV3 = function (tz) {
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: reportingApi().assessmentsResultsAllUrl(tz),
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.getDetailedAssessmentReport = function (_assessment, tz) {
      const endpoint = AssessmentsApiEndpoint() + '/' + _assessment + '/report/detailed.csv' + '?tz=' + tz;
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: endpoint,
            responseType: 'arraybuffer',
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.retrieveCertificateTemplatesList = function () {
      const endpoint = AssessmentsApiEndpoint() + '/certificates/templates';
      AssessmentsApi.addLoading();
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.removeCertificateTemplate = function (_template) {
      const endpoint = AssessmentsApiEndpoint() + '/certificates/templates/' + _template;
      AssessmentsApi.addLoading();
      return $http
        .delete(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.saveCertificateTemplate = function (template) {
      let endpoint = AssessmentsApiEndpoint() + '/certificates/templates';
      if (template._id) endpoint += '/' + template._id;
      const method = template._id ? $http.put : $http.post;
      AssessmentsApi.addLoading();
      return method(endpoint, template, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .catch(function (response) {
          return response;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.downloadAssessmentCertificate = function (_assessment, _attempt) {
      const endpoint = AssessmentsApiEndpoint() + '/' + _assessment + '/certificate/download/' + _attempt;
      AssessmentsApi.addLoading();
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.downloadSampleCertificate = function (_assessment, _template) {
      const endpoint = AssessmentsApiEndpoint() + '/' + _assessment + '/certificate/sample/' + (_template || 'basic');
      AssessmentsApi.addLoading();
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.loadGroupsForCompany = () => {
      AssessmentsApi.addLoading();
      return $http
        .get(`${AssessmentsApiEndpoint()}/groups`, HttpConfigService.getHttpConfig())
        .then((response) => response.data)
        .finally(() => {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getAssessmentList = function (filter, pageOptions, sortBy, filterBy, groupFilterBy) {
      $log.debug('AssessmentsApi - getAssessmentList()');
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint(),
            params: angular.extend({}, AssessmentsApi.requestParams(filter, pageOptions), {
              sortBy: sortBy.replace('createdat', '_id'),
              statusFilter: filterBy === 'all' ? '' : filterBy,
              groupFilterId: groupFilterBy,
              ignoreInclude: true,
            }),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.retrieveAllowances = function () {
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/allowances',
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getAssessmentTemplates = function (filter, pageOptions) {
      $log.debug('AssessmentsApi - getAssessmentTemplates()');
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/templates',
            params: angular.extend({}, AssessmentsApi.requestParams(filter, pageOptions)),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.logInvalidChallenge = function (selection) {
      $log.debug('AssessmentsApi - logInvalidChallenge(' + JSON.stringify(selection) + ')');
      AssessmentsApi.addLoading();

      const postData = selection;
      return $http
        .post(AssessmentsApiEndpoint() + '/invalid/challenge', postData, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.attemptFilters = {
      completed_all: 'completed_all',
      completed_success: 'completed_success',
      completed_failed: 'completed_failed',
      pending: 'pending',
      unfinished: 'unfinished',
      in_progress: 'in_progress',
      all_not_self_assess: 'all_not_self_assess',
    };
    AssessmentsApi.getAttemptsList = function (assessmentId, filter, pageOptions, sortBy) {
      const _filter = angular.extend({}, filter);
      const _params = angular.extend({}, AssessmentsApi.requestParams(_filter, pageOptions), { sortBy: sortBy });
      $log.debug('Querying assessment attempt list with params: ', _params);

      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt',
            params: _params,
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getMyAttemptsList = function (assessmentId, filter, pageOptions, sortBy) {
      const _filter = angular.extend({}, filter);
      const _params = angular.extend({}, AssessmentsApi.requestParams(_filter, pageOptions), { sortBy: sortBy });
      $log.debug('Querying assessment attempt list with params: ', _params);

      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/' + assessmentId + '/mine',
            params: _params,
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getPublicAssessmentsList = function (assessmentId, filter, pageOptions, sortBy) {
      $log.debug('getPublicAssessmentsList - getPublicAssessmentsList()');
      AssessmentsApi.addLoading();
      if (_.isEmpty(assessmentId)) {
        assessmentId = 'all';
      }
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/' + assessmentId + '/public',
            params: angular.extend({}, AssessmentsApi.requestParams(filter, pageOptions), { sortBy: sortBy }),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getAssessmentData = function (assessmentId, filter, pageOptions) {
      $log.debug('AssessmentsApi - getAssessmentData(' + assessmentId + ')');
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AssessmentsApiEndpoint() + '/' + assessmentId,
            params: angular.extend({}, AssessmentsApi.requestParams(filter, pageOptions)),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getDeveloperProgressSummary = function (assessmentId) {
      $log.debug('AssessmentsApi - getDeveloperProgressSummary(' + assessmentId + ')');
      AssessmentsApi.addLoading();
      return $http
        .get(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/developerProgressSummary',
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.cloneAssessment = function (assessmentId) {
      $log.debug('AssessmentsApi - cloneAssessment(' + assessmentId + ')');
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'POST',
            url: AssessmentsApiEndpoint() + '/' + assessmentId + '/clone',
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.createAssessment = function (assessment) {
      $log.debug('AssessmentsApi - createAssessment()', assessment);
      AssessmentsApi.addLoading();
      return $http
        .post(AssessmentsApiEndpoint(), assessment, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.closeAssessment = function (assessment) {
      $log.debug('AssessmentsApi - closeAssessment()', assessment);
      // remove attempts property since it was originally added by server but never used
      delete assessment.attempts;
      AssessmentsApi.addLoading();
      return $http
        .get(AssessmentsApiEndpoint() + '/' + assessment._id + '/close', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.updateAssessment = function (assessment) {
      $log.debug('AssessmentsApi - updateAssessment()', assessment);

      // remove attempts property since it was originally added by server but never used
      delete assessment.attempts;

      AssessmentsApi.addLoading();
      return $http
        .put(AssessmentsApiEndpoint() + '/' + assessment._id, assessment, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.updateSupersededAssessment = function (assessment) {
      $log.debug('AssessmentsApi - updateSupersededAssessment()', assessment);

      AssessmentsApi.addLoading();
      return $http
        .put(
          AssessmentsApiEndpoint() + '/' + assessment._id + '/levelGrouping',
          assessment.levelGrouping,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.updateAssessmentRetries = function (assessmentId, retriesAllowed) {
      $log.debug('AssessmentsApi - updateAssessmentRetries()', assessmentId);

      AssessmentsApi.addLoading();
      return $http
        .put(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/retries',
          retriesAllowed,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.deleteAssessment = function (assessment) {
      $log.debug('AssessmentsApi - deleteAssessment()', assessment);
      AssessmentsApi.addLoading();
      return $http
        .delete(AssessmentsApiEndpoint() + '/' + assessment._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.removePendingAttempts = function (assessment) {
      $log.debug('AssessmentsApi - removePendingAttempts()', assessment);
      AssessmentsApi.addLoading();
      return $http
        .delete(AssessmentsApiEndpoint() + '/' + assessment._id + '/pending', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getAssessmentAttempt = function (assessmentId, attemptId) {
      $log.debug('AssessmentsApi - getAssessmentAttempt(' + assessmentId + ', ' + attemptId + ')');
      AssessmentsApi.addLoading();
      return $http
        .get(AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt/' + attemptId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getAssessmentAttemptDetails = function (assessmentId, attemptId) {
      AssessmentsApi.addLoading();
      return $http
        .get(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt/' + attemptId + '/details',
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.deleteAssessmentAttempt = function (assessmentId, attemptId) {
      $log.debug('AssessmentsApi - deleteAssessmentAttempt(' + assessmentId + ', ' + attemptId + ')');
      AssessmentsApi.addLoading();
      return $http
        .delete(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt/' + attemptId,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.createAttempt = function (assessmentId, filter, pageOptions, sortBy) {
      $log.debug('createAttempt - createAttempt()');
      AssessmentsApi.addLoading();
      return $http(
        angular.extend(
          {
            method: 'POST',
            url: AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt/create/',
            params: angular.extend({}, AssessmentsApi.requestParams(filter, pageOptions), { sortBy: sortBy }),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.inviteTargetsWithSearch = function (assessmentId, searchObj, languages) {
      const postData = {
        search: searchObj,
        languages: languages,
      };
      $log.debug('AssessmentsApi - inviteTargetsWithSearch(' + assessmentId + ')', postData);
      AssessmentsApi.addLoading();
      return $http
        .post(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/people',
          postData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.inviteTargets = function (assessmentId, targets, languages) {
      // support for single id or array of ids.
      targets = Array.isArray(targets) ? targets : [targets];
      const postData = {
        _targets: targets,
        languages: languages,
      };
      $log.debug('AssessmentsApi - inviteTargets(' + assessmentId + ')', postData);
      AssessmentsApi.addLoading();
      return $http
        .post(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/people',
          postData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.inviteTeams = function (assessmentId, teamIds, languages) {
      const postData = {
        _teams: Array.isArray(teamIds) ? teamIds : [teamIds],
        languages: languages,
      };
      $log.debug('AssessmentsApi - inviteTeams(' + assessmentId + ')', postData);
      AssessmentsApi.addLoading();
      return $http
        .post(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/teams',
          postData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.inviteCompany = function (assessmentId, _params, languages) {
      const postData = {
        languages: languages,
      };
      $log.debug('AssessmentsApi - inviteCompany(' + assessmentId + ')', postData);
      AssessmentsApi.addLoading();
      return $http
        .post(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/company',
          postData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.inviteTeam = function (assessmentId, _params, languages) {
      const postData = {
        languages: languages,
      };
      $log.debug('AssessmentsApi - inviteTeam(' + assessmentId + ')', postData);
      AssessmentsApi.addLoading();
      return $http
        .post(
          AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/team',
          postData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.sendReminderEmail = function (assessmentId) {
      $log.debug('AssessmentsApi - sendReminderEmail(' + assessmentId + ')');
      AssessmentsApi.addLoading();
      return $http
        .post(AssessmentsApiEndpoint() + '/' + assessmentId + '/invite/reminder', {}, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.getLanguagesStats = function (languages, allowDeprecated) {
      $log.debug('AssessmentsApi - getLanguagesStats(' + JSON.stringify(languages) + ')');
      AssessmentsApi.addLoading();

      const postData = {
        languages: languages,
      };

      const statsRoute = allowDeprecated ? '/quest-story/combinedStats' : '/quest-story/stats';
      return $http
        .post(AssessmentsApiEndpoint() + statsRoute, postData, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.setAssessmentAttemptLanguage = function (assessmentId, attemptId, language) {
      $log.debug('AssessmentsApi - setAssessmentAttemptLanguage(' + assessmentId + ', ' + attemptId + ')', language);
      AssessmentsApi.addLoading();
      const url = AssessmentsApiEndpoint() + '/' + assessmentId + '/attempt/' + attemptId + '/language';
      return $http
        .post(url, { language: language }, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    AssessmentsApi.hasAssessmentsForGroups = (groupIds) => {
      return httpGet({
        url: `${AssessmentsApiEndpoint()}/hasAssessmentsForGroups`,
        params: { groupIds },
      });
    };

    AssessmentsApi.extractFilename = (responseHeaders) => {
      const scwDownload = 'SCWDownload';
      if (responseHeaders && responseHeaders['content-disposition']) {
        try {
          const disposition = contentDisposition.parse(responseHeaders['content-disposition']);
          return disposition?.parameters?.filename ?? scwDownload;
        } catch (e) {
          $log.warn('Unable to extract filename from content-disposition header');
        }
      }
      // This is better than 'undefined' or 'null'.
      // The browser will guess the extension based on the content-type.
      return scwDownload;
    };

    AssessmentsApi.performDownload = (response) => {
      const resHeaders = response.headers();
      const filename = AssessmentsApi.extractFilename(resHeaders);

      const contentType = resHeaders['content-type'];

      let blob = new Blob([response.data], { type: contentType });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE 11
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');

        try {
          a.setAttribute('href', url);
          a.setAttribute('download', filename);

          let clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: false,
            cancelable: false,
          });

          a.dispatchEvent(clickEvent);
        } finally {
          URL.revokeObjectURL(a.href);
          a.remove();
        }
      }
    };

    AssessmentsApi.getScormPackageLink = (assessmentId, version) => {
      const url = `${AssessmentsApiEndpoint()}/${assessmentId}/download-scorm-package`;
      const params = version ? { version: version } : {};
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: url,
            responseType: 'arraybuffer',
            params: params,
          },
          HttpConfigService.getHttpConfig()
        )
      );
    };

    AssessmentsApi.abandonAssessment = (assessmentId, attemptId) => {
      $log.debug('AssessmentsApi - abandonAssessment(' + assessmentId + ', ' + attemptId + ')');
      AssessmentsApi.addLoading();
      const url = `${AssessmentsApiEndpoint()}/${assessmentId}/attempt/${attemptId}/abandon`;
      return $http
        .post(
          url,
          {
            _assessment: assessmentId,
            _assessmentAttempt: attemptId,
          },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response;
        })
        .finally(function () {
          AssessmentsApi.removeLoading();
        });
    };

    return AssessmentsApi;
  },
]);
