export const categorySelectionAnalytics = (sendEvent) => ({
    onSearchUsed: (searchTerm) => {
        sendEvent({ event: 'Category Search Changed', scope: 'explore', searchTerm: searchTerm });
    },
    onCategoryToggled: (wasCategorySelected, id) => {
        const event = wasCategorySelected ? 'Category Unselected' : 'Category Selected';
        sendEvent({ event, scope: 'explore', categoryId: id });
    },
    onFilterUsed: (selectedFilter) => {
        sendEvent({ event: 'Category Subfilter Selected', scope: 'explore', selectedFilter: selectedFilter });
    },
    onCollectionToggled: (collectionName, wasSelected) => {
        const event = wasSelected ? 'Category Collection Unselected' : 'Category Collection Selected';
        sendEvent({ event, scope: 'explore', collectionName: collectionName });
    },
    onSearchReset: () => {
        sendEvent({ event: 'Category Search Reset', scope: 'explore' });
    },
});
