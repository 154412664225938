import angular from 'angular';
import MODULE from './module';
import { CustomerAnalyticsClient, createAmplitudeCustomerAnalyticsClient } from '@securecodewarrior/integrations-core';

const app = angular.module(MODULE);

app.service('customerAnalyticsClient', [
  '$window',
  function ($window) {
    const amplitudeClient = createAmplitudeCustomerAnalyticsClient(getCustomerAnalyticsConfig($window.SCW_ENV ?? {}));

    const params = new URLSearchParams($window.location.search);
    const integrationId = params.get('integration_id') ?? undefined;
    const tenantId = params.get('tenant_id') ?? undefined;
    const partnerId = params.get('partner_id') ?? undefined;

    return new CustomerAnalyticsClient(amplitudeClient, {
      ...(integrationId && { integrationId }),
      ...(tenantId && { tenantId }),
      ...(partnerId && { partnerId }),
    });
  },
]);

/**
 * Extracts the AmplitudeConfig for Customer Analytics from Portal's SCW_ENV global
 * @param scwEnv {{AMPLITUDE_APIKEY: string, ApiEndpoint: string}}
 * @return {{amplitudeEndpointHost: string, apiKey: string}}
 */
function getCustomerAnalyticsConfig(scwEnv) {
  const amplitudeEndpointHost = scwEnv.ApiEndpoint ? `${new URL(scwEnv.ApiEndpoint).host}` : undefined;
  return { apiKey: scwEnv.AMPLITUDE_APIKEY, amplitudeEndpointHost };
}
