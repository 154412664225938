import angular from 'angular';
import MODULE from './module';
import templateUrl from './plan-error.html';

const app = angular.module(MODULE);

app.controller('ErrorPageController', [
  '$http',
  '$scope',
  '$rootScope',
  '$stateParams',
  '$state',
  '$translate',
  '$window',
  'HttpConfigService',
  function ($http, $scope, $rootScope, $stateParams, $state, $translate, $window, HttpConfigService) {
    /**var translation;
     $translate(['UNKNOWN', 'UNAUTHORIZED', 'ERROR']).then( function(translations){
      translation = translations;
    }, function(translationIds){
      translations = this.translationIds;
    });**/
    var ERRORCFG = {};
    var error = {};
    var errcode = '';

    $window.SCW_ENV.ApiEndpoint =
      $window.SCW_ENV.ApiEndpoints[$window.SCW_ENV.API_ENDPOINT_INDEXES[$stateParams.region?.toUpperCase()]] ??
      $window.SCW_ENV.ApiEndpoint;
    //if encrypted company id exists retrieve sso error message from backend and display
    if ($stateParams.ecid) {
      var config = HttpConfigService.getHttpConfig();
      config.params = { ecid: $stateParams.ecid };

      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;

      $http
        .get($window.SCW_ENV.ApiEndpoint + '/auth/sso/error-message', config)
        .then(function (response) {
          $scope.ssoErrorMsg = response.data.message;
          if ($scope.ssoErrorMsg) {
            $scope.hasSsoErrorMsg = true;
          }
        })
        .finally(function (_res) {
          $rootScope.loading--;
          if ($rootScope.loading < 0) $rootScope.loading = 0;
        });
    }

    $translate([
      'UNKNOWN.HEADER',
      'UNKNOWN.MESSAGE',
      'UNKNOWN.CONTINUEMESSAGE',
      'UNKNOWN.NEXTSTATE',
      'UNAUTHORIZED.HEADER',
      'UNAUTHORIZED.MESSAGE',
      'UNAUTHORIZED.CONTINUEMESSAGE',
      'SSO_ERROR_MESSAGE_FROM_ADMIN',
    ]).then(function (translations) {
      ERRORCFG = {
        UNKNOWN: {
          header: translations['UNKNOWN.HEADER'],
          message: translations['UNKNOWN.MESSAGE'],
          continueMessage: translations['UNKNOWN.CONTINUEMESSAGE'],
          nextState: 'login',
        },
        UNAUTHORIZED: {
          header: translations['UNAUTHORIZED.HEADER'],
          message: $scope.hasSsoErrorMsg
            ? translations['SSO_ERROR_MESSAGE_FROM_ADMIN']
            : translations['UNAUTHORIZED.MESSAGE'],
          continueMessage: translations['UNAUTHORIZED.CONTINUEMESSAGE'],
          nextState: 'login',
          unauthed: true,
        },
      };

      errcode = $stateParams.err || 'UNKNOWN';
      error = ERRORCFG[errcode];
      $scope.error = error;
      $scope.next = goNext;
    });

    function goNext() {
      $state.go(error.nextState);
    }
  },
]);

app.factory('ErrorHandler', [
  '$translate',
  '$log',
  '$swal',
  '$state',
  function ($translate, $log, $swal, $state) {
    var ErrorHandler = {};

    //ErrorHandler.errors = [];

    ErrorHandler.addError = function (errMsg) {
      $translate(['ERROR', 'OK']).then(function (translations) {
        //ErrorHandler.errors.push({"type": "danger", "msg": errMsg});
        $swal({
          title: translations.ERROR,
          text: errMsg,
          type: 'error',
          confirmButtonText: translations.OK,
          keyResolution: true,
        });
      });
    };

    function isRequestAborted(response) {
      // old koa sends "error" property, new learning path sends "message" property
      let aborted;
      const responseData = response?.data;
      if (!responseData) {
        aborted = true;
      } else {
        const responseErrMsg = responseData.error || responseData.message;
        aborted = responseErrMsg === 'HTTP request aborted';
      }
      if (aborted) {
        $log.debug('Received aborted request');
      }
      return aborted;
    }

    function isInvalidClientState(responseData) {
      return responseData === 'INVALID_CLIENT_STATE' || responseData?.error === 'INVALID_CLIENT_STATE';
    }

    function isFeatureInAccessibleDueToPlan(responseData) {
      return responseData === 'FEATURE_INACCESSIBLE' || responseData?.error === 'FEATURE_INACCESSIBLE';
    }

    function handleValidationError(responseData, translations) {
      const validationError = responseData.details.validationErrors[0];
      if (validationError.stack === 'request.body.name is required') {
        return translations.DOES_NOT_MEET_MINIMUM_LENGTH_OF_1;
      }
      return validationError.message;
    }

    function displayInfoAlertAndGoBackToHomePage(translations) {
      $swal({
        title: translations.FEATURE_UNAVAILABLE_DUE_TO_PLAN,
        type: 'info',
        showConfirmButton: true,
        confirmButtonText: translations.CLOSE,
        customClass: 'custom-swal-self-adjust',
        templateUrl,
      }).then((valueIsOK) => {
        if (valueIsOK) {
          $state.go('home', {}, { reload: true });
        }
      });
    }

    function displayErrorAlert(translations, swalText) {
      // Time delay to prevent dialog timing problems
      setTimeout(function () {
        $swal({
          title: translations.ERROR,
          text: swalText,
          type: 'error',
          confirmButtonText: translations.OK,
          keyResolution: true,
        });
      }, 200);
    }

    ErrorHandler.addHttpError = function (errMsg, response) {
      $log.debug('Error: ' + errMsg, response);
      $translate([
        'ERROR',
        'OK',
        'RELOAD',
        'ERROR_CURRENT_CHALLENGE_PROGRESS_CLOSE_BROWSER',
        'DOES_NOT_MEET_MINIMUM_LENGTH_OF_1',
        'FEATURE_UNAVAILABLE_DUE_TO_PLAN',
        'CLOSE',
      ]).then(function (translations) {
        if (!response) {
          displayErrorAlert(translations, errMsg);
          return;
        }

        if (isRequestAborted(response)) {
          return;
        }

        let swalText = errMsg;
        if (response?.data) {
          if (isInvalidClientState(response.data)) {
            swalText = translations.ERROR_CURRENT_CHALLENGE_PROGRESS_CLOSE_BROWSER;
          } else if (response.data.details?.validationErrors) {
            swalText += ': ' + handleValidationError(response.data, translations);
          } else if (response.data.error) {
            swalText += ': ' + response.data.error;
          }
        }

        if (isFeatureInAccessibleDueToPlan(response.data)) {
          displayInfoAlertAndGoBackToHomePage(translations);
        } else {
          displayErrorAlert(translations, swalText);
        }
      });
    };

    ErrorHandler.clearErrors = function () {
      delete ErrorHandler.errors;
      ErrorHandler.errors = [];
    };

    return ErrorHandler;
  },
]);

// used for matching password and confirm password in RegisterController, ResetController and ProfileController
app.directive('matchValidator', function () {
  return {
    require: 'ngModel',
    link: function (scope, _element, attrs, ngModel) {
      ngModel.$parsers.push(function (value) {
        ngModel.$setValidity('match', value == scope.$eval(attrs.matchValidator));
        return value;
      });
    },
  };
});

//todo this LOOKS useless / unused - how can I be 100%
app.factory('FormValidation', function () {
  var FormValidation = {};

  return FormValidation;
});
