import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { InputAdornment, Typography, styled, useTheme } from '@mui/material';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers-pro';
import TextField from '@securecodewarrior/design-system-react/lib/base/legacy/TextField/TextField';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export function failed(f) {
    return f instanceof Error || (Array.isArray(f) && f.length > 0 && f[0] instanceof Error);
}
export function flattenErrors(r) {
    if (r instanceof Error) {
        return [r];
    }
    if (Array.isArray(r)) {
        return flattenErrorArr(r);
    }
    if (r && typeof r === 'object') {
        const errors = Object.entries(r)
            .map(([k, v]) => [k, flattenErrors(v)])
            .flatMap(([, v]) => (failed(v) ? (Array.isArray(v) ? v : [v]) : undefined))
            .filter((x) => x)
            .map((x) => x);
        return errors.length ? errors : r;
    }
    return r;
}
function flattenErrorArr(r) {
    const r1 = r.map((e) => flattenErrors(e));
    const errors = r1.filter((x) => Array.isArray(x)).flat();
    return errors.length ? errors : r;
}
// export function allowError<T>(value: T): { k: 'ok'; value: T } | { k: 'error'; error: Error; lastGoodValue: T } {
//   return { k: 'ok', value };
// }
export function allowError(value) {
    return value;
}
export function useBoolInputBuffer(value, onChange, options) {
    const isValid = value && !(value instanceof Error);
    const [inputBuffer, setInputBuffer] = useState(isValid ? value : !!(options === null || options === void 0 ? void 0 : options.defaultValue));
    useLayoutEffect(() => {
        if (isValid) {
            setInputBuffer(value);
        }
    }, [value, isValid]);
    const validationError = value instanceof Error ? value : null;
    const update = useCallback((ev) => {
        setInputBuffer(ev.target.checked);
        onChange(ev.target.checked);
    }, [onChange]);
    return {
        checked: inputBuffer,
        update,
        isValid,
        validationError,
    };
}
export const SuccessRatioInput = ({ value, onChange, disabled, }) => {
    const { t } = useTranslation();
    const isValid = !(value instanceof Error);
    const [inputBuffer, setInputBuffer] = useState(isValid && value !== null ? `${value}` : '');
    useLayoutEffect(() => {
        if (isValid) {
            setInputBuffer(value !== null ? `${value}` : '');
            setInputValidationError(null);
        }
    }, [value, isValid]);
    const [inputValidationError, setInputValidationError] = useState(null);
    const validationError = inputValidationError || (value instanceof Error ? value : null);
    const update = useCallback((ev) => {
        setInputBuffer(ev.target.value);
        const isNumber = /^\s*[0-9]+\s*$/.test(ev.target.value);
        if (!ev.target.value.trim()) {
            setInputValidationError(null);
            onChange(null);
        }
        else if (isNumber) {
            const num = parseInt(ev.target.value);
            setInputValidationError(null);
            onChange(num);
        }
        else {
            const err = new Error(t('pages.multiassessments.inputs.successRatioInput.invalidNumber'));
            setInputValidationError(err);
            onChange(err);
        }
    }, [onChange, t]);
    return (_jsx(_Fragment, { children: _jsx(TextField, { label: t('pages.multiassessments.minimumPassPercentage'), sx: { width: '100%' }, variant: "outlined", id: "successRatio", value: inputBuffer, placeholder: !inputBuffer ? t('pages.multiassessments.noMinimumPassScore') : undefined, onChange: update, InputLabelProps: { shrink: true }, InputProps: {
                disabled,
            }, inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
                max: 100,
            }, error: !!validationError, helperText: (validationError === null || validationError === void 0 ? void 0 : validationError.message) || '' }) }));
};
export function TimeLimitInput(props) {
    const theme = useTheme();
    const { t } = useTranslation();
    const unitLabel = props.per === 'total' ? t('pages.multiassessments.minutesTotal') : t('pages.multiassessments.minutesPerChallenge');
    const msecPerUnit = 60000;
    const value = props.value;
    const isValid = !(value instanceof Error);
    const [inputBuffer, setInputBuffer] = useState(isValid && value !== null ? `${value / msecPerUnit}` : '');
    useLayoutEffect(() => {
        if (isValid) {
            setInputBuffer(value !== null ? `${value / msecPerUnit}` : '');
            setInputValidationError(null);
        }
    }, [value, isValid]);
    const [inputValidationError, setInputValidationError] = useState(null);
    const validationError = inputValidationError || (value instanceof Error ? value : null);
    const { onChange } = props;
    const update = useCallback((ev) => {
        setInputBuffer(ev.target.value);
        const isNumber = /^\s*[0-9]+\s*$/.test(ev.target.value);
        if (!ev.target.value.trim()) {
            setInputValidationError(null);
            onChange(null);
        }
        else if (isNumber) {
            const num = parseInt(ev.target.value) * msecPerUnit;
            setInputValidationError(null);
            onChange(num);
        }
        else {
            const err = new Error(t('pages.multiassessments.inputs.successRatioInput.invalidNumber'));
            setInputValidationError(err);
            onChange(err);
        }
    }, [onChange, t]);
    return (_jsx(TextField, { label: t('pages.multiassessments.timeLimitLabel'), sx: { width: '100%' }, InputLabelProps: { shrink: true }, size: props.size, type: "number", variant: "outlined", placeholder: !inputBuffer ? t('pages.multiassessments.inputs.timeLimitInput.noTimeLimit') : undefined, value: inputBuffer, onChange: update, error: !!validationError, helperText: (validationError === null || validationError === void 0 ? void 0 : validationError.message) || '', inputProps: { min: 0, type: 'number' }, InputProps: {
            disabled: props.disabled,
            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(Typography, { sx: props.disabled ? { color: theme.palette.grayscale.bg6 } : {}, children: unitLabel }) })),
        } }));
}
export const MaxRetriesInput = ({ value, onChange, disabled, }) => {
    const { t } = useTranslation();
    const isValid = !(value instanceof Error);
    const [inputBuffer, setInputBuffer] = useState(value !== null && isValid ? `${value}` : '');
    useLayoutEffect(() => {
        if (isValid) {
            setInputBuffer(value !== null ? `${value}` : '');
            setInputValidationError(null);
        }
    }, [value, isValid]);
    const [inputValidationError, setInputValidationError] = useState(null);
    const validationError = inputValidationError || (value instanceof Error ? value : null);
    const update = useCallback((ev) => {
        setInputBuffer(ev.target.value);
        const isNumber = /^\s*[0-9]+\s*$/.test(ev.target.value);
        if (!ev.target.value.trim()) {
            setInputValidationError(null);
            onChange(null);
        }
        else if (isNumber) {
            const num = parseInt(ev.target.value);
            setInputValidationError(null);
            onChange(num);
        }
        else {
            const err = new Error(t('pages.multiassessments.inputs.successRatioInput.invalidNumber'));
            setInputValidationError(err);
            onChange(err);
        }
    }, [onChange, t]);
    return (_jsx(TextField, { label: t('pages.multiassessments.retryCount'), sx: { width: '58%' }, InputLabelProps: { shrink: true }, type: "number", variant: "outlined", id: "maxRetries", value: inputBuffer, placeholder: inputBuffer === '' ? t('pages.multiassessments.unlimitedAttempts') : '', onChange: update, inputProps: {
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            max: 99,
        }, InputProps: {
            disabled,
        }, error: !!validationError, helperText: (validationError === null || validationError === void 0 ? void 0 : validationError.message) || '' }));
};
export const RetryWaitingHoursInput = ({ value, onChange, disabled, }) => {
    const { t } = useTranslation();
    const isValid = !(value instanceof Error);
    const [inputBuffer, setInputBuffer] = useState(value !== null && isValid ? `${value}` : '');
    useLayoutEffect(() => {
        if (isValid) {
            setInputBuffer(value !== null ? `${value}` : '');
            setInputValidationError(null);
        }
    }, [value, isValid]);
    const [inputValidationError, setInputValidationError] = useState(null);
    const validationError = inputValidationError || (value instanceof Error ? value : null);
    const update = useCallback((ev) => {
        setInputBuffer(ev.target.value);
        const isNumber = /^\s*[0-9]+\s*$/.test(ev.target.value);
        if (!ev.target.value.trim()) {
            setInputValidationError(null);
            onChange(null);
        }
        else if (isNumber) {
            const num = parseInt(ev.target.value);
            setInputValidationError(null);
            onChange(num);
        }
        else {
            const err = new Error(t('pages.multiassessments.inputs.successRatioInput.invalidNumber'));
            setInputValidationError(err);
            onChange(err);
        }
    }, [onChange, t]);
    return (_jsx(TextField, { sx: { width: '38%' }, label: t('pages.multiassessments.retryIntervalHours'), InputLabelProps: { shrink: true }, type: "number", variant: "outlined", value: inputBuffer, onChange: update, helperText: (validationError === null || validationError === void 0 ? void 0 : validationError.message) || '', error: !!validationError, inputProps: { min: 0, max: 72, type: 'number' }, InputProps: {
            disabled,
        } }));
};
export const DateTimePicker = styled(MuiDateTimePicker)(({ theme }) => ({
    '& label': {
        '&.Mui-focused': {
            color: theme.palette.secondary.high,
        },
        '&.Mui-error': {
            color: theme.palette.error.high,
        },
        '&:Mui-disabled': {
            color: theme.palette.grayscale.bg6,
        },
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 2 * theme.shape.borderRadius,
        '& fieldset': {
            borderColor: theme.palette.grayscale.bg6,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.grayscale.bg9,
        },
        '&.Mui-disabled': {
            fieldset: {
                borderColor: theme.palette.grayscale.bg4,
            },
            svg: {
                color: theme.palette.grayscale.bg4,
            },
        },
        '&.Mui-focused': {
            fieldset: {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-error fieldset': {
                borderColor: theme.palette.error.main,
            },
        },
    },
}));
