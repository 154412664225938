import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyboardArrowDownRounded as DownArrowIcon, KeyboardArrowUpRounded as UpArrowIcon } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useId, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePlatformContext } from '../platformContext';
import { goalAnalytics } from './Goals.analytics';
import { formatDate, getDaysLeft } from '../dateHelper';
export const GoalElement = (props) => {
    const [expanded, setExpanded] = useState(true);
    const expandAriaControlsId = useId();
    const [t] = useTranslation();
    const theme = useTheme();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => goalAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const sx = useMemo(() => createStyle(theme), [theme]);
    const toggleExpanded = useMemo(() => {
        return (e) => {
            e.stopPropagation();
            setExpanded((was) => {
                analytics.onGoalExpandToggle(!was, props.title);
                return !was;
            });
        };
    }, [setExpanded, analytics, props.title]);
    return (_jsxs(Box, { sx: sx, children: [_jsxs(Box, { className: "header", onClick: toggleExpanded, children: [_jsx("img", { src: props.imageUrl, alt: "" }), _jsxs("div", { children: [_jsx(Typography, { title: props.title, variant: "h6", component: "h4", children: props.title }), _jsx(Typography, { variant: "caption", component: "p", "data-completed": !!props.completedAt, children: props.completedAt ? (_jsx(Trans, { i18nKey: "goals.goal.completedOn", values: { date: formatDate(props.completedAt) } })) : (_jsx(Trans, { i18nKey: "goals.goal.endsIn", values: { count: getDaysLeft(props.deadline) } })) })] }), _jsx(IconButton, { onClick: toggleExpanded, "aria-expanded": expanded, "aria-controls": expandAriaControlsId, "aria-label": t(`goals.goal.${expanded ? 'collapse' : 'expand'}`, { name: props.title }), children: expanded ? _jsx(UpArrowIcon, {}) : _jsx(DownArrowIcon, {}) })] }), _jsx("div", { className: "body", "data-expanded": expanded, id: expandAriaControlsId, children: props.children })] }));
};
const createStyle = (theme) => ({
    marginY: 4,
    marginX: 7,
    border: `1px solid ${theme.palette.container.border.default}`,
    borderRadius: 3,
    overflow: 'hidden',
    background: theme.palette.container.fill.card1,
    '.header': {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        cursor: 'pointer',
        height: theme.typography.pxToRem(80),
        img: {
            opacity: '.5',
            aspectRatio: '1',
            height: '100%',
        },
        div: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            gap: theme.typography.pxToRem(3),
            overflow: 'hidden',
            'h3, p': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            'p[data-completed=true]': {
                color: theme.palette.text.status.success,
            },
        },
        button: {
            svg: {
                fontSize: theme.typography.pxToRem(34),
                borderRadius: '50%',
            },
            ':focus-visible svg': {
                outline: `2px solid ${theme.palette.clickable.default}`,
            },
            padding: 0,
            marginRight: 5,
        },
    },
    '.header button svg': { color: theme.palette.text.soft },
    '.header:hover button svg': { color: theme.palette.text.title },
    '.body': {
        '&[data-expanded=false]': {
            display: 'none',
        },
        borderTop: `1px solid ${theme.palette.container.border.default}`,
        paddingY: 2,
    },
});
