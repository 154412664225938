import angular from 'angular';
import 'angular-translate';

import SCWError from '../error';
import SCWGame013 from '../game-013';
import SCWAnalytics from '../analytics';
import SCWFeatures from '../feature-flags';

export default angular.module('SCWAuth', ['pascalprecht.translate', SCWError, SCWFeatures, SCWGame013, SCWAnalytics])
  .name;
