/**
 * Search widget for SCW frontend
 * Author: Lea Anthony <lanthony@securecodewarrior.com>
 * Example Usage:

  <search-widget 	placeholder="{{'ASSESSMENT_SEARCH' | translate}}"
          placeholder="{{'SEARCH_ASSESSMENTS' | translate}}"
          input-analytics="assessmentList-field-search"
          search-analytics="assessmentList-button-search"
          clear-search-analytics="assessmentList-button-clearSearch"
          search-callback="newAssessmentSearch">
  </search-widget>


Attributes:
  label: The text that is read out for those using screen readers
  placeholder: The default text in the search box
  input-analytics: The text to use for analytics when the user focusses on the input box
  search-analytics: The text to use for analytics when the user clicks the search button
  clear-search-analytics: The text to use for analytics when the user clicks the clear selection icon
  search-callback: Function to call for the search. It will pass the search text through as the first parameter to the call.
*/

import angular from 'angular';

const app = angular.module('SCWSearchWidget', []);
export default app.name;

import templateUrl from './search-widget.html';

app.directive('searchWidget', [
  '$log',
  function ($log) {
    var requiredAttributes = {
      inputAnalytics: 'input-analytics',
      searchAnalytics: 'search-analytics',
      clearSearchAnalytics: 'clear-search-analytics',
      placeholder: 'placeholder',
      searchCallback: 'search-callback',
    };

    return {
      restrict: 'E',
      scope: {
        searchTrigger: '=?',
        placeholder: '@?',
        searchCallback: '=?',
        searchAnalytics: '@?',
        inputAnalytics: '@?',
        clearSearchAnalytics: '@?',
        label: '@?',
        searchText: '=?',
        searchForModule: '@?',
      },
      templateUrl,
      controller: [
        '$scope',
        '$element',
        function ($scope, $element) {
          $scope.searchText = $scope.searchText || '';
          $scope.searchForModule = $scope.searchForModule || '';
          $scope.triggerSearch = _.debounce(doSearchCallback, 500, { maxWait: 500 });

          // Report if required attributes not present
          for (var requiredAttr in requiredAttributes) {
            if (!$scope[requiredAttr]) {
              $log.error(
                "Required attribute '%s' not present in <search-widget> element.",
                requiredAttributes[requiredAttr]
              );
            }
          }

          $element.bind('keyup', function handleEsc(event) {
            if (event.key === 'Escape') {
              clearSearch();
              event.preventDefault && event.preventDefault();
              event.stopPropagation && event.stopPropagation();
            } else if (event.key === 'Enter') {
              doSearchCallback();
            }
            //At the moment just a temporary fix only for courses, can be extended to all modules
            else if ($scope.searchForModule === 'courses') {
              $scope.triggerSearch();
            }
          });

          $scope.checkBlank = checkBlank;
          $scope.clearSearch = clearSearch;
          $scope.doSearchCallback = doSearchCallback;
          $scope.searchTrigger = doSearchCallback;

          function checkBlank() {
            if ($scope.searchText.length == 0) {
              $scope.doSearchCallback();
            }
          }

          function clearSearch() {
            $scope.searchText = '';
            $scope.doSearchCallback();
          }

          function doSearchCallback() {
            $scope.searchCallback($scope.searchText);
          }
        },
      ],
    };
  },
]);
