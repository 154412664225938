import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('ManagementAdminCompanyVulnerabilitySummaryController', [
  '$rootScope',
  '$scope',
  '$translate',
  '$uibModalInstance',
  'FeatureFlagsApi',
  'AnalyticsService',
  'FeatureFlags',
  '$state',
  '$window',
  function (
    $rootScope,
    $scope,
    $translate,
    $uibModalInstance,
    FeatureFlagsApi,
    AnalyticsService,
    FeatureFlags,
    $state,
    $window
  ) {
    $('.management-css').prop('disabled', false);
    $scope.translate = function translate(...args) {
      return $translate.instant(...args);
    };
    FeatureFlagsApi.isFeatureEnabled(FeatureFlags.VULNERABILITY_SUMMARY_REPORT).then((flag) => {
      if (!flag) {
        $state.go('unavailable');
      }
    });
    $scope.resolveCategoryName = function resolveCategoryName(catSlugs) {
      const [type, cat, subcat] = catSlugs;
      const subcategoryName = $rootScope.metadata.categories[type]?.[cat]?.subitem[subcat]?.name;
      const categoryName = $rootScope.metadata.categories[type]?.[cat]?.name || '';
      return { categoryName, ...(subcategoryName && { subcategoryName }) };
    };
    $scope.directLinkingApiEndpoint = $window.SCW_ENV.DirectLinkingApiEndpoint;
    $scope.close = function close() {
      $uibModalInstance.close();
    };
    $scope.logEvent = function logEvent(eventType, eventProperties) {
      AnalyticsService.logEvent(eventType, eventProperties);
    };
  },
]);
