import angular from 'angular';

import SCWTrainingApi from './TrainingApi';
import SCWTrainingChallengeNew from './TrainingChallengeNew';
import SCWTrainingManagerMetrics from './ManagerMetrics';
import SCWTrainingProgressLeaderboard from './progressLeaderboard';
import SCWTrainingListCourses from './ListCourses';
import SCWTrainingPointsLeaderboard from './pointsLeaderboard';

export default angular.module('SCWTraining', [
  SCWTrainingApi,
  SCWTrainingChallengeNew,
  SCWTrainingManagerMetrics,
  SCWTrainingProgressLeaderboard,
  SCWTrainingPointsLeaderboard,
  SCWTrainingListCourses,
]).name;
