import DOMPurify from 'dompurify';
class EditorHtmlSanitizer {
    constructor() {
        Object.defineProperty(this, "purifyConfig", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                FORBID_ATTR: ['style'],
                ADD_ATTR: ['target'],
            }
        });
    }
    sanitize(html) {
        const clean = DOMPurify.sanitize(html, this.purifyConfig);
        return clean;
    }
}
export { EditorHtmlSanitizer };
