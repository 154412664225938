import angular from 'angular';

var arcRed = '#c80f28';
var arcGrey = '#555555';
var svgXmlns = 'http://www.w3.org/2000/svg';
var xlinkXmlns = 'http://www.w3.org/1999/xlink';

export const findBezierControlPoint = (pointA, pointB) => {
  var originalGradient = gradient(pointA, pointB);
  var mid = midpoint(pointA, pointB);
  var normalGradient = -1 / originalGradient;
  var i = 0;
  var normalYIntercept = yInterceptConstant(mid.x, mid.y, normalGradient);
  var dist = 0;
  var controlPointDistance = distanceBetween(pointA, pointB) / 6;
  var testPoint;

  if (normalGradient === Number.POSITIVE_INFINITY || normalGradient === Number.NEGATIVE_INFINITY) {
    testPoint = { x: mid.x, y: mid.y - controlPointDistance };
  } else if (normalGradient > 0) {
    while (dist < controlPointDistance) {
      var minX = Math.min(pointA.x, pointB.x);
      if (mid.x - i < minX) {
        testPoint = {
          x: minX,
          y: normalGradient * minX + normalYIntercept,
        };
        break;
      }
      testPoint = {
        x: mid.x - i,
        y: normalGradient * (mid.x - i) + normalYIntercept,
      };
      dist = distanceBetween(mid, testPoint);
      i += 1;
    }
  } else {
    while (dist < controlPointDistance) {
      var maxX = Math.max(pointA.x, pointB.x);
      if (mid.x + i > maxX) {
        testPoint = {
          x: maxX,
          y: normalGradient * maxX + normalYIntercept,
        };
        break;
      }
      testPoint = {
        x: mid.x + i,
        y: normalGradient * (mid.x + i) + normalYIntercept,
      };
      dist = distanceBetween(mid, testPoint);
      i += 1;
    }
  }

  return testPoint;
};

export const midpoint = (pointA, pointB) => {
  var res = {};
  res.x = (pointA.x + pointB.x) / 2;
  res.y = (pointA.y + pointB.y) / 2;
  return res;
};

export const distanceBetween = (pointA, pointB) => {
  return Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));
};

export const yInterceptConstant = (x, y, m) => {
  return y - m * x;
};

export const gradient = (pointA, pointB) => {
  return (pointA.y - pointB.y) / (pointA.x - pointB.x);
};

export const drawArc = (logger, htmlDoc) => (fromCoords, toCoords, xOffset, yOffset, isAttacking) => {
  logger.debug('Drawing arc from', fromCoords, 'to', toCoords);
  var controlPoint = findBezierControlPoint(fromCoords, toCoords);

  var g = $('.jvectormap-container > svg > g[transform]');
  var path = htmlDoc.createElementNS(svgXmlns, 'path');
  var pathString =
    'M' +
    fromCoords.x +
    ',' +
    fromCoords.y +
    ' Q' +
    controlPoint.x +
    ',' +
    controlPoint.y +
    ' ' +
    (toCoords.x + xOffset) +
    ',' +
    (toCoords.y + yOffset);
  path.setAttributeNS(null, 'fill', 'none');
  path.setAttributeNS(null, 'stroke', isAttacking ? arcRed : arcGrey);
  path.setAttributeNS(null, 'stroke-width', '2');
  path.setAttributeNS(null, 'stroke-linecap', 'butt');
  path.setAttributeNS(null, 'class', 'arc');
  path.setAttributeNS(null, 'd', pathString);

  g.append(path);
};

export const addIcon =
  (compile, scope, logger, htmlDoc) =>
  (coords, iconName, xOffset, yOffset, countryCode, targetClickCountryCode, isAttacking, isAttacked) => {
    logger.debug(
      'Adding ' + iconName + ' icon at: ',
      coords,
      countryCode,
      targetClickCountryCode,
      isAttacking,
      isAttacked
    );

    var g = angular.element('.jvectormap-container > svg > g[transform]');
    var img = htmlDoc.createElementNS(svgXmlns, 'image');
    img.setAttributeNS(null, 'x', coords.x - 15 + xOffset);
    img.setAttributeNS(null, 'y', coords.y - 15 + yOffset);
    img.setAttributeNS(null, 'width', '30');
    img.setAttributeNS(null, 'height', '30');

    var iconSuffix = isAttacking ? '-01' : '-02';
    var iconFile;

    switch (iconName) {
      case 'hacker':
        iconFile = `images/Attackers/hacker2${iconSuffix}.png`;
        break;
      case 'hacktivist':
        iconFile = `images/Attackers/hacktivist2${iconSuffix}.png`;
        break;
      case 'cybercriminal':
        iconFile = `images/Attackers/cybercriminal${iconSuffix}.png`;
        break;
      case 'statesponsored':
        iconFile = `images/Attackers/statesponsored${iconSuffix}.png`;
        break;
      case 'bank':
        iconFile = `images/Targets/bank${iconSuffix}.png`;
        break;
      case 'cloud':
        iconFile = `images/Targets/cloud${iconSuffix}.png`;
        break;
      case 'ecommerce':
        iconFile = `images/Targets/ecommerce${iconSuffix}.png`;
        break;
      case 'utility':
        iconFile = `images/Targets/utilities${iconSuffix}.png`;
        break;
    }

    img.setAttributeNS(xlinkXmlns, 'href', iconFile);
    img.setAttributeNS(xlinkXmlns, 'orig-ng-click', 'onCountryIconSelected("' + targetClickCountryCode + '")');
    img.setAttributeNS(null, 'pointer', true);

    compile(img)(scope);
    g.append(img);
  };
