import { z } from 'zod';
import { createSearchParams } from 'react-router-dom';
export const filterStateSchema = z.object({
    language: z.array(z.string()).default([]),
    category: z.array(z.string()).default([]),
    status: z.array(z.string()).default([]),
    activityType: z.array(z.string()).default([]),
    searchTerm: z.string().default(''),
});
export const createFilterState = (params) => {
    var _a, _b, _c, _d, _e;
    return ({
        language: (_a = params === null || params === void 0 ? void 0 : params.getAll('language')) !== null && _a !== void 0 ? _a : [],
        category: (_b = params === null || params === void 0 ? void 0 : params.getAll('category')) !== null && _b !== void 0 ? _b : [],
        status: (_c = params === null || params === void 0 ? void 0 : params.getAll('status')) !== null && _c !== void 0 ? _c : [],
        activityType: (_d = params === null || params === void 0 ? void 0 : params.getAll('activityType')) !== null && _d !== void 0 ? _d : [],
        searchTerm: (_e = params === null || params === void 0 ? void 0 : params.get('searchTerm')) !== null && _e !== void 0 ? _e : '',
    });
};
const FILTER_STATE_KEY = 'explore-filter-state';
export const saveFilterState = (state) => {
    try {
        window.localStorage.setItem(FILTER_STATE_KEY, JSON.stringify(state));
    }
    catch (e) {
        console.error(e);
    }
};
export const getFilterState = () => {
    try {
        const state = window.localStorage.getItem(FILTER_STATE_KEY);
        if (!state)
            return filterStateSchema.parse({});
        return filterStateSchema.parse(JSON.parse(state));
    }
    catch (e) {
        console.error(e);
        return filterStateSchema.parse({});
    }
};
export const isFilterStateEmpty = (state) => {
    return !Object.values(state).find((value) => value.length);
};
export const createQueryString = (state) => {
    const searchParams = createSearchParams(state);
    searchParams.forEach((_v, k) => {
        var _a;
        if (((_a = searchParams.get(k)) === null || _a === void 0 ? void 0 : _a.length) === 0)
            searchParams.delete(k);
    });
    return searchParams.toString();
};
export const createFilterStateFromLocation = (location) => {
    return createFilterState(createSearchParams(location.split('?').pop()));
};
