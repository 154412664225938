export const getLanguageIcon = (languageMap, language) => {
  const frameworkIcon = languageMap[language._id]?.framework[language._framework]?.icon;
  if (frameworkIcon) return frameworkIcon;

  const languageIcon = languageMap[language._id]?.icon;
  if (languageIcon) return languageIcon;

  return 'devicon-devicon-plain';
};

export const getLanguageInformation = (languageMap) => (language) => {
  const langName = languageMap[language._id]?.name;
  const frameworkName = languageMap[language._id]?.framework[language._framework]?.name;
  const icon = getLanguageIcon(languageMap, language);

  return {
    id: `${language._id}:${language._framework}`,
    languageId: language._id,
    frameworkId: language._framework,
    label: `${langName} ${frameworkName}`.trim(),
    iconClassName: icon,
  };
};
