import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('withLoading', [
  '$rootScope',
  '$translate',
  function ($rootScope, $translate) {
    return function (fn, container) {
      return function () {
        var args = arguments;
        var result = fn.apply(this, args);

        // is promise
        if (result.then) {
          $rootScope.currentLoadingMessage = $translate.instant(
            'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
          );
          $rootScope.loading++;

          result.inprogress = true;
          result.done = false;

          if (container) {
            container.inprogress = true;
            container.done = false;
          }

          return result.finally(function () {
            result.inprogress = false;
            result.done = true;

            if (container) {
              container.inprogress = false;
              container.done = true;
            }

            $rootScope.loading--;
          });
        }

        return result; // not promise
      };
    };
  },
]);
