import angular from 'angular';
import MODULE from './module';

(function () {
  var app = angular.module(MODULE);

  // This is the challenge player that is shown when launching a challenge from CMS
  app.controller('ChallengeTestController', [
    '$stateParams',
    '$scope',
    '$rootScope',
    'ErrorHandler',
    'GameApiService',
    function ($stateParams, $scope, $rootScope, ErrorHandler, GameApiService) {
      $scope.useCmsVersion = { value: 'yes' };

      $scope.loadPlayer = () => {
        $scope.hasChosen = true;
        $scope.challenge = undefined;
        GameApiService.addLoading();

        const appId = $stateParams.appId;
        const cId = $stateParams.challengeId;
        const cbl = $stateParams.cbl.slice(-2);
        const data = { useCmsVersion: $scope.useCmsVersion.value === 'yes' };
        GameApiService.postCreateSingleChallengePlayerSession(appId, cId, cbl, data)
          .then((externalPlayerUrl) => {
            $scope.playmode = 'cms';
            $scope.challenge = { externalPlayerUrl, cId };
            $rootScope.challengeInProgress = true;
          })
          .catch((res) => {
            ErrorHandler.addHttpError('new challenge player failed to alloc session', res);
            $scope.hasChosen = false;
          })
          .finally(GameApiService.removeLoading);
      };

      $scope.restartChallenge = () => {
        $rootScope.challengeInProgress = false;
        $scope.challenge = undefined;
        $scope.hasChosen = false;
      };
    },
  ]);
})();
