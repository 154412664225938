import { styled } from '@mui/material';
export const Numbers = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    width: '42px',
    height: '30px',
});
// Digit Container
export const DigitContainer = styled('div')(() => ({
    perspective: '1000px',
    height: '28px',
    '&:first-of-type': {
        '--justify-content': 'flex-end',
        '--padding': '0 4px 0 0',
    },
}));
// Flip Card Styles
export const FlipCard = styled('div')({
    width: '100%',
    height: '100%',
    position: 'relative',
});
export const FlipCardInner = styled('div')(({ flip }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.25s ease-in-out',
    transform: flip ? 'rotateX(-180deg)' : 'rotateX(0deg)',
}));
export const FlipCardFace = styled('div')({
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'var(--justify-content, flex-start)',
    padding: 'var(--padding, 0 0 0 4px)',
    alignItems: 'center',
    fontFamily: "'Hubot-Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#ffffff',
    backgroundColor: 'transparent',
});
export const FlipCardFront = styled(FlipCardFace)({
    transform: 'rotateX(0deg)',
    zIndex: 2,
});
export const FlipCardBack = styled(FlipCardFace)({
    transform: 'rotateX(180deg)',
});
