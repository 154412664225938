import angular from 'angular';
import MODULE from './module';

// templates
import listTemplate from './tournaments.list.html';
import adminEditTemplate from './tournaments.admin.edit.html';
import playerHomeTemplate from './tournaments.player-home.html';
import challengeTemplate from './tournaments.challenge.html';
import liveStatusTemplate from './tournaments.live-status.html';
import notLicensedTemplate from './tournaments.not-licensed.html';
import iframeTemplate from './tournaments.new.html';

angular.module(MODULE).config([
  '$stateProvider',
  'USER_ROLES',
  function ($stateProvider, USER_ROLES) {
    $stateProvider
      .state('tournaments', {
        url: '/tournaments',
        controller: 'TournamentsRootController',
        template: '<ui-view/>',
      })
      .state('tournaments.list', {
        url: '/list?tournamentId',
        controller: 'TournamentListController',
        templateUrl: listTemplate,
      })
      .state('tournaments.new', {
        url: '/list/new',
        controller: 'TournamentsNewController',
        templateUrl: iframeTemplate,
      })
      .state('tournaments.add', {
        url: '/add',
        controller: 'TournamentAddController',
        templateUrl: adminEditTemplate,
        data: {
          authorizedRoles: USER_ROLES.higherThan(USER_ROLES.manager),
        },
      })
      .state('tournaments.edit', {
        url: '/edit/{id}?:tournamentId', //separate id attribute because tournamentId can be empty, linking to list instead of detail
        controller: 'TournamentAddController',
        templateUrl: adminEditTemplate,
        data: {
          authorizedRoles: USER_ROLES.higherThan(USER_ROLES.manager),
        },
      })
      .state('tournaments.player', {
        url: '/{_tournament}/player-home?viewResults',
        controller: 'TournamentPlayerHomeController',
        templateUrl: playerHomeTemplate,
      })
      .state('tournaments.challenge', {
        url: '/{_tournament}/quest/{_quest}/challenge',
        controller: 'TournamentChallengeController',
        templateUrl: challengeTemplate,
        params: { tournament: false }, // don't fetch twice
      })
      .state('tournaments.live-status', {
        url: '/live-status/{id}?from&forceView&:tournamentId', //separate id attribute because tournamentId can be empty, linking to list instead of detail
        controller: 'TournamentLiveStatusController',
        templateUrl: liveStatusTemplate,
      })
      .state('tournaments-not-licensed', {
        url: '/tournaments-not-licensed',
        templateUrl: notLicensedTemplate,
        controller: 'TournamentNotLicensedController',
      });
  },
]);
