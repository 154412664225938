import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

const app = angular.module(MODULE);

app.service('LanguageUtils', [
  function () {
    return {
      isAvailable: function (scopes, language) {
        var scope;
        var i;
        var foundFirstScope = false;

        // search for first available scope in order specified by the array
        for (i = 0; i < scopes.length; i++) {
          scope = scopes[i];
          if (!scope) continue;
          if (!scope.languages || scope.languages.length < 1) continue;

          foundFirstScope = true;

          var lang;
          var x;
          for (x = 0; x < scope.languages.length; x++) {
            lang = scope.languages[x];
            if (lang && lang._id == language._id && lang._framework == language._framework) {
              return true;
            }
          }

          // halt after checking language against applicable scope
          if (foundFirstScope) break;
        }
        return false;
      },
      isExclusiveLanguage: function (_id, _framework) {
        return _.get(metadata.languages[_id], 'framework.' + _framework + '.exclusiveLanguage', false);
      },
      languageList: function (filter) {
        var _id;
        var lang;
        var response = [];
        for (_id in metadata.languages) {
          lang = metadata.languages[_id];
          for (var _framework in lang.framework) {
            var framework = lang.framework[_framework];
            if (framework.exclusiveLanguage !== true) {
              var langObj = {
                _id: _id,
                _framework: _framework,
                $$categoryType: framework.categoryType,
                $$isPoc: framework.isPoc,
                $$isAvailable: framework.isAvailable,
              };

              if (framework?.deprecated) {
                langObj.$$isDeprecated = true;
                langObj.$$deprecationReason = framework.deprecationReason;
              }

              var match = true;
              if (filter) {
                for (var _attr in filter) {
                  if (langObj[_attr] && langObj[_attr] != filter[_attr])
                    // :
                    match = false;
                }
              }
              if (match) {
                response.push(langObj);
              }
            }
          }
        }

        // sort by language name and availability
        response = response.sort(function (a, b) {
          if (b.$$isAvailable > a.$$isAvailable) {
            return 1;
          } else if (b.$$isAvailable == a.$$isAvailable) {
            return 0;
          } else {
            return -1;
          }
        });

        return response;
      },
      languageName: function (language) {
        if (!language) return;

        var metadataLanguage = metadata.languages[language._id];
        return metadataLanguage.name + ' ' + metadataLanguage.framework[language._framework].name;
      },
      // populate a language list [{_id, _framework}, ...] with isPoc and isAvailable values from metadata
      populate: function (list) {
        for (var i = 0; i < list.length; i++) {
          var item = list[i];
          var metadataItem = metadata.languages[item._id].framework[item._framework];
          item.$$isPoc = metadataItem.isPoc;
          item.$$isAvailable = metadataItem.isAvailable;
        }
      },
    };
  },
]);

app.controller('Game013LanguagesController', [
  '$log',
  '$scope',
  '$state',
  '$translate',
  'Game013Api',
  'Game013StateService',
  'ErrorHandler',
  'LanguageUtils',
  'PlatformLanguagesService',
  'AuthService',
  'USER_ROLES',
  'USER_TYPE',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $log,
    $scope,
    $state,
    $translate,
    Game013Api,
    Game013StateService,
    ErrorHandler,
    LanguageUtils,
    PlatformLanguagesService,
    AuthService,
    USER_ROLES,
    USER_TYPE,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $scope.allLanguages = [];
    $scope.languageStats = PlatformLanguagesService.remote().then(function (response) {
      $scope.allLanguages = response.languages;
    });

    $scope.languages = [];

    $scope.isAvailable = function (lang) {
      var user = $scope.Session.user;
      var team = user.properties.team;
      var company = user.properties.company;
      var isAdmin = AuthService.isAuthorized([USER_ROLES.admin]);
      var isStandalone = !user.properties._cid && !user.properties._tid;
      var isStandaloneTeam = !user.properties._cid && user.properties._tid;

      // For standalone teams, if languages array is empty (indicating all languages), assign all languages to the array
      if (isStandaloneTeam && !team.languages.length) team.languages = LanguageUtils.languageList();

      var scopes = isStandalone ? [user] : isStandaloneTeam ? [team] : [team, company];
      return isAdmin || LanguageUtils.isAvailable(scopes, lang);
    };

    $scope.playLanguage = function (language, status) {
      if (status === 'coming soon' || status === 'not subscribed') {
        // When a trial user clicked on a locked language, log the action to Amplitute.
        if (
          $scope.Session.getUserType() === USER_TYPE.WEB_TRIAL ||
          $scope.Session.getUserType() === USER_TYPE.SALES_TRIAL
        ) {
          AnalyticsService.logEvent(AnalyticsEvents.Training.CLICK_LOCKED_LANGUAGE, {
            locked_language_framework_clicked: language._id + ' ' + language._framework,
          });
        }
        return;
      }

      Game013StateService.setActiveState({ _language: language._id, _framework: language._framework });
      Game013Api.markLanguageAsActive(Game013StateService.active)
        .then(function (_data) {
          $state.flow('game.013.play.realms', Game013StateService.active);
        })
        .catch(function (response) {
          $translate(['FAILED_TO_LOAD_LANGUAGE']).then(function (translations) {
            ErrorHandler.addHttpError(translations.FAILED_TO_LOAD_LANGUAGE, response);
          });
        });
    };

    function languageSortFn(a, b) {
      if (a.language._id < b.language._id) {
        return -1;
      }
      if (a.language._id > b.language._id) {
        return 1;
      }
      // _id must be equal so sort by _framework
      if (a.language._framework < b.language._framework) {
        return -1;
      }
      if (a.language._framework > b.language._framework) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    // need to reload languages from server to fetch latest status
    // only needed if navigating from another part of the game since language status needs to be refreshed
    // for eg: if c# was previously active, then user started playing in java, need to reload language status
    $scope.$gameStatusPromise = $scope.$gameStatusPromise.then(Game013Api.getGameStatus).then(function (data) {
      $log.debug('languages getGameStatus: ', data);

      // Remove coming soon languages
      data.languages = data.languages.filter(function (language) {
        return language && language.status !== $scope.metadata.constants.language.status.COMING_SOON;
      });

      var unlockedLanguages = [];
      var lockedLanguages = [];
      var i;
      for (i = 0; i < data.languages.length; i++) {
        var lang = data.languages[i];
        // If a language is retired or is not available for user, just loop over the same
        if (lang.isRetired || lang.status == $scope.metadata.constants.language.status.NOT_AVAILABLE) {
          continue;
        }

        if (lang.status == $scope.metadata.constants.language.status.NOT_SUBSCRIBED) {
          lockedLanguages.push(lang);
        } else {
          // available or proof of concept
          unlockedLanguages.push(lang);
        }
      }

      unlockedLanguages.sort(languageSortFn);
      lockedLanguages.sort(languageSortFn);

      $scope.gameData.languages = unlockedLanguages.concat(lockedLanguages);
      $scope.gameData.homeCountry = data.homeCountry;
      $scope.languages = $scope.gameData.languages;

      $scope.initialTrainingLanguageSelected = $scope.languages.some(function (lang) {
        return lang.status === $scope.metadata.constants.language.status.ACTIVE;
      });

      $scope.prettyDate = function (date) {
        return moment(date).format('D MMM YYYY');
      };

      // Added numberOfChallenges Key to available-languages array
      $scope.languageStats.then(function () {
        for (i = 0; i < $scope.languages.length; i++) {
          for (var j = 0; j < $scope.allLanguages.length; j++) {
            if (
              $scope.languages[i].language._id == $scope.allLanguages[j].id._id &&
              $scope.languages[i].language._framework == $scope.allLanguages[j].id._framework
            ) {
              $scope.languages[i].numberOfChallenges = $scope.allLanguages[j].numberOfChallenges * 3; // each set of source code can support 3 challenge types
              $scope.languages[i].estimatedTime = Math.round($scope.languages[i].numberOfChallenges / 10);
            }
          }
        }
      });
    });

    $scope.$on('$destroy', function () {
      $scope.languages = [];
    });
  },
]);
