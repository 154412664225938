import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('LicenseUtils', [
  '$q',
  '$http',
  '$window',
  function ($q, $http, $window) {
    var NOT_AVAILABLE = 'not available';
    var _languageStats = null;

    return {
      findLanguage: findLanguage,
      isLicensed: isLicensed,
      languageList: languageList,
      isInherited: isInherited,
    };

    function languageStats() {
      if (_languageStats) {
        return $q.when(JSON.parse(JSON.stringify(_languageStats)));
      }

      return $http.get($window.SCW_ENV.ApiEndpoint + '/public/stats').then(function (res) {
        _languageStats = res.data.languages;
        return JSON.parse(JSON.stringify(_languageStats));
      });
    }

    function languageList(entityLicenses) {
      return languageStats().then(function (languages) {
        return languages
          .filter(function (language) {
            return NOT_AVAILABLE !== language.gameAvailability['013'];
          })
          .map(function (language) {
            var license = findLanguage(entityLicenses, language.id);

            return {
              language: language.id,
              seats: license ? license.seats : 0,
              allocated: license ? license.allocated : 0,
              used: license ? license.used : 0,
              usedPercent: license ? Math.round((license.used / license.seats) * 100) : 0,
              isLicensed: !!license,
              inherited: license ? license.inherited : false,
            };
          });
      });
    }

    function findLanguage(entityLicenses, language) {
      var found = null;

      if (entityLicenses && entityLicenses.forEach) {
        entityLicenses.forEach(function (license) {
          if (license.language._id === language._id && license.language._framework === language._framework) {
            found = license;
          }
        });
      }

      return found;
    }

    function isLicensed(entityLicenses, language) {
      return !!findLanguage(entityLicenses, language);
    }

    function isInherited(entityLicenses) {
      return entityLicenses.every(function (license) {
        return license.inherited;
      });
    }
  },
]);
