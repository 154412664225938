var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useId, useState } from 'react';
import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import { Box as MuiBox, Collapse as MuiCollapse, List as MuiList, styled, useMediaQuery, } from '@mui/material';
import { Unstable_Menu } from '@securecodewarrior/design-system-react/lib/legacy';
import { AppBarMobileContext } from '../../AppBar.context';
import { Unstable_AppBarButton } from '../AppBarButton';
import { appBarMenuStyles } from './AppBarMenu.styles';
// =============================================================
// Styled Components
// =============================================================
const baseProps = { shouldForwardProp: (prop) => prop !== 'opened' };
const StyledBox = styled(MuiBox, baseProps)(({ opened, theme }) => (Object.assign({}, appBarMenuStyles(opened, theme).root)));
const StyledExpandMoreIcon = styled(ExpandMoreOutlined, baseProps)(({ opened, theme }) => (Object.assign({}, appBarMenuStyles(opened, theme).icon)));
const StyledExpandLessIcon = styled(ExpandLessOutlined, baseProps)(({ opened, theme }) => (Object.assign({}, appBarMenuStyles(opened, theme).icon)));
// =============================================================
// Mobile
// =============================================================
const AppBarMenuMobile = (props) => {
    const { active: _active, children, className = '', component: _component, label, startIcon } = props, rest = __rest(props, ["active", "children", "className", "component", "label", "startIcon"]);
    const appBarMobileContext = useContext(AppBarMobileContext);
    const opened = appBarMobileContext.menuOpened === label;
    const iconSize = 'large';
    const handleClick = useCallback(() => {
        var _a;
        (_a = appBarMobileContext === null || appBarMobileContext === void 0 ? void 0 : appBarMobileContext.setOpenMenu) === null || _a === void 0 ? void 0 : _a.call(appBarMobileContext, label);
    }, [appBarMobileContext, label]);
    return (_jsxs(StyledBox, { className: `ScwAppBarMenu-root ${className}`, component: "div", opened: opened, children: [_jsx(Unstable_AppBarButton, Object.assign({}, rest, { active: opened, endIcon: _jsxs(_Fragment, { children: [_jsx(StyledExpandLessIcon, { opened: opened, fontSize: iconSize }), _jsx(StyledExpandMoreIcon, { opened: !opened, fontSize: iconSize })] }), onClick: handleClick, startIcon: startIcon, children: label })), _jsx(MuiCollapse, { in: opened, timeout: "auto", unmountOnExit: true, children: _jsx(MuiList, { component: "div", disablePadding: true, children: children }) })] }));
};
// =============================================================
// Desktop
// =============================================================
const AppBarMenuDesktop = (props) => {
    const { active, children, className = '', component, label, startIcon } = props, rest = __rest(props, ["active", "children", "className", "component", "label", "startIcon"]);
    const [opened, setOpen] = useState(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    const DefaultButton = () => (_jsx(Unstable_AppBarButton, Object.assign({}, rest, { active: opened || active, endIcon: _jsxs(_Fragment, { children: [_jsx(StyledExpandLessIcon, { opened: opened, fontSize: "inherit" }), _jsx(StyledExpandMoreIcon, { opened: !opened, fontSize: "inherit" })] }), startIcon: startIcon, children: label })));
    const ariaLabelledBy = useId();
    const ariaControls = useId();
    return (_jsx(StyledBox, { className: `ScwAppBarMenu-root ${className}`, component: "div", opened: opened, children: _jsx(Unstable_Menu, { ariaLabelledBy: ariaLabelledBy, ariaControls: ariaControls, component: component || DefaultButton(), onClose: handleClose, onOpen: handleOpen, children: children }) }));
};
// =============================================================
// AppBarMenu
// =============================================================
export const AppBarMenuComponent = (props) => {
    const isDesktopLayout = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return isDesktopLayout ? _jsx(AppBarMenuDesktop, Object.assign({}, props)) : _jsx(AppBarMenuMobile, Object.assign({}, props));
};
AppBarMenuComponent.displayName = 'AppBarMenu';
export const Unstable_AppBarMenu = React.memo(AppBarMenuComponent);
/** @deprecated use {@link Unstable_AppBarMenu} to indicate that you are aware that this component will change */
export const AppBarMenu = Unstable_AppBarMenu;
export default AppBarMenu;
