import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.controller('TournamentsNewController', [
  '$scope',
  '$location',
  '$rootScope',
  '$browser',
  '$window',
  'Session',
  function ($scope, $location, $rootScope, $browser, $window, Session) {
    const { SCW_ENV } = $window;
    // check if url is localhost
    const isLocalhost = $location.host() === 'localhost';
    let iframeUrl = '';

    const reactPath = $location.$$path.split('/tournaments/')[1];
    if (isLocalhost) {
      iframeUrl = `${SCW_ENV.TournamentUiHost}/tournaments/#/${reactPath}`;
    } else {
      iframeUrl = `${SCW_ENV.TournamentUiHost}/#/${reactPath}`;
    }

    $scope.newAppUrl = iframeUrl;

    $window.addEventListener('message', receiveMessage);

    function sendMessage(message, targetOrigin) {
      const iframe = document.getElementById('tournaments-iframe');
      iframe.contentWindow.postMessage(message, targetOrigin);
    }

    const isTrustedOrigin = (origin) => {
      // if localhost, allow all origins
      if (isLocalhost) {
        return true;
      }
      // if not localhost, check if origin is a subdomain of the current host
      const host = $location.host();
      const temp = host.split('.').reverse();
      // get root domain of the current host eg. '.securecodewarrior.com'
      const rootDomain = '.' + temp[1] + '.' + temp[0];
      return origin.indexOf(rootDomain) > -1;
    };

    function receiveMessage(event) {
      if (event.isTrusted && isTrustedOrigin(event.origin)) {
        const messageType = event.data.type;
        const messageData = event.data.data;
        const reload = !!event.data.reload;

        if (messageType === 'syncUrl') {
          handleUrlSync(messageData, reload);
        }

        if (messageType === 'navigateToUrl') {
          handleUrlNavigate(messageData, reload);
        }

        if (messageType === 'click' && messageData === 'closeDropdowns') {
          closeDropDowns();
        }

        if (messageType === 'setAppLoaded') {
          $rootScope.appLoaded = messageData;
        }

        if (messageType === 'loadSession') {
          sendMessage(
            {
              type: 'session',
              data: {
                id: Session.getSessionId(),
                region: getRegion(),
              },
            },
            new URL($scope.newAppUrl).origin
          );
        }
      } else {
        console.warn('iFrame message not sent from trusted origin', event.origin);
      }
    }

    function handleUrlSync(url, reload) {
      const currentPath = location.hash.replace('#', '');
      if (currentPath !== url) {
        $browser.url('#' + url, false, null);
        if (reload) {
          $window.location.reload(); // Hard refresh the page
        }
        $rootScope.$digest(); // Soft re-render the app
      }
    }

    function handleUrlNavigate(url, reload) {
      const location = $location.path(url);

      if (reload) {
        $window.location.href = `#/${location.url()}`;
        $window.location.reload(); // Hard refresh the page
      } else {
        $rootScope.$digest(); // Soft re-render the app
      }
    }

    function closeDropDowns() {
      angular.element('.dropdown.open').click();
    }

    function getRegion() {
      return $window.SCW_ENV.ApiEndpoint.includes('.eu.') ? 'eu' : 'us';
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('message', receiveMessage);
    });

    $scope.handleUrlSync = handleUrlSync;
  },
]);
