import angular from 'angular';
import SCWAnalytics from '../analytics';
import { react_router_states } from '../scw-react/enums';

const MODULE = 'SCWTrainingListCourses';
export default MODULE;

const app = angular.module(MODULE, [SCWAnalytics]);

app.controller('CourseNewController', [
  '$scope',
  '$location',
  '$rootScope',
  '$browser',
  '$window',
  '$state',
  '$stateParams',
  function ($scope, $location, $rootScope, $browser, $window, $state, $stateParams) {
    const { relayState } = $stateParams;
    if (relayState) {
      $state.go('home.relay-state', { relayState });
      $rootScope.appLoaded = true;
    }

    $scope.newAppUrl = '/web/index.html';

    $window.addEventListener('message', receiveMessage);

    function receiveMessage(event) {
      if (event.isTrusted && event.origin === location.origin) {
        var messageType = event.data.type;
        var messageData = event.data.data;
        var reload = !!event.data.reload;
        var i18nLanguage = event.data.i18nLanguage;

        if (messageType === 'syncUrl') {
          handleUrlSync(messageData, reload);
        }

        if (messageType === 'navigateToUrl') {
          handleUrlNavigate(messageData, reload);
        }

        if (messageType === 'click' && messageData === 'closeDropdowns') {
          closeDropDowns();
        }

        if (messageType === 'setAppLoaded') {
          $rootScope.appLoaded = messageData;
        }

        if (messageType === 'profileCompleted') {
          handleProfileCompleted(messageData, reload, i18nLanguage);
        }
      } else {
        console.warn('iFrame message not sent from trusted origin', event.origin);
      }
    }

    function handleUrlSync(url, reload) {
      var currentPath = location.hash.replace('#', '');
      if (currentPath !== url) {
        $browser.url('#' + url, false, null);
        if (reload) {
          $window.location.reload(); // Hard refresh the page
        }
        $rootScope.$digest(); // Soft re-render the app
      }
    }

    function handleUrlNavigate(url, reload) {
      const [path, search] = url.split('?');

      $location.path(path);
      if (search) {
        const params = search.split('&');
        for (const param of params) {
          $location.search(...param.split('='));
        }
      }

      if (reload) {
        $window.location.href = `#/${$location.url()}`;
        $window.location.reload(); // Hard refresh the page
      } else {
        $rootScope.$digest(); // Soft re-render the app
      }
    }

    function closeDropDowns() {
      angular.element('.dropdown.open').click();
    }

    function handleProfileCompleted(url, reload, i18nLanguage) {
      const profileIncomplete = $rootScope.assessmentProfileIncomplete;
      const redirectOnProfileCompletion = JSON.parse($window.localStorage.getItem('redirectOnProfileCompletion'));

      if (profileIncomplete && profileIncomplete.assessmentId && profileIncomplete.attemptId) {
        handleUrlNavigate(`assessments/view/${profileIncomplete.assessmentId}/attempt/${profileIncomplete.attemptId}`);
      } else if (redirectOnProfileCompletion && !relayState) {
        // PLAT-13424 If the language has been CHANGED, reload page, so it's translated correctly
        $rootScope.changeLanguage(i18nLanguage);

        $rootScope.userUpdate().then(() => {
          $window.localStorage.removeItem('redirectOnProfileCompletion');

          // scw-react (explore, quests, etc..) uses a catch-all *path param to so that all route definitions can be defined
          // inside the react app only. Angular router doesn't allow us to directly transition to a URL, so we stored the
          // part of the path after the top-level state and reconstruct the url here
          if (Object.values(react_router_states).includes(redirectOnProfileCompletion.requestedState)) {
            const path =
              '/' +
              redirectOnProfileCompletion.requestedState +
              (redirectOnProfileCompletion.requestedStateParams.path
                ? redirectOnProfileCompletion.requestedStateParams.path
                : '');
            window.location = `${window.location.origin}/#${path}`;
            return;
          }

          $state.go(redirectOnProfileCompletion.requestedState, redirectOnProfileCompletion.requestedStateParams);
        });
      } else {
        handleUrlSync(url, reload);
      }
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('message', receiveMessage);
    });

    $scope.handleUrlSync = handleUrlSync;
  },
]);
