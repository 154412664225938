import angular from 'angular';
import MODULE from './module';

(function () {
  angular.module(MODULE).factory('Game013Api', [
    '$window',
    '$http',
    '$rootScope',
    '$log',
    '$q',
    '$translate',
    'HttpConfigService',
    function ($window, $http, $rootScope, $log, $q, $translate, HttpConfigService) {
      function withLoading(fn, container) {
        return function () {
          const result = fn.apply(this, arguments);

          // is promise
          if (result.then) {
            $rootScope.currentLoadingMessage = $translate.instant(
              'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
            );
            $rootScope.loading++;

            result.inprogress = true;
            result.done = false;

            if (container) {
              container.inprogress = true;
              container.done = false;
            }

            return result.finally(function () {
              result.inprogress = false;
              result.done = true;

              if (container) {
                container.inprogress = false;
                container.done = true;
              }

              $rootScope.loading--;
            });
          }

          return result; // not promise
        };
      }

      function getHttpConfig(disableIFR) {
        return _.merge({}, disableIFR ? HttpConfigService.getHttpConfigNoIFR() : HttpConfigService.getHttpConfig(), {
          withCredentials: true, // required to set cookie for CF
        });
      }

      function transformChallenge(data) {
        $log.debug('transformChallenge()', data);
        data.attempt = data.attempt || data.challengeAttempt;

        if (!data.challenge) {
          data.challenge = {};
        }

        if (!data.challenge.stages) {
          data.challenge.stages = _.get(data.attempt, 'stages');
        }

        if (!data.challenge.stages) {
          data.challenge.stages = [{ status: data.stage1status }, { status: data.stage2status }];
        }

        return data;
      }

      function url(path) {
        return $window.SCW_ENV.ApiEndpoint + '/game/013' + (path || '');
      }

      function langUrl(ctx, path) {
        return url('/' + ctx._language + '/' + ctx._framework + (path || ''));
      }

      function publicUrl() {
        return $window.SCW_ENV.ApiEndpoint + '/public';
      }

      function levelUrl(ctx) {
        return langUrl(ctx) + '/' + ctx._realm + '/' + ctx._level;
      }

      function questUrl(ctx) {
        return levelUrl(ctx) + '/' + ctx._quest;
      }

      function responseData(response) {
        return response.data;
      }

      const httpGet = withLoading(function httpGet(url) {
        if ('string' === typeof url) {
          return $http.get(url, getHttpConfig(false)).then(responseData);
        } else {
          return $http(
            angular.extend(
              {
                method: 'GET',
              },
              getHttpConfig(false),
              url
            )
          ).then(responseData);
        }
      });

      const httpGetNoIFR = withLoading(function httpGetNoIFR(url) {
        return $http.get(url, getHttpConfig(true)).then(responseData);
      });

      const httpPost = withLoading(function httpPost(url, body) {
        return $http.post(url, body, getHttpConfig(false)).then(responseData);
      });

      const httpPostNoIFR = withLoading(function httpPostNoIFR(url, body) {
        return $http.post(url, body, getHttpConfig(true)).then(responseData);
      });

      let currentSolutions = [];

      const languageQueryParameter = function (language) {
        return language ? '&language=' + encodeURIComponent(language._id + ':' + language._framework) : '';
      };

      const paginationParams = function (options) {
        return { page: options.page, size: options.size };
      };

      return {
        url: url,
        langUrl: langUrl,
        currentSolutions: currentSolutions,
        getGameStatus: function () {
          return httpGetNoIFR(url());
        },
        getGameLeaderboard: function () {
          return httpGet(url('/leaderboard'));
        },
        getGamePlayerMetrics: function () {
          return httpGet(url('/player-metrics'));
        },
        updateGameProfile: function (homeCountry) {
          const body = { homeCountry: homeCountry };
          return httpPost(url(), body);
        },
        getLanguageStatus: function (ctx) {
          return httpGet(langUrl(ctx));
        },
        markLanguageAsActive: function (ctx) {
          return httpPostNoIFR(langUrl(ctx, '/active'));
        },
        getLanguageLeaderboard: function (ctx, options) {
          return httpGet({
            url: langUrl(ctx) + '/leaderboard',
            params: paginationParams(options),
          });
        },
        // allow leaderboard fetching to be in reverse to avoid worldmap leaderboard only showing e.g. 4 records when player is near the bottom and ends up on the last page
        getDummyLeaderboard: function (size) {
          return httpGet({
            url: publicUrl() + '/anonimized-leaderboard/' + size,
          });
        },
        getLanguagePlayerMetrics: function (ctx, reverseLeaderboard) {
          const pagerOptionsString = reverseLeaderboard ? 'page=1&size=-10' : 'size=10';
          // since we dont use the leaderboard on the player metrics page, just request the least amount of leaderboard data possible
          return httpGet(langUrl(ctx) + '/player-metrics?' + pagerOptionsString);
        },
        getPublicLanguagePlayerMetrics: function (_uid, language) {
          // Function to check both US and EU servers - originally from auth.app.factory
          const { SCW_ENV } = $window;
          const endpointUnaware = {
            get: function (path, config) {
              $log.debug('Endpoint unaware GET');

              config.isRetry = true;
              config.isEndpointUnawareCheck = true;

              const promiseUS = $http
                .get(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.US] + path, config)
                .then(function (response) {
                  return response;
                })
                .catch(function (err) {
                  return err;
                });
              const promiseEU = $http
                .get(SCW_ENV.ApiEndpoints[SCW_ENV.API_ENDPOINT_INDEXES.EU] + path, config)
                .then(function (response) {
                  return response;
                })
                .catch(function (err) {
                  return err;
                });

              return $q.all([promiseUS, promiseEU]).then(function (responses) {
                let res = undefined;
                for (let i = 0; i < responses.length; i++) {
                  if (responses[i].status === 200) {
                    res = responses[i];
                    break;
                  }
                }
                return res;
              });
            },
          };

          return endpointUnaware
            .get('/public/player/' + _uid + '?' + languageQueryParameter(language), HttpConfigService.getHttpConfig())
            .then(function (res) {
              const data = res.data;
              // deal with inconsistent API and convert data into format consistent with gameData.languages
              for (let i = 0; i < data.languages.length; i++) {
                const language = data.languages[i];
                language.status = $rootScope.metadata.constants.language.status.AVAILABLE;
                language.language = {
                  _id: language._id,
                  _framework: language._framework,
                };
              }
              return data;
            })
            .catch(function (_response) {
              $log.debug('Error has occurred retrieving player statistics');
            });
        },
        getLevelStatus: function (ctx) {
          return httpGet(levelUrl(ctx));
        },
        getQuestProgress: function (_language, _framework, _realm, _level, _quest) {
          return httpGet(url(`/progress/${_language}/${_framework}/${_realm}/${_level}/${_quest}`));
        },
        getChallenge: function (ctx) {
          currentSolutions = [];
          const url = questUrl(ctx) + '/challenge';
          return httpGet(url).then(transformChallenge);
        },
        skipChallenge: function (ctx) {
          currentSolutions = [];
          const url = questUrl(ctx) + '/challenge/skip';
          return httpPost(url, null).then(transformChallenge);
        },
      };
    },
  ]);
})();
