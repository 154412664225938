import angular from 'angular';
import MODULE from './module';
import Papa from 'papaparse';
import * as _ from 'lodash';
import templateUrl from './admin.companies.bulk-manage.html';

angular.module(MODULE).service('AdminBulkManageUsersCSV', [
  '$uibModal',
  function ($uibModal) {
    return function (company) {
      return $uibModal
        .open({
          openedClass: 'curtain',
          templateUrl,
          controller: 'AdminBulkManageUsersCSVModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            defaults: function () {
              return {};
            },
            csvMapping: function () {
              return ['email', 'role', 'team'];
            },
            company: function () {
              return company;
            },
          },
        })
        .result.catch(angular.noop);
    };
  },
]);

angular.module(MODULE).controller('AdminBulkManageUsersCSVModalController', [
  '$uibModalInstance',
  '$window',
  '$timeout',
  '$log',
  '$scope',
  '$translate',
  'AdminApiService',
  'USER_ROLES',
  'AuthService',
  'ExportUsersCSVFn',
  'ErrorHandler',
  'Session',
  'csvMapping',
  'defaults',
  'company',
  function (
    $uibModalInstance,
    $window,
    $timeout,
    $log,
    $scope,
    $translate,
    AdminApiService,
    USER_ROLES,
    AuthService,
    ExportUsersCSVFn,
    ErrorHandler,
    Session,
    csvMapping,
    defaults,
    company
  ) {
    $scope.emailRegex = $window.emailRegex;
    $scope.roleList = Object.values($scope.userRoles);
    $scope.filter = dashFilter;
    $scope.ui = {
      stage: 'upload',
      simulation: {
        isNewFilter: { isNew: true },
        isUpdateFilter: { isUpdate: true },
        isIgnoreFilter: { isIgnored: true },
      },
      modal: $uibModalInstance,
      detailView: {
        sort: 'email',
        filter: detailViewFilter,
      },
    };
    $scope.fileupload = {};
    $scope.users = [];
    $scope.errored = [];
    $scope.simulationSummary = {
      newUsersCount: '...',
      usersInDisabledTeamCount: '...',
    };
    $scope.exportUserListsCSV = exportUserListsCSV;
    $scope.cancel = cancel;
    $scope.isFixable = isFixable;
    $scope.isNotFixable = isNotFixable;
    $scope.reprocessErrored = reprocessErrored;
    $scope.removeErrored = removeErrored;
    $scope.removeWarning = removeWarning;
    $scope.process = process;
    $scope.acceptUpdate = acceptUpdate;
    $scope.resetUpdate = resetUpdate;
    $scope.isReadyToProceed = isReadyToProceed;
    $scope.isNothingToDo = isNothingToDo;
    $scope.sortDetailTableBy = sortDetailTableBy;
    $scope.resetDetailViewTab = resetDetailViewTab;
    $scope.isValidEmail = isValidEmail;
    $scope.teamSearch = teamSearch;
    $scope.closeDisableUsersWarningDiv = closeDisableUsersWarningDiv;
    $scope.fileExtensionValid = true;
    // need to expose in order to test properly
    $scope.simulateImpact = simulateImpact;

    const operationPayload = ($scope.operationPayload = {
      company: company?._id,
      simulate: false,
      update: false,
    });

    $scope.$watch('fileupload.csvFile', processFile);

    $scope.$watch('ui.stage', function (newValue, oldValue) {
      if (newValue === 'detailView') {
        $scope.simulationSummary.users = $scope.simulationSummary.users.filter((u) => !u.teamIsDisabled);
        $scope.discardedWarnings = $scope.warnings.filter(
          (entry) => !operationPayload.update && entry.error === 'CANT_UPDATE_COMPANY_ADMIN'
        );
        $scope.warnings = _.difference($scope.warnings, $scope.discardedWarnings);
      }

      if (newValue === 'summary' && oldValue === 'detailView') {
        $scope.warnings = _.uniqBy(
          [...$scope.warnings, $scope.discardedWarnings].filter((u) => !!u),
          'email'
        );
      }
    });

    function cancel() {
      $uibModalInstance.close(false);
    }

    function exportUserListsCSV(event) {
      if (event) {
        event.stopPropagation();
      }

      const query = {
        'properties._cid': company._id,
        roles: [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player],
      };

      return ExportUsersCSVFn(query);
    }

    function isValidEmail(email) {
      return email && $window.emailRegex.test(email);
    }

    function dashFilter(source, filter) {
      const signature = JSON.stringify(filter);

      if (!source.cache) {
        source.cache = {};
      }

      if (!source.cache[signature]) {
        source.cache[signature] = _.filter(source, filter);
      }
      return source.cache[signature];
    }

    function resetDetailViewTab() {
      $scope.ui.detailView.search = '';
      $scope.ui.detailView.page = 1;
    }

    function sortDetailTableBy(attribute) {
      const alreadySet = _.get($scope, 'ui.detailView.sort') === attribute;
      if (alreadySet) {
        attribute = `-${attribute}`;
      }
      _.set($scope, 'ui.detailView.sort', attribute);
    }

    function isNothingToDo() {
      if (operationPayload.update && $scope.simulationSummary.updatedUsersCount > 0) {
        return false;
      }

      return $scope.simulationSummary.newUsersCount <= 0;
    }

    function isReadyToProceed() {
      if (!$scope.simulationSummary) {
        return false;
      }

      if ($scope.simulationSummary.updatedUsersCount > 0) {
        if (!operationPayload.update && !acceptUpdate.ignore) {
          return false;
        }
      }

      return true;
    }

    function resetUpdate() {
      acceptUpdate(false);
      acceptUpdate.ignore = false;
      $scope.users.forEach((value) => {
        value.isIgnored = false;
      });
    }

    function acceptUpdate(accept) {
      if (accept) {
        if (!acceptUpdate.confirm) {
          acceptUpdate.confirm = true;

          return $timeout(function () {
            acceptUpdate.confirm = false;
          }, 4000);
        }

        acceptUpdate.confirm = false;
        operationPayload.update = true;
      } else {
        $scope.users.forEach((value) => {
          value.isIgnored = !value.isNew || value.isIgnored;
        });
        operationPayload.update = false;
        acceptUpdate.ignore = true;
      }
    }

    function simulateImpact() {
      if ($scope.ui.simulating) {
        return;
      }

      $scope.ui.stage = 'upload';
      $scope.ui.simulating = true;
      $scope.users = _.uniqBy(
        []
          .concat($scope.users, $scope.warnings)
          .filter((u) => !!u)
          .map((u) => ({
            ...u,
            email: u.email.toLowerCase(),
          })),
        'email'
      );

      const operationPayload = {
        company: company._id,
        simulate: true,
        update: true,
      };

      return AdminApiService.bulkManageUsers($scope.users, operationPayload)
        .then(function (summary) {
          summary.users.forEach((user) => {
            $log.debug('User has team(s)', user.team);
          });

          // non-fixable errors
          $scope.warnings = _.uniqBy(
            summary.users.filter((u) => u.errored && isNotFixable(u)),
            'email'
          );
          $scope.errored = _.uniqBy(
            summary.users.filter((u) => u.errored && isFixable(u)),
            'email'
          );
          $scope.users = _.differenceBy(summary.users, $scope.errored, 'email');
          $scope.users = _.differenceBy($scope.users, $scope.warnings, 'email');

          summary.updatedUsersCount = 0;
          summary.newUsersCount = 0;
          summary.reassignedUsersCount = 0;
          summary.usersInDisabledTeamCount = 0;

          $scope.users = $scope.users.map((u) => {
            u.teamIsDisabled = !!u.teamIsDisabled;
            u.isIgnored = u.teamIsDisabled;
            u.isNew = !u._id && !u.isIgnored;
            u.isUpdate = !u.isNew && !u.isIgnored;

            if (u.properties) {
              u.previousRole = u.properties?._previousRole || AuthService.mainRole(u.roles);
              u.isReassigned = u.properties?._previousTeam !== u.properties._tid;
              u.isRoleShift = u.previousRole !== u.role;
            }

            if (!u.teamIsDisabled) {
              summary.updatedUsersCount += u.isUpdate ? 1 : 0;
              summary.newUsersCount += u.isNew ? 1 : 0;
              summary.reassignedUsersCount += u.isReassigned ? 1 : 0;
            } else {
              summary.usersInDisabledTeamCount++;
            }

            return u;
          });

          $scope.simulationSummary = summary;
          $scope.ui.stage = 'summary';
          $scope.teamSearch.results = [...new Set([...summary.users.map((u) => u.team)])].map((t) => ({ name: t }));

          return summary;
        })
        .catch(function (err) {
          ErrorHandler.addHttpError($translate.instant('UNABLE_TO_APPLY_CHANGES'), err);
        })
        .finally(function () {
          $timeout(function () {
            $scope.ui.stage = 'summary';
            $scope.ui.simulating = false;
          }, 1000);
        });
    }

    function isFixable(entry) {
      return ['INVALID_TEAM', 'INVALID_ROLE', 'INVALID_EMAIL'].indexOf(entry.error) > -1;
    }

    function isNotFixable(entry) {
      return !isFixable(entry);
    }

    function process() {
      $scope.ui.processing = true;
      return AdminApiService.bulkManageUsers($scope.users, $scope.operationPayload)
        .then(function (_summary) {
          swal({
            type: 'success',
            title: $translate.instant('Admin.BulkInviteUsers.Modal.Messages.Success.title'),
            text: $translate.instant('Admin.BulkInviteUsers.Modal.Messages.Success.text'),
            confirmButtonText: $translate.instant('OK'),
          });
          $uibModalInstance.close(true);
        })
        .catch(function (err) {
          ErrorHandler.addHttpError($translate.instant('UNABLE_TO_APPLY_CHANGES'), err);
        })
        .finally(function () {
          $scope.ui.processing = false;
        });
    }

    function removeWarning(entry) {
      if (entry.error === 'CANT_UPDATE_COMPANY_ADMIN') {
        entry.role = USER_ROLES.companyAdmin;
        delete entry.error;
        delete entry.errored;

        $scope.users.push(entry);
        return simulateImpact();
      }

      _.remove($scope.warnings, entry);
    }

    function removeErrored(entry) {
      if (entry.errored) delete entry.errored;

      _.remove($scope.errored, entry);

      if ($scope.errored.length < 1) {
        simulateImpact();
      }
    }

    function reprocessErrored(entry) {
      let newEntry = _.merge({}, entry);
      newEntry.errored = false;
      newEntry = processEntry(newEntry, csvMapping);

      if (newEntry.errored) {
        return entry;
      }

      removeErrored(entry);
      $scope.users.push(newEntry);

      return newEntry;
    }

    function processEntry(entry, csvMapping) {
      const TAGS_INDEX = 3;
      const user = { ...defaults };
      let tags = [];

      if (Array.isArray(entry)) {
        csvMapping.forEach((attr, idx) => {
          if (entry[idx]) {
            user[attr] = entry[idx].trim();
          }
        });

        if (entry[TAGS_INDEX]) {
          tags = entry[TAGS_INDEX].split(';').filter((tag) => tag.trim() !== '');
        }
        user.properties = { tags: tags };

        return user;
      }

      return {
        ...entry,
      };
    }

    function teamSearch(text) {
      return AdminApiService.getTeamList({ _cid: company._id }, text, { size: 50 }, 'name').then(function (teams) {
        const listedTeams = teamSearch.results || [];
        const allTeams = listedTeams.concat(
          teams.data.filter((existing) => {
            if (!existing) {
              return false;
            }

            if (!existing.name) {
              return false;
            }

            return !_.find(listedTeams, function (listed) {
              if (!listed && listed.name) return false;

              return listed.name.toLowerCase().trim() === existing.name.toLowerCase().trim();
            });
          })
        );
        return (teamSearch.results = allTeams);
      });
    }

    function detailViewFilter(entry) {
      let search = $scope.ui.detailView.search;

      if (!search) {
        return true;
      }

      search = search.match(/[a-z0-9]+/gi).join('.*');
      const regexp = new RegExp(search, 'ig');
      const searchable = ['email', 'team', 'tags'];
      let match = false;

      searchable.forEach((attr) => {
        if (Array.isArray(entry[attr])) {
          if (_.find(entry[attr], regexp.test)) {
            match = true;
          }
        } else {
          if (regexp.test(entry[attr])) {
            match = true;
          }
        }
      });

      return match;
    }

    function isFileExtensionCorrect(file) {
      return file.name.split('.').pop().toLowerCase() === 'csv';
    }

    function processFile(file) {
      file = file || $scope?.fileupload?.csvFile?.name;

      if (!file) {
        return;
      }

      if (!isFileExtensionCorrect(file)) {
        $scope.fileExtensionValid = false;
        return;
      }

      $scope.fileExtensionValid = true;

      const reader = new $window.FileReader();
      reader.onloadend = loadHandler;
      reader.readAsText(file);

      function loadHandler(evt) {
        const content = evt.target.result;
        const users = [];
        Papa.parse(content, {
          comments: '#',
          step: (results, _parser) => {
            const result = results.data[0].trim().search(/^email$/i);
            if (result === -1) {
              const user = processEntry(results.data, csvMapping);

              if (user.email === Session.user.email) {
                return;
              }

              if (!user.email || user.email.trim() === '') {
                return;
              }

              users.push(user);
            }
          },
        });

        $scope.users = users;
        simulateImpact();
      }
    }

    function closeDisableUsersWarningDiv() {
      $('#disable-users-warning-section').hide();

      if (
        !$scope.simulationSummary.impacted &&
        !$scope.simulationSummary.newUsersCount &&
        !$scope.simulationSummary.reassignedUsersCount &&
        !$scope.simulationSummary.updatedUsersCount &&
        !$scope.simulationSummary.usersInDisabledTeamCount
      ) {
        $scope.ui.modal.close();
      }
    }
  },
]);
