import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).controller('TournamentNotLicensedController', [
  '$scope',
  '$interval',
  function ($scope, $interval) {
    $scope.Session = {
      user: {
        properties: {
          preferences: {
            leaderboard: {
              fields: {
                rank: true,
                points: true,
                accuracy: true,
              },
            },
          },
        },
      },
    };
    $scope.tournament = {
      name: 'Tournament',
      leaderboardOptions: {
        options: {
          isDisplayed: true,
          hideBeforeEnd: 0,
        },
      },
    };
    $scope.tournamentData = {
      developerTableData: [
        {
          currentRank: 0,
          points: 12000,
          profile: {
            displayPic: 'images/logo.png',
            name: { first: 'Jonathan', middle: '', last: 'Smith' },
          },
          language: { _id: 'java', _framework: 'spring' },
          displayLanguage: 'Java',
          displayFramework: 'Spring',
          displayAccuracy: '85',
          displayTimeSpent: $scope.utility.shortFormatDuration(1000000),
          displayOtherStats: [{ type: 'accuracy', isActive: true }, { type: 'timeSpent' }],
          stats: {
            quests: [
              {
                name: 'SQL injection',
                isActiveSlide: true,
                displayCompletePercent: 90,
              },
              {
                name: 'Cross-site scripting (XSS)',
                isActiveSlide: true,
                displayCompletePercent: 90,
              },
            ],
          },
        },
        {
          currentRank: 1,
          points: 7550,
          profile: {
            displayPic: 'images/logo.png',
            name: { first: 'Camilla', middle: '', last: 'Gardener' },
          },
          language: { _id: 'c_sharp', _framework: 'mvc' },
          displayLanguage: 'C#',
          displayFramework: 'MVC',
          displayAccuracy: '91',
          displayTimeSpent: $scope.utility.shortFormatDuration(1000000),
          displayOtherStats: [{ type: 'accuracy', isActive: true }, { type: 'timeSpent' }],
          stats: {
            quests: [
              {
                name: 'SQL injection',
                isActiveSlide: true,
                displayCompletePercent: 60,
              },
              {
                name: 'Cross-site scripting (XSS)',
                isActiveSlide: true,
                displayCompletePercent: 50,
              },
            ],
          },
        },
        {
          currentRank: 2,
          points: 3000,
          profile: {
            displayPic: 'images/logo.png',
            name: { first: 'Adam', middle: '', last: 'Pine' },
          },
          language: { _id: 'c_sharp', _framework: 'web_forms' },
          displayLanguage: 'C#',
          displayFramework: 'Web Forms',
          displayAccuracy: '57',
          displayTimeSpent: $scope.utility.shortFormatDuration(1000000),
          displayOtherStats: [{ type: 'accuracy', isActive: true }, { type: 'timeSpent' }],
          stats: {
            quests: [
              {
                name: 'SQL injection',
                isActiveSlide: true,
                displayCompletePercent: 10,
              },
              {
                name: 'Cross-site scripting (XSS)',
                isActiveSlide: true,
                displayCompletePercent: 20,
              },
            ],
          },
        },
      ],
    };

    $scope.isShowingLiveStats = true;
    // bullshit countdown timer because why the heck not
    $scope.isShowingCountdown = true;
    $scope.countdownString = '';
    $scope.countdownValue = 100;
    $interval(function () {
      $scope.countdownValue -= 1;
      if (!$scope.countdownValue) {
        $scope.countdownValue = 100;
      }

      $scope.countdownString = $scope.utility.longFormatDuration($scope.countdownValue * 1000);
    }, 1000);
  },
]);
