import angular from 'angular';
const MODULE = 'SCWChurnZeroMetrics';

export default MODULE;

angular.module(MODULE, []).constant('CHURNZERO_METRICS', {
  LEARNING: {
    USER: {
      ACCESSED: 'Learning Resource Accessed User',
    },
  },
  TOURNAMENT: {
    USER: {
      COMPLETED: 'Tournament Completed User',
    },
  },
});
