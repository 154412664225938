import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.service('CustomerPlanApi', [
  'HttpConfigService',
  '$http',
  '$window',
  'SessionId',
  function (HttpConfigService, $http, $window, SessionId) {
    const service = {};
    const sessionBasedCache = {};

    service.isAllowed = async function (resouceName) {
      const sessionId = SessionId.get();
      let constraintMap = sessionBasedCache[sessionId];
      if (!constraintMap) {
        const apiUrl = `${$window.SCW_ENV.ApiEndpoint}/customer-plan`;
        const result = await $http.get(apiUrl, HttpConfigService.getHttpConfigNoIFR());
        constraintMap = result.data;
        sessionBasedCache[sessionId] = constraintMap;
      }

      const allowed = constraintMap[resouceName];

      return allowed === undefined || allowed;
    };

    return service;
  },
]);
