import angular from 'angular';
import MODULE from './module';
import templateUrl from './search.base.table-filter.html';

angular.module(MODULE).directive('searchBaseTableFilter', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: true,
      link: function (scope, _element, attributes, _controllers) {
        scope.options = attributes.options;
        scope.$$model = { val: _.get(scope, attributes.model) };

        scope.$watch(
          '$$model',
          function (n, _o) {
            const curr = _.get(scope, attributes.model);
            if (n !== curr) _.set(scope, attributes.model, n.val);
          },
          true
        );

        scope.$watch(
          attributes.model,
          function (n, _o) {
            if (scope.$$model.val !== n) scope.$$model.val = n;
          },
          true
        );
      },
    };
  },
]);
