import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { DigitContainer, FlipCard, FlipCardInner, FlipCardFront, FlipCardBack } from './FlippingNumber.styled';
const FlipDigit = ({ value, isFirstChild }) => {
    const previousValue = useRef(value);
    const [flip, setFlip] = useState(false);
    useEffect(() => {
        if (value !== previousValue.current) {
            setFlip(true);
            const timeout = setTimeout(() => {
                previousValue.current = value;
                setFlip(false);
            }, 125);
            return () => clearTimeout(timeout);
        }
    }, [value]);
    return (_jsx(DigitContainer, { isFirstChild: isFirstChild, children: _jsx(FlipCard, { children: _jsxs(FlipCardInner, { flip: flip, children: [_jsx(FlipCardFront, { children: previousValue.current }), _jsx(FlipCardBack, { children: value })] }) }) }));
};
export default FlipDigit;
