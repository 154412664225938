var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { WandaSwitch } from '../../common/Switch/Switch';
export const OptimisticSwitch = (_a) => {
    var _b;
    var { onSave } = _a, props = __rest(_a, ["onSave"]);
    const [isChecked, setIsChecked] = useState((_b = props.checked) !== null && _b !== void 0 ? _b : false);
    const [switchColor, setSwitchColor] = useState('primary');
    useEffect(() => {
        if (props.checked !== undefined) {
            setIsChecked(props.checked);
        }
    }, [props.checked]);
    const handleChange = useCallback((event) => {
        const newChecked = event.target.checked;
        setIsChecked(newChecked);
        setSwitchColor('primary');
        onSave(newChecked)
            .then(() => {
            setSwitchColor('success');
        })
            .catch((error) => {
            console.error(`OptimisticSwitch: Failed to save`, error);
            setTimeout(() => {
                setIsChecked(!newChecked); // Revert the change
                setSwitchColor('error');
            }, 1000);
        })
            .finally(() => {
            // Reset color to primary after 2 seconds
            setTimeout(() => {
                setSwitchColor('primary');
            }, 2000);
        });
    }, [onSave]);
    return (_jsx(WandaSwitch, Object.assign({}, props, { sx: { gridArea: 'switch' }, checked: isChecked, disabled: props.disabled, onChange: handleChange, inputProps: props.inputProps, color: switchColor })));
};
