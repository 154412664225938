import angular from 'angular';
import MODULE from './module';
import templateUrl from './search.base.selection-count.html';

angular.module(MODULE).directive('searchBaseSelectionCount', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        selection: '=?selection',
        page: '=?page',
        clear: '&?clear',
      },
      link: function (_scope, _element, _attrs) {},
    };
  },
]);
