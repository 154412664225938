import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, Td, Th, Thead } from './ListStyled';
import { ConfirmationDialog, DangerButton } from '../../../common';
import { useState } from 'react';
export function List(props) {
    const { integrations, translator, onDeleteIntegration } = props;
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [integrationToDelete, setIntegrationToDelete] = useState({
        integrationId: '',
        tenantId: '',
    });
    const handleDeleteIntegration = (integrationId, tenantId) => {
        setIntegrationToDelete({ integrationId, tenantId });
        setConfirmationOpen(true);
    };
    const confirmDeleteIntegration = () => {
        onDeleteIntegration(integrationToDelete.integrationId, integrationToDelete.tenantId);
        setConfirmationOpen(false);
    };
    const cancelDeleteIntegration = () => {
        setConfirmationOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { className: "table-bordered", children: [_jsx(Thead, { children: _jsxs("tr", { children: [_jsx(Th, { children: translator('DISPLAY_NAME') }), _jsx(Th, { children: translator('INTEGRATION_ID') }), _jsx(Th, { children: translator('TENANT_ID') }), _jsx(Th, { children: translator('ACTION') })] }) }), _jsx("tbody", { children: integrations.map((integration, index) => (_jsxs("tr", { children: [_jsx(Td, { children: integration.displayName }), _jsx(Td, { children: integration.integrationId }), _jsx(Td, { children: integration.tenantId }), _jsx(Td, { children: _jsx(DangerButton, { onClick: () => handleDeleteIntegration(integration.integrationId, integration.tenantId), children: translator('DELETE_INTEGRATION') }) })] }, index))) })] }), confirmationOpen && (_jsx(ConfirmationDialog, { open: confirmationOpen, onCancel: cancelDeleteIntegration, onConfirm: confirmDeleteIntegration, dialogTitle: translator('CONFIRMATION_DELETE'), dialogText: translator('ARE_YOU_SURE_YOU_WANT_TO_DELETE_INTEGRATION'), cancelButtonLabel: translator('CANCEL'), confirmButtonLabel: translator('DELETE') }))] }));
}
