import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Skeleton } from '@securecodewarrior/design-system-react';
import { Box, Stack, Typography } from '@mui/material';
import { SearchInput } from '@securecodewarrior/design-system-react/lib/wanda';
import { useTranslation } from 'react-i18next';
import { IconBox } from './tabs/Tabs';
export const QuestSkeleton = () => {
    const { t } = useTranslation();
    return (_jsxs(Box, { mt: 5, children: [_jsx(TabSkeleton, {}), _jsx(Container, { sx: { py: 10 }, children: _jsxs(Stack, { gap: 6, minWidth: "600px", children: [_jsx(Stack, { maxWidth: "333px", direction: { sm: 'column', md: 'row' }, justifyContent: "space-between", gap: 2, children: _jsx(SearchInput, { fullWidth: true, placeholder: t('pages.quests.search'), search: '', setSearch: () => { }, onReset: () => { } }) }), _jsx(Chapter, {}), _jsx(Chapter, {})] }) })] }));
};
const Chapter = () => {
    return (_jsxs(Box, { py: 4, children: [_jsxs(Stack, { flexDirection: "row", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Box, { width: "70%", height: "80px", children: [_jsx(Typography, { variant: "overline", children: _jsx(Skeleton, { width: "10%" }) }), _jsx(Typography, { component: "h2", variant: "h3", children: _jsx(Skeleton, { width: "40%" }) })] }), _jsxs("svg", { width: "128", height: "19", viewBox: "0 0 128 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M51.15 18.57C53.95 18.57 56.22 16.3 56.22 13.51C56.22 10.72 53.95 8.45 51.15 8.45C48.35 8.45 46.08 10.72 46.08 13.51C46.08 16.3 48.35 18.57 51.15 18.57Z", fill: "#FFBE12" }), _jsx("path", { d: "M75.74 7.02C77.69 7.02 79.26 5.45 79.26 3.51C79.26 1.57 77.69 0 75.74 0C73.8 0 72.22 1.57 72.22 3.51C72.22 5.45 73.8 7.02 75.74 7.02Z", fill: "#7058F3" }), _jsx("path", { d: "M98.78 15.02C100.73 15.02 102.3 13.45 102.3 11.51C102.3 9.57 100.73 8 98.78 8C96.84 8 95.26 9.57 95.26 11.51C95.26 13.45 96.84 15.02 98.78 15.02Z", fill: "#FFBE12" }), _jsx("path", { d: "M122.94 13.15C125.51 13.15 127.58 11.07 127.58 8.51C127.58 5.95 125.51 3.87 122.94 3.87C120.38 3.87 118.3 5.95 118.3 8.51C118.3 11.07 120.38 13.15 122.94 13.15Z", fill: "#0BCCE2" }), _jsx("path", { d: "M3.52 13.02C5.46 13.02 7.04 11.45 7.04 9.51C7.04 7.57 5.46 6 3.52 6C1.58 6 0 7.57 0 9.51C0 11.45 1.58 13.02 3.52 13.02Z", fill: "#7058F3" }), _jsx("path", { d: "M26.56 10.02C28.5 10.02 30.08 8.45 30.08 6.51C30.08 4.57 28.5 3 26.56 3C24.62 3 23.04 4.57 23.04 6.51C23.04 8.45 24.62 10.02 26.56 10.02Z", fill: "#0BCCE2" })] })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Topic, {}), _jsx(Topic, {})] })] }));
};
const Topic = () => {
    return (_jsx(Box, { padding: 4, borderRadius: 3, bgcolor: (theme) => theme.palette.container.fill.card1, border: (theme) => `1px solid ${theme.palette.container.border.default}`, children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 4, pl: 3, children: [_jsx(Typography, { variant: "h2", component: "p", color: (theme) => theme.palette.text.soft, children: _jsx(Skeleton, { variant: "rectangular", width: "35px", height: "35px" }) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", component: "h3", children: _jsx(Skeleton, {}) }), _jsxs(Stack, { gap: 2, direction: "row", children: [_jsx(Skeleton, { width: "120px" }), _jsx(Skeleton, { width: "120px" })] })] })] }), _jsxs(Stack, { direction: "row", alignItems: 'center', gap: 2, children: [_jsx(Skeleton, { width: "40px" }), _jsx(Skeleton, { variant: "circular", width: "20px", height: "20px" })] })] }) }));
};
const TabSkeleton = () => {
    return (_jsxs(Stack, { maxHeight: "81px", paddingX: 10, borderBottom: (theme) => `1px solid ${theme.palette.container.border.default}`, flexDirection: "row", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(IconBox, { icon: _jsx(Skeleton, { variant: "circular", width: "35px", height: "35px" }), variant: 'selected' }), _jsxs(Box, { pl: 5, maxWidth: "500px", children: [_jsx(Typography, { variant: "caption", children: _jsx(Skeleton, { width: "100px" }) }), _jsx(Typography, { variant: "h2", component: "h3", children: _jsx(Skeleton, { width: "150px" }) })] })] }), _jsxs(Stack, { pl: 4, direction: "row", gap: 3, children: [_jsx(IconBox, { icon: _jsx(Skeleton, { variant: "circular", width: "35px", height: "35px" }) }), _jsx(IconBox, { icon: _jsx(Skeleton, { variant: "circular", width: "35px", height: "35px" }), variant: "languageSelection" })] })] }));
};
