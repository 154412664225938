var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import { Edit as MuiEditIcon } from '@mui/icons-material';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { CommunicationsSection as Section, WandaModal as Modal, WandaModalSection as ModalSection, } from '../Communications.styled';
import { RichTextForm } from '../../common/quill/RichTextForm';
import { Item } from './Item';
import { useApiClient } from '../../ApiContext';
import { communicationsAnalytics } from '../Communications.analytics';
import { usePlatformContext } from '../../platformContext';
import { DeleteMessagePrompt } from './DeleteMessagePrompt';
export const PlatformMessaging = ({ isLoading, showToaster }) => {
    const { t } = useTranslation();
    const { trpc, wrapErrorPromise } = useApiClient();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const [onboardingMessage, setOnboardingMessage] = useState('');
    const [ssoErrorMessage, setSsoErrorMessage] = useState('');
    const [modalState, setModalState] = useState({
        onboarding: false,
        ssoError: false,
    });
    const [dialogState, setDialogState] = useState({
        onboarding: false,
        ssoError: false,
    });
    const [switchColor, setSwitchColor] = useState({
        onboarding: 'primary',
        ssoError: 'primary',
    });
    const loadMessages = useCallback(() => {
        wrapErrorPromise(trpc.adminSettings.getCompanySettings
            .query()
            .then((resp) => {
            if (resp.kind === 'error') {
                throw new Error(t('pages.administration.communicationsCentre.error.loadCommunicationPreferences'));
            }
            setOnboardingMessage(resp.onboardingMessage);
            setSsoErrorMessage(resp.ssoErrorMessage);
        })
            .catch((e) => {
            console.error(e);
            showToaster(t('pages.administration.communicationsCentre.error.loadCommunicationPreferences'));
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadMessages();
    }, [loadMessages]);
    const handleOpen = (key) => setModalState(Object.assign(Object.assign({}, modalState), { [key]: true }));
    const handleClose = (key) => setModalState(Object.assign(Object.assign({}, modalState), { [key]: false }));
    const handleSaveMessage = (key, value) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield trpc.adminSettings.saveCompanySettings.mutate({
                onboardingMessage: key === 'onboarding' ? value : onboardingMessage,
                ssoErrorMessage: key === 'ssoError' ? value : ssoErrorMessage,
            });
            if (key === 'onboarding') {
                setOnboardingMessage(value);
                analytics.onboardingMessage.onSave();
                if (onboardingMessage === '' && value !== '') {
                    analytics.onboardingMessage.onToggle(true);
                }
                else {
                    analytics.onboardingMessage.onEdit();
                }
            }
            else {
                setSsoErrorMessage(value);
                analytics.ssoErrorMessage.onSave();
                if (ssoErrorMessage === '' && value !== '') {
                    analytics.ssoErrorMessage.onToggle(true);
                }
                else {
                    analytics.ssoErrorMessage.onEdit();
                }
            }
            handleClose(key);
            setSwitchColor((prev) => (Object.assign(Object.assign({}, prev), { [key]: 'success' })));
            // Reset switch color after 2 seconds
            setTimeout(() => {
                setSwitchColor((prev) => (Object.assign(Object.assign({}, prev), { [key]: 'primary' })));
            }, 2000);
        }
        catch (error) {
            console.error(`Failed to save ${key} message:`, error);
            throw new Error(t('pages.administration.communicationsCentre.error.saveCommunicationPreferences'));
        }
    });
    const handleOpenDialog = (key) => setDialogState(Object.assign(Object.assign({}, dialogState), { [key]: true }));
    const handleCloseDialog = (key) => setDialogState(Object.assign(Object.assign({}, dialogState), { [key]: false }));
    const handleDisableMessage = (key) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield handleSaveMessage(key, '');
            if (key === 'onboarding') {
                setOnboardingMessage('');
                analytics.onboardingMessage.onToggle(false);
            }
            else {
                setSsoErrorMessage('');
                analytics.ssoErrorMessage.onToggle(false);
            }
            handleCloseDialog(key);
        }
        catch (error) {
            console.error('Failed to disable message:', error);
        }
    });
    return (_jsxs(Section, { children: [_jsx(Typography, { variant: "h5", children: t('pages.administration.communicationsCentre.platformMessaging.title') }), _jsxs(Item, { title: t('pages.administration.communicationsCentre.platformMessaging.customOnBoardingMessage.title'), description: _jsx(Trans, { i18nKey: "pages.administration.communicationsCentre.platformMessaging.customOnBoardingMessage.caption", components: {
                        1: (_jsx(Link, { variant: "caption", href: "https://help.securecodewarrior.com/hc/en-us/articles/10290551079183-Configure-Company-Communications", target: "_blank", rel: "noopener noreferrer", children: "Click here" })),
                    } }), switchProps: {
                    disabled: isLoading,
                    checked: onboardingMessage !== '',
                    onChange: (e) => (e.target.checked ? handleOpen('onboarding') : handleOpenDialog('onboarding')),
                    inputProps: {
                        'aria-label': t('pages.administration.communicationsCentre.platformMessaging.customOnBoardingMessage.title'),
                    },
                    color: switchColor.onboarding,
                }, children: [_jsx(Button, { startIcon: _jsx(MuiEditIcon, {}), onClick: () => handleOpen('onboarding'), variant: "outlined", disabled: isLoading || !onboardingMessage, sx: { opacity: onboardingMessage ? 1 : 0 }, children: t('pages.administration.communicationsCentre.platformMessaging.customOnBoardingMessage.button') }), _jsx(Modal, { open: modalState.onboarding, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsx(RichTextForm, { onClose: () => handleClose('onboarding'), onSave: (value) => handleSaveMessage('onboarding', value), onCancel: () => handleClose('onboarding'), initialContent: onboardingMessage, children: _jsx(ModalSection, { className: "scw-section", children: _jsx(Typography, { id: "modal-modal-title", variant: "h3", component: "h3", children: t('pages.administration.communicationsCentre.platformMessaging.customOnBoardingMessage.modalTitle') }) }) }) })] }), _jsxs(Item, { title: t('pages.administration.communicationsCentre.platformMessaging.customSSOErrorMessage.title'), description: t('pages.administration.communicationsCentre.platformMessaging.customSSOErrorMessage.caption'), switchProps: {
                    disabled: isLoading,
                    checked: ssoErrorMessage !== '',
                    onChange: (e) => (e.target.checked ? handleOpen('ssoError') : handleOpenDialog('ssoError')),
                    inputProps: {
                        'aria-label': t('pages.administration.communicationsCentre.platformMessaging.customSSOErrorMessage.title'),
                    },
                    color: switchColor.ssoError,
                }, children: [_jsx(Button, { startIcon: _jsx(MuiEditIcon, {}), onClick: () => handleOpen('ssoError'), variant: "outlined", disabled: isLoading || !ssoErrorMessage, sx: { opacity: ssoErrorMessage ? 1 : 0 }, children: t('pages.administration.communicationsCentre.platformMessaging.customSSOErrorMessage.button') }), _jsx(Modal, { open: modalState.ssoError, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsx(RichTextForm, { onClose: () => handleClose('ssoError'), onSave: (value) => handleSaveMessage('ssoError', value), onCancel: () => handleClose('ssoError'), initialContent: ssoErrorMessage, type: "simple", children: _jsx(ModalSection, { className: "scw-section", children: _jsx(Typography, { id: "modal-modal-title", variant: "h3", component: "h3", children: t('pages.administration.communicationsCentre.platformMessaging.customSSOErrorMessage.modalTitle') }) }) }) })] }), _jsx(DeleteMessagePrompt, { open: dialogState.onboarding, onClose: () => handleCloseDialog('onboarding'), onConfirm: () => handleDisableMessage('onboarding'), messageKey: "onboarding" }), _jsx(DeleteMessagePrompt, { open: dialogState.ssoError, onClose: () => handleCloseDialog('ssoError'), onConfirm: () => handleDisableMessage('ssoError'), messageKey: "ssoError" })] }));
};
