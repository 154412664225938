import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MoreVert } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { DataLabel } from '../../molecules/DataLabel';
export const LearningProgressActive = ({ value }) => {
    const theme = useTheme();
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: "Learning Progress" }), _jsxs(Stack, { direction: "row", gap: 2, flexGrow: 1, justifyContent: "flex-end", flexShrink: 0, children: [_jsx(Button, { variant: "outlined", children: "View progress report" }), _jsx(Button, { centerIcon: _jsx(MoreVert, {}), variant: "outlined" })] })] }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: {
                                    value: value,
                                    type: 'percentage',
                                    animate: true,
                                    name: 'value',
                                    fill: theme.palette.graph.data.e2,
                                }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsxs(Typography, { textAlign: "center", children: [_jsxs("strong", { children: [value, "%"] }), " learners completed main Quest"] }) })] }), _jsxs(Stack, { gap: 8, children: [_jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: "Total assigned learners", value: "0" }), _jsx(DataLabel, { label: "Learners with objective assigned", value: "0" })] }), _jsx(Stack, { gap: 2, children: _jsx(DataLabel, { label: "Vulnerability objectives", value: "0" }) }), _jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: "Start date", value: "25 October 2023" }), _jsx(DataLabel, { label: "Due date", value: "13 August 2024" })] })] })] })] }));
};
