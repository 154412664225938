var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { InputAdornment, Typography } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
export const DatePicker = (_a) => {
    var { slotProps, errorMessage } = _a, props = __rest(_a, ["slotProps", "errorMessage"]);
    const theme = useTheme();
    return (_jsx(MuiDatePicker, Object.assign({ reduceAnimations: true, format: "DD MMMM YYYY", slots: { openPickerIcon: CalendarTodayIcon }, slotProps: Object.assign({ popper: {
                sx: {
                    '.MuiPaper-root, .MuiDialog-paper': {
                        backgroundColor: theme.palette.container.fill.card1,
                        borderRadius: theme.spacing(2),
                    },
                    '.MuiPaper-root': {
                        borderRadius: theme.spacing(2),
                        border: `1px solid ${theme.palette.clickable.default}`,
                        margin: theme.spacing(2, 0),
                        '& .MuiDateCalendar-root': {
                            minHeight: '346px',
                            maxHeight: '346px',
                        },
                        '.MuiPickersYear-root button': {
                            color: theme.palette.text.title,
                            '&.Mui-selected': {
                                color: theme.palette.clickable.button.text,
                                background: theme.palette.clickable.button.default,
                            },
                            '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):focus': {
                                background: theme.palette.clickable.button.hover,
                                color: theme.palette.clickable.button.text,
                            },
                        },
                    },
                    '.MuiPaper-root .MuiPickersCalendarHeader-switchViewIcon': {
                        color: theme.palette.clickable.default,
                    },
                    '.MuiPaper-root .MuiPickersArrowSwitcher-button': {
                        color: theme.palette.clickable.default,
                        fontSize: theme.spacing(7),
                        width: theme.spacing(7),
                        height: theme.spacing(7),
                        '&:last-of-type': {
                            marginLeft: theme.spacing(4),
                        },
                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        '&:focus': {
                            outline: `2px solid ${theme.palette.clickable.button.default}`,
                        },
                    },
                    '.MuiPaper-root .MuiDayCalendar-weekDayLabel': {
                        fontSize: theme.typography.pxToRem(14),
                        color: theme.palette.text.soft,
                    },
                    '.MuiPaper-root .MuiPickersCalendarHeader-labelContainer': {
                        border: `1px solid ${theme.palette.container.border.input}`,
                        padding: '5px 8px 5px 12px',
                        borderRadius: theme.spacing(2),
                        '&:focus-within': {
                            outline: `2px solid ${theme.palette.clickable.button.default}`,
                        },
                    },
                    '.MuiPaper-root .MuiPickersCalendarHeader-label': {
                        color: theme.palette.text.title,
                        fontWeight: 400,
                    },
                    '.MuiPaper-root .MuiPickersDay-root': {
                        fontSize: theme.typography.pxToRem(14),
                        color: theme.palette.text.title,
                        letterSpacing: theme.typography.pxToRem(0.25),
                        '&.Mui-disabled': {
                            color: theme.palette.container.border.input,
                        },
                        '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):focus': {
                            background: theme.palette.clickable.button.hover,
                            color: theme.palette.clickable.button.text,
                        },
                        '&.Mui-selected': {
                            color: theme.palette.clickable.button.text,
                            background: theme.palette.clickable.button.default,
                        },
                        '&.MuiPickersDay-today': {
                            border: 'none',
                            fontWeight: 700,
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: theme.spacing(1),
                                left: 'calc(50% - 1px)',
                                color: 'transparent',
                                background: theme.palette.text.title,
                                width: '2px',
                                height: '2px',
                                borderRadius: '50%',
                            },
                            '&.Mui-selected::after': {
                                background: theme.palette.clickable.button.text,
                            },
                        },
                    },
                    '.MuiPaper-root .MuiPickersCalendarHeader-root': {
                        paddingLeft: theme.spacing(4),
                        marginTop: theme.spacing(5),
                        marginBottom: theme.spacing(4),
                    },
                },
            }, dialog: {
                sx: {
                    '.MuiDialog-paper': {
                        backgroundColor: theme.palette.container.fill.card1,
                        borderRadius: theme.spacing(2),
                    },
                },
            }, openPickerIcon: { fontSize: 'inherit' }, openPickerButton: { color: 'primary', sx: { zIndex: 1 } }, textField: {
                variant: 'outlined',
                color: 'secondary',
                helperText: errorMessage,
                error: !!errorMessage,
                onKeyDown: (e) => {
                    var _a;
                    e.key === 'Enter' && ((_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e));
                },
                InputProps: {
                    sx: {
                        borderRadius: theme.spacing(2),
                        backgroundColor: theme.palette.container.fill.card1,
                        '& .MuiInputBase-input': {
                            height: theme.typography.pxToRem(3),
                            color: theme.palette.text.title,
                        },
                        ':hover:not(.Mui-disabled)': {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.badge.error.border,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.container.border.input,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.container.border.active,
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.clickable.default,
                        },
                        '& .MuiInputAdornment-root button:focus': {
                            outline: `2px solid ${theme.palette.clickable.button.default}`,
                            outlineOffset: '-4px',
                        },
                        '&.Mui-disabled': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.container.border.default,
                            },
                            svg: {
                                color: theme.palette.container.border.default,
                            },
                        },
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.container.fill.card2,
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.clickable.default,
                                borderWidth: '1px',
                            },
                        },
                    },
                    startAdornment: (_jsx(InputAdornment, { id: "labelId", position: "start", sx: {
                            pointerEvents: 'none',
                        }, children: _jsxs(Typography, { variant: "caption", children: [props.label, ":"] }) })),
                },
            } }, slotProps) }, props, { label: null })));
};
