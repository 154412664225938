import angular from 'angular';
import MODULE from './module';

(function (moduleName) {
  angular
    .module(moduleName)
    .filter('language', [
      '$rootScope',
      function ($rootScope) {
        return function (language) {
          var languages = $rootScope.metadata.languages;
          var _lang = (language && language._id) || (language && language._language) || language;
          if (!_lang) return '';

          return languages[_lang].name;
        };
      },
    ])

    .filter('framework', [
      '$rootScope',
      function ($rootScope) {
        return function (language) {
          var languages = $rootScope.metadata.languages;
          var _lang = (language && language._id) || (language && language._language) || language;
          var _fmwk = language && language._framework;
          if (!_lang || !_fmwk) return '';

          return languages[_lang].framework[_fmwk].name;
        };
      },
    ])

    .filter('languageName', [
      '$rootScope',
      function ($rootScope) {
        return function (language) {
          if (!language) {
            return '';
          }
          var languages = $rootScope.metadata.languages;
          var _lang = (language && language._id) || (language && language._language) || language;
          var _fmwk = language && language._framework;
          if (!_lang || !_fmwk) return '';

          return languages[_lang].name + ' ' + languages[_lang].framework[_fmwk].name;
        };
      },
    ]);
})(MODULE);
