import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).directive('ngAppLanguage', [
  '$rootScope',
  function ($rootScope) {
    return {
      link: function (_scope, element, _attrs) {
        $rootScope.$on('changeLanguage', (_event, eventData) => {
          element.attr('lang', eventData.lang || 'en');
        });
      },
    };
  },
]);
