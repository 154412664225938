import angular from 'angular';
import MODULE from './module';
import 'angular-ui-router';

import _ from 'lodash';

// templates
import loginModalTemplate from './login-modal.html';
import logoutModalTemplate from './logout-modal.html';
import signupModalTemplate from './signup-modal.html';
import containerTemplate from './container.html';
import indexTemplate from './index.html';
import statsTemplate from './stats.html';
import questTemplate from './microlearning-quest.html';
import { getProfileFromLocalStorage, getUtmSourceFromString } from './utils';
import './microlearning-controller';

const app = angular.module(MODULE);

function zoom() {}

/**
 * Extension of Game013StatsController to support stats display after game-mode in Microlearning.
 */
app.controller('MicrolearningStatsController', [
  '$scope',
  '$controller',
  '$state',
  '$stateParams',
  '$rootScope',
  'Session',
  '$uibModal',
  '$window',
  '$uibModalStack',
  function ($scope, $controller, $state, $stateParams, $rootScope, Session, $uibModal, $window, $uibModalStack) {
    angular.extend(this, $controller('Game013StatsController', { $scope: $scope }));

    $scope.playerStats = {};
    $scope.stateParams = $stateParams;
    $scope.newProfile = getProfileFromLocalStorage($window);
    $scope.playAgainStateParams = {
      app_type: $stateParams.app_type,
      category: $stateParams.category,
      subcategory: $stateParams.subcategory,
      _language: $stateParams.language,
      _framework: $stateParams.framework,
      _realm: 'contextual-microlearning',
      _level: getUtmSourceFromString($window.location.search).replaceAll('.', '_'),
      _quest: `${$stateParams.category}_${$stateParams.subcategory}`,
      _return: 'microlearning.play',
      _returnParams: angular.copy($stateParams),
    };

    function updateProfile(updatedProfile) {
      $scope.hasSetName = !!updatedProfile?.firstName?.trim();
      if (!$scope.hasSetName) {
        return;
      }
      $scope.firstName = updatedProfile?.firstName?.trim();
      if ($scope.developerTableData) {
        $scope.developerTableData[$scope.developerTableData.length - 1].name = updatedProfile.firstName;
      }
    }

    function getDetailsFromSession() {
      var profile = getProfileFromLocalStorage($window);
      $scope.registered = profile.registered || false;
      // Update profile name in session storage if session is not anonymous.
      if (!Session.isAnonymous()) {
        if (Session.user.properties.profile.isComplete) {
          profile.firstName = Session.user.properties.profile.name.first || null;
        } else {
          profile.firstName = null;
        }
        $window.localStorage.profile = JSON.stringify(profile);
        $scope.registered = true;
      }

      if (profile.firstName && profile.firstName.trim()) {
        return updateProfile(profile);
      }
      $scope.hasSetName = false;
    }

    $scope.showSignupModal = function () {
      $uibModal
        .open({
          templateUrl: signupModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          controller: 'ContextualMicroLearningSignupModalController',
          size: 'lg',
          resolve: {
            translations: {
              modalTitle: 'SIMPLE_FLOW_SIGNUP_MODAL_TITLE_AFTER_CHALLENGE',
              modalIntroText: 'SIMPLE_FLOW_SIGNUP_MODAL_INTRO_TEXT_AFTER_CHALLENGE',
            },
            sourcePage: function () {
              return 'register trial';
            },
          },
        })
        .result.then(function (updatedProfile) {
          $scope.newProfile = updatedProfile;
        })
        .catch(angular.noop);
    };

    $scope.showLoginModal = function () {
      clearAllModals();
      if (!Session.user) {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = $stateParams;
      }
      $uibModal
        .open({
          animation: true,
          ariaLabelledBy: 'loginModalTitle',
          ariaDescribedBy: 'loginModalBody',
          backdrop: 'static',
          controller: 'LoginController',
          size: 'md',
          templateUrl: loginModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          resolve: {
            translations: {},
          },
        })
        .result.then(getDetailsFromSession)
        .catch(function () {
          $scope.hasSetName = false;
        })
        .finally(function () {
          getDetailsFromSession();
        });
    };

    $scope.showLogoutModal = function () {
      clearAllModals();
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'logoutModalTitle',
        ariaDescribedBy: 'logoutModalBody',
        backdrop: 'static',
        controller: 'LoginController',
        size: 'md',
        templateUrl: logoutModalTemplate,
        windowClass: 'simple-flow-modal-z-index-hack',
        resolve: {
          translations: {},
        },
      });
    };

    zoom();

    function clearAllModals() {
      $uibModalStack.dismissAll();
    }

    $scope.$on('$destroy', function () {
      clearAllModals();
    });
  },
]);

/**
 * Offer more categories to play a teaser on Microlearning for a given language/framework.
 */
app.controller('MicrolearningBonusController', [
  '$scope',
  '$state',
  '$stateParams',
  '$log',
  'MicrolearningServices',
  'AuthService',
  '$window',
  '$uibModalStack',
  function ($scope, $state, $stateParams, $log, MicrolearningServices, AuthService, $window, $uibModalStack) {
    $scope.stateParams = $stateParams;
    $scope.categories = {};

    // Categories available for language/framework based on actual quest availability.
    var _app_type = $stateParams.app_type;
    var category = $stateParams.category;
    var subcategory = $stateParams.subcategory;
    var _language = $stateParams.language;
    var _framework = $stateParams.framework;

    MicrolearningServices.getAvailableQuestsInfo(_app_type, _language, _framework).then(function (availableQuests) {
      $log.debug('Available quests for lang/framework: ', availableQuests);

      var available = availableQuests.length;
      var random = Math.floor(Math.random() * (available - 1));
      var _quest = availableQuests[random];

      AuthService.resumeSession()
        .then(function () {
          $state.go(
            'microlearning-quest',
            {
              app_type: _app_type,
              category: category,
              subcategory: subcategory,
              _language: _language,
              _framework: _framework,
              _realm: 'contextual-microlearning',
              _level: getUtmSourceFromString($window.location.search).replaceAll('.', '_'),
              _quest: _quest,
              _return: 'microlearning.play',
              _returnParams: angular.copy($stateParams),
            },
            { reload: true }
          );
        })
        .catch(function (_e) {
          var onReady = AuthService.goAnonymous();
          onReady.then(function () {
            $state.go(
              'microlearning-quest',
              {
                app_type: _app_type,
                category: category,
                subcategory: subcategory,
                _language: _language,
                _framework: _framework,
                _realm: 'contextual-microlearning',
                _level: getUtmSourceFromString($window.location.search).replaceAll('.', '_'),
                _quest: _quest,
                _return: 'microlearning.play',
                _returnParams: angular.copy($stateParams),
              },
              { reload: true }
            );
          });
        });
    });

    function clearAllModals() {
      $uibModalStack.dismissAll();
    }

    $scope.$on('$destroy', function () {
      clearAllModals();
    });
  },
]);

/* --------------------------------------------------------------
 * BASIC SERVICES REQUIRED BY THE Microlearning MODULE
 * ----------------------------------------------------------- */
app.factory('MicrolearningServices', [
  '$log',
  '$http',
  '$window',
  'HttpConfigService',
  '$state',
  function ($log, $http, $window, HttpConfigService, $state) {
    const { SCW_ENV } = $window;
    var self = this || {};

    self.getCategoryInformation = function (type, category, subcategory) {
      var path = ['public', 'categories', type, category, subcategory];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      $log.debug('Category information endpoint is: ', endpoint);
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {});
    };

    self.getAvailableQuestsInfo = function (_type, language, framework) {
      var path = ['public', 'quests', language, framework];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {});
    };

    self.checkQuestAvailability = function ({ app_type, category, subcategory, language, framework }) {
      var path = ['public', 'quests', app_type, language, framework, category, subcategory];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      return $http.get(endpoint, HttpConfigService.getHttpConfig()).then(function (response) {
        return response.data;
      });
    };

    self.signup = function (data) {
      var path = ['public', 'signup'];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      $log.debug('Creating trial team through Microlearning: ', data);
      return $http
        .post(endpoint, data, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function (data) {
          return data;
        });
    };

    self.invalidApptypeCategoryOrSubcategory = function (app_type, category, subcategory, categories, source) {
      var withParams;
      switch (source) {
        case 'category': {
          if (!app_type && category) {
            $state.go('microlearning-picker.category', { appTypeError: true });
            return true;
          }
          break;
        }
        case 'subcategory': {
          if (!app_type) {
            $state.go('microlearning-picker.category', { appTypeError: true });
            return true;
          }
          if (!category) {
            withParams = {
              app_type: app_type,
              categorySubcategoryError: true,
            };
            $state.go('microlearning-picker.app-type', withParams);
            return true;
          }
          break;
        }
        case 'language': {
          if (!app_type) {
            $state.go('microlearning-picker.category', { appTypeError: true });
            return true;
          }
          if (!category) {
            withParams = {
              app_type: app_type,
              categorySubcategoryError: true,
            };
            $state.go('microlearning-picker.app-type', withParams);
            return true;
          }
          if (!subcategory) {
            // re-route to ssubcategory page
            withParams = {
              app_type: app_type,
              category: category,
              categorySubcategoryError: true,
            };
            $state.go('microlearning-picker.subcategory', withParams);
            return true;
          }
          break;
        }
      }

      if (!app_type) return false; // blanks are ok
      if (!categories.hasOwnProperty(app_type)) {
        // re-route to picker
        withParams = { appTypeError: true };
        $state.go('microlearning-picker.category', withParams);
        return true;
      }
      // is the category invalid?
      if (!category) return false; // blanks are ok
      if (!categories[app_type].hasOwnProperty(category)) {
        // re-route to app type
        withParams = {
          app_type: app_type,
          categorySubcategoryError: true,
        };
        $state.go('microlearning-picker.app-type', withParams);
        return true;
      }
      // Is the subcategory invalid?
      if (!subcategory) return false; // blanks are ok
      if (!categories[app_type][category].subitem.hasOwnProperty(subcategory)) {
        // re-route to ssubcategory page
        withParams = {
          app_type: app_type,
          category: category,
          categorySubcategoryError: true,
        };
        $state.go('microlearning-picker.subcategory', withParams);
        return true;
      }
      return false;
    };

    // Legacy code, not for Microlearning
    self.invalidLanguage = function (app_type, category, subcategory, _language, metaLanguages) {
      if (!_language || Object.keys(metaLanguages).includes(_language)) {
        return false;
      }
      var withParams = {
        app_type: app_type,
        category: category,
        subcategory: subcategory,
        invalidLangError: true,
      };
      $state.go('microlearning.languages', withParams);
      return true;
    };

    return self;
  },
]);

app.controller('ContextualMicroLearningSignupModalController', [
  '$scope',
  '$uibModalInstance',
  '$translate',
  'translations',
  'MicrolearningServices',
  '$window',
  '$state',
  '$stateParams',
  '$rootScope',
  '$uibModal',
  'AnalyticsService',
  'sourcePage',
  'AnalyticsEvents',
  'Session',
  function (
    $scope,
    $uibModalInstance,
    $translate,
    translations,
    MicrolearningServices,
    $window,
    $state,
    $stateParams,
    $rootScope,
    $uibModal,
    AnalyticsService,
    sourcePage,
    AnalyticsEvents,
    Session
  ) {
    $translate([
      'JOB_ROLE_STUDENT',
      'JOB_ROLE_DEVELOPER_SOFTWARE_ENGINEER',
      'JOB_ROLE_APPSEC_ENGINEER',
      'JOB_ROLE_APPSEC_MANAGER_DIRECTOR',
      'JOB_ROLE_SECURITY_ARCHITECT',
      'JOB_ROLE_DEVELOPMENT_MANAGER',
      'JOB_ROLE_SECURITY_AWARENESS',
      'JOB_ROLE_HR_LEARNING_DEVELOPMENT',
      'JOB_ROLE_RISK_COMPLIANCE',
      'JOB_ROLE_C_SUITE',
      'JOB_ROLE_OTHER',
    ]).then(function (translations) {
      $scope.availableJobRoles = [
        translations.JOB_ROLE_STUDENT,
        translations.JOB_ROLE_DEVELOPER_SOFTWARE_ENGINEER,
        translations.JOB_ROLE_APPSEC_ENGINEER,
        translations.JOB_ROLE_APPSEC_MANAGER_DIRECTOR,
        translations.JOB_ROLE_SECURITY_ARCHITECT,
        translations.JOB_ROLE_DEVELOPMENT_MANAGER,
        translations.JOB_ROLE_SECURITY_AWARENESS,
        translations.JOB_ROLE_HR_LEARNING_DEVELOPMENT,
        translations.JOB_ROLE_RISK_COMPLIANCE,
        translations.JOB_ROLE_C_SUITE,
        translations.JOB_ROLE_OTHER,
      ];
    });

    $translate(['SECURE_CODING_SKILLS', 'REGULATORY_COMPLIANCE', 'RECURRING_VULNERABILITIES', 'JOB_RELEVANT']).then(
      function (translations) {
        $scope.availablePurchasingDrivers = [
          translations.SECURE_CODING_SKILLS,
          translations.REGULATORY_COMPLIANCE,
          translations.RECURRING_VULNERABILITIES,
          translations.JOB_RELEVANT,
        ];
      }
    );

    $scope.availableCountries = _.map($rootScope.metadata.countries.all, function (country) {
      return country.name;
    }).sort();
    $scope.countryKeyMap = {};

    Object.keys(_.get($rootScope, 'metadata.countries.all')).forEach(function (key) {
      var countryName = _.get($rootScope, 'metadata.countries.all.' + key + '.name');
      if (countryName) {
        $scope.countryKeyMap[countryName] = { code: key };
      }
    });

    function validateUpdatedProfile(updatedProfile) {
      return (
        $scope.simpleFlowSignupModal.$invalid ||
        !updatedProfile.email ||
        !updatedProfile.company ||
        !updatedProfile.firstName ||
        !updatedProfile.lastName
      );
    }

    $scope.profile = getProfileFromLocalStorage($window);
    $scope.profile.subscriptionOptIn = 'no';
    $scope.translations = translations;
    $scope.save = function (updatedProfile) {
      if ($scope.simpleFlowSignupModal && validateUpdatedProfile(updatedProfile)) {
        $scope.hasSubmittedForm = true;
        return;
      }
      var profileFromLocalStorage = getProfileFromLocalStorage($window);

      updatedProfile.submitted = true;
      updatedProfile = _.merge(profileFromLocalStorage, updatedProfile);
      $window.localStorage.profile = JSON.stringify(updatedProfile);

      if (!updatedProfile.email) {
        return $uibModalInstance.close(updatedProfile);
      }

      if (!updatedProfile.company || updatedProfile.company.length < 4) {
        updatedProfile.company = 'team 1';
      }

      var profileForSignup = {
        firstName: updatedProfile.firstName,
        lastName: updatedProfile.lastName,
        teamName: updatedProfile.company,
        jobRole: updatedProfile.jobRole,
        language: {
          _id: $stateParams.language || $stateParams._language,
          _framework: $stateParams.framework || $stateParams._framework,
        },
        email: updatedProfile.email,
        location: updatedProfile.country,
        phone: updatedProfile.phoneNumber,
        purchasingDriver: 'Deprecated - Purchasing Driver from Trial/ML layout has changed',
        subscriptionOptIn: updatedProfile.subscriptionOptIn,
      };
      MicrolearningServices.signup(profileForSignup)
        .then(function (_result) {
          swal({
            type: 'success',
            title: $translate.instant('SimpleFlow.Signup.Messages.Success.title'),
            text: $translate.instant('SimpleFlow.Signup.Messages.Success.text'),
            confirmButtonText: $translate.instant('OK'),
          });
          AnalyticsService.logEvent(AnalyticsEvents.PlayNow.SIGN_UP, { sign_up_page: sourcePage });
        })
        .catch(function (error) {
          swal({
            type: 'error',
            title: 'Error',
            text: error.data.error,
            confirmButtonText: $translate.instant('OK'),
          });
        });
      $uibModalInstance.close(updatedProfile);
    };

    function updateProfile(updatedProfile) {
      $scope.hasSetName = !!updatedProfile?.firstName?.trim();
      if (!$scope.hasSetName) {
        return;
      }
      $scope.firstName = updatedProfile?.firstName?.trim();
      if ($scope.developerTableData) {
        $scope.developerTableData[$scope.developerTableData.length - 1].name = updatedProfile.firstName;
      }
    }

    function getDetailsFromSession() {
      var profile = getProfileFromLocalStorage($window);
      $scope.registered = profile.registered || false;
      // Update profile name in session storage if session is not anonymous.
      if (!Session.isAnonymous()) {
        if (Session.user.properties.profile.isComplete) {
          profile.firstName = Session.user.properties.profile.name.first || null;
        } else {
          profile.firstName = null;
        }
        $window.localStorage.profile = JSON.stringify(profile);
        $scope.registered = true;
      }

      if (profile.firstName && profile.firstName.trim()) {
        return updateProfile(profile);
      }
      $scope.hasSetName = false;
    }

    $scope.showLoginModal = function () {
      $scope.cancel();
      if (!Session.user) {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = $stateParams;
      }
      $uibModal
        .open({
          animation: true,
          ariaLabelledBy: 'loginModalTitle',
          ariaDescribedBy: 'loginModalBody',
          backdrop: 'static',
          controller: 'LoginController',
          size: 'md',
          templateUrl: loginModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          resolve: {
            translations: {},
          },
        })
        .result.then(getDetailsFromSession)
        .catch(function () {
          $scope.hasSetName = false;
        })
        .finally(function () {
          getDetailsFromSession();
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.close();
    };
  },
]);

app.controller('MicrolearningQuestController', [
  '$window',
  '$state',
  '$scope',
  'Game013Api',
  '$translate',
  'AnalyticsService',
  'AnalyticsEvents',
  function ($window, $state, $scope, Game013Api, $translate, AnalyticsService, AnalyticsEvents) {
    $window._partnerQuest = $scope;

    $scope.playmode = 'microlearning';
    $scope.quest = null;
    $scope.loading = true;

    $state.params = {
      ...$state.params,
      language: $state.params._language,
      framework: $state.params._framework,
    };

    $scope.beginChallenge = beginChallenge;
    $scope.nextChallenge = nextChallenge;
    $scope.skipChallenge = skipChallenge;

    this.$onInit = function () {
      $scope.loading = true;

      Game013Api.getChallenge($state.params)
        .then((response) => {
          $scope.quest = response;
          $scope.loading = false;
        })
        .catch(handleError);
    };

    function beginChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.BEGIN_CHALLENGE);
    }

    function nextChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.COMPLETE_CHALLENGE);
      return $state.go('microlearning.done', $state.params);
    }

    function skipChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.SKIP_CHALLENGE);
      $scope.loading = true;
      Game013Api.skipChallenge($state.params, $scope.challenge).then(reload).catch(handleError);
    }

    function reload() {
      return $state.go($state.current.name, $state.params, { reload: true });
    }

    function back(reload) {
      $state.go('microlearning-picker', {}, { reload });
    }

    function sendAnalyticsEvent(eventName) {
      const eventProps = {
        challenge_id: $scope.quest?.challenge?._id,
        portal_area: $scope.playmode,
        language_framework: `${$state.params?._language}:${$state.params?._framework}`,
      };
      AnalyticsService.logEvent(eventName, eventProps);
    }

    function handleError(response) {
      $scope.error = {
        status: response.status || '',
        message: `${$translate.instant('ERROR_RETRIEVING_CHALLENGE')}: ${response.data.error}`,
        onReload: reload,
        onBack: back,
      };
    }
  },
]);
/* --------------------------------------------------------------
 * UI ROUTER CONFIGURATION
 * ----------------------------------------------------------- */
app.config([
  '$stateProvider',
  '$urlMatcherFactoryProvider',
  '$urlRouterProvider',
  function ($stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
    $urlMatcherFactoryProvider.strictMode(false);

    // Ref: https://ui-router.github.io/ng1/docs/0.4.2/#/api/ui.router.state.$stateProvider - state(name, stateConfig)
    // Ref: https://github.com/angular-ui/ui-router/wiki/URL-Routing#query-parameters
    $stateProvider.state('microlearning-picker', {
      url: '/contextual-microlearning?app_type&category&subcategory&language&framework&login',
      params: { finished: false },
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
      resolve: {
        metadata: [
          '$rootScope',
          '$translate',
          function ($rootScope, $translate) {
            return $rootScope.getLocaleMetadata($translate.use());
          },
        ],
      },
    });
    $stateProvider.state('microlearning-app-type-only', {
      url: '/contextual-microlearning/:app_type?login',
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
      params: { finished: false },
    });

    $stateProvider.state('microlearning-category-only', {
      url: '/contextual-microlearning/:app_type/:category?login',
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
      params: { finished: false },
    });

    // BEHOLD!
    // ... the wall of redirects
    //#region $urlRouterProvider.when(*, '/contextual-microlearning'...)
    $urlRouterProvider.when('/simple-flow', '/contextual-microlearning');
    $urlRouterProvider.when('/partner-trial', '/contextual-microlearning');
    $urlRouterProvider.when('/website-trial', '/contextual-microlearning');

    $urlRouterProvider.when('/simple-flow/:app_type', '/contextual-microlearning/:app_type');
    $urlRouterProvider.when('/partner-trial/:app_type', '/contextual-microlearning/:app_type');
    $urlRouterProvider.when('/website-trial/:app_type', '/contextual-microlearning/:app_type');

    $urlRouterProvider.when('/simple-flow/:app_type/:category', '/contextual-microlearning/:app_type/:category');
    $urlRouterProvider.when('/partner-trial/:app_type/:category', '/contextual-microlearning/:app_type/:category');
    $urlRouterProvider.when('/website-trial/:app_type/:category', '/contextual-microlearning/:app_type/:category');

    $urlRouterProvider.when(
      '/simple-flow/:app_type/:category/:subcategory',
      '/contextual-microlearning/:app_type/:category/:subcategory'
    );
    $urlRouterProvider.when(
      '/partner-trial/:app_type/:category/:subcategory',
      '/contextual-microlearning/:app_type/:category/:subcategory'
    );
    $urlRouterProvider.when(
      '/website-trial/:app_type/:category/:subcategory',
      '/contextual-microlearning/:app_type/:category/:subcategory'
    );

    $urlRouterProvider.when(
      '/simple-flow/:app_type/:category/:subcategory/:language',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language'
    );
    $urlRouterProvider.when(
      '/partner-trial/:app_type/:category/:subcategory/:language',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language'
    );
    $urlRouterProvider.when(
      '/website-trial/:app_type/:category/:subcategory/:language',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language'
    );

    $urlRouterProvider.when(
      '/simple-flow/:app_type/:category/:subcategory/:language/:framework',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language/:framework'
    );
    $urlRouterProvider.when(
      '/partner-trial/:app_type/:category/:subcategory/:language/:framework',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language/:framework'
    );
    $urlRouterProvider.when(
      '/website-trial/:app_type/:category/:subcategory/:language/:framework',
      '/contextual-microlearning/:app_type/:category/:subcategory/:language/:framework'
    );

    $urlRouterProvider.when(
      '/simple-flow/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest',
      '/contextual-microlearning/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest'
    );
    $urlRouterProvider.when(
      '/partner-trial/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest',
      '/contextual-microlearning/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest'
    );
    $urlRouterProvider.when(
      '/website-trial/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest',
      '/contextual-microlearning/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest'
    );
    //#endregion

    $stateProvider.state('microlearning', {
      url: '/contextual-microlearning/:app_type/:category/:subcategory?login',
      abstract: true,
      templateUrl: containerTemplate,
      params: { finished: false },
    });
    $stateProvider.state('microlearning.auto-choose-language', {
      url: '',
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
    });

    $stateProvider.state('microlearning.flow', {
      url: '/:language/:framework',
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
      resolve: {
        isQuestAvailable: [
          '$stateParams',
          'MicrolearningServices',
          async function ($stateParams, MicrolearningServices) {
            await MicrolearningServices.checkQuestAvailability({ ...$stateParams })
              .then(function (data) {
                var resolution = (data && data.available) || false;
                $stateParams.isQuestAvailable = resolution;
                return resolution;
              })
              .catch(() => {});
          },
        ],
        metadata: [
          '$rootScope',
          '$translate',
          function ($rootScope, $translate) {
            return $rootScope.getLocaleMetadata($translate.use());
          },
        ],
      },
    });
    $stateProvider.state('microlearning.play', {
      url: '/:language/:framework/play',
      controller: 'MicrolearningController',
      templateUrl: indexTemplate,
      params: { finished: false },
      resolve: {
        isQuestAvailable: [
          '$stateParams',
          'MicrolearningServices',
          async function ($stateParams, MicrolearningServices) {
            await MicrolearningServices.checkQuestAvailability({ ...$stateParams })
              .then(function (data) {
                var resolution = (data && data.available) || false;
                $stateParams.isQuestAvailable = resolution;
                return resolution;
              })
              .catch(() => {});
          },
        ],
      },
    });
    $stateProvider.state('microlearning.done', {
      url: '/:language/:framework/done',
      controller: 'MicrolearningStatsController',
      templateUrl: statsTemplate,
      resolve: {
        _auth: [
          'AuthService',
          'Session',
          function (AuthService, Session) {
            if (Session.user) return Session.user;
            return AuthService.resumeSession();
          },
        ],
      },
    });
    $stateProvider.state('microlearning.bonus', {
      url: '/:language/:framework/bonus',
      controller: 'MicrolearningBonusController',
    });
    // Microlearning-quest does not follow abstract state Microlearning to prevent use of container template
    $stateProvider.state('microlearning-quest', {
      url: '/contextual-microlearning/:app_type/:category/:subcategory/:_language/:_framework/realm/:_realm/level/:_level/quest/:_quest',
      controller: 'MicrolearningQuestController',
      templateUrl: questTemplate,
      params: { _return: false, onQuestCompletion: false },
      data: {},
    });
  },
]);
