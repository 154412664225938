import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import { Chart } from 'chart.js';

const app = angular.module(MODULE);

import challengeMetrics from './challenge-metrics.html';

// GameApiService swapped out for Game013Api
app.controller('Game013StatsController', [
  '$state',
  '$stateParams',
  '$rootScope',
  '$scope',
  '$translate',
  '$back',
  '$swal',
  'ErrorHandler',
  'USER_ROLES',
  'Game013Api',
  'AuthService',
  'Session',
  'Game013StateService',
  '$log',
  'AnalyticsService',
  'AnalyticsEvents',
  function (
    $state,
    $stateParams,
    $rootScope,
    $scope,
    $translate,
    $back,
    $swal,
    ErrorHandler,
    USER_ROLES,
    Game013Api,
    AuthService,
    Session,
    Game013StateService,
    $log,
    AnalyticsService,
    AnalyticsEvents
  ) {
    $translate([
      'POINTS',
      'ACCURACY',
      'CHALLENGES_ATTEMPTED',
      'HOURS_PLAYED',
      'NO_REQUIREMENTS',
      'BEGINNER',
      'SECURITY_AWARE',
      'SECURITY_SKILLED',
      'SECURITY_CHAMPION',
    ]).then(function (xlations) {
      var MATURITY_LEVEL_RULES = ($scope.MATURITY_LEVEL_RULES = {
        champion: {
          timeSpent: 0,
          points: 12000,
          challenges: { attempted: 60, ratioCorrect: 0.9 },
        },
        skilled: {
          timeSpent: 0,
          points: 8000,
          challenges: { attempted: 40, ratioCorrect: 0.6 },
        },
        aware: {
          timeSpent: 0,
          points: 4000,
          challenges: { attempted: 20, ratioCorrect: 0.2 },
        },
        beginner: {
          timeSpent: 0,
          points: 0,
          challenges: { attempted: 0, ratioCorrect: 0 },
        },
      });
      var MATURITY_LEVEL_REQS_HTML = {
        champion:
          '<ul><li>' +
          xlations.POINTS +
          ': ' +
          MATURITY_LEVEL_RULES.champion.points +
          '</li><li>' +
          xlations.ACCURACY +
          ': ' +
          MATURITY_LEVEL_RULES.champion.challenges.ratioCorrect * 100 +
          '%</li><li>' +
          xlations.CHALLENGES_ATTEMPTED +
          ': ' +
          MATURITY_LEVEL_RULES.champion.challenges.attempted +
          '</li></ul>',
        skilled:
          '<ul><li>' +
          xlations.POINTS +
          ': ' +
          MATURITY_LEVEL_RULES.skilled.points +
          '</li><li>' +
          xlations.ACCURACY +
          ': ' +
          MATURITY_LEVEL_RULES.skilled.challenges.ratioCorrect * 100 +
          '%</li><li>' +
          xlations.CHALLENGES_ATTEMPTED +
          ': ' +
          MATURITY_LEVEL_RULES.skilled.challenges.attempted +
          '</li></ul>',
        aware:
          '<ul><li>' +
          xlations.POINTS +
          ': ' +
          MATURITY_LEVEL_RULES.aware.points +
          '</li><li>' +
          xlations.ACCURACY +
          ': ' +
          MATURITY_LEVEL_RULES.aware.challenges.ratioCorrect * 100 +
          '%</li><li>' +
          xlations.CHALLENGES_ATTEMPTED +
          ': ' +
          MATURITY_LEVEL_RULES.aware.challenges.attempted +
          '</li></ul>',
        beginner: '<ul><li>' + xlations.NO_REQUIREMENTS + '</li></ul>',
      };

      $scope.USER_ROLES = USER_ROLES;
      $scope.moment = moment;
      $scope.forms = {};
      $scope.languageMap = {}; // combined map of historic languages from metrics and active/available languages
      $scope.removeSecurityMaturityTimespent = true;

      var SECURITY_MATURITY_TEXT = {};

      SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id] = xlations.BEGINNER;
      SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.AWARE.id] =
        xlations.SECURITY_AWARE;
      SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.SKILLED.id] =
        xlations.SECURITY_SKILLED;
      SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id] =
        xlations.SECURITY_CHAMPION;

      var PROGRESS_STATS_CAROUSEL = [
        {
          id: $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id,
          name: SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id],
          points: { required: MATURITY_LEVEL_RULES.beginner.points },
          accuracy: { required: MATURITY_LEVEL_RULES.beginner.challenges.ratioCorrect * 100 },
          attempted: { required: MATURITY_LEVEL_RULES.beginner.challenges.attempted },
        },
        {
          id: $scope.metadata.constants.metrics.security_maturity.level.AWARE.id,
          name: SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.AWARE.id],
          points: { required: MATURITY_LEVEL_RULES.aware.points },
          accuracy: { required: MATURITY_LEVEL_RULES.aware.challenges.ratioCorrect * 100 },
          attempted: { required: MATURITY_LEVEL_RULES.aware.challenges.attempted },
        },
        {
          id: $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id,
          name: SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.SKILLED.id],
          points: { required: MATURITY_LEVEL_RULES.skilled.points },
          accuracy: { required: MATURITY_LEVEL_RULES.skilled.challenges.ratioCorrect * 100 },
          attempted: { required: MATURITY_LEVEL_RULES.skilled.challenges.attempted },
        },
        {
          id: $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id,
          name: SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id],
          points: { required: MATURITY_LEVEL_RULES.champion.points },
          accuracy: { required: MATURITY_LEVEL_RULES.champion.challenges.ratioCorrect * 100 },
          attempted: { required: MATURITY_LEVEL_RULES.champion.challenges.attempted },
        },
      ];

      $scope.comparisonSeries = ['You'];
      $scope.userId = $stateParams.userId;
      $scope.encodeURIComponent = encodeURIComponent; // Since angular can't access globals

      if (Session.user) {
        $scope.publicProfileURL = window.location.origin + '/#/stats/' + Session.user._id;
        Session.user.properties.profile.isMetricsPublic = Session.user.properties.profile.isMetricsPublic || false;

        $scope.challengeMetricsTempl = challengeMetrics;
      }

      $scope.goBack = function () {
        return $back().or('admin', {}).go();
      };

      $scope.updatePlayerStats = function (statsData) {
        $scope.playerStats = statsData;

        if ($scope.playerStats.languages) {
          $scope.updateLanguageList($scope.playerStats.languages);
        }

        $scope.playerStats.points = $scope.playerStats.points || 0;

        if ($scope.playerStats.strengthsAndWeaknesses) {
          var rawSwData = $scope.playerStats.strengthsAndWeaknesses;

          // calculate real attempts since server number is unreliable
          rawSwData.auth.challenges.realAttempts =
            rawSwData.auth.challenges.correct + rawSwData.auth.challenges.incorrect;
          rawSwData.misconfig.challenges.realAttempts =
            rawSwData.misconfig.challenges.correct + rawSwData.misconfig.challenges.incorrect;
          rawSwData.practices.challenges.realAttempts =
            rawSwData.practices.challenges.correct + rawSwData.practices.challenges.incorrect;
          rawSwData.sensitive_data.challenges.realAttempts =
            rawSwData.sensitive_data.challenges.correct + rawSwData.sensitive_data.challenges.incorrect;
          rawSwData.data_handling.challenges.realAttempts =
            rawSwData.data_handling.challenges.correct + rawSwData.data_handling.challenges.incorrect;

          $scope.swData = [
            [
              Math.round((rawSwData.auth.challenges.correct / rawSwData.auth.challenges.realAttempts) * 100) || 0,
              Math.round(
                (rawSwData.misconfig.challenges.correct / rawSwData.misconfig.challenges.realAttempts) * 100
              ) || 0,
              Math.round(
                (rawSwData.practices.challenges.correct / rawSwData.practices.challenges.realAttempts) * 100
              ) || 0,
              Math.round(
                (rawSwData.sensitive_data.challenges.correct / rawSwData.sensitive_data.challenges.realAttempts) * 100
              ) || 0,
              Math.round(
                (rawSwData.data_handling.challenges.correct / rawSwData.data_handling.challenges.realAttempts) * 100
              ) || 0,
            ],
          ];

          $scope.swLegendCorrectData = [
            rawSwData.auth.challenges.correct,
            rawSwData.misconfig.challenges.correct,
            rawSwData.practices.challenges.correct,
            rawSwData.sensitive_data.challenges.correct,
            rawSwData.data_handling.challenges.correct,
          ];

          $scope.swLegendInCorrectData = [
            rawSwData.auth.challenges.incorrect,
            rawSwData.misconfig.challenges.incorrect,
            rawSwData.practices.challenges.incorrect,
            rawSwData.sensitive_data.challenges.incorrect,
            rawSwData.data_handling.challenges.incorrect,
          ];

          var maxValue = 1;
          var x;

          for (x = 0; x < $scope.swLegendCorrectData.length; x++) {
            if ($scope.swLegendCorrectData[x] > maxValue) maxValue = $scope.swLegendCorrectData[x];
            if ($scope.swLegendInCorrectData[x] > maxValue) maxValue = $scope.swLegendInCorrectData[x];
          }

          for (x = 0; x < $scope.swLegendCorrectData.length; x++) {
            $scope.swLegendCorrectData[x] = ($scope.swLegendCorrectData[x] / maxValue) * 30; // using static 1000 for now, 76px high is 100% so divide by 2 for correct/incorrect and we leave 1px always on for each, then subtract a few px to make it look nicer
            $scope.swLegendInCorrectData[x] = ($scope.swLegendInCorrectData[x] / maxValue) * 30;
          }

          $('#auth_column_correct').height($scope.swLegendCorrectData[0] + 1);
          $('#auth_column_incorrect').height($scope.swLegendInCorrectData[0] + 1);
          $('#misconfig_column_correct').height($scope.swLegendCorrectData[1] + 1);
          $('#misconfig_column_incorrect').height($scope.swLegendInCorrectData[1] + 1);
          $('#practices_column_correct').height($scope.swLegendCorrectData[2] + 1);
          $('#practices_column_incorrect').height($scope.swLegendInCorrectData[2] + 1);
          $('#sensitivedata_column_correct').height($scope.swLegendCorrectData[3] + 1);
          $('#sensitivedata_column_incorrect').height($scope.swLegendInCorrectData[3] + 1);
          $('#datahandling_column_correct').height($scope.swLegendCorrectData[4] + 1);
          $('#datahandling_column_incorrect').height($scope.swLegendInCorrectData[4] + 1);
        } else {
          // placeholder data for empty chart
          $scope.swData = [[0, 0, 0, 0, 0]];
          $scope.swLegendCorrectData = [0, 0, 0, 0, 0];
          $scope.swLegendInCorrectData = [0, 0, 0, 0, 0];
          $('#auth_column_correct').height(1);
          $('#auth_column_incorrect').height(1);
          $('#misconfig_column_correct').height(1);
          $('#misconfig_column_incorrect').height(1);
          $('#practices_column_correct').height(1);
          $('#practices_column_incorrect').height(1);
          $('#sensitivedata_column_correct').height(1);
          $('#sensitivedata_column_incorrect').height(1);
          $('#datahandling_column_correct').height(1);
          $('#datahandling_column_incorrect').height(1);
        }

        if ($scope.playerStats.timeSpent) {
          var mTimeSpent = moment.duration($scope.playerStats.timeSpent.total, 'milliseconds');
          $scope.totalHoursData = [Math.floor(mTimeSpent.as('hours'))];
          $scope.totalHoursPercentData = (mTimeSpent.as('hours') / 12) * 100; // using 12 hours as max for now - requirement to get to Security Champion
          $scope.fullTotalHoursDisplay = $rootScope.utility.longFormatDuration($scope.playerStats.timeSpent.total);
        } else {
          $scope.totalHoursData = [0];
        }

        if ($scope.playerStats.challenges) {
          $scope.challengesCorrect = $scope.playerStats.challenges.correct;
          $scope.challengesFailed = $scope.playerStats.challenges.incorrect;
          $scope.challengesAttempted = $scope.challengesCorrect + $scope.challengesFailed; // $scope.playerStats.challenges.attempted;
          $scope.challengesAccuracy = Math.round(($scope.challengesCorrect / $scope.challengesAttempted) * 100) || 0;
        }

        if ($scope.playerStats.hints) {
          $scope.hintsUsed = $scope.playerStats.hints.used;
          $scope.hintsTotal = $scope.playerStats.hints.total;
          $scope.confidenceLevel = Math.round(($scope.hintsUsed / $scope.hintsTotal) * 100) || 0;
        }

        if ($scope.playerStats.attempts) {
          $scope.attemptsTotal = $scope.playerStats.attempts.correct + $scope.playerStats.attempts.incorrect;
          $scope.attemptsAccuracyPercent =
            $scope.attemptsTotal > 0
              ? Math.round(($scope.playerStats.attempts.correct / $scope.attemptsTotal) * 100)
              : 0;
        }

        $scope.currentRank = null;
        $scope.rankChange = null;
        if ($scope.playerStats.leaderboard.rank != -1) {
          $scope.currentRank = $scope.playerStats.leaderboard.rank + 1;
          $scope.previousRank = $scope.playerStats.leaderboard.previousRank;
          if ($scope.previousRank && $scope.previousRank != -1) {
            $scope.rankChange = $scope.previousRank - $scope.currentRank; // angular templating deals with this being NaN
          } else {
            $scope.rankChange = 'New';
          }
        }

        if ($scope.userId) {
          $scope.playerDetails = $scope.playerStats;

          // PORTAL-1038 - null for invited users that have not accepted the invite
          if ($scope.playerDetails && $scope.playerDetails.registered) {
            $scope.playerDetails.registeredDisplay = moment($scope.playerDetails.registered).format('LL');
          }
        }

        if ($scope.playerStats.securityMaturity) {
          if (
            $scope.playerStats.securityMaturity.maturityLevel ==
            $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
          ) {
            $scope.playerStats.maturityText = SECURITY_MATURITY_TEXT[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.maturityReqsHtml =
              MATURITY_LEVEL_REQS_HTML[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.securityMaturity.maturityLevelNext =
              $scope.metadata.constants.metrics.security_maturity.level.AWARE.id;
          } else if (
            $scope.playerStats.securityMaturity.maturityLevel ==
            $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
          ) {
            $scope.playerStats.maturityText = SECURITY_MATURITY_TEXT[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.maturityReqsHtml =
              MATURITY_LEVEL_REQS_HTML[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.securityMaturity.maturityLevelNext =
              $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id;
          } else if (
            $scope.playerStats.securityMaturity.maturityLevel ==
            $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
          ) {
            $scope.playerStats.maturityText = SECURITY_MATURITY_TEXT[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.maturityReqsHtml =
              MATURITY_LEVEL_REQS_HTML[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.securityMaturity.maturityLevelNext =
              $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id;
          } else if (
            $scope.playerStats.securityMaturity.maturityLevel ==
            $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
          ) {
            $scope.playerStats.maturityText = SECURITY_MATURITY_TEXT[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.maturityReqsHtml =
              MATURITY_LEVEL_REQS_HTML[$scope.playerStats.securityMaturity.maturityLevel];
            $scope.playerStats.securityMaturity.maturityLevelNext = null;
          }

          if ($scope.playerStats.securityMaturity.maturityLevelNext) {
            $scope.playerStats.securityMaturity.progressStats = JSON.parse(JSON.stringify(PROGRESS_STATS_CAROUSEL));

            for (var i = 0; i < $scope.playerStats.securityMaturity.progressStats.length; i++) {
              var progressStat = $scope.playerStats.securityMaturity.progressStats[i];
              progressStat.points.current = $scope.playerStats.points;
              progressStat.points.percentage = Math.floor(
                (progressStat.points.current / progressStat.points.required) * 100
              );
              progressStat.points.percentage =
                progressStat.points.percentage > 100 ? 100 : progressStat.points.percentage;

              if (!$scope.removeSecurityMaturityTimespent) {
                progressStat.time.current = $scope.totalHoursData[0];
                progressStat.time.currentDisplay = $scope.fullTotalHoursDisplay || $scope.totalHoursData[0];
                progressStat.time.percentage = Math.floor(
                  (progressStat.time.current / progressStat.time.required) * 100
                );
                progressStat.time.percentage = progressStat.time.percentage > 100 ? 100 : progressStat.time.percentage;
              }

              progressStat.accuracy.current = $scope.challengesAccuracy;
              progressStat.accuracy.percentage = Math.floor(
                (progressStat.accuracy.current / progressStat.accuracy.required) * 100
              );
              progressStat.accuracy.percentage =
                progressStat.accuracy.percentage > 100 ? 100 : progressStat.accuracy.percentage;

              progressStat.attempted.current = $scope.playerStats.challenges.correct;
              progressStat.attempted.percentage = Math.floor(
                (progressStat.attempted.current / progressStat.attempted.required) * 100
              );
              progressStat.attempted.percentage =
                progressStat.attempted.percentage > 100 ? 100 : progressStat.attempted.percentage;
            }

            $scope.progressStatIndex = Object.keys(MATURITY_LEVEL_RULES)
              .reverse()
              .indexOf($scope.playerStats.securityMaturity.maturityLevelNext);
          }
        } else {
          $scope.playerStats.maturityText =
            SECURITY_MATURITY_TEXT[$scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id];
        }

        $scope.addToLinkedInProfileUrl = $scope.encodeLinkedInAddToProfileUrl(moment());
      };

      $scope.encodeLinkedInAddToProfileUrl = function (certDate) {
        // see https://addtoprofile.linkedin.com/ for documentation
        const scwLinkedInOrgId = '4852706';
        const certName = encodeURIComponent('Secure Code Warrior - ' + $scope.playerStats.maturityText);
        const certUrl = encodeURIComponent($scope.publicProfileURL);
        const issueYear = certDate.format('YYYY');
        const issueMonth = certDate.format('MM');
        const expirationMoment = moment(certDate).add({ month: 3 });
        const expirationYear = expirationMoment.format('YYYY');
        const expirationMonth = expirationMoment.format('MM');

        return (
          `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME` +
          `&organizationId=${scwLinkedInOrgId}` +
          `&name=${certName}&certUrl=${certUrl}` +
          `&issueYear=${issueYear}&issueMonth=${issueMonth}` +
          `&expirationYear=${expirationYear}&expirationMonth=${expirationMonth}`
        );
      };

      $scope.progressCarouselPrev = function () {
        $scope.progressStatIndex--;
        if ($scope.progressStatIndex < 0) {
          $scope.progressStatIndex = 0;
        }
      };

      $scope.progressCarouselNext = function () {
        $scope.progressStatIndex++;
        if ($scope.progressStatIndex >= $scope.playerStats.securityMaturity.progressStats.length) {
          $scope.progressStatIndex = $scope.playerStats.securityMaturity.progressStats.length - 1;
        }
      };

      $scope.updateIsMetricsPublic = function () {
        $scope.disableButton('updateIsMetricsPublic');
        AuthService.addLoading();
        //PORTAL - 1181 - Added email address in request body to match the schema
        var postData = {
          email: Session.user.email,
          profile: Session.user.properties.profile,
        };
        AuthService.updateProfile(postData)
          .catch(function (response) {
            ErrorHandler.addHttpError('Error updating metrics public state', response);
          })
          .finally(function () {
            AuthService.removeLoading();
            $scope.enableButton('updateIsMetricsPublic');
          });
      };

      // if public stats requested (user ID provided)
      if ($scope.userId) {
        Game013Api.getPublicLanguagePlayerMetrics($scope.userId, { _id: 'all', _framework: 'all' })
          .then(function (data) {
            $scope.populateLanguageList(data.languages);

            // set up watch on selected language to refresh stats on change
            $scope.$watch('forms.selectedLanguageFramework', function (_val) {
              Game013Api.getPublicLanguagePlayerMetrics($scope.userId, $scope.forms.selectedLanguageFramework.language)
                .then($scope.updatePlayerStats)
                .catch(function (response) {
                  $translate([
                    'USER_HAS_NOT_PUBLISHED_THEIR_PLAYER_STATISTICS',
                    'ERROR_RETRIEVING_PLAYER_STATISTICS',
                  ]).then(function (translations) {
                    if (response.status == 403) {
                      ErrorHandler.addError(translations.USER_HAS_NOT_PUBLISHED_THEIR_PLAYER_STATISTICS);
                    } else {
                      ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_PLAYER_STATISTICS, response);
                    }
                  });
                  // placeholder data for empty chart
                  $scope.swData = [[0, 0, 0, 0, 0]];
                  $scope.currentRank = null;
                });
            });

            // default to global
            var defaultIndex = 0;
            $scope.forms.selectedLanguageFramework = $scope.languageList[defaultIndex];
          })
          .catch(function (response) {
            $translate(['ERROR_RETRIEVING_PLAYER_STATISTICS', 'USER_HAS_NOT_PUBLISHED_THEIR_PLAYER_STATISTICS']).then(
              function (translations) {
                if (response.status == 403) {
                  ErrorHandler.addError(translations.USER_HAS_NOT_PUBLISHED_THEIR_PLAYER_STATISTICS);
                } else {
                  ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_PLAYER_STATISTICS, response);
                }
                // placeholder data for empty chart
                $scope.swData = [[0, 0, 0, 0, 0]];
                $scope.currentRank = null;
              }
            );
          });
      }
      // else requesting My Stats
      else {
        if (!$scope.$gameStatusPromise)
          //::
          $scope.$gameStatusPromise = Game013Api.getGameStatus().then(function (data) {
            $scope.gameData = data;
          });

        Game013Api.getGameStatus().then(function (gameData) {
          var active =
            gameData.activeIndex > -1 && gameData.languages[gameData.activeIndex]
              ? gameData.languages[gameData.activeIndex].active
              : null;
          if (!Session.isAnonymous()) {
            if (!active) {
              $swal({
                type: 'warning',
                title: $translate.instant('MyStats.Errors.noActiveLanguage.title'),
                text: $translate.instant('MyStats.Errors.noActiveLanguage.text'),
                keyResolution: true,
                confirmButtonText: $translate.instant('OK'),
              });

              return $state.go('game.013.languages', { flow: 'bounce', source: 'training my statistics' });
            }

            Game013StateService.setActiveState(active);

            $scope.Game013StateService = Game013StateService;
            var _activeLanguage = Game013StateService.active._language;
            var _activeFramework = Game013StateService.active._framework;

            $scope.populateLanguageList(gameData.languages);

            // set up watch on selected language to refresh stats on change
            $scope.$watch('forms.selectedLanguageFramework', function (_val) {
              var ctx = JSON.parse(JSON.stringify(Game013StateService.active));
              ctx._language = $scope.forms.selectedLanguageFramework.language._id || 'all';
              ctx._framework = $scope.forms.selectedLanguageFramework.language._framework || 'all';
              Game013Api.getLanguagePlayerMetrics(ctx)
                .then($scope.updatePlayerStats)
                .catch(function (response) {
                  $translate(['ERROR_RETRIEVING_PLAYER_STATISTICS']).then(function (translations) {
                    ErrorHandler.addHttpError(translations.ERROR_RETRIEVING_PLAYER_STATISTICS, response);
                  });
                  // placeholder data for empty chart
                  $scope.swData = [[0, 0, 0, 0, 0]];
                  $scope.currentRank = null;
                });
            });

            // try to find active language and select it, default to global otherwise
            // default to user active language
            $log.debug('Searching for active language index', _activeLanguage, _activeFramework);
            var defaultIndex =
              _.findIndex($scope.languageList, { language: { _id: _activeLanguage, _framework: _activeFramework } }) ||
              0;
            var activeLanguage = $scope.languageList[defaultIndex];
            if (defaultIndex > 0) {
              // don't mark "all" as active.
              activeLanguage.isActive = true;
            }
            $scope.forms.selectedLanguageFramework = activeLanguage;
          }
        });
      }

      $scope.populateLanguageList = function (languageData) {
        // populate language list
        var languageList = JSON.parse(JSON.stringify(languageData));
        var filteredLanguageList = [];
        for (var i = 0; i < languageList.length; i++) {
          var language = languageList[i];
          if (
            $scope.metadata.languages[language.language._id] &&
            (language.status == $scope.metadata.constants.language.status.ACTIVE ||
              language.status == $scope.metadata.constants.language.status.AVAILABLE)
          ) {
            language.displayLanguage = $scope.metadata.languages[language.language._id].name;
            language.displayFramework =
              $scope.metadata.languages[language.language._id].framework[language.language._framework].name;
            filteredLanguageList.push(language);
            $scope.languageMap[language.language._id + language.language._framework] = true;
          }
        }

        filteredLanguageList.unshift({
          displayLanguage: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
          displayFramework: '',
          language: {
            _id: 'all',
            _framework: 'all',
          },
        });

        $scope.languageList = filteredLanguageList;
      };

      $scope.updateLanguageList = function (languageData) {
        // update language list - data structure is different to gameData.languages
        var languageList = languageData;
        for (var i = 0; i < languageList.length; i++) {
          var language = languageList[i];
          language.language = {
            _id: language._id, // making data consistent with data in gameData.languages
            _framework: language._framework,
          };
          if ($scope.metadata.languages[language.language._id]) {
            if ($scope.languageMap[language.language._id + language.language._framework]) {
              continue;
            } else {
              $scope.languageMap[language.language._id + language.language._framework] = true;
              language.displayLanguage = $scope.metadata.languages[language.language._id].name;
              language.displayFramework =
                $scope.metadata.languages[language.language._id].framework[language.language._framework].name;
              $scope.languageList.push(language);
            }
          }
        }

        $scope.languageList.sort(function (a, b) {
          if (a.language._id == 'all') {
            return -1; // always put all:all at the top
          }
          var aConcatLanguageFramework = a.language._id + a.language._framework;
          var bConcatLanguageFramework = b.language._id + b.language._framework;
          if (aConcatLanguageFramework < bConcatLanguageFramework) {
            return -1;
          }
          if (aConcatLanguageFramework > bConcatLanguageFramework) {
            return 1;
          }
          return 0;
        });
      };

      $scope.logLinkedinClick = function (playerStats) {
        var amplitudeEventData = {
          language_framework_selected_for_sharing: `${$scope.forms.selectedLanguageFramework.language._id}:${$scope.forms.selectedLanguageFramework.language._framework}`,
          current_maturity_level: playerStats.maturityText,
        };
        AnalyticsService.logEvent(AnalyticsEvents.Training.SHARE_STATS_ON_LINKEDIN, amplitudeEventData);
      };

      /*** strength weakness chart ***/
      $translate([
        'AUTH_ACCESS_CONTROL',
        'SECURITY_CONFIGURATION',
        'SECURE_DEVELOPMENT_PRACTICES',
        'SENSITIVE_DATA_PROT',
        'DATA_HANDLING',
      ]).then(function (translations) {
        $scope.swLabels = [
          translations.AUTH_ACCESS_CONTROL,
          translations.SECURITY_CONFIGURATION,
          translations.SECURE_DEVELOPMENT_PRACTICES,
          translations.SENSITIVE_DATA_PROT,
          translations.DATA_HANDLING,
        ];
      });
      //$scope.swData = [[7,3,3,8,7],[6,7,4,3,3]];
      $scope.swData = null;

      $scope.chartJSRadarDataset = [
        {
          borderColor: 'rgba(255,183,0,0.7)',
          backgroundColor: 'rgba(255,183,0,0.5)',
          pointBorderColor: 'rgba(255,183,0,0.9)',
          pointBackgroundColor: 'rgba(255,183,0,0.9)',
        },
        {
          borderColor: 'rgba(255,255,255,0.3)',
          backgroundColor: 'rgba(255,255,255,0.2)',
          pointBorderColor: 'rgba(255,255,255,0.5)',
          pointBackgroundColor: 'rgba(255,255,255,0.5)',
        },
      ];
      $scope.swColours = ['rgba(255,183,0,0.9)'];
      $scope.swOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
          ticks: {
            min: 0,
            max: 100,
            display: false,
          },
          pointLabels: {
            fontColor: '#AAAAAA',
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel;
              label += '%';
              return label;
            },
          },
        },
      };

      /*** easy pie charts ***/

      $scope.easyPieTimeOptions = {
        animate: {
          duration: 500,
          enabled: true,
        },
        barColor: '#ffb700',
        trackColor: 'rgba(255,255,255,0.3)',
        scaleColor: '#FFF',
        lineWidth: 10,
        lineCap: 'circle',
        size: 104,
      };

      $scope.easyPiePointsOptions = {
        animate: {
          duration: 500,
          enabled: true,
        },
        barColor: '#ffb700',
        trackColor: 'rgba(255,255,255,0.3)',
        scaleColor: false,
        lineWidth: 10,
        lineCap: 'circle',
        size: 90,
      };

      $scope.totalHoursData = 0;
      $scope.totalHoursPercentData = 0;
      $scope.challengesCorrect = 0;
      $scope.challengesAttempted = 0;
      $scope.challengesAccuracy = 0;
      $scope.challengesFailed = 0;
      $scope.hintsUsed = 0;
      $scope.hintsTotal = 0;

      Chart.defaults.global.scaleLineColor = '#666';
      Chart.defaults.global.scaleFontColor = '#ccc';
    });
  },
]);
