import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('$back', [
  '$rootScope',
  '$state',
  '$stateParams',
  function ($rootScope, $state, $stateParams) {
    return $back;

    function $back() {
      var prepared = prepare();
      return {
        or: _or,
        prepared: prepared,
        with: _with,
        asFunction: asFunction,
        go: _go,
      };
    }

    function asFunction() {
      var $self = this;
      return function () {
        $self.go();
      };
    }

    function _or(defaultState, defaultParams, options) {
      this.prepared = prepare(defaultState, defaultParams, options);
      return this;
    }

    function _go() {
      return $state.go(this.prepared.name, this.prepared.params, this.prepared.options);
    }

    function _with(params, options) {
      _.merge(this.prepared.params, params);
      _.merge(this.prepared.options, options);
      return this;
    }

    function prepare(defaultState, defaultParams, options) {
      options = _.merge({ reload: true }, options);

      var previousStateName =
        $stateParams.from || ($rootScope.previousState && $rootScope.previousState.name) || defaultState; //"game.013.play.level";

      var previousStateParams =
        ($stateParams._return && $stateParams._returnParams) ||
        ($rootScope.previousState && $rootScope.previousState.params) ||
        defaultParams; //Game013StateService.active;

      // $state.go(previousStateName, previousStateParams, {reload: true});
      return {
        name: previousStateName,
        params: previousStateParams,
        options: options,
      };
    }
  },
]);
