import angular from 'angular';
import MODULE from './module';

// templates
import editTemplate from './admin.teams.edit.html';
import advancedOptionsTemplate from './admin.teams.view.advance-options.html';

angular.module(MODULE).controller('ManagementAdminTeamController', [
  '$uibModal',
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$log',
  '$translate',
  '$window',
  '$back',
  '$location',
  'AdminApiService',
  'USER_ROLES',
  'ErrorHandler',
  '$swal',
  'AnalyticsService',
  'AdminBulkReInviteUsers',
  function (
    $uibModal,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $log,
    $translate,
    $window,
    $back,
    $location,
    AdminApiService,
    USER_ROLES,
    ErrorHandler,
    $swal,
    AnalyticsService,
    AdminBulkReInviteUsers
  ) {
    $('.management-css').prop('disabled', false);

    // @tmp
    $window._team = $scope;

    $scope.userRoles = USER_ROLES;
    $scope.isGlobalStandalone = !$stateParams.teamId;
    $scope.selfRegisterURL = $window.location.origin + '/#/register';
    var companyId = $stateParams.companyId;
    $scope.timestamp = Date.parse(new Date());

    // Begin: exposed methods
    $scope.companyId = companyId;
    $scope.regenInviteToken = regenInviteToken;
    $scope.editTeam = editTeam;
    $scope.init = init;
    $scope.reload = init;
    $scope.goBack = goBack;
    $scope.showAdvancedOptions = showAdvancedOptions;
    $scope.getCompany = getCompany;
    $scope.bulkReInviteTeam = bulkReInviteTeam;
    $scope.anonymise = false;
    $scope.isMetricsEnabled = $rootScope.isTrainingAllowedByCustomerPlan;
    $scope.isActivitySummaryEnabled =
      $rootScope.isTrainingAllowedByCustomerPlan && $rootScope.isAssessmentAllowedByCustomerPlan;

    $scope.tabs = {
      0: 'Team managers',
      1: 'Developers',
    };
    // End: exposed methods

    // Begin: load pre-fetch state
    if ($stateParams.team) setTeam($stateParams.team);
    // End: load pre-fetch state

    function getCompany(companyId) {
      return AdminApiService.getCompany(companyId).then(setCompany);
    }

    function goBack() {
      if ($scope.team._cid) {
        return $state.go('admin.company', {
          companyId: $scope.team._cid,
        });
      }
      return $back().or('admin.companies', {}).go();
    }

    function setCompany(company) {
      $scope.company = company;
    }

    function displayMemberJoinToken(memberJoinToken) {
      return memberJoinToken;
    }

    function setTeam(val) {
      $scope.team = $scope.editedTeam = val;
      // if non-SSO created team.
      if ($scope.team && !$scope.team.ssoTeamId)
        $scope.displayMemberJoinToken = displayMemberJoinToken($scope.team.memberJoinToken);

      // if comes from previous state, no need to reload.
      if (!$scope.company && val._cid) {
        AdminApiService.getCompany(val._cid).then(function (company) {
          $scope.company = company;
        });
      }
      if (val._cid) {
        AdminApiService.getAnonymisation(val._cid).then(function ({ anonymise }) {
          $scope.anonymise = !!anonymise;
        });
      }
    }

    function getTeam(teamId) {
      AdminApiService.addLoading();
      if (teamId) {
        AdminApiService.getTeam(teamId)
          .then(setTeam)
          .catch(function (response) {
            ErrorHandler.addHttpError($translate.instant('FAILED_TO_FETCH_TEAM_DATA'), response);
          })
          .finally(function () {
            AdminApiService.removeLoading();
          });
      }
    }

    function init() {
      var teamId = ($scope.teamId = $stateParams.teamId || null);

      if (
        !teamId &&
        $scope.isAuthorized(USER_ROLES.manager) &&
        !$scope.isAuthorized([USER_ROLES.companyAdmin, USER_ROLES.reseller, USER_ROLES.admin])
      ) {
        if ($scope.Session.user.properties.team && $scope.Session.user.properties.team._id) {
          $state.go('admin.team', { teamId: $scope.Session.user.properties.team._id });
          return;
        } else {
          $translate(['USER_TEAM_DATA_IS_INVALID']).then(function (translations) {
            ErrorHandler.addError(translations.USER_TEAM_DATA_IS_INVALID);
          });
          return;
        }
      }

      // Emit admin:users-updated which in turn calls reload for all directives listening on admin:users-updated - PORTAL-2938
      $scope.$emit('admin:users-updated');

      getTeam(teamId);

      $scope.$watch('$$team.ui.selectedTab', function (newTab) {
        AnalyticsService.logPageViewEvent($location.absUrl(), $scope.tabs[newTab], 'tab');
      });
    }

    function editTeam() {
      var teamId = ($scope.teamId = $stateParams.teamId || null);
      AdminApiService.getTeam(teamId)
        .then(function (team) {
          $scope.team = team;
        })
        .then(function () {
          return $uibModal.open({
            templateUrl: editTemplate,
            controller: 'EditTeamModalController',
            size: 'lg',
            scope: $scope,
            backdrop: 'static',
            resolve: {
              company: function () {
                return $scope.company;
              },
              team: function () {
                return $scope.team;
              },
            },
          }).result;
        })
        .then(function (saved) {
          setTeam(saved);
        })
        .catch(function (team) {
          if ($scope.team.apiKeyDisplay != team.apiKeyDisplay) setTeam(team);
        });
    }

    function regenInviteToken() {
      $translate(
        [
          'CANCEL',
          'REGENERATE',
          'REGENERATE_INVITE_TOKEN',
          'USERS_NOTIFY_UNABLE_TO_USE_OLD_TOKEN',
          'FAILED_TO_REGENERATE_INVITE_TOKEN',
        ],
        { memberJoinToken: displayMemberJoinToken($scope.team.memberJoinToken) }
      ).then(function (translations) {
        swal(
          {
            title: translations.REGENERATE_INVITE_TOKEN,
            text: translations.USERS_NOTIFY_UNABLE_TO_USE_OLD_TOKEN,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: translations.REGENERATE,
            cancelButtonText: translations.CANCEL,
          },
          function (isConfirm) {
            if (isConfirm) {
              AdminApiService.addLoading();
              AdminApiService.regenInviteToken({ _id: $scope.team._id })
                .then(function (teamData) {
                  $scope.team.memberJoinToken = teamData.memberJoinToken;
                  $scope.displayMemberJoinToken = displayMemberJoinToken($scope.team.memberJoinToken);
                })
                .catch(function (response) {
                  // @todo - use static strings for i18n
                  ErrorHandler.addHttpError(translations.FAILED_TO_REGENERATE_INVITE_TOKEN, response);
                })
                .finally(function () {
                  AdminApiService.removeLoading();
                });
            }
          }
        );
      });
    }

    /*** intro tours ***/
    $translate(
      [
        'TOUR_TEAM_MANAGER_OPTIONS_1',
        'TOUR_TEAM_MANAGER_OPTIONS_2',
        'TOUR_TEAM_MANAGER_OPTIONS_3',
        'TOUR_TEAM_MANAGER_OPTIONS_4',
        'TOUR_TEAM_MANAGER_OPTIONS_5',
        'TOUR_TEAM_MANAGER_OPTIONS_6',
        'NEXT',
        'PREVIOUS',
        'CLOSE',
        'FINISH',
      ],
      { isAuthorized: $scope.isAuthorized([USER_ROLES.admin, USER_ROLES.companyAdmin]) ? 'yes' : 'no' },
      'messageformat'
    ).then(function (translations) {
      $scope.tourTeamManagerOptions = {
        steps: [
          {
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_1,
          },
          {
            element: '#team-actions-bar',
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_2,
            position: 'top',
          },
          {
            element: '#invitation-token-section',
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_3,
            position: 'bottom',
          },
          {
            element: '#team-managers-tab',
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_4,
            position: 'top',
          },
          {
            element: '#searchFilter-tab',
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_5,
            position: 'top',
          },
          {
            element: '#developers-tab',
            intro: translations.TOUR_TEAM_MANAGER_OPTIONS_6,
            position: 'top',
          },
        ],
        showStepNumbers: false,
        exitOnOverlayClick: false,
        exitOnEsc: true,
        nextLabel: translations.NEXT,
        prevLabel: translations.PREVIOUS,
        skipLabel: translations.CLOSE,
        doneLabel: translations.FINISH,
        overlayOpacity: 0.9,
        showBullets: false,
      };
    });

    function showAdvancedOptions() {
      return $swal({
        title: $translate.instant('ADMIN_TEAMS_VIEW_ADVANCED_OPTIONS_TITLE'),
        templateUrl: advancedOptionsTemplate,
        showConfirmButton: false,
        showCancelButton: false,
        scope: $scope.$new(),
      }).then(function (response) {
        $log.debug('[AdminTeamsView] ng-submit option response', response);

        if (response === 'metrics') return $state.go('metrics', { t1: $scope._id });
        if (response === 'edit') return $scope.editTeam();
      });
    }

    function bulkReInviteTeam() {
      return AdminBulkReInviteUsers.bulkReInviteTeam($scope.teamId);
    }

    // lifecycle
    init();
  },
]);
