import { useCallback, useEffect } from 'react';
import { usePlatformContext } from '../platformContext';
import { useStableReferenceByJson } from './hooks';
export const useLoadAnalytics = (event) => {
    const { logAnalyticsEvent } = usePlatformContext();
    const stableEvent = useStableReferenceByJson(event);
    useEffect(() => {
        logAnalyticsEvent(stableEvent);
    }, [logAnalyticsEvent, stableEvent]);
};
export const useMonitoredFunction = (event, fn) => {
    const { logAnalyticsEvent } = usePlatformContext();
    const stableEvent = useStableReferenceByJson(event);
    return useCallback((...params) => {
        logAnalyticsEvent(stableEvent);
        return fn(...params);
    }, [fn, logAnalyticsEvent, stableEvent]);
};
export function useMonitoredCallback(event, callback, deps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cb = useCallback(callback, deps);
    return useMonitoredFunction(event, cb);
}
export const multiassessmentAnalytis = {
    openBulkEditProgramStage: (programId, stageId) => ({
        event: 'Open Bulk Edit screen',
        scope: 'multiassessments',
        kind: 'programStage',
        programId,
        stageId,
    }),
    openBulkEditCourse: (courseId) => ({
        event: 'Open Bulk Edit screen',
        scope: 'multiassessments',
        kind: 'course',
        courseId,
    }),
    openBulkEditLevelGrouping: (levelGroupingId) => ({
        event: 'Open Bulk Edit screen',
        scope: 'multiassessments',
        kind: 'levelGrouping',
        levelGroupingId,
    }),
    editPressed: { event: 'click', scope: 'multiassessments', type: 'button', name: 'Edit' },
    cancelPressed: { event: 'click', scope: 'multiassessments', type: 'button', name: 'Cancel' },
    applyPressed: (versionBumpAllowed) => ({ event: 'click', scope: 'multiassessments', type: 'button', name: 'Apply', versionBumpAllowed }),
    resetToDefaultPressed: (assessmentId, assessmentStackId, field) => ({
        event: 'click',
        scope: 'multiassessments',
        type: 'button',
        name: 'Reset to default',
        assessmentId,
        assessmentStackId,
        field,
    }),
    manageAssessmentPressed: (assessmentId, assessmentStackId) => ({
        event: 'click',
        scope: 'multiassessments',
        type: 'button',
        name: 'Manage Assessment',
        assessmentId,
        assessmentStackId,
    }),
    fixProgramPressed: (assessmentStackId, assessmentIds, programId, stageId) => ({
        event: 'click',
        scope: 'multiassessments',
        type: 'button',
        name: 'fix',
        assessmentIds,
        assessmentStackId,
        programId,
        stageId,
    }),
    filterPressed: (filterName) => ({
        event: 'click',
        scope: 'multiassessments',
        type: 'button',
        name: 'filter',
        filterName,
    }),
    editDefaultSetting: (update) => ({
        event: 'Field edited',
        scope: 'multiassessments',
        update,
    }),
    navigateBack: (kind) => ({
        event: 'click',
        scope: 'multiassessments',
        type: 'button',
        name: 'Back',
        kind,
    }),
    toggleVersionBump: (value) => ({
        event: 'toggle',
        scope: 'multiassessments',
        type: 'switch',
        name: 'versionBumpAllowed',
        value,
    }),
};
