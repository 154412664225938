import { styled, Box as MuiBox } from '@mui/material';
export const StatusBadge = styled(MuiBox)(({ theme }) => ({
    color: theme.palette.badge.text,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .play-circle': {
        display: 'inline-flex',
        padding: theme.spacing(0.75),
        border: `1px solid ${theme.palette.badge.primary.border}`,
        borderRadius: '50%',
        '&.not_started': {
            backgroundColor: theme.palette.container.fill.card1,
            borderColor: theme.palette.container.border.default,
            color: theme.palette.container.fill.card1,
        },
        '&.in_progress': {
            backgroundColor: theme.palette.container.fill.card1,
            borderColor: theme.palette.container.border.default,
            color: theme.palette.container.fill.card1,
        },
        '&.completed': {
            backgroundColor: theme.palette.badge.success.fill,
            borderColor: theme.palette.badge.success.border,
        },
        '&.undiscovered': {
            backgroundColor: theme.palette.container.fill.card1,
            borderColor: theme.palette.container.border.default,
            color: theme.palette.container.border.default,
        },
        '&.missed': {
            backgroundColor: theme.palette.container.fill.card1,
            borderColor: theme.palette.container.border.default,
            color: theme.palette.container.border.default,
        },
    },
    svg: {
        fontSize: theme.typography.pxToRem(16),
        flexShrink: 0,
    },
}));
