import MODULE from './module';
import './sso-saml';
import './AuthService';
import './auth';
import './session';
import './profile';
import './login';
import './sso-tos';

export default MODULE;
