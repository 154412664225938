import angular from 'angular';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import MODULE from './module';

// templates
import searchTemplate from './admin.users.search.html';
import passwordResetTemplate from './admin.users.password-reset.html';

angular.module(MODULE).directive('adminUsersSearchTable', [
  '$window',
  '$log',
  '$translate',
  '$timeout',
  '$uibModal',
  '$state',
  '$swal',
  '$location',
  'AuthService',
  'AdminApiService',
  'AddInviteEditUser',
  'Session',
  'ErrorHandler',
  'AnalyticsService',
  'AdminBulkReInviteUsers',
  function (
    $window,
    $log,
    $translate,
    $timeout,
    $uibModal,
    $state,
    $swal,
    $location,
    AuthService,
    AdminApiService,
    AddInviteEditUser,
    Session,
    ErrorHandler,
    AnalyticsService,
    AdminBulkReInviteUsers
  ) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: searchTemplate,
      scope: {
        id: '@',
        companyId: '=?companyId',
        teamId: '=?teamId',
        roles: '=?roles',
        sort: '=?sort',
        showSummary: '=?showSummary',
        showStatus: '=?showStatus',
        showStandaloneTeam: '=?showStandaloneTeam',
        showIndividualControls: '=?showIndividualControls',
        hideInviteAndResetPasswordButtons: '=?hideInviteAndResetPasswordButtons',
        autoInit: '=?autoInit',
        selectable: '=?selectable',
        paginationPreferences: '=?paginationPreferences',
        onUserUpdate: '&?onUserUpdate',
        autoFocus: '=?',
        bind: '=?bind',
        anonymise: '=?anonymise',
        isMetricsEnabled: '=?isMetricsEnabled',
      },
      link: function (scope, element, _attrs, _controller, transcludeFn) {
        // @tmp
        ($window._userSearch = _.merge($window._userSearch, {}))[scope.id] = scope;

        element.find('transclusion').append(transcludeFn(scope, function () {}));
        scope.bind = scope.bind || {};
        scope.auth = AuthService;

        // Being: subscribe to user updates in other instances
        var subscribed = [];

        subscribed.push(
          scope.$root.$on('admin:users-updated', function () {
            reload(true);
          })
        );

        subscribed.push(
          scope.$root.$on('admin:user-reinvited', function () {
            reload(true);
          })
        );

        scope.$on('$destroy', function () {
          var idx;
          for (idx = 0; idx < subscribed.length; idx++) subscribed[idx]();
        });
        // End: subscribe to user updates in other instances

        // Begin: init component
        var usersQuery = {
          'properties._cid': scope.companyId,
          'properties._tid': scope.teamId,
        };

        if (scope.roles) usersQuery.roles = Array.isArray(scope.roles) ? scope.roles : [scope.roles];

        scope.$watch('companyId', function (companyId) {
          if (companyId != undefined) usersQuery['properties._cid'] = companyId;
        });

        scope.$watch('teamId', function (teamId) {
          if (teamId != undefined) usersQuery['properties._tid'] = teamId;
        });

        if (scope.autoInit) {
          scope.$watchGroup(['companyId', 'teamId', 'options'], function (_n, _o) {
            $timeout(function () {
              scope.usersQuery = usersQuery;
            }, 10);
          });
        }

        // bind bulk selectors
        scope.$watch(
          'baseSearch.ui.selection',
          function (_n, _o) {
            if (scope.baseSearch && scope.baseSearch.selection)
              scope.bulkActions = {
                ids: scope.baseSearch.selection(),
              };
          },
          true
        );

        // End: init component

        // Begin: expose behaviors and data
        scope.searchText = '';
        scope.onSearchCompletion = onSearchCompletion;
        scope.isDisabledResetPassword = isDisabledResetPassword;
        scope.toggleUserStatus = toggleUserStatus;
        scope.resetPassword = resetPassword;
        scope.reinviteUser = reinviteUser;
        scope.bulkReInviteTeam = bulkReInviteTeam;
        scope.removeUser = removeUser;
        scope.Session = Session;
        scope.search = search;
        scope.reload = reload;
        scope.baseSearch = {}; // base search control
        scope.paginationOptions = {};
        scope.onSelectionUpdate = onSelectionUpdate;
        scope.bulkActions = {};
        scope.onBulkUpdate = onBulkUpdate;
        scope.invite = inviteEditUser;
        scope.edit = inviteEditUser;
        scope.moment = moment;
        // End: expose behaviors and data

        // Begin: back-controller (bind)
        scope.bind.search = search;
        scope.bind.reload = reload;
        // undefined or null would mean data has not been passed and in that case it should default to what we have today
        scope.showMetrics = scope.isMetricsEnabled ?? true;
        // End: back-controller (bind)

        // visual: scroll to table after search and pagination (low-res hack)
        function onSearchCompletion(_page) {
          if (scope.autoFocus) {
            $timeout(function () {
              if (scope.autoFocus === 'always' || (scope.autoFocus === 'non-first' && onSearchCompletion.loadedFirst)) {
                // var elementTop = element.offset().top;
                // $window.scroll({ top: elementTop - 200, behavior: "smooth" });
                angular.element('table tbody tr:nth(0)', element).focus();
              }
            }, 500);
          }

          if (!onSearchCompletion.loadedFirst) onSearchCompletion.loadedFirst = true;
        }

        function reload(keepOptions) {
          if (scope.baseSearch.search.loaded) search(scope.baseSearch.search.text, keepOptions);
        }

        async function inviteEditUser(action, userType, user) {
          if (user) {
            try {
              AdminApiService.addLoading();
              user = await AdminApiService.getUser(user._id);
            } catch (e) {
              $log.debug('failed to fetch user', e);
            } finally {
              AdminApiService.removeLoading();
            }
          }

          user = user || {};

          if (scope.companyId) {
            _.set(user, 'properties._cid', scope.companyId);
          }

          if (scope.teamId) {
            _.set(user, 'properties._tid', scope.teamId);
          }

          AnalyticsService.logPageViewEvent($location.absUrl(), 'Invite User Modal', 'modal');

          return AddInviteEditUser.apply(this, [action, userType, user]).then(function (response) {
            if (scope.onUserUpdate) {
              scope.onUserUpdate();
            }
            $timeout(function () {
              scope.$emit('admin:users-updated', response);
            }, 500);
            return response;
          });
        }

        function search(text, keepOptions) {
          scope.usersQuery = {};
          if (scope.roles) scope.usersQuery.roles = Array.isArray(scope.roles) ? scope.roles : [scope.roles];

          if (scope.companyId !== undefined) scope.usersQuery['properties._cid'] = scope.companyId;

          if (scope.teamId !== undefined) scope.usersQuery['properties._tid'] = scope.teamId;

          // scope.autoInit = true;
          search.loaded = true;
          search.text = text;
          scope.baseSearch.search(text, keepOptions, scope.usersQuery);
        }

        function onBulkUpdate() {
          if (scope.onUserUpdate) scope.onUserUpdate();

          scope.$emit('admin:users-updated');
        }

        function onSelectionUpdate(selection) {
          $log.debug('invoked selectionupdate with', selection);
          scope.bulkActions = {
            ids: selection,
          };
        }

        function reinviteUser(user) {
          AdminApiService.addLoading();
          AdminApiService.resendInvite(user)
            .then(function (_user) {
              $swal({
                title: $translate.instant('INVITATION_RESET'),
                text: $translate.instant('NEW_INVITATION_EMAIL_SENT'),
                type: 'success',
                confirmButtonText: $translate.instant('OK'),
                keyResolution: true,
              });

              scope.$emit('admin:user-reinvited');
            })
            .catch(function (response) {
              ErrorHandler.addHttpError($translate.instant('FAILED_TO_RESEND_INVITATION'), response);
            })
            .finally(function () {
              AdminApiService.removeLoading();
            });
        }

        function removeUserOnError(response) {
          if (response.status === 500) {
            $translate(['CANNOT_REMOVE_USER_500']).then(function (translations) {
              $timeout(function () {
                ErrorHandler.addError(translations.CANNOT_REMOVE_USER_500);
              }, 100);
            });
          } else {
            //@todo - use static strings for i18n
            let numb = response.data.error.match(/\d/g) || [];
            numb = numb.join('');
            $translate(['CANNOT_REMOVE_USER'], { numb: numb }).then(function (translations) {
              $timeout(function () {
                ErrorHandler.addError(translations.CANNOT_REMOVE_USER);
              }, 100);
            });
          }
        }

        function removeUser(user) {
          $translate(['DELETE_USER', 'DELETE_USER_CONFIRMATION', 'DELETE', 'CANCEL']).then(function (translations) {
            $swal({
              title: translations.DELETE_USER,
              text: translations.DELETE_USER_CONFIRMATION,
              type: 'warning',
              html: true,
              showCancelButton: true,
              confirmButtonColor: 'var(--color-scw-red)',
              confirmButtonText: translations.DELETE,
              cancelButtonText: translations.CANCEL,
              keyResolution: true,
            }).then(function (isConfirm) {
              if (isConfirm) {
                AdminApiService.addLoading();
                AdminApiService.removeUser(user._id)
                  .then(function () {
                    user.status = 'removed';
                    scope.baseSearch.search(scope.baseSearch.search.text, true);
                    if (scope.onUserUpdate) {
                      var onUserUpdate = scope.onUserUpdate();
                      if (typeof onUserUpdate === 'function') onUserUpdate(user);
                    }
                  })
                  .catch(removeUserOnError)
                  .finally(function () {
                    AdminApiService.removeLoading();
                  });
              }
            });
          });
        }

        function resetPassword(user) {
          $translate(
            [
              'RESET',
              'CANCEL',
              'RESET_PASSWORD',
              'WOULD_YOU_LIKE_TO_RESET_PASSWORD_FOR_USER',
              'FAILED_TO_SEND_PASSWORD_RESET_REQUEST',
            ],
            { email: user.email }
          ).then(function (translations) {
            $swal({
              title: translations.RESET_PASSWORD + '?',
              text: translations.WOULD_YOU_LIKE_TO_RESET_PASSWORD_FOR_USER,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: translations.RESET,
              cancelButtonText: translations.CANCEL,
              keyResolution: true,
            }).then(function (isConfirm) {
              if (isConfirm) {
                AdminApiService.addLoading();
                AdminApiService.resetPassword(user._id)
                  .then(function (response) {
                    $uibModal
                      .open({
                        templateUrl: passwordResetTemplate,
                        controller: [
                          '$scope',
                          '$uibModalInstance',
                          'resetResponse',
                          function ($scope, $uibModalInstance, resetResponse) {
                            $scope.resetResponse = resetResponse;
                            $scope.dismiss = function () {
                              $uibModalInstance.close();
                            };
                          },
                        ],
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                          resetResponse: response,
                        },
                      })
                      .result.catch(angular.noop);
                  })
                  .catch(function (response) {
                    ErrorHandler.addHttpError(translations.FAILED_TO_SEND_PASSWORD_RESET_REQUEST, response);
                  })
                  .finally(AdminApiService.removeLoading);
              }
            });
          });
        }

        function isDisabledResetPassword(user) {
          return (
            user._id === Session.user._id ||
            _.get(user, 'properties.team.status') === 'disabled' ||
            user.status === 'invited' ||
            user.roles.includes('scw admin')
          );
        }

        function toggleUserStatus(user) {
          var payload = angular.copy(user);
          var previousStatus = user.status;

          payload.status = user.status == 'disabled' ? 'enabled' : 'disabled';
          user.status = payload.status;

          // workaround for API schema and computed property
          delete payload.expirationDate;
          delete payload.properties.SAMLAttributes;

          AdminApiService.updateUser(payload._id, payload)
            .then(function (data) {
              user.status = data.status;

              if (scope.onUserUpdate) {
                var onUserUpdate = scope.onUserUpdate();
                if (typeof onUserUpdate === 'function') onUserUpdate(user);
              }

              scope.baseSearch.search(scope.baseSearch.search.text, true);
            })
            .catch(function (response) {
              var action = payload.status == 'enabled' ? 'enable' : 'disable';
              $translate(['FAILED_TO_EDIT_USER'], { action: action }).then(function (translations) {
                ErrorHandler.addHttpError(translations.FAILED_TO_EDIT_USER, response);
              });
              user.status = previousStatus;
            });
        }

        function bulkReInviteTeam() {
          return AdminBulkReInviteUsers.bulkReInviteTeam(scope.teamId);
        }

        scope.showBulkReInviteTeam = function () {
          return scope.teamId;
        };

        // keyboard-navigation wrappers
        scope.goToMetrics = function (state, vars) {
          $state.go(state, vars);
        };

        scope.toggleUserStatusKeyboardNavWrapper = function (user) {
          if (
            user._id == scope.Session.user._id ||
            (user.properties && user.properties.team && user.properties.team.status == 'disabled')
          )
            return;
          scope.toggleUserStatus(user);
        };

        scope.resetPasswordKeyboardNavWrapper = function (user) {
          if (scope.isDisabledResetPassword(user)) return;
          scope.resetPassword(user);
        };
        scope.reinviteUserKeyboardNavWrapper = function (user) {
          if (user.status !== metadata.constants.user.status.INVITED) return;
          scope.reinviteUser(user);
        };
        scope.removeUserKeyboardNavWrapper = function (user) {
          if (user._id == scope.Session.user._id) return;
          scope.removeUser(user);
        };
      },
    };
  },
]);
