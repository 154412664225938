import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CommunicationItemContainer as ItemContainer } from '../Communications.styled';
import { WandaSwitch } from '../../common/Switch/Switch';
import { Box, Stack, Typography } from '@mui/material';
export const Item = ({ title, description, descriptionComponent, switchProps, children }) => {
    return (_jsxs(ItemContainer, { sx: { rowGap: descriptionComponent || description ? 4 : undefined }, children: [_jsx(WandaSwitch, Object.assign({ sx: { gridArea: 'switch' } }, switchProps)), _jsx(Typography, { sx: { gridArea: 'title' }, children: title }), (description || descriptionComponent) && (_jsx(Stack, { rowGap: 4, sx: { gridArea: 'caption', alignSelf: 'start' }, children: !descriptionComponent ? _jsx(Typography, { variant: "caption", children: description }) : descriptionComponent })), _jsx(Box, { sx: {
                    gridArea: 'right',
                    alignSelf: 'flex-start',
                    marginInlineStart: { sm: 0, md: 4 },
                    marginBlockStart: { sm: 4, md: 0 },
                }, children: children })] }));
};
