import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { Icon, IconSize, Tooltip } from '@securecodewarrior/design-system-react/lib/legacy';
import { Trans } from 'react-i18next';
/** @deorecated This component uses an old icon component that's probably going to be removed in the future (for icons
 *  you can use, have a look at the material ui icons) */
const IconTooltip = (props) => {
    return (_jsx(Tooltip, { title: 'tooltipTitle' in props ? _jsx("div", { children: props.tooltipTitle }) : _jsx(Trans, { i18nKey: props.tooltipTitleTransKey }), arrow: true, children: _jsx(Icon, { name: props.name, size: IconSize.xsmall }) }));
};
export default IconTooltip;
