import angular from 'angular';
import MODULE from './module';
import templateUrl from './search.base.sortable-indicator.html';

angular.module(MODULE).directive('searchBaseSortableIndicator', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        current: '=',
        attribute: '=',
      },
      link: function (_scope, _element, _attrs) {},
    };
  },
]);
