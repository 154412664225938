var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-autofocus */
import { useCallback, useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { AdminPanelSettingsRounded, AssignmentRounded, DonutSmallRounded, EmojiEventsRounded, HomeRounded, LibraryBooksRounded, LiveHelpRounded, MapRounded, NotificationsRounded, PermIdentityRounded, SchoolRounded, } from '@mui/icons-material';
import { Avatar, Button, Icon, IconSize, Loading, SearchInput, } from '@securecodewarrior/design-system-react/lib/legacy';
import { AppBar, AppBarButton, AppBarMenu, AppBarMenuItem, AppBarToolbar, Unstable_AppBarMenuItem, featureFlags, } from '../../common';
import { roleMap } from '../../common/helpers/roles';
import { CybermonIcon } from '../../scw-react/cybermon/components/CybermonIcon';
const HIDE_USERSNAP_WHEN_LMS_FOR_THESE_COMPANIES = ['5667512e718800ba3692f8a3'];
// TODO this method is far too long and needs to be split up
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function PlatformHeader(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const { angularToReactBridge, sessionUser, isLoading, loadingMessage, impersonateEmail, impersonateEmailOnChange, impersonateUserOnClick, buttonActive, routeParts, isAssessmentAllowedByCustomerPlan, isTrainingAllowedByCustomerPlan, isSuccessHubEnabled, isNotificationCenterEnabled, isGoalsAdminEnabled, isCybermonEnabled, hasTutorial, hideUserSnapApp, hideSupportApp, gameThirteenStateService, metadata, reloadPage, versionMismatch, changeLanguageFramework, simpleFlowCurrentLanguage, utility, learningModules } = props, rest = __rest(props, ["angularToReactBridge", "sessionUser", "isLoading", "loadingMessage", "impersonateEmail", "impersonateEmailOnChange", "impersonateUserOnClick", "buttonActive", "routeParts", "isAssessmentAllowedByCustomerPlan", "isTrainingAllowedByCustomerPlan", "isSuccessHubEnabled", "isNotificationCenterEnabled", "isGoalsAdminEnabled", "isCybermonEnabled", "hasTutorial", "hideUserSnapApp", "hideSupportApp", "gameThirteenStateService", "metadata", "reloadPage", "versionMismatch", "changeLanguageFramework", "simpleFlowCurrentLanguage", "utility", "learningModules"]);
    // Bridge
    const $rootScope = angularToReactBridge.$rootScope;
    const $translate = angularToReactBridge.$translate;
    const $state = angularToReactBridge.$state;
    const $analyticsService = $rootScope.AnalyticsService;
    // State Aliases
    const Session = $rootScope.Session;
    // Access Control
    const isAuthenticated = () => !!sessionUser && $rootScope.isAuthenticated();
    const isAnonymous = () => Session.isAnonymous();
    const isLMSInitialized = () => $rootScope.isLMSInitialized();
    const isFeatureEnabled = (feature) => !!sessionUser && $rootScope.isFeatureEnabled(feature);
    const isUserRegisteredOrEnabled = () => (sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.status) === 'registered' || (sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.status) === 'enabled';
    const hasTournamentAccess = () => !!sessionUser && $rootScope.hasTournamentAccess();
    // PLAT-14708 Remove Devlympics from TopNav until further notice.  Return false rather than making function call.
    const hasDevlympicsAccess = () => false;
    const isAuthorized = (roles) => !!sessionUser && $rootScope.isAuthorized(roles);
    const checkFeatureFlag = (featureFlag, callback) => {
        if (!isUserRegisteredOrEnabled()) {
            callback('disabled');
            return;
        }
        $rootScope.isFeatureFlagEnabled(featureFlag).then((success) => {
            callback(success ? 'enabled' : 'disabled');
        }, () => callback('disabled'));
    };
    const checkLearningModule = (learningModule, callback) => {
        if (!isUserRegisteredOrEnabled()) {
            callback('disabled');
            return;
        }
        $rootScope.isLearningModuleEnabled(learningModule).then((success) => {
            callback(success === true ? 'enabled' : success === false ? 'disabled' : 'unset');
        }, () => callback('disabled'));
    };
    const isStandaloneUser = sessionUser && !sessionUser.roles.includes('scw admin') && !((_a = sessionUser.properties) === null || _a === void 0 ? void 0 : _a.company);
    // TODO: this could use a rename to make it more clear this isn't the only thing that defines it'll be visible
    const [questsVisible, setQuestsVisible] = useState('loading');
    const [questV3Visible, setQuestV3Visible] = useState('loading');
    const [hideTraining, setHideTraining] = useState('loading');
    const [showExploreMenuItem, setShowExploreMenuItem] = useState('loading');
    const [dashboardPage, setDashboardPage] = useState('loading');
    const [showGuidedLearningModule, setShowGuidedLearningModule] = useState('loading');
    const [showQuestsModule, setShowQuestsModule] = useState('loading');
    // Managing local state for the impersonate field, otherwise it becomes readonly when there is a value.
    const [impersonateEmailAddress, setImpersonateEmailAddress] = useState((_b = impersonateEmail === null || impersonateEmail === void 0 ? void 0 : impersonateEmail.address) !== null && _b !== void 0 ? _b : '');
    const handleImpersonateEmailAddressChange = (e) => {
        impersonateEmailOnChange === null || impersonateEmailOnChange === void 0 ? void 0 : impersonateEmailOnChange(e);
        setImpersonateEmailAddress(e.currentTarget.value);
    };
    const handleImpersonateEmailAddressSubmit = (e) => {
        e.preventDefault();
        impersonateUserOnClick();
    };
    useEffect(() => {
        checkFeatureFlag(featureFlags.QUESTS, setQuestsVisible);
        checkFeatureFlag(featureFlags.HIDE_TRAINING, setHideTraining);
        checkFeatureFlag(featureFlags.SHOW_EXPLORE_MENU_ITEM, setShowExploreMenuItem);
        checkFeatureFlag(featureFlags.DASHBOARD, setDashboardPage);
        checkFeatureFlag(featureFlags.QUESTS_V3, setQuestV3Visible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionUser]);
    useEffect(() => {
        checkLearningModule('guidedLearning', setShowGuidedLearningModule);
        checkLearningModule('quests', setShowQuestsModule);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningModules, sessionUser]);
    // Methods Wrappers
    const translate = function (translationId, interpolateParams = {}) {
        return $translate.instant(translationId, interpolateParams);
    };
    const go = (toState, stateParams) => {
        $state.go(toState, stateParams, { reload: true });
    };
    const onAppBarButtonClick = ({ redirectPath, stateParams }, zendeskKeyword = null) => () => {
        go(redirectPath, stateParams);
        if (zendeskKeyword) {
            $rootScope.setZendeskSearchKeyword(zendeskKeyword);
        }
    };
    const hideUsersnapWhenLMS = ((_c = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _c === void 0 ? void 0 : _c._cid) && HIDE_USERSNAP_WHEN_LMS_FOR_THESE_COMPANIES.includes(sessionUser.properties._cid);
    // Internal Methods
    const getIcon = useCallback((iconName, size = IconSize.small) => _jsx(Icon, { name: iconName, size: size }), []);
    const appBarProps = {
        logoProps: {
            src: (_f = (_e = (_d = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e.logo) !== null && _f !== void 0 ? _f : 'images/logo-symbol-2024.png',
            alt: (_j = (_h = (_g = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _g === void 0 ? void 0 : _g.company) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : 'Secure Code Warrior',
            href: dashboardPage === 'enabled' && isAuthorized([roleMap.companyAdmin, roleMap.reseller])
                ? '/#/dashboard'
                : '/#/home',
            width: '46px',
        },
    };
    const userProfile = {
        image: (_m = (_l = (_k = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _k === void 0 ? void 0 : _k.profile) === null || _l === void 0 ? void 0 : _l.displayPic) !== null && _m !== void 0 ? _m : 'images/user-icon.png',
        email: sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.email,
        name: sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.email,
    };
    return (_jsx(AppBar, Object.assign({}, appBarProps, rest, { children: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsxs(AppBarToolbar, { children: ['new-simple-flow-quest' in routeParts && isAnonymous() && (_jsx(AppBarButton, { startIcon: _jsx(HomeRounded, {}), active: 'home' in routeParts, onClick: $rootScope.showSimpleFlowSignupModalFromNavbar, children: translate('SIMPLE_FLOW_REGISTER_TEXT') })), isAuthenticated() && !isLMSInitialized() && !('new-simple-flow-quest' in routeParts) && (_jsxs(_Fragment, { children: [isAuthorized([roleMap.reseller, roleMap.companyAdmin]) && dashboardPage === 'enabled' ? (_jsx(AppBarButton, { startIcon: _jsx(HomeRounded, {}), active: 'dashboard' in routeParts, onClick: onAppBarButtonClick({ redirectPath: 'dashboard' }), children: translate('HOME') })) : (
                                /* else if not roleMap.admin or anonymous, show old home page */
                                !isAuthorized([roleMap.admin, roleMap.anonymous]) && (_jsx(AppBarButton, { startIcon: _jsx(HomeRounded, {}), active: 'home' in routeParts, onClick: onAppBarButtonClick({ redirectPath: 'home' }), children: translate('HOME') }))), !isAuthorized(['anonymous']) && showGuidedLearningModule !== 'disabled' && (_jsx(AppBarMenu, { label: translate('GUIDED_LEARNING'), active: 'programs' in routeParts, startIcon: _jsx(AssignmentRounded, {}), children: [
                                        {
                                            condition: isAuthorized([roleMap.companyAdmin, roleMap.manager]),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'programs.management' }, 'program'), children: translate('PROGRAM_MANAGEMENT_TOP_MENU') }, "Program management")),
                                        },
                                        {
                                            condition: !isAuthorized([roleMap.admin]),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'programs.my-programs' }, 'program'), children: translate('PROGRAMS') }, "Programs")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(Divider, { sx: (theme) => ({ backgroundColor: theme.palette.grayscale.bg3 }), flexItem: true }, "PROGRAMS_COURSES_DIVIDER")),
                                        },
                                        {
                                            condition: isAuthorized([roleMap.companyAdmin, roleMap.manager]),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'courses.management' }, 'course'), children: translate('COURSE_MANAGEMENT_TOP_MENU') }, "COURSE_MANAGEMENT")),
                                        },
                                        {
                                            condition: !isAuthorized([roleMap.admin]),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'courses.my-courses' }, 'course'), children: translate('COURSES') }, "COURSES")),
                                        },
                                        {
                                            condition: !isAuthorized([roleMap.admin]),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'courses-progressLeaderboard' }, 'training leaderboard'), children: translate('COURSE_LEADERBOARD') }, "LEADERBOARD")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(Divider, { sx: (theme) => ({ backgroundColor: theme.palette.grayscale.bg3 }), flexItem: true }, "COURSES_ASSESSMENTS_DIVIDER")),
                                        },
                                        {
                                            condition: isAssessmentAllowedByCustomerPlan && !isAuthorized(['anonymous']),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'assessments.list' }, 'assessments'), children: translate('ASSESSMENTS') }, "ASSESSMENTS")),
                                        },
                                    ]
                                        .filter(({ condition }) => condition)
                                        .map(({ Component }) => Component) })), !hasDevlympicsAccess() && (_jsxs(_Fragment, { children: [hasTournamentAccess() && (_jsx(AppBarButton, { active: 'tournaments' in routeParts, startIcon: _jsx(EmojiEventsRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'tournaments' }, 'tournament'), children: translate('TOURNAMENTS') })), !hasTournamentAccess() && (_jsx(AppBarButton, { active: 'tournaments-not-licensed' in routeParts, startIcon: _jsx(EmojiEventsRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'tournaments-not-licensed' }, 'tournament'), children: translate('TOURNAMENTS') }))] })), hasDevlympicsAccess() && (_jsx(AppBarMenu, { label: translate('TOURNAMENTS'), active: 'tournaments' in routeParts, startIcon: _jsx(EmojiEventsRounded, {}), children: [
                                        {
                                            condition: hasTournamentAccess(),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'tournaments' }, 'tournament'), children: translate('MY_TOURNAMENTS_TOP_MENU') }, "MY_TOURNAMENTS")),
                                        },
                                        {
                                            condition: !hasTournamentAccess(),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'tournaments-not-licensed' }, 'tournament'), children: translate('MY_TOURNAMENTS_TOP_MENU') }, "tournaments-not-licensed")),
                                        },
                                        {
                                            condition: isUserRegisteredOrEnabled(),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'tournaments.devlympics' }, 'devlympics2022'), children: translate('DEVLYMPICS') }, "DEVLYMPICS")),
                                        },
                                    ]
                                        .filter(({ condition }) => condition)
                                        .map(({ Component }) => Component) })), !isFeatureEnabled('training') &&
                                    !isAuthorized([roleMap.anonymous]) &&
                                    showGuidedLearningModule !== 'disabled' &&
                                    showQuestsModule !== 'enabled' && (_jsx(AppBarButton, { active: 'training-not-licensed' in routeParts, startIcon: _jsx(SchoolRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'training-not-licensed' }, 'training'), children: translate('TRAINING') })), (isAuthorized([roleMap.admin, roleMap.reseller]) ||
                                    (isFeatureEnabled('training') &&
                                        isTrainingAllowedByCustomerPlan &&
                                        !isAuthorized([roleMap.anonymous]) &&
                                        hideTraining === 'disabled')) &&
                                    showGuidedLearningModule !== 'disabled' &&
                                    showQuestsModule !== 'enabled' && (_jsx(AppBarMenu, { label: translate('TRAINING'), active: 'game.013' in routeParts, startIcon: _jsx(SchoolRounded, {}), children: [
                                        {
                                            condition: isUserRegisteredOrEnabled(),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'game.013.languages', stateParams: { source: 'training languages' } }, 'languages'), children: translate('LANGUAGES') }, "LANGUAGES")),
                                        },
                                        {
                                            condition: isUserRegisteredOrEnabled(),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'game.013' }, 'mission'), children: translate('MISSION_CONTROL_TOP_MENU') }, "MISSION_CONTROL")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'game.013.stats' }, 'player'), children: translate('MY_STATISTICS_TOP_MENU') }, "PLAYER_STATS")),
                                        },
                                        {
                                            condition: !((_r = (_q = (_p = (_o = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _o === void 0 ? void 0 : _o.preferences) === null || _p === void 0 ? void 0 : _p.leaderboard) === null || _q === void 0 ? void 0 : _q.options) === null || _r === void 0 ? void 0 : _r.hideLeaderboard),
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'game.013.leaderboard' }, 'leaderboard'), children: translate('LEADERBOARD') }, "LEADERBOARD")),
                                        },
                                    ]
                                        .filter(({ condition }) => condition)
                                        .map(({ Component }) => Component) })), (isAuthorized([roleMap.admin]) || isFeatureEnabled('sensei')) && (_jsx(AppBarMenu, { label: translate('SENSEI'), active: 'sensei-download' in routeParts || 'sensei-metrics' in routeParts, startIcon: _jsx(NotificationsRounded, {}), children: [
                                        {
                                            condition: true,
                                            Component: (_jsx(AppBarMenuItem, { target: "_blank", href: "https://securecodewarrior.com/sensei", children: translate('OVERVIEW') }, "OVERVIEW")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'sensei-download' }, 'sensei'), children: translate('DOWNLOAD') }, "DOWNLOAD")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(AppBarMenuItem, { target: "_blank", href: "https://sensei-docs-beta.securecodewarrior.com/index.html", children: translate('SENSEI_DOCUMENTATION') }, "SENSEI_DOCUMENTATION")),
                                        },
                                        {
                                            condition: true,
                                            Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'sensei-metrics' }, 'sensei'), children: translate('METRICS') }, "METRICS")),
                                        },
                                    ]
                                        .filter(({ condition }) => condition)
                                        .map(({ Component }) => Component) })), questV3Visible === 'enabled' && showQuestsModule !== 'disabled' && (_jsx(AppBarButton, { active: 'my-quests' in routeParts, startIcon: _jsx(MapRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'my-quests', stateParams: { path: '' } }, 'my-quests'), children: translate('MY_QUESTS') })), questV3Visible === 'enabled' && showQuestsModule !== 'disabled' && (_jsx(AppBarButton, { active: 'learn' in routeParts, startIcon: _jsx(MapRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'learn', stateParams: { path: '' } }, 'learn'), children: translate('LEARN') })), questsVisible === 'enabled' && !isStandaloneUser && showQuestsModule !== 'disabled' && (_jsx(AppBarButton, { active: 'quests' in routeParts, startIcon: _jsx(MapRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'quests', stateParams: { path: '' } }, 'quests'), children: translate('QUESTS') })), showExploreMenuItem === 'enabled' && (_jsx(AppBarButton, { active: 'explore' in routeParts, startIcon: _jsx(LibraryBooksRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'explore', stateParams: { path: '' } }, 'explore'), children: translate('EXPLORE') }))] })), isAuthenticated() && isLMSInitialized() && (_jsx(AppBarButton, { startIcon: getIcon('close'), onClick: $rootScope.lmsClose, children: translate('EXIT') })), !isAuthenticated() && (_jsx(AppBarButton, { active: 'explore' in routeParts, startIcon: _jsx(LibraryBooksRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'explore', stateParams: { path: '' } }, 'explore'), children: translate('EXPLORE') })), isAuthenticated() && isCybermonEnabled && !isLMSInitialized() && (_jsx(AppBarButton, { active: 'cybermon' in routeParts, startIcon: _jsx(CybermonIcon, {}), onClick: () => {
                                window.location.href = `#/cybermon/`;
                            }, children: "Cybermon" }, "CYBERMON"))] }), _jsxs(AppBarToolbar, { children: [_jsx(Box, { sx: visuallyHidden, "aria-live": "polite", role: "status", children: isLoading ? translate('ACCESSIBILITY_LOADING') : translate('ACCESSIBILITY_LOADING_COMPLETED') }), isLoading ? _jsx(Loading, { children: loadingMessage }) : null, isAuthenticated() && (_jsxs(_Fragment, { children: [(((_s = userProfile.email) === null || _s === void 0 ? void 0 : _s.endsWith('@securecodewarrior.com')) ||
                                    ((_t = userProfile.email) === null || _t === void 0 ? void 0 : _t.endsWith('@securecodewarriors.com'))) &&
                                    isAuthorized([roleMap.admin]) && (_jsx("form", { onSubmit: handleImpersonateEmailAddressSubmit, children: _jsx(SearchInput, { sx: {
                                            button: {
                                                boxSizing: 'content-box',
                                                height: '20px',
                                                width: '20px',
                                            },
                                        }, autoFocus: !!impersonateEmailAddress, icon: _jsx(PermIdentityRounded, {}), placeholder: translate('EMAIL_OR_SHORTHAND'), disabled: buttonActive.impersonateUser, value: impersonateEmailAddress, onChange: handleImpersonateEmailAddressChange, buttonProps: { onClick: impersonateUserOnClick }, variant: "critical" }) })), (('game.013' in routeParts &&
                                    !('game.013.leaderboard' in routeParts || 'game.013.stats' in routeParts)) ||
                                    'new-simple-flow-quest' in routeParts) &&
                                    !!((_u = gameThirteenStateService === null || gameThirteenStateService === void 0 ? void 0 : gameThirteenStateService.active) === null || _u === void 0 ? void 0 : _u._language) && (_jsxs("span", { children: [(metadata === null || metadata === void 0 ? void 0 : metadata.languages) &&
                                            ((_v = gameThirteenStateService === null || gameThirteenStateService === void 0 ? void 0 : gameThirteenStateService.active) === null || _v === void 0 ? void 0 : _v._language) &&
                                            (metadata === null || metadata === void 0 ? void 0 : metadata.languages[(_w = gameThirteenStateService === null || gameThirteenStateService === void 0 ? void 0 : gameThirteenStateService.active) === null || _w === void 0 ? void 0 : _w._language].name), ' ', (metadata === null || metadata === void 0 ? void 0 : metadata.languages) &&
                                            ((_x = gameThirteenStateService === null || gameThirteenStateService === void 0 ? void 0 : gameThirteenStateService.active) === null || _x === void 0 ? void 0 : _x._language) &&
                                            (metadata === null || metadata === void 0 ? void 0 : metadata.languages[(_y = gameThirteenStateService === null || gameThirteenStateService === void 0 ? void 0 : gameThirteenStateService.active) === null || _y === void 0 ? void 0 : _y._language].framework[gameThirteenStateService.active._framework].name)] })), isAuthenticated() && isAuthorized([roleMap.companyAdmin, roleMap.manager]) && !isLMSInitialized() && (_jsx(AppBarButton, { startIcon: _jsx(DonutSmallRounded, {}), active: 'metrics' in routeParts || 'courses-manager-metrics' in routeParts || 'reporting' in routeParts, onClick: () => {
                                        try {
                                            $analyticsService.logEvent('click', {
                                                name: 'Metrics',
                                                scope: 'Header Navigation',
                                                type: 'link',
                                            });
                                        }
                                        catch (e) {
                                            console.error(e);
                                        }
                                        window.location.href = `#/reporting/`;
                                    }, children: translate('METRICS') })), isAuthenticated() &&
                                    isUserRegisteredOrEnabled() &&
                                    isAuthorized([roleMap.reseller, roleMap.companyAdmin]) &&
                                    !isLMSInitialized() && (_jsxs(AppBarMenu, { label: translate('ADMINISTRATION'), active: routeParts.admin ||
                                        routeParts.communications ||
                                        routeParts['success-hub'] ||
                                        routeParts['admin/goals'] ||
                                        routeParts['admin/learning-modules'], startIcon: _jsx(AdminPanelSettingsRounded, {}), children: [isSuccessHubEnabled && (_jsx(AppBarMenuItem, { onClick: () => {
                                                try {
                                                    $analyticsService.logEvent('click', {
                                                        name: 'Success Hub',
                                                        scope: 'Header Navigation',
                                                        type: 'link',
                                                    });
                                                }
                                                catch (e) {
                                                    console.error(e);
                                                }
                                                window.location.href = `#/success-hub/`;
                                            }, children: translate('SUCCESS_HUB') }, "SUCCESS_HUB")), isNotificationCenterEnabled && (_jsx(Unstable_AppBarMenuItem, { onClick: () => {
                                                try {
                                                    $analyticsService.logEvent('click', {
                                                        name: 'Communications',
                                                        scope: 'Header Navigation',
                                                        type: 'link',
                                                    });
                                                }
                                                catch (e) {
                                                    console.error(e);
                                                }
                                                window.location.href = `#/communications`;
                                            }, children: translate('COMMUNICATIONS') }, "COMMUNICATIONS")), isGoalsAdminEnabled && (_jsx(Unstable_AppBarMenuItem, { onClick: () => {
                                                try {
                                                    $analyticsService.logEvent('click', {
                                                        name: 'Goals admin',
                                                        scope: 'Header Navigation',
                                                        type: 'link',
                                                    });
                                                }
                                                catch (e) {
                                                    console.error(e);
                                                }
                                                window.location.href = `#/admin/goals`;
                                            }, children: translate('LEARNING_GOALS') }, "GOALS_ADMIN")), isAuthorized([roleMap.companyAdmin, roleMap.manager]) && (_jsx(AppBarMenuItem, { onClick: () => {
                                                try {
                                                    $analyticsService.logEvent('click', {
                                                        name: 'Trust Agent configuration',
                                                        scope: 'Header Navigation',
                                                        type: 'link',
                                                    });
                                                }
                                                catch (e) {
                                                    console.error(e);
                                                }
                                                window.location.href = `#/trust-agent/configuration`;
                                            }, children: translate('TRUST_AGENT_CONFIGURATION') }, "TRUST_AGENT_CONFIGURATION")), _jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'admin' }), children: translate('ADMINISTRATION') }, "ADMINISTRATION")] })), isAuthenticated() &&
                                    isUserRegisteredOrEnabled() &&
                                    isAuthorized([roleMap.admin, roleMap.manager]) &&
                                    !isLMSInitialized() && (_jsx(AppBarButton, { active: routeParts['admin'] || routeParts['admin/learning-modules'], startIcon: _jsx(AdminPanelSettingsRounded, {}), onClick: onAppBarButtonClick({ redirectPath: 'admin' }), children: translate('ADMINISTRATION') }))] })), _jsxs(AppBarMenu, { label: translate('HELP'), active: 'help' in routeParts || 'support' in routeParts, startIcon: _jsx(LiveHelpRounded, {}), children: [_jsx(AppBarMenuItem, { href: "https://help.securecodewarrior.com/hc/en-us/categories/5097427221007-Release-Notes9", target: "_blank", rel: "external", onClick: () => {
                                        try {
                                            $analyticsService.logEvent('click', {
                                                name: 'Release Notes',
                                                scope: 'Header Navigation',
                                                type: 'link',
                                            });
                                        }
                                        catch (e) {
                                            console.error(e);
                                        }
                                    }, children: translate('KNOWLEDGE_BASE_RELEASE_NOTES_TOP_MENU') }), [
                                    {
                                        condition: true,
                                        Component: (_jsx(AppBarMenuItem, { href: window.SCW_ENV.HelpdeskEndpoint, target: "_blank", rel: "external", children: translate('KNOWLEDGE_BASE_ARTICLES_TOP_MENU') }, "HELP_CENTER")),
                                    },
                                    {
                                        condition: !hideSupportApp,
                                        Component: (_jsx(AppBarMenuItem, { onClick: $rootScope.showZendesk, children: translate('IN_APP_ARTICLES') }, "IN_APP_ARTICLES")),
                                    },
                                    {
                                        condition: !hideUserSnapApp && !(isLMSInitialized() && hideUsersnapWhenLMS),
                                        Component: (_jsx(AppBarMenuItem, { onClick: $rootScope.showUsersnap, children: translate('SUBMIT_FEEDBACK') }, "SUBMIT_FEEDBACK")),
                                    },
                                    {
                                        condition: hasTutorial,
                                        Component: (_jsx(AppBarMenuItem, { onClick: $rootScope.showTutorial, children: translate('LAUNCH_TUTORIAL') }, "LAUNCH_TUTORIAL")),
                                    },
                                    {
                                        condition: isAuthenticated(),
                                        Component: (_jsx(AppBarMenuItem, { target: "_blank", href: "https://join.slack.com/t/scw-community/shared_invite/zt-23lz21hon-HGtHAy5jFet3hBDqkm8D9w", children: translate('JOIN_SLACK_COMMUNITY') }, "SLACK_COMMUNITY")),
                                    },
                                ]
                                    .filter(({ condition }) => condition)
                                    .map(({ Component }) => Component)] }), ('picker' in routeParts || ('simple-flow' in routeParts && !('simple-flow.signup' in routeParts))) && (_jsx(Button, { onClick: changeLanguageFramework, size: IconSize.small, startIcon: _jsx("i", { className: (simpleFlowCurrentLanguage === null || simpleFlowCurrentLanguage === void 0 ? void 0 : simpleFlowCurrentLanguage._id) && utility.languageIcon(simpleFlowCurrentLanguage) }), children: (metadata === null || metadata === void 0 ? void 0 : metadata.languages) &&
                                (simpleFlowCurrentLanguage === null || simpleFlowCurrentLanguage === void 0 ? void 0 : simpleFlowCurrentLanguage._id) &&
                                metadata.languages[simpleFlowCurrentLanguage._id].name })), isAuthenticated() && (_jsx(AppBarMenu, { component: _jsx(Avatar, { image: userProfile.image, name: userProfile.name || 'unknown', variant: "minified" }), label: "Profile", children: [
                                {
                                    condition: !Session.isAnonymous(),
                                    Component: (_jsx(AppBarMenuItem, { disabled: true, children: userProfile.email }, userProfile.email)),
                                },
                                {
                                    condition: Session.isAnonymous(),
                                    Component: (_jsx(AppBarMenuItem, { disabled: true, children: translate('YOU_ARE_ANON') }, "YOU_ARE_ANON")),
                                },
                                {
                                    condition: true,
                                    Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'profile' }), children: translate('MY_PROFILE_TOP_MENU') }, "MY_PROFILE")),
                                },
                                {
                                    condition: true,
                                    Component: (_jsx(AppBarMenuItem, { onClick: onAppBarButtonClick({ redirectPath: 'logout' }), children: translate('LOGOUT') }, "LOGOUT")),
                                },
                            ]
                                .filter(({ condition }) => condition)
                                .map(({ Component }) => Component) })), !isAuthenticated() && (_jsx(AppBarButton, { startIcon: getIcon('lock'), onClick: onAppBarButtonClick({ redirectPath: 'login' }), children: translate('LOGIN') })), versionMismatch && (_jsx(Button, { onClick: reloadPage, size: "small", children: translate('RELOAD') }))] })] }) })));
}
