'use strict';

export function resetWorldmap(worldmap) {
  return new Promise((res, rej) => {
    setTimeout(() => {
      if (!worldmap) {
        return rej();
      }

      worldmap.scale = 1;
      worldmap.transX = 0;
      worldmap.transY = 0;
      worldmap.applyTransform();
      return res();
    }, 1000);
  });
}
