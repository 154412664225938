import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.service('EarlyAccessApi', [
  'HttpConfigService',
  '$http',
  '$window',
  function (HttpConfigService, $http, $window) {
    const service = {};

    service.logCurrentSettings = function () {
      return $http.post(
        $window.SCW_ENV.ApiEndpoint + '/early-access/current/log',
        null,
        HttpConfigService.getHttpConfigNoIFR()
      );
    };

    return service;
  },
]);
