import angular from 'angular';
import MODULE from './module';
import * as _ from 'lodash';

angular.module(MODULE).controller('TournamentChallengeController', [
  '$window',
  '$log',
  '$back',
  '$rootScope',
  '$interval',
  '$translate',
  '$state',
  '$scope',
  '$sce',
  'TournamentsApiService',
  'Session',
  'AnalyticsService',
  'AnalyticsEvents',
  'AuthService',
  'ErrorHandler',
  function (
    $window,
    $log,
    $back,
    $rootScope,
    $interval,
    $translate,
    $state,
    $scope,
    $sce,
    TournamentsApiService,
    Session,
    AnalyticsService,
    AnalyticsEvents,
    AuthService,
    ErrorHandler
  ) {
    $window._tournamentQuest = $scope;

    let _tournament = $state.params._tournament;
    let _quest = $state.params._quest;

    if (!_quest) {
      $state.go('tournaments.list');
    }

    const { DEBUG_ENABLED, DEBUG_BAR_ENABLED } = $window.SCW_ENV;

    if (DEBUG_ENABLED !== undefined && DEBUG_BAR_ENABLED !== undefined) {
      $scope.debugBar = !!(DEBUG_ENABLED && DEBUG_BAR_ENABLED);
    } else {
      $scope.debugBar = false;
    }

    window.addEventListener('message', handleMessage);

    $scope.playmode = 'tournaments';
    $scope.tournament = null;
    $scope.quest = null;
    $scope.api = TournamentsApiService;
    $scope.state = { active: $state.params };
    $scope.backToMissions = backToMissions;
    $scope.beginChallenge = beginChallenge;
    $scope.nextChallenge = nextChallenge;
    $scope.skipChallenge = skipChallenge;
    $scope.handleMessage = handleMessage;
    $scope.launchMissionTutorial = launchMissionTutorial;
    $scope.playerReady = playerReady;

    if ($scope.debugBar) {
      $scope.hintUsed = hintUsed;
    }

    $scope.tutorial = Session.user.properties.tutorial;

    $scope.isShowingCountdown = false;
    $scope.isShowingLiveStats = true;
    $scope.timeIsUp = false;
    $scope.COUNTDOWN_TIMER_INTERVAL = 1000;
    $scope.countdownString = $translate.instant('LOADING');

    this.$onInit = function () {
      $scope.loading = true;

      TournamentsApiService.getChallenge($state.params)
        .then((response) => {
          // No challenges left
          if (!response) {
            return $state.go('tournaments.player', { _tournament });
          }

          $scope.quest = response;
          $scope.loading = false;

          if ($scope.quest.externalPlayerUrl) {
            if ($scope.debugBar) {
              $scope.playerSessionId = $scope.quest.externalPlayerUrl.split('?id=')[1];
            }
            const userLanguage = Session?.user?.properties?.profile?.i18nLanguagePreference || 'en';
            const urlObj = new URL($scope.quest.externalPlayerUrl);
            const searchParams = new URLSearchParams(urlObj.search);
            searchParams.set('lang', userLanguage);
            urlObj.search = searchParams.toString();
            $scope.externalPlayerUrl = $sce.trustAsResourceUrl(urlObj.toString());
            $rootScope.hideNavBar = true;
            $scope.extendAdversarySession();
          }

          if ($state.params.tournament) {
            setTournament($state.params.tournament);
          } else {
            TournamentsApiService.getTournament(_tournament).then(setTournament);
          }

          TournamentsApiService.getPlayerDetails(_tournament, Session.user).then(setPlayerDetails);
        })
        .catch(handleError);
    };

    $scope.extendAdversarySession = function () {
      var INTERVAL = 5 * 60 * 1000; // 5 minutes
      $scope.extendSessionInterval = $interval(extendSessionTimes, INTERVAL);
    };

    /**
     * Extending the session by calling the player details in between
     * This is to be done if we have actively loaded the adversary iframe
     * More checks are needed to distinguish so users dont record
     * unbelievable times
     */
    function extendSessionTimes() {
      return TournamentsApiService.getPlayerDetails(_tournament, Session.user).then(function () {
        return;
      });
    }

    function onStageCompletion() {
      return TournamentsApiService.getPlayerDetails(_tournament, Session.user).then(setPlayerDetails);
    }

    function setTournament(tournament) {
      $scope.quest.tournament = tournament;
      $scope.tournament = tournament;
      startCountdownTimer(tournament._id);
    }

    function startCountdownTimer(tournamentId) {
      if ($scope._countdownTimerInterval) {
        $log.debug('Timer already running');
        return;
      }

      TournamentsApiService.getCountdownForTournament(tournamentId)
        .then(function (data) {
          const { remainingSeconds } = data;
          $scope.countdownValue = Math.floor(remainingSeconds);
          $scope.timeLimit = ($scope.tournament.finishTime - $scope.tournament.startTime) / 1000;

          $scope.timeThreshold = 181; // time in seconds - go into final countdown mode at 3 mins unless configured
          if (_.get($scope, 'tournament.leaderboardOptions.options.hideBeforeEnd', 0)) {
            $scope.timeThreshold = $scope.tournament.leaderboardOptions.options.hideBeforeEnd / 1000 + 1;
          }

          handleCountdownValue();
          $scope._countdownTimerInterval = $interval(countdownTimerTick, $scope.COUNTDOWN_TIMER_INTERVAL);
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GETTING_COUNTDOWN'), response);
        });
    }

    function beginChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.BEGIN_CHALLENGE);
    }

    function nextChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.COMPLETE_CHALLENGE);
      return reload();
    }

    function skipChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.SKIP_CHALLENGE);
      $scope.loading = true;
      TournamentsApiService.skipChallenge({ _tournament, _quest }, $scope.quest.challengeAttemptId)
        .then(reload)
        .catch(handleError);
    }

    function reload() {
      return $state.go(
        $state.current.name,
        {
          tournament: $scope.tournament,
          _tournament,
          _quest,
        },
        { reload: true }
      );
    }

    function backToMissions() {
      $back().or('tournaments.player', $scope.state.active).with({ reload: true }).go();
    }

    function handleMessage(event) {
      if (!event.data || !event.data.event) {
        return;
      }

      switch (event.data.event) {
        case 'skipChallenge':
          return skipChallenge();
        case 'navigateToNextStage':
          return backToMissions();

        case 'navigateToMissions':
          return backToMissions();

        case 'playerReady':
          playerReady();
          break;

        case 'hintRated':
          return AnalyticsService.logEvent(AnalyticsEvents.Missions.MISSIONS_HINT_FEEDBACK, {
            tournament_id: $scope.tournament._id,
            instruction_name: event.data?.instruction,
            hint_id: event.data?.id,
            hint_description: event.data?.hint,
            hint_feedback: event.data?.rating ? 'thumbs up' : 'thumbs down',
            portal_area: 'tournaments',
          });

        case 'instructionCompleted':
          return AnalyticsService.logEvent(AnalyticsEvents.Missions.INSTRUCTIONS_COMPLETED, {
            tournament_languages: $scope.tournament.languages.map(
              (language) => `${language._id}:${language._framework}`
            ),
            tournament_id: $scope.tournament._id,
            tournament_name: $scope.tournament.name,
            missions_difficulty: $scope.quest.difficulty,
            missions_vulnerability_category: $scope.quest.category,
            instruction_id: event.data?.id,
            instruction_name: event.data?.title,
          });

        case 'cp:stageComplete':
          onStageCompletion();
          break;
      }
    }

    function countdownTimerTick() {
      // Defensive check in case countdownTimer cancellation was unsuccessful see PLAT-13175
      if ($scope.timeIsUp) return;
      $scope.countdownValue -= 1;
      handleCountdownValue();
    }

    function handleCountdownValue() {
      if (!$scope.isShowingCountdown && $scope.countdownValue < $scope.timeThreshold) {
        if ($scope.tournament.leaderboardOptions.options.hideBeforeEnd) {
          $scope.isShowingLiveStats = false;
        }
        $scope.isShowingCountdown = true;
      }

      $scope.countdownValuePercent = Math.round(($scope.countdownValue / $scope.timeLimit) * 100);

      $scope.countdownString = $rootScope.utility.longFormatDuration($scope.countdownValue * 1000);

      if ($scope.countdownValue <= 0) {
        $scope.countdownValue = 0;
        $scope.countdownString = 'Tournament Over';
        $scope.timeIsUp = true;
        sendTimeoutLog();
        stopCountdownTimer();

        if ($scope.tournament.leaderboardOptions.options.isDisplayed) {
          $scope.isShowingLiveStats = true;
        }
      }
    }

    function sendTimeoutLog() {
      $scope.api.sendTimeoutLog(_tournament);
    }

    function stopCountdownTimer() {
      try {
        if ($scope._countdownTimerInterval) {
          if ($interval.cancel($scope._countdownTimerInterval)) {
            $log.debug('_countdownTimerInterval cancelled - tournament challenge');
            $scope._countdownTimerInterval = undefined;
          } else {
            $log.warn('did not cancel _countdownTimerInterval - tournament challenge');
          }
        }
      } catch (e) {
        $log.error('Error when trying to cancel _countdownTimerInterval - tournament challenge', e);
      }
    }

    function setPlayerDetails(player) {
      // language is required by the hints modal component
      if (!$scope.player) {
        $scope.state.active = _.merge(
          {
            _language: player.language._id,
            _framework: player.language._framework,
          },
          $state.params
        );
      }
      $scope.player = player;
    }

    function playerReady() {
      const playerIframe = document.getElementById('adversary');
      if (playerIframe) {
        playerIframe.contentWindow.postMessage({ event: 'initialMessage', mode: 'tournament' }, '*');
      }

      if ($scope.tutorial.enabled && !$scope.tutorial.items.doneMission) {
        launchMissionTutorial(playerIframe);
      }
    }

    function launchMissionTutorial(playerIframe) {
      if (playerIframe) {
        playerIframe.contentWindow.postMessage({ event: 'startTutorial' }, '*');
      }

      $scope.tutorial.items.doneMission = true;

      AuthService.updateTutorialStatus($scope.tutorial)
        .then(function (data) {
          Session.user.properties.tutorial = data;
        })
        .catch(function (response) {
          ErrorHandler.addHttpError('Error updating tutorial status', response);
        });
    }

    function hintUsed() {
      if ($scope.debugBar) {
        console.log('[DEBUGBAR] Hint has been used.');
      }
    }

    function sendAnalyticsEvent(eventName) {
      const eventProps = {
        challenge_id: $scope.quest?.challenge?._id,
        portal_area: $scope.playmode,
        language_framework: `${$scope.player?.language?._id}:${$scope.player?.language?._framework}`,
      };
      AnalyticsService.logEvent(eventName, eventProps);
    }

    function handleError(response) {
      $scope.error = {
        status: response.status || '',
        message: `${$translate.instant('ERROR_RETRIEVING_CHALLENGE')}: ${response.data?.error}`,
        onReload: reload,
        onBack: backToMissions,
      };
    }

    $scope.$on('$destroy', function () {
      if ($scope.extendSessionInterval) {
        $interval.cancel($scope.extendSessionInterval);
      }
      //Return back to the undefined state it was in
      $rootScope.hideNavBar = undefined;
      window.removeEventListener('message', handleMessage);

      stopCountdownTimer();
    });
  },
]);
