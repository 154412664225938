var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, MenuItem, RadioGroup, FormLabel, FormControlLabel, Radio, FormGroup, Switch, Autocomplete, Typography, } from '@mui/material';
import { DateTimePicker, MaxRetriesInput, SuccessRatioInput, TimeLimitInput, RetryWaitingHoursInput, useBoolInputBuffer, } from './Inputs';
import TextField from '@securecodewarrior/design-system-react/lib/base/legacy/TextField/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ErrorWithStoredValue } from './state';
import { useTranslation } from 'react-i18next';
import { multiassessmentAnalytis } from './analytics';
import { usePlatformContext } from '../platformContext';
import { useCallback } from 'react';
dayjs.extend(utc);
dayjs.extend(timezone);
const formLabelStyles = { fontWeight: 600, marginTop: '12px', fontSize: '16px' };
const defaultAvailableTimeZones = Intl.supportedValuesOf('timeZone');
export const TopLevelSettingsEditor = (props) => {
    var _a, _b, _c, _d;
    const { mode, editBuffer, essentials, timeZonesInUse } = props;
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const updateSettings = useCallback((update) => {
        const { name: _ } = update, payload = __rest(update, ["name"]);
        logAnalyticsEvent(multiassessmentAnalytis.editDefaultSetting(payload));
        return props.updateSettings(update);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logAnalyticsEvent, props.updateSettings]);
    const certificateValue = editBuffer.assessmentSettings.emitsCertificate
        ? editBuffer.assessmentSettings._certificateTemplate || 'default'
        : 'none';
    const extraTimeZones = [...new Set(timeZonesInUse.filter((tz) => !defaultAvailableTimeZones.includes(tz)))].filter((x) => !!x);
    const availableTimeZones = defaultAvailableTimeZones.concat(extraTimeZones);
    const startTimeValue = editBuffer.assessmentSettings.startDate
        ? dayjs
            .utc(editBuffer.assessmentSettings.startDate instanceof ErrorWithStoredValue
            ? editBuffer.assessmentSettings.startDate.currentValue
            : editBuffer.assessmentSettings.startDate)
            .tz(editBuffer.assessmentSettings.timezone || 'utc')
        : null;
    const endTimeValue = editBuffer.assessmentSettings.endDate
        ? dayjs
            .utc(editBuffer.assessmentSettings.endDate instanceof ErrorWithStoredValue
            ? editBuffer.assessmentSettings.endDate.currentValue
            : editBuffer.assessmentSettings.endDate)
            .tz(editBuffer.assessmentSettings.timezone || 'utc')
        : null;
    const sendInvitesOnValue = editBuffer.assessmentSettings.sendInvitesOn
        ? dayjs
            .utc(editBuffer.assessmentSettings.sendInvitesOn instanceof ErrorWithStoredValue
            ? editBuffer.assessmentSettings.sendInvitesOn.currentValue
            : editBuffer.assessmentSettings.sendInvitesOn)
            .tz(editBuffer.assessmentSettings.timezone || 'utc')
        : null;
    const selfAssess = useBoolInputBuffer(editBuffer.assessmentSettings.selfAssess, (checked) => {
        const sendInvitesUpdate = checked ? { sendInvitesOn: null } : {};
        updateSettings({
            assessmentSettings: Object.assign({ selfAssess: checked }, sendInvitesUpdate),
        });
    });
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: "en-gb", children: _jsxs(Box, { sx: {
                marginLeft: 6,
                marginRight: 3,
                display: 'flex',
                gap: 3,
                flexDirection: 'column',
                '& .MuiInputBase-input[type="text"]:not(.SearchInput-input):disabled': {
                    // global style in portal-frontend that's causing issues
                    backgroundColor: 'transparent',
                },
            }, children: [_jsx(Typography, { variant: "h5", sx: { marginBottom: 3.5 }, children: t('pages.multiassessments.defaultSettings') }), _jsx(TextField, { label: t('pages.multiassessments.namePrefix'), required: true, type: "text", variant: "outlined", value: editBuffer.name instanceof ErrorWithStoredValue ? editBuffer.name.currentValue : editBuffer.name || '', onChange: (ev) => updateSettings({ name: ev.target.value }), helperText: editBuffer.name instanceof Error ? editBuffer.name.message : '', error: editBuffer.name instanceof Error, sx: {
                        width: '100%',
                    }, InputProps: {
                        disabled: mode === 'view',
                    } }), _jsx(FormLabel, { sx: formLabelStyles, children: t('pages.multiassessments.schedulingOptions') }), _jsx(DateTimePicker, { label: t('pages.multiassessments.startDate'), format: "llll", disabled: mode === 'view' || editBuffer.assessmentSettings.lmsIntegrated, value: startTimeValue, timezone: editBuffer.assessmentSettings.timezone, maxDateTime: editBuffer.assessmentSettings.endDate && !(editBuffer.assessmentSettings.endDate instanceof Error)
                        ? dayjs.utc(editBuffer.assessmentSettings.endDate).tz(editBuffer.assessmentSettings.timezone || 'utc')
                        : undefined, onChange: (startDate) => {
                        var _a;
                        updateSettings({
                            assessmentSettings: {
                                startDate: (_a = startDate === null || startDate === void 0 ? void 0 : startDate.toDate()) !== null && _a !== void 0 ? _a : null,
                            },
                        });
                    }, slotProps: {
                        textField: editBuffer.assessmentSettings.startDate instanceof ErrorWithStoredValue
                            ? {
                                helperText: editBuffer.assessmentSettings.startDate.message,
                                error: true,
                            }
                            : { error: false, helperText: '' },
                        field: {
                            clearable: true,
                            onClear: () => updateSettings({
                                assessmentSettings: {
                                    startDate: null,
                                },
                            }),
                        },
                    } }), _jsx(DateTimePicker, { label: t('pages.multiassessments.endDate'), format: "llll", disabled: mode === 'view' || editBuffer.assessmentSettings.lmsIntegrated, value: endTimeValue, timezone: editBuffer.assessmentSettings.timezone, minDateTime: editBuffer.assessmentSettings.startDate && !(editBuffer.assessmentSettings.startDate instanceof Error)
                        ? dayjs.utc(editBuffer.assessmentSettings.startDate).tz(editBuffer.assessmentSettings.timezone || 'utc')
                        : undefined, onChange: (endDate) => {
                        var _a;
                        updateSettings({
                            assessmentSettings: {
                                endDate: (_a = endDate === null || endDate === void 0 ? void 0 : endDate.toDate()) !== null && _a !== void 0 ? _a : null,
                            },
                        });
                    }, slotProps: {
                        textField: editBuffer.assessmentSettings.endDate instanceof ErrorWithStoredValue
                            ? {
                                helperText: editBuffer.assessmentSettings.endDate.message,
                                error: true,
                            }
                            : { error: false, helperText: '' },
                        field: {
                            clearable: true,
                            onClear: () => updateSettings({
                                assessmentSettings: {
                                    endDate: null,
                                },
                            }),
                        },
                    } }), _jsx(Autocomplete, { onChange: (_, timezone) => { var _a; return updateSettings({ assessmentSettings: { timezone: (_a = timezone === null || timezone === void 0 ? void 0 : timezone.replaceAll(' / ', '/')) === null || _a === void 0 ? void 0 : _a.replaceAll(' ', '_') } }); }, options: availableTimeZones.map((tz) => tz.replaceAll('_', ' ').replaceAll('/', ' / ')), disableClearable: true, value: (_b = (_a = editBuffer.assessmentSettings.timezone) === null || _a === void 0 ? void 0 : _a.replaceAll('_', ' ')) === null || _b === void 0 ? void 0 : _b.replaceAll('/', ' / '), renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: t('pages.multiassessments.timeZone') })), disabled: mode === 'view' }), _jsx(FormLabel, { sx: Object.assign(Object.assign({}, formLabelStyles), { marginBottom: '-6px' }), children: t('pages.multiassessments.timeLimitKey') }), _jsxs(RadioGroup, { value: editBuffer.timeLimitIsPerChallenge ? 'per-challenge' : 'total', name: "radio-buttons-group", sx: { flexDirection: 'row', marginBottom: 0 }, children: [_jsx(FormControlLabel, { value: "total", control: _jsx(Radio, {}), label: t('pages.multiassessments.total'), onChange: () => updateSettings({ timeLimitIsPerChallenge: false }), sx: { marginBottom: 0 }, disabled: mode === 'view' }), _jsx(FormControlLabel, { value: "per-challenge", control: _jsx(Radio, {}), label: t('pages.multiassessments.perChallenge'), onChange: () => updateSettings({ timeLimitIsPerChallenge: true }), sx: { marginBottom: 0 }, disabled: mode === 'view' })] }), _jsx(TimeLimitInput, { per: editBuffer.timeLimitIsPerChallenge ? 'challenge' : 'total', value: editBuffer.assessmentSettings.timeLimit, disabled: mode === 'view', onChange: (timeLimit) => updateSettings({
                        assessmentSettings: {
                            timeLimit,
                        },
                    }) }), _jsx(FormLabel, { sx: formLabelStyles, children: t('pages.multiassessments.passFailOptions') }), _jsx(SuccessRatioInput, { value: editBuffer.assessmentSettings.successRatio, disabled: mode === 'view', onChange: (successRatio) => {
                        updateSettings({
                            assessmentSettings: {
                                successRatio,
                            },
                        });
                    } }), _jsx(CertificateTemplateInput, { value: certificateValue instanceof ErrorWithStoredValue
                        ? (_c = certificateValue.currentValue) !== null && _c !== void 0 ? _c : undefined
                        : certificateValue, onChange: (certificate) => {
                        const emitsCertificate = certificate !== 'none';
                        const _certificateTemplate = emitsCertificate ? (certificate !== 'default' ? certificate : '') : null;
                        updateSettings({
                            assessmentSettings: {
                                emitsCertificate,
                                _certificateTemplate,
                            },
                        });
                    }, certificates: essentials.certificateTemplates || [], disabled: mode === 'view' }), _jsx(FormLabel, { sx: Object.assign(Object.assign({}, formLabelStyles), { marginBottom: '-6px' }), children: t('pages.multiassessments.userRetries') }), _jsx(FormGroup, { children: _jsx(FormControlLabel, { sx: { marginBottom: 0 }, disabled: editBuffer.assessmentSettings.lmsIntegrated || mode === 'view', control: _jsx(Switch, { checked: editBuffer.assessmentSettings.maxRetries !== 0, onChange: (event) => {
                                const maxRetries = event.target.checked ? null : 0;
                                updateSettings({
                                    assessmentSettings: {
                                        maxRetries,
                                        retryWaitingHours: null,
                                    },
                                });
                            } }), label: t('pages.multiassessments.allowUserRetries') }) }), editBuffer.assessmentSettings.maxRetries !== 0 && (_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(MaxRetriesInput, { disabled: mode === 'view' || editBuffer.assessmentSettings.lmsIntegrated, value: editBuffer.assessmentSettings.maxRetries, onChange: (maxRetries) => {
                                const extraUpdate = maxRetries === 0 ? { retryWaitingHours: null } : {};
                                updateSettings({
                                    assessmentSettings: Object.assign({ maxRetries: maxRetries !== null && maxRetries !== void 0 ? maxRetries : null }, extraUpdate),
                                });
                            } }), _jsx(RetryWaitingHoursInput, { disabled: mode === 'view' || editBuffer.assessmentSettings.lmsIntegrated, value: editBuffer.assessmentSettings.retryWaitingHours, onChange: (retryWaitingHours) => updateSettings({
                                assessmentSettings: {
                                    retryWaitingHours,
                                },
                            }) })] })), _jsx(FormLabel, { sx: Object.assign(Object.assign({}, formLabelStyles), { marginBottom: '-6px' }), children: t('pages.multiassessments.invitationOptions') }), _jsx(FormGroup, { children: _jsx(FormControlLabel, { sx: { marginBottom: 0 }, disabled: mode === 'view' || editBuffer.assessmentSettings.lmsIntegrated, control: _jsx(Switch, { checked: selfAssess.checked, disabled: editBuffer.assessmentSettings.lmsIntegrated, onChange: selfAssess.update }), label: t('pages.multiassessments.allowUsersToSelfAssess') }) }), _jsx(DateTimePicker, { label: t('pages.multiassessments.sendInvitesOnEllipsis'), format: "llll", disabled: mode === 'view' || editBuffer.assessmentSettings.selfAssess, maxDateTime: editBuffer.assessmentSettings.startDate && !(editBuffer.assessmentSettings.startDate instanceof Error)
                        ? dayjs.utc(editBuffer.assessmentSettings.startDate).tz(editBuffer.assessmentSettings.timezone || 'utc')
                        : editBuffer.assessmentSettings.endDate && !(editBuffer.assessmentSettings.endDate instanceof Error)
                            ? dayjs.utc(editBuffer.assessmentSettings.endDate).tz(editBuffer.assessmentSettings.timezone || 'utc')
                            : undefined, value: sendInvitesOnValue, timezone: editBuffer.assessmentSettings.timezone, onChange: (date) => { var _a; return updateSettings({ assessmentSettings: { sendInvitesOn: (_a = date === null || date === void 0 ? void 0 : date.toDate()) !== null && _a !== void 0 ? _a : null } }); }, slotProps: {
                        textField: editBuffer.assessmentSettings.sendInvitesOn instanceof ErrorWithStoredValue
                            ? {
                                helperText: editBuffer.assessmentSettings.sendInvitesOn.message,
                                error: true,
                            }
                            : { error: false, helperText: '' },
                        field: {
                            clearable: true,
                            onClear: () => updateSettings({
                                assessmentSettings: {
                                    sendInvitesOn: null,
                                },
                            }),
                        },
                    } }), _jsx(FormLabel, { sx: Object.assign(Object.assign({}, formLabelStyles), { marginBottom: '-6px' }), children: t('pages.multiassessments.otherSettings') }), _jsx(FormGroup, { children: _jsx(FormControlLabel, { sx: { marginBottom: 0 }, disabled: mode === 'view', control: _jsx(Switch, { checked: editBuffer.assessmentSettings.fixedChallenges, onChange: (event) => {
                                updateSettings({
                                    assessmentSettings: {
                                        fixedChallenges: event.target.checked,
                                    },
                                });
                            } }), label: t('pages.multiassessments.fixedSetOfChallengesForAssessment') }) }), _jsx(FormGroup, { children: _jsx(FormControlLabel, { sx: { marginBottom: 0 }, disabled: mode === 'view', control: _jsx(Switch, { checked: editBuffer.assessmentSettings.lmsIntegrated, onChange: (event) => {
                                const extraUpdates = event.target.checked
                                    ? {
                                        startDate: null,
                                        endDate: null,
                                        selfAssess: true,
                                        sendInvitesOn: null,
                                        maxRetries: null,
                                        retryWaitingHours: null,
                                    }
                                    : {};
                                updateSettings({
                                    assessmentSettings: Object.assign({ lmsIntegrated: event.target.checked }, extraUpdates),
                                });
                            } }), label: t('pages.multiassessments.lmsIntegration') }) }), ((_d = essentials === null || essentials === void 0 ? void 0 : essentials.levelGroupings) === null || _d === void 0 ? void 0 : _d.length) > 0 && (_jsx(LevelGroupInput, { value: editBuffer.assessmentSettings.levelGrouping || '', onChange: (value) => updateSettings({ assessmentSettings: { levelGrouping: value } }), levelGroupings: essentials.levelGroupings, disabled: mode === 'view' }))] }) }));
};
function LevelGroupInput(props) {
    const { t } = useTranslation();
    return (_jsxs(TextField, { label: t('pages.multiassessments.levelGrouping'), select: true, variant: "outlined", value: props.value, onChange: (ev) => props.onChange(ev.target.value), InputProps: {
            disabled: props.disabled,
        }, children: [_jsx(MenuItem, { value: "", children: t('pages.multiassessments.none') }), props.levelGroupings.map((option) => (_jsx(MenuItem, { value: option._id, children: option.name }, option._id)))] }));
}
function CertificateTemplateInput(props) {
    const { t } = useTranslation();
    return (_jsxs(TextField, { label: t('pages.multiassessments.certificate'), select: true, variant: "outlined", value: props.value, onChange: (ev) => props.onChange(ev.target.value), InputProps: {
            disabled: props.disabled,
        }, children: [_jsx(MenuItem, { value: "none", children: t('pages.multiassessments.none') }), _jsx(MenuItem, { value: "default", children: t('pages.multiassessments.default') }), props.certificates.map((option) => (_jsx(MenuItem, { value: option._id, children: option.name }, option._id)))] }));
}
