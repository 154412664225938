import angular from 'angular';
import MODULE from './module';
import templateUrl from './keyboard-navigation.directive.help.html';

(function (moduleName) {
  /**
   * When this keys are _down(true)_, navigation won't act.
   */
  var controlKeys = ['Control', 'Alt'];

  /**
   * Keep track of pressed keys.
   */
  var keysDown = {};

  /**
   * Keep track of the state of the Main, Auxiliary and Secondary mouse buttons
   * so that we can make an assumption that events are not instigated by the keyboard
   * Index 0 = Main, 1 = Auxiliary, 2 = Secondary
   */
  const mouseButtonsDown = new Array(3).fill(false);

  /**
   * Clean-up currently _focused_ and marked-active elements.
   * (used on feature de-activation).
   */
  function cleanup() {
    $('.keyboard-selected, :focus').removeClass('keyboard-selected');
  }

  /**
   * Return a valid index based on :idx for an array :group,
   * properly supporting overflowing.
   * Used for last-to-first && first-to-last transitions.
   * @param {Number} idx
   * @param {Array} group
   */
  function arraybound(idx, group) {
    if (idx >= group.length) return arraybound(idx - group.length, group);
    if (idx < 0) return arraybound(idx + group.length, group);

    return idx;
  }

  var navigableSection; // current navigableSection
  var navigationEnabled = false;
  var direction = 1; // directionality (changes with ShiftKey[Down|Up])

  /**
   * Execute :thenFn only if navigation is enabled.
   * @param {Function} thenFn
   * @returns Function generated conditional function
   */
  function ifNavigationIsEnabled(thenFn) {
    return function () {
      // implicit browser's function call stack :arguments
      if (!navigationEnabled) return true;
      return thenFn.apply(this, arguments);
    };
  }

  angular
    .module(moduleName)
    /**
     * General configuration and event bindings to jump through navigable sections.
     * [Tab] key (or Shift+Tab) will allow to navigate through major navigable sections
     * (marked by the same keyboard-navigation directive).
     */
    .run([
      '$window',
      '$log',
      '$rootScope',
      '$swal',
      '$translate',
      '$timeout',
      'UserPreferences',
      function ($window, $log, $rootScope, $swal, $translate, $timeout, UserPreferences) {
        var allowJump = true;

        angular.element($window).on('keydown', ifNavigationIsEnabled(jumpNavigableSection));
        angular.element($window).on('blur', deactivateControlKeys);

        // angular.element($window).on("keydown keyup", toggleInverse);
        angular.element($window).on('keydown keyup', keyTracking);
        angular.element($window).on('keydown', keyboardActivation);
        angular.element($window).on('mousedown mouseup', mouseButtonUpDownTracking);

        $rootScope.$on('user-preferences:updated', function (_event, preferences) {
          var newState = _.get(preferences, 'accessibility.keyboardNavigation');
          if (navigationEnabled !== newState) setActive(newState);
        });

        $rootScope.$on('user-preferences:loaded', function (_event, preferences) {
          setActive(_.get(preferences, 'accessibility.keyboardNavigation'));
        });

        /**
         * Track which keys are being held.
         * @param {KeyboardEvent} event
         */
        function keyTracking(event) {
          keysDown[event.key] = event.type === 'keydown';
        }

        /**
         * Track the state of the main mouse buttons.
         * Note: this function is designed with the assumption that
         * it is bound to 'mousedown' and 'mouseup' only.
         * @param {MouseEvent} event
         */
        function mouseButtonUpDownTracking(event) {
          if (0 <= event.button && event.button <= 2) {
            mouseButtonsDown[event.button] = event.type === 'mousedown';
          }
        }

        /**
         * Deactivate all control keys on window.blur.
         * @param {*} event
         */
        function deactivateControlKeys() {
          keysDown = {};
        }

        /**
         * Toggle feature activation.
         * @param {*} event
         */
        function keyboardActivation(event) {
          if (event && event.key && event.key.toLowerCase() !== 'k') return;
          if (!keysDown['Control']) return;
          if (!keysDown['Shift']) return;

          event && event.preventDefault && event.preventDefault();
          event && event.stopPropagation && event.stopPropagation();

          var newState = !navigationEnabled;
          setActive(newState);
          UserPreferences.save('accessibility.keyboardNavigation', newState);

          var inform = {
            enabled: {
              html: true,
              type: 'success',
              title: $translate.instant('KeyboardNavigation.StateChange.Enabled.title'),
              text: $translate.instant('KeyboardNavigation.StateChange.Enabled.text'),
              keyResolution: true,
              confirmButtonText: $translate.instant('OK'),
            },
            disabled: {
              html: true,
              type: 'warning',
              title: $translate.instant('KeyboardNavigation.StateChange.Disabled.title'),
              text: $translate.instant('KeyboardNavigation.StateChange.Disabled.text'),
              keyResolution: true,
              confirmButtonText: $translate.instant('Ok'),
            },
          };

          $swal(inform[(newState && 'enabled') || 'disabled']).then(function (resolution) {
            if (resolution === 'help') {
              $log.debug(
                '[knav-enabed] resolution ',
                resolution,
                $translate.instant('KeyboardNavigation.Help.GeneralBehavior.Explanation.title')
              );
              $timeout(function () {
                $swal({
                  type: 'info',
                  title: $translate.instant('KeyboardNavigation.Help.GeneralBehavior.Explanation.title'),
                  templateUrl,
                  keyResolution: true,
                  scope: $rootScope,
                  confirmButtonText: $translate.instant('OK'),
                });
              }, 1000);
            }
          });
        }

        function setActive(enable) {
          navigationEnabled = enable;
          $('body')[(enable && 'addClass') || 'removeClass']('keyboard-navigation-enabled');
          $('body')[(enable && 'addClass') || 'removeClass']('keyboard-navigation-help-enabled');

          cleanup();
          if (navigationEnabled) $('.keyboard-navigation:not([skip-tab]):visible').first().trigger('focus');
        }

        // /**
        //  * Handle directionality of section jump when Tab key is pressed.
        //  * @param {KeyboardEvent} event
        //  */
        // function toggleInverse(event) {
        // 	if (event.key === "Shift")
        // 		direction = event.type === "keydown" ? -1 : 1;
        // }

        /**
         * Jump to the next/previous navigable section when Tab key is pressed.
         * :direction var will establish the directionality of the loop
         * used to find the next section.
         * @param {KeyboardEvent} event
         */
        function jumpNavigableSection(event) {
          // $log.debug("[keyboard-navigation] jumpNavigableSection()", event.key);
          if (!allowJump) return;

          if (event.key === 'Tab') {
            var curridx, nextidx;
            var sections = $('[keyboard-navigation]:visible:not([skip-tab])');
            var target = $(event.target);
            var within = target.closest(navigableSection);
            var direction = (keysDown['Shift'] && -1) || 1;
            $log.debug('[jumpSection] with context', navigableSection, sections);

            if (!navigableSection) {
              navigableSection = sections.first();
            } else {
              if (within[0]) {
                curridx = sections.index(navigableSection);
                nextidx = arraybound(curridx + 1 * direction, sections);
                navigableSection = $(sections[nextidx]);
              } else navigableSection = sections.first();

              $log.debug('[jumpSection] curr vs next', curridx, nextidx);
              cleanup();
            }

            if (navigableSection) {
              // $log.debug("[keyboard-navigation] Jump to section: ", navigableSection);
              event.preventDefault();
              event.stopPropagation();

              navigableSection.attr('tabindex', 0);
              navigableSection.focus();
              // let the section deal with it's own *current*
            }
          }
        }
      },
    ])

    /**
     * Actual keyboard-navigation directive used to configure
     * _NavigableElement(s)_ within a _NavigableSection_
     * and handle Arrow[Key] navigation.
     *
     * The directive will look at its own attribute (keyboard-navigation)
     * to find applicable selectors that allow to identify _NavigableElement(s)_
     * in this _NavigableSection_ (that being, all elements inside
     * the DOMElement containing the directive).
     *
     * ## Example usage:
     * ```
     * <div navigable-section="li:not(.disabled), button">
     * 	<ul>
     * 		<li>This item is navigable</li>
     * 		<li class="disabled">This item is not navigable</li>
     * 		<li>This item is also navigable</li>
     *  </ul>
     * 	<button>This button is navigable</button>
     * </div>
     * ```
     */
    .directive('keyboardNavigation', [
      '$log',
      '$timeout',
      function ($log, $timeout) {
        return {
          restrict: 'A',
          // transclude: true,
          // template: "<ng-transclude></ng-transclude>",
          priority: 5,
          scope: {
            keyboardBindings: '=?',
            keyboardExtension: '=?',
          },
          link: function (scope, element, attrs) {
            element.attr('tabindex', 0); // make focusable

            /*
             * Support event.key differences on IE11
             */
            var keyAliases = {
              Right: 'ArrowRight',
              Left: 'ArrowLeft',
              Down: 'ArrowDown',
              Up: 'ArrowUp',
            };

            var current = null; // currently selected _NavigableElement_
            var bindings = null;

            // Associate _event.key_ to a function handler.
            var gridBindings = {
              ArrowLeft: goLeft,
              ArrowRight: goRight,
              ArrowDown: goDown,
              ArrowUp: goUp,
              Enter: triggerClick,
              Tab: kidnapNavigation,
            };

            var formBindings = {
              // ArrowLeft: justDefault,
              // ArrowRight: justDefault,
              // ArrowDown: justDefault,
              // ArrowUp: justDefault,
              Enter: triggerClick,
              Tab: navigateForm,
            };

            element.on('DOMSubtreeModified', ifNavigationIsEnabled(makeFocusable));
            element.on('keydown', ifNavigationIsEnabled(keyHandler)); // bind to main handler
            element.on('focusin', ifNavigationIsEnabled(onSectionFocus));

            init();

            function init() {
              bindings = isForm() ? formBindings : gridBindings;
            }

            /**
             * Configure _override_ and _extend_ bindings.
             */
            scope.$watchGroup(['keyboardBindings', 'keyboardExtension'], function (n, _o) {
              bindings = scope.keyboardBindings || defaultBindings();

              if (n) {
                bindings = _.merge({}, bindings, scope.keyboardExtension);
                $log.debug('[keyboard-navigation] using custom bindings.', bindings);
              }
            });

            /**
             * Built-in grid and form navigation bindings.
             */
            function defaultBindings() {
              return isForm() ? formBindings : gridBindings;
            }

            /**
             * Make sure NavigableElement's are focusable after
             * DOM tree change.
             */
            function makeFocusable() {
              var fn = makeFocusable;
              if (!fn.inprogress) {
                fn.inprogress = true;
                $timeout(function () {
                  var navigable = $(attrs.keyboardNavigation, element).visible();
                  navigable.attr('tabindex', 0);
                  fn.inprogress = false;
                }, 100);
              }
            }

            /**
             * Define if current is a navigable form configuration.
             */
            function isForm() {
              return element[0].tagName === 'FORM' || attrs.isForm;
            }

            /**
             * Handle basic configurations and behaviors
             * when a given _NavigableSection_ is focused.
             * @param {FocusEvent} event
             */
            function onSectionFocus(event) {
              $log.debug('[onSectionFocus] focus event', event.type);
              if (event.target && ~['INPUT', 'TEXTAREA'].indexOf(event.target.tagName)) return false;
              // Ignore section focus event if we think it was related to a mouse button press
              if (mouseButtonsDown.some((buttonPressed) => buttonPressed === true)) return false;

              stopPropagation(event);
              // var prefocused = false;
              var prev = null;
              var target = $(event.target);
              navigableSection = target.closest('[keyboard-navigation]:visible');

              cleanup();

              var navigable = $(attrs.keyboardNavigation, element).visible();
              if (event && event.target) {
                event.target.style.outline = '0';
              }

              if (event.target !== element[0]) {
                // $log.debug("[onSectionFocus] Analyzing focusin on target", event.target);
                var focused = $(event.target);
                if (navigable.index(focused) > -1) {
                  $log.debug('[onSectionFocus] Setting prefocus', event.target);
                  // prefocused = true;
                  event.stopPropagation();
                  current = focused;
                  return onSelectionChange(focused, null);
                }
              }

              // _form hack_ => use directionality to decide between first and last element
              if (event.target === element[0] && isForm()) {
                current = direction === 1 ? navigable.first() : navigable.last();
              }

              if (!current || !current.is(':visible')) {
                $log.debug('[onSectionFocus] No current selection.');
                current = navigable.first();
              }

              if (current !== prev) onSelectionChange(current, prev);
            }

            function controlKeysDown() {
              var pressed = _.chain(keysDown)
                .keys()
                .filter(function (key) {
                  return keysDown[key];
                })
                .value();

              var found = _.intersection(pressed, controlKeys);
              return (found.length && found) || false;
            }

            /**
             * This _main_ handler takes care of dealing with the actual
             * selection logic, highlighting (through css class) and
             * setting focus on the provided _NavigableElement_.
             *
             * It will rely on the _bindings_ given to provide
             * the appropriate element for a given key instruction.
             *
             * @param {KeyboardEvent} event
             */
            function keyHandler(event) {
              var key = event.key; // key pressed
              var controlKeys = controlKeysDown();
              if (controlKeys) {
                $log.debug('[keyHandler] control keys are down. Ignoring.', controlKeys);
                return true; // don't interfere with smart key combinations
              }

              if (keyAliases[key])
                // IE11 support
                key = keyAliases[key];

              if (bindings[key]) {
                $log.debug('[keyHandler] received', event.key, bindings[key], event, bindings);
                var navigable = $(attrs.keyboardNavigation, element).visible();
                var prev = current; // keep previous selection
                var candidate = bindings[key](navigable, current, event);

                // $log.debug("[keyHandler] candidate is now", candidate);
                if (candidate && typeof candidate === 'object') {
                  $log.debug('[keyHandler] candidate is now', candidate);
                  stopPropagation(event);

                  if (candidate !== prev) {
                    current = candidate;
                    onSelectionChange(current, prev);
                  }
                } else return candidate;
              }

              return true;
            }

            /**
             * Stop propagation if possible.
             *
             * @param {DOMEvent} event
             */
            function stopPropagation(event) {
              if (event) {
                event.preventDefault && event.preventDefault();
                event.stopPropagation && event.stopPropagation();
              }
            }

            /**
             * Make sure :current is properly visible on screen.
             * @param {JQuery} current
             */
            function scrollToView(current) {
              var d = {};
              d.w_top = $(window).scrollTop();
              d.w_bottom = d.w_top + $(window).height();
              d.e_top = 0;
              d.e_bottom = 0;

              var _current = $(current);
              if (_current) {
                d.e_top = _current.offset() && _current.offset().top;
                d.e_bottom = d.e_top + _current.height();
              }

              $log.debug('[scrollToView] data', d);
              if (d.e_bottom >= d.w_bottom || d.e_top <= d.w_top) {
                // IE11 specific (scroll into view is not great...)
                if (current[0].setActive) {
                  $(window).scrollTop(d.e_top - 150 < 0 ? 0 : d.e_top - 150);
                } else {
                  current[0].scrollIntoView({
                    block: 'center',
                    inline: 'start',
                  });
                }
              }
            }

            /**
             * Highlight and configure the newly selected element.
             *
             * @param {JQuery} current
             * @param {JQuery} prev
             * @param {String} key
             */
            function onSelectionChange(current, prev, prefocused) {
              if (current && prev !== current) {
                cleanup();

                current.addClass('keyboard-selected'); // highlight current
                if (!prefocused) {
                  // prevent infinite recursion
                  current.attr('tabindex', 0); // make focusable
                  if (current[0] && document.activeElement !== current[0]) {
                    // IE11 hack:
                    // setActive is only available and used
                    // on IE11 to prevent horizontal scrolling jumps
                    try {
                      (current[0].setActive && current[0].setActive()) || current.focus(); // focus current
                    } catch (e) {
                      // ie11  sometimes complains, but the function actually acts on DOM.
                    }
                  }
                }

                scrollToView(current);
                // if (current[0].scrollIntoView)
                // 	current[0].scrollIntoView({
                // 		behavior: "smooth",
                // 		// block: "center",
                // 		inline: "start"
                // 	});
              }
            }

            /**
             * Find and return the best candidate to be selected
             * that is BELOW the currently selected element.
             *
             * If there is no candidate *below* it will return the best candidate
             * at the very top of the section(circular navigation).
             *
             * @param {JQuery[]} navigable
             * @param {JQuery} current
             * @returns {JQuery} JQuery wrapper for DOMElement to be selected
             */
            function goDown(navigable, current) {
              return upDown(navigable, current, 'down') || goRight();
            }

            /**
             * Find and return the best candidate to be selected
             * that is ABOVE the currently selected element.
             *
             * If there is no candidate *above* it will return the best candidate
             * at the very bottom of the section (circular navigation).
             *
             * @param {JQuery[]} navigable
             * @param {JQuery} current
             * @returns {JQuery} JQuery wrapper for DOMElement to be selected
             */
            function goUp(navigable, current) {
              return upDown(navigable, current, 'up') || goLeft();
            }

            /**
             * Handles the actual *vertical navigation*.
             * This function tries and finds the best candidate for selection
             * when vertical navigation is required (up/down arrow keys).
             *
             * @param {JQuery[]} navigable
             * @param {JQuery} current
             * @param {String} direction
             * @returns {JQuery} JQuery wrapper for DOMElement to be selected
             */
            function upDown(navigable, current, direction) {
              var initOffset = direction === 'up' ? -1 : 1;
              if (!current) return $(navigable[0]).focus();

              current = $(current) || $(navigable[0]);
              var next = current;

              if (current && current[0]) {
                var currx = current.offset().left + current.position().left; // current x position
                var curridx = arraybound(navigable.index(current), navigable); // current index in *navigable*
                var candidate, champion; // contenders
                var candidateOffset, championOffset; // contenders' offset in relationship to *current*
                var idx = arraybound(curridx + initOffset, navigable);

                // iterate through *navigable* elements trying to find best candidate
                for (; idx != curridx; idx = arraybound(idx + initOffset, navigable)) {
                  candidate = $(navigable[idx]); // current candidate to check
                  // calculate delta between this candidate and *current* X position
                  candidateOffset = Math.abs(currx - candidate.offset().left - candidate.position().left);

                  // is this a better candidate?
                  if (!champion || candidateOffset < championOffset) {
                    champion = candidate;
                    championOffset = candidateOffset;

                    // workaround: 40px is close enough, prevent from keep going down
                    // (or worse, jump to top) just because another candidate
                    // happens to be a few px closer to X.
                    if (championOffset < 40) break; // drop it. 40px is close enough
                  }
                }

                next = champion; // found our best _NavigableElement_
              }

              return next;
            }

            /**
             * Return the "next" navigable element to the right.
             *
             * @param {JQuery[]} navigable
             * @param {Jquery} current
             * @returns {JQuery} JQuery wrapper for DOMElement to be selected
             */
            function goRight(navigable, current) {
              if (navigable && navigable.length) {
                var next = $(current ? navigable[arraybound(navigable.index(current) + 1, navigable)] : navigable[0]);
                return next;
              }
              return current;
            }

            /**
             * Return the "next" navigable element to the left.
             *
             * @param {JQuery[]} navigable
             * @param {Jquery} current
             * @returns {JQuery} JQuery wrapper for DOMElement to be selected
             */
            function goLeft(navigable, current) {
              if (navigable && navigable.length) {
                var next = $(current ? navigable[arraybound(navigable.index(current) - 1, navigable)] : navigable[0]);
                return next;
              }
              return current;
            }

            /**
             * Kidnap navigation handling into this _NavigableSection_
             * (useful for dialogs and $swal's).
             * @param {JQuery[]} _navigable
             * @param {JQuery} current
             * @param {DOMEvent} _event
             */
            function kidnapNavigation(_navigable, current, _event) {
              // $log.debug("[keyboard-navigation] kidnap navigation?", !!attrs.kidnapNavigation);
              if (attrs.kidnapNavigation) return current;

              return true;
            }

            /**
             *
             * @param {JQuey[]} _navigable
             * @param {Jquery} current
             * @returns {Boolean}		`
             */
            function triggerClick(_navigable, current, _event) {
              return current && current.click();
            }

            /**
             *
             * @param {JQuery[]} navigable
             * @param {Jquery} current
             * @param {DOMEvent} event
             */
            function navigateForm(navigable, current, event) {
              var currentIndex = navigable.index(current);
              var direction = (keysDown['Shift'] && -1) || 1;

              $log.debug('[navigatForm] directionality is ', direction);
              if (!attrs.kidnapNavigation) {
                if (direction === 1 && currentIndex === navigable.length - 1) {
                  // // $log.debug("[keyboard-navigation] Last form element reached. Jumping out.");
                  return true;
                }

                if (direction === -1 && currentIndex === 0) {
                  // // $log.debug("keyboard-navigation] First form element reached. Jumping out.");
                  return true;
                }
              }

              // if not end of form
              stopPropagation(event);
              var next = direction === 1 ? goRight(navigable, current) : goLeft(navigable, current);
              return next || current;
            }
          },
        };
      },
    ]);
})(MODULE);
