import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { DataGridPro, 
// eslint-disable-next-line import/named
GridToolbarColumnsButton, 
// eslint-disable-next-line import/named
GridToolbarContainer, 
// eslint-disable-next-line import/named
GridToolbarDensitySelector, useGridApiContext, } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import JiraCsvSummaryParser from '../molecules/JiraCsvSummaryParser';
import * as React from 'react';
import { Button as MuiButton } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Button } from '@securecodewarrior/design-system-react/lib/legacy';
const CustomExportButton = ({ onClick, translator, logEvent }) => {
    const handleClick = () => {
        logEvent('download', {
            report_type: 'Vulnerability Summary',
            success: 'yes',
        });
        onClick();
    };
    return (_jsx(MuiButton, { "data-testid": "data-grid-export-btn", onClick: handleClick, "aria-label": "export", size: "small", color: "primary", startIcon: _jsx(FileDownloadOutlined, {}), children: translator('EXPORT') }));
};
// TODO: Removed filter as there are many texts to be translated
const CustomToolbar = ({ translator, logEvent }) => {
    const apiRef = useGridApiContext();
    return (_jsxs(GridToolbarContainer, { children: [_jsx(GridToolbarColumnsButton, {}), _jsx(GridToolbarDensitySelector, {}), _jsx(CustomExportButton, { onClick: apiRef.current.exportDataAsCsv, translator: translator, logEvent: logEvent })] }));
};
const localizeDataGrid = (translator) => ({
    // Toolbar Labels
    toolbarColumns: translator('COLUMNS'),
    toolbarFilters: translator('FILTERS'),
    toolbarDensity: translator('DENSITY'),
    toolbarExport: translator('EXPORT'),
    // DENSITY Options
    toolbarDensityCompact: translator('COMPACT'),
    toolbarDensityStandard: translator('STANDARD'),
    toolbarDensityComfortable: translator('COMFORTABLE'),
    //Export Options
    toolbarExportLabel: translator('EXPORT'),
    toolbarExportCSV: translator('DOWNLOAD_AS_CSV'),
    toolbarExportPrint: translator('PRINT'),
    // Columns panel text
    columnsPanelTextFieldLabel: translator('FIND_COLUMN'),
    columnsPanelTextFieldPlaceholder: translator('COLUMN_TITLE'),
    columnsPanelDragIconLabel: translator('REORDER_COLUMN'),
    columnsPanelShowAllButton: translator('SHOW_ALL'),
    columnsPanelHideAllButton: translator('HIDE_ALL'),
    // Total row amount footer text
    footerTotalRows: translator('TOTAL_ROWS:'),
    footerRowSelected: (count) => count !== 1 ? translator('#_ROWS_SELECTED', { count }) : translator('#_ROW_SELECTED', { count }),
});
const localiseColumns = (translator) => [
    {
        field: 'categoryName',
        headerName: translator('CATEGORY'),
        flex: 1,
    },
    {
        field: 'subcategoryName',
        headerName: translator('SUBCATEGORY'),
        flex: 1,
    },
    {
        field: 'slugs',
        headerName: translator('IDENTIFIER'),
        flex: 1,
    },
    {
        field: 'numberOfRecords',
        headerName: translator('NUMBER_OF_RECORDS'),
        flex: 1,
    },
];
const getSortingDirection = (direction) => {
    if (direction === 'asc') {
        return 'ascending';
    }
    if (direction === 'desc') {
        return 'descending';
    }
    return 'None';
};
export const VulnerabilitySummaryReporter = (props) => {
    const [vulnerabilityInfo, setVulnerabilityInfo] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        categoryName: true,
        subcategoryName: true,
        slugs: false,
        numberOfRecords: true,
    });
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'numberOfRecords',
            sort: 'desc',
        },
    ]);
    useEffect(() => {
        props.logEvent('view', {
            page_name: 'Vulnerability Summary - Start',
            type: 'modal',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSortChange = (name, sorting_type) => {
        props.logEvent('click', {
            name,
            scope: 'Vulnerability Summary - Results',
            type: 'table header',
            sorting_type,
            total_rows: vulnerabilityInfo === null || vulnerabilityInfo === void 0 ? void 0 : vulnerabilityInfo.length,
        });
    };
    const parseSuccess = vulnerabilityInfo !== undefined;
    const showDataGrid = isLoading || parseSuccess;
    return (_jsxs(Box, { className: "modal-container", sx: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '860px',
            padding: '24px',
            gap: '24px',
            width: '100%',
            fontFamily: 'Poppins',
        }, children: [_jsxs(Box, { children: [_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [_jsx("h2", { style: {
                                    fontFamily: 'Red Hat Display',
                                    fontWeight: 500,
                                    marginTop: 'unset',
                                    fontSize: '28px',
                                }, children: props.translator('SUMMARIZE_MY_VULNERABILITIES') }), _jsx(MuiButton
                            // Rendering icon is handled in AngularJS
                            , { "data-testid": "close-icon", "aria-label": props.translator('CLOSE'), onClick: props.onClose, sx: { color: 'black', minWidth: 'unset' }, children: _jsx("span", { className: "fa fa-times" }) })] }), _jsxs("div", { children: [_jsx("p", { children: props.translator('VULNERABILITY_SUMMARY_DESCRIPTION') }), _jsxs("ul", { style: { marginInlineStart: '20px' }, children: [_jsx("li", { children: props.translator('VULNERABILITY_SUMMARY_INSTRUCTIONS') }), _jsx("li", { children: props.translator('VULNERABILITY_SUMMARY_FORMAT_INSTRUCTION') })] })] })] }), _jsxs(Box, { sx: { gap: '40px', display: 'flex', minHeight: '80px', flexDirection: 'column' }, children: [_jsx(JiraCsvSummaryParser, { isLoading: setIsLoading, setVulnerabilityInfo: setVulnerabilityInfo, translator: props.translator, directLinkingApiEndpoint: props.directLinkingApiEndpoint, logEvent: props.logEvent }), showDataGrid && (_jsx(Box, { style: {
                            height: '468px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, children: _jsx(DataGridPro, { loading: isLoading, disableRowSelectionOnClick: true, localeText: localizeDataGrid(props.translator), sx: {
                                '& .MuiDataGrid-columnHeaders': {
                                    background: '#DFE0E7',
                                },
                            }, sortModel: sortModel, onSortModelChange: (model) => {
                                setSortModel((previous) => {
                                    var _a;
                                    const fieldName = model.length ? model[0].field : previous[0].field;
                                    handleSortChange(fieldName, getSortingDirection((_a = model[0]) === null || _a === void 0 ? void 0 : _a.sort));
                                    return [...model];
                                });
                            }, slots: {
                                toolbar: CustomToolbar,
                            }, slotProps: {
                                toolbar: {
                                    translator: props.translator,
                                    logEvent: props.logEvent,
                                },
                            }, columnVisibilityModel: columnVisibilityModel, onColumnVisibilityModelChange: (newModel) => setColumnVisibilityModel(newModel), rows: vulnerabilityInfo
                                ? vulnerabilityInfo.map((value) => {
                                    const labelNames = props.resolveCategoryName(value.category.slugs);
                                    return Object.assign(Object.assign({}, labelNames), { slugs: value.category.slugs.join('.'), id: value.category.slugs.join('.'), numberOfRecords: value.numberOfRecords });
                                })
                                : [], columns: localiseColumns(props.translator) }) }))] }), _jsxs(Box, { sx: { justifyContent: parseSuccess ? 'space-between' : 'flex-end', display: 'flex' }, children: [parseSuccess && _jsx("p", { children: props.translator('VULNERABILITY_SUMMARY_PARSE_SUCCESS_MESSAGE') }), _jsx(Button, { onClick: props.onClose, disabled: false, variant: "outlined", children: props.translator('CLOSE') })] })] }));
};
