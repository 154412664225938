import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.factory('EventLogApiService', [
  '$log',
  '$http',
  '$rootScope',
  '$translate',
  'HttpConfigService',
  '$window',
  function ($log, $http, $rootScope, $translate, HttpConfigService, $window) {
    var self = {};

    function endpoint(path) {
      return $window.SCW_ENV.ApiEndpoint + '/events' + (path ? '/' + path : '');
    }

    self.send = function (eventType, message) {
      $log.debug('Sending ' + eventType + ' to log', message);
      addLoading();
      return $http
        .post(endpoint(eventType), message, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          removeLoading();
        });
    };

    function addLoading() {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    }

    function removeLoading() {
      $rootScope.loading--;
      if ($rootScope.loading < 0) $rootScope.loading = 0;
    }

    // self.requestParams = function(filter, pageOptions) {
    // 	pageOptions = pageOptions || {};

    // 	return { filter: filter, page: pageOptions.page, size: pageOptions.size };
    // };

    return self;
  },
]);
