import { mapLocale } from './locales';
import { DSThemeProvider, TranslationProvider } from '@securecodewarrior/design-system-react';
import { ExternalProps } from '@scw/react-components';

export default function reactToAngularFactory({ createRoot, createElement }) {
  function getParseableAttributes(attributes) {
    // ignore style(s) and angular attrs
    return [...Object.keys(attributes.$attr).filter((attr) => !/(^styles?$|^ng|^analytics|^ui)/gi.test(attr))];
  }

  function getOverridableMethodsMap(ngModel, props) {
    return {
      onChange: function (event) {
        ngModel.$setViewValue(event.target.value);
        props?.onChange?.(event);
      },
      onClear: function (event) {
        ngModel.$setViewValue('');
        props?.onClear?.(event);
      },
    };
  }

  function watchForScopeChanges(scope, attributes, callbackFn) {
    const parseableAttributes = getParseableAttributes(attributes);

    if (parseableAttributes.length > 0) {
      parseableAttributes.forEach((propertyName) => {
        // At this time we only support checking for reference equality on objects... NOT deep equality checking...
        // When using the deep equality checking it did not notify us when a new promise reference was received.
        scope.$watch(
          function () {
            return scope.$parent.$eval(attributes[propertyName] ?? propertyName);
          },
          function () {
            callbackFn();
          },
          false // Don't change this to true or you will break any pagination that uses promises
        );
      });
    } else {
      // Render the component once If there's no attributes to watch
      callbackFn();
    }
  }

  function getProps(scope, attributes, options, ngModel) {
    /**
     * Place where we build the props object that we will be sent to the react components.
     * It's necessary to check if the property is a method or not:
     *   - Not a method (string | object | list): just send it to react;
     *   - A method: Add the scope.$apply to change the AngularJS state from a ReactComponent
     *     (Flow: React -> AngularJS)
     */
    let props = getParseableAttributes(attributes).reduce((prevProps, propertyName) => {
      const propertyValue = scope.$parent.$eval(attributes[propertyName] ?? propertyName);
      const isMethod = typeof propertyValue === 'function' && !/^\s*class/.test(propertyValue.toString());

      if (isMethod) {
        return {
          ...prevProps,
          [propertyName]: function (...args) {
            return scope.$apply(function () {
              return propertyValue(...args);
            });
          },
        };
      }

      return {
        ...prevProps,
        [propertyName]: propertyValue,
      };
    }, {});

    /**
     * If our directive got a ng-model attribute, we will add a overridableMethods to add a TwoWayDataBinding behaviour.
     * <scw-input-angularjs-directive tabindex="-1" ng-model="variableWithTwoWayDataBinding">
     *   <input tabindex="1" value="bla" onChange="mockedMethodByReactToAngularFactory" />
     * </scw-input-angularjs-directive>
     */
    if (ngModel !== null && options?.overridableMethods) {
      const overridableMethodsMap = getOverridableMethodsMap(ngModel, { ...props });

      options?.overridableMethods.forEach((currentOverridableMethod) => {
        props[currentOverridableMethod] = overridableMethodsMap[currentOverridableMethod];
      });
    }

    return props;
  }

  function renderReactElement(ReactComponent, scope, element, attributes, ngModel, options, $translate) {
    scope.props = getProps(scope, attributes, options, ngModel);

    const subscribe = function (callback) {
      scope.notifyChanged = callback;
      return () => {
        scope.notifyChanged = null;
      };
    };

    const getSnapshot = () => {
      return scope.props;
    };

    scope.rootReactElement = createRoot(element[0]);
    const component = createElement(ExternalProps(ReactComponent), { subscribe, getSnapshot });
    const themedComponent = createElement(DSThemeProvider, { theme: options?.theme ?? 'dark' }, component);
    const translatedComponent = createElement(
      TranslationProvider,
      { language: mapLocale($translate.use()) },
      themedComponent
    );

    scope.rootReactElement.render(translatedComponent);
  }

  function unmountReactElement(scope) {
    scope.rootReactElement?.unmount();
  }

  function notifyChanges(scope, attributes, options, ngModel) {
    scope.props = getProps(scope, attributes, options, ngModel);

    if (scope.notifyChanged) {
      scope.notifyChanged();
    }
  }

  function createDirective(ReactComponent, options) {
    return [
      '$translate',
      function ($translate) {
        return {
          restrict: 'E',
          require: ['?ngModel'],
          scope: {},
          link: function (scope, element, attributes, controllers) {
            /**
             * Directive don't need to be accessible. This will ensure it.
             * <scw-button-angularjs-directive tabindex="-1">
             *   <button tabindex="1" role="button">I'm a react component</button>
             * </scw-button-angularjs-directive>
             */
            element.attr('tabindex', -1);

            /**
             * Check if the angularjs directive has the ng-model as an attribute.
             * It will be used to add a behaviour to simulate the ng-model behavior (TwoWayDataBinding) when you are working with react-components
             * <scw-input-angularjs-directive tabindex="-1" ng-model="variableWithTwoWayDataBinding">
             *   <input tabindex="1" value="bla" onChange="mockedMethodByReactToAngularFactory" />
             * </scw-input-angularjs-directive>
             */
            const ngModel = controllers[0];

            if (ngModel && !('ngIf' in attributes))
              console.error(
                'Rendered react component with ng-model without ng-if',
                ReactComponent.name,
                attributes.$$element,
                new Error()
              );

            /**
             * Here it's the place where the react component will be rendered.
             * Add a watch for every attribute sent to a directive.
             * Weather some property change on AngularJS, we will be able to update the ReactComponent state.
             * Flow: AngularJS property changes -> ReactComponent need to be changed.
             * <scw-button-angularjs-directive tabindex="-1" children="'I'm a react component - Children Property'">
             *   <button tabindex="1" role="button">I'm a react component - Children Property</button>
             * </scw-button-angularjs-directive>
             */
            renderReactElement(ReactComponent, scope, element, attributes, ngModel, options, $translate);

            watchForScopeChanges(scope, attributes, () => {
              notifyChanges(scope, attributes, options, ngModel);
            });

            /**
             * Here it's the place where the react component will be unmounted.
             */
            scope.$on('$destroy', () => {
              unmountReactElement(scope);
            });
          },
        };
      },
    ];
  }

  return {
    createDirective,
  };
}
