import angular from 'angular';
import MODULE from './module';

import signupTemplate from './Signup.html';

const app = angular.module(MODULE);

app.controller('SignupController', [
  '$scope',
  '$location',
  '$rootScope',
  '$browser',
  '$window',
  function ($scope, $location, $rootScope, $browser, $window) {
    $rootScope.appLoaded = true;
    $rootScope.removePreloading = true;
    $scope.newAppUrl = '/web/index.html';
    $window.addEventListener('message', receiveMessage);
    const title = 'Free Website Trial - Create an Account with Secure Code Warrior';
    $window.document.title = title;
    $window.document.getElementsByTagName('meta').title.content = title;

    function receiveMessage(event) {
      if (event.isTrusted && event.origin === location.origin) {
        var messageType = event.data.type;
        var messageData = event.data.data;
        var reload = !!event.data.reload;

        if (messageType === 'syncUrl') {
          handleUrlSync(messageData, reload);
        }

        if (messageType === 'navigateToUrl') {
          handleUrlNavigate(messageData, reload);
        }

        if (messageType === 'click' && messageData === 'closeDropdowns') {
          closeDropDowns();
        }

        if (messageType === 'setAppLoaded') {
          $rootScope.appLoaded = messageData;
        }
      } else {
        console.warn('iFrame message not sent from trusted origin', event.origin);
      }
    }

    function handleUrlSync(url, reload) {
      var currentPath = location.hash.replace('#', '');
      if (currentPath !== url) {
        $browser.url('#' + url, false, null);
        if (reload) {
          $window.location.reload(); // Hard refresh the page
        }
        $rootScope.$digest(); // Soft re-render the app
      }
    }
    function handleUrlNavigate(url, reload) {
      const location = $location.path(url);

      if (reload) {
        $window.location.href = `#/${location.url()}`;
        $window.location.reload(); // Hard refresh the page
      } else {
        $rootScope.$digest(); // Soft re-render the app
      }
    }

    function closeDropDowns() {
      angular.element('.dropdown.open').click();
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('message', receiveMessage);
    });

    $scope.handleUrlSync = handleUrlSync;
  },
]);

app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider.state('product-trial-signup', {
      url: '/trial-signup',
      controller: 'SignupController',
      templateUrl: signupTemplate,
    });
  },
]);
