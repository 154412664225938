import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).service('SearchService', [
  '$http',
  '$log',
  '$window',
  'HttpConfigService',
  function ($http, $log, $window, HttpConfigService) {
    var ApiEndpoint = function (url) {
      var endpoint = $window.SCW_ENV.ApiEndpoint;
      if (url) {
        if (url.indexOf('/') != 0) endpoint += '/';

        endpoint += url;
      }

      return endpoint;
    };

    function buildSearchUrl(url, query, filter, pageOptions, sortBy) {
      var paramsObj = {
        query: JSON.stringify(query),
        filter: filter && encodeURIComponent(filter),
        sortBy: sortBy && encodeURIComponent(sortBy),
        page: pageOptions && pageOptions.page,
        size: pageOptions && pageOptions.size,
      };

      var parameters = [];
      _.mapValues(paramsObj, function (val, key) {
        if (val) parameters.push(key + '=' + val);
      });

      $log.debug('Got parameters for query', parameters.join('&'), parameters);
      return ApiEndpoint(url + '?' + parameters.join('&'));
    }

    return {
      search: function (url, query, filter, pageOptions, sortBy, mapFunction) {
        $log.debug('SearchService search on ' + url, query, filter);
        return $http
          .get(buildSearchUrl(url, query, filter, pageOptions, sortBy), HttpConfigService.getHttpConfig())
          .then(function (response) {
            if (mapFunction) return mapFunction(response.data);

            return response.data;
          });
      },

      summary: function (url, query, filter, mapFunction) {
        $log.debug('SearchService summary on ' + url, query, filter);
        return $http
          .get(buildSearchUrl(url, query, filter), HttpConfigService.getHttpConfig())
          .then(function (response) {
            if (mapFunction) return mapFunction(response.data);

            return response.data;
          });
      },
    };
  },
]);
