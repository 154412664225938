import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { dayjs } from '@securecodewarrior/design-system-react';
import { Highlight } from '@securecodewarrior/design-system-react/lib/legacy';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const f = (t) => dayjs.unix(t).format('D MMMM YYYY LT');
export const Banner = ({ startDate, endDate, status }) => {
    const { t } = useTranslation();
    const now = dayjs.utc();
    if (status === 'superseded')
        return _jsx(Highlight, { color: "warning", children: t('pages.assessments.overview.scheduling.superseded') });
    // ended
    if (endDate && now.isAfter(dayjs.unix(endDate)))
        return (_jsx(Highlight, { color: 'error', children: t('pages.assessments.overview.scheduling.ended', { date: f(endDate) }) }));
    // closed in the platform generally means the assessment's enddate has been exceeded, but just in case it gets used
    // for more than that we can show this banner
    if (status === 'closed')
        return _jsx(Highlight, { color: "warning", children: t('pages.assessments.overview.scheduling.closed') });
    // not started, start only
    if (!endDate && startDate && now.isBefore(dayjs.unix(startDate)))
        return (_jsx(Highlight, { color: 'accent', children: t('pages.assessments.overview.scheduling.starting', { date: f(startDate) }) }));
    // both, started
    if (startDate && endDate && now.isAfter(dayjs.unix(startDate)))
        return (_jsx(Highlight, { color: 'warning', children: t('pages.assessments.overview.scheduling.until', { date: f(endDate) }) }));
    // both
    if (startDate && endDate)
        return (_jsx(Highlight, { color: 'warning', children: t('pages.assessments.overview.scheduling.between', { startDate: f(startDate), endDate: f(endDate) }) }));
    // end only
    if (!startDate && endDate)
        return (_jsx(Highlight, { color: 'warning', children: t('pages.assessments.overview.scheduling.until', { date: f(endDate) }) }));
    // start only
    if (startDate && !endDate)
        return (_jsx(Highlight, { color: 'accent', children: t('pages.assessments.overview.scheduling.started', { date: f(startDate) }) }));
    // unrestricted
    return null;
};
