import angular from 'angular';
import SCWUtil from '../util';
import SCWCommons from '../components/commons';
import SCWGameComponents from '../components/challenges';

const MODULE = 'SCWTrainingChallengeNew';
export default MODULE;

const app = angular.module(MODULE, [SCWUtil, SCWCommons, SCWGameComponents]);

app.controller('TrainingChallengeNewController', [
  '$scope',
  '$state',
  'TrainingApiService',
  '$translate',
  'AnalyticsService',
  'AnalyticsEvents',
  function ($scope, $state, TrainingApiService, $translate, AnalyticsService, AnalyticsEvents) {
    $scope.playmode = 'courses';
    $scope.quest = null;
    $scope.loading = true;
    $scope.beginChallenge = beginChallenge;
    $scope.skipChallenge = skipChallenge;
    $scope.nextChallenge = nextChallenge;

    this.$onInit = function () {
      $scope.loading = true;

      TrainingApiService.getChallenge($state.params)
        .then((response) => {
          $scope.quest = response;
          $state.params = {
            ...$state.params,
            _taskAttempt: $scope.quest.taskAttemptId,
            courseId: $scope.quest.courseId,
          };
          $scope.loading = false;
        })
        .catch(handleError);
    };

    function beginChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.BEGIN_CHALLENGE);
    }

    function nextChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.COMPLETE_CHALLENGE);
      $scope.loading = true;
      const { courseId, _userCourse, _language, _module, _taskAttempt } = $state.params;

      if ($scope.quest?.willCompleteCourse) {
        return $state.go('courses.course-list-language-concluded', {
          courseId: courseId,
          languageId: _language,
          moduleId: _module,
          taskAttemptId: _taskAttempt,
        });
      }

      TrainingApiService.getNextTask(_language, _module, _taskAttempt)
        .then(function (response) {
          $scope._module = response.module;
          $scope._task = response.task;
          $scope.taskType = response.taskType;

          if ($scope.taskType === 'video' || $scope.taskType === 'custom' || $scope.taskType === 'guideline') {
            $state.go(
              'courses.view-task',
              {
                userCourseId: _userCourse,
                languageId: _language,
                moduleId: $scope._module,
                taskId: $scope._task,
              },
              { reload: true }
            );
          }
          if ($scope.taskType === 'challenge' || $scope.taskType === 'checkpoint') {
            $state.go(
              'courses-challenge',
              {
                _userCourse: _userCourse,
                _language: _language,
                _module: $scope._module,
                _task: $scope._task,
              },
              { reload: true }
            );
          }
          if ($scope.taskType === 'mission') {
            $state.go(
              'courses-mission',
              {
                _userCourse: _userCourse,
                _language: _language,
                _module: $scope._module,
                _task: $scope._task,
              },
              { reload: true }
            );
          }
          if ($scope.taskType === 'coding_lab') {
            $state.go(
              'courses-coding-lab',
              {
                _userCourse: _userCourse,
                _language: _language,
                _module: $scope._module,
                _task: $scope._task,
              },
              { reload: true }
            );
          }
        })
        .catch(function (response) {
          $translate(['ERROR_LOADING_CHALLENGE']).then(function (_translations) {
            //ErrorHandler.addHttpError(translations.ERROR_LOADING_CHALLENGE, response);
            swal(
              {
                title: $translate.instant('ERROR_LOADING_CHALLENGE'),
                text: response.data.message,
                type: 'error',
                confirmButton: $translate.instant('OK'),
              },
              function (isConfirm) {
                if (isConfirm) {
                  $state.go('courses.course-list-language-breakdown', {
                    courseId,
                    languageId: _language,
                  });
                }
              }
            );
          });
        })
        .finally(function () {
          TrainingApiService.removeLoading();
        });
    }

    function skipChallenge() {
      sendAnalyticsEvent(AnalyticsEvents.Challenges.SKIP_CHALLENGE);
      $scope.loading = true;
      TrainingApiService.skipChallenge($state.params).then(reload).catch(handleError);
    }

    function reload() {
      return $state.go($state.current.name, $state.params, { reload: true });
    }

    function back(reload) {
      $state.go(
        'courses.course-list-language-breakdown',
        {
          courseId: $state.params.courseId,
          languageId: $state.params._language,
        },
        { reload }
      );
    }

    function sendAnalyticsEvent(eventName) {
      // quest (task) language should always be there nowadays, but it's still missing for older session
      const languagesplit = $scope.quest?.language?.split('.');
      if (languagesplit) {
        const [courseLanguage, courseFramework] = languagesplit;
        const eventProps = {
          challenge_id: $scope.quest?.challengeId,
          portal_area: $scope.playmode,
          language_framework: `${courseLanguage}:${courseFramework}`,
        };
        AnalyticsService.logEvent(eventName, eventProps);
      }
    }

    function handleError(response) {
      $scope.error = {
        status: response.status || '',
        message: `${$translate.instant('ERROR_RETRIEVING_CHALLENGE')}: ${response.data.error || response.data.message}`,
        onReload: reload,
        onBack: back,
      };
      if (response.data.message.includes('uninvited')) {
        $state.go('courses.my-courses');
        return;
      }
    }
  },
]);
