import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';
import * as _ from 'lodash';

(function () {
  var app = angular.module(MODULE);

  app.controller('Game013LeaderboardController', [
    '$scope',
    '$rootScope',
    '$state',
    '$log',
    '$translate',
    'Game013Api',
    'Game013StateService',
    'Session',
    function ($scope, $rootScope, $state, $log, $translate, Game013Api, Game013StateService, Session) {
      $scope.forms = {};
      $scope.gameData = {
        ...$scope.gameData,
      };
      $scope.languageMap = {}; // combined map of historic languages from metrics and active/available languages

      $scope.viewStarted = new Date();
      $scope.selectedLanguages = [];
      $scope.accessedPages = [1];

      $scope.populateLanguageList = function (languageData) {
        // populate language list
        let languageList = JSON.parse(JSON.stringify(languageData));
        let filteredLanguageList = [];
        for (let i = 0; i < languageList.length; i++) {
          var language = languageList[i];
          if (
            $scope.metadata.languages[language.language._id] &&
            (language.status == $scope.metadata.constants.language.status.ACTIVE ||
              language.status == $scope.metadata.constants.language.status.AVAILABLE)
          ) {
            language.displayLanguage = $scope.metadata.languages[language.language._id].name;
            language.displayFramework =
              $scope.metadata.languages[language.language._id].framework[language.language._framework].name;
            filteredLanguageList.push(language);
            $scope.languageMap[language.language._id + language.language._framework] = true;
          }
        }
        filteredLanguageList.unshift({
          displayLanguage: $translate.instant('ALL_LANGUAGES_AND_FRAMEWORKS'),
          displayFramework: '',
          language: {
            _id: 'all',
            _framework: 'all',
          },
        });
        $scope.languageList = filteredLanguageList;
      };

      $scope.updateLanguageList = function (languageData) {
        // update language list - data structure is different to gameData.languages
        let languageList = languageData;
        for (let i = 0; i < languageList.length; i++) {
          let language = languageList[i];
          language.language = {
            _id: language._id, // making data consistent with data in gameData.languages
            _framework: language._framework,
          };
          if ($scope.metadata.languages[language.language._id]) {
            if ($scope.languageMap[language.language._id + language.language._framework]) {
              continue;
            } else {
              $scope.languageMap[language.language._id + language.language._framework] = true;
              language.displayLanguage = $scope.metadata.languages[language.language._id].name;
              language.displayFramework =
                $scope.metadata.languages[language.language._id].framework[language.language._framework].name;
              $scope.languageList.push(language);
            }
          }
        }

        $scope.languageList.sort(function (a, b) {
          if (a.language._id == 'all') {
            return -1; // always put all:all at the top
          }
          let aConcatLanguageFramework = a.language._id + a.language._framework;
          let bConcatLanguageFramework = b.language._id + b.language._framework;
          if (aConcatLanguageFramework < bConcatLanguageFramework) {
            return -1;
          }
          if (aConcatLanguageFramework > bConcatLanguageFramework) {
            return 1;
          }
          return 0;
        });
      };

      Game013Api.getGameStatus()
        .then(function (gameData) {
          let active =
            gameData.activeIndex > -1 && gameData.languages[gameData.activeIndex]
              ? gameData.languages[gameData.activeIndex].active
              : null;

          if (!active) {
            swal({
              type: 'warning',
              title: $translate.instant('MyStats.Errors.noActiveLanguage.title'),
              text: $translate.instant('MyStats.Errors.noActiveLanguage.text'),
              confirmButtonText: $translate.instant('OK'),
            });

            return $state.go('game.013.languages', { flow: 'bounce', source: 'training leaderboard' });
          }

          Game013StateService.setActiveState(active);

          let _activeLanguage = Game013StateService.active._language;
          let _activeFramework = Game013StateService.active._framework;

          $scope.populateLanguageList(gameData.languages);

          // try to find active language and select it, default to global otherwise
          // default to user active language
          $log.debug('Searching for active language index', _activeLanguage, _activeFramework);
          let defaultIndex =
            _.findIndex($scope.languageList, { language: { _id: _activeLanguage, _framework: _activeFramework } }) || 0;
          let activeLanguage = $scope.languageList[defaultIndex];
          if (defaultIndex > 0) {
            // don't mark "all" as active.
            activeLanguage.isActive = true;
          }
          $scope.forms.selectedLanguageFramework = activeLanguage;
          // $scope.forms.selectedLanguageFramework = $scope.languageList[defaultIndex];

          $scope.$watch('forms.selectedLanguageFramework', function (_val) {
            // reset page options so new language leaderboard is loaded to the current user's page
            $scope.page.options = { size: $rootScope.pagerPrefs.itemsPerPage };
            $scope.getLeaderboard();
          });
        })
        .then($scope.getLeaderboard);

      $scope.page = {
        options: { size: $rootScope.pagerPrefs.itemsPerPage },
      };

      $scope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
        if (newVal != oldVal) {
          delete $scope.page.options.page;
          $scope.page.options.size = newVal;
          $scope.getLeaderboard();
        }
      });

      $scope.pagerMaxSize = 10;

      $scope.pagerChanged = function () {
        const haspage = $scope.accessedPages.find((p) => p === $scope.page.options.page);
        if (!haspage) $scope.accessedPages.push($scope.page.options.page);
        $scope.getLeaderboard();
      };

      $scope.getLeaderboard = function () {
        let ctx = JSON.parse(JSON.stringify(Game013StateService.active));
        ctx._language = $scope.forms.selectedLanguageFramework.language._id || 'all';
        ctx._framework = $scope.forms.selectedLanguageFramework.language._framework || 'all';

        const selectedLanguage = `${ctx._language}:${ctx._framework}`;
        const hasLanguage = $scope.selectedLanguages.find((l) => l === selectedLanguage);
        if (!hasLanguage) $scope.selectedLanguages.push(selectedLanguage);

        Game013Api.getLanguageLeaderboard(ctx, $scope.page.options)
          .then(function (leaderboardWithLeaderboardPreferences) {
            if (leaderboardWithLeaderboardPreferences.leaderboard?.delta) {
              $scope.deltaInfo = leaderboardWithLeaderboardPreferences.leaderboard.delta;
              $scope.deltaInfo.displayDuration = moment.duration($scope.deltaInfo.duration).asDays();
              $scope.deltaInfo.displayTimestamp = moment($scope.deltaInfo.timestamp).format('LL');
            }

            $scope.gameData.leaderboard = leaderboardWithLeaderboardPreferences.leaderboard;
            $scope.page.options = leaderboardWithLeaderboardPreferences.leaderboard.list;
            $scope.gameData.developerTableData = leaderboardWithLeaderboardPreferences.leaderboard.list.data;

            if (leaderboardWithLeaderboardPreferences.leaderboard?.languages) {
              $scope.updateLanguageList(leaderboardWithLeaderboardPreferences.leaderboard.languages);
            }
            for (let i = 0; i < $scope.gameData.developerTableData.length; i++) {
              let developer = $scope.gameData.developerTableData[i];

              if (developer.profile.name) {
                developer.name = developer.profile.name.first + ' ' + developer.profile.name.last;
                developer.name = developer.name.trim() || 'Anonymous';
              }

              if (developer.previousRank != -1) {
                developer.rankChange = developer.previousRank - developer.currentRank;
              } else {
                developer.rankChange = 'New';
              }

              if (developer.securityMaturity) {
                if (
                  developer.securityMaturity.maturityLevel ==
                  $scope.metadata.constants.metrics.security_maturity.level.BEGINNER.id
                ) {
                  developer.sortedSecurityMaturity = 1;
                  developer.displaySecurityMaturity = $translate.instant('BEGINNER');
                } else if (
                  developer.securityMaturity.maturityLevel ==
                  $scope.metadata.constants.metrics.security_maturity.level.AWARE.id
                ) {
                  developer.sortedSecurityMaturity = 2;
                  developer.displaySecurityMaturity = $translate.instant('AWARE');
                } else if (
                  developer.securityMaturity.maturityLevel ==
                  $scope.metadata.constants.metrics.security_maturity.level.SKILLED.id
                ) {
                  developer.sortedSecurityMaturity = 3;
                  developer.displaySecurityMaturity = $translate.instant('SKILLED');
                } else if (
                  developer.securityMaturity.maturityLevel ==
                  $scope.metadata.constants.metrics.security_maturity.level.CHAMPION.id
                ) {
                  developer.sortedSecurityMaturity = 4;
                  developer.displaySecurityMaturity = $translate.instant('CHAMPION');
                }
              }

              if (developer.securityMaturity && developer.securityMaturity.challenges) {
                developer.accuracy = Math.round(developer.securityMaturity.challenges.ratioCorrect * 100);
              }

              if (developer.securityMaturity && developer.securityMaturity.hints) {
                developer.confidenceLevel = developer.securityMaturity.hints.confidenceLevel;
              }

              // PORTAL-1038 - null for invited users that have not accepted the invite
              if (developer.registered) {
                developer.registeredDisplay = moment(developer.registered).format('LL');
              }
            }

            _.set(
              Session.user,
              'properties.preferences.leaderboard',
              leaderboardWithLeaderboardPreferences.leaderboardPreferences
            );
          })
          .catch($log.error);
      };

      if (_.get(Session.user, 'properties.preferences.leaderboard.options.hideLeaderboard')) {
        $state.go('home');
      }
    },
  ]);
})();
