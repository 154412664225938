import angular from 'angular';
import MODULE from './module';

export default MODULE;

(function () {
  var app = angular.module(MODULE);

  app.controller('IntroSplashController', [function () {}]);

  app.config([
    '$urlRouterProvider',
    function ($urlRouterProvider) {
      $urlRouterProvider.when('/intro-splash', '/home');
    },
  ]);
})();
