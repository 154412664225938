var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { mSx } from '@securecodewarrior/design-system-react/lib/utils/styleHelpers';
export const Badge = (_a) => {
    var _b;
    var { variant = 'primary' } = _a, props = __rest(_a, ["variant"]);
    const _c = (_b = props.TypographyProps) !== null && _b !== void 0 ? _b : {}, { sx } = _c, typographyProps = __rest(_c, ["sx"]);
    return (_jsx(Typography, Object.assign({ variant: "caption", textAlign: "center", sx: mSx((theme) => ({
            color: theme.palette.badge.text,
            border: '1px solid',
            backgroundColor: theme.palette.badge[variant].fill,
            borderColor: theme.palette.badge[variant].border,
            padding: theme.spacing(1, 2),
            borderRadius: 2,
            whiteSpace: 'nowrap',
        }), sx) }, typographyProps, { children: props.label })));
};
