import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { createPath, useNavigate, useResolvedPath, } from 'react-router-dom';
import { Link as BaseLink } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../platformContext';
import { setSearch } from './Url';
export const usePlatformNavigate = () => {
    const ctx = usePlatformContext();
    const routerNavigate = useNavigate();
    const to = (to, options) => {
        routerNavigate(to, options);
        const p = createPath(to);
        ctx.navigateTo(p, false, false);
    };
    const toState = (state, params) => {
        ctx.goToState(state, params);
    };
    const setRequestedState = (state, params) => {
        ctx.setRequestedState(state, params);
    };
    //overrides the searchParams, might be useful to have 'add/delete' helpers
    const setSearchParams = (search, replace = false) => {
        const p = setSearch(window.location.hash.replace('#', ''), decodeURIComponent(search));
        ctx.navigateTo(p, true, replace);
    };
    return {
        to,
        toState,
        setSearchParams,
        setRequestedState,
    };
};
export const Link = ({ to, relative, state, linkProps, children, analyticsCallback, }) => {
    const navigate = usePlatformNavigate();
    const location = useResolvedPath(to, { relative });
    const onClickListener = (e) => {
        if (e.ctrlKey || e.metaKey)
            return;
        e.preventDefault();
        navigate.to(location, state ? { state } : undefined);
        if (analyticsCallback)
            analyticsCallback === null || analyticsCallback === void 0 ? void 0 : analyticsCallback();
    };
    return (_jsx(BaseLink, Object.assign({}, linkProps, { onClick: onClickListener, href: `/#${location.pathname + location.search}`, children: children })));
};
// This is needed, otherwise this won't work:
// - go to explore page
// - start an activity
// - press browser back
// now the URL will be explore, but the page content still shows the activity
export const SyncPlatform = (props) => {
    const ctx = usePlatformContext();
    const routerNavigate = useNavigate();
    useEffect(() => {
        const syncUrlAndRouter = () => {
            routerNavigate(window.location.hash.replace('#', ''));
        };
        ctx.addUrlChangeListener(syncUrlAndRouter);
        return () => ctx.removeUrlChangeListener(syncUrlAndRouter);
    }, [ctx, routerNavigate]);
    return _jsx(_Fragment, { children: props.children });
};
