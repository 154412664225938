import angular from 'angular';
import MODULE from './module';
import { setDisabled } from './tournaments.api.handlers';

const app = angular.module(MODULE);

(function () {
  app.factory('TournamentsApiService', [
    '$log',
    '$http',
    '$window',
    '$rootScope',
    '$translate',
    'HttpConfigService',
    function ($log, $http, $window, $rootScope, $translate, HttpConfigService) {
      const { SCW_ENV } = $window;
      var TournamentsApi = {};
      var TournamentsApiEndpoint = function () {
        return SCW_ENV.ApiEndpoint + '/tournament';
      };

      var TOURNAMENT_PROPERTIES_TO_REMOVE = ['stats', 'canAcceptNewParticipants', 'rank'];

      // PORTAL-1612 remove large stats property
      // PORTAL-2742 remove canAcceptNewParticipants property - UI generated
      function removeInvalidProperties(tournament) {
        TOURNAMENT_PROPERTIES_TO_REMOVE.map(function (property) {
          delete tournament[property];
        });
      }

      TournamentsApi.addLoading = function () {
        $rootScope.currentLoadingMessage = $translate.instant(
          'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
        );
        $rootScope.loading++;
      };

      TournamentsApi.removeLoading = function () {
        $rootScope.loading--;
        if ($rootScope.loading < 0) $rootScope.loading = 0;
      };

      TournamentsApi.requestParams = function (filter, pageOptions) {
        pageOptions = pageOptions || {};

        return { filter: filter, page: pageOptions.page, size: pageOptions.size };
      };

      // TODO: this should be send from the backend, currently just for demo purpose
      TournamentsApi.setChallengeAttempt = function (trackingId, answerCorrect) {
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: SCW_ENV.ApiEndpoint + '/external-players/set-attempt',
              data: { trackingId: trackingId, answerCorrect: answerCorrect },
            },
            HttpConfigService.getHttpConfig()
          )
        );
      };

      TournamentsApi.getTournamentList = function (filter, pageOptions, _sortBy, status, companyId) {
        $log.debug('TournamentsApi - getTournamentList()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint(),
              params: angular.extend(
                { status: status, companyId: companyId },
                TournamentsApi.requestParams(filter, pageOptions)
              ),
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getTournament = function (tournamentId) {
        $log.debug('TournamentsApi - getTournament()', tournamentId);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/' + tournamentId,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getParticipants = function (tournamentId, filter, pageOptions, sortBy) {
        $log.debug('TournamentsApi - getParticipants()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user',
              params: angular.extend({}, TournamentsApi.requestParams(filter, pageOptions), { sortBy: sortBy }),
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getUserRank = function (tournamentId) {
        $log.debug('TournamentsApi - getUserRank()', tournamentId);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user/rank',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.downloadSummaryCsv = function (tournamentId) {
        $log.debug('TournamentsApi - downloadSummaryCsv()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/export/' + tournamentId + '/summary.csv',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.downloadAllSummaryCsv = function () {
        $log.debug('TournamentsApi - downloadSummaryCsv()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/export/summary.csv',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.downloadDetailedCsv = function (tournamentId) {
        $log.debug('TournamentsApi - downloadDetailedCsv()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/export/' + tournamentId + '/detailed.csv',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.downloadPublicDetailedCsv = function (tournament) {
        $log.debug('TournamentsApi - downloadPublicDetailedCsv()', arguments);
        encodeParams(arguments);
        const tournamentId = encodeURIComponent(tournament._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/export/' + tournamentId + '/detailedPublic.csv',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getLeaderboard = function (tournamentId, filter, pageOptions, sortBy) {
        $log.debug('TournamentsApi - getLeaderboard()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/leaderboard',
              params: angular.extend({}, TournamentsApi.requestParams(filter, pageOptions), { sortBy: sortBy }),
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            response.data.list.data = response.data.list.data.map(function (participant) {
              participant.stats = TournamentsApi.stats.addProgress(participant.stats);
              return participant;
            });
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getGlobalLeaderboard = function (tournamentId) {
        $log.debug('TournamentsApi - getGlobalLeaderboard()', arguments);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'GET',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/globalLeaderboard',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.checkLanguageQuests = function (
        tournamentId,
        languages,
        numberOfChallenges,
        enableBonusLevelCheck,
        appTypeVersion,
        scoringPreset
      ) {
        $log.debug(
          'TournamentsApi - checkLanguageQuests()',
          tournamentId,
          languages,
          numberOfChallenges,
          appTypeVersion,
          scoringPreset
        );
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/quest/stats',
              data: {
                languages: languages,
                numberOfChallenges: numberOfChallenges,
                enableBonusLevelCheck: enableBonusLevelCheck,
                appTypeVersion: appTypeVersion,
                scoringPreset: scoringPreset,
              },
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.createTournament = function (tournament) {
        $log.debug('TournamentsApi - createTournament()', tournament);

        encodeParams(arguments);

        removeInvalidProperties(tournament);

        if (tournament.admins) {
          tournament.adminIds = tournament.admins.map(function (admin) {
            return admin._id;
          });
          delete tournament.admins;
        }

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint(),
              data: tournament,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.updateTournament = function (tournament) {
        $log.debug('TournamentsApi - updateTournament()', tournament);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);

        removeInvalidProperties(tournament);

        if (tournament.admins) {
          tournament.adminIds = tournament.admins.map(function (admin) {
            return admin._id;
          });
          delete tournament.admins;
        }

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'PUT',
              url: TournamentsApiEndpoint() + '/' + tournamentId,
              data: tournament,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.cloneTournament = function (tournamentId) {
        $log.debug('TournamentsApi - cloneTournament()', tournamentId);
        encodeParams(arguments);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/clone',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.deleteTournament = function (tournament) {
        $log.debug('TournamentsApi - deleteTournament()', tournament);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'DELETE',
              url: TournamentsApiEndpoint() + '/' + tournamentId,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.joinTournament = function (tournament, sharedSecret) {
        $log.debug('TournamentsApi - joinTournament()', tournament, sharedSecret);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user',
              data: { sharedSecret: sharedSecret },
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.recalcTournamentQuests = function (tournament) {
        $log.debug('TournamentsApi - recalcTournamentQuests()', tournament);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/recalc-quests',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.blockUser = async function (tournament, user) {
        $log.debug('TournamentsApi - blockUser()', tournament, user);
        encodeParams(arguments);
        const tournamentId = encodeURIComponent(tournament._id);
        const userId = encodeURIComponent(user._id);
        await setDisabled(tournamentId, userId, true, TournamentsApi, $http, TournamentsApiEndpoint, HttpConfigService);
      };

      TournamentsApi.unblockUser = async function (tournament, user) {
        $log.debug('TournamentsApi - unblockUser()', tournament, user);
        encodeParams(arguments);

        const tournamentId = encodeURIComponent(tournament._id);
        const userId = encodeURIComponent(user._id);

        await setDisabled(
          tournamentId,
          userId,
          false,
          TournamentsApi,
          $http,
          TournamentsApiEndpoint,
          HttpConfigService
        );
      };

      TournamentsApi.deleteUser = function (tournament, user) {
        $log.debug('TournamentsApi - deleteUser()', tournament, user);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);
        var userId = encodeURIComponent(user._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'DELETE',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user/' + userId,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.recalcUser = function (tournament, user) {
        $log.debug('TournamentsApi - recalcUser()', tournament, user);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);
        var userId = encodeURIComponent(user._id);

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'PUT',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user/' + userId + '/recalc',
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.updatePlayerProfile = function (tournament, user) {
        $log.debug('TournamentsApi - updatePlayerProfile()', tournament, user);
        encodeParams(arguments);
        var tournamentId = encodeURIComponent(tournament._id);
        var userId = encodeURIComponent(user._id);

        // PORTAL-1612 - small perf fix
        delete user.stats;

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'PUT',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/user/' + userId,
              data: user,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getPlayerDetails = function (tournament, user) {
        $log.debug('TournamentsApi - getPlayerDetails()', tournament, user);
        encodeParams(arguments);
        var _tournament = (tournament && tournament._id) || tournament;
        var _user = (user && user._id) || user;

        var playerDetailsUrl = [TournamentsApiEndpoint(), _tournament, 'user', _user].join('/');

        TournamentsApi.addLoading();
        return $http
          .get(playerDetailsUrl, HttpConfigService.getHttpConfig())
          .then(function (response) {
            response.data.stats = TournamentsApi.stats.addProgress(response.data.stats);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      TournamentsApi.getCountdownForTournament = function (tournamentId) {
        $log.debug('TournamentsApi - getCountdownForTournament()', tournamentId);
        encodeParams(arguments);

        const getCountdownUrl = [TournamentsApiEndpoint(), tournamentId, 'countdown'].join('/');
        return $http.get(getCountdownUrl, HttpConfigService.getHttpConfig()).then(function (response) {
          const clockOffsetInMills = Date.now() - response.data.serverUtcTime;
          return { ...response.data, clockOffsetInMills };
        });
      };

      TournamentsApi.getChallenge = TournamentsApi.getNextStage = function (state) {
        $log.debug('TournamentsApi - getChallenge()', state);
        encodeParams(arguments);

        var getChallengeUrl = [TournamentsApiEndpoint(), state._tournament, 'quest', state._quest, 'challenge'].join(
          '/'
        );

        return $http
          .get(
            getChallengeUrl,
            _.merge(
              {
                withCredentials: true,
              },
              HttpConfigService.getHttpConfig()
            )
          )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .catch(function (err) {
            $log.error(err);
            throw err;
          });
      };

      TournamentsApi.skipChallenge = function (state, challengeAttemptId) {
        $log.debug('TournamentsApi - skipChallenge()', state);
        encodeParams(arguments);

        var skipUrl = [TournamentsApiEndpoint(), state._tournament, 'quest', state._quest, 'challenge/skip'].join('/');

        return $http.post(skipUrl, { challengeAttemptId }, HttpConfigService.getHttpConfig()).then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
      };

      TournamentsApi.listQuests = function (tournamentId, state) {
        $log.debug('TournamentsApi - listQuests()', tournamentId);
        encodeParams(arguments);
        tournamentId = encodeURIComponent(tournamentId);
        var path = TournamentsApiEndpoint() + '/' + tournamentId + '/quest/list';

        return $http(
          angular.extend(
            {
              method: 'GET',
              url: path,
              params: {
                state: state,
              },
            },
            HttpConfigService.getHttpConfig()
          )
        ).then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
      };

      TournamentsApi.saveQuests = function (tournamentId, quests) {
        $log.debug('TournamentsApi - saveQuests()', tournamentId, quests);
        encodeParams(arguments);
        tournamentId = encodeURIComponent(tournamentId);
        var path = TournamentsApiEndpoint() + '/' + tournamentId + '/quest/save';

        TournamentsApi.addLoading();
        return $http(
          angular.extend(
            {
              method: 'PUT',
              url: path,
              data: quests,
            },
            HttpConfigService.getHttpConfig()
          )
        )
          .then(function (response) {
            $log.debug(response.data);
            return response.data;
          })
          .finally(function () {
            TournamentsApi.removeLoading();
          });
      };

      // Tournament Stats helper
      TournamentsApi.stats = {};

      /*
       * addProgress()
       * Fills in progress stats previously done on the backend
       */
      TournamentsApi.stats.addProgress = function (stats) {
        var ORDERED_STATUSES = ['in_progress', 'skipped', 'pending', 'done'];

        stats.challengeSummary = { done: 0, in_progress: 0, pending: 0, skipped: 0, total: 0 };
        stats.stageSummary = { incorrect: 0, correct: 0, in_progress: 0, pending: 0, skipped: 0, total: 0 };
        stats.quests = stats.quests.map(function (quest) {
          quest.challengeSummary = { done: 0, in_progress: 0, pending: 0, skipped: 0, total: 0 };
          quest.stageSummary = { incorrect: 0, correct: 0, in_progress: 0, pending: 0, skipped: 0, total: 0 };
          quest.challenges.map(function (challenge) {
            var lowestIndex = challenge.stages.reduce(function (lowestIndex, stage) {
              var status = stage.status;
              if (['incorrect', 'correct'].indexOf(stage.status) > -1) {
                status = 'done';
              }
              var index = ORDERED_STATUSES.indexOf(status);
              stats.stageSummary[stage.status] += 1;
              quest.stageSummary[stage.status] += 1;
              return index < lowestIndex ? index : lowestIndex;
            }, ORDERED_STATUSES.length - 1);
            challenge.status = ORDERED_STATUSES[lowestIndex];
            quest.challengeSummary[challenge.status] += 1;
            stats.challengeSummary[challenge.status] += 1;
            quest.challengeSummary.total += 1;
            stats.challengeSummary.total += 1;
            return challenge;
          });
          quest.challengeSummary.displayPercentage =
            quest.challengeSummary.total > 0
              ? Math.round((100 * quest.challengeSummary.done) / quest.challengeSummary.total)
              : 0;
          var totalAttempts = quest.stageSummary.correct + quest.stageSummary.incorrect;
          quest.stageSummary.ratioCorrect = totalAttempts ? quest.stageSummary.correct / totalAttempts : 0;
          quest.stageSummary.percentageCorrect = Math.round(quest.stageSummary.ratioCorrect * 100);
          return quest;
        });

        stats.challengeSummary.displayPercentage =
          stats.challengeSummary.total > 0
            ? Math.round((100 * stats.challengeSummary.done) / stats.challengeSummary.total)
            : 0;
        var totalAttempts = stats.stageSummary.correct + stats.stageSummary.incorrect;
        stats.stageSummary.ratioCorrect = totalAttempts ? stats.stageSummary.correct / totalAttempts : 0;
        stats.stageSummary.percentageCorrect = Math.round(stats.stageSummary.ratioCorrect * 100);

        return stats;
      };

      TournamentsApi.sendTimeoutLog = function (tournamentId) {
        return $http(
          angular.extend(
            {
              method: 'POST',
              url: TournamentsApiEndpoint() + '/' + tournamentId + '/log/timeout',
              data: {
                dedupKey: Math.random().toString(16).substring(2), // Unique event key for dedup purpose
                clientUtcTime: new Date().toUTCString(),
              },
            },
            HttpConfigService.getHttpConfig()
          )
        ).catch(function () {
          // Swallow any error as this call is for debugging purpose only
        });
      };
      return TournamentsApi;
    },
  ]);
})();
