var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Menu, Stack, Typography, menuClasses, menuItemClasses, typographyClasses, ButtonBase, } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { MenuItem } from '@securecodewarrior/design-system-react/lib/legacy';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const Tabs = (props) => {
    const { t } = useTranslation();
    const options = props.languages.filter((option) => option.id !== props.selectedLanguage.id);
    const first3 = options.length >= 3 ? options.slice(0, 3) : options;
    const restOptions = options.length >= 3 ? options.slice(3) : [];
    return (_jsxs(Stack, { maxHeight: "81px", paddingX: 10, borderBottom: (theme) => `1px solid ${theme.palette.container.border.default}`, flexDirection: "row", alignItems: "center", children: [_jsx(IconBox, { icon: _jsx("div", { className: props.selectedLanguage.iconClassName }), variant: 'selected' }), _jsxs(Box, { pl: 5, flexGrow: 1, minWidth: "500px", children: [_jsx(Typography, { variant: "caption", children: t('pages.quests.yourQuestIn') }), _jsx(Typography, { variant: "h2", component: "h3", textOverflow: "ellipsis", overflow: "hidden", noWrap: true, children: props.selectedLanguage.label })] }), _jsxs(Stack, { pl: 3, direction: "row", gap: 3, children: [first3.map((option) => (_jsx(SelectableTab, Object.assign({ "aria-label": t('pages.quests.accessibility.viewQuestInLanguage', { language: option.label }), icon: _jsx("div", { className: option.iconClassName }) }, option, { onClick: () => props.onTabChange(option.id) }), option.id))), restOptions.length !== 0 && _jsx(SelectTab, { onLanguageSelected: props.onTabChange, options: restOptions }), _jsx(SelectableTab, { "aria-label": t('pages.quests.accessibility.openLanguageSelection'), onClick: props.onLanguageSelectionRequested, icon: _jsx(AddRounded, { sx: (theme) => ({
                                fontSize: theme.typography.pxToRem(34),
                                color: theme.palette.clickable.nav.item.default,
                            }) }), variant: "languageSelection" })] })] }));
};
const SelectableTab = (_a) => {
    var { onClick, 'aria-label': ariaLabel } = _a, props = __rest(_a, ["onClick", 'aria-label']);
    return (_jsx(ButtonBase, { "aria-label": ariaLabel, onClick: onClick, sx: (theme) => ({
            ':focus-visible > div': {
                outline: `1px solid ${theme.palette.clickable.default}`,
            },
            ':hover > div': {
                outline: `1px solid ${theme.palette.container.border.active}`,
                backgroundColor: theme.palette.container.fill.card2,
            },
        }), children: _jsx(IconBox, Object.assign({}, props)) }));
};
const SelectTab = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const a11yId = useId();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onSelectOption = (option) => {
        props.onLanguageSelected(option.id);
        handleClose();
    };
    return (_jsxs(Box, { children: [_jsx(ButtonBase, { sx: (theme) => ({
                    ':focus-visible > div': {
                        outline: `1px solid ${theme.palette.clickable.default}`,
                    },
                    ':hover > div': {
                        backgroundColor: theme.palette.container.fill.card2,
                        outline: `1px solid ${theme.palette.container.border.active}`,
                    },
                }), onClick: (e) => handleClick(e), children: _jsx(IconBox, { focused: open, icon: _jsxs(Typography, { variant: "h2", color: (theme) => theme.palette.text.soft, component: "p", children: ["+", props.options.length] }) }) }), _jsx(Menu, { id: a11yId, anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, sx: (theme) => ({
                    [`.${menuClasses.paper}`]: {
                        marginTop: '-1px',
                        borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                        scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
                        backgroundImage: 'unset',
                        scrollbarWidth: 'thin',
                    },
                    [`.${menuClasses.list}`]: {
                        backgroundColor: theme.palette.container.fill.card1,
                        borderRadius: `${theme.shape.borderRadius * 2}px 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                        border: `1px solid ${theme.palette.clickable.default}`,
                        backgroundImage: 'unset',
                    },
                    [`.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
                        backgroundColor: 'unset',
                        color: theme.palette.text.title,
                        backgroundImage: 'unset',
                        ':hover': {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                        ':focus': {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                        [`& .${typographyClasses.root}`]: {
                            color: theme.palette.text.title,
                        },
                    },
                    [`.${menuItemClasses.selected}:focus`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                    },
                    [`.${menuItemClasses.focusVisible}`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                        backgroundImage: 'unset',
                    },
                    [`& .${menuItemClasses.root}:not(${menuItemClasses.selected}`]: {
                        [`& .${typographyClasses.root}`]: {
                            color: theme.palette.text.soft,
                        },
                    },
                    [`.${menuItemClasses.root}:hover`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                    },
                    '::-webkit-scrollbar': {
                        background: theme.palette.grayscale.bg4,
                        width: '4px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: theme.palette.grayscale.bg6,
                        borderRadius: 4,
                    },
                }), MenuListProps: {
                    'aria-labelledby': a11yId,
                }, children: props.options.map((option) => (_jsx(MenuItem, { onClick: () => onSelectOption(option), children: option.label }, option.id))) })] }));
};
export const IconBox = ({ icon, variant = 'default', focused = false }) => {
    return (_jsx(Stack, { width: "80px", height: "80px", padding: (theme) => theme.typography.pxToRem(20), borderRadius: 2, alignItems: "center", justifyContent: "center", bgcolor: (theme) => variant === 'languageSelection'
            ? theme.palette.container.fill.card2
            : variant === 'selected'
                ? theme.palette.container.fill.body
                : theme.palette.container.fill.card1, border: (theme) => `1px solid ${focused ? theme.palette.clickable.default : theme.palette.container.border.default}`, sx: (theme) => ({
            marginBottom: variant === 'selected' ? '-2px' : undefined,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 0,
            fontSize: theme.typography.pxToRem(34),
        }), children: icon }));
};
