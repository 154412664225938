import angular from 'angular';
import MODULE from './module';
import { USER_ROLES } from '../auth/constants';

const app = angular.module(MODULE);

app.service('RedirectService', [
  'AuthService',
  '$state',
  function (AuthService, $state) {
    function determineHomeUrl() {
      return AuthService.isAuthorized(USER_ROLES.admin) ? 'admin.companies' : 'home';
    }

    this.goHome = function () {
      $state.go(determineHomeUrl(), {}, { reload: true });
    };
  },
]);
