import angular from 'angular';
import MODULE from './module';
import templateUrl from './search.base.input.html';

angular.module(MODULE).directive('searchBaseInput', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {},
      link: function (scope, _element, _attrs) {
        scope.search = scope.$parent.search;
        scope.searchText = '';
        scope.clear = clear;

        scope.$on('search-base:search-triggered', function (_evt, search) {
          scope.searchText = search.text;
        });

        function clear() {
          scope.search('');
          scope.searchText = '';
        }
      },
    };
  },
]);
