import angular from 'angular';
import MODULE from './module';
import templateUrl from './paging.size.html';

angular.module(MODULE).directive('pagingSize', [
  function () {
    return {
      restrict: 'E',
      templateUrl,
      scope: {
        paginationOptions: '=paginationOptions',
      },
      link: function (_scope, _element, _attrs) {},
    };
  },
]);
