import { locales } from '@securecodewarrior/design-system-react';
const fullLocaleMap = Object.fromEntries(locales.map((locale) => [locale.toLowerCase(), locale]));
const localeMap = Object.assign(Object.assign({}, fullLocaleMap), { de: 'de-DE', en: 'en-US', es: 'es-ES', fr: 'fr-FR', ja: 'ja-JP', ko: 'ko-KR', zh: 'zh-CN' });
export function platformLocaleToBcp47(locale, fallback = 'en-US') {
    if (!locale || locale === 'none') {
        return null;
    }
    const language = locale.toLowerCase();
    if (language in localeMap) {
        return localeMap[language];
    }
    return fallback;
}
