import angular from 'angular';
import MODULE from './module';

export default MODULE;

(function () {
  var app = angular.module(MODULE);

  app.filter('capitalize', function () {
    return function (input, _scope) {
      if (input) {
        input = input.toLowerCase();
        return input.substring(0, 1).toUpperCase() + input.substring(1);
      } else {
        return null;
      }
    };
  });

  app.factory('StateStoryStitcher', [
    '$rootScope',
    '$log',
    '$compile',
    '$timeout',
    function ($rootScope, $log, $compile, $timeout) {
      var copy = angular.extend;
      var stateStoryStitcher = {};

      stateStoryStitcher.substitute = function (template, scope, resObj) {
        var p = document.createElement('p');
        var pText = document.createTextNode(template);
        p.appendChild(pText);
        $compile(p)(scope);
        if (!scope.$$phase) {
          scope.$apply(); // since triggered by jQuery not Angular
        }
        $timeout(function () {
          resObj.res = p.innerText;
        }, 50);
      };

      /**
    Returns an easy to understand data structure that can be used to inject state information

    @method parse
    @param {object} state Current user state
    @param {object} story Game storyline
    */
      stateStoryStitcher.parse = function (state, story) {
        $log.debug('Stitching: ', state, story);
        // create a new instance
        story = JSON.parse(JSON.stringify(story));
        var storyProps = story.properties;

        copy(story, state);
        delete story.level;
        delete story.quests;

        // properties
        story.properties = copy({}, storyProps, state.properties);

        // ==== level info
        var levels = story.levels;
        var level = story.levels.data ? story.levels.data[state.level.index] : story.levels[state.level.index];
        var levelProps = level.properties;
        copy(level, state.level);
        level.properties = copy({}, levelProps, state.level.properties);

        // mark all levels before current active to completed
        var i;
        for (i = 0; i < state.level.index; i += 1) {
          story.levels.data[i].status = $rootScope.metadata.constants.game.status.DONE;
        }

        levels.total = levels.data.length;
        levels.completed = levels.data.reduce(function (d, level) {
          if ($rootScope.metadata.constants.game.status.DONE === level.status) {
            d += 1;
          }
          return d;
        }, 0);
        levels.remaining = levels.total - levels.completed;

        // ==== quest info
        for (i in state.quests) {
          var quest = level.quests.data[i];
          var questTasks = quest.tasks;
          var questState = state.quests[i];
          var questProps = quest.properties;
          copy(quest, questState);
          quest.properties = copy({}, questProps, questState.properties);
          quest.tasks = questTasks;

          for (var j in state.quests[i].tasks) {
            var task = quest.tasks.data[j];
            var taskState = state.quests[i].tasks[j];
            var taskProps = task.properties;
            copy(task, taskState);
            task.properties = copy({}, taskProps, taskState.properties);
          }
        }

        level.quests.total = level.quests.data.length;
        level.quests.completed = level.quests.data.reduce(function (d, quest) {
          if ($rootScope.metadata.constants.game.status.DONE === quest.status) {
            d += 1;
          }
          return d;
        }, 0);
        level.quests.remaining = level.quests.total - level.quests.completed;

        // ==== task info
        level.quests.data.forEach(function (quest) {
          var firstActive = null;
          quest.tasks.total = quest.tasks.data.length;
          quest.tasks.completed = quest.tasks.data.reduce(function (d, task) {
            if ($rootScope.metadata.constants.game.status.DONE === task.status) {
              d += 1;

              if (!firstActive) {
                firstActive = task;
              }
            }
            return d;
          }, 0);
          quest.tasks.remaining = quest.tasks.total - quest.tasks.completed;
          quest.tasks.active = firstActive;
        });

        return story;
      };

      return stateStoryStitcher;
    },
  ]);
})();
