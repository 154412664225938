import { jsx as _jsx } from "react/jsx-runtime";
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../commonStyle';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export const MissionPlayerWrapper = (props) => {
    const { missionId, language, playerUrl, onComplete } = props;
    const [t] = useTranslation();
    useEffect(() => {
        const handlePostMessage = (event) => {
            const kind = event.data.event;
            if (kind === 'navigateToMissions' || kind === 'navigateToNextStage' || kind === 'skipChallenge') {
                onComplete();
            }
        };
        window.addEventListener('message', handlePostMessage);
        return () => window.removeEventListener('message', handlePostMessage);
    }, [onComplete]);
    const usersnapData = {
        ['data-mission-id']: missionId,
        ['data-mission-language']: language,
    };
    return (_jsx("iframe", Object.assign({ src: playerUrl, id: "adversary", style: iframeStyle, "aria-label": t('MISSION'), title: t('MISSION'), allow: "clipboard-read; clipboard-write; display-capture;" }, usersnapData)));
};
const iframeStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
