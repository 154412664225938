import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Button, TextArea, RadioGroup } from '@securecodewarrior/design-system-react/lib/wanda';
import { Divider } from '../Divider';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
export const FeedbackDialog = (props) => {
    const { open, onClose, reasons, onSubmit } = props;
    const { t } = useTranslation();
    const [reason, setReason] = useState(Object.values(props.reasons)[0]);
    const [additionalInformation, setAdditionalInformation] = useState('');
    return (_jsxs(Dialog, { open: open, fullWidth: true, maxWidth: 'sm', scroll: 'body', onClose: onClose, sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                backgroundColor: theme.palette.container.fill.card1,
                backgroundImage: 'unset',
                borderRadius: 3,
            },
        }), children: [_jsxs(DialogContent, { sx: { p: 10, display: 'flex', gap: 3, flexDirection: 'column', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: 'h2', typography: "h1", children: t('components.organisms.feedback.title') }), _jsx(RadioGroup, { sx: { py: 3 }, options: reasons, value: reason, onChange: (e) => setReason(e.target.value), defaultValue: Object.values(reasons)[0], "aria-label": "reason" }), _jsx(TextArea, { label: t('components.organisms.feedback.additionalInformation'), value: additionalInformation, onChange: (e) => setAdditionalInformation(e.target.value) })] }), _jsx(Divider, {}), _jsxs(DialogActions, { sx: { py: 5, px: 10, display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Button, { variant: "text", onClick: onClose, children: t('common.labels.cancel') }), _jsx(Button, { variant: "contained", onClick: () => onSubmit(reason, additionalInformation), children: t('components.organisms.feedback.submit') })] })] }));
};
