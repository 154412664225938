export const breadcrumbsHeight = 61;
export const platformHeaderHeight = 64;
export const createPlayerContainerStyle = (config) => {
    const breadcrumbsHeight = config.breadcrumbsHeight || 0;
    const footerHeight = config.showFooter ? 80 : 0;
    return {
        border: '0',
        position: 'absolute',
        top: `${breadcrumbsHeight}px`,
        bottom: `${footerHeight}px`,
        width: '100%',
        height: `calc(100vh - ${platformHeaderHeight + breadcrumbsHeight + footerHeight}px)`,
        overflow: 'hidden',
    };
};
