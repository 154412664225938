import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, styled } from '@mui/material';
import { Skeleton, Container } from '@securecodewarrior/design-system-react';
import { useTranslation } from 'react-i18next';
const StyledIFrame = styled('iframe')(({ theme }) => ({
    display: 'flex',
    border: 'none',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    height: 'calc(100vh - 112px)', //112px = Height of the top navigation bar + outer padding
    width: '100%',
    scrollSnapAlign: 'center',
    scrollSnapStop: 'normal',
}));
const ErrorStateWrapper = styled(Box)(({ theme }) => ({
    paddingBlock: theme.spacing(40),
    display: 'grid',
    placeContent: 'center',
    gap: theme.spacing(6.5),
    '& .MuiTypography-root': {
        maxWidth: 600,
    },
}));
const LoadingState = () => {
    return (_jsx(_Fragment, { children: _jsx(Skeleton, { sx: { height: '100vh' }, variant: "rounded" }) }));
};
const SuccessState = ({ url }) => {
    return _jsx(StyledIFrame, { src: url, title: "EverAfter" });
};
const ErrorState = () => {
    const { t } = useTranslation();
    return (_jsxs(ErrorStateWrapper, { children: [_jsx(Typography, { variant: "h3", align: "center", children: t('pages.administration.successHub.error') }), _jsx(Typography, { variant: "body1", align: "center", children: t('pages.administration.successHub.errorDescription') })] }));
};
const Embedded = ({ url }) => {
    return (_jsxs(Box, { sx: (theme) => ({
            border: `1px solid ${theme.palette.container.border.default}`,
            bgcolor: theme.palette.container.fill.card2,
            borderRadius: 2,
        }), children: [url && _jsx(SuccessState, { url: url }), !url && _jsx(ErrorState, {})] }));
};
export const SuccessHub = ({ isLoading, url }) => {
    const { t } = useTranslation();
    return (_jsxs(Container, { maxWidth: "xl", children: [_jsxs(Box, { mb: 10, sx: { scrollSnapAlign: 'center', scrollSnapStop: 'normal' }, children: [_jsx(Typography, { variant: "overline", children: t('pages.administration.successHub.admin') }), _jsx(Typography, { variant: "h2", children: t('pages.administration.successHub.title') })] }), _jsxs(Box, { p: 6, bgcolor: (theme) => theme.palette.container.fill.card1, border: (theme) => `1px solid ${theme.palette.container.border.default}`, borderRadius: 2, children: [isLoading && _jsx(LoadingState, {}), !isLoading && _jsx(Embedded, { url: url })] })] }));
};
