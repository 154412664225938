'use strict';
/**
 * This script is for CSP (Content Security Policy) for third party inline scripts.
 * Because most of these inline scripts need to be injected variables that vary upon environments,
 * Calculating csp inline script hashes will be a pain.
 * As a result, we moved all these inline scripts into head.js/foot.js to avoid the dynamic hash calculations.
 *
 * Please add third party tag/script integration via head.js/foot.js.
 */

/**
 * Get the env variables.
 */
const { SCW_ENV } = window;

// 1. ChurnZero
var ChurnZero = ChurnZero || [];
(function (a, b, c, d) { var cz = a.createElement(b); cz.type = c; cz.async = true; cz.src = d; var s = a.getElementsByTagName(b)[0]; s.parentNode.appendChild(cz); })(document, "script", "text/javascript", "https://eu1analytics.churnzero.net/churnzero.js");

// 2. Munchkin
if (SCW_ENV.MUNCHKIN_ACCOUNT_ID) {
  (function() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://munchkin.marketo.net/munchkin.js';
    document.getElementsByTagName('head')[0].appendChild(s);
  })();
}
// 3. Datadog
if (SCW_ENV.DD_CLIENT_TOKEN && SCW_ENV.DD_APPLICATION_ID) {
  const loadDD = function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.appendChild(d,n)
  };

  const options = {
    clientToken: SCW_ENV.DD_CLIENT_TOKEN,
    applicationId: SCW_ENV.DD_APPLICATION_ID,
    site: 'datadoghq.com',
    service: 'portal-ui',
    env: SCW_ENV.ENV_NAME,
    version: SCW_ENV.GIT_COMMIT,
    sampleRate: 100,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
  };

  loadDD(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM');
  DD_RUM.onReady(function() {
    DD_RUM.init({
      ...options,
      sampleRate: 30,
      trackFrustrations: true,
      trackLongTasks: true,
      allowedTracingOrigins: [/https:\/\/.*\.securecodewarrior\.com.*/],
    });
  });

  loadDD(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-logs-v4.js','DD_LOGS');
  DD_LOGS.onReady(function() {
      DD_LOGS.init({
        ...options,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        forwardReports: 'all'
      })
    });

    window.addEventListener("error", (event)  => {
      DD_LOGS.onReady(() => {
        DD_LOGS.logger.error('Uncaught error', { event });
      });
    });
}
