import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { createFilterState } from './FilterState';
import { isVideo } from '../../../explore/ContentItem';
export const createCybermonFilteringEvent = (state, resultCount) => {
    return {
        event: 'filtering',
        scope: 'cybermon',
        count: resultCount,
        applied_filters_type_language: Array.from(state.language).join(', '),
    };
};
const visibleInState = (state, value) => state.length === 0 || state.includes(value);
export const calculateRelevance = (item, filterState) => {
    if (isVideo(item) || visibleInState(filterState.language, item.language.id)) {
        return 1;
    }
    return 0;
};
export const applyChangeToFilter = (state, change) => {
    switch (change.action) {
        case 'reset':
            return createFilterState();
        case 'changeLanguages':
            state.language = change.value;
            return state;
        default:
            assertNever(change, 'Invalid filter action');
    }
};
export const mapFilterActionToFilteringEventTrigger = (filter) => {
    if (filter.action === 'changeLanguages') {
        return {
            event: 'filter_trigger',
            scope: 'cybermon',
            type: 'replace',
            filter_name: 'languages',
            languages: filter.value,
        };
    }
    throw new Error(`Unhandled filter action: ${filter.action}`);
};
