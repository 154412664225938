import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Skeleton } from '@securecodewarrior/design-system-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const EditMultiAssessmentPageSkeleton = () => {
    return (_jsxs(_Fragment, { children: [_jsx(EditMultiAssessmentTopBarSkeleton, {}), _jsx(EditMultiAssessmentContentSkeleton, {})] }));
};
const EditMultiAssessmentTopBarSkeleton = () => {
    const theme = useTheme();
    return (_jsx(Box, { sx: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(4.5),
            marginBottom: theme.spacing(10),
        }, children: _jsxs(IconButton, { sx: { display: 'flex', alignItems: 'center !important', padding: theme.spacing(4) }, onClick: () => { }, children: [_jsx(ArrowBackIcon, { fontSize: "large" }), _jsx(Typography, { variant: "h3", sx: { marginLeft: theme.spacing(2) }, children: "Assessments" })] }) }));
};
const EditMultiAssessmentSideBarSkeleton = () => {
    return (_jsx(Box, { ml: "20px", display: "grid", columnGap: 5, rowGap: 5, gridAutoRows: "880px", gridTemplateColumns: "repeat(1, 1fr)", children: _jsx(Skeleton, { variant: "rounded", height: "100%" }) }));
};
export const EditMultiAssessmentListSkeleton = (props) => {
    return (_jsxs(Box, { display: "grid", gap: 5, mr: 5, gridTemplateColumns: "repeat(1, 1fr)", children: [!props.hideOverridesSkeleton && _jsx(Skeleton, { variant: "rounded", height: "54px" }), _jsx(Skeleton, { variant: "rounded", height: "260px" }), _jsx(Skeleton, { variant: "rounded", height: "260px" }), _jsx(Skeleton, { variant: "rounded", height: "260px" }), _jsx(Skeleton, { variant: "rounded", height: "260px" })] }));
};
export const EditMultiAssessmentContentSkeleton = () => {
    return (_jsxs(Box, { sx: { display: 'grid', alignItems: 'start', gridTemplateColumns: '380px 1fr', gap: '20px' }, children: [_jsx(EditMultiAssessmentSideBarSkeleton, {}), _jsx(EditMultiAssessmentListSkeleton, {})] }));
};
