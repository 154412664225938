const PROGRAM_STATE = 'programs.my';
const ASSESSMENTS_STATE = '^.list';
const STORAGE_KEY = 'assessments_nav_details';

export class AssessmentNavigationService {
  constructor() {
    this._source = null;
    this._id = null;
  }

  setFrom(params) {
    const currentFrom = this._load();

    if (params && params.from) {
      this._updateReturnDetails(params.from);

      if (currentFrom !== params.from) {
        this._persist(params.from);
      }

      return;
    }

    this._updateReturnDetails(currentFrom);
  }

  getReturnDetails() {
    if (this._source === 'program') {
      return {
        state: PROGRAM_STATE,
        param: this._id,
      };
    }

    if (this._source === 'assessments') {
      return {
        state: ASSESSMENTS_STATE,
      };
    }

    return {
      state: ASSESSMENTS_STATE,
    };
  }

  _load() {
    let value = sessionStorage.getItem(STORAGE_KEY);

    if (!value) {
      value = localStorage.getItem(STORAGE_KEY);
    }

    return value;
  }

  _updateReturnDetails(value) {
    if (value) {
      const [source, id] = value.split(',');

      if (source === 'program' || source === 'assessments') {
        this._source = source;
        this._id = id;
        return;
      }
    }

    this._source = null;
    this._id = null;
  }

  _persist(value) {
    localStorage.setItem(STORAGE_KEY, value);
    sessionStorage.setItem(STORAGE_KEY, value);
  }
}
