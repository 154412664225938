import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.service('TrainingUtils', [
  '$translate',
  function ($translate) {
    var utils = {
      formatCourse: function (_courseObj) {},
      unformatCourse: function (_courseObj) {},
      formatPlayer: function (player) {
        player.displayName =
          player.profile.name.first +
          (player.profile.name.middle ? ' ' + player.profile.name.middle : '') +
          ' ' +
          player.profile.name.last;
      },
      getProgressBars: function (correctAttempts, totalAttempts) {
        var progressArray = [];
        if (totalAttempts === 0) {
          return progressArray;
        }
        var progress = correctAttempts / totalAttempts;
        if (progress === 0) {
          return [0];
        }
        for (var i = 0; i < Math.ceil(progress * 6); i++) {
          progressArray.push(i);
        }
        return progressArray;
      },
      getEmptyProgressBars: function (correctAttempts, totalAttempts) {
        var progressArray = [];
        if (totalAttempts === 0) {
          return [0, 1, 2, 3, 4, 5];
        }
        var progress = correctAttempts / totalAttempts;
        if (progress === 0) {
          return [0, 1, 2, 3, 4];
        }
        for (var i = 0; i < 6 - Math.ceil(progress * 6); i++) {
          progressArray.push(i);
        }
        return progressArray;
      },
      getProgressBarColour: function (progress) {
        if (progress <= 1 / 3) {
          return 'progress-bar-danger';
        } else if (progress <= 2 / 3) {
          return 'progress-bar-warning';
        } else {
          return 'progress-bar-success';
        }
      },
      getNameProperty: function (obj, language) {
        /** Return course/badge name for user's language, default en name if no translated name is present */
        if (!obj) return;
        if (language != 'en') {
          var formattedName = 'name_' + language;
          if (obj[formattedName]) return obj[formattedName];
        }
        return obj.name;
      },
      getDescriptionProperty: function (obj, language) {
        /** Return course/badge description for user's language, default en description if no translated description is present */
        if (!obj) return;
        if (language != 'en') {
          var formattedDescription = 'description_' + language;
          if (obj[formattedDescription]) return obj[formattedDescription];
        }
        return obj.description;
      },
      dateFromObjectId: function (objectId) {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
      },
      //convert milliseconds to displayable time
      convertMsToDisplayTime: function (timespent) {
        //if timespent is greater than one hour
        var minutes = 0;
        var hours = 0;
        var days = 0;
        var interhours = 0;
        var interdays = 0;

        if (timespent > 3600 * 1000 * 24) {
          //This means greater than a day, we are looking to use days
          days = Math.floor(timespent / (1000 * 3600 * 24));
          interdays = Math.floor(timespent % (1000 * 3600 * 24));
          hours = Math.floor(interdays / (1000 * 3600));
          interhours = Math.floor(interdays % (1000 * 3600));
          minutes = Math.round(interhours / (1000 * 60));
          if (minutes === 60) {
            hours = hours + 1;
            if (hours === 24) {
              days = days + 1;
              return days + ' d ';
            } else {
              return days + ' d ' + hours + ' h ';
            }
          }
          if (minutes > 0) {
            return days + ' d ' + hours + ' h ' + minutes + ' m ';
          } else {
            return days + ' d ' + hours + ' h ';
          }
        } else if (timespent > 3600 * 1000) {
          //We are restricted only to the hours
          /*hours = Math.floor(timespent/1000/3600);
            minutes = Math.round((timespent - hours*1000*3600)/1000/60);
            if(minutes===60){ // if the rounding off is very close
              hours =+ 1;
            }
            if(minutes>0){
              return hours + " h " + minutes + " m ";
            }
            else {
              return hours + " h ";
            }*/
          hours = Math.floor(timespent / (1000 * 3600));
          interhours = Math.floor(timespent % (1000 * 3600));
          minutes = Math.round(interhours / (1000 * 60));

          if (minutes === 60) {
            hours = hours + 1;
            if (hours === 24) {
              days = days + 1;
              return days + ' d ';
            } else {
              return hours + ' h ';
            }
          }

          if (minutes > 0) {
            return hours + ' h ' + minutes + ' m ';
          } else {
            return hours + ' h ';
          }
        } else {
          //We have not even completed a single hour
          /*minutes = Math.round(timespent/1000/60);
            return minutes + " mins";*/
          minutes = Math.round(timespent / 1000 / 60);
          if (minutes === 60) {
            return hours + ' h ';
          } else {
            return minutes + ' m ';
          }
        }
      },
      pressEnter: function (event, fn, arg) {
        if (event.keyCode === 13) {
          fn.apply(null, arg);
        }
      },
      confidenceCalculator: function (hintsUsed, totalHints, totalAttempts) {
        if (totalAttempts === 0 || totalHints === 0) return 0;

        return (1 - hintsUsed / totalHints) * 100;
      },
      getLanguageName: function (language) {
        language = this.ensureUnfoldLanguage(language);
        return (
          metadata.languages[language._id].name +
          ' ' +
          metadata.languages[language._id].framework[language._framework].name
        );
      },
      badgesEarned: function (course) {
        if (course.earnedBadges.length === 0) {
          return $translate.instant('NONE');
        } else {
          return _.map(course.earnedBadges, 'name').join(', ');
        }
      },
      ensureUnfoldLanguage: function (language) {
        // Convert language to the format of { _id: 'java', _framework: 'ee' }, if the passed in parameter is a string like 'java.ee'
        if (_.isString(language)) {
          var tokens = language.split('.');
          language = { _id: tokens[0], _framework: tokens[1] };
        }
        return language;
      },
      getAvailableLanguages: function (course, type) {
        var languages = [];
        if (!type) {
          languages.push(utils.getLanguageName(course.language));
        } else {
          languages.push(utils.getLanguageName(course.language));
        }
        return languages.join(', ');
      },
      generateCsvSwal: function () {
        $translate(['GENERATING_CSV_REPORT', 'PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT']).then(
          function (translations) {
            swal({
              title: translations.GENERATING_CSV_REPORT,
              text: translations.PLEASE_GIVE_SOME_TIME_TO_GENERATE_REQUESTED_CSV_EXPORT,
              type: 'info',
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        );
      },
      openCsvSwal: function (url) {
        var isIE = /Trident|MSIE/.test(window.navigator.userAgent);
        $translate(
          [
            'CSV_REPORT_READY',
            'YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS',
          ],
          { dataUrl: url }
        ).then(function (translations) {
          if (window.navigator.msSaveBlob && isIE) {
            // typical IE - freaks out and redirects instantly to about:blank if we try to do this in same tab, cancelling the download
            swal({
              title: translations.CSV_REPORT_READY,
              text: translations.YOUR_REQUESTED_CSV_EXPORT_IS_NOW_READY_FOR_YOU_TO_DOWNLOAD_WITH_SWAL_CLOSE_BUTTON_SAVE_TARGET_AS,
              type: 'success',
              html: true,
              showCancelButton: false,
              showConfirmButton: false,
            });
          } else {
            document.location.href = url;
            swal.close();
          }
        });
      },
      csvEmailSwal: function (isBeingGenerated, type = 'exportAllData') {
        const message =
          type === 'playerData'
            ? 'YOU_CURRENTLY_HAVE_A_PLAYER_COURSE_SUMMARY_CSV_BEING_GENERATED'
            : 'YOU_CURRENTLY_HAVE_A_CHALLENGES_PLAYED_CSV_BEING_GENERATED';

        if (isBeingGenerated) {
          $translate(['CANNOT_GENERATE_CSV_REPORT', message]).then(function (translations) {
            swal({
              title: translations.CANNOT_GENERATE_CSV_REPORT,
              text: translations[message],
              type: 'error',
              html: true,
              showCancelButton: false,
              showConfirmButton: true,
            });
          });
        } else {
          $translate(['SUCCESSFULLY_REQUESTED_CSV_FILE', 'YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED', 'OK']).then(
            function (translations) {
              swal({
                title: translations.SUCCESSFULLY_REQUESTED_CSV_FILE,
                text: translations.YOUR_CSV_FILE_IS_CURRENTLY_BEING_GENERATED,
                type: 'success',
                html: true,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: translations.OK,
              });
            }
          );
        }
      },
      isCourseExpired: function (course) {
        if (course.hasOwnProperty('endDate') && course.endDate != null && course.endDate < Date.now()) {
          return true;
        } else return false;
      },
      displayEndDate: function (course) {
        if (course.hasOwnProperty('endDate') && course.endDate != null) return true;
        else return false;
      },
      isCourseCompleted: function (course) {
        if (course.hasOwnProperty('status') && course.status === 'done') return true;
        else return false;
      },
      displayInviteStatus: function (status) {
        return $translate.instant(status.toUpperCase());
      },
      convertTimestampToDate: function (ts) {
        return new Date(ts);
      },
      getUnlicensedLanguages: function (course, type) {
        var languages = [];
        if (!type) {
          for (var catType in course.unlicensedLanguages) {
            course.unlicensedLanguages[catType].forEach(function (lang) {
              languages.push(utils.getLanguageName(lang));
            });
          }
        } else {
          course.unlicensedLanguages[type].forEach(function (lang) {
            languages.push(utils.getLanguageName(lang));
          });
        }
        return languages.join(', ');
      },
      getLastReminderDate: function (invites) {
        var size = invites.length;
        var lastReminder = invites[size - 1]; //will always be the reminder
        if (lastReminder.type != 'invite') return new Date(lastReminder.sent);
      },
    };

    return utils;
  },
]);
