import angular from 'angular';
import MODULE from './module';

const app = angular.module(MODULE);

app.factory('AdminApiService', [
  '$log',
  '$http',
  '$rootScope',
  '$translate',
  '$window',
  'HttpConfigService',
  function ($log, $http, $rootScope, $translate, $window, HttpConfigService) {
    const { SCW_ENV } = $window;
    const AdminApiService: any = {};
    const AdminApiEndpoint = function (url?: Array<unknown> | string) {
      if (!url) {
        return SCW_ENV.ApiEndpoint + '/management/admin';
      }

      if (Array.isArray(url)) {
        url = url.join('/');
      }

      if (url.indexOf('/') === 0) {
        url = url.substr(1);
      }

      return SCW_ENV.ApiEndpoint + '/management/admin/' + url;
    };

    const languageQueryParameter = function (language) {
      return language ? '&language=' + encodeURIComponent(JSON.stringify(language)) : '';
    };

    const filterQueryParameter = function (filter) {
      return filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : '';
    };

    const filterStringQueryParameter = function (filter) {
      return filter ? '&filter=' + encodeURIComponent(filter) : '';
    };

    const filterActiveUsersQueryParameter = function (filter) {
      return filter ? '&activeUsersFilter=' + encodeURIComponent(filter) : '';
    };

    const filterParams = function (filter) {
      return filter ? { filter: filter } : {};
    };

    const paginationParams = function (options) {
      if (options) {
        return { page: options.page, size: options.size };
      }

      return {};
    };

    function paginationParamsString(pageOptions) {
      if (pageOptions) {
        return (
          (pageOptions.page ? 'page=' + encodeURIComponent(pageOptions.page) + '&' : '') +
          'size=' +
          encodeURIComponent(pageOptions.size) +
          '&'
        );
      }

      return '';
    }

    function sortByStringQueryParameter(sortBy) {
      return sortBy ? '&sortBy=' + encodeURIComponent(sortBy) : '';
    }

    function deltaDurationQueryParameter(deltaDuration) {
      return deltaDuration ? '&delta=' + encodeURIComponent(deltaDuration) : '';
    }

    AdminApiService.endpoint = AdminApiEndpoint;

    AdminApiService.ping = function () {
      $log.debug('AdminApiService - ping()');
      return $http.get(SCW_ENV.ApiEndpoint + '/ping', HttpConfigService.getHttpConfig()).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    /*** company and team ***/

    // for both team and company _id represents team or company id
    AdminApiService.getPreferences = function (_id) {
      $log.debug('AdminApiService - getPreferences(' + _id + ')');
      return $http
        .get(AdminApiEndpoint() + '/preferences/' + _id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.updatePreferences = function (_id, preferences) {
      $log.debug('AdminApiService - updatePreferences(' + _id + '), ', preferences);
      return $http
        .put(AdminApiEndpoint() + '/preferences/' + _id, { model: preferences }, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getAnonymisation = function (_id) {
      $log.debug('AdminApiService - getAnonymisation');
      return $http
        .get(AdminApiEndpoint() + `/preferences/${_id}/anonymization`, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };
    AdminApiService.getSuccessHub = function (_id, shouldCancelOnNavigation = true) {
      $log.debug('AdminApiService - getSuccessHub');
      return $http
        .get(
          AdminApiEndpoint() + `/preferences/${_id}/successHub`,
          shouldCancelOnNavigation ? HttpConfigService.getHttpConfig() : HttpConfigService.getHttpConfigNoIFR()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };
    AdminApiService.getIntegrations = async function (companyId) {
      $log.debug('AdminApiService - getIntegrations(' + companyId + ')');
      return $http
        .get(AdminApiEndpoint() + '/companies/' + companyId + '/integrations', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data ?? [];
        });
    };

    AdminApiService.registerIntegration = async function (companyId, registrationData) {
      return $http
        .post(
          AdminApiEndpoint() + '/companies/' + companyId + '/integrations',
          registrationData,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.deleteIntegration = async function (companyId, integrationId, tenantId) {
      return $http
        .delete(
          `${AdminApiEndpoint()}/companies/${companyId}/integration/${integrationId}/tenant/${tenantId}`,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    /*** company ***/
    AdminApiService.getCompanyList = function (filter, pageOptions, sortBy, customerType, status, clientOwner) {
      $log.debug('AdminApiService - getCompanyList(%s)', filter);
      const fieldsFilterParams: any = {};

      if (customerType) {
        fieldsFilterParams.customerType = customerType;
      }
      if (status) {
        fieldsFilterParams.status = status;
      }
      if (clientOwner) {
        fieldsFilterParams.accountOwnerId = clientOwner;
      }

      return $http(
        angular.extend(
          {
            method: 'GET',
            url: AdminApiEndpoint() + '/companies',
            params: angular.extend({}, fieldsFilterParams, filterParams(filter), paginationParams(pageOptions), {
              sortBy: sortBy,
            }),
          },
          HttpConfigService.getHttpConfig()
        )
      ).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    AdminApiService.getConsolidatedCompanyList = function () {
      return $http
        .get(AdminApiEndpoint() + '/companies/global/consolidatedList', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getCompany = function (companyId) {
      $log.debug('AdminApiService - getCompany(' + companyId + ')');
      return $http
        .get(AdminApiEndpoint() + '/companies/' + companyId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getCompaniesReport = function (
      filter,
      customerType,
      clientOwner,
      status,
      customerPlan,
      sortBy,
      tz
    ) {
      const fieldsFilterParams = {
        ...(customerType && { customerType }),
        ...(status && { status }),
        ...(clientOwner && { accountOwnerId: clientOwner }),
        ...(customerPlan && { customerPlan }),
      };

      const endpoint = AdminApiEndpoint() + '/companies/csv/report';
      AdminApiService.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url: endpoint,
            params: angular.extend({}, { query: fieldsFilterParams }, filterParams(filter), { sortBy: sortBy, tz: tz }),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    AdminApiService.downloadRatingsCsv = function () {
      const url = '/ratings/export/summary.csv';
      // TODO this should probably be SCW_ENV.ApiEndpoint, or have a helper like
      //  the one near the top of util/search.service.js
      // @ts-expect-error Please change to SCW_ENV.ApiEndpoint, but TEST!
      return $http.get(ApiEndpoint + url, HttpConfigService.getHttpConfig()).then(function (response) {
        $log.debug(response.data);
        return response.data;
      });
    };

    AdminApiService.updateCompanySecurity = function (company, securityGroups) {
      const companyId = (company && company._id) || company;

      securityGroups = securityGroups || [];
      $log.debug('AdminApiService - updateCompanySecurity(' + companyId + ') ', securityGroups);
      return $http
        .put(
          AdminApiEndpoint(['companies', companyId, 'securityGroups']),
          { securityGroups: securityGroups },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.checkForDuplicateDomains = function (domain) {
      return $http
        .post(
          AdminApiEndpoint() + '/companies/duplicate-domain-check',
          { domain: domain },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.updateCompany = function (company) {
      const companyId = company._id;
      const updateCompany = JSON.parse(JSON.stringify(company)) as any;

      delete updateCompany.teams;
      delete updateCompany.admins;
      delete updateCompany.createDate;
      delete updateCompany.securityGroups;
      delete updateCompany.loginImageEncryptedId;
      delete updateCompany.apiKeys;

      $log.debug('AdminApiService - updateCompany(' + companyId + ') ', updateCompany);
      return $http
        .put(AdminApiEndpoint() + '/companies/' + companyId, updateCompany, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.deleteCompany = function (companyId) {
      $log.debug('AdminApiService - deleteCompany(' + companyId + ')');
      return $http
        .delete(AdminApiEndpoint() + '/companies/' + companyId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.generateAPIKey = function (companyId, label) {
      $log.debug('AdminApiService - generateAPIKey(' + companyId + ')');
      return $http
        .post(
          AdminApiEndpoint() + '/companies/' + companyId + '/api-key',
          { label: label },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.disableAPIAccess = function (companyId, apiKeyId) {
      $log.debug('AdminApiService - disableAPIAccess(' + companyId + ')');
      return $http
        .delete(
          AdminApiEndpoint() + '/companies/' + companyId + '/api-key/' + apiKeyId,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.generateAdminAPIKey = function (companyId, label) {
      $log.debug('AdminApiService - generateAdminAPIKey(' + companyId + ')');
      return $http
        .post(
          AdminApiEndpoint() + '/companies/' + companyId + '/admin-api-key',
          { label: label },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.getAdminAPIKeyDetails = function (companyId) {
      $log.debug('AdminApiService - gAdminAPIKey(' + companyId + ')');
      return $http
        .get(AdminApiEndpoint() + '/companies/' + companyId + '/admin-api-key', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.disableAdminAPIAccess = function (companyId, apiKeyId) {
      $log.debug('AdminApiService - disableAdminAPIAccess(' + companyId + ')');
      return $http
        .delete(
          AdminApiEndpoint() + '/companies/' + companyId + '/admin-api-key/' + apiKeyId,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.generateScimAPIKey = function (companyId, label) {
      $log.debug('AdminApiService - generateScimAPIKey(' + companyId + ')');
      return $http
        .post(
          AdminApiEndpoint() + '/companies/' + companyId + '/scim-api-key',
          { label: label },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.getScimAPIKeyDetails = function (companyId) {
      $log.debug('AdminApiService - getScimAPIKey(' + companyId + ')');
      return $http
        .get(AdminApiEndpoint() + '/companies/' + companyId + '/scim-api-key', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.disableScimAPIAccess = function (companyId, apiKeyId) {
      $log.debug('AdminApiService - disableScimAPIAccess(' + companyId + ')');
      return $http
        .delete(
          AdminApiEndpoint() + '/companies/' + companyId + '/scim-api-key/' + apiKeyId,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.addCompany = function (company) {
      $log.debug('AdminApiService - addCompany()', company);

      delete company.logoFile;

      return $http
        .post(AdminApiEndpoint() + '/companies', company, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /*** team ***/

    AdminApiService.getTeamList = function (query, filter, pageOptions, sortBy) {
      $log.debug('AdminApiService - getTeamList(%j,%s)', query, filter);
      return $http
        .get(
          AdminApiEndpoint() +
            '/teams?' +
            paginationParamsString(pageOptions) +
            'query=' +
            JSON.stringify(query) +
            filterStringQueryParameter(filter) +
            sortByStringQueryParameter(sortBy),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getTeam = function (teamId) {
      $log.debug('AdminApiService - getTeam(' + teamId + ')');
      return $http
        .get(AdminApiEndpoint() + '/teams/' + teamId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.updateTeam = function (team) {
      const teamId = team._id;
      const updateTeam = JSON.parse(JSON.stringify(team)) as any;

      delete updateTeam.managers;
      delete updateTeam.members;
      delete updateTeam.apiKeys;

      $log.debug('AdminApiService - updateTeam(' + teamId + ') ', updateTeam);
      return $http
        .put(AdminApiEndpoint() + '/teams/' + teamId, updateTeam, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.deleteTeam = function (teamId) {
      $log.debug('AdminApiService - deleteTeam(' + teamId + ')');
      return $http
        .delete(AdminApiEndpoint() + '/teams/' + teamId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.addTeam = function (team) {
      $log.debug('AdminApiService - addTeam()', team);

      return $http
        .post(AdminApiEndpoint() + '/teams', team, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.generateTeamAPIKey = function (companyId, teamId, label) {
      $log.debug('AdminApiService - generateTeamAPIKey(' + teamId + ')');
      return $http
        .post(
          AdminApiEndpoint() + '/teams/' + teamId + '/api-key',
          {
            companyId: companyId,
            label: label,
          },
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.disableTeamAPIAccess = function (teamId, apiKey) {
      $log.debug('AdminApiService - disableTeamAPIAccess(' + teamId + ')');
      return $http
        .delete(AdminApiEndpoint() + '/teams/' + teamId + '/api-key/' + apiKey._id, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.regenInviteToken = function (team) {
      $log.debug('AdminApiService - regenInviteToken()', team);

      return $http
        .post(AdminApiEndpoint() + '/teams/' + team._id + '/regen-invite-token', {}, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.sendWeeklyActivitySummaryTeam = function (teamId, period) {
      $log.debug('AdminApiService - sendWeeklyActivitySummary(' + teamId + ', ' + period + ')');
      return $http
        .post(
          AdminApiEndpoint() + '/teams/' + teamId + '/weekly-activity-summary?period=' + (period || ''),
          {},
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          return response.data;
        });
    };

    /*** audit ***/
    AdminApiService.getAudit = function (companyId, filter, pageOptions, sortBy) {
      $log.debug('AdminApiService - getAudit(%s,%s)', companyId, filter);
      // filter= added to avoid && which causes a schema error in backend
      return $http
        .get(
          AdminApiEndpoint() +
            '/audit/' +
            companyId +
            '?' +
            paginationParamsString(pageOptions) +
            'filter=' +
            sortByStringQueryParameter(sortBy),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    /*** user ***/

    AdminApiService.getUser = function (userId) {
      $log.debug('AdminApiService - getUser(' + userId + ')');
      return $http
        .get(AdminApiEndpoint() + '/users/' + userId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getUsers = function (query, filter, pageOptions, sortBy) {
      if (query.roles && !Array.isArray(query.roles)) {
        query.roles = [query.roles];
      }

      $log.debug('AdminApiService - getUsers(%j,%s)', query, filter);
      return $http
        .get(
          AdminApiEndpoint() +
            '/users?' +
            paginationParamsString(pageOptions) +
            'query=' +
            JSON.stringify(query) +
            filterStringQueryParameter(filter) +
            sortByStringQueryParameter(sortBy),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getUserStatusSummary = function (query, filter) {
      AdminApiService.addLoading();
      if (query.roles && !Array.isArray(query.roles)) {
        query.roles = [query.roles];
      }

      $log.debug('AdminApiService - getUserStatusSummary(%j,%s)', query, filter);
      return $http
        .get(
          AdminApiEndpoint() +
            '/users/status/summary?query=' +
            JSON.stringify(query) +
            filterStringQueryParameter(filter),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          response.data.forEach(function (elem) {
            elem._id = elem._id[0].toUpperCase() + elem._id.substr(1);
            elem._id = elem._id.replace(/_/g, ' ');
          });
          return response.data;
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    AdminApiService.deleteUser = function (userId) {
      $log.debug('AdminApiService - deleteUser(' + userId + ')');
      return $http
        .delete(AdminApiEndpoint() + '/admin/deleteUser/' + userId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        });
    };

    // Not used???

    // AdminApiService.addUser = function (user) {
    //   $log.debug('AdminApiService - addUser()', user);
    //   return $http
    //     .post(AdminApiEndpoint() + '/users', user, HttpConfigService.getHttpConfig())
    //     .then(function (response) {
    //       $log.debug(response.data);
    //       return response.data;
    //     });
    // };

    AdminApiService.updateUser = function (userId, user) {
      // Remove properties that are frontend generated or otherwise invalid
      const INVALID_PROPERTIES = ['name', 'expirationDate', 'existing'];
      INVALID_PROPERTIES.map(function (property) {
        delete user[property];
      });

      delete user.properties.profile.preferredDevLanguages;

      $log.debug('AdminApiService - updateUser()', userId, user);
      return $http
        .put(AdminApiEndpoint() + '/users/' + userId, user, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.inviteUser = function (user) {
      $log.debug('AdminApiService - inviteUser()', user);
      return $http
        .post(AdminApiEndpoint() + '/users/invite', user, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getBulkResendInvitationCount = function (entityType, entityId) {
      return $http
        .get(AdminApiEndpoint(['users/bulkResend', entityType, entityId]), HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data.count;
        });
    };

    AdminApiService.bulkResendInvitations = function (entityType, entityId) {
      return $http
        .post(AdminApiEndpoint(['users/bulkResend', entityType, entityId]), {}, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        });
    };

    AdminApiService.bulkManageUsers = function (users, options) {
      if (!Array.isArray(users)) {
        users = [users];
      }

      const payload = {
        users: users,
        options: options,
      };

      return $http
        .post(AdminApiEndpoint() + '/users/bulkManage', payload, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.resendInvite = function (user) {
      $log.debug('AdminApiService - resendInvite()', user);
      return $http
        .post(AdminApiEndpoint() + '/users/invite/resend', { email: user.email }, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.impersonateUser = function (impersonateEmail) {
      $log.debug('authService - impersonateUser()', impersonateEmail);
      return $http
        .post(AdminApiEndpoint() + '/users/impersonate', { email: impersonateEmail }, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.changeTeam = function (userId, teamId) {
      $log.debug('AdminApiService - changeTeam()', userId, teamId);
      return $http
        .patch(AdminApiEndpoint() + '/users/' + userId + '/team', { _tid: teamId }, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.changeRoles = function (userId, user) {
      $log.debug('AdminApiService - changeRoles()', userId, user);
      return AdminApiService.updateUser(userId, user);
    };

    AdminApiService.removeUser = function (userId) {
      $log.debug('AdminApiService - removeUser()', userId);
      return $http
        .delete(AdminApiEndpoint() + '/users/' + userId, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getUserLog = function (userId, filter, pageOptions, logType) {
      $log.debug('AdminApiService - getUserLog(%s,%s)', userId, filter);

      return $http
        .get(
          AdminApiEndpoint() +
            '/users/' +
            userId +
            '/game-log/' +
            logType +
            '?' +
            paginationParamsString(pageOptions) +
            (filter ? 'filter=' + encodeURIComponent(filter) : ''),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.resetPassword = function (userId) {
      $log.debug('AdminApiService - resetPassword()', userId);
      return $http
        .post(AdminApiEndpoint() + '/users/' + userId + '/reset-password', {}, HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getMetricsTarget = function (_id) {
      $log.debug('AdminApiService - getMetricsTarget(' + _id + ')');
      return $http
        .get(
          SCW_ENV.ApiEndpoint + '/management/metrics/targets/' + encodeURIComponent(_id),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getMetricsTargets = function (language, filter) {
      $log.debug('AdminApiService - getMetricsTargets()');
      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            '/management/metrics/targets?' +
            languageQueryParameter(language) +
            filterQueryParameter(filter),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getTargetProperties = function (options) {
      $log.debug('AdminApiService - getTargetProperties()', options);
      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            '/management/metrics/targets/options?options=' +
            encodeURIComponent(JSON.stringify(options)),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getMetric = function (metricType, options, language) {
      $log.debug('AdminApiService - getMetric()', metricType, options, language);
      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            '/management/metrics/data/' +
            metricType +
            '?options=' +
            encodeURIComponent(JSON.stringify(options)) +
            languageQueryParameter(language),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getPlayerMetrics = function (options, language) {
      $log.debug('AdminApiService - getPlayerMetrics()', options, language);
      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            '/management/metrics/player' +
            '?options=' +
            encodeURIComponent(JSON.stringify(options)) +
            languageQueryParameter(language),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getLeaderboard = function (options, language, pageOptions, deltaDuration, sortBy, filter) {
      $log.debug('AdminApiService - getLeaderboard()', options);

      let url = '/management/metrics/';

      if (options.type === 'team') {
        url = url + 'team-leaderboard';
      } else {
        options.filterActiveUsers = false;
        url = url + 'leaderboard';
      }

      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            url +
            '?' +
            paginationParamsString(pageOptions) +
            'options=' +
            encodeURIComponent(JSON.stringify({ target: options.target })) +
            languageQueryParameter(language) +
            deltaDurationQueryParameter(deltaDuration) +
            sortByStringQueryParameter(sortBy) +
            filterStringQueryParameter(filter) +
            filterActiveUsersQueryParameter(options.filterActiveUsers),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getLeaderboardCSV = function (options, language, type, deltaDuration) {
      let url = '/management/metrics/';
      if (options.type === 'team') {
        url = url + 'team-leaderboard';
      } else {
        options.filterActiveUsers = false;
        url = url + 'leaderboard';
      }
      url = url + '/csv?';

      const typeParam = type ? 'type=' + type + '&' : '';

      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            url +
            typeParam +
            'options=' +
            encodeURIComponent(JSON.stringify({ target: options.target })) +
            languageQueryParameter(language) +
            deltaDurationQueryParameter(deltaDuration) +
            filterActiveUsersQueryParameter(options.filterActiveUsers),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.exportUserListsCSV = function (query) {
      return $http
        .get(AdminApiEndpoint() + '/csv/user-list?query=' + JSON.stringify(query), HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.exportAuditLogCSV = function (query) {
      return $http
        .get(AdminApiEndpoint() + '/csv/audit-log?query=' + JSON.stringify(query), HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.downloadAvailableContentCSV = function (query) {
      return $http
        .get(
          AdminApiEndpoint() + '/csv/available-content?query=' + JSON.stringify(query),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getProgressCSV = function (options, language, type, deltaDuration) {
      const url = '/management/metrics/leaderboard-detailed/progress?';
      const typeParam = type ? 'type=' + type + '&' : '';

      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            url +
            typeParam +
            'options=' +
            encodeURIComponent(JSON.stringify({ target: options.target })) +
            languageQueryParameter(language) +
            deltaDurationQueryParameter(deltaDuration),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getChallengeLogCSV = function (options, language, type, deltaDuration) {
      const url = '/management/metrics/leaderboard-detailed/challenge-log?';
      const typeParam = type ? 'type=' + type + '&' : '';

      return $http
        .get(
          SCW_ENV.ApiEndpoint +
            url +
            typeParam +
            'options=' +
            encodeURIComponent(JSON.stringify({ target: options.target })) +
            languageQueryParameter(language) +
            deltaDurationQueryParameter(deltaDuration),
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.recalculateStats = function (options) {
      $log.debug('AdminApiService - recalculateStats()', options);

      return $http
        .post(
          SCW_ENV.ApiEndpoint + '/management/metrics/recalc-stats',
          options.target,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.recalculateQuests = function (options) {
      $log.debug('AdminApiService - recalculateQuests()', options);

      return $http
        .post(
          SCW_ENV.ApiEndpoint + '/management/metrics/recalc-quests',
          options.target,
          HttpConfigService.getHttpConfig()
        )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.getUserActivitySummary = function (_user, from, to, timezone) {
      $log.debug('AdminApiService - getUserActivitySummary()');

      AdminApiService.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url:
              SCW_ENV.ApiEndpoint +
              '/activity/stats/user/' +
              _user +
              '/summary?from=' +
              encodeURIComponent(from) +
              '&to=' +
              encodeURIComponent(to) +
              '&timezone=' +
              encodeURIComponent(timezone),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    AdminApiService.getTeamActivitySummary = function (_tid, from, to, timezone) {
      $log.debug('AdminApiService - getTeamActivitySummary()');

      AdminApiService.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url:
              SCW_ENV.ApiEndpoint +
              '/activity/stats/team/' +
              _tid +
              '/summary?from=' +
              encodeURIComponent(from) +
              '&to=' +
              encodeURIComponent(to) +
              '&timezone=' +
              encodeURIComponent(timezone),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    AdminApiService.getCompanyActivitySummary = function (_cid, from, to, timezone) {
      $log.debug('AdminApiService - getCompanyActivitySummary()');

      AdminApiService.addLoading();
      return $http(
        angular.extend(
          {
            method: 'GET',
            url:
              SCW_ENV.ApiEndpoint +
              '/activity/stats/company/' +
              _cid +
              '/summary?from=' +
              encodeURIComponent(from) +
              '&to=' +
              encodeURIComponent(to) +
              '&timezone=' +
              encodeURIComponent(timezone),
          },
          HttpConfigService.getHttpConfig()
        )
      )
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        })
        .finally(function () {
          AdminApiService.removeLoading();
        });
    };

    AdminApiService.getAdminLanguageStats = function () {
      $log.debug('AdminApiService - getAdminLanguageStats()');
      return $http
        .get(SCW_ENV.ApiEndpoint + '/language-stats', HttpConfigService.getHttpConfig())
        .then(function (response) {
          $log.debug(response.data);
          return response.data;
        });
    };

    AdminApiService.addLoading = function () {
      $rootScope.currentLoadingMessage = $translate.instant(
        'LoadingMessages.' + Math.floor(Math.random() * $rootScope.loadingMessagesLength)
      );
      $rootScope.loading++;
    };

    AdminApiService.removeLoading = function () {
      $rootScope.loading--;
      if ($rootScope.loading < 0) {
        $rootScope.loading = 0;
      }
    };

    return AdminApiService;
  },
]);
