import angular from 'angular';
import introJs from 'intro.js';

const app = angular.module('SCWChallengeInactivityDetect', []);
export default app.name;

var ACTIVITY_EVENTS = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';
var INACTIVITY_PERIOD = 5 * 60 * 1000; // 5 minutes

app.directive('challengeInactivityDetect', [
  function () {
    return {
      restrict: 'E',
      template: '<challenge-inactivity-popup ng-if="!$root.usersnapDisplaying"></challenge-inactivity-popup>',
    };
  },
]);

app.directive('challengeInactivityPopup', [
  '$state',
  '$stateParams',
  '$translate',
  '$swal',
  function ($state, $stateParams, $translate, $swal) {
    return {
      restrict: 'E',
      link: function (_scope, element, _attrs) {
        var activityHandler = function () {
          if (introJs) introJs().exit(); // close walkthroughs
          $translate([
            'CONTINUE',
            'CHALLENGE_INACTIVE',
            'CHALLENGE_INACTIVE_PICK_UP_FROM_WHERE_YOU_LEFT_OFF_ANYTIME',
          ]).then(function (translations) {
            $swal({
              title: translations.CHALLENGE_INACTIVE,
              text: translations.CHALLENGE_INACTIVE_PICK_UP_FROM_WHERE_YOU_LEFT_OFF_ANYTIME,
              type: 'info',
              html: true,
              confirmButtonText: translations.CONTINUE,
              keyResolution: true,
            }).then(function (_isConfirm) {
              //Need to reload this to get the correct state params needed for screen and other routes
              $state.go($state.current.name, $stateParams, { reload: true });
            });
          });
        };

        var debouncedActivityHandler = _.debounce(activityHandler, INACTIVITY_PERIOD);
        $('body').on(ACTIVITY_EVENTS, debouncedActivityHandler);

        element.on('$destroy', function () {
          $('body').off(ACTIVITY_EVENTS, debouncedActivityHandler);
          debouncedActivityHandler.cancel();
        });

        // start inactivity timer
        debouncedActivityHandler();

        return;
      },
    };
  },
]);
