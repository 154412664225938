import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack } from '@mui/system';
import { CybermonDialog, CybermonDialogContainer, CybermonDialogRiveContainer } from '../styled/CybermonDialog.styled';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useModal } from '../context/ModalContext';
import { useCybermon } from '../hooks/useCybermon';
import { Details } from './CybermonDetail';
import { Box, Typography, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { Rive } from '../../common/Rive/Rive';
import { BadgeImageContainer, BadgeModalImageButton } from '../styled/BadgeModal.styled';
import { useTranslation } from 'react-i18next';
import { cybermonEvents } from '../hooks/Cybermon.analytics';
import { usePlatformContext } from '../../platformContext';
const BadgeImage = ({ cybermon, onClick }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.modal.badgeDetails' });
    return (_jsxs(BadgeImageContainer, Object.assign({}, (cybermon.earned && {
        onClick: onClick,
        role: 'button',
    }), { earned: cybermon.earned, children: [_jsx("img", { src: cybermon.custom_data.images.detail.full, alt: cybermon.name, style: { objectFit: 'cover', height: '100%', width: '100%' } }), cybermon.earned && (_jsx(BadgeModalImageButton, { className: "showAnimation", children: _jsx(Typography, { color: "clickable.button.default", children: t('showAnimation') }) }))] })));
};
const RiveModal = ({ src, onClose }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.modal.badgeDetails' });
    return (_jsxs(_Fragment, { children: [_jsx(CybermonDialogRiveContainer, { children: _jsx(Rive, { src: src }) }), _jsx(Button, { onClick: onClose, variant: "contained", color: "default", sx: (theme) => ({ boxShadow: theme.shadows[1] }), children: t('close') })] }));
};
const CybermonDetailModal = ({ cybermon, imageClicked, }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.cybermon.modal.badgeDetails' });
    const { setCybermonModalOpen } = useModal();
    const { logAnalyticsEvent } = usePlatformContext();
    const badgeAnalytics = cybermonEvents(logAnalyticsEvent);
    const handleImageClicked = () => {
        badgeAnalytics.showAnimation(cybermon.name);
        imageClicked();
    };
    return (_jsx(Card, { sx: { width: '100%', padding: 0, overflow: 'hidden' }, children: _jsxs(Stack, { sx: { height: '100%' }, children: [_jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, overflow: 'hidden' }, children: [_jsx(BadgeImage, { cybermon: cybermon, onClick: handleImageClicked }), _jsx(Details, { cybermon: cybermon })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", p: 5, sx: { borderTop: (theme) => `1px solid ${theme.palette.container.border.default}` }, children: [_jsx(Link, { href: `#/explore?category=${cybermon.custom_data.category}`, onClick: () => badgeAnalytics.exploreActivities(cybermon.name), children: t('exploreActivities') }), _jsx(Button, { onClick: () => setCybermonModalOpen({
                                cybermon: '',
                                isOpen: false,
                            }), variant: "contained", children: t('close') })] })] }) }));
};
export const BadgeModal = () => {
    const { cybermon } = useCybermon();
    const { isCybermonModalOpen, setCybermonModalOpen } = useModal();
    const [selectedCybermon, setSelectedCybermon] = useState();
    const [showRive, setShowRive] = useState(false);
    useEffect(() => {
        setSelectedCybermon(cybermon.find((c) => c.name === isCybermonModalOpen.cybermon));
    }, [cybermon, isCybermonModalOpen]);
    if (!isCybermonModalOpen.cybermon || !selectedCybermon)
        return;
    return (_jsx(CybermonDialog, { open: isCybermonModalOpen.isOpen, PaperComponent: CybermonDialogContainer, PaperProps: {
            sx: { maxWidth: 1280, width: 'unset', overflow: { xs: 'hidden', md: 'auto' }, justifyContent: 'center' },
        }, children: selectedCybermon.name === 'Cyberhero' ? (_jsx(RiveModal, { src: `https://media.securecodewarrior.com/achievements/rive/CyberHero.riv`, onClose: () => setCybermonModalOpen({ cybermon: '', isOpen: false }) })) : !showRive ? (_jsx(CybermonDetailModal, { cybermon: selectedCybermon, imageClicked: () => setShowRive(true) })) : (_jsx(RiveModal, { src: `https://media.securecodewarrior.com/achievements/rive/${selectedCybermon.name}.riv`, onClose: () => setShowRive(false) })) }));
};
