import angular from 'angular';
import moment from 'moment-timezone';
import MODULE from './module';

angular.module(MODULE).controller('EditCompanyModalController', [
  '$uibModalInstance',
  '$scope',
  '$translate',
  '$window',
  'company',
  'AdminApiService',
  'FeatureFlagsApi',
  'FeatureFlags',
  'LanguageUtils',
  'USER_ROLES',
  'ErrorHandler',
  'AuthService',
  'Session',
  '$q',
  'MetadataApiService',
  '$rootScope',
  function (
    $uibModalInstance,
    $scope,
    $translate,
    $window,
    company,
    AdminApiService,
    FeatureFlagsApi,
    FeatureFlags,
    LanguageUtils,
    USER_ROLES,
    ErrorHandler,
    AuthService,
    Session,
    $q,
    MetadataApiService,
    $rootScope
  ) {
    // @tmp
    $window._editCompany = $scope;

    $scope.company = company = angular.copy(company || {});
    if ($scope.company.languages) {
      $scope.company.languages = $scope.company.languages.filter(function (l) {
        return !LanguageUtils.isExclusiveLanguage(l._id, l._framework);
      });
    }

    $scope.apiKeys = company.apiKeys;
    $scope.adminAPI = { maskedKey: '' };
    $scope.scimAPIKeys = { maskedKey: '' };
    company.allowances = company.allowances || {};
    company.i18nLanguagePreference = company.i18nLanguagePreference || 'en';

    if (!company._id) company.accountOwnerId = company.accountOwnerId || Session.user._id;

    if (company.loginImageEncryptedId)
      $scope.customLoginUrl = $window.SCW_ENV.DefaultUiHost + '/#/login?eid=' + company.loginImageEncryptedId;

    // Begin: exposed data
    $scope.auth = AuthService;
    $scope.userRoles = USER_ROLES;
    $scope.languageList = LanguageUtils.languageList();
    $scope.languageOptionList = [];
    $scope.forms = {
      licenseStartDate: (company.licenseStartDate && new Date(company.licenseStartDate)) || null,
      expirationDate: (company.expirationDate && new Date(company.expirationDate)) || null,
    };
    // End: exposed data

    // PLAT-14650 Hide onboarding message and sso error message from old UI
    FeatureFlagsApi.isFeatureEnabled(FeatureFlags.COMMS_CENTRE_LEGACY).then((legacyFlagEnabled) => {
      if (legacyFlagEnabled) {
        $scope.showCustomMessages = true;
      } else {
        FeatureFlagsApi.isFeatureEnabled(FeatureFlags.COMMS_CENTRE_QUILL).then((quillFlagEnabled) => {
          $scope.showCustomMessages = !quillFlagEnabled;
        });
      }
    });

    // Begin: exposed methods
    $scope.isSalesforceIdRequired = isSalesforceIdRequired;
    $scope.removeLogo = removeLogo;
    $scope.removeLoginImage = removeLoginImage;
    $scope.setExpirationDate = setExpirationDate;
    $scope.addAllLanguages = addAllLanguages;
    $scope.setLanguageOptions = setLanguageOptions;
    $scope.changeCustomerPlan = changeCustomerPlan;
    $scope.addAllRemovedLanguages = addAllRemovedLanguages;
    $scope.generateAPIKey = generateAPIKey;
    $scope.disableAPIAccess = disableAPIAccess;
    $scope.generateAdminAPIKey = generateAdminAPIKey;
    $scope.disableAdminAPIAccess = disableAdminAPIAccess;
    $scope.generateScimAPIKey = generateScimAPIKey;
    $scope.disableScimAPIKey = disableScimAPIKey;
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.accountOwners = accountOwners;
    $scope.isAnonymizationEnabled = isAnonymizationEnabled;
    $scope.customerPlanEnabled = true; // Set as true and maintained as we would like to keep the "ADD_ALL" button hidden
    const isEditMode = !!company._id;
    $scope.customerPlanLangMap = new Map();
    $scope.customerPlans = [];
    $scope.comparePlansHelpLink = $window.SCW_ENV.COMPARE_PLANS_HELP_URL;
    $scope.scimAPIKeyLabel = '';
    // End: exposed methods

    MetadataApiService.getCustomerPlans().then((data) => {
      $scope.customerPlanLangMap = new Map(
        (data || []).map((x) => [x._id, x.constraints?.languages || { type: 'allow', value: [] }])
      );
      $scope.customerPlans = (data || [])
        .filter((x) => isEditMode || x.purchasable)
        .map((x) => ({ id: x._id, name: x.name }));

      if (!$rootScope.productTrialEnabled) {
        $scope.customerPlans = $scope.customerPlans.filter((x) => x.id !== 'product_trial');
      }

      setLanguageOptions();
    });

    // Get Company Preferences
    if ($scope.company._id) {
      AdminApiService.getPreferences($scope.company._id)
        .then((data) => {
          $scope.companyPreferences = data;
        })
        .finally(() => {
          $scope.isAdminApiKeyAllowed =
            $rootScope.isUserManagementApiAllowedByCustomerPlan &&
            $scope.companyPreferences.model.api.adminAPIKeyApp.enabled;
          if ($scope.isAdminApiKeyAllowed) {
            AdminApiService.getAdminAPIKeyDetails($scope.company._id).then(function (data) {
              $scope.adminAPI = data;
            });
          }
        });

      // Get and show SCIM API Keys
      AdminApiService.getScimAPIKeyDetails($scope.company._id).then(function (data) {
        $scope.scimAPIKeys = data;
      });
    }

    function accountOwners(list) {
      var found;

      found = _.find(list, { _id: Session.user._id });
      if (!found) list = [Session.user].concat(list);

      found = _.find(list, { _id: company.accountOwnerId });
      if (!found) list = [company.accountOwner].concat(list);

      return list;
    }

    function removeLogo() {
      company.logo = null;
    }

    function removeLoginImage() {
      company.loginImage = null;
    }

    function setExpirationDate() {
      if ($scope.addEdit == 'add') {
        if (company.customerType == 'trial') {
          company.expirationDate = moment().add(3, 'weeks');
          $scope.forms.expirationDate = new Date(company.expirationDate);
        } else {
          company.expirationDate = null;
          $scope.forms.expirationDate = null;
        }
      }
    }

    function isSalesforceIdRequired() {
      return company.customerType !== 'conference' && company.customerType !== 'product_trial';
    }

    function addAllLanguages() {
      company.languages = [];
      for (var i = 0; i < $scope.languageList.length; i++) {
        var lang = $scope.languageList[i];
        if (lang.$$isAvailable) company.languages.push(lang);
      }
    }

    function setLanguageOptions() {
      const allLanguages = LanguageUtils.languageList().filter((x) => x.$$isAvailable);
      const { customerPlan } = company;
      if (customerPlan) {
        const languagesConstraint = $scope.customerPlanLangMap.get(customerPlan);
        const typeOfLanguageConstraint = languagesConstraint?.type ?? 'allow';
        /**
         * This is based on current assumption that the no languages provided means allow all, like for legacy.
         * Unlikely we will create a plan that denies all languages.
         */
        if (languagesConstraint?.value?.length) {
          const set = new Set(languagesConstraint.value);
          if (typeOfLanguageConstraint === 'allow') {
            $scope.languageOptionList = allLanguages.filter((x) => set.has(`${x._id}:${x._framework}`));
          } else {
            $scope.languageOptionList = allLanguages.filter((x) => !set.has(`${x._id}:${x._framework}`));
          }
        } else {
          $scope.languageOptionList = allLanguages;
        }
      } else {
        $scope.languageOptionList = [];
      }
    }

    function changeCustomerPlan() {
      setLanguageOptions();
      addAllRemovedLanguages();
    }

    function addAllRemovedLanguages() {
      company.languages = [...$scope.languageOptionList];
    }

    function generateAPIKey() {
      if (!$scope.reportingApiKeyLabel) return;
      AdminApiService.generateAPIKey(company._id, $scope.reportingApiKeyLabel)
        .then(function (data) {
          $scope.apiKeys.push(data);
          $scope.reportingApiKeyLabel = null;

          swal({
            title: $translate.instant('GENERATED_API_KEY'),
            text: $translate.instant('GENERATED_API_KEY_MSG', { key: data.apiKey }),
            type: 'warning',
            html: true,
            showCancelButton: false,
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GENERATING_API_KEY'), response);
        });
    }

    function disableAPIAccess(apiKey) {
      $translate(['OK', 'DELETE_API_KEY', 'DELETE_API_KEY_MSG', 'ERROR_DELETING_API_KEY']).then(
        function (translations) {
          AdminApiService.disableAPIAccess(company._id, apiKey._id)
            .then(function () {
              _.remove($scope.apiKeys, function (value) {
                return value._id === apiKey._id;
              });

              swal({
                title: translations.DELETE_API_KEY,
                text: translations.DELETE_API_KEY_MSG,
                type: 'info',
                showCancelButton: false,
                confirmButtonText: translations.OK,
              });
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_DELETING_API_KEY, response);
            });
        }
      );
    }

    function generateAdminAPIKey() {
      AdminApiService.generateAdminAPIKey(company._id, $scope.adminApiKeyLabel)
        .then(function (data) {
          $scope.adminAPI.push(data);
          $scope.adminApiKeyLabel = null;
          swal({
            title: $translate.instant('GENERATED_ADMIN_API_KEY'),
            text: $translate.instant('GENERATED_API_KEY_MSG', { key: data.adminAPIKey }),
            html: true,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GENERATING_ADMIN_API_KEY'), response);
        });
    }

    function disableAdminAPIAccess(apiKey) {
      $translate(['OK', 'DELETE_ADMIN_API_KEY', 'DELETE_ADMIN_API_KEY_MSG', 'ERROR_DELETING_ADMIN_API_KEY']).then(
        function (translations) {
          AdminApiService.disableAdminAPIAccess(company._id, apiKey._id)
            .then(function () {
              _.remove($scope.adminAPI, function (value) {
                return value._id === apiKey._id;
              });

              swal({
                title: translations.DELETE_ADMIN_API_KEY,
                text: translations.DELETE_ADMIN_API_KEY_MSG,
                type: 'info',
                showCancelButton: false,
                confirmButtonText: translations.OK,
              });
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_DELETING_ADMIN_API_KEY, response);
            });
        }
      );
    }

    function generateScimAPIKey() {
      AdminApiService.generateScimAPIKey(company._id, $scope.scimAPIKeyLabel)
        .then(function (data) {
          $scope.scimAPIKeys.push(data);
          $scope.scimAPIKeyLabel = null;
          swal({
            title: $translate.instant('GENERATED_SCIM_API_KEY'),
            text: $translate.instant('GENERATED_API_KEY_MSG', { key: data.scimAPIKey }),
            html: true,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: $translate.instant('OK'),
          });
        })
        .catch(function (response) {
          ErrorHandler.addHttpError($translate.instant('ERROR_GENERATING_API_KEY'), response);
        });
    }

    function disableScimAPIKey(scimAPIKey) {
      $translate(['OK', 'DELETE_SCIM_API_KEY', 'DELETE_SCIM_API_KEY_MSG', 'ERROR_DELETING_SCIM_API_KEY']).then(
        function (translations) {
          AdminApiService.disableScimAPIAccess(company._id, scimAPIKey._id)
            .then(function () {
              _.remove($scope.scimAPIKeys, function (value) {
                return value._id === scimAPIKey._id;
              });

              swal({
                title: translations.DELETE_SCIM_API_KEY,
                text: translations.DELETE_SCIM_API_KEY_MSG,
                type: 'info',
                showCancelButton: false,
                confirmButtonText: translations.OK,
              });
            })
            .catch(function (response) {
              ErrorHandler.addHttpError(translations.ERROR_DELETING_SCIM_API_KEY, response);
            });
        }
      );
    }

    function getImageDataUri(file, maxHeight) {
      return $q(function (resolve, reject) {
        var image = new Image();
        image.onload = function () {
          var width = image.width;
          var height = image.height;
          var maxWidth = Infinity;

          var scale = Math.max(1, height / maxHeight, width / maxWidth);
          width /= scale;
          height /= scale;

          var canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          URL.revokeObjectURL(image.src);

          var result = canvas.toDataURL('image/png');
          resolve(result);
        };
        image.onerror = reject;
        image.src = file.$ngfBlobUrl;
      });
    }

    async function save() {
      save.inprogress = true;

      // convert date to timestamp (easier to process in messages)
      if ($scope.forms.expirationDate) {
        company.expirationDate = moment($scope.forms.expirationDate).endOf('day').valueOf();
      } else {
        company.expirationDate = null;
      }

      company.licenseStartDate = moment($scope.forms.licenseStartDate).endOf('day').valueOf();
      company.onboardingMessage = company.onboardingMessage || '';
      company.ssoErrorMessage = company.ssoErrorMessage || '';

      if (!company.logoFile && !company.logo) company.logo = null;

      if (!company.loginImageFile && !company.loginImage) company.loginImage = null;

      var saveMethod = company._id ? 'updateCompany' : 'addCompany';

      var promises = [];

      if (company.logoFile) {
        promises.push(
          getImageDataUri(company.logoFile, 100)
            .then(function (result) {
              company.logo = result;
            })
            .catch(function (_response) {
              $translate(['NOT_AN_IMAGE']).then(function (translations) {
                ErrorHandler.addError(translations.NOT_AN_IMAGE);
              });
            })
        );
      }

      if (company.loginImageFile) {
        promises.push(
          getImageDataUri(company.loginImageFile, 340)
            .then(function (result) {
              company.loginImage = result;
            })
            .catch(function (_response) {
              $translate(['NOT_AN_IMAGE']).then(function (translations) {
                ErrorHandler.addError(translations.NOT_AN_IMAGE);
              });
            })
        );
      }

      delete company.numLicenses;
      delete company.logoFile;
      delete company.loginImageFile;
      delete company.loginImageEncryptedId;
      delete company.auditLog;
      delete company.apiKeyDisplay;
      delete company.apiKeyUpdateAt;
      delete company.adminAPIKey;

      const p = promises.length ? $q.all(promises) : Promise.resolve();

      return p.then(function () {
        return AdminApiService[saveMethod](company)
          .then(function (saved) {
            $uibModalInstance.close(saved);
          })
          .catch(function (response) {
            if (company._id) return ErrorHandler.addHttpError($translate.instant('FAILED_TO_UPDATE_COMPANY'), response);
            return ErrorHandler.addHttpError($translate.instant('FAILED_TO_CREATE_COMPANY'), response);
          })
          .finally(function () {
            save.inprogress = false;
          });
      });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function isAnonymizationEnabled() {
      return _.get(Session, 'user.properties.preferences.anonymization') === 3;
    }
  },
]);
